import { asyncActionName } from "src/utils/AsyncUtils";
import {
  GET_COMMUNITY_CONNECTIONS,
  GET_COMMUNITY_SUGGESTIONS,
  GET_COMMUNITY_PENDING_RECEIVED_CONNECTIONS,
  GET_COMMUNITY_PENDING_SENT_CONNECTIONS,
} from "src/actions";

import { initialState } from "src/store";

const ConnectionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case asyncActionName(GET_COMMUNITY_CONNECTIONS).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_COMMUNITY_CONNECTIONS).success:
      return {
        ...state,
        connections: payload.data.data,
        pageDetails: payload.data.page,
        success: true,
      };
    case asyncActionName(GET_COMMUNITY_CONNECTIONS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };

    case asyncActionName(GET_COMMUNITY_SUGGESTIONS).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_COMMUNITY_SUGGESTIONS).success:
      return {
        ...state,
        suggestions: payload.data.data,
        suggestionsPageDetails: payload.data.page,
        success: true,
      };
    case asyncActionName(GET_COMMUNITY_SUGGESTIONS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };

    case asyncActionName(GET_COMMUNITY_PENDING_RECEIVED_CONNECTIONS).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_COMMUNITY_PENDING_RECEIVED_CONNECTIONS).success:
      return {
        ...state,
        received: payload.data.data,
        receivedPageDetails: payload.data.page,
        success: true,
      };
    case asyncActionName(GET_COMMUNITY_PENDING_RECEIVED_CONNECTIONS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };

    case asyncActionName(GET_COMMUNITY_PENDING_SENT_CONNECTIONS).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_COMMUNITY_PENDING_SENT_CONNECTIONS).success:
      return {
        ...state,
        sent: payload.data.data,
        sentPageDetails: payload.data.page,
        success: true,
      };
    case asyncActionName(GET_COMMUNITY_PENDING_SENT_CONNECTIONS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };

    default:
      return state;
  }
};

export default ConnectionReducer;
