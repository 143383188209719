import React, { useContext, useState } from "react";
import classes from "./reset_password_v2.module.scss";
import tefLogo from "src/assets/img/logo/logo_new.webp";
import tefLogo_mobile from "src/assets/img/logo/logo_new_white.svg";

import { forgetPassword } from "src/requests/AuthRequest";
import { Link, useHistory } from "react-router-dom";
import useForm from "src/utils/useForm";
import { validateEmail } from "src/utils/Validations/validateSignup";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Spinner,
  FormFeedback,
} from "reactstrap";
import { Context } from "src/store";
import StatusModal from "src/components/StatusModal/StatusModal";
import { CarouselContent } from "src/Auth/Login/loginv2";
import img1 from "src/assets/img/bg/forgot_pwd.webp";

import { useCarousel } from "src/utils/useCarousel";

const slides = [
  {
    image: img1,
    title: "Be Connected!",
    paragraph:
      "Connect with entrepreneurs, mentors, and invsetors alike across and beyond Africa",
  },
];

const ResetPasswordv2 = () => {
  const [alertDetails, setAlertDetails] = useState({
    alertMessage: "",
    alertType: "",
  });
  const { dispatch } = useContext(Context);

  const [isLoading, setIsLoading] = useState(false);
  const { currentIndex, snapToSlide } = useCarousel(slides.length, 3000);

  // const _handleScoll = () => {
  //   const c_ss = document.getElementById("cp_ss");
  //   const l_ss = document.getElementById("lp_ss");
  //   if (c_ss) {
  //     const distanceTop_c_ss = c_ss.getBoundingClientRect().top;
  //     if (distanceTop_c_ss <= -443.859375) {
  //       l_ss.style.display = "none";
  //     } else {
  //       l_ss.style.display = "block";
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", _handleScoll);
  //   return () => window.addEventListener("scroll", _handleScoll);
  // }, []);

  const dismissAlert = () => {
    setAlertDetails({ alertMessage: "", alertType: "" });
  };

  const history = useHistory();

  const handleSend = () => {
    setIsLoading(true);

    setAlertDetails({
      ...alertDetails,
      alertMessage: "",
      alertType: "",
    });

    const userInfo = {
      email: values.email,
    };

    forgetPassword(dispatch, userInfo)
      .then((res) => {
        const { status, data } = res;
        if (status !== 200) {
          const { message } = data;
          setIsLoading(false);
          setAlertDetails({
            ...alertDetails,
            alertMessage: message,
            alertType: "danger",
            showButton: true,
            buttonClick: (e) => dismissAlert(),
            buttonText: "Close",
            title: message,
          });
        } else {
          setIsLoading(false);
          setAlertDetails({
            // ...alertDetails,
            alertMessage: res.data.message,
            alertType: "success",
            showButton: true,
            buttonText: "Back to login",
            buttonClick: () => history.replace("/login"),
          });
        }
      })
      .catch((_error) => {
        setIsLoading(false);
        setAlertDetails({
          ...alertDetails,
          alertMessage: "Something went wrong, please contact support",
          alertType: "danger",
        });
      });
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    handleSend,
    validateEmail
  );

  return (
    <div className={classes.container}>
      <div className={classes.formSection}>
        <div className={classes.logoContainter}>
          <Link to="/">
            <img src={tefLogo} alt="tef_logo" className={classes.tef_logo} />
          </Link>
        </div>

        <div className={classes.logoContainter_mobile} id="lp_ss">
          <Link to="/">
            <img
              src={tefLogo_mobile}
              alt="tef_logo"
              className={classes.tef_logo_mobile}
            />
          </Link>
        </div>

        <div className={classes.carouselSS} id="cp_ss">
          <CarouselContent
            slide={slides[currentIndex]}
            slides={slides}
            active={currentIndex}
            snapToSlide={snapToSlide}
          />
        </div>

        <div className={classes.formContainer}>
          <h2 className={classes.logintitle}>Forgot Password</h2>
          <p className={classes.newUser}>Enter your registered email below</p>
          <Form onSubmit={handleSubmit}>
            <FormGroup className={classes.f_row}>
              <Label className={classes.formLabel}>Email Address</Label>

              <Input
                className={classes.f_input}
                placeholder="Enter your registered email below"
                required
                onChange={handleChange}
                value={values.email}
                name="email"
                invalid={errors.email}
              />
              {errors.email && (
                <FormFeedback
                  className={classes.errorText}
                  style={{ color: "red" }}
                >
                  {errors.email}
                </FormFeedback>
              )}
            </FormGroup>

            <Button
              className={classes.loginBTN}
              disabled={isLoading}
              data-testid="reset-password"
            >
              Proceed{" "}
              {isLoading && (
                <Spinner
                  color="white"
                  size={5}
                  style={{ width: 15, height: 15 }}
                />
              )}
            </Button>
          </Form>
          <StatusModal
            status={alertDetails.alertType}
            open={alertDetails.alertMessage && alertDetails.alertType}
            heading={alertDetails.title && alertDetails.title}
            text={alertDetails.alertMessage && alertDetails.alertMessage}
            showButton={alertDetails.showButton}
            buttonText={alertDetails.buttonText}
            onButtonClick={alertDetails.buttonClick}
            toggle={() =>
              setAlertDetails({
                alertMessage: "",
                alertType: "",
              })
            }
          ></StatusModal>
        </div>
      </div>

      {slides.map((slide, idx) => {
        if (currentIndex === idx) {
          return (
            <div
              key={idx}
              className={`${classes.carouselContainer} ${classes.fade}`}
              style={{ backgroundImage: `url(${slide.image})` }}
            >
              <div className={classes.carouselBS}>
                <CarouselContent
                  slide={slide}
                  slides={slides}
                  active={currentIndex}
                  snapToSlide={snapToSlide}
                />
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default ResetPasswordv2;
