import React, { useState } from "react";
import classes from "./Requests.module.scss";
import ReactAvatar from "react-avatar";
import { Button } from "reactstrap";
import Avatars from "src/components/Avatar/Avatar";
// import { getManyUserLightProfile } from "src/requests/ProfileRequest";
import {
  studyGroupAcceptInvite,
  studyGroupCancelInvite,
} from "src/requests/StudyGroupsRequest";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import { updateQueryStringParameter } from "src/utils/helper";

const RequestItem = ({
  isSent,
  groupInfo,
  getList,
  loadingUser,
  invitee,
  userInfo,
  currentPage,
}) => {
  // const [invitee, setInvitee] = useState(null);
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  // const [, setLoadinguser] = useState(false);
  const inviteText = () => {
    // return groupInfo.status === "pending_invite"
    //   ? `You requested ${(userInfo && userInfo.firstName) || "Anonymous"} ${
    //       (userInfo && userInfo.lastName) || "Anonymous"
    //     }  to join this group`
    //   : "You requested to join this group";
    return "You requested to join this group";
  };

  const _acceptInvite = () => {
    setLoadingAccept(true);
    studyGroupAcceptInvite(groupInfo.id).then(() => {
      getList(currentPage);
      setLoadingAccept(false);
    });
  };

  const _rejectInvite = () => {
    setLoadingReject(true);
    studyGroupCancelInvite(groupInfo.id, groupInfo.inviteeUserId).then(() => {
      getList(currentPage);
      setLoadingReject(false);
    });
  };

  const _getRedirectUrl = () => {
    let url = `/training/studygroups/group?groupId=${groupInfo.group.id}&course=${groupInfo.group.courseId}`;
    if (!isSent) {
      url = updateQueryStringParameter(url, "acceptance", true);
      url = updateQueryStringParameter(url, "invite", groupInfo.id);
    }

    return url;
  };

  return (
    <div>
      <div className={classes.requestItemC}>
        <div className={classes.groupHeading}>
          {groupInfo.group.imageUrl ? (
            <Avatars src={groupInfo.group.imageUrl} dimension={"50px"} />
          ) : (
            <ReactAvatar round name={groupInfo.group.name} size={50} />
          )}

          <div>
            <h5>
              <Link to={_getRedirectUrl()}> {groupInfo.group.name}</Link>
            </h5>
            {isSent && <span>{inviteText()}</span>}
            {!isSent && (
              <span>
                {userInfo && userInfo.id
                  ? `${userInfo.firstName} ${userInfo.lastName} has`
                  : "You have been"}{" "}
                invited to join this group
              </span>
            )}
          </div>
        </div>
        {isSent && (
          <div className={classes.btnGroup}>
            <Button color="primary" outline className={classes.btn}>
              Pending
            </Button>
          </div>
        )}
        {!isSent && (
          <div className={classes.btnGroup}>
            <Button
              color="secondary"
              outline
              className={classes.btn}
              onClick={_acceptInvite}
            >
              Accept{" "}
              {loadingAccept && (
                <Loader
                  type="Oval"
                  height={20}
                  width={20}
                  color="green"
                  style={{ float: "right" }}
                />
              )}
            </Button>
            <Button
              className={classes.btn}
              color="default"
              onClick={_rejectInvite}
            >
              Decline
              {loadingReject && (
                <Loader
                  type="Oval"
                  height={20}
                  width={20}
                  color="red"
                  style={{ float: "right" }}
                />
              )}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestItem;
