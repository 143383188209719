import React from "react";
import Layout from "src/components/Layout/Layout";
import HomePage from "./HomePage/HomePage";
import RightBar from "src/modules/profile/components/RightBar/RightBar";
const Index = (props) => {
  return (
    <Layout heading="" SideContent={<RightBar />}>
      <div className="notranslate">
        <HomePage {...props} />
      </div>
    </Layout>
  );
};

Index.propTypes = {};

export default Index;
