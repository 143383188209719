import axios from "axios";
import { ProfileUrl, AuthURL } from "src/constants";
import { env } from "src/constants/env";

export const _truncateText = (value, maxLength) => {
  if (maxLength === null) {
    return value;
  } else {
    if (value.length > maxLength) {
      return value.substring(0, maxLength) + " ...";
    }
    return value;
  }
};

export const retrieveUserProfile = async (id) => {
  const res = await axios.get(`${ProfileUrl.GET_USER_PROFILE_URL}/${id}`);
  return res;
};

export const retrieveUserInfo = async (id) => {
  const res = await axios.get(`${AuthURL.GET_USER_URL}/${id}`, {
    auth: {
      username: env.REACT_APP_CLIENT_ID,
      password: env.REACT_APP_CLIENT_SECRET,
    },
  });
  return res;
};

export const isMentor = (user) => {
  // eslint-disable-next-line no-prototype-builtins
  return user.hasOwnProperty("tef-mentor");
};
