import axios from "axios";

import "react-toastify/dist/ReactToastify.css";
import {
  GET_ALL_DUEDILLIGENCE_DOCUMENTS,
  GET_ALL_SEEDCAPITAL_DOCUMENTS,
  POST_BANKDETAILS,
  GET_BANKDETAILS,
  GET_DUEDILLIGENCE_REQUIRED_DOCUMENTS,
  GET_SEEDCAPITAL_REQUIRED_DOCUMENTS,
  GET_BUSINESSPLANS_REQUIRED_DOCUMENTS,
  GET_ALL_BUSINESSPLAN_DOCUMENTS,
} from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import { DmsUrl } from "src/constants";

export const getDuelligenceDocuments = (dispatch, programId) => {
  dispatch(asyncActions(GET_ALL_DUEDILLIGENCE_DOCUMENTS).loading(true));
  return axios({
    method: "GET",
    url: `${DmsUrl.GET_ALL_DUEDILLIGENCE_DOCUMENTS}/${programId}`,
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(GET_ALL_DUEDILLIGENCE_DOCUMENTS).loading(false));
        const { data } = response.data;
        localStorage.setItem("dueDilligence", JSON.stringify(data));
        dispatch(asyncActions(GET_ALL_DUEDILLIGENCE_DOCUMENTS).success(data));
      }
    })
    .catch((error) => {
      dispatch(asyncActions(GET_ALL_DUEDILLIGENCE_DOCUMENTS).loading(false));
      dispatch(asyncActions(GET_ALL_DUEDILLIGENCE_DOCUMENTS).failure(error));
    });
};

export const getSeedCapitalDocuments = (dispatch, programId) => {
  dispatch(asyncActions(GET_ALL_SEEDCAPITAL_DOCUMENTS).loading(true));
  return axios({
    method: "GET",
    url: `${DmsUrl.GET_ALL_SEEDCAPITAL_DOCUMENTS}/${programId}`,
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(GET_ALL_SEEDCAPITAL_DOCUMENTS).loading(false));
        const { data } = response.data;
        localStorage.setItem("seedCapital", JSON.stringify(data));
        dispatch(asyncActions(GET_ALL_SEEDCAPITAL_DOCUMENTS).success(data));
      }
    })
    .catch((error) => {
      dispatch(asyncActions(GET_ALL_SEEDCAPITAL_DOCUMENTS).loading(false));
      dispatch(asyncActions(GET_ALL_SEEDCAPITAL_DOCUMENTS).failure(error));
    });
};

export const getBusinessPlanDocuments = (dispatch, programId) => {
  dispatch(asyncActions(GET_ALL_BUSINESSPLAN_DOCUMENTS).loading(true));
  return axios({
    method: "GET",
    url: `${DmsUrl.GET_ALL_BUSINESSPLAN_DOCUMENTS}/${programId}`,
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(GET_ALL_BUSINESSPLAN_DOCUMENTS).loading(false));
        const { data } = response.data;
        localStorage.setItem("businessPlan", JSON.stringify(data));
        dispatch(asyncActions(GET_ALL_BUSINESSPLAN_DOCUMENTS).success(data));
      }
    })
    .catch((error) => {
      dispatch(asyncActions(GET_ALL_BUSINESSPLAN_DOCUMENTS).loading(false));
      dispatch(asyncActions(GET_ALL_BUSINESSPLAN_DOCUMENTS).failure(error));
    });
};

export const saveDueDilligenceDocumentsForLater = (payload) => {
  return axios({
    method: "POST",
    url: DmsUrl.POST_DUEDILLIGENCE_DOCUMENTS,
    data: payload,
    params: {
      documentSubmitType: 1,
    },
  });
};

export const submitDueDilligenceDocuments = (payload) => {
  return axios({
    method: "POST",
    url: DmsUrl.POST_DUEDILLIGENCE_DOCUMENTS,
    data: payload,
    params: {
      documentSubmitType: 2,
    },
  });
};

export const submitSeedCapitalDocuments = (payload) => {
  return axios({
    method: "POST",
    url: DmsUrl.POST_SEEDCAPITAL_DOCUMENTS,
    data: payload,
    params: {
      documentSubmitType: 2,
    },
  });
};

export const submitBusinessPlansDocuments = (payload) => {
  return axios({
    method: "POST",
    url: DmsUrl.POST_BUSINESSPLANS_DOCUMENTS,
    data: payload,
    params: {
      documentSubmitType: 2,
    },
  });
};

export const submitBusinessPlansForLater = (payload) => {
  return axios({
    method: "POST",
    url: DmsUrl.POST_BUSINESSPLANS_DOCUMENTS,
    data: payload,
    params: {
      documentSubmitType: 1,
    },
  });
};

export const saveSeedCapitalDocumentsForLater = (payload) => {
  return axios({
    method: "POST",
    url: DmsUrl.POST_SEEDCAPITAL_DOCUMENTS,
    data: payload,
    params: {
      documentSubmitType: 1,
    },
  });
};

export const postBankDetails = (dispatch, payload) => {
  dispatch(asyncActions(POST_BANKDETAILS).loading(true));
  return axios({
    method: "POST",
    url: DmsUrl.POST_BANKDETAILS,
    data: payload,
  });
};

export const getBankDetails = (dispatch) => {
  dispatch(asyncActions(GET_BANKDETAILS).loading(true));
  return axios({
    method: "GET",
    url: DmsUrl.GET_BANKDETAILS,
  })
    .then((response) => {
      if (response.status === 200) {
        const {
          data: { data },
        } = response;
        dispatch(asyncActions(GET_BANKDETAILS).loading(false));
        dispatch(asyncActions(GET_BANKDETAILS).success(data));
      }
    })
    .catch((error) => {
      dispatch(asyncActions(GET_BANKDETAILS).loading(false));
      dispatch(asyncActions(GET_BANKDETAILS).failure(error));
    });
};

export const getDueDilligenceRequiredDocuments = (dispatch, submissionType) => {
  return axios({
    method: "GET",
    url: `${DmsUrl.GET_STAGE_REQUIRED_DOCUMENTS}/${submissionType}`,
  })
    .then((response) => {
      if (response.status === 200) {
        const {
          data: { data },
        } = response;
        dispatch(
          asyncActions(GET_DUEDILLIGENCE_REQUIRED_DOCUMENTS).loading(false)
        );
        dispatch(
          asyncActions(GET_DUEDILLIGENCE_REQUIRED_DOCUMENTS).success(data)
        );
      }
    })
    .catch((error) => {
      dispatch(
        asyncActions(GET_DUEDILLIGENCE_REQUIRED_DOCUMENTS).loading(false)
      );
      dispatch(
        asyncActions(GET_DUEDILLIGENCE_REQUIRED_DOCUMENTS).failure(error)
      );

      return error;
    });
};

export const getBusinessplansRequiredDocuments = (dispatch, submissionType) => {
  return axios({
    method: "GET",
    url: `${DmsUrl.GET_STAGE_REQUIRED_DOCUMENTS}/${submissionType}`,
  })
    .then((response) => {
      if (response.status === 200) {
        const {
          data: { data },
        } = response;
        dispatch(
          asyncActions(GET_BUSINESSPLANS_REQUIRED_DOCUMENTS).loading(false)
        );
        dispatch(
          asyncActions(GET_BUSINESSPLANS_REQUIRED_DOCUMENTS).success(data)
        );
      }
    })
    .catch((error) => {
      dispatch(
        asyncActions(GET_BUSINESSPLANS_REQUIRED_DOCUMENTS).loading(false)
      );
      dispatch(
        asyncActions(GET_BUSINESSPLANS_REQUIRED_DOCUMENTS).failure(error)
      );

      return error;
    });
};

export const getSeedCapitalRequiredDocuments = (dispatch, submissionType) => {
  return axios({
    method: "GET",
    url: `${DmsUrl.GET_STAGE_REQUIRED_DOCUMENTS}/${submissionType}`,
  })
    .then((response) => {
      if (response.status === 200) {
        const {
          data: { data },
        } = response;
        dispatch(
          asyncActions(GET_SEEDCAPITAL_REQUIRED_DOCUMENTS).loading(false)
        );
        dispatch(
          asyncActions(GET_SEEDCAPITAL_REQUIRED_DOCUMENTS).success(data)
        );
      }
    })
    .catch((error) => {
      dispatch(asyncActions(GET_SEEDCAPITAL_REQUIRED_DOCUMENTS).loading(false));
      dispatch(asyncActions(GET_SEEDCAPITAL_REQUIRED_DOCUMENTS).failure(error));

      return error;
    });
};

export const postBuilderInfo = (payload) => {
  return axios({
    method: "POST",
    url: DmsUrl.POST_BUSINESSPLANS_BUILDER,
    data: payload,
    params: {
      documentSubmitType: 2,
    },
  });
};

export const getBuilderInfo = (programId) => {
  return axios({
    method: "GET",
    url: `${DmsUrl.GET_BUSINESSPLANS_BUILDER}/${programId}`,
  });
};

export const getTemplates = (params) => {
  const { programId, submissionType } = params;
  return axios({
    method: "GET",
    url: DmsUrl.GET_TEMPLATES,
    params: {
      programId,
      submissionType,
    },
  });
};

export const getSettings = (payload) => {
  const { programId, submissionType } = payload;
  return axios({
    method: "GET",
    url: DmsUrl.GET_SETTINGS,
    params: {
      programId,
      submissionType,
    },
  });
};

export const uploadSeedCapitalInvoice = (payload) => {
  return axios({
    method: "POST",
    url: DmsUrl.UPLOAD_SEED_CAPITAL_INVOICE,
    data: payload,
  });
};
