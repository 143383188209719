export const personalInfo = {
  applicationId: "",
  firstName: "",
  lastName: "",
  middleName: "",
  gender: "",
  dateOfBirth: "",
  phoneNumber: "",
  email: "",
  levelOfEducation: "",
  yearsOfExperience: "",
  state: "",
  city: "",
  address: "",
  appliedForOtherFoundationGrant: false,
  nameOfOrganization: "",
  typeOfId: "",
  idUrl: "",
  staticIdUrl: "",
  howYouHearAboutUs: "",
  kinFirstName: "",
  kinLastName: "",
  kinMiddleName: "",
  kinPhone: "",
  kinEmail: "",
  // mentorFirstName: "",
  // mentorLastName: "",
  // mentorMiddleName: "",
  // mentorPhone: "",
  // mentorEmail: "",
  // mentorGender: "",
  aboutIdea: "",
  yearsOfStartupExperience: "",
  isIdeaInFieldOfExperience: false,
  sdgs: [],
  businessName: "",
  businessAddress: "",
  businessIndustry: "",
  businessState: "",
  businessCity: "",
  idNumber: "",
  businessCountryId: "",
  industrySubSector: "",
  businessAge: 0,
  isWorkingInTeam: false,
  teamSize: 0,
  hoursPerWeekInvested: 0,
  isIdeaProfitable: false,
  monthlyIncome: 0,
  haveBusinessPartners: false,
  hasBusinessPartnerConsent: null,
  partnerConsentLetterUrl: "",
  // hasBusinessPartnerConsent: false,
  status: "Ongoing",
  languageId: "",
  countryId: "",
  isBusinessRural: false,
  isResidentialRural: false,
  skipTour: false,
  ageRange: "",
  isBusinessAddressGreenEconomyOrClimateChange: false,
  businessAddressGreenEconomyOrClimateChangeDescription: "",
  businessWebsite: "",
  businessStage: "",
  businessRegistrationNumber: "",
  businessLocalGovernmentArea: "",
  motivation: "",
  isBusinessRegistered: false,

  validationErrors: {
    firstName: null,
    lastName: null,
    ageRange: null,

    // middleName: null,
    gender: null,
    dateOfBirth: null,
    phoneNumber: null,
    email: null,
    levelOfEducation: null,
    yearsOfExperience: null,
    state: null,
    city: null,
    address: null,
    nameOfOrganization: null,
    typeOfId: null,
    idUrl: null,
    howYouHearAboutUs: null,
    idNumber: null,
    partnerConsentLetterUrl: null,
    kinPhone: null,
    // kinPhone: "",
    kinEmail: null,
    // kinEmail: "",
    // mentorPhone: null,
    // mentorEmail: null,
    // mentorEmail: "",
    aboutIdea: null,
    yearsOfStartupExperience: null,
    sdgs: null,
    businessName: null,
    businessAddress: null,
    businessIndustry: null,
    businessState: null,
    businessCity: null,
    businessCountryId: null,
    industrySubSector: null,
    languageId: null,
    countryId: null,
    monthlyIncome: null,
    teamSize: null,
    hasBusinessPartnerConsent: null,
    businessAge: null,
    businessAddressGreenEconomyOrClimateChangeDescription: null,
    businessWebsite: null,
    businessStage: null,
    businessRegistrationNumber: null,
    businessLocalGovernmentArea: null,
    motivation: null,
  },

  steps: {
    completedSteps: [],
    previousStep: "",
    currentCompletedStep: "",
    nextStep: "",
  },
};

// export const personalInfo = {
//   applicationId: "",
//   firstName: "",
//   lastName: "",
//   middleName: "",
//   gender: "",
//   dateOfBirth: "",
//   phoneNumber: "",
//   email: "",
//   levelOfEducation: "",
//   yearsOfExperience: "",
//   state: "",
//   city: "",
//   address: "",
//   appliedForOtherFoundationGrant: false,
//   nameOfOrganization: "",
//   typeOfId: "",
//   idUrl: "",
//   staticIdUrl: "",
//   howYouHearAboutUs: "",
//   kinFirstName: "",
//   kinLastName: "",
//   kinMiddleName: "",
//   kinPhone: "",
//   kinEmail: "",
//   mentorFirstName: "",
//   mentorLastName: "",
//   mentorMiddleName: "",
//   mentorPhone: "",
//   mentorEmail: "",
//   mentorGender: "",
//   aboutIdea: "",
//   yearsOfStartupExperience: "",
//   isIdeaInFieldOfExperience: false,
//   sdgs: [],
//   businessName: "",
//   businessAddress: "",
//   businessIndustry: "",
//   businessState: "",
//   businessCity: "",
//   businessCountryId: "",
//   industrySubSector: "",
//   businessAge: 0,
//   isWorkingInTeam: false,
//   teamSize: 0,
//   hoursPerWeekInvested: 0,
//   isIdeaProfitable: false,
//   monthlyIncome: 0,
//   haveBusinessPartners: false,
//   hasBusinessPartnerConsent: null,
//   // hasBusinessPartnerConsent: false,
//   status: "Ongoing",
//   languageId: "",
//   countryId: "",
//   isBusinessRural: false,
//   isResidentialRural: false,
//   skipTour: false,
//   hasStableInternet: false,
//   motivation: "",

//   validationErrors: {
//     middleName: null,
//     gender: null,
//     dateOfBirth: null,
//     phoneNumber: null,
//     email: null,
//     levelOfEducation: null,
//     yearsOfExperience: null,
//     state: null,
//     city: null,
//     address: null,
//     nameOfOrganization: null,
//     typeOfId: null,
//     idUrl: null,
//     howYouHearAboutUs: null,
//     kinPhone: "",
//     kinEmail: "",
//     mentorPhone: "",
//     mentorEmail: "",
//     aboutIdea: null,
//     yearsOfStartupExperience: null,
//     sdgs: null,
//     businessName: null,
//     businessAddress: null,
//     businessIndustry: null,
//     businessState: null,
//     businessCity: null,
//     businessCountryId: null,
//     industrySubSector: null,
//     languageId: null,
//     countryId: null,
//     monthlyIncome: null,
//     teamSize: null,
//     hasBusinessPartnerConsent: null,
//     motivation: null,
//   },

//   steps: {
//     completedSteps: [],
//     previousStep: "",
//     currentCompletedStep: "",
//     nextStep: "",
//   },
// };
