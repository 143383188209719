import React, { useContext } from "react";
import { Context } from "src/store";
import CalendarLogo from "src/modules/mentorship/Calendar/utils/calendarLogo";
import classes from "./InvitedEvent.module.css";
import { deleteInvitation } from "src/requests/MentorRequests";
import Empty from "src/modules/mentorship/Calendar/utils/Empty";
import Swal from "sweetalert2";
import moment from "moment";

const SentEvents = ({ sentInvitations, reload }) => {
  const { dispatch } = useContext(Context);

  // const _updateInvitation = (id) => {
  //   updateAppointment();
  //   // updateInvitation(dispatch, id);
  // };

  const _deleteInvitation = (id) => {
    Swal.fire({
      title: "Are you sure, you want to delete this appointment",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#da291c",
      cancelButtonColor: "#eddcd2",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteInvitation(dispatch, id).then((res) => {
          if (res.status === 200) {
            Swal.fire(
              "Deleted!",
              "Your Appointment request has been deleted.",
              "success"
            );
            reload();
          }
        });
      }
    });
  };
  const formatDate = (d) => moment(d).format("MMM DD");
  return (
    <>
      {sentInvitations && sentInvitations.length <= 0 ? (
        <Empty message="No Invitation to show" />
      ) : (
        sentInvitations.map((event) => (
          <div className={classes.InvitedEvent} key={event.id}>
            <div className={classes.Left}>
              <div className={classes.EventLogo}>
                <CalendarLogo />
              </div>
              <div className={classes.EventDetails}>
                <div className={classes.EventTitle}>{event.title}</div>
                <div className={classes.EventDate}>{event.datetime}</div>
                <div className={classes.Requestedby}>
                  Sent to:{" "}
                  <span className={classes.Requestee}>
                    {event.invitees && event.invitees[0].name}
                  </span>{" "}
                </div>
                <div className={classes.Requestedby}>
                  <span>From: {formatDate(event && event.startDate)}</span>{" "}
                  &nbsp; - &nbsp;
                  <span>To: {formatDate(event && event.endDate)}</span>
                </div>
              </div>
            </div>

            <div className={classes.Right}>
              {/* <div className={classes.AcceptButton} onClick={() => _updateInvitation(event.id)}>
								Update
							</div> */}
              <div
                className={classes.AcceptButton}
                onClick={() => _deleteInvitation(event.id)}
              >
                Delete
              </div>
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default SentEvents;
