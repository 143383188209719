/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import Loader from "src/components/Loader/Loader";
import {
  getConnectionsGroups,
  isGroupMember,
} from "src/requests/StudyGroupsRequest";
import { Context } from "src/store";
import { useCurrentUser } from "src/utils/Services/currentUser";
import FriendsGroupCard from "src/modules/training/StudyGroups/components/FriendsGroup/FriendsGroupCard/FriendsGroupCard";
import ResultPagination from "src/modules/training/StudyGroups/components/ResultPagination/ResultPagination";

import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import axios from "axios";

const ConnectionsGroups = () => {
  const user = get_user_info_from_token() && get_user_info_from_token().user;
  const trainingEventId = user && user.training ? user.training[1] : null;

  axios.defaults.headers.common["trainingevent-id"] = trainingEventId;

  const { userId } = useCurrentUser();
  const { dispatch } = useContext(Context);
  const [groupMembers, setGroupMembers] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [pager, setPager] = useState({
    firstItemOnPage: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    lastItemOnPage: 1,
    pageCount: 1,
    pageNumber: 1,
    pageSize: 1,
    totalItemCount: 1,
  });
  const [pageDetails, setPageDetails] = useState({
    pageSize: 16,
    pageNumber: pager.pageNumber,
  });

  const onPageSelect = (e) => {
    setIsPageLoading(true);
    setPageDetails({
      ...pageDetails,
      pageNumber: e.selected + 1,
    });
  };

  useEffect(() => {
    setIsPageLoading(true);
    getConnectionsGroups(dispatch, userId, pageDetails).then((res) => {
      if (res !== undefined && res.status === 200) {
        const {
          data: { data, pager },
        } = res.data;
        setPager(pager);
        setUserGroups(data);
        isGroupMember(dispatch, userId).then((res) => {
          if (res.status === 200) {
            const {
              data: { data },
            } = res.data;
            setGroupMembers(data);
            setIsPageLoading(false);
          }
        });
      }
    });
  }, [pageDetails]);

  const cards = userGroups.map((group, index) => {
    const { id, name, membersCount, privacy, imageUrl } = group;
    const belongs = Boolean(
      Number(
        groupMembers.filter((groupMember) => groupMember.id === group.id).length
      )
    );
    return (
      <FriendsGroupCard
        key={index}
        groupId={id}
        title={name}
        members={membersCount}
        location={privacy}
        groupImage={imageUrl}
        belongs={belongs}
      />
    );
  });

  return (
    <div className="results-main">
      <div className="results-main__heading">
        <Link
          to="/training/studygroups"
          className="results-main__heading--back"
        >
          <FaAngleLeft /> &nbsp; Back
        </Link>
        <h1 className="results-main__heading--title">Connections Groups</h1>
      </div>
      {isPageLoading ? (
        <div className="results-main__loader">
          <Loader color="danger" />
        </div>
      ) : (
        <div className="results-main__results">
          {userGroups.length > 0 ? (
            cards
          ) : (
            <p className="results-main__results--no-group">
              You don't belong to any group at the moment
            </p>
          )}
        </div>
      )}
      {userGroups.length > 0 ? (
        <div className="results-main__pagination results-main__pagination--bottom">
          <ResultPagination
            pageCount={pager.pageCount}
            pageRange={1}
            onSelect={onPageSelect}
            current={pager.pageNumber}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ConnectionsGroups;
