import React, { useEffect, useState, useContext } from "react";
import { Col, Row } from "reactstrap";
import { Context } from "src/store";
import { FcApproval } from "react-icons/fc";

const DueDilligenceRequiredDocuments = ({ document }) => {
  const [dueDilligenceDocs, setDocuments] = useState([]);

  const { state } = useContext(Context);
  useEffect(() => {
    const dueDilligenceDocuments =
      state.dms &&
      state.dms.dueDilligence &&
      state.dms.dueDilligence.dueDilligenceDocuments;

    if (dueDilligenceDocuments) {
      setDocuments(dueDilligenceDocuments);
    }
  }, [state.dms.dueDilligence]);

  const checkIfSubmitted = (id) => {
    return dueDilligenceDocs.some((doc) => doc.stageRequiredDocumentId === id);
  };

  return (
    <div className="">
      <Row>
        <Col sm="1" xs={1}>
          <strong>{document.order}</strong>
        </Col>
        <Col sm="8" xs={8}>
          <p style={{ lineHeight: "1.5em" }}>
            <strong>{document.title}</strong> <br />
            {document.isRequired ? (
              <em style={{ color: "#A4A5A6" }}>Required</em>
            ) : (
              <em style={{ color: "#A4A5A6" }}>Optional</em>
            )}
          </p>
        </Col>
        <Col sm="2" xs={2}>
          {checkIfSubmitted(document.id) && <FcApproval />}
        </Col>
      </Row>
    </div>
  );
};

export default DueDilligenceRequiredDocuments;
