import React, { useState, useEffect, useContext } from "react";
import { Context } from "src/store";
import "./UserLikes.scss";
import { getLikeUsers } from "src/requests/StudyGroupPostRequest";
import Loader from "src/components/Loader/Loader";

const UserLikes = ({ postId }) => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { dispatch } = useContext(Context);

  useEffect(() => {
    getLikeUsers(dispatch, postId).then((res) => {
      if (res.status === 200) {
        setIsLoading(false);
        const { data } = res.data;
        setUsers(data);
      }
    });
  }, []);

  const usersList = users.map((userList) => {
    const {
      user: { userId, firstName, lastName },
    } = userList;
    return (
      <li className="user-likes__list--item" key={userId}>
        {firstName + " " + lastName}
      </li>
    );
  });

  return (
    <div className="user-likes">
      <ul className="user-likes__list">
        {isLoading ? <Loader color="danger" /> : usersList}
      </ul>
    </div>
  );
};

export default UserLikes;
