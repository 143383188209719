import React, { useState } from "react";
import { useChangeLanguage } from "src/utils/translation";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import classes from "./LanguageDropdown.module.scss";
import { getLanguage } from "src/utils/helper";

export const LanguageDropdown = () => {
  const { changeLanguage } = useChangeLanguage();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Language names taken from https://www.html-code-generator.com/html/drop-down/languages-name
  const languages = [
    { value: "ar", label: "العربية" },
    { value: "en", label: "English" },
    { value: "fr", label: "français" },
    { value: "pt", label: "português" },
    { value: "rw", label: "Kinyarwanda" },
    { value: "sw", label: "Kiswahili" },
  ];

  const findLanguage = languages.filter(({ value }) => value === getLanguage());
  const currentLanguage =
    findLanguage.length === 1 ? findLanguage[0].label : "English";

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      translate="no"
      className={classes.LanguageDropdown}
    >
      <DropdownToggle caret className={classes.LanguageToggle} color="white">
        {currentLanguage}
      </DropdownToggle>
      <DropdownMenu>
        {languages
          .filter(({ label }) => label !== currentLanguage)
          .map(({ value, label }) => (
            <DropdownItem
              key={value}
              onClick={() => changeLanguage(value)}
              className={classes.LanguageItem}
            >
              {label}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Dropdown>
  );
};
