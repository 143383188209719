import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import Select from "react-select";

import { searchMember } from "src/requests/AuthRequest";
import { groupInvite } from "src/requests/CommunityRequest";
import { Context } from "src/store";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Loader from "react-loader-spinner";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const InviteFriendsModal = ({ isOpen, toggle }) => {
  const [isClearable] = useState(true);
  const [isDisabled] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [isRtl] = useState(false);
  const [isSearchable] = useState(true);
  const [inputValue, setInputvalue] = useState("");
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const { state, dispatch } = useContext(Context);
  const qa = useLocation();
  const { groupId } = queryString.parse(qa.search);
  const { user } = get_user_info_from_token();

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    setInputvalue(inputValue);
    return inputValue;
  };

  const searchUser = async () => {
    setisLoading(true);
    const newMembersArray = [];
    if (inputValue === "") {
      setMembers([]);
      setisLoading(false);
      return newMembersArray;
    }
    const members = await searchMember(dispatch, inputValue);
    if (members !== null) {
      for (let index = 0; index < members.length; index++) {
        newMembersArray.push({
          value: members[index],
          label: members[index].firstName + " " + members[index].lastName,
        });
      }
      setMembers(newMembersArray);
    }
    setisLoading(false);
    return newMembersArray;
  };

  useEffect(() => {
    searchUser();
  }, [inputValue]);

  const onInviteClick = async () => {
    const payload = {
      groupId: groupId,
      inviteedByUserId: user.id,
      inviteeUserId: selectedMember.id,
      inviteeEmail: selectedMember.email,
    };
    const res = await groupInvite(dispatch, payload);
    if (res.data.status === "success") {
      toggle();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
      <ModalHeader toggle={toggle} closeButton>
        <div className="text-center">Invite Friends to Group</div>
      </ModalHeader>
      <ModalBody className="mb-5">
        <p>
          <label>Enter Name</label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            name="selectedMember"
            options={members}
            onInputChange={handleInputChange}
            placeholder="Enter friend's name"
            onChange={(selected) =>
              selected.value !== null && setSelectedMember(selected.value)
            }
          />
        </p>
      </ModalBody>
      <ModalFooter className="mt-5">
        <Button color="link" onClick={toggle}>
          Close
        </Button>
        <Button
          className="d-flex"
          disabled={selectedMember === ""}
          onClick={onInviteClick}
        >
          {" "}
          {state.Communities.inviteLoading && (
            <span className="mr-3">
              {" "}
              <Loader height={15} width={15} color="white" type="Oval" />
            </span>
          )}{" "}
          Invite Member{" "}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(InviteFriendsModal);
