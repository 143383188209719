import React from "react";
import classes from "./ChatTabs.module.scss";
import { ListGroup } from "reactstrap";
import ChatBlock from "src/modules/Alumni/Chat/Components/ChatList/ChatBlock/ChatBlock";

const PersonalChatTab = ({
  allChatBookmarks = [],
  currentChat = {},
  switchDefaultActiveChat,
  personalChat,
}) => {
  return (
    <div className={classes["chat-tab__wrapper"]}>
      <ListGroup className="bg-transparent border-0">
        {personalChat.length > 0
          ? personalChat.map((chat) => {
              return (
                <ChatBlock
                  switchDefaultActiveChat={switchDefaultActiveChat}
                  currentChat={currentChat}
                  chatDetails={chat}
                  key={JSON.stringify(chat)}
                />
              );
            })
          : null}
        {/* <ChatBlock isGroup={false} />
        <ChatBlock isGroup={true} />
        <ChatBlock isGroup={false} />
        <ChatBlock isGroup={false} /> */}
      </ListGroup>
    </div>
  );
};

export default PersonalChatTab;
