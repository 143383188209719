import React, { createContext, useReducer } from "react";
import MentorReducer from "./reducers/MentorReducer";
import GoalReducer from "./reducers/GoalReducer";
import FaqReducer from "./reducers/FaqReducer";
import UserReducer from "./reducers/AuthReducer";
import UserSingleReducer from "./reducers/UserReducer";
import ProfileReducer from "./reducers/ProfileReducer";
import OnboadingReducer from "./reducers/onboardingReducer";
import PostReducer from "./reducers/PostReducer";
import combineReducers from "react-combine-reducers";
import CommentReducer from "./reducers/CommentReducer";
import DicussionReducer from "./reducers/discussionsReducer";
import ApplicationReducer from "./reducers/ApplicationReducer";
import ProgrammeReducer from "./reducers/ProgrammeReducer";
import UserGroupReducer from "./reducers/UserGroupReducer";
import PersonalGoalReducer from "./reducers/PersonalGoalReducer";
import FeedbackReducer from "./reducers/FeedbackReducer";
import FileUploadReducer from "./reducers/fileReducer";
import InviteReducer from "./reducers/InviteReducer";
import OpportunityReducer from "./reducers/OpportunityReducer";
import CommunityReducer from "./reducers/CommunityReducer";
import InterestReducer from "./reducers/InterestReducer";
import TrainingReducer from "./reducers/TrainingReducer";
import TopicReducer from "./reducers/TopicReducer";
import ToolsReducer from "./reducers/ToolsReducer";
import MessageReducer from "./reducers/MessageReducer";
import ProgrammeEventReducer from "./reducers/ProgrammeEventReducer";
import ConnectionReducer from "./reducers/ConnectionReducer";
import DiscoverReducer from "./reducers/DiscoverReducer";
import DiscoverStoryReducer from "./reducers/DiscoverStoryReducer";
import TefNewsReducer from "./reducers/TefNewsReducer";
import AlumniHubReducer from "./reducers/AlumniHubReducer";
import DmsReducer from "./reducers/DmsReducer";

export const initialState = {
  fileUpload: {
    uploadPrgoress: 0,
    requestingFileUpload: false,
    files: [],
  },
  mentors: {},
  application: {
    userApplications: [],
  },
  user: {},
  goals: {},
  FAQs: {
    loading: false,
    error: "",
    FAQs: [],
  },
  users: {
    user: {},
    isAuthenticated: false,
    loading: false,
    token: "",
    error: false,
  },
  onboarding: {
    organizations: [],
    skills: [],
    areas: [],
    interests: [],
    onboardingData: {},
  },
  posts: {
    posts: [],
    pageDetails: {},
    post: {},
    vote: {},
    likeUsers: [],
    error: false,
    loading: false,
    bookmark: {},
    toggleBookmark: false,
    bookmarks: [],
  },
  comments: {
    comments: [],
    comment: {},
    pageDetails: {},
    error: false,
    loading: false,
  },
  // eslint-disable-next-line no-dupe-keys
  profile: {
    profile: {},
    onboardingCompleted: false,
    error: false,
    loading: false,
  },
  discussions: {
    discussions: [],
    discussionDetail: {},
    replies: [],
    replyDeatails: {},
    loading: false,
    error: null,
  },
  programmes: {
    programmes: [],
    programme: {},
    error: false,
    loading: false,
  },
  userGroups: {
    groups: [],
    group: {},
    error: false,
    loading: false,
  },
  personalGoal: {
    goals: [],
    error: false,
    loading: false,
  },
  feedback: {
    feedback: {},
    dataAccess: {},
    error: false,
    loading: false,
  },
  invites: {
    invite: {},
    error: false,
    loading: false,
  },
  opportunities: {
    opportunities: [],
    opportunity: {},
    categories: [],
    myOpportunities: [],
    types: [],
    topOpportunities: [],
    isFlagged: false,
    error: false,
    loading: false,
    comments: [],
    likeOpportunityComment: false,
    responses: [],
    // eslint-disable-next-line no-dupe-keys
    likeOpportunityComment: false,
    allLikers: [],
  },
  communities: {
    categories: [],
    categoryGroups: [],
    groups: [],
    groupDetails: {},
    groupPosts: [],
    group: {},
    isGroupMember: [],
    joinGroup: false,
    leaveGroup: false,
    groupUsers: [],
    userGroups: [],
    groupSearchResults: [],
    connectionGroups: [],
    groupUsersSuccess: false,
    groupUsersLoading: true,
    groupUsersError: false,
    inviteLoading: false,
    error: false,
    loading: false,
  },
  interests: {
    interests: [],
    interest: {},
  },
  training: {
    menteeTrainingScore: null,
    courseModule: [],
    userCourseModule: null,
    userCourses: [],
    coursesQAndA: null,
    lessonContent: {},
    loading: false,
    error: false,
    coursemates: null,
    receivedRequest: null,
    sentRequest: null,
    surveyQuestions: null,
    trainingCourseAssessment: null,
  },
  topics: {
    topics: [],
    topic: {},
    error: false,
    loading: false,
    topicDetails: {},
    topicPosts: [],
  },
  tools: {
    categories: [],
    tools: [],
    tool: {},
    comments: [],
    likeToolComment: false,
    allLikers: [],
  },
  messages: {
    message: {},
    messages: [],
    error: false,
    loading: false,
  },
  programmeEvents: {
    programmeEvents: [],
    programmeEvent: {},
    programmeEventTypes: [],
  },
  connections: {
    connections: [],
    suggestions: [],
    connection: {},
    pageDetails: {},
    error: false,
    loading: false,
  },
  discovers: {
    categories: [],
    tags: [],
    error: false,
    loading: false,
  },
  masterClass: {
    categories: [],
    tags: [],
    masterClasses: [],
    error: false,
    loading: false,
  },
  discoverStory: {
    countries: [],
    industries: [],
    error: false,
    loading: false,
  },
  tefNews: {
    news: [],
    error: false,
    loading: false,
  },
  alumniHub: {
    hubs: [],
    error: false,
    loading: false,
  },
  dms: {
    dueDilligence: {},
    seedCapital: {},
    bankDetails: {},
    businessPlan: {},
    dueDilligenceRequiredDocuments: [],
    seedCapitalRequiredDocuments: [],
    businessPlansRequiredDocuments: [],
  },
};

export const Context = createContext(initialState);

export const Provider = ({ children }) => {
  const [rootReducerCombined, initialStateCombined] = combineReducers({
    Mentors: [MentorReducer, initialState.mentors],
    Goals: [GoalReducer, initialState.goals],
    FAQs: [FaqReducer, initialState.FAQs],
    Users: [UserReducer, initialState.users],
    User: [UserSingleReducer, initialState.user],
    Onboarding: [OnboadingReducer, initialState.onboarding],
    Posts: [PostReducer, initialState.posts],
    Profile: [ProfileReducer, initialState.profile],
    Comments: [CommentReducer, initialState.comments],
    Discussions: [DicussionReducer, initialState.discussions],
    Application: [ApplicationReducer, initialState.application],
    Programmes: [ProgrammeReducer, initialState.programmes],
    UserGroups: [UserGroupReducer, initialState.userGroups],
    PersonalGoal: [PersonalGoalReducer, initialState.personalGoal],
    Feedback: [FeedbackReducer, initialState.feedback],
    FileUpload: [FileUploadReducer, initialState.fileUpload],
    Invites: [InviteReducer, initialState.invites],
    Opportunities: [OpportunityReducer, initialState.opportunities],
    Communities: [CommunityReducer, initialState.communities],
    Interests: [InterestReducer, initialState.interests],
    Training: [TrainingReducer, initialState.training],
    Topics: [TopicReducer, initialState.topics],
    Tools: [ToolsReducer, initialState.tools],
    Messages: [MessageReducer, initialState.messages],
    ProgrammeEvents: [ProgrammeEventReducer, initialState.programmeEvents],
    Connections: [ConnectionReducer, initialState.connections],
    Discovers: [DiscoverReducer, initialState.discovers],
    DiscoverStory: [DiscoverStoryReducer, initialState.discoverStory],
    TefNews: [TefNewsReducer, initialState.tefNews],
    AlumniHub: [AlumniHubReducer, initialState.alumniHub],
    dms: [DmsReducer, initialState.dms],
  });

  const [state, dispatch] = useReducer(
    rootReducerCombined,
    initialStateCombined
  );

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};
