import React from "react";

import FormInput from "src/modules/Tef-Dms/components/Form/FormInput";

const MarketingPlan = ({ marketingPlan, setMarketingPlan }) => {
  const handleChange = (e) => {
    setMarketingPlan({
      ...marketingPlan,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <React.Fragment>
      <h6 className="font-weight-bold mb-3">Marketing Plan</h6>
      <div className="pr-2">
        <FormInput
          label="Marketing and Promotion Channels :"
          getFormInputValue={(e) => handleChange(e)}
          value={marketingPlan.marketingChannels}
          name="marketingChannels"
          placeholder=""
        />

        <FormInput
          label="Pricing Strategy:"
          getFormInputValue={(e) => handleChange(e)}
          value={marketingPlan.pricingStrategy}
          name="pricingStrategy"
          placeholder=""
        />

        <FormInput
          label="Distribution Strategy:"
          getFormInputValue={(e) => handleChange(e)}
          value={marketingPlan.distributionStrategy}
          name="distributionStrategy"
          placeholder=""
        />
      </div>
    </React.Fragment>
  );
};

export default MarketingPlan;
