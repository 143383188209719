import React from "react";
import { MdHome } from "react-icons/md";
import SideNavItem from "src/components/Layout/SideBar/SideNavItem/SideNavItem";
import {
  FaShoppingCart,
  FaTools,
  FaAsterisk,
  FaFileInvoice,
  FaSearch,
  FaBullhorn,
} from "react-icons/fa";
import { RiUserSettingsLine } from "react-icons/ri";
import { ReactComponent as Connections } from "src/assets/img/sidebar/Connections.svg";
import { ReactComponent as Community } from "src/assets/img/sidebar/Community.svg";
import styles from "./Navigations.module.scss";
import { useTranslation } from "react-i18next";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import isAlumniUser from "src/utils/isAlumni";
import isDMsUser from "src/utils/isDms";
import { Link } from "react-router-dom";

const Navigations = () => {
  const path = window.location.pathname;
  const { t } = useTranslation();
  const TOKEN = JSON.parse(localStorage.getItem("data")).token;
  const user = get_user_info_from_token().user;

  return (
    <>
      <ul className={styles.Navigation}>
        <SideNavItem
          Icon={MdHome}
          navName={t("Home")}
          linkTo="/home"
          isActive={path.includes("/home") || path === "/"}
        />
        <SideNavItem
          Icon={Connections}
          navName={t("Connections")}
          linkTo="/connections"
          isActive={path === "/connections"}
        />

        <SideNavItem
          Icon={FaTools}
          navName={t("Tools")}
          linkTo="/tools"
          isActive={path.includes("/tools")}
        />
        {/* <SideNavItem */}
        {/* // Icon={FaCalendar} */}
        {/* // navName={t("Events")} */}
        {/* // linkTo="/events" */}
        {/* // isActive={path.includes("/event")} */}
        {/* // /> */}
        <SideNavItem
          Icon={FaSearch}
          navName={t("Discover")}
          linkTo="/discover"
          isActive={path.includes("/discover")}
        />
        <SideNavItem
          Icon={FaAsterisk}
          navName={t("Opportunities")}
          linkTo="/opportunities"
          isActive={path.includes("/opportunities")}
        />
        <SideNavItem
          Icon={Community}
          navName={t("Community")}
          linkTo="/community"
          isActive={path.includes("/community")}
        />
        {isAlumniUser() && (
          <SideNavItem
            Icon={RiUserSettingsLine}
            navName={t("TEF Alumni")}
            linkTo={null}
            dropDown={true}
            isActive={path.includes("/Alumni")}
            redirectInApp={true}
            externalLink={true}
            dropDownList={[
              {
                name: "Alumni",
                link: `/alumni`,
              },
              {
                name: "Opportunities",
                link: `/alumni-opportunity`,
              },
              {
                name: "Events",
                link: `/alumni-events`,
              },
              {
                name: "Alumni Hubs",
                link: `/alumni-hub/`,
              },
              {
                name: "Group Chat",
                link: `/alumni/chat`,
              },
              {
                name: "Alumni Resources",
                link: `/alumni/resources`,
              },

              {
                name: "Business Reporting",
                link: `/alumni/business-reporting/`,
              },
            ]}
          />
        )}

        <SideNavItem
          Icon={FaShoppingCart}
          navName={t("Marketplace")}
          // isCustomer is set to true when redirecting to the customer page and false when redirecting to the merchant page
          linkTo={null}
          isActive={path.includes("/marketplace")}
          externalLink={true}
          dropDown={true}
          dropDownList={
            Object.prototype.hasOwnProperty.call(user, "alumni")
              ? [
                  {
                    name: "Visit Marketplace",
                    link: `${process.env.REACT_APP_MARKET_PLACE_URI_BASE}`,
                  },
                  {
                    name: "Visit Marketplace Admin",
                    link: `${process.env.REACT_APP_MARKET_PLACE_URI_ALUMNUS}?isCustomer=false&token=${TOKEN}`,
                  },
                ]
              : [
                  {
                    name: "Visit Marketplace",
                    link: `${process.env.REACT_APP_MARKET_PLACE_URI_BASE}`,
                  },
                ]
          }
        />
        <SideNavItem
          Icon={FaBullhorn}
          navName="Pitching"
          linkTo={null}
          isActive={false}
          externalLink={true}
          dropDown={true}
          dropDownList={[
            {
              name: "Visit Pitching",
              link: "http://pitch2022.tefconnect.com/login.phpx",
            },
          ]}
        />
        {isDMsUser() && (
          <SideNavItem
            Icon={FaFileInvoice}
            navName="TEF DMS"
            linkTo="/tef-dms"
            isActive={path.includes("/tef-dms")}
          />
        )}
        {/* <SideNavItem */}
        {/* // Icon={Topics} */}
        {/* // navName={t("Topics")} */}
        {/* // linkTo="/topics" */}
        {/* // isActive={path.includes("/topics")} */}
        {/* // /> */}
        {/* <SideNavItem */}
        {/* // Icon={FaStar} */}
        {/* // navName={t("Alumni / TEF All Stars")} */}
        {/* // linkTo="/all-stars" */}
        {/* // isActive={path.includes("/all-stars")} */}
        {/* // /> */}
      </ul>
      {/* <h3 className={styles.Navigation__navTitle}>Topics you like</h3> */}
      {/* <ul className={styles.Navigation}> */}
      {/* <SideNavItem navName="BusinessNG" linkTo="#" isActive={false} /> */}
      {/* <SideNavItem navName="Cocoa Export" linkTo="#" isActive={false} /> */}
      {/* <SideNavItem navName="Project Managers" linkTo="#" isActive={false} /> */}
      {/* </ul> */}

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className={styles.bContainer}>
        <ul className={styles.nav}>
          <li className={styles.navItem}>
            <a
              href="https://www.tonyelumelufoundation.org/"
              target="_blank"
              rel="noreferrer"
              className={styles.mainLinkText}
            >
              <span style={{ color: "red" }}> &bull; </span> About TEF
            </a>
          </li>
          <li className={styles.navItem}>
            <Link to="/terms-of-use" className={styles.mainLinkText}>
              <span style={{ color: "red" }}> &bull; </span>Terms Of Use
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link to="/privacy" className={styles.mainLinkText}>
              <span style={{ color: "red" }}> &bull; </span>Privacy Policy
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link to="/feedback" className={styles.mainLinkText}>
              <span style={{ color: "red" }}> &bull; </span>Send Feedback
            </Link>
          </li>
        </ul>
        <span className={styles.navItem}>
          <Link to="#" className={styles.mainLinkText}>
            TEFConnect. All rights reserved. &copy; {new Date().getFullYear()}
          </Link>
        </span>
      </div>
    </>
  );
};

export default Navigations;
