/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import pdfFIle from "src/assets/files/TEFEP Certificate (No Name).pdf";
import { PDFDocument, rgb } from "pdf-lib";
import fontt from "src/fonts/Blancha.ttf";
// import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
// import * as fs from "fs";
// import path from "path";

import {
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  Form,
  Button,
  Spinner,
} from "reactstrap";
import certificateImg from "src/assets/img/group-6-copy.png";
import { BiDownload } from "react-icons/bi";
import classes from "./Certificate.module.css";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { UploadFile } from "src/requests/fileUploadRequest";
import { Context } from "src/store";
import Swal from "sweetalert2";
import {
  get_user_info_from_token,
  refreshToken,
} from "src/utils/someTokenMenthods";
import { getSettings, uploadSeedCapitalInvoice } from "src/requests/DmsRequest";
import { toast } from "react-toastify";
import Documents from "src/modules/Tef-Dms/components/Documents";
import { getAllProgrammes } from "src/requests/ProgrammeRequest";
import { addUserToGroup, getUserGroups } from "src/requests/UserGroupRequest";

async function downloadDoc(username) {
  try {
    const existingPdfBytes = await fetch(pdfFIle).then((res) =>
      res.arrayBuffer()
    );

    const font = await fetch(fontt);
    const fontBytes = await font.arrayBuffer();

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    pdfDoc.registerFontkit(fontkit);
    const Blancha = await pdfDoc.embedFont(fontBytes);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    firstPage.drawText(`${username}`, {
      x: 90,
      y: 350,
      size: 55.6,
      font: Blancha,
      color: rgb(0.95, 0.1, 0.1),
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    const fileName = `TEFEP Certificate-(${username})`;
    link.download = fileName;
    link.click();
  } catch (e) {
    console.log(e);
  }
}

const Certificate = () => {
  const { firstName, lastName } = useCurrentUser();
  const [isUploadactive, setUploadActive] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [file, setFile] = useState(null);
  const user = get_user_info_from_token().user;
  const [showCert, setShowCert] = useState(false);
  const [showUpload, setShowUplaod] = useState(true);
  const [setting, setSetting] = useState(null);
  const { state, dispatch } = useContext(Context);
  const submissionType = "CertificateDownload";
  const [showWaitingScreen, setShowWaitingScreen] = useState(true);
  const [programme, setProgramme] = useState(null);
  const [groups, setGroups] = useState([]);

  const getProgrammes = () => {
    getAllProgrammes(dispatch);
  };

  const getGroups = () => {
    getUserGroups(dispatch).then((res) => {
      const { data } = res.data;
      if (data) {
        setGroups(data);
      }
    });
  };

  const currentProgramme = (programmeId, programmes) => {
    return programmes.find((p) => {
      return p.id === programmeId;
    });
  };

  const filterEvent = (events, eventName) => {
    return programme.programmeEvents.find((event) => {
      return event.eventType.name
        .toLowerCase()
        .includes(eventName.toLowerCase());
    });
  };

  useEffect(() => {
    if (
      state.Programmes &&
      state.Programmes.programmes &&
      state.Programmes.programmes.length > 0
    ) {
      const programme = currentProgramme(
        user.dms_user[0],
        state.Programmes.programmes
      );

      setProgramme(programme);
    }
  }, [state.Programmes.programmes]);

  const onCheckAcknowledgement = (e) => {
    setUploadActive(!isUploadactive);
  };

  const onUploadDoc = (e) => {
    e.preventDefault();
    setLoadingSubmit(true);
    UploadFile(dispatch, false, file)
      .then((res) => {
        const { files } = res;
        uploadSeedCapitalInvoice({
          programId: user.dms_user[0],
          fileUrl: files[0],
        })
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              toast("Invoice has been uploaded successfully");
              setShowCert(true);
              setShowUplaod(false);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            add_to_mentorship_group();
          });
      })
      .catch((err) => {
        console.log(err);
        setLoadingSubmit(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong uploading your file",
        });
      });
  };

  const onFileChange = (e) => {
    e.preventDefault();
    setFile(e.target.files);
  };

  const _getSetting = async () => {
    try {
      const { data } = await getSettings({
        submissionType,
        programId: user.dms_user[0],
      });

      const setting = data.data;

      if (setting.length > 0) {
        setSetting(setting[0]);
      } else {
        setSetting(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      state.dms &&
      state.dms.bankDetails &&
      state.dms.bankDetails.seedCapitalAckInvoice
    ) {
      setShowCert(true);
      setShowUplaod(false);
    }
  }, [state.dms, setting]);

  useEffect(() => {
    if (setting) {
      if (new Date(setting.endDate) <= new Date()) {
        setShowWaitingScreen(false);
      }
    }
  }, [setting]);

  useEffect(() => {
    _getSetting();
    getProgrammes();
    getGroups();
  }, []);

  const onDownload = async () => {
    await downloadDoc(`${firstName} ${lastName}`);
  };

  const add_to_mentorship_group = () => {
    const event = filterEvent(programme.programmeEvents, "mentorship");
    const mentee_group = groups.find(
      (group) =>
        group.name.toLowerCase() ===
        process.env.REACT_APP_TEF_MENTEE_KEYWORD.toLowerCase()
    );

    if (event && mentee_group) {
      const payload = {
        programmeEventId: event.id,
        programmeId: programme.id,
        userId: user.id,
        groupId: mentee_group.id,
      };
      addUserToGroup(payload).then(() => {
        refreshToken().then(() => {
          window.location.reload();
        });
      });
    }
  };

  return (
    <>
      {showWaitingScreen ? (
        <div>
          This phase has not commenced.
          <br /> The TEF Entrepreneurship Programmes team will notify you via
          email once the certificates are ready for download.
        </div>
      ) : (
        <>
          {showUpload && (
            <Col>
              <Form onSubmit={onUploadDoc}>
                <FormGroup>
                  <Label check>
                    <Input
                      type="checkbox"
                      onChange={onCheckAcknowledgement}
                      checked={isUploadactive}
                      value={isUploadactive}
                    />
                    I acknowledge that I have received my seed capital
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label>Upload seed capital receipt</Label>
                  <Input
                    type="file"
                    disabled={isUploadactive === false}
                    onChange={onFileChange}
                  />
                </FormGroup>

                {state.dms &&
                  state.dms.bankDetails &&
                  state.dms.bankDetails.seedCapitalAckInvoice && (
                    <Documents
                      document={{
                        documentUrl:
                          state.dms.bankDetails.seedCapitalAckInvoice,
                        title: "Seed Capital receipt",
                        stageRequiredDocumentTitle: "Receipt",
                      }}
                    />
                  )}

                <Button disabled={!file || loadingSubmit}>
                  {loadingSubmit ? <Spinner /> : "Submit"}
                </Button>
              </Form>
            </Col>
          )}
          <br />

          {showCert && (
            <Row>
              <Col md={3}>
                <h6 style={{ fontWeight: "700" }}>Recent Certificate</h6>
                <p>
                  <small style={{ color: "#BDC3CC" }}>
                    You can access & download your "Certificate of Completion &
                    Achievement" here.
                  </small>
                </p>
              </Col>
              <Col md={9}>
                <div className={classes.CertificateCard}>
                  <div className={classes.CertificateImgContainer}>
                    <img src={certificateImg} alt="certificate" />
                  </div>

                  <div className={`mt-3 ${classes.CertificateDetails}`}>
                    <div>
                      <small className={classes.Topic}>CERTIFICATE</small>
                      <h6 style={{ fontWeight: "700" }}>
                        Certificate for the completion of training
                      </h6>
                    </div>
                    <Row className="mt-4">
                      <Col md={7}>
                        <div>
                          <small className={classes.Topic}>ISSUED</small>
                          <p>
                            <small style={{ fontWeight: "600" }}>
                              12th Sept. 2020 5:30pm
                            </small>
                          </p>
                        </div>
                      </Col>
                      <Col md={5}>
                        <div className="mt-3 pointer" onClick={onDownload}>
                          <BiDownload color="#DA291C" />
                          <span
                            style={{ marginLeft: "0.5em", color: "#DA291C" }}
                          >
                            Download
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default Certificate;
