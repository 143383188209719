import React from "react";
import "./CustomFormField.scss";
import shortid from "shortid";

export const enforceWordCountValue = (wordCount, value, maxLength) => {
  if (wordCount > maxLength) {
    // value = value.trimRight();
    // return value;
    return value.split(" ").slice(0, maxLength).join(" ").trimRight();
  }
  return value;
};

export const wordCounter = (value) => {
  if (value === "") {
    return 0;
  }
  return value.split(" ").length;
};

export const FormField = ({
  className,
  type,
  name,
  label,
  labelInfo,
  placeholder,
  children,
  onChange,
  value,
  showRmoveIcon,
  hasCounter,
  maxLength,
  onRemoveIconClick,
  required,
  disabled,
  min,
  max,
  isEmpty,
  onBlur,
  onFocus,
  error,
  ref,
  id,
}) => {
  const labelInfoColor = required && isEmpty ? "red" : "#919ba8";

  const showForm = () => {
    if (children) {
      return (
        <div className={`form-field ${className}`} ref={ref} id={id}>
          <label htmlFor={name} className="form-field__label">
            {label}
            {labelInfo && (
              <span style={{ color: labelInfoColor }}> - {labelInfo}</span>
            )}
          </label>
          {children}
        </div>
      );
    }
    return (
      <div className={`form-field ${className}`} ref={ref} id={id}>
        <label htmlFor={name} className="form-field__label">
          {label}
          {labelInfo && (
            <span style={{ color: labelInfoColor }}> - {labelInfo}</span>
          )}
        </label>
        {type === "textarea" ? (
          <div style={{ position: "relative" }}>
            <textarea
              placeholder={placeholder}
              onChange={(e) => onChange(e.target.name, e.target.value)}
              value={value}
              className="form-type"
              maxLength={maxLength} // comment if any need to restore custom word counter
              name={name}
              disabled={disabled}
              required={required}
              style={{
                borderColor: error ? "red" : "",
              }}
              onBlur={onBlur}
              onFocus={onFocus}
            ></textarea>
            {hasCounter && (
              <span
                style={{
                  // position: "absolute",
                  // bottom: "5px",
                  marginTop: "-10px",
                  zIndex: 1,
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  color: "#63666a",
                  float: "right",
                }}
              >
                {`${value.length} / ${maxLength}`}
                {/* {`${wordCounter(value)} / ${maxLength}`} */}
              </span>
            )}
            <div style={{ clear: "both" }}></div>
          </div>
        ) : (
          <>
            {!showRmoveIcon ? (
              // <div>
              <div style={{ position: "relative", height: "100%" }}>
                <input
                  type={`${type}`}
                  name={name}
                  placeholder={placeholder}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                  value={value}
                  className="form-type"
                  maxLength={maxLength} // comment if any need to restore custom word counter
                  disabled={disabled}
                  required={required}
                  min={min || undefined}
                  max={max || undefined}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  // ref={(input) => console.log(input)}
                  style={{
                    borderColor: error ? "red" : "",
                  }}
                />
                {hasCounter && (
                  <span
                    style={{
                      marginTop: "-10px",
                      zIndex: 1,
                      fontSize: "14px",
                      fontFamily: "Poppins",
                      color: "#63666a",
                      float: "right",
                    }}
                  >
                    {/* {`${wordCounter(value)} / ${maxLength}`} */}
                    {`${value.length} / ${maxLength}`}
                  </span>
                )}
                <div style={{ clear: "both" }}></div>
                {/* <span>error</span> */}
              </div>
            ) : (
              <div style={{ display: "flex", position: "relative" }}>
                <input
                  type={`${type}`}
                  name={name}
                  placeholder={placeholder}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                  value={value}
                  className="form-type"
                  maxLength={maxLength}
                  disabled={disabled}
                  required={required}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  style={{
                    borderColor: error ? "red" : "",
                  }}
                />
                <div
                  style={{
                    alignSelf: "center",
                    marginLeft: "10px",
                    color: "#919ba8",
                    cursor: "pointer",
                  }}
                  onClick={onRemoveIconClick}
                >
                  X
                </div>
                {hasCounter && (
                  <span
                    style={{
                      position: "absolute",
                      top: "15px",
                      // bottom: "5px",
                      right: "10px",
                      zIndex: 1,
                      fontSize: "14px",
                      fontFamily: "Poppins",
                      color: "#63666a",
                    }}
                  >
                    {`${value.length} / ${maxLength}`}
                  </span>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  };
  return <>{showForm()}</>;
};

FormField.defaultProps = {
  className: "",
  type: "text",
  placeholder: "",
  hasCounter: false,
  ref: null,
  // max
  showRmoveIcon: false,
  onRemoveIconClick: () => {},
  required: false,
  isEmpty: false,
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
  value: "",
  id: null,
};

export const Select = ({
  onChange,
  value,
  list,
  style,
  className,
  name,
  defaultVal,
  required,
  disabled,
  onBlur,
  onFocus,
  error,
  ref,
}) => {
  return (
    <div className={className} style={{ ...style }} ref={ref}>
      <select
        className={`select-css ${className}`}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        value={value}
        name={name}
        disabled={disabled}
        required={required}
        // defaultValue="Selected"
        // style={{ color: "#919ba8" }}
        onBlur={onBlur}
        onFocus={onFocus}
        style={{
          borderColor: error ? "red" : "",
        }}
      >
        {defaultVal ? (
          <option value="" defaultValue>
            {defaultVal}
          </option>
        ) : (
          <option value="" defaultValue>
            Select
          </option>
        )}
        {list &&
          list.length &&
          list.map((listItem) => {
            return (
              <option key={shortid.generate()} value={listItem.value}>
                {listItem.label}
              </option>
            );
          })}
      </select>
    </div>
  );
};
Select.defaultProps = {
  className: "",
  style: {},
  ref: null,
  onBlur: () => {},
  onFocus: () => {},
};

export const Radio = ({ label, value, name, className, onChange, checked }) => {
  return (
    <label className={`radio-container ${className}`}>
      {label}
      <input
        type="radio"
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        checked={checked}
      />
      <span className="checkmark"></span>
    </label>
  );
};

Radio.defaultProps = {
  className: "",
  onChange: () => {},
};

export const CheckBox = ({
  label,
  value,
  name,
  className,
  checked,
  onChange,
  disabled,
}) => {
  return (
    <label className={`check-container ${className}`}>
      {label}
      <input
        type="checkbox"
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <span className="check-checkmark"></span>
    </label>
  );
};
CheckBox.defaultProps = {
  className: "",
};

export const CustomSwitch = ({
  label,
  value,
  name,
  checked,
  onChange,
  disabled,
}) => {
  return (
    <div>
      <label>{label}</label>
      <label className="c-switch">
        <input
          type="checkbox"
          className="checker"
          checked={checked}
          value={value}
          onChange={onChange}
          name={name}
          disabled={disabled}
        />
        <span className="switch-slider round"></span>
      </label>
    </div>
  );
};
