import axios from "src/utils/axios";
import "react-toastify/dist/ReactToastify.css";
import {
  GET_TRAINING_SURVEY_QUESTIONS,
  // SUBMIT_TRAINING_SURVEY_QUESTIONS,
} from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import { trainingURL } from "src/constants";
// import { updateQueryStringParameter } from "src/utils/helper";

export const confirmUserSurvey = (lang, trainingEventId, surveyType) => {
  return axios({
    method: "get",
    url: `${trainingURL.TRAINING_GENERAL}/survey/user?lang=${lang}&trainingEventId=${trainingEventId}&surveyType=${surveyType}`,
  });
};

export const submitSurveyQuestions = (trainingEventId, payload) => {
  return axios({
    url: `${trainingURL.TRAINING_GENERAL}/survey-questions/user-answers`,
    method: "post",
    data: payload,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

export const getSurveyQuestions = (
  dispatch,
  lang,
  trainingEventId,
  surveyId
) => {
  dispatch(asyncActions(GET_TRAINING_SURVEY_QUESTIONS).loading(true));
  return axios({
    method: "get",
    url: `${trainingURL.TRAINING_GENERAL}/survey-questions/survey/${surveyId}/questions/for-users?language=${lang}`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  })
    .then((response) => {
      dispatch(asyncActions(GET_TRAINING_SURVEY_QUESTIONS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_TRAINING_SURVEY_QUESTIONS).success(data));
        dispatch(asyncActions(GET_TRAINING_SURVEY_QUESTIONS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 404) {
        dispatch(asyncActions(GET_TRAINING_SURVEY_QUESTIONS).success(null));
      }
      dispatch(asyncActions(GET_TRAINING_SURVEY_QUESTIONS).loading(false));
      dispatch(
        asyncActions(GET_TRAINING_SURVEY_QUESTIONS).failure(
          true,
          error.response
        )
      );
      return error.response;
    });
};
