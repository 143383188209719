import React from "react";

const MarketingPlanTemplate = ({ marketingPlan }) => {
  return (
    <div>
      <h4 className="text-center font-weight-bold mb-5">Marketing Plan</h4>
      <p>
        <span className="mr-4">7.1</span>
        <span>Marketing and Promotion Channels</span>
      </p>
      <p className="ml-5">
        {marketingPlan.marketingChannels === ""
          ? "[MARKETING CHANNELS]"
          : marketingPlan.marketingChannels}
      </p>

      <p>
        <span className="mr-4">7.2</span>
        <span>Pricing Strategy</span>
      </p>
      <p className="ml-5">
        {marketingPlan.pricingStrategy === ""
          ? "[PRICING STRATEGY]"
          : marketingPlan.pricingStrategy}
      </p>
      <p>
        <span className="mr-4">7.3</span>
        <span>Distribution Strategy</span>
      </p>
      <p className="ml-5">
        {marketingPlan.distributionStrategy === ""
          ? "[DISTRIBUTION STRATEGY]"
          : marketingPlan.distributionStrategy}
      </p>
    </div>
  );
};

export default MarketingPlanTemplate;
