import React, { useEffect, useState } from "react";
import styles from "./SideDrawer.module.scss";
// import { get_user_info_from_token } from "src/modules/Application/utils/someTokenMenthods";
import { useTranslation } from "react-i18next";
import BackDrop from "src/components/Layout/BackDrop/BackDrop";
import UserSection from "src/components/Layout/SideBar/UserSection/UserSection";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { Link, useHistory } from "react-router-dom";
import Logo from "src/assets/img/logo/logo_200.png";
import { Logout } from "src/requests/logout";
import queryString from "query-string";

const SideDrawer = ({ show, onClose }) => {
  const history = useHistory();
  const { endDate } = queryString.parse(history.location.search);
  const pathname = window.location.pathname;
  // const user = get_user_info_from_token().user;
  const currentUser = useCurrentUser();
  const { t } = useTranslation();
  // const token = JSON.parse(localStorage.getItem("data")).token;

  const calculateTimeLeft = () => {
    const difference = +new Date(endDate) - +new Date();
    let timeLeft = {
      days: "00",
      hours: "00",
      minutes: "00",
      seconds: "00",
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const handleLogout = (e) => {
    e.preventDefault();
    Logout(history);
  };

  const isCurrentMainPath = (title) => {
    const path = window.location.pathname;
    let currentPath;
    currentPath = path.split("/").filter(Boolean)[1];
    if (
      currentPath.toLowerCase() === "tests" ||
      currentPath.toLowerCase() === "personalinfo"
    ) {
      currentPath = path.split("/").filter(Boolean)[2];
    }
    return currentPath.toLowerCase() === title.toLowerCase();
  };

  return (
    <>
      <BackDrop show={show} close={onClose} />
      <div
        className={`${styles.SideDrawer} ${
          show ? styles.SideDrawerOpen : styles.SideDrawerHide
        }`}
      >
        <div className={styles.SideDrawer__search}>
          <Link to="/home">
            <img src={Logo} alt="" className={styles.SideDrawer__Logo} />
          </Link>
        </div>
        <h4 className={styles.SideDrawer__sideHeading}>
          {t("TEF Application Countdown")}
        </h4>
        <div className={`${styles.SideDrawer__countdownTimer} notranslate`}>
          <h3>
            {timeLeft.days} <span>{t("days")}</span>
          </h3>
          <h3>
            {timeLeft.hours} <span>{t("hours")}</span>
          </h3>
          <h3>
            {timeLeft.minutes} <span>{t("minutes")}</span>
          </h3>
          <h3>
            {timeLeft.seconds} <span>{t("seconds")}</span>
          </h3>
        </div>
        <UserSection currentUser={currentUser} />
        <ul className={styles.SideDrawer__nav}>
          <span>
            <div className={styles.SideDrawer__subTopics}>
              <h4 className={styles.SideDrawer__number}>1</h4>
              <div>
                <h4 className={styles.SideDrawer__topicName}>
                  {t("Getting to know you")}
                </h4>
                <ul>
                  <li
                    className={
                      isCurrentMainPath("bio") &&
                      `${styles.SideDrawer__sideActive}`
                    }
                  >
                    {t("Bio")}
                  </li>
                  <li
                    className={
                      isCurrentMainPath("experience") &&
                      `${styles.SideDrawer__sideActive}`
                    }
                  >
                    {t("Idea & Experience")}
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.SideDrawer__subTopics}>
              <h4 className={styles.SideDrawer__number}>2</h4>
              <div>
                <h4 className={styles.SideDrawer__topicName}>
                  {pathname.includes("offcycle")
                    ? "Assessment"
                    : "Know thyself"}
                </h4>
                <ul>
                  {!pathname.includes("offcycle") && (
                    <li
                      className={
                        isCurrentMainPath("personality") &&
                        `${styles.SideDrawer__sideActive}`
                      }
                    >
                      {t("Personality Test")}
                    </li>
                  )}
                  <li
                    className={
                      isCurrentMainPath("mcq") &&
                      `${styles.SideDrawer__sideActive}`
                    }
                  >
                    {t("Cognitive Test")}
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.SideDrawer__subTopics}>
              <h4 className={styles.SideDrawer__number}>3</h4>
              <div>
                <h4
                  className={
                    isCurrentMainPath("declaration")
                      ? `${styles.SideDrawer__sideActive} ${styles.SideDrawer__topicName}`
                      : `${styles.SideDrawer__topicName}`
                  }
                >
                  {t("Declaration")}
                </h4>
              </div>
            </div>
          </span>
          <span className={styles.SideDrawer__LogoutContainer}>
            <Link
              to="#"
              onClick={handleLogout}
              className={`${styles.SideDrawer__topicName} ${styles.SideDrawer__logoutLink}`}
            >
              {t("Logout")}
            </Link>
          </span>
        </ul>
      </div>
    </>
  );
};

export default SideDrawer;
