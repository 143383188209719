import React from "react";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import styles from "./DataCollectionModal.module.scss";

const DataCollectionModal = ({ modal, toggle }) => {
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4 className={styles.DataCollectionModal__h4}>
            Consent Clause for Data Collection
          </h4>
        </ModalHeader>
        <ModalBody>
          <p className={styles.DataCollectionModal__p}>
            By signing up on TEFCONNECT, you hereby consent to the collection,
            processing, use and, where required, the transfer of your personal
            data to third parties (within or outside Nigeria) by The Tony
            Elumelu Foundation for the purpose of application processing,
            sharing news and publications, Events, Surveys and any other data
            processing activities which may arise therefrom.
          </p>
          <p className={styles.DataCollectionModal__p}>
            You affirm that you are aware and take cognisance of your rights
            under the relevant Data Protection Laws in Nigeria and other terms
            relating to how we will treat your personal data, detailed in our{" "}
            <Link to="/privacy" className="terms__link">
              Data Protection and Privacy Policy{" "}
            </Link>
          </p>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DataCollectionModal;
