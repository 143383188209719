import React from "react";
import classes from "./LandingPage.module.scss";

const Trade = () => {
  return (
    <div className={classes.tradeContainer}>
      <div className={classes.tradeContainer__inner}>
        <div className={classes.tradeContainer__inner_detailContainer}>
          <div className={classes.tradeContainer__inner_detailContainer__left}>
            <h5>Trade</h5>
            <h4>Conduct swift and easy online shopping transactions…</h4>
          </div>
          <div className={classes.tradeContainer__inner_detailContainer__right}>
            <h5>
              …on the TEFConnect Marketplace from anywhere across the continent
              (CTA To Marketplace)
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trade;
