import React, { useState } from "react";
import "./FormInput.scss";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const FormInput = ({
  labelName,
  labelId,
  forLabel,
  inputType,
  inputName,
  changed,
  validStatus,
  isRequired,
  isFocused,
  isBlurred,
  inputLength,
  errorName,
  inputValue,
}) => {
  const styleName = "form-input-field__field";

  const [show, setShow] = useState(false);
  const togglePassword = () => setShow(!show);

  return (
    <div className="form-input-field">
      <label className="form-input-field__label" htmlFor={labelId}>
        {labelName}
      </label>
      {inputType === "password" ? (
        <div className="password-field">
          <input
            className={styleName}
            type={show ? "text" : "password"}
            name={inputName}
            onChange={changed}
            id={forLabel}
            required={isRequired}
            onFocus={isFocused}
            onBlur={isBlurred}
            value={inputValue || ""}
          />
          <span className="password-field-icons">
            {show ? (
              <FaEyeSlash onClick={togglePassword} style={{ opacity: "0.5" }} />
            ) : (
              <FaEye onClick={togglePassword} style={{ opacity: "0.5" }} />
            )}
          </span>
        </div>
      ) : inputLength ? (
        <div className="input-field-div">
          <input
            className={styleName}
            type={inputType}
            name={inputName}
            onChange={changed}
            id={forLabel}
            required={isRequired}
            maxLength={inputLength}
            value={inputValue || ""}
          />
          <label htmlFor={forLabel} className="input-field-length">
            {inputLength}
          </label>
        </div>
      ) : (
        <input
          className={styleName}
          type={inputType}
          name={inputName}
          onChange={changed}
          id={forLabel}
          required={isRequired}
          value={inputValue || ""}
        />
      )}
      {errorName && <p className="input-field-error">{errorName}</p>}
    </div>
  );
};

export default FormInput;
