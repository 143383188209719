import { asyncActionName } from "src/utils/AsyncUtils";
import { initialState } from "src/store";
import * as types from "src/actions";

const onboardingReducer = (
  state = initialState.onboarding.organizations,
  action
) => {
  switch (action.type) {
    // organization
    case asyncActionName(types.GET_ORGANIZATIONS).loading:
      return { ...state, requestingData: action.payload };
    case asyncActionName(types.GET_ORGANIZATIONS).success:
      return {
        ...state,
        organizations: action.payload,
      };
    case asyncActionName(types.GET_ORGANIZATIONS).failure:
      return {
        ...state,
        error: action.payload.status,
      };
    // skills
    case asyncActionName(types.GET_SKILLS).loading:
      return { ...state, requestingSkills: action.payload };
    case asyncActionName(types.GET_SKILLS).success:
      return {
        ...state,
        skills: action.payload,
      };
    case asyncActionName(types.GET_SKILLS).failure:
      return {
        ...state,
        requestingSkillError: action.payload.status,
      };

    // area
    case asyncActionName(types.GET_MENTORSHIP_AREAS).loading:
      return { ...state, requestingMentorshipAreas: action.payload };
    case asyncActionName(types.GET_MENTORSHIP_AREAS).success:
      return {
        ...state,
        areas: action.payload,
      };
    case asyncActionName(types.GET_MENTORSHIP_AREAS).failure:
      return {
        ...state,
        requestingMentorshipAreasError: action.payload.status,
      };
    // interests
    case asyncActionName(types.GET_INTERESTS).loading:
      return { ...state, requestingInterests: action.payload };
    case asyncActionName(types.GET_INTERESTS).success:
      return {
        ...state,
        interests: action.payload,
      };
    case asyncActionName(types.GET_INTERESTS).failure:
      return {
        ...state,
        requestingInteretsError: action.payload.status,
      };

    // onboarding data
    case asyncActionName(types.SUBMIT_ONBOARDING_DATA).loading:
      return { ...state, requestingSubmitOnboardingData: action.payload };
    case asyncActionName(types.SUBMIT_ONBOARDING_DATA).success:
      return {
        ...state,
        onboardingData: action.payload,
      };
    case asyncActionName(types.SUBMIT_ONBOARDING_DATA).failure:
      return {
        ...state,
        submitOnboardingDataError: action.payload.status,
      };

    default:
      return state;
  }
};
export default onboardingReducer;
