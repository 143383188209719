import React, { useState, useEffect } from "react";
import { Row, Col, Progress, Button } from "reactstrap";
// import SubmitDocument from "src/modules/Tef-Dms/components/SubmitDocument/SubmitDocument";
import classes from "./BusinessPlanGenerator.module.css";
import "./BusinessPlan.scss";
// import SubmitDocumentModal from "src/modules/Tef-Dms/components/Modals/SubmitDocumentModal/SubmitDocumentModal";
import ExecutiveSummaryForm from "src/modules/Tef-Dms/components/Steps/ExecutiveSummary/ExecutiveSummaryForm";
import Introduction from "src/modules/Tef-Dms/components/Steps/Introduction/Introduction";
import IndustryAndMarketAnalysis from "src/modules/Tef-Dms/components/Steps/IndustryAndMarketAnalysis/IndustryAndMarketAnalysis";
import Competition from "src/modules/Tef-Dms/components/Steps/Competition/Competition";
import SwotAnalysis from "src/modules/Tef-Dms/components/Steps/SwotAnalysis/SwotAnalysis";
import OperationalPlans from "src/modules/Tef-Dms/components/Steps/OperationalPlans/OperationalPlans";
import Products from "src/modules/Tef-Dms/components/Steps/Products/Products";
import MarketingPlan from "src/modules/Tef-Dms/components/Steps/MarketingPlan/MarketingPlan";
import Management from "src/modules/Tef-Dms/components/Steps/Management/Management";
import FinancialPlan from "src/modules/Tef-Dms/components/Steps/FinancialPlan/FinancialPlan";
import ExecutiveSummaryTemplate from "src/modules/Tef-Dms/components/Steps/ExecutiveSummary/ExecutiveSummaryTemplate";
import IntroductionTemplate from "src/modules/Tef-Dms/components/Steps/Introduction/IntroductionTemplate";
import ProductTemplate from "src/modules/Tef-Dms/components/Steps/Products/ProductTemplate";
import IndustryMarketAnalysisTemplate from "src/modules/Tef-Dms/components/Steps/IndustryAndMarketAnalysis/IndustryMarketAnalysisTemplate";
import CompetitionTemplate from "src/modules/Tef-Dms/components/Steps/Competition/CompetitionTemplate";
import SwotAnalysisTemplate from "src/modules/Tef-Dms/components/Steps/SwotAnalysis/SwotAnalysisTemplate";
import OperationalPlansTemplate from "src/modules/Tef-Dms/components/Steps/OperationalPlans/OperationalPlansTemplate";
import MarketingPlanTemplate from "src/modules/Tef-Dms/components/Steps/MarketingPlan/MarketingPlanTemplate";
import ManagementTemplate from "src/modules/Tef-Dms/components/Steps/Management/ManagementTemplate";
import FinancialPlanTemplate from "src/modules/Tef-Dms/components/Steps/FinancialPlan/FinancialPlanTemplate";
import DownloadBusinessPlan from "./DownloadBusinessPlan";
import jwt_decode from "src/utils/jwt-decode";

import { postBuilderInfo, getBuilderInfo } from "src/requests/DmsRequest";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BusinessPlanGenerator = () => {
  const [step, setSteps] = useState(1);
  const [loader, setLoader] = useState(false);
  const [executiveSummary, setExecutiveSummary] = useState({
    coverPhoto: null,
    businessName: "",
    stageOfBusiness: "",
    addressOfBusiness: "",
    valueProposition: "",
    targetMarket: "",
    marketApproach: "",
    objective: "",
    diffrentiation: "",
    typeOfOwnership: "",
    businessOwner: [],
    businessOwnerProfile: [],
    visionStatement: "",
    missionStatement: "",
    fundingRequirement: "",
    useOfFundingRequirement: "",
    salesTurnoverAmount: "",
    grossProfitAmount: "",
    netProfit: "",
    risks: [],
  });
  const [introduction, setIntroduction] = useState({
    elevatorPitch: "",
    businessDescription: "",
    businessObjectives: [],
    successfactors: [],
    valueProposition: "",
    businessStatus: "",
    contributionToEconomy: "",
  });
  const [product, setProduct] = useState({
    description: "",
    photos: [],
  });
  const [industryMarketAnalysis, setIndustryMarketAnalysis] = useState({
    descriptionOfIndustry: "",
    descriptionOfMarket: "",
    competitors: [],
    challengesInBusiness: [],
    mitigationOfChallenges: [],
    opportunity: [],
    targetMarket: [],
  });
  const [competition, setCompetition] = useState({
    competitiveAnalysis: "",
    productOffers: "",
    nameOfCompetitors: ["", "", ""],
    locationOfCompetitors: ["", "", ""],
    productsOffered: ["", "", ""],
    pricingStrategy: ["", "", ""],
    uniqueness: ["", "", ""],
    strength: ["", "", ""],
    weaknesses: ["", "", ""],
    durationOfExistence: ["", "", ""],
    customerSegments: ["", "", ""],
  });
  const [swot, setSwot] = useState({
    strength: "",
    weakness: "",
    opportunity: "",
    threats: "",
    risks: [],
  });
  const [operationalPlans, setOperationalPlans] = useState({
    descriptionOfOperations: "",
    keyResources: [],
    suppliers: [],
    rawMaterials: [{ materials: "", description: "", source: "" }],
    capitalEquipments: [{ equipment: "", description: "", source: "" }],
    consumablesList: [{ materials: "", description: "", source: "" }],
  });
  const [marketingPlan, setMarketingPlan] = useState({
    marketingChannels: "",
    pricingStrategy: "",
    distributionStrategy: "",
  });
  const [management, setManagement] = useState({
    reason: "",
    detailsOfEmployee: "",
    operatingHours: "",
    keyManagementTeam: [{ Position: "", Skills: "", responsibility: "" }],
    organisationalStructure: "",
  });
  const [financialPlan, setFinancialPlan] = useState({
    revenueModel: "",
    productPrices: "",
  });

  const totalSteps = 10;

  const Next = () => {
    return setSteps((prevState) => {
      if (prevState >= totalSteps) {
        return prevState;
      } else {
        return prevState + 1;
      }
    });
  };

  const Back = () => {
    return setSteps((prevState) => {
      if (prevState <= 1) {
        return prevState;
      } else {
        return prevState - 1;
      }
    });
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    try {
      e.preventDefault();
      const tkn = localStorage.getItem("data");
      const token = JSON.parse(tkn).token;
      const { dms_user } = jwt_decode(token);
      const programId = dms_user[0];
      const programEventId = dms_user[1];

      const payload = {
        programId,
        programEventId,
        ...executiveSummary,
        ...introduction,
        ...product,
        ...industryMarketAnalysis,
        ...competition,
        ...swot,
        ...operationalPlans,
        ...marketingPlan,
        ...management,
        ...financialPlan,
      };

      const response = await postBuilderInfo(payload);
      toast("You can now upload your business plan");
      setLoader(false);
      return response;
    } catch (error) {
      setLoader(false);

      return error;
    }
  };

  const _getBuilderData = async () => {
    try {
      const tkn = localStorage.getItem("data");
      const token = JSON.parse(tkn).token;
      const { dms_user } = jwt_decode(token);
      const programId = dms_user[0];

      const response = await getBuilderInfo(programId);

      const { data } = response.data;

      setExecutiveSummary({
        ...executiveSummary,
        coverPhoto: null,
        businessName: data.businessName === null ? "" : data.businessName,
        stageOfBusiness:
          data.stageOfBusiness === null ? "" : data.stageOfBusiness,
        addressOfBusiness:
          data.addressOfBusiness === null ? "" : data.addressOfBusiness,
        valueProposition:
          data.valueProposition === null ? "" : data.valueProposition,
        targetMarket: data.targetMarket === null ? "" : data.targetMarket,
        marketApproach: data.marketApproach === null ? "" : data.marketApproach,
        objective: data.objective === null ? "" : data.objective,
        diffrentiation: data.diffrentiation === null ? "" : data.diffrentiation,
        typeOfOwnership:
          data.typeOfOwnership === null ? "" : data.typeOfOwnership,
        businessOwner: data.businessOwner === null ? [] : data.businessOwner,
        businessOwnerProfile:
          data.businessOwnerProfile === null ? [] : data.businessOwnerProfile,
        visionStatement:
          data.visionStatement === null ? "" : data.visionStatement,
        missionStatement:
          data.missionStatement === null ? "" : data.missionStatement,
        fundingRequirement:
          data.fundingRequirement === null ? "" : data.fundingRequirement,
        useOfFundingRequirement:
          data.useOfFundingRequirement === null
            ? ""
            : data.useOfFundingRequirement,
        salesTurnoverAmount:
          data.salesTurnoverAmount === null ? "" : data.salesTurnoverAmount,
        grossProfitAmount:
          data.grossProfitAmount === null ? "" : data.grossProfitAmount,
        netProfit: data.netProfit === null ? "" : data.netProfit,
        risks: data.risks === null ? [] : data.risks,
      });
      setIntroduction({
        ...introduction,
        elevatorPitch: data.elevatorPitch === null ? "" : data.elevatorPitch,
        businessDescription:
          data.businessDescription === null ? "" : data.businessDescription,
        businessObjectives:
          data.businessObjectives === null ? [] : data.businessObjectives,
        successfactors: data.successfactors === null ? [] : data.successfactors,
        valueProposition:
          data.valueProposition === null ? "" : data.valueProposition,
        businessStatus: data.businessStatus === null ? "" : data.businessStatus,
        contributionToEconomy:
          data.contributionToEconomy === null ? "" : data.contributionToEconomy,
      });
      setProduct({
        ...product,
        description: data.description === null ? "" : data.description,
        photos: data.photos === null ? [] : data.photos,
      });
      setIndustryMarketAnalysis({
        ...industryMarketAnalysis,
        descriptionOfIndustry:
          data.descriptionOfIndustry === null ? "" : data.descriptionOfIndustry,
        descriptionOfMarket:
          data.descriptionOfMarket === null ? "" : data.descriptionOfMarket,
        competitors: data.competitors === null ? [] : data.competitors,
        challengesInBusiness:
          data.challengesInBusiness === null ? [] : data.challengesInBusiness,
        mitigationOfChallenges:
          data.mitigationOfChallenges === null
            ? []
            : data.mitigationOfChallenges,
        opportunity: data.opportunity === null ? [] : data.opportunity,
        targetMarket: data.targetMarket === null ? [] : data.targetMarket,
      });
      setCompetition({
        ...competition,
        competitiveAnalysis:
          data.competitiveAnalysis === null ? "" : data.competitiveAnalysis,
        productOffers: data.productOffers === null ? "" : data.productOffers,
        nameOfCompetitors:
          data.nameOfCompetitors === null
            ? ["", "", ""]
            : data.nameOfCompetitors,
        locationOfCompetitors:
          data.locationOfCompetitors === null
            ? ["", "", ""]
            : data.locationOfCompetitors,
        productsOffered:
          data.productsOffered === null ? ["", "", ""] : data.productsOffered,
        pricingStrategy:
          data.pricingStrategy === null ? ["", "", ""] : data.pricingStrategy,
        uniqueness: data.uniqueness === null ? ["", "", ""] : data.uniqueness,
        strength: data.strength2 === null ? ["", "", ""] : data.strength2,
        weaknesses: data.weaknesses === null ? ["", "", ""] : data.weaknesses,
        durationOfExistence:
          data.durationOfExistence === null
            ? ["", "", ""]
            : data.durationOfExistence,
        customerSegments:
          data.customerSegments === null ? ["", "", ""] : data.customerSegments,
      });
      setSwot({
        ...swot,
        strength: data.strength === null ? "" : data.strength,
        weakness: data.weakness === null ? "" : data.weakness,
        opportunity: data.opportunity === null ? "" : data.opportunity,
        threats: data.threats === null ? "" : data.threats,
        risks: data.risks === null ? [] : data.risks,
      });
      setOperationalPlans({
        ...operationalPlans,
        descriptionOfOperations:
          data.descriptionOfOperations === null
            ? ""
            : data.descriptionOfOperations,
        keyResources: data.keyResources === null ? [] : data.keyResources,
        suppliers: data.suppliers === null ? [] : data.suppliers,
        rawMaterials:
          data.rawMaterials === null
            ? [{ materials: "", description: "", source: "" }]
            : data.rawMaterials,
        capitalEquipments:
          data.capitalEquipments === null
            ? [{ equipment: "", description: "", source: "" }]
            : data.capitalEquipments,
        consumablesList:
          data.consumablesList === null
            ? [{ materials: "", description: "", source: "" }]
            : data.consumablesList,
      });
      setMarketingPlan({
        ...marketingPlan,
        marketingChannels:
          data.marketingChannels === null ? "" : data.marketingChannels,
        pricingStrategy:
          data.pricingStrategy === null ? "" : data.pricingStrategy,
        distributionStrategy:
          data.distributionStrategy === null ? "" : data.distributionStrategy,
      });
      setManagement({
        ...management,
        reason: data.reason === null ? "" : data.reason,
        detailsOfEmployee:
          data.detailsOfEmployee === null ? "" : data.detailsOfEmployee,
        operatingHours: data.operatingHours === null ? "" : data.operatingHours,
        keyManagementTeam:
          data.keyManagementTeam === null
            ? [{ Position: "", Skills: "", responsibility: "" }]
            : data.keyManagementTeam,
        organisationalStructure:
          data.organisationalStructure === null
            ? ""
            : data.organisationalStructure,
      });
      setFinancialPlan({
        ...financialPlan,
        revenueModel: data.revenueModel === null ? "" : data.revenueModel,
        productPrices: data.productPrices === null ? "" : data.productPrices,
      });

      return data;
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    _getBuilderData();
  }, []);

  let progressValue = (step / totalSteps) * 100;
  progressValue = Math.ceil(progressValue);

  return (
    <Row className="pt-4">
      <Col md={5}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div style={{ overflowY: "scroll", height: "60vh" }}>
            {step === 1 && (
              <ExecutiveSummaryForm
                executiveSummary={executiveSummary}
                setExecutiveSummary={setExecutiveSummary}
              />
            )}
            {step === 2 && (
              <Introduction
                introduction={introduction}
                setIntroduction={setIntroduction}
              />
            )}
            {step === 3 && (
              <Products product={product} setProduct={setProduct} />
            )}
            {step === 4 && (
              <IndustryAndMarketAnalysis
                industryMarketAnalysis={industryMarketAnalysis}
                setIndustryMarketAnalysis={setIndustryMarketAnalysis}
              />
            )}
            {step === 5 && (
              <Competition
                competition={competition}
                setCompetition={setCompetition}
              />
            )}
            {step === 6 && <SwotAnalysis swot={swot} setSwot={setSwot} />}
            {step === 7 && (
              <OperationalPlans
                operationalPlans={operationalPlans}
                setOperationalPlans={setOperationalPlans}
              />
            )}
            {step === 8 && (
              <MarketingPlan
                marketingPlan={marketingPlan}
                setMarketingPlan={setMarketingPlan}
              />
            )}
            {step === 9 && (
              <Management
                management={management}
                setManagement={setManagement}
              />
            )}
            {step === 10 && (
              <FinancialPlan
                financialPlan={financialPlan}
                setFinancialPlan={setFinancialPlan}
              />
            )}
          </div>

          <div className="d-flex justify-content-between mt-4">
            {step === 1 ? null : (
              <Button onClick={() => Back()}>Previous</Button>
            )}
            {step === 10 && (
              <DownloadBusinessPlan
                executiveSummary={executiveSummary}
                introductionDetails={introduction}
                productPhotosDetails={product}
                industryMarketAnalysisDetails={industryMarketAnalysis}
                competitionDetails={competition}
                swotDetails={swot}
                operationalPlanDetails={operationalPlans}
                marketingPlanDetails={marketingPlan}
                managementDetails={management}
                financialPlanDetails={financialPlan}
                loader={loader}
              />
            )}
            {step === 10 ? null : <Button onClick={() => Next()}>Next</Button>}
          </div>
        </form>
      </Col>
      <Col md={7}>
        <Row>
          <div className={classes.Template}>
            <p>
              Business Plan Completion
              <span> {progressValue}%</span>
            </p>

            <div className="dms-progress-container">
              <Progress
                value={progressValue}
                min={0}
                max={100}
                color="danger"
                className={`${classes.Progress}`}
              />
            </div>
            <div className="mt-2">
              {step === 1 && (
                <ExecutiveSummaryTemplate
                  executiveSummary={executiveSummary}
                  setExecutiveSummary={setExecutiveSummary}
                />
              )}
              {step === 2 && (
                <IntroductionTemplate
                  executiveSummary={executiveSummary}
                  introduction={introduction}
                  setIntroduction={setIntroduction}
                />
              )}
              {step === 3 && (
                <ProductTemplate product={product} setProduct={setProduct} />
              )}
              {step === 4 && (
                <IndustryMarketAnalysisTemplate
                  industryMarketAnalysis={industryMarketAnalysis}
                  setIndustryMarketAnalysis={setIndustryMarketAnalysis}
                />
              )}
              {step === 5 && (
                <CompetitionTemplate
                  competition={competition}
                  setCompetition={setCompetition}
                />
              )}
              {step === 6 && (
                <SwotAnalysisTemplate swot={swot} setSwot={setSwot} />
              )}
              {step === 7 && (
                <OperationalPlansTemplate
                  operationalPlans={operationalPlans}
                  setOperationalPlans={setOperationalPlans}
                />
              )}
              {step === 8 && (
                <MarketingPlanTemplate
                  marketingPlan={marketingPlan}
                  setMarketingPlan={setMarketingPlan}
                />
              )}
              {step === 9 && (
                <ManagementTemplate
                  management={management}
                  setManagement={setManagement}
                />
              )}
              {step === 10 && (
                <FinancialPlanTemplate
                  financialPlan={financialPlan}
                  setFinancialPlan={setFinancialPlan}
                />
              )}
            </div>
          </div>
        </Row>
        {/* <Row className="mt-5">
          <SubmitDocument>
            <SubmitDocumentModal />
          </SubmitDocument>
        </Row> */}
      </Col>
    </Row>
  );
};

export default BusinessPlanGenerator;
