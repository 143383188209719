export const toolsValidationErrors = {
  toolTitle: null,
  toolShortDescription: null,
  toolTopics: null,
  toolOfficialWebsite: null,
  fileName: null,
};

export const toolsObject = {
  toolTitle: "",
  toolShortDescription: "",
  toolTopics: [],
  toolOfficialWebsite: "",
  fileName: "",
};
