/* eslint-disable no-prototype-builtins */
import * as types from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import { discussionURL } from "src/constants";
import axios from "src/utils/axios";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const user = get_user_info_from_token() && get_user_info_from_token().user;
let mentorshipGroup;
if (user)
  mentorshipGroup =
    user && user.hasOwnProperty("tef-mentor")
      ? user["tef-mentor"]
      : user.hasOwnProperty("tef-mentee")
      ? user["tef-mentee"]
      : null;
// if (!mentorshipGroup) return;
// axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1]

export const getGoalDiscussions = (dispatch, goalId, loading = true) => {
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];

  if (!goalId) return;
  dispatch(asyncActions(types.GET_GOAL_DISCUSSIONS).loading(loading));
  dispatch(asyncActions(types.GET_GOAL_DISCUSSIONS).success([]));
  return axios({
    method: "get",
    url: `${discussionURL.GET_GOAL_DISCUSSIONS}/${goalId}/discussions`,
  })
    .then((response) => {
      dispatch(asyncActions(types.GET_GOAL_DISCUSSIONS).loading(false));

      if (response.status === 200) {
        const { data } = response.data;
        console.log(response);
        dispatch(asyncActions(types.GET_GOAL_DISCUSSIONS).success(data));
        dispatch(asyncActions(types.GET_GOAL_DISCUSSIONS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(types.GET_GOAL_DISCUSSIONS).loading(true));
      dispatch(asyncActions(types.GET_GOAL_DISCUSSIONS).success([]));
      dispatch(
        asyncActions(types.GET_GOAL_DISCUSSIONS).failure(true, error.response)
      );
      return { status: 504, data: null };
    });
};

export const postDiscussion = (
  dispatch,
  payload,
  history,
  userInfo,
  userId
) => {
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];

  dispatch(asyncActions(types.CREATE_DISCUSSION_ON_GOAL).loading(true));
  return axios({
    method: "post",
    url: discussionURL.CREATE_DISCUSSION_ON_GOAL,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(types.CREATE_DISCUSSION_ON_GOAL).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        const disData = {
          ...payload,
          fileNames: payload.fileUploadNames,
          id: Number(data),
          otherDetails: userInfo,
          createdBy: userId,
          totalLikes: 0,
          totalReplies: 0,
          createdDate: new Date(),
        };
        console.log(disData);
        dispatch(
          asyncActions(types.CREATE_DISCUSSION_ON_GOAL).success(disData)
        );
        dispatch(asyncActions(types.CREATE_DISCUSSION_ON_GOAL).loading(false));
        return { data, status: response.status };
        // history.push("/onboarding/success");
      }
      return response;
    })
    .catch((error) => {
      console.log(error.response);
      dispatch(asyncActions(types.CREATE_DISCUSSION_ON_GOAL).success([]));
      dispatch(asyncActions(types.CREATE_DISCUSSION_ON_GOAL).loading(true));
      dispatch(
        asyncActions(types.CREATE_DISCUSSION_ON_GOAL).failure(
          true,
          error.response
        )
      );
      return { status: 504, data: null };
    });
};

export const likeOrUnlike = (dispatch, discussionId) => {
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];

  dispatch(asyncActions(types.LIKE_DISCUSSION).loading(false));
  return axios({
    method: "put",
    url: `${discussionURL.LIKE_DISCUSSION}/${discussionId}/like-unlike`,
    data: { discussionId },
  })
    .then((response) => {
      dispatch(asyncActions(types.LIKE_DISCUSSION).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        getDiscussionDetails(dispatch, discussionId, false).then((res) => {
          if (res.status === 200) {
            dispatch(asyncActions(types.LIKE_DISCUSSION).success(res.data));
            dispatch(asyncActions(types.LIKE_DISCUSSION).loading(false));
            return { data, status: response.status };
          }
        });
      }
      return response;
    })
    .catch((error) => {
      console.log(error.response);
      dispatch(asyncActions(types.LIKE_DISCUSSION).success([]));
      dispatch(asyncActions(types.LIKE_DISCUSSION).loading(true));
      dispatch(
        asyncActions(types.LIKE_DISCUSSION).failure(true, error.response)
      );
      return { status: 504, data: null };
    });
};

export const getDiscussionDetails = (
  dispatch,
  discussionId,
  loading = true
) => {
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];

  dispatch(asyncActions(types.GET_DISCUSSION_DETAILS).loading(false));
  return axios({
    method: "get",
    url: `${discussionURL.GET_DISCUSSION_DETAILS}/${discussionId}`,
    data: { discussionId },
  })
    .then((response) => {
      dispatch(asyncActions(types.GET_DISCUSSION_DETAILS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        console.log(response);
        dispatch(asyncActions(types.GET_DISCUSSION_DETAILS).success(data));
        dispatch(asyncActions(types.GET_DISCUSSION_DETAILS).loading(false));
        return { data, status: response.status };
      }
      return response;
    })
    .catch((error) => {
      console.log(error.response);
      dispatch(asyncActions(types.GET_DISCUSSION_DETAILS).success({}));
      dispatch(asyncActions(types.GET_DISCUSSION_DETAILS).loading(false));
      dispatch(
        asyncActions(types.GET_DISCUSSION_DETAILS).failure(
          false,
          error.response
        )
      );
      return { status: 504, data: null };
    });
};

export const getDiscussionReplies = (
  dispatch,
  discussionId,
  loading = true
) => {
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];

  if (!discussionId) return;
  dispatch(asyncActions(types.GET_DISCUSSION_REPLIES).loading(loading));
  // dispatch(asyncActions(types.GET_DISCUSSION_REPLIES).success([]));
  return axios({
    method: "get",
    url: `${discussionURL.GET_DISCUSSION_REPLIES}/${discussionId}/replies?discussionId=${discussionId}`,
  })
    .then((response) => {
      dispatch(asyncActions(types.GET_DISCUSSION_REPLIES).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        console.log(response);
        dispatch(asyncActions(types.GET_DISCUSSION_REPLIES).success(data));
        dispatch(asyncActions(types.GET_DISCUSSION_REPLIES).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(types.GET_DISCUSSION_REPLIES).loading(true));
      dispatch(asyncActions(types.GET_DISCUSSION_REPLIES).success([]));
      dispatch(
        asyncActions(types.GET_DISCUSSION_REPLIES).failure(true, error.response)
      );
      return { status: 504, data: null };
    });
};

export const postDiscussionReply = (dispatch, data, loading = true) => {
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];

  const { discussionId } = data;
  dispatch(asyncActions(types.CREATE_DISCUSSION_REPLY).loading(loading));
  // dispatch(asyncActions(types.CREATE_DISCUSSION_REPLY).success([]));
  return axios({
    method: "post",
    url: `${discussionURL.CREATE_DISCUSSION_REPLY}`,
    data,
  })
    .then((response) => {
      dispatch(asyncActions(types.CREATE_DISCUSSION_REPLY).loading(false));

      if (response.status === 200) {
        const { data } = response.data;
        console.log(response);
        getDiscussionDetails(dispatch, discussionId, false).then((res) => {
          if (res.status === 200) {
            dispatch(
              asyncActions(types.CREATE_DISCUSSION_REPLY).success(res.data)
            );
            dispatch(
              asyncActions(types.CREATE_DISCUSSION_REPLY).loading(false)
            );
            return { data, status: response.status };
          }
        });
        // dispatch(asyncActions(types.CREATE_DISCUSSION_REPLY).success(data));
        // dispatch(asyncActions(types.CREATE_DISCUSSION_REPLY).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(types.CREATE_DISCUSSION_REPLY).loading(true));
      dispatch(asyncActions(types.CREATE_DISCUSSION_REPLY).success([]));
      dispatch(
        asyncActions(types.CREATE_DISCUSSION_REPLY).failure(
          true,
          error.response
        )
      );
      return { status: 504, data: null };
    });
};

export const likeOrUnlikeReply = (dispatch, replyId) => {
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];

  if (!replyId) return;
  dispatch(asyncActions(types.LIKE_REPLY).loading(false));
  return axios({
    method: "get",
    url: `${discussionURL.LIKE_REPLY}/${replyId}/like-unlike`,
  })
    .then((response) => {
      dispatch(asyncActions(types.LIKE_REPLY).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        getReplyDetails(dispatch, replyId, false).then((res) => {
          if (res.status === 200) {
            dispatch(asyncActions(types.LIKE_REPLY).success(res.data));
            dispatch(asyncActions(types.LIKE_REPLY).loading(false));
            return { data, status: response.status };
          }
        });
      }
      return response;
    })
    .catch((error) => {
      console.log(error.response);
      dispatch(asyncActions(types.LIKE_REPLY).success([]));
      dispatch(asyncActions(types.LIKE_REPLY).loading(true));
      dispatch(asyncActions(types.LIKE_REPLY).failure(true, error.response));
      return { status: 504, data: null };
    });
};

export const getReplyDetails = (dispatch, replyId, loading = true) => {
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];

  dispatch(asyncActions(types.GET_REPLY_DETAILS).loading(false));
  return axios({
    method: "get",
    url: `${discussionURL.GET_REPLY_DETAILS}/${replyId}`,
  })
    .then((response) => {
      dispatch(asyncActions(types.GET_REPLY_DETAILS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        // console.log(response);
        dispatch(asyncActions(types.GET_REPLY_DETAILS).success(data));
        dispatch(asyncActions(types.GET_REPLY_DETAILS).loading(false));
        return { data, status: response.status };
      }
      return response;
    })
    .catch((error) => {
      console.log(error.response);
      dispatch(asyncActions(types.GET_REPLY_DETAILS).success({}));
      dispatch(asyncActions(types.GET_REPLY_DETAILS).loading(false));
      dispatch(
        asyncActions(types.GET_REPLY_DETAILS).failure(false, error.response)
      );
      return { status: 504, data: null };
    });
};
