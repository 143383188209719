import React from "react";
import { PhotoSwipe } from "react-photoswipe";
import "react-photoswipe/lib/photoswipe.css";

const PhotoModal = ({ image, open, toggle }) => {
  let items = [];
  if (image.length > 0) {
    items = image.map((item) => {
      return {
        src: item.url,
        w: 900,
        h: 900,
        title: "",
      };
    });
  }

  const options = {
    // http://photoswipe.com/documentation/options.html
  };

  return (
    <PhotoSwipe
      isOpen={open}
      items={items}
      options={options}
      onClose={toggle}
    />
  );
};

export default PhotoModal;
