import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import { Context } from "src/store";
import { FaAngleLeft } from "react-icons/fa";
import {
  isGroupMember,
  getCategoryGroups,
} from "src/requests/CommunityRequest";
import "../Results/Results.scss";
import SuggestedGroupCard from "src/modules/Community/components/SuggestedGroup/SuggestedGroupCard/SuggestedGroupCard";
import Loader from "src/components/Loader/Loader";
import ResultPagination from "src/modules/Community/components/ResultPagination/ResultPagination";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const CategoryGroup = () => {
  const {
    user: { id },
  } = get_user_info_from_token();
  const qa = useLocation();
  const { state, dispatch } = useContext(Context);
  const [usersGroups, setUsersGroups] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [pager, setPager] = useState({
    firstItemOnPage: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    lastItemOnPage: 1,
    pageCount: 1,
    pageNumber: 1,
    pageSize: 1,
    totalItemCount: 1,
  });
  const [pageDetails, setPageDetails] = useState({
    pageSize: 16,
    pageNumber: pager.pageNumber,
  });
  const { categoryId, title } = queryString.parse(qa.search);

  const onPageSelect = (e) => {
    setIsPageLoading(true);
    setPageDetails({
      ...pageDetails,
      pageNumber: e.selected + 1,
    });
  };

  useEffect(() => {
    const payload = {
      categoryId,
      pageNumber: pageDetails.pageNumber,
      maxItem: pageDetails.pageSize,
    };

    getCategoryGroups(dispatch, payload).then((res) => {
      const {
        data: { pager },
      } = res.data;
      setPager(pager);
      setIsPageLoading(false);
    });
  }, [pageDetails]);

  useEffect(() => {
    isGroupMember(dispatch, id).then((res) => {
      if (res.status === 200) {
        const {
          data: { data },
        } = res.data;
        setUsersGroups(data);
      }
    });
  }, [pageDetails]);

  const { groups } = state.Communities;
  const cards = groups.map((group, index) => {
    const belongs = Boolean(
      Number(
        usersGroups.filter((userGroup) => userGroup.id === group.id).length
      )
    );
    return (
      <SuggestedGroupCard
        key={index}
        id={group.id}
        groupLogo={group.imageUrl}
        title={group.name}
        members={group.membersCount}
        location={group.privacy}
        belongs={belongs}
        ownerId={group.userId}
      />
    );
  });

  return (
    <div className="results-main">
      <div className="results-main__heading">
        <Link to="/community" className="results-main__heading--back">
          <FaAngleLeft /> &nbsp; Back
        </Link>
        <h1 className="results-main__heading--title">
          {decodeURIComponent(title)} Category
        </h1>
      </div>
      {isPageLoading ? (
        <div className="results-main__loader">
          <Loader color="danger" />
        </div>
      ) : (
        <React.Fragment>
          <div className="results-main__results">{cards}</div>
          <div className="results-main__pagination results-main__pagination--bottom">
            <ResultPagination
              pageCount={pager.pageCount}
              pageRange={1}
              onSelect={onPageSelect}
              current={pager.pageNumber}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default CategoryGroup;
