import React, { useEffect, useContext } from "react";
import { Context } from "src/store";
import { getReply } from "src/requests/OpportunityRequest";
import Avatar from "src/components/Avatar/Avatar";
import classes from "./ResponseToComment.module.css";

const ResponseToComment = ({ commentId }) => {
  const { state, dispatch } = useContext(Context);

  const { responses } = state.Opportunities;
  useEffect(() => {
    if (commentId) {
      getReply(dispatch, commentId);
    }
  }, []);

  return (
    <div>
      {responses &&
        responses.map((response) => (
          <div className={classes.Response} key={response.id}>
            <div className={classes.Avatar}>
              <Avatar
                dimension={12}
                src={response.otherDetails.imageUrl}
                name={`${response.otherDetails.firstName} ${response.otherDetails.lastName}`}
              />
            </div>
            <div className={classes.OtherDetails}>
              <div
                className={classes.CommentPoster}
              >{`${response.otherDetails.firstName} ${response.otherDetails.lastName}`}</div>
              <p className={classes.ResponseBody}>{response.note}</p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ResponseToComment;
