import React, { useState, useContext, useEffect, Fragment } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import ReactAvatar from "react-avatar";
import "./AllMembers.scss";
import { getAllGroupUsers, removeUser } from "src/requests/CommunityRequest";
import queryString from "query-string";
import { useLocation, Link } from "react-router-dom";
import { Context } from "src/store";
import Skeleton from "react-loading-skeleton";
import { FaTimesCircle } from "react-icons/fa";
import { onHandleDelete } from "src/utils/helper";

const AllMembers = ({
  backgroundColor,
  belongsToId,
  userId,
  setToggleInviteFriendsModal,
  adminId,
}) => {
  const [searchparam, setSearchparam] = useState("");
  const { state, dispatch } = useContext(Context);
  const qa = useLocation();
  const { groupId } = queryString.parse(qa.search);
  const isAdmin = belongsToId === userId;

  const getGroupUsers = () => {
    getAllGroupUsers(dispatch, groupId, searchparam);
  };

  useEffect(() => {
    getGroupUsers();
  }, [searchparam, state.Communities.removeUser]);

  const handleDelete = (id) => {
    const payload = {
      userId: id,
      groupId,
    };
    onHandleDelete(removeUser, dispatch, payload);
  };

  return (
    <div
      style={{ backgroundColor: backgroundColor || "#f9fbfd" }}
      className="mb-5 px-2 py-4"
    >
      <Form>
        <FormGroup>
          <div className="d-flex justify-content-between px-2">
            <Label for="searchMembers" className="mb-2">
              Members
            </Label>

            <Label
              onClick={() => setToggleInviteFriendsModal(true)}
              className="fs-4 fw-bold text-danger"
            >
              Invite member
            </Label>
          </div>
          <Input
            type="search"
            name="searchparam"
            id="searchMembers"
            placeholder="Find a Member"
            value={searchparam}
            onChange={(e) => setSearchparam(e.target.value.replace(/\W/g, ""))}
          />
        </FormGroup>
      </Form>

      {state.Communities.groupUsersLoading ? (
        <Fragment>
          <div className="d-flex align-items-center px-3">
            <div className="mr-2">
              <Skeleton circle={true} height={35} width={35} />
            </div>
            <div>
              <Skeleton width={100} />
            </div>
          </div>
          <div className="d-flex align-items-center px-3">
            <div className="mr-2">
              <Skeleton circle={true} height={35} width={35} />
            </div>
            <div>
              <Skeleton width={100} />
            </div>
          </div>
          <div className="d-flex align-items-center px-3">
            <div className="mr-2">
              <Skeleton circle={true} height={35} width={35} />
            </div>
            <div>
              <Skeleton width={100} />
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="member-display-area">
          {state.Communities.groupUsers.map((member, index) => (
            <div key={index} className="all-members-list">
              <Link
                to={`/profile/${member.userId}`}
                className="all-members-link"
              >
                <span className="all-members-display-div">
                  {member.imageUrl && member.imageUrl !== "string" ? (
                    <img
                      src={member.imageUrl}
                      className="all-members-display-pic "
                      alt="display"
                    />
                  ) : (
                    <ReactAvatar
                      name={member.firstName + " " + member.lastName}
                      size={35}
                      round={true}
                    />
                  )}
                </span>
                <small className="all-members-list-name">
                  {member.firstName + " " + member.lastName}{" "}
                </small>
              </Link>

              {isAdmin && adminId !== member.userId && (
                <FaTimesCircle
                  color="red"
                  onClick={() => handleDelete(member.userId)}
                  style={{ cursor: "pointer" }}
                  title="Remove"
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(AllMembers);
