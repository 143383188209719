import axios from "src/utils/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { asyncActions } from "src/utils/AsyncUtils";
import { messageURL } from "src/constants";
import { SEND_MESSAGE, GET_ALL_MESSAGES, GET_USER_MESSAGES } from "src/actions";

export const getAllMessages = (dispatch) => {
  dispatch(asyncActions(GET_ALL_MESSAGES).loading(true));
  return axios({
    method: "get",
    url: `${messageURL.GET_ALL_MESSAGES_URL}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_ALL_MESSAGES).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_ALL_MESSAGES).success(data));
        dispatch(asyncActions(GET_ALL_MESSAGES).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_ALL_MESSAGES).loading(false));
      dispatch(asyncActions(GET_ALL_MESSAGES).failure(true, error.response));
      return error.response;
    });
};

export const getAllMessagesFromUser = (dispatch, id) => {
  dispatch(asyncActions(GET_USER_MESSAGES).loading(true));
  return axios({
    method: "get",
    url: `${messageURL.GET_USER_MESSAGES_URL}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_USER_MESSAGES).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_USER_MESSAGES).success(data));
        dispatch(asyncActions(GET_USER_MESSAGES).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_USER_MESSAGES).loading(false));
      dispatch(asyncActions(GET_USER_MESSAGES).failure(true, error.response));
      toast.error("An error occurred");
      return error.response;
    });
};

export const sendMessage = (dispatch, payload) => {
  dispatch(asyncActions(SEND_MESSAGE).loading(true));
  return axios({
    method: "post",
    url: messageURL.SEND_MESSAGE_URL,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(SEND_MESSAGE).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(SEND_MESSAGE).success(data));
        dispatch(asyncActions(SEND_MESSAGE).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(SEND_MESSAGE).loading(false));
      dispatch(asyncActions(SEND_MESSAGE).failure(true, error.response));
      toast.error("An error occurred");
      return error.response;
    });
};
