import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Swal from "sweetalert2";
import { FiPlus, FiPaperclip, FiSmile, FiNavigation } from "react-icons/fi";
import classes from "./Chat.Module.scss";
import ChatList from "./Components/ChatList/ChatList";
import Layout from "src/components/Layout/CustomLayout";
import SearchBox from "./Components/SearchBox/SearchBox";
import { UploadFile } from "src/requests/fileUploadRequest";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import AlumniList from "./Components/AlumniList/AlumniList";
import CreateGroupChat from "./Components/CreateGroupChat/index";
import MobileChatBox from "./Components/MobileChatBox";
// import groupMemberAvatarAlt from "src/modules/Alumni/assets/avatar-img-alt.png";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import AddChatAttachment from "./Components/AddChatAttachments/index";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { getUserHub } from "src/requests/AlumniHubRequest";
import { Context } from "src/store";
import { getHubUsers } from "./helper/getHubUsers";
import { HiUserAdd, HiDotsVertical } from "react-icons/hi";
import AdUserToGroup from "./Components/AddUserToGroup/index";
import "./chats.scss";
import MessageViewers from "./Components/MessageViewers/index";
// import EditorBox from "src/components/Editor/EditorBox";
import ViewGroupMembers from "./Components/ViewGroupMembers/index";
import { HubConnectionBuilder } from "@microsoft/signalr";

import {
  getChatBookmarks,
  getUserGroups,
  getPersonalMessageHistory,
  getGroupMessageHistory,
  getGroupUsers,
  markAsRead,
  restrictPosting,
  getMessageViewers,
  fromUserFromGroup,
  markAllMessageAsRead,
} from "src/requests/AlumniChatRequest";
import Chatubble from "./Components/ChatBubble/index";
import _ from "lodash";
import moment from "moment";
// import { AlumniRequestURL } from './../../../constants/index';
let copyHistory = [];
let copyDeletedChatId = "";
const Chat = () => {
  const userData = get_user_info_from_token().user;
  const [isOpen, setIsOpen] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [unReadMessageCount, setUnReadMessageCount] = useState("");
  const [chatMessage, setChatMessage] = useState("");
  const [file, setFile] = useState([]);
  const [text, setText] = useState("");
  const emojiRef = useRef(null);
  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
  const [hubUsers, setHubUsers] = useState([]);
  const [chatMessageViwers, setChatMessageViwers] = useState([]);
  const [filteredHubUsers, setFilteredHubUsers] = useState([]);
  const [filteredMessageViewers, setfilteredMessageViewers] = useState([]);
  const [personalChat, setPersonalChat] = useState([]);
  const [groupChat, setGroupChat] = useState([]);
  const [, setDeleteChatId] = useState("");
  const [reload, setReload] = useState(false);
  const [searchByName, setSearchByName] = useState("");
  const [isloadingMessageViewers, setIsloadingMessageViewers] = useState(false);
  const { dispatch } = useContext(Context);
  const [isloadingHubUsers, setIsLoadingHubUsers] = useState(true);

  const [isAddUserToGroupModalOpen, setIsAddUserToGroupModalOpen] = useState(
    false
  );
  const [isMembersModalOpen, setIsMembersModalOpenn] = useState(false);
  const [chatBookmarks, setChatBookmarks] = useState([]);
  const [filteredChatBookmarks, setFilteredChatBookmarks] = useState([]);
  const [searchByChat, setSearchByChat] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenMobile, setDropdownOpenMobile] = useState(false);
  const [isMessageViewersModalOpen, setIsMessageViewersModalOpen] = useState(
    false
  );
  const [currentChat, setCurrentChat] = useState(null);
  const [connection, setConnection] = useState(null);
  const [chatBoxOpen, setChatBoxOpen] = useState(false);
  const [currentChatHistory, setCurrentChatHistory] = useState([]);
  const [, setIsLoadingCurrentChatHistory] = useState(false);
  const [groupTitle, setGroupTitle] = useState("Alumni Member");
  const pageDetails = {
    firstItemOnPage: 1,
    hasNextPage: true,
    hasPreviousPage: false,
    lastItemOnPage: 0,
    pageCount: 0,
    pageNumber: 1,
    pageSize: 5,
    totalItemCount: 0,
  };
  const [bookmarkChatPageDetails, setBookmarkChatPageDetails] = useState({
    ...pageDetails,
  });

  const toggleAddUserToGroupModalOpen = () => {
    setIsAddUserToGroupModalOpen(!isAddUserToGroupModalOpen);
  };

  const chatBubbleBox = document.querySelector(".current-chat_scroll");
  if (chatBubbleBox) {
    chatBubbleBox.scrollTop = chatBubbleBox?.scrollHeight;
  }
  const toggleMembersModal = () => {
    setIsMembersModalOpenn(!isMembersModalOpen);
  };

  const toggleMessageViewer = () => {
    setIsMessageViewersModalOpen(!isMessageViewersModalOpen);
  };

  const toggleAttachmantModal = () => {
    setIsAttachmentModalOpen(!isAttachmentModalOpen);
  };
  const isGroupAdmin = () => currentChat?.createdBy === userData.id;

  onClickOutside(emojiRef, () => setShowEmojis(!showEmojis));

  const addEmoji = (e) => {
    const emoji = e.native;
    setText((prevText) => prevText + emoji);
    setShowEmojis(false);
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const onDrop = (picture) => {
    const existingFiles = [...file];

    existingFiles.push(...picture);
    const uniqueFile = _.uniqBy(existingFiles, "name");

    setFile(uniqueFile);

    console.log("pictures", picture);
  };

  const _getGroupUser = (id) => {
    setIsLoadingHubUsers(true);
    if (currentChat?.isGroupChat)
      getGroupUsers(id).then((res) => {
        const {
          data: { data },
        } = res.data;

        setGroupTitle("Group Members");
        setIsLoadingHubUsers(false);
        setHubUsers(data);
      });
  };

  const leaveGroupChat = () => {
    Swal.fire({
      title: "Leave this group?",

      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#da291c",
      cancelButtonColor: "#eddcd2",
      confirmButtonText: "Remove",
      // iconHtml: "&#128683;",
      customClass: {
        icon: "swal-icon",
        confirmButton: "swal-confirm",
        cancelButton: "swal-cancel",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const param = {
          groupId: currentChat.withId,
          userIds: [userData.id],
        };
        fromUserFromGroup(param).then((res) => {
          Swal.fire("Removed", "Users has been removed from group", "success");
          toggleMembersModal();
          refreshPage();
        });
      }
    });
  };
  const _getMessageViewers = (lastMessageId) => {
    setIsloadingMessageViewers(true);

    if (currentChat.isGroupChat)
      getMessageViewers(lastMessageId).then((res) => {
        let {
          data: { data },
        } = res.data;

        data = data.map((user) => {
          return {
            user,
          };
        });
        setIsloadingMessageViewers(false);
        setChatMessageViwers(data);
      });
  };

  useEffect(() => {
    if (chatBubbleBox) {
      chatBubbleBox.scrollTop = chatBubbleBox?.scrollHeight;
    }
  }, [currentChatHistory]);

  // chatBubbleBox.scroll(0, 0);

  const submitChatWithImage = (e) => {
    e.preventDefault();
    if (file.length > 0) {
      UploadFile(dispatch, true, file)
        .then((res) => {
          if (res && res.status === 200) {
            const file = res.files[0];
            if (currentChat.isGroupChat) {
              connection.invoke(
                "SendMessageToGroup",
                currentChat.withId,
                chatMessage,
                [file]
              );
            } else {
              connection.invoke(
                "SendMessageToUser",
                currentChat.withId,
                chatMessage,
                [file]
              );
            }

            setChatMessage("");
            setIsAttachmentModalOpen(!isAttachmentModalOpen);
          }
        })
        .catch(() => {
          console.log("something went wrong");
        });
    }
  };
  const sendPublicMessage = () => {
    connection.invoke("SendMessageToGroup", currentChat.withId, text, []);
    setText("");
  };
  const sendPersonalMessage = () => {
    connection.invoke("SendMessageToUser", currentChat.withId, text, []);
    setText("");
  };

  // function to create a new message
  const handleSubmit = (e) => {
    e.preventDefault();
    if (text) {
      if (currentChat.isPersonalChat) {
        sendPersonalMessage();
      } else {
        sendPublicMessage();
      }
    }

    // setReload((prev) => !prev);
  };

  const _getHubUsers = getHubUsers(
    dispatch,
    userData,
    setHubUsers,
    setIsLoadingHubUsers
  );

  // TODO: backend needs to remove user hubs from this endpoint
  const _getUserGroups = () => {
    getUserGroups(dispatch)
      .then((response) => {
        console.log("response", response);
      })
      .catch((getuserGroupError) => {
        console.log("getuserGroupError >>>", getuserGroupError);
      });
  };

  const _markAsRead = (payload) => {
    markAsRead(dispatch, payload)
      .then((response) => {
        refreshPage();
      })
      .catch((getuserGroupError) => {
        console.log("something went wrong >>>", getuserGroupError);
      });
  };

  const _restrictPosting = () => {
    const chatGroupId = currentChat.withId;
    const payload = {
      groupId: [chatGroupId],
      restrict: !currentChat.isRestricted,
    };

    restrictPosting(payload)
      .then((response) => {
        refreshPage();
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (currentChat?.isGroupChat) {
      _getGroupUser(currentChat?.withId);
    }
  }, [currentChat?.withId]);

  // function to create new hub connection
  const createHubConnection = () => {
    const hubConnection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_ALUMNI_CHAT_URL, {
        accessTokenFactory: () => get_user_info_from_token().token,
      })
      .withAutomaticReconnect()
      .build();
    setConnection(hubConnection);

    hubConnection
      .start()

      .then(() => console.log("connectionstate", hubConnection.state))
      .catch((error) => {
        console.log("Error establishing connection", error);
      });
    hubConnection?.on("PersonalMessage", (data, err) => {
      console.log("heyey", data, moment(data.createdOn).from());

      const currentChat = {
        fromUser: data.senderId,
        toUser: data.receieverId,
        message: data.message,
        imageUrl: data.imageUrl,
        createdOn: data.createdOn,
        attachment: data.attachments,
        messageId: data.messageId,
        senderName: data.senderName,
        withId: data.messageId,
        senderProfile: {
          imageUrl: data.senderImageUrl,
          fullName: data.senderName,
          userId: data.senderId,
        },
        receiverProfile: {
          imageUrl: data.receiverImageUrl,
          fullName: data.receiverName,
          userId: data.receiverId,
        },
      };

      const newArr = [...copyHistory, currentChat];
      // refreshPage();
      setCurrentChatHistory(newArr);
      copyHistory = newArr;
    });

    hubConnection?.on("ReceiveMessage", (data) => {
      console.log("reseses", data);
      const currentChat = {
        fromUser: data.senderId,
        message: data.message,
        createdOn: data.createdOn,
        attachment: data.attachments,
        messageId: data.messageId,
        withId: data.messageId,
        senderProfile: {
          userId: data.senderId,
          fullName: data.senderName,
          imageUrl: data.imageUrl,
        },
        receiverProfile: {
          imageUrl: "",
          fullName: "",
          userId: "",
        },
      };

      const newArr = [...copyHistory, currentChat];

      setCurrentChatHistory(newArr);
      copyHistory = newArr;
      if (chatBubbleBox) {
        chatBubbleBox.scrollTop = chatBubbleBox?.scrollHeight;
      }
    });
    hubConnection.on("PersonalMessageDeleted", (data) => {
      if (data.deleted) {
        const newCopy = copyHistory.filter(
          (chat) => chat.id !== copyDeletedChatId
        );
        setCurrentChatHistory(newCopy);
        copyHistory = newCopy;
      }
    });
    hubConnection.on("GroupMessageDeleted", (data) => {
      if (data.deleted) {
        const newCopy = copyHistory.filter(
          (chat) => chat.id !== copyDeletedChatId
        );
        setCurrentChatHistory(newCopy);
        copyHistory = newCopy;
      }
    });
  };
  useEffect(() => {});

  // function to stop hub connection
  const stopHubConnection = () => {
    connection.stop();
  };
  useEffect(() => {
    if (isMessageViewersModalOpen) {
      _getMessageViewers(currentChat?.lastMessageId);
    }
  }, [isMessageViewersModalOpen]);

  // function to get group chat history
  const _getGroupMessageHistory = (chatId) => {
    setIsLoadingCurrentChatHistory(true);

    markAllMessageAsRead(chatId);
    if (currentChat.isGroupChat) {
      getGroupMessageHistory(dispatch, chatId)
        .then((response) => {
          const {
            data: { data },
          } = response.data;

          copyHistory = data.slice();
          const readMessage = copyHistory
            .filter((msg) => msg.isRead === false)
            .map((msg) => msg.id);
          setCurrentChat({
            ...currentChat,
            lastMessageId: data[data.length - 1]?.id,
          });
          setCurrentChatHistory(data);
          if (readMessage.length > 0) {
            _markAsRead(readMessage);
          }
        })
        .catch((getGroupMessageHistoryError) => {})
        .finally(() => setIsLoadingCurrentChatHistory(false));
    } else {
      getPersonalMessageHistory(dispatch, chatId)
        .then((response) => {
          const {
            data: { data },
          } = response.data;

          copyHistory = data.slice();

          const readMessage = copyHistory
            .filter((msg) => msg.isRead === false)
            .map((msg) => msg.id);

          setCurrentChatHistory(data);
          if (readMessage.length > 0) {
            _markAsRead(readMessage);
          }
        })
        .catch((getGroupMessageHistoryError) => {})
        .finally(() => setIsLoadingCurrentChatHistory(false));
    }
  };

  const handleSearch = (e) => {
    const str = e.target.value;
    setSearchByName(str);
  };
  const handleSearchByChat = (e) => {
    const str = e.target.value;
    setSearchByChat(str);
  };

  const nameFilter = (data) => {
    return data.filter((ar) => {
      return (
        ar.user?.firstName
          .toLowerCase()
          .trim()
          .includes(searchByName.toLowerCase().trim()) ||
        ar.user?.lastName
          .toLowerCase()
          .trim()
          .includes(searchByName.toLowerCase().trim())
      );
    });
  };
  useEffect(() => {
    const filteredHub = nameFilter(hubUsers);
    setFilteredHubUsers(filteredHub);
  }, [searchByName, hubUsers, reload]);
  useEffect(() => {
    const viewers = nameFilter(chatMessageViwers);
    setfilteredMessageViewers(viewers);
  }, [searchByName, chatMessageViwers]);

  useEffect(() => {
    const filteredChat = chatBookmarks
      .filter((x) => x.withId)
      .filter((ar) => {
        return (
          ar.name
            .toLowerCase()
            .trim()
            .includes(searchByChat.toLowerCase().trim()) ||
          ar.lastMessage
            .toLowerCase()
            .trim()
            .includes(searchByChat.toLowerCase().trim())
        );
      });
    setFilteredChatBookmarks(filteredChat);
  }, [searchByChat, chatBookmarks]);

  const getChatList = async () => {
    const [bookmarks, userGroup] = await Promise.all([
      getChatBookmarks(dispatch, 1),
      getUserGroups(dispatch),
    ]);
    const {
      data: { data: bookMarkedData, pager },
    } = bookmarks.data;
    const { data: userGroupData } = userGroup.data;

    const chatGroups = [...bookMarkedData];
    if (bookMarkedData.length || userGroupData.length) {
      const defaultActiveChat = bookMarkedData[0] || bookMarkedData[0] || {};
      //  setCurrentChat(defaultActiveChat);
      if (!currentChat) {
        setCurrentChat(defaultActiveChat);
      }
    }
    const messageCount = bookMarkedData.reduce(
      (sum, curr) => sum + curr.unread,
      0
    );

    setUnReadMessageCount(messageCount);
    // const isGroupChat = chatGroups.filter((item) => item.isGroupChat === true);
    const isPersonalChat = chatGroups.filter(
      (item) => item.isPersonalChat === true
    );
    setPersonalChat(isPersonalChat);
    setGroupChat(userGroupData);
    setChatBookmarks(chatGroups);
    setBookmarkChatPageDetails(pager);
  };

  const switchHubUser = (id) => {
    const foundUser = hubUsers.find((user) => user.userId === id);
    const currentUser = {};
    currentUser.withId = foundUser.userId;
    currentUser.isGroupChat = false;
    currentUser.isPersonalChat = true;
    currentUser.name = foundUser.user.firstName;
    currentUser.imageUrl = foundUser.user.imageUrl;

    setCurrentChat(currentUser);
  };

  const switchDefaultActiveChat = (id, isMobile = false) => {
    const group = chatBookmarks.find((group) => group.withId === id);
    if (group.isGroupChat === false && group.isPersonalChat === false) {
      group.isGroupChat = true;
    }
    if (isMobile) {
      toggleChatBox();
    }

    setCurrentChat(group);
  };
  const switchDefaultGroupChat = (id, isMobile = false) => {
    const group = groupChat.find((group) => group.id === id);

    group.isGroupChat = true;
    if (isMobile) {
      toggleChatBox();
    }
    setCurrentChat({ ...group, withId: group.id });

    if (group.isGroup) {
      _getGroupUser(group.id);
    } else {
      getUserHubList(group.id);
    }
  };
  const refreshPage = () => setReload((prev) => !prev);
  const getUserHubList = (id) => {
    setIsLoadingHubUsers(true);
    _getHubUsers(id);
    setGroupTitle("Alumni Members");
    setIsLoadingHubUsers(false);
  };

  const removeChat = (id) => {
    copyDeletedChatId = id;

    connection.invoke("DeleteChatMessage", id);

    setDeleteChatId(id);
  };

  // connect to signalr

  useEffect(() => {
    createHubConnection();
    return () => {
      if (connection !== null) {
        stopHubConnection();
      }
    };
  }, []);

  useEffect(() => {
    getChatList();
  }, [reload]);
  useEffect(() => {
    if (currentChat && currentChat.withId) {
      _getGroupMessageHistory(currentChat.withId);
    }
  }, [currentChat?.withId]);

  // get user hub and hub members
  useEffect(() => {
    getUserHub(dispatch)
      .then((response) => {
        const activeHub = response.data.data.data.find(
          (hub) => hub.isCurrent === true
        );

        _getHubUsers(activeHub.id);
      })
      .catch((getUserHubError) => console.log(getUserHubError))
      .finally(() => setIsLoadingHubUsers(false));
  }, []);

  useEffect(() => {
    _getUserGroups();
  }, []);

  const toggleChatBox = () => setChatBoxOpen((prev) => !prev);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleGroupMemberMobile = () => {
    setDropdownOpenMobile((prevState) => !prevState);
  };

  // useEffect(() => {
  //   _markAsRead(readMessage);
  // }, readMessage.length);

  function onClickOutside(emojiRef, handler) {
    useEffect(() => {
      const listener = (event) => {
        if (!emojiRef.current || emojiRef.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [emojiRef, handler]);
  }

  const renderChatTextareaFunc = () => {
    return (
      <div className={classes["current-chat__action"]}>
        <div className={classes["current-chat__action-left"]}>
          {showEmojis ? (
            <span style={styles.emojiPicker} ref={emojiRef}>
              <Picker
                onSelect={addEmoji}
                emojiTooltip={true}
                showPreview={false}
                title=""
              />
            </span>
          ) : (
            <>
              <span
                style={styles.getEmojiButton}
                onClick={() => setShowEmojis(true)}
              >
                <FiSmile />
              </span>
              <span
                style={{ fontSize: 24, cursor: "pointer" }}
                onClick={toggleAttachmantModal}
              >
                <FiPaperclip />
              </span>
            </>
          )}
        </div>
        <div className={classes["current-chat__action-input__box"]}>
          <form onSubmit={handleSubmit}>
            <textarea
              className="form-control"
              style={{ resize: "none" }}
              value={text}
              onChange={handleChange}
              placeholder="Type Message"
            ></textarea>
          </form>
          {/* <EditorBox useEmoji={true} onEditorChange={handleChange} /> */}
        </div>
        <div
          className={classes["current-chat__action-right"]}
          onClick={handleSubmit}
        >
          <FiNavigation />
        </div>
      </div>
    );
  };
  const handleLoadMore = (pageNumber) => {
    getChatBookmarks(dispatch, pageNumber).then((res) => {
      const {
        data: { data, pager },
      } = res.data;
      const messageCount = data.reduce((sum, curr) => sum + curr.unread, 0);

      setUnReadMessageCount(messageCount);
      const isPersonalChat = data.filter(
        (item) => item.isPersonalChat === true
      );
      const allBookmarkChats = [...chatBookmarks, ...data];

      setPersonalChat([...personalChat, ...isPersonalChat]);
      setChatBookmarks(allBookmarkChats);
      setBookmarkChatPageDetails(pager);
    });
  };

  const showChatTextarea = () => {
    if (currentChat && currentChat.isGroupChat && !currentChat.isRestricted) {
      return renderChatTextareaFunc();
    } else if (
      currentChat &&
      currentChat.isGroupChat &&
      currentChat.isRestricted === true &&
      isGroupAdmin()
    ) {
      return renderChatTextareaFunc();
    } else if (currentChat && currentChat.isGroupChat === false) {
      return renderChatTextareaFunc();
    } else {
      return null;
    }
  };
  return (
    <CreateGroupChat
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(true)}
      secondaryButtonAction={() => setIsOpen(false)}
      refreshPage={refreshPage}
    >
      <MobileChatBox
        isOpen={chatBoxOpen}
        setIsOpen={() => setIsOpen(true)}
        secondaryButtonAction={() => setIsOpen(false)}
        currentChatHistory={currentChatHistory}
        dropdownOpenMobile={dropdownOpenMobile}
        showEmojis={showEmojis}
        handleSubmit={handleSubmit}
        toggleMembersModal={toggleMembersModal}
        removeChat={removeChat}
        currentChat={currentChat}
        toggleAddUserToGroupModalOpen={toggleAddUserToGroupModalOpen}
        classes={classes}
        toggleGroupMemberMobile={toggleGroupMemberMobile}
        styles={styles}
        emojiRef={emojiRef}
        addEmoji={addEmoji}
        handleChange={handleChange}
        text={text}
        setShowEmojis={setShowEmojis}
        toggleAttachmantModal={toggleAttachmantModal}
        toggleChatBox={toggleChatBox}
        isGroupAdmin={isGroupAdmin}
        toggleMessageViewer={toggleMessageViewer}
        leaveGroupChat={leaveGroupChat}
        restrictPosting={_restrictPosting}
        showChatTextarea={showChatTextarea}
        renderChatTextareaFunc={renderChatTextareaFunc}
      />

      <Layout hideRight heading={null}>
        <div className={classes["chat-wrapper"]}>
          <Row className="no-gutters" style={{ height: "80vh" }}>
            <Col
              xs={12}
              md={3}
              className="py-4 bg-light"
              style={{ background: "#F6F8FC !important" }}
            >
              <div className="px-3">
                <SearchBox
                  placeholder="search"
                  handleSearch={handleSearchByChat}
                />
                <div className="d-flex align-items-center justify-content-between my-4">
                  <h5 className={classes["unread-mesage__count"]}>
                    <span className={classes["unread-mesage__count-text"]}>
                      Messages
                    </span>
                    {unReadMessageCount > 1 && (
                      <span className={classes["unread-mesage__count-number"]}>
                        {unReadMessageCount}
                      </span>
                    )}
                  </h5>
                  <Button
                    color="secondary"
                    style={{ fontSize: 13 }}
                    onClick={() => setIsOpen(true)}
                  >
                    <FiPlus /> New Group Chat
                  </Button>
                </div>
              </div>
              <ChatList
                chatBookmarks={filteredChatBookmarks}
                currentChat={currentChat}
                switchDefaultActiveChat={switchDefaultActiveChat}
                switchDefaultGroupChat={switchDefaultGroupChat}
                groupChat={groupChat}
                personalChat={personalChat}
                handleSearchByChat={handleSearchByChat}
                handleLoadMore={handleLoadMore}
                mobile="desktop-chat-list"
                bookmarkChatPageDetails={bookmarkChatPageDetails}
              />
              <ChatList
                chatBookmarks={filteredChatBookmarks}
                currentChat={currentChat}
                switchDefaultActiveChat={(id) =>
                  switchDefaultActiveChat(id, true)
                }
                switchDefaultGroupChat={(id) =>
                  switchDefaultGroupChat(id, true)
                }
                groupChat={groupChat}
                personalChat={personalChat}
                handleSearchByChat={handleSearchByChat}
                mobile="mobile-chat-list"
                toggleChatbox={toggleChatBox}
                handleLoadMore={handleLoadMore}
                bookmarkChatPageDetails={bookmarkChatPageDetails}
              />
            </Col>
            <Col
              md={6}
              className="border-right border-left h-100 mobile-chat-body"
            >
              <div className={classes["chat-widget__body"]}>
                <div className={classes["current-chat__info"]}>
                  <div className="d-flex align-items-center">
                    <div className={classes["current-chat__image"]}>
                      {currentChat &&
                        (currentChat?.imageUrl || currentChat?.bannerUrl) && (
                          <img
                            src={
                              currentChat?.imageUrl || currentChat?.bannerUrl
                            }
                            alt=""
                          />
                        )}
                    </div>
                    <h5 className={classes["current-chat__name"]}>
                      {currentChat && currentChat?.name}
                    </h5>
                  </div>
                  <div className="d-flex align-items-center">
                    <div
                      style={{ fontSize: 24, cursor: "pointer" }}
                      onClick={toggleAddUserToGroupModalOpen}
                    >
                      {currentChat?.isGroup && (
                        <HiUserAdd className="mr-2" color={"#3a3838"} />
                      )}
                    </div>
                    {currentChat && currentChat.isGroupChat && (
                      <div>
                        <Dropdown
                          isOpen={dropdownOpen}
                          toggle={toggle}
                          style={{ overflow: "initial" }}
                          className="float-none"
                        >
                          <DropdownToggle color="white">
                            <HiDotsVertical />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>
                              <div
                                onClick={() => toggleAddUserToGroupModalOpen()}
                              >
                                Add Group Members
                              </div>
                            </DropdownItem>

                            <DropdownItem>
                              <div onClick={toggleMembersModal}>
                                Remove Members
                              </div>
                            </DropdownItem>
                            <DropdownItem>
                              <div onClick={leaveGroupChat}>Leave Group</div>
                            </DropdownItem>

                            {currentChat?.isGroupChat && isGroupAdmin() && (
                              <DropdownItem>
                                <div onClick={_restrictPosting}>
                                  {currentChat.isRestricted
                                    ? "unRestrict Posting"
                                    : "Restrict Posting"}
                                </div>
                              </DropdownItem>
                            )}
                            {currentChat?.isGroupChat && isGroupAdmin() && (
                              <DropdownItem>
                                <div onClick={toggleMessageViewer}>
                                  Messages Viewers
                                </div>
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={`${classes["current-chat__messages"]} current-chat_scroll`}
                >
                  <Chatubble
                    chatHistory={currentChatHistory}
                    removeChat={removeChat}
                  />
                </div>

                {showChatTextarea()}
              </div>
            </Col>
            <Col md={3} className="mobile-chat-body">
              <AlumniList
                hubUsers={filteredHubUsers}
                isloadingHubUsers={isloadingHubUsers}
                switchDefaultActiveChat={switchHubUser}
                groupChat={groupChat}
                personalChat={personalChat}
                groupTitle={groupTitle}
                handleSearch={handleSearch}
                showSelectUser={false}
                readonly={true}
                currentChat={currentChat}
                isGroupAdmin
                toggleAddUserToGroupModalOpen={toggleAddUserToGroupModalOpen}
              />
            </Col>
          </Row>
        </div>

        {/* add groupchat attachment modal */}
        <AddChatAttachment
          toggleAttachmantModal={toggleAttachmantModal}
          isAttachmentModalOpen={isAttachmentModalOpen}
          chatMessage={chatMessage}
          submitChatWithImage={submitChatWithImage}
          onDrop={onDrop}
          attachnmentInputChange={(e) => setChatMessage(e.target.value)}
        />

        {/* add user to group modal */}
        <AdUserToGroup
          selectedGroupId={currentChat?.id}
          refreshPage={refreshPage}
          isAddUserToGroupModalOpen={isAddUserToGroupModalOpen}
          toggleAddUserToGroupModalOpen={toggleAddUserToGroupModalOpen}
          groupUsers={hubUsers}
          isMobile="desktop-add-member"
        />
        <AdUserToGroup
          selectedGroupId={currentChat?.id}
          refreshPage={refreshPage}
          isAddUserToGroupModalOpen={isAddUserToGroupModalOpen}
          toggleAddUserToGroupModalOpen={toggleAddUserToGroupModalOpen}
          groupUsers={hubUsers}
          isMobile="mobile-add-member"
        />

        {/* view group memnbers modal */}
        <ViewGroupMembers
          isMembersModalOpen={isMembersModalOpen}
          toggleMembersModal={toggleMembersModal}
          hubUsers={filteredHubUsers}
          isloadingHubUsers={isloadingHubUsers}
          switchDefaultActiveChat={switchHubUser}
          groupChat={groupChat}
          personalChat={personalChat}
          groupTitle={groupTitle}
          handleSearch={handleSearch}
          selectedGroupId={currentChat?.id}
          refreshPage={refreshPage}
          currentChat={currentChat}
          isGroupAdmin={isGroupAdmin}
          readonly={false}
        />
        <MessageViewers
          isMembersModalOpen={isMessageViewersModalOpen}
          toggleMembersModal={toggleMessageViewer}
          hubUsers={filteredMessageViewers}
          isloadingHubUsers={isloadingMessageViewers}
          switchDefaultActiveChat={switchHubUser}
          groupChat={groupChat}
          personalChat={personalChat}
          groupTitle={groupTitle}
          handleSearch={handleSearch}
          showTitle={false}
        />
      </Layout>
    </CreateGroupChat>
  );
};

export default ifOnboardedThenShow(Chat);

const styles = {
  container: {
    padding: 20,
    borderTop: "1px #4C758F solid",
    marginBottom: 20,
  },
  form: {
    display: "flex",
  },
  input: {
    color: "inherit",
    background: "none",
    outline: "none",
    border: "none",
    flex: 1,
    fontSize: 16,
  },
  getEmojiButton: {
    cssFloat: "right",
    border: "none",
    margin: 0,
    marginRight: 20,
    fontSize: 24,
    cursor: "pointer",
  },
  emojiPicker: {
    position: "absolute",
    bottom: 0,
    left: 0,
    cssFloat: "left",
    marginLeft: "0px",
  },
};
