import React, { useRef, useEffect } from "react";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";

const style = { height: 40, backgroundColor: "#f0f0f0" };

const InputField = ({
  title,
  type,
  isGrouped,
  isRequired,
  name,
  id,
  value,
  onChange,
  placeholder,
  autoFocus,
  invalid,
  errorMsg,
  options,
  inputClassName,
  labelClassName,
  inputStyle,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (autoFocus && ref.current) ref.current.focus();
  }, []);
  return (
    <FormGroup tag={isGrouped ? "fieldset" : undefined}>
      {title && (
        <Label className={labelClassName} for="exampleEmail">
          {title}
          {isRequired && (
            <span className="text-danger text-sm" style={{ fontSize: 10 }}>
              {" "}
              -Required
            </span>
          )}
          {isRequired === false && (
            <span className="text-muted text-sm" style={{ fontSize: 10 }}>
              {" "}
              -Optional
            </span>
          )}
        </Label>
      )}
      {isGrouped ? (
        <>
          {options &&
            options.length > 0 &&
            options.map((option, i) => (
              <FormGroup key={`${i}_${name}`} check>
                <Label style={{ fontWeight: 400, color: "#555" }} check>
                  <Input
                    type={type || "text"}
                    name={name}
                    value={option.value}
                    onChange={onChange}
                  />{" "}
                  {option.label}
                </Label>
              </FormGroup>
            ))}
        </>
      ) : (
        <Input
          type={type || "text"}
          style={{ ...style, ...(inputStyle || {}) }}
          name={name}
          id={id}
          innerRef={ref}
          className={inputClassName}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          invalid={invalid || false}
        >
          {options &&
            options.length > 0 &&
            options.map((option, i) => (
              <option key={i} value={option.value}>
                {option.label}
              </option>
            ))}
        </Input>
      )}
      {errorMsg && invalid === true && (
        <FormFeedback>You will not be able to see this</FormFeedback>
      )}
    </FormGroup>
  );
};

export default InputField;
