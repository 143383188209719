import React from "react";
import { useTranslation } from "react-i18next";
import "./_sectionContainer.scss";
export const SectionContainer = ({
  title,
  subTitle,
  children,
  titleClassName,
  subTitleClassName,
  useTitleTags,
  useSubTitleTags,
}) => {
  const { t } = useTranslation();
  return (
    <div className="section-container pad">
      <div className="section-container-heading" style={{ marginLeft: "9px" }}>
        {title &&
          (useTitleTags ? (
            title
          ) : (
            <h1 className={titleClassName || ""}>{t(`${title}`)}</h1>
          ))}
        {subTitle &&
          (useSubTitleTags ? (
            subTitle
          ) : (
            <span className={subTitleClassName || ""}>{t(`${subTitle}`)}</span>
          ))}
      </div>
      {children}
    </div>
  );
};

SectionContainer.defaulProps = {
  useTitleTags: false,
  useSubTitleTags: false,
};
