import React from "react";
import { Card, Button, CardTitle, Row, Col } from "reactstrap";
import MentorHeaderIcon from "src/assets/img/header/mentorship.svg";
import "./BannerCard.scss";
const BannerCard = ({ setCurrentTab, role, isMentor }) => {
  return (
    <Card className="banner-card" body>
      <Row>
        <Col md="6">
          {isMentor ? (
            <CardTitle className="banner-heading">
              <span className="heading-red">Guide and share </span> your
              experience <br />
              with African Entrepreneurs
            </CardTitle>
          ) : (
            <CardTitle className="banner-heading">
              <span className="heading-red">Learn</span> valuable skills from{" "}
              {"TEF's pool of experts"}
            </CardTitle>
          )}

          <Row>
            {role === "Entrepreneur" ? (
              <Button
                color="secondary"
                onClick={() => setCurrentTab("Find A Mentor")}
                className="header-button-red"
              >
                Find a mentor
              </Button>
            ) : (
              <Button
                color="secondary"
                onClick={() => setCurrentTab("Find A Mentee")}
                className="header-button-red"
              >
                Find a mentee
              </Button>
            )}
            {/* <Button className="header-button-red">Become a mentor</Button> */}
          </Row>
        </Col>
        <Col md="6" className="d-none d-md-block">
          <img
            style={{ width: "100%" }}
            src={MentorHeaderIcon}
            className="mentor-svg "
            alt=""
          />
        </Col>
      </Row>
    </Card>
  );
};

BannerCard.propTypes = {};

export default BannerCard;
