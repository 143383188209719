import { asyncActionName } from "src/utils/AsyncUtils";
import { SEND_FEEDBACK, SEND_DATA_ACCESS_RIGHT } from "src/actions";
import { initialState } from "src/store";

const FeedbackReducer = (state = initialState.feedback, { type, payload }) => {
  switch (type) {
    case asyncActionName(SEND_FEEDBACK).loading:
      return { ...state, loading: payload };
    case asyncActionName(SEND_FEEDBACK).success:
      return {
        ...state,
        feedback: payload,
        success: true,
      };
    case asyncActionName(SEND_FEEDBACK).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(SEND_DATA_ACCESS_RIGHT).loading:
      return { ...state, loading: payload };
    case asyncActionName(SEND_DATA_ACCESS_RIGHT).success:
      return {
        ...state,
        dataAccess: payload,
        success: true,
      };
    case asyncActionName(SEND_DATA_ACCESS_RIGHT).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    default:
      return state;
  }
};

export default FeedbackReducer;
