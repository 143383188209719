import React, { useState, useContext } from "react";
import { Input, Label, FormGroup } from "reactstrap";
import ModalT from "src/components/Modal/Modal";
import { Context } from "src/store";
import { updateGoal } from "src/requests/GoalRequests";
import { DatePickerInput } from "rc-datepicker";

const EditGoalModal = ({ modal, className, buttonLabel, toggle, goal }) => {
  const { id } = JSON.parse(localStorage.getItem("data")).user;
  const { dispatch } = useContext(Context);
  const initialData = {
    title: goal.title,
    description: goal.description,
    dueDate: goal.dueDate,
  };
  const [data, setData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });
  const handleDateChange = (date) => setData({ ...data, dueDate: date });

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      ...data,
      userId: id,
    };

    updateGoal(dispatch, goal.id, payload).then((res) => {
      setIsLoading(false);
      if (res.status === 200) {
        toggle();
      }
    });
  };

  return (
    <ModalT
      title={"Edit Goal"}
      modal={modal}
      className={className}
      buttonLabel={buttonLabel}
      toggle={toggle}
      onSubmit={handleSubmit}
      loadingCreate={isLoading}
      buttonText="Update Goal"
    >
      <FormGroup>
        <Label htmlFor="goalTitle">Title of your goal - Required</Label>
        <Input
          type="text"
          name="title"
          id="goalTitle"
          value={data.title}
          onChange={handleChange}
          placeholder="A short title of what you want to achieve"
          required
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="goalDescription">Description - Optional</Label>
        <Input
          type="textarea"
          name="description"
          id="goalDescription"
          value={data.description}
          onChange={handleChange}
          placeholder="Give a brief overview of what you are trying to achieve "
          rows={5}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="goalDate">Due Date - Required</Label>
        <div>
          <DatePickerInput
            dateFormat="yyyy-MM-dd"
            id="goalDate"
            minDate={new Date()}
            defaultValue={data.dueDate}
            onChange={handleDateChange}
            required={true}
          />
        </div>
      </FormGroup>
    </ModalT>
  );
};

export default EditGoalModal;
