import React from "react";
import Layout from "src/components/Layout/Layout";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import ConnectionsGroups from "./ConnectionsGroups";

const ConnectionsGroupsIndex = (props) => {
  return (
    <Layout SideContent={<SidebarRight />}>
      <ConnectionsGroups />
    </Layout>
  );
};

export default ifOnboardedThenShow(ConnectionsGroupsIndex);
