import React, { useEffect } from "react";
import Layout from "src/components/Layout/Layout";
import SideRight from "./components/SideRight/SideRight";
// import MainContent from "./components/MainContent/MainContent";

import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import { refreshToken } from "src/utils/someTokenMenthods";
import queryString from "query-string";
import MainContent_v2 from "./components/MainContent/MainContent_v2";
import ProfileStatus from "./components/ProfileStatus/ProfileStatus";

const HomepageFix = (props) => {
  useEffect(() => {
    const query = props.history.location.search;
    if (query) {
      const parsedQuery = queryString.parse(query);
      if (
        Object.keys(parsedQuery).includes("redirect") ||
        Object.keys(parsedQuery).includes("Redirect")
      ) {
        refreshToken();
      }
    }
  }, [props.history.location.search]);
  return (
    <Layout
      SideContent={
        <>
          <ProfileStatus />
          <SideRight />
          {/* <Suggestion /> */}
        </>
      }
    >
      <MainContent_v2 pageName="homePage" />
    </Layout>
  );
};

export default ifOnboardedThenShow(HomepageFix);
