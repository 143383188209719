import React, { useContext, useState, useEffect } from "react";
import classes from "./reset_password_v2.module.scss";
import tefLogo from "src/assets/img/logo/logo_new.webp";
import { resetPassword } from "src/requests/AuthRequest";
import { Link, useHistory } from "react-router-dom";
import useForm from "src/utils/useForm";
import { validatePasswordResetFields } from "src/utils/Validations/validateSignup";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import {
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Spinner,
  FormFeedback,
} from "reactstrap";
import { Context } from "src/store";
import StatusModal from "src/components/StatusModal/StatusModal";
import PasswordStrengthBar from "react-password-strength-bar";
import queryString from "query-string";
import { CarouselContent } from "src/Auth/Login/loginv2";
import img1 from "src/assets/img/bg/forgot_pwd.webp";
// import img2 from "src/assets/img/onbording/mentor.webp";
import { useCarousel } from "src/utils/useCarousel";
import tefLogo_mobile from "src/assets/img/logo/logo_new_white.svg";

const slides = [
  {
    image: img1,
    title: "Be Connected!",
    paragraph:
      "Connect with entrepreneurs, mentors, and invsetors alike across and beyond Africa",
  },
];

const ResetPasswordForm_v2 = (props) => {
  const [alertDetails, setAlertDetails] = useState({
    alertMessage: "",
    alertType: "",
  });
  const { dispatch } = useContext(Context);
  const { currentIndex, snapToSlide } = useCarousel(slides.length, 3000);

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const _handleScoll = () => {
    console.log("scroll");
    const c_ss = document.getElementById("fc_ss");
    const l_ss = document.getElementById("fl_ss");
    if (c_ss) {
      const distanceTop_c_ss = c_ss.getBoundingClientRect().top;
      if (distanceTop_c_ss <= 27.859375) {
        l_ss.style.display = "none";
      } else {
        l_ss.style.display = "block";
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", _handleScoll);
    return () => window.addEventListener("scroll", _handleScoll);
  }, []);

  const dismissAlert = () => {
    setAlertDetails({ alertMessage: "", alertType: "" });
  };

  const handleSend = (e) => {
    setIsLoading(true);
    setAlertDetails({
      ...alertDetails,
      alertMessage: "",
      alertType: "",
    });

    const qs = queryString.parse(props.location.search);
    if (!qs.userId && !qs.token) {
      setIsLoading(false);
      setAlertDetails({
        ...alertDetails,
        alertMessage: "Invalid request. token and id required",
        alertType: "danger",
        buttonText: "Close",
        showButton: true,
        buttonClick: () => dismissAlert(),
      });
      return;
    }

    const userInfo = {
      password: values.password,
      userId: qs.userId,
      token: qs.token.replace(/ /g, "+"),
    };

    resetPassword(dispatch, userInfo)
      .then((res) => {
        const { status, data } = res;
        if (status !== 200) {
          const { message, errors } = data;
          setIsLoading(false);
          setAlertDetails({
            ...alertDetails,
            alertMessage: `${message}. ${errors[0].description}`,
            alertType: "danger",
            buttonText: "Close",
            showButton: true,
            buttonClick: () => dismissAlert(),
          });
        } else {
          setIsLoading(false);
          setAlertDetails({
            ...alertDetails,
            alertMessage: res.data.message,
            alertType: "success",
            buttonText: "Close",
            title: "New Password created Successfully!",
            showButton: true,
            buttonClick: () => history.replace("/"),
          });
        }
      })
      .catch((_error) => {
        setIsLoading(false);
        setAlertDetails({
          ...alertDetails,
          alertMessage: "Something went wrong, please contact support",
          alertType: "danger",
          buttonText: "Close",
          showButton: true,
          buttonClick: () => dismissAlert(),
        });
      });
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    handleSend,
    validatePasswordResetFields
  );

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  return (
    <div className={classes.container}>
      <div className={classes.formSection}>
        <div
          className={classes.logoContainter}
          style={{ marginBottom: "98px" }}
        >
          <Link to="/">
            <img src={tefLogo} alt="tef_logo" className={classes.tef_logo} />
          </Link>
        </div>

        <div className={classes.logoContainter_mobile} id="fl_ss">
          <Link to="/">
            <img
              src={tefLogo_mobile}
              alt="tef_logo"
              className={classes.tef_logo_mobile}
            />
          </Link>
        </div>

        <div className={classes.carouselSS} id="fc_ss">
          <CarouselContent
            slide={slides[currentIndex]}
            slides={slides}
            active={currentIndex}
            snapToSlide={snapToSlide}
          />
        </div>

        <div className={classes.formContainer}>
          <h2 className={classes.logintitle}>New Password</h2>
          <p className={classes.newUser}>
            Please enter a new password that is easy to remember
          </p>
          <Form onSubmit={handleSubmit}>
            <FormGroup className={`${classes.f_row} ${classes.relativeField}`}>
              <Label className={classes.formLabel}>New Password</Label>

              <Input
                className={classes.f_input}
                placeholder="Enter password"
                required
                onChange={handleChange}
                value={values.password}
                name="password"
                invalid={errors.password}
                type={showPassword ? "text" : "password"}
              />

              {errors.password && (
                <FormFeedback
                  className={classes.errorText}
                  style={{ color: "red" }}
                >
                  {errors.password}
                </FormFeedback>
              )}

              <span className={classes.hideIcon}>
                {showPassword ? (
                  <FaEyeSlash
                    onClick={toggleShowPassword}
                    style={{ opacity: "0.5" }}
                  />
                ) : (
                  <FaEye
                    onClick={toggleShowPassword}
                    style={{ opacity: "0.5" }}
                  />
                )}
              </span>
            </FormGroup>

            <FormGroup className={`${classes.f_row} ${classes.relativeField}`}>
              <Label className={classes.formLabel}>Confirm password</Label>

              <Input
                className={classes.f_input}
                placeholder="Enter password"
                required
                onChange={handleChange}
                value={values.confirmPassword}
                name="confirmPassword"
                invalid={errors.confirmPassword}
                type={showConfirmPassword ? "text" : "password"}
              />

              <span className={classes.hideIcon}>
                {showConfirmPassword ? (
                  <FaEyeSlash
                    onClick={toggleShowConfirmPassword}
                    style={{ opacity: "0.5" }}
                  />
                ) : (
                  <FaEye
                    onClick={toggleShowConfirmPassword}
                    style={{ opacity: "0.5" }}
                  />
                )}
              </span>
              {errors.confirmPassword && (
                <FormFeedback
                  className={classes.errorText}
                  style={{ color: "red" }}
                >
                  {errors.confirmPassword}
                </FormFeedback>
              )}
            </FormGroup>

            <PasswordStrengthBar password={values.password} />
            <br />

            <Button
              className={classes.loginBTN}
              disabled={isLoading}
              data-testid="reset-password-finish"
            >
              Finish{" "}
              {isLoading && (
                <Spinner
                  color="white"
                  size={5}
                  style={{ width: 15, height: 15 }}
                />
              )}
            </Button>
          </Form>
          <StatusModal
            data-testid={`status-${alertDetails.alertType}`}
            status={alertDetails.alertType}
            open={alertDetails.alertMessage && alertDetails.alertType}
            heading={alertDetails.title && alertDetails.title}
            text={alertDetails.alertMessage && alertDetails.alertMessage}
            showButton={alertDetails.showButton}
            buttonText={alertDetails.buttonText}
            onButtonClick={alertDetails.buttonClick}
            toggle={() =>
              setAlertDetails({
                alertMessage: "",
                alertType: "",
              })
            }
          ></StatusModal>
        </div>
      </div>

      {slides.map((slide, idx) => {
        if (currentIndex === idx) {
          return (
            <div
              key={idx}
              className={`${classes.carouselContainer} ${classes.fade}`}
              style={{ backgroundImage: `url(${slide.image})` }}
            >
              <div className={classes.carouselBS}>
                <CarouselContent
                  slide={slide}
                  slides={slides}
                  active={currentIndex}
                  snapToSlide={snapToSlide}
                />
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default ResetPasswordForm_v2;
