import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { getUser } from "src/requests/AuthRequest";
import { Context } from "src/store";
import Layout from "src/components/Layout/Layout";
import { Col, Card, Row } from "reactstrap";
import "./GoalDetails.scss";
import {
  UpdateGoalStatus,
  GoalMoreItem,
  GoalUpdateCard,
} from "src/modules/profile/components/Goal/goalUpdateCard";
import { IoIosMore, IoMdList } from "react-icons/io";
import {
  _truncateText,
  retrieveUserProfile,
  retrieveUserInfo,
} from "src/modules/profile/components/helper";
import LoaderSpinner from "react-loader-spinner";
import { getPersonalGoal } from "src/requests/PersonalGaolRequest";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const Loader = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingTop: "50px",
      }}
    >
      <LoaderSpinner
        type="Puff"
        color="var(--tef-red)"
        height={75}
        width={75}
      />
    </div>
  );
};

export const _getStatusColor = (status) => {
  if (status) {
    status = status.toLowerCase();
    switch (status) {
      case "not started":
        return "status-color-in-progress";
      case "under review":
        return "status-color-in-review";
      default:
        return "status-color-completed";
    }
  }
};

const GoalDetails = (props) => {
  const user = get_user_info_from_token(null, null, true).user;
  const { state, dispatch } = useContext(Context);
  const [showGoalStatusCard, setGoalStatusCard] = useState(false);
  const [showGoalMoreCard, setShowGoalMoreCard] = useState(false);
  const [loadingStatusUpdate, setLoadingStatusUpdate] = useState(false);
  const [deleteGoalLoading, setDeleteGoalLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [assignedToImage, setAssignedToImage] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [assignedTo, setAssignedTo] = useState({ firstName: "", lastName: "" });
  const [descriptionMaxLength, setDescriptionMaxLength] = useState(500);

  useEffect(() => {
    getPersonalGoal(dispatch, props.match.params.goalId);
  }, []);

  useEffect(() => {
    if (state.PersonalGoal.goal) {
      getUser(dispatch, state.PersonalGoal.goal.userId);
    }
  }, [state.PersonalGoal]);

  const isMentor = () => {
    // eslint-disable-next-line no-prototype-builtins
    return user.hasOwnProperty("tef-mentor");
  };

  useEffect(() => {
    if (state.PersonalGoal.goal) {
      const { mentees, mentors } = state.PersonalGoal.goal;

      if (mentees === null && mentors) {
        if (mentors.includes(user.id)) {
          setAssignedTo(user);
        } else {
          retrieveUserInfo(mentors[0]).then((res) => {
            setAssignedTo(res.data.data);
          });
        }
        retrieveUserProfile(mentors[0]).then((res) => {
          setAssignedToImage(res.data.data.imageUrl);
        });
      } else if (mentors == null && mentees) {
        if (mentees.includes(user.id)) {
          setAssignedTo(user);
        } else {
          retrieveUserInfo(mentees[0]).then((res) => {
            setAssignedTo(res.data.data);
          });
        }
        retrieveUserProfile(mentees[0]).then((res) => {
          setAssignedToImage(res.data.data.imageUrl);
        });
      }
    }
  }, [state.PersonalGoal.goal]);

  useEffect(() => {
    setLoadingStatusUpdate(state.PersonalGoal.requestingChangeGoalStatus);
  }, [state.PersonalGoal.requestingChangeGoalStatus]);

  useEffect(() => {
    setDeleteGoalLoading(state.PersonalGoal.requestingGoalDelete);
  }, [state.PersonalGoal.requestingGoalDelete]);

  useEffect(() => {
    if (
      state.PersonalGoal.goal &&
      state.PersonalGoal.goal.status === "deleted"
    ) {
      props.history.goBack();
    }
  }, [state.PersonalGoal.goal]);

  const _lineThrough = (status) => {
    if (status && status.toLowerCase() === "completed") {
      return "line-through";
    }
    return "";
  };

  const toggleDescriptionMaxLength = () => {
    if (descriptionMaxLength === null) {
      setDescriptionMaxLength(500);
    } else {
      setDescriptionMaxLength(null);
    }
  };

  return (
    <Layout hideRight>
      <Col md={9}>
        <div className="goal-details-breadcrumb">
          <h6 className="goal-details-breadcrumb__heading">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => props.history.push("/mentorship")}
            ></span>{" "}
          </h6>
        </div>
        <Card className="goal-details-card">
          {state.PersonalGoal.goal ? (
            <Col>
              {console.log(state.PersonalGoal.goal)}
              <Row>
                <Col md={8}>
                  <h3
                    className={`goal-details-heading ${_lineThrough(
                      state.PersonalGoal.goal && state.PersonalGoal.goal.status
                    )}`}
                  >
                    {state.PersonalGoal.goal && state.PersonalGoal.goal.title}
                  </h3>
                  <p className="goal-details-su">
                    Added by{" "}
                    <span
                      className="notranslate"
                      style={{ color: "var(--tef-red)" }}
                    >
                      Me
                    </span>{" "}
                    |{" "}
                    {state.PersonalGoal.goal &&
                      moment(state.PersonalGoal.goal.createdOn).fromNow()}
                  </p>
                </Col>
                <Col md={4}>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {state.PersonalGoal.goal.creator === user.id && (
                      <IoIosMore
                        onClick={() => {
                          setGoalStatusCard(false);
                          setShowGoalMoreCard(!showGoalMoreCard);
                        }}
                        style={{ marginLeft: "15px", cursor: "pointer" }}
                      />
                    )}
                    <GoalUpdateCard show={showGoalMoreCard}>
                      <GoalMoreItem
                        setShowCard={setShowGoalMoreCard}
                        goalId={state.PersonalGoal.goal.id}
                        loading={deleteGoalLoading}
                      />
                    </GoalUpdateCard>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <h4 className="goal-details-sub">DUE DATE</h4>
                  <p className="goal-date-due">
                    {state.PersonalGoal.goal &&
                      moment(state.PersonalGoal.goal.dueDate).format(
                        "YYYY-MM-DD"
                      )}
                  </p>
                  <p className="goal-details-sub due">
                    <span>
                      Due{" "}
                      {state.PersonalGoal.goal &&
                        moment(state.PersonalGoal.goal.dueDate).fromNow()}
                    </span>
                  </p>
                </Col>
                <Col md={4}>
                  <div style={{ width: "100%", position: "relative" }}>
                    {isMentor() && (
                      <span
                        className="change-action-button"
                        onClick={() => {
                          setGoalStatusCard(!showGoalStatusCard);
                          setShowGoalMoreCard(false);
                        }}
                      >
                        change
                      </span>
                    )}
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        marginTop: "-20px",
                      }}
                    >
                      {isMentor() && (
                        <GoalUpdateCard show={showGoalStatusCard}>
                          <UpdateGoalStatus
                            setShowCard={() => setGoalStatusCard(false)}
                            goalId={state.PersonalGoal.goal.id}
                            loading={loadingStatusUpdate}
                          />
                        </GoalUpdateCard>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <hr />
              <div>
                <h5 className="goal-description">Goal Description</h5>
                <div className="notranslate" style={{ display: "flex" }}>
                  <span style={{ marginRight: "10px" }}>
                    <IoMdList size={25} />
                  </span>
                  {state.PersonalGoal.goal &&
                    state.PersonalGoal.goal.description && (
                      <p className="goal-paragraph">
                        {_truncateText(
                          state.PersonalGoal.goal &&
                            state.PersonalGoal.goal.description,
                          descriptionMaxLength
                        )}
                      </p>
                    )}
                </div>
                <br />
                {descriptionMaxLength !== null &&
                  state.PersonalGoal.goal.description.length > 500 && (
                    <span
                      className="show-full-desc-text"
                      onClick={toggleDescriptionMaxLength}
                    >
                      Show Full Description
                    </span>
                  )}
                {descriptionMaxLength == null && (
                  <span
                    className="show-full-desc-text"
                    onClick={toggleDescriptionMaxLength}
                  >
                    Show Less
                  </span>
                )}
              </div>
            </Col>
          ) : (
            Loader()
          )}
        </Card>
      </Col>
    </Layout>
  );
};

GoalDetails.propTypes = {};

export default GoalDetails;

// Could not open NewPollCard.js in the editor.
// The editor process exited with an error: spawn code ENOENT.

// To set up the editor integration, add something like REACT_EDITOR=atom to the .env.local file in your project folder and restart the development server. Learn more: https://goo.gl/MMTaZt
