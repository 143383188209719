import React, { useState, useContext } from "react";
import { Modal, ModalHeader, ModalBody, Input, Form } from "reactstrap";
import ClickOutside from "react-click-outside-component";
import ReactAvatar from "react-avatar";
import { FaPlus } from "react-icons/fa";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import attach from "src/assets/img/home/attachments.svg";
import "../CreatePost/CreatePostModal/CreatePostModal.scss";
import "../CreatePost/CreatePost.scss";
import { Context } from "src/store";
import { createQuestion } from "src/requests/PostRequest";
import Loader from "src/components/Loader/Loader";
import QuestionFormField from "./QuestionFormField/QuestionFormField";
import DatePicker from "react-datepicker";
import "./CreateQuestion.scss";

const CreateQuestion = ({ modal, toggle, user, dp }) => {
  const [inputFields, setInputFields] = useState(["option 1", "option 2"]);
  const [options, setOptions] = useState({});
  const [postInput, setPostInput] = useState("");
  const closeModal = true;
  const { dispatch } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");
  const currentDate = new Date();
  const [alertDetails, setAlertDetails] = useState({
    alertMessage: "",
    alertType: "",
  });
  const emojiStyleName = showEmoji ? "emoji" : "emoji hide-emoji";

  const handleInputChange = (e) => {
    setPostInput(e.target.value);
  };

  const handleOptionChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setOptions({
      ...options,
      [name]: value,
    });
  };

  const toggleEmoji = () => {
    setShowEmoji(!showEmoji);
  };

  const addEmoji = (e) => {
    const emoji = e.native;
    setPostInput(postInput + emoji);
  };

  const appendField = () => {
    if (inputFields.length < 4) {
      const newInput = `option ${inputFields.length + 1}`;
      setInputFields(inputFields.concat([newInput]));
    }
  };

  const removeField = (index) => {
    const list = [...inputFields];
    if (list.length > 2) {
      list.splice(index, 1);
      setInputFields(list);
    }
  };

  const handleExpiryDateChange = (dateString) => setExpiryDate(dateString);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const optionsObject = Object.values(options).map((value) => {
      return { content: value };
    });

    const questionInfo = {
      post: {
        content: postInput,
        accessibleBy: 0,
        expireDate: expiryDate,
      },
      options: optionsObject,
      files: [],
    };

    createQuestion(dispatch, questionInfo)
      .then((res) => {
        const { status, data } = res;
        if (status !== 200) {
          setIsLoading(false);
          const { message } = data;
          setAlertDetails({
            ...alertDetails,
            alertMessage: message,
            alertType: "danger",
          });
        } else {
          setIsLoading(false);
          setAlertDetails({
            ...alertDetails,
            alertMessage: res.data.message,
            alertType: "success",
          });

          setPostInput("");
          toggle();
        }
      })
      .catch((_error) => {
        setIsLoading(false);
        setAlertDetails({
          ...alertDetails,
          alertMessage: "Something went wrong, please contact support",
          alertType: "danger",
        });
      });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={modal && closeModal}
        toggle={toggle}
        className="create-post-modal"
      >
        <ModalHeader toggle={toggle}>
          <div className="create-post-modal__top-actions">
            <div>ASK QUESTION</div>
          </div>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <div className="create-question-div">
              {dp === null || dp === undefined || dp === "" ? (
                <ReactAvatar name={user} size={45} round={true} />
              ) : (
                <img src={dp} alt="profile" className="card-div__dp" />
              )}
              <div className="question-form">
                <Input
                  type="textarea"
                  name="post"
                  id="text-area"
                  value={postInput}
                  onChange={handleInputChange}
                  required={true}
                  className="question-textarea"
                  placeholder="What’s your question?"
                  style={{ marginLeft: "0" }}
                />
                <br />
                {inputFields.map((field, index) => (
                  <QuestionFormField
                    key={index}
                    name={field}
                    index={index}
                    remove={removeField}
                    handleChange={handleOptionChange}
                  />
                ))}
                <p className="add-field" onClick={appendField}>
                  <FaPlus /> Add more
                </p>
                <div className="expiry-date-details">
                  <label className="expiry-date-details__date">
                    Expiry date
                  </label>
                  <DatePicker
                    placeholderText="YYYY-MM-DD"
                    dateFormat="yyyy-MM-dd"
                    selected={expiryDate}
                    onChange={handleExpiryDateChange}
                    minDate={currentDate.setDate(currentDate.getDate() + 1)}
                    maxDate={currentDate.setDate(currentDate.getDate() + 2 * 7)}
                  />
                </div>
              </div>
            </div>
            <div className="create-post-modal__bottom-actions">
              <div className="icons-div">
                <span className="d-none">
                  <label htmlFor="img">
                    {" "}
                    <img src={attach} alt="attachment" />
                  </label>
                  <input
                    type="file"
                    id="img"
                    hidden
                    name="img"
                    accept="image/*"
                  />
                </span>

                <span className={emojiStyleName}>
                  <ClickOutside onClickOutside={() => setShowEmoji(false)}>
                    <Picker onSelect={addEmoji} />
                  </ClickOutside>
                </span>

                <span onClick={toggleEmoji}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                  >
                    <path
                      fill="#919BA8"
                      fillRule="evenodd"
                      d="M2.894 2.977c-3.437 3.437-3.437 9.009 0 12.446 3.436 3.436 9.008 3.436 12.445 0 3.436-3.437 3.436-9.009 0-12.446-3.437-3.436-9.009-3.436-12.445 0zm11.348 11.35c-2.83 2.83-7.421 2.83-10.252 0-2.831-2.832-2.831-7.422 0-10.253 2.831-2.832 7.421-2.832 10.252 0 2.832 2.83 2.832 7.421 0 10.252zm-1.675-3.495c.315 0 .5.342.341.615-.76 1.301-2.168 2.183-3.788 2.183-1.63 0-3.046-.893-3.803-2.21-.15-.262.053-.588.355-.588zm-.563-4.492c.53 0 .962.431.962.962 0 .532-.431.963-.962.963-.532 0-.963-.431-.963-.963 0-.53.431-.962.963-.962zm-5.775 0c.53 0 .962.431.962.962 0 .532-.431.963-.962.963-.532 0-.963-.431-.963-.963 0-.53.431-.962.963-.962z"
                    />
                  </svg>
                </span>

                <span className="d-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#919BA8"
                      fillRule="evenodd"
                      d="M6 1.5h12c2.485 0 4.5 2.015 4.5 4.5v12c0 2.485-2.015 4.5-4.5 4.5H6c-2.485 0-4.5-2.015-4.5-4.5V6c0-2.485 2.015-4.5 4.5-4.5zm0 1C4.067 2.5 2.5 4.067 2.5 6v12c0 1.933 1.567 3.5 3.5 3.5h12c1.933 0 3.5-1.567 3.5-3.5V6c0-1.933-1.567-3.5-3.5-3.5H6zM8.306 9c.803 0 1.472.201 2.007.604.535.402.889.95 1.061 1.645h-1.147c-.146-.37-.387-.665-.721-.886-.335-.22-.731-.331-1.19-.331-.42 0-.798.1-1.132.302-.335.201-.596.487-.784.857-.188.37-.282.804-.282 1.304 0 .526.096.977.287 1.353.19.377.46.664.807.862.348.198.75.297 1.21.297.56 0 1.04-.177 1.442-.53.402-.354.644-.84.726-1.456H8.115v-.818h3.393v1.061c-.077.507-.253.966-.53 1.378-.278.412-.644.741-1.1.988-.455.247-.976.37-1.562.37-.656 0-1.236-.15-1.74-.448-.503-.298-.891-.712-1.165-1.241C5.137 13.78 5 13.177 5 12.495c0-.681.137-1.287.41-1.816.275-.529.662-.94 1.162-1.236C7.072 9.148 7.65 9 8.306 9zm5.39.058v6.874h-1.09V9.058h1.09zm5.304 0v.896h-2.896v2.132h2.39v.857h-2.39v2.989h-1.089V9.058H19z"
                    />
                  </svg>
                </span>

                {/* <span className="icon-view"> */}
                {/* <svg */}
                {/* xmlns="http://www.w3.org/2000/svg" */}
                {/* width="15" */}
                {/* height="17" */}
                {/* viewBox="0 0 15 17" */}
                {/* > */}
                {/* <g fill="none" fillRule="evenodd"> */}
                {/* <path */}
                {/* fill="#FFD7BF" */}
                {/* d="M7.905.421c-2.478 0-4.483 1.72-4.483 3.846 0 2.125 2.005 3.845 4.483 3.845 1.696 0 3.07-1.72 3.07-3.845C10.974 2.14 9.601.42 7.904.42z" */}
                {/* /> */}
                {/* <path */}
                {/* fill="#4A90E2" */}
                {/* d="M7.84 9.572c-4.082 0-7.39 3.03-7.39 6.767h13.327c0-3.737-2.871-6.767-5.937-6.767z" */}
                {/* /> */}
                {/* <path */}
                {/* fill="#202841" */}
                {/* fillRule="nonzero" */}
                {/* d="M7.238 8.31c2.298 0 4.168-1.865 4.168-4.155C11.406 1.864 9.536 0 7.238 0S3.07 1.864 3.07 4.154c0 2.291 1.87 4.155 4.168 4.155zm0-7.468c1.832 0 3.323 1.486 3.323 3.313 0 1.826-1.49 3.312-3.323 3.312-1.832 0-3.323-1.486-3.323-3.312 0-1.827 1.49-3.313 3.323-3.313z" */}
                {/* /> */}
                {/* <path */}
                {/* fill="#202841" */}
                {/* d="M7.238 9.993c3.369 0 6.135 2.621 6.353 5.925H2.357v.842h11.67c.233 0 .422-.188.422-.42 0-3.964-3.235-7.189-7.21-7.189-3.977 0-7.212 3.225-7.212 7.188 0 .233.189.421.422.421h1.908v-.842H.886c.218-3.304 2.984-5.925 6.352-5.925zM7.238 6.064c.554 0 1.08-.24 1.445-.658.153-.176.135-.442-.041-.595-.176-.152-.443-.133-.596.042-.204.234-.499.368-.808.368-.309 0-.603-.134-.807-.368-.154-.175-.42-.194-.596-.042-.176.153-.195.419-.042.595.365.418.891.658 1.445.658z" */}
                {/* /> */}
                {/* </g> */}
                {/* </svg> */}
                {/* Everyone */}
                {/* </span> */}
              </div>
              <button disabled={isLoading}>
                {isLoading ? <Loader color="light" /> : "share"}
              </button>
            </div>
            {/* <div className="create-post-modal__publish"> */}
            {/* <p> */}
            {/* Publish Articles <span>on TEFConnect</span> */}
            {/* </p> */}
            {/* </div> */}
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default CreateQuestion;
