import React, { useState, useEffect, useContext } from "react";
import styles from "./NotificationNav_v2.module.scss";
import { FaRegBell } from "react-icons/fa";
import { BsChatDots } from "react-icons/bs";
import { useHistory, Link } from "react-router-dom";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { Logout } from "src/requests/logout";
import NotificationBody from "./NotificationBody";
import NotificationCounter from "./NotificationCounter";
import { getNotifications } from "src/requests/NotificationRequest";
import { Context } from "src/store";
import UserSectionHeader from "src/components/Layout/SideBar/UserSection/UserSectionHeader";
import tefLOgo from "src/assets/img/logo/logo_new.webp";
import { RiMenu3Fill } from "react-icons/ri";

const NotificationNav_v2 = ({ toggleDrawaer }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const { dispatch } = useContext(Context);
  const path = window.location.pathname;
  const history = useHistory();
  const user = useCurrentUser();

  useEffect(() => {
    getNotifications(dispatch)
      .then((res) => {
        if (res.status === 200) {
          const {
            notificationDetails,
            numberOfUnreadNotification,
          } = res.data.data;
          setNotifications(notificationDetails);
          setUnreadNotifications(numberOfUnreadNotification);
        } else {
          setNotifications([]);
          setUnreadNotifications(0);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <nav className={styles.container}>
      <div className={styles.iconContainer}>
        <Link to="/messages" className={`${styles.icon}`}>
          <BsChatDots
            size={24}
            className={path.includes("/messages") ? styles.activeIcon : ""}
          />
        </Link>

        <Link className={`${styles.icon} ${styles.bell}`} to="#">
          {unreadNotifications > 0 && (
            <NotificationCounter notificationCount={unreadNotifications} />
          )}
          <FaRegBell
            size={24}
            id="NotificationPopover"
            className={styles.NotificationNav__iconInActive}
          />
          <UncontrolledPopover
            trigger="legacy"
            placement="bottom"
            target="NotificationPopover"
          >
            <div
              className="NotificationContainer"
              style={{
                maxHeight: "70vh",
                overflowY: "scroll",
              }}
            >
              <div
                className="NotificationHeading"
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  borderBottom: "1px solid #000",
                  padding: "0.5rem 0.5rem 0 0.5rem",
                }}
              >
                Notifications
              </div>
              <PopoverBody style={{ padding: 0 }}>
                <NotificationBody notifications={notifications} />
              </PopoverBody>
            </div>
          </UncontrolledPopover>
        </Link>
      </div>

      <div className={styles.mobile_menu_item}>
        <RiMenu3Fill size={30} onClick={toggleDrawaer} />
      </div>
      <Link className={`${styles.mobile_menu_item}`}>
        <img src={tefLOgo} alt="logo" />
      </Link>
      <div to="#" className={styles.acount} id="PopoverLegacy" type="button">
        <UserSectionHeader currentUser={user} />

        <UncontrolledPopover
          trigger="legacy"
          placement="bottom"
          target="PopoverLegacy"
        >
          <Link to={`/profile/${user.userId}`}>
            <PopoverBody className={styles.accountIcon}>Profile</PopoverBody>
          </Link>
          <Link to="/bookmarks">
            <PopoverBody className={styles.accountIcon}>
              My Bookmarks
            </PopoverBody>
          </Link>

          <Link to="#" onClick={() => Logout(history)}>
            <PopoverBody className={styles.accountIcon}>Logout</PopoverBody>
          </Link>
        </UncontrolledPopover>
      </div>
    </nav>
  );
};

export default NotificationNav_v2;
