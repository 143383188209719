import React from "react";

import FormInput from "src/modules/Tef-Dms/components/Form/FormInput";

const Products = ({ product, setProduct }) => {
  const handleChange = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <React.Fragment>
      <FormInput
        label="DESCRIPTIONS OF PRODUCT(S) OR SERVICE(S):"
        getFormInputValue={(e) => handleChange(e)}
        value={product.description}
        name="description"
        placeholder=""
      />
      Product Photos: <input type="file"></input>
    </React.Fragment>
  );
};

export default Products;
