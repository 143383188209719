import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import AlumniList from "src/modules/Alumni/Chat/Components/AlumniList/AlumniList";

const MessageViewers = ({
  isMembersModalOpen,
  toggleMembersModal,
  className,
  hubUsers = [],
  isloadingHubUsers = false,
  switchDefaultActiveChat,
  groupTitle,
  handleSearch,
  personalChat,
  groupChat,
  showTitle,
}) => {
  return (
    <div>
      <Modal
        isOpen={isMembersModalOpen}
        toggle={toggleMembersModal}
        className="modal-container alumni-member"
        centered={true}
      >
        <ModalHeader toggle={toggleMembersModal}>
          {/* <h4>{groupTitle}</h4> */}
        </ModalHeader>
        <ModalBody>
          <AlumniList
            hubUsers={hubUsers}
            isloadingHubUsers={isloadingHubUsers}
            switchDefaultActiveChat={switchDefaultActiveChat}
            groupChat={groupChat}
            personalChat={personalChat}
            groupTitle={groupTitle}
            handleSearch={handleSearch}
            showTitle={showTitle}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default MessageViewers;
