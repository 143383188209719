import axios from "axios";
import { asyncActions } from "src/utils/AsyncUtils";
import { AlumniEventRequestUrl, AlumniRequestURL } from "src/constants";
import { toast } from "react-toastify";
import {
  GET_ALL_ALUMNI_EVENTS,
  GET_ALUMNI_EVENT,
  CREATE_ALUMNI_EVENT,
  POST_EVENT_RSVP,
  POST_EVENT_COMMENT,
  GET_ALL_ALUMNI_EVENT_COMMENTS,
  DELETE_EVENT_COMMENT,
} from "src/actions";

function updateQueryStringParameter(uri, key, value) {
  const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  const separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
}

export const getAllAlumniEvents = (dispatch, params) => {
  const { eventsExpiry, eventUserGroups, eventsPrivacy, pageNumber } = params;

  const query = {
    eventUserGroups,
    eventsPrivacy,
    eventsExpiry,
    PageNumber: pageNumber,
    MaxItem: 10,
  };

  Object.keys(query).forEach((key) => {
    if (!query[key]) {
      delete query[key];
    }
  });

  const filteredQuery = query;
  let queryString = "";
  const keys = Object.keys(filteredQuery);
  if (keys.length > 0) {
    keys.forEach((key) => {
      queryString = updateQueryStringParameter(
        queryString,
        key,
        filteredQuery[key]
      );
    });
  }

  dispatch(asyncActions(GET_ALL_ALUMNI_EVENTS).loading(true));
  return axios({
    method: "get",
    url: `${AlumniEventRequestUrl.GET_EVENT_URL}${queryString}`,
  });
};

export const getAlumniEvent = (dispatch, id) => {
  dispatch(asyncActions(GET_ALUMNI_EVENT).loading(true));
  return axios({
    method: "get",
    url: `${AlumniEventRequestUrl.GET_EVENT_URL}/${id}`,
  });
};
export const getAllFeaturedAlumniEvents = (dispatch, pageNumber) => {
  dispatch(asyncActions(GET_ALUMNI_EVENT).loading(true));
  return axios({
    method: "get",
    url: `${AlumniEventRequestUrl.GET_EVENT_URL}/featured?PageNumber=${pageNumber}&MaxItem=10`,
  });
};

export const postEventOpportunity = (dispatch, payload, history) => {
  dispatch(asyncActions(CREATE_ALUMNI_EVENT).loading(true));
  return axios({
    method: "post",
    url: `${AlumniEventRequestUrl.GET_EVENT_URL}`,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(CREATE_ALUMNI_EVENT).loading(false));

      if (response.status === 200 || response.status === 201) {
        const { data } = response.data;
        return data;
      }
    })
    .catch((error) => {
      const {
        data: { message },
      } = error.response;
      const fail = "An error occurred, Please try again ";
      dispatch(asyncActions(CREATE_ALUMNI_EVENT).loading(false));
      dispatch(asyncActions(CREATE_ALUMNI_EVENT).failure(true, error.response));
      toast.error(message || fail);
    });
};

export const likeEvent = (dispatch, eventId) => {
  return axios({
    method: "post",
    url: `${AlumniEventRequestUrl.GET_EVENT_URL}/${eventId}/likes/toggles`,
  });
};
export const getTotalComment = (dispatch, payload, pageNumber) => {
  dispatch(asyncActions(GET_ALL_ALUMNI_EVENT_COMMENTS).loading(true));
  return axios({
    method: "get",
    url: `${AlumniEventRequestUrl.GET_EVENT_URL}/${payload}/comments?`,
  });
};
export const postComment = (dispatch, data) => {
  dispatch(asyncActions(POST_EVENT_COMMENT).loading(true));
  return axios({
    method: "POST",
    url: `${AlumniEventRequestUrl.GET_EVENT_URL}/comments`,
    data,
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(asyncActions(POST_EVENT_COMMENT).loading(false));
        // dispatch(asyncActions(POST_OPPORTUNITY_COMMENT).success(data));
        toast("Comment Added ✅");
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(POST_EVENT_COMMENT).loading(false));
      dispatch(asyncActions(POST_EVENT_COMMENT).failure(true));
      return error.response;
    });
};

export const deleteComment = (dispatch, params) => {
  dispatch(asyncActions(DELETE_EVENT_COMMENT).loading(true));
  return axios({
    method: "delete",
    url: `${AlumniEventRequestUrl.GET_EVENT_URL}/${params.eventId}/comments/${params.commentId}`,
  })
    .then((response) => {
      dispatch(asyncActions(DELETE_EVENT_COMMENT).loading(false));
      if (response.status === 200 || response.status === 201) {
        dispatch(asyncActions(DELETE_EVENT_COMMENT).loading(false));
        toast("Comment Deleted Successfully ✅");
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(DELETE_EVENT_COMMENT).loading(false));
      dispatch(
        asyncActions(DELETE_EVENT_COMMENT).failure(true, error.response)
      );
      toast("An error occured, Please try again");
      return error.response;
    });
};

export const postRsvp = (dispatch, param) => {
  dispatch(asyncActions(POST_EVENT_RSVP).loading(true));
  return axios({
    method: "POST",
    url: `${AlumniEventRequestUrl.GET_EVENT_URL}/${param}/rsvps/toggles`,
  });
};
export const getRsvp = (dispatch, param) => {
  dispatch(asyncActions(POST_EVENT_RSVP).loading(true));
  return axios({
    method: "GET",
    url: `${AlumniEventRequestUrl.GET_EVENT_URL}/${param}/rsvps/getbycurrentuser`,
  });
};
export const checkVisaLetterStatus = (dispatch, param) => {
  dispatch(asyncActions(POST_EVENT_RSVP).loading(true));
  return axios({
    method: "GET",
    url: `${AlumniEventRequestUrl.GET_EVENT_URL}/visa/letter/requests/${param}/getbycurrentuser`,
  });
};

export const requestVisaLetter = (dispatch, payload) => {
  dispatch(asyncActions(POST_EVENT_RSVP).loading(true));
  return axios({
    method: "POST",
    url: `${AlumniEventRequestUrl.GET_EVENT_URL}/visa/letter/requests/toggles`,
    data: payload,
  });
};
export const userlikedEvent = (dispatch, eventId) => {
  return axios({
    method: "GET",
    url: `${AlumniEventRequestUrl.GET_EVENT_URL}/${eventId}/likes/getbyusers`,
  });
};

export const requestedVisaLetter = (dispatch, param) => {
  return axios({
    method: "GET",
    url: `${AlumniEventRequestUrl.GET_EVENT_URL}/visa/letter/requests/${param}/getbycurrentuser`,
  });
};
export const getResources = (dispatch, params) => {
  return axios({
    method: "GET",
    url: `${AlumniRequestURL.GET_RESOURCE_URL}/?FilteredBy=${params}`,
  });
};
export const getNews = (dispatch) => {
  return axios({
    method: "GET",
    url: `${AlumniRequestURL.GET_NEWS_URL}`,
  });
};

export const getAlumniHub = (dispatch) => {
  return axios({
    method: "GET",
    url: `${AlumniRequestURL.GET_ALUMNIHUB_URL}`,
  });
};

export const getEventTags = () => {
  return axios({
    method: "GET",
    url: `${AlumniEventRequestUrl.TAGS}`,
  });
};
