import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
// import shortid from "shortid";
import classes from "./ChatTabs.module.scss";
import { ListGroup } from "reactstrap";
import ChatBlock from "src/modules/Alumni/Chat/Components/ChatList/ChatBlock/ChatBlock";

const AllChats = ({
  allChatBookmarks = [],
  currentChat = {},
  switchDefaultActiveChat,
  groupChat,
  handleLoadMore,
  bookmarkChatPageDetails,
}) => {
  return (
    <InfiniteScroll
      dataLength={allChatBookmarks.length}
      next={() => handleLoadMore(bookmarkChatPageDetails.pageNumber + 1)}
      hasMore={bookmarkChatPageDetails.hasNextPage}
      height={400}
      loader={
        <div style={{ position: "absolute", bottom: 0, left: "50%" }}>
          Loading....
        </div>
      }
    >
      <div className={classes["chat-tab__wrapper"]}>
        <ListGroup className="bg-transparent border-0">
          {allChatBookmarks.length > 0
            ? allChatBookmarks.map((chat) => (
                <ChatBlock
                  switchDefaultActiveChat={switchDefaultActiveChat}
                  currentChat={currentChat}
                  isGroup={
                    chat.isGroupChat === true ||
                    chat.isPersonalChat === false ||
                    chat.isGroupChat === false
                  }
                  key={JSON.stringify(chat)}
                  chatDetails={chat}
                />
              ))
            : null}
        </ListGroup>
      </div>
    </InfiniteScroll>
  );
};

export default AllChats;
