import React from "react";
import Loader from "react-loader-spinner";

export const Btn = ({ value, className, loading, onClick, disabled }) => {
  if (disabled) {
    return (
      <div
        className={className}
        style={{ backgroundColor: "grey", color: "white" }}
      >
        {value}
      </div>
    );
  }
  if (loading) {
    return (
      <div className={className}>
        {value}
        <span style={{ marginLeft: "5px" }}>
          <Loader height={20} width={20} color="white" type="Oval" />
        </span>
      </div>
    );
  }
  return (
    <div onClick={onClick} className={className}>
      {value}{" "}
    </div>
  );
};

Btn.defaultProps = {
  onClick: () => {},
  loading: false,
};

export const NewItem = ({ onItemClick, value, buttonText }) => {
  return (
    <div className="new-item-c" onClick={onItemClick}>
      {value}
      <div className="add-new">+ Add new {buttonText}</div>
    </div>
  );
};

export const filterListOnSearch = (list, filterItem, field) => {
  if (filterItem.length === 0) {
    return [];
  }
  return list
    .filter((item) => {
      return item[field].toLowerCase().startsWith(filterItem.toLowerCase());
    })
    .sort((a, b) => {
      return a[field].toLowerCase().localeCompare(b[field].toLowerCase());
    });
};

export const isExistingSearchedItem = (list, filterItem, field) => {
  const result = list.find((item) => {
    return item[field].toLowerCase() === filterItem.toLowerCase();
  });

  return result;
};

export const filterSkill = (incoming, existing) => {
  if (existing.length < 1 || incoming.length < 1) {
    return incoming;
  }
  const filtered = [];
  incoming.forEach((item) => {
    if (!existing.includes(item.id)) {
      filtered.push(item);
    }
  });
  return filtered;
};
