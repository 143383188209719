import React, { useEffect } from "react";
import "./CreateOpportunitySuccess.scss";
import SuccessImage from "src/assets/img/success-tef.png";
import Layout from "src/components/Layout/Layout";
import { Link } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Row } from "reactstrap";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

const CreateOpportunitySuccess = (props) => {
  const { t } = useTranslation();
  useEffect(() => {}, []);
  const { id } = queryString.parse(window.location.search);

  return (
    <Layout hideRight hideLeft {...props}>
      <div className="create-opp-breadcrumb">
        <Link to="/home" style={{ fontWeight: "bold" }}>
          {t("Home")} <IoIosArrowRoundForward />
        </Link>
        <Link to="/opportunities" style={{ fontWeight: "bold" }}>
          {t("Opportunities")} <IoIosArrowRoundForward />
        </Link>
        <Link to="/opportunities/create">
          {t("Submit Opportunity ")}
          <IoIosArrowRoundForward />
        </Link>
        <span>{t("Completed")}</span>
      </div>
      <div className="success-create-opp">
        <div className="success-create-opp__content">
          <div className="success-create-opp__content--image-container">
            <img src={SuccessImage} alt="icon" className="img-fluid" />
          </div>
          <div className="success-create-opp__content--message">
            <h1>{t("Congratulations")} 👏</h1>
            <p>
              {t(
                `Your opportunity has been published to the opportunities board. An admin personnel will take a look at the published opportunity and take appropriate action accordingly.`
              )}
            </p>
            <br />

            <Row className="mb-4"></Row>
            <Link
              to={`/opportunity-details/${id}`}
              className="create-opp-success-view-opp"
            >
              {t("View Opportunity")} <MdKeyboardArrowRight />
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateOpportunitySuccess;
