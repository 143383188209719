import React, { useState, useContext } from "react";
import { changePassword } from "src/requests/AuthRequest";
import { Context } from "src/store";
import NewAuthLayout from "src/Auth/NewLayout";
import FormInput from "src/Auth/components/FormInput/FormInput";
import Loader from "src/components/Loader/Loader";
import AlertDiv from "src/Auth/components/AlertDiv";
import tefDark from "src/assets/img/logo/logo_200.png";
import PasswordStrengthBar from "react-password-strength-bar";
import { validateChangePasswordFields } from "src/utils/Validations/validateSignup";
import useForm from "src/utils/useForm";

const ChangePassword = () => {
  const [alertDetails, setAlertDetails] = useState({
    alertMessage: "",
    alertType: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useContext(Context);
  const [show, setShow] = useState(false);

  const toggleFocus = () => setShow(true);
  const toggleBlur = () => setShow(false);

  const handleChangePassword = () => {
    setIsLoading(true);

    setAlertDetails({
      ...alertDetails,
      alertMessage: "",
      alertType: "",
    });

    const userInfo = {
      currentPassword: values.oldPassword,
      newPassword: values.newPassword,
    };

    changePassword(dispatch, userInfo)
      .then((res) => {
        const { status, data } = res;
        if (status !== 200) {
          setIsLoading(false);
          const { message } = data;
          setAlertDetails({
            ...alertDetails,
            alertMessage: message,
            alertType: "danger",
          });
        } else {
          setAlertDetails({
            ...alertDetails,
            alertMessage: res.data.message,
            alertType: "success",
          });
        }
      })
      .catch((_error) => {
        setIsLoading(false);
        setAlertDetails({
          ...alertDetails,
          alertMessage: "Something went wrong, please contact support",
          alertType: "danger",
        });
      });
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    handleChangePassword,
    validateChangePasswordFields
  );

  return (
    <NewAuthLayout>
      <div className="main-wrapper__form-div">
        <img src={tefDark} className="img-2" alt="TEFConnect Logo" />
        <div className="reset-password-div" style={{ marginBottom: "30px" }}>
          <h1 className="reset-password-div__heading">Change Password</h1>
          <h3
            className="reset-password-div__sub-heading"
            style={{ opacity: "0", margin: "0" }}
          >
            We will send you a password reset link connected to your existing
            account
          </h3>
          <form className="reset-password-div__form" onSubmit={handleSubmit}>
            <FormInput
              labelName="Old Password"
              labelId="oldPassword"
              forLabel="oldPassword"
              inputType="password"
              inputName="oldPassword"
              isRequired="required"
              changed={handleChange}
              errorName={errors.oldPassword}
              inputValue={values.oldPassword}
            />

            <FormInput
              labelName="New Password"
              labelId="newPassword"
              forLabel="newPassword"
              inputType="password"
              inputName="newPassword"
              isRequired="required"
              isFocused={toggleFocus}
              isBlurred={toggleBlur}
              changed={handleChange}
              errorName={errors.newPassword}
              inputValue={values.newPassword}
            />
            <PasswordStrengthBar password={values.newPassword} />
            {show && (
              <p className="hint-text">
                <b>Hint:</b> For a strong password, it must be at least 8
                characters and contain an upper case (A-Z), lower case (a-z),
                number (0-9) and a special character (e.g. !@#$%^&*)
              </p>
            )}

            <FormInput
              labelName="Confirm New Password"
              labelId="confirmNewPassword"
              forLabel="confirmNewPassword"
              inputType="password"
              inputName="confirmNewPassword"
              isRequired="required"
              changed={handleChange}
              errorName={errors.confirmNewPassword}
              inputValue={values.confirmNewPassword}
            />

            <div className="signup-main-form__alert-div">
              {alertDetails.alertMessage && alertDetails.alertType ? (
                <AlertDiv color={alertDetails.alertType}>
                  {alertDetails.alertMessage}
                </AlertDiv>
              ) : (
                ""
              )}
            </div>

            <button className="reset-password-button" disabled={isLoading}>
              {isLoading ? <Loader color="light" /> : "Change Password"}
            </button>
          </form>
        </div>
      </div>
    </NewAuthLayout>
  );
};

export default ChangePassword;
