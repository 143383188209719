import entrepreneurship from "src/assets/img/topics/entrepreneurship.png";
import oilAndgas from "src/assets/img/topics/oil&gas.png";
import agriculture from "src/assets/img/topics/agriculture.png";
import agrobusiness from "src/assets/img/topics/agrobusiness.png";
import banking from "src/assets/img/topics/banking.png";
import business from "src/assets/img/topics/business.png";
import construction from "src/assets/img/topics/construction.png";
import defaultI from "src/assets/img/topics/default.png";
import ecommerce from "src/assets/img/topics/ecommerce.png";
import education from "src/assets/img/topics/education.png";
import entertainment from "src/assets/img/topics/entertainment.png";
import fishing from "src/assets/img/topics/fishing.png";
import healthcare from "src/assets/img/topics/healthcare.png";
import logistics from "src/assets/img/topics/logistics.png";
import manufacturing from "src/assets/img/topics/manufacturing.png";
import powerenergy from "src/assets/img/topics/powerenergy.png";
import retailcommerce from "src/assets/img/topics/retailcommerce.png";
import startups from "src/assets/img/topics/startups.png";
import technology from "src/assets/img/topics/technology.png";
import telecommunications from "src/assets/img/topics/telecommunications.png";
import tourismAndhospitality from "src/assets/img/topics/tourism&hospitality.png";

export const getInterestIcon = (interest) => {
  interest = interest.toLowerCase().trim();
  switch (interest) {
    case "entrepreneurship":
      return entrepreneurship;
    case "oil&gas":
      return oilAndgas;
    case "agriculture":
      return agriculture;
    case "agrobusiness":
      return agrobusiness;
    case "banking":
      return banking;
    case "business":
      return business;
    case "construction":
      return construction;
    case "ecommerce":
      return ecommerce;
    case "education":
      return education;
    case "entertainment":
      return entertainment;
    case "fishing":
      return fishing;
    case "healthcare":
      return healthcare;
    case "logistics":
      return logistics;
    case "manufacturing":
      return manufacturing;
    case "powerenergy":
      return powerenergy;
    case "retailcommerce":
      return retailcommerce;
    case "startups":
      return startups;
    case "technology":
      return technology;
    case "telecommunications":
      return telecommunications;
    case "tourism&hospitality":
      return tourismAndhospitality;
    default:
      return defaultI;
  }
};
