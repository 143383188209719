import React from "react";
import { Row } from "reactstrap";
import BookCard from "src/modules/Alumni/Resources/components/BookCard/index";
import "./index.scss";

const WhitePaper = ({ resources, onLike, onDownload, ErrorEmpPage }) => {
  return (
    <div>
      <Row className="pl-4 pr-4">
        {resources.length > 0 ? (
          resources.map((data) => (
            <BookCard
              md={4}
              key={data.id}
              onDownload={() => onDownload(data)}
              coverImg={
                data.coverPageUrl ||
                "https://d1csarkz8obe9u.cloudfront.net/posterpreviews/contemporary-fiction-night-time-book-cover-design-template-1be47835c3058eb42211574e0c4ed8bf_screen.jpg?ts=1594616847"
              }
              title={data.title}
              desc={data.description}
              author={data.author}
              isLiked={data.isLiked}
              onLike={() => onLike(data)}
            />
          ))
        ) : (
          <ErrorEmpPage />
        )}
      </Row>
    </div>
  );
};

export default WhitePaper;
