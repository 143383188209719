import React, { useState, useContext } from "react";
import {
  Spinner,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { GoComment } from "react-icons/go";
import { FiShare2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { likeComment } from "src/requests/OpportunityRequest";
import classesm from "./UserReactions.module.css";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";

import { useTranslation } from "react-i18next";
import moment from "moment";
import { Context } from "src/store";
import { FRONTEND_URL } from "src/utils/frontendUrl";

const UserReact = ({
  userReact,
  toggleReply,
  idx,
  replies,
  deleteUserComment,
  getComments,
  handleLikeLoader,
  isLoadingLike,
  opportunityId,
}) => {
  const { t } = useTranslation();
  const {
    id,
    createdDate,
    message,
    createdBy,
    totalLikes,
    allLikesIds,
  } = userReact;

  const current_user = get_user_info_from_token()?.user?.id ?? "";
  const canDelete = () => current_user === createdBy;
  const [liked] = useState(false);
  const { dispatch } = useContext(Context);
  const [countLikes, setCountLikes] = useState(0);
  const [tempCountLikes] = useState(allLikesIds.length);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const localDomain = `${FRONTEND_URL}/alumni-opportunity-detail/${opportunityId}`;

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const handleDeleteComment = (id) => {
    deleteUserComment(id);
  };
  const userLiked = () => allLikesIds.includes(current_user);
  const handleLikePost = () => {
    // handleLikeLoader(true);
    likeComment(dispatch, id).then((res) => {
      getComments(false);
    });

    const totalLikeCount =
      countLikes <= tempCountLikes ? tempCountLikes - 1 : tempCountLikes;
    liked ? setCountLikes(totalLikeCount) : setCountLikes(tempCountLikes + 1);
  };

  return (
    <div className={classesm.UserReactions}>
      <div className={`${classesm.Likes} mobile-pad`} onClick={handleLikePost}>
        {isLoadingLike ? (
          <Spinner size="sm" />
        ) : userLiked() ? (
          <FaHeart style={{ color: "#cf1d10" }} />
        ) : (
          <FaRegHeart
            size={15}
            color="#919BA8"
            style={{ cursor: "pointer", marginRight: "10px" }}
          />
        )}
        {totalLikes || ""}
        <span className="react-text">Like(s)</span>
      </div>
      <div
        className={`${classesm.Reply} mobile-pad`}
        onClick={toggleReply}
        data-replyid={idx}
      >
        <GoComment
          size={15}
          color="#919BA8"
          style={{ cursor: "pointer", marginRight: "10px" }}
        />{" "}
        <span className={`countPlaceholder-${idx} react-text`}> Reply</span>
      </div>
      <div className={`${classesm.Share} mobile-pad`} id="shareComment">
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle className="btn-drop mr-0">
            <FiShare2
              size={15}
              color="#919BA8"
              style={{ cursor: "pointer", marginRight: "10px" }}
            />{" "}
            <span className="react-text">{` Share`}</span>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <LinkedinShareButton url={localDomain} title={message}>
                <LinkedinIcon size={20} round={true} className="icon-tab" />
                Share via LinkedIn
              </LinkedinShareButton>
            </DropdownItem>
            <DropdownItem>
              <FacebookShareButton url={localDomain} quote={message}>
                <FacebookIcon size={20} round={true} className="icon-tab" />
                Share via Facebook
              </FacebookShareButton>
            </DropdownItem>
            <DropdownItem>
              <TwitterShareButton url={localDomain} title={message}>
                <TwitterIcon size={20} round={true} className="icon-tab" />
                Share via Twitter
              </TwitterShareButton>
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>

        {/* <FiShare2
          size={15}
          color="#919BA8"
          style={{ cursor: "pointer", marginRight: "10px" }}
        />{" "}
        <span className="react-text">{` Share`}</span> */}
      </div>
      {canDelete() && (
        <div
          style={{ display: "flex", alignItems: "center" }}
          className={`${classesm.Delete} mr-3`}
          onClick={() => handleDeleteComment(id)}
        >
          <RiDeleteBin6Line color="#919BA8" size={15} />{" "}
          <span className="react-text ml-2">{t("Delete")}</span>
        </div>
      )}
      <div className={classesm.Posted}>{moment(createdDate).fromNow()}</div>
    </div>
  );
};

export default UserReact;
