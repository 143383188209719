import { asyncActionName } from "src/utils/AsyncUtils";
import { CREATE_ALUMNI_HUB } from "src/actions";
import { initialState } from "src/store";

const AlumniHubReducer = (state = initialState.alumniHub, action) => {
  switch (action.type) {
    case asyncActionName(CREATE_ALUMNI_HUB).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(CREATE_ALUMNI_HUB).success:
      return {
        ...state,
        hubs: action.payload,
      };
    case asyncActionName(CREATE_ALUMNI_HUB).failure:
      return {
        ...state,
        error: action.payload.status,
      };
    default:
      return state;
  }
};
export default AlumniHubReducer;
