import React from "react";
import Layout from "src/components/Layout/Layout";
import Profile from "./ProfileBanner/ProfileBanner";
import SideRight from "src/modules/homepage/components/SideRight/SideRight";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
const index = (props) => {
  return (
    <Layout SideContent={<SideRight />} heading="Profile">
      <Profile />
    </Layout>
  );
};

index.propTypes = {};

export default ifOnboardedThenShow(index);
