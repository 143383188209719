import React, { useContext, useEffect, useState } from "react";
import { Card, Row, Col } from "reactstrap";
import "./AboutMini.scss";
import { _truncateText } from "src/modules/mentorship/components/helper";
import { MdLanguage, MdMailOutline } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FiBriefcase } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { setCurrentUser, useCurrentUser } from "src/utils/Services/currentUser";
import { OptionsDropDown } from "src/modules/profile/components/About/optionsDropdown/option";
import { Context } from "src/store";
import { hideProfileFields } from "src/requests/ProfileRequest";

const AboutMini = ({
  bio,
  joinedDate,
  businessName,
  firstName,
  lastName,
  email,
  hiddenFields,
}) => {
  const { dispatch } = useContext(Context);
  const { id } = useParams();
  const user = useCurrentUser();
  const isCurrentUser = user.userId === id;
  const [isHidden, setIsHidden] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [loadingHideFields, setLoadingHideFields] = useState(false);

  const isHiddenF = (fieldName) => {
    return hiddenFields.indexOf(fieldName) >= 0;
  };

  useEffect(() => {
    hiddenFields && hiddenFields.length > 0 && setIsHidden(isHiddenF("email"));
  }, [hiddenFields]);

  const toggleHiddenField = (fieldName) => {
    setLoadingHideFields(true);
    let copy = [...hiddenFields];
    if (isHidden) {
      copy = copy.filter((f) => f !== fieldName);
    } else {
      copy.push(fieldName);
    }
    user.hiddenFields = copy;
    hideProfileFields(dispatch, copy, callBack(user)).then(() => {
      setLoadingHideFields(false);
    });
  };

  const onMouseOn = () => {
    setShowMenu(true);
  };

  const onMouseOut = () => {
    if (showMenu) {
      setShowMenu(false);
    }
  };

  const callBack = (data) => {
    setCurrentUser(data);
  };

  const date = new Date(joinedDate);
  return (
    <Card className="aboutMini">
      <h1>About Me</h1>
      <hr />
      <div className="aboutMini__bio">
        <p>{`"${bio && _truncateText(bio, 100)}"`}</p>
        <hr />
      </div>
      <Col>
        <Row>
          <Col md={10}>{/* <p>Add Location</p> */}</Col>
        </Row>
      </Col>

      <Col>
        <Row>
          <Col md={10}>
            <p className="p">
              <span>
                <MdLanguage
                  size={22}
                  color="#919ba8"
                  style={{ borderRadius: "100%" }}
                />{" "}
              </span>
              {`${window.origin}${window.location.pathname}`}
            </p>
          </Col>
        </Row>
      </Col>

      <Col>
        <Row>
          <Col md={10}>
            <p className="p">
              <span>
                <FaRegCalendarAlt size={20} color="#919ba8" />{" "}
              </span>
              Joined {date.toDateString()}
            </p>
          </Col>
        </Row>
      </Col>

      <Col>
        <Row>
          <Col md={10}>
            <p className="p">
              <span>
                <FiBriefcase size={20} color="#919ba8" />{" "}
              </span>
              Working at {businessName}
            </p>
          </Col>
        </Row>
      </Col>

      {isHidden && !isCurrentUser ? null : (
        <Col className="a-t" onMouseEnter={onMouseOn} onMouseLeave={onMouseOut}>
          {isCurrentUser && showMenu && (
            <OptionsDropDown show={showMenu} toggle={onMouseOut}>
              <li onClick={() => toggleHiddenField("email")}>
                {!loadingHideFields ? (
                  <> {isHidden ? "Make Public" : "Hide from public"}</>
                ) : (
                  <>{"please wait..."}</>
                )}
              </li>
            </OptionsDropDown>
          )}
          <Row>
            <Col md={10}>
              <p className="p">
                {" "}
                <span>
                  <MdMailOutline size={20} color="#919ba8" />{" "}
                </span>
                {email}
              </p>
            </Col>
          </Row>
        </Col>
      )}
    </Card>
  );
};

AboutMini.propTypes = {};

export default AboutMini;
