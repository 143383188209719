import React, { useState, useContext } from "react";
import classes from "./GroupChatForm.module.scss";
import PrimaryButton from "src/modules/Alumni/Components/PrimaryButton/index";
import SecondaryButton from "src/modules/Alumni/Components/SecondaryButton/index";
import ImageUploader from "react-images-upload";
import { Label, Input } from "reactstrap";
import { Context } from "src/store";
import { useForm } from "react-hook-form";
import { createChatGroup } from "src/requests/AlumniChatRequest";
import { toast } from "react-toastify";
import { UploadFile } from "src/requests/fileUploadRequest";

const GroupChatForm = ({ secondaryButtonAction, refreshPage }) => {
  const [file, setFile] = useState("");
  const onDrop = (pictureFiles) => {
    setFile(pictureFiles);
  };
  const { dispatch } = useContext(Context);

  const { register, handleSubmit, reset, formState } = useForm();

  const { isSubmitting } = formState;

  const onSubmit = async (data) => {
    const bannerUrl = await UploadFile(dispatch, false, file).then((res) => {
      const { files } = res;
      return files[0];
    });
    data.bannerUrl = bannerUrl;
    createChatGroup(dispatch, data).then(() => {
      reset();
      secondaryButtonAction();
      setFile("");
      toast.success("Chat group created successfully");
      refreshPage();
    });
  };

  return (
    <div className={classes["group-chat__form-wrapper"]}>
      <div className="form-header border-bottom mb-4 pb-2">
        <h4 className="fs-17 fw-600">New Group Chat</h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* image upload */}
        <ImageUploader
          withIcon={false}
          fileContainerStyle={{ backgroundColor: "#f0f0f0" }}
          buttonText="Choose images"
          onChange={onDrop}
          label="Max file size: 1mb, accepted: jpg, png, jpeg, gif"
          withPreview={true}
          imgExtension={[
            ".jpg",
            ".JPEG",
            "PNG",
            "JPG",
            ".png",
            ".gif",
            ".jpeg",
          ]}
          maxFileSize={5242880}
        />
        {/* subject */}
        <div className="form-group my-4">
          <Label htmlFor="name" className="fs-14 fw-500">
            Group Name/Subject
          </Label>
          <Input
            className="bg-light"
            name="name"
            id="name"
            innerRef={register({ required: true })}
          />
        </div>

        {/* description */}
        <div className="form-group">
          <Label htmlFor="descriotion" className="fs-14 fw-500">
            Description
          </Label>
          <Input
            type="textarea"
            className="bg-light"
            name="description"
            id="description"
            innerRef={register({ required: true })}
          />
        </div>
        {/* submit button */}
        <div className="d-flex">
          <SecondaryButton
            buttonText="Cancel"
            type="button"
            buttonAction={secondaryButtonAction}
          />
          <PrimaryButton
            type="submit"
            buttonText="Create"
            disabled={isSubmitting}
            style={{ marginLeft: 20 }}
          />
        </div>
      </form>
    </div>
  );
};

export default GroupChatForm;
