/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import "./FriendsGroupCard.scss";
// import FriendsList from "src/modules/Community/components/FriendsGroup/FriendsList/FriendsList";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import {
  joinGroup,
  leaveGroup,
  joinPrivateGroup,
} from "src/requests/CommunityRequest";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { Context } from "src/store";
import Loader from "src/components/Loader/Loader";
import { toast } from "react-toastify";
import { isRequest } from "src/modules/Community/components/SuggestedGroup/SuggestedGroupCard/SuggestedGroupCard";

const FriendsGroupCard = ({
  groupId,
  title,
  location,
  members,
  groupImage,
  belongs,
  ownerId,
  inviteStatus,
}) => {
  const { dispatch } = useContext(Context);
  const linkTo = `/community/group?groupId=${groupId}`;
  const { userId, email } = useCurrentUser();
  const [joined, setJoined] = useState(belongs);
  const [requested, setRequested] = useState(isRequest(inviteStatus));
  const [left, setLeft] = useState(false);
  const [isJoinLoading, setIsJoinLoading] = useState(false);
  const [noOfMembers, setNoOfMembers] = useState(members);
  const [buttonText, setButtonText] = useState("join Group");

  const handleJoinGroup = () => {
    setIsJoinLoading(true);
    setNoOfMembers(noOfMembers + 1);
    joinGroup(dispatch, { groupId, userId }).then((_) => {
      setIsJoinLoading(false);
      setJoined(true);
      setLeft(false);
    });
  };

  const handleLeaveGroup = () => {
    setIsJoinLoading(true);
    setNoOfMembers(noOfMembers - 1);
    leaveGroup(dispatch, { groupId, userId }).then((_) => {
      setIsJoinLoading(false);
      setLeft(true);
      setJoined(false);
    });
  };

  const handleRequestJoinGroup = () => {
    const payload = {
      groupId,
      inviteedByUserId: ownerId,
      inviteeUserId: userId,
      inviteeEmail: email,
    };
    setIsJoinLoading(true);
    joinPrivateGroup(dispatch, payload).then((res) => {
      setIsJoinLoading(false);
      setJoined(false);
      setLeft(false);
      if (res.status === 201) {
        setRequested(true);
        toast(
          "A request has been sent to the admin of this group '" + title + "'"
        );
      }
    });
  };

  const handleClick = () => {
    joined
      ? handleLeaveGroup()
      : location === "private"
      ? handleRequestJoinGroup()
      : handleJoinGroup();
  };

  useEffect(() => {
    if (joined) {
      setButtonText("leave group");
    }
    if (left) {
      setButtonText("join group");
    }
    if (requested) {
      setButtonText("requested");
    }
  }, [joined, left, requested]);

  return (
    <div className="friends-group-card">
      <div className="friends-group-card__image">
        <Link to={linkTo}>
          {groupImage ? (
            <img src={groupImage} className="group-image-1" alt="group logo" />
          ) : (
            <Avatar name={title} size="100%" />
          )}
        </Link>
      </div>
      <div className="friends-group-card__details">
        <div className="friends-group-details-info">
          <h1 className="friends-group-details-info__h1">
            <Link to={linkTo}>{title}</Link>
          </h1>
          <h3 className="friends-group-details-info__h3">
            {location} <span>.</span> {noOfMembers} Members
          </h3>
          {/* <FriendsList /> */}
        </div>
        <button
          disabled={isJoinLoading || requested}
          className={`friends-group-card-details-button ${
            joined
              ? "friends-group-card-button-active"
              : "friends-group-card-button-inactive"
          }`}
          onClick={handleClick}
        >
          {isJoinLoading ? <Loader color="light" /> : buttonText}
        </button>
      </div>
    </div>
  );
};

export default FriendsGroupCard;
