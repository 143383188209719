import React, { useState, useContext } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import tefDark from "src/assets/img/logo/logo_200.png";
import { Context } from "src/store";
import { sendFeedback } from "src/requests/FeedbackRequest";
import "./FeedbackForm.scss";
import { Input } from "reactstrap";
import FormInput from "src/Auth/components/FormInput/FormInput";
import Loader from "src/components/Loader/Loader";
import useForm from "src/utils/useForm";
import { validateFeedbackFields } from "src/utils/Validations/validateSignup";

const FeedbackForm = () => {
  const history = useHistory();
  const initialState = {
    feedBackType: "",
    description: "",
  };

  const [feedbackData, setFeedbackData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useContext(Context);

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFeedbackData({
      ...feedbackData,
      [name]: value,
    });
  };

  const submitFeedBack = () => {
    setIsLoading(true);
    const payload = {
      ...feedbackData,
      ...values,
    };

    sendFeedback(dispatch, payload).then((res) => {
      setIsLoading(false);
      setFeedbackData({
        ...feedbackData,
        feedBackType: "",
        description: " ",
      });
    });
  };

  const onBackClick = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    submitFeedBack,
    validateFeedbackFields
  );

  return (
    <div className="feedback-container">
      <img src={tefDark} alt="TEF Logo" />
      <Link to="#" onClick={onBackClick}>
        <h6 className="declaration-h6">
          <FaAngleLeft /> &nbsp; back
        </h6>
      </Link>
      <h1>feedback form</h1>
      <h3>
        we would love to hear your thought, concerns or problems with anything
        so we can improve
      </h3>
      <div className="feedback-form-div">
        <form onSubmit={handleSubmit}>
          <div className="feedback-input-div">
            <label>
              feedback type<span>*</span>
            </label>
            <div className="feedback-radio-container">
              <div className="feedback-radio-div">
                <input
                  required
                  type="radio"
                  name="feedBackType"
                  value="comment"
                  onChange={handleInputChange}
                />
                <label>comments</label>
              </div>
              <div className="feedback-radio-div">
                <input
                  required
                  type="radio"
                  name="feedBackType"
                  value="bug"
                  onChange={handleInputChange}
                />
                <label>bug report</label>
              </div>
              <div className="feedback-radio-div">
                <input
                  required
                  type="radio"
                  name="feedBackType"
                  value="question"
                  onChange={handleInputChange}
                />
                <label>questions</label>
              </div>
            </div>
          </div>

          <div className="feedback-input-div">
            <label>
              describe feedback <span>*</span>
            </label>
            <Input
              type="textarea"
              name="description"
              onChange={handleInputChange}
              value={feedbackData.description}
              required
            />
          </div>
          <div className="feedback-input-div">
            <label>
              name<span>*</span>
            </label>
            <div className="feedback-name-container">
              <div className="feedback-name-div">
                <FormInput
                  labelName="First Name"
                  labelId="firstName"
                  forLabel="firstName"
                  inputType="text"
                  inputName="firstName"
                  isRequired="required"
                  changed={handleChange}
                  errorName={errors.firstName}
                  inputValue={values.firstName}
                  inputLength={30}
                />
              </div>
              <div className="feedback-name-div">
                <FormInput
                  labelName="Last Name"
                  labelId="lastName"
                  forLabel="lastName"
                  inputType="text"
                  inputName="lastName"
                  isRequired="required"
                  changed={handleChange}
                  errorName={errors.lastName}
                  inputValue={values.lastName}
                  inputLength={30}
                />
              </div>
            </div>
          </div>
          <div className="feedback-input-div email">
            <FormInput
              labelName="Email Address"
              labelId="email"
              forLabel="email"
              inputType="email"
              inputName="email"
              isRequired="required"
              changed={handleChange}
              errorName={errors.email}
              inputValue={values.email}
            />
          </div>

          <div className="feedback-input-div">
            <button className="submit-button" disabled={isLoading}>
              {isLoading ? <Loader color="light" /> : "Join TEFConnect now!"}
            </button>
            {/* <input type="submit" name="submit" value="send feedback" /> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default FeedbackForm;
