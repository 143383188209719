import "./List.scss";
import React, { Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import { FiClock } from "react-icons/fi";
import { AiOutlineWallet } from "react-icons/ai";
import { Context } from "src/store";
import { Row, Col } from "reactstrap";
import Empty from "src/assets/img/mentorship-empty.png";
import shortid from "shortid";
import moment from "moment";
import { _truncateText } from "src/utils/helper";
import ContentLoader from "react-content-loader";
import { increaseView } from "src/requests/OpportunityRequest";
import { useTranslation } from "react-i18next";

const ListItem = ({ data }) => {
  const { dispatch } = useContext(Context);
  const history = useHistory();

  const { t } = useTranslation();

  return (
    <div
      className="opp-list-item"
      onClick={() => {
        data.isAlumni
          ? history.push(`/alumni-opportunity-detail/${data.id}`)
          : history.push(`/opportunity-details/${data.id}`);
        increaseView(dispatch, data.id);
      }}
    >
      <div className="opp-list-item__image-c">
        <img src={data.fileName} />
        {data.isAlumni && (
          <div
            style={{ backgroundColor: "#d42316", left: "18px" }}
            className="alumni__only"
          >
            Alumni
          </div>
        )}
      </div>
      <div className="opp-list-item__info-c">
        <div className="opp-list-item__category-date">
          <span className="opp-cat">{data.category.name}</span>
          <span> | </span>
          <span className="opp-date-posted">
            {moment(data.createdDate).fromNow()}
          </span>
        </div>

        <div className="opp-list-item__info-c__opp-title">
          <h5>{_truncateText(data.title, 81)}</h5>
        </div>

        <div className="opp-list-item__info-c__deadline-fund">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span className="opp-icon">
              <FiClock />
            </span>
            <span className="deadline-fund">
              <span>
                {data.deadline && new Date(data.deadline).toDateString()}{" "}
              </span>
              <span className="info-badge">{t("Deadline")} </span>
            </span>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span className="opp-icon">
              <AiOutlineWallet />
            </span>
            <span className="deadline-fund">
              <span
                style={{ overflowWrap: "break-word", wordWrap: "break-word" }}
              >
                ${data.totalPrice.toLocaleString()}{" "}
              </span>
              <span className="info-badge">{t("Total Prize")} </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ReactContentLoader = () => {
  return (
    <div style={{ backgroundColor: "white", margin: "8px" }}>
      <div
        style={{ backgroundColor: "#f3f3f3", height: "200px", width: "100%" }}
      ></div>
      <div
        style={{
          backgroundColor: "white",
          padding: "10px",
        }}
        // className="loader-item"
      >
        <ContentLoader
          speed={2}
          // width={324}
          // height={150}
          viewBox="0 0 400 160"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="8" rx="3" ry="3" width="300" height="30" />
          <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
          <rect x="0" y="56" rx="3" ry="3" width="300" height="6" />
          <rect x="0" y="72" rx="3" ry="3" width="300" height="6" />
          <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
          {/* <circle cx="20" cy="20" r="20" /> */}
        </ContentLoader>
      </div>
    </div>
  );
};

export const showLoading = () => {
  const loaders = [];
  for (let i = 0; i <= 20; i++) {
    loaders.push(
      <Col md={4} lg={4} sm={6} xs={12} key={i}>
        <ReactContentLoader key={shortid.generate()} />
      </Col>
    );
  }

  return loaders;
};

const OpportunityList = ({ list }) => {
  const { t } = useTranslation();

  const { state } = useContext(Context);

  const _displayOpportunities = () => {
    if (list && list.length > 0) {
      return list.map((listItem) => {
        return (
          <Fragment key={shortid.generate()}>
            {" "}
            <Col md={4} lg={4} sm={6} xs={12}>
              <ListItem data={listItem} />
            </Col>
          </Fragment>
        );
      });
    } else {
      return (
        <Col style={{ textAlign: "center" }} md={{ size: 6, offset: 3 }}>
          <br />
          <br />

          <img src={Empty} alt={"error"} />
          <h3 className="goals-heading">{t("No Opportunities to Show")}</h3>
          <br />
          <br />
        </Col>
      );
    }
  };

  return (
    <>
      {state.Opportunities.requestingOpportunities && (
        <Row>{showLoading()}</Row>
      )}
      <Row>{_displayOpportunities()}</Row>
    </>
  );
};

export default OpportunityList;
