import { useLocation } from "react-router-dom";
import { googleAnalyticsPageview } from "./googleAnalytics";

export const usePageView = () => {
  const location = useLocation();
  if (!location) {
    return;
  }

  googleAnalyticsPageview(location.pathname);
};
