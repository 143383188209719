import React, { useContext } from "react";
import classes from "./ToolCard.module.css";
import { AiFillEye } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import Avatar from "src/components/Avatar/Avatar";
import { FaRegCommentAlt } from "react-icons/fa";
import moment from "moment";
import { Context } from "src/store";
import { increaseViewCount } from "src/requests/ToolsRequest";
import { toast } from "react-toastify";
import Viewers from "src/modules/Discover/components/TefMasterClass/MasterclassDetails/components/Viewers/Viewers";

const ToolCard = ({ tools, noTools }) => {
  const { dispatch } = useContext(Context);
  const history = useHistory();

  const _handleToolDetails = (id) => {
    increaseViewCount(dispatch, id)
      .then((res) => {
        if (res && res.status === 200) {
          //
        }
      })
      .catch(() => toast("An error has occurred on the view"));
    history.push(`/tool-details/${id}`);
  };

  return (
    <div className={classes.ToolCards}>
      {tools &&
        tools.map((tool) => (
          <div className={classes.ToolCard} key={tool && tool.id}>
            <div className={classes.Row1}>
              <div className={classes.ToolIcon}>
                <Avatar
                  src={tool && tool.logo}
                  name={tool && tool.title}
                  dimension={30}
                />
              </div>
              <div className={classes.TitleCategoryAndDateCreated}>
                <div className={`${classes.ToolTitle} notranslate`}>
                  {tool && tool.title.charAt(0).toUpperCase()}
                  {tool && tool.title.slice(1)}
                </div>
                <div className={classes.CategoryAndDateCreated}>
                  <span className={classes.ToolCategory}>
                    {tool.categories && tool.categories[0].name}
                  </span>{" "}
                  |{" "}
                  <span className={classes.DateCreated}>
                    created: {moment(tool && tool.createdOn).format("MMM YYYY")}
                  </span>
                </div>
              </div>
            </div>
            <div className={classes.ToolDescription}>
              {tool && tool.summary}
            </div>
            <div className={classes.AvatarAndComment}>
              {!noTools && (
                <Viewers
                  viewersList={tool.viewers && tool.viewers}
                  fetchViewers={!noTools}
                />
              )}
              <div className={classes.Comments}>
                <div className={classes.CommentIcon}>
                  <FaRegCommentAlt size={10} style={{ color: "grey" }} />
                </div>
                <div className={classes.CommentCount}>
                  {tool && tool.commentCount}
                </div>
              </div>
            </div>
            <div className={classes.ViewTool}>
              <AiFillEye size={10} />
              <div
                className={classes.ViewToolText}
                onClick={() => _handleToolDetails(tool && tool.id)}
              >
                View Tool{" "}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ToolCard;
