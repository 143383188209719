import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "reactstrap";
import "./Group.scss";
// import { groups } from "./GroupList";
import Title from "src/modules/Discover/components/Title/Title";
import {
  getAllGroups,
  isGroupMember,
  joinGroup,
} from "src/requests/CommunityRequest";
import { Context } from "src/store";
import { imageValid } from "src/modules/Discover/helper";
// import EmptyImage from "src/assets/img/courseImage.jpg";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import classes from "./Group.module.css";

const Groups = () => {
  const [groups, setGroups] = useState([]);
  const [groupMember, setGroupMember] = useState([]);
  const [totalGroup, setTotalGroup] = useState(0);
  const [refreshPage, setRefreshPage] = useState(false);

  const { dispatch } = useContext(Context);
  const userId = useCurrentUser().userId;
  const history = useHistory();

  useEffect(() => {
    _getGroups();
    _isGroupMember();
  }, [refreshPage]);

  const _getGroups = () => {
    getAllGroups(dispatch).then((res) => {
      const { data } = res.data.data;
      // setGroups(data.slice(0, 4));
      generateRandomGroups(data);
      setTotalGroup(data.length);
    });
  };

  const _isGroupMember = () => {
    isGroupMember(dispatch, userId).then((res) => {
      const { data } = res.data.data;
      const groupBelongedToId = data.map((grp) => grp.id);
      setGroupMember(groupBelongedToId);
      // console.log(groupBelongedToId);
      // console.log(data);
    });
  };

  const _joinGroup = (groupId) => {
    const userDetails = {
      groupId,
      userId,
    };
    Swal.fire({
      title: "Are you sure, you want to join this group?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#da291c",
      cancelButtonColor: "#63666a",
      confirmButtonText: "Yes, Join!",
    }).then((result) => {
      if (result.isConfirmed) {
        joinGroup(dispatch, userDetails).then((res) => {
          if (res.status === 200) {
            Swal.fire(
              "Joined!",
              "You've joined the group",
              "success"
            ).then(() => setRefreshPage(!refreshPage));
          }
        });
        setRefreshPage(!refreshPage);
      }
    });
  };

  const generateRandomGroups = (groups) => {
    const arr = [];
    if (groups.length > 4) {
      while (arr.length < 4) {
        const r = Math.ceil(Math.random() * groups.length - 1);
        if (arr.indexOf(r) === -1) arr.push(groups[r]);
      }
    }
    setGroups(arr);
  };

  const handleShowMoreGroup = () => {
    history.push(`/community`);
  };
  const handleGroupDetails = (id) => {
    history.push(`/community/group?groupId=${id}`);
  };
  return (
    <div className="mt-3">
      <Title
        name="Groups"
        nameLength={totalGroup}
        seeAll="See All"
        seeAllAction={handleShowMoreGroup}
      />
      <div className="video-list-item p-3 mt-2">
        <Row>
          {groups &&
            groups.map((item, index) => (
              <Col md={6} key={index}>
                <div className={classes.GroupCard}>
                  <div
                    onClick={() => handleGroupDetails(item.id)}
                    className="post-list-item__image"
                  >
                    <img
                      src={
                        imageValid(item.imageUrl)
                          ? item.imageUrl
                          : "https://cdn.pixabay.com/photo/2017/04/19/13/16/computer-2242264_1280.jpg"
                      }
                      alt="group logo"
                      className={classes.GroupBanner}
                    />
                  </div>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <h1 onClick={() => handleGroupDetails(item.id)}>
                      {item && item.name}
                    </h1>
                    <div className="d-flex">
                      <h1 className="mr-1">{item && item.membersCount}</h1>
                      <h1>
                        {item && item.membersCount === 1 ? "member" : "members"}
                      </h1>
                    </div>
                  </div>
                  <div>
                    {groupMember.includes(item.id) ? (
                      <div className="text-center show-more">Joined</div>
                    ) : (
                      <div
                        className="text-center joined"
                        onClick={() => _joinGroup(item.id)}
                      >
                        Join Group
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="mb-2 group-list">
                  <Row>
                    <Col md={9}>
                      <div className="d-flex">
                        <div
                          className="group-list__image mr-4"
                          
                        >
                          {item && (
                           
                          )}
                        </div>
                        <div className="pt-3">
                          <h1 onClick={() => handleGroupDetails(item.id)}>
                            {item && item.name}
                          </h1>
                          <div className="group-list__group-category">
                            <span>{item && item.category.name}</span>
                            <span className="px-2" style={{ fontSize: "1rem" }}>
                              &#46;
                            </span>

                            
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={3} className="pt-3 pr-4">
                      {groupMember.includes(item.id) ? (
                        <div className="text-center show-more">Joined</div>
                      ) : (
                        <div
                          className="text-center joined"
                          onClick={() => _joinGroup(item.id)}
                        >
                          Join Group
                        </div>
                      )}
                    </Col>
                  </Row>
                </div> */}
              </Col>
            ))}
        </Row>

        <div
          className="mt-4 text-center w-25 show-more"
          onClick={handleShowMoreGroup}
        >
          Show more
        </div>
      </div>
    </div>
  );
};

export default Groups;
