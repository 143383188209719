import { asyncActionName } from "src/utils/AsyncUtils";
import {
  REGISTER_USER,
  FORGET_PASSWORD,
  VERIFY_ACCOUNT,
  SEND_VERIFY_LINK,
  CHANGE_PASSWORD,
} from "src/actions";
import { initialState } from "src/store";

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case asyncActionName(REGISTER_USER).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(REGISTER_USER).success:
      return {
        ...state,
        users: action.payload,
        success: true,
      };
    case asyncActionName(REGISTER_USER).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(FORGET_PASSWORD).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(FORGET_PASSWORD).success:
      return {
        ...state,
        users: action.payload,
        success: true,
      };
    case asyncActionName(FORGET_PASSWORD).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(VERIFY_ACCOUNT).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(VERIFY_ACCOUNT).success:
      return {
        ...state,
        users: action.payload,
        success: true,
      };
    case asyncActionName(VERIFY_ACCOUNT).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(SEND_VERIFY_LINK).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(SEND_VERIFY_LINK).success:
      return {
        ...state,
        user: action.payload,
        success: true,
      };
    case asyncActionName(SEND_VERIFY_LINK).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(CHANGE_PASSWORD).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(CHANGE_PASSWORD).success:
      return {
        ...state,
        success: true,
      };
    case asyncActionName(CHANGE_PASSWORD).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    default:
      return state;
  }
};

export default AuthReducer;
