import { subSectors } from "./experience/options";
import countryAndRegion from "./dump.json";

export const getIndustrySubSector = (filter, lang) => {
  const sectorObj = subSectors[lang || "en"];
  const iSubSectors = sectorObj[filter] || [];
  return iSubSectors.map((sector) => {
    return { value: sector, label: sector };
  });
};

export const getCountryAlpha2Code = (filter) => {
  const code = countryAndRegion.find((country) => {
    return (
      country.countryName === filter || country.countryShortCode === filter
    );
  });
  return code.countryShortCode || "NG";
};
