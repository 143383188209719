import React from "react";
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardImg,
  CardImgOverlay,
  CardFooter,
} from "reactstrap";
import "./index.scss";

const CardIndex = ({
  img,
  alt,
  className,
  style,
  md,
  onClick,
  content,
  TopLeftEle,
  TopRightEle,
  BottomRightEle,
  BottomLeftEle,
  id,
}) => {
  return (
    <Col
      className="card-board"
      md={md || 0}
      onClick={() => {
        if (onClick) onClick();
      }}
      id={id || null}
    >
      <div className="col-md-12">
        <Card
          style={style || {}}
          className={`card-board__curve-box ${className || ""}`}
          inverse
        >
          {img && (
            <CardImg
              className="card-bg-overlay"
              width="100%"
              height="100%"
              src={img}
              alt={alt || "Background Image"}
            />
          )}
          <CardImgOverlay style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
            <CardTitle tag="h5" className="titleContainer">
              <Row>
                <Col
                  md={6}
                  style={{ justifyContent: "left", textAlign: "left" }}
                >
                  {TopLeftEle && TopLeftEle}
                </Col>
                <Col
                  md={6}
                  style={{ justifyContent: "right", textAlign: "right" }}
                >
                  {TopRightEle && TopRightEle}
                </Col>
              </Row>
            </CardTitle>
            <CardText>{content}</CardText>
            <CardFooter className="footerContainer">
              <Row>
                <Col style={{ justifyContent: "left", textAlign: "left" }}>
                  {BottomLeftEle && BottomLeftEle}
                </Col>
                <Col style={{ justifyContent: "right", textAlign: "right" }}>
                  {BottomRightEle && BottomRightEle}
                </Col>
              </Row>
            </CardFooter>
          </CardImgOverlay>
        </Card>
      </div>
    </Col>
  );
};

export default CardIndex;
