import React, { useState } from "react";
import classes from "./Transcript.module.css";

const Transcript = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("Select Language");

  const languages = [
    { key: "english", value: "English" },
    { key: "french", value: "French" },
  ];

  const transcriptions = [
    {
      time: "00:09",
      contents:
        "Escape with host Saleem Reshamwala as he journeys across the globe in search of the world's most surprising and imaginative ideas. It's not a travel show, exactly. It's a deep dive into the ideas that shape a particular spot on the map, brought to you by local journalists and creators.",
    },
    {
      time: "00:29",
      contents:
        "Escape with host Saleem Reshamwala as he journeys across the globe in search of the world's most surprising and imaginative ideas. It's not a travel show, exactly. It's a deep dive into the ideas that shape a particular spot on the map, brought to you by local journalists and creators.",
    },
    {
      time: "01:09",
      contents: "(coughs)",
    },
    {
      time: "02:29",
      contents:
        "Escape with host Saleem Reshamwala as he journeys across the globe in search of the world's most surprising",
    },
  ];
  const handleSelectLanguage = (e) => {
    e.preventDefault();
    setSelectedLanguage(e.target.value);
  };

  return (
    <div>
      <div>
        <p>Language</p>
        <form>
          <select
            value={selectedLanguage}
            onChange={handleSelectLanguage}
            className={classes.SelectLanguage}
          >
            <option>Select Language</option>
            {languages.map((language, index) => (
              <option key={index} value={language.value}>
                {language.value}
              </option>
            ))}
          </select>
        </form>
      </div>
      {transcriptions.map((transcription, index) => (
        <div className={classes.Transcriptions} key={index}>
          <div className={classes.Time}>{transcription.time}</div>
          <div className={classes.Content}>{transcription.contents}</div>
        </div>
      ))}
    </div>
  );
};

export default Transcript;
