import React from "react";
import classes from "./ChatTabs.module.scss";
import { ListGroup } from "reactstrap";
import ChatBlock from "src/modules/Alumni/Chat/Components/ChatList/ChatBlock/ChatBlock";

const GroupChatTab = ({
  allChatBookmarks = [],
  currentChat = {},
  groupChat,
  switchDefaultGroupChat,
}) => {
  return (
    <div className={classes["chat-tab__wrapper"]}>
      <ListGroup className="bg-transparent border-0">
        {groupChat.length > 0
          ? groupChat.map((chat) => (
              <ChatBlock
                currentChat={currentChat}
                switchDefaultActiveChat={switchDefaultGroupChat}
                isGroup={
                  chat.isGroupChat === true ||
                  chat.isPersonalChat === false ||
                  chat.isGroupChat === false
                }
                key={chat.id}
                chatDetails={chat}
              />
            ))
          : null}
        {/* <ChatBlock isGroup={false} />
        <ChatBlock isGroup={true} />
        <ChatBlock isGroup={false} />
        <ChatBlock isGroup={false} /> */}
      </ListGroup>
    </div>
  );
};

export default GroupChatTab;
