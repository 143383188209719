import React from "react";
import { Col, Row } from "reactstrap";
import classes from "./CurrentStage.module.css";

const CurrentStage = ({ stageIndex, stageName, activeTab }) => {
  const getProgressOfCurrentStage = () => {
    switch (activeTab) {
      case "1":
        return (
          <span style={{ color: "#DA291C" }}>
            {localStorage.getItem("DueDilligenceStatus")}
          </span>
        );
      case "2":
        return (
          <span style={{ color: "#DA291C" }}>
            {localStorage.getItem("businessPlanStatus")}
          </span>
        );
      case "3":
        return (
          <span style={{ color: "#DA291C" }}>
            {localStorage.getItem("seedCapitalStatus")}
          </span>
        );
      default:
        return null;
    }
  };
  return (
    <Row className={classes.CurrentStageContainer}>
      <Col sm="1" xs={1} className="pr-1">
        <h2>{stageIndex}</h2>
      </Col>
      <Col sm="10" xs={10}>
        <p>
          <strong>{stageName}</strong> <br />
          {getProgressOfCurrentStage()}
        </p>
      </Col>
    </Row>
  );
};

export default CurrentStage;
