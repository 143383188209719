import React from "react";
// import TefRedButton from "src/modules/Alumni/Components/Button/TefRedButton";
import classes from "./InputComment.module.css";
import { Button } from "reactstrap";
import Avatar from "src/components/Avatar/Avatar";
import Loader from "src/components/Loader/Loader";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const user = get_user_info_from_token();

const current_user = user?.user;

// import { postComment } from "src/requests/OpportunityRequest";

const InputComment = ({
  submitHandler,
  inputHandler,
  commentData,
  postingComment,
  screenType,
}) => {
  // const [newComment, setNewComment] = useState("");

  // const cUser = localStorage.getItem("current_user");
  // const current_user = jwt_decode(cUser);

  // const submitCommentHandler = (e) => {
  //   e.preventDefault();
  //   const payload = {
  //     opportunityId,
  //     newComment,
  //   };
  //   postComment(dispatch, payload);
  //   setNewComment("");
  //   reload();
  // };

  return (
    <form onSubmit={submitHandler} className={screenType}>
      <div className={classes.InputComment}>
        <div className={classes.UserImage}>
          <Avatar
            src={current_user.imageUrl}
            dimension={40}
            name={`${current_user.firstName} ${current_user.lastName}`}
          />
        </div>
        <div className={classes.UserInput}>
          <textarea
            placeholder="What do you think of this opportunity?"
            value={commentData}
            onChange={inputHandler}
            className={classes.commentForm}
            maxLength="500"
            name="content"
          />
          {/* <EditorBox
            onEditorChange={setNewComment}
            content={newComment}
            useEmoji
            // useMentions
            mentions={[
              { name: "brown", link: "https//google.com" },
              { name: "John Doe", link: "http://facebook.com" },
            ]}
            placeholder="What do you think of this opportunity?  You can @mention a connect."
          /> */}
        </div>
        <Button className="float-right" disabled={postingComment} type="submit">
          {postingComment ? <Loader color="light" /> : "Comment"}
        </Button>
        {/* {newComment.trim() !== "" ? (
          <div className={`${classes.ShareButton}`}>
            <TefRedButton text={`Share`} />
          </div>
        ) : (
          <div className={`${classes.ShareButton} ${classes.Disabled}`}>
            <TefRedButton text={`Share`} />
          </div>
        )} */}
      </div>
    </form>
  );
};

export default InputComment;
