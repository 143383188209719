/* eslint-disable no-unused-vars */

import React, { useContext } from "react";
import { Button, Row, Col } from "reactstrap";
import Empty from "src/assets/img/mentorship-empty.png";
import { Context } from "src/store";
import "./Mentors.scss";
import ConnectionCard from "src/modules/mentorship/components/Mentor/ConnectionCard";
import { isMentor } from "src/modules/mentorship/components/helper";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import PageLoader from "src/modules/homepage/components/PageLoader/PageLoader";

const Mentors = ({ setCurrentTab, role }) => {
  const data = get_user_info_from_token();
  const { state } = useContext(Context);

  const showConnections = () => {
    const connections = state.Mentors && state.Mentors.connected;
    return connections.map((connection, i) => {
      return (
        <ConnectionCard
          key={i}
          isConnected={true}
          isMentor={isMentor(data.user)}
          // showAvailability={!isMentor(data.user)}
          userId={connection.namedId}
          // showModeOfCommunication={!isMentor(data.user)}
          // connectionStatus="connect"
          userDetails={connection.otherDetails}
        />
      );
    });
  };

  return (
    <div className="Mentors-container">
      {/* <Col md={12}> */}
      {/* <Row> */}
      {!state.Mentors.connected ||
        (state.Mentors.connected.length === 0 && (
          <Col style={{ textAlign: "center" }} md={{ size: 6, offset: 3 }}>
            <img src={Empty} />
            <h3 className="goals-heading">
              No {role === "Entrepreneur" && "Mentor"}{" "}
              {role === "Mentor" && "Mentee"} To Show
            </h3>
            {role === "Entrepreneur" && (
              <Button onClick={() => setCurrentTab("Find A Mentor")}>
                Find a Mentor
              </Button>
            )}
            {role === "Mentor" && (
              <Button onClick={() => setCurrentTab("Find A Mentee")}>
                Find a Mentee
              </Button>
            )}
            <br />
            <br />
          </Col>
        ))}
      {state.Mentors && !state.Mentors.connected && (
        <PageLoader
          type="Facebook"
          counts={2}
          className="d-flex flex-direction-row"
        />
      )}
      <div className="connection-list-c">
        {state.Mentors &&
          state.Mentors.connected &&
          state.Mentors.connected.length > 0 &&
          showConnections()}
        {/* <div style={{ flex: 1, minWidth: "320px" }}></div> */}
      </div>

      {/* </Row> */}
      {/* </Col> */}
    </div>
  );
};

Mentors.propTypes = {};

export default Mentors;
