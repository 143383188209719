import React from "react";

import FormInput from "src/modules/Tef-Dms/components/Form/FormInput";
import { Button } from "reactstrap";

const Introduction = ({ introduction, setIntroduction }) => {
  const handleChange = (e) => {
    setIntroduction({
      ...introduction,
      [e.target.name]: e.target.value,
    });
  };

  const handleDynamicInput = (key, i, e) => {
    const newArray = key;
    newArray[i] = e.target.value;
    setIntroduction({
      ...introduction,
      [key]: newArray,
    });
  };

  const addRow = (key, value) => {
    const newArray = key;
    newArray.push(value);
    setIntroduction({
      ...introduction,
      [key]: newArray,
    });
  };

  return (
    <React.Fragment>
      <FormInput
        label="Elevator Pitch:"
        getFormInputValue={(e) => handleChange(e)}
        value={introduction.elevatorPitch}
        name="elevatorPitch"
        placeholder="Type your Elevator pitch here"
      />
      <FormInput
        label="Business Description:"
        getFormInputValue={(e) => handleChange(e)}
        value={introduction.businessDescription}
        name="businessDescription"
        placeholder=""
      />

      <div className="mb-3">
        {introduction.businessObjectives.map((item, index) => (
          <FormInput
            key={index}
            label="Business Objectives:"
            getFormInputValue={(e) =>
              handleDynamicInput(introduction.businessObjectives, index, e)
            }
            value={item}
            placeholder=""
          />
        ))}

        <Button
          type="button"
          onClick={() => addRow(introduction.businessObjectives, "")}
          style={{ fontSize: "0.75em" }}
          className="btn btn-primary text-white px-2 py-1 border-0 mt-2"
        >
          Add Objective
        </Button>
      </div>

      <div className="mb-3">
        {introduction.successfactors.map((item, index) => (
          <FormInput
            key={index}
            label="Success Factor(s) of the Business:"
            getFormInputValue={(e) =>
              handleDynamicInput(introduction.successfactors, index, e)
            }
            value={item}
            placeholder=""
          />
        ))}

        <Button
          type="button"
          onClick={() => addRow(introduction.successfactors, "")}
          style={{ fontSize: "0.75em" }}
          className="btn btn-primary text-white px-2 py-1 border-0 mt-2"
        >
          Add Success Factor
        </Button>
      </div>

      <FormInput
        label="Value Proposition:"
        getFormInputValue={(e) => handleChange(e)}
        value={introduction.valueProposition}
        name="valueProposition"
        placeholder=""
      />

      <FormInput
        label="Business Status:"
        getFormInputValue={(e) => handleChange(e)}
        value={introduction.businessStatus}
        name="businessStatus"
        placeholder=""
      />

      <FormInput
        label="Contribution to Local and National Economy:"
        getFormInputValue={(e) => handleChange(e)}
        value={introduction.contributionToEconomy}
        name="contributionToEconomy"
        placeholder=""
      />
    </React.Fragment>
  );
};

export default Introduction;
