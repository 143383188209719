import React, { useState, useContext, useEffect } from "react";
import { Context } from "src/store";
import {
  getAllCategories,
  isGroupMember,
  getAllGroups,
  // getConnectionsGroups,
} from "src/requests/StudyGroupsRequest";
import "./Main.scss";
import Search from "src/modules/training/StudyGroups/components/Search/Search";
import SuggestedGroup from "src/modules/training/StudyGroups/components/SuggestedGroup";
// import Categories from "src/modules/training/StudyGroups/components/Categories";
// import FriendsGroup from "src/modules/training/StudyGroups/components/FriendsGroup";
// import ThreadNav from "src/modules/training/StudyGroups/components/Thread/ThreadNav/ThreadNav";
// import { useCurrentUser } from "src/modules/utils/Services/currentUser";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import axios from "axios";
import { useHistory } from "react-router";
// import { IoIosArrowRoundForward } from "react-icons/io";
// import { Link } from "react-router-dom";

const Main = () => {
  const user = get_user_info_from_token() && get_user_info_from_token().user;
  const trainingEventId = user && user.training ? user.training[1] : null;

  axios.defaults.headers.common["trainingevent-id"] = trainingEventId;
  // const { userId } = useCurrentUser();
  const { state, dispatch } = useContext(Context);
  const [, setIsLoading] = useState(true);
  const [usersGroups, setUsersGroups] = useState([]);
  const {
    user: { id },
  } = get_user_info_from_token();
  const [group, setGroup] = useState([]);
  const [isGettingGroupsLoading, setIsGettingGroupsLoading] = useState(true);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1392 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1391, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 538 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 538, min: 356 },
      items: 2,
    },
    verySmallMobile: {
      breakpoint: { max: 355, min: 320 },
      items: 1,
    },
  };
  // const [connectionsGroups, setConnectionsGroups] = useState([]);
  // const [isGroupsLoading, setIsGroupsLoading] = useState(true);

  const courseId = new URLSearchParams(location.search).get("course");

  const history = useHistory();

  const getGroupUserBelongsTo = () => {
    isGroupMember(dispatch, id).then((res) => {
      if (res !== undefined && res.status === 200) {
        const {
          data: { data },
        } = res.data;
        setUsersGroups(data);
      }
    });
  };

  useEffect(() => {
    getGroupUserBelongsTo();
  }, [!!state.Communities.joinGroup, !!state.Communities.leaveGroup]);

  useEffect(() => {
    getAllCategories(dispatch).then((res) => {
      if (res !== undefined && res.status === 200) {
        setIsLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    _getGroupsData();
  }, []);

  const _getGroupsData = () => {
    getAllGroups(dispatch, null, courseId).then((res) => {
      if (res !== undefined && res.status === 200) {
        const {
          data: { data },
        } = res.data;
        setIsGettingGroupsLoading(false);
        setGroup(data);
      }
    });
  };

  // useEffect(() => {
  //   getConnectionsGroups(dispatch, userId).then((res) => {
  //     if (res.status) {
  //       const {
  //         data: { data },
  //       } = res.data;
  //       setConnectionsGroups(data);
  //       setIsGroupsLoading(false);
  //     }
  //   });
  // }, []);

  const { categories } = state.Communities;

  return (
    <div className="community-main">
      <div className="create-opp-breadcrumb">
        <span onClick={() => history.goBack()} style={{ cursor: "pointer" }}>
          Go Back
        </span>
      </div>
      {/* <ThreadNav categories={categories} /> */}
      <Search categories={categories} getGroups={_getGroupsData} />
      <SuggestedGroup
        usersGroups={usersGroups}
        allGroups={group}
        isLoading={isGettingGroupsLoading}
        responsiveSize={responsive}
      />

      {/* <div>How</div> */}
      {/* <Categories
        categories={categories}
        loading={isLoading}
        responsiveSize={responsive}
      /> */}
      {/* <FriendsGroup
        groups={connectionsGroups}
        groupLoading={isGroupsLoading}
        usersGroups={usersGroups}
      /> */}
    </div>
  );
};

export default Main;
