import React from "react";
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import { FiClock } from "react-icons/fi";

const CalenderModal = ({ children, isOpen, onRequestClose }) => {
  return (
    <div>
      <Modal isOpen={isOpen}>
        <ModalHeader>Select Calender Type</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button onClick={onRequestClose}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CalenderModal;

export function Buttons({ children, onClick }) {
  return (
    <Button
      onClick={onClick}
      className="event-cta event-detail--visa btn-remainder"
    >
      <div className="add-calender-box">
        <span className="mr-2">
          <FiClock color="red" />
        </span>
        <span className="add-to-calender"> {children}</span>
      </div>
    </Button>
  );
}
