import React, { useState, useEffect } from "react";
import Layout from "src/components/Layout/Layout";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import MainContent from "./MainContent/MainContent";
import queryString from "query-string";
import PageNav from "src/components/TabNavs/TabNavs";
// import {
//   searchConnections,
//   searchPendingConnectionReceived,
//   searchPendingConnectionSent,
//   searchSuggestions,
// } from "src/requests/ConnectionRequest";
// import { Context } from "src/store";
// import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { AiOutlineSearch } from "react-icons/ai";
import ConnectionsSearch from "./Search/Search";

const resourses = {
  MY_CONNECTION: "My Connections",
  SUGGESTED_CONNECTIONS: "Suggestions",
  SENT_REQUEST: "Sent Requests",
  RECEIVED_REQUEST: "Received Requests",
};

const Connection = (props) => {
  // const { dispatch } = useContext(Context);
  const [currentTab, setCurrentTab] = useState("Suggestions");
  // const [searchData, setSearchData] = useState("");
  const [searchActive, setSetSearchActive] = useState(false);

  const titles = [
    "Suggestions",
    "Sent Requests",
    "Received Requests",
    "My Connections",
  ];

  // const data = get_user_info_from_token();
  // const { id } = data.user;

  const toggleSearch = () => setSetSearchActive(!searchActive);

  useEffect(() => {
    const setActiveTabsFromQuery = (value) => {
      setCurrentTab(value);
    };
    const { activeTab } = queryString.parse(window.location.search);
    if (activeTab) {
      setActiveTabsFromQuery(activeTab);
    }
  }, [window.location.search]);

  const updateCurrentTabUrl = (value) => {
    const activeTab = value;

    props.history.push({
      pathname: window.location.pathname,
      search: `?activeTab=${activeTab}`,
    });
  };

  return (
    <Layout SideContent={<SidebarRight />} heading="Connections">
      {searchActive ? (
        <>
          <ConnectionsSearch
            currentTab={currentTab}
            toggleSearch={toggleSearch}
          />
        </>
      ) : (
        <>
          <div className="conn-nav-c">
            <div className="conn-nav-c__tabs">
              <PageNav
                titles={titles}
                activeTab={currentTab}
                setTab={updateCurrentTabUrl}
              />
            </div>
            {(currentTab === resourses.SUGGESTED_CONNECTIONS ||
              currentTab === resourses.MY_CONNECTION) && (
              <div className="conn-nav-c__search" onClick={toggleSearch}>
                <span>
                  <span className="cs">Search... </span>
                  <AiOutlineSearch className="ci" size={20} />
                </span>
              </div>
            )}
          </div>

          <MainContent currentTab={currentTab} setCurrentTab={setCurrentTab} />
        </>
      )}
    </Layout>
  );
};

export default ifOnboardedThenShow(Connection);
