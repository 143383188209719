import React from "react";

const FinancialPlanTemplate = ({ financialPlan }) => {
  return (
    <div>
      <h4 className="text-center font-weight-bold mb-5">Financial Plan</h4>
      <p>
        <span className="mr-4">9.1</span>
        <span>Financial Plan</span>
      </p>
      <p className="ml-5">
        {financialPlan.revenueModel === ""
          ? "[REVENUE MODEL]"
          : financialPlan.revenueModel}
      </p>
      <p className="ml-5">
        {financialPlan.productPrices === ""
          ? "[PRODUCT PRICES]"
          : financialPlan.productPrices}
      </p>
    </div>
  );
};

export default FinancialPlanTemplate;
