/* eslint-disable react/jsx-key */
import React, { useState, useContext, useEffect } from "react";
import { Container, Col, Row, Card, Collapse } from "reactstrap";
import { Context } from "src/store";
import { BsArrowLeft } from "react-icons/bs";
import "./Faq.scss";
import { getApplicationFaq } from "src/requests/ApplicationRequest";
import { useTranslation } from "react-i18next";
import Layout from "src/modules/Application/components/Layout/Layout";

const Faq = (props) => {
  const [isOpen, setIsOpen] = useState("");
  const { state, dispatch } = useContext(Context);
  useEffect(() => {
    getApplicationFaq(dispatch);
  }, []);

  const toggle = (index) => {
    if (isOpen === index) {
      setIsOpen(false);
    } else {
      setIsOpen(index);
    }
  };

  const { t } = useTranslation();

  const handleLogoClick = () => {
    props.history.goBack();
  };

  return (
    <Layout>
      <Col md={12} sm={12} xs={12} lg={12}>
        <p className="app-faq-back-btn text-black-50" onClick={handleLogoClick}>
          <BsArrowLeft /> Go Back
        </p>
        <h2 className="application-faq-heading">Frequently Asked Questions</h2>
        <p className="personality-text">{t("undp welcome")}</p>
        <Card className="personality-card">
          <Row>
            {state.Application &&
              state.Application.FAQ &&
              state.Application.FAQ.map((faq, index) => (
                <Container>
                  <Card
                    onClick={() => toggle(index)}
                    style={{
                      marginBottom: "1rem",
                      padding: "2%",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <Col>
                      <p className="faq-question d-flex justify-content-between">
                        {" "}
                        <span> {faq.question}</span>
                        <span className="font-weight-bold">+</span>{" "}
                      </p>
                      <p>
                        <Collapse isOpen={isOpen !== false && isOpen === index}>
                          <hr />
                          <span className="faq-answer">{faq.solution}</span>
                        </Collapse>
                      </p>
                    </Col>
                  </Card>
                </Container>
              ))}
          </Row>
        </Card>
      </Col>
    </Layout>
  );
};

export default Faq;
