import React, { useState, useEffect } from "react";
import styles from "./SideDrawer.module.scss";
import BackDrop from "src/components/Layout/BackDrop/BackDrop";
import { isTefMentorOrMentee } from "src/components/Layout/SideBar/SideBar";
// import SearchBar from "src/components/SearchBar/SearchBar";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { MdHome } from "react-icons/md";
import { RiUserSettingsLine } from "react-icons/ri";
import SideNavItem from "src/components/Layout/SideBar/SideNavItem/SideNavItem";
import { getTrainingRoute } from "src/modules/Flags/urlFlagParams";
import {
  FaShoppingCart,
  FaCalendarCheck,
  FaTools,
  FaChalkboardTeacher,
  FaAsterisk,
  FaFileInvoice,
  FaSearch,
  FaBullhorn,
} from "react-icons/fa";
import { BsChatDots } from "react-icons/bs";
import { ReactComponent as Connections } from "src/assets/img/sidebar/Connections.svg";
import { ReactComponent as Mentorship } from "src/assets/img/sidebar/Mentorship.svg";
import { ReactComponent as Community } from "src/assets/img/sidebar/Community.svg";
import UserSection from "src/components/Layout/SideBar/UserSection/UserSection";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { useTranslation } from "react-i18next";
import WalthroughWizard from "src/components/WalkthroughWizard/WalkthroughWizard";
import isAlumniUser from "src/utils/isAlumni";
import isDmsUser from "src/utils/isDms";
import { Link } from "react-router-dom";

const SideDrawer = ({ show, onClose }) => {
  const path = window.location.pathname;
  const { token, user } = get_user_info_from_token();
  const currentUser = useCurrentUser();
  const { t } = useTranslation();
  const tourState = [
    {
      selector: ".mobile-tour",
      content: `TEF Flagship Programme opens 1st of January,
      2021, cick here to start application.`,
    },
  ];
  const [isTourOpen, setIsTourOpen] = useState(false);
  useEffect(() => {
    const disableWalkthrough = process.env.REACT_APP_DISABLE_WALKTHROUGH;
    if (disableWalkthrough === "false") {
      if (show) {
        const displayTour = localStorage.getItem("showSideBarTour");
        if (!displayTour) {
          setIsTourOpen(true);
          localStorage.setItem("showSideBarTour", 1);
        }
      }
    }
  });

  return (
    <>
      <WalthroughWizard
        tourStep={tourState}
        isOpen={isTourOpen}
        setIsTourOpen={setIsTourOpen}
      />
      <BackDrop show={show} close={onClose} />
      <div
        className={`${styles.SideDrawer} ${
          show ? styles.SideDrawerOpen : styles.SideDrawerHide
        }`}
      >
        {/* <div className={styles.SideDrawer__search}>
          <SearchBar />
        </div> */}
        <UserSection currentUser={currentUser} />
        <ul className={styles.SideDrawer__nav}>
          <SideNavItem
            Icon={MdHome}
            navName="Home"
            linkTo="/home"
            isActive={path.includes("/home")}
          />
          <SideNavItem
            Icon={Connections}
            navName="Connections"
            linkTo="/connections"
            isActive={path === "/connections"}
          />
          <SideNavItem
            Icon={FaCalendarCheck}
            navName="Applications"
            linkTo="/programme/dashboard"
            isActive={path.includes("/programme")}
            otherClassName="mobile-tour"
          />
          <SideNavItem
            Icon={BsChatDots}
            navName="Messages"
            linkTo="/messages"
            isActive={path.includes("/messages")}
          />
          <SideNavItem
            Icon={FaChalkboardTeacher}
            navName="TEFTraining"
            // linkTo="/training"
            linkTo={getTrainingRoute()}
            isActive={path.includes("/training")}
          />
          <SideNavItem
            Icon={FaAsterisk}
            navName="Opportunities"
            linkTo="/opportunities"
            isActive={path.includes("/opportunities")}
          />
          {/* <SideNavItem */}
          {/* Icon={FaCalendar} */}
          {/* navName="Event" */}
          {/* linkTo="/events" */}
          {/* isActive={path.includes("/event")} */}
          {/* /> */}
          <SideNavItem
            Icon={FaSearch}
            navName="Discover"
            linkTo="/discover"
            isActive={path.includes("/discover")}
          />
          {isTefMentorOrMentee(user) && (
            <SideNavItem
              Icon={Mentorship}
              navName="Mentorship"
              linkTo="/mentorship"
              isActive={path.includes("/mentorship")}
            />
          )}
          <SideNavItem
            Icon={Community}
            navName="Community"
            linkTo="/community"
            isActive={path.includes("/community")}
          />
          {isAlumniUser() && (
            <SideNavItem
              Icon={RiUserSettingsLine}
              navName={t("TEF Alumni")}
              linkTo={null}
              dropDown={true}
              isActive={path.includes("/Alumni")}
              redirectInApp={true}
              externalLink={true}
              dropDownList={[
                {
                  name: "Alumni",
                  link: `/alumni`,
                },
                {
                  name: "Opportunities",
                  link: `/alumni-opportunity`,
                },
                {
                  name: "Events",
                  link: `/alumni-events`,
                },
                {
                  name: "Alumni Hubs",
                  link: `/alumni-hub/`,
                },
                {
                  name: "Group Chat",
                  link: `/alumni/chat`,
                },
                {
                  name: "Alumni Resources",
                  link: `/alumni/resources`,
                },

                {
                  name: "Business Reporting",
                  link: `/alumni/business-reporting/`,
                },
              ]}
            />
          )}
          {isDmsUser() && (
            <SideNavItem
              Icon={FaFileInvoice}
              navName="TEF DMS"
              linkTo="/tef-dms"
              isActive={path.includes("/tef-dms")}
            />
          )}
          <SideNavItem
            Icon={FaShoppingCart}
            navName={t("Marketplace")}
            // isCustomer is set to true when redirecting to the customer page and false when redirecting to the merchant page
            linkTo={null}
            isActive={path.includes("/marketplace")}
            externalLink={true}
            dropDown={true}
            dropDownList={
              Object.prototype.hasOwnProperty.call(user, "alumni")
                ? [
                    {
                      name: "Visit Marketplace",
                      link: `${process.env.REACT_APP_MARKET_PLACE_URI_BASE}`,
                    },
                    {
                      name: "Visit Marketplace Admin",
                      link: `${process.env.REACT_APP_MARKET_PLACE_URI_ALUMNUS}?isCustomer=false&token=${token}`,
                    },
                  ]
                : [
                    {
                      name: "Visit Marketplace",
                      link: `${process.env.REACT_APP_MARKET_PLACE_URI_BASE}`,
                    },
                  ]
            }
          />
          <SideNavItem
            Icon={FaTools}
            navName="Tools"
            linkTo="/tools"
            isActive={path.includes("/tools")}
          />
          <SideNavItem
            Icon={FaBullhorn}
            navName="Pitching"
            linkTo={null}
            isActive={false}
            externalLink={true}
            dropDown={true}
            dropDownList={[
              {
                name: "Visit Pitching",
                link: "http://pitch2022.tefconnect.com/login.phpx",
              },
            ]}
          />
          {/* <SideNavItem */}
          {/* Icon={Topics} */}
          {/* navName="Topics" */}
          {/* linkTo="/topics" */}
          {/* isActive={path.includes("/topics")} */}
          {/* /> */}
          {/* <SideNavItem */}
          {/* Icon={FaStar} */}
          {/* navName="TEF All-Star" */}
          {/* linkTo="/all-stars" */}
          {/* isActive={path.includes("/all-stars")} */}
          {/* /> */}
        </ul>
        {/* <h3 className={styles.SideDrawer__navTitle}>Topics you like</h3> */}
        {/* <ul className={styles.SideDrawer__nav}> */}
        {/* <SideNavItem navName="BusinessNG" linkTo="#" isActive={false} /> */}
        {/* <SideNavItem navName="Cocoa Export" linkTo="#" isActive={false} /> */}
        {/* <SideNavItem navName="Project Managers" linkTo="#" isActive={false} /> */}
        {/* </ul> */}

        <div className={styles.bContainer}>
          <ul className={styles.nav}>
            <li className={styles.navItem}>
              <a
                href="https://www.tonyelumelufoundation.org/"
                target="_blank"
                rel="noreferrer"
                className={styles.mainLinkText}
              >
                <span style={{ color: "red" }}> &bull; </span> About TEF
              </a>
            </li>
            <li className={styles.navItem}>
              <Link to="/terms-of-use" className={styles.mainLinkText}>
                <span style={{ color: "red" }}> &bull; </span>Terms Of Use
              </Link>
            </li>
            <li className={styles.navItem}>
              <Link to="/privacy" className={styles.mainLinkText}>
                <span style={{ color: "red" }}> &bull; </span>Privacy Policy
              </Link>
            </li>
            <li className={styles.navItem}>
              <Link to="/feedback" className={styles.mainLinkText}>
                <span style={{ color: "red" }}> &bull; </span>Send Feedback
              </Link>
            </li>
          </ul>
          <span className={styles.navItem}>
            <Link to="#" className={styles.mainLinkText}>
              TEFConnect. All rights reserved. &copy; {new Date().getFullYear()}
            </Link>
          </span>
        </div>
      </div>
    </>
  );
};

export default SideDrawer;
