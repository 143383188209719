import React, { useState } from "react";
import classes from "./Requests.module.scss";
import ReactAvatar from "react-avatar";
import { Button, ButtonGroup } from "reactstrap";
import Loader from "react-loader-spinner";
import Avatars from "src/components/Avatar/Avatar";

const RequestItem = ({
  isSent,
  userInfo,
  groupId,
  groupInfo,
  onAccept,
  onReject,
}) => {
  const [loadingDecline, setLoadingDecline] = useState(false);
  const [loadingAccept, setLoadingAccept] = useState(false);

  const _accept = () => {
    setLoadingAccept(true);
    onAccept(groupInfo.id);
  };

  const _decline = () => {
    setLoadingDecline(true);
    onReject(groupInfo.id, groupInfo.inviteeUserId);
  };

  return (
    <div className={classes.groupRequestsItem}>
      <div className={classes.avatarC}>
        {userInfo.imageUrl ? (
          <Avatars dimension={32} src={userInfo.imageUrl} />
        ) : (
          <ReactAvatar
            name={`${userInfo.firstName} ${userInfo.lastName}`}
            round
            size={32}
          />
        )}
      </div>
      <div className={classes.name}>
        <h6>
          {userInfo.firstName} {userInfo.lastName}
        </h6>
        <ButtonGroup size="sm">
          {isSent && (
            <Button color="primary" outline>
              Pending
            </Button>
          )}
          {!isSent && (
            <>
              <Button
                outline
                disabled={loadingAccept || loadingDecline}
                onClick={_accept}
                className="d-flex"
              >
                Accept
                {loadingAccept && (
                  <Loader
                    type="Oval"
                    height={15}
                    width={15}
                    color="var(--white)"
                    style={{ marginLeft: "2px", float: "float-right" }}
                  />
                )}
              </Button>
              <Button
                color="primary"
                outline
                disabled={loadingDecline || loadingAccept}
                onClick={_decline}
                className="d-flex"
              >
                Decline
                {loadingDecline && (
                  <Loader
                    type="Oval"
                    height={15}
                    width={15}
                    color="var(--white)"
                    style={{ marginLeft: "2px", float: "float-right" }}
                  />
                )}
              </Button>
            </>
          )}
        </ButtonGroup>
      </div>
    </div>
  );
};

export default RequestItem;
