import React, { useEffect, useState, useContext } from "react";
import Layout from "src/components/Layout/Layout";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import {
  Row,
  Col,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useHistory } from "react-router-dom";
// import DefaultImage from "src/assets/img/bg/background_640-1.jpg";
// import { FaCaretDown } from "react-icons/fa";
import Select from "react-select";
import { RiEqualizerLine, RiAddFill } from "react-icons/ri";
// import { getAlumninOpportunities } from "src/requests/AlumniOpportunityRequest";
import { Context } from "src/store";
import { OpportunityCard } from "src/modules/Alumni/Components/Cards/OpportunityCard";
import ArticleNotFound from "src/modules/Alumni/Components/NotFound/ArticleNotFound";
import OpportunityContentLoader from "src/modules/Alumni/Components/SkeletonLoader/opportunityLoader";
// import moment from "moment";
// import { MdClose } from "react-icons/md";
// import Select, { components } from "react-select";
import Drawer, {
  DropdownIndicator,
} from "src/modules/Alumni/Components/Drawer";
// import CustomButtonDrop from "src/modules/Alumni/components/ButtonDropDown/ButtonDropDown";
import {
  getOpportunityCategories,
  getOpportunityTypes,
  getOpportunities,
  getAllFeaturedOpportunities,
} from "src/requests/OpportunityRequest";
// import { Drawer } from "rsuite";
// import Drawer from "rc-drawer";
import countryList from "src/utils/countryList.json";
import FeaturedEvent from "src/modules/Alumni/Components/Slider/EventSlider";
import OpportunityContent from "src/modules/Alumni/Components/Slider/OpportunityContent";
// import "rsuite/dist/styles/rsuite-default.css";
// import 'antd/lib/style';
import "./opportunity.scss";

const AlumniOpportunity = () => {
  const history = useHistory();
  // const [opportunities, setOpportunities] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const { dispatch, state } = useContext(Context);
  // const [showDrawer, setShowDrawer] = useState(false);
  const [searchParams, setSearchParams] = useState({
    country: "",
    opportunityTypeId: "",
    categoryId: "",
    isAlumni: true,
    isFeatured: false,
  });
  const [dropdownOpen, setOpen] = useState(false);
  const [
    fetchingFeaturedOpportunity,
    setFetchingFeaturedOpportunity,
  ] = useState(false);
  const [featuredOpportunity, setFeaturedOpportunity] = useState([]);
  const [requestingOpportunities, setRequestingOpportunities] = useState(false);
  const [verifiedOpportunities, setVerifiedOpportunities] = useState([]);
  const toggle = () => setOpen(!dropdownOpen);
  const drawerToggle = () => {
    const selectDrawer = document.querySelector(".drawer-search ");
    if (!selectDrawer.classList.contains("show-drawer")) {
      selectDrawer.classList.remove("hide-drawer");
      selectDrawer.classList.add("show-drawer");
    } else {
      selectDrawer.classList.add("hide-drawer");
      selectDrawer.classList.remove("show-drawer");
    }
  };
  // const closeDrawer = () => setShowDrawer(false);
  const [pageDetails] = useState({
    pageNumber: 1,
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const getAllFeaturedOpportunity = () => {
    setFetchingFeaturedOpportunity(true);
    getAllFeaturedOpportunities({
      isAlumni: true,
      isFeatured: true,
    }).then((res) => {
      const {
        data: { data },
      } = res;
      const transformData = data.map((item) => {
        return {
          ...item,
          width: "100%",
        };
      });
      setFetchingFeaturedOpportunity(false);
      setFeaturedOpportunity(transformData);
    });
  };
  const handlerSearchParam = (e) => {
    const { value, name } = e.target;
    setSearchParams({ ...searchParams, [name]: value });
  };
  useEffect(() => {
    getAllOpportunities();
    // getOpportunities(dispatch, searchParams);
  }, [searchParams]);

  useEffect(() => {
    getAllFeaturedOpportunity();
  }, []);

  useEffect(() => {
    // getAllOpportunities();
    // getOpportunities(dispatch, { isAlumni: true, isFeatured: false });
    getOpportunityCategories(dispatch);
    getOpportunityTypes(dispatch);
  }, []);

  const getAllOpportunities = (dis) => {
    setRequestingOpportunities(true);
    getOpportunities(dispatch, {
      ...searchParams,
      isAlumni: true,
      isFeatured: false,
    }).then((res) => {
      const {
        data: { data },
      } = res;
      const transformData = data.filter(
        (verifiedOpportunity) => verifiedOpportunity.isVerified
      );
      setRequestingOpportunities(false);
      setVerifiedOpportunities(transformData);
    });
  };
  // const deadLineHumanDate = (deadline) => {
  //   return moment(deadline).format("ll");
  // };

  // const headerStyle = (opportunity) => ({
  //   backgroundImage: `linear-gradient(
  //     to right bottom,
  //     rgba(0,0,0,0.3),
  //     rgba(0,0,0,0.4)
  // ),url(${opportunity.fileName || DefaultImage})`,
  //   backgroundRepeat: "no-repeat",
  //   backgroundPosition: "center",
  //   backgroundSize: "cover",
  //   borderRadius: "10px",
  // });

  const selectHandler = (selectedItem, key) => {
    setSearchParams({ ...searchParams, [key]: selectedItem.value });
  };

  const { categories, types } = state.Opportunities;
  const categoryObjData =
    categories &&
    categories.map((category) => {
      return {
        label: category.name,
        value: category.id,
      };
    });
  const categoryObj = [{ label: "All", value: "" }, ...categoryObjData];
  const typesObjData = types.map((t) => {
    return {
      label: t.name,
      value: t.id,
    };
  });
  const typesObj = [{ label: "All", value: "" }, ...typesObjData];
  const countryObjData =
    countryList &&
    countryList.map((country) => {
      return {
        value: country.countryShortCode,
        label: country.countryName,
      };
    });
  const countryObj = [{ label: "All", value: "" }, ...countryObjData];
  return (
    <Layout SideContent={<SidebarRight />}>
      <div className="add-opp-box">
        {/* <Button
          className="float-right"
          onClick={() => history.push("/alumni-opportunity/create")}
        >
          Create Opportunity
        </Button> */}
        <div className="float-right">
          <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret>Manage Opportunities</DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => history.push("/alumni-opportunity/create")}
              >
                Create Opportunity
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => history.push("/opportunities/my-opportunities")}
              >
                My Opportunities
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </div>
      </div>
      <Drawer
        drawerToggle={drawerToggle}
        typesObj={typesObj}
        categoryObj={categoryObj}
        handlerSearchParam={handlerSearchParam}
        selectHandler={selectHandler}
        countryObj={countryObj}
      />
      <div className="opp-home-heading opp-home-heading-full">
        <p className="opportunity__short-desc">Alumni Opportunities</p>

        {/* <Link to={`${window.location.pathname}/create`} className="link-create">
          <span>Add Opportunities</span>
        </Link> */}
        <div className="search-select">
          <div className="search-boxes mr-3">
            <span className="search-cate opp-search-cate">Category:</span>
            <Select
              isClearable
              isSearchable
              name="color"
              placeholder="All"
              className="custom-opp-select"
              classNamePrefix="opp-select"
              options={categoryObj}
              components={{ DropdownIndicator }}
              onChange={(val) => selectHandler(val, "categoryId")}
              styles={{
                menu: (base) => ({
                  ...base,
                  marginBottom: 76,
                  width: "100%",
                  border: "none",
                  borderRadius: 0,
                }),
              }}
            />
          </div>
          <div className="search-boxes mr-3">
            <span className="search-cate opp-search-cate">Country</span>
            <Select
              isClearable
              isSearchable
              name="color"
              placeholder="All"
              className="custom-opp-select"
              classNamePrefix="opp-select"
              components={{ DropdownIndicator }}
              options={countryObj}
              onChange={(val) => selectHandler(val, "country")}
              styles={{
                menu: (base) => ({
                  ...base,
                  marginBottom: 76,
                  width: "100%",
                  border: "none",
                  borderRadius: 0,
                }),
              }}
            />
          </div>
          <div className="search-boxes">
            <span className="search-cate opp-search-cate">Type:</span>
            <Select
              isClearable
              isSearchable
              name="color"
              placeholder="All"
              className="custom-opp-select"
              classNamePrefix="opp-select"
              components={{ DropdownIndicator }}
              options={typesObj}
              onChange={(val) => selectHandler(val, "opportunityTypeId")}
              styles={{
                menu: (base) => ({
                  ...base,
                  marginBottom: 76,
                  width: "100%",
                  border: "none",
                  borderRadius: 0,
                }),
              }}
            />
          </div>
        </div>
      </div>
      <div className="opp-home-heading-mobile">
        <p className="opportunity__mobile-desc">Alumni Opportunities</p>

        <span className="toggle-search" onClick={drawerToggle}>
          <RiEqualizerLine size={25} />
        </span>

        <span
          className="mobile-add"
          onClick={() => history.push("/alumni-opportunity/create")}
        >
          <RiAddFill color="#fff" size={15} />
        </span>
      </div>

      {fetchingFeaturedOpportunity ? (
        <OpportunityContentLoader />
      ) : featuredOpportunity.length === 0 ? null : ( // replace this  <ArticleNotFound /> with null if need be
        <>
          <Row className="mb-4">
            <Col>
              {featuredOpportunity.length > 0 && (
                <figure className="alumni-opportunity-imgbox">
                  <FeaturedEvent
                    list={featuredOpportunity}
                    SliderContent={OpportunityContent}
                    carouselHeight="365"
                  />
                </figure>
              )}
              {/* <figure
                  className="alumni-opportunity-imgbox"
                  style={headerStyle(opportunity)}
                >
                  <div className="opportunity__slide-wrap">
                    <Button
                      className="alumni-opportunity-imgbox__btn--featured"
                      onClick={() =>
                        history.push(
                          `/alumni-opportunity-detail/${opportunity.id}`
                        )
                      }
                    >
                      Featured
                    </Button>
                    <div className="alumni-opportunity-imgbox__title">
                      <p className="alumni-opportunity-imgbox__title--para">
                        {opportunity.title}
                      </p>
                    </div>
                    <div className="alumni-opportunity-imgbox__metaData">
                      <div className="meta-info">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            color: "white",
                            fontSize: "12px",
                            marginBottom: "10px",
                          }}
                        >
                          <span className="alumni-opportunity-imgbox__icon">
                            <FiClock size={16} />
                          </span>
                          <span className="opport-deadline-fund">
                            Deadline: {deadLineHumanDate(opportunity.deadline)}
                          </span>
                        </div>
                      </div>

                      <Button
                        className="view-opportunity"
                        style={{ fontSize: "12px" }}
                        onClick={() =>
                          history.push(
                            `/alumni-opportunity-detail/${opportunity.id}`
                          )
                        }
                      >
                        View Detail
                      </Button>
                    </div>
                  </div>
                </figure> */}
            </Col>
          </Row>
        </>
      )}

      {requestingOpportunities ? (
        <OpportunityContentLoader />
      ) : verifiedOpportunities.length === 0 ? (
        <ArticleNotFound />
      ) : (
        <div className="alumni-opp-list">
          {verifiedOpportunities.map((opportunity, idx) => (
            <OpportunityCard opportunity={opportunity} key={idx} />
          ))}
        </div>
      )}
      {pageDetails.hasNextPage && (
        <Row>
          <Button
            // disabled={isLoading}
            // onClick={_onLoadMoreClick}
            className="loadmore-btn"
          >
            Show more
          </Button>
        </Row>
      )}
    </Layout>
  );
};

export default ifOnboardedThenShow(AlumniOpportunity);
