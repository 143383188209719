/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useContext } from "react";
import { EditorBox } from "src/components/Editor";
import classes from "./CreateQuestion_v2.module.scss";
import postStyles from "src/modules/homepage/components/CreatePost/CreatePostModal/CreatePostForm.module.scss";
import {
  Button,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Spinner,
  FormText,
} from "reactstrap";
// import { FaTimesCircle } from "react-icons/fa";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createQuestion } from "src/requests/PostRequest";
import { Context } from "src/store";
import StatusModal from "src/components/StatusModal/StatusModal";

const CreateQuestionForm = ({ togglePostModal }) => {
  const [content, setContent] = useState("");
  const [options, setOptions] = useState(["", ""]);
  const [expiryDate, setExpiryDate] = useState("");
  const [expiryDateValue, setExpiryDateValue] = useState("1day");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const inputRef = useRef();
  const { dispatch } = useContext(Context);
  const [alertDetails, setAlertDetails] = useState({
    alertMessage: "",
    alertType: "",
  });
  const clearField = () => {
    inputRef.current.clearInput();
    setOptions(["", ""]);
  };

  const currentDate = new Date();
  // let errRef = useRef();

  const _handleOptionChange = (idx, e) => {
    const { value } = e.target;
    const opts = [...options];
    opts[idx] = value;

    setOptions([...opts]);
  };

  useEffect(() => {
    computeDays(expiryDateValue);
  }, [expiryDateValue]);

  const computeDays = (value) => {
    switch (value) {
      case "1day":
        setExpiryDate(new Date(currentDate.setDate(currentDate.getDate() + 1)));
        break;
      case "3days":
        setExpiryDate(new Date(currentDate.setDate(currentDate.getDate() + 3)));
        break;
      case "1week":
        setExpiryDate(new Date(currentDate.setDate(currentDate.getDate() + 7)));
        break;
      case "2weeks":
        setExpiryDate(
          new Date(currentDate.setDate(currentDate.getDate() + 2 * 7))
        );
        break;

      default:
        setExpiryDate(new Date(currentDate.setDate(currentDate.getDate() + 1)));
        break;
    }
  };
  const _addMoreOption = () => {
    const opts = [...options];
    if (opts.length >= 4) {
      return;
    }
    opts.push("");
    setOptions([...opts]);
  };

  const remove = (idx) => {
    const opts = [...options];
    if (opts.length <= 2) return;
    const filtered = opts.filter((o, i) => i !== idx);
    setOptions([...filtered]);
  };

  const _handleExpiryDateSelect = (e) => {
    const { value } = e.target;
    setExpiryDateValue(value);
  };

  // const _handleDateSelect = (dateString) => setExpiryDate(dateString);

  const hasMinOption = () => options.filter((option) => option);
  const hasValidationError = () => {
    if (!content) {
      setError("Please enter a question");
      return true;
    }
    if (hasMinOption().length < 2) {
      setError("Please add atleast two options");
      return true;
    }
    return false;
  };

  const dismissAlert = () => {
    setAlertDetails({ alertMessage: "", alertType: "" });
  };

  const onSubmit = () => {
    if (hasValidationError()) {
      return;
    }

    setError("");
    setLoading(true);
    const payload = {
      post: {
        content: content,
        accessibleBy: 0,
        expireDate: expiryDate,
      },
      options: options.filter((op) => op).map((opt) => ({ content: opt })),
      files: [],
    };

    createQuestion(dispatch, payload)
      .then((res) => {
        const { status, data } = res;
        if (status !== 200) {
          setLoading(false);
          const { message } = data;
          setAlertDetails({
            ...alertDetails,
            alertMessage: message,
            alertType: "danger",
            showButton: true,
            buttonClick: (e) => dismissAlert(),
            buttonText: "Close",
            // title: "Error",
          });
        } else {
          setLoading(false);
          setAlertDetails({
            ...alertDetails,
            alertMessage: res.data.message,
            alertType: "success",
          });

          clearField();
          setAlertDetails({
            // ...alertDetails,
            alertMessage: res.data.message,
            alertType: "success",
            showButton: false,
          });
          setTimeout(() => {
            dismissAlert();
            togglePostModal();
          }, 1000);
        }
      })
      .catch((_error) => {
        setLoading(false);
        setAlertDetails({
          ...alertDetails,
          alertMessage: "Something went wrong, please contact support",
          alertType: "danger",
        });
      });
  };

  return (
    <div className={classes.container}>
      <EditorBox
        ref={inputRef}
        onEditorChange={setContent}
        content={content}
        placeholder={"What is your Question \nE.g How do you raise capital?"}
        className={postStyles.textField}
        optionsClassName={classes.options}
        useEmoji
        addtionalOptions={
          <div className={classes.buttons}>
            <Button disabled={loading} onClick={onSubmit}>
              Post{" "}
              {loading && <Spinner style={{ height: "20px", width: "20px" }} />}
            </Button>
          </div>
        }
        additionalContentClass={classes.contentContainer}
        additionalContent={
          <Row>
            <Col md={6}>
              {options.map((option, idx) => {
                return (
                  <FormGroup key={idx} className={classes.form_group}>
                    <Label className={classes.f_label}>
                      <div>Option {idx + 1}</div>{" "}
                      {options.length > 2 && (
                        <div
                          className={classes.remove}
                          onClick={() => remove(idx)}
                        >
                          Remove
                        </div>
                      )}
                    </Label>
                    <div className={classes.inputContainer}>
                      <Input
                        value={option}
                        onChange={(e) => _handleOptionChange(idx, e)}
                        className={classes.option_question}
                        placeholder={`option ${idx + 1}`}
                      />
                      {/* {options.length > 2 && (
                        <FaTimesCircle
                          size={21}
                          style={{ marginLeft: "5px" }}
                          onClick={() => remove(idx)}
                        />
                      )} */}
                    </div>
                  </FormGroup>
                );
              })}

              {error && (
                <FormText
                  // ref={(ref) => (errRef = ref)}
                  color="secondary"
                >
                  {error}
                </FormText>
              )}

              {options.length < 4 && (
                <span
                  className={classes.add_more_text}
                  onClick={_addMoreOption}
                >
                  + Add more option
                </span>
              )}
            </Col>

            <Col md={6}>
              <FormGroup
                className={classes.form_group}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Label className={classes.f_label}>Poll Duration</Label>
                {/* <DatePicker
                  className={`${classes.option_question} ${classes.picker}`}
                  placeholderText="YYYY-MM-DD"
                  dateFormat="yyyy-MM-dd"
                  selected={expiryDate}
                  onChange={_handleDateSelect}
                  minDate={currentDate.setDate(currentDate.getDate() + 1)}
                  maxDate={currentDate.setDate(currentDate.getDate() + 2 * 7)}
                  isClearable
                  required
                /> */}
                <Input
                  type="select"
                  className={classes.option_question}
                  value={expiryDateValue}
                  onChange={_handleExpiryDateSelect}
                >
                  <option value={"1day"}>1 day</option>
                  <option value={"3days"}>3 days</option>
                  <option value="1week">1 week</option>
                  <option value="2weeks">2 weeks</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
        }
      />

      <div className={classes.warningText}>
        We will not allow requests for political opinions or other sensitive
        data.
      </div>
      <StatusModal
        status={alertDetails.alertType}
        open={!!alertDetails.alertMessage && !!alertDetails.alertType}
        heading={alertDetails.title && alertDetails.title}
        text={alertDetails.alertMessage && alertDetails.alertMessage}
        showButton={alertDetails.showButton}
        buttonText={alertDetails.buttonText}
        onButtonClick={alertDetails.buttonClick}
        toggle={() =>
          setAlertDetails({
            alertMessage: "",
            alertType: "",
          })
        }
      />
    </div>
  );
};

export default CreateQuestionForm;
