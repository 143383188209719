export const titles = [
  { label: "Mr", value: "Mr" },
  { label: "Mrs", value: "Mrs" },
  { label: "Ms", value: "Ms" },
  { label: "Dr", value: "Dr" },
  { label: "Prof", value: "Prof" },
];
export const languages = [
  { label: "English", value: "en" },
  { label: "French", value: "fr" },
  { label: "Portuguese", value: "pt" },
  { label: "Arabic", value: "ar" },
  // { label: "Spanish", value: "es" },
];
export const religions = [
  { label: "Christianity", value: "Christianity" },
  { label: "Islam", value: "Islam" },
  // { label: "Others", value: "Others" },
];

export const qualities = [
  {
    label: "Good character (honesty, integrity, etc)",
    value: "Good character",
  },
  { label: "Experienced", value: "Experienced" },
  { label: "Open minded", value: "Open minded" },
  { label: "Inquisitive", value: "Inquisitive" },
  { label: "Good listener", value: "Good listener" },
  { label: "Approachable/Available", value: "Approachable/Available" },
  { label: "Willingness to share", value: "Willingness to share" },
];

export const industryOfExperience = [
  {
    label: "Agriculture",
    value: "Agriculture",
  },
  { label: "Fashion", value: "Fashion" },
  { label: "Aviation", value: "Aviation" },
  { label: "Financial Services", value: "Financial Services" },
  { label: "Commercial/Retail", value: "Commercial/Retail" },
  { label: "Construction", value: "Construction" },
  { label: "FMCG", value: "FMCG" },
  { label: "Haulage/ Logistics", value: "Haulage/ Logistics" },
  { label: "Healthcare", value: "Healthcare" },
  { label: "Education and Training", value: "Education and Training" },
  { label: "Media and Entertainment", value: "Media and Entertainment" },
  { label: "Oil & Gas", value: "Oil & Gas" },
  { label: "Professional Services", value: "Professional Services" },
  { label: "Tourism/Hospitality", value: "Tourism/Hospitality" },
  { label: "Legal Services / Law", value: "Legal Services / Law" },
  {
    label: "Forestry and Wood Processing",
    value: "Forestry and Wood Processing",
  },
  { label: "Mining", value: "Mining" },
];

export const numbers = (value) => {
  const numbers = [];
  for (let i = 1; i <= value; i++) {
    numbers.push({ label: i, value: i });
  }
  return numbers;
};

export const noOfPeopleMentored = [
  { label: "None", value: "None" },
  { label: "1-10 people", value: "1-10 people" },
  { label: "11-20 people", value: "11-20 people" },
  { label: "21-30 people", value: "21-30 people" },
  { label: "More than 30 people", value: "More than 30 people" },
];

export const periodMentored = [
  { label: "Never", value: "Never" },
  { label: "Less than 1 year", value: "Less than 1 year" },
  { label: "1 – 3 years", value: "1-3 years" },
  { label: "4 – 6 years", value: "4-6 years" },
  { label: "6 + years", value: "6 + years" },
];

export const canMentorHours = [
  { label: "1 – 3 Hours", value: "1-3 Hours" },
  { label: "4 – 6 Hours", value: "4-6 Hours" },
  { label: "6 + Hours", value: "6+ Hours" },
];
export const yearsOfTefMentor = [
  { label: "5 years", value: "5 years" },
  { label: "4 years", value: "4 years" },
  { label: "3 years", value: "3 years" },
  { label: "2 years", value: "2 years" },
  { label: "1 year", value: "1 year" },
];

export const motivations = [
  {
    label: "I want to be affiliated with the Tony Elumelu Foundation",
    value: "I want to be affiliated with the Tony Elumelu Foundation",
  },
  {
    label: "I want to play my part by empowering African entrepreneurs",
    value: "I want to play my part by empowering African entrepreneurs",
  },
  {
    label: "I want to foster new relationships",
    value: "I want to foster new relationships",
  },
];

export const meansOfInformation = [
  {
    label: "I am an existing TEF mentor",
    value: "I am an existing TEF mentor",
  },
  { label: "TEF Newsletter", value: "TEF Newsletter" },
  { label: "TEF Website", value: "TEF Website" },
  { label: "LinkedIn", value: "LinkedIn" },
  { label: "Facebook", value: "Facebook" },
  { label: "Twitter", value: "Twitter" },
  { label: "Instagram", value: "Instagram" },
  { label: "Referral from TEF mentor", value: "Referral from TEF mentor" },
];

export const trueAndFalse = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const preferedMeansOfCom = [
  { label: "Phone call", value: "Phone call" },
  { label: "Email", value: "Email" },
  { label: "Whatsapp", value: "Whatsapp" },
  { label: "Face to Face", value: "Face to Face" },
  { label: "Video call", value: "Video call" },
  { label: "Social Media", value: "Social Media" },
];
