import React, { useEffect } from "react";
import Tour from "reactour";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

const WalkThroughWizard = ({ tourStep, isOpen, setIsTourOpen }) => {
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);
  return (
    <Tour
      steps={tourStep}
      isOpen={isOpen}
      onRequestClose={() => setIsTourOpen(false)}
      onAfterOpen={disableBody}
      onBeforeClose={enableBody}
      rounded={5}
    />
  );
};

export default WalkThroughWizard;
