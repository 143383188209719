import React, { useState } from "react";
import classes from "./NewAuthLayout_v2.module.scss";
import tef_logo from "src/assets/img/logo/logo_new.webp";
import { FiChevronDown } from "react-icons/fi";
import { Link } from "react-router-dom";
import AboutMenu from "./MenuDropdown";
import { RiMenu3Fill } from "react-icons/ri";
import ClickOutside from "react-click-outside-component";
import useWindowDimensions from "src/utils/useWindowDimensions";
import { LanguageDropdown } from "src/components/LanguageDropdown/LanguageDropdown";
/* eslint-disable no-new */

/**
 * Min viewport width (in pixels) to keep Google Translate in the header.
 * We can't use CSS to hide the `<div>` because then it does not render in the footer.
 * Probably two elements with the same `id` are the problem. Thus we have to
 * use a JSX condition based on viewport width.
 */
export const GOOGLE_TRANSLATE_IN_HEADER_MIN_WIDTH = 550;

const AuthLayoutHeader = () => {
  const [activedd, setActivedd] = useState(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { width: viewportWidth } = useWindowDimensions();

  const ddList = {
    about: "about",
  };

  const toggleMenuDD = (menu) => {
    if (activedd === menu) {
      setActivedd(null);
    } else {
      setActivedd(menu);
    }
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
    toggleMenuDD(null);
  };

  return (
    <div className={classes.headerContainer}>
      <div className={classes.logo_container}>
        <Link to="/">
          <img src={tef_logo} className={classes.tef_logo} />
        </Link>
      </div>
      <div className={classes.menu_container}>
        <button
          className={classes.aboutbtn}
          onClick={() => toggleMenuDD(ddList.about)}
        >
          About <FiChevronDown />
          <AboutMenu
            isOpen={activedd === ddList.about}
            toggle={toggleMenuDD}
            title={ddList.about}
          />
        </button>
        {viewportWidth >= GOOGLE_TRANSLATE_IN_HEADER_MIN_WIDTH && (
          <LanguageDropdown />
        )}
        <Link to="/login">
          <button className={classes.signinbtn} data-testid="signin">
            Sign in
          </button>
        </Link>
        <Link to="/signup">
          <button className={classes.signupbtn} data-testid="signup">
            Sign up
          </button>
        </Link>

        <div className={classes.menuIcon} data-testid="menu">
          <RiMenu3Fill size={25} onClick={toggleMobileMenu} />
        </div>
      </div>
      {showMobileMenu && (
        <ClickOutside onClickOutside={toggleMobileMenu}>
          <div className={classes.mobile_menu}>
            <button
              className={classes.mobile_aboutus_btn}
              onClick={() => toggleMenuDD(ddList.about)}
            >
              About <FiChevronDown />
              <AboutMenu
                isOpen={activedd === ddList.about}
                toggle={toggleMenuDD}
                title={ddList.about}
              />
            </button>
            <Link to="/login">
              <button
                className={classes.mobile_signin_btn}
                data-testid="mobile_signin"
              >
                Sign in
              </button>
            </Link>
            <Link to="/signup">
              <button
                className={classes.mobile_signup_btn}
                data-testid="mobile_signup"
              >
                Sign up
              </button>
            </Link>
          </div>
        </ClickOutside>
      )}
    </div>
  );
};

export default AuthLayoutHeader;
