import React, { useState } from "react";
import classes from "./FullDescription.module.css";
import SubHeading from "src/modules/Opportunity/components/SubHeading/SubHeading";
import DOMPurify from "dompurify";
import { MdDescription, MdEmail } from "react-icons/md";
import { BsListCheck } from "react-icons/bs";
import { FiGift } from "react-icons/fi";
import { FcWebcam } from "react-icons/fc";
import { useTranslation } from "react-i18next";

const FullDescription = ({
  fullDescription,
  eligibilityRequirement,
  benefits,
  contactEmail,
  officialWebsite,
}) => {
  const [expanded, setExpanded] = useState(false);

  const continueReading = () => {
    setExpanded(!expanded);
  };
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div
        className={
          expanded
            ? `${classes.specialText}-expanded`
            : `${classes.specialText}`
        }
      >
        <div>
          <SubHeading
            text="Full Description"
            icon={<MdDescription color={`#313645`} />}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(fullDescription),
            }}
          ></div>
        </div>
        <div>
          <SubHeading
            text="Eligibility Requirement"
            icon={<BsListCheck color={`#313645`} />}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(eligibilityRequirement),
            }}
          ></div>
        </div>
        <div>
          <SubHeading
            text="Benefits"
            icon={<FiGift style={{ color: "#313645" }} />}
          />
          <div
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(benefits) }}
          ></div>
        </div>
        <div>
          <SubHeading text="Contact Email" icon={<MdEmail color="#313645" />} />
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(contactEmail),
            }}
          ></div>
        </div>
        <div>
          <SubHeading text="Official Website" icon={<FcWebcam color="red" />} />
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(officialWebsite),
            }}
          ></div>
        </div>
      </div>
      <button className={classes.expandButton} onClick={continueReading}>
        {expanded ? `${t("Show Less Details")}` : `${t("Show All Details")}`}
      </button>
    </div>
  );
};

export default FullDescription;
