import React from "react";
import classes from "./VideoPlayer.module.css";

const VideoPlayer = ({
  item: { youtubeLink, featureVideoUrl, featureImageUrl },
}) => {
  return (
    <div className={classes.videoContainer}>
      <video
        id="tef-masterclass-video"
        controls
        preload="auto"
        poster={featureImageUrl}
        className={classes.Videoplayer}
        autoPlay
        src={youtubeLink || featureVideoUrl}
      >
        <source src={youtubeLink} type="video/mp4" />
        <p>Your browser does not support the video type</p>
      </video>
      {/* <div className={classes.Caption}>Video Title</div> */}
      <div className={classes.Control}>
        <div className={classes.BottomControl}>
          <div className={classes.ButtonPlay} title="Play/Pause video">
            <span className={classes.IconPlay}></span>
          </div>
          <div className={classes.ProgressBar}>
            <div className={classes.Progress}>
              <span className={classes.BufferBar}></span>
              <span className={classes.Timebar}></span>
            </div>
          </div>
          <div className="sound sound2 btn" title="Mute/Unmute sound">
            <span className="icon-sound"></span>
          </div>
          <div className="btnFS btn" title="Switch to full screen">
            <span className="icon-fullscreen"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
