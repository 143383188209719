import "./tefScore.scss";
import React, { useContext, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Context } from "src/store";
import { getMenteeTrainingScore } from "src/requests/TrainingRequest";
import Loader from "react-loader-spinner";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const TefScore = ({ title, userId, progammeEventId }) => {
  const { state, dispatch } = useContext(Context);
  const user = get_user_info_from_token().user;

  useEffect(() => {
    getMenteeTrainingScore(dispatch, {
      userId,
      trainingEventId: user.id === userId ? user.training[1] : progammeEventId,
    });
  }, [userId, progammeEventId]);

  return (
    <React.Fragment>
      <div className="tef-score-card">
        {title && <h1>{title}</h1>}

        {state.Training.loadingTrainingScore ? (
          <Loader color="red" type="Oval" height={30} width={30} />
        ) : (
          <>
            {state.Training.menteeTrainingScore ? (
              <div className="tef-score-card__outter-ring">
                <CircularProgressbar
                  value={Number(
                    state.Training.menteeTrainingScore.score
                  ).toFixed(2)}
                  text={`${Number(
                    state.Training.menteeTrainingScore.score
                  ).toFixed(2)}%`}
                  strokeWidth={5}
                  styles={buildStyles({
                    backgroundColor: "#3e98c7",
                    pathColor: `#ffc46d`,
                    textColor: "#0b1525",
                    textSize: "20px",
                  })}
                />
              </div>
            ) : (
              <span>No Record found</span>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default TefScore;
