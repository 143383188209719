import { asyncActionName } from "src/utils/AsyncUtils";
import {
  CREATE_PROGRAMME,
  GET_ALL_PROGRAMMES,
  DELETE_PROGRAMME,
} from "src/actions";
import { initialState } from "src/store";

const ProgrammeReducer = (
  state = initialState.programmes,
  { type, payload }
) => {
  switch (type) {
    case asyncActionName(CREATE_PROGRAMME).loading:
      return { ...state, loading: payload };
    case asyncActionName(CREATE_PROGRAMME).success:
      return {
        ...state,
        programme: payload.data,
        success: true,
      };
    case asyncActionName(CREATE_PROGRAMME).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_ALL_PROGRAMMES).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_ALL_PROGRAMMES).success:
      return {
        ...state,
        programmes: payload,
        success: true,
      };
    case asyncActionName(GET_ALL_PROGRAMMES).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(DELETE_PROGRAMME).loading:
      return { ...state, loading: payload };
    case asyncActionName(DELETE_PROGRAMME).success:
      return {
        ...state,
        programme: payload,
        success: true,
      };
    case asyncActionName(DELETE_PROGRAMME).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    default:
      return state;
  }
};

export default ProgrammeReducer;
