export const howYouHearAboutUs = {
  en: [
    { value: "radio", label: "Radio" },
    { value: "tv", label: "TV" },
    { value: "social media", label: "Social Media" },
    { value: "friends and family", label: "Friends & Family" },
    {
      value: "association or community",
      label: "Association or Community",
    },
    {
      value: "TEFConnect website",
      label: "TEFConnect Website",
    },
    { value: "newspaper", label: "Newspaper" },
    { value: "I got an email", label: "I got an email" },
    { value: "NYSC", label: "NYSC CDS Group/Event" },
    { value: "Others", label: "Not listed" },
  ],

  ar: [
    { value: "radio", label: "الراديو." },
    { value: "tv", label: "تلفزيون." },
    { value: "social media", label: "الوسائط الاجتماعية." },
    { value: "friends and family", label: "الأصدقاء والعائلة." },
    {
      value: "association or community",
      label: "جمعية أو جماعة.",
    },
    {
      value: "TEFConnect website",
      label: "موقع ويب TEFConnect. ",
    },
    { value: "newspaper", label: "جريدة." },
    { value: "I got an email", label: "تلقيت بريدًا إلكترونيًا " },
    { value: "Others", label: "غير مدرج" },
  ],

  fr: [
    { value: "radio", label: "Radio" },
    { value: "tv", label: "TV" },
    { value: "Les médias sociaux", label: "Les médias sociaux" },
    { value: "Amis et famille", label: "Amis et famille" },
    {
      value: "Association ou communauté",
      label: "Association ou communauté",
    },
    { value: "Site web de TEFConnect", label: "Site web de TEFConnect" },
    // { value: "Journal", label: "Journal" },
    { value: "J'ai reçu un courriel", label: "J'ai reçu un courriel" },
    { value: "Others", label: "Non listé" },
  ],
  pt: [
    { value: "radio", label: "Radio" },
    { value: "tv", label: "TELEVISÃO" },
    { value: "Mídia social", label: "Mídia social" },
    { value: "Família de amigos", label: "Família de amigos" },
    {
      value: "Associação ou Comunidade",
      label: "Associação ou Comunidade",
    },
    {
      value: "Site TEFConnect",
      label: "Site TEFConnect",
    },
    { value: "Jornal", label: "Jornal" },
    { value: "Recebi um e-mail", label: "Recebi um e-mail" },
    { value: "Others", label: "Não listado" },
  ],
  sw: [
    { value: "radio", label: "Redio" },
    { value: "tv", label: "Runinga" },
    { value: "social media", label: "Mitandao ya Kijamii" },
    { value: "friends and family", label: "Marafiki & Familia" },
    { value: "association or community", label: "Chama au Jumuiya" },
    { value: "TEFConnect website", label: "Tovuti ya TEFConnect" },
    { value: "newspaper", label: "Gazeti" },
    { value: "I got an email", label: "Nilipata barua pepe" },
    { value: "NYSC", label: "Kikundi/Tukio la NYSC CDS" },
    { value: "Others", label: "Siyo Kwenye Orodha" },
  ],
};
