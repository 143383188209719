import axios from "axios";
import { toast } from "react-toastify";
import { asyncActions } from "src/utils/AsyncUtils";
import { ToolsURL } from "src/constants";
import {
  GET_ALL_TOOLS_CATEGORIES,
  GET_ALL_TOOLS,
  GET_TOOL_CATEGORY,
  FILTER_TOOL_LIST,
  CREATE_TOOL,
  GET_TOOL_DETAILS,
  POST_TOOL_COMMENT,
  GET_TOOL_COMMENTS,
  LIKE_TOOL_COMMENT,
  DELETE_TOOL_COMMENT,
  INCREASE_TOOL_VIEW,
  INCREASE_TOOL_POPULARITY,
  GET_TOOLS_BASED_ON_POPULARITY,
  GET_CATEGORY_NAME,
  GET_TOOL_VIEWERS,
  ADD_TOOL_NOTIFICATION,
} from "src/actions";

export const getToolViewers = (dispatch, id) => {
  dispatch(asyncActions(GET_TOOL_VIEWERS).loading(true));
  return axios({
    method: "get",
    url: `${ToolsURL.GET_TOOL_VIEWERS_URL}?toolId=${id}`,
  });
};

export const getToolsCategories = (dispatch) => {
  dispatch(asyncActions(GET_ALL_TOOLS_CATEGORIES).loading(true));
  return axios({
    method: "get",
    url: `${ToolsURL.GET_ALL_TOOLS_CATEGORIES_URL}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_ALL_TOOLS_CATEGORIES).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_ALL_TOOLS_CATEGORIES).success(data));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_ALL_TOOLS_CATEGORIES).loading(false));
      dispatch(
        asyncActions(GET_ALL_TOOLS_CATEGORIES).failure(true, error.response)
      );
      return error.response;
    });
};

export const getToolsBasedOnPopularity = (dispatch) => {
  dispatch(asyncActions(GET_TOOLS_BASED_ON_POPULARITY).loading(true));
  return axios({
    method: "get",
    url: `${ToolsURL.GET_TOOLS_BASED_ON_POPULARITY_URL}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_TOOLS_BASED_ON_POPULARITY).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_TOOLS_BASED_ON_POPULARITY).success(data));
        dispatch(asyncActions(GET_TOOLS_BASED_ON_POPULARITY).failure(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_TOOLS_BASED_ON_POPULARITY).loading(false));
      dispatch(
        asyncActions(GET_TOOLS_BASED_ON_POPULARITY).failure(
          true,
          error.response
        )
      );
      return error.response;
    });
};

export const getAllTools = (dispatch, pageNumber, pageSize) => {
  dispatch(asyncActions(GET_ALL_TOOLS).loading(true));
  return axios({
    method: "get",
    url: `${ToolsURL.GET_ALL_TOOLS_URL}?PageNumber=${pageNumber}&PageSize=${pageSize}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_ALL_TOOLS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_ALL_TOOLS).success(data));
        dispatch(asyncActions(GET_ALL_TOOLS).failure(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_ALL_TOOLS).loading(false));
      dispatch(asyncActions(GET_ALL_TOOLS).failure(true, error.response));
      return error.response;
    });
};

export const getToolDetails = (dispatch, id) => {
  dispatch(asyncActions(GET_TOOL_DETAILS).loading(true));
  return axios({
    method: "get",
    url: `${ToolsURL.GET_TOOL_DETAILS_URL}/${id}`,
  });
  // .then((response) => {
  //   dispatch(asyncActions(GET_TOOL_DETAILS).loading(false));
  //   if (response.status === 200) {
  //     const { data } = response.data;
  //     dispatch(asyncActions(GET_TOOL_DETAILS).success(data));
  //     dispatch(asyncActions(GET_TOOL_DETAILS).failure(false));
  //   }
  //   return response;
  // })
  // .catch((error) => {
  //   dispatch(asyncActions(GET_TOOL_DETAILS).loading(false));
  //   dispatch(asyncActions(GET_TOOL_DETAILS).failure(true, error.response));
  //   return error.response;
  // });
};

export const filterToolList = (dispatch, id) => {
  dispatch(asyncActions(FILTER_TOOL_LIST).loading(true));
  return axios({
    method: "get",
    url: `${ToolsURL.FILTER_TOOL_LIST_URL}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(FILTER_TOOL_LIST).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(FILTER_TOOL_LIST).success(data));
        dispatch(asyncActions(FILTER_TOOL_LIST).failure(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(FILTER_TOOL_LIST).loading(false));
      dispatch(asyncActions(FILTER_TOOL_LIST).failure(true, error.response));
      return error.response;
    });
};

export const getToolCategory = (dispatch, id) => {
  dispatch(asyncActions(GET_TOOL_CATEGORY).loading(true));
  return axios({
    method: "get",
    url: `${ToolsURL.GET_TOOL_CATEGORY_URL}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_TOOL_CATEGORY).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_TOOL_CATEGORY).success(data));
        dispatch(asyncActions(GET_TOOL_CATEGORY).failure(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_TOOL_CATEGORY).loading(false));
      dispatch(asyncActions(GET_TOOL_CATEGORY).failure(true, error.response));
      return error.response;
    });
};

export const addToolNotification = (dispatch, payload) => {
  dispatch(asyncActions(ADD_TOOL_NOTIFICATION).loading(true));
  return axios({
    method: "POST",
    url: `${ToolsURL.ADD_TOOL_NOTIFICATION_URL}`,
    data: payload,
  });
};

export const createTool = (dispatch, payload) => {
  dispatch(asyncActions(CREATE_TOOL).loading(true));
  return axios({
    method: "POST",
    url: `${ToolsURL.CREATE_TOOL_URL}`,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(CREATE_TOOL).loading(false));
      if (response.status === 200 || response.status === 201) {
        dispatch(asyncActions(CREATE_TOOL).failure(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(CREATE_TOOL).loading(false));
      dispatch(asyncActions(CREATE_TOOL).failure(true, error.response));
      return error.response;
    });
};

export const postComment = (dispatch, payload) => {
  dispatch(asyncActions(POST_TOOL_COMMENT).loading(true));
  return axios({
    method: "POST",
    url: `${ToolsURL.POST_TOOL_COMMENT_URL}`,
    data: {
      toolId: payload.toolId,
      message: payload.message,
      fileUploadNames: [""],
    },
  })
    .then((response) => {
      dispatch(asyncActions(POST_TOOL_COMMENT).loading(false));
      if (response.status === 200 || response.status === 201) {
        dispatch(asyncActions(POST_TOOL_COMMENT).failure(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(POST_TOOL_COMMENT).loading(false));
      dispatch(asyncActions(POST_TOOL_COMMENT).failure(true, error.response));
      return error.response;
    });
};

export const getComments = (dispatch, payload) => {
  dispatch(asyncActions(GET_TOOL_COMMENTS).loading(true));
  return axios({
    method: "GET",
    url: `${ToolsURL.GET_TOOL_COMMENTS_URL}/${payload}/discussions`,
  });
};

export const likeComment = (dispatch, commentId) => {
  dispatch(asyncActions(LIKE_TOOL_COMMENT).loading(true));
  return axios({
    method: "PUT",
    url: `${ToolsURL.LIKE_TOOL_COMMENT_URL}/${commentId}/like-unlike`,
  });
};

export const deleteComment = (dispatch, commentId) => {
  dispatch(asyncActions(DELETE_TOOL_COMMENT).loading(true));
  return axios({
    method: "delete",
    url: `${ToolsURL.DELETE_TOOL_COMMENT_URL}/${commentId}`,
  })
    .then((response) => {
      dispatch(asyncActions(DELETE_TOOL_COMMENT).loading(false));
      if (response.status === 200 || response.status === 201) {
        dispatch(asyncActions(DELETE_TOOL_COMMENT).loading(false));
        toast("Comment Deleted Successfully ✅");
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(DELETE_TOOL_COMMENT).loading(false));
      dispatch(asyncActions(DELETE_TOOL_COMMENT).failure(true, error.response));
      toast("An error occured, Please try again");
      return error.response;
    });
};

export const increaseViewCount = (dispatch, id) => {
  dispatch(asyncActions(INCREASE_TOOL_VIEW).loading(true));
  return axios({
    method: "POST",
    url: `${ToolsURL.INCREASE_TOOL_VIEW_URL}/${id}`,
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(asyncActions(INCREASE_TOOL_VIEW).loading(false));
        dispatch(asyncActions(INCREASE_TOOL_VIEW).failure(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(INCREASE_TOOL_VIEW).loading(false));
      dispatch(asyncActions(INCREASE_TOOL_VIEW).failure(true));
      return error.response;
    });
};

export const increasePopularity = (dispatch, id) => {
  dispatch(asyncActions(INCREASE_TOOL_POPULARITY).loading(true));
  return axios({
    method: "POST",
    url: `${ToolsURL.INCREASE_TOOL_POPULARITY_URL}/${id}`,
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(asyncActions(INCREASE_TOOL_POPULARITY).loading(false));
        dispatch(asyncActions(INCREASE_TOOL_POPULARITY).failure(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(INCREASE_TOOL_POPULARITY).loading(false));
      dispatch(asyncActions(INCREASE_TOOL_POPULARITY).failure(true));
      return error.response;
    });
};

export const getCategoryName = (dispatch, id) => {
  dispatch(asyncActions(GET_CATEGORY_NAME).loading(true));
  return axios({
    method: "GET",
    url: `${ToolsURL.GET_CATEGORY_NAME_URL}/${id}`,
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(asyncActions(GET_CATEGORY_NAME).loading(false));
        dispatch(asyncActions(GET_CATEGORY_NAME).failure(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_CATEGORY_NAME).loading(false));
      dispatch(asyncActions(GET_CATEGORY_NAME).failure(true));
      return error.response;
    });
};
