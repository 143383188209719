import React from "react";
import { ModalBody, Modal } from "reactstrap";
import InstructionModalImg from "src/assets/img/instruction-illustration.png";
import { useTranslation } from "react-i18next";
import "./InstructionModal.scss";

const InstructionModal = ({ open, toggle, instructionsObj }) => {
  const { t } = useTranslation();
  const {
    title,
    subHeading,
    instruction1,
    instruction2,
    instruction3,
    instruction4,
    list,
    list1,
    list2,
    list4,
    list5,
    list6,
    list7,
    next,
  } = instructionsObj;

  return (
    <Modal isOpen={open} toggle={toggle} backdrop="static">
      <ModalBody className="instruction-modal">
        <div className="instruction-modal__image">
          <img
            className="instruction-modal__image--img"
            alt="instruction"
            src={InstructionModalImg}
          />
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", padding: "0 5px" }}
        >
          <h3 className="instruction-modal__title">{title}</h3>
          <h3 className="instruction-modal__sub-title">{subHeading}</h3>
          <p className="instruction-modal__paragraph">{instruction1}</p>
          <p className="instruction-modal__paragraph">{instruction4}</p>
          <p className="instruction-modal__paragraph">{instruction2}</p>
          <p className="instruction-modal__paragraph">{instruction3}</p>
          <p className="instruction-modal__paragraph">{list}</p>

          <ul>
            <li>{list1}</li>
            <li>{list4}</li>
            <li>{list5}</li>
            <li>{list6}</li>
            <li>{list7}</li>
            <li>{list2}</li>
          </ul>
          <p>{next}</p>
          <button
            className="instruction-modal__button"
            onClick={toggle}
            data-testid="instructions-next"
          >
            {t("Next")}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default InstructionModal;
