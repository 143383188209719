import React, { useState, useContext, useEffect } from "react";
import Layout from "src/components/Layout/Layout";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import { Row, Col } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import NotFound from "./Components/NotFound/ArticleNotFound";
import "./alumni.scss";
import { Context } from "src/store";
// import DefaultImage from "src/assets/img/Opportunity.jpeg";
import { BsChat, BsTools } from "react-icons/bs";
import {
  FaRandom,
  FaRegGem,
  FaRegComments,
  FaUsersCog,
  FaUsers,
} from "react-icons/fa";
import {
  getAllAlumniEvents,
  getResources,
  getAlumniHub,
} from "src/requests/AlumniEventRequest";
import FeaturedEvent from "./Components/Slider/EventSlider";
import AlumniContent from "./Components/Slider/AlumniContent";
import EventSubCard, {
  ResourceSubCard,
  NewsSubCard,
} from "./Components/Cards/EventSubCard";

const iconArr = [
  FaRegComments,
  BsTools,
  FaUsersCog,
  FaUsers,
  FaRandom,
  FaRegGem,
];
const Alumni = () => {
  const [searchParams] = useState({
    eventUserGroups: 1,
    eventsExpiry: 2,
    eventsPrivacy: 1,
  });

  const [fetchingEvents, setFetchingEvents] = useState(false);
  const [loadingResource, setLoadingResource] = useState(false);
  const [resources, setResources] = useState([]);
  const [loadingNews, setLoadingNews] = useState(false);
  const [alumniHub, setAlumniHub] = useState([]);
  const [loadingAlumniHub, setLoadingAlumniHub] = useState(false);
  const [news, setNews] = useState([]);
  const { dispatch } = useContext(Context);
  const [alumniEvent, setAlumniEvent] = useState([]);
  const [isFeaturedEvent, setIsFeaturedEvent] = useState([]);
  const history = useHistory();

  const truncate = (str, no_words) => {
    str = str.replace(/<\/?[^>]+(>|$)/g, "");

    return str.split(" ").splice(0, no_words).join(" ");
  };
  useEffect(() => {
    _getAllAlumniEvent();
    __getAllResources();

    __getAllNews();
    __getAllAlumniHub();
  }, [searchParams]);
  const __getAllResources = () => {
    setLoadingResource(true);
    getResources(dispatch).then((res) => {
      const {
        data: { data },
      } = res.data;

      setLoadingResource(false);
      setResources(data);
    });
  };
  const __getAllAlumniHub = () => {
    setLoadingAlumniHub(true);
    getAlumniHub(dispatch).then((res) => {
      const {
        data: { data },
      } = res.data;

      setLoadingAlumniHub(false);
      const transformData = data.map((item, idx) => {
        return {
          ...item,
          icon: () => `<${iconArr[idx]} color="red" />`,
        };
      });
      setAlumniHub(transformData);
    });
  };
  const __getAllNews = () => {
    setLoadingNews(true);
    getResources(dispatch, "news").then((res) => {
      const {
        data: { data },
      } = res.data;

      setLoadingNews(false);
      setNews(data);
    });
  };
  const _getAllAlumniEvent = () => {
    setFetchingEvents(true);
    const obj = { ...searchParams };

    getAllAlumniEvents(dispatch, obj).then((res) => {
      const {
        data: { data },
      } = res.data;

      const transformData = data.map((item) => {
        return {
          ...item,
          width: "100%",
          description: truncate(item.description, 50),
          height: "400px",
        };
      });
      let otherEvent = transformData.filter(
        (item) => item.isFeatured === false
      );
      let isFeaturedEvent = transformData.filter(
        (item) => item.isFeatured === true
      );

      if (isFeaturedEvent.length === 0) {
        isFeaturedEvent = transformData.slice(0, 3);
        otherEvent = transformData.slice(3);
      }

      setIsFeaturedEvent(isFeaturedEvent);
      setFetchingEvents(false);
      setAlumniEvent(otherEvent);
    });
  };
  // const style = {
  //   backgroundImage: `linear-gradient(
  //     180deg,
  //     rgba(0,0,0,0.7),
  //     rgba(0,0,0,0.7)
  // )`,
  //   backgroundRepeat: "no-repeat",
  //   backgroundPosition: "center",
  //   backgroundSize: "cover",
  //   height: "400px",
  //   borderRadius: "10px",
  // };
  return (
    <Layout SideContent={<SidebarRight />}>
      <Row>
        <Col>
          <div className="under-alumni">
            {fetchingEvents ? (
              <Skeleton width={"100%"} height={400} />
            ) : alumniEvent.length > 0 ? (
              <figure className="alumni">
                <FeaturedEvent
                  list={isFeaturedEvent}
                  showControl={true}
                  SliderContent={AlumniContent}
                  carouselHeight="390"
                  mobileRadius="0"
                  desktopRadius="0"
                />
              </figure>
            ) : (
              <NotFound />
            )}
          </div>
        </Col>
      </Row>
      <Row className="alumni__main-content">
        <Col sm="12" md="4" className="mb-5">
          <div className="alumni-box">
            <h6 className="alumni-box__category">Events</h6>
            <p
              className="alumni-box__more"
              onClick={() => history.push("/alumni-events")}
            >
              {alumniEvent.length > 0 && "See all"}
            </p>
          </div>
          {fetchingEvents &&
            Array(5)
              .fill(1)
              .map((item, idx) => (
                <div className="mb-1" key={idx}>
                  <Skeleton width={"100%"} height={50} />
                </div>
              ))}
          {alumniEvent.length > 0 &&
            alumniEvent
              .slice(0, 6)
              .map((item, idx) => <EventSubCard key={idx} item={item} />)}
        </Col>
        <Col sm="12" md="4" className="mb-3">
          <div className="alumni-box">
            <h6 className="alumni-box__category">Resources</h6>
            <p
              className="alumni-box__more"
              onClick={() => history.push("/alumni/resources")}
            >
              {resources.length > 0 && "See all"}
            </p>
          </div>
          <div className="resource mb-4">
            {/* <p className="resource__title">
              <span>
                <FaCaretRight color="#d42316" size={13} />
              </span>
              <span className="resoure-text-box">
                <span className="resource-text">Digital Library</span>
                <span className="resource__description">
                  Collection of Resource for Alumni
                </span>
              </span>
              <span className="view-alumni-arr">
                <BsChevronRight size={19} />
              </span>
            </p> */}

            {loadingResource ? (
              Array(5)
                .fill(1)
                .map((item, idx) => (
                  <div className="mb-1" key={idx}>
                    <Skeleton width={"100%"} height={10} />
                  </div>
                ))
            ) : resources.length ? (
              resources
                .slice(0, 6)
                .map((item, idx) => <ResourceSubCard key={idx} item={item} />)
            ) : (
              <NotFound />
            )}
          </div>

          <h6 className="alumni-box__category">Interact</h6>

          <div className="resource">
            <p
              className="interact__title"
              onClick={() => history.push("/alumni/chat")}
            >
              <span className="alumniHub__icon">
                <BsChat color="#D42316" />
              </span>{" "}
              <span className="interact-text">GROUP CHAT</span>
              <span className="resource__description">
                Be part of the discussion
              </span>
              {/* <span className="view-alumni-arr">
                <BsChevronRight size={19} />
              </span> */}
            </p>
            {loadingAlumniHub ? (
              Array(5)
                .fill(1)
                .map((item, idx) => (
                  <div className="mb-1" key={idx}>
                    <Skeleton width={"100%"} height={10} />
                  </div>
                ))
            ) : alumniHub.length ? (
              alumniHub.slice(0, 6).map((item, idx) => (
                <p
                  className="interact__title"
                  key={idx}
                  onClick={() =>
                    history.push(`/alumni/hubs/${item.id}/details`)
                  }
                >
                  <span className="alumniHub__icon">
                    {iconArr[idx]({ color: "red", size: "14" })}
                  </span>{" "}
                  <span className="interact-text">{item.name}</span>
                  <span className="resource__description">
                    Join our communities({item.name})
                  </span>
                </p>
              ))
            ) : (
              <NotFound />
            )}
          </div>
        </Col>
        <Col sm="12" md="4">
          <div className="alumni-box">
            <h6 className="alumni-box__category">News</h6>
            <p
              className="alumni-box__more"
              onClick={() => history.push("/alumni/resources?tab=News")}
            >
              {news.length > 0 && "See all"}
            </p>
          </div>
          {loadingNews ? (
            Array(5)
              .fill(1)
              .map((item, idx) => (
                <div className="mb-1" key={idx}>
                  <Skeleton width={"100%"} height={50} />
                </div>
              ))
          ) : news.length ? (
            news
              .slice(0, 6)
              .map((item, idx) => <NewsSubCard key={idx} item={item} />)
          ) : (
            <NotFound />
          )}
          {/* <div className="event-box">
            <div className="media media-cd">
              <img className="event-box__photo" src={DefaultImage} alt="user" />
            </div>
            <div className="event-box__detail-box">
              <h6 className="event-box__title">
                Tips: where to buy good second hand books online and in store
              </h6>
              <p className="event-box__location">07 June 2020</p>
            </div>
          </div> */}
        </Col>
      </Row>
    </Layout>
  );
};

export default ifOnboardedThenShow(Alumni);
