export default class ResourceModel {
  constructor() {
    this.author = "";
    this.id = "";
    this.coverPageUrl = "";
    this.title = "";
    this.avatar = "";
    this.datePublished = new Date();
    this.rawPublishedDate = new Date();
    this.createdOn = new Date();
    this.resourceCategory = "";
    this.status = "";
    this.description = "";
    this.resourceType = "";
    this.commentCount = 0;
    this.downloadCount = 0;
    this.likeCount = 0;
    this.viewCount = 0;
    this.fileUrl = "";
    this.isLiked = false;
    this.section = "";
    this.subSection = "";
  }

  updateProperties(payload, findLikedStatus = true) {
    const userId = JSON.parse(localStorage.getItem("data")).user.id;
    this.author = payload.author;
    this.id = payload.id || payload.resourceId;
    this.title = payload.title;
    this.avatar = payload.avatar;
    this.description = payload.description;
    this.datePublished = new Date(payload.datePublished);
    this.rawPublishedDate = this.datePublished;
    this.createdOn = new Date(payload.createdOn);
    this.resourceCategory = payload.resourceCategory;
    this.status = payload.status || "unpublished";
    this.resourceType = payload.resourceType;
    this.commentCount = payload.commentCount;
    this.downloadCount = payload.downloadCount;
    this.likeCount = payload.likeCount;
    this.viewCount = payload.viewCount;
    this.fileUrl = payload.fileUrl;
    this.section = payload.section;
    this.coverPageUrl = payload.coverPageUrl;
    if (findLikedStatus)
      this.isLiked = payload.userLikes.findIndex((l) => l.id === userId) > -1;
    this.subSection = payload.subSection;
  }

  setIsLiked(isLiked) {
    this.isLiked = isLiked;
  }

  updateStatus(status) {
    this.status = status;
  }
}
