/* eslint-disable react/no-unescaped-entities */
import React, { useState, useContext, useEffect } from "react";
import { Context } from "src/store";
import {
  getGroupDetails,
  getAllGroupPosts,
  // getGroupInvitesSent,
} from "src/requests/StudyGroupsRequest";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import "./Group.scss";
import moment from "moment";
import SideInfo from "./component/SideInfo/SideInfo";
import CreatePost from "src/modules/training/StudyGroups/components/CreatePost/CreatePost";
import CoverPhoto from "src/components/CoverPhoto/CoverPhoto";
import PostCard from "src/modules/training/StudyGroups/components/PostCard/PostCard";
import Layout from "src/components/Layout/Layout";
import PageLoader from "src/modules/homepage/components/PageLoader/PageLoader";
// import SideRight from "src/modules/homepage/components/SideRight/SideRight";
import MobileSideInfo from "./component/MobileSideInfo";
import { useCurrentUser } from "src/utils/Services/currentUser";
import ErrorBoundaryComponentLevel from "src/utils/Services/ErrorBoundaryComponentLevel";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import axios from "axios";
import Coursemates from "./component/Coursemate/Coursemate";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "src/components/Loader/Loader";

const Group = () => {
  const user = get_user_info_from_token() && get_user_info_from_token().user;
  const trainingEventId = user && user.training ? user.training[1] : null;

  axios.defaults.headers.common["trainingevent-id"] = trainingEventId;
  const { imageUrl, firstName, lastName } = useCurrentUser();
  const userName = firstName + " " + lastName;
  const { state, dispatch } = useContext(Context);
  const qa = useLocation();
  const { groupId } = queryString.parse(qa.search);
  const [groupInfo, setGroupInfo] = useState({});
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [groupPosts, setGroupPosts] = useState([]);
  const [paging, setPaging] = useState({
    pageNumber: 1,
    hasNextPage: false,
  });
  let postCard;

  useEffect(() => {
    _getGroupDetails();
    // _getGroupInvitesSent();
  }, []);

  const _getGroupDetails = () => {
    return getGroupDetails(dispatch, groupId).then((groupResponse) => {
      if (groupResponse !== undefined && groupResponse.status === 200) {
        const {
          data: { data },
        } = groupResponse;
        setGroupInfo(data);
        setIsPageLoading(false);
      }
    });
  };

  // const _getGroupInvitesSent = () => {
  //   getGroupInvitesSent(dispatch, user.id);
  // };

  const _getGroupPosts = (pageNumber, reset) => {
    getAllGroupPosts(dispatch, groupId, pageNumber).then((post) => {
      if (post !== undefined && post.status === 200) {
        const {
          data: {
            data: { data, page },
          },
        } = post;
        setPaging({
          ...paging,
          ...page,
        });
        if (reset) {
          setGroupPosts(data);
        } else {
          const posts = [...groupPosts, ...data];
          setGroupPosts(posts);
        }
      }
    });
  };
  useEffect(() => {
    _getGroupPosts(1, true);
  }, [state.Posts.post, state.Posts.vote]);

  const _loadmore = () => {
    _getGroupPosts(paging.pageNumber + 1);
  };

  // useEffect(() => {
  //   getGroupUserBelongsTo();
  // }, [!!state.Communities.joinGroup, !!state.Communities.leaveGroup]);

  if (groupPosts.length > 0) {
    postCard = groupPosts.map((post, index) => {
      const user = post.user ? post.user : "";
      const userDp =
        user.imageUrl === "" ||
        user.imageUrl === null ||
        user.imageUrl === undefined
          ? ""
          : user.imageUrl;
      return (
        <ErrorBoundaryComponentLevel key={post.id}>
          <PostCard
            key={post.id}
            postId={post.id}
            name={user ? user.firstName + " " + user.lastName : "Anonymous"}
            postDate={moment
              .utc(post.createdOn)
              .local()
              .format("MMMM Do YYYY, h:mm:ss a")}
            post={post.content}
            likeCount={post.likeCount}
            commentCount={post.commentCount}
            shareCount={post.shareCount}
            userId={user ? user.id : ""}
            profilePic={userDp}
            postImages={post.postFiles}
            groupName={groupInfo.name}
            hideComment={!groupInfo.isMember}
          />
        </ErrorBoundaryComponentLevel>
      );
    });
  } else {
    postCard = (
      <h2 className="no-post">
        No post at the moment
        <br />
        <span className="no-post__sub">
          Be the first to post here{" "}
          <span role="img" aria-label="joy">
            😊
          </span>
        </span>
      </h2>
    );
  }

  return (
    <Layout
      SideContent={
        <Coursemates
          isAdmin={groupInfo && groupInfo.userId === user.id}
          groupMembers={
            state.Communities.groupUsers.data
              ? state.Communities.groupUsers.data
              : []
          }
          adminId={groupInfo && groupInfo.userId}
          groupId={groupId}
        />
      }
    >
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <div className="community-group-home">
          <nav className="community-group-home__side d-none d-md-block">
            <SideInfo
              groupLogo={groupInfo.imageUrl}
              groupTitle={groupInfo.name}
              groupType={groupInfo.privacy}
              createdBy={
                groupInfo.user
                  ? groupInfo.user.firstName + " " + groupInfo.user.lastName
                  : "Anonymous"
              }
              about={groupInfo.about}
              membersCount={groupInfo.membersCount}
              NoOfPosts={groupPosts.length}
              member={groupInfo.isMember}
              creatorPhoneNumber={groupInfo.phoneNumber}
              creatorEmail={groupInfo.email}
              // groupCategory={groupInfo.category.name}
              createdDate={moment(groupInfo.createdOn).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}
              creatorId={groupInfo.userId}
              createdByDp={groupInfo.user && groupInfo.user.imageUrl}
              getGroupDetails={_getGroupDetails}
              groupLikeCount={groupInfo.likeCount}
              hasLikedGroup={groupInfo.hasLiked}
              programmeEventId={groupInfo.programmeEventId}
              programmeId={groupInfo.programmeId}
              courseId={groupInfo.courseId}
            />
          </nav>
          <main className="community-group-home__main">
            <div className="community-group-home__main--container">
              <CoverPhoto
                coverPhoto={groupInfo.imageUrl}
                groupTitle={groupInfo.name}
                showButton={false}
              />
              {groupInfo.privacy.toLowerCase() === "private" &&
              !groupInfo.isMember ? (
                <h5 className="no-member">
                  <span role="img" aria-label="closed lock">
                    🔒
                  </span>{" "}
                  Only members can see who's in the group and what they post
                </h5>
              ) : groupInfo.isMember ? (
                <CreatePost
                  userName={userName}
                  groupId={groupId}
                  dp={imageUrl}
                />
              ) : (
                <h5 className="no-member">
                  Only members can add posts/comments
                </h5>
              )}

              <div className="d-block d-md-none">
                <MobileSideInfo
                  creatorId={groupInfo.userId}
                  member={groupInfo.isMember}
                  groupType={groupInfo.privacy}
                  groupLogo={groupInfo.imageUrl}
                  groupTitle={groupInfo.name}
                  createdBy={
                    groupInfo.user
                      ? groupInfo.user.firstName + " " + groupInfo.user.lastName
                      : "Anonymous"
                  }
                  about={groupInfo.about}
                  membersCount={groupInfo.membersCount}
                  NoOfPosts={groupPosts.length}
                  creatorPhoneNumber={groupInfo.phoneNumber}
                  creatorEmail={groupInfo.email}
                  // groupCategory={groupInfo.category.name}
                  createdDate={moment(groupInfo.createdOn).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                  createdByDp={groupInfo.user && groupInfo.user.imageUrl}
                  getGroupDetails={_getGroupDetails}
                  groupLikeCount={groupInfo.likeCount}
                  hasLikedGroup={groupInfo.hasLiked}
                  programmeEventId={groupInfo.programmeEventId}
                  programmeId={groupInfo.programmeId}
                  courseId={groupInfo.courseId}
                />
              </div>
              {groupInfo.privacy.toLowerCase() === "private" &&
              !groupInfo.isMember ? (
                ""
              ) : (
                <>
                  <InfiniteScroll
                    hasMore={paging.hasNextPage}
                    dataLength={groupPosts.length}
                    next={_loadmore}
                    loader={
                      <div className="homepage-main-content__loader-container">
                        <Loader color="danger" />
                      </div>
                    }
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        <b>The end!</b>
                      </p>
                    }
                  >
                    {postCard}
                  </InfiniteScroll>
                </>
              )}
            </div>
          </main>
        </div>
      )}
    </Layout>
  );
};

export default Group;
