/* eslint-disable no-unused-vars */
import React, { useContext, useRef, useState } from "react";
import classes from "./CreatePostForm.module.scss";
import { EditorBox } from "src/components/Editor";

import { MdAttachFile } from "react-icons/md";
import Avatars from "src/components/Avatar/Avatar";
import { useCurrentUser } from "src/utils/Services/currentUser";
import ImageGridGenerator from "src/utils/imageGridGenerator";
import { UploadFile } from "src/requests/fileUploadRequest";
import { createPost } from "src/requests/PostRequest";
import { Context } from "src/store";
import StatusModal from "src/components/StatusModal/StatusModal";
import { Button, Spinner } from "reactstrap";
const CreatePostForm = ({
  files,
  switchUplaod,
  clearFiles,
  setContent,
  content,
  togglePostModal,
}) => {
  const { firstName, lastName, imageUrl } = useCurrentUser();
  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(Context);
  const [alertDetails, setAlertDetails] = useState({
    alertMessage: "",
    alertType: "",
  });
  const clearField = () => {
    inputRef.current.clearInput();
    clearFiles();
  };

  const dismissAlert = () => {
    setAlertDetails({ alertMessage: "", alertType: "" });
  };

  const hasContent = () => {
    return files.length > 0 || inputRef.current.hasContent();
  };

  const handlePost = (e) => {
    e.preventDefault();
    if (!hasContent()) return;
    setLoading(true);

    const payload = {
      post: {
        content: content,
        accessibleBy: 0,
      },
      files: [],
      // files: urlFiles.length > 0 ? updatePayloadUrl() : [],
      // groupId: null,
    };

    if (files.length > 0) {
      UploadFile(dispatch, true, files)
        .then((res) => {
          if (res && res.status === 200) {
            // eslint-disable-next-line array-callback-return
            res.files.map((img) => {
              const newObj = { url: img, type: "image" };
              payload.files.push(newObj);
            });
            _handleSubmit(payload);
          }
        })
        .catch((_err) => {
          _handleSubmit(payload);
        });
    } else {
      _handleSubmit(payload);
    }
    // clearQueryUrlParam();
  };

  const _handleSubmit = (data) => {
    createPost(dispatch, data)
      .then((res) => {
        const { status, data } = res;
        if (status !== 200) {
          setLoading(false);
          const { message } = data;
          setAlertDetails({
            ...alertDetails,
            alertMessage: message,
            alertType: "danger",
            showButton: true,
            buttonClick: (e) => dismissAlert(),
            buttonText: "Close",
          });
        } else {
          setLoading(false);
          setAlertDetails({
            ...alertDetails,
            alertMessage: res.data.message,
            alertType: "success",
          });
          clearField();
          setTimeout(() => {
            dismissAlert();
            togglePostModal();
          }, 1000);
        }
      })
      .catch((_error) => {
        setLoading(false);
        setAlertDetails({
          ...alertDetails,
          alertMessage: "Something went wrong, please contact support",
          alertType: "danger",
          showButton: true,
          buttonClick: (e) => dismissAlert(),
          buttonText: "Close",
        });
      });
  };

  return (
    <div className={classes.container}>
      <div className={classes.post__form}>
        <div className={classes.img}>
          <Avatars
            dimension={34}
            name={`${firstName} ${lastName}`}
            src={imageUrl}
          />
        </div>

        <EditorBox
          ref={inputRef}
          content={content}
          onEditorChange={setContent}
          placeholder="What’s on your mind?"
          useEmoji
          className={classes.textField}
          optionsClassName={classes.options}
          addtionalOptions={
            <div className={classes.buttons}>
              <MdAttachFile
                className={classes.attachF}
                size={21}
                onClick={switchUplaod}
              />
              <Button disabled={loading} onClick={handlePost}>
                Post{" "}
                {loading && (
                  <Spinner style={{ height: "20px", width: "20px" }} />
                )}
              </Button>
            </div>
          }
          additionalContent={
            <ImageGridGenerator
              imageLength={files.length}
              images={files.map((file) => {
                return file.url ? file : { url: URL.createObjectURL(file) };
              })}
              handleClick={() => {}}
            />
          }
        />
      </div>
      <StatusModal
        status={alertDetails.alertType}
        open={!!alertDetails.alertMessage && !!alertDetails.alertType}
        heading={alertDetails.title && alertDetails.title}
        text={alertDetails.alertMessage && alertDetails.alertMessage}
        showButton={alertDetails.showButton}
        buttonText={alertDetails.buttonText}
        onButtonClick={alertDetails.buttonClick}
        toggle={() =>
          setAlertDetails({
            alertMessage: "",
            alertType: "",
          })
        }
      />
    </div>
  );
};

export default CreatePostForm;
