import React, { useEffect, useContext } from "react";
import classnames from "classnames";
import { Nav, NavItem, NavLink } from "reactstrap";
import FaqModal from "src/modules/Tef-Dms/components/Modals/FrequencyAskedQuestionsModal/FaqModal";
import { Context } from "src/store";
import { getApplicationFaq } from "src/requests/ApplicationRequest";

import "./sectionnav.css";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const SectionNav = ({ toggle, activeTab }) => {
  const { dispatch } = useContext(Context);

  const _getFaq = () => {
    return getApplicationFaq(dispatch);
  };

  useEffect(() => {
    _getFaq();
  }, []);
  return (
    <>
      <FaqModal />
      <Nav className="section-nav-container">
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggle("1")}
          >
            Due Diligence
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            Business Plans
          </NavLink>
        </NavItem>
        {get_user_info_from_token().user.dms_user_seed_capital && (
          <>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggle("3");
                }}
              >
                Seed Capital
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "4" })}
                onClick={() => {
                  toggle("4");
                }}
              >
                Bank Details
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "5" })}
                onClick={() => {
                  toggle("5");
                }}
              >
                Certificates
              </NavLink>
            </NavItem>
          </>
        )}
      </Nav>
    </>
  );
};

export default SectionNav;
