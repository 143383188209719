import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Logout } from "src/requests/logout";

import Logo from "src/assets/img/logo/logo_new.webp";
import "./SideBar.scss";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
const SideBar = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { endDate } = queryString.parse(history.location.search);
  const { pathname } = history.location;

  const calculateTimeLeft = () => {
    const difference = +new Date(endDate) - +new Date();
    let timeLeft = {
      days: "00",
      hours: "00",
      minutes: "00",
      seconds: "00",
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const handleLogout = (e) => {
    e.preventDefault();
    Logout(history);
  };

  const isCurrentMainPath = (title) => {
    const path = window.location.pathname;
    let currentPath;
    currentPath = path.split("/").filter(Boolean)[1];
    if (
      currentPath.toLowerCase() === "tests" ||
      currentPath.toLowerCase() === "personalinfo"
    ) {
      currentPath = path.split("/").filter(Boolean)[2];
    }

    return currentPath.toLowerCase() === title.toLowerCase();
  };

  return (
    <div className="application-sidebar">
      <Link to="/home">
        <img src={Logo} alt="" className="side-logo" />
      </Link>
      <h4 className="side-heading">{t("TEF Application Countdown")}</h4>
      <div className="countdown-timer notranslate">
        <h3>
          {timeLeft.days} <span>{t("days")}</span>
        </h3>
        <h3>
          {timeLeft.hours} <span>{t("hours")}</span>
        </h3>
        <h3>
          {timeLeft.minutes} <span>{t("minutes")}</span>
        </h3>
        <h3>
          {timeLeft.seconds} <span>{t("seconds")}</span>
        </h3>
      </div>

      <div className="sub-topics">
        <h4 className="number">1</h4>
        <div>
          <h4 className="topic-name">{t("Getting to know you")}</h4>
          <ul>
            <li className={isCurrentMainPath("bio") ? "side-active" : ""}>
              {t("Bio")}
            </li>
            <li
              className={isCurrentMainPath("experience") ? "side-active" : ""}
            >
              {t("Idea & Experience")}
            </li>
          </ul>
        </div>
      </div>

      <div className="sub-topics">
        <h4 className="number">2</h4>
        <div>
          <h4 className="topic-name">
            {pathname.includes("offcycle") ? "Assessment" : "Know thyself"}
          </h4>
          <ul>
            {!pathname.includes("offcycle") && (
              <li
                className={
                  isCurrentMainPath("personality") ? "side-active" : ""
                }
              >
                {t("Personality Test")}
              </li>
            )}
            <li className={isCurrentMainPath("mcq") ? "side-active" : ""}>
              {t("Cognitive Test")}
            </li>
          </ul>
        </div>
      </div>

      <div className="sub-topics">
        <h4 className="number">3</h4>
        <div>
          <h4
            className={
              isCurrentMainPath("declaration")
                ? "side-active topic-name"
                : "topic-name"
            }
          >
            {t("Declaration")}
          </h4>
        </div>
      </div>

      <div className="sub-topics">
        <div>
          <h4>
            <Link
              to="#"
              onClick={handleLogout}
              className="topic-name logout-link"
            >
              {t("Logout")}
            </Link>
          </h4>
        </div>
      </div>

      <h4 className="show-mobile">
        <Link to="#" onClick={handleLogout} className="logout-link">
          Logout
        </Link>
      </h4>
    </div>
  );
};

export default SideBar;
