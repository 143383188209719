import React, { useContext, useEffect } from "react";
import { isMentor } from "src/modules/mentorship/components/helper";
import { getConnections } from "src/requests/MentorRequests";
import { Context } from "src/store";
import UserCard from "./UserCard";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
const Survey = () => {
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    getConnections(dispatch);
  }, []);

  const showConnections = () => {
    const data = get_user_info_from_token();

    return (
      state.Mentors &&
      state.Mentors.connected &&
      state.Mentors.connected.map((connection, i) => {
        return (
          <UserCard
            key={i}
            userId={connection.namedId}
            isMentor={isMentor(data.user)}
          />
        );
      })
    );
  };

  return (
    <div>{state.Mentors && state.Mentors.connected && showConnections()}</div>
  );
};

Survey.propTypes = {};

export default Survey;
