import React, { useCallback, useState } from "react";
import "./Search.scss";
import { FaSearch } from "react-icons/fa";
import CreateStudyGroup from "src/modules/training/StudyGroups/components/CreateCommunityGroup/CreateGroup";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";

const Search = ({ categories, getGroups }) => {
  const [searchData, setSearchData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const toggle = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
  const history = useHistory();
  const courseId = new URLSearchParams(location.search).get("course");

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(
      `/training/studygroups/groups/search?q=${searchData}&course=${courseId}`
    );
  };

  return (
    <div className="community-search-div">
      <h2></h2>
      <div className="community-search-div__search">
        <form onSubmit={handleSubmit} className="search-container">
          <input
            type="text"
            placeholder="Search Study Groups"
            name="search"
            className="search-community"
            onChange={(e) => setSearchData(e.target.value)}
          />
          <button
            type="submit"
            disabled={searchData === ""}
            className="search-community-icon"
          >
            <FaSearch />
          </button>
        </form>

        <div>
          <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropDown}>
            <DropdownToggle caret>Manage Study Groups</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={toggle}>Add New</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
                <Link to={`/training/studygroups/my-groups?course=${courseId}`}>
                  My Groups
                </Link>
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </div>
      </div>
      <CreateStudyGroup
        categories={categories}
        show={showModal}
        toggle={toggle}
        courseId={courseId}
        getGroups={getGroups}
      />
    </div>
  );
};

export default Search;
