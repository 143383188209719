import React from "react";
import Loader from "src/components/Loader/Loader";
import "./index.scss";
import { Link } from "react-router-dom";
import SuggestedGroupCard from "./SuggestedGroupCard/SuggestedGroupCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const SuggestedGroup = ({
  // usersGroups,
  allGroups,
  isLoading,
  responsiveSize,
}) => {
  const suggestedGroups =
    allGroups.length > 0 &&
    allGroups.map((suggestedGroup, index) => {
      return (
        <SuggestedGroupCard
          key={suggestedGroup.id}
          id={suggestedGroup.id}
          groupLogo={suggestedGroup.imageUrl}
          title={suggestedGroup.name}
          members={suggestedGroup.membersCount}
          location={suggestedGroup.privacy}
          belongs={suggestedGroup.isAMember}
          ownerId={suggestedGroup.userId}
          inviteStatus={suggestedGroup.inviteStatus}
        />
      );
    });

  return (
    <div className="suggested-group-main">
      <div className="suggested-group-heading">
        <div className="suggested-group-heading__text">
          <h1 className="text-h1">suggested groups</h1>
          <h3 className="text-h3">Groups you might be interested in.</h3>
        </div>
        <Link
          to="/community/all-groups"
          className="suggested-group-heading__link"
        >
          see all
        </Link>
      </div>
      {isLoading ? (
        <div className="suggested-group-cards__loader">
          <Loader color="danger" />
        </div>
      ) : (
        <Carousel responsive={responsiveSize}>{suggestedGroups}</Carousel>
      )}
    </div>
  );
};

export default SuggestedGroup;
