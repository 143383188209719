import React from "react";

const SwotAnalysisTemplate = ({ swot }) => {
  return (
    <div>
      <h4 className="text-center font-weight-bold mb-5">SWOT Analysis</h4>

      <table className="table table-bordered text-center">
        <tbody>
          {/* <tr>
        <td colSpan="2" className="p-2 text-left font-weight-bold">
          Competitive Analysis
        </td>
      </tr> */}
          <tr>
            <td>Strength</td>
            <td>Weakness</td>
          </tr>
          <tr>
            <td className="bg-success">
              {swot.strength === "" ? "[STRENGTH]" : swot.strength}
            </td>
            <td className="bg-info">
              {swot.weakness === "" ? "[WEAKNESSES]" : swot.weakness}
            </td>
          </tr>
          <tr>
            <td className="bg-warning">
              {swot.opportunity === "" ? "[OPPORTUNITIES]" : swot.opportunity}
            </td>
            <td className="bg-danger">
              {swot.threats === "" ? "[THREATS]" : swot.threats}
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        The risks involved in this kind of business are;
        <p>
          <ul>
            {swot.risks.map((risk, index) => (
              <li key={index}> {risk}</li>
            ))}
          </ul>
        </p>
      </p>
    </div>
  );
};

export default SwotAnalysisTemplate;
