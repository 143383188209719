import React, { useEffect, useState, useContext } from "react";
import { Row, Col, PopoverBody, Popover } from "reactstrap";
import "../Articles/Article.scss";
import Title from "src/modules/Discover/components/Title/Title";
import {
  FaRegSave,
  FaShareAlt,
  FaRegBookmark,
  FaRegShareSquare,
} from "react-icons/fa";
import { getAllNews } from "src/requests/TefNew";
import { Context } from "src/store";
import ContentLoader from "react-content-loader";
import { useHistory } from "react-router-dom";
import ArticleNotFound from "src/modules/Discover/components/NotFound/ArticleNotFound";
import moment from "moment";
import {
  createAndDeleteBookmark,
  downloadResourceSummary,
  getAllBookmarks,
} from "src/requests/DiscoverRequest";
import Swal from "sweetalert2";
import { useCurrentUser } from "src/utils/Services/currentUser";
// import { imageValid } from "src/modules/Discover/helper";
// import EmptyImage from "src/assets/img/courseImage.jpg";
import classes from "./News.module.css";
import Avatars from "src/components/Avatar/Avatar";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { imageValid } from "src/modules/Discover/helper";

const ReactContentLoader = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div
        style={{ backgroundColor: "#f3f3f3", height: "200px", width: "100%" }}
      ></div>
      <div
        style={{
          backgroundColor: "white",
          padding: "10px",
        }}
        // className="loader-item"
      >
        <ContentLoader
          speed={2}
          // width={324}
          // height={150}
          viewBox="0 0 400 160"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="8" rx="3" ry="3" width="300" height="30" />
          <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
          <rect x="0" y="56" rx="3" ry="3" width="300" height="6" />
          <rect x="0" y="72" rx="3" ry="3" width="300" height="6" />
          <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
          {/* <circle cx="20" cy="20" r="20" /> */}
        </ContentLoader>
      </div>
    </div>
  );
};

const TefNews = ({ handleSeeAll, numberToReturn, filterOptions }) => {
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageDetails, setPageDetails] = useState({
    pageNumber: 1,
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const [bookmarked, setBookmarked] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [newsLength, setNewsLength] = useState(0);
  const [openPopOver, setOpenpopover] = useState(false);
  const [targetId, setTargetId] = useState(null);
  const [sortString, setSortString] = useState("");
  const userId = useCurrentUser().userId;
  const { dispatch } = useContext(Context);

  useEffect(() => {
    _getAllNews(1, false);
    getAllBookmarked();
  }, [refresh]);

  useEffect(() => {
    _getAllNews(1, false, sortString);
  }, [sortString]);

  const humanDate = (item) => {
    return moment.utc(item.createdOn).local().format("MMMM Do YYYY");
  };

  const _getAllNews = async (pageNumber, loadMore, sortString) => {
    try {
      const news = await getAllNews(dispatch, pageNumber, sortString);
      const {
        data: { data, pager },
      } = news.data;
      if (data && data.length > 0) {
        setNewsLength(data.length);
        const renderedData = numberToReturn
          ? data.slice(0, numberToReturn)
          : data;
        if (loadMore) {
          const previousArticle = [...news];
          const newArticle = [...previousArticle, ...data];
          setNews(newArticle);
        } else {
          setNews(renderedData);
        }
        setPageDetails({ ...pageDetails, ...pager });
        setIsLoading(false);
      } else {
        setNewsLength(0);
        setNews([]);
      }
    } catch ({ response }) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          response && response.data
            ? response.data
            : "Something went wrong while fetching News",
        confirmButtonColor: "#da291c",
      }).then(() => setRefresh(!refresh));
    }
  };

  const handleBookmark = async (storyId, title) => {
    Swal.fire({
      title: "Bookmark?",
      text: bookmarked.find((b) => b.contentId === storyId)
        ? "You are about removing this story from your bookmark list?"
        : "Are you sure you want to bookmark this story?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#da291c",
      cancelButtonColor: "#63666a",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        createAndDeleteBookmark(userId, storyId, title, "TEF-News").then(
          (res) => {
            if (res.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Bookmarked!",
                text: "Story Bookmarked!",
                confirmButtonColor: "#da291c",
              }).then(() => setRefresh(!refresh));
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Unable to bookmark story at this time, try again!",
                confirmButtonColor: "#da291c",
              }).then(() => setRefresh(!refresh));
            }
          }
        );
      }
    });
  };

  const getAllBookmarked = async () => {
    const bookmarked = await getAllBookmarks(userId);
    const {
      data: { data },
    } = bookmarked;
    setBookmarked(data);
  };
  const history = useHistory();
  const RenderContentLoader = () => {
    const arr = [1, 1, 1, 1];
    const contentArr = arr.fill(4);
    const contLoadArr = contentArr.map((c, idx) => (
      <Col md={6} key={idx}>
        <ReactContentLoader />
      </Col>
    ));
    return <Row>{contLoadArr}</Row>;
  };
  const newsDetails = (item) => {
    history.push(`discover/news/${item.id}`);
  };

  const handleDownload = (id, author) => {
    downloadResourceSummary(id, author);
  };
  const handleShare = (id) => {
    if (id !== null) {
      setTargetId(id);
    }
    setOpenpopover(!openPopOver);
  };
  const currentURL = window.location.href;

  const getSortBy = (str) => {
    setSortString(str);
  };

  return (
    <>
      {isLoading ? (
        RenderContentLoader()
      ) : news.length <= 0 ? (
        <ArticleNotFound />
      ) : (
        <div className="mt-3">
          <Title
            name="In the News"
            nameLength={newsLength}
            seeAll="See All"
            seeAllAction={() => handleSeeAll("In the News")}
            showFilterOptions={filterOptions}
            sortBy={getSortBy}
          />
          <Row>
            {news.slice(0, 1).map((item, index) => (
              <Col md={12} key={index}>
                <div className="post-item">
                  <div className="category-image position-relative">
                    <span
                      onClick={() => history.push(`/discover/news/${item.id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={
                          imageValid(item.bannerUrl)
                            ? item.bannerUrl
                            : "https://cdn.pixabay.com/photo/2017/04/19/13/16/computer-2242264_1280.jpg"
                        }
                        alt="TEF News"
                        className={classes.NewsBanner}
                      />
                    </span>
                    <div className="position-absolute post-item__1">
                      <div
                        className="border-bottom"
                        onClick={() =>
                          history.push(`/discover/news/${item.id}`)
                        }
                      >
                        <h3>{item.categoryName}</h3>
                        <h1 className="text-white">{item.title}</h1>
                      </div>
                      <div className="py-2 mt-2">
                        <Row>
                          <Col md={7}>
                            <div
                              className="d-flex"
                              style={{
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              {item.imageUrl ? (
                                <img
                                  src={item.imageUrl}
                                  alt="avatar"
                                  className="writer-avatar mr-2"
                                />
                              ) : (
                                <Avatars
                                  name={item.createdBy}
                                  dimension={30}
                                  src={item.imageUrl}
                                />
                              )}

                              <h2 className="mr-1 ml-3">{item.createdBy}</h2>
                              <h4>{humanDate(item)}</h4>
                            </div>
                          </Col>
                          {/* <Col md={3}>
                          </Col> */}
                          <Col md={5}>
                            <div
                              className="d-flex justify-content-end pt-1"
                              style={{ color: "#919ba8", cursor: "pointer" }}
                            >
                              <span
                                onClick={() => {
                                  handleBookmark(item.id, item.title);
                                }}
                                className={
                                  bookmarked.find(
                                    (b) => b.contentId === item.id
                                  )
                                    ? classes.Bookmarked
                                    : ""
                                }
                              >
                                <FaRegBookmark size="0.8em" />
                              </span>
                              {/* <FaShare/> */}
                              <span className="ml-3">
                                <FaRegShareSquare size="0.8em" />
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <div className="video-list-item p-3 mt-2">
            <Row>
              {news &&
                news.slice(1).map((item) => (
                  <Col md={12} key={item.id}>
                    <div className="mb-2 group-list d-flex">
                      <div
                        className={`group-list__image mr-4 ${classes.ArticleBanner}`}
                        onClick={() => newsDetails(item)}
                      >
                        <img
                          src={
                            imageValid(item.bannerUrl)
                              ? item.bannerUrl
                              : "https://cdn.pixabay.com/photo/2017/04/19/13/16/computer-2242264_1280.jpg"
                          }
                          alt="News banner"
                          style={{ width: "100%" }}
                          className={classes.NewsBanner}
                        />
                      </div>
                      <div className="pt-3">
                        <h1 onClick={() => newsDetails(item)}>{item.title}</h1>
                        <div
                          className="group-list__group-category"
                          onClick={() => newsDetails(item)}
                        >
                          <p>{item.shortDescription}</p>
                        </div>
                        <div>
                          <div
                            className={classes.ArticlePoster}
                            onClick={() => newsDetails(item)}
                          >
                            <span>
                              <Avatars
                                name={item.createdBy}
                                dimension={20}
                                src={item.imageUrl}
                              />
                            </span>

                            <span
                              className={`username mr-4 ${classes.TruncateText}`}
                            >
                              {item.createdBy}
                            </span>
                            {/* <span className="separator mx-2"></span> */}
                            <span className="date-posted">
                              <span className="mr-2">Posted on:</span>
                              {moment(item.publishedDate).format("DD-MMM-YY")}
                            </span>
                          </div>
                          <div>
                            <div
                              onClick={() =>
                                handleDownload(item.id, item.title)
                              }
                            >
                              <span>
                                <FaRegSave size="0.8em" />
                              </span>
                              <span className="save-btn pr-3 pl-1">Save</span>
                            </div>
                            <div
                              id={`pop__${item.id}`}
                              onClick={() => handleShare(`pop__${item.id}`)}
                            >
                              <span>
                                <FaShareAlt size="0.8em" />
                              </span>
                              <span className="share-btn pr-3 pl-1">Share</span>
                            </div>
                            {targetId === `pop__${item.id}` && (
                              <Popover
                                className="p-0 border-0 d-none d-md-block"
                                trigger="legacy"
                                placement="right"
                                isOpen={openPopOver}
                                target={targetId}
                                toggle={() => handleShare(null)}
                              >
                                <PopoverBody>
                                  <ul className="share-options__lists">
                                    <li>
                                      <TwitterShareButton
                                        url={`${currentURL}/news/${item.id}`}
                                        title={item.title}
                                      >
                                        <TwitterIcon
                                          size={20}
                                          round={true}
                                          className="icon-tab"
                                        />
                                        Share via Twitter
                                      </TwitterShareButton>
                                    </li>
                                    <li>
                                      <FacebookShareButton
                                        url={`${currentURL}/news/${item.id}`}
                                        quote={item.title}
                                      >
                                        <FacebookIcon
                                          size={20}
                                          round={true}
                                          className="icon-tab"
                                        />
                                        Share via Facebook
                                      </FacebookShareButton>
                                    </li>
                                    <li>
                                      <LinkedinShareButton
                                        url={`${currentURL}/news/${item.id}`}
                                        title={item.title}
                                        summary={item.title}
                                      >
                                        <LinkedinIcon
                                          size={20}
                                          round={true}
                                          className="icon-tab"
                                        />
                                        Share via LinkedIn
                                      </LinkedinShareButton>
                                    </li>
                                  </ul>
                                </PopoverBody>
                              </Popover>
                            )}
                            <div
                              onClick={() => {
                                handleBookmark(item.id, item.title);
                              }}
                              className={
                                bookmarked.find((b) => b.contentId === item.id)
                                  ? classes.Bookmarked
                                  : ""
                              }
                            >
                              <span>
                                <FaRegBookmark size="0.8em" />
                              </span>
                              <span className="share-btn pl-1">
                                {bookmarked.find((b) => b.contentId === item.id)
                                  ? "Bookmarked"
                                  : "Bookmark"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default TefNews;
