import React from "react";
import AppointmentsContainer from "./Appointments/AppointmentsContainer";
import Selectable from "./CustomCalendar";

const CalendarHome = () => {
  return (
    <>
      <AppointmentsContainer />
      <Selectable />
    </>
  );
};

export default CalendarHome;
