import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import Layout from "src/components/Layout/Layout";
import { getAllBookmarks } from "src/requests/DiscoverRequest";
import { useCurrentUser } from "src/utils/Services/currentUser";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import Empty from "src/modules/mentorship/Calendar/utils/Empty";
import Bookmark from "./Bookmark";
import classes from "./Bookmark.module.css";

const DiscoverBookmarks = () => {
  const [bookmarkList, setBookmarkList] = useState([]);
  const userId = useCurrentUser().userId;
  useEffect(() => {
    getBookmarks();
  }, []);
  const getBookmarks = async () => {
    const bookmarks = await getAllBookmarks(userId);
    const {
      data: { data },
    } = bookmarks;
    setBookmarkList(data);
  };
  return (
    <Layout SideContent={<SidebarRight />} heading="Bookmarks">
      <Row className={classes.Bookmarks}>
        {bookmarkList.length > 0 ? (
          <Bookmark list={bookmarkList} />
        ) : (
          <Empty message="You do not have any item bookmarked" />
        )}
      </Row>
    </Layout>
  );
};

export default DiscoverBookmarks;
