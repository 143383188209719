import React from "react";
import {
  Facebook,
  Instagram,
  Code,
  List,
  BulletList,
} from "react-content-loader";
import PropTypes from "prop-types";

const GetType = (type, key) => {
  switch (type) {
    case "Facebook":
      return <Facebook key={key} />;

    case "Instagram":
      return <Instagram key={key} />;
    case "Code":
      return <Code key={key} />;
    case "List":
      return <List key={key} />;
    case "BulletList":
      return <BulletList key={key} />;

    default:
      return <Facebook key={key} />;
  }
};

const PageLoader = ({ counts, type, className }) => {
  const count = Array(counts).fill();
  const loaders = count.map((_, index) => GetType(type, index));
  return <div className={`card-div ${className}`}>{loaders}</div>;
};

PageLoader.defaultProps = {
  counts: 4,
  type: "Facebook",
  className: "",
};

PageLoader.propTypes = {
  type: PropTypes.oneOf([
    "Facebook",
    "Instagram",
    "List",
    "Code",
    "BulletList",
  ]),
};

export default PageLoader;
