/* eslint-disable no-prototype-builtins */
import React from "react";
import styles from "./HeaderNav_v2.module.scss";
import { Link } from "react-router-dom";
import { isTefMentorOrMentee } from "src/components/Layout/SideBar/SideBar";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { getTrainingRoute } from "src/modules/Flags/urlFlagParams";

const HeaderNav_v2 = () => {
  const path = window.location.pathname;
  const user = get_user_info_from_token().user;

  const redirectMentorhip = (user) => {
    if (user["tef-mentee"]) {
      // return setDefaultMentorshipUrl("/mentorship");
      return "/mentorship";
    }
    if (user["tef-mentor"]) {
      return "/mentorship-redirects";
    }
  };
  return (
    <nav className={styles.HeaderNav}>
      <Link
        // to="/training"
        to={getTrainingRoute()}
        className={`${styles.HeaderNav__links} ${
          path.includes("/training")
            ? styles.HeaderNav__linksActive
            : styles.HeaderNav__linksInActive
        }`}
      >
        <span>tef</span> training
      </Link>
      <Link
        to="/programme/dashboard"
        className={`tour-program ${styles.HeaderNav__links} ${
          path.includes("/programme")
            ? styles.HeaderNav__linksActive
            : styles.HeaderNav__linksInActive
        }`}
        data-testid="applications"
      >
        applications
      </Link>
      <Link
        to="/mentorship-application"
        className={`${styles.HeaderNav__links} ${
          path.includes("/mentorship-application")
            ? styles.HeaderNav__linksActive
            : styles.HeaderNav__linksInActive
        }`}
      >
        mentors application
      </Link>
      {isTefMentorOrMentee(user) && (
        <Link
          to={redirectMentorhip(user)}
          className={`${styles.HeaderNav__links} ${
            path.includes("/mentorship")
              ? styles.HeaderNav__linksActive
              : styles.HeaderNav__linksInActive
          }`}
        >
          mentorship
        </Link>
      )}
    </nav>
  );
};

export default HeaderNav_v2;
