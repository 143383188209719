import React, { useContext } from "react";
import { FaTrash, FaUserAlt } from "react-icons/fa";
import { Context } from "src/store";
import { deleteGroup } from "src/requests/CommunityRequest";
import "./MoreOptions.scss";
import Swal from "sweetalert2";
import { useHistory, Link } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
// import { ReactComponent as Email } from "src/assets/img/home/email.svg";
import { ReactComponent as Share } from "src/assets/img/home/share.svg";
import { FRONTEND_URL } from "src/utils/frontendUrl";

const MoreOptions = ({
  show,
  groupOwnerId,
  userId,
  groupId,
  setToggleInviteFriendsModal,
  groupTitle,
  lastName,
  firstName,
  groupType,
}) => {
  const { dispatch } = useContext(Context);
  const history = useHistory();

  const message = `Hello, \n${
    firstName + " " + lastName
  } is inviting you to contribute to the ${groupTitle} discussions. Click on the link below to join.`;

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.value) {
        deleteGroup(dispatch, groupId).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              title: "Deleted!",
              text: "Group successfully deleted",
              icon: "success",
              confirmButtonColor: "red",
            }).then((_) => history.push("/community"));
          } else {
            Swal.fire({
              title: "Cancelled",
              text: "Could not delete, please try again",
              icon: "error",
              confirmButtonColor: "red",
            });
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Cancelled",
          text: "Delete cancelled",
          icon: "error",
          confirmButtonColor: "red",
        });
      }
    });
  };

  return (
    <div className={show ? "more-options" : "more-options hide-options"}>
      <ul className="share-options__lists">
        <li>
          <Share className="icon-tab" />
          Share on my timeline
        </li>
        <li>
          <TwitterShareButton
            url={`${FRONTEND_URL}/community/group?groupId=${groupId}`}
            title={message}
          >
            <TwitterIcon size={20} round={true} className="icon-tab" />
            Share via Twitter
          </TwitterShareButton>
        </li>
        <li>
          <FacebookShareButton
            url={`${FRONTEND_URL}/community/group?groupId=${groupId}`}
            quote={message}
          >
            <FacebookIcon size={20} round={true} className="icon-tab" />
            Share via Facebook
          </FacebookShareButton>
        </li>
        <li>
          <LinkedinShareButton
            url={`${FRONTEND_URL}/community/group?groupId=${groupId}`}
            source={message}
          >
            <LinkedinIcon size={20} round={true} className="icon-tab" />
            Share via LinkedIn
          </LinkedinShareButton>
        </li>
      </ul>
      {groupOwnerId === userId ? (
        <>
          {groupType === "private" && (
            <Link
              to={`/community/group/requests?groupId=${groupId}`}
              className="more-options__btn"
            >
              <FaUserAlt />
              <span className="more-options__btn--span">Pending Invite</span>
            </Link>
          )}
          <button onClick={handleDelete} className="more-options__btn">
            <FaTrash />
            <span className="more-options__btn--span">Delete Group</span>
          </button>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default MoreOptions;
