import * as types from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import { OnboardingURL, InterestsUrl } from "src/constants";
import axios from "axios";

export const getInterests = (dispatch) => {
  dispatch(asyncActions(types.GET_INTERESTS).loading(true));
  return axios({
    method: "get",
    url: OnboardingURL.GET_INTERESTS,
  })
    .then((response) => {
      dispatch(asyncActions(types.GET_INTERESTS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(types.GET_INTERESTS).success(data));
        dispatch(asyncActions(types.GET_INTERESTS).loading(false));
        return { data, status: response.status };
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(types.GET_INTERESTS).success([]));
      dispatch(asyncActions(types.GET_INTERESTS).loading(true));
      dispatch(asyncActions(types.GET_INTERESTS).failure(true, error.response));
      return { status: 504, data: null };
    });
};

export const getInterestById = (dispatch, id) => {
  dispatch(asyncActions(types.GET_SINGLE_INTEREST).loading(true));
  return axios({
    method: "get",
    url: `${InterestsUrl.GET_SINGLE_INTEREST}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(types.GET_SINGLE_INTEREST).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(types.GET_SINGLE_INTEREST).success(data));
        dispatch(asyncActions(types.GET_SINGLE_INTEREST).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(types.GET_SINGLE_INTEREST).success([]));
      dispatch(asyncActions(types.GET_SINGLE_INTEREST).loading(true));
      dispatch(
        asyncActions(types.GET_SINGLE_INTEREST).failure(true, error.response)
      );
      // return { status: 504, data: null };
    });
};
