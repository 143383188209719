import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useDisabilityList = () => {
  const { t } = useTranslation();

  const list = [
    "Visual impairment",
    "Hearing impairment",
    "Disability affecting mobility/wheelchair user",
    "Developmental or intellectual disability",
    "Albinism",
    "Psychosocial disability",
    "Other disability",
  ];
  const memoized = useMemo(() => {
    return list.map((item) => {
      return {
        value: item,
        label: t(item),
      };
    });
  });
  return { disabilityList: memoized };
};
