import React, { useEffect, useState } from "react";
import Avatar from "src/components/Avatar/Avatar";
import { FaRegCommentAlt, FaTrash, FaPenAlt } from "react-icons/fa";

// import axios from "axios";
// FaRegHeart
// import { ProfileUrl } from "src/modules/constants";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import ReactAvatar from "react-avatar";
import { FileViewerReadOnly } from "src/components/FileViewer/FileViewer";
// import { useCurrentUser } from "src/modules/utils/Services/currentUser";
import { EditorContentDisplay } from "src/components/Editor";
import { IoMdHeart } from "react-icons/io";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import "./index.scss";
import ClickOutside from "react-click-outside-component";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const DISCUSSION = "discussion";

function MoreOptions({ onEditClick, onDeleteClick, type }) {
  return (
    <>
      {}
      <div className="q_and_a_dot_options-moreOptions">
        {type !== DISCUSSION && (
          <button onClick={onEditClick}>
            <FaPenAlt className="option-i-con" />
            Edit
          </button>
        )}
        <button onClick={onDeleteClick}>
          <FaTrash className="option-i-con" />
          Delete
        </button>
      </div>
    </>
  );
}

const DiscussionChat = ({
  type,
  onLikeClick,
  onReplyClick,
  files,
  senderId,
  firstName,
  lastName,
  imageUrl,
  message,
  likeCount,
  replyCount,
  createdDate,
  itemId,
  eventId,
  onUnLikeClick,
  getLikes,
  showLikes,
  onDeleteClick,
  onEditClick,
  onClick,
}) => {
  const [loadingUserInfo] = useState(false);
  const history = useHistory();
  const [hasLikedPost, setHasLikedPost] = useState(false);
  const [likes, setLikes] = useState(likeCount);
  const [openOption, setOpenOption] = useState(false);
  const user = get_user_info_from_token().user;

  useEffect(() => {
    // getLikes()

    getLikes &&
      getLikes(itemId, eventId).then((res) => {
        const { status, data } = res;
        if (status && (status === 200 || status === 201)) {
          if (data.data) {
            setHasLikedPost(true);
          }
        }
      });
  }, [itemId]);

  useEffect(() => {
    setLikes(likeCount || 0);
  }, [likeCount]);

  const displayFiles = () => {
    const allFiles = files.filter((file) => file && file.length > 1);
    return <FileViewerReadOnly files={allFiles} />;
  };

  const _onLikeClick = () => {
    if (hasLikedPost) {
      setLikes(likes - 1);
      setHasLikedPost(false);
      onUnLikeClick();
    } else {
      setHasLikedPost(true);
      setLikes(likes + 1);
      onLikeClick();
    }
  };

  const viewUser = () => {
    history.push(`/profile/${senderId}`);
  };

  return (
    <div className="chat-container">
      <div className="chat-container__chat-item">
        <div className="image-container">
          {!imageUrl ? (
            <ReactAvatar name={`${firstName} ${lastName}`} round size={24} />
          ) : (
            <Avatar
              dimension="24px"
              src={imageUrl}
              onClick={() => viewUser()}
            />
          )}
        </div>
        <div className="content-container postion_relative">
          <h1
            onClick={() => {
              viewUser();
            }}
            className="user-name"
          >
            {loadingUserInfo ? <Skeleton /> : `${firstName} ${lastName}`}

            {/* {`${userInfo.firstName} ${userInfo.lastName}`} <span>mentor</span> */}
          </h1>
          {senderId === user.id && (
            <div className="q_and_a_dot_options">
              <BiDotsHorizontalRounded
                size={20}
                className="pointer"
                onClick={() => setOpenOption(true)}
              />
              <ClickOutside onClickOutside={() => setOpenOption(false)}>
                {openOption ? (
                  <MoreOptions
                    onDeleteClick={onDeleteClick}
                    onEditClick={onEditClick}
                    type={type}
                  />
                ) : null}
              </ClickOutside>
            </div>
          )}

          {/* <p>{data.message || data.note}</p> */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              overflow: "auto",
            }}
            className="chat-text"
          >
            <EditorContentDisplay
              content={message}
              className="chat-text"
              style={{ cursor: "pointer" }}
              onClick={onClick}
            />
          </div>
          <>{files && displayFiles()}</>
          <div className="like-reply-container">
            {showLikes && (
              <span className="like">
                <IoMdHeart
                  onClick={_onLikeClick}
                  className="like-reply-icon"
                  color={`${hasLikedPost ? "var(--tef-red)" : ""}`}
                />{" "}
                <span>{likes} Likes</span>
              </span>
            )}

            {type === DISCUSSION && (
              <span className="reply">
                <FaRegCommentAlt
                  onClick={onReplyClick}
                  className="like-reply-icon"
                />{" "}
                <span>{replyCount || 0} Answer(s)</span>
              </span>
            )}

            {type === DISCUSSION && (
              <span className="discussion-date">
                <span>{new Date(createdDate).toDateString()}</span>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

DiscussionChat.defaultProps = {
  onLikeClick: () => {},
  onReplyClick: () => {},
  files: null,
  // getLikes: () => {},
  onUnLikeClick: () => {},
  showLikes: true,
  onClick: () => {},
};

export default DiscussionChat;
