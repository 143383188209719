import axios from "axios";
import { env } from "src/constants/env";
import setAuthToken from "./AuthTokenUtils";
import { googleAnalyticsTiming } from "./googleAnalytics";

axios.defaults.baseURL = env.REACT_APP_BASE_URL;

setAuthToken(
  localStorage.getItem("data") && JSON.parse(localStorage.getItem("data")).token
);

// GUIDs are 36 alphanumeric characters with dashes.
const GUID_REGEX = /[0-9a-f-]{36}/g;
// The query in the URL starts with a question mark.
const QUERY_REGEX = /\?.*/;

const reportAxiosTiming = (response) => {
  if (!window.performance) {
    return;
  }
  const duration = Math.round(
    performance.now() - response.config.metadata.startTime
  );
  const anonymizedURL = response.config.url
    .replace(GUID_REGEX, "{id}")
    .replace(QUERY_REGEX, "");
  googleAnalyticsTiming({
    category: "Axios",
    variable: `${response.config.method.toUpperCase()} ${anonymizedURL}`,
    milliseconds: duration,
  });
};

// Request interceptor sets startTime.
axios.interceptors.request.use(
  function (config) {
    if (!window.performance) {
      return config;
    }

    if (config.metadata) {
      config.metadata.startTime = performance.now();
    } else {
      config.metadata = { startTime: performance.now() };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Response interceptor reports to Google Analytics.
axios.interceptors.response.use(
  function (response) {
    reportAxiosTiming(response);
    return response;
  },
  function (error) {
    reportAxiosTiming(error);
    return Promise.reject(error);
  }
);

export default axios;
