export const ageRange = {
  en: [
    {
      value: "18-23",
      label: "18-23",
    },
    {
      value: "24-33",
      label: "24-33",
    },
    {
      value: "34-40",
      label: "34-40",
    },
    // {
    //   value: "43-50",
    //   label: "43-50",
    // },
    // {
    //   value: "above 50",
    //   label: "above 50",
    // },
  ],

  ar: [
    {
      value: "18-23",
      label: "18-23",
    },
    {
      value: "24-33",
      label: "24-33",
    },
    {
      value: "34-40",
      label: "34-40",
    },
    // {
    //   value: "43-50",
    //   label: "43-50",
    // },
    // {
    //   value: "فوق 50",
    //   label: "فوق 50",
    // },
  ],

  fr: [
    {
      value: "18-23",
      label: "18-23",
    },
    {
      value: "24-33",
      label: "24-33",
    },
    {
      value: "34-40",
      label: "34-40",
    },
    // {
    //   value: "43-50",
    //   label: "43-50",
    // },
    // {
    //   value: "Au-dessus de 50",
    //   label: "Au-dessus de 50",
    // },
  ],
  pt: [
    {
      value: "18-23",
      label: "18-23",
    },
    {
      value: "24-33",
      label: "24-33",
    },
    {
      value: "34-40",
      label: "34-40",
    },
    // {
    //   value: "43-50",
    //   label: "43-50",
    // },
    // {
    //   value: " Acima de 50",
    //   label: " Acima de 50",
    // },
  ],
  sw: [
    {
      value: "18-23",
      label: "18-23",
    },
    {
      value: "24-33",
      label: "24-33",
    },
    {
      value: "34-40",
      label: "34-40",
    },
    // {
    //   value: "43-50",
    //   label: "43-50",
    // },
    // {
    //   value: "above 50",
    //   label: "above 50",
    // },
  ],
};
