import axios from "axios";
import { asyncActions } from "src/utils/AsyncUtils";
import { NotificationURL } from "src/constants";
import { GET_NOTIFICATIONS, READ_NOTIFICATION } from "src/actions";

export const getNotifications = (dispatch) => {
  dispatch(asyncActions(GET_NOTIFICATIONS).loading(true));
  return axios({
    method: "get",
    url: `${NotificationURL.GET_ALL_NOTIFICATIONS}`,
  });
};

export const readNotification = (dispatch, id) => {
  dispatch(asyncActions(READ_NOTIFICATION).loading(true));
  return axios({
    method: "PATCH",
    url: `${NotificationURL.GET_ALL_NOTIFICATIONS}?ids=${id}`,
  });
};
