/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Col, Row, Button } from "reactstrap";
import UploadDocumentModal from "./Modals/UploadDocumentModal/UploadDocument";

const UploadDocument = ({
  businessPlanText,
  tabId,
  handleBuilder,
  handleUpload,
  documentTypes,
  handleDocumentSelect,
  uploadLoader,
  canUpload,
}) => {
  return (
    <Row>
      <Col md={7}>
        <div className="mt-5 pt-3 uploaded-documents">
          <p>Uploaded Documents {businessPlanText}</p>
          {tabId === 1 && (
            <small style={{ color: "#BDC3CC" }}>
              You are required to upload all due diligence documents listed in
              the “Stage Required Documents” section, with the exception of
              ‘Partner Consent’ which is only compulsory if you have a business
              partner.
            </small>
          )}
          {tabId === 2 && (
            <small style={{ color: "#BDC3CC" }}>
              You are required to submit your "Business Summary Plan" which is a
              cohesive document of your business operation & milestones.
            </small>
          )}
          {tabId === 3 && (
            <small style={{ color: "#BDC3CC" }}>
              You are expected to upload all seed capital documents listed in
              the Stage Required Document’’ section.
            </small>
          )}
        </div>
      </Col>
      <Col md={5}>
        <div className="mt-5 pt-5">
          <UploadDocumentModal
            handleDocumentSelect={handleDocumentSelect}
            documentTypes={documentTypes}
            handleUpload={handleUpload}
            uploadLoader={uploadLoader}
            canUpload={canUpload}
          />
        </div>
        {tabId === 2 && (
          <div className="mt-2">
            {/* <Button
              style={{ width: "100%" }}
              className="shadow-none"
              outline
              color="secondary"
              onClick={() => handleBuilder()}
              disabled
            >
              Use Builder
            </Button> */}
          </div>
        )}
      </Col>
    </Row>
  );
};

export default UploadDocument;
