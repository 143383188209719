import React from "react";
import { FormGroup, Label, Col, Input } from "reactstrap";
import { FaTimes } from "react-icons/fa";

import "./QuestionFormField.scss";

const QuestionFormField = ({ name, index, remove, handleChange }) => (
  <FormGroup row>
    <Col sm={10}>
      <Input
        type="text"
        name={name}
        className="form-input"
        onChange={handleChange}
        required={true}
      />
    </Col>
    <Label sm={2} onClick={() => remove(index)} className="form-label">
      <FaTimes />
    </Label>
  </FormGroup>
);

export default QuestionFormField;
