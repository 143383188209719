import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import "../Articles/Article.scss";
import Title from "src/modules/Discover/components/Title/Title";
import { FaRegBookmark, FaRegSave, FaShareAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { Context } from "src/store";
import moment from "moment";
import ContentLoader from "react-content-loader";
import Avatar from "src/components/Avatar/Avatar";
import { getAllLearningResources } from "src/requests/LearningResource";
import { imageValid } from "src/modules/Discover/helper";
import Swal from "sweetalert2";
import {
  createAndDeleteBookmark,
  downloadResourceSummary,
  getAllBookmarks,
} from "src/requests/DiscoverRequest";
import { useCurrentUser } from "src/utils/Services/currentUser";
// import EmptyImage from "src/assets/img/courseImage.jpg";
import classes from "./TefLearningResources.module.css";

const ReactContentLoader = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div
        style={{ backgroundColor: "#f3f3f3", height: "200px", width: "100%" }}
      ></div>
      <div
        style={{
          backgroundColor: "white",
          padding: "10px",
        }}
        // className="loader-item"
      >
        <ContentLoader
          speed={2}
          // width={324}
          // height={150}
          viewBox="0 0 400 160"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="8" rx="3" ry="3" width="300" height="30" />
          <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
          <rect x="0" y="56" rx="3" ry="3" width="300" height="6" />
          <rect x="0" y="72" rx="3" ry="3" width="300" height="6" />
          <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
          {/* <circle cx="20" cy="20" r="20" /> */}
        </ContentLoader>
      </div>
    </div>
  );
};
const TefLearningResources = ({
  numberToReturn,
  handleSeeAll,
  filterOptions,
}) => {
  const history = useHistory();
  const [learningResources, setLearningResources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loading] = useState(false);
  const [pageDetails, setPageDetails] = useState({
    pageNumber: 1,
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const [resourcesLength, setResourcesLength] = useState(0);
  const { dispatch } = useContext(Context);
  const [bookmarked, setBookmarked] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [sortString, setSortString] = useState("");
  const userId = useCurrentUser().userId;

  useEffect(() => {
    _getAllLearningResources(1, false);
    getAllBookmarked();
  }, []);

  useEffect(() => {
    _getAllLearningResources(1, false, sortString);
  }, [sortString]);

  const _onLoadMoreClick = () => {
    const pageNum = pageDetails.pageNumber + 1;
    _getAllLearningResources(pageNum, true);
  };
  const _getAllLearningResources = (pageNumber, loadMore, sortBy) => {
    getAllLearningResources(dispatch, pageNumber, sortBy).then((res) => {
      if (res.status === 200) {
        const {
          data: { data, pager },
        } = res.data;
        if (data && data.length > 0) {
          setResourcesLength(data.length);
          const renderedData = numberToReturn
            ? data.slice(0, numberToReturn)
            : data;
          if (loadMore) {
            const previousLearningResource = [...learningResources];
            const newLearningResource = [...previousLearningResource, ...data];
            setLearningResources(newLearningResource);
          } else {
            setLearningResources(renderedData);
          }
          setPageDetails({ ...pageDetails, ...pager });
        } else {
          setResourcesLength(0);
          setLearningResources([]);
        }
        setIsLoading(false);
      }
    });
  };
  const RenderContentLoader = () => {
    const arr = [1, 1, 1, 1];
    const contentArr = arr.fill(4);
    const contLoadArr = contentArr.map((c, idx) => (
      <Col md={6} key={idx}>
        <ReactContentLoader />
      </Col>
    ));
    return <Row>{contLoadArr}</Row>;
  };

  const handleLearningResourcesDetails = (itemId) => {
    history.push(`/discover/learning-resource/${itemId}`);
  };

  const handleBookmark = async (storyId, title) => {
    Swal.fire({
      title: "Bookmark?",
      text: bookmarked.find((b) => b.contentId === storyId)
        ? "You are about removing this resource from your bookmark list?"
        : "Are you sure you want to bookmark this resource?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#da291c",
      cancelButtonColor: "#63666a",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        createAndDeleteBookmark(
          userId,
          storyId,
          title,
          "TEF Learning Resources"
        ).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Bookmarked!",
              text: "Resource Bookmarked!",
              confirmButtonColor: "#da291c",
            }).then(() => setRefresh(!refresh));
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Unable to bookmark resource at this time, try again!",
              confirmButtonColor: "#da291c",
            }).then(() => setRefresh(!refresh));
          }
        });
      }
    });
  };

  const getAllBookmarked = async () => {
    const bookmarked = await getAllBookmarks(userId);
    const {
      data: { data },
    } = bookmarked;
    setBookmarked(data);
  };

  const handleDownload = (id, author) => {
    Swal.fire({
      title: "Download?",
      text: "Are you sure you want to download this resource?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#da291c",
      cancelButtonColor: "#63666a",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        downloadResourceSummary(id, author).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Downloaded!",
              text: "Resource Downloaded!",
              confirmButtonColor: "#da291c",
            }).then(() => setRefresh(!refresh));
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Unable to download resource at this time, try again!",
              confirmButtonColor: "#da291c",
            }).then(() => setRefresh(!refresh));
          }
        });
      }
    });
  };

  const getSortBy = (str) => {
    setSortString(str);
  };

  return (
    <>
      {isLoading ? (
        RenderContentLoader()
      ) : (
        <div className="mt-3">
          <Title
            name="TEF Learning Resources"
            nameLength={resourcesLength}
            seeAll="See All"
            seeAllAction={() => handleSeeAll("TEF Learning Resources")}
            showFilterOptions={filterOptions}
            sortBy={getSortBy}
          />
          <div className="video-list-item p-3 mt-2">
            <Row>
              {learningResources.map((item, index) => (
                <Col md={12} key={index}>
                  <div className="mb-2 group-list d-flex">
                    <div
                      className="group-list__image mr-4"
                      onClick={() => handleLearningResourcesDetails(item.id)}
                    >
                      <img
                        src={
                          imageValid(item.featureImageUrl)
                            ? item.featureImageUrl
                            : "https://cdn.pixabay.com/photo/2017/04/19/13/16/computer-2242264_1280.jpg"
                        }
                        alt="group logo"
                      />
                    </div>
                    <div className="pt-3">
                      <h1
                        onClick={() => handleLearningResourcesDetails(item.id)}
                      >
                        {item.title}
                      </h1>
                      <div
                        onClick={() => handleLearningResourcesDetails(item.id)}
                        className="group-list__group-category"
                      >
                        <p>{item.shortDescription}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          {/* <img
                            src={item.userAvatar}
                            alt="avatar"
                            className="user-avartar mr-1"
                          /> */}
                          <Avatar
                            // src={item.imageUrl}
                            name={item.author}
                            dimension={30}
                          />
                          <span className="username">{item.author}</span>
                          <span className="separator mx-2"></span>
                          <span className="date-posted">
                            {moment(item.publishedDate).fromNow()}
                          </span>
                        </div>
                        <div
                          onClick={() => handleDownload(item.id, item.author)}
                        >
                          <FaRegSave />
                          <span className="save-btn ml-1 mr-2">Save</span>
                        </div>
                        <div>
                          <FaShareAlt />
                          <span className="share-btn ml-1 mr-2">Share</span>
                        </div>
                        <div>
                          <span
                            onClick={() => {
                              handleBookmark(item.id, item.title);
                            }}
                            className={
                              bookmarked.find((b) => b.contentId === item.id)
                                ? classes.Bookmarked
                                : ""
                            }
                          >
                            <FaRegBookmark size="0.8em" />
                            <span className="ml-1 mr-2">
                              {bookmarked.find((b) => b.contentId === item.id)
                                ? "Bookmarked"
                                : "Bookmark"}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            {pageDetails.hasNextPage && (
              <Row>
                <Button
                  disabled={loading}
                  onClick={_onLoadMoreClick}
                  className="loadmore-btn"
                >
                  Show more
                </Button>
              </Row>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TefLearningResources;
