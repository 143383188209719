/* eslint-disable no-prototype-builtins */
import axios from "src/utils/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CREATE_GOAL,
  UPDATE_GOAL,
  VIEW_ALL_GOALS,
  DELETE_GOAL,
  VIEW_ALL_GOALS_ANALYSIS,
  VIEW_GOAL,
  CHANGE_GOAL_STATUS,
  RATE_GOAL,
  CHANGE_MENTEE_GOAL_STATUS,
  UPDATE_GOAL_REMINDER,
} from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import { GoalUrl } from "src/constants";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const user = get_user_info_from_token() && get_user_info_from_token().user;
let mentorshipGroup;
if (user)
  mentorshipGroup =
    user && user.hasOwnProperty("tef-mentor")
      ? user["tef-mentor"]
      : user.hasOwnProperty("tef-mentee")
      ? user["tef-mentee"]
      : null;
// if (!mentorshipGroup) return;
// axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1]

export const createGoal = (dispatch, payload) => {
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];

  dispatch(asyncActions(CREATE_GOAL).loading(true));
  return axios({
    method: "post",
    url: GoalUrl.CREATE_GOAL_URL,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(CREATE_GOAL).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(CREATE_GOAL).success(response.data));
        dispatch(asyncActions(CREATE_GOAL).loading(false));
        getAllGoals(dispatch).then(() => {
          toast("Goal Created Successfully");
        });
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(CREATE_GOAL).loading(false));
      dispatch(asyncActions(CREATE_GOAL).failure(true, error.response));
      toast("An error occurred, Please try again ");
      return error.response;
    });
};

export const getAllGoals = (dispatch) => {
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(VIEW_ALL_GOALS).loading(true));

  return axios({
    method: "get",
    url: GoalUrl.VIEW_ALL_GOALS_URL,
  })
    .then((response) => {
      dispatch(asyncActions(VIEW_ALL_GOALS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(VIEW_ALL_GOALS).success(data));
        dispatch(asyncActions(VIEW_ALL_GOALS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(VIEW_ALL_GOALS).loading(false));
      dispatch(asyncActions(VIEW_ALL_GOALS).failure(true, error.response));
      return error.response;
    });
};

export const getGoal = (dispatch, id, loading = true) => {
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];

  dispatch(asyncActions(VIEW_GOAL).loading(loading));
  return axios({
    method: "get",
    url: `${GoalUrl.VIEW_GOAL}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(VIEW_GOAL).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(VIEW_GOAL).success(data));
        dispatch(asyncActions(VIEW_GOAL).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(VIEW_GOAL).loading(false));
      dispatch(asyncActions(VIEW_GOAL).failure(true, error.response));
      return error.response;
    });
};

export const getAllGoalsAnalysis = (dispatch) => {
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];

  dispatch(asyncActions(VIEW_ALL_GOALS_ANALYSIS).loading(true));
  return axios({
    method: "get",
    url: GoalUrl.VIEW_GOALS_ANALYSIS_URL,
  })
    .then((response) => {
      dispatch(asyncActions(VIEW_ALL_GOALS_ANALYSIS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(VIEW_ALL_GOALS_ANALYSIS).success(data));
        dispatch(asyncActions(VIEW_ALL_GOALS_ANALYSIS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(VIEW_ALL_GOALS_ANALYSIS).loading(false));
      dispatch(
        asyncActions(VIEW_ALL_GOALS_ANALYSIS).failure(true, error.response)
      );
      return error.response;
    });
};

export const deleteGoal = (dispatch, id) => {
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];

  dispatch(asyncActions(DELETE_GOAL).loading(true));
  return axios({
    method: "delete",
    url: `${GoalUrl.GOAL_BASE}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(DELETE_GOAL).loading(false));
      if (response.status === 200) {
        // const { data } = response.data;
        dispatch(asyncActions(DELETE_GOAL).success(id));
        dispatch(asyncActions(DELETE_GOAL).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(DELETE_GOAL).loading(false));
      dispatch(asyncActions(DELETE_GOAL).failure(true, error.response));
      return error.response;
    });
};

export const updateGoalStatus = (dispatch, id, status) => {
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];

  dispatch(asyncActions(CHANGE_GOAL_STATUS).loading(true));
  return axios({
    method: "patch",
    url: `${GoalUrl.GOAL_BASE}/${id}/${status}`,
  })
    .then((response) => {
      dispatch(asyncActions(CHANGE_GOAL_STATUS).loading(false));
      if (response.status === 200) {
        getGoal(dispatch, id, false).then((res) => {
          if (res.status === 200) {
            dispatch(asyncActions(CHANGE_GOAL_STATUS).success(res.data.data));
          }
        });
        dispatch(asyncActions(CHANGE_GOAL_STATUS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(CHANGE_GOAL_STATUS).loading(false));
      dispatch(asyncActions(CHANGE_GOAL_STATUS).failure(true, error.response));
      return error.response;
    });
};
export const updateMenteeGoalStatus = (dispatch, id, status) => {
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];

  dispatch(asyncActions(CHANGE_MENTEE_GOAL_STATUS).loading(true));
  return axios({
    method: "patch",
    url: `${GoalUrl.GOAL_BASE}/${id}/mentee/status`,
  })
    .then((response) => {
      dispatch(asyncActions(CHANGE_MENTEE_GOAL_STATUS).loading(false));
      if (response.status === 200) {
        getGoal(dispatch, id, false).then((res) => {
          if (res.status === 200) {
            dispatch(
              asyncActions(CHANGE_MENTEE_GOAL_STATUS).success(res.data.data)
            );
          }
        });
        dispatch(asyncActions(CHANGE_MENTEE_GOAL_STATUS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(CHANGE_MENTEE_GOAL_STATUS).loading(false));
      dispatch(
        asyncActions(CHANGE_MENTEE_GOAL_STATUS).failure(true, error.response)
      );
      return error.response;
    });
};

export const rateGoal = (dispatch, id, rating) => {
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];

  dispatch(asyncActions(RATE_GOAL).loading(true));
  return axios({
    method: "patch",
    url: `${GoalUrl.GOAL_BASE}/${id}/${rating}/update`,
  })
    .then((response) => {
      dispatch(asyncActions(RATE_GOAL).loading(false));
      if (response.status === 200) {
        getGoal(dispatch, id, false).then((res) => {
          if (res.status === 200) {
            dispatch(asyncActions(RATE_GOAL).success(res.data.data));
          }
        });
        dispatch(asyncActions(RATE_GOAL).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(RATE_GOAL).loading(false));
      dispatch(asyncActions(RATE_GOAL).failure(true, error.response));
      return error.response;
    });
};

export const updateGoal = (dispatch, id, payload) => {
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];

  dispatch(asyncActions(CREATE_GOAL).loading(true));
  return axios({
    method: "patch",
    url: `${GoalUrl.CREATE_GOAL_URL}/${id}`,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(UPDATE_GOAL).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(UPDATE_GOAL).success(response.data));
        dispatch(asyncActions(UPDATE_GOAL).loading(false));
        getGoal(dispatch, id).then(() => {
          toast("Goal Updated Successfully");
        });
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(UPDATE_GOAL).loading(false));
      dispatch(asyncActions(UPDATE_GOAL).failure(true, error.response));
      toast("An error occurred, Please try again ");
      return error.response;
    });
};

export const updateGoalReminder = (dispatch, goalId) => {
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];

  dispatch(asyncActions(CREATE_GOAL).loading(true));
  return axios({
    method: "put",
    url: `${GoalUrl.UPDATE_GOAL_REMINDER}/${goalId}`,
  })
    .then((response) => {
      dispatch(asyncActions(UPDATE_GOAL_REMINDER).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(UPDATE_GOAL_REMINDER).success(response.data));
        dispatch(asyncActions(UPDATE_GOAL_REMINDER).loading(false));
        getGoal(dispatch, goalId).then(() => {
          toast("Reminder successfully updated");
        });
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(UPDATE_GOAL_REMINDER).loading(false));
      dispatch(
        asyncActions(UPDATE_GOAL_REMINDER).failure(true, error.response)
      );
      toast("An error occurred, Please try again ");
      return error.response;
    });
};
