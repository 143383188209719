/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  FormFeedback,
  Button,
  Spinner,
} from "reactstrap";
import { CustomCountryDropdown } from "src/components/CustomCountryRegionDropdown";
import { languages } from "src/modules/onboarding/tefMentorOnboarding/static";
import { industryTypes } from "./industryType";
import { formatMaxBirthYear, gender, validateBirthYear } from "./Sections";
import styles from "./StepTwo_v2.module.scss";
import ValidateJs from "src/utils/Validations/validation_wrapper";
import { defaultUserInfo } from "src/modules/onboarding/stepOne/userInfo";
import {
  get_user_info_from_token,
  refreshToken,
} from "src/utils/someTokenMenthods";
import {
  CastValue,
  retrieveUserTempProfile,
  storeOnboardingProgress,
} from "src/modules/onboarding/helper";
import { useHistory } from "react-router-dom";
import {
  getMentorshipAreas,
  submitOnboardingData,
} from "src/requests/onboarding";
import { Context } from "src/store";
import { _setCurrentStep } from "src/modules/onboarding/stepThree/stepThree";
import { getUserUpdatedProfile } from "src/requests/ProfileRequest";
import { Multiselect } from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";

const OnboardingForm = ({ setLoading }) => {
  const [data, setData] = useState(defaultUserInfo);
  const [areas, setAreas] = useState([]);
  const { dispatch } = useContext(Context);
  const user = get_user_info_from_token().user;
  const [loadingPrevious, setLoadingPrevious] = useState(false);
  const [locationKeys, setLocationKeys] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const history = useHistory();

  const currentDate = new Date();

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);

          // Handle back event
          _onPrevious();
        }
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    retrieveUserTempProfile().then((profile) => {
      if (!profile || profile === null) {
        history.replace({ pathname: "/onboarding", search: "" });
      } else {
        if (!profile.groupId || profile.groupId === null) {
          history.push({ pathname: "/onboarding", search: "" });
        } else {
          if (
            profile.role.toLowerCase() ===
            process.env.REACT_APP_MENTOR_KEYWORD.toLowerCase()
          ) {
            getMentorshipAreas(dispatch).then((res) => {
              const { status, data } = res;
              if (status === 200) {
                const ars = data.map((a) => {
                  return { value: a.id, label: a.area };
                  // return { value: a.id, label: t(`${a.area}`) };
                });
                setAreas(ars);
              } else {
                setAreas([]);
              }
            });
          }
          setLoading(false);
          // delete profile.dateOfBirth;
          setData(profile);
        }
      }
    });
  }, []);

  const validateSubmit = () => {
    const {
      dateOfBirth,
      gender,
      phone,
      businessIndustry,
      country,
      languages,
    } = data;
    let validate = { gender, phone, businessIndustry, country, languages };
    if (
      data.role.toLowerCase() ===
      process.env.REACT_APP_ENTREPRENEUR_KEYWORD.toLowerCase()
    ) {
      validate = { ...validate, dateOfBirth };
      if (dateOfBirth) {
        delete validate.dateOfBirth;
      }
    }
    let errors = {};
    Object.keys(validate).forEach((key) => {
      errors = { ...errors, [key]: ValidateJs(key, validate[key]) };
    });

    const errorKeys = [];

    Object.keys(errors).forEach((key) => {
      if (errors[key] !== null) {
        errorKeys.push(key);
      }
    });
    setData({ ...data, validationErrors: errors });

    return {
      confirmed: errorKeys.length > 0,
      errorKeys,
    };
  };

  const _handleChange = (target, value) => {
    setData({ ...data, [target]: CastValue(target, value) });
  };

  const _handleValidationErrorChanges = (target, value) => {
    const obj = { ...data.validationErrors, [target]: value };
    _handleChange("validationErrors", obj);
  };

  const _onPrevious = () => {
    setLoadingPrevious(true);
    let uInfo = JSON.parse(JSON.stringify(data));
    Object.keys(uInfo.validationErrors).forEach((key) => {
      uInfo.validationErrors[key] = null;
    });
    uInfo = _setCurrentStep(
      uInfo,
      uInfo.steps.currentCompletedStep,
      window.location.pathname,
      "/onboarding"
    );
    previousStep(uInfo).then(() => {
      history.replace("/onboarding", {});
      history.go(1);
    });
  };

  const previousStep = (userInfo) => {
    return new Promise((resolve, reject) => {
      const info = JSON.parse(JSON.stringify(userInfo));
      storeOnboardingProgress(JSON.stringify(info)).then((status) => {
        setLoadingPrevious(false);
        if (status === "success") {
          return resolve();
        }
      });
    });
  };

  const makeEmptyNull = (list, data) => {
    const obj = { ...data };
    for (const key of list) {
      if (obj[key].length === 0) {
        obj[key] = null;
      }
    }
    return obj;
  };

  const _sanitizeData = (data) => {
    const userArea = data.userMentorshipAreas
      ? data.userMentorshipAreas.map((area) => {
          const { value } = area;
          return { mentorshipAreaId: value };
        })
      : [];

    const languages = data.languages
      ? data.languages.map((lang) => {
          return lang.value;
        })
      : [];

    const obj = {
      ...data,
      userMentorshipAreas: userArea,
    };
    obj.firstName = user.firstName;
    obj.lastName = user.lastName;
    obj.email = user.email;
    if (
      obj.role.toLowerCase() ===
      process.env.REACT_APP_ENTREPRENEUR_KEYWORD.toLowerCase()
    ) {
      obj.language = languages[0];
    } else {
      obj.tefMentor.languages = languages;
    }
    // delete obj["countryPhoneCode"];
    // delete obj.role;
    delete obj.validationErrors;
    delete obj.languages;
    // delete obj["steps"];
    return makeEmptyNull(
      [
        "facebookUrl",
        "twitterUrl",
        "websiteUrl",
        "linkedinUrl",
        "businessWebsite",
        "imageUrl",
        "youtubeUrl",
        "instagramUrl",
      ],
      obj
    );
  };

  const _submit = (data) => {
    const role = data.role;
    const redirectTo =
      role && role.toLowerCase() === process.env.REACT_APP_ENTREPRENEUR_KEYWORD
        ? "/programme/dashboard"
        : "/home";
    delete data.role;

    setLoadingSubmit(true);
    submitOnboardingData(dispatch, data, history)
      .then((res) => {
        const { status } = res;
        if (status === 201 || status === 200) {
          return refreshToken()
            .then(() => {
              return getUserUpdatedProfile(dispatch, user.id).then(() => {
                setLoadingSubmit(false);
                return history.replace({
                  pathname: redirectTo,
                });
              });
            })
            .catch((e) => {
              setLoadingSubmit(false);
              history.replace({
                pathname: redirectTo,
              });
            });
        }
        setLoadingSubmit(false);
      })
      .catch((e) => {
        console.log(e);
        setLoadingSubmit(false);
      });
  };

  const sumbitForm = (userInfo) => {
    if (validateSubmit().confirmed) {
      return;
    }
    setLoadingSubmit(true);
    const { role } = userInfo;
    let data = Object.assign({}, userInfo);
    delete data.roledId;

    delete data.investor;
    delete data.isTefMentor;
    if (
      role.toLowerCase() === process.env.REACT_APP_MENTOR_KEYWORD.toLowerCase()
    ) {
      delete data.dateOfBirth;
      data = _sanitizeData(data);
      // if (!data.tefMentor.isATefMentor) {
      //   data.tefMentor.yearOfTefMentorship = "";
      // }
      _submit(data);
    } else {
      delete data.tefMentor;
      data = _sanitizeData(data);
      _submit(data);
    }
  };

  return (
    <Form
      className={`${styles.form_container} notranslate`}
      onSubmit={(e) => {
        e.preventDefault();
        sumbitForm(data);
      }}
    >
      <h4>Complete your TEF Registration</h4>
      <p>Kindly provide the rest of your details below</p>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label className={styles.label}>First Name</Label>
            <Input
              // required
              placeholder="Enter First Name"
              className={styles.input}
              disabled
              value={user.firstName}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label className={styles.label}>Last Name</Label>
            <Input
              placeholder="Enter Last Name"
              className={styles.input}
              disabled
              value={user.lastName}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label className={styles.label}>Gender</Label>
            <Input
              required
              placeholder="Select Gender"
              type="select"
              className={styles.input}
              onChange={(e) => _handleChange(e.target.name, e.target.value)}
              name="gender"
              onBlur={() =>
                _handleValidationErrorChanges(
                  "gender",
                  ValidateJs("gender", data.gender)
                )
              }
              invalid={data.validationErrors.gender !== null}
              value={data.gender}
            >
              <option value="">Select Gender</option>
              {gender.map((g, _) => {
                return (
                  <option key={_} value={g.value}>
                    {g.label}
                  </option>
                );
              })}
            </Input>
            {data.validationErrors.gender && (
              <FormFeedback>{data.validationErrors.gender}</FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={6} data-testid="phone">
          <Label className={styles.label}>Phone Number</Label>
          <PhoneInput
            countryCodeEditable={false}
            enableSearch
            country={"ng"}
            value={data.phone}
            inputClass={`${styles.input}`}
            name="phone"
            onChange={(phone) => _handleChange("phone", phone)}
            autoFormat={false}
            inputStyle={{
              width: "100%",
              marginTop: "8px 10px",
              // height: "50px",
              borderColor: data.validationErrors.phone ? "red" : "",
            }}
            onBlur={() =>
              _handleValidationErrorChanges(
                "phone",
                ValidateJs("phone", data.phone)
              )
            }
          />
          {data.validationErrors.phone && (
            <span style={{ color: "var(--tef-red)" }}>
              {data.validationErrors.phone}
            </span>
          )}
          {/* <FormGroup>
            <Label className={styles.label}>Phone Number</Label>
            <Input
              required
              placeholder="Enter Phone Number"
              type="tel"
              className={styles.input}
              onChange={(e) => _handleChange(e.target.name, e.target.value)}
              name="phone"
              onBlur={() =>
                _handleValidationErrorChanges(
                  "phone",
                  ValidateJs("phone", data.phone)
                )
              }
              invalid={data.validationErrors.phone !== null}
              value={data.phone}
            />
            {data.validationErrors.phone && (
              <FormFeedback>{data.validationErrors.phone}</FormFeedback>
            )}
          </FormGroup> */}
        </Col>
      </Row>

      <Row className="notranslate">
        {data.role.toLowerCase() ===
          process.env.REACT_APP_ENTREPRENEUR_KEYWORD.toLowerCase() && (
          <Col md={6} className="notranslate">
            <FormGroup
              style={{ display: "flex", flexDirection: "column" }}
              className="notranslate"
            >
              <Label className={styles.label}>Date of Birth</Label>
              {/* <Input
                required
                placeholder="Enter Date of Birth"
                type="date"
                className={styles.input}
                max={formatMaxBirthYear(18)}
                onChange={(e) =>
                  validateBirthYear(
                    "dateOfBirth",
                    e.target.value,
                    _handleChange
                  )
                }
                onBlur={() =>
                  _handleValidationErrorChanges(
                    "dateOfBirth",
                    ValidateJs("dateOfBirth", data.dateOfBirth)
                  )
                }
                invalid={data.validationErrors.dateOfBirth !== null}
                value={data.dateOfBirth}
                name="dateOfBirth"
              /> */}

              <DatePicker
                className={`${styles.input} ${styles.c_style} notranslate`}
                placeholderText="YYYY-MM-DD"
                dateFormat="yyyy-MM-dd"
                selected={
                  data.dateOfBirth
                    ? new Date(data.dateOfBirth)
                    : data.dateOfBirth
                }
                onChange={(dateString) =>
                  _handleChange("dateOfBirth", dateString || "")
                }
                name="dateOfBirth"
                maxDate={
                  new Date(
                    currentDate.setFullYear(currentDate.getFullYear() - 18)
                  )
                }
                onBlur={() =>
                  _handleValidationErrorChanges(
                    "dateOfBirth",
                    ValidateJs("dateOfBirth", data.dateOfBirth.toString())
                  )
                }
                isClearable
                required
                shouldCloseOnSelect={false}
                forceShowMonthNavigation
                scrollableYearDropdown
                showMonthDropdown
                showYearDropdown
                yearDropdownItemNumber={70}
              />
              {data.validationErrors.dateOfBirth && (
                <span style={{ color: "var(--tef-red)" }}>
                  {data.validationErrors.dateOfBirth}
                </span>
              )}
            </FormGroup>
          </Col>
        )}
        <Col md={6}>
          <FormGroup>
            <Label className={styles.label}>
              Business{" "}
              {data.role.toLowerCase() ===
              process.env.REACT_APP_ENTREPRENEUR_KEYWORD.toLowerCase()
                ? "Industry"
                : "Sector"}
            </Label>
            <Input
              required
              placeholder="Enter Business Industry"
              type="select"
              className={styles.input}
              onBlur={() =>
                _handleValidationErrorChanges(
                  "businessIndustry",
                  ValidateJs("businessIndustry", data.businessIndustry)
                )
              }
              name="businessIndustry"
              invalid={data.validationErrors.businessIndustry !== null}
              value={data.businessIndustry}
              onChange={(e) => _handleChange(e.target.name, e.target.value)}
            >
              <option value="">Select Business Industry</option>
              {industryTypes.en.map((industry, idx) => {
                return (
                  <option key={idx} value={industry.value}>
                    {industry.label}
                  </option>
                );
              })}
            </Input>
            {data.validationErrors.businessIndustry && (
              <FormFeedback>
                {data.validationErrors.businessIndustry}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>

        {data.role.toLowerCase() ===
          process.env.REACT_APP_MENTOR_KEYWORD.toLowerCase() && (
          <Col md={6}>
            <FormGroup>
              <Label className={styles.label}>Area of Expertise</Label>
              <Multiselect
                placeholder="Area of Expertise"
                displayValue="label"
                options={areas}
                // onChange={(val) => _handleChange("userMentorshipAreas", val)}
                onSelect={(selectedList, selectedItem) =>
                  _handleChange("userMentorshipAreas", selectedList)
                }
                onRemove={(selectedList, selectedItem) =>
                  _handleChange("userMentorshipAreas", selectedList)
                }
                showArrow
                className={styles.input}
                selectedValues={data.userMentorshipAreas}
                style={{
                  chips: {
                    background: "var(--tef-red)",
                    color: "white",
                  },
                  searchBox: {
                    border: "none",
                    minHeight: "50px",
                    background: "white",
                  },
                  placeholder: {
                    color: "black",
                  },
                }}
              />
            </FormGroup>
          </Col>
        )}
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label className={styles.label}>Country</Label>
            <CustomCountryDropdown
              required
              valueType="short"
              invalid={data.validationErrors.country !== null}
              classes={styles.input}
              onBlur={() =>
                _handleValidationErrorChanges(
                  "country",
                  ValidateJs("country", data.country)
                )
              }
              name="country"
              onChange={(data, e) => _handleChange("country", data)}
              value={data.country}
            />
            {data.validationErrors.country && (
              <FormFeedback>{data.validationErrors.country}</FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label className={styles.label}>
              {data.role.toLowerCase() ===
              process.env.REACT_APP_ENTREPRENEUR_KEYWORD.toLowerCase()
                ? "Language"
                : "Language(s)"}{" "}
              fluent in
            </Label>
            <Multiselect
              // placeholder="Select Language(s)"
              displayValue="label"
              required
              options={languages.map((lang) => ({
                label: lang.label,
                value: lang.value,
              }))}
              singleSelect={
                data.role.toLowerCase() ===
                process.env.REACT_APP_ENTREPRENEUR_KEYWORD.toLowerCase()
              }
              onSelect={(selectedList, selectedItem) => {
                _handleChange("languages", selectedList);
              }}
              className={styles.input}
              onRemove={(selectedList, selectedItem) =>
                _handleChange("languages", selectedList)
              }
              showArrow
              selectedValues={data.languages}
              style={{
                chips: {
                  background: "var(--tef-red)",
                  color: "white",
                },
                searchBox: {
                  border:
                    data.validationErrors.languages !== null
                      ? "1px solid red"
                      : "none",
                  minHeight: "50px",
                  background: "white",
                },
                placeholder: {
                  color: "black",
                },

                // control: (base) => ({
                //   ...base,
                //   minHeight: 50,
                //   borderColor:
                //     data.validationErrors.languages !== null
                //       ? "red"
                //       : "transparent",
                //   outline: "none",
                //   "&:focus": {
                //     borderColor: "transparent",
                //   },
                // }),
                // menu: (base) => ({
                //   ...base,
                //   zIndex: 2,
                // }),

                // option: (base) => ({
                //   ...base,
                //   "&:hover": {
                //     borderColor: "red",
                //   },
                // }),
              }}
              onBlur={() =>
                _handleValidationErrorChanges(
                  "languages",
                  ValidateJs("languages", data.languages)
                )
              }
            />
            <Input invalid={data.validationErrors.languages !== null} hidden />
            {data.validationErrors.languages && (
              <FormFeedback>{data.validationErrors.languages}</FormFeedback>
            )}
          </FormGroup>
        </Col>
      </Row>

      <div className={styles.btn_div}>
        <Button
          color="primary"
          outline
          onClick={(e) => {
            e.preventDefault();
            _onPrevious();
          }}
          disabled={loadingPrevious || loadingSubmit}
        >
          Previous{" "}
          {loadingPrevious && (
            <Spinner size={15} style={{ height: 15, width: 15 }} />
          )}
        </Button>

        <Button
          className={styles.submit_btn}
          disabled={loadingPrevious || loadingSubmit}
          data-testid="complete"
        >
          Complete Onboarding{" "}
          {loadingSubmit && (
            <Spinner size={15} style={{ height: 15, width: 15 }} />
          )}
        </Button>
      </div>
    </Form>
  );
};
export default OnboardingForm;
