import React from "react";
import classes from "./DateTimeDetails.module.css";
import moment from "moment";

const DateTimeDetails = ({ date, startTime, endTime, duration, location }) => {
  return (
    <div className={classes.DateTimeDetails}>
      <div className={classes.Date}>
        <div className={classes.Value}>{new Date(date).toDateString()}</div>
        <div className={classes.Label}>Date</div>
      </div>
      <div className={classes.Time}>
        <div className={classes.Value}>
          {startTime} - {endTime}
        </div>
        <div className={classes.Label}>Time</div>
      </div>
      <div className={classes.Duration}>
        <div className={classes.Value}>
          {moment.duration(duration).asMinutes()} mins
        </div>
        <div className={classes.Label}>Duration</div>
      </div>
      <div className={classes.Location}>
        <div className={classes.Value}>{location}</div>
        <div className={classes.Label}>Location</div>
      </div>
    </div>
  );
};

export default DateTimeDetails;
