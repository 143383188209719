/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { FaShareAlt } from "react-icons/fa";
import "./DashboardCard.scss";
import { verifyResult } from "src/requests/ApplicationRequest";
import Loader from "react-loader-spinner";
import { Context } from "src/store";
import CategoryModal from "src/modules/Application/components/CategoryModal/CategoryModal";
import { retrieveTempUserApplication } from "src/modules/Application/helper";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import {
  LinkedinShareButton,
  TwitterShareButton,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import { useOuterClick } from "src/utils/helper";
import { useProgrammeDetails } from "src/modules/Application/applicationHooks";
import Swal from "sweetalert2";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import HowToApply from "src/modules/Program/HowToApplyModal/HowToApply";
import UNDPCategoryModal from "src/modules/Application/components/CategoryModal/UNDPCategoryModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const stopped = "stopped";
const notstarted = "notstarted";
const running = "running";

/**
 * A card representing a single program application.
 */
export const DashboardCard = ({
  cardWidth,
  title,
  overview,
  startDate,
  endDate,
  id,
  imageSrc,
  programmeType,
  status,
  isEligible,
  programmeEventId,
  index,
  removeTour,
  isTourOpen,
}) => {
  const { t } = useTranslation();
  const user = get_user_info_from_token();
  const { state, dispatch } = useContext(Context);
  const [isLoading, setisLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Apply");
  const [appPrgress, setAppProgress] = useState(0);
  const [score, setScore] = useState(0);
  const [showLevelModal, setShowLevelModal] = useState(false);
  const [showUndpLevelModal, setShowUndpLevelModal] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const history = useHistory();
  const [showShare, setShowShare] = useState(false);
  const [showApplyModal, setShowApplyModal] = useState(false);
  const toggleShare = () => setShowShare(!showShare);
  const toggleShowApplyModal = () => {
    if (isTourOpen) {
      removeTour();
    }
    setShowApplyModal(!showApplyModal);
  };

  const clickOut = useOuterClick(() => {
    setShowShare(false);
  });

  const [lang, setlang] = useState("en");
  const [progressLang, setProgressLang] = useState("en");

  const pDetails = useProgrammeDetails({
    programmeId: id,
    programmeTitle: title,
  });

  // console.log(title + " : ", new Date(endDate) < new Date());

  useEffect(() => {
    if (
      pDetails &&
      pDetails.applicationEvent &&
      pDetails.applicationEvent.status.toLowerCase() === running
    ) {
      retrieveTempUserApplication(pDetails.applicationEvent.id).then((app) => {
        if (app) {
          setInProgress(true);
          setProgressLang(app.languageId);
        }
      });
    }
  }, [pDetails.applicationEvent]);

  useEffect(() => {
    if (new Date(endDate) < new Date()) {
      setButtonText("Closed");
      setAppProgress(0);
      // return
    }
    if (pDetails && pDetails.applicationEvent) {
      const eventStatus = pDetails.applicationEvent.status;
      if (
        eventStatus.toLowerCase() === stopped ||
        status.toLowerCase() === stopped ||
        new Date(pDetails.applicationEvent.endDate) < new Date() ||
        new Date(endDate) < new Date()
      ) {
        setButtonText("Closed");
        setAppProgress(0);
        return;
      }

      let applications = [];
      applications =
        state.Application.userApplications &&
        state.Application.userApplications;
      const currentApplication = applications.find((app) => {
        return app.applicationId === pDetails.applicationEvent.id;
      });

      if (currentApplication) {
        setAppProgress(currentApplication.progress);
        setlang(currentApplication.languageId);
        if (currentApplication.progress < 100) {
          setButtonText("Complete");
        } else {
          setButtonText("Completed");
        }
      } else {
        if (inProgress) {
          setButtonText("In progress");
        } else {
          setAppProgress(0);
        }
      }
    }
  }, [state.Application.userApplications, inProgress, pDetails]);

  const displayError = (title, text, messageType = "warning") => {
    // warning, error, or success
    return Swal.fire({
      title,
      text,
      icon: messageType,
      confirmButtonColor: "red",
    });
  };

  const canApply = () => {
    let next = true;
    // check eligiblity
    if (!isEligible) {
      displayError("Not allowed", "You are not Eligible for this programme");
      next = false;
      setisLoading(false);
      return next;
    }
    if (status === stopped || new Date(endDate) < new Date()) {
      displayError("Not allowed", `The ${title} application window is closed`);
      next = false;
      setisLoading(false);
      return next;
    } else if (status.replace(" ", "") === notstarted) {
      displayError(
        "Not allowed",
        `This Programme has not started yet. check back later`
      );
      next = false;
      setisLoading(false);
      return next;
    } else {
      next = true;
      return next;
    }
  };

  const callback = (application) => {
    if (appPrgress === 0) {
      if (programmeType && programmeType.toLowerCase() === "offcycle") {
        return history.push({
          pathname: "/application/offcycle/tour",
          search: `?applicationId=${application.id}&endDate=${application.endDate}&lang=${progressLang}&programmeEventId=${programmeEventId}&title=${title}`,
        });
      }
      return history.push({
        pathname:
          programmeType && programmeType.toLowerCase() === "offcycle"
            ? "/application/offcycle/tour"
            : "/application/tour",
        search: `?applicationId=${application.id}&endDate=${application.endDate}&lang=${progressLang}&programmeEventId=${programmeEventId}&title=${title}`,
      });
    } else if (appPrgress > 0 && appPrgress === 40) {
      if (programmeType && programmeType.toLowerCase() === "offcycle") {
        return history.push({
          pathname: "/application/offcycle/tests/mcq",
          search: `?applicationId=${application.id}&endDate=${application.endDate}&lang=${lang}&title=${title}`,
        });
      }
      return history.push({
        pathname:
          programmeType && programmeType.toLowerCase() === "offcycle"
            ? "/application/offcycle/tests/mcq"
            : "/application/tests/personality",
        search: `?applicationId=${application.id}&endDate=${application.endDate}&lang=${lang}&title=${title}`,
      });
    } else if (appPrgress > 40 && appPrgress === 90) {
      if (programmeType && programmeType.toLowerCase() === "offcycle") {
        return history.push({
          pathname: "/application/offcycle/declaration",
          search: `?applicationId=${application.id}&endDate=${application.endDate}&lang=${lang}&title=${title}`,
        });
      }
      return history.push({
        pathname:
          programmeType && programmeType.toLowerCase() === "offcycle"
            ? "/application/offcycle/declaration"
            : "/application/declaration",
        search: `?applicationId=${application.id}&endDate=${application.endDate}&lang=${lang}&title=${title}`,
      });
    } else if (appPrgress > 90 && appPrgress === 100) {
      verify(application);
    }
  };

  const verify = (application) => {
    verifyResult(dispatch, application.id).then((res) => {
      const {
        data: {
          data: { status, score },
        },
      } = res;
      if (status === "Incompleted") {
        const redirectTo =
          programmeType && programmeType.toLowerCase() === "offcycle"
            ? `/application/offcycle/tests/mcq?applicationId=${application.id}&endDate=${application.endDate}&lang=${lang}&title=${title}`
            : `/application/tests/personality?applicationId=${application.id}&endDate=${application.endDate}&lang=${lang}&title=${title}`;
        history.push(redirectTo, {});
      } else {
        setScore(score);
        programmeType.toLowerCase() === "offcycle"
          ? setShowUndpLevelModal(true)
          : setShowLevelModal(true);
        setisLoading(false);
      }
    });
  };

  const hasActiveApplication = (application) => {
    // console.log("user", user.user.application[1]);
    // console.log("app", application.id);
    return (
      Object.prototype.hasOwnProperty.call(user.user, "application") &&
      application.id !== user.user.application[1] &&
      Object.prototype.hasOwnProperty.call(user.user, "dms_user_seed_capital")
    );
    // return false;
  };

  const _appyClick = () => {
    if (isTourOpen) {
      removeTour();
    }
    setisLoading(true);
    const canProceed = canApply();
    if (canProceed) {
      const {
        // loadingApplicationEvent,
        applicationEvent,
        error,
        message,
        // showErrorModal,
      } = pDetails;
      if (error) {
        setisLoading(false);
        return displayError("Not Allowed", message);
      } else {
        const hasAppliedBefore = hasActiveApplication(
          pDetails.applicationEvent
        );

        if (hasAppliedBefore) {
          setisLoading(false);
          return displayError(
            "Not Allowed",
            "You have already applied for a programme."
          );
        }

        callback(applicationEvent);
      }
    }
  };

  return (
    <div className="dashboard-card" style={{ width: cardWidth }}>
      <div className="programme-share-container" ref={clickOut}>
        <div
          className="programme-share-container__share-icon"
          onClick={toggleShare}
        >
          <FaShareAlt className="dashboard-icons" />
        </div>
        {showShare && (
          <>
            <div className="programme-share-container__share-icon actual-share-icon">
              <LinkedinShareButton
                round
                url={window.location.href + "?programmeId=" + id}
                title={title}
                summary={overview}
              >
                <LinkedinIcon round size={40}></LinkedinIcon>
              </LinkedinShareButton>
            </div>
            <div className="programme-share-container__share-icon actual-share-icon">
              <TwitterShareButton
                round
                url={window.location.href + "?programmeId=" + id}
                title={title}
              >
                <TwitterIcon round size={40} />
              </TwitterShareButton>
            </div>
          </>
        )}
      </div>
      <div className="dashboard-card-image">
        {imageSrc ? (
          <LazyLoadImage
            src={imageSrc}
            alt="Programme Flyer Goes Here"
            effect="blur"
            wrapperClassName="dashboard-card-img"
          />
        ) : (
          <Avatar name={title} size="100%" />
        )}
      </div>
      <div className="dashboard-card-details">
        <h5>{title}</h5>
        <h6>{overview}</h6>
        {programmeType.toLowerCase() === "offcycle" ? (
          <p>
            <a
              style={{ color: "red", textDecoration: "underline" }}
              target="blank"
              href={
                title.toLowerCase().includes("undp")
                  ? process.env.REACT_APP_HOW_TO_APPLY_LINK
                  : process.env.REACT_APP_HOW_TO_APPLY_LINK_BEDEA
              }
              className={`about-program-${index}`}
            >
              {t("read-more")}
            </a>
          </p>
        ) : (
          <p
            style={{
              color: "red",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={toggleShowApplyModal}
            className={`about-program-${index}`}
          >
            {t("read-more")}
          </p>
        )}
        <p>start date: {startDate} </p>
        <p>end date: {endDate} </p>
      </div>
      <div className="dashboard-card-actions">
        <button
          className={`apply-program-${index}`}
          data-testid={`apply-programme-${id}`}
          onClick={
            programmeType.toLowerCase() === "offcycle" &&
            !state.Application.userApplicationsError &&
            !inProgress &&
            appPrgress <= 0 &&
            pDetails.applicationEvent &&
            pDetails.applicationEvent.status.toLowerCase() === running
              ? toggleShowApplyModal
              : _appyClick
          }
        >
          {state.Application.loadingUserApplications || isLoading ? (
            <Loader width={15} height={15} color="#fff" type="Oval" />
          ) : state.Application.userApplicationsError ? (
            <span>An Error Occurred</span>
          ) : (
            <span>
              {appPrgress > 0 && `${appPrgress}%`} {buttonText}
            </span>
          )}
        </button>
      </div>
      <CategoryModal
        levelModal={showLevelModal}
        score={score}
        onContinueClick={() => setShowLevelModal(false)}
        setLevelModal={() => setShowLevelModal(false)}
        lang={lang}
      />
      <UNDPCategoryModal
        levelModal={showUndpLevelModal}
        score={score}
        onContinueClick={() => setShowUndpLevelModal(false)}
        setLevelModal={() => setShowUndpLevelModal(false)}
        lang={lang}
      />
      <HowToApply
        show={showApplyModal}
        toggle={toggleShowApplyModal}
        apply={_appyClick}
        programmeEventId={programmeEventId}
        lang={lang}
      />
    </div>
  );
};
