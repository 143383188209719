import React, { useState } from "react";
import Swal from "sweetalert2";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import AlumniList from "src/modules/Alumni/Chat/Components/AlumniList/AlumniList";
import { fromUserFromGroup } from "src/requests/AlumniChatRequest";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const userId = get_user_info_from_token()?.user?.id;
const ViewGroupMembers = ({
  isMembersModalOpen,
  toggleMembersModal,
  className,
  hubUsers = [],
  isloadingHubUsers = false,
  switchDefaultActiveChat,
  groupTitle,
  handleSearch,
  personalChat,
  selectedGroupId,
  groupChat,
  refreshPage,
  isGroupAdmin,
  readonly,
}) => {
  const [userIdCollections, setUserIdCollection] = useState([]);
  const removeUsersFromGroup = () => {
    Swal.fire({
      title: "Remove user from group?",

      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#da291c",
      cancelButtonColor: "#eddcd2",
      confirmButtonText: "Remove",
      // iconHtml: "&#128683;",
      customClass: {
        icon: "swal-icon",
        confirmButton: "swal-confirm",
        cancelButton: "swal-cancel",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const param = {
          groupId: selectedGroupId,
          userIds: userIdCollections,
        };
        fromUserFromGroup(param).then((res) => {
          Swal.fire("Removed", "Users has been removed from group", "success");
          toggleMembersModal();
          refreshPage();
        });
      }
    });
  };

  const addToList = (id) => {
    if (!userIdCollections.includes(id) && id !== userId) {
      const newIds = [...userIdCollections, id];
      setUserIdCollection(newIds);
    }
  };

  const removeFromList = (id) => {
    if (userIdCollections.includes(id)) {
      const newIds = userIdCollections.filter((idx) => idx !== id);
      setUserIdCollection(newIds);
    }
  };

  return (
    <div>
      <Modal
        isOpen={isMembersModalOpen}
        toggle={toggleMembersModal}
        className="modal-container alumni-member"
        centered={true}
      >
        <ModalHeader toggle={toggleMembersModal}>
          {isGroupAdmin() && (
            <Button
              color="secondary"
              style={{ fontSize: 13, float: "right" }}
              onClick={removeUsersFromGroup}
            >
              Remove Member
            </Button>
          )}
        </ModalHeader>
        <ModalBody>
          <AlumniList
            hubUsers={hubUsers}
            isloadingHubUsers={isloadingHubUsers}
            switchDefaultActiveChat={switchDefaultActiveChat}
            groupChat={groupChat}
            personalChat={personalChat}
            groupTitle={groupTitle}
            handleSearch={handleSearch}
            addToList={addToList}
            removeFromList={removeFromList}
            readonly={readonly}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ViewGroupMembers;
