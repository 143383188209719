import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FaRegCommentAlt, FaCaretDown } from "react-icons/fa";
import "./Goal.scss";
import { _getStatusColor } from "src/modules/mentorship/components/GoalDetails/GoalDetails";
import { IoIosMore } from "react-icons/io";
import Avatar from "src/components/Avatar/Avatar";
import {
  GoalUpdateCard,
  UpdateGoalStatus,
  GoalMoreItem,
} from "./goalUpdateCard";
import { Context } from "src/store";
// import { ProfileUrl } from "src/constants";
import EditGoalModal from "src/modules/mentorship/components/EditGoalModal/EditGoalModal";
import {
  _truncateText,
  retrieveUserProfile,
} from "src/modules/mentorship/components/helper";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const Goal = ({
  title,
  status,
  id,
  discussionCount,
  createdBy,
  isActive,
  hasMenteeCompleted,
  goal,
}) => {
  // const user = JSON.parse(localStorage.getItem("data")).user;
  const user = get_user_info_from_token().user;
  const { state } = useContext(Context);
  const [showGoalStatusCard, setGoalStatusCard] = useState(false);
  const [showGoalMoreCard, setShowGoalMoreCard] = useState(false);
  const [loadingStatusUpdate, setLoadingStatusUpdate] = useState(false);
  const [deleteGoalLoading, setDeleteGoalLoading] = useState(false);
  const [userProfile, setUserProfile] = useState("");
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  const isMentor = () => {
    // const copyUser = user;
    // return copyUser.hasOwnProperty("tef-mentor");

    return Object.prototype.hasOwnProperty.call(user, "tef-mentor");
  };

  const history = useHistory();
  const viewGoal = () => {
    const path = `/mentorship/goals/${id}`;
    history.push(path);
  };

  useEffect(() => {
    retrieveUserProfile(createdBy).then((res) => {
      setUserProfile(res.data.data);
    });
  }, []);

  useEffect(() => {
    setLoadingStatusUpdate(state.Goals.requestingChangeGoalStatus);
  }, [state.Goals.requestingChangeGoalStatus]);

  useEffect(() => {
    setDeleteGoalLoading(state.Goals.requestingGoalDelete);
  }, [state.Goals.requestingGoalDelete]);

  return (
    <div className="g-card-c">
      <div className="g-card-c__head">
        <div
          className={`g-card-c__head--bar stat ${_getStatusColor(
            isActive ? status : "Locked"
          )}`}
          onClick={() => {
            setGoalStatusCard(!showGoalStatusCard);
            setShowGoalMoreCard(false);
          }}
        >
          {isActive ? status : "Locked"}

          {isMentor() && isActive && (
            <span>
              <FaCaretDown color="#fff" />
            </span>
          )}
        </div>
        {/* <span className="g-card-c__head--bar prog">TEF PROGRAM</span> */}
        {createdBy === user.id && (
          <span
            style={{
              marginLeft: "5px",
              alignSelf: "center",
              cursor: "pointer",
            }}
          >
            <IoIosMore
              // color="#ccd3da"
              onClick={() => {
                setGoalStatusCard(false);
                setShowGoalMoreCard(!showGoalMoreCard);
              }}
            />
          </span>
        )}

        {isMentor() && isActive && (
          <GoalUpdateCard show={showGoalStatusCard}>
            <UpdateGoalStatus
              setShowCard={() => setGoalStatusCard(false)}
              goalId={id}
              loading={loadingStatusUpdate}
            />
          </GoalUpdateCard>
        )}

        <GoalUpdateCard show={showGoalMoreCard}>
          {/* <UpdateGoalStatus /> */}
          <GoalMoreItem
            setShowCard={setShowGoalMoreCard}
            goalId={id}
            toggle={toggleModal}
            loading={deleteGoalLoading}
          />
        </GoalUpdateCard>
      </div>
      <div className="g-card-c__title" onClick={viewGoal}>
        <h1>{_truncateText(title, 55)}</h1>
      </div>
      <div className="g-card-c__like-comment" onClick={viewGoal}>
        <div style={{ alignSelf: "center" }}>
          <span className="g-like-comment">
            <FaRegCommentAlt color="#92929d" /> <span>{discussionCount}</span>
          </span>
        </div>

        {hasMenteeCompleted && (
          <span className="g-like-comment" style={{ alignSelf: "center" }}>
            <span className="mentee-completed-div">Completed by Mentee</span>
            {hasMenteeCompleted}
          </span>
        )}
        <span>
          {userProfile && userProfile.tefMentor ? (
            // {isMentor() ? (
            <Avatar dimension="31px" src={userProfile.imageUrl} showBadge />
          ) : (
            <Avatar dimension="31px" src={userProfile.imageUrl} />
          )}
        </span>
      </div>
      {goal && <EditGoalModal modal={modal} toggle={toggleModal} goal={goal} />}
    </div>
  );
};

Goal.propTypes = {};

export default Goal;
