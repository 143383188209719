import React from "react";
import classes from "./faq.module.scss";
import Loader from "react-loader-spinner";
import FAQ from "src/components/FAQ/FAQ";

const TrainingFAQ = ({ list, loading }) => {
  return (
    <div className={classes.main}>
      {loading && (
        <Loader
          type="ThreeDots"
          height={35}
          width={35}
          color="var(--tef-red)"
          style={{ margin: "0 auto" }}
        />
      )}
      <FAQ faqs={list} className="m-2" />
    </div>
  );
};

export default TrainingFAQ;
