import React, { useEffect, useState } from "react";
import { Row, Col, Button, CardGroup } from "reactstrap";
import { RiArrowRightFill, RiHeart3Line, RiHeart3Fill } from "react-icons/ri";
import Layout from "src/components/Layout/Layout";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import SecondaryButton from "src/modules/Alumni/Components/SecondaryButton/index";
import Card from "src/modules/Alumni/Resources/components/Card/index";
import CommentBody from "src/modules/Alumni/Resources/components/CommentBody/index";
import ResourceModel from "src/modules/Alumni/models/ResourceModel";
import CustomLoader from "src/modules/Alumni/Components/CustomLoader/index";
import {
  getResourceById,
  getResources,
  toggleResourceLike,
} from "src/requests/AlumniRequest";

import "./VideoView.scss";

const VideoView = () => {
  const history = useHistory();
  // const { dispatch } = useContext(Context);
  const { resourceId } = useParams();
  const [pageUpdated, setPageUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [resources, setResources] = useState([]);
  const [isRecommendedLoading, setIsRecommendedLoading] = useState(false);
  const [resource, setResource] = useState(new ResourceModel());

  useEffect(() => {
    _getLearningResourceById();
  }, []);

  const _getRecommendedResources = () => {
    setIsRecommendedLoading(true);
    getResources("FilteredBy=videos&MaxItem=3")
      .then((res) => {
        const { data } = res.data.data;
        const __resources = [];

        data.forEach((res) => {
          if (res.id !== resourceId) {
            const _resource = new ResourceModel();
            _resource.updateProperties(res);
            __resources.push(_resource);
          }
        });

        setResources(__resources);

        // setPageCount(pager.pageCount);
        setIsRecommendedLoading(false);
      })
      .catch(() => {
        setIsRecommendedLoading(false);
        toast("Something went wrong, try again", { type: toast.TYPE.ERROR });
      });
  };

  const _getLearningResourceById = () => {
    setIsLoading(true);
    getResourceById(resourceId)
      .then((res) => {
        const { data } = res.data;
        const newResource = new ResourceModel();
        newResource.updateProperties(data);

        setResource(newResource);
        _getRecommendedResources();
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        history.push(`/alumni/resources`);
        toast("Something went wrong, try again");
      });
  };

  const onToggleLike = async () => {
    // toggle like immediately it gets clicked
    resource.setIsLiked(!resource.isLiked);

    /* Because we are just updating a model property,
     * we need a way to let React know that content has changed
     */
    setPageUpdated(!pageUpdated);
    try {
      await toggleResourceLike(resource.id);
      // const updated
    } catch (e) {
      // reset liked, because this request failed to get completed
      resource.setIsLiked(!resource.isLiked);
      setPageUpdated(!pageUpdated);
      toast("Something went wrong, try again");
    }
  };

  return (
    <Layout SideContent={<SidebarRight />}>
      {isLoading && <CustomLoader fullScreen={true} />}
      <div className="video-view-body">
        <div className="navigation">
          <span className="navigation__content">
            <b
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/alumni/resources?tab=Videos")}
            >
              Alumni Resource Library
            </b>
            <RiArrowRightFill style={{ marginRight: 5 }} />
            {resource.title}
          </span>
        </div>

        <div className="video-player">
          <video
            id="tef-masterclass-video"
            controls
            preload="auto"
            poster={
              "https://i.pinimg.com/originals/19/1c/70/191c7063838dff3add0caffcff6284ce.jpg"
            }
            autoPlay
            src={resource.fileUrl}
          >
            <source src={""} type="video/mp4" />
            <p>Your browser does not support the video type</p>
          </video>
        </div>
        <Row style={{ marginTop: 15 }}>
          <Col md={8}>
            <div className="page_description">
              <h3>{resource.title}</h3>
              <div className="d-flex mb-5">
                <h5>VIEWS {resource.viewCount}</h5>
                <h5>{resource.createdOn.toLocaleDateString()}</h5>
              </div>
              <p>{resource.description}</p>
            </div>
          </Col>
          <Col md={4} className="text-right page_description__like-btn">
            <Button
              onClick={onToggleLike}
              color={resource.isLiked ? "new-faded-white" : "tef-grey"}
            >
              {!resource.isLiked ? (
                <RiHeart3Line color="fff" style={{ fontSize: 17 }} />
              ) : (
                <RiHeart3Fill color="da291c" style={{ fontSize: 17 }} />
              )}
            </Button>
            <b>{resource.likeCount}</b>
          </Col>
        </Row>

        {/* Content of the Video Page */}
        <div className="video-view-content">
          <Row>
            <Col md={8}>
              <CommentBody />
            </Col>
            <Col
              md={4}
              className="video-view-content__recommended_container pl-0 pr-0"
            >
              {resources.length > 0 && (
                <div>
                  <h6>Recommended</h6>
                  {isRecommendedLoading && <CustomLoader />}
                  <CardGroup>
                    {resources.map((data) => (
                      <Card
                        key={data.id}
                        img={
                          data.link ||
                          "https://www.pcclean.io/wp-content/uploads/2020/4/MzUfam.jpg"
                        }
                        BottomLeftEle={
                          <SecondaryButton
                            buttonText="Watch Video"
                            style={{
                              borderRadius: 10,
                              height: 30,
                              fontSize: 7,
                            }}
                            buttonAction={() =>
                              history.push(
                                `/alumni/resources/video-view/${data.id}`
                              )
                            }
                          />
                        }
                        // BottomRightEle={
                        //   <div>
                        //     <Badge
                        //       color="h1-black-new"
                        //       pill
                        //       style={{ opacity: 0.9, padding: 10 }}
                        //     >
                        //       09:32
                        //     </Badge>
                        //   </div>
                        // }
                        content={
                          <span className="normal_card_container">
                            {data.title}
                          </span>
                        }
                      />
                    ))}
                  </CardGroup>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default ifOnboardedThenShow(VideoView);
