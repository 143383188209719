/* eslint-disable node/handle-callback-err */
/* eslint-disable no-unused-vars */
import React from "react";
import classes from "./LMSCourseCard.module.scss";
// import Empty from "src/modules/mentorship/Calendar/utils/Empty";
import { useLocation } from "react-router-dom";
import { userStartsCourse } from "src/requests/TrainingRequest";
import LMSCourseCard from "./LMSCourseCard";
import { Col, Row } from "reactstrap";
import Empty from "src/assets/img/mentorship-empty.png";

const LMSCourseList = ({ courses, userCourses }) => {
  // const mapRegisteredCourseWithAdminCreatedCourses = (
  //   adminCourse,
  //   userCourses
  // ) => {
  //   return userCourses.find((course) => course.courseId === adminCourse.id);
  // };

  return (
    <div className={classes.LMSList}>
      {courses && courses.length > 0 ? (
        courses.map((course, idx) => {
          return (
            <LMSCourseCard
              key={idx}
              title={course.courseOverview?.title}
              imageUrl={course.imageUrl}
              programmeEventId={course.programmeEventId}
              overview={course.courseOverview?.overview}
              programmeId={course.programmeId}
              lang={course.courseOverview?.lang}
              courseId={course.id}
              segment={course.segment}
              totalModules={course.totalModules}
              aveRating={course.avgRating}
              modulesCompleted={course.modulesCompleted}
              index={idx}
            />
          );
        })
      ) : (
        <Row style={{ width: "100%" }}>
          {/* <Empty message="No courses available at the moment" /> */}
          <>
            <Col style={{ textAlign: "center", width: "100%" }}>
              <br />
              <br />

              <img src={Empty} alt={"error"} />
              <h3 className="goals-heading">
                No training courses at the moment
              </h3>
              <br />
              <br />
            </Col>
          </>
        </Row>
      )}
    </div>
  );
};

export default LMSCourseList;
