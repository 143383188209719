import React, { useState } from "react";
import classes from "./MasterclassPageNav.module.css";

const MasterclassPageNav = ({ selectDetail, selectTranscript, selectFile }) => {
  const [detailSelected, setDetailSelected] = useState(true);
  const [transcriptSelected, setTranscriptSelected] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);

  const handleDetailsSelected = () => {
    setDetailSelected(true);
    setTranscriptSelected(false);
    setFileSelected(false);
    selectDetail();
  };
  const handleTranscriptSelected = () => {
    setDetailSelected(false);
    setTranscriptSelected(true);
    setFileSelected(false);
    selectTranscript();
  };
  const handleFileSelected = () => {
    setDetailSelected(false);
    setTranscriptSelected(false);
    setFileSelected(true);
    selectFile();
  };
  return (
    <div className={classes.MasterclassPageNav}>
      <div className={classes.Details} onClick={handleDetailsSelected}>
        <div className={classes.TabHeader}>Details</div>
        <span
          className={`${classes.TabBody} ${
            detailSelected ? `${classes.Selected}` : ""
          }`}
        >
          About the talk and Author
        </span>
      </div>
      <div className={classes.Transcript} onClick={handleTranscriptSelected}>
        <div className={classes.TabHeader}>Transcript</div>
        <span
          className={`${classes.TabBody} ${
            transcriptSelected ? `${classes.Selected}` : ""
          }`}
        >
          Language
        </span>
      </div>
      <div className={classes.Files} onClick={handleFileSelected}>
        <div className={classes.TabHeader}>Files & Resources</div>
        <span
          className={`${classes.TabBody} ${
            fileSelected ? `${classes.Selected}` : ""
          }`}
        >
          Download Files
        </span>
      </div>
    </div>
  );
};

export default MasterclassPageNav;
