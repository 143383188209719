import React from "react";
import { Col } from "reactstrap";
import classes from "./Bookmark.module.css";
import { useHistory } from "react-router-dom";

const Bookmark = ({ list }) => {
  const history = useHistory();
  const handleNavigation = (id, type) => {
    type === "TEF-Article" && history.push(`/discover/article/${id}`);
    type === "TEF-News" && history.push(`/discover/news/${id}`);
    type === "TEF-Story" && history.push(`/discover/story/${id}`);
    type === "TEF Learning Resources" &&
      history.push(`/discover/learning-resource/${id}`);
  };
  return (
    <>
      {list &&
        list.map((item) => (
          <Col
            md={4}
            className={classes.Bookmark}
            key={item.contentId}
            onClick={() => handleNavigation(item.contentId, item.contentType)}
          >
            <img
              src={
                item.imageUrl
                  ? item.imageUrl
                  : "https://cdn.pixabay.com/photo/2017/04/19/13/16/computer-2242264_1280.jpg"
              }
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
                objectPosition: "50% 50%",
              }}
            />

            <div className={classes.Title}>{item.title}</div>
            <div className={classes.Type}>{item.contentType}</div>
          </Col>
        ))}
    </>
  );
};

export default Bookmark;
