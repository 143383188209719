import React from "react";
import classes from "./HubBanner.module.scss";
import { FaComments } from "react-icons/fa";
import { useHistory } from "react-router-dom";
// import DefaultImage from "src/assets/img/bg/background_640-1.webp";

const HubBanner = ({ hubBanner }) => {
  const history = useHistory();
  const style = {
    backgroundImage: `linear-gradient(
      to right bottom,
      rgba(0,0,0,0.3),
      rgba(0,0,0,0.4)
  ), url(${hubBanner.bannerUrl})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "300px",
  };
  return (
    <figure className={classes.alumniHub} style={style}>
      <div className={classes.alumniHub__title}>
        <p>{hubBanner.name || hubBanner.state}</p>
      </div>
      <div className="alumniHub__meta-box">
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "13px",
            marginRight: "20px",
          }}
          className={classes["alumniHub-box-icon-box__meta"]}
        >
          <span className="alumniHub__icon">
            <ImImage />
          </span>
          <span className={classes["alumniHub__icon-text"]}>
            <span>Photo Gallery</span>
          </span>
        </div> */}
        <div
          onClick={() => history.push("/alumni/chat")}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "13px",
            cursor: "pointer",
          }}
          className={classes["alumniHub-box-icon-box__meta"]}
        >
          <span className={classes.alumniHub__icon}>
            <FaComments />
          </span>
          <span className={classes["alumniHub__icon-text"]}>
            <span>Group Chat</span>
          </span>
        </div>
      </div>
    </figure>
  );
};

export default HubBanner;
