import React from "react";
import { Col, Row, Button } from "reactstrap";
import "./ScreenThree.scss";
import PhoneImg from "src/assets/img/tour/phone-copy.png";
import PhoneImgFr from "src/assets/img/tour/phoneImg-fr.png";
import { FaArrowRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const ScreenThree = (props) => {
  const { t } = useTranslation();
  const { languageId } = props.info;

  return (
    <Col md={{ size: 6 }}>
      <Row
        style={{
          backgroundColor: "white",
          borderRadius: 5,
          textAlign: "center",
          padding: "50px 0px 0px 0px",
        }}
      >
        <Col md={12}>
          <div className="tour-menu-title" style={{ color: "#da291c" }}>
            <span>
              {t("Super")} <span style={{ color: "#26292d" }}>{t("Easy")}</span>
            </span>
          </div>
          <div className="tour-menu-title">
            <span style={{ color: "#26292d" }}>{t("Application Process")}</span>
          </div>
        </Col>
        <Col md={12}>
          <img
            className="tourImg"
            src={languageId === "fr" ? PhoneImgFr : PhoneImg}
            alt="Phone"
          />
        </Col>
        <Col>
          <div className="tourBottom">
            <Row>
              <Col md={12} style={{ marginTop: "-1rem" }}>
                {t("tourCompleteApplication")}
              </Col>
            </Row>
            <Row className=" bottomFooter" style={{ textAlign: "center" }}>
              <Col
                onClick={props.onPreviousClick}
                className="back backLink"
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
                md={3}
              >
                {t("Go back")}
              </Col>
              <Col md={6}>
                <Button
                  color=""
                  style={{ backgroundColor: "white", color: "#26292" }}
                >
                  <div onClick={props.onNextClick}>
                    {t("Get Started")}
                    <span style={{ paddingLeft: "5px", opacity: "0.5" }}>
                      <FaArrowRight />
                    </span>
                  </div>
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default ScreenThree;
