import React, { useState, useContext, useEffect } from "react";
import "./Goals.scss";
import { TabContent, Tabs } from "src/components/CustomTab/CustomTab";
import { FaCaretDown } from "react-icons/fa";
import { Row, Col } from "reactstrap";
import { Context } from "src/store";
import Goal from "src/modules/mentorship/components/Goal/Goal";
import TefScore from "src/modules/mentorship/components/TEFScore/tefScore";
import { getAllGoals } from "src/requests/GoalRequests";
import LoaderSpinner from "react-loader-spinner";
import Empty from "src/assets/img/mentorship-empty.png";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { getTrainingEventDetails } from "src/requests/MentorRequests";
const Loader = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingTop: "50px",
      }}
    >
      <LoaderSpinner
        type="Puff"
        color="var(--tef-red)"
        height={75}
        width={75}
      />
    </div>
  );
};
const NoGoals = () => {
  return (
    <Col style={{ textAlign: "center" }} md={{ size: 6, offset: 3 }}>
      <img src={Empty} />
      <h3 className="goals-heading">No Goal To Show</h3>
      {/* <Button>Create a Goal</Button> */}
      <br />
      <br />
    </Col>
  );
};

const UserInfo = ({ otherDetails }) => {
  return (
    <>
      {!otherDetails ? null : ( // <Skeleton width={100} />
        <span
        // onClick={onSelect}
        >
          {otherDetails.firstName && otherDetails.firstName}{" "}
          {otherDetails.lastName && otherDetails.lastName}
        </span>
      )}
      <span style={{ float: "right" }}>
        {/* <Avatar dimension="24px" src={profileImage} onClick={onSelect} /> */}
      </span>
      <div style={{ clear: "both" }}></div>
    </>
  );
};

const MentorGoalsView = () => {
  const user = get_user_info_from_token().user;
  const [activeTab, setActiveTab] = useState("My Goals");
  const { state, dispatch } = useContext(Context);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [mentorsGoals, setMentorsGoals] = useState([]);
  const [menteesGoals, setMenteesGoals] = useState([]);
  const [filterMenteeGoals, setFilterMenteeGoals] = useState([]);
  const [selectedmentee, setSelectedMentee] = useState("");
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    id: "",
  });
  const [trainingEventId, setTrainingEventId] = useState(null);

  useEffect(() => {
    if (state.Goals.goals && Array.isArray(state.Goals.goals)) {
      mentorsCreatedGoals(state.Goals.goals);
    }
  }, [state.Goals.goals]);

  useEffect(() => {
    const trainingEventDetail = getTrainingEventDetails(
      user["tef-mentor"][0],
      dispatch
    );
    setTrainingEventId(trainingEventDetail && trainingEventDetail.id);
    document.addEventListener("mousedown", _handleHideElement);

    return () => {
      document.removeEventListener("mousedown", _handleHideElement);
    };
  }, []);

  useEffect(() => {
    getAllGoals(dispatch);
  }, []);

  useEffect(() => {
    getAllGoals(dispatch);
  }, [state.Goals.goal]);

  useEffect(() => {
    if (state.Mentors.connected && state.Mentors.connected.length > 0) {
      if (!selectedmentee) {
        setSelectedMentee(state.Mentors.connected[0].namedId);
        const filtered = menteesGoals.filter((g) => {
          return g.creator === state.Mentors.connected[0].namedId;
        });
        setFilterMenteeGoals(filtered);
      }
    }
  }, [state.Mentors.connected, selectedmentee]);

  useEffect(() => {
    if (selectedmentee) {
      const filtered = filterGoal();
      setFilterMenteeGoals(filtered);
    }
  }, [selectedmentee, state.Goals.goals]);

  const _handleHideElement = () => {};
  const filterGoal = () => {
    return menteesGoals.filter((g) => {
      return g.creator === selectedmentee;
    });
  };

  const _onSelectMentee = (id, userInfo) => {
    setSelectedMentee(id);
    setUserInfo(userInfo);
  };

  const mentorsCreatedGoals = (goals) => {
    const mGoals = [];
    const menteesG = [];

    goals.forEach((g) => {
      if (g.creator === user.id) {
        mGoals.push(g);
      } else {
        menteesG.push(g);
      }
    });

    setMentorsGoals(mGoals);
    setMenteesGoals(menteesG);
  };

  useEffect(() => {
    if (
      state.Mentors &&
      state.Mentors.connected &&
      state.Mentors.connected.length > 0
    ) {
      _onSelectMentee(
        state.Mentors.connected[0].namedId,
        state.Mentors.connected[0].otherDetails
      );
    }
  }, [state.Mentors]);

  return (
    <div className="g-container-main">
      <div className="goals-tab-c">
        <span
          onClick={() => setActiveTab("My Goals")}
          className={`goals-tab-ind ${
            activeTab === "My Goals" ? "goals-tab-active-item" : ""
          }`}
        >
          Mentor-Initiated Goals
        </span>
        <span
          onClick={() => setActiveTab("My Mentee's Goals")}
          className={`goals-tab-ind ${
            activeTab === "My Mentee's Goals" ? "goals-tab-active-item" : ""
          }`}
        >
          Mentee-Initiated Goals
        </span>
      </div>
      <div style={{ width: "100%" }}>
        <Tabs activeTab={activeTab}>
          <TabContent title="My Goals">
            {state.Goals.goals ? (
              <div className="row">
                {mentorsGoals.length < 1 ? (
                  <NoGoals />
                ) : (
                  mentorsGoals.map((goal, i) => (
                    <div className="col-md-6" key={i}>
                      <Goal
                        title={goal.title}
                        likeCount={goal.likeCount}
                        discussionCount={goal.discussionCount}
                        status={goal.status}
                        id={goal.id}
                        createdBy={goal.creator}
                        isActive={goal.isActive}
                        hasMenteeCompleted={goal.hasMenteeCompleted}
                        goal={goal}
                      />
                    </div>
                  ))
                )}
              </div>
            ) : (
              Loader()
            )}
          </TabContent>
          <TabContent title="My Mentee's Goals">
            <div>
              <Row
                style={{ backgroundColor: "white" }}
                className="select-mentee-c"
              >
                <h3 className="my-board-heading" style={{ marginTop: "12px" }}>
                  Select Mentee:
                </h3>
                <Col md={6} className="top-row-icon">
                  <div className="select-container">
                    <span
                      className="top-row-text"
                      onClick={() => {
                        setOpenDropdown(true);
                      }}
                      style={{
                        cursor: "Pointer",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <span>
                        {selectedmentee ? (
                          <UserInfo
                            id={selectedmentee}
                            onSelect={() => {}}
                            otherDetails={userInfo}
                          />
                        ) : (
                          "Select mentee"
                        )}
                      </span>{" "}
                      <span>
                        <FaCaretDown />
                      </span>
                    </span>
                    {openDropdown && (
                      <div
                        className="goal-list-container selected"
                        id="goals-list"
                      >
                        <div>
                          <span
                            onClick={() => setOpenDropdown(false)}
                            style={{ float: "right", cursor: "pointer" }}
                          >
                            X
                          </span>
                          <div style={{ clear: "both" }}></div>
                        </div>
                        {state.Mentors.connected &&
                          state.Mentors.connected.map((mentee, i) => {
                            return (
                              <div
                                key={i}
                                className="goal-list-item mentee-list-item"
                                id="goal-list-item"
                                onClick={() => {
                                  _onSelectMentee(
                                    mentee.namedId,
                                    mentee.otherDetails
                                  );
                                  setOpenDropdown(false);
                                }}
                              >
                                <UserInfo
                                  id={mentee.namedId}
                                  // onSelect={() => {
                                  //   _onSelectMentee(mentee.namedId);
                                  //   setOpenDropdown(false);
                                  // }}
                                  otherDetails={mentee.otherDetails}
                                />
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>

              {trainingEventId && (
                <div className="mentee-info">
                  <div
                    style={{
                      height: "200px",
                      width: "200px",
                      margin: "0 auto",
                    }}
                  >
                    <h1>TEF Training Completion Score</h1>
                    <TefScore
                      userId={selectedmentee}
                      progammeEventId={trainingEventId}
                    />
                  </div>
                </div>
              )}

              {state.Goals.goals && (
                <div className="row">
                  {filterMenteeGoals.length < 1 ? (
                    <NoGoals />
                  ) : (
                    filterMenteeGoals.map((goal, i) => {
                      return (
                        <div className="col-md-6" key={i}>
                          <Goal
                            title={goal.title}
                            likeCount={goal.likeCount}
                            discussionCount={goal.discussionCount}
                            status={goal.status}
                            id={goal.id}
                            createdBy={goal.creator}
                            hasMenteeCompleted={goal.hasMenteeCompleted}
                            isActive={goal.isActive}
                            goal={goal}
                          />
                        </div>
                      );
                    })
                  )}
                </div>
              )}
            </div>
          </TabContent>
        </Tabs>
      </div>
    </div>
  );
};

export default MentorGoalsView;
