import React, { useState, useContext } from "react";
import "./ProfileImage.scss";
import "../common.scss";
import { UploadFile, isImageFile } from "src/requests/fileUploadRequest";
import { Btn } from "src/modules/profile/ProfileEdit/common";
import { Context } from "src/store";
import { updateUserProfile } from "src/requests/ProfileRequest";

export const ProfileImageUpdate = (props) => {
  const { imageUrl, onCancel } = props;
  const [previewImage, setPreviewImage] = useState(null);
  const [fileError, setFileError] = useState("");
  const { dispatch } = useContext(Context);
  const [uploading, setUploading] = useState(false);
  const _handleImageChange = (e) => {
    const files = e.target.files;
    // const file = files[0];

    if (files[0].name.endsWith("gif")) {
      setFileError("Invalid file format");
      return;
    }
    if (isImageFile(files, false).hasError) {
      setFileError("Invalid file format");
      return;
    }
    setFileError("");
    setPreviewImage(files);
  };

  const _setImage = () => {
    if (imageUrl && !previewImage) {
      return imageUrl;
    } else if ((previewImage && imageUrl) || (previewImage && !imageUrl)) {
      return URL.createObjectURL(previewImage[0]);
    } else {
      return "https://via.placeholder.com/156";
    }
  };

  const onSubmit = () => {
    if (!previewImage) {
      return;
    }
    setUploading(true);
    UploadFile(dispatch, false, previewImage)
      .then((res) => {
        const { status, files } = res;
        if ((status && status === 200) || (status && status === 201)) {
          const payload = { imageUrl: files[0] };
          updateUserProfile(dispatch, payload).then(() => {
            setUploading(false);
            cancel();
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setUploading(false);
      });
  };

  const cancel = () => {
    setPreviewImage(null);
    onCancel();
  };

  return (
    <div className="">
      <div className="edit-profile-header">
        <h1>Edit Photo</h1>
      </div>
      <hr />

      <div className="edit-photo-body">
        <div className="img-container">
          <img src={_setImage()} className="img-fluid" />
        </div>
        <input
          type="file"
          className="photo-file"
          id="photo-file"
          onChange={_handleImageChange}
          accept="image/x-png,image/jpeg"
        />
      </div>
      <div style={{ fontSize: "13px", color: "var(--helper-text-color)" }}>
        {" "}
        valid format:jpg|jpeg|png
      </div>
      {fileError && (
        <div style={{ color: "red", fontSize: "12px" }}>{fileError}</div>
      )}
      <hr />
      <div className="btn-box">
        <Btn className="btn-cancel" value="Cancel" onClick={cancel} />
        <div style={{ flex: 1 }}></div>
        <Btn
          className="btn-choose-photo"
          value="Choose photo"
          onClick={() => document.getElementById("photo-file").click()}
        />
        <Btn
          className="btn-update"
          value="Save Photo"
          loading={uploading}
          onClick={() => onSubmit()}
        />
        {/* </div> */}
      </div>
    </div>
  );
};
