import React from "react";
import classes from "./LandingPage.module.scss";
// import img1 from "src/assets/img/landingPage/landingPagem5.webp";

const Event = () => {
  return (
    <div className={classes.eventContainer}>
      <div className={classes.eventContainer__left}>
        {/* <img src={img1} alt="Alt" className={`img-fluid`} /> */}
        <div className={classes.eventContainer__left_info_container}>
          <h4>Save the date</h4>
          {/* <span>Orange Academy</span> */}
        </div>
      </div>
      <div className={classes.eventContainer__right}>
        <h4>Events</h4>

        <h3>Stay informed on world class events:</h3>
        <span>
          conferences, webinars, meet-ups, expos, conferences, festivals and
          forums, brought to you by the Tony Elumelu Foundation
        </span>
      </div>
    </div>
  );
};

export default Event;
