import React from "react";
import "./Tips.scss";

const TipsContainer = (props) => {
  return <div className="opp-tips-Container">{props.children}</div>;
};

const TipsItem = ({ title, description }) => {
  return (
    <div className="opp-tips-item">
      {title}
      <p>{description}</p>
    </div>
  );
};

export { TipsContainer, TipsItem };
