import React, { useEffect } from "react";
import { CardGroup } from "reactstrap";
import NewsCard from "src/modules/Alumni/Resources/components/NewsCard/index";
import CardContentLoader from "src/modules/Alumni/Components/CardContentLoader/index";
import "./index.scss";

const OurPick = ({
  onLoadData,
  resources,
  isLoading,
  ErrorEmpPage,
  onClick,
  singlePostPerLine,
}) => {
  useEffect(() => {
    onLoadData(
      {
        SearchBy: "Our Picks",
        Category: "ourPicks",
      },
      "SearchBy"
    );
  }, []);

  return (
    <div className="mt-1">
      <div className="black_header">
        <h4 className="text-white pt-3">OUR PICKS</h4>
      </div>
      {isLoading && resources.length < 1 ? (
        <div className="mb-2 mt-0 pt-0 pb-0">
          <CardContentLoader />
        </div>
      ) : resources.length > 0 ? (
        <CardGroup>
          {resources.map((res, i) => (
            <NewsCard
              key={i}
              onClick={() => onClick(res)}
              md={singlePostPerLine ? 12 : 6}
              img={res.fileUrl}
              title={res.title}
              author={res.author || ""}
              date={res.createdOn.toLocaleDateString()}
              content={""}
            />
          ))}
        </CardGroup>
      ) : (
        <ErrorEmpPage />
      )}
    </div>
  );
};

export default OurPick;
