import jwt_decode from "src/utils/jwt-decode";

export const isLoggedIn = () => {
  const data = JSON.parse(localStorage.getItem("data"));
  if (data && data.token !== null) {
    const { exp } = jwt_decode(data.token);
    const currentTime = Date.now().valueOf() / 1000;

    if (currentTime > exp) {
      const lastPageVisited = window.location.pathname + window.location.search;
      localStorage.clear();
      localStorage.setItem("lastPageVisited", lastPageVisited);
      return false;
    }
    return true;
  }
  return false;
};
