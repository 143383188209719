import React, { useEffect, useContext } from "react";
import { Card, Button, Row, Col } from "reactstrap";
import { Context } from "src/store";
import { getAllGoals } from "src/requests/GoalRequests";
import Goal from "src/modules/mentorship/components/Goal/Goal";
import Empty from "src/assets/img/mentorship-empty.png";

import "./Goals.scss";
const Goals = ({ toggle }) => {
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    getAllGoals(dispatch);
  }, []);

  useEffect(() => {
    getAllGoals(dispatch);
  }, [state.Goals.goal]);

  const displayGoals = () => {
    return (
      state.Goals.goals &&
      state.Goals.goals.map((goal, i) => (
        <Col md={6} lg={6} sm={6} xl={6} key={i}>
          <Goal
            title={goal.title}
            likeCount={goal.likeCount}
            discussionCount={goal.discussionCount}
            status={goal.status}
            id={goal.id}
            createdBy={goal.creator}
            hasMenteeCompleted={goal.hasMenteeCompleted}
            goal={goal}
            isActive={goal.isActive}
          />
        </Col>
      ))
    );
  };
  return (
    <Row>
      <Card className="goals-card">
        <Col>
          <h3 className="goals-heading">All Goals</h3>
        </Col>
        <div className="goals-container">
          <Col md={12}>
            {state.Goals.goals && state.Goals.goals.length < 1 && (
              <Col style={{ textAlign: "center" }} md={{ size: 6, offset: 3 }}>
                <img src={Empty} />
                <h3 className="goals-heading">No Goal To Show</h3>
                <Button onClick={toggle}>Create a Goal</Button>
                <br />
                <br />
              </Col>
            )}
            <Row className="goals-rows">{displayGoals()}</Row>
            <br />
          </Col>
        </div>
      </Card>
    </Row>
  );
};

Goals.propTypes = {};

export default Goals;
