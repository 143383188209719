import React, { useContext, useState, useEffect } from "react";
import classes from "src/Auth/Login/login_v2.module.scss";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import { Link, useHistory, useLocation } from "react-router-dom";
import { Context } from "src/store";
import queryString from "query-string";

import { validateSignin } from "src/utils/Validations/validateSignup";
import { reSendLink } from "src/requests/AuthRequest";
import { changePasswordModal } from "src/utils/helper";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  FormFeedback,
} from "reactstrap";
import StatusModal from "src/components/StatusModal/StatusModal";
import { logSignInEvent } from "src/utils/googleAnalytics";
import { AuthURL } from "src/constants";
import { SignInRedirect } from "src/Auth/OpenId/OpenIdCallbacks";
import SocialSignupButtons from "src/Auth/components/SocialSignupButton/SocialSignupButtons";
import { clearLoginProvider } from "src/utils/loginMethod";
import Loader from "src/components/Loader/Loader";

const EMPTY_ALERT_DETAILS = {
  alertMessage: "",
  alertType: "",
};

/** Login form based on OpenID SSO flow. */
const LoginFormV3 = () => {
  const { search } = useLocation();
  // TODO(jakuba): Fill in the email from URL params in the error case.
  // If the authorization fails, the server sends back the error code and message.
  const { errorCode, errorMessage, returnUrl, email } = queryString.parse(
    search
  );

  const [showText, setShowText] = useState(false);
  const togglePassword = () => setShowText(!showText);
  const [alertDetails, setAlertDetails] = useState(EMPTY_ALERT_DETAILS);
  const [loading, setLoading] = useState(false);
  const [loadingModalBTN, setLoadModalBTN] = useState(false);

  const history = useHistory();
  const { dispatch } = useContext(Context);

  useEffect(() => {
    if (!errorCode) {
      setAlertDetails({
        ...alertDetails,
        alertMessage: "",
        alertType: "",
      });
      return;
    }

    logSignInEvent("FormV3", errorCode);
    switch (errorCode) {
      case "EMAIL_NOT_VERIFIED":
        setAlertDetails({
          ...alertDetails,
          alertMessage: errorMessage,
          alertType: "danger",
          alertLink: true,
          showButton: true,
          buttonClick: (e) => handleResendEmail(e),
          buttonText: "Resend Link",
        });
        break;
      case "PASSWORD_CHANGE_REQUIRED":
        changePasswordModal();
        break;
      default:
        setAlertDetails({
          ...alertDetails,
          alertMessage: errorMessage,
          // "Kindly check and make sure you enter the correct email or password",
          alertType: "danger",
          showButton: true,
          buttonClick: () => dismissAlert(),
          buttonText: "Close",
        });
    }
  }, [errorCode, errorMessage]);

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    clearLoginProvider();
    const newErrors = validateSignin(values);
    if (Object.keys(newErrors).length > 0) {
      e.preventDefault();
    } else {
      setLoading(true);
    }
    setErrors(newErrors);
  };

  const handleChange = (e) => {
    e.persist();
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const dismissAlert = () => {
    setAlertDetails({ alertMessage: "", alertType: "" });
  };

  const handleResendEmail = (e) => {
    e.preventDefault();
    setLoadModalBTN(true);
    const userInfo = {
      email: values?.email || email,
    };
    reSendLink(dispatch, userInfo)
      .then((res) => {
        const { status } = res;
        if (status === 200) {
          setAlertDetails({
            ...alertDetails,
            alertMessage: "verification link sent",
            alertType: "info",
            alertLink: false,
          });
          setTimeout(() => {
            history.push(`/confirmed/${userInfo.email}`);
          }, 1500);
        }
      })
      .finally(() => setLoadModalBTN(false));
  };

  // If user accesses login page directly, go through sign-in redirect to check for single-sign-on.
  if (!returnUrl) {
    return <SignInRedirect />;
  }

  return (
    <>
      <Form
        action={AuthURL.SSO_LOGIN_USER_URL}
        method="POST"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="returnUrl" defaultValue={returnUrl} />

        <FormGroup className={classes.f_row}>
          <Label className={classes.formLabel}>Email</Label>

          <Input
            className={classes.f_input}
            placeholder="Enter email"
            onChange={handleChange}
            value={values.email}
            name="email"
            invalid={!!errors.email}
            data-testid="email"
          />
          {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
        </FormGroup>
        <FormGroup className={`${classes.f_row} ${classes.relativeField}`}>
          <Label className={classes.formLabel}>Password</Label>

          <Input
            className={classes.f_input}
            placeholder="Enter password"
            type={showText ? "text" : "password"}
            onChange={handleChange}
            value={values.password}
            name="password"
            invalid={!!errors.password}
            data-testid="password"
          />
          {errors.password && <FormFeedback>{errors.password}</FormFeedback>}
          <span className={classes.hideIcon}>
            {showText ? (
              <FaEyeSlash
                onClick={togglePassword}
                className={classes.passwordEye}
              />
            ) : (
              <FaEye onClick={togglePassword} className={classes.passwordEye} />
            )}
          </span>
        </FormGroup>

        <StatusModal
          status={alertDetails.alertType || "success"}
          open={!!alertDetails.alertMessage && !!alertDetails.alertType}
          heading={alertDetails.title && alertDetails.title}
          text={alertDetails.alertMessage && alertDetails.alertMessage}
          showButton={alertDetails.showButton}
          buttonText={alertDetails.buttonText}
          onButtonClick={alertDetails.buttonClick}
          toggle={() => setAlertDetails(EMPTY_ALERT_DETAILS)}
          loading={loadingModalBTN}
        />

        <Link
          to={`/reset-password`}
          className={`float-right ${classes.forgot_password_text}`}
          data-testid="forgot"
        >
          Forgot password?
        </Link>
        <Button className={classes.loginBTN} data-testid="signin">
          {loading ? <Loader size={20} /> : <span>Login</span>}
        </Button>
      </Form>
      <h2 className={classes.hr}>
        <span>or</span>
      </h2>
      <SocialSignupButtons returnUrl={returnUrl} />
    </>
  );
};

export default LoginFormV3;
