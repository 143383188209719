export const successMessage = {
  en: {
    congrats: "Congratulations",
    title: "Dear",
    p:
      "You have successfully validated your application. However, you can still make additional changes if need be.",
  },
  fr: {
    congrats: "Félicitations",
    title: "Cher",
    p:
      "Vous avez validé avez succès votre candidature. Cependant, vous pouvez toujours faire des modifications additionnelles si nécessaire.",
  },
  pt: {
    congrats: "Parabéns",
    title: "Caro",
    p:
      "Você validou sua candidatura com sucesso. No entanto, você ainda pode fazer alterações adicionais, se necessário.",
  },
  ar: {
    congrats: "تهانينا",
    title: "عزيزي",
    p:
      "لقد نجحت في التحقق من صحة طلبك.ومع ذلك ، لا يزال بإمكانك إجراء تغييرات إضافية إذا لزم الأمر",
    // p:"لقد نجحت في التحقق من صحة طلبك.ومع ذلك ، لا يزال بإمكانك إجراء تغييرات إضافية إذا لزم الأمر"
  },
};

export const others = {
  en: {
    edit: "Edit Information",
    backHome: "Back to Homepage",
    validate: "Validate",
    discard: "Discard",
    fullAppDetails: "Full application details",
    updateBio: "Update Personal data",
    updateExp: "Update Idea & Experience",
  },
  fr: {
    edit: "Changer les informations",
    backHome: "Retour à la page d'accueil",
    validate: "Valider",
    discard: "Débarrasser",
    fullAppDetails: "Tous les détails de la candidature",
    updateBio: "Mise à jour des donnée personnelle",
    updateExp: "Mise à jour de l'idée & l'expérience",
  },
  pt: {
    edit: "Editar Informação",
    backHome: "Voltar à página inicial",
    validate: "Validar",
    discard: "Descartar",
    fullAppDetails: "Detalhes completos da candidatura",
    updateBio: "Actualizar dados pessoais",
    updateExp: "Actualizar ideia e experiência",
  },
  ar: {
    edit: "تحرير المعلومات",
    backHome: "العودة إلى الصفحة الرئيسية",
    validate: "التحقق",
    discard: "تجاهل",
    fullAppDetails: "كامل تفاصيل التطبيق",
    updateBio: "تحديث البيانات الشخصية",
    updateExp: "تحديث الفكرة والخبرة",
  },
};
