import React from "react";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const MultiSelect = ({
  options,
  isMulti,
  value,
  onChange,
  closeMenuOnSelect,
  onBlur,
}) => {
  return (
    <Select
      closeMenuOnSelect={closeMenuOnSelect}
      components={animatedComponents}
      // defaultValue={options && options[0]}
      isMulti={isMulti}
      options={options}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
MultiSelect.defaultProps = {
  closeMenuOnSelect: false,
  onBlur: () => {},
};

export default MultiSelect;
