import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { RiArrowRightFill } from "react-icons/ri";
import Layout from "src/components/Layout/Layout";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import {
  getSurveyById,
  submitBusinessReport,
  getBusinessReportsStatus,
} from "src/requests/AlumniRequest";
import Input from "src/modules/Alumni/Components/InputField/index";
import CustomLoader from "src/modules/Alumni/Components/CustomLoader/index";
import SecondaryButton from "src/modules/Alumni/Components/SecondaryButton/index";
import PrimaryButton from "src/modules/Alumni/Components/PrimaryButton/index";
import _formFields from "./helpers/formFields";
import questionModel from "./helpers/questionModel";
import FormFieldModel from "src/modules/Alumni/models/FormField";
import "./index.scss";

let isMounted;
const SUPPORTS_MULTIPLE_OPTIONS = ["checkbox", "radio", "select"];
const rowStyle = {
  margin: 10,
};

const ShareBusinessUpdate = () => {
  const history = useHistory();
  const { surveyId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState({});
  const [questionFields, setQuestionFields] = useState(_formFields());
  const [formIsInvalid, setFormIsInvalid] = useState(false);
  const [isAlreadySubmitted, setIsAlreadySubmitted] = useState(false);
  // const [pageUpdated, setPageUpdated] = useState(false);

  useEffect(() => {
    // set default value for FormFields
    isMounted = true;

    loadSurveyById(surveyId, {
      setIsLoading,
      questionFields,
      formFields,
      history,
      setQuestionFields,
      setFormFields,
      setIsAlreadySubmitted,
    });

    return () => {
      setFormFields({});
      setQuestionFields(_formFields());

      isMounted = false;
    };
  }, []);

  const updateFormField = (e, options = {}) => {
    const formFieldsCopy = { ...formFields };
    const name = e.target.name || options.subName; // for elements that doesn't support the name property

    if (formFieldsCopy[name].formType === "file") {
      formFieldsCopy[name].uploadFileData(e.target.files[0]);
    } else {
      if (options && options.isFalseFile)
        formFieldsCopy[name].updateFormType("file");
      const formField = formFieldsCopy[name].setValue(e.target.value);
      if (e.target.value !== "") formField.setValidity(true);
      else formField.setErrorMsg().setValidity(false);
    }
    setFormFields(formFieldsCopy);
  };

  const isFormInvalid = () => {
    let _formIsInvalid = false;

    Object.keys(formFields).forEach((field) => {
      if (formFields[field].value.replace(/\s+/g, "") === "") {
        formFields[field].setErrorMsg().setValidity(false);
        _formIsInvalid = true;
      }
    });

    setFormIsInvalid(!formIsInvalid);
    return _formIsInvalid;
  };

  const createBusinessReport = async () => {
    if (!isFormInvalid()) {
      const formData = [];
      const paramObj = {
        questionId: "",
        optionId: "",
        value: "",
      };
      Object.keys(formFields).forEach((questionId) => {
        if (
          SUPPORTS_MULTIPLE_OPTIONS.indexOf(formFields[questionId].formType) >
          -1
        ) {
          formData.push({
            ...paramObj,
            questionId,
            optionId: formFields[questionId].value,
          });
        } else {
          formData.push({
            ...paramObj,
            questionId,
            value: formFields[questionId].value,
          });
        }
      });

      try {
        setIsLoading(true);
        const resp = await submitBusinessReport({
          userSelection: formData,
          surveyId,
        });
        if (resp.status === 200 || resp.status === 201) {
          toast(`Business Report Submitted Successfully`, {
            type: toast.TYPE.SUCCESS,
          });
          if (isMounted === true) setIsLoading(false);
          history.push("/alumni/business-reporting");
        }
      } catch (e) {
        console.log("ERROR", e);
        setIsLoading(false);
        toast("Something went wrong, try again", { type: toast.TYPE.ERROR });
      }
    }
  };

  const ActionButton = () => (
    <div className="d-flex">
      <SecondaryButton
        style={{
          width: "44%",
          marginLeft: "1.5%",
          marginRight: "1.5%",
          border: "1px solid #ccc",
        }}
        buttonText="Cancel"
        buttonAction={() => history.push("/alumni/business-reporting")}
      />
      {!isAlreadySubmitted && (
        <PrimaryButton
          style={{ width: "44%", marginLeft: "1.5%", marginRight: "1.5%" }}
          buttonText="Submit"
          buttonAction={createBusinessReport}
        />
      )}
    </div>
  );

  return (
    <Layout
      SideContent={<SidebarRight />}
      smallHeader={
        <div className="navigation">
          <span className="navigation__content">
            <b
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/alumni/business-reporting")}
            >
              Alumni Business Reporting
            </b>
            <RiArrowRightFill style={{ marginRight: 5 }} />
            Submit a Business Report
          </span>
        </div>
      }
    >
      {isLoading && <CustomLoader fullScreen={true} />}
      <div className="mb-5">
        <Row className="tab-heading mainContainer">
          <Col md={9}>
            <h2 className="mainContainer__title" style={{ fontSize: 18 }}>
              {questionFields.title.value}
            </h2>
            <label style={{ color: "#555" }}>
              {questionFields.summary.value}
            </label>
          </Col>
          <Col md={3} style={{ padding: 10, textAlign: "right" }}>
            <ActionButton />
          </Col>
        </Row>

        <div className="mt-3 pt-2 pb-2 bg-white" style={{ borderRadius: 5 }}>
          {/* <Row style={rowStyle}>
            <Col md={6}>
              <Input
                title="What's the nature of your business?"
                isRequired={true}
                isGrouped={true}
                name="businessNature"
                id="businessNature"
                type="radio"
                onChange={updateFormField}
                options={questionFields.businessNature.value.map((nature) => ({
                  label: nature.label,
                  value: nature.id,
                }))}
                errorMsg={formFields.businessNature.errorMsg}
                invalid={!formFields.businessNature.isValid}
              />
            </Col>
          </Row> */}
          {!isAlreadySubmitted ? (
            <Row style={rowStyle}>
              {questionFields.questions.length > 0 &&
                questionFields.questions.map((ques) => (
                  <Col md={6} key={ques.id}>
                    {SUPPORTS_MULTIPLE_OPTIONS.indexOf(ques.inputType.value) ===
                    -1 ? (
                      <Input
                        title={ques.title.value}
                        isRequired={ques.isRequired.value || false}
                        isGrouped={
                          SUPPORTS_MULTIPLE_OPTIONS.indexOf(
                            ques.inputType.value
                          ) > -1
                        }
                        name={ques.id}
                        id={ques.id}
                        value={
                          formFields[ques.id] ? formFields[ques.id].value : ""
                        }
                        type={ques.inputType.value}
                        onChange={updateFormField}
                        errorMsg={
                          formFields[ques.id]
                            ? formFields[ques.id].errorMsg
                            : ""
                        }
                        invalid={
                          formFields[ques.id]
                            ? !formFields[ques.id].isValid
                            : false
                        }
                      />
                    ) : (
                      <Input
                        title={ques.title.value}
                        isRequired={ques.isRequired.value || false}
                        isGrouped={
                          SUPPORTS_MULTIPLE_OPTIONS.indexOf(
                            ques.inputType.value
                          ) > -1
                        }
                        name={ques.id}
                        id={ques.id}
                        type={"radio"}
                        value={
                          formFields[ques.id] ? formFields[ques.id].value : ""
                        }
                        onChange={updateFormField}
                        options={
                          SUPPORTS_MULTIPLE_OPTIONS.indexOf(
                            ques.inputType.value
                          ) > -1
                            ? ques.options.value.map((opt) => ({
                                label: opt.label,
                                value: opt.id,
                              }))
                            : []
                        }
                        errorMsg={
                          formFields[ques.id]
                            ? formFields[ques.id].errorMsg
                            : ""
                        }
                        invalid={
                          formFields[ques.id]
                            ? !formFields[ques.id].isValid
                            : false
                        }
                      />
                    )}
                  </Col>
                ))}
            </Row>
          ) : (
            <h5 className="text-center mt-2 mb-2">
              You have already filled this form.
            </h5>
          )}
        </div>
      </div>
    </Layout>
  );
};

const loadSurveyById = async (
  id,
  {
    setIsLoading,
    formFields,
    questionFields,
    setQuestionFields,
    setFormFields,
    history,
    setIsAlreadySubmitted,
  }
) => {
  if (isMounted) setIsLoading(true);
  const res = await getSurveyById(id);
  await getSubmittedStatus(id, setIsAlreadySubmitted);
  if (res.status === 200) {
    const { data } = res.data;
    const _questionFields = { ...questionFields };
    const _formFields = { ...formFields };
    // Set formFields
    _questionFields.title.setValue(data.title);
    _questionFields.summary.setValue(data.summary);
    // _questionFields.businessNature.setValue(
    //   data.businessNature.map((option) => ({
    //     id: option.id,
    //     label: option.title,
    //   }))
    // );

    data.questions.forEach((qes) => {
      const question = questionModel();
      question.id = qes.id;
      question.title.setValue(qes.title);
      question.inputType.setValue(qes.inputType);
      question.isRequired.setValue(qes.isRequired || false);
      question.options.setValue(
        qes.options.map((opt) => ({
          id: opt.id,
          label: opt.option,
        }))
      );

      _formFields[question.id] = new FormFieldModel(
        question.id,
        question.isRequired.value,
        question.inputType.value
      );
      _questionFields.questions.unshift(question);
    });

    setQuestionFields(_questionFields);
    setFormFields(_formFields);
  } else {
    history.push("/alumni/business-reporting");
  }
  if (isMounted) setIsLoading(false);
};

const getSubmittedStatus = async (surveyId, setIsAlreadySubmitted) => {
  try {
    const res = await getBusinessReportsStatus(surveyId);
    const { data } = res.data;
    return setIsAlreadySubmitted(data);
  } catch (e) {
    return false;
  }
};

export default ifOnboardedThenShow(ShareBusinessUpdate);
