import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from "reactstrap";
import Star from "src/assets/img/profile/star.svg";
import Phone from "src/assets/img/profile/phone.svg";
import Gender from "src/assets/img/profile/gender.svg";
import DOB from "src/assets/img/profile/dob.svg";
import YOE from "src/assets/img/profile/years-of-experience.svg";
import "./About.scss";
import { _resolveRole } from "src/modules/mentorship/components/HomePage/HomePage";
import ReactAvatar from "react-avatar";
import {
  getCountryFromCode,
  capitalize_Words,
  getLanguageNameOrCode,
} from "src/utils/helper";
import {
  FaBlackTie,
  FaHome,
  FaInstagram,
  FaYoutube,
  FaFacebook,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import { GiDiamondRing, GiModernCity } from "react-icons/gi";
import { GoGlobe } from "react-icons/go";
import { MdLanguage, MdSchool } from "react-icons/md";
import { setCurrentUser, useCurrentUser } from "src/utils/Services/currentUser";
import { useParams } from "react-router-dom";
import { OptionsDropDown } from "./optionsDropdown/option";
import { hideProfileFields } from "src/requests/ProfileRequest";
import { Context } from "src/store";

const AboutItem = ({
  icon,
  label,
  value,
  isHidden,
  fieldName,
  showOptionMenu,
  isCurrentUser,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [loadingHideFields, setLoadingHideFields] = useState(false);

  const user = useCurrentUser();

  const { hiddenFields } = user;
  const { dispatch } = useContext(Context);

  const toggleHiddenField = () => {
    setLoadingHideFields(true);
    let copy = [...hiddenFields];
    if (isHidden) {
      copy = copy.filter((f) => f !== fieldName);
    } else {
      copy.push(fieldName);
    }
    user.hiddenFields = copy;
    hideProfileFields(dispatch, copy, callBack(user)).then(() =>
      setLoadingHideFields(false)
    );
  };

  const onMouseOn = () => {
    if (showOptionMenu) {
      setShowMenu(true);
    }
  };
  const onMouseOut = () => {
    if (showOptionMenu && showMenu) {
      setShowMenu(false);
    }
  };

  const callBack = (data) => {
    setCurrentUser(data);
  };

  return (
    <>
      {isHidden && !isCurrentUser ? null : (
        <Col
          className="about-item notranslate a-t"
          md={6}
          onMouseEnter={onMouseOn}
          onMouseLeave={onMouseOut}
        >
          {showMenu && (
            <OptionsDropDown show={showMenu} toggle={onMouseOut}>
              <li onClick={toggleHiddenField}>
                {!loadingHideFields ? (
                  <> {isHidden ? "Make Public" : "Hide from public"}</>
                ) : (
                  <>{"please wait..."}</>
                )}
              </li>
            </OptionsDropDown>
          )}

          <Row>
            <Col className="about-item__logo d-none d-md-block" md={1}>
              {icon}
            </Col>
            <Col className="about-item__details" md={10}>
              <h3 style={{ textTransform: "capitalize" }}>{value}</h3>
              <p>{label}</p>
            </Col>
          </Row>
          {isHidden && isCurrentUser && (
            <span style={{ fontSize: "9px" }}>Hidden</span>
          )}

          <hr />
        </Col>
      )}
    </>
  );
};

const About = ({ profile, firstName, lastName }) => {
  const [role, setRole] = useState("");
  const user = useCurrentUser();

  const { id } = useParams();

  const updateRole = () => {
    return new Promise((resolve, reject) => {
      let groups = [];
      groups =
        profile &&
        profile.userGroups &&
        profile.userGroups.map((org) => {
          return org.group.name;
        });
      return resolve(groups);
    });
  };

  useEffect(() => {
    updateRole().then((groups) => {
      if (groups && groups.length > 0) {
        setRole(_resolveRole(groups));
      }
    });
  }, [profile]);

  const name = `${firstName} ${lastName}`;

  const isHidden = (fieldName) => {
    return (
      profile &&
      profile.hiddenFields &&
      profile.hiddenFields.indexOf(fieldName) >= 0
    );
  };

  return (
    <Col>
      <Row>
        <AboutItem
          icon={<ReactAvatar name={name} size={38} round color="grey" />}
          label="Full Name"
          value={name}
          isCurrentUser={user.userId === id}
        />

        <AboutItem
          icon={<img src={Star} alt="star" />}
          label="Profile Category"
          value={role}
          isCurrentUser={user.userId === id}
        />

        <AboutItem
          icon={
            <FaBlackTie
              size={38}
              color="#919ba8"
              style={{ borderRadius: "100%" }}
            />
          }
          label="Industry Role"
          value={
            profile &&
            profile.industryRole &&
            capitalize_Words(profile.industryRole)
          }
          isCurrentUser={user.userId === id}
        />

        <AboutItem
          icon={
            <GiDiamondRing
              size={38}
              color="#919ba8"
              style={{ borderRadius: "100%" }}
            />
          }
          label="Marital Status"
          value={
            profile &&
            profile.maritalStatus &&
            capitalize_Words(profile.maritalStatus)
          }
          isHidden={isHidden("maritalStatus")}
          isCurrentUser={user.userId === id}
        />

        <AboutItem
          isCurrentUser={user.userId === id}
          icon={
            <MdLanguage
              size={38}
              color="#919ba8"
              style={{ borderRadius: "100%" }}
            />
          }
          label="Language"
          value={
            profile &&
            profile.language &&
            getLanguageNameOrCode("name", profile.language)
          }
          isHidden={isHidden("language")}
        />

        <AboutItem
          icon={
            <FaHome
              size={38}
              color="#919ba8"
              style={{ borderRadius: "100%" }}
            />
          }
          label="Address"
          value={profile && profile.address}
          showOptionMenu={user.userId === id}
          fieldName="address"
          isHidden={isHidden("address")}
          isCurrentUser={user.userId === id}
        />

        <AboutItem
          icon={
            <GiModernCity
              size={38}
              color="#919ba8"
              style={{ borderRadius: "100%" }}
            />
          }
          label="Location"
          value={`${profile && profile.city && `${profile.city},`}
          ${profile && profile.state},${" "}
          ${profile && profile.country && getCountryFromCode(profile.country)}`}
          showOptionMenu={user.userId === id}
          fieldName="country"
          isHidden={isHidden("country")}
          isCurrentUser={user.userId === id}
        />

        <AboutItem
          icon={<img src={Phone} alt="phone" />}
          label="Phone Number"
          value={profile && profile.phone}
          isHidden={isHidden("phone")}
          showOptionMenu={user.userId === id}
          fieldName="phone"
          isCurrentUser={user.userId === id}
        />

        <AboutItem
          icon={<img src={Gender} alt="gender" />}
          label="Gender"
          value={profile && profile.gender && capitalize_Words(profile.gender)}
          isHidden={isHidden("gender")}
          isCurrentUser={user.userId === id}
        />

        <AboutItem
          icon={<img src={DOB} alt="Calendar" />}
          label="Date of Birth"
          value={new Date(profile && profile.dateOfBirth).toLocaleDateString()}
          showOptionMenu={user.userId === id}
          fieldName="dateOfBirth"
          isHidden={isHidden("dateOfBirth")}
          isCurrentUser={user.userId === id}
        />

        <AboutItem
          icon={<img src={YOE} alt="icon" />}
          label="Years of Experience"
          value={profile && profile.yearsOfExperience}
          isHidden={isHidden("yearsOfExperience")}
          isCurrentUser={user.userId === id}
        />

        <AboutItem
          icon={
            <MdSchool
              size={38}
              color="#919ba8"
              style={{ borderRadius: "100%" }}
            />
          }
          label="Highest Level of Education"
          value={
            (profile &&
              profile.levelOfEducation &&
              capitalize_Words(profile.levelOfEducation)) ||
            null
          }
          isHidden={isHidden("levelOfEducation")}
          isCurrentUser={user.userId === id}
        />

        <AboutItem
          icon={
            <FaFacebook
              size={38}
              color="#919ba8"
              style={{ borderRadius: "100%" }}
            />
          }
          label="Facebook Profile"
          value={profile && profile.facebookUrl}
          isHidden={isHidden("facebookUrl")}
          isCurrentUser={user.userId === id}
        />

        <AboutItem
          icon={
            <FaLinkedin
              size={38}
              color="#919ba8"
              style={{ borderRadius: "100%" }}
            />
          }
          label="Linkedin Profle"
          value={profile && profile.linkedinUrl}
          isHidden={isHidden("linkedinUrl")}
          isCurrentUser={user.userId === id}
        />

        <AboutItem
          icon={
            <FaTwitter
              size={38}
              color="#919ba8"
              style={{ borderRadius: "100%" }}
            />
          }
          label="Twitter Profile"
          value={profile && profile.twitterUrl}
          isHidden={isHidden("twitterUrl")}
          isCurrentUser={user.userId === id}
        />

        <AboutItem
          icon={
            <FaInstagram
              size={38}
              color="#919ba8"
              style={{ borderRadius: "100%" }}
            />
          }
          label="Instagram Profile"
          value={profile && profile.instagramUrl}
          isHidden={isHidden("instagramUrl")}
          isCurrentUser={user.userId === id}
        />

        <AboutItem
          icon={
            <FaYoutube
              size={38}
              color="#919ba8"
              style={{ borderRadius: "100%" }}
            />
          }
          label="Youtube Profile"
          value={profile && profile.youtubeUrl}
          isHidden={isHidden("youtubeUrl")}
          isCurrentUser={user.userId === id}
        />

        <AboutItem
          icon={
            <GoGlobe
              size={38}
              color="#919ba8"
              style={{ borderRadius: "100%" }}
            />
          }
          label="Personal Website"
          value={profile && profile.websiteUrl}
          isHidden={isHidden("youtubeUrl")}
          isCurrentUser={user.userId === id}
        />
      </Row>
    </Col>
  );
};

About.propTypes = {};

export default About;
