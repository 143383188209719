import React from "react";
import "./CategoryModal.scss";
import { Col, ModalBody, Modal, ModalHeader } from "reactstrap";
import ModalImg2 from "src/assets/img/success-tef.png";
import { useTranslation } from "react-i18next";
// import { useCurrentUser } from "src/utils/Services/currentUser";

const messages_success = {
  en: {
    p1: "Dear Applicant,",
    p2:
      "Your application has been successfully submitted and will be assessed.",

    p3:
      "The TEF Entrepreneurship Programmes team will contact you via email in the coming weeks on your eligibility status and next steps.",

    p4: "We wish you all the best as you progress through the Programme.",
  },

  fr: {
    p1: "Cher candidat",
    p2: "Votre candidature a été soumise avec succès et sera évaluée.",

    p3:
      "L'équipe des programmes d'entrepreneuriat du TEF vous contactera par e-mail dans les semaines à venir concernant votre statut d'éligibilité et les prochaines étapes.",

    p4:
      "Nous vous souhaitons bonne chance dans votre progression dans le programme.",
  },

  // ar: {
  //   p1:
  //     "لقد أكملت بنجاح تقييم التطبيق وتم العثور على مؤهل للذهاب إلى قسم التدريب على إدارة الأعمال مفصل من البرنامج. سيتصل بك فريقنا في أي وقت بعد 16 أبريل 2022 عبر البريد الإلكتروني للحصول على تفاصيل حول التدريب والخطوات التالية",

  //   p2:
  //     "يرجى العلم أن هذا التدريب هو على الانترنت ولا يعني أنك سوف تتلقى رأس المال الأولي كما سيكون هناك معايير أخرى للتأهل لهذا.",

  //   p3: "حظا سعيدا كما تقدم لك إلى المرحلة التالية.",
  // },

  // pt: {
  //   p1:
  //     "Você concluiu com êxito a avaliação da inscrição e foi considerado elegível para prosseguir para o segmento de Treinamento em Gestão de Negócios sob medida do programa. Nossa equipe entrará em contato com você a qualquer momento após o dia 16 de Abril de 2022 via e-mail para os detalhes sobre o treinamento e as próximas etapas.",

  //   p2:
  //     "Informamos que este treinamento é online e não significa que você receberá capital inicial, pois haverá outros critérios para se qualificar para isso.",

  //   p3: "Boa sorte conforme você avança para a próxima fase.",
  // },
};

const UNDPCategoryModal = ({
  levelModal,
  score,
  onContinueClick,
  lang = "en",
}) => {
  const { t } = useTranslation();
  // const user = useCurrentUser();
  const paragraphs_success = messages_success[lang];

  return (
    <Modal isOpen={levelModal} toggle={onContinueClick}>
      <ModalHeader
        style={{ borderBottom: "none" }}
        toggle={onContinueClick}
      ></ModalHeader>
      <ModalBody style={{ textAlign: "center", padding: "2rem" }}>
        <div className="modalImg">
          <img src={ModalImg2} alt="" />
        </div>
        <span></span>
        <h4 style={{ marginBottom: "1.2rem" }}>{/* Great work!😃 */}</h4>

        <Col style={{ textAlign: "left" }}>
          <span style={{ fontWeight: "bold" }}>{paragraphs_success.p1}</span>

          <div>
            <p>{paragraphs_success.p2}</p>
            <p>{paragraphs_success.p3}</p>
            <p>{paragraphs_success.p4}</p>
          </div>

          {/* {score < 20 && (
            <span>
              <p>{t("UNDP not shortlisted welcome paragraph")}</p>
              <p>{t("UNDP not shortlisted paragraph-1")}</p>
              <p>{t("UNDP not shortlisted paragraph-2")}</p>
              <p>{t("UNDP not shortlisted paragraph-3")}</p>
              <p>{t("UNDP not shortlisted paragraph-4")}</p>
            </span>
          )}

          {score >= 20 && (
            <span>
              <p>{t("UNDP shortlisted paragraph-1")}</p>
              <p>{t("UNDP shortlisted paragraph-2")}</p>
              <p>{t("UNDP shortlisted paragraph-3")}</p>
              <p>{t("UNDP shortlisted paragraph-4")}</p>
              <p>{t("UNDP shortlisted paragraph-5")}</p>
            </span>
          )} */}
          <div className="continue-btn" onClick={onContinueClick}>
            {t("Continue")}
          </div>
        </Col>
      </ModalBody>
    </Modal>
  );
};

export default UNDPCategoryModal;
