import React, { useState } from "react";
import SearchBox from "src/modules/Alumni/Chat/Components/SearchBox/SearchBox";
import classes from "./AlumniList.module.scss";
import {
  ListGroup,
  ListGroupItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { HiDotsVertical } from "react-icons/hi";
import AlumniMember from "./AlumniMember";
import { selectColor } from "src/modules/Alumni/AlumniHub/helper/selectColor";
import AlumniListSkeletalLoader from "./SkeletalLoader";
import Participant from "src/modules/Alumni/Chat/Components/Participant/index";

const AlumniList = ({
  hubUsers = [],
  isloadingHubUsers = false,
  switchDefaultActiveChat,
  groupTitle,
  handleSearch,
  showTitle = true,
  readonly = true,
  addToList,
  removeFromList,
  showSelectUser,
  currentChat,
  toggleAddUserToGroupModalOpen,
  isGroupAdmin,
}) => {
  const colorPicker = selectColor();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <div className={classes["alumni-list__wrapper"]}>
      <div
        className="group_search"
        style={{ display: "flex", alignItems: "center" }}
      >
        {showTitle && (
          <h4
            className={classes["section-title"]}
            style={{ marginRight: "auto", alignItems: "center" }}
          >
            {groupTitle}
          </h4>
        )}
        {currentChat && currentChat.isGroupChat && (
          <div>
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              style={{ overflow: "initial" }}
              className="float-none"
            >
              <DropdownToggle color="white">
                <HiDotsVertical />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <div onClick={() => toggleAddUserToGroupModalOpen()}>
                    Add Group Members
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        )}
      </div>
      {isloadingHubUsers ? (
        <div className="mt-4">
          {[1, 2, 3, 4, 5, 6].map((num) => (
            <AlumniListSkeletalLoader key={num} />
          ))}
        </div>
      ) : (
        <>
          <SearchBox
            extraClass="bg-grey"
            placeholder="search"
            handleSearch={handleSearch}
          />
          <ListGroup flush className="mt-3">
            <ListGroupItem className="px-0 bg-transparent">
              {readonly
                ? hubUsers && hubUsers.length > 0
                  ? hubUsers.map((user, idx) => (
                      <AlumniMember
                        key={user.id}
                        user={user}
                        idx={idx}
                        colorPicker={colorPicker}
                        switchDefaultActiveChat={switchDefaultActiveChat}
                      />
                    ))
                  : null
                : hubUsers && hubUsers.length > 0
                ? hubUsers.map((user, idx) => (
                    <Participant
                      colorPicker={colorPicker}
                      userInfo={user.user}
                      addToList={addToList}
                      removeFromList={removeFromList}
                      key={user.id}
                    />
                  ))
                : null}
            </ListGroupItem>
          </ListGroup>
        </>
      )}
    </div>
  );
};

export default AlumniList;
