import React from "react";
import { Button } from "src/modules/training/components";
import { GiBrokenPottery } from "react-icons/gi";
import * as Sentry from "@sentry/react";
import { googleAnalyticsException } from "../googleAnalytics";

const ErrorBoundary = (props) => {
  const logToGoogleAnalytics = (error) => {
    // Send the message + component stack.
    googleAnalyticsException({
      error: `${error}\n${JSON.stringify(
        error,
        Object.getOwnPropertyNames(error)
      )}`,
      fatal: true,
    });

    // Send also just the message to allow better grouping.
    googleAnalyticsException({ error, fatal: true });
  };

  return (
    <Sentry.ErrorBoundary
      onError={logToGoogleAnalytics}
      fallback={
        <div className="internet-error">
          <GiBrokenPottery size={100} style={{ alignSelf: "center" }} />
          <p>Something went wrong</p>
          <Button value="Go Back" onClick={() => window.location.reload()} />
        </div>
      }
    >
      {props.children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
