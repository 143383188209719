import React from "react";
import Declaration from "src/modules/Application/Flagship/Declaration/Declaration";
const DeclarationIndex = (props) => {
  return (
    <>
      <Declaration props={props} />
    </>
  );
};

export default DeclarationIndex;
