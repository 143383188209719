import { asyncActionName } from "src/utils/AsyncUtils";
import {
  CREATE_PERSONAL_GOAL,
  VIEW_PERSONAL_GOALS,
  VIEW_PERSONAL_GOAL,
} from "src/actions";
import { initialState } from "src/store";

const PersonalGoalReducer = (
  state = initialState.personalGoal,
  { type, payload }
) => {
  switch (type) {
    case asyncActionName(CREATE_PERSONAL_GOAL).loading:
      return { ...state, loading: payload };
    case asyncActionName(CREATE_PERSONAL_GOAL).success:
      return {
        ...state,
        goal: payload.data,
        success: true,
      };
    case asyncActionName(CREATE_PERSONAL_GOAL).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(VIEW_PERSONAL_GOALS).loading:
      return { ...state, loading: payload };
    case asyncActionName(VIEW_PERSONAL_GOALS).success:
      return {
        ...state,
        goals: payload,
        success: true,
      };
    case asyncActionName(VIEW_PERSONAL_GOALS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(VIEW_PERSONAL_GOAL).loading:
      return { ...state, loading: payload };
    case asyncActionName(VIEW_PERSONAL_GOAL).success:
      return {
        ...state,
        goal: payload,
        success: true,
      };
    case asyncActionName(VIEW_PERSONAL_GOAL).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    default:
      return state;
  }
};

export default PersonalGoalReducer;
