import React from "react";
import CreatableSelect from "react-select/creatable";

const CreatableMulti = ({ onChange, options, isMulti, className }) => {
  return (
    <CreatableSelect
      className={`${className}`}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
    />
  );
};
export default CreatableMulti;
