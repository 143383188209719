import React, { useState, useContext, useEffect } from "react";
import classes from "./UserReactions.module.css";
import { Popover, PopoverBody } from "reactstrap";
import { FaHeart, FaRegHeart, FaRegCommentAlt } from "react-icons/fa";
// import { GoComment } from "react-icons/go";
import { FiShare2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Context } from "src/store";
import {
  likeComment,
  deleteComment,
  likeCommentNotification,
} from "src/requests/OpportunityRequest";
import ReplyComment from "./ReplyComment/ReplyComment";
import ResponseToComment from "./ReplyComment/ResponseToComment";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import { ReactComponent as Email } from "src/assets/img/home/email.svg";
import { ReactComponent as Share } from "src/assets/img/home/share.svg";
import { useTranslation } from "react-i18next";
import { useCurrentUser } from "src/utils/Services/currentUser";
import Swal from "sweetalert2";

const UserReactions = ({
  message,
  commentId,
  totalLikes,
  allLikesIds,
  createdBy,
  opportunityId,
  reload,
  totalReplies,
}) => {
  const { dispatch } = useContext(Context);
  // const { responses } = state.Opportunities;
  const user = useCurrentUser().userId;

  const [liked, setLiked] = useState(false);
  const [countLikes, setCountLikes] = useState(totalLikes);
  const [showReplyComment, setShowReplyComment] = useState(false);
  const [shareComment, setShareComment] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    hasLiked() ? setLiked(true) : setLiked(false);
  }, []);

  const hasLiked = () => allLikesIds.includes(user);

  const handleLikeComment = () => {
    setLoading(true);
    likeComment(dispatch, commentId).then((res) => {
      if (res && res.status === 200) {
        liked ? setLiked(false) : setLiked(true);
        hasLiked()
          ? setCountLikes(countLikes - 1)
          : setCountLikes(countLikes + 1);
      } else {
        hasLiked() ? setLiked(false) : setLiked(true);
      }
    });
    likeCommentNotification(dispatch, commentId, location.pathname);
    setLoading(false);
  };

  const handleReplyComment = () => {
    setShowReplyComment(!showReplyComment);
  };

  // console.log(loading);
  const handleShareComment = () => setShareComment(!shareComment);

  const canDelete = () => user === createdBy;

  const handleDeleteComment = () => {
    Swal.queue([
      {
        title: "Delete Comment",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#FC5A5A",
        preConfirm: () => {
          deleteComment(dispatch, commentId);
          reload();
        },
      },
    ])
      .then(({ value }) => {
        if (value[0]) {
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Your comment has been deleted.",
            confirmButtonColor: "#FC5A5A",
          });
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong!",
          text: "Your comment has not been deleted.",
          confirmButtonColor: "#FC5A5A",
        });
      });
  };

  const { t } = useTranslation();

  return (
    <div>
      <div className={classes.UserReactions}>
        <div
          onClick={() => handleLikeComment()}
          className={`${classes.ActionButton} ${
            loading ? classes.Disabled : ""
          }`}
        >
          {liked ? (
            <span className="mr-1">
              <FaHeart
                size={15}
                style={{ color: "#cf1d10", cursor: "pointer" }}
              />
            </span>
          ) : (
            <span className="mr-1">
              <FaRegHeart
                size={15}
                style={{ cursor: "pointer", color: "#444" }}
              />
            </span>
          )}

          <span>
            <span>{countLikes}</span>
            <span>Likes</span>
          </span>
        </div>

        <div className={classes.ActionButton} onClick={handleReplyComment}>
          <span className="mr-1">
            <FaRegCommentAlt
              size={15}
              style={{ cursor: "pointer", color: "#444" }}
            />
          </span>

          <span>{totalReplies} </span>
          <span>Responses</span>
        </div>
        <div
          className={classes.ActionButton}
          onClick={handleShareComment}
          id="shareComment"
        >
          <span className="mr-1">
            <FiShare2 size={15} style={{ cursor: "pointer", color: "#444" }} />
          </span>
          <span>Share</span>
          <Popover
            className={`p-0 border-0 d-none d-md-block`}
            trigger="legacy"
            placement="bottom"
            isOpen={shareComment}
            target="shareComment"
            toggle={handleShareComment}
          >
            <PopoverBody>
              <ul className="share-options__lists">
                <li>
                  <Share className="icon-tab" />
                  {t("Share on my timeline")}
                </li>
                <li>
                  <Email className="icon-tab" />
                  {t("Send as Message")}
                </li>
                <li>
                  <TwitterShareButton url="tefconnect.com" title={message}>
                    <TwitterIcon size={20} round={true} className="icon-tab" />
                    {t("Share via Twitter")}
                  </TwitterShareButton>
                </li>
                <li>
                  <FacebookShareButton url="tefconnect.com" quote={message}>
                    <FacebookIcon size={20} round={true} className="icon-tab" />
                    {t("Share via Facebook")}
                  </FacebookShareButton>
                </li>
                <li>
                  <LinkedinShareButton
                    url="tefconnect.com"
                    title="test"
                    summary={message}
                  >
                    <LinkedinIcon size={20} round={true} className="icon-tab" />
                    {t("Share via LinkedIn")}
                  </LinkedinShareButton>
                </li>
              </ul>
            </PopoverBody>
          </Popover>
        </div>
        {canDelete() && (
          <div
            className={classes.ActionButton}
            onClick={() => handleDeleteComment()}
          >
            <span>
              <RiDeleteBin6Line
                size={15}
                style={{ cursor: "pointer", color: "#444" }}
              />
            </span>
            <span>{t("Delete")}</span>
          </div>
        )}
        {/* <div className={classes.Time}>
          20 minutes ago
      </div> */}
      </div>
      {showReplyComment && (
        <div>
          <ReplyComment commentId={commentId} opportunityId={opportunityId} />
          <ResponseToComment commentId={commentId} />
        </div>
      )}
    </div>
  );
};

export default UserReactions;
