import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import Select from "react-select";
import { searchMember } from "src/requests/AuthRequest";
import { Context } from "src/store";
import { getConversationId } from "src/utils/Services/getConversationId";
import { getUserProfile } from "src/requests/ProfileRequest";
import { useHistory } from "react-router-dom";
import Loader from "src/components/Loader/Loader";
import styles from "./NewMessage.module.scss";

const NewMessage = ({ isOpen, toggle, list, updateUserDetails }) => {
  const history = useHistory();
  const { dispatch } = useContext(Context);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isStartLoading, setIsStartLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleChange = (selectedOption) => setSelectedOption(selectedOption);

  const onStartChat = async () => {
    setIsStartLoading(true);
    const res = await getConversationId(selectedOption.value);
    let id;
    if (res.status === 404) {
      getUserProfile(dispatch, selectedOption.value).then((res) => {
        if (res.status === 200) {
          setIsStartLoading(false);
          const {
            data: { userId, firstName, lastName, imageUrl },
          } = res.data;
          const newCl = {
            conversationId: selectedOption.value,
            createdOn: new Date(),
            from: "",
            to: "",
            user: {
              userId: userId,
              firstName: firstName,
              lastName: lastName,
              imageUrl: imageUrl,
            },
          };
          list.unshift(newCl);
          updateUserDetails({
            name: `${firstName} ${lastName}`,
            userId: userId,
          });
          history.push(`/messages/thread/${userId}`);
        } else {
          setIsStartLoading(false);
        }
      });
    } else {
      setIsStartLoading(false);
      id = res.conversationId;
      history.push(`/messages/thread/${id}`);
    }
    toggle();
  };

  const handleInputChange = (newValue) => setInputValue(newValue);

  const getOptions = async () => {
    setIsLoading(true);
    const newMembersArray = [];
    const members = await searchMember(dispatch, inputValue);
    members &&
      members.forEach((member) => {
        newMembersArray.push({
          value: member.id,
          label: `${member.firstName} ${member.lastName}`,
        });
      });
    setOptions(newMembersArray);
    setIsLoading(false);
    return newMembersArray;
  };

  useEffect(() => {
    getOptions();
  }, [inputValue]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <div className="text-center">New message</div>
      </ModalHeader>
      <ModalBody className="mb-5">
        <Select
          value={selectedOption}
          onChange={handleChange}
          options={options}
          isLoading={isLoading}
          placeholder="Search connection"
          name="searchConnection"
          isClearable={true}
          autoFocus={true}
          onInputChange={handleInputChange}
        />
      </ModalBody>
      <ModalFooter className="mt-5">
        <Button color="link" onClick={toggle}>
          Close
        </Button>
        <Button
          disabled={selectedOption === ""}
          className={styles.submitButton}
          onClick={onStartChat}
        >
          <span style={{ marginRight: "5px" }}>Send message</span>
          {isStartLoading && <Loader color="light" />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(NewMessage);
