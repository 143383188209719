/* eslint-disable node/handle-callback-err */
/* eslint-disable no-unused-vars */
/* eslint no-unneeded-ternary: "error" */
import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Row,
  Progress,
  Modal,
  ModalBody,
  ModalHeader,
  // Button,
} from "reactstrap";
import { Button } from "src/modules/training/components";
import "./_banner.scss";
// import TCMainIndex from "src/modules/training/components/TCmodal/TCMainIndex";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { Context } from "src/store";
// import { hasAgreedToParticipate } from "src/requests/MentorRequests";
import { useHistory, useParams } from "react-router-dom";
// import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import Loader from "react-loader-spinner";
import {
  // getUserCourses,
  userStartsCourse,
  getUserRatingOnCourse,
  getRatingOnCourse,
} from "src/requests/TrainingRequest";
import { confirmUserSurvey } from "src/requests/TrainingSurveyRequest";
import { getDefaultGUID, getLanguage, _truncateText } from "src/utils/helper";
import ReactStars from "react-stars";
import RatingComponent from "./RatingComponent";
import Survey from "./Survey/Survey";

import entrepreneurCats from "src/modules/training/components/entrepreneurCat.json";

const surveyType = "pre-training";
export const Banner = () => {
  const { userId } = useCurrentUser();
  // const user = get_user_info_from_token().user;
  // const trainingEventId = user.training ? user.training[1] : null;
  // const [showModal, setShowModal] = useState(false);
  // const [hasAgreed, setHasAgreed] = useState(false);
  const { state, dispatch } = useContext(Context);
  const [startingCourse, setStartingCourse] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  // const [hasRated, setHasRated] = useState(false);
  const [userCourseRating, setUserCourseRating] = useState({});
  const [courseRating, setCourseRating] = useState(0);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [loadingUserSurvey, setLoadingUserSurvey] = useState(false);
  const [survey, setSurvey] = useState(null);
  const [score, setScore] = useState(0);

  const course = state.Training.userCourseModules;

  const history = useHistory();

  const { eventId, courseId } = useParams();
  const lessionUrl = `/training/lms/course?trainingEventId=${eventId}&courseId=${courseId}`;
  const _toggleSurveyModal = () => setShowSurveyModal(!showSurveyModal);

  const redirectTo = () => {
    if (course.startDate) {
      return history.push(lessionUrl);
    }
    startCourse();
  };

  const _getUserRatingOnCourse = () => {
    return getUserRatingOnCourse(courseId, userId, eventId)
      .then((res) => {
        if ((res.status === 200 || res.status === 201) && res.data) {
          const result = res.data.data;
          setUserCourseRating(result);
        }
      })
      .catch(() => {
        setUserCourseRating({});
      });
  };

  const _getRatingOnCourse = () => {
    return getRatingOnCourse(courseId, eventId).then((res) => {
      if ((res.status === 200 || res.status === 201) && res.data) {
        const result = res.data?.data;
        const { average } = result;
        setCourseRating(average);
      }
    });
  };

  // this method should only be called based on the date course was started and not by the course progress
  const startCourse = () => {
    setStartingCourse(true);
    userStartsCourse(dispatch, eventId, courseId).then((res) => {
      setStartingCourse(false);
      if (res.status === 200 || res.status === 201) {
        return history.push(lessionUrl);
      }
    });
  };

  const _confirmUserSurvey = () => {
    setLoadingUserSurvey(true);
    confirmUserSurvey(getLanguage() || "en", eventId, surveyType)
      .then((res) => {
        if (res.status === 200 && res.data.data.data) {
          const result = res.data.data.data;
          setSurvey(result);
          _toggleSurveyModal();
        }
        setLoadingUserSurvey(false);
      })
      .catch((err) => {
        setLoadingUserSurvey(false);
      });
  };

  const computeProgress = () => {
    return course.modulesCompleted === Number(0)
      ? 0
      : Number(
          (Number(course.modulesCompleted) / Number(course.modules.length)) *
            100
        ).toFixed(2);
  };

  useEffect(() => {
    // getAgreedToParticipation();
    setLoadingUserSurvey(true);
    _getUserRatingOnCourse();
  }, []);

  useEffect(() => {
    setScore(state.Training?.trainingCourseAssessment?.cumulativeAvgScore);
  }, [state.Training?.trainingCourseAssessment]);

  useEffect(() => {
    if (course) {
      setCourseRating(course.avgRating);
    }
  }, [course]);

  useEffect(() => {
    if (eventId) {
      if (eventId === getDefaultGUID()) {
        setLoadingUserSurvey(false);
      } else {
        _confirmUserSurvey();
      }
    }
  }, [eventId]);

  const toggleRatingModal = () => setShowRatingModal(!showRatingModal);
  return (
    <>
      <Row fluid="true" className="banner-wrapper">
        {state.Training.loadingGetUserTrainingModules && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "300px",
            }}
          >
            <Loader
              type="ThreeDots"
              height={50}
              width={50}
              color="white"
              // color="var(--tef-red)"
            />
          </div>
        )}
        {!state.Training.loadingGetUserTrainingModules && course && (
          <>
            {" "}
            <Col md={6} className="banner-wrapper--text-container">
              <section className="section-banner-header">
                <h1 className="section-banner-header--text">{course?.title}</h1>
                <p>
                  {course?.segment.toLowerCase() ===
                  entrepreneurCats.Preparatory.toLowerCase()
                    ? "This is an optional course available to ALL TEFConnect subscribers(Ungraded)."
                    : "This is a mandatory and graded TEF Programme training course for entrepreneurs in this category."}
                </p>
              </section>

              <section className="section-rating">
                <span>Ratings</span>

                <div className="course-rate-container">
                  <span
                    className="training-course-rating"
                    onClick={toggleRatingModal}
                  >
                    {courseRating > 0 && (
                      <span className="value">
                        {Number(courseRating).toFixed(0)}
                      </span>
                    )}

                    <ReactStars
                      count={5}
                      value={parseInt(Number(courseRating).toFixed(2))}
                      size={17}
                      color2={"#ffb319"}
                      half
                      edit={false}
                      // className="training-course-rating"
                      color1={"rgb(187, 179, 179)"}
                    />
                  </span>
                  <div onClick={toggleRatingModal} className="rr-button">
                    {Object.keys(userCourseRating).length > 0
                      ? "Update rating"
                      : "Rate this course"}
                  </div>
                </div>
              </section>

              <section className="section-rating">
                <span>modules</span>
                <span className="value">
                  {state.Training?.userCourseModules?.modules?.length || 0}
                </span>
              </section>

              <section className="section-rating">
                <span>Enrolments</span>
                <span className="value">{course?.totalEnrollment || 0}</span>
              </section>

              <section>
                <Progress
                  value={Math.round(computeProgress()).toString()}
                  color="secondary"
                  // color="#0db884"
                  // className={classes.pBar}
                />
                <span>{Math.round(computeProgress())}% complete</span>
              </section>
              <section className="section-btn">
                <Button
                  value={
                    "Proceed to training"
                    // progress > 0 ? `Continue to Lecture` : `Start Training!`
                  }
                  onClick={redirectTo}
                  isDisabled={startingCourse}
                  // isLoading={true}
                  spinnerColor="white"
                  // icon={}
                />
                {startingCourse && (
                  <div style={{ marginLeft: "5px" }}>
                    <Loader
                      color="white"
                      type="ThreeDots"
                      height={35}
                      width={35}
                    />
                  </div>
                )}
              </section>
            </Col>
            <Col
              md={6}
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <section className="section-banner-image">
                <img
                  src={
                    course.imageUrl ||
                    "https://cdn.pixabay.com/photo/2017/04/19/13/16/computer-2242264_1280.jpg"
                  }
                  alt="bannerImage"
                  className="img-fluid"
                />
              </section>
            </Col>
          </>
        )}
      </Row>
      <React.Fragment>
        <Modal isOpen={showRatingModal} centered toggle={toggleRatingModal}>
          <ModalHeader toggle={toggleRatingModal}>Submit rating</ModalHeader>
          <ModalBody>
            <RatingComponent
              courseId={courseId}
              getCourseRating={_getRatingOnCourse}
              hasRated={Object.keys(userCourseRating).length > 0}
              userId={userId}
              toggle={toggleRatingModal}
              eventId={eventId}
              userCourseRating={userCourseRating}
              getUserCourseRating={_getUserRatingOnCourse}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>

      <React.Fragment>
        <Modal isOpen={showSurveyModal} size="lg">
          <ModalHeader>
            <span style={{ fontWeight: "bolder", fontSize: "18px" }}>
              {survey?.overview?.title}
            </span>
          </ModalHeader>
          <ModalBody>
            <Survey survey={survey} toggle={_toggleSurveyModal} />
          </ModalBody>
        </Modal>
      </React.Fragment>

      <React.Fragment>
        <Modal isOpen={loadingUserSurvey} centered size="md">
          <ModalBody style={{ display: "flex", justifyContent: "center" }}>
            <Loader
              type="Oval"
              color="var(--tef-red)"
              height={35}
              width={35}
              style={{ margin: "0 auto" }}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>
    </>
  );
};
