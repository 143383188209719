import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Comment from "src/modules/Alumni/Resources/components/Comment/index";
import InputField from "src/modules/Alumni/Components/InputField/index";
import PrimaryButton from "src/modules/Alumni/Components/PrimaryButton/index";
import CustomLoader from "src/modules/Alumni/Components/CustomLoader/index";
import {
  fetchComments,
  createComment,
  getUserProfile,
} from "src/requests/AlumniRequest";

let isMounted;
const CommentBody = () => {
  const { resourceId } = useParams();
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPostingCommentLoading, setIsPostingCommentLoading] = useState(false);

  useEffect(() => {
    isMounted = true;
    loadComments();
  }, []);

  const loadComments = async () => {
    try {
      if (isMounted === true) setIsLoading(true);
      const _userIds = [];
      const commentsReq = await fetchComments(resourceId);
      if (commentsReq.status === 200 || commentsReq.status === 201) {
        const { data } = commentsReq.data.data;
        const _comments = [];
        data.forEach((comment) => {
          _userIds.push(comment.userId);
          _comments.push({
            ...comment,
          });
        });

        const profileReq = await getUserProfile(_userIds);
        if (isMounted === true) {
          // now we have all commenters profiles, we have to match them with their comments
          const { data: _profiles } = profileReq.data;
          setComments(
            _comments.map((comment) => ({
              ...comment,
              profile: _profiles.find((p) => p.userId === comment.userId),
            }))
          );
        }

        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      toast("Something went wrong, try again", { type: toast.TYPE.ERROR });
    }
  };

  const _createComment = async () => {
    try {
      if (isMounted === true) setIsPostingCommentLoading(true);
      const _comment = await createComment(resourceId, comment);
      if (_comment.status === 200 || _comment.status === 201) {
        // refetch comments
        if (isMounted === true) {
          setComment("");
          setIsPostingCommentLoading(false);
        }
        loadComments();
      }
    } catch (e) {
      if (isMounted === true) setIsPostingCommentLoading(false);
      toast("Something went wrong, try again", { type: toast.TYPE.ERROR });
    }
  };

  return (
    <>
      <div className="video-view-content__comment_container">
        <h6>Comments ({comments.length})</h6>
        {isLoading ? (
          <CustomLoader size={20} />
        ) : (
          <div className="video-view-content__comment_container__comment_list">
            {comments.map(({ content, id, createdOn, profile }, i) => (
              <Comment
                key={id}
                name={`${profile.firstName} ${profile.lastName}`}
                avatar={profile.imageUrl}
                content={content}
                date={new Date(createdOn).toLocaleDateString()}
              />
            ))}
          </div>
        )}
      </div>
      <div className="video-view-content__comment_container">
        <h6>Add Comment</h6>
        <div className="create-comment-box">
          <InputField
            name="message"
            id="message"
            type="textarea"
            label="Comment / Message"
            placeholder="Your comment / message"
            style={{ height: 60 }}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <div className="create-comment-box__btn-component">
            {isPostingCommentLoading && <CustomLoader size={20} />}
            {!isPostingCommentLoading && (
              <PrimaryButton
                buttonText="POST"
                buttonAction={() => {
                  if (comment.replace(/\s+/g, "") !== "") _createComment();
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentBody;
