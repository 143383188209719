import { env } from "src/constants/env";
// always go to training lms
const shouldRedirectTefTrainingToMoodle = () => {
  return false;
};
/** Returns the route which opens trainings. The route differs depending on whether Moodle is enabled. */
export const getTrainingRoute = () => {
  return shouldRedirectTefTrainingToMoodle() ? "/moodle" : "/training/LMS";
};

/**
 * Disables Google Analytics, Google Translate and Sentry to simplify debugging
 * (fewer network calls).
 */
export const FLAG_DISABLE_THIRD_PARTY_NETWORK_CALLS = "no_3p_net";

/** Flag modifier which enables its usage in prod. */
const MODIFIER_ENABLED_IN_PROD = "ENABLED_IN_PROD";

/** All flags need to be listed here, with an array of modifiers.
 * To enable a flag in prod, list it as follows: [FLAG_NAME]: [MODIFIER_ENABLED_IN_PROD].
 */
const URL_FLAG_PARAMS = {
  [FLAG_DISABLE_THIRD_PARTY_NETWORK_CALLS]: [],
};

/** Enables or disables flags based on the URL parameters. */
export const processUrlFlagParams = () => {
  const location = new URL(window.location.href);

  for (const param of Object.keys(URL_FLAG_PARAMS)) {
    const value = location.searchParams.get(param);
    if (!value) {
      continue;
    }

    switch (value) {
      case "1":
        localStorage.setItem(param, "1");
        break;
      case "0":
        localStorage.removeItem(param);
        break;
      default:
        throw new Error(`Unexpected value for ${param}: '${value}'`);
    }
  }
};

const getFlagValue = (flagName) => {
  if (URL_FLAG_PARAMS[flagName] === undefined) {
    return null;
  }
  if (
    !URL_FLAG_PARAMS[flagName].includes(MODIFIER_ENABLED_IN_PROD) &&
    env.REACT_APP_ALLOW_DEBUG_URL_FLAGS !== "true"
  ) {
    return null;
  }
  return localStorage.getItem(flagName);
};

/** Returns the value of the boolean URL flag. */
export const getBooleanFlagValue = (flagName) => {
  return getFlagValue(flagName) === "1";
};

/** Implements the route which goes to Moodle. */
export const moodleRoute = () => {
  window.location.replace(process.env.REACT_APP_MOODLE_URL);
  return null;
};
