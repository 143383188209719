import React from "react";
import HomepageContainer from "./Container";
import "./_homepage.scss";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import Layout from "src/components/Layout/Layout";
import SideRight from "src/modules/homepage/components/SideRight/SideRight";

const Home = (props) => {
  return (
    <Layout smallHeader SideContent={<SideRight />}>
      <HomepageContainer {...props} />
    </Layout>
  );
};

export default ifOnboardedThenShow(Home);
