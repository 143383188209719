import React from "react";
import styles from "./CustomContent.module.scss";
import SideBar from "src/components/Layout/SideBar/SideBar";
import RightSideBar from "src/components/Layout/RightSideBar/RightSideBar";

const Content = ({
  hideRightSideBar,
  withoutRIghtSideBar,
  rightSideContent,
  title,
  smallTitle,
  children,
}) => {
  return (
    <div className={styles.Content}>
      <SideBar />
      <div
        className={`${
          withoutRIghtSideBar
            ? styles.Content__mainContainer_withoutSideRight
            : styles.Content__mainContainer
        }`}
      >
        {smallTitle && (
          <span className={styles.Content__smallTopText}>{smallTitle}</span>
        )}
        {title && (
          <h1 className={styles.Content__mainContainerTitle}>{title}</h1>
        )}
        {children}
      </div>
      <RightSideBar hide={hideRightSideBar}>{rightSideContent}</RightSideBar>
    </div>
  );
};

export default Content;
