import React, { useEffect, useContext, useState } from "react";
import { Row, Col, Label, Form, FormGroup, Input, Button } from "reactstrap";
import Empty from "src/assets/img/mentorship-empty.png";
import {
  getMentorshipProfiles,
  getConnections,
  getRequests,
  connect,
} from "src/requests/MentorRequests";
import { Context } from "src/store";
import "./Mentors.scss";
import { isMentor } from "src/modules/mentorship/components/helper";
import ConnectionCard from "src/modules/mentorship/components/Mentor/ConnectionCard";
import Swal from "sweetalert2";

import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { useTranslation } from "react-i18next";
import countryList from "src/utils/countryList.json";
import SearchBar from "src/components/SearchBar/SearchBar";
import { industryOfExperience as industries } from "src/modules/onboarding/tefMentorOnboarding/static";
import Loader from "src/components/Loader/Loader";

const Mentors = () => {
  const { state, dispatch } = useContext(Context);
  const data = get_user_info_from_token();
  const [loadingConnect, setLoadingConnect] = useState(null);
  const [searchWord, setSearchWord] = useState("");
  const [list, setList] = useState([]);
  // const [originList, setOriginalList] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    country: null,
    gender: null,
    industry: null,
    yearsOfExperience: null,
    businessIndustry: null,
  });

  const [paging, setPaging] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    pageNumber: 1,
  });
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);

  const { t } = useTranslation();

  const handleSearchInputChange = (value) => {
    // const filtered = handleFilter(originList, filterOptions);

    setSearchWord(value);
    // if (value === "") {
    //   setList(filtered);
    // } else {
    //   const searchString = value.toLowerCase();
    //   const searchResult = handleSearchFilter(filtered, searchString);
    //   setList(searchResult);
    // }
    getData(1, value);
  };

  // const handleSearchFilter = (list, searchString) => {
  //   return list.filter((item) => {
  //     return (
  //       item.firstName.toLowerCase().includes(searchString) ||
  //       item.lastName.toLowerCase().includes(searchString) ||
  //       item.email.toLowerCase().includes(searchString)
  //     );
  //   });
  // };

  useEffect(() => {
    getData(paging.pageNumber, searchWord);
  }, []);

  const getData = (pageNumber, searchString, merge) => {
    setLoadingContent(true);
    getRequests(dispatch);
    getConnections(dispatch);
    return getMentorshipProfiles(dispatch, isMentor(data.user), {
      pageNumber,
      ...filterOptions,
      search: searchString,
    }).then((res) => {
      const { data, pager } = res.data.data;
      if (data) {
        if (merge) {
          setList((prevList) => [...prevList, ...data]);
        } else {
          setList(data);
        }
        setLoadingContent(false);
        // setOriginalList(data);
      }
      setPaging((prevPaging) => ({ ...prevPaging, ...pager }));
    });
  };

  const handleConnect = (id, userDetails) => {
    const maxAllowedMentorConnection = 5;
    const maxAllowedMenteeConnection = 3;
    const maxAllowed = isMentor(data.user)
      ? maxAllowedMentorConnection
      : maxAllowedMenteeConnection;
    const message = isMentor(data.user)
      ? "You have reached the maximum number of mentees you can connect with."
      : "You have reached the maximum number of mentors you can connect with.";
    const connectionCount =
      (state.Mentors.connected && state.Mentors.connected.length) || 0;
    if (connectionCount >= maxAllowed)
      return Swal.fire({
        title: "Warning",
        text: message,
        icon: "warning",
        confirmButtonColor: "red",
      });
    setLoadingConnect(id);
    connect(dispatch, id, userDetails).then(() => {
      setLoadingConnect(null);
    });
  };

  const isConnectedUser = (userId) => {
    return (
      state.Mentors.connected &&
      state.Mentors.connected
        .map((unconnected) => {
          return unconnected.namedId;
        })
        .includes(userId)
    );
  };

  const connStatus = (userId) => {
    if (
      state.Mentors.unconnected &&
      state.Mentors.unconnected
        .map((unconnected) => {
          return unconnected.namedId;
        })
        .includes(userId)
    ) {
      return "pending";
    }

    if (
      state.Mentors.unconnected &&
      !state.Mentors.unconnected
        .map((unconnected) => {
          return unconnected.namedId;
        })
        .includes(userId) &&
      state.Mentors.connected &&
      !state.Mentors.connected
        .map((unconnected) => {
          return unconnected.namedId;
        })
        .includes(userId)
    ) {
      return "connect";
    }
  };

  const onFilterChange = (e) => {
    let { value, name } = e.target;
    value = value === "" ? null : value;
    setFilterOptions({
      ...filterOptions,
      [name]: value,
    });
  };

  // const handleFilter = (list, filters) => {
  //   let filterObj = {};
  //   for (const [key, value] of Object.entries(filters)) {
  //     if (value) {
  //       filterObj = { ...filterObj, [key]: value };
  //     }
  //   }

  //   if (Object.keys(filterObj).length === 0) {
  //     return list;
  //   }
  //   const filterKeys = Object.keys(filterObj);
  //   return list.filter((item) => {
  //     return filterKeys.every((key) => {
  //       if (key === "yearsOfExperience") {
  //         const range = filterObj[key].split("-");
  //         const min = Number(range[0]);
  //         const max = Number(range[1]);
  //         return item[key] >= min && item[key] <= max;
  //       }
  //       return (
  //         filterObj[key].toString().toLowerCase() ===
  //         item[key].toString().toLowerCase()
  //       );
  //     });
  //   });
  // };

  useEffect(() => {
    // const filtered = handleFilter(originList, filterOptions);

    // if (searchWord.length > 0) {
    //   return setList(handleSearchFilter(filtered, searchWord));
    // }
    // setList(filtered);
    getData(1, searchWord);
  }, [filterOptions]);

  const showConnections = () => {
    return list && list.length > 0 ? (
      list.map((conn, i) => (
        <ConnectionCard
          key={i}
          userId={conn.userId}
          isConnected={isConnectedUser(conn.userId)}
          connectionStatus={connStatus(conn.userId)}
          onConnect={() => handleConnect(conn.userId, conn)}
          userDetails={conn}
          loadingSendingRequest={loadingConnect === conn.userId}
        />
      ))
    ) : (
      <Col
        style={{ textAlign: "center", flexDirection: "column" }}
        // md={{ size: 6 }}
        className="d-flex justify-content-center flex-direction-column"
      >
        <br />
        <br />
        <img src={Empty} alt={"error"} style={{ alignSelf: "center" }} />
        <h3 className="goals-heading">
          {" "}
          {isMentor(data.user) ? "No Mentee To Show" : "No Mentor To Show"}
        </h3>
        <br />
        <br />
      </Col>
    );
  };
  const yearsOfExperienceOptions = [
    { value: "0-5", label: "0-5" },
    { value: "5-10", label: "5-10" },
    { value: "10-15", label: "10-15" },
    { value: "15-20", label: "15-20" },
    { value: "20-60", label: "20 and above" },
  ];

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];

  const _onShowMoreClick = () => {
    setLoadingMore(true);

    getData(paging.pageNumber + 1, searchWord, true).then(() => {
      setLoadingMore(false);
    });
  };

  return (
    <div className="Mentors-container">
      <Col md={12}>
        <div className="opp-tab-navs" style={{ marginTop: "-2px" }}>
          <Row>
            <Col md={5} sm={12} lg={5}>
              <h5 className="find-a-mentor-heading">
                {isMentor(data.user) ? "Find a mentee" : "Find a mentor"}
              </h5>
            </Col>
            <Col md={3} sm={12} lg={3}></Col>
            <Col md={4} sm={12} lg={4} className="float-right">
              {/* <Input
                value={searchWord}
                placeholder={`Search ${
                  isMentor(data.user) ? "Mentees" : "Mentors"
                }`}
                onChange={handleSearchInputChange}
                className="search-mentorship-input"
              /> */}
              <SearchBar
                style={{
                  backgroundColor: "white",
                  border: "1px solid var(--new-faded-white)",
                }}
                placeholder={
                  isMentor(data.user) ? "Search mentee" : "Search mentors"
                }
                setSearchData={handleSearchInputChange}
              />
            </Col>
          </Row>

          <div style={{ clear: "both" }}></div>
          <span className="opp-tabs-title">{t("Filter by")}:</span>
          <Form>
            <Row form>
              <Col md={3} lg={3} sm={12} xs={12}>
                <FormGroup>
                  <Label for="experience" className="label-dp">
                    {t("Experience")}
                  </Label>
                  <Input
                    type="select"
                    name="yearsOfExperience"
                    id="experience"
                    onChange={onFilterChange}
                    value={filterOptions.yearsOfExperience}
                  >
                    <option value="">{t("All")}</option>
                    {yearsOfExperienceOptions &&
                      yearsOfExperienceOptions.map((year) => {
                        return (
                          <option key={year.value} value={year.value}>
                            {year.label}
                          </option>
                        );
                      })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={3} lg={3} sm={12} xs={12}>
                <FormGroup className="st-dp">
                  <Label for="country" className="label-dp">
                    {t("Country")}
                  </Label>
                  <Input
                    type="select"
                    name="country"
                    id="country"
                    onChange={onFilterChange}
                    value={filterOptions.country}
                    className="st-dp"
                  >
                    <option value="">{t("All")}</option>
                    {countryList &&
                      countryList.map((country) => {
                        return (
                          <option
                            value={country.countryShortCode}
                            key={country.countryShortCode}
                          >
                            {country.countryName}
                          </option>
                        );
                      })}
                  </Input>
                </FormGroup>
              </Col>
              {!isMentor(data.user) && (
                <Col md={3} lg={3} sm={12} xs={12}>
                  <FormGroup>
                    <Label for="industry" className="label-dp">
                      {t("Industry")}
                    </Label>
                    <Input
                      type="select"
                      name="industry"
                      id="industry"
                      onChange={onFilterChange}
                      value={filterOptions.industry}
                    >
                      <option value="">{t("All")}</option>
                      {industries &&
                        industries.map((industry) => {
                          return (
                            <option key={industry.value} value={industry.value}>
                              {industry.label}
                            </option>
                          );
                        })}
                    </Input>
                  </FormGroup>
                </Col>
              )}

              <Col md={3} lg={3} sm={12} xs={12}>
                <FormGroup>
                  <Label for="gender" className="label-dp">
                    Gender
                  </Label>
                  <Input
                    type="select"
                    name="gender"
                    id="gender"
                    onChange={onFilterChange}
                    value={filterOptions.gender}
                  >
                    <option value="">All</option>
                    {genderOptions &&
                      genderOptions.map((gender) => {
                        return (
                          <option key={gender.value} value={gender.value}>
                            {gender.label}
                          </option>
                        );
                      })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>
        {loadingContent && (
          <div className="d-flex justify-content-center">
            <Loader color="secondary" />
          </div>
        )}
        <Row>
          <div className="connection-list-c">{showConnections()}</div>
        </Row>
        {paging.hasNextPage && (
          <>
            {loadingMore && (
              <div className="d-flex justify-content-center">
                <Loader color="secondary" />
              </div>
            )}

            <div
              onClick={_onShowMoreClick}
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <Button>Show More</Button>
            </div>
          </>
        )}
      </Col>
      <Col></Col>

      {/* <Modal isOpen ></Modal> */}
    </div>
  );
};

Mentors.propTypes = {};

export default Mentors;
