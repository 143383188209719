import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import classes from "./NewAuthLayout_v2.module.scss";
import ClickOutside from "react-click-outside-component";
import { FiChevronLeft } from "react-icons/fi";

// import { useDetectClickOutside } from "react-detect-click-outside";

const AboutMenu = ({ isOpen, toggle, title }) => {
  // const dpref = useDetectClickOutside({ onTriggered: () => toggle(title) });
  return (
    <>
      {isOpen ? (
        // <OutsideClick >
        <ClickOutside onClickOutside={() => toggle(title)}>
          <div>
            <Row className={classes.aboutMenu_dp}>
              <Col md={6}>
                <h4 className={classes.back_btn} onClick={() => toggle(title)}>
                  <FiChevronLeft /> Back
                </h4>
                <h4 className={classes.menu_title}>Company</h4>
                <ul className={classes.menu_list}>
                  <li>
                    <a
                      href="https://www.tonyelumelufoundation.org/about-us"
                      target="_blank"
                      rel="noreferrer"
                    >
                      About us
                    </a>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact us</Link>
                  </li>
                  <li>
                    <Link>Awards</Link>
                  </li>
                  <li>
                    <a
                      href="https://tonyelumelufoundation.org/partners"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Partnerships
                    </a>
                  </li>
                  <li>
                    <Link to="/become-a-partner">
                      Become a Partner / Investor
                    </Link>
                  </li>
                </ul>
              </Col>

              <Col md={6}>
                <h4 className={classes.menu_title}>Resources</h4>
                <ul className={classes.menu_list}>
                  <li>
                    <Link to="/faq">FAQs</Link>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/user/tonyelumelufdn"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Webinars
                    </a>
                  </li>
                  <li>
                    {/* <Link>Case Studies</Link> */}
                    <a
                      href="https://www.tonyelumelufoundation.org/success-stories"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Success Stories
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://tonyelumelufoundation.org/mentors"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Mentorship
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://marketplace.tefconnect.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Marketplace
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </ClickOutside>
      ) : // </OutsideClick>
      null}
    </>
  );
};

export default AboutMenu;
