import React, { useEffect, useState } from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const useOnboarded = () => {
  const [hasProfile, setHasProfile] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const user = get_user_info_from_token()?.user;
    if (user) {
      setLoadingProfile(false);
      if (typeof user.role === "string" || Array.isArray(user.role)) {
        const onboarding =
          user.role.includes("on-boarding") || user.role.includes("onboarding");
        setHasProfile(!onboarding);
      }
    } else {
      history.replace("/login", {});
      history.go(1);
    }
  }, []);

  return { hasProfile, loadingProfile };
};

/** Shows the given component if user is already onboarded, otherwise goes to onboarding. */
export const ifOnboardedThenShow = (Comp) => (props) => {
  const user = useOnboarded();
  if (!user.loadingProfile) {
    if (user.hasProfile) {
      return <Comp {...props} />;
    } else {
      props.history.replace("/onboarding", {});
      props.history.go(1);
    }
  }
  return (
    <div className="loading_spinner">
      <Loader
        type="ThreeDots"
        color="red"
        height={100}
        width={100}
        // timeout={3000} //3 secs
      />
    </div>
  );
};

/** Shows the given component if user is not yet onboarded, otherwise goes to home. */
export const ifNotOnboardedThenShow = (Comp) => (props) => {
  const user = useOnboarded();
  if (!user.loadingProfile) {
    if (!user.hasProfile) {
      return <Comp {...props} />;
    } else {
      props.history.replace("/home", {});
      props.history.go(1);
    }
  }
  return (
    <div className="loading_spinner">
      <Loader
        type="ThreeDots"
        color="red"
        height={100}
        width={100}
        // timeout={3000} //3 secs
      />
    </div>
  );
};
// this may look like  duplicate code above, i just needed to seperate both to avoid too much logic in one
