import React, { useState, useEffect } from "react";
import { getPaticipationAgreement } from "src/requests/TrainingRequest";
import Loader from "react-loader-spinner";

const FlagshipTC = () => {
  const [content, setContent] = useState(null);
  const [loadingContent, setLoadingContent] = useState(false);

  const query = new URLSearchParams(location.search);

  const _getPaticipationAgreement = () => {
    setLoadingContent(true);
    getPaticipationAgreement(query.get("e"), "en", "terms-conditions").then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          const content =
            res.data.data &&
            res.data.data.length > 0 &&
            res.data.data[0].content;
          if (content) {
            setContent(content);
          }
        }
        setLoadingContent(false);
      }
    );
  };

  useEffect(() => {
    _getPaticipationAgreement();
  }, []);

  return (
    <div>
      {loadingContent && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "inherit" }}
        >
          <Loader height={30} width={30} type="Oval" color="var(--tef-red)" />
        </div>
      )}
      {!loadingContent && content && (
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      )}
    </div>
  );
};

export default FlagshipTC;
