import React, { useState, useEffect, useContext } from "react";
import Layout from "src/components/Layout/Layout";
import RightBar from "src/modules/profile/components/RightBar/RightBar";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import { BannerCarousel } from "src/modules/Opportunity/components/Banner/Banner";
import "../components/common.scss";
import OpportunityList from "src/modules/Opportunity/components/List/List";
import {
  getOpportunityCategories,
  getOpportunityTypes,
  getOpportunities,
} from "src/requests/OpportunityRequest";
import { Context } from "src/store";
import countryList from "src/utils/countryList.json";
import {
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useTranslation } from "react-i18next";

const OpportunityHome = (props) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Context);

  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  const [defaultParams, setDefaultParams] = useState({
    country: "",
    opportunityTypeId: "",
    categoryId: "",
  });

  useEffect(() => {
    const _getBasicData = () => {
      getOpportunities(dispatch, {});
      getOpportunityCategories(dispatch);
      getOpportunityTypes(dispatch);
    };
    _getBasicData();
  }, []);

  const _onSelectFilter = (e) => {
    setDefaultParams({ ...defaultParams, [e.target.name]: e.target.value });
  };

  // console.log(defaultParams);

  useEffect(() => {
    getOpportunities(dispatch, defaultParams);
  }, [defaultParams]);

  const categories =
    state.Opportunities.categories && state.Opportunities.categories;
  const types = state.Opportunities.types && state.Opportunities.types;

  const list = state.Opportunities.opportunities;
  const verifiedOpportunities = list.filter(
    (verifiedOpportunity) => verifiedOpportunity.isVerified
  );

  return (
    <Layout SideContent={<RightBar />}>
      <div className="opp-home-heading">
        <h1>{t("Opportunities")}</h1>
        {/* <Link to={`${window.location.pathname}/create`} className="link-create">
          <span>Add Opportunities</span>
        </Link> */}
        <div>
          <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret>{t("Manage Opportunities")}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() =>
                  props.history.push(`${window.location.pathname}/create`)
                }
              >
                {t("Add New")}
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() =>
                  props.history.push(
                    `${window.location.pathname}/my-opportunities`
                  )
                }
              >
                {t("My Opportunities")}
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </div>
      </div>
      <div className="opp">
        <BannerCarousel />

        <div className="opp-tab-navs">
          <span className="opp-tabs-title">{t("Filter by")}:</span>
          <Form>
            <Row form>
              <Col md={4}>
                <FormGroup>
                  <Label for="categoryId" className="label-dp">
                    {t("Category")}
                  </Label>
                  <Input
                    type="select"
                    name="categoryId"
                    id="categoryId"
                    onChange={_onSelectFilter}
                    value={defaultParams.categoryId}
                  >
                    <option value="">{t("All")}</option>
                    {categories &&
                      categories.map((cat) => {
                        return (
                          <option key={cat.id} value={cat.id}>
                            {cat.name}
                          </option>
                        );
                      })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup className="st-dp">
                  <Label for="country" className="label-dp">
                    {t("Country")}
                  </Label>
                  <Input
                    type="select"
                    name="country"
                    id="country"
                    onChange={_onSelectFilter}
                    value={defaultParams.country}
                    className="st-dp"
                  >
                    <option value="">{t("All")}</option>
                    {countryList &&
                      countryList.map((country) => {
                        return (
                          <option
                            value={country.countryShortCode}
                            key={country.countryShortCode}
                          >
                            {country.countryName}
                          </option>
                        );
                      })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="opportunityTypeId" className="label-dp">
                    {t("Type")}
                  </Label>
                  <Input
                    type="select"
                    name="opportunityTypeId"
                    id="opportunityTypeId"
                    onChange={_onSelectFilter}
                    value={defaultParams.opportunityTypeId}
                  >
                    <option value="">{t("All")}</option>
                    {types &&
                      types.map((type) => {
                        return (
                          <option key={type.id} value={type.id}>
                            {type.name}
                          </option>
                        );
                      })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>
        <OpportunityList list={verifiedOpportunities} />
      </div>
    </Layout>
  );
};

export default ifOnboardedThenShow(OpportunityHome);
