import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import {
  retrieveUserProfile,
  getWeeklySurveyQuestions,
} from "src/modules/mentorship/components/helper";
import Avatar from "src/components/Avatar/Avatar";
import SurveyModal from "src/modules/mentorship/components/SurveyModal/SurveyModal";
import Empty from "src/assets/img/mentorship-empty.png";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const UserCard = ({ userId, isMentor }) => {
  const [userInfo, setUserInfo] = useState({});
  const [survey, setSurvey] = useState(false);
  const [surveyQuestion, setSurveyQuestion] = useState();
  const user = get_user_info_from_token().user;

  const audience = !isMentor ? "mentee" : "mentor";
  useEffect(() => {
    retrieveUserProfile(userId)
      .then((res) => {
        if (res.status && res.status === 200) {
          setUserInfo(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [userId]);

  useEffect(() => {
    getWeeklySurveyQuestions(
      userId,
      audience,
      isMentor ? user["tef-mentor"][1] : user["tef-mentee"][1]
    )
      .then((res) => {
        if (res.status && res.status === 200) {
          const questions = res.data.data.find(
            (question) => (question.frequency = "WEEKLY")
          );
          setSurveyQuestion(questions);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [userId]);

  const showSurveyModal = () => {
    setSurvey(!survey);
  };

  return (
    <div className="mb-3 px-1">
      {surveyQuestion && surveyQuestion.length !== 0 && (
        <Card>
          <CardBody>
            <Row>
              <Col md={1} sm={1} xs={1}>
                <Avatar
                  dimension={50}
                  name={`${userInfo && userInfo.firstName} ${
                    userInfo && userInfo.lastName
                  }`}
                  src={userInfo && userInfo.imageUrl}
                />
              </Col>
              <Col md={5} sm={5} xs={5} className="mt-3 ml-2">
                <p>
                  {userInfo && userInfo.firstName}{" "}
                  {userInfo && userInfo.lastName}
                </p>
              </Col>
              <Col md={5} sm={5} xs={5} className="mt-2 float-right">
                <Row className="float-right">
                  <Button
                    style={{ padding: "5px", display: "inline" }}
                    onClick={showSurveyModal}
                    className="mr-1 px-4"
                  >
                    Rate
                  </Button>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
      {surveyQuestion && surveyQuestion.length === 0 && (
        <Col
          style={{ textAlign: "center", marginTop: "5px" }}
          md={{ size: 6, offset: 3 }}
        >
          <img alt="empty" src={Empty} />
          <h3 className="goals-heading">No Survey To Show</h3>
          <br />
          <br />
        </Col>
      )}

      {surveyQuestion && (
        <SurveyModal
          survey={survey}
          setSurvey={setSurvey}
          questions={surveyQuestion}
          setQuestions={setSurveyQuestion}
          respondedToUserId={userId}
          userInfo={userInfo}
        />
      )}
    </div>
  );
};

UserCard.propTypes = {};

export default UserCard;
