import React, { Fragment } from "react";
import ContentLoader from "react-content-loader";
import shortid from "shortid";

export const ReactContentLoader = () => {
  return (
    <span
      style={{
        backgroundColor: "white",
        margin: "8px",
        height: "250px",
        width: "280px",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "10px",
        }}
      >
        <ContentLoader
          height={140}
          width={250}
          speed={2}
          viewBox="0 0 400 160"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="10" rx="3" ry="3" width="450" height="250" />
        </ContentLoader>
      </div>
    </span>
  );
};

const loaders = Array(3).fill();
export const showLoading = () =>
  loaders.map((loader) => (
    <Fragment key={shortid.generate()}>
      <ReactContentLoader />
    </Fragment>
  ));
