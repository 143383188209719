import React from "react";
import classes from "./GalleryImage.module.scss";

const GalleryImage = ({ src, alt }) => {
  return (
    <div className={classes["gallery-image__wrapper"]}>
      <img src={src} alt={alt} className="img-fluid" />
    </div>
  );
};

export default GalleryImage;
