import DOMPurify from "dompurify";
import React from "react";
import "./checkMarkerStyles.scss";

export const CheckMarker = ({
  label,
  value,
  name,
  className,
  onChange,
  checked,
}) => {
  return (
    <label className={`checker ${className}`}>
      <span
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(label) }}
      ></span>
      <input
        type="radio"
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        checked={checked}
      />
      <span className="checkmark"></span>
    </label>
  );
};
