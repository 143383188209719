import axios from "src/utils/axios";
import { toast } from "react-toastify";
import { asyncActions } from "src/utils/AsyncUtils";
import { FeedbackURL } from "src/constants";
import { SEND_FEEDBACK, SEND_DATA_ACCESS_RIGHT } from "src/actions";
import { env } from "src/constants/env";

export const sendFeedback = (dispatch, payload) => {
  dispatch(asyncActions().loading(true));
  return axios
    .post(FeedbackURL.SEND_FEEDBACK_URL, payload)
    .then((response) => {
      dispatch(asyncActions(SEND_FEEDBACK).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(SEND_FEEDBACK).success(response.data));
        dispatch(asyncActions(SEND_FEEDBACK).loading(false));
        toast.success("Thank you for your feedback");
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(SEND_FEEDBACK).loading(false));
      dispatch(asyncActions(SEND_FEEDBACK).failure(true, error.response));
      toast.error("Something went wrong, Please try again ");
      return error.response;
    });
};

export const sendDataAccessRight = (dispatch, payload) => {
  dispatch(asyncActions().loading(true));
  return axios
    .post(FeedbackURL.SEND_DATA_ACCESS_RIGHT_URL, payload, {
      auth: {
        username: env.REACT_APP_CLIENT_ID,
        password: env.REACT_APP_CLIENT_SECRET,
      },
    })
    .then((response) => {
      dispatch(asyncActions(SEND_DATA_ACCESS_RIGHT).loading(false));
      if (response.status === 201) {
        dispatch(asyncActions(SEND_DATA_ACCESS_RIGHT).success(response.data));
        dispatch(asyncActions(SEND_DATA_ACCESS_RIGHT).loading(false));
        toast("Sent successfully");
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(SEND_DATA_ACCESS_RIGHT).loading(false));
      dispatch(
        asyncActions(SEND_DATA_ACCESS_RIGHT).failure(true, error.response)
      );
      toast("Something went wrong, Please try again ");
      return error.response;
    });
};

export const contact_us = (data) => {
  return axios({
    url: FeedbackURL.CONTACT_US,
    data,
    method: "post",
  });
};
