import React from "react";

const ProductTemplate = ({ product }) => {
  return (
    <div>
      <h4 className="text-center font-weight-bold mb-5">
        The Products/Service
      </h4>
      <p>
        <span className="mr-4">2.1 </span> DESCRIPTIONS OF PRODUCT(S) OR
        SERVICE(S)
      </p>
      <p className="ml-5">
        {product.description === ""
          ? "[DESCRIPTION OF PRODUCTS OR SERVICES]"
          : product.description}
      </p>
      <p className="ml-5">[INSERT PHOTOS OF PRODUCT(S)]</p>
    </div>
  );
};

export default ProductTemplate;
