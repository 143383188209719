import React from "react";
import { Container, Row, Col } from "reactstrap";

import "./GeneralLayout.scss";

const GeneralLayout = ({ sidebar, children, rightSideRar }) => {
  return (
    <Container fluid className="application-layout notranslate">
      <Row>
        <Col lg="2" className="col1">
          {sidebar}
        </Col>
        <Col lg="7" className="col2">
          {children}
        </Col>
        <Col lg="3" className="col3 d-none d-lg-block">
          {rightSideRar}
        </Col>
      </Row>
    </Container>
  );
};

export default GeneralLayout;
