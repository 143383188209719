import React from "react";
import { Col } from "reactstrap";
import { hasDecimal } from "src/utils/helper";
import styles from "./assessment_summary.module.scss";

const CourseSummaryCard = ({ score, title }) => {
  return (
    <Col md={3} title="Course Summary" className={styles.course_summary_card}>
      <div className={styles.content_container}>
        <h3 className={styles.score}>
          {hasDecimal(score) ? Number(score).toFixed(1) : score}
        </h3>
        <span className={styles.title}>{title}</span>
      </div>
    </Col>
  );
};

export default CourseSummaryCard;
