import React, { Fragment, useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { Row, Col, Popover, PopoverBody } from "reactstrap";
import "./Title.scss";
// import { useHistory } from "react-router-dom";
// import AllArticles from "src/modules/Discover/components/Articles/AllArticles";

const Title = ({
  name,
  nameLength,
  seeAll,
  seeAllAction,
  menuTitle,
  rightTitle,
  handlePage,
  showFilterOptions,
  sortBy,
}) => {
  const [popOverOpen, setPopoverOpen] = useState(false);
  const handlefilterOptions = () => setPopoverOpen(!popOverOpen);
  return (
    <div className="all-modules">
      <Row>
        {menuTitle !== undefined ? (
          <Fragment>
            <Col md={8}>
              <h1>
                {name}
                <span className="all-modules__length"> ({nameLength})</span>
              </h1>
            </Col>
            <Col md={4}>
              <span
                className="text-center submit-tef-story p-2 mr-4"
                onClick={handlePage}
              >
                {rightTitle}
              </span>
              {showFilterOptions && (
                <>
                  <span id="filterOptions">
                    <FiMoreHorizontal />
                  </span>
                  <Popover
                    trigger="legacy"
                    placement="bottom"
                    target="filterOptions"
                    toggle={handlefilterOptions}
                    isOpen={popOverOpen}
                  >
                    <PopoverBody
                      style={{
                        width: "10rem",
                        padding: "0",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          fontWeight: "600",
                          borderBottom: "solid #eee 1px",
                          paddingBottom: "0.5rem",
                          marginBottom: "0.5rem",
                        }}
                      >
                        Sort by:
                      </div>
                      <div
                        className="MoreTopOptions"
                        onClick={() => sortBy("author")}
                      >
                        Author
                      </div>
                      <div
                        className="MoreTopOptions"
                        onClick={() => sortBy("category")}
                      >
                        Category
                      </div>
                      <div
                        className="MoreTopOptions"
                        onClick={() => sortBy("trending")}
                      >
                        Most Viewed
                      </div>
                      <div
                        className="MoreTopOptions"
                        onClick={() => sortBy("createdOn")}
                      >
                        Date Created
                      </div>
                    </PopoverBody>
                  </Popover>
                </>
              )}
            </Col>
          </Fragment>
        ) : (
          <Fragment>
            <Col md={10}>
              <h1>
                {name}
                <span className="all-modules__length">({nameLength})</span>
              </h1>
            </Col>
            <Col md={2}>
              <span className="see-all mr-3">
                <u onClick={seeAllAction}>{seeAll}</u>
              </span>
              {showFilterOptions && (
                <>
                  <span id="filterOptionstype2">
                    <FiMoreHorizontal />
                  </span>
                  <Popover
                    trigger="legacy"
                    placement="bottom"
                    target="filterOptionstype2"
                    toggle={handlefilterOptions}
                    isOpen={popOverOpen}
                  >
                    <PopoverBody
                      style={{
                        width: "10rem",
                        padding: "0",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          fontWeight: "600",
                          borderBottom: "solid #eee 1px",
                          paddingBottom: "0.5rem",
                          marginBottom: "0.5rem",
                        }}
                      >
                        Sort by:
                      </div>
                      <div
                        className="MoreTopOptions"
                        onClick={() => sortBy("author")}
                      >
                        Author
                      </div>
                      <div
                        className="MoreTopOptions"
                        onClick={() => sortBy("category")}
                      >
                        Category
                      </div>
                      <div
                        className="MoreTopOptions"
                        onClick={() => sortBy("trending")}
                      >
                        Most Viewed
                      </div>
                      <div
                        className="MoreTopOptions"
                        onClick={() => sortBy("createdOn")}
                      >
                        Date Created
                      </div>
                    </PopoverBody>
                  </Popover>
                </>
              )}
            </Col>
          </Fragment>
        )}
      </Row>
    </div>
  );
};

export default Title;
