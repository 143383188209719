import React, { useState, useContext } from "react";
import { Context } from "src/store";
import ShareInput from "src/modules/mentorship/components/ShareInput/ShareInput";
import { Col } from "reactstrap";
import {
  getDiscussionReplies,
  postDiscussionReply,
  likeOrUnlikeReply,
} from "src/requests/discussion";
import ContentLoader from "react-content-loader";
import shortid from "shortid";
import Empty from "src/assets/img/mentorship-empty.png";
import DiscussionChat from "./DiscussionChat";

import "./Discussions.scss";

const Loader = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "10px",
        // border: "1px solid #c4cdd5",
        borderRadius: "10px",
        margin: "8px",
        width: "100%",
      }}
      className="loader-item"
    >
      <ContentLoader
        speed={2}
        // width="100%"
        height={160}
        viewBox="0 0 400 160"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
        <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
        <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
        <circle cx="20" cy="20" r="20" />
      </ContentLoader>
    </div>
  );
};

const showLoading = () => {
  const loaders = [];
  for (let i = 0; i <= 20; i++) {
    loaders.push(<Loader key={shortid.generate()} />);
  }

  return loaders;
};

const ReplayChat = ({ discussion, activeGoal }) => {
  const [note, setNote] = useState("");
  const { state, dispatch } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [clearContent, setClearContent] = useState(false);

  // useEffect(() => {
  //   if (selectedDiscussion === discussion.id) {
  //     getDiscussionReplies(dispatch, discussion.id, true);
  //   }
  // }, []);

  // useEffect(() => {
  //   getDiscussionReplies(dispatch, discussion.id, true);
  // }, []);

  const _onChange = (value) => {
    setNote(value);
  };

  const _submit = () => {
    const replyObj = {
      goalId: activeGoal.id,
      discussionId: discussion.id,
      note,
    };
    setLoading(true);
    setClearContent(true);
    postDiscussionReply(dispatch, replyObj)
      .then((res) => {
        if (res.status === 200) {
          getDiscussionReplies(dispatch, discussion.id, false);
        }
      })
      // .then(() => {
      //   // setTimeout(() => {
      //     getDiscussionDetails(dispatch, discussion.id, false);
      //   // }, 1000);
      // })
      .then(() => {
        setLoading(false);
      });
  };

  const _onLikeClick = (replyId) => {
    likeOrUnlikeReply(dispatch, replyId);
  };

  return (
    <div className="reply-container">
      <div className="reply-container__input">
        <h6>Replies</h6>
        <ShareInput
          value={note}
          onChange={_onChange}
          onSumit={_submit}
          clearContent={clearContent}
          setClearContent={setClearContent}
          // files={[1]}
          loading={loading}
        />
      </div>
      {state.Discussions.requestingReplies && <>{showLoading()}</>}

      <div className="reply-container__replies">
        {state.Discussions.replies && state.Discussions.replies.length > 0 ? (
          state.Discussions.replies.map((reply, i) => {
            return (
              <DiscussionChat
                key={reply.id}
                data={reply}
                onLikeClick={() => {
                  _onLikeClick(reply.id);
                }}
                senderId={reply.createdBy}
                senderDetails={reply.otherDetails}
              />
            );
          })
        ) : (
          <Col
            style={{ textAlign: "center", marginTop: "20px" }}
            md={{ size: 6, offset: 3 }}
          >
            <img src={Empty} />
            <h3 className="goals-heading">No Replies</h3>
            <br />
            <br />
          </Col>
        )}
      </div>
    </div>
  );
};

const Reply = ({ discussion, activeGoal, selectedDiscussion }) => {
  const showReply = () => {
    if (selectedDiscussion === discussion.id) {
      return (
        <ReplayChat
          discussion={discussion}
          activeGoal={activeGoal}
          // selectedDiscussion={selectedDiscussion}
        />
      );
    }
    return null;
  };

  return <>{showReply()}</>;
};

export default Reply;
