import axios from "src/utils/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CREATE_TOPIC,
  GET_ALL_TOPICS,
  GET_TOPIC_POSTS,
  GET_TOPIC_DETAILS,
  DELETE_TOPIC,
  LIKE_TOPIC,
} from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import { TopicURL } from "src/constants";

export const createTopic = (dispatch, payload) => {
  dispatch(asyncActions(CREATE_TOPIC).loading(true));
  return axios({
    method: "post",
    url: TopicURL.CREATE_TOPIC_URL,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(CREATE_TOPIC).loading(false));
      if (response.status === 201) {
        const { data } = response.data;
        dispatch(asyncActions(CREATE_TOPIC).success(data));
        dispatch(asyncActions(CREATE_TOPIC).loading(false));
        toast("Topic Created Successfully ✅");
      }
      return response;
    })
    .catch((error) => {
      let errorMessage = "An error occurred, Please try again";
      if (error.response.status < 500) {
        const {
          data: { message },
        } = error.response;
        errorMessage = message;
      }
      dispatch(asyncActions(CREATE_TOPIC).loading(false));
      dispatch(asyncActions(CREATE_TOPIC).failure(true, error.response));
      toast(errorMessage);
      return error.response;
    });
};

export const getAllTopics = (dispatch, payload) => {
  const params = payload;
  dispatch(asyncActions(GET_ALL_TOPICS).loading(true));
  return axios({
    method: "get",
    url: `${TopicURL.GET_ALL_TOPICS_URL}`,
    // url: `${TopicURL.GET_ALL_TOPICS_URL}?categoryId=${payload.categoryId}`,
    params,
  })
    .then((response) => {
      dispatch(asyncActions(GET_ALL_TOPICS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_ALL_TOPICS).success(data));
        dispatch(asyncActions(GET_ALL_TOPICS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_ALL_TOPICS).loading(false));
      dispatch(asyncActions(GET_ALL_TOPICS).failure(true, error.response));
      return error.response;
    });
};

export const fetchTopicDetails = (dispatch, topicId) => {
  dispatch(asyncActions(GET_TOPIC_DETAILS).loading(true));
  return axios({
    method: "get",
    url: `${TopicURL.GET_ALL_TOPICS_URL}/${topicId}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_TOPIC_DETAILS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_TOPIC_DETAILS).success(data));
        dispatch(asyncActions(GET_TOPIC_DETAILS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_TOPIC_DETAILS).loading(false));
      dispatch(asyncActions(GET_TOPIC_DETAILS).failure(true, error.response));
      return error.response;
    });
};

export const fetchTopicPosts = (dispatch, topicId) => {
  dispatch(asyncActions(GET_TOPIC_POSTS).loading(true));
  return axios({
    method: "get",
    url: `${TopicURL.GET_ALL_TOPICS_URL}/${topicId}/posts`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_TOPIC_POSTS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_TOPIC_POSTS).success(data));
        dispatch(asyncActions(GET_TOPIC_POSTS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_TOPIC_POSTS).loading(false));
      dispatch(asyncActions(GET_TOPIC_POSTS).failure(true, error.response));
      return error.response;
    });
};

export const deleteTopic = (dispatch, id) => {
  dispatch(asyncActions(DELETE_TOPIC).loading(true));
  return axios({
    method: "delete",
    url: `${TopicURL.DELETE_TOPIC_URL}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(DELETE_TOPIC).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(DELETE_TOPIC).success(data));
        dispatch(asyncActions(DELETE_TOPIC).loading(false));
      }
      return response;
    })
    .catch((error) => {
      const {
        data: { message },
      } = error.response;
      const fail = "An error occurred, Please try again ";
      dispatch(asyncActions(DELETE_TOPIC).loading(false));
      dispatch(asyncActions(DELETE_TOPIC).failure(true, error.response));
      toast.error(message || fail);
      return error.response;
    });
};

export const likeTopic = (dispatch, payload) => {
  const { topicId, userId } = payload;
  dispatch(asyncActions(LIKE_TOPIC).loading(true));
  return axios({
    method: "post",
    url: TopicURL.LIKE_TOPIC_URL,
    data: {
      topicId,
      userId,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(LIKE_TOPIC).success(response.data));
        dispatch(asyncActions(LIKE_TOPIC).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(LIKE_TOPIC).failure(error));
      return error.response;
    });
};
