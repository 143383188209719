import React from "react";
import jwt from "jsonwebtoken";
import axios from "axios";
import JwtDecode from "src/utils/jwt-decode";
import { env } from "src/constants/env";

const secret = "iuysjmkcjbhf984itueybnmnfd8940i9843ygeufbdns";

export const retrieveUserTempProfile = async () => {
  let profile;
  try {
    const res = await axios.get(`${env.REACT_APP_CORE_BASE_URL}/profile/temp`);
    const { data } = res;
    const decoded = JwtDecode(data.data.data);
    profile = JSON.parse(decoded.profile);
  } catch (e) {
    console.log(e.response);
    if (e.response && e.response.data) {
      profile = e.response.data.data;
    }
  }
  return profile;
};

export const storeOnboardingProgress = async (userInfo) => {
  let status;
  const token = jwt.sign({ profile: userInfo }, secret);
  try {
    const res = await axios.patch(
      `${env.REACT_APP_CORE_BASE_URL}/profile/temp`,
      {
        data: token,
      }
    );
    const { data } = res;
    status = data.status;
  } catch (e) {
    console.log(e.response);
    status = e.response.data.status;
  }
  return status;
};

const numbers = [
  "yearsOfExperience",
  "businessRevenue",
  "recommendationLevel",
  "maxNoOfEntrepreneurs",
  "teamSize",
  "hoursPerWeekInvested",
  "monthlyIncome",
  "totalPrice",
];
const boolean = [
  "isATefMentor",
  "isAvailableForMentoring",
  "isAvailableForOneYear",
];
const dateTime = ["dateOfBirth"];

export const CastValue = (target, value) => {
  if (numbers.indexOf(target) >= 0) {
    return Number(value);
  } else if (boolean.indexOf(target) >= 0) {
    return value.toLowerCase() === "true";
  } else if (dateTime.indexOf(target) >= 0) {
    // console.log(value);
    return value;
    // const date = new Date(value).toUTCString();
    // console.log(date);
  } else {
    return value;
  }
};

export const displayError = (error) => {
  // const { t } = useTranslation();
  return (
    <span
      style={{
        color: "red",
        marginTop: "-5px",
        marginLeft: "10px",
        fontFamily: "Poppins",
        fontSize: "12px",
      }}
    >
      {error}
    </span>
  );
};

export const getRanndomColor = (colors) => {
  if (colors.length > 0) {
    return colors[Math.floor(Math.random() * colors.length)];
  }
};

export const filterListOnSearch = (list, filterItem, field) => {
  if (filterItem.length === 0) {
    return [];
  }
  return list
    .filter((item) => {
      return item[field].toLowerCase().startsWith(filterItem.toLowerCase());
    })
    .sort((a, b) => {
      return a[field].toLowerCase().localeCompare(b[field].toLowerCase());
    });
};

export const retrieveValueFromId = (list, filter, fieldName) => {
  return list.find((item) => {
    return item.id === filter;
  })[fieldName];
};

export const isCheckedCheckBox = (list, filter) => {
  return list.includes(filter);
};

export const isCheckedRadio = (target, filter) => {
  return target === filter;
};

export const isExistingSearchedItem = (list, filterItem, field) => {
  const result = list.find((item) => {
    return item[field].toLowerCase() === filterItem.toLowerCase();
  });

  return result;
};
export const _scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

export class Validator {
  /**
   *Creates an instance of Validator.
   * @param {*} type
   * @memberof Validator
  /**
   *Creates an instance of Validator.
   * @param {String} type
   * @param {Array} fields
   * @param {Object} object
   * @memberof Validator
   */
  constructor(type, fields, object) {
    this.hasError = false;
    this.fieldsWithErrors = [];
    this.type = type;
    this.fields = fields;
    this.object = object;
  }

  validateArrayInputs() {
    if (this.object !== undefined && typeof this.object === "object") {
      const extractedFields = this.extractFields(this.fields, this.object);
      for (const key in extractedFields) {
        if (extractedFields[key].length === 0) {
          this.fieldsWithErrors.push(key);
        }
      }
      return this.fieldsWithErrors;
    }
  }

  validateStringInput() {
    if (this.object !== undefined && typeof this.object === "object") {
      const extractedFields = this.extractFields(this.fields, this.object);
      for (const key in extractedFields) {
        if (extractedFields[key] === "" || extractedFields[key] === null) {
          this.fieldsWithErrors.push(key);
        }
      }
      return this.fieldsWithErrors;
    }
  }

  validateGeneralError() {
    if (this.object !== undefined && typeof this.object === "object") {
      const extractedFields = this.extractFields(this.fields, this.object);
      for (const key in extractedFields) {
        if (
          extractedFields[key] !== null
          // extractedFields[key].length !== 0 ||
          // extractedFields[key] !== undefined
        ) {
          this.fieldsWithErrors.push(key);
        }
      }
      return this.fieldsWithErrors;
    }
  }

  extractFields(fields, object) {
    const filtered = fields.reduce(
      (obj, key) => ({ ...obj, [key]: object[key] }),
      {}
    );
    return filtered;
  }

  validate() {
    switch (this.type) {
      case "array":
        return this.validateArrayInputs();
      case "string":
        return this.validateStringInput();
      default:
        return this.validateGeneralError();
    }
  }
}
