import React, { useEffect, useContext, useState } from "react";
import { Col, Row, Button } from "reactstrap";
import Interest from "./Interest";
import "./Interests.scss";
import { getInterests } from "src/requests/InterestRequest";
import { Context } from "src/store";
import Loader from "react-loader-spinner";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { addInterest, deleteUserInterest } from "src/requests/ProfileRequest";

import createPersistedState from "use-persisted-state";
const useInterestState = createPersistedState("interestsList");

const Interests = () => {
  const { state, dispatch } = useContext(Context);
  const [userInterest, setUserInterest] = useState([]);
  const [selectedInterest, setSelectInterest] = useState(null);
  const [maxLengthToShow, setMaxLengthToShow] = useState(10);
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [interestList, setInterestList] = useInterestState([]);

  const user = useCurrentUser();

  const sortUserInterest = () => {
    const sorted = user.userInterests?.map((i) => {
      return {
        userInterestId: i.id,
        interestId: i.interest.id,
      };
    });
    setUserInterest(sorted);
  };

  useEffect(() => {
    if (!interestList || interestList.length < 1) {
      getInterests(dispatch);
    }
  }, []);

  useEffect(() => {
    sortUserInterest();
  }, [user]);

  const _isFollowing = (id) => {
    return userInterest.findIndex((i) => i.interestId === id) >= 0;
  };

  const _onFollowClick = (data) => {
    const { userInterestId, id, isFolllowing } = data;
    setSelectInterest(id);
    if (!isFolllowing) {
      addInterest(dispatch, [{ interestId: id }]).then(() => {
        setSelectInterest(null);
      });
    } else {
      if (userInterestId) {
        deleteUserInterest(dispatch, [{ userInterestId }]).then(() => {
          setSelectInterest(null);
        });
      }
    }
  };

  useEffect(() => {
    if (
      state.Interests &&
      state.Interests.interests &&
      state.Interests.interests.length > 0
    ) {
      const interests = state.Interests.interests;
      setInterestList(interests);
    }
  }, [state.Interests.interests]);

  const displayInterest = () => {
    const interests = interestList && interestList;
    const maxLength = maxLengthToShow;
    const sliced = interests.slice(0, maxLength);
    return sliced.map((interest, i) => {
      return (
        <Interest
          key={i}
          name={interest.name}
          id={interest.id}
          followers={interest.followers}
          onFollowClick={_onFollowClick}
          userInterest={userInterest.find((i) => i.interestId === interest.id)}
          isFolllowing={_isFollowing(interest.id)}
          selected={selectedInterest}
        />
      );
    });
  };

  const onShowMoreClick = () => {
    const originalLength = interestList.length;
    const defaultMaxLength = 10;
    // let maxLength = maxLengthToShow;
    const shouldShowMore = !isShowingMore;
    shouldShowMore
      ? setMaxLengthToShow(originalLength)
      : setMaxLengthToShow(defaultMaxLength);
    setIsShowingMore(shouldShowMore);
  };

  return (
    <>
      {state.Interests.requestingInterests ? (
        <div className="interest-spinner-w">
          <Loader type="Oval" height={20} width={20} color="red" />
        </div>
      ) : (
        <Col className="interests-wrapper">
          <Col>
            <Row>{displayInterest()}</Row>
          </Col>
          <Col>
            <Button
              className="show-more-interest-btn"
              onClick={onShowMoreClick}
            >
              {isShowingMore ? "Show less" : "show more"}
            </Button>
          </Col>
        </Col>
      )}
    </>
  );
};

Interests.propTypes = {};

export default Interests;
