import React, { useEffect, useState } from "react";
// import Loader from "react-loader-spinner";
import { getManyUserLightProfile } from "src/requests/ProfileRequest";
import {
  getGroupRequests,
  studyGroupAcceptInvite,
  studyGroupCancelInvite,
} from "src/requests/StudyGroupsRequest";
import ResultPagination from "src/modules/training/StudyGroups/components/ResultPagination/ResultPagination";
import RequestItem from "./RequestItem";
import classes from "./Requests.module.scss";
import Skeleton from "react-loading-skeleton";

const SKLoader = ({ count }) =>
  Array(count || 3)
    .fill()
    .map((_, i) => {
      return (
        <React.Fragment key={i}>
          <div className="d-flex align-items-center px-3">
            <div className="mr-2">
              <Skeleton circle={true} height={35} width={35} />
            </div>
            <div>
              <Skeleton width={100} />
            </div>
          </div>
        </React.Fragment>
      );
    });

const GroupRequests = ({ groupId, getGroupUsers }) => {
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [requests, setRequests] = useState([]);
  const [users, setUsers] = useState([]);
  const [paging, setPaging] = useState({
    hasNextPage: false,
    pageNumber: 1,
    pageCount: 0,
    maxItem: 5,
  });
  const [deleted, setDeleted] = useState([]);

  const _getData = (pageNumber) => {
    setLoadingRequest(true);
    getGroupRequests(groupId, "pending_invite", {
      pageNumber,
      maxItem: paging.maxItem,
    })
      .then((res) => {
        if (
          (res.status === 201 || res.status === 200) &&
          res.data &&
          res.data.data
        ) {
          const { data, pager } = res.data.data;
          setPaging({
            ...paging,
            ...pager,
          });
          let uIDs = [];
          if (data.length > 0) {
            setRequests(data);
            setLoadingUsers(true);
            uIDs = data.map((d) => d.inviteeUserId);
          }
          if (uIDs.length > 0) {
            _getProfiles(uIDs);
          }
        }
        setLoadingRequest(false);
      })
      .catch((e) => {
        console.log(e.response);
        setLoadingRequest(false);
      });
  };
  useEffect(() => {
    _getData(paging.pageNumber);
  }, []);

  const _getProfiles = (ids) => {
    getManyUserLightProfile(ids).then((res) => {
      if (res.data && res.data.data && res.data.data.length > 0) {
        setUsers(res.data.data);
      } else {
        setUsers([]);
      }
      setLoadingUsers(false);
    });
  };

  const onPageChange = (value) => {
    value = value.selected + 1;
    _getData(value);
  };

  const mapUserToGroup = (userId) =>
    users.find((u) => u.userId === userId) || {
      firstName: "Anonymous",
      lastName: "Anonymous",
      email: null,
    };

  const _acceptInvite = (inviteId) => {
    studyGroupAcceptInvite(inviteId).then(() => {
      // _getData(paging.pageNumber);
      getGroupUsers();
      setDeleted([...deleted, inviteId]);
    });
  };

  const _rejectInvite = (inviteId, inviteeUserId) => {
    studyGroupCancelInvite(inviteId, inviteeUserId).then(() => {
      // _getData(paging.pageNumber);
      getGroupUsers();
      setDeleted([...deleted, inviteId]);
    });
  };

  return (
    <>
      <div className={classes.groupRequestC}>
        {loadingRequest || loadingUsers ? (
          <SKLoader />
        ) : (
          <>
            {requests.length > 0 ? (
              <>
                {requests.map((data, i) => {
                  if (deleted.includes(data.id)) return null;
                  return (
                    <React.Fragment key={i}>
                      <RequestItem
                        userInfo={mapUserToGroup(data.inviteeUserId)}
                        isSent={data.inviteeUserId !== data.inviteedByUserId}
                        onAccept={_acceptInvite}
                        onReject={_rejectInvite}
                        groupInfo={data}
                      />
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <>
                <span>No Record found</span>
              </>
            )}
          </>
        )}
      </div>
      {(paging.hasNextPage || paging.hasPreviousPage) && (
        <ResultPagination
          pageRange={3}
          pageCount={paging.pageCount}
          current={paging.pageNumber}
          onSelect={onPageChange}
        />
      )}
    </>
  );
};
export default GroupRequests;
