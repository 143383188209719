import React from "react";
import { ListGroupItem, FormGroup, Label, Input } from "reactstrap";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const userId = get_user_info_from_token()?.user?.id;

const Participant = ({
  colorPicker,
  userInfo,
  addToList,
  removeFromList,
  idx,
  showSelectUser = true,
  switchDefaultActiveChat,
}) => {
  const handleSelect = (e) => {
    const { checked, dataset } = e.target;

    if (checked) {
      if (addToList) {
        addToList(dataset.userid);
      }
    } else {
      removeFromList(dataset.userid);
    }
  };
  return (
    <ListGroupItem tag="a" href="#" style={{ border: "none" }}>
      <FormGroup check style={{ paddingLeft: 0 }}>
        <Label check>
          {showSelectUser && (
            <Input
              type="checkbox"
              disabled={userInfo.userId === userId}
              data-userid={userInfo.userId}
              onClick={handleSelect}
              style={{ marginTop: "0.8rem", position: "absolute", right: 0 }}
            />
          )}

          <div
            className="d-flex align-items-center"
            onClick={() => switchDefaultActiveChat(userInfo.userId)}
          >
            <div className="w-30 mr-2">
              {userInfo.imageUrl ? (
                <img
                  src={userInfo.imageUrl}
                  style={{ width: "35px", height: "35px", borderRadius: "50%" }}
                />
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "100%",
                    backgroundColor: colorPicker(idx)?.background,
                    color: colorPicker(idx)?.color,
                  }}
                >
                  <span>{`${userInfo.firstName[0]}${userInfo.lastName[0]}`}</span>
                </div>
              )}
            </div>
            <h4 className="fs-16 user-info-name">{`${userInfo.firstName} ${userInfo.lastName}`}</h4>
          </div>
        </Label>
      </FormGroup>
    </ListGroupItem>
  );
};

export default Participant;
