import React, { useEffect, useState, useContext } from "react";
import classes from "./ToolDetails.module.css";
import { Link, useParams } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import Layout from "src/components/Layout/Layout";
import RightBar from "src/modules/profile/components/RightBar/RightBar";
import Tags from "src/modules/Tools/components/Tags/Tags";
import ToolPoster from "src/modules/Tools/components/ToolPoster/ToolPoster";
import {
  LinkedinShareButton,
  TwitterShareButton,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import TefRedButton from "src/modules/Opportunity/components/Button/TefRedButton";
import {
  getToolDetails,
  postComment,
  increasePopularity,
} from "src/requests/ToolsRequest";
import { Context } from "src/store";
import { toast } from "react-toastify";
import Avatar from "src/components/Avatar/Avatar";
import { useCurrentUser } from "src/utils/Services/currentUser";
import ViewComments from "src/modules/Tools/components/ViewComment/ViewComments";
import { useTranslation } from "react-i18next";
import { getUserLightProfile } from "src/requests/ProfileRequest";

const ToolDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatch } = useContext(Context);

  const [tool, setTool] = useState({});
  const [newComment, setNewComment] = useState("");
  const [reload, setReload] = useState(false);
  const [creator, setCreator] = useState(null);

  useEffect(() => {
    _getToolDetails();
  }, [reload]);

  const currentUser = useCurrentUser();

  const _getToolDetails = () => {
    getToolDetails(dispatch, id)
      .then((res) => {
        if (res.status && res.status === 200) {
          const { data } = res.data;
          const { createdBy } = data;
          // console.log(createdBy);
          setTool(data);
          return createdBy;
        }
      })
      .then((res) => {
        getUserLightProfile(dispatch, [res]).then((creator) => {
          const {
            data: { data },
          } = creator;
          setCreator(data[0]);
        });
      })
      .catch(() =>
        toast(
          "An error occurred while retrieving the tool details. Try again later"
        )
      );
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    const payload = {
      toolId: id,
      message: newComment,
    };
    postComment(dispatch, payload)
      .then((res) => {
        if (res.status && res.status === 200) {
          toast("Comment Added Successfully ✅");
        }
      })
      .catch(() =>
        toast.error("An error occured while adding comment, try again later")
      );
    setNewComment("");
    setReload(!reload);
  };

  const _increasePopularity = (id) => {
    increasePopularity(dispatch, id).then((res) => {
      const { status } = res.data;
      console.log(status);
    });
  };

  return (
    <Layout SideContent={<RightBar />}>
      <div className={classes.MainContent}>
        <div className="create-opp-breadcrumb">
          <Link to="/home" style={{ fontWeight: "bold" }}>
            {t("Home")} <IoIosArrowRoundForward />
          </Link>
          <Link to="/tools" style={{ fontWeight: "bold" }}>
            {t("Tools")} <IoIosArrowRoundForward />
          </Link>
          <span>{t("Tool Details")}</span>
        </div>
        <div className={classes.TopRow}>
          <div className={classes.Logo}>
            <img
              src={tool && tool.logo}
              alt="Logo"
              className={classes.LogoImage}
            />
          </div>
          <div className={classes.TopRowDetailBody}>
            <div className={`${classes.ToolTitle} notranslate`}>
              {tool && tool.title}
            </div>
            <div className={classes.SubTitle}>{tool && tool.summary}</div>
            <div className={classes.Tags}>
              <Tags tags={tool && tool.categories} />
            </div>
            <div className={classes.Poster}>
              <ToolPoster
                poster={creator && creator}
                datePoster={tool && tool.createdOn}
              />
            </div>
            <div className={classes.ShareOnSocialMedia}>
              <div className={classes.TwitterButton}>
                <TwitterShareButton
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    outline: "none",
                  }}
                  url={tool && tool.url}
                  title={tool && tool.title}
                >
                  <TwitterIcon size={28} /> Tweet
                </TwitterShareButton>
              </div>
              <div className={classes.LinkedButton}>
                <LinkedinShareButton
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    outline: "none",
                  }}
                  url={tool && tool.url}
                  title="test"
                  summary={tool && tool.title}
                >
                  <LinkedinIcon size={28} /> Share
                </LinkedinShareButton>
              </div>
              <div
                className={classes.WebsiteButton}
                onClick={() => _increasePopularity(tool.id)}
              >
                <a href={tool && tool.url} rel="noreferrer" target="_blank">
                  <TefRedButton text="View Website" />
                </a>
              </div>
              <div
                className={classes.MobileWebsiteButton}
                onClick={() => _increasePopularity(tool.id)}
              >
                <a href={tool && tool.url} rel="noreferrer" target="_blank">
                  <TefRedButton text="View Website" />
                </a>
              </div>
            </div>
          </div>
          {/* <div
            className={classes.WebsiteButton}
            onClick={() => _increasePopularity(tool.id)}
          >
            <a href={tool && tool.url} rel="noreferrer" target="_blank">
              <TefRedButton text="View Website" />
            </a>
          </div> */}
        </div>
        <div className={classes.BottomRow}>
          <div className={classes.SpaceLeft}></div>
          <div className={classes.DescriptionContent}>
            <div className={classes.DescriptionTitle}>Description</div>
            <div className={classes.DescriptionBody}>
              {tool && tool.summary}
            </div>
            <hr />
            <div className={classes.CommentTitle}>
              Comments <span className={classes.CommentCount}></span>
            </div>
            <form onSubmit={handleCommentSubmit} className="mb-4">
              <div className={classes.InputComment}>
                <div className={classes.AvatarContainer}>
                  <Avatar
                    dimension={30}
                    name={`${currentUser.firstName} ${currentUser.lastName}`}
                    src={
                      currentUser &&
                      currentUser.imageUrl &&
                      currentUser.imageUrl
                    }
                  />
                </div>
                <div className={classes.EditorBoxContainer}>
                  <textarea
                    placeholder="What do you think about this tool?"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    className={classes.EditorBox}
                  />
                </div>
                {newComment && newComment.trim() !== "" ? (
                  <div
                    className={classes.ShareButton}
                    onClick={handleCommentSubmit}
                  >
                    {t("Share")}
                  </div>
                ) : (
                  <div className={classes.DisabledShareButton}>
                    {t("Share")}
                  </div>
                )}
              </div>
            </form>
            <div className={classes.ViewComments}>
              <ViewComments toolId={id && id} reloadComments={reload} />
            </div>
          </div>
          <div className={classes.SpaceRight}></div>
        </div>
      </div>
    </Layout>
  );
};

export default ToolDetails;
