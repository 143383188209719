const sterilize = (queryObj) => {
  const str = [];
  Object.keys(queryObj).forEach((k) => {
    if (queryObj[k] !== "")
      str.push(encodeURIComponent(k) + "=" + encodeURIComponent(queryObj[k]));
  });
  return str.join("&");
};

export default sterilize;
