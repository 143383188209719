import React, { useState, useContext } from "react";
// import "./Mentor.scss";
import { MdMoreVert, MdDelete } from "react-icons/md";
import { _truncateText } from "src/modules/mentorship/components/helper";
import { Context } from "src/store";
import { useHistory } from "react-router-dom";
import shortid from "shortid";
import Loader from "react-loader-spinner";
import ReactAvatar from "react-avatar";
import { useOuterClick } from "src/utils/helper";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  Label,
} from "reactstrap";
import {
  cancelConnection,
  // getConnections,
} from "src/requests/ConnectionRequest";
import { MoreInterestOption } from "src/modules/mentorship/components/Mentor/ConnectionCard";
import { AiOutlineMessage } from "react-icons/ai";
import Swal from "sweetalert2";
import sendMessage from "src/utils/NewMessageHelper";

// const getRequestId = (userId, requests) => {
//   const req = requests.find((r) => {
//     return r.namedId === userId;
//   });
//   return req.requestId;
// };

const MoreOptions = ({ showOption, onRemoveConnection }) => {
  const ref = useOuterClick(() => {
    showOption(false);
  });
  return (
    <div className="connection-card-more-option" ref={ref}>
      <span style={{ cursor: "pointer" }} onClick={onRemoveConnection}>
        <span>
          <MdDelete />
        </span>
        Remove Connection
      </span>
    </div>
  );
};

const ConnectionCard = ({
  userId,
  isConnected,
  connectionStatus,
  onConnect,
  loadingSendingRequest,
  userDetails,
  connectionId,
  id,
  showOptionDD,
  showChatIcon,
  refreshConnection,
}) => {
  // const [userInfo, setUserInfo] = useState({});
  const { state, dispatch } = useContext(Context);
  const [showMoreOption, setShowMoreOption] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [reasonForDisconnecting, setReasonForDisconnecting] = useState("");
  const [toggleReason, setToggleReason] = useState(false);
  const [RemoveBtnTex, setRemoveBtnText] = useState("Disconnect");
  const maxSize = 4;

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleReasonChange = (e) => {
    setReasonForDisconnecting(e.target.value);
  };

  const _toggleReasonForm = () => {
    setToggleReason(!toggleReason);
    setReasonForDisconnecting("");
  };

  const _dispalyInterest = () => {
    let interests = userDetails && userDetails.userInterests;
    if (interests && interests.length > 0) {
      interests = _truncateInterest(0, maxSize, interests);
      return interests.map((interest) => {
        return (
          <span key={shortid.generate()} className="interest-item">
            {interest && interest.interest && interest.interest.name}
          </span>
        );
      });
    } else {
      return <span> Non</span>;
    }
  };

  const _truncateInterest = (start, end, array) => {
    return array.length > maxSize ? array.slice(start, end) : array;
  };

  const _onRemoveConnection = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Hello, Are you sure?",
      text: "You might not be able add this user again",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove connection!",
      cancelButtonText: "No, keep it",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.value) {
        setRemoveBtnText("Disconnecting...");

        cancelConnection(dispatch, connectionId).then((res) => {
          // setDeleteId(null);
          if (refreshConnection) {
            refreshConnection(1, false);
          }
          res.status === 200
            ? Swal.fire({
                title: "Deleted!",
                text: "Successfully removed",
                icon: "success",
                confirmButtonColor: "red",
              })
            : Swal.fire({
                title: "Cancelled",
                text: "Could not remove connection, please try again",
                icon: "error",
                confirmButtonColor: "red",
              });
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Cancelled",
          text: "Action cancelled",
          icon: "error",
          confirmButtonColor: "red",
        });
      }
    });
  };

  const history = useHistory();
  const viewProfile = () => {
    const path = `/profile/${userId}`;
    history.push(path);
  };

  const removeConnectionPrompt = () => {
    return (
      <>
        <Modal isOpen={toggleReason} toggle={_toggleReasonForm}>
          <ModalHeader toggle={_toggleReasonForm}>
            Are you sure you want to remove this connection
          </ModalHeader>
          <ModalBody>
            <Form>
              <Label for="reason">
                State the reason for removing connection
              </Label>
              <Input
                placeholder="Reason for disconnecting"
                onChange={handleReasonChange}
                value={reasonForDisconnecting}
                name="reason"
                id="reason"
                required
              />
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={_onRemoveConnection}
              disabled={reasonForDisconnecting.length === 0}
            >
              <span style={{ display: "flex" }}>
                {RemoveBtnTex}{" "}
                {state.Mentors.loadingRemoveConnection && (
                  <span style={{ marginLeft: "10px" }}>
                    <Loader height={25} width={15} type="Oval" color="white" />
                  </span>
                )}
              </span>
            </Button>
            <Button color="secondary" outline onClick={_toggleReasonForm}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  };

  return (
    <div className={`connection-card-c`}>
      {userDetails &&
      typeof userDetails === "object" &&
      userDetails &&
      Object.keys(userDetails).length > 0 ? (
        <>
          <div className="connection-card-c__heading">
            <div className="connection-card-image">
              {userDetails && userDetails.imageUrl ? (
                <img
                  src={userDetails.imageUrl}
                  className="img-fluid img-style"
                  // width={62}
                />
              ) : (
                <ReactAvatar
                  name={`${userDetails && userDetails.firstName} ${
                    userDetails && userDetails.lastName
                  }`}
                  size={62}
                  round
                />
              )}
            </div>
            <div className="connection-card__buttons">
              {isConnected ? (
                <div className="connect-btn connect-btn-connected">
                  Connected
                </div>
              ) : !isConnected && connectionStatus === "pending" ? (
                <div className="connect-btn connect-btn-pending">Pending</div>
              ) : !isConnected && connectionStatus === "connect" ? (
                <div
                  className="connect-btn connect-btn-connect"
                  onClick={loadingSendingRequest ? () => {} : () => onConnect()}
                >
                  {loadingSendingRequest ? "Requesting..." : "Connect"}
                </div>
              ) : (
                <div
                  className="connect-btn connect-btn-connect"
                  onClick={loadingSendingRequest ? () => {} : () => onConnect()}
                >
                  {loadingSendingRequest ? "Requesting..." : "Connect"}
                </div>
              )}

              {showChatIcon && (
                <div
                  className="cht-c"
                  onClick={() => sendMessage(userId, history)}
                >
                  <AiOutlineMessage color="var(--helper-text-color)" />
                </div>
              )}

              {isConnected && showOptionDD && (
                <MdMoreVert
                  color="#919ba8"
                  className="more-option-btn"
                  onClick={() => setShowMoreOption(!showMoreOption)}
                />
              )}
            </div>
          </div>
          <div className="connection-card-c__name" onClick={viewProfile}>
            <h6>{`${
              userDetails && userDetails.firstName
                ? userDetails.firstName.toUpperCase()
                : ""
            } ${
              userDetails && userDetails.lastName
                ? userDetails.lastName.toUpperCase()
                : ""
            }`}</h6>
          </div>

          <div className="connection-card-c__bio">
            <p className="break-word">
              {_truncateText(
                userDetails && userDetails.bio ? userDetails.bio : "",
                125
              )}
            </p>
          </div>

          <div className="connection-card-c__interest-c">
            <span className="">Interests: </span>
            <> {_dispalyInterest()}</>
            <>
              {userDetails &&
                userDetails.userInterests &&
                userDetails.userInterests.length > maxSize &&
                !showMore && (
                  <span className="more-interest-item" onClick={toggleShowMore}>
                    +
                    {userDetails &&
                      userDetails.userInterests &&
                      userDetails.userInterests.length - maxSize}{" "}
                  </span>
                )}
            </>

            {showMore && (
              <div style={{ position: "relative", width: "100%" }}>
                <MoreInterestOption
                  toggleOption={setShowMore}
                  interests={
                    userDetails &&
                    userDetails.userInterests &&
                    userDetails.userInterests.length > 0 &&
                    _truncateInterest(
                      4,
                      userDetails.userInterests.length,
                      userDetails.userInterests
                    )
                  }
                  onViewMoreClick={viewProfile}
                />
              </div>
            )}
          </div>
        </>
      ) : !userDetails || userDetails === null ? (
        <div
          style={{
            flex: 1,
            alignContent: "center",
            width: "318px",
            height: "300px",
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          We could not retrieve information about this user.
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "318px",
            height: "300px",
            padding: "20px",
            flex: 1,
          }}
        >
          <Loader height={50} width={50} type="Oval" color="red" />
        </div>
      )}

      {toggleReason && removeConnectionPrompt()}

      {showMoreOption && (
        <MoreOptions
          showOption={setShowMoreOption}
          onRemoveConnection={_onRemoveConnection}
        />
      )}
    </div>
  );
};

ConnectionCard.defaultProps = {
  showAvailability: false,
  showModeOfCommunication: false,
  isConnected: false,
  onConnect: () => {},
  loadingSendingRequest: false,
  showMoreClick: () => {},
  // isMentor: fa,
  showOptionDD: false,
  showChatIcon: true,
};
export default ConnectionCard;
