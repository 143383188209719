import React, { useState, useContext } from "react";
import Layout from "src/components/Layout/Layout";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import classes from "./AddTool.module.css";
import AddToolForm from "src/modules/Tools/components/AddToolForm/AddToolForm";
import { toolsObject } from "src/modules/Tools/components/Helper/Helper";
import { CastValue } from "src/modules/onboarding/helper";
import RightSideCard from "src/modules/Tools/components/RightSideCard/RightSideCard";
import addToolSVG from "src/modules/Tools/assets/addToolSVG.PNG";
import { Link, useHistory } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import { createTool } from "src/requests/ToolsRequest";
import { Context } from "src/store";

import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

const AddTool = () => {
  const { dispatch } = useContext(Context);
  const [data, setData] = useState(toolsObject);

  const history = useHistory();

  const onChange = (target, value) => {
    setData({ ...data, [target]: CastValue(target, value) });
  };

  const onSubmit = async (payload) => {
    try {
      const backendExpectedPayload = {
        topics: payload.toolTopics,
        logo: payload.fileName,
        title: payload.toolTitle,
        summary: payload.toolShortDescription,
        url: payload.toolOfficialWebsite,
      };
      const tool = await createTool(dispatch, backendExpectedPayload);
      if (tool.status === 200 || tool.status === 201) {
        setTimeout(() => history.push("/add-tool-success"), 1000);
      } else {
        const {
          data: { message },
        } = tool;
        setTimeout(() => {
          console.log(message);
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "This tool already exists! Please add a new tool",
            confirmButtonColor: "#da291c",
          }).then(() => (window.location = "/tools"));
        }, 1000);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error,
        confirmButtonColor: "#da291c",
      });
    }
  };

  const { t } = useTranslation();

  return (
    <Layout hideLeft hideRight>
      <div className={classes.MainContent}>
        <div className="create-opp-breadcrumb">
          <Link to="/home" style={{ fontWeight: "bold" }}>
            {t("Home")} <IoIosArrowRoundForward />
          </Link>
          <Link to="/tools" style={{ fontWeight: "bold" }}>
            {t("Tools")} <IoIosArrowRoundForward />
          </Link>
          <span>{t("Add Tool")}</span>
        </div>
        <div className={classes.TopRow}>
          <div className={classes.TopRowRight}>
            <div className={classes.TellUsMore}>
              {t("Tell us more about this tool")} 👋{" "}
            </div>
            <div className={classes.FocusOnFeatures}>
              {t("Focus on features and value proposition.")}
            </div>
          </div>
          <div className={classes.TopRowLeft}>
            <img src={addToolSVG} alt="Add Tool" />
          </div>
        </div>
        <div className={classes.BottomRow}>
          <div className={classes.BottomLeft}>
            <AddToolForm data={data} onChange={onChange} onSubmit={onSubmit} />
          </div>
          <div className={classes.BottomRight}>
            <div className={classes.BottomRightTop}>
              <div className={classes.CardTitle}>
                {t("We're excited to share your tool with the community!")}
              </div>
              <div className={classes.CardLabel}>
                {t("Here are helpful tips to get your tool featured")}
              </div>
            </div>
            <RightSideCard />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ifOnboardedThenShow(AddTool);
