import React, { useContext, useState, useEffect } from "react";
import {
  FaRegHeart,
  FaReply,
  FaCaretDown,
  FaHeart,
  FaRegFlag,
} from "react-icons/fa";
import ReactAvatar from "react-avatar";
import Comment from "src/modules/homepage/components/Comment/Comment";
import moment from "moment";
import { Context } from "src/store";
import { getAllComments } from "src/requests/CommentRequest";
import { unlikePost, likePost, getUserLike } from "src/requests/PostRequest";

import FlagPost from "src/modules/Community/components/Thread/FlagPost/FlagPost";

const PostAndComments = ({ post }) => {
  const { state, dispatch } = useContext(Context);
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState([]);
  const [counts, setCounts] = useState(post.commentCount);
  const [postLiked, setpostLiked] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggle = () => {
    setShowModal(!showModal);
  };

  const handleCommentToggle = () => setShowComments(!showComments);

  const getComments = (postId) => {
    handleCommentToggle();
    triggerGetComments(postId);
  };

  const triggerGetComments = (postId) => {
    getAllComments(dispatch, postId).then((res) => {
      const { data } = res.data.data;
      setCounts(data.length);
      setComments(data);
    });
  };

  const likeAPost = () => {
    likePost(dispatch, post.id);
    setpostLiked(true);
  };

  const unlikeAPost = () => {
    unlikePost(dispatch, post.id);
    setpostLiked(false);
  };

  const handleLike = () => {
    if (postLiked === true) {
      unlikeAPost();
    } else {
      likeAPost();
    }
  };

  useEffect(() => {
    getUserLike(dispatch, post.id).then((res) => {
      const {
        data: { data },
        status,
      } = res;
      if (status !== 200) {
        setpostLiked(false);
      }
      if (data) {
        setpostLiked(true);
      }
    });
  }, []);
  return (
    <div>
      <hr />
      <div className={`row`}>
        <div className="col-sm-1">
          <div>
            <span className="cover-photo">
              {post.profile && post.profile.imageUrl !== null ? (
                <img
                  src={post.profile.imageUrl}
                  className="all-members-display-pic "
                  alt="display"
                />
              ) : (
                <ReactAvatar
                  name={
                    post.profile
                      ? post.profile.firstName + " " + post.profile.lastName
                      : "Anonymous"
                  }
                  size={35}
                  round={true}
                  s
                />
              )}
            </span>
          </div>
        </div>

        <div className="col-sm-11">
          <div className="d-flex justify-content-between">
            <div>
              {post.profile
                ? post.profile.firstName + " " + post.profile.lastName
                : "Anonymous"}
            </div>{" "}
            <div>{moment(post.createdOn).format("MMMM Do YYYY - LT")}</div>
          </div>
          <p>{post.content}</p>

          <div className="d-flex justify-content-between align-items-center mt-4">
            <div>
              <span
                onClick={() => getComments(post.id)}
                className="text-gray cursor-pointer mr-3"
              >
                {post.commentCount} Replies <FaCaretDown />
              </span>
            </div>
            <div className="d-flex justify-content-end align-items-center mt-1">
              <span
                onClick={handleLike}
                className="text-gray font-weight-bold cursor-pointer mr-3"
              >
                {post.likeCount}{" "}
                {postLiked ? (
                  <FaHeart style={{ color: "#cf1d10" }} />
                ) : (
                  <FaRegHeart />
                )}
              </span>
              <span
                onClick={() => getComments(post.id)}
                className="text-gray font-weight-bold cursor-pointer mr-3"
              >
                <FaReply /> Reply
              </span>
              <span
                onClick={toggle}
                className="text-gray font-weight-bold cursor-pointer mr-4"
              >
                Flag <FaRegFlag />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-11 offset-1 mt-3">
        <Comment
          id={post.id}
          count={counts}
          comments={comments}
          style={showComments}
          dp={state.Profile.profile.imageUrl}
          getComments={() => triggerGetComments(post.id)}
        />
      </div>

      {showModal && (
        <FlagPost
          toggle={toggle}
          show={showModal}
          details={{
            contentId: post.id,
            contentType: "post",
          }}
          title="Report Post"
        />
      )}
    </div>
  );
};

export default PostAndComments;
