import React from "react";
import styles from "./SignoutPage.module.scss";
import LogoutIcon from "src/assets/img/logout.svg";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";

const SignoutPage = () => {
  const { search } = useLocation();
  // If the sign out fails, the server sends back the error code and message.
  const { errorCode, errorMessage } = queryString.parse(search);

  if (errorCode) {
    return (
      <div className={styles.Logout}>
        <img
          src={LogoutIcon}
          alt="logout"
          className={styles.Logout__logoutIcon}
        />
        <h1 className={`${styles.Logout__text} ${styles.Logout__h1}`}>
          There was an error while trying to log you out
        </h1>
        <h3 className={`${styles.Logout__text} ${styles.Logout__h3}`}>
          {errorMessage}
        </h3>
      </div>
    );
  }

  return (
    <div className={styles.Logout}>
      <img
        src={LogoutIcon}
        alt="logout"
        className={styles.Logout__logoutIcon}
      />
      <h1 className={`${styles.Logout__text} ${styles.Logout__h1}`}>
        Your TEFConnect account has been logged out
      </h1>
      <h3 className={`${styles.Logout__text} ${styles.Logout__h3}`}>
        You can continue to enjoy the awesome communities and content on
        TEFConnect
      </h3>
      <div className={styles.Logout__buttons}>
        <Link
          to="/login"
          className={`${styles.Logout__btn} ${styles.Logout__signinBtn}`}
        >
          Sign in
        </Link>
      </div>
    </div>
  );
};

export default SignoutPage;
