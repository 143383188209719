/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import Avatar from "react-avatar";
import { Context } from "src/store";
import {
  joinGroup,
  leaveGroup,
  joinPrivateGroup,
} from "src/requests/CommunityRequest";
import Loader from "src/components/Loader/Loader";
import "./SuggestedGroupCard.scss";
import { Link } from "react-router-dom";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { toast } from "react-toastify";

export const isRequest = (status) => {
  return status === "pending_invite";
};

const SuggestedGroupCard = ({
  groupLogo,
  id,
  title,
  members,
  location,
  belongs,
  ownerId,
  inviteStatus,
}) => {
  const { dispatch } = useContext(Context);
  const [joined, setJoined] = useState(belongs);
  const [left, setLeft] = useState(false);
  const [requested, setRequested] = useState(isRequest(inviteStatus));
  const [noOfMembers, setNoOfMembers] = useState(members);
  const linkTo = `/community/group?groupId=${id}`;
  const [isJoinLoading, setIsJoinLoading] = useState(false);
  const { userId, email } = useCurrentUser();
  const [buttonText, setButtonText] = useState("Join Group");

  const handleJoinGroup = () => {
    setIsJoinLoading(true);
    setNoOfMembers(noOfMembers + 1);
    joinGroup(dispatch, { groupId: id, userId }).then((res) => {
      setIsJoinLoading(false);
      setJoined(true);
      setLeft(false);
    });
  };

  const handleLeaveGroup = () => {
    setIsJoinLoading(true);
    setNoOfMembers(noOfMembers - 1);

    leaveGroup(dispatch, { groupId: id, userId }).then((_) => {
      setIsJoinLoading(false);
      setLeft(true);
      setJoined(false);
    });
  };

  const handleRequestJoinGroup = () => {
    const payload = {
      groupId: id,
      inviteedByUserId: ownerId,
      inviteeUserId: userId,
      inviteeEmail: email,
    };
    setIsJoinLoading(true);
    joinPrivateGroup(dispatch, payload).then((res) => {
      setIsJoinLoading(false);
      setJoined(false);
      setLeft(false);
      if (res.status === 201) {
        setRequested(true);

        toast(
          "A request has been sent to the admin of this group '" + title + "'"
        );
      }
    });
  };

  const handleClick = () => {
    joined
      ? handleLeaveGroup()
      : location === "private"
      ? handleRequestJoinGroup()
      : handleJoinGroup();
  };

  useEffect(() => {
    if (joined) {
      setButtonText("leave group");
    }
    if (left) {
      setButtonText("join group");
    }
    if (requested) {
      setButtonText("requested");
    }
  }, [joined, left, requested]);

  return (
    <div className="suggested-group-card">
      <div className="suggested-group-card__image">
        <Link to={linkTo}>
          {groupLogo && groupLogo !== "string" ? (
            <LazyLoadImage
              wrapperClassName="group-image-1"
              alt="group icon"
              effect="blur"
              src={groupLogo}
            />
          ) : (
            <Avatar name={title} size="100%" />
          )}
        </Link>
      </div>
      <div className="suggested-group-card__details">
        <h1 className="suggested-group-details-h1">
          <Link to={linkTo}>{title}</Link>
        </h1>
        <h3 className="suggested-group-details-h3">{location}</h3>
        <h3 className="suggested-group-details-h3">
          {" "}
          {noOfMembers > 1 ? `${noOfMembers} Members` : `${noOfMembers} Member`}
        </h3>
        {ownerId !== userId && (
          <button
            className={`suggested-group-details-button ${
              joined
                ? "suggested-group-button-active"
                : "suggested-group-button-inactive"
            }`}
            onClick={handleClick}
            disabled={isJoinLoading || requested}
          >
            {/* {isJoinLoading ? (
              <Loader color="light" />
            ) : joined ? (
              "leave group"
            ) : requested ? (
              "requested"
            ) : (
              "join group"
            )} */}

            {isJoinLoading ? <Loader color="light" /> : buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default SuggestedGroupCard;
