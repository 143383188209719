import React, { useState, useEffect, useContext } from "react";
import "./Mentor.scss";
import { MdMoreVert, MdDelete, MdEmail, MdLocalPhone } from "react-icons/md";
import { FaWhatsapp, FaVideo } from "react-icons/fa";
import { GiFaceToFace } from "react-icons/gi";
import { _truncateText } from "src/modules/mentorship/components/helper";
import { Context } from "src/store";
import { useHistory } from "react-router-dom";
import shortid from "shortid";
import Loader from "react-loader-spinner";
import ReactTooltip from "react-tooltip";
import ReactAvatar from "react-avatar";
import { useOuterClick } from "src/utils/helper";
import { removeConnect } from "src/requests/MentorRequests";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  Label,
} from "reactstrap";

const ModeOfCommunication = (value) => {
  switch (value) {
    case "Email":
      return <MdEmail color="#919ba8" size={15} />;
    case "Phone call":
      return <MdLocalPhone color="#919ba8" size={15} />;
    case "Whatsapp":
      return <FaWhatsapp color="green" size={15} />;
    case "Face to Face":
      return <GiFaceToFace color="#919ba8" size={15} />;
    // case "Social Media":
    //   return;
    case "Video call":
      return <FaVideo color="#919ba8" size={15} />;

    default:
      return value;
  }
};

const getRequestId = (userId, requests) => {
  const req = requests.find((r) => {
    return r.namedId === userId;
  });
  return req.requestId;
};

const MoreOptions = ({ showOption, onRemoveConnection }) => {
  const ref = useOuterClick(() => {
    showOption(false);
  });
  return (
    <div className="connection-card-more-option" ref={ref}>
      <span style={{ cursor: "pointer" }} onClick={onRemoveConnection}>
        <span>
          <MdDelete />
        </span>
        Remove Connection
      </span>
    </div>
  );
};

export const MoreInterestOption = ({
  toggleOption,
  interests,
  onViewMoreClick,
}) => {
  const ref = useOuterClick(() => {
    toggleOption(false);
  });
  return (
    <div className="interest-more" ref={ref}>
      <h4 className="other-interest-h4">Other Interests</h4>
      <hr />
      <div className="more-interest-list">
        {interests &&
          interests.length > 0 &&
          interests.map((i, index) => {
            return (
              <span key={index} style={{ cursor: "pointer" }}>
                {i.interest.name}
              </span>
            );
          })}
      </div>
      <hr />
      <h4 className="interest-view-more" onClick={onViewMoreClick}>
        See more
      </h4>
    </div>
  );
};

const ConnectionCard = ({
  userId,
  isConnected,
  showAvailability,
  showModeOfCommunication,
  isMentor,
  connectionStatus,
  onConnect,
  loadingSendingRequest,
  userDetails,
}) => {
  const [userInfo, setUserInfo] = useState({});
  const { state, dispatch } = useContext(Context);
  const [showMoreOption, setShowMoreOption] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const maxSize = 4;
  const [reasonForDisconnecting, setReasonForDisconnecting] = useState("");
  const [toggleReason, setToggleReason] = useState(false);
  const [RemoveBtnTex, setRemoveBtnText] = useState("Disconnect");

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const idModeOfCom = shortid.generate().toString();
  const handleReasonChange = (e) => {
    setReasonForDisconnecting(e.target.value);
  };

  const _toggleReasonForm = () => {
    setToggleReason(!toggleReason);
    setReasonForDisconnecting("");
  };

  const _setProfile = async () => {
    if (userDetails && Object.keys(userDetails).length > 0) {
      setUserInfo(userDetails);
    } else {
      // await _getProfile();
      setUserInfo(null);
    }
  };

  useEffect(() => {
    _setProfile();
  }, [userId]);

  const _dispalyInterest = () => {
    let interests = userInfo && userInfo.userInterests;
    if (interests.length > 0) {
      interests = _truncateInterest(0, maxSize, interests);
      return interests.map((interest) => {
        return (
          <span key={shortid.generate()} className="interest-item">
            {interest.interest.name}
          </span>
        );
      });
    } else {
      return <span> Non</span>;
    }
  };
  const _truncateInterest = (start, end, array) => {
    return array.length > maxSize ? array.slice(start, end) : array;
  };

  const _onRemoveConnection = (e) => {
    e.preventDefault();
    setRemoveBtnText("Disconnecting...");
    const reqId = getRequestId(userId, state.Mentors.connected);
    removeConnect(dispatch, reqId, userId, reasonForDisconnecting).then(() => {
      setRemoveBtnText("Disconnect");
      _toggleReasonForm();
    });
  };

  const isMentorAvailable = (value) => {
    const selectDays =
      userInfo && userInfo.tefMentor && userInfo.tefMentor.availabilityDays;
    if (selectDays && Array.isArray(selectDays)) {
      return selectDays.indexOf(value) >= 0;
    }
    return false;
  };

  const history = useHistory();
  const viewProfile = () => {
    const path = `/profile/${userId}`;
    history.push(path);
  };

  const removeConnectionPrompt = () => {
    return (
      <>
        <Modal isOpen={toggleReason} toggle={_toggleReasonForm}>
          <ModalHeader toggle={_toggleReasonForm}>
            Are you sure you want to remove this connection
          </ModalHeader>
          <ModalBody>
            <Form>
              <Label for="reason">
                State the reason for removing connection
              </Label>
              <Input
                placeholder="Reason for disconnecting"
                onChange={handleReasonChange}
                value={reasonForDisconnecting}
                name="reason"
                id="reason"
                required
              />
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={_onRemoveConnection}
              disabled={reasonForDisconnecting.length === 0}
            >
              <span style={{ display: "flex" }}>
                {RemoveBtnTex}{" "}
                {state.Mentors.loadingRemoveConnection && (
                  <span style={{ marginLeft: "10px" }}>
                    <Loader height={25} width={15} type="Oval" color="white" />
                  </span>
                )}
              </span>
            </Button>
            <Button color="secondary" outline onClick={_toggleReasonForm}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  };

  return (
    <div className={`connection-card-c ${showMore ? "extended" : ""}`}>
      {userInfo &&
      typeof userInfo === "object" &&
      userInfo &&
      Object.keys(userInfo).length > 0 ? (
        <>
          <div className="connection-card-c__heading">
            <div className="connection-card-image">
              {userInfo && userInfo.imageUrl ? (
                <img src={userInfo.imageUrl} className="img-fluid img-style" />
              ) : (
                <ReactAvatar
                  name={`${userInfo && userInfo.firstName} ${
                    userInfo && userInfo.lastName
                  }`}
                  size={62}
                  round
                />
              )}
            </div>
            <div className="connection-card__buttons">
              {isConnected ? (
                <div className="connect-btn connect-btn-connected">
                  Connected
                </div>
              ) : !isConnected && connectionStatus === "pending" ? (
                <div className="connect-btn connect-btn-pending">Pending</div>
              ) : !isConnected && connectionStatus === "connect" ? (
                <div
                  className="connect-btn connect-btn-connect"
                  onClick={onConnect}
                >
                  {loadingSendingRequest ? "Requesting..." : "Connect"}
                </div>
              ) : (
                <div
                  className="connect-btn connect-btn-connect"
                  onClick={onConnect}
                >
                  {loadingSendingRequest ? "Requesting..." : "Connect"}
                </div>
              )}

              {isConnected && isMentor && (
                <MdMoreVert
                  color="#919ba8"
                  className="more-option-btn"
                  onClick={() => setShowMoreOption(!showMoreOption)}
                />
              )}
            </div>
          </div>
          <div
            className="connection-card-c__name break-word"
            onClick={viewProfile}
          >
            <h6 className="break-word">{`${
              userInfo && userInfo.firstName
                ? userInfo.firstName.toUpperCase()
                : // <Skeleton width={50} height={30} />
                  ""
            } ${
              userInfo && userInfo.lastName
                ? userInfo.lastName.toUpperCase()
                : // <Skeleton width={50} height={30} />
                  ""
            }`}</h6>
          </div>

          <div className="connection-card-c__bio">
            <p className="break-word">
              {_truncateText(userInfo && userInfo.bio ? userInfo.bio : "", 75)}
            </p>
          </div>
          {showAvailability && (
            <div className="connection-card-c__availabilty">
              <span>Availabilty:</span>
              <div className="d-container">
                <span
                  className={`day-item ${
                    isMentorAvailable("sun") ? "available-day" : ""
                  }`}
                >
                  S
                </span>
                <span
                  className={`day-item ${
                    isMentorAvailable("mon") ? "available-day" : ""
                  }`}
                >
                  M
                </span>
                <span
                  className={`day-item ${
                    isMentorAvailable("tue") ? "available-day" : ""
                  }`}
                >
                  T
                </span>
                <span
                  className={`day-item ${
                    isMentorAvailable("wed") ? "available-day" : ""
                  }`}
                >
                  W
                </span>
                <span
                  className={`day-item ${
                    isMentorAvailable("thur") ? "available-day" : ""
                  }`}
                >
                  T
                </span>
                <span
                  className={`day-item ${
                    isMentorAvailable("fri") ? "available-day" : ""
                  }`}
                >
                  F
                </span>
                <span
                  className={`day-item ${
                    isMentorAvailable("sat") ? "available-day" : ""
                  }`}
                >
                  {" "}
                  S
                </span>
              </div>
            </div>
          )}

          {showModeOfCommunication && (
            <div className="connection-card-c__mode-of-communication">
              <span>Mode of communication:</span>
              <span
                style={{ marginLeft: "5px" }}
                data-tip
                data-for={idModeOfCom}
              >
                {ModeOfCommunication(
                  userInfo &&
                    userInfo.tefMentor &&
                    userInfo.tefMentor.preferedModeOfCommunication
                )}
              </span>
              {userInfo &&
                userInfo.tefMentor &&
                userInfo.tefMentor.preferedModeOfCommunication && (
                  <ReactTooltip id={idModeOfCom} place="top" effect="solid">
                    {userInfo.tefMentor.preferedModeOfCommunication}
                  </ReactTooltip>
                )}
            </div>
          )}

          <div className="connection-card-c__interest-c">
            <span className="">Interests: </span>
            <> {_dispalyInterest()}</>

            <>
              {userInfo &&
                userInfo.userInterests &&
                userInfo.userInterests.length > maxSize &&
                !showMore && (
                  <span className="more-interest-item" onClick={toggleShowMore}>
                    +
                    {userInfo &&
                      userInfo.userInterests &&
                      userInfo.userInterests.length - maxSize}{" "}
                  </span>
                )}
            </>
            {showMore && (
              <div style={{ position: "relative", width: "100%" }}>
                <MoreInterestOption
                  toggleOption={setShowMore}
                  interests={
                    userInfo &&
                    userInfo.userInterests &&
                    userInfo.userInterests.length > 0 &&
                    _truncateInterest(
                      4,
                      userInfo.userInterests.length,
                      userInfo.userInterests
                    )
                  }
                  onViewMoreClick={viewProfile}
                />
              </div>
            )}
          </div>
        </>
      ) : !userInfo || userInfo === null ? (
        <div
          style={{
            flex: 1,
            alignContent: "center",
            width: "318px",
            height: "300px",
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          We could not retrieve information about this user.
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "318px",
            height: "300px",
            padding: "20px",
            flex: 1,
          }}
        >
          <Loader height={50} width={50} type="Oval" color="red" />
        </div>
      )}

      {toggleReason && removeConnectionPrompt()}

      {showMoreOption && (
        <MoreOptions
          showOption={setShowMoreOption}
          onRemoveConnection={_toggleReasonForm}
        />
      )}
    </div>
  );
};

ConnectionCard.defaultProps = {
  showAvailability: false,
  showModeOfCommunication: false,
  isConnected: false,
  onConnect: () => {},
  loadingSendingRequest: false,
  showMoreClick: () => {},
  // isMentor: fa,
};
export default ConnectionCard;
