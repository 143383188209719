import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import "./Article.scss";

const ReportArticle = ({ modal, toggle }) => {
  return (
    <div className="">
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="modal-title"
        className="px-4"
      >
        <ModalHeader toggle={toggle} className="">
          <div className="">Report Article</div>
        </ModalHeader>

        <ModalBody>
          <p>Select Report Category</p>

          <div className="download-format">
            <FormGroup check>
              <Label check className="font-weight-normal">
                <Input type="radio" name="radio1" /> Spam
              </Label>
            </FormGroup>
          </div>
          <div className="download-format">
            <FormGroup check>
              <Label check className="font-weight-normal">
                <Input type="radio" name="radio1" /> Harrassment
              </Label>
            </FormGroup>
          </div>
          <div className="download-format">
            <FormGroup check>
              <Label check className="font-weight-normal">
                <Input type="radio" name="radio1" /> Rules Violation
              </Label>
            </FormGroup>
          </div>

          <div className="d-flex justify-content-between mt-5 cursor-pointer">
            <div className="cancel-download-btn">Cancel</div>
            <div className="download-article-btn">Report</div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ReportArticle;
