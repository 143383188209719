import React, { useContext, useEffect, useState } from "react";
// import { Form, FormGroup, Input } from "reactstrap";
import {
  getCoursemates,
  getGroupInvitesSent,
} from "src/requests/StudyGroupsRequest";
import { Context } from "src/store";
import classes from "./coursemate.module.scss";
import CoursemateItem from "./CourseMateItem";
import queryString from "query-string";
import { getManyUserLightProfile } from "src/requests/ProfileRequest";
import Loader from "react-loader-spinner";
import ResultPagination from "src/modules/training/StudyGroups/components/ResultPagination/ResultPagination";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const Coursemates = ({
  groupMembers,
  updateGroupMember,
  isAdmin,
  adminId,
  groupId,
}) => {
  const { state, dispatch } = useContext(Context);
  const [paging, setPaging] = useState({
    pageNumber: 1,
    hasPreviousPage: false,
    hasNextPage: false,
  });
  const [couresmates, setCoursemates] = useState([]);
  const [searchString] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const user = get_user_info_from_token().user;
  const [invitesSent, setInvitesSent] = useState([]);

  const { course } = queryString.parse(location.search);
  const _getCoursemates = (pageNumber, search) => {
    setLoadingData(true);
    getCoursemates(dispatch, course, { pageNumber, search }).then(() => {
      setLoadingData(false);
    });
  };

  useEffect(() => {
    _getCoursemates(paging.pageNumber, searchString);
  }, [course]);

  useEffect(() => {
    if (state.Training.coursemates) {
      setPaging({
        ...paging,
        ...state.Training.coursemates.pager,
      });

      if (!state.Training.coursemates && !state.Training.coursemates.result)
        return setCoursemates([]);
      const uIDs = state.Training.coursemates.result.map((e) => e.userId);
      if (uIDs.length > 0) {
        getManyUserLightProfile(uIDs).then((res) => {
          if (res.data && res.data.data) {
            setCoursemates(res.data.data);
          }
        });
      }
    }
  }, [state.Training.coursemates]);

  const _getGroupInvitesSent = () => {
    getGroupInvitesSent(dispatch, user.id).catch((e) =>
      console.log(e.response)
    );
  };

  const onPageSelect = (page) => {
    const selectedPage = page.selected + 1;
    // setCoursemates([]);
    _getCoursemates(selectedPage, searchString);
  };

  useEffect(() => {
    if (state.Training.sentRequest && state.Training.sentRequest.length > 0) {
      setInvitesSent(
        state.Training.sentRequest.filter(
          (req) => req.groupId === groupId && req.status === "pending_invite"
        )
      );
    }
  }, [state.Training.sentRequest]);

  const isInvited = (userId) => {
    return (
      invitesSent.findIndex((invite) => invite.inviteeUserId === userId) >= 0
    );
  };

  // console.log(invitesSent);

  // const _searchCoursemates = (searchString) => {
  //   _getCoursemates(1, searchString);
  // };

  // const _handleSearch = (e) => {
  //   const value = e.target.value;
  //   setSearchString(value);
  //   _searchCoursemates(value);
  // };

  // const _onFormSubmit = (e) => {
  //   e.preventDefaul();
  //   _searchCoursemates(searchString);
  // };

  return (
    <div className={classes.coursemateContainerF}>
      <h3 className={classes.heading}>Coursemates</h3>
      {/* <Form onSubmit={_onFormSubmit}>
        <FormGroup>
          <Input
            type="search"
            className={classes.search}
            placeholder="Search by name or email"
            onChange={_handleSearch}
          />
        </FormGroup>
      </Form> */}
      {loadingData && (
        <Loader
          type="ThreeDots"
          height={10}
          color="var(--tef-red)"
          style={{ margin: "0 auto" }}
        />
      )}

      <div className={classes.coursemateContainer}>
        {couresmates && couresmates.length > 0 ? (
          couresmates.map((c, i) => {
            return (
              <CoursemateItem
                firstName={c.firstName}
                lastName={c.lastName}
                email={c.email}
                imageUrl={c.imageUrl}
                userId={c.userId}
                key={c.userId}
                isAdmin={isAdmin}
                isGroupMember={
                  groupMembers &&
                  groupMembers.findIndex((e) => e.userId === c.userId) >= 0
                }
                adminId={adminId}
                getInvitesSent={_getGroupInvitesSent}
                isInvitee={isInvited(c.userId)}
              />
            );
          })
        ) : (
          <>
            {!loadingData && <>No one seem to have started this course yet</>}
          </>
        )}
      </div>
      {loadingData && (
        <Loader
          type="ThreeDots"
          height={10}
          color="var(--tef-red)"
          style={{ margin: "0 auto" }}
        />
      )}
      {(paging.hasNextPage || paging.hasPreviousPage) && (
        <ResultPagination
          pageCount={paging.pageCount || 0}
          pageRange={5}
          current={paging.pageNumber}
          onSelect={onPageSelect}
        />
      )}
    </div>
  );
};

export default Coursemates;
