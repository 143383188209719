import React, { useEffect, useState } from "react";
import "./screenOne.scss";
import lang1 from "src/assets/img/lang-icon1.png";
import lang2 from "src/assets/img/lang-icon2.png";
import lang3 from "src/assets/img/lang-icon3.png";
import { useTranslation } from "react-i18next";
const LanguageCard = ({
  greeting,
  language,
  value,
  icon,
  onSelect,
  selectedLang,
}) => {
  return (
    <div
      className={`lang-card-c__item ${
        selectedLang === value ? "selected" : ""
      }`}
      onClick={() => onSelect(value)}
    >
      <div className="item-img-c">
        <img
          src={selectedLang === value ? lang1 : icon}
          className="img-fluid"
        />
      </div>
      <h6>{greeting}</h6>
      <h5>{language}</h5>
    </div>
  );
};

const ScreenOne = ({
  onNextClick,
  // onPreviousClick,
  info,
  onLanguageSelect,
}) => {
  const { t } = useTranslation();
  const [selectedLang, setSelectedlanguage] = useState(null);

  useEffect(() => {
    // console.log("this was updated");
    setSelectedlanguage(info.languageId);
  }, [info.languageId]);

  return (
    <div className="a-o-card-main">
      <h1>
        {t("Welcome to")} <span>{t("TEF Application")}</span>
      </h1>
      <p>{t("What language would you prefer?")}</p>

      <div className="lang-card-c">
        <LanguageCard
          language="English Language"
          greeting="Hello Africa 👋"
          value="en"
          icon={lang3}
          selectedLang={selectedLang}
          // selectedLang={info.languageId}
          onSelect={onLanguageSelect}
        />
        <LanguageCard
          language="Langue Française"
          greeting="Bonjour Afrique ☺️"
          value="fr"
          icon={lang2}
          // selectedLang={info.languageId}
          selectedLang={selectedLang}
          onSelect={onLanguageSelect}
        />
        <LanguageCard
          language="Idioma Português"
          greeting="Olá África 👋"
          value="pt"
          icon={lang3}
          // selectedLang={info.languageId}
          selectedLang={selectedLang}
          onSelect={onLanguageSelect}
        />
        {/* <LanguageCard
          language="اللغة العربية"
          greeting="👋 مرحبًا بأفريقيا"
          value="ar"
          icon={lang2}
          selectedLang={info.languageId}
          onSelect={onLanguageSelect}
        /> */}
      </div>
      <div className="next-prev-c">
        {!selectedLang ? (
          <div className="next-btn" style={{ backgroundColor: "grey" }}>
            {t("Next")}
          </div>
        ) : (
          <div className="next-btn" onClick={onNextClick}>
            {t("Next")}
          </div>
        )}
      </div>
    </div>
  );
};

export default ScreenOne;
