import React, { useContext, useEffect } from "react";
import FAQ from "src/components/FAQ/FAQ";
import { getMentorFaq } from "src/requests/MentorRequests";
import { Context } from "src/store";

const FaqList = ({ props }) => {
  const { state, dispatch } = useContext(Context);
  useEffect(() => {
    getMentorFaq(dispatch);
  }, []);

  const displayFaq = () => {
    console.log(state.FAQs.FAQs);
    return <FAQ faqs={state.FAQs.FAQs} {...props} />;
  };

  return <div style={{ padding: "5px" }}>{displayFaq()}</div>;
};

FaqList.propTypes = {};

export default FaqList;
