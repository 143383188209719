import React from "react";
import { Button } from "reactstrap";

import classes from "./SubmitDocument.module.css";
import Swal from "sweetalert2";

const SubmitDocument = ({
  children,
  buttonText = "Save & Continue Later",
  message,
}) => {
  const popUp = () => {
    if (message != null) {
      return Swal.fire({
        icon: "error",
        text: message,
      });
    }
    return Swal.fire({
      icon: "success",
      text: "Document Saved for Later",
    });
  };

  return (
    <div className={classes.SubmitDocument}>
      <Button
        outline
        color="secondary"
        onClick={() => popUp()}
        className="mr-2"
      >
        {buttonText}
      </Button>
      {children}
    </div>
  );
};

export default SubmitDocument;
