import React, { useState, useContext } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
} from "reactstrap";
import Avatar from "src/components/Avatar/Avatar";
import {
  acceptConnection,
  rejectConnection,
  // getPendingConnectionReceived,
  // getPendingConnectionSent,
} from "src/requests/ConnectionRequest";
import "./Requests.scss";

import { Context } from "src/store";
import Loader from "react-loader-spinner";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import sendMessage from "src/utils/NewMessageHelper";

// import { getUserProfile } from "src/requests/ProfileRequest";
// const status = {
//   PENDING: "pending",
//   ACCEPTED: "accepted",
//   DECLINED: "declined",
//   CANCELED: "canceled",
// };
const Request = ({
  user,
  // setCurrentTab,
  // role,
  ownersRequest,
  connectionId,
  loggedInUserId,
  createdOn,
}) => {
  const { dispatch } = useContext(Context);
  // const loggedInUser = useCurrentUser();
  const history = useHistory();

  const [modal, setModal] = useState(false);
  const [reason, setReason] = useState("");
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isDecline, setIsDeclined] = useState(false);
  const [isAcceptedConn, setIsAcceptedConn] = useState(false);

  const toggle = () => setModal(!modal);

  const handleConnect = () => {
    setLoadingAccept(true);
    acceptConnection(dispatch, connectionId).then(() => {
      setLoadingAccept(false);
      setIsAcceptedConn(true);
      // if (ownersRequest) {
      //   getPendingConnectionSent(dispatch, loggedInUserId, 1, status.PENDING);
      // } else {
      //   getPendingConnectionReceived(
      //     dispatch,
      //     loggedInUserId,
      //     1,
      //     status.PENDING
      //   );
      // }
    });
  };
  const handleDecline = () => {
    setLoadingDelete(true);
    return rejectConnection(dispatch, connectionId).then((res) => {
      // toggle();
      // if (ownersRequest) {
      //   getPendingConnectionSent(dispatch, loggedInUserId, 1, status.PENDING);
      // } else {
      //   getPendingConnectionReceived(
      //     dispatch,
      //     loggedInUserId,
      //     1,
      //     status.PENDING
      //   );
      // }
      setIsDeclined(true);
      setLoadingDelete(false);
      return res;
    });
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };
  const declineRequest = () => (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Decline Connection Request</ModalHeader>
        <ModalBody>
          <Form>
            <Input
              placeholder="Reason for decline"
              onChange={handleReasonChange}
              value={reason}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleDecline}>
            Decline
          </Button>{" "}
          <Button color="secondary" outline onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );

  const onHandleReject = () => {
    Swal.fire({
      title: "Hello, Are you sure?",
      text: "You are about to decline a request",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.value) {
        handleDecline().then((res) => {
          res.status === 200
            ? Swal.fire({
                title: "Decline!",
                text: "Successfully declined request",
                icon: "success",
                confirmButtonColor: "red",
              })
            : Swal.fire({
                title: "Cancelled",
                text: "Could not decline request, please try again",
                icon: "error",
                confirmButtonColor: "red",
              });
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Cancelled",
          text: "action cancelled",
          icon: "error",
          confirmButtonColor: "red",
        });
      }
    });
  };

  return (
    <>
      <div className="request-conn-card">
        <div className="request-conn-card__left">
          <Avatar
            dimension={47}
            name={`${user.firstName} ${user.lastName}`}
            onClick={() => {
              history.push(`/profile/${user.userId}`);
            }}
            src={user.imageUrl}
          />
          <div className="request-conn-card__left--name">
            <Link to={`/profile/${user.userId}`}>
              <h4>
                {user.firstName} {user.lastName}
              </h4>
            </Link>
            <span> {moment(createdOn).fromNow()}</span>
          </div>
        </div>

        <div className="request-conn-card__right float-right">
          {!ownersRequest ? (
            <Row>
              <>
                {isDecline ? (
                  <Button
                    style={{ padding: "5px", display: "inline" }}
                    color="secondary"
                    outline
                  >
                    <span style={{ display: "flex" }}>Declined</span>
                  </Button>
                ) : isAcceptedConn ? (
                  <>
                    <Button
                      color="primary"
                      outline
                      onClick={() => sendMessage(user.userId, history)}
                    >
                      Send Message
                    </Button>
                  </>
                ) : (
                  <>
                    {" "}
                    <Button
                      onClick={handleConnect}
                      className="mr-1"
                      disabled={loadingAccept || loadingDelete}
                    >
                      <span style={{ display: "flex" }}>
                        Accept
                        {loadingAccept && (
                          <span style={{ marginLeft: "5px" }}>
                            <Loader
                              height={15}
                              width={15}
                              color="white"
                              type="Oval"
                            />
                          </span>
                        )}
                      </span>
                    </Button>
                    <Button
                      style={{ padding: "5px", display: "inline" }}
                      onClick={onHandleReject}
                      color="secondary"
                      outline
                      disabled={loadingAccept || loadingDelete}
                    >
                      <span style={{ display: "flex" }}>
                        Decline
                        {loadingDelete && (
                          <span style={{ marginLeft: "5px" }}>
                            <Loader
                              height={15}
                              width={15}
                              color="red"
                              type="Oval"
                            />
                          </span>
                        )}
                      </span>
                    </Button>
                  </>
                )}
              </>
            </Row>
          ) : (
            // </Col>
            <Col md={5} className="mt-2 mb-2 d-flex justify-content-end">
              <Button color="primary" outline>
                Pending
              </Button>
            </Col>
          )}
        </div>
      </div>
      {declineRequest()}
    </>
  );
};

Request.propTypes = {};

export default Request;
