import React from "react";
// import ContentLoader from "react-content-loader";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const OpportunityDetailContentLoader = () => {
  const items = [1, 1, 1];
  return (
    <>
      {items.map((item, idx) => (
        <SkeletonTheme key={idx} item={idx}>
          <div className="absolute">
            <Skeleton width={"100%"} height={250} />
          </div>
        </SkeletonTheme>
      ))}
    </>
  );
};

export default OpportunityDetailContentLoader;
