import React from "react";

const IntroductionTemplate = ({ introduction, executiveSummary }) => {
  return (
    <div>
      <h4 className="text-center font-weight-bold">Introduction</h4>
      <h5 className="text-center mb-5">Business Overview</h5>
      <p>
        <span className="mr-4">1.1 </span> Description of Business
      </p>
      <p className="ml-5">
        {introduction.elevatorPitch === ""
          ? "[ELEVATOR PITCH]"
          : introduction.elevatorPitch}{" "}
        <br />
        {introduction.businessDescription === ""
          ? "[DETAILED DESCRIPTION OF BUSINESS]"
          : introduction.businessDescription}
      </p>
      <p>
        <span className="mr-4">1.2 </span> Vision and Mission Statement
      </p>
      <p className="ml-5">
        Vision: <br />
        {executiveSummary.visionStatement === ""
          ? "[VISION STATEMENT]"
          : executiveSummary.visionStatement}
      </p>
      <p className="ml-5">
        Mission: <br />
        {executiveSummary.missionStatement === ""
          ? "[MISSION STATEMENT]"
          : executiveSummary.missionStatement}
      </p>
      <p>
        <span className="mr-4">1.3 </span> Business Objective(s)
      </p>
      <p className="ml-5">
        {introduction.businessObjectives.length === 1 ? (
          "[OBJECTIVES]"
        ) : (
          <ul>
            {introduction.businessObjectives.map((objective, index) => (
              <li key={index}>{objective}</li>
            ))}
          </ul>
        )}
      </p>
      <p>
        <span className="mr-4">1.4 </span> Critical Success Factors of the
        Business are:
      </p>
      <p className="ml-5">
        {introduction.successfactors.length === 1 ? (
          "[SUCCESS FACTORS]"
        ) : (
          <ul>
            {introduction.successfactors.map((factor, index) => (
              <li key={index}>{factor}</li>
            ))}
          </ul>
        )}
      </p>
      <p>
        <span className="mr-4">1.5 </span> Value Propostion
      </p>
      <p className="ml-5">
        {introduction.valueProposition === ""
          ? "[VALUE PROPOSTION]"
          : introduction.valueProposition}
      </p>
      <p>
        <span className="mr-4">1.6 </span> Current Status of Business
      </p>
      <p className="ml-5">
        {introduction.businessStatus === ""
          ? "[CURRRENT STATE OF BUSINESS]"
          : introduction.businessStatus}
      </p>
      <p>
        <span className="mr-4">1.7 </span> Contribution to Local and National
        Economy
      </p>
      <p className="ml-5">
        {introduction.contributionToEconomy === ""
          ? "[COMMUNITY AND NATIONAL CONTRIBUTION]"
          : introduction.contributionToEconomy}
      </p>
    </div>
  );
};

export default IntroductionTemplate;
