import authService from "src/Auth/OpenId/authService";
import { usedSingleSignOn } from "src/utils/loginMethod";

export const Logout = (history) => {
  if (usedSingleSignOn()) {
    authService.logout();
  } else {
    history.push("/signout");
    localStorage.clear();
    sessionStorage.clear();
  }
};
