/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Logout } from "src/requests/logout";

import Logo from "src/assets/img/logo/logo_new.webp";
import styles from "./SideBar.module.scss";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
const SideBar = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { endDate } = queryString.parse(history.location.search);
  const { pathname } = history.location;

  const calculateTimeLeft = () => {
    const difference = +new Date(endDate) - +new Date();
    let timeLeft = {
      days: "00",
      hours: "00",
      minutes: "00",
      seconds: "00",
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const handleLogout = (e) => {
    e.preventDefault();
    Logout(history);
  };

  const isCurrentMainPath = (title) => {
    const path = window.location.pathname;
    let currentPath;
    currentPath = path.split("/").filter(Boolean)[1];
    if (
      currentPath.toLowerCase() === "tests" ||
      currentPath.toLowerCase() === "personalinfo"
    ) {
      currentPath = path.split("/").filter(Boolean)[2];
    }

    return currentPath.toLowerCase() === title.toLowerCase();
  };

  return (
    <div className={styles.ApplicationSidebar}>
      <Link to="/home">
        <img src={Logo} alt="" className={styles.ApplicationSidebar__Logo} />
      </Link>
      <h4 className={styles.ApplicationSidebar__sideHeading}>
        {t("TEF Application Countdown")}
      </h4>
      <div
        className={`${styles.ApplicationSidebar__countdownTimer} notranslate`}
        style={{ background: "var(--tef-red)" }}
      >
        <h3>
          {timeLeft.days} <span>{t("days")}</span>
        </h3>
        <h3>
          {timeLeft.hours} <span>{t("hours")}</span>
        </h3>
        <h3>
          {timeLeft.minutes} <span>{t("minutes")}</span>
        </h3>
        <h3>
          {timeLeft.seconds} <span>{t("seconds")}</span>
        </h3>
      </div>

      <div className={styles.ApplicationSidebar__subTopics}>
        <h4 className={styles.ApplicationSidebar__number}>1</h4>
        <div>
          <h4 className={styles.ApplicationSidebar__topicName}>
            {t("Getting to know you")}
          </h4>
          <ul>
            <li
              className={
                isCurrentMainPath("bio") &&
                `${styles.ApplicationSidebar__sideActive}`
              }
            >
              {t("Bio")}
            </li>
            <li
              className={
                isCurrentMainPath("experience") &&
                `${styles.ApplicationSidebar__sideActive}`
              }
            >
              {t("Idea & Experience")}
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.ApplicationSidebar__subTopics}>
        <h4 className={styles.ApplicationSidebar__number}>2</h4>
        <div>
          <h4 className={styles.ApplicationSidebar__topicName}>
            {pathname.includes("offcycle") ? "Assessment" : "Know thyself"}
          </h4>
          <ul>
            {!pathname.includes("offcycle") && (
              <li
                className={
                  isCurrentMainPath("personality") &&
                  `${styles.ApplicationSidebar__sideActive}`
                }
              >
                {t("Personality Test")}
              </li>
            )}
            <li
              className={
                isCurrentMainPath("mcq") &&
                `${styles.ApplicationSidebar__sideActive}`
              }
            >
              {t("Cognitive Test")}
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.ApplicationSidebar__subTopics}>
        <h4 className={styles.ApplicationSidebar__number}>3</h4>
        <div>
          <h4
            className={
              isCurrentMainPath("declaration")
                ? `${styles.ApplicationSidebar__sideActive} ${styles.ApplicationSidebar__topicName}`
                : `${styles.ApplicationSidebar__topicName}`
            }
          >
            {t("Declaration")}
          </h4>
        </div>
      </div>

      <div className={styles.ApplicationSidebar__subTopics}>
        <div>
          <h4>
            <Link
              to="#"
              onClick={handleLogout}
              className={`${styles.ApplicationSidebar__topicName} ${styles.ApplicationSidebar__logoutLink}`}
            >
              {t("Logout")}
            </Link>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
