import axios from "src/utils/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { asyncActions } from "src/utils/AsyncUtils";
import { InviteURL } from "src/constants";
import { SEND_INVITE } from "src/actions";

export const sendInvite = (dispatch, payload) => {
  dispatch(asyncActions(SEND_INVITE).loading(true));
  return axios({
    method: "post",
    url: InviteURL.SEND_INVITE_URL,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(SEND_INVITE).loading(false));
      if (response.status === 200) {
        const { message } = response.data;
        dispatch(asyncActions(SEND_INVITE).success(response));
        dispatch(asyncActions(SEND_INVITE).loading(false));
        toast(message);
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(SEND_INVITE).loading(false));
      dispatch(asyncActions(SEND_INVITE).failure(true, error.response));
      toast("An error occurred, Please try again ");
      return error.response;
    });
};
