/* eslint-disable no-dupe-keys */
import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import CountryRegionData from "src/utils/countryList.json";
import PropTypes from "prop-types";

const Constants = {
  SHORT: "short",
  FULL: "full",
};

export const CustomCountryRegionDropdown = ({
  country,
  value,
  name,
  id,
  classes,
  blankOptionLabel,
  defaultOptionLabel,
  onChange,
  onBlur,
  countryValueType,
  disabled,
  required,
  invalid,
}) => {
  const [regions, setRegions] = useState([]);
  const _getDefaultOptions = () => {
    if (!country) {
      return <option value="">{blankOptionLabel}</option>;
    }
    if (defaultOptionLabel) {
      return <option value="">{defaultOptionLabel}</option>;
    }
  };

  useEffect(() => {
    _getRegionList();
  }, [country]);

  const _getRegionList = () => {
    if (!country) {
      return setRegions([]);
    }
    const filterType =
      countryValueType === Constants.SHORT ? "countryShortCode" : "countryName";

    const filtered = CountryRegionData.find((c) => {
      return c[filterType] === country;
    });
    const regions = filtered.regions;
    setRegions(regions);
  };

  const setArributes = () => {
    const attr = {
      id,
      onChange: (e) => onChange(e.target.value, e),
      onBlur: (e) => onBlur(e),
      disabled,
      className: classes,
      name,
      required,
      invalid,
    };
    if (id) {
      attr.id = id;
    }

    if (classes) {
      attr.className = classes;
    }

    return attr;
  };

  const _displayRegions = () => {
    return regions.map((r, index) => {
      return (
        <option key={index} value={r.name}>
          {r.name}
        </option>
      );
    });
  };

  return (
    <>
      <Input
        {...setArributes()}
        type="select"
        value={value}
        style={{ outline: "none" }}
      >
        {_getDefaultOptions()}
        {_displayRegions()}
      </Input>
    </>
  );
};

CustomCountryRegionDropdown.propTypes = {
  id: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  classes: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  countryValueType: PropTypes.oneOf([Constants.FULL, Constants.SHORT]),
  disabled: PropTypes.bool,
  name: PropTypes.string,
  defaultOptionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  invalid: PropTypes.bool,
};

CustomCountryRegionDropdown.defaultProps = {
  country: "",
  value: "",
  name: "rcrs-region",
  id: "",
  classes: "",
  blankOptionLabel: "-",
  defaultOptionLabel: "Select Region",
  onChange: () => {},
  onBlur: () => {},
  countryValueType: Constants.FULL,
  disabled: false,
  invalid: false,
  required: false,
};
