import React, { useContext, useState } from "react";
import classes from "src/Auth/Register/Register_v2.module.scss";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  FormFeedback,
  Spinner,
} from "reactstrap";
import StatusModal from "src/components/StatusModal/StatusModal";
import useForm from "src/utils/useForm";
import { validateSignup } from "src/utils/Validations/validateSignup";
import { registerUser } from "src/requests/AuthRequest";
import { Context } from "src/store";
import PasswordStrengthBar from "react-password-strength-bar";
import { Link, useHistory } from "react-router-dom";
import DataCollectionModal from "src/Auth/components/DataCollectionModal/DataCollectionModal";
import CookieConsent from "react-cookie-consent";

const RegisterForm_v2 = () => {
  const [showText, setShowText] = useState(false);
  const togglePassword = () => setShowText(!showText);
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useContext(Context);
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [check, setCheck] = useState(false);
  const [show, setShow] = useState(false);

  const [alertDetails, setAlertDetails] = useState({
    alertMessage: "",
    alertType: "",
  });

  const dismissAlert = () => {
    setAlertDetails({ alertMessage: "", alertType: "" });
  };

  const handleSignup = () => {
    setIsLoading(true);
    setAlertDetails({
      ...alertDetails,
      alertMessage: "",
      alertType: "",
    });

    const userInfo = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
    };

    registerUser(dispatch, userInfo)
      .then((res) => {
        const { status, data } = res;
        if (status !== 201) {
          setIsLoading(false);
          const { message } = data;
          setAlertDetails({
            ...alertDetails,
            alertMessage: message,
            alertType: "danger",
            buttonText: "Close",
            buttonClick: () => dismissAlert(),
            showButton: true,
          });
        } else {
          setAlertDetails({
            ...alertDetails,
            alertMessage: `Registration successful: ${res.data.message}`,
            alertType: "success",
            buttonText: "Close",
            buttonClick: () => dismissAlert(),
            showButton: true,
          });
          setTimeout(() => {
            history.push(`/confirmed/${userInfo.email}`);
          }, 1500);
        }
      })
      .catch((_error) => {
        setIsLoading(false);
        setAlertDetails({
          ...alertDetails,
          alertMessage: "Something went wrong, please contact support",
          alertType: "danger",
          buttonText: "Close",
          buttonClick: () => dismissAlert(),
          showButton: true,
        });
      });
  };

  const handleToggleConsentModal = (event) => {
    event.preventDefault();
    setShowConsentModal(!showConsentModal);
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    handleSignup,
    validateSignup
  );

  const onChecked = (e) => {
    setCheck(e.target.checked);
  };

  const toggleFocus = () => setShow(true);
  const toggleBlur = () => setShow(false);

  const history = useHistory();
  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup className={classes.f_row}>
        <Label className={classes.formLabel}>First Name</Label>

        <Input
          className={classes.f_input}
          placeholder="Enter First Name"
          required
          onChange={handleChange}
          value={values.firstName}
          name="firstName"
          invalid={errors.firstName}
          data-testid="firstName"
        />
        {errors.firstName && <FormFeedback>{errors.firstName}</FormFeedback>}
      </FormGroup>

      <FormGroup className={classes.f_row}>
        <Label className={classes.formLabel}>Last Name</Label>

        <Input
          className={classes.f_input}
          placeholder="Enter Last Name"
          required
          onChange={handleChange}
          value={values.lastName}
          name="lastName"
          invalid={errors.lastName}
          data-testid="lastName"
        />
        {errors.lastName && <FormFeedback>{errors.lastName}</FormFeedback>}
      </FormGroup>

      <FormGroup className={classes.f_row}>
        <Label className={classes.formLabel}>Email</Label>

        <Input
          className={classes.f_input}
          placeholder="Enter email"
          required
          onChange={handleChange}
          value={values.email}
          name="email"
          invalid={errors.email}
          data-testid="email"
        />
        {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
      </FormGroup>
      <FormGroup className={`${classes.f_row} ${classes.relativeField}`}>
        <Label className={classes.formLabel}>Password</Label>
        <Input
          className={classes.f_input}
          placeholder="Enter password"
          type={showText ? "text" : "password"}
          required
          onChange={handleChange}
          value={values.password}
          name="password"
          invalid={errors.password}
          onFocus={toggleFocus}
          onBlur={toggleBlur}
          data-testid="password"
        />
        {errors.password && <FormFeedback>{errors.password}</FormFeedback>}
        <span className={classes.hideIcon}>
          {showText ? (
            <FaEyeSlash onClick={togglePassword} style={{ opacity: "0.5" }} />
          ) : (
            <FaEye onClick={togglePassword} style={{ opacity: "0.5" }} />
          )}
        </span>
      </FormGroup>

      <div style={{ margin: "5px 0" }}>
        <PasswordStrengthBar password={values.password} />
      </div>

      {show && (
        <p className={classes.hint_text}>
          <b>Hint:</b> For a strong password, it must be at least 8 characters
          and contain an upper case (A-Z), lower case (a-z), number (0-9) and a
          special character (e.g. !@#$%^&*)
        </p>
      )}

      <FormGroup check>
        <Label check className={classes.terms}>
          <Input
            type="checkbox"
            onChange={onChecked}
            checked={check}
            value={check}
          />
          By clicking “Sign up” you agree to our Consent Clause for{" "}
          <span
            className={classes.span_red_bold}
            onClick={() => setShowConsentModal(!showConsentModal)}
          >
            Data Collection{" "}
          </span>
          and{" "}
          <span>
            <Link to="/privacy" className={classes.span_red_bold}>
              Privacy Policy
            </Link>
          </span>
        </Label>
      </FormGroup>

      <StatusModal
        status={alertDetails.alertType || "success"}
        open={!!(alertDetails.alertMessage && alertDetails.alertType)}
        heading={alertDetails.title && alertDetails.title}
        text={alertDetails.alertMessage && alertDetails.alertMessage}
        showButton={alertDetails.showButton}
        buttonText={alertDetails.buttonText}
        onButtonClick={alertDetails.buttonClick}
        toggle={() =>
          setAlertDetails({
            alertMessage: "",
            alertType: "",
          })
        }
      ></StatusModal>
      <Button
        className={classes.loginBTN}
        disabled={isLoading || !check}
        data-testid="signup"
      >
        Sign up{" "}
        {isLoading && (
          <Spinner color="white" size={5} style={{ width: 15, height: 15 }} />
        )}
      </Button>
      <h2 className={classes.hr}>
        <span>or</span>
      </h2>

      <DataCollectionModal
        modal={showConsentModal}
        toggle={handleToggleConsentModal}
      />

      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="myAwesomeCookieName2"
        style={{
          background: "#2B373B",
          opacity: "1",
          fontWeight: "600",
          zIndex: 10000,
        }}
        buttonStyle={{
          backgroundColor: "red",
          color: "#fff",
          fontSize: "13px",
          borderRadius: "50px",
          fontWeight: "600",
        }}
        expires={150}
      >
        <span style={{ fontSize: "16px" }}>
          This website uses cookies to offer you the most relevant information.
          By continuing to browse the website, you are agreeing to use our site
          cookies.{" "}
          <Link to="/privacy" style={{ color: "red", fontWeight: "600" }}>
            Read our policy here
          </Link>
        </span>
      </CookieConsent>
    </Form>
  );
};
export default RegisterForm_v2;
