import React, { useState, useContext } from "react";
import Avatar from "src/components/Avatar/Avatar";
import TefRedButton from "src/modules/Opportunity/components/Button/TefRedButton";
import classes from "./ReplyComment.module.css";
import { postReply } from "src/requests/OpportunityRequest";
import { Context } from "src/store";
import { useCurrentUser } from "src/utils/Services/currentUser";

const ReplyComment = ({ commentId, opportunityId }) => {
  const { dispatch } = useContext(Context);
  const [reply, setReply] = useState("");

  const submitReplyHandler = (e) => {
    e.preventDefault();
    const payload = {
      commentId,
      opportunityId,
      response: reply,
    };
    postReply(dispatch, payload);
    setReply("");
  };

  const current_user = useCurrentUser();
  return (
    <form onSubmit={submitReplyHandler}>
      <div className={classes.ReplyContainer}>
        <div className={classes.ReplyAvatar}>
          <Avatar
            src={current_user.imageUrl}
            dimension={30}
            name={`${current_user.firstName} ${current_user.lastName}`}
          />
        </div>
        <div className={classes.ReplyInput}>
          <textarea
            rows={1}
            placeholder="What do you think?"
            value={reply}
            onChange={(e) => setReply(e.target.value)}
          />
        </div>
        <div className={classes.ReplySend}>
          <TefRedButton text="send" />
        </div>
      </div>
    </form>
  );
};

export default ReplyComment;
