import { FILE_UPLOAD } from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import { FileUpload } from "src/constants";
// import axios from "src/utils/axios";
import axios from "axios";

// check if file is image
export const isImageFile = (files, isMulti = false) => {
  if (files.length < 1)
    return { hasError: true, errors: ["No files where added"] };
  const errors = [];
  if (isMulti && files.length > 0) {
    const fileArray = Array.from(files);
    fileArray.forEach((file) => {
      if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {
        errors.push(file);
      }
    });
  } else {
    const singleFile = files[0];
    if (!singleFile.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {
      errors.push(singleFile);
    }
  }
  return { hasError: errors.length > 0, errors };
};

export const isAllowedNonImageFiles = (files, isMulti = false) => {
  // eslint-disable-next-line prefer-regex-literals
  const regex = new RegExp(
    "(.*?).(docx|doc|pdf|xml|bmp|ppt|xls|xlsx|csv|txt|rtf)$"
  );
  if (files.length < 1)
    return { hasError: true, errors: ["No files where added"] };
  const errors = [];
  if (isMulti && files.length > 0) {
    const fileArray = Array.from(files);
    fileArray.forEach((file) => {
      if (!regex.test(file)) {
        errors.push(file);
      }
    });
  } else {
    const singleFile = files[0];
    if (!regex.test(singleFile)) {
      errors.push(singleFile);
    }
  }
  return { hasError: errors.length > 0, errors };
};

export const isAllowedFiles = (files, isMulti = false) => {
  if (files.length < 1)
    return { hasError: true, errors: ["No files where added"] };
  const errors = [];
  if (isMulti && files.length > 0) {
    const fileArray = Array.from(files);
    fileArray.forEach((file) => {
      if (
        !file.name
          .toLowerCase()
          .match(
            /\.(jpg|jpeg|png|gif|rtf|docx|doc|pdf|xml|bmp|ppt|xls|xlsx|csv|txt)$/
          )
      ) {
        errors.push(file);
      }
    });
  } else {
    const singleFile = files[0];
    if (
      !singleFile.name
        .toLowerCase()
        .match(
          /\.(jpg|jpeg|png|gif|rtf|docx|doc|pdf|xml|bmp|ppt|xls|xlsx|csv|txt)$/
        )
    ) {
      errors.push(singleFile);
    }
  }
  return { hasError: errors.length > 0, errors };
};

// upload file
export const UploadFile = (dispatch, isMulti = false, files) => {
  const config = {
    onUploadProgress: function (progressEvent) {},
  };

  const formData = new FormData();
  if (isMulti && files.length > 0) {
    const fileArray = Array.from(files);
    fileArray.forEach((file) => {
      formData.append("file", file);
    });
  } else {
    const fileSingle = files[0];
    formData.append("file", fileSingle);
  }
  return axios
    .post(`${FileUpload.FILE_UPLOAD}`, formData, config)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        const { data, status } = res;
        dispatch(asyncActions(FILE_UPLOAD).success(data.data));
        dispatch(asyncActions(FILE_UPLOAD).loading(false));
        return { status, files: data.data };
      }
    })
    .catch((e) => {
      console.log(e);
      asyncActions(FILE_UPLOAD).failure(true, e.response);
      dispatch(asyncActions(FILE_UPLOAD).loading(true));
      dispatch(asyncActions(FILE_UPLOAD).success([]));
      return { status: e.response.status, files: [] };
    });
};

export const isFileTooLarge = (fileSize, sizeLimit) => sizeLimit > fileSize;
