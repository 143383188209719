import axios from "src/utils/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GET_COMMUNITY_SUGGESTIONS,
  GET_COMMUNITY_CONNECTIONS,
  REQUEST_COMMUNITY_CONNECTION,
  GET_COMMUNITY_PENDING_RECEIVED_CONNECTIONS,
  GET_COMMUNITY_PENDING_SENT_CONNECTIONS,
  ACCEPT_COMMUNITY_CONNECTION,
  REJECT_COMMUNITY_CONNECTION,
} from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import { CommunityURL } from "src/constants";
import { updateQueryStringParameter } from "src/utils/helper";

export const getConnections = (dispatch, payload, pageNumber, searchString) => {
  let url = `${CommunityURL.CONNECTION_URL}/user/${payload}?pageNumber=${pageNumber}&maxItem=9`;
  if (searchString && searchString.length > 2) {
    url = updateQueryStringParameter(url, "search", searchString);
  }
  dispatch(asyncActions(GET_COMMUNITY_CONNECTIONS).loading(true));
  return axios({
    method: "get",
    url,
  })
    .then((response) => {
      dispatch(asyncActions(GET_COMMUNITY_CONNECTIONS).loading(false));
      if (response.status === 200) {
        dispatch(
          asyncActions(GET_COMMUNITY_CONNECTIONS).success(response.data)
        );
        dispatch(asyncActions(GET_COMMUNITY_CONNECTIONS).loading(false));
        // toast("Commented Successfully ✅")
      }
      return response;
    })
    .catch((error) => {
      console.log(error);
      dispatch(asyncActions(GET_COMMUNITY_CONNECTIONS).loading(false));
      dispatch(
        asyncActions(GET_COMMUNITY_CONNECTIONS).failure(true, error.response)
      );
      //   toast("An error occurred, Please try again ")
      return error.response;
    });
};
export const searchConnections = (
  dispatch,
  payload,
  pageNumber,
  searchWord
) => {
  dispatch(asyncActions(GET_COMMUNITY_CONNECTIONS).loading(true));
  return axios({
    method: "get",
    url: `${CommunityURL.CONNECTION_URL}/user/${payload}?pageNumber=${pageNumber}&maxItem=40&search=${searchWord}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_COMMUNITY_CONNECTIONS).loading(false));
      if (response.status === 200) {
        dispatch(
          asyncActions(GET_COMMUNITY_CONNECTIONS).success(response.data)
        );
        dispatch(asyncActions(GET_COMMUNITY_CONNECTIONS).loading(false));
        // toast("Commented Successfully ✅")
      }
      return response;
    })
    .catch((error) => {
      console.log(error);
      dispatch(asyncActions(GET_COMMUNITY_CONNECTIONS).loading(false));
      dispatch(
        asyncActions(GET_COMMUNITY_CONNECTIONS).failure(true, error.response)
      );
      //   toast("An error occurred, Please try again ")
      return error.response;
    });
};

export const getSuggestions = (dispatch, payload, pageNumber, searchString) => {
  dispatch(asyncActions(GET_COMMUNITY_SUGGESTIONS).loading(true));

  let url = `${CommunityURL.CONNECTION_URL}/user/${payload}/suggestions?pageNumber=${pageNumber}&maxItem=9`;
  if (searchString && searchString.length > 2) {
    url = updateQueryStringParameter(url, "search", searchString);
  }

  return axios({
    method: "get",
    url,
  })
    .then((response) => {
      dispatch(asyncActions(GET_COMMUNITY_SUGGESTIONS).loading(false));
      if (response.status === 200) {
        dispatch(
          asyncActions(GET_COMMUNITY_SUGGESTIONS).success(response.data)
        );
        dispatch(asyncActions(GET_COMMUNITY_SUGGESTIONS).loading(false));
        // toast("Commented Successfully ✅")
      }
      return response;
    })
    .catch((error) => {
      console.log(error);
      dispatch(asyncActions(GET_COMMUNITY_SUGGESTIONS).loading(false));
      dispatch(
        asyncActions(GET_COMMUNITY_SUGGESTIONS).failure(true, error.response)
      );
      //   toast("An error occurred, Please try again ")
      return error.response;
    });
};
export const searchSuggestions = (
  dispatch,
  payload,
  pageNumber,
  searchWord
) => {
  dispatch(asyncActions(GET_COMMUNITY_SUGGESTIONS).loading(true));
  return axios({
    method: "get",
    url: `${CommunityURL.CONNECTION_URL}/user/${payload}/suggestions?pageNumber=${pageNumber}&maxItem=40&search=${searchWord}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_COMMUNITY_SUGGESTIONS).loading(false));
      if (response.status === 200) {
        dispatch(
          asyncActions(GET_COMMUNITY_SUGGESTIONS).success(response.data)
        );
        dispatch(asyncActions(GET_COMMUNITY_SUGGESTIONS).loading(false));
        // toast("Commented Successfully ✅")
      }
      return response;
    })
    .catch((error) => {
      console.log(error);
      dispatch(asyncActions(GET_COMMUNITY_SUGGESTIONS).loading(false));
      dispatch(
        asyncActions(GET_COMMUNITY_SUGGESTIONS).failure(true, error.response)
      );
      //   toast("An error occurred, Please try again ")
      return error.response;
    });
};

export const getPendingConnectionReceived = (
  dispatch,
  payload,
  pageNumber,
  status
) => {
  let url = `${CommunityURL.CONNECTION_URL}/user/${payload}/received?pageNumber=${pageNumber}&maxItem=10`;
  if (status) {
    url = updateQueryStringParameter(url, "status", status);
  }

  dispatch(
    asyncActions(GET_COMMUNITY_PENDING_RECEIVED_CONNECTIONS).loading(true)
  );

  return axios({
    method: "get",
    url,
  })
    .then((response) => {
      dispatch(
        asyncActions(GET_COMMUNITY_PENDING_RECEIVED_CONNECTIONS).loading(false)
      );
      if (response.status === 200) {
        dispatch(
          asyncActions(GET_COMMUNITY_PENDING_RECEIVED_CONNECTIONS).success(
            response.data
          )
        );
        dispatch(
          asyncActions(GET_COMMUNITY_PENDING_RECEIVED_CONNECTIONS).loading(
            false
          )
        );
        // toast("Commented Successfully ✅")
      }
      return response;
    })
    .catch((error) => {
      console.log(error);
      dispatch(
        asyncActions(GET_COMMUNITY_PENDING_RECEIVED_CONNECTIONS).loading(false)
      );
      dispatch(
        asyncActions(GET_COMMUNITY_PENDING_RECEIVED_CONNECTIONS).failure(
          true,
          error.response
        )
      );
      //   toast("An error occurred, Please try again ")
      return error.response;
    });
};

export const searchPendingConnectionReceived = (
  dispatch,
  payload,
  pageNumber,
  searchWord
) => {
  dispatch(
    asyncActions(GET_COMMUNITY_PENDING_RECEIVED_CONNECTIONS).loading(true)
  );
  return axios({
    method: "get",
    url: `${CommunityURL.CONNECTION_URL}/user/${payload}/received?pageNumber=${pageNumber}&maxItem=40&search=${searchWord}`,
  })
    .then((response) => {
      dispatch(
        asyncActions(GET_COMMUNITY_PENDING_RECEIVED_CONNECTIONS).loading(false)
      );
      if (response.status === 200) {
        dispatch(
          asyncActions(GET_COMMUNITY_PENDING_RECEIVED_CONNECTIONS).success(
            response.data
          )
        );
        dispatch(
          asyncActions(GET_COMMUNITY_PENDING_RECEIVED_CONNECTIONS).loading(
            false
          )
        );
        // toast("Commented Successfully ✅")
      }
      return response;
    })
    .catch((error) => {
      console.log(error);
      dispatch(
        asyncActions(GET_COMMUNITY_PENDING_RECEIVED_CONNECTIONS).loading(false)
      );
      dispatch(
        asyncActions(GET_COMMUNITY_PENDING_RECEIVED_CONNECTIONS).failure(
          true,
          error.response
        )
      );
      //   toast("An error occurred, Please try again ")
      return error.response;
    });
};

export const getPendingConnectionSent = (
  dispatch,
  payload,
  pageNumber,
  status
) => {
  dispatch(asyncActions(GET_COMMUNITY_PENDING_SENT_CONNECTIONS).loading(true));

  let url = `${CommunityURL.CONNECTION_URL}/user/${payload}/sent?pageNumber=${pageNumber}&maxItem=10`;
  if (status) {
    url = updateQueryStringParameter(url, "status", status);
  }
  return axios({
    method: "get",
    url,
  })
    .then((response) => {
      dispatch(
        asyncActions(GET_COMMUNITY_PENDING_SENT_CONNECTIONS).loading(false)
      );
      if (response.status === 200) {
        dispatch(
          asyncActions(GET_COMMUNITY_PENDING_SENT_CONNECTIONS).success(
            response.data
          )
        );
        dispatch(
          asyncActions(GET_COMMUNITY_PENDING_SENT_CONNECTIONS).loading(false)
        );
        // toast("Commented Successfully ✅")
      }
      return response;
    })
    .catch((error) => {
      console.log(error);
      dispatch(
        asyncActions(GET_COMMUNITY_PENDING_SENT_CONNECTIONS).loading(false)
      );
      dispatch(
        asyncActions(GET_COMMUNITY_PENDING_SENT_CONNECTIONS).failure(
          true,
          error.response
        )
      );
      //   toast("An error occurred, Please try again ")
      return error.response;
    });
};

export const searchPendingConnectionSent = (
  dispatch,
  payload,
  pageNumber,
  searchWord
) => {
  dispatch(asyncActions(GET_COMMUNITY_PENDING_SENT_CONNECTIONS).loading(true));
  return axios({
    method: "get",
    url: `${CommunityURL.CONNECTION_URL}/user/${payload}/sent?pageNumber=${pageNumber}&maxItem=40&search=${searchWord}`,
  })
    .then((response) => {
      dispatch(
        asyncActions(GET_COMMUNITY_PENDING_SENT_CONNECTIONS).loading(false)
      );
      if (response.status === 200) {
        dispatch(
          asyncActions(GET_COMMUNITY_PENDING_SENT_CONNECTIONS).success(
            response.data
          )
        );
        dispatch(
          asyncActions(GET_COMMUNITY_PENDING_SENT_CONNECTIONS).loading(false)
        );
        // toast("Commented Successfully ✅")
      }
      return response;
    })
    .catch((error) => {
      console.log(error);
      dispatch(
        asyncActions(GET_COMMUNITY_PENDING_SENT_CONNECTIONS).loading(false)
      );
      dispatch(
        asyncActions(GET_COMMUNITY_PENDING_SENT_CONNECTIONS).failure(
          true,
          error.response
        )
      );
      //   toast("An error occurred, Please try again ")
      return error.response;
    });
};

export const createConnection = (dispatch, fromUserId, toUserId) => {
  dispatch(asyncActions(REQUEST_COMMUNITY_CONNECTION).loading(true));
  return axios({
    method: "post",
    url: `${CommunityURL.CONNECTION_URL}`,
    data: {
      fromUserId,
      toUserId,
    },
  })
    .then((response) => {
      dispatch(asyncActions(REQUEST_COMMUNITY_CONNECTION).loading(false));
      if (response.status === 200) {
        dispatch(
          asyncActions(REQUEST_COMMUNITY_CONNECTION).success(response.data)
        );
        dispatch(asyncActions(REQUEST_COMMUNITY_CONNECTION).loading(false));
        toast("Connection Request Sent ✅");
      }
      return response;
    })
    .catch((error) => {
      console.log(error);
      dispatch(asyncActions(REQUEST_COMMUNITY_CONNECTION).loading(false));
      dispatch(
        asyncActions(REQUEST_COMMUNITY_CONNECTION).failure(true, error.response)
      );
      toast("An error occurred, Please try again ");
      return error.response;
    });
};

export const acceptConnection = (dispatch, connectionId) => {
  dispatch(asyncActions(ACCEPT_COMMUNITY_CONNECTION).loading(true));
  return axios({
    method: "put",
    url: `${CommunityURL.CONNECTION_URL}/${connectionId}?status=accepted`,
  })
    .then((response) => {
      dispatch(asyncActions(ACCEPT_COMMUNITY_CONNECTION).loading(false));
      if (response.status === 200) {
        dispatch(
          asyncActions(ACCEPT_COMMUNITY_CONNECTION).success(response.data)
        );
        dispatch(asyncActions(ACCEPT_COMMUNITY_CONNECTION).loading(false));
        toast("Connection Request Accepted ✅");
      }
      return response;
    })
    .catch((error) => {
      console.log(error);
      dispatch(asyncActions(ACCEPT_COMMUNITY_CONNECTION).loading(false));
      dispatch(
        asyncActions(ACCEPT_COMMUNITY_CONNECTION).failure(true, error.response)
      );
      toast("An error occurred, Please try again ");
      return error.response;
    });
};

export const rejectConnection = (dispatch, connectionId) => {
  dispatch(asyncActions(REJECT_COMMUNITY_CONNECTION).loading(true));
  return axios({
    method: "put",
    url: `${CommunityURL.CONNECTION_URL}/${connectionId}?status=declined`,
  })
    .then((response) => {
      dispatch(asyncActions(REJECT_COMMUNITY_CONNECTION).loading(false));
      if (response.status === 200) {
        dispatch(
          asyncActions(REJECT_COMMUNITY_CONNECTION).success(response.data)
        );
        dispatch(asyncActions(REJECT_COMMUNITY_CONNECTION).loading(false));
        toast("Connection Request Declined ✅");
      }
      return response;
    })
    .catch((error) => {
      console.log(error);
      dispatch(asyncActions(REJECT_COMMUNITY_CONNECTION).loading(false));
      dispatch(
        asyncActions(REJECT_COMMUNITY_CONNECTION).failure(true, error.response)
      );
      toast("An error occurred, Please try again ");
      return error.response;
    });
};

export const cancelConnection = (dispatch, connectionId, status) => {
  dispatch(asyncActions(REJECT_COMMUNITY_CONNECTION).loading(true));
  return axios({
    method: "put",
    url: `${CommunityURL.CONNECTION_URL}/${connectionId}?status=canceled`,
  })
    .then((response) => {
      dispatch(asyncActions(REJECT_COMMUNITY_CONNECTION).loading(false));
      if (response.status === 200) {
        dispatch(
          asyncActions(REJECT_COMMUNITY_CONNECTION).success(response.data)
        );
        dispatch(asyncActions(REJECT_COMMUNITY_CONNECTION).loading(false));
        toast("Connection Cancelled ✅");
      }
      return response;
    })
    .catch((error) => {
      console.log(error);
      dispatch(asyncActions(REJECT_COMMUNITY_CONNECTION).loading(false));
      dispatch(
        asyncActions(REJECT_COMMUNITY_CONNECTION).failure(true, error.response)
      );
      toast("An error occurred, Please try again ");
      return error.response;
    });
};
