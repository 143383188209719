import React, { useState, useEffect, useContext } from "react";
import RightBar from "src/modules/profile/components/RightBar/RightBar";
import Layout from "src/components/Layout/Layout";
import classes from "./OpportunityDetails.module.css";
import { Popover, PopoverBody } from "reactstrap";
import Tag from "src/modules/Opportunity/components/Tag/Tag";
import OpportunityPoster from "src/modules/Opportunity/components/OpportunityPoster/OpportunityPoster";
import TEFButton from "src/modules/Opportunity/components/Button/TEFButton";
import SocialMediaButton from "src/modules/Opportunity/components/Button/SocialMediaButton";
import Logo from "src/modules/Opportunity/assets/logo_globe.png";
import TefRedButton from "src/modules/Opportunity/components/Button/TefRedButton";
import AlarmClock from "src/modules/Opportunity/assets/alarm-clock.svg";
import SummaryCard from "src/modules/Opportunity/components/SummaryCard/SummaryCard";
import { BsTag } from "react-icons/bs";
import { FiClock } from "react-icons/fi";
import { RiWallet2Line, RiFlagLine } from "react-icons/ri";
import { TiArrowSortedDown } from "react-icons/ti";
import { FaTwitter, FaEllipsisV, FaCommentAlt } from "react-icons/fa";
import {
  AiFillLinkedin,
  AiFillHome,
  AiOutlineEdit,
  AiOutlineDelete,
} from "react-icons/ai";
import { IoIosArrowRoundForward } from "react-icons/io";
import FullDescription from "src/modules/Opportunity/components/FullDescription/FullDescription";
import SubHeading from "src/modules/Opportunity/components/SubHeading/SubHeading";
import RelatedOpportunity from "src/modules/Opportunity/components/RelatedOpportunity/RelatedOpportunity";
import FlagOpportunityModal from "src/modules/Opportunity/components/FlagOpportunityModal/FlagOpportunityModal";
import { LinkedinShareButton, TwitterShareButton } from "react-share";
import Swal from "sweetalert2";
import { Context } from "src/store";
import {
  getOpportunity,
  deleteOpportunity,
  getComments,
  setReminder,
  increasePopularity,
  cancelReminder,
} from "src/requests/OpportunityRequest";
import { getInterests } from "src/requests/onboarding";
import Loader from "react-loader-spinner";
import moment from "moment";
import { useHistory, useParams, Link } from "react-router-dom";
import InputComment from "src/modules/Opportunity/components/Comments/InputComment";
import ViewComment from "src/modules/Opportunity/components/Comments/ViewComment";
import ShareOpportunityModal from "src/modules/Opportunity/components/ShareOpportunityModal/ShareOpportunityModal";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import { useCurrentUser } from "src/utils/Services/currentUser";

const OpportunityDetails = () => {
  const { state, dispatch } = useContext(Context);
  const { id } = useParams();

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [showFlagModal, setShowFlagModal] = useState(false);
  const [showShareOnTEFModal, setShowShareOnTEFModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [interestTags, setInterestTags] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (id) {
      getOpportunity(dispatch, id);
      getComments(dispatch, id);
      getInterests(dispatch).then((res) => {
        const { status, data } = res;
        if (status === 200 || status === 201) {
          const interests = data.map((interest) => {
            return {
              interestId: interest.id,
              interestName: interest.name,
            };
          });
          setInterestTags(interests);
        } else {
          setInterestTags([]);
        }
      });
    }
    // eslint-disable-next-line
  }, [id, reload]);

  const current_user = useCurrentUser();

  const { opportunity } = state.Opportunities;
  const { relatedTopicIds } = opportunity;

  useEffect(() => {
    const view = document.getElementById("main-view");
    if (view) {
      view.scrollIntoView();
    }
  }, [opportunity]);

  const handleMoreOption = () => setPopoverOpen(!popoverOpen);
  const toggleShowFlagModal = () => setShowFlagModal(!showFlagModal);
  const handleShareOnTEFConnect = () =>
    setShowShareOnTEFModal(!showShareOnTEFModal);
  const reloadHandler = () => {
    setReload(!reload);
  };

  const canEdit = () =>
    current_user.userId === opportunity.createdByDetails.userId &&
    !opportunity.isVerified
      ? editable()
      : notEditable();
  const editable = () => (
    <>
      <div className={classes.Edit}>
        <Link to={`/opportunities/${id}/edit`} className={classes.Link}>
          <AiOutlineEdit size={13} />
          <p>Edit Opportunity</p>
        </Link>
      </div>
      <div className={classes.More} id="MoreOption">
        <FaEllipsisV
          onClick={handleMoreOption}
          style={{ cursor: "pointer" }}
          size={10}
        />
      </div>
      <Popover
        className={`p-0 border-0 d-none d-md-block`}
        trigger="legacy"
        placement="bottom"
        isOpen={popoverOpen}
        target="MoreOption"
        toggle={handleMoreOption}
      >
        <PopoverBody>
          <div className={classes.MoreOptions}>
            <div className={classes.Option} onClick={toggleShowFlagModal}>
              <RiFlagLine /> Flag Opportunity
            </div>
            <div className={classes.Option} onClick={deleteOpportunityHandler}>
              <AiOutlineDelete /> Delete Opportunity
            </div>
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
  const notEditable = () => (
    <div className={classes.Uneditable} onClick={toggleShowFlagModal}>
      <RiFlagLine /> Flag Opportunity
    </div>
  );
  const deleteOpportunityHandler = () =>
    Swal.queue([
      {
        title: "Delete Opportunity",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        showLoaderOnConfirm: true,
        preConfirm: () => deleteOpportunity(dispatch, id, history),
      },
    ]);

  const deadline = () => moment(opportunity.deadline).format("MMMM DD YYYY");

  const flagged = () => (
    <div className={classes.Flagged}>This opportunity is Flagged!</div>
  );

  const _increasePopularity = () => increasePopularity(dispatch, id);

  const _setReminder = (reminderType, reminderTypeString) =>
    setReminder(dispatch, id, reminderType).then(() =>
      Swal.fire(
        `You'll receive a reminder email about this opportunity a ${reminderTypeString} before the deadline`
      )
    );

  const _cancelReminder = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not receive reminder about this opportunity anymore",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel reminder!",
      cancelButtonText: "Go Back",
    }).then((result) => {
      if (result.value) {
        cancelReminder(dispatch, id).then((res) => {
          if (res.status && res.status === 200) {
            Swal.fire({
              icon: "error",
              title: "Cancelled!",
              text: "You have cancelled reminder about this opportunity",
              confirmButtonColor: "#FC5A5A",
            });
          }
        });
      }
    });
  };

  const MainOpportunity = () => (
    <div className={classes.sections} id="main-view">
      {opportunity.isFlagged ? flagged() : null}
      <section className={`${classes.Banner} ${classes.sectionContainer}`}>
        <img src={opportunity && opportunity.fileName} alt="Banner" />
      </section>
      <section className={`${classes.Header} ${classes.sectionContainer}`}>
        <div className={classes.HeaderLeft}>
          <h2 className={classes.MainTitle}>
            {opportunity && opportunity.title}
          </h2>
          <div className={classes.Tag}>
            {relatedTopicIds &&
              interestTags &&
              interestTags
                .filter((relevantTag) =>
                  relatedTopicIds.includes(relevantTag.interestId)
                )
                .map((tag) => (
                  <Tag key={tag.interestId} tagName={tag.interestName} />
                ))}
          </div>
          <div className={classes.PostedBy}>
            <OpportunityPoster
              name={`${
                opportunity.createdByDetails &&
                opportunity.createdByDetails.firstName
              } ${
                opportunity.createdByDetails &&
                opportunity.createdByDetails.lastName
              }`}
              deadline={deadline()}
              category={opportunity.category && opportunity.category.name}
              posterPhoto={
                opportunity.createdByDetails &&
                opportunity.createdByDetails.imageUrl
              }
            />
          </div>
          <div className={classes.ShareButton}>
            <span onClick={handleShareOnTEFConnect}>
              <TEFButton logo={Logo} label="Share on TEFConnect" />
            </span>
            <TwitterShareButton
              url={window.location.href}
              title={opportunity && opportunity.title}
              style={{ outline: "none", width: "6rem" }}
            >
              <SocialMediaButton
                logo={<FaTwitter color={`white`} />}
                label="Tweet"
                bgColor="#1da1f2"
                textColor="white"
              />
            </TwitterShareButton>
            <LinkedinShareButton
              url={window.location.href}
              title={opportunity && opportunity.title}
              summary={opportunity.title}
              style={{ outline: "none", width: "6rem" }}
            >
              <SocialMediaButton
                logo={<AiFillLinkedin color={"white"} />}
                label="Post"
                bgColor="#0077b5"
                textColor="white"
              />
            </LinkedinShareButton>
          </div>
          <ShareOpportunityModal
            toggle={handleShareOnTEFConnect}
            modal={showShareOnTEFModal}
          />
        </div>

        <div className={classes.HeaderRight}>
          <div className={classes.ApplyAndReminder}>
            <div className={classes.ApplyButton} onClick={_increasePopularity}>
              <a
                href={opportunity && opportunity.opportunityLink}
                className={classes.ApplyNow}
                target="_blank"
                rel="noreferrer"
              >
                <TefRedButton text={`Apply Now`} />
              </a>
            </div>
            <div
              className={
                opportunity.isReminderSet
                  ? `${classes.ReminderContainer} ${classes.Reminded}`
                  : `${classes.ReminderContainer} ${classes.NotReminded}`
              }
              id="ReminderOption"
            >
              <div className={classes.Reminder}>
                <div>
                  <img src={AlarmClock} alt="Alarm Clock" />
                </div>
                <p>Reminder Options</p>
                <TiArrowSortedDown />
              </div>
            </div>

            <Popover
              className={`p-0 border-0 d-none d-md-block`}
              trigger="legacy"
              placement="bottom"
              isOpen={popoverOpen}
              target="ReminderOption"
              toggle={handleMoreOption}
            >
              <PopoverBody>
                <div className={classes.MoreOptions}>
                  <div
                    className={classes.Option}
                    onClick={() => _setReminder(200, "Day")}
                  >
                    A Day before
                  </div>
                  <div
                    className={classes.Option}
                    onClick={() => _setReminder(201, "Week")}
                  >
                    A Week before
                  </div>
                  <div
                    className={classes.Option}
                    onClick={() => _setReminder(202, "Month")}
                  >
                    A Month before
                  </div>
                  <div
                    className={classes.Option}
                    onClick={() => _cancelReminder()}
                  >
                    Cancel Reminder
                  </div>
                </div>
              </PopoverBody>
            </Popover>
          </div>
          <div className={`${classes.EditOpportunity} `}>
            {canEdit()}
            <FlagOpportunityModal
              modal={showFlagModal}
              toggle={toggleShowFlagModal}
              opportunityId={id}
              currentUrl={location.pathname}
            />
          </div>
        </div>
      </section>
      <section className={`${classes.Summary} ${classes.sectionContainer}`}>
        <SubHeading text={`Summary`} icon={<AiFillHome color={`#26292d`} />} />
        <div className={classes.SummaryText}>{opportunity.shortSummary}</div>
        <div className={classes.SummaryHighlight}>
          <SummaryCard
            icon={<FiClock size={30} />}
            info="Deadline"
            details={deadline()}
          />
          <SummaryCard
            icon={<RiWallet2Line size={30} />}
            info={` $${opportunity.totalPrice.toLocaleString()}`}
            details="Total Prize"
          />
          <SummaryCard
            icon={<BsTag size={30} />}
            info={opportunity.opportunityType.name}
            details="Opportunity Type"
          />
        </div>
        <div className={classes.FullDescription}>
          <FullDescription
            fullDescription={opportunity && opportunity.fullDescription}
            eligibilityRequirement={
              opportunity && opportunity.eligibilityRequirement
            }
            benefits={opportunity && opportunity.benefits}
            contactEmail={opportunity && opportunity.contactEmail}
            officialWebsite={opportunity && opportunity.officialWebsite}
          />
        </div>
      </section>
      <section className={`${classes.Comments} ${classes.sectionContainer}`}>
        <SubHeading text={`Comments`} icon={<FaCommentAlt />} />
        <InputComment
          opportunityId={id}
          dispatch={dispatch}
          reload={reloadHandler}
        />
        <ViewComment opportunityId={id} reloadComponent={reload} />
      </section>

      <section
        className={`${classes.RelatedOpportunities} ${classes.sectionContainer}`}
      >
        <SubHeading text={`Related Opportunities`} />
        <RelatedOpportunity relatedOpportunitiesIds={opportunity.interestIds} />
        {/* <RelatedOpportunity
          relatedOpportunitiesIds={opportunity.relatedTopicIds}
        /> */}
      </section>
    </div>
  );

  const LoaderComponent = (
    <div className={classes.Loader}>
      <Loader type={`Oval`} color={`red`} />
    </div>
  );
  return (
    <Layout SideContent={<RightBar />}>
      <div className="create-opp-breadcrumb">
        <Link to="/home" style={{ fontWeight: "bold" }}>
          Home <IoIosArrowRoundForward />
        </Link>
        <Link to="/opportunities" style={{ fontWeight: "bold" }}>
          Opportunities <IoIosArrowRoundForward />
        </Link>
        <span>{opportunity.title}</span>
      </div>
      {opportunity && opportunity.createdByDetails
        ? MainOpportunity(opportunity.createdByDetails)
        : LoaderComponent}
    </Layout>
  );
};

export default ifOnboardedThenShow(OpportunityDetails);
