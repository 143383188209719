import { asyncActionName } from "src/utils/AsyncUtils";
import {
  GET_ALL_DUEDILLIGENCE_DOCUMENTS,
  GET_ALL_SEEDCAPITAL_DOCUMENTS,
  GET_ALL_BUSINESSPLAN_DOCUMENTS,
  GET_BANKDETAILS,
  GET_BUSINESSPLANS_REQUIRED_DOCUMENTS,
  GET_SEEDCAPITAL_REQUIRED_DOCUMENTS,
  GET_DUEDILLIGENCE_REQUIRED_DOCUMENTS,
} from "src/actions";
import { initialState } from "src/store";

const DmsReducer = (state = initialState.dms, action) => {
  switch (action.type) {
    case asyncActionName(GET_ALL_DUEDILLIGENCE_DOCUMENTS).loading:
      return { ...state, loadingDueDilligence: action.payload };

    case asyncActionName(GET_ALL_DUEDILLIGENCE_DOCUMENTS).success:
      return {
        ...state,
        dueDilligence: action.payload,
        success: true,
      };

    case asyncActionName(GET_ALL_SEEDCAPITAL_DOCUMENTS).loading:
      return { ...state, loadingSeedCapitalDocuments: action.payload };

    case asyncActionName(GET_ALL_SEEDCAPITAL_DOCUMENTS).success:
      return {
        ...state,
        seedCapital: action.payload,
        success: true,
      };

    case asyncActionName(GET_ALL_BUSINESSPLAN_DOCUMENTS).loading:
      return { ...state, loadingBusinessPlans: action.payload };

    case asyncActionName(GET_ALL_BUSINESSPLAN_DOCUMENTS).success:
      return {
        ...state,
        businessPlan: action.payload,
        success: true,
      };

    case asyncActionName(GET_BANKDETAILS).loading:
      return { ...state, loadingBankDetails: action.payload };

    case asyncActionName(GET_BANKDETAILS).success:
      return {
        ...state,
        bankDetails: action.payload,
        success: true,
      };
    case asyncActionName(GET_DUEDILLIGENCE_REQUIRED_DOCUMENTS).success:
      return {
        ...state,
        dueDilligenceRequiredDocuments: action.payload,
        success: true,
      };
    case asyncActionName(GET_SEEDCAPITAL_REQUIRED_DOCUMENTS).success:
      return {
        ...state,
        seedCapitalRequiredDocuments: action.payload,
        success: true,
      };
    case asyncActionName(GET_BUSINESSPLANS_REQUIRED_DOCUMENTS).success:
      return {
        ...state,
        businessPlansRequiredDocuments: action.payload,
        success: true,
      };
    default:
      return state;
  }
};
export default DmsReducer;
