import React from "react";
import { Card } from "reactstrap";
import "./StatsCard.scss";
import { _getStatusColor } from "src/modules/mentorship/components/GoalDetails/GoalDetails";

const StatsCard = (props) => {
  return (
    <Card className={`stats-card`}>
      <h3 className="stats-figure">{props.number}</h3>
      <p className="stats-text">{props.text}</p>
      <div className={`stats-rect ${_getStatusColor(props.status)}`}>{""}</div>
    </Card>
  );
};

StatsCard.propTypes = {};

export default StatsCard;
