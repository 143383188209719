import React from "react";

import { Button } from "reactstrap";
import FormInput from "src/modules/Tef-Dms/components/Form/FormInput";

const OperationalPlans = ({ operationalPlans, setOperationalPlans }) => {
  const handleChange = (e) => {
    setOperationalPlans({
      ...operationalPlans,
      [e.target.name]: e.target.value,
    });
  };

  const handleDynamicInput = (key, i, e) => {
    const newArray = key;
    newArray[i] = e.target.value;
    setOperationalPlans({
      ...operationalPlans,
      [key]: newArray,
    });
  };

  const handleMultipleDynamicInput = (array, key, i, e) => {
    const newArray = array;
    newArray[i][key] = e.target.value;
    setOperationalPlans({
      ...operationalPlans,
      [key]: newArray,
    });
  };

  const addRow = (key, value) => {
    const newArray = key;
    newArray.push(value);
    setOperationalPlans({
      ...operationalPlans,
      [key]: newArray,
    });
  };

  return (
    <React.Fragment>
      <FormInput
        label="Description of Operations"
        getFormInputValue={(e) => handleChange(e)}
        value={operationalPlans.descriptionOfOperations}
        name="descriptionOfOperations"
      />
      <div style={{ fontSize: "0.75rem" }}>
        <div className="mt-3">
          {operationalPlans.keyResources.map((_, index) => (
            <div className="d-flex align-items-center" key={index}>
              <FormInput
                key={index}
                getFormInputValue={(e) =>
                  handleDynamicInput(operationalPlans.keyResources, index, e)
                }
                label="Key Resources:"
                placeholder=""
              />
            </div>
          ))}
          <Button
            type="button"
            onClick={() => addRow(operationalPlans.keyResources, "")}
            style={{ fontSize: "0.75em" }}
            className="btn btn-primary text-white px-2 py-1 border-0 mt-2"
          >
            Add Resource
          </Button>
        </div>
        <div className="mt-3">
          <label>Suppliers: </label>
          {operationalPlans.suppliers.map((_, index) => (
            <div className="d-flex align-items-center" key={index}>
              <FormInput
                key={index}
                getFormInputValue={(e) =>
                  handleDynamicInput(operationalPlans.suppliers, index, e)
                }
                label="Suppliers::"
                placeholder=""
              />
            </div>
          ))}
          <Button
            type="button"
            onClick={() => addRow(operationalPlans.suppliers, "")}
            style={{ fontSize: "0.75em" }}
            className="btn btn-primary text-white px-2 py-1 border-0 mt-2"
          >
            Add Supplier
          </Button>
        </div>
        <div className="mt-5">
          <label>Raw Materials/Consumables Needed/Supplies</label>
          <table className="table table-bordered text-center">
            <thead>
              <tr>
                <th scope="row">Materials</th>
                <th>Description/ uses</th>
                <th>Key Supplier</th>
              </tr>
            </thead>
            .
            <tbody>
              {operationalPlans.rawMaterials.map((item, index) => (
                <tr key={index}>
                  <td>
                    <FormInput
                      key={index}
                      getFormInputValue={(e) =>
                        handleMultipleDynamicInput(
                          operationalPlans.rawMaterials,
                          "materials",
                          index,
                          e
                        )
                      }
                      placeholder=""
                      value={item.materials}
                    />
                  </td>
                  <td>
                    <FormInput
                      key={index}
                      getFormInputValue={(e) =>
                        handleMultipleDynamicInput(
                          operationalPlans.rawMaterials,
                          "description",
                          index,
                          e
                        )
                      }
                      placeholder=""
                      value={item.description}
                    />
                  </td>
                  <td>
                    <FormInput
                      key={index}
                      getFormInputValue={(e) =>
                        handleMultipleDynamicInput(
                          operationalPlans.rawMaterials,
                          "source",
                          index,
                          e
                        )
                      }
                      placeholder=""
                      value={item.source}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button
            type="button"
            onClick={() => addRow(operationalPlans.rawMaterials, "")}
            style={{ fontSize: "0.75em" }}
            className="btn btn-primary text-white px-2 py-1 border-0 mt-2"
          >
            Add row
          </Button>
        </div>
        <div className="mt-5">
          <label>List of Capital Equipments</label>
          <table className="table table-bordered text-center">
            <thead>
              <tr>
                <th scope="row">Equipment</th>
                <th>Description/ uses</th>
                <th>Key Supplier</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {operationalPlans.capitalEquipments.map((item, index) => (
                <tr key={index}>
                  <td>
                    <FormInput
                      key={index}
                      getFormInputValue={(e) =>
                        handleMultipleDynamicInput(
                          operationalPlans.capitalEquipments,
                          "equipment",
                          index,
                          e
                        )
                      }
                      placeholder=""
                      value={item.equipment}
                    />
                  </td>
                  <td>
                    <FormInput
                      key={index}
                      getFormInputValue={(e) =>
                        handleMultipleDynamicInput(
                          operationalPlans.capitalEquipments,
                          "description",
                          index,
                          e
                        )
                      }
                      placeholder=""
                      value={item.description}
                    />
                  </td>
                  <td>
                    <FormInput
                      key={index}
                      getFormInputValue={(e) =>
                        handleMultipleDynamicInput(
                          operationalPlans.capitalEquipments,
                          "source",
                          index,
                          e
                        )
                      }
                      placeholder=""
                      value={item.source}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button
            type="button"
            onClick={() => addRow(operationalPlans.capitalEquipments, "")}
            style={{ fontSize: "0.75em" }}
            className="btn btn-primary text-white px-2 py-1 border-0 mt-2"
          >
            Add row
          </Button>
        </div>
        <div className="mt-5">
          <label>List of Consumables</label>
          <table className="table table-bordered text-center">
            <thead>
              <tr>
                <th scope="row">Materials</th>
                <th>Description/ uses</th>
                <th>Key Supplier</th>
              </tr>
            </thead>
            <tbody>
              {operationalPlans.consumablesList.map((item, index) => (
                <tr key={index}>
                  <td>
                    <FormInput
                      key={index}
                      getFormInputValue={(e) =>
                        handleMultipleDynamicInput(
                          operationalPlans.consumablesList,
                          "materials",
                          index,
                          e
                        )
                      }
                      placeholder=""
                      value={item.materials}
                    />
                  </td>
                  <td>
                    <FormInput
                      key={index}
                      getFormInputValue={(e) =>
                        handleMultipleDynamicInput(
                          operationalPlans.consumablesList,
                          "description",
                          index,
                          e
                        )
                      }
                      placeholder=""
                      value={item.description}
                    />
                  </td>
                  <td>
                    <FormInput
                      key={index}
                      getFormInputValue={(e) =>
                        handleMultipleDynamicInput(
                          operationalPlans.consumablesList,
                          "source",
                          index,
                          e
                        )
                      }
                      placeholder=""
                      value={item.source}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button
            type="button"
            onClick={() => addRow(operationalPlans.consumablesList, "")}
            style={{ fontSize: "0.75em" }}
            className="btn btn-primary text-white px-2 py-1 border-0 mt-2"
          >
            Add row
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OperationalPlans;
