import React, { useContext, useState } from "react";
import classes from "./coursemate.module.scss";
import ReactAvatar from "react-avatar";
import Avatars from "src/components/Avatar/Avatar";
import { groupInvite } from "src/requests/StudyGroupsRequest";
import queryString from "query-string";
import { Context } from "src/store";
import Loader from "react-loader-spinner";

const CoursemateItem = ({
  firstName,
  lastName,
  imageUrl,
  email,
  userId,
  isAdmin,
  isGroupMember,
  adminId,
  getInvitesSent,
  isInvitee,
}) => {
  const { groupId } = queryString.parse(location.search);
  const [loadingInvite, setLoadingInvite] = useState(false);
  const { dispatch } = useContext(Context);
  const [desc, setDesc] = useState("Invite to group");

  const _onInvite = () => {
    setLoadingInvite(true);

    const payload = {
      groupId: groupId,
      inviteedByUserId: adminId,
      inviteeUserId: userId,
      inviteeEmail: email,
    };
    groupInvite(dispatch, payload).then((res) => {
      // getInvitesSent();
      setLoadingInvite(false);
      setDesc("Pending request");
    });
  };

  useState(() => {
    if (isInvitee && isInvitee === true) {
      setDesc("Pending request");
    }
  }, [isInvitee]);

  return (
    <div className={classes.coursemateItemC}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {imageUrl ? (
          <div className={classes.avatar}>
            <Avatars dimension={40} src={imageUrl} />
          </div>
        ) : (
          <ReactAvatar
            name={`${firstName} ${lastName}`}
            round
            size={40}
            className={classes.avatar}
          />
        )}

        <div className={classes.name}>
          <h5>
            {firstName} {lastName}
          </h5>
          {isAdmin && !isGroupMember && (
            <div
              onClick={!isInvitee ? () => _onInvite() : () => {}}
              className={classes.desc}
              style={{ backgroundColor: isInvitee ? "var(--warning)" : "" }}
            >
              {desc}
              {loadingInvite && (
                <Loader type="Oval" height={15} width={15} color="red" />
              )}
            </div>
          )}
        </div>
      </div>
      <div className={classes.clear}></div>
    </div>
  );
};

export default CoursemateItem;
