import React, { useState } from "react";
import { Input, Form, Button, Row, Col, InputGroup, Spinner } from "reactstrap";
import classes from "./LandingPage.module.scss";
import img1 from "src/assets/img/landingPage/phone-message.webp";
import img2 from "src/assets/img/landingPage/pin.webp";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { toast } from "react-toastify";

const NewsLetter = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const _handleSuccess = (message, status) => {
    if (!success) {
      toast(message);
      setSuccess(status);
    }
    console.log(status);
    setEmail("");
  };
  const _handleError = (message, status) => {
    if (!error) {
      toast(message);
      setError(status);
    }
  };

  const _clear = () => {
    setError(null);
    setSuccess(null);
  };
  return (
    <div className={classes.newsLetterContainer}>
      <Row style={{ alignItems: "center" }}>
        <Col md={3} className="mt-3">
          <div className={classes.t}>
            <h4>Newsletter</h4>
            <span>Get the latest news and updates</span>
          </div>
        </Col>

        <Col md={3} className="mt-3">
          <MailchimpSubscribe
            url={process.env.REACT_APP_MAIL_CHIMP_URL}
            render={({ subscribe, status, message }) => {
              status === "success" && _handleSuccess(message, status);
              status === "error" && _handleError(message, status);
              return (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    _clear();
                    subscribe({ EMAIL: email });
                  }}
                >
                  <InputGroup>
                    <Input
                      placeholder="Your email"
                      style={{ flex: 1 }}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      value={email}
                    />
                    <Button
                      disabled={!email || status === "sending"}
                      type="submit"
                    >
                      Subscribe{" "}
                      {status === "sending" && (
                        <Spinner style={{ height: "15px", width: "15px" }} />
                      )}
                    </Button>
                  </InputGroup>
                </Form>
              );
            }}
          />
        </Col>

        <Col md={3} className="mt-3">
          <div className={classes.contact}>
            <div className={classes.img_c}>
              <img src={img1} alt="img" className={`img-fluid`} />
            </div>
            <div>
              <h4>{process.env.REACT_APP_WHATSAPP_CONTACT_NUMBER}</h4>
              <span>{process.env.REACT_APP_SUPPORT_EMAIL}</span>
            </div>
          </div>
        </Col>

        <Col md={3} className="mt-3">
          <div className={classes.contact}>
            <div className={classes.img_c}>
              <img src={img2} alt="img" className={`img-fluid`} />
            </div>

            <div>
              <span>1, Macgregor Road, Ikoyi, Lagos</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NewsLetter;
