/* eslint-disable react/jsx-key */
import React, { useState, useEffect, useContext } from "react";
import BorderedAvatar from "src/components/BorderedAvatar/BorderedAvatar";
import MoreViewers from "src/modules/Discover/components/TefLearningResources/LearningResourcesDetail/components/MoreViewers/MoreViewers";
import classes from "./Viewers.module.css";
import { getUserLightProfile } from "src/requests/ProfileRequest";
import { Context } from "src/store";

const Viewers = ({ viewersList, fetchViewers }) => {
  const { dispatch } = useContext(Context);
  const [stackedViewers, setStackedViewers] = useState(null);

  useEffect(() => {
    _getViewersProfile(viewersList);
  }, [viewersList]);

  const getTopFourViewers = (allToolViewers) => {
    setStackedViewers(
      allToolViewers.slice(0, Math.min(4, allToolViewers.length))
    );
  };

  const _getViewersProfile = (viewersList) => {
    if (fetchViewers) {
      getUserLightProfile(dispatch, viewersList).then((res) => {
        if (res.status === 200) {
          const { data } = res.data;
          getTopFourViewers(data);
        }
      });
    }
  };
  return (
    <div className={classes.StackedAvatarContainer}>
      {stackedViewers &&
        stackedViewers.map((viewer, i) => (
          <span className={classes.StackAvatar}>
            <BorderedAvatar
              src={viewer && viewer.imageUrl}
              name={`${viewer && viewer.firstName} ${
                viewer && viewer.lastName
              }`}
              width={30}
              height={35}
              border="2px solid white"
              key={i}
            />
          </span>
        ))}
      {viewersList && viewersList.length > 4 && (
        <MoreViewers howManyMore={+viewersList.length - 4} />
      )}
    </div>
  );
};

export default Viewers;
