import React from "react";
// import TefRedButton from "src/modules/Alumni/Components/Button/TefRedButton";
import classes from "./InputComment.module.css";
import jwt_decode from "src/utils/jwt-decode";
import { Button, Spinner } from "reactstrap";
import Avatar from "src/components/Avatar/Avatar";
import Loader from "src/components/Loader/Loader";
import ViewReplies from "./ViewReplies";

const ReplyComment = ({
  submitHandler,
  inputHandler,
  commentData,
  postingReply,
  idx,
  loadingReplies,
  replies,
}) => {
  const cUser = localStorage.getItem("current_user");
  const current_user = jwt_decode(cUser);

  return (
    <>
      <form
        key={idx}
        onSubmit={submitHandler}
        className="hide-reply-button"
        data-formId={`form-${idx}`}
        data-commentId={idx}
      >
        <div className={classes.InputComment}>
          <div className={classes.UserImage}>
            <Avatar
              src={current_user.imageUrl}
              dimension={40}
              name={`${current_user.firstName} ${current_user.lastName}`}
            />
          </div>
          <div className={classes.UserInput}>
            <textarea
              placeholder="What do you think of this opportunity?  You can @mention a connect."
              value={commentData}
              onChange={inputHandler}
              className={classes.commentForm}
              maxLength="500"
              name="content"
            />
          </div>
          <Button
            className="float-right"
            disabled={postingReply}
            type="submit"
            data-commid={idx}
          >
            {postingReply ? <Loader color="light" /> : "Comment"}
          </Button>
        </div>
        {loadingReplies ? (
          <Spinner />
        ) : (
          replies && replies.length > 0 && <ViewReplies userReplies={replies} />
        )}
      </form>
    </>
  );
};

export default ReplyComment;
