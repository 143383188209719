import { asyncActionName } from "src/utils/AsyncUtils";
import {
  GET_OPPORTUNITY,
  DELETE_OPPORTUNITY,
  GET_OPPORTUNITY_CATEGORIES,
  GET_OPPORTUNITY_TYPES,
  CREATE_OPPORTUNITY,
  FLAG_OPPORTUNITY,
  GET_RELATED_OPPORTUNITIES,
  GET_TOP_OPPORTUNITIES,
  GET_OPPORTUNITIES,
  GET_OPPORTUNITY_COMMENTS,
  POST_OPPORTUNITY_COMMENT,
  LIKE_OPPORTUNITY_COMMENT,
  REPLY_OPPORTUNITY_COMMENT,
  GET_OPPORTUNITY_COMMENT_RESPONSE,
  GET_MY_OPPORTUNITES,
  TOGGLE_OPPORTUNITY_REMINDER,
  INCREASE_OPPORTUNITY_VIEW,
  INCREASE_OPPORTUNITY_POPULARITY,
  CANCEL_OPPORTUNITY_REMINDER,
} from "src/actions";

import { initialState } from "src/store";

const OpportunityReducer = (state = initialState.opportunities, action) => {
  switch (action.type) {
    case asyncActionName(GET_OPPORTUNITY).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(GET_OPPORTUNITY).success:
      return {
        ...state,
        opportunity: action.payload,
        success: true,
      };
    case asyncActionName(GET_OPPORTUNITY).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(DELETE_OPPORTUNITY).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(DELETE_OPPORTUNITY).success:
      return {
        ...state,
        opportunity: action.payload,
        success: true,
      };
    case asyncActionName(DELETE_OPPORTUNITY).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    // get categories
    case asyncActionName(GET_OPPORTUNITY_CATEGORIES).loading:
      return {
        ...state,
        loadingGetOpportunityCategories: action.payload,
      };
    case asyncActionName(GET_OPPORTUNITY_CATEGORIES).success:
      return {
        ...state,
        categories: action.payload,
        success: true,
      };
    case asyncActionName(GET_OPPORTUNITY_CATEGORIES).failure:
      return {
        ...state,
        error: action.payload,
        success: false,
      };
    // get types
    case asyncActionName(GET_OPPORTUNITY_TYPES).loading:
      return {
        ...state,
        loadingGetOpportunityTypes: action.payload,
      };
    case asyncActionName(GET_OPPORTUNITY_TYPES).success:
      return {
        ...state,
        types: action.payload,
        success: true,
      };
    case asyncActionName(GET_OPPORTUNITY_TYPES).failure:
      return {
        ...state,
        error: action.payload,
        success: false,
      };

    case asyncActionName(CREATE_OPPORTUNITY).loading:
      return {
        ...state,
        loadingPostOpportunity: action.payload,
      };

    case asyncActionName(CREATE_OPPORTUNITY).failure:
      return {
        ...state,
        error: action.payload,
        success: false,
      };
    case asyncActionName(FLAG_OPPORTUNITY).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(FLAG_OPPORTUNITY).success:
      return {
        ...state,
        isFlagged: true,
        success: true,
      };
    case asyncActionName(FLAG_OPPORTUNITY).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(GET_RELATED_OPPORTUNITIES).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(GET_RELATED_OPPORTUNITIES).success:
      return {
        ...state,
        relatedOpportunities: action.payload,
        success: true,
      };
    case asyncActionName(GET_RELATED_OPPORTUNITIES).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };

    // top opportunities
    case asyncActionName(GET_TOP_OPPORTUNITIES).loading:
      return {
        ...state,
        loadingTopOpportunities: action.payload,
      };
    case asyncActionName(GET_TOP_OPPORTUNITIES).success:
      return {
        ...state,
        topOpportunities: action.payload,
        success: true,
      };
    case asyncActionName(GET_TOP_OPPORTUNITIES).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };

    case asyncActionName(GET_OPPORTUNITIES).loading:
      return {
        ...state,
        requestingOpportunities: action.payload,
      };
    case asyncActionName(GET_OPPORTUNITIES).success:
      return {
        ...state,
        opportunities: action.payload,
        success: true,
      };
    case asyncActionName(GET_OPPORTUNITIES).failure:
      return {
        ...state,
        getOpportunitiesError: action.payload.status,
        success: false,
      };
    case asyncActionName(GET_OPPORTUNITY_COMMENTS).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(GET_OPPORTUNITY_COMMENTS).success:
      return {
        ...state,
        comments: action.payload,
        success: true,
      };
    case asyncActionName(GET_OPPORTUNITY_COMMENTS).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(POST_OPPORTUNITY_COMMENT).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(POST_OPPORTUNITY_COMMENT).success:
      return {
        ...state,
        comments: action.payload,
        success: true,
      };
    case asyncActionName(POST_OPPORTUNITY_COMMENT).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(LIKE_OPPORTUNITY_COMMENT).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(LIKE_OPPORTUNITY_COMMENT).success:
      return {
        ...state,
        likeOpportunityComment: true,
        allLikers: action.payload,
        success: true,
      };
    case asyncActionName(LIKE_OPPORTUNITY_COMMENT).failure:
      return {
        ...state,
        likeOpportunityComment: false,
        success: false,
      };
    case asyncActionName(REPLY_OPPORTUNITY_COMMENT).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(REPLY_OPPORTUNITY_COMMENT).success:
      return {
        ...state,
        response: action.payload,
        success: true,
      };
    case asyncActionName(REPLY_OPPORTUNITY_COMMENT).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(GET_OPPORTUNITY_COMMENT_RESPONSE).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(GET_OPPORTUNITY_COMMENT_RESPONSE).success:
      return {
        ...state,
        responses: action.payload,
        success: true,
      };
    case asyncActionName(GET_OPPORTUNITY_COMMENT_RESPONSE).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };

    case asyncActionName(GET_MY_OPPORTUNITES).success:
      return {
        ...state,
        myOpportunities: action.payload,
        success: true,
      };
    case asyncActionName(TOGGLE_OPPORTUNITY_REMINDER).success:
      return {
        ...state,
        isReminded: action.payload,
        success: true,
      };
    case asyncActionName(TOGGLE_OPPORTUNITY_REMINDER).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(TOGGLE_OPPORTUNITY_REMINDER).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(CANCEL_OPPORTUNITY_REMINDER).success:
      return {
        ...state,
        isReminded: action.payload,
        success: true,
      };
    case asyncActionName(CANCEL_OPPORTUNITY_REMINDER).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(CANCEL_OPPORTUNITY_REMINDER).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(INCREASE_OPPORTUNITY_VIEW).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(INCREASE_OPPORTUNITY_VIEW).success:
      return {
        ...state,
        opportunity: action.payload,
        success: true,
      };
    case asyncActionName(INCREASE_OPPORTUNITY_VIEW).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(INCREASE_OPPORTUNITY_POPULARITY).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(INCREASE_OPPORTUNITY_POPULARITY).success:
      return {
        ...state,
        opportunity: action.payload,
        success: true,
      };
    case asyncActionName(INCREASE_OPPORTUNITY_POPULARITY).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    default:
      return state;
  }
};

export default OpportunityReducer;
