import axios from "src/utils/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CREATE_COMMENT,
  GET_COMMENTS,
  DELETE_COMMENT,
  LIKE_COMMENT,
  GET_USER_LIKE_COMMENT,
} from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import { PostUrl } from "src/constants";

export const createComment = (dispatch, payload) => {
  dispatch(asyncActions(CREATE_COMMENT).loading(true));
  return axios({
    method: "post",
    url: PostUrl.CREATE_COMMENT_URL,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(CREATE_COMMENT).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(CREATE_COMMENT).success(response.data));
        dispatch(asyncActions(CREATE_COMMENT).loading(false));
        toast("Commented Successfully ✅");
      }
      return response;
    })
    .catch((error) => {
      console.log(error);
      dispatch(asyncActions(CREATE_COMMENT).loading(false));
      dispatch(asyncActions(CREATE_COMMENT).failure(true, error.response));
      toast("An error occurred, Please try again ");
      return error.response;
    });
};

export const getAllComments = (dispatch, payload) => {
  dispatch(asyncActions(GET_COMMENTS).loading(true));
  return axios({
    method: "get",
    url: `${PostUrl.GET_COMMENTS_URL}/${payload}/comments`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_COMMENTS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_COMMENTS).success(data));
        dispatch(asyncActions(GET_COMMENTS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_COMMENTS).loading(false));
      dispatch(asyncActions(GET_COMMENTS).failure(true, error.response));
      return error.response;
    });
};

export const deleteComment = (dispatch, id) => {
  dispatch(asyncActions(DELETE_COMMENT).loading(true));
  return axios({
    method: "delete",
    url: `${PostUrl.DELETE_COMMENT_URL}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(DELETE_COMMENT).loading(false));
      if (response.status === 200) {
        console.log(response);
        const { data } = response.data;
        dispatch(asyncActions(DELETE_COMMENT).success(data));
        dispatch(asyncActions(DELETE_COMMENT).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(DELETE_COMMENT).loading(false));
      dispatch(asyncActions(DELETE_COMMENT).failure(true, error.response));
      return error.response;
    });
};

export const likeComment = (dispatch, payload) => {
  dispatch(asyncActions(LIKE_COMMENT).loading(true));
  return axios({
    method: "post",
    url: `${PostUrl.LIKE_COMMENT_URL}/${payload}/like/toggle`,
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(LIKE_COMMENT).success(response.data));
        dispatch(asyncActions(LIKE_COMMENT).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(LIKE_COMMENT).failure(error));
      return error.response;
    });
};

export const getUserLikeComment = (dispatch, payload) => {
  dispatch(asyncActions(GET_USER_LIKE_COMMENT).loading(true));
  return axios({
    method: "get",
    url: `${PostUrl.GET_USER_LIKE_COMMENT_URL}/${payload}/like/user`,
  })
    .then((response) => {
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_USER_LIKE_COMMENT).success(data));
        dispatch(asyncActions(GET_USER_LIKE_COMMENT).loading(false));
        return response;
      }
    })
    .catch((error) => {
      dispatch(asyncActions(GET_USER_LIKE_COMMENT).failure(error));
      return error.response;
    });
};
