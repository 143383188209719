import React, { useState, useContext, useEffect } from "react";
import "./About.scss";
import { Btn } from "src/modules/profile/ProfileEdit/common";
import ValidateJs from "src/utils/Validations/validation_wrapper";
import { displayError } from "src/modules/onboarding/helper";
import { FormField, Select } from "src/modules/onboarding/components/formField";
import {
  formatMaxBirthYear,
  validateBirthYear,
} from "src/modules/onboarding/stepTwo/Sections";
// import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";

import {
  CustomCountryDropdown,
  CustomCountryRegionDropdown,
} from "src/components/CustomCountryRegionDropdown";
import "react-phone-input-2/lib/style.css";

import "react-datepicker/dist/react-datepicker.css";
import { getCountryAlpha2Code } from "src/utils/helper";
import { Context } from "src/store";
import { updateUserProfile } from "src/requests/ProfileRequest";
import degreeObj from "src/modules/onboarding/stepTwo/degreeTypes2.json";
import { DatePickerInput } from "rc-datepicker";
import { useTranslation } from "react-i18next";
// import { useLocation } from "react-router-dom";
import queryString from "query-string";

const postions = [
  { label: "Chief Executive Officer", value: "chief executive officer" },
  { label: "Director", value: "director" },
  { label: "Manager", value: "manager" },
  { label: "VC Partner", value: "vc partner" },
  { label: "Investor Scout", value: "investor scout" },
  { label: "Analyst", value: "analyst" },
  { label: "Associate", value: "associate" },
  { label: "Officer", value: "officer" },
  // { label: "Others", value: "others" },
];

export const languages = [
  { label: "English", value: "en" },
  { label: "French", value: "fr" },
  { label: "Portuguese", value: "pt" },
  { label: "Arabic", value: "ar" },
  { label: "Spanish", value: "es" },
];

// const yearsOfExperience = () => {
//   const years = [];
//   for (var i = 1; i <= 30; i++) {
//     years.push({ label: i, value: i });
//   }
//   return years;
// };

const maritalStatus = [
  { label: "Married", value: "married" },
  { label: "Single", value: "single" },
  { label: "Divorced", value: "divorced" },
  // { label: "I'd rather not say", value: "i'd rather not say" },
];
const gender = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  // { label: "Other", value: "other" },
  // { label: "I'd rather not say", value: "i'd rather not say" },
];

const PersonalInfo = ({ onCancel, onChange, userInfo }) => {
  const [validationErrors, setValidationErrors] = useState({
    gender: null,
    phone: null,
    dateOfBirth: null,
    country: null,
    state: null,
    city: null,
    address: null,
    firstName: null,
    lastName: null,
  });
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const { dispatch } = useContext(Context);

  const { target_prop } = queryString.parse(location.search);

  useEffect(() => {
    // When the user comes from profile status, this move them to the field they want oto eidt.
    if (target_prop) {
      const target = document.getElementById(target_prop);
      if (target) {
        target.scrollIntoView();
      }
    }
  }, [target_prop]);

  const onSubmit = () => {
    const {
      industryRole,
      yearsOfExperience,
      gender,
      dateOfBirth,
      maritalStatus,
      phone,
      country,
      state,
      city,
      language,
      address,
      levelOfEducation,
      lastName,
      firstName,
    } = userInfo;

    const requiredFields = {
      gender,
      address,
      state,
      country,
      phone,
      dateOfBirth,
      city,
      firstName,
      lastName,
    };

    const hasErrors = _hasValidationErrors(requiredFields);

    if (hasErrors.isConfirmed)
      return document.getElementById(hasErrors.errorList[0]).scrollIntoView();

    const payload = {
      industryRole,
      yearsOfExperience,
      gender,
      dateOfBirth,
      maritalStatus,
      phone,
      country,
      state,
      city,
      language,
      address,
      levelOfEducation,
      firstName,
      lastName,
    };
    if (!payload.language) {
      delete payload.language;
    }

    return _handleSubmit(payload);
  };

  const _hasValidationErrors = (data) => {
    let errors = {};
    const errorList = [];
    Object.keys(data).forEach((key) => {
      errors = { ...errors, [key]: ValidateJs(key, data[key]) };
    });

    Object.keys(errors).forEach((key) => {
      if (errors[key] !== null) {
        errorList.push(key);
      }
    });
    setValidationErrors(errors);

    return { isConfirmed: errorList.length > 0, errorList };
  };

  const _handleSubmit = (payload) => {
    setLoading(true);
    updateUserProfile(dispatch, payload).then(() => {
      setLoading(false);
      cancel();
    });
  };

  const cancel = () => {
    onCancel();
  };

  const _limitYearsOfExperience = (target, value) => {
    if (Number(value) > 30) {
      if (value.length >= 3) {
        value = value.substr(0, 2);
      } else if (value.length === 2) {
        value = value.substr(0, 1);
      }
      onChange(target, value);
    } else {
      onChange(target, value);
    }
  };

  // const validateBirthYear = (target, value) => {
  //   // console.log(new Date(value).toISOString().split("T")[0]);
  //   const date = new Date(value).toLocaleDateString().split("/");

  //   let incoming_year = date[2];
  //   const incoming_date = date[0];
  //   const incoming_month = date[1];

  //   const maxYear = formatMaxBirthYear(18).split("-")[0];

  //   if (incoming_year > maxYear) {
  //     incoming_year = maxYear;
  //     onChange(target, `${incoming_year}-${incoming_month}-${incoming_date}`);
  //     return;
  //   }
  //   return onChange(
  //     target,
  //     `${incoming_year}-${incoming_month}-${incoming_date}`
  //   );
  // };

  const handleValidationErrorChanges = (target, value) => {
    setValidationErrors({ ...validationErrors, [target]: value });
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="col-md-6">
            <FormField
              id="firstName"
              name="firstName"
              label="Firstname"
              onChange={onChange}
              value={userInfo.firstName}
              onBlur={() =>
                handleValidationErrorChanges(
                  "firstName",
                  ValidateJs("firstName", userInfo.firstName)
                )
              }
              error={validationErrors.firstName}
            ></FormField>
            {validationErrors.firstName &&
              displayError(validationErrors.firstName)}
          </div>
          <div className="col-md-6">
            <FormField
              id="lastName"
              name="lastName"
              label="LastName"
              onChange={onChange}
              value={userInfo.lastName}
              onBlur={() =>
                handleValidationErrorChanges(
                  "lastName",
                  ValidateJs("lastName", userInfo.lastName)
                )
              }
              error={validationErrors.lastName}
            ></FormField>
            {validationErrors.lastName &&
              displayError(validationErrors.lastName)}
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <FormField label="Select Role">
              <Select
                list={postions}
                value={userInfo.industryRole}
                onChange={onChange}
                name="industryRole"
                className="flex"
              />
            </FormField>
          </div>
          <div className="col-md-6">
            <FormField
              className="flex"
              label="Years of Experience"
              labelInfo=""
              type="number"
              onChange={_limitYearsOfExperience}
              value={userInfo.yearsOfExperience.toString()}
              name="yearsOfExperience"
              min="0"
              max="30"
            ></FormField>
          </div>
        </div>

        {/* marital status */}
        <div className="row">
          <div className="col-md-6">
            <FormField
              // className="flex"
              label="Gender*"
              required
              id="gender"
              // isEmpty={!userInfo["gender"]}
            >
              <Select
                list={gender}
                value={userInfo.gender}
                onChange={onChange}
                name="gender"
                className="flex"
                // disabled
                onBlur={() =>
                  handleValidationErrorChanges(
                    "gender",
                    ValidateJs("gender", userInfo.gender)
                  )
                }
                error={validationErrors.gender}
              />
            </FormField>
          </div>
          <div className="col-md-6">
            <FormField label="Marital Status" labelInfo="">
              <Select
                list={maritalStatus}
                value={userInfo.maritalStatus}
                onChange={onChange}
                name="maritalStatus"
                className="flex"
              />
            </FormField>
          </div>
        </div>

        {validationErrors.gender && displayError(validationErrors.gender)}

        {/* dob */}
        <div className="row">
          <div className="col-md-6">
            <FormField
              id="dateOfBirth"
              className="flex"
              label="Date of Birth*"
              // labelInfo="Required"
              error={validationErrors.dateOfBirth}
            >
              <div
                className={`rc-dp${
                  validationErrors.dateOfBirth ? " default-error-state" : ""
                }`}
              >
                <DatePickerInput
                  className="default-error-state"
                  readOnly
                  maxDate={new Date(formatMaxBirthYear(18))}
                  startDate={new Date(formatMaxBirthYear(18))}
                  minDate={new Date(new Date().setFullYear(1950))}
                  onChange={(jsDate, dateString) =>
                    validateBirthYear("dateOfBirth", dateString, onChange)
                  }
                  placeholder="MM/DD/YYYY"
                  onHide={() =>
                    handleValidationErrorChanges(
                      "dateOfBirth",
                      ValidateJs("dateOfBirth", userInfo.dateOfBirth)
                    )
                  }
                  value={userInfo.dateOfBirth}
                  showOnInputClick
                  autoClose={false}
                />
              </div>
            </FormField>
            {validationErrors.dateOfBirth &&
              displayError(`${t(validationErrors.dateOfBirth)}`)}
          </div>

          <FormField
            className="col-md-6 pad-inner"
            label="Phone*"
            // labelInfo="Required"
            id="phone"
            required
            // isEmpty={!userInfo["phone"]}
          >
            <div style={{ marginTop: "9.5px" }}>
              <PhoneInput
                enableSearch
                country={"ng"}
                value={userInfo.phone}
                inputClass="phone-input"
                name="phone"
                onChange={(phone) => onChange("phone", phone)}
                autoFormat={false}
                inputStyle={{
                  width: "100%",
                  marginTop: "8px 10px",
                  borderColor: validationErrors.phone ? "red" : "",
                }}
                onBlur={() =>
                  handleValidationErrorChanges(
                    "phone",
                    ValidateJs("phone", userInfo.phone)
                  )
                }
                countryCodeEditable={false}
              />
              {validationErrors.phone
                ? displayError(validationErrors.phone)
                : displayError("")}
            </div>
          </FormField>
        </div>

        <div className="row">
          <div className="col-md-6">
            <FormField label="Country*" className="flex" id="country">
              <CustomCountryDropdown
                classes={`flex country-state-list ${
                  validationErrors.country
                    ? "country-state-list-error-state"
                    : ""
                }`}
                value={
                  userInfo &&
                  userInfo.country &&
                  getCountryAlpha2Code(userInfo.country)
                }
                name="country"
                valueType="short"
                onChange={(val) => onChange("country", val)}
                onBlur={() =>
                  handleValidationErrorChanges(
                    "country",
                    ValidateJs("country", userInfo.country)
                  )
                }
              />

              {validationErrors.country ? (
                <div className="displayError">
                  {displayError(validationErrors.country)}
                </div>
              ) : (
                <div className="displayError">{displayError("")}</div>
              )}
            </FormField>
          </div>
          <div className="col-md-6">
            <FormField label="State*" className="flex" id="state">
              <CustomCountryRegionDropdown
                countryValueType="short"
                defaultOptionLabel="Select region"
                country={
                  userInfo &&
                  userInfo.country &&
                  getCountryAlpha2Code(userInfo.country)
                }
                classes={`flex country-state-list ${
                  validationErrors.state ? "country-state-list-error-state" : ""
                }`}
                name="state"
                value={userInfo.state}
                onChange={(val) => onChange("state", val)}
                onBlur={() =>
                  handleValidationErrorChanges(
                    "state",
                    ValidateJs("state", userInfo.state)
                  )
                }
              />
              {validationErrors.state ? (
                <div className="displayError">
                  {displayError(validationErrors.state)}
                </div>
              ) : (
                <div className="displayError">{displayError("")}</div>
              )}
            </FormField>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <FormField
              label="City*"
              placeholder="Please enter your city of residence"
              name="city"
              className="flex"
              id="city"
              required
              onChange={onChange}
              value={userInfo.city}
              onBlur={() =>
                handleValidationErrorChanges(
                  "city",
                  ValidateJs("city", userInfo.city)
                )
              }
              error={validationErrors.city}
            />
            {validationErrors.city
              ? displayError(validationErrors.city)
              : displayError("")}
          </div>
          <div className="col-md-6">
            <FormField label="Language">
              <Select
                list={languages}
                value={userInfo.language}
                onChange={onChange}
                name="language"
                className="flex"
              />
            </FormField>
          </div>
        </div>

        <FormField
          label="Address*"
          type="textarea"
          // labelInfo="Required"
          placeholder="Please enter your residential address"
          name="address"
          className="flex"
          required
          id="address"
          onChange={onChange}
          value={userInfo.address || ""}
          hasCounter
          maxLength={100}
          onBlur={() =>
            handleValidationErrorChanges(
              "address",
              ValidateJs("address", userInfo.address)
            )
          }
          error={validationErrors.address}
        />
        {validationErrors.address
          ? displayError(validationErrors.address)
          : displayError("")}

        <FormField id="levelOfEducation" label="Highest level of education">
          <Select
            list={degreeObj}
            defaultVal={"Select"}
            name="levelOfEducation"
            onChange={onChange}
            value={userInfo.levelOfEducation}
          />
        </FormField>

        <div className="btn-box">
          <hr />
          <Btn className="btn-cancel" value="Cancel" onClick={onCancel} />
          <div style={{ flex: 1 }}></div>
          <Btn
            className="btn-update"
            value="Save Personal Info"
            loading={loading}
            onClick={() => onSubmit()}
          />
        </div>
      </form>
    </div>
  );
};

export default PersonalInfo;
