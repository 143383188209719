/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from "react";
import "../common.scss";
import "./experience.scss";
import { FormField, Select } from "src/modules/onboarding/components";
import { FaSave, FaUpload } from "react-icons/fa";
import { SelectBox } from "src/modules/Application/Offcycle/personalInfo/components";
import {
  retrieveTempUserApplication,
  storeTempUserApplication,
  _setSteps,
  getUserCurrentApplication,
  _sanitizeData,
} from "src/modules/Application/helper";
import { personalInfo } from "src/modules/Application/Offcycle/personalInfo/personanlInfoObj";
import { Context } from "src/store";
import {
  CastValue,
  displayError,
  // _scrollToRef,
} from "src/modules/onboarding/helper";
import queryString from "query-string";
import { Multiselect } from "multiselect-react-dropdown";
import { Input } from "reactstrap";

import "react-phone-input-2/lib/style.css";
import { sdgs, experience, businessStages } from "./options";
import {
  getIndustrySubSector,
  getCountryAlpha2Code,
} from "src/modules/Application/Offcycle/personalInfo/helper";
import { industryTypes } from "src/modules/onboarding/stepTwo/industryType.js";
import ValidateJs from "src/utils/Validations/validation_wrapper";
import Loader from "react-loader-spinner";
import axios from "axios";
// import { numbers } from "src/modules/onboarding/tefMentorOnboarding/static";
import CLoader from "src/modules/Application/components/CLoader";
import ProgressReport from "src/modules/Application/components/ApplicantProgressReport/ProgressReport";
import { toast } from "react-toastify";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { sectionTwoTranslations } from "src/modules/Application/instructions";
import { useTranslation } from "react-i18next";
// import { DeclarationTranslations } from "src/modules/Application/Offcycle/Declaration/DeclarationTranslations";
import {
  CustomCountryRegionDropdown,
  CustomCountryDropdown,
} from "src/components/CustomCountryRegionDropdown";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { updateUserProfile } from "src/requests/ProfileRequest";
import { UploadFile } from "src/requests/fileUploadRequest";
import { returnTranslationOption } from "../../Declaration/DeclarationTranslations";
import { FileViewerReadOnly } from "src/components/FileViewer/FileViewer";

const setExistingInfo = (info, profile) => {
  info = JSON.parse(JSON.stringify(info));

  const profileInfo = [
    "businessName",
    "businessCity",
    // "businessCountry",
    "businessState",
    "businessAddress",
    "businessIndustry",
    "industrySubSector",
  ];

  profileInfo.forEach((pInfo) => {
    if (!info[pInfo]) {
      info[pInfo] = profile[pInfo];
    }
  });

  if (!info.businessCountryId) {
    info.businessCountryId = getCountryAlpha2Code(
      profile.businessCountry || "Nigeria"
    );
  }

  return info;
};

function isValidFile(fileName) {
  const ext = fileName.split(".").pop();
  const validEx = ["pdf", "docx", "doc"];
  return validEx.includes(ext.toLowerCase());
}

const Experience = (props) => {
  const { applicationId, endDate, lang, title } = queryString.parse(
    props.history.location.search
  );
  const [info, setInfo] = useState(personalInfo);
  const [isPersonalInfoInit, setIsPersonalInfoInit] = useState(false);
  const { dispatch } = useContext(Context);
  const [hasError, setHasError] = useState(false);
  const { user } = get_user_info_from_token();
  const [isSavingAndContinue, setIsSavingAndContinue] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [percentComplete, setPercentComplete] = useState(40);
  const [fileError, setFileError] = useState("");
  const [isuploadingFile, setIsUploadingFile] = useState(false);
  const [updatingMessage, setUpdatingMessage] = useState("");

  const currentUser = useCurrentUser();
  // const language = JSON.parse(localStorage.getItem("language"));
  const [language, setLanguage] = useState(lang);
  // const yearsOfExperience = yearsOfStarupExperience[language || "en"];
  const experienceObj = experience[language || "en"];
  const sdgGoals = sdgs[language || "en"];
  const industryObj = industryTypes[language || "en"];
  const bStages = businessStages[language || "en"];
  const [industrySubSectList, setIndustrySubSectList] = useState([]);
  const declarationTranslations = returnTranslationOption()[language || "en"];

  const { t } = useTranslation();

  const ref = useRef();

  const extractUserGroup = () => {
    const userGroups = currentUser.userGroups.map((group) => {
      return group.group.id;
    });
    return userGroups;
  };

  useEffect(() => {
    const lng = lang || "en";
    setLanguage(lng);
    onChange("languageId", lang);
  }, [lang]);

  useEffect(() => {
    if (info.businessIndustry) {
      const sectors = getIndustrySubSector(info.businessIndustry, lang || "en");
      if (sectors && sectors.length) {
        setIndustrySubSectList(sectors);
      }
    }
  }, [info.businessIndustry]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const query = props.history.location.search;
    if (query) {
      const parsedQuery = queryString.parse(query);
      if (Object.keys(parsedQuery).length === 0 || !parsedQuery.applicationId) {
        props.history.replace("/programme/dashboard", {});
        props.history.go(1);
      }
    } else {
      props.history.replace("/programme/dashboard", {});
      props.history.go(1);
    }
  }, [props.history.location.search]);

  useEffect(() => {
    getUserCurrentApplication(
      `${process.env.REACT_APP_APPLICATION_BASE_URL}/applicant`
    ).then((userApps) => {
      const applicationId = queryString.parse(props.history.location.search)
        .applicationId;

      const foundApplication = userApps.filter((app) => {
        return app.applicationId === applicationId;
      });

      if (foundApplication.length > 0) {
        const app = foundApplication[0];
        if (app.progress === 40) {
          return props.history.push({
            pathname: "/application/offcycle/tests/mcq",
            search: `?applicationId=${applicationId}&endDate=${endDate}&lang=${app.languageId}&title=${title}`,
          });
        }
        if (app.progress > 40 || app.progress === 90) {
          return props.history.push({
            pathname: "/application/offcycle/declaration",
            search: `?applicationId=${applicationId}&endDate=${endDate}&lang=${app.languageId}&title=${title}`,
          });
        }
      } else {
        retrieveTempUserApplication(applicationId).then((application) => {
          if (!application || application === null) {
            props.history.replace("/application/tour", {});
            props.history.go(1);
          } else {
            if (!application.applicationId) {
              props.history.replace("/home", {});
              props.history.go(1);
            } else {
              setInfo(application);
              setIsPersonalInfoInit(true);
              return application;
            }
          }
        });
      }
    });
  }, []);

  // useEffect(() => {
  //   getUserProfile(dispatch, user.id);
  // }, []);

  useEffect(() => {
    if (currentUser && isPersonalInfoInit) {
      let application = { ...info };
      application = setExistingInfo(application, currentUser, user);
      setInfo(application);
    }
  }, [currentUser, isPersonalInfoInit]);

  const onChange = (target, value) => {
    if (target === "businessCountryId") {
      const obj = { ...info, [target]: value, businessState: "" };
      if (value.toLowerCase() !== "") {
        obj.businessLocalGovernmentArea = "";
      }
      setInfo(obj);
    } else if (
      target === "teamSize" &&
      info.isWorkingInTeam &&
      (value === "" || value === 0)
    ) {
      setInfo({ ...info, [target]: 1 });
    } else {
      setInfo({ ...info, [target]: CastValue(target, value) });
    }
  };

  const onSdgSelect = (target, value) => {
    if (!value || value.length === 0) {
      return onChange(target, []);
    }
    return onChange(target, value);
    // return onChange(target, value.slice(0, 3));
  };

  const _handleValidationErrorChanges = (target, value) => {
    const obj = { ...info.validationErrors, [target]: value };
    onChange("validationErrors", obj);
  };

  const hasValidationError = (data) => {
    const exclude = [
      "appliedForOtherFoundationGrant",
      "isIdeaInFieldOfExperience",
      // "haveBusinessPartners",
      // "isBusinessRural",
      "isResidentialRural",
      "middleName",
      "dateOfBirth",
      "levelOfEducation",
      "yearsOfExperience",
      "businessName",
      // "businessAddress",
      "state",
      "city",
      "address",
      "nameOfOrganization",
      "typeOfId",
      "idUrl",
      "howYouHearAboutUs",
      "kinPhone",
      "kinEmail",
      "mentorPhone",
      "mentorEmail",
      "gender",
      "phoneNumber",
      "email",
      "countryId",
      "idNumber",
      "ageRange",
      "businessAge",
      "yearsOfStartupExperience",
      "businessWebsite",
      "businessRegistrationNumber",
      "businessAddressGreenEconomyOrClimateChangeDescription",
      "businessLocalGovernmentArea",
    ];

    const booleanFields = [
      "haveBusinessPartners",
      "isBusinessRural",
      "isBusinessAddressGreenEconomyOrClimateChange",
      "isBusinessRegistered",
      "hasBusinessPartnerConsent",
    ];

    data = { ...data };
    for (const key of exclude) {
      delete data[key];
    }

    let errors = {};
    const errorList = [];
    booleanFields.forEach((item) => {
      if (typeof data[item] !== "boolean")
        errors = { ...errors, [item]: "Please select" };
    });
    Object.keys(data).forEach((key) => {
      if (!booleanFields.indexOf(key) >= 0) {
        errors = { ...errors, [key]: ValidateJs(key, data[key]) };
      }
    });

    // if (data.isWorkingInTeam && data.teamSize === 0) {
    //   errors.teamSize = "Please specify how many people in your team";
    // }

    // if (data.isIdeaProfitable && data.monthlyIncome === 0) {
    //   errors.monthlyIncome = "How much are you currently making";
    // }

    if (
      info.isBusinessAddressGreenEconomyOrClimateChange &&
      !info.businessAddressGreenEconomyOrClimateChangeDescription
    ) {
      errors.businessAddressGreenEconomyOrClimateChangeDescription =
        "Required.";
    }

    if (
      info.businessCountryId.toLowerCase() === "ng" &&
      !info.businessLocalGovernmentArea
    ) {
      errors.businessLocalGovernmentArea = "Required.";
    }

    if (info.isBusinessRegistered && !info.businessRegistrationNumber) {
      errors.businessRegistrationNumber = "Required.";
    }

    if (info.haveBusinessPartners && info.hasBusinessPartnerConsent === null) {
      errors.hasBusinessPartnerConsent = "Please select.";
    }

    if (info.hasBusinessPartnerConsent && !info.partnerConsentLetterUrl) {
      errors.partnerConsentLetterUrl =
        "Please upload a signed business partner consent letter";
    }

    Object.keys(errors).forEach((key) => {
      if (errors[key] !== null) {
        errorList.push(key);
      }
    });
    const errObj = { ...info, validationErrors: errors };
    setInfo(errObj);
    console.log(errorList);
    return { confirmed: errorList.length > 0, errors: errorList };
  };

  const updateProfileAccount = (info) => {
    const profileDetails = [
      "businessName",
      "businessCity",
      "businessState",
      "businessAddress",
      "businessIndustry",
      "industrySubSector",
    ];

    let profileObj = {};
    profileDetails.forEach((key) => {
      if (info[key]) {
        profileObj = { ...profileObj, [key]: info[key] };
      }
    });

    if (!profileObj.businessCountry) {
      profileObj.businessCountry = info.businessCountryId;
    }

    if (!profileObj.aboutBusiness) {
      profileObj.aboutBusiness = info.aboutIdea;
    }

    if (!profileObj.businessRevenue) {
      profileObj.businessRevenue = info.monthlyIncome;
    }

    delete profileObj.businessCountryId;
    return updateUserProfile(dispatch, profileObj);
  };

  const _handleSubmit = (e) => {
    e.preventDefault();
    if (loadingSave) return;
    const hasErrors = hasValidationError(info);
    if (!hasErrors.confirmed) {
      setLoadingSave(true);
      const data = _sanitizeData(info, extractUserGroup);
      axios.defaults.headers.common["application-id"] = applicationId;
      setUpdatingMessage("Saving your application progress...");
      return axios
        .post(`${process.env.REACT_APP_APPLICATION_BASE_URL}/applicant`, {
          ...data,
        })
        .then((res) => {
          setUpdatingMessage("Updating profile information...");
          return updateProfileAccount(data).then(() => {
            setUpdatingMessage("");
            setPercentComplete(res.data.data.progress);
            setLoadingSave(false);
            setShowReport(true);
          });
        })
        .catch((e) => {
          setLoadingSave(false);
          // console.log(e);
        });
    }

    // _scrollToRef(ref);
    document.getElementById(hasErrors.errors[0]).scrollIntoView();
    setHasError(true);
  };

  const _onContinueClick = () => {
    return props.history.push({
      pathname: `/application/offcycle/tests/mcq`,
      search: `?applicationId=${info.applicationId}&endDate=${endDate}&lang=${language}&title=${title}`,
    });
  };

  const _saveAndContinue = (e) => {
    e.preventDefault();
    if (isSavingAndContinue) return;
    setIsSavingAndContinue(true);
    const appInfo = JSON.parse(JSON.stringify(info));
    Object.keys(appInfo.validationErrors).forEach((key) => {
      appInfo.validationErrors[key] = null;
    });
    const currentStep =
      window.location.pathname +
      `?applicationId=${info.applicationId}&endDate=${endDate}&lang=${language}&title=${title}`;
    const userInfo = _setSteps(appInfo, currentStep, currentStep, "");
    setUpdatingMessage("Saving your application progress...");

    // applicationId is misnamed - this is really programmeEventId
    // TODO(TP-2083): rename to programmeEventId.
    storeTempUserApplication(userInfo, info.applicationId).then((app) => {
      setUpdatingMessage("Updating profile information...");
      return updateProfileAccount(userInfo).then(() => {
        toast(`${t("your application progress has been saved")}`);
        // _scrollToRef(ref);
        setIsSavingAndContinue(false);
      });
    });
  };

  const _previousStep = (e) => {
    e.preventDefault();
    const appInfo = JSON.parse(JSON.stringify(info));
    Object.keys(appInfo.validationErrors).forEach((key) => {
      appInfo.validationErrors[key] = null;
    });
    const currentStep =
      window.location.pathname +
      `?applicationId=${info.applicationId}&endDate=${endDate}&lang=${language}&title=${title}`;
    const userInfo = _setSteps(
      appInfo,
      appInfo.steps.currentCompletedStep,
      currentStep,
      `/application/offcycle/personalinfo/bio?applicationId=${
        appInfo.applicationId
      }&endDate=${endDate.trim()}&lang=${lang}&title=${title}`
    );
    setIsSavingAndContinue(true);
    // return console.log(userInfo);
    setUpdatingMessage("Saving your application progress...");
    // applicationId is misnamed - this is really programmeEventId
    // TODO(TP-2083): rename to programmeEventId.
    storeTempUserApplication(userInfo, userInfo.applicationId).then((app) => {
      setIsSavingAndContinue(false);
      // return console.log(userInfo);
      setUpdatingMessage("");
      props.history.replace(
        `/application/offcycle/personalinfo/bio?applicationId=${info.applicationId}&endDate=${endDate}&lang=${language}&title=${title}`,
        {}
      );
      props.history.go(1);
    });
  };

  const _onDocChange = (e) => {
    const files = e.target.files;
    if (!isValidFile(files[0].name)) {
      setFileError("Invalid file format. Only doc, docx and pdf are allowed");
      return;
    }

    setFileError("");
    setIsUploadingFile(true);
    // setPreviewImage(files);
    _handleValidationErrorChanges("partnerConsentLetterUrl", null);
    UploadFile(dispatch, false, files)
      .then((res) => {
        const { files } = res;
        onChange("partnerConsentLetterUrl", files[0]);
        setIsUploadingFile(false);
      })
      .catch((e) => {
        setIsUploadingFile(false);
      });
  };

  return (
    <div className="application-personal-info-c notransalate">
      <div className="application-personal-info-c__heading">
        <h1>{experienceObj.title}</h1>
        <p>{declarationTranslations.welcome}</p>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <span
          className="back-2-somewhere"
          onClick={() => props.history.replace("/home", {})}
        >
          {t("Back to Home")}
        </span>

        <span className="back-2-somewhere" onClick={_previousStep}>
          {t("Back to Bio")}
        </span>
      </div>

      <div className="application-personal-info-c__form">
        <div className="p-info-form-heading">
          <h5>
            <span className="bold">{experienceObj.title}</span>{" "}
          </h5>
          <h4>{sectionTwoTranslations[language || "en"]}</h4>
        </div>
        <form ref={ref}>
          {hasError && (
            <span style={{ color: "red" }}>{experienceObj.requiredInfo}</span>
          )}
          <div className="row">
            <div className="col-md-12">
              <FormField
                placeholder={experienceObj.businessSummary}
                label={experienceObj.businessIdea}
                type="textarea"
                id="aboutIdea"
                value={info.aboutIdea}
                onChange={onChange}
                name="aboutIdea"
                onBlur={() =>
                  _handleValidationErrorChanges(
                    "aboutIdea",
                    ValidateJs("aboutIdea", info.aboutIdea)
                  )
                }
                error={info.validationErrors.aboutIdea}
              />
              {info.validationErrors.aboutIdea &&
                displayError(t(info.validationErrors.aboutIdea))}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <br />
              <FormField
                label={experienceObj.goals}
                // labelInfo="Choose top 3"
                id="sdgs"
                error={info.validationErrors.sdgs}
              >
                <Multiselect
                  options={sdgGoals}
                  displayValue="label"
                  onSelect={(selectedList, selectedItem) =>
                    onSdgSelect("sdgs", selectedList)
                  }
                  placeholder={t("Select")}
                  selectionLimit={3}
                  selectedValues={info.sdgs}
                  closeOnSelect={false}
                  style={{
                    chips: {
                      background: "red",
                    },
                  }}
                  onBlur={() =>
                    _handleValidationErrorChanges(
                      "sdgs",
                      ValidateJs("sdgs", info.sdgs)
                    )
                  }
                  onRemove={(selectedList, selectedItem) =>
                    onSdgSelect("sdgs", selectedList)
                  }
                />
              </FormField>
              {info.validationErrors.sdgs &&
                displayError(t(info.validationErrors.sdgs))}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <br />
              <FormField
                label={
                  experienceObj.isBusinessAddressGreenEconomyOrClimateChange
                }
                id="isBusinessAddressGreenEconomyOrClimateChange"
              >
                <div className="row">
                  <div className="col-sm-6 gutter-v">
                    <SelectBox
                      value={true}
                      label={experienceObj.yes}
                      onSelect={(target, value) => {
                        const errors = { ...info.validationErrors };
                        errors.isBusinessAddressGreenEconomyOrClimateChange = null;
                        setInfo({
                          ...info,
                          [target]: value,
                          validationErrors: errors,
                        });
                      }}
                      name="isBusinessAddressGreenEconomyOrClimateChange"
                      selected={
                        info.isBusinessAddressGreenEconomyOrClimateChange
                      }
                    />
                  </div>
                  <div className="col-sm-6 gutter-v">
                    <SelectBox
                      value={false}
                      label={experienceObj.no}
                      onSelect={(target, value) => {
                        const errors = { ...info.validationErrors };
                        errors.isBusinessAddressGreenEconomyOrClimateChange = null;
                        setInfo({
                          ...info,
                          [target]: value,
                          businessAddressGreenEconomyOrClimateChangeDescription:
                            "",
                          validationErrors: errors,
                        });
                      }}
                      name="isBusinessAddressGreenEconomyOrClimateChange"
                      selected={
                        info.isBusinessAddressGreenEconomyOrClimateChange
                      }
                    />
                  </div>
                </div>
                {info.validationErrors
                  .isBusinessAddressGreenEconomyOrClimateChange &&
                  displayError(
                    info.validationErrors
                      .isBusinessAddressGreenEconomyOrClimateChange
                  )}
              </FormField>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <FormField
                disabled={
                  info.isBusinessAddressGreenEconomyOrClimateChange === false
                }
                label={
                  experienceObj.businessAddressGreenEconomyOrClimateChangeDescription
                }
                type="textarea"
                id="businessAddressGreenEconomyOrClimateChangeDescription"
                value={
                  info.businessAddressGreenEconomyOrClimateChangeDescription
                }
                onChange={onChange}
                name="businessAddressGreenEconomyOrClimateChangeDescription"
                onBlur={() =>
                  _handleValidationErrorChanges(
                    "businessAddressGreenEconomyOrClimateChangeDescription",
                    ValidateJs(
                      "businessAddressGreenEconomyOrClimateChangeDescription",
                      info.businessAddressGreenEconomyOrClimateChangeDescription
                    )
                  )
                }
                error={
                  info.validationErrors
                    .businessAddressGreenEconomyOrClimateChangeDescription
                }
              />
              {info.validationErrors
                .businessAddressGreenEconomyOrClimateChangeDescription &&
                displayError(
                  info.validationErrors
                    .businessAddressGreenEconomyOrClimateChangeDescription
                )}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6" style={{ marginTop: "-5px" }}>
              <FormField
                label={experienceObj.businessName}
                placeholder={experienceObj.namePlaceholder}
                value={info.businessName}
                onChange={onChange}
                name="businessName"
                id="businessName"
                error={info.validationErrors.businessName}
              />
            </div>
            <div className="col-md-6">
              <FormField label={experienceObj.industry} id="businessIndustry">
                <Select
                  defaultVal={experienceObj.select1}
                  list={industryObj}
                  value={info.businessIndustry}
                  onChange={(target, value) => {
                    const obj = { ...info };
                    obj[target] = value;
                    obj.industrySubSector = "";
                    setInfo(obj);
                  }}
                  name="businessIndustry"
                  onBlur={() =>
                    _handleValidationErrorChanges(
                      "businessIndustry",
                      ValidateJs("businessIndustry", info.businessIndustry)
                    )
                  }
                  error={info.validationErrors.businessIndustry}
                />
              </FormField>
              {info.validationErrors.businessIndustry &&
                displayError(info.validationErrors.businessIndustry)}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormField label={experienceObj.subSector} id="industrySubSector">
                <Input
                  type="select"
                  defaultValue={t("Select")}
                  onBlur={() =>
                    _handleValidationErrorChanges(
                      "industrySubSector",
                      ValidateJs("industrySubSector", info.industrySubSector)
                    )
                  }
                  onChange={(e) =>
                    onChange("industrySubSector", e.target.value)
                  }
                  name="industrySubSector"
                  value={info.industrySubSector}
                >
                  <option value="">{t("Select")}</option>
                  {industrySubSectList.map((e, i) => {
                    return (
                      <option key={i} value={e.value}>
                        {e.label}{" "}
                      </option>
                    );
                  })}
                </Input>
              </FormField>
              {info.validationErrors.industrySubSector &&
                displayError(info.validationErrors.industrySubSector)}
            </div>
            <div className="col-md-6">
              <FormField
                // className="col-md-6 "
                label={experienceObj.businessCountry}
                id="businessCountryId"
              >
                <CustomCountryDropdown
                  classes={`flex country-state-list`}
                  value={info.businessCountryId}
                  name="businessCountryId"
                  valueType="short"
                  onChange={(val) => onChange("businessCountryId", val)}
                  onBlur={() =>
                    _handleValidationErrorChanges(
                      "businessCountryId",
                      ValidateJs("businessCountryId", info.businessCountryId)
                    )
                  }
                />
              </FormField>
              {info.validationErrors.businessCountryId ? (
                <div className="displayError" style={{ marginLeft: "3px" }}>
                  {displayError(info.validationErrors.businessCountryId)}
                </div>
              ) : (
                <div className="displayError">{displayError("")}</div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormField label={experienceObj.businessState} id="businessState">
                <CustomCountryRegionDropdown
                  countryValueType="short"
                  defaultOptionLabel={experienceObj.select2}
                  country={info.businessCountryId}
                  classes={`flex country-state-list $}`}
                  name="businessState"
                  value={info.businessState}
                  onChange={(val) => onChange("businessState", val)}
                  onBlur={() =>
                    _handleValidationErrorChanges(
                      "businessState",
                      ValidateJs("businessState", info.businessState)
                    )
                  }
                />
              </FormField>
              {info.validationErrors.businessState ? (
                <div className="displayError" style={{ marginLeft: "3px" }}>
                  {displayError(info.validationErrors.businessState)}
                </div>
              ) : (
                <div className="displayError">{displayError("")}</div>
              )}
            </div>
            <div className="col-md-6" style={{ marginTop: "-10px" }}>
              <FormField
                label={experienceObj.businessCity}
                placeholder={experienceObj.cityPlaceholder}
                value={info.businessCity}
                name="businessCity"
                id="businessCity"
                onChange={onChange}
                required
                onBlur={() =>
                  _handleValidationErrorChanges(
                    "businessCity",
                    ValidateJs("businessCity", info.businessCity)
                  )
                }
              />
              {info.validationErrors.businessCity &&
                displayError(info.validationErrors.businessCity)}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormField
                label={experienceObj.businessLocation}
                placeholder="Enter Business address"
                value={info.businessAddress}
                onChange={onChange}
                name="businessAddress"
                id="businessAddress"
                onBlur={() =>
                  _handleValidationErrorChanges(
                    "businessAddress",
                    ValidateJs("businessAddress", info.businessAddress)
                  )
                }
                error={info.validationErrors.businessAddress}
              />
              {info.validationErrors.businessAddress &&
                displayError(info.validationErrors.businessAddress)}
            </div>

            <div className="col-md-6">
              <FormField
                label={experienceObj.businessWebsite}
                // placeholder="Enter Business address"
                value={info.businessWebsite}
                onChange={onChange}
                name="businessWebsite"
                id="businessWebsite"
                type="url"
                error={info.validationErrors.businessWebsite}
              />
              {info.validationErrors.businessWebsite &&
                displayError(info.validationErrors.businessWebsite)}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormField
                label={experienceObj.businessLocalGovernmentArea}
                placeholder={experienceObj.businessLocalGovernmentArea}
                value={info.businessLocalGovernmentArea}
                onChange={onChange}
                disabled={info.businessCountryId.toLowerCase() !== "ng"}
                name="businessLocalGovernmentArea"
                id="businessLocalGovernmentArea"
                onBlur={() =>
                  _handleValidationErrorChanges(
                    "businessLocalGovernmentArea",
                    ValidateJs(
                      "businessLocalGovernmentArea",
                      info.businessLocalGovernmentArea
                    )
                  )
                }
                error={info.validationErrors.businessLocalGovernmentArea}
              />
              {info.validationErrors.businessLocalGovernmentArea &&
                displayError(info.validationErrors.businessLocalGovernmentArea)}
            </div>

            <div className="col-md-6">
              <FormField label={experienceObj.businessStage} id="businessStage">
                <Select
                  defaultVal={experienceObj.select1}
                  list={bStages}
                  value={info.businessStage}
                  onChange={onChange}
                  name="businessStage"
                  onBlur={() =>
                    _handleValidationErrorChanges(
                      "businessStage",
                      ValidateJs("businessStage", info.businessStage)
                    )
                  }
                  error={info.validationErrors.businessStage}
                />
              </FormField>
              {info.validationErrors.businessStage &&
                displayError(info.validationErrors.businessStage)}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <FormField
                label={experienceObj.isBusinessRegistered}
                id="isBusinessRegistered"
              >
                <div className="row">
                  <div className="col-sm-6 gutter-v">
                    <SelectBox
                      value={true}
                      label={experienceObj.yes}
                      onSelect={(target, value) => {
                        const errors = { ...info.validationErrors };
                        errors.isBusinessRegistered = null;
                        setInfo({
                          ...info,
                          [target]: value,
                          validationErrors: errors,
                        });
                      }}
                      name="isBusinessRegistered"
                      selected={info.isBusinessRegistered}
                    />
                  </div>
                  <div className="col-sm-6 gutter-v">
                    <SelectBox
                      value={false}
                      label={experienceObj.no}
                      onSelect={(target, value) => {
                        const errors = { ...info.validationErrors };
                        errors.isBusinessRegistered = null;
                        setInfo({
                          ...info,
                          [target]: value,
                          businessRegistrationNumber: "",
                          validationErrors: errors,
                        });
                      }}
                      name="isBusinessRegistered"
                      selected={info.isBusinessRegistered}
                    />
                  </div>
                </div>
                {info.validationErrors.isBusinessRegistered &&
                  displayError(info.validationErrors.isBusinessRegistered)}
              </FormField>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <FormField
                disabled={!info.isBusinessRegistered}
                label={experienceObj.businessRegistrationNumber}
                id="businessRegistrationNumber"
                value={info.businessRegistrationNumber}
                onChange={onChange}
                name="businessRegistrationNumber"
                onBlur={() =>
                  _handleValidationErrorChanges(
                    "businessRegistrationNumber",
                    ValidateJs(
                      "businessRegistrationNumber",
                      info.businessRegistrationNumber
                    )
                  )
                }
                error={info.validationErrors.businessRegistrationNumber}
              />
              {info.validationErrors.businessRegistrationNumber &&
                displayError(info.validationErrors.businessRegistrationNumber)}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <FormField
                label={experienceObj.noOfPeople}
                placeholder="e.g 20"
                type="number"
                min="1"
                name="teamSize"
                id="teamSize"
                onChange={onChange}
                value={info.teamSize.toString()}
              ></FormField>
              {info.validationErrors.teamSize &&
                displayError(info.validationErrors.teamSize)}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <br />
              <FormField
                label={experienceObj.businessPartners}
                id="haveBusinessPartners"
              >
                <div className="row">
                  <div className="col-sm-6 gutter-v">
                    <SelectBox
                      value={true}
                      selected={info.haveBusinessPartners}
                      name="haveBusinessPartners"
                      label={experienceObj.yes}
                      onSelect={(target, value) => {
                        const errors = { ...info.validationErrors };
                        errors.haveBusinessPartners = null;
                        setInfo({
                          ...info,
                          [target]: value,
                          validationErrors: errors,
                        });
                      }}
                    />
                  </div>
                  <div className="col-sm-6 gutter-v">
                    <SelectBox
                      value={false}
                      selected={info.haveBusinessPartners}
                      name="haveBusinessPartners"
                      label={experienceObj.no}
                      onSelect={(target, value) => {
                        const errors = { ...info.validationErrors };
                        errors.haveBusinessPartners = null;
                        setInfo({
                          ...info,
                          [target]: value,
                          hasBusinessPartnerConsent: null,
                          validationErrors: errors,
                        });
                      }}
                    />
                  </div>
                </div>
                {info.validationErrors.haveBusinessPartners &&
                  displayError(info.validationErrors.haveBusinessPartners)}
              </FormField>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <br />
              <FormField
                label={experienceObj.partnerConsent}
                id="hasBusinessPartnerConsent"
              >
                <div className="row">
                  <div className="col-sm-6 gutter-v">
                    <SelectBox
                      value={true}
                      selected={info.hasBusinessPartnerConsent}
                      name="hasBusinessPartnerConsent"
                      label={experienceObj.yes}
                      onSelect={(target, value) => {
                        const errors = { ...info.validationErrors };
                        errors.hasBusinessPartnerConsent = null;
                        setInfo({
                          ...info,
                          [target]: value,
                          validationErrors: errors,
                        });
                      }}
                      disabled={!info.haveBusinessPartners}
                      nullable={info.hasBusinessPartnerConsent === null}
                    />
                  </div>
                  <div className="col-sm-6 gutter-v">
                    <SelectBox
                      value={false}
                      selected={info.hasBusinessPartnerConsent}
                      name="hasBusinessPartnerConsent"
                      label={experienceObj.no}
                      onSelect={(target, value) => {
                        const errors = { ...info.validationErrors };
                        errors.hasBusinessPartnerConsent = null;
                        setInfo({
                          ...info,
                          [target]: value,
                          validationErrors: errors,
                        });
                      }}
                      disabled={!info.haveBusinessPartners}
                      nullable={info.hasBusinessPartnerConsent === null}
                    />
                  </div>
                </div>
              </FormField>
              {info.validationErrors.hasBusinessPartnerConsent &&
                displayError(info.validationErrors.hasBusinessPartnerConsent)}
            </div>
          </div>

          {info.hasBusinessPartnerConsent && (
            <div className="row">
              <div className="col-md-12">
                <FormField
                  label={experienceObj.partnerConsentLetterUrl}
                  type="file"
                  name="partnerConsentLetterUrl"
                  id="partnerConsentLetterUrl"
                  onBlur={() =>
                    _handleValidationErrorChanges(
                      "partnerConsentLetterUrl",
                      ValidateJs(
                        "partnerConsentLetterUrl",
                        info.partnerConsentLetterUrl
                      )
                    )
                  }
                  error={info.validationErrors.partnerConsentLetterUrl}
                >
                  <div>
                    <div>
                      <div
                        className="id-upload-btn col-md-5"
                        onClick={() =>
                          document
                            .getElementById("previewPartnerConsentLetterUrl")
                            .click()
                        }
                      >
                        <span>
                          <FaUpload color="white" size={12} />
                        </span>
                        <span>{t("Choose file")}</span>
                        <span>
                          {isuploadingFile && (
                            <Loader
                              type="Oval"
                              height={13}
                              width={13}
                              color="white"
                            />
                          )}
                        </span>
                      </div>
                    </div>
                    <input
                      type="file"
                      name="previewPartnerConsentLetterUrl"
                      id="previewPartnerConsentLetterUrl"
                      onChange={_onDocChange}
                      className="id-file-upload"
                      // accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />
                  </div>

                  {info.partnerConsentLetterUrl && (
                    <div className="">
                      <FileViewerReadOnly
                        files={[info.partnerConsentLetterUrl]}
                      />
                    </div>
                  )}
                </FormField>
                {fileError && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {fileError}
                  </span>
                )}
                {info.validationErrors.partnerConsentLetterUrl &&
                  displayError(info.validationErrors.partnerConsentLetterUrl)}
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-md-6">
              <br />
              <FormField label={experienceObj.rural} id="isBusinessRural">
                <div className="row">
                  <div className="col-sm-6 gutter-v">
                    <SelectBox
                      value={true}
                      selected={info.isBusinessRural}
                      name="isBusinessRural"
                      label={experienceObj.yes}
                      onSelect={(target, value) => {
                        const errors = { ...info.validationErrors };
                        errors.isBusinessRural = null;
                        setInfo({
                          ...info,
                          [target]: value,
                          validationErrors: errors,
                        });
                      }}
                    />
                  </div>
                  <div className="col-sm-6 gutter-v">
                    <SelectBox
                      value={false}
                      selected={info.isBusinessRural}
                      name="isBusinessRural"
                      label={experienceObj.no}
                      onSelect={(target, value) => {
                        const errors = { ...info.validationErrors };
                        errors.isBusinessRural = null;
                        setInfo({
                          ...info,
                          [target]: value,
                          validationErrors: errors,
                        });
                      }}
                    />
                  </div>
                </div>
                {info.validationErrors.isBusinessRural &&
                  displayError(info.validationErrors.isBusinessRural)}
              </FormField>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <br />
              <FormField
                label={experienceObj.isInternetStable}
                id="hasStableInternet"
              >
                <div className="row">
                  <div className="col-sm-6 gutter-v">
                    <SelectBox
                      value={true}
                      selected={info.hasStableInternet}
                      name="hasStableInternet"
                      label={experienceObj.yes}
                      onSelect={onChange}
                    />
                  </div>
                  <div className="col-sm-6 gutter-v">
                    <SelectBox
                      value={false}
                      selected={info.hasStableInternet}
                      name="hasStableInternet"
                      label={experienceObj.no}
                      onSelect={onChange}
                    />
                  </div>
                </div>
              </FormField>
            </div>
          </div>

          <div className="submit-box">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="submit-btn" onClick={_handleSubmit}>
                  <span>{experienceObj.continue}</span>
                  {loadingSave && (
                    <Loader type="Oval" color="white" height={15} width={15} />
                  )}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <button
                  className="save-continue-btn"
                  onClick={_saveAndContinue}
                >
                  {isSavingAndContinue ? (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        outline: "none",
                      }}
                    >
                      <span> {t("saving progress")}</span>
                      <span>
                        <Loader
                          type="Oval"
                          color="red"
                          height={15}
                          width={15}
                        />
                      </span>
                    </span>
                  ) : (
                    <span>
                      <span>
                        <FaSave />{" "}
                      </span>{" "}
                      {t("Save to continue later")}
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <>
        <CLoader
          loading={!isPersonalInfoInit || loadingSave || isSavingAndContinue}
          additonalInfo={updatingMessage}
        />
      </>
      <>
        <ProgressReport
          showReport={showReport}
          onReportClose={_onContinueClick}
          onSubmitClick={_onContinueClick}
          percentageComplete={percentComplete}
        />
      </>
    </div>
  );
};
export default Experience;
