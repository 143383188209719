/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Context } from "src/store";
import { FaAngleLeft } from "react-icons/fa";
import { getUserGroups } from "src/requests/StudyGroupsRequest";
import SuggestedGroupCard from "src/modules/training/StudyGroups/components/SuggestedGroup/SuggestedGroupCard/SuggestedGroupCard";
import Loader from "src/components/Loader/Loader";
import ResultPagination from "src/modules/training/StudyGroups/components/ResultPagination/ResultPagination";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import axios from "axios";
import queryString from "query-string";

const MyGroups = () => {
  const user = get_user_info_from_token() && get_user_info_from_token().user;
  const trainingEventId = user && user.training ? user.training[1] : null;

  axios.defaults.headers.common["trainingevent-id"] = trainingEventId;
  const { userId } = useCurrentUser();
  const { state, dispatch } = useContext(Context);
  const [userGroups, setUserGroups] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [pager, setPager] = useState({
    firstItemOnPage: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    lastItemOnPage: 1,
    pageCount: 1,
    pageNumber: 1,
    pageSize: 1,
    totalItemCount: 1,
  });

  const [pageDetails, setPageDetails] = useState({
    pageSize: 16,
    pageNumber: pager.pageNumber,
  });

  const onPageSelect = (e) => {
    setIsPageLoading(true);
    setPageDetails({
      ...pageDetails,
      pageNumber: e.selected + 1,
    });
  };

  const { course } = queryString.parse(location.search);

  useEffect(() => {
    setIsPageLoading(true);
    getUserGroups(dispatch, userId, pageDetails, course).then((res) => {
      if (res !== undefined && res.status === 200) {
        const {
          data: { data, pager },
        } = res.data;
        setPager(pager);
        setUserGroups(data);
        setIsPageLoading(false);
      }
    });
  }, [
    pageDetails,
    !!state.Communities.joinGroup,
    !!state.Communities.leaveGroup,
  ]);

  const cards = userGroups.map((group, index) => {
    return (
      <SuggestedGroupCard
        key={index}
        id={group.id}
        groupLogo={group.imageUrl}
        title={group.name}
        members={group.membersCount}
        location={group.location}
        belongs={group.isMember}
        creatorId={group.userId}
        privacy={group.privacy}
        likeCount={group.likeCount}
      />
    );
  });

  const history = useHistory();
  return (
    <div className="results-main">
      <div className="results-main__heading">
        <Link
          // to="/training/studygroups"
          className="results-main__heading--back"
          onClick={() => history.goBack()}
        >
          <FaAngleLeft /> &nbsp; Back
        </Link>
        <h1 className="results-main__heading--title">My Groups</h1>
      </div>
      {isPageLoading ? (
        <div className="results-main__loader">
          <Loader color="danger" />
        </div>
      ) : (
        <div className="results-main__results">
          {userGroups.length > 0 ? (
            cards
          ) : (
            <p className="results-main__results--no-group">
              You don't belong to any group at the moment
            </p>
          )}
        </div>
      )}
      {userGroups.length > 0 ? (
        <div className="results-main__pagination results-main__pagination--bottom">
          <ResultPagination
            pageCount={pager.pageCount}
            pageRange={1}
            onSelect={onPageSelect}
            current={pager.pageNumber}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MyGroups;
