import React from "react";

import FormInput from "src/modules/Tef-Dms/components/Form/FormInput";

const Competition = ({ competition, setCompetition }) => {
  const handleChange = (e) => {
    setCompetition({
      ...competition,
      [e.target.name]: e.target.value,
    });
  };

  const handleDynamicInput = (key, i, e) => {
    const newArray = key;
    newArray[i] = e.target.value;
    setCompetition({
      ...competition,
      [key]: newArray,
    });
  };

  return (
    <React.Fragment>
      <FormInput
        label="Competitive Analysis"
        getFormInputValue={(e) => handleChange(e)}
        value={competition.competitiveAnalysis}
        name="competitiveAnalysis"
        placeholder="Competitve Advantage"
      />
      <FormInput
        getFormInputValue={(e) => handleChange(e)}
        value={competition.productOffers}
        name="productOffers"
        placeholder="Product/Service Offers"
      />

      <div className="mt-2" style={{ fontSize: "0.75rem" }}>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td colSpan="3" className="p-2">
                Competitive Analysis
              </td>
            </tr>
            <tr>
              <th scope="row">Name of Competitors</th>
              {competition.nameOfCompetitors.map((item, index) => (
                <td key={index}>
                  <FormInput
                    key={index}
                    getFormInputValue={(e) =>
                      handleDynamicInput(
                        competition.nameOfCompetitors,
                        index,
                        e
                      )
                    }
                    value={item}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row">Location of Competitors</th>
              {competition.locationOfCompetitors.map((item, index) => (
                <td key={index}>
                  <FormInput
                    key={index}
                    getFormInputValue={(e) =>
                      handleDynamicInput(
                        competition.locationOfCompetitors,
                        index,
                        e
                      )
                    }
                    value={item}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row">Products and services offered</th>
              {competition.productsOffered.map((item, index) => (
                <td key={index}>
                  <FormInput
                    key={index}
                    getFormInputValue={(e) =>
                      handleDynamicInput(competition.productsOffered, index, e)
                    }
                    value={item}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row">Pricing Strategy</th>
              {competition.pricingStrategy.map((item, index) => (
                <td key={index}>
                  <FormInput
                    key={index}
                    getFormInputValue={(e) =>
                      handleDynamicInput(competition.pricingStrategy, index, e)
                    }
                    value={item}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row">What are their uniqueness?</th>
              {competition.uniqueness.map((item, index) => (
                <td key={index}>
                  <FormInput
                    key={index}
                    getFormInputValue={(e) =>
                      handleDynamicInput(competition.uniqueness, index, e)
                    }
                    value={item}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row">What is their other strength?</th>
              {competition.strength.map((item, index) => (
                <td key={index}>
                  <FormInput
                    key={index}
                    getFormInputValue={(e) =>
                      handleDynamicInput(competition.strength, index, e)
                    }
                    value={item}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row">What are their weaknesses?</th>
              {competition.weaknesses.map((item, index) => (
                <td key={index}>
                  <FormInput
                    key={index}
                    getFormInputValue={(e) =>
                      handleDynamicInput(competition.weaknesses, index, e)
                    }
                    value={item}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row">How long have they been existing?</th>
              {competition.durationOfExistence.map((item, index) => (
                <td key={index}>
                  <FormInput
                    key={index}
                    getFormInputValue={(e) =>
                      handleDynamicInput(
                        competition.durationOfExistence,
                        index,
                        e
                      )
                    }
                    value={item}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row">Customers segments</th>
              {competition.customerSegments.map((item, index) => (
                <td key={index}>
                  <FormInput
                    key={index}
                    getFormInputValue={(e) =>
                      handleDynamicInput(competition.customerSegments, index, e)
                    }
                    value={item}
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default Competition;
