import React, { useState, useContext, useEffect } from "react";
import { Context } from "src/store";
import {
  getAllCategories,
  // isGroupMember,
  getAllGroups,
  getConnectionsGroups,
} from "src/requests/CommunityRequest";
import "./Main.scss";
import Search from "src/modules/Community/components/Search/Search";
import SuggestedGroup from "src/modules/Community/components/SuggestedGroup";
import Categories from "src/modules/Community/components/Categories";
import FriendsGroup from "src/modules/Community/components/FriendsGroup";
import ThreadNav from "src/modules/Community/components/Thread/ThreadNav/ThreadNav";
import { useCurrentUser } from "src/utils/Services/currentUser";

const Main = () => {
  const { userId } = useCurrentUser();
  const { state, dispatch } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  // const [usersGroups, setUsersGroups] = useState([]);
  const [group, setGroup] = useState([]);
  const [isGettingGroupsLoading, setIsGettingGroupsLoading] = useState(true);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1392 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1391, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 538 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 538, min: 356 },
      items: 2,
    },
    verySmallMobile: {
      breakpoint: { max: 355, min: 320 },
      items: 1,
    },
  };
  const [connectionsGroups, setConnectionsGroups] = useState([]);
  const [isGroupsLoading, setIsGroupsLoading] = useState(true);

  // const getGroupUserBelongsTo = () => {
  //   isGroupMember(dispatch, userId).then((res) => {
  //     if (res !== undefined && res.status === 200) {
  //       const {
  //         data: { data },
  //       } = res.data;

  //       setUsersGroups(data);
  //     }
  //   });
  // };

  useEffect(() => {
    // getGroupUserBelongsTo();
  }, [!!state.Communities.joinGroup, !!state.Communities.leaveGroup]);

  useEffect(() => {
    getAllCategories(dispatch).then((res) => {
      if (res !== undefined && res.status === 200) {
        setIsLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    getAllGroups(dispatch, { pageSize: 7, pageNumber: 1 }).then((res) => {
      if (res !== undefined && res.status === 200) {
        const {
          data: { data },
        } = res.data;
        setIsGettingGroupsLoading(false);
        setGroup(data);
      }
    });
  }, [state.Communities.group]);

  useEffect(() => {
    getConnectionsGroups(dispatch, userId, {
      pageNumber: 1,
      pageSize: 6,
    }).then((res) => {
      if (res.status) {
        const {
          data: { data },
        } = res.data;
        setConnectionsGroups(data);
        setIsGroupsLoading(false);
      }
    });
  }, []);

  const { categories } = state.Communities;

  return (
    <div className="community-main">
      <ThreadNav categories={categories} />
      <Search categories={categories} />
      <SuggestedGroup
        // usersGroups={usersGroups}
        allGroups={group}
        isLoading={isGettingGroupsLoading}
        responsiveSize={responsive}
      />
      <Categories
        categories={categories}
        loading={isLoading}
        responsiveSize={responsive}
      />
      <FriendsGroup
        groups={connectionsGroups}
        groupLoading={isGroupsLoading}
        // usersGroups={usersGroups}
      />
    </div>
  );
};

export default Main;
