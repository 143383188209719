import React from "react";
import Layout from "src/modules/Application/components/Layout/Layout";
import ExperiencePage from "./experience";

const Experience = (props) => {
  return (
    <div>
      <Layout>
        <ExperiencePage {...props} />
      </Layout>
    </div>
  );
};

export default Experience;
