import React, { useState, useContext, useEffect, useRef } from "react";
import { Col, Row, Button, Card, Badge } from "reactstrap";
import { useLocation } from "react-router-dom";
import { Context } from "src/store";
import StepWizard from "react-step-wizard";
import {
  getQuestionsForModule,
  submitAssessment,
} from "src/requests/TrainingRequest";
import { useTranslation } from "react-i18next";
import { sectionFourTranslations } from "src/modules/Application/instructions";
import ProgressReport from "src/modules/training/components/ApplicantProgressReport/ProgressReport";
import CLoader from "src/modules/Application/components/CLoader";
import Empty from "src/modules/mentorship/Calendar/utils/Empty";
import queryString from "query-string";
import ExceededQuizTrial from "src/modules/training/components/ExceededQuizTrial/ExceededQuizTrial";
import { showLoading } from "src/modules/Tools/components/CLoader";
import Loader from "react-loader-spinner";
import classes from "./CognitiveTest.module.css";
import Swal from "sweetalert2";

const LMSQuestions = (props) => {
  const { t } = useTranslation();
  const initialState = {
    answers: null,
  };

  const [loading, setLoading] = useState(false);
  const [quizTrialExceeded, setQuizTrialExceeded] = useState(false);
  const [finalScore, setFinalScore] = useState(0);
  const [levelModal, setLevelModal] = React.useState(false);
  const [loader] = React.useState(false);
  const [score, setScore] = React.useState(0);
  const [answers, setAnswers] = useState(initialState.answers);
  const prevAnswer = usePrevious({ answers, setAnswers });
  const { dispatch } = useContext(Context);
  const qa = useLocation();
  const { lang, trainingEventId, quizId } = queryString.parse(qa.search);

  useEffect(() => {
    _getQuestionsForModule();
  }, []);

  useEffect(() => {
    if (answers && answers.length > 0) {
      updateLocalStorage();
    }
  }, [answers]);
  if (!localStorage.mcq || localStorage.mcq === undefined) {
    localStorage.mcq = "[]";
  }

  const _onContinueClick = () => {
    props.changeState(false);
    props.onContinue();
  };

  const _getQuestionsForModule = () => {
    getQuestionsForModule(
      dispatch,
      trainingEventId,
      quizId,
      lang,
      "61fa54b9-c720-4d2f-ab67-ece1da2b0e2e"
    )
      .then((res) => {
        if (res && res.status === 200) {
          const { answerableQuestions } = res.data.data;
          setAnswers(answerableQuestions && answerableQuestions);
        }

        if (res && res.status === 209) {
          setFinalScore(res.data?.data?.score);
          setQuizTrialExceeded(true);
          setAnswers([]);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          const { data } = err.response.data;
          setFinalScore(data);
          setQuizTrialExceeded(true);
          setAnswers([]);
        } else if (err.response && err.response.status === 400) {
          Swal.fire({
            icon: "info",
            title: "Error!",
            // text: "You cannot submit quiz for general courses",
            text: err.response.data.message,
            confirmButtonColor: "#ad2116",
          });
          setAnswers([]);
        } else {
          setAnswers([]);
        }
      });
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const handleSubmit = () => {
    setLoading(true);
    const payload = JSON.parse(localStorage.mcq);
    const answerObject = [
      {
        answerableQuestions: payload,
      },
    ];
    submitAssessment(dispatch, trainingEventId, quizId, answerObject).then(
      (res) => {
        if (res.status === 200) {
          setScore(res.data.data);
          setLevelModal(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    );
  };

  const updateLocalStorage = () => {
    localStorage.mcq = JSON.stringify(answers);
  };

  const handleInputChange = (optionId, optionIndex) => {
    const index = answers.findIndex((answers) =>
      answers.options.find((option) => option.id === optionId)
    );
    const question = answers.find((answers) =>
      answers.options.find((option) => option.id === optionId)
    );

    const selectedOption = {
      ...question,
    };
    selectedOption.options.forEach((opt) => {
      opt.isSelected = false;
    });
    selectedOption.options[optionIndex].isSelected = true;
    if (answers[index]) {
      const existingQuestions = [...answers];
      existingQuestions[index] = selectedOption;
      setAnswers(existingQuestions);
    }
  };

  const DisplayQuestions = ({
    question,
    options,
    questionIndex,
    nextStep,
    previousStep,
    currentStep,
    totalSteps,
  }) => {
    const checkedOption =
      prevAnswer.answers &&
      prevAnswer.answers.length !== 0 &&
      prevAnswer.answers[questionIndex].options.find(
        (option) => option.isSelected === true
      );
    return (
      <Col>
        {/* {showTimer()} */}
        <Row>
          <Badge color="light">
            {" "}
            {questionIndex + 1} of {answers && answers.length}
          </Badge>
        </Row>
        <Row>
          {/* <p>
            {question} <br />
          </p> */}
          <div dangerouslySetInnerHTML={{ __html: `${question}` }}></div> <br />
        </Row>

        <Row>
          <form className="form">
            {options &&
              options.map((option, index) => (
                <div className="inputGroup" key={index}>
                  <input
                    id={option.optionContent + question}
                    name="radio"
                    type="radio"
                    // eslint-disable-next-line no-unused-vars
                    onChange={(e) => handleInputChange(option.id, index)}
                    checked={
                      checkedOption ? checkedOption.id === option.id : false
                    }
                  />
                  <label htmlFor={option.optionContent + question}>
                    {option.optionContent}
                  </label>
                </div>
              ))}
          </form>
        </Row>
        <Row>
          {currentStep > 1 && (
            <Button
              color="secondary"
              onClick={previousStep}
              style={{ marginRight: "5%" }}
            >
              Previous
            </Button>
          )}
          {currentStep !== totalSteps && (
            <Button color="secondary" onClick={nextStep}>
              {t("Next")}
            </Button>
          )}
          {currentStep === totalSteps && (
            <div
              className={`btn btn-outline-secondary ${
                loading ? classes.Disabled : ""
              } `}
              onClick={handleSubmit}
            >
              {loading ? (
                <Loader type="Oval" color="red" height={30} width={30} />
              ) : (
                t("Submit")
              )}
            </div>
          )}
        </Row>
      </Col>
    );
  };

  const custom = {
    enterRight: "none",
    enterLeft: "none",
    exitRight: "none",
    exitLeft: "none",
  };

  return (
    <>
      {answers === null && showLoading()}
      {answers && !quizTrialExceeded && answers.length === 0 && (
        <Empty
          message="No question has been added to this module"
          linkText={"Go to next module"}
          goToNext={_onContinueClick}
        />
      )}
      {answers && quizTrialExceeded && answers.length === 0 && (
        <ExceededQuizTrial
          percentageComplete={finalScore && finalScore}
          onSubmitClick={_onContinueClick}
          onReportClose={_onContinueClick}
          showReport={true}
        />
      )}
      {answers && answers.length > 0 && (
        <Col md={12} sm={12} xs={12} lg={9}>
          <Card className="personality-card">
            {answers.length < 1 ? (
              <CLoader loading={loader} />
            ) : (
              <span>
                <Row>
                  <h3 className="personality-title">
                    <b>MultiChoice</b> Questions
                  </h3>
                </Row>
                <Row>
                  <p className="personality-sub">
                    {sectionFourTranslations.en}
                  </p>
                </Row>
                <StepWizard transitions={custom}>
                  {answers &&
                    answers.map((question, index) => (
                      <DisplayQuestions
                        key={index}
                        question={question.questionContent}
                        options={question.options}
                        questionIndex={index}
                      />
                    ))}
                </StepWizard>
              </span>
            )}
          </Card>
          <ProgressReport
            showReport={levelModal}
            onReportClose={_onContinueClick}
            onSubmitClick={_onContinueClick}
            percentageComplete={score}
            quizInfo={props.quizInfo}
            onQuizClick={props.onQuizClick}
            close={() => setLevelModal(!levelModal)}
            onRetakeQuiz={props.changeState}
            getCumulativeAvgForModule={props.getCumulativeAvgForModule}
            numberOfAttempts={props.numberOfAttempts}
          />
        </Col>
      )}
    </>
  );
};
LMSQuestions.propTypes = {};

export default LMSQuestions;
