import React from "react";
// import ContentLoader from "react-content-loader";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const CommentLoader = () => {
  const items = [1];
  return (
    <>
      {items.map((item, idx) => (
        <SkeletonTheme key={idx} item={idx}>
          <Skeleton
            width={50}
            circle={true}
            height={50}
            style={{ float: "left" }}
          />
          <div className="absolute">
            <div style={{ float: "left" }}>
              {" "}
              <Skeleton width={600} height={100} style={{ float: "left" }} />
            </div>
            <div></div>
          </div>
        </SkeletonTheme>
      ))}
    </>
  );
};

export default CommentLoader;
