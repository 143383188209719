import axios from "axios";
import { asyncActions } from "src/utils/AsyncUtils";
import { discoverURL } from "src/constants";
import { toast } from "react-toastify";
import {
  GET_ALL_NEWS_COMMENTS,
  GET_ALL_NEWS,
  GET_NEWS,
  CREATE_NEWS_COMMENT,
} from "src/actions";

export const getAllComments = (dispatch, payload, pageNumber) => {
  dispatch(asyncActions(GET_ALL_NEWS_COMMENTS).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ALL_NEWS_URL}/${payload}/comments?pageNumber=${pageNumber}`,
  });
};
export const toggleLike = (dispatch, newsId) => {
  dispatch(asyncActions(GET_ALL_NEWS_COMMENTS).loading(true));
  return axios({
    method: "post",
    url: `${discoverURL.GET_ALL_NEWS_URL}/${newsId}/likes/user/toggle`,
  });
};

export const getAllLikeUser = (dispatch, newsId) => {
  dispatch(asyncActions(GET_ALL_NEWS_COMMENTS).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ALL_NEWS_URL}/${newsId}/likes`,
  });
};

export const getTotalComment = (dispatch, newsId) => {
  dispatch(asyncActions(GET_ALL_NEWS_COMMENTS).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ALL_NEWS_URL}/${newsId}/comments/count`,
  });
};
export const getNewsView = (dispatch, newsId) => {
  dispatch(asyncActions(GET_ALL_NEWS_COMMENTS).loading(true));
  return axios({
    method: "post",
    url: `${discoverURL.GET_ALL_NEWS_URL}/${newsId}/views`,
  });
};
export const getAllNews = (dispatch, pageNumber, sortString = "") => {
  dispatch(asyncActions(GET_ALL_NEWS).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ALL_NEWS_URL}?pageNumber=${pageNumber}&SortBy=${sortString}`,
  });
};
export const getNews = (dispatch, id) => {
  dispatch(asyncActions(GET_NEWS).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ALL_NEWS_URL}/${id}`,
  });
};

export const createNewsComment = (dispatch, payload) => {
  dispatch(asyncActions(CREATE_NEWS_COMMENT).loading(true));
  return axios({
    method: "post",
    url: discoverURL.CREATE_ARTICLE_COMMENT,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(CREATE_NEWS_COMMENT).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(CREATE_NEWS_COMMENT).success(response.data));
        dispatch(asyncActions(CREATE_NEWS_COMMENT).loading(false));
        toast("Commented Successfully ✅");
      }
      return response;
    })
    .catch((error) => {
      console.log(error);
      dispatch(asyncActions(CREATE_NEWS_COMMENT).loading(false));
      dispatch(asyncActions(CREATE_NEWS_COMMENT).failure(true, error.response));
      toast("An error occurred, Please try again ");
      return error.response;
    });
};
