import { asyncActionName } from "src/utils/AsyncUtils";
import {
  GET_ALL_TRAINING_MODULES,
  GET_LESSON_CONTENT,
  GET_MENTEE_TRAINING_SCORE,
  USER_STARTS_LESSON,
  GET_USER_TRAINING_MODULES,
  GET_TRAINING_QUESTION_AND_ANSWER,
  USER_STARTS_MODULE,
  USER_COMPLETES_MODULE,
  USER_COMPLETES_COURSE,
  GET_STUDY_GROUP_COURSE_MATES,
  GET_STUDY_GROUP_INVITATION_SENT,
  GET_STUDY_GROUP_INVITATION_RECEIVED,
  GET_TRAINING_SURVEY_QUESTIONS,
  GET_TRAINING_COURSE_ASSESSMENT,
} from "src/actions";

import { initialState } from "src/store";

const qANDaState = (previousState, payload) => {
  if (!previousState) return { data: payload.data, page: payload.page };
  const data = payload.clearInitialState ? [] : previousState.data;
  const page = payload.page;
  const newData = payload.data;
  const updatedData = [...data, ...newData];
  return { data: updatedData, page };
};

const TrainingReducer = (state = initialState.training, { type, payload }) => {
  switch (type) {
    case asyncActionName(GET_MENTEE_TRAINING_SCORE).loading:
      return { ...state, loadingTrainingScore: payload };
    case asyncActionName(GET_MENTEE_TRAINING_SCORE).success:
      return {
        ...state,
        menteeTrainingScore: payload,
        success: true,
      };
    case asyncActionName(GET_MENTEE_TRAINING_SCORE).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_ALL_TRAINING_MODULES).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_ALL_TRAINING_MODULES).success:
      return {
        ...state,
        courseModule: payload,
        success: true,
      };
    case asyncActionName(GET_ALL_TRAINING_MODULES).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_LESSON_CONTENT).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_LESSON_CONTENT).success:
      return {
        ...state,
        lessonContent: payload,
        success: true,
      };
    case asyncActionName(GET_LESSON_CONTENT).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(USER_STARTS_LESSON).loading:
      return { ...state, loading: payload };
    case asyncActionName(USER_STARTS_LESSON).success:
      return {
        ...state,
        startLesson: payload,
        success: true,
      };
    case asyncActionName(USER_STARTS_LESSON).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };

    case asyncActionName(GET_USER_TRAINING_MODULES).loading:
      return { ...state, loadingGetUserTrainingModules: payload };
    case asyncActionName(GET_USER_TRAINING_MODULES).success:
      return {
        ...state,
        userCourseModules: payload,
        success: true,
      };
    case asyncActionName(GET_USER_TRAINING_MODULES).failure:
      return {
        ...state,
        errorLoadingUserTrainingModules: payload.status,
        success: false,
      };

    case asyncActionName(GET_TRAINING_QUESTION_AND_ANSWER).loading:
      return { ...state, loadingGetCourseQA: payload };
    case asyncActionName(GET_TRAINING_QUESTION_AND_ANSWER).success:
      return {
        ...state,
        coursesQAndA: qANDaState(state.coursesQAndA, payload),
        success: true,
      };
    // case asyncActionName(CLEAR_QANDA_POST_STATE).success:
    //   return {
    //     ...state,
    //     coursesQAndA: { data: [] },
    //     success: true,
    //   };
    case asyncActionName(GET_TRAINING_QUESTION_AND_ANSWER).failure:
      return {
        ...state,
        errorLoadingCourseQA: payload.status,
        success: false,
      };
    case asyncActionName(USER_STARTS_MODULE).loading:
      return { ...state, loading: payload };
    case asyncActionName(USER_STARTS_MODULE).success:
      return {
        ...state,
        startModule: payload,
        success: true,
      };
    case asyncActionName(USER_STARTS_MODULE).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(USER_COMPLETES_MODULE).loading:
      return { ...state, loading: payload };
    case asyncActionName(USER_COMPLETES_MODULE).success:
      return {
        ...state,
        completeModule: payload,
        success: true,
      };
    case asyncActionName(USER_COMPLETES_MODULE).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(USER_COMPLETES_COURSE).loading:
      return { ...state, loading: payload };
    case asyncActionName(USER_COMPLETES_COURSE).success:
      return {
        ...state,
        completeCourse: payload,
        success: true,
      };
    case asyncActionName(USER_COMPLETES_COURSE).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };

    case asyncActionName(GET_STUDY_GROUP_COURSE_MATES).loading:
      return { ...state, loadingGettingCoursemate: payload };
    case asyncActionName(GET_STUDY_GROUP_COURSE_MATES).success:
      return {
        ...state,
        coursemates: payload,
        success: true,
      };
    case asyncActionName(GET_STUDY_GROUP_COURSE_MATES).failure:
      return {
        ...state,
        errorGettingCoursemates: payload.status,
        success: false,
      };

    case asyncActionName(GET_STUDY_GROUP_INVITATION_RECEIVED).loading:
      return { ...state, loadingGettingReceivedReques: payload };
    case asyncActionName(GET_STUDY_GROUP_INVITATION_RECEIVED).success:
      return {
        ...state,
        receivedRequest: payload,
        success: true,
      };
    case asyncActionName(GET_STUDY_GROUP_INVITATION_RECEIVED).failure:
      return {
        ...state,
        errorGettingReceivedRequests: payload.status,
        success: false,
      };

    case asyncActionName(GET_STUDY_GROUP_INVITATION_SENT).loading:
      return { ...state, loadingGettingSentRequest: payload };
    case asyncActionName(GET_STUDY_GROUP_INVITATION_SENT).success:
      return {
        ...state,
        sentRequest: payload,
        success: true,
      };
    case asyncActionName(GET_STUDY_GROUP_INVITATION_SENT).failure:
      return {
        ...state,
        errorGettingSentRequests: payload.status,
        success: false,
      };

    case asyncActionName(GET_TRAINING_SURVEY_QUESTIONS).loading:
      return { ...state, loadingGettingTrainingSurveyQuestions: payload };
    case asyncActionName(GET_TRAINING_SURVEY_QUESTIONS).success:
      return {
        ...state,
        surveyQuestions: payload,
        success: true,
      };
    case asyncActionName(GET_TRAINING_SURVEY_QUESTIONS).failure:
      return {
        ...state,
        errorGettingTrainingSurveyQuestions: payload.status,
        success: false,
      };
    case asyncActionName(GET_TRAINING_COURSE_ASSESSMENT).success:
      return {
        ...state,
        trainingCourseAssessment: payload,
      };

    default:
      return state;
  }
};

export default TrainingReducer;
