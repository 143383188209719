import "../common.scss";
import React, { useState, useContext, useEffect } from "react";

import { Btn, filterSkill } from "src/modules/profile/ProfileEdit/common";
import {
  addMentorshipArea,
  deleteMentorshipArea,
} from "src/requests/ProfileRequest";
import { Context } from "src/store";
import { FormField } from "src/modules/onboarding/components/formField";
import { GoTrashcan } from "react-icons/go";
import Loader from "react-loader-spinner";
import { getMentorshipAreas } from "src/requests/onboarding";
import ReactSelect from "react-select";
import { useCurrentUser } from "src/utils/Services/currentUser";

const ExistingMentorItem = ({ id, name, loading, onDeleteClick }) => {
  return (
    <div className="edit-list-item">
      <span>{name}</span>
      <div style={{ display: "flex" }}>
        <span style={{ cursor: "pointer" }}>
          <GoTrashcan onClick={() => onDeleteClick(id)} />
        </span>
        {loading && (
          <span>
            <Loader color="red" type="Oval" height={14} width={14} />
          </span>
        )}
      </div>
    </div>
  );
};

export const MentorshipEdit = (props) => {
  const { cancel, callbacks } = props;
  const [loading, setLoading] = useState(false);
  const [mentorShipAreas, setMentorshipArea] = useState([]);
  const [existingMentorshipArea, setExistingMentorshipAreas] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [areasToSearchFrom, setAreasToSearchFrom] = useState([]);
  const [userMentorshipArea, setUserMentorshipArea] = useState([]);
  const currentUser = useCurrentUser();
  const [_getDecoupledProfileParts] = callbacks;

  useEffect(() => {
    setExistingMentorshipAreas(props.mentorshipArea);
  }, [props.mentorshipArea]);

  useEffect(() => {
    if (existingMentorshipArea) {
      const existingAreaIds = existingMentorshipArea.map((area) => {
        return area.mentorshipAreaId;
      });

      let filtered = filterSkill(mentorShipAreas, existingAreaIds);
      filtered = filtered.map((item) => {
        return { label: item.area, value: item.id };
      });

      setAreasToSearchFrom(filtered);
    }
  }, [existingMentorshipArea, mentorShipAreas]);

  useEffect(() => {
    getMentorshipAreas(dispatch).then((res) => {
      const { status, data } = res;
      if (status === 200) {
        setMentorshipArea(data);
      } else {
        setMentorshipArea([]);
      }
    });
  }, []);

  const { dispatch } = useContext(Context);

  // const onChange = (target, value) => {
  //   setUserSkills(value);
  // };

  const _handleSubmit = () => {
    setLoading(true);

    const uMentorshipArea = userMentorshipArea.map((area) => {
      return { mentorshipAreaId: area.value };
    });

    addMentorshipArea(dispatch, uMentorshipArea).then(() => {
      _getDecoupledProfileParts(currentUser.userId);
      onCancel();
    });
  };

  const _handleChange = (value) => {
    setUserMentorshipArea(value || []);
  };

  const onCancel = () => {
    cancel();
  };

  const _onDeleteClick = (id) => {
    setDeleteId(id);
    deleteMentorshipArea(dispatch, [
      {
        userMentorshipAreaId: id,
      },
    ]).then(() => {
      _getDecoupledProfileParts(currentUser.userId);
    });
  };

  return (
    <div className="">
      <div className="edit-profile-header">
        <h1>Edit Mentorship Area</h1>
      </div>
      <hr />

      {existingMentorshipArea && existingMentorshipArea.length > 0 && (
        <h1 className="heading-h1">Your Mentorship Areas</h1>
      )}

      {existingMentorshipArea && existingMentorshipArea.length > 0 && (
        <div className="edit-list-container">
          {existingMentorshipArea.map((mentorship) => {
            return (
              <ExistingMentorItem
                name={mentorship.area}
                id={mentorship.id}
                key={mentorship.id}
                onDeleteClick={_onDeleteClick}
                loading={deleteId === mentorship.id}
              />
            );
          })}
        </div>
      )}
      <br />
      <h1 className="heading-h1">Add New Area</h1>
      <div className="search-item-c">
        <FormField
          label="Select Area"
          labelInfo="Optional"
          // onChange={handleSearch}
          name="area"
        >
          <ReactSelect
            isMulti
            options={areasToSearchFrom}
            onChange={_handleChange}
          />
        </FormField>
      </div>
      <hr />
      <div className="btn-box">
        <Btn className="btn-cancel" value="Cancel" onClick={onCancel} />
        <div style={{ flex: 1 }}></div>

        {userMentorshipArea.length > 0 && (
          <Btn
            className="btn-update"
            value="Save"
            loading={loading}
            onClick={() => _handleSubmit()}
          />
        )}

        {/* </div> */}
      </div>
    </div>
  );
};
