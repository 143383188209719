import React, { useContext, useEffect, useState } from "react";
import ScreenOne from "src/modules/Application/Flagship/onboarding/screenOne/screenOne";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import CLoader from "src/modules/Application/components/CLoader";
import classes from "./ApplicationUpdate.module.scss";
import { BsInfoCircle } from "react-icons/bs";
import { updateApplication } from "src/requests/ApplicationRequest";
import { updateUserProfile } from "src/requests/ProfileRequest";
import { Context } from "src/store";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useChangeLanguage } from "src/utils/translation";

const ApplicantUpdateLanguageSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({ languageId: "" });
  const { dispatch } = useContext(Context);
  const [message, setMessage] = useState("");

  const { t } = useTranslation();

  const [selectedLang, setSetSelctedLang] = useState(null);

  const history = useHistory();
  const { applicationId, lang } = queryString.parse(history.location.search);

  const { changeLanguage } = useChangeLanguage();

  const _onLanguageSelect = (value) => {
    changeLanguage(value);
    setInfo({ ...info, languageId: value, applicationId: applicationId });
  };

  useEffect(() => {
    if (lang) {
      setSetSelctedLang(lang);
    }
  }, [lang]);

  useEffect(() => {
    if (selectedLang) {
      setInfo({
        ...info,
        languageId: lang,
        applicationId: applicationId,
      });
    }
  }, [selectedLang]);
  const redirectToApp = () => {
    history.replace(
      `/applicant/preview-application?applicationId=${applicationId}&lang=${lang}&applicants=2020`,
      {}
    );
    history.go(1);
  };

  // history.push(
  //   `/applicant/preview-application?applicationId=${applicationId}&lang=${lang}`
  // );
  const _onNextClick = () => {
    setLoading(true);
    setMessage("Updating your application...");
    updateApplication(
      dispatch,
      { languageId: selectedLang },
      applicationId,
      false
    ).then((res) => {
      const { status } = res;
      if (status === 200 || status === 201) {
        setMessage("Updating your profile...");
        return updateUserProfile(dispatch, { langauge: selectedLang }).then(
          () => {
            setMessage("");
            redirectToApp();
          }
        );
      }
      setLoading(false);
      setMessage("");
      toast("There was an issue updating your application");
    });
  };

  return (
    <div className="application-onboarding-c notranslate">
      <div className={classes.langSelectPrompt}>
        <BsInfoCircle color="var(--tef-blue)" />{" "}
        {t("Please update your language")}
      </div>
      <div
        className={classes.langSelectPrompt}
        onClick={() => history.replace("/home", {})}
      >
        {t("Back to Home")}
      </div>
      <ScreenOne
        info={info}
        onLanguageSelect={_onLanguageSelect}
        onNextClick={_onNextClick}
      />
      ;
      <CLoader loading={loading} additonalInfo={message} />
    </div>
  );
};

export default ApplicantUpdateLanguageSelect;
