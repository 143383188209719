import React from "react";
import Layout from "src/components/Layout/Layout";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import ProgramDashboard from "./ProgramDashboard/ProgramDashboard";

const Dashboard = (props) => {
  return (
    <Layout SideContent={<SidebarRight />} heading="Applications">
      <ProgramDashboard />
    </Layout>
  );
};

export default ifOnboardedThenShow(Dashboard);
