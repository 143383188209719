import axios from "src/utils/axios";
import {
  GET_USER_PROFILE,
  UPDATE_MENTOR_PROFILE,
  UPDATE_USER_PROFILE,
  USER_PHOTO_ADD,
  USER_PHOTO_DELETE,
  USER_PHOTO_LIKE,
  GET_USER_LIGHT_PROFILE,
} from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import { ProfileUrl } from "src/constants";
import { toast } from "react-toastify";
import { setCurrentUser } from "src/utils/Services/currentUser";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
// import { get_user_info_from_token } from "src/utils/someTokenMenthods";

export const getUserProfile = (dispatch, id) => {
  dispatch(asyncActions(GET_USER_PROFILE).loading(true));
  return axios({
    method: "get",
    url: `${ProfileUrl.GET_USER_PROFILE_URL}/${id}/full`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_USER_PROFILE).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_USER_PROFILE).success(data));
        dispatch(asyncActions(GET_USER_PROFILE).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_USER_PROFILE).loading(false));
      dispatch(asyncActions(GET_USER_PROFILE).failure(true, error.response));

      return error.response;
    });
};

export const getUserPhotos = (id) => {
  // dispatch(asyncActions(GET_USER_PROFILE).loading(true));
  return axios({
    method: "get",
    url: `${ProfileUrl.GET_USER_PROFILE_URL}/${id}/gallery`,
  })
    .then((response) => {
      // dispatch(asyncActions(GET_USER_PROFILE).loading(false));
      // if (response.status === 200) {
      //   // const { data } = response.data;
      //   // dispatch(asyncActions(GET_USER_PROFILE).success(data));
      //   // dispatch(asyncActions(GET_USER_PROFILE).loading(false));
      // }
      return response;
    })
    .catch((error) => {
      // dispatch(asyncActions(GET_USER_PROFILE).loading(false));
      // dispatch(asyncActions(GET_USER_PROFILE).failure(true, error.response));

      return error.response;
    });
};

export const getUserProfileFull = (dispatch, id) => {
  dispatch(asyncActions(GET_USER_PROFILE).loading(true));
  return axios({
    method: "get",
    url: `${ProfileUrl.GET_USER_PROFILE_URL}/${id}/full`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_USER_PROFILE).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_USER_PROFILE).success(data));
        dispatch(asyncActions(GET_USER_PROFILE).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_USER_PROFILE).loading(false));
      dispatch(asyncActions(GET_USER_PROFILE).failure(true, error.response));

      return error.response;
    });
};

export const getUserUpdatedProfile = (dispatch, id) => {
  dispatch(asyncActions(GET_USER_PROFILE).loading(true));
  return axios({
    method: "get",
    url: `${ProfileUrl.GET_USER_PROFILE_URL}/${id}/full`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_USER_PROFILE).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        setCurrentUser(data);
        dispatch(asyncActions(GET_USER_PROFILE).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_USER_PROFILE).loading(false));
      dispatch(asyncActions(GET_USER_PROFILE).failure(true, error.response));

      return error.response;
    });
};

export const updateUserProfile = (dispatch, payload, cb) => {
  dispatch(asyncActions(UPDATE_USER_PROFILE).loading(true));
  return axios({
    method: "patch",
    url: `${ProfileUrl.UPDATE_USER_PROFILE}`,
    data: payload,
  })
    .then((response) => {
      if (response.status === 200) {
        getUserUpdatedProfile(
          dispatch,
          get_user_info_from_token().user.id
        ).then(() => {
          toast("Your profile has been updated");
          dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
        });
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
      dispatch(asyncActions(UPDATE_USER_PROFILE).failure(true, error.response));

      return error.response;
    });
};

export const addAchievement = (dispatch, payload) => {
  dispatch(asyncActions(UPDATE_USER_PROFILE).loading(true));
  return axios({
    method: "post",
    url: `${ProfileUrl.USER_ACHIEVEMENT}`,
    data: payload,
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        // getUserUpdatedProfile(
        //   dispatch,
        //   JSON.parse(localStorage.getItem("data")).user.id
        // ).then(() => {
        toast("Achievement Added");
        //   dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
        // });
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
      dispatch(asyncActions(UPDATE_USER_PROFILE).failure(true, error.response));

      return error.response;
    });
};

export const deleteAchievement = (dispatch, payload) => {
  dispatch(asyncActions(UPDATE_USER_PROFILE).loading(true));
  return axios({
    method: "delete",
    url: `${ProfileUrl.USER_ACHIEVEMENT}`,
    data: payload,
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        // getUserUpdatedProfile(
        //   dispatch,
        //   JSON.parse(localStorage.getItem("data")).user.id
        // ).then(() => {
        dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
        // });
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
      dispatch(asyncActions(UPDATE_USER_PROFILE).failure(true, error.response));

      return error.response;
    });
};

export const addEducation = (dispatch, payload) => {
  dispatch(asyncActions(UPDATE_USER_PROFILE).loading(true));
  return axios({
    method: "post",
    url: `${ProfileUrl.USER_EDUCATION}`,
    data: payload,
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        getUserUpdatedProfile(
          dispatch,
          JSON.parse(localStorage.getItem("data")).user.id
        ).then(() => {
          toast("New education has been added");
          dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
        });
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
      dispatch(asyncActions(UPDATE_USER_PROFILE).failure(true, error.response));

      return error.response;
    });
};

export const deleteEducation = (dispatch, payload) => {
  dispatch(asyncActions(UPDATE_USER_PROFILE).loading(true));
  return axios({
    method: "delete",
    url: `${ProfileUrl.USER_EDUCATION}`,
    data: payload,
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        getUserUpdatedProfile(
          dispatch,
          JSON.parse(localStorage.getItem("data")).user.id
        ).then(() => {
          dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
        });
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
      dispatch(asyncActions(UPDATE_USER_PROFILE).failure(true, error.response));

      return error.response;
    });
};

export const addSkills = (dispatch, payload) => {
  dispatch(asyncActions(UPDATE_USER_PROFILE).loading(true));
  return axios({
    method: "post",
    url: `${ProfileUrl.USER_SKILL}`,
    data: payload,
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        // getUserUpdatedProfile(
        //   dispatch,
        //   JSON.parse(localStorage.getItem("data")).user.id
        // ).then(() => {
        toast("Your skills have been updated");
        //   dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
        // });
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
      dispatch(asyncActions(UPDATE_USER_PROFILE).failure(true, error.response));

      return error.response;
    });
};

export const deleteSkill = (dispatch, payload) => {
  dispatch(asyncActions(UPDATE_USER_PROFILE).loading(true));
  return axios({
    method: "delete",
    url: `${ProfileUrl.USER_SKILL}`,
    data: payload,
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        // getUserUpdatedProfile(
        //   dispatch,
        //   JSON.parse(localStorage.getItem("data")).user.id
        // ).then(() => {
        toast("Successful");
        //   dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
        // });
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
      dispatch(asyncActions(UPDATE_USER_PROFILE).failure(true, error.response));

      return error.response;
    });
};

export const addInterest = (dispatch, payload) => {
  dispatch(asyncActions(UPDATE_USER_PROFILE).loading(true));
  return axios({
    method: "post",
    url: `${ProfileUrl.USER_INTEREST}`,
    data: payload,
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        getUserUpdatedProfile(
          dispatch,
          JSON.parse(localStorage.getItem("data")).user.id
        ).then(() => {
          toast("Successful");
          dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
        });
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
      dispatch(asyncActions(UPDATE_USER_PROFILE).failure(true, error.response));

      return error.response;
    });
};

export const deleteUserInterest = (dispatch, payload) => {
  dispatch(asyncActions(UPDATE_USER_PROFILE).loading(true));
  return axios({
    method: "delete",
    url: `${ProfileUrl.USER_INTEREST}`,
    data: payload,
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        getUserUpdatedProfile(
          dispatch,
          JSON.parse(localStorage.getItem("data")).user.id
        ).then(() => {
          toast("Interest Removed");
          dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
        });
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
      dispatch(asyncActions(UPDATE_USER_PROFILE).failure(true, error.response));

      return error.response;
    });
};

export const addMentorshipArea = (dispatch, payload, currentUser) => {
  dispatch(asyncActions(UPDATE_USER_PROFILE).loading(true));
  return axios({
    method: "post",
    url: `${ProfileUrl.USER_MENTORSHIPAREA}`,
    data: payload,
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        // getUserMentorshipArea(get_user_info_from_token().user.id).then(
        //   (res) => {
        //     if (res.data && res.data.data) {
        //       setCurrentUser({ ...currentUser, ...res.data.data });
        //     }
        toast("Your mentorship areas have been updated");
        dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
        // }
        // );
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
      dispatch(asyncActions(UPDATE_USER_PROFILE).failure(true, error.response));

      return error.response;
    });
};

export const deleteMentorshipArea = (dispatch, payload) => {
  dispatch(asyncActions(UPDATE_USER_PROFILE).loading(true));
  return axios({
    method: "delete",
    url: `${ProfileUrl.USER_MENTORSHIPAREA}`,
    data: payload,
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        // getUserUpdatedProfile(
        //   dispatch,
        //   get_user_info_from_token().user.id
        // ).then(() => {
        //   dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
        // });
        toast("successfully deleted mentorship area");
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(UPDATE_USER_PROFILE).loading(false));
      dispatch(asyncActions(UPDATE_USER_PROFILE).failure(true, error.response));

      return error.response;
    });
};

export const updateMentorProfile = (dispatch, payload, cb) => {
  dispatch(asyncActions(UPDATE_MENTOR_PROFILE).loading(true));
  return axios({
    method: "patch",
    url: `${ProfileUrl.UPDATE_MENTOR_PROFILE}`,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(UPDATE_MENTOR_PROFILE).loading(false));
      if (response.status === 200) {
        toast("Your availabilty has been updated");
        dispatch(asyncActions(UPDATE_MENTOR_PROFILE).loading(false));
        if (cb) {
          cb();
        }
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(UPDATE_MENTOR_PROFILE).loading(false));
      dispatch(
        asyncActions(UPDATE_MENTOR_PROFILE).failure(true, error.response)
      );

      return error.response;
    });
};

export const getMentorProfile = (userId) => {
  return axios({
    method: "get",
    url: `${ProfileUrl.GET_USER_PROFILE_URL}/${userId}/tef-mentor`,
  });
};

export const addPhoto = (dispatch, payload, cb = null) => {
  dispatch(asyncActions(USER_PHOTO_ADD).loading(true));
  return axios({
    method: "post",
    url: `${ProfileUrl.USER_GALLERY}`,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(USER_PHOTO_ADD).loading(false));
      if (response.status === 200 || response.status === 201) {
        const { data } = response.data;
        dispatch(asyncActions(USER_PHOTO_ADD).success(data));
        getUserUpdatedProfile(
          dispatch,
          JSON.parse(localStorage.getItem("data")).user.id
        );
        cb && cb(data);
        toast("Photo added successfully");
        dispatch(asyncActions(USER_PHOTO_ADD).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(USER_PHOTO_ADD).loading(false));
      dispatch(asyncActions(USER_PHOTO_ADD).failure(true, error.response));

      return error.response;
    });
};

export const deletePhoto = (dispatch, id, cb) => {
  dispatch(asyncActions(USER_PHOTO_DELETE).loading(true));
  return axios({
    method: "delete",
    url: `${ProfileUrl.USER_GALLERY}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(USER_PHOTO_DELETE).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(USER_PHOTO_DELETE).success(data));
        getUserUpdatedProfile(
          dispatch,
          JSON.parse(localStorage.getItem("data")).user.id
        );
        cb && cb(id);
        toast("Photo deleted successfully");
        dispatch(asyncActions(USER_PHOTO_DELETE).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(USER_PHOTO_DELETE).loading(false));
      dispatch(asyncActions(USER_PHOTO_DELETE).failure(true, error.response));

      return error.response;
    });
};

export const likeOrUnlikeUserPhoto = (dispatch, id, isUser, cb = null) => {
  dispatch(asyncActions(USER_PHOTO_LIKE).loading(true));
  return axios({
    method: "patch",
    url: `${ProfileUrl.USER_GALLERY}/${id}/like`,
  })
    .then((response) => {
      dispatch(asyncActions(USER_PHOTO_LIKE).loading(false));
      if (response.status === 200) {
        // if (isUser) {
        //   getUserUpdatedProfile(
        //     dispatch,
        //     JSON.parse(localStorage.getItem("data")).user.id
        //   );
        // }
        cb && cb(id);
        // toast("Photo deleted successfully");
        dispatch(asyncActions(USER_PHOTO_LIKE).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(USER_PHOTO_LIKE).loading(false));
      dispatch(asyncActions(USER_PHOTO_LIKE).failure(true, error.response));

      return error.response;
    });
};

export const hideProfileFields = (dispatch, payload, cb = null) => {
  return axios({
    method: "patch",
    url: `${ProfileUrl.HIDE_PROFLE_FIELDS}`,
    data: payload,
  })
    .then((response) => {
      if (response.status === 200) {
        cb && cb();
      }
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const getUserLightProfile = (dispatch, viewersIds) => {
  dispatch(asyncActions(GET_USER_LIGHT_PROFILE).loading(true));
  return axios({
    method: "post",
    data: viewersIds,
    url: `${ProfileUrl.GET_USER_LIGHT_PROFILE_URL}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_USER_LIGHT_PROFILE).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_USER_LIGHT_PROFILE).success(data));
        dispatch(asyncActions(GET_USER_LIGHT_PROFILE).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_USER_LIGHT_PROFILE).loading(false));
      dispatch(
        asyncActions(GET_USER_LIGHT_PROFILE).failure(true, error.response)
      );

      return error.response;
    });
};

export const getUserAchievemets = (userId) => {
  return axios({
    method: "get",
    url: `${ProfileUrl.GET_USER_PROFILE_URL}/${userId}/achievements`,
  });
};

export const getUserMentorshipArea = (userId) => {
  return axios({
    method: "get",
    url: `${ProfileUrl.GET_USER_PROFILE_URL}/${userId}/mentorship-areas`,
  });
};

export const getUserSkills = (userId) => {
  return axios({
    method: "get",
    url: `${ProfileUrl.GET_USER_PROFILE_URL}/${userId}/skills`,
  });
};

export const getManyUserLightProfile = (ids) => {
  return axios({
    method: "post",
    url: ProfileUrl.GET_USER_LIGHT_PROFILE_URL,
    data: ids,
  });
};
