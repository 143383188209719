import React, { useState } from "react";
import Loader from "react-loader-spinner";
import ReactAvatar from "react-avatar";
import Avatar from "src/components/Avatar/Avatar";

import { useCurrentUser } from "src/utils/Services/currentUser";
import { Col, Row } from "reactstrap";
// import { BiSearchAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

export const loader = (height) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: height || "300px",
      }}
      // className="training-card__overview"
    >
      <Loader
        type="ThreeDots"
        height={50}
        width={50}
        // color="white"
        color="var(--tef-red)"
      />
    </div>
  );
};

export const PlaceholderField = ({
  onClick,
  // onSearchClose,
  onSearchChange,
}) => {
  const { imageUrl, lastName, firstName } = useCurrentUser();

  const [openSearch, SearchOpenText] = useState(false);
  const _toggleSearch = () => {
    SearchOpenText(!openSearch);
  };

  const _closeSearch = () => {
    onSearchChange("");
    _toggleSearch();
  };

  return (
    <Row className="placeholderField_c">
      <Col md={2}>
        <div className="user">
          {!imageUrl ? (
            <ReactAvatar name={`${firstName} ${lastName}`} round size={40} />
          ) : (
            <Avatar dimension="40px" src={imageUrl} />
          )}
        </div>
      </Col>
      {!openSearch && (
        <Col md={6}>
          <div onClick={onClick} className="placeholderField_c__f">
            What is your question?
          </div>
        </Col>
      )}

      {!openSearch && (
        <Col md={4} className="">
          <button onClick={onClick} className="bzz">
            Ask a question
          </button>{" "}
          {!openSearch && <FaSearch onClick={_toggleSearch} />}
        </Col>
      )}
      {openSearch && (
        <Col md={10} className="d-flex align-items-center">
          <input
            type="text"
            className="placeholderField_c__f"
            placeholder="Search Questions"
            onChange={(e) => onSearchChange(e.target.value)}
            autoFocus
          />
          <MdCancel onClick={_closeSearch} style={{ cursor: "pointer" }} />
        </Col>
      )}

      {/* <Col md={1}>
        <FaSearch />
      </Col> */}
    </Row>
    // <div className=">

    // </div>
  );
};
