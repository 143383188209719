import React from "react";

import Certificate from "src/modules/Tef-Dms/components/Certificates/Certificate";

import { Row, Col } from "reactstrap";

const Certificates = () => {
  return (
    <Row>
      <Col md={12} className="pt-5">
        <Certificate />
      </Col>
    </Row>
  );
};

export default Certificates;
