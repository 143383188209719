import React from "react";
import ApplicationOnboardingSteps from "src/modules/Application/Flagship/onboarding";

const OnboardingIndex = (props) => {
  return (
    <>
      <ApplicationOnboardingSteps props={props} />
    </>
  );
};

export default OnboardingIndex;
