import React, { Fragment } from "react";
import classes from "./ViewComment.module.css";
import Avatar from "src/components/Avatar/Avatar";
import { EditorContentDisplay } from "src/components/Editor";
import { FaFlag } from "react-icons/fa";
import { deleteComment, flagComment } from "src/requests/DiscoverRequest";
import Swal from "sweetalert2";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { RiDeleteBin5Line } from "react-icons/ri";

const ViewComment = ({ postId, commentDa, refresh, postType = "comment" }) => {
  const current_user = get_user_info_from_token()?.user?.id ?? "";
  const handleDeleteComment = (id) => {
    Swal.fire({
      title: "Delete?",
      text: "Are you sure you want to delete this comment?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#da291c",
      cancelButtonColor: "#63666a",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteComment(id)
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                text: "Comment Deleted!",
                confirmButtonColor: "#da291c",
              }).then(() => refresh());
            }
          })
          .then(() => refresh());
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Unable to delete the comment at this time, try again!",
          confirmButtonColor: "#da291c",
        }).then(() => refresh());
      }
    });
  };

  const handleFlagComment = async (contentId) => {
    const payload = {
      reportedByUserId: current_user,
      contentType: postType,
      contentId,
      archiveId: "",
    };
    Swal.fire({
      title: "Why do you want to flag this comment?",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      confirmButtonColor: "#da291c",
      cancelButtonColor: "#63666a",
      preConfirm: (comm) => {
        const data = {
          ...payload,
          description: comm,
        };
        flagComment(data)
          .then((response) => {
            if (response.status !== 201 || response.status !== 200) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .catch((error) => {
            // eslint-disable-next-line no-unused-expressions
            error.message === "OK" || error.message === "Created"
              ? ""
              : Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Flagged!",
          text: "Comment Flagged!",
          confirmButtonColor: "#da291c",
        });
      }
    });
  };
  return (
    <div className={classes.ViewComment}>
      {commentDa &&
        commentDa.map((comment) => (
          <Fragment key={comment.id}>
            <div className={classes.UserInfo}>
              <div className={classes.UserImage}>
                {comment && comment.profile && (
                  <Avatar
                    src={comment.profile.imageUrl}
                    dimension="30px"
                    name={`${comment.profile.firstName} ${comment.profile.lastName}`}
                  />
                )}
              </div>
              <div className={classes.Comment}>
                <div className={classes.UserDetails}>
                  <div className={classes.UserName}>{`${
                    comment.profile && comment.profile.firstName
                  } ${comment.profile && comment.profile.lastName}`}</div>
                </div>
                <div className={classes.CommentBody}>
                  <EditorContentDisplay content={comment.content} readOnly />
                </div>
                {/* <UserReactions
                  message={comment.content}
                  commentId={comment.id}
                  totalLikes={comment.totalLikes}
                  allLikesIds={comment.allLikesIds}
                  totalReplies={comment.totalReplies}
                  createdBy={comment.createdBy}
                  articleId={articleId}
                /> */}
              </div>
              <div
                className={classes.CommentFlag}
                onClick={() => handleFlagComment(comment.id)}
              >
                <FaFlag />
              </div>
              {current_user === comment.userId && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteComment(comment.id)}
                >
                  <RiDeleteBin5Line />
                </div>
              )}
            </div>
          </Fragment>
        ))}
    </div>
  );
};

export default ViewComment;
