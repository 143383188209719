import React from "react";
import { Card, Col } from "reactstrap";
import HIW1 from "src/assets/img/hiw1.png";
import HIW2 from "src/assets/img/hiw2.png";
import HIW3 from "src/assets/img/hiw3.png";
import "./HowItWorks.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const HowItWorks = () => {
  const { t } = useTranslation();
  const data = get_user_info_from_token().user;
  return (
    <Card className="HowItWorks-card">
      <Col md={12}>
        <h3 className="HowItWorks-heading">HOW IT WORKS</h3>
        <br />
        <img className="hiw-header-img" src={HIW3} />
        <h5 className="HowItWorks-subheading">Update your profile</h5>
        <p className="HowItWorks-text">
          Say a bit more about yourself and add more interests to your{" "}
          <Link to={`/profile/${data.id}`}> profile</Link>
        </p>

        <hr />
        <img className="hiw-header-img" src={HIW2} />
        <h5 className="HowItWorks-subheading">Find a Mentor</h5>
        <p className="HowItWorks-text">
          Browse profiles of potential mentees. Connect with anyone who seems to
          be a good fit.
        </p>

        <hr />

        <img className="hiw-header-img" src={HIW1} />
        <h5 className="HowItWorks-subheading">{t("Get Started")}!</h5>
        <p className="HowItWorks-text">
          Create and discuss mentorship goals and get reminders to make progress
          together, week-by-week.
        </p>
      </Col>
    </Card>
  );
};

HowItWorks.propTypes = {};

export default HowItWorks;
