import React, { Fragment } from "react";
import classes from "./ViewComment.module.css";
import moment from "moment";
// import EventReaction from "./EventReaction";
import StarRatingComponent from "react-star-rating-component";
import Avatar from "src/components/Avatar/Avatar";
import { EditorContentDisplay } from "src/components/Editor";

const ViewCommentWithRate = ({
  comments,
  deleteUserComment,
  isLoadingLike,
  handleLikeLoader,
}) => {
  const HumanDate = (date) => {
    return `${moment(date).format("DD MMMM, YYYY")} at ${moment(date).format(
      "hh:mma"
    )}`;
  };

  return (
    <>
      <div className={classes.ViewComment}>
        {comments &&
          comments.map((comment) => (
            <Fragment key={comment.id}>
              <div className={classes.UserInfo}>
                <div className={classes.UserImage}>
                  {comments && comment.eventsCommentsByUser && (
                    <Avatar
                      src={comment.eventsCommentsByUser.imageUrl}
                      dimension="30px"
                      name={`${comment.eventsCommentsByUser.firstName} ${comment.eventsCommentsByUser.lastName}`}
                    />
                  )}
                </div>
                <div className={classes.Comment}>
                  <div className={classes.CommentStarRate}>
                    <div className={classes.UserNameRate}>{`${
                      comment.eventsCommentsByUser &&
                      comment.eventsCommentsByUser.firstName
                    } ${
                      comment.eventsCommentsByUser &&
                      comment.eventsCommentsByUser.lastName
                    }`}</div>

                    <div className="star__rate ml-5" style={{ height: "28px" }}>
                      <StarRatingComponent
                        name="rate1"
                        starCount={5}
                        marginTop={90}
                        marginLeft={300}
                        padding={0}
                        starColor="#fd9e9e"
                        value={comment.ratings}
                        emptyStarColor="#bac1ca"
                      />
                    </div>
                  </div>

                  <p className="date-commented">
                    {HumanDate(comment.createdOn)}
                  </p>

                  <div
                    className={classes.CommentBody}
                    style={{ color: "#919BA8" }}
                  >
                    <EditorContentDisplay content={comment.comment} readOnly />
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
      </div>
    </>
  );
};

export default ViewCommentWithRate;
