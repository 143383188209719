/* eslint-disable no-unmodified-loop-condition */
import React, { useEffect, useState, Fragment } from "react";
import { Row, Col } from "reactstrap";
// import { RiLayoutGridFill, RiLayoutRowLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import qs from "query-string";
import Layout from "src/components/Layout/Layout";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import InputField from "src/modules/Alumni/Components/InputField/index";
import TopCardList from "./components/TopCardList/index";
import DocumentTab from "./components/Tabs/Table/index";
import VideoTab from "./components/Tabs/Video/index";
import TabHeader from "src/modules/Alumni/Components/TabHeader/index";
import CaseStudy from "./components/Tabs/CaseStudy/index";
import News from "./components/Tabs/News/index";
import WhitePaper from "./components/Tabs/WhitePaper/index";
import CardContentLoader from "src/modules/Alumni/Components/CardContentLoader/index";
import CustomLoader from "src/modules/Alumni/Components/CustomLoader/index";
import serializeUrl from "src/modules/Alumni/helpers/serializeUrl";
import ResourceModel from "src/modules/Alumni/models/ResourceModel";
import {
  getResources,
  toggleResourceLike,
  registerDownload,
  getFeaturedResources,
} from "src/requests/AlumniRequest";
import "./index.scss";

const fetchQueryParams = {
  FilteredBy: "",
  SearchBy: "",
  PageNumber: 1,
  Category: "",
};

const _resources = {
  documents: [],
  videos: [],
  case_studies: [],
  white_papers: [],
  news: {
    main: [],
    ourPicks: [],
  },
};

let currentQueryParamToUpdate = "";

const Resources = () => {
  const history = useHistory();
  const { tab: _tab } = qs.parse(location.search);
  const TAB = _tab || "Documents";
  // const { dispatch } = useContext(Context);
  const [pageUpdated, setPageUpdated] = useState(false);
  const [isFeaturedLoading, setIsFeaturedLoading] = useState(false);
  const [tab, setTab] = useState(TAB);
  const [resources, setResources] = useState({ ..._resources });
  const [isLoading, setIsLoading] = useState(true);
  const [queryParams, setQueryParams] = useState(fetchQueryParams);
  const [featuredResources, setFeaturedResources] = useState([]);

  useEffect(() => {
    if (currentQueryParamToUpdate === "FilteredBy") {
      if (queryParams.FilteredBy) {
        prepareRequest();
      }
    }
  }, [queryParams.FilteredBy]);

  useEffect(() => {
    if (currentQueryParamToUpdate === "SearchBy") {
      if (queryParams.SearchBy && queryParams.FilteredBy) {
        prepareRequest();
      }
    }
  }, [queryParams.SearchBy]);

  useEffect(() => {
    updateQueryParam(
      {
        FilteredBy: tab.replace(" ", "_").toLowerCase(),
        SearchBy: "",
        PageNumber: 1,
        Category: "",
      },
      "FilteredBy"
    );
  }, [tab]);

  useEffect(() => _getFeaturedResources(), []);

  const prepareRequest = () => {
    const QS = serializeUrl(queryParams);
    _getResources(QS);
  };

  const _getFeaturedResources = () => {
    setIsFeaturedLoading(true);
    getFeaturedResources()
      .then((res) => {
        const { data } = res.data.data;
        const __resources = [];
        data.forEach((res) => {
          const resource = new ResourceModel();
          resource.updateProperties(res, false);
          __resources.push(resource);
        });
        setFeaturedResources(__resources);
        setIsFeaturedLoading(false);
      })
      .catch((e) => {
        console.log("EE", e);
        setIsFeaturedLoading(false);
        toast("Something went wrong, try again", { type: toast.TYPE.ERROR });
      });
  };

  const _getResources = (QS) => {
    setIsLoading(true);
    getResources(QS)
      .then((res) => {
        const { data } = res.data.data;
        const __resources = [];

        data.forEach((res) => {
          const resource = new ResourceModel();
          resource.updateProperties(res);
          __resources.push(resource);
        });

        if (queryParams.FilteredBy === "news") {
          setResources({
            ...resources,
            [queryParams.FilteredBy]: {
              ...resources[queryParams.FilteredBy],
              [queryParams.Category || "main"]: __resources,
            },
          });
        } else
          setResources({
            ...resources,
            [queryParams.FilteredBy]: __resources,
          });

        // setPageCount(pager.pageCount);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log("EE", e);
        setIsLoading(false);
        toast("Something went wrong, try again", { type: toast.TYPE.ERROR });
      });
  };

  const onToggleLike = async (data) => {
    // toggle like immediately it gets clicked
    data.setIsLiked(!data.isLiked);

    /* Because we are just updating a model property,
     * we need a way to let React know that content has changed
     */
    setPageUpdated(!pageUpdated);
    try {
      await toggleResourceLike(data.id);
    } catch (e) {
      // reset liked, because this request failed to get completed
      data.setIsLiked(!data.isLiked);
      setPageUpdated(!pageUpdated);
      toast("Something went wrong, try again");
    }
  };

  const initiateDownload = async (id) => {
    try {
      await registerDownload(id);
    } catch (e) {
      // reset liked, because this request failed to get completed
      toast("Something went wrong, try again");
    }
  };

  const formatList = (list) => {
    if (queryParams.SearchBy && list.length > 0) {
      const search = queryParams.SearchBy.toLowerCase();
      return list.filter(
        (data) =>
          data.title.toLowerCase().includes(search) ||
          data.description.toLowerCase().includes(search) ||
          (data.author || "").toLowerCase().includes(search)
      );
    }
    return list;
  };

  const onDownload = (data) => {
    initiateDownload(data.id);
    const a = document.createElement("a");
    a.href = data.fileUrl;
    a.download = true;
    a.click();
  };

  const updateQueryParam = (changedParams, updatedParam = "") => {
    // console.log("changedParams\n", changedParams);
    currentQueryParamToUpdate = updatedParam;
    setQueryParams({ ...queryParams, ...changedParams });
  };

  const openPage = (path, data) =>
    history.push(`/alumni/resources/${path}/${data.id}`);

  return (
    <Layout SideContent={<SidebarRight />} smallHeader="Alumni Resources">
      <div>
        <Row className="tab-heading mainContainer">
          <Col md={8} style={{ paddingLeft: 0 }}>
            <h2 className="mainContainer__title">
              Welcome To Alumni Resources
            </h2>
            <span className="mainContainer__desc">
              Please take advantage of the tools, resources and information that
              we have curated just for you
            </span>
          </Col>
        </Row>

        {isFeaturedLoading ? (
          <Row className="mb-2 mt-0 pt-0 pb-0">
            {[1, 2, 3].map((i) => (
              <Col md={4} lg={4} sm={6} xs={12} key={i}>
                <CardContentLoader />
              </Col>
            ))}
          </Row>
        ) : (
          <TopCardList
            data={featuredResources}
            onDownload={onDownload}
            onOpenLink={openPage}
          />
        )}

        <div className="mt-5">
          <TabHeader
            onChange={setTab}
            currentTab={tab}
            tabSize={7}
            postTabSize={5}
            tabStyle={{ backgroundColor: "transparent" }}
            tabs={[
              "Documents",
              "Videos",
              "Case Studies",
              "White Papers",
              "News",
            ]}
            postTabContent={
              <div className="d-flex">
                <Col md={10} className="pl-0 pr-0">
                  <InputField
                    name="search"
                    id="search"
                    placeholder="Search"
                    inputStyle={{
                      backgroundColor: "white",
                      height: 40,
                      width: "100%",
                    }}
                    type="text"
                    value={
                      queryParams.SearchBy === "Our Picks"
                        ? ""
                        : queryParams.SearchBy
                    }
                    onChange={(e) =>
                      updateQueryParam({ SearchBy: e.target.value })
                    }
                  />
                </Col>
                {/* <Col md={3} style={{ marginTop: 10 }}>
                  <RiLayoutGridFill fontSize={20} />
                  <RiLayoutRowLine fontSize={20} />
                </Col> */}
              </div>
            }
          />
        </div>
        <div className={`content-holder ${tab === "News" ? "" : "bg-white"}`}>
          {isLoading && tab !== "News" && (
            <div className="mb-2 mt-0 pt-0 pb-0">
              <CustomLoader size={20} />
            </div>
          )}
          {tab === "Documents" && (
            <DocumentTab
              resources={formatList(resources.documents)}
              onDownload={onDownload}
              onLike={onToggleLike}
              ErrorEmpPage={
                !resources.white_papers.length && !isLoading
                  ? ErrorEmpPage
                  : Fragment
              }
            />
          )}
          {tab === "Videos" && (
            <VideoTab
              resources={formatList(resources.videos)}
              onOpen={(d) => openPage("video-view", d)}
              onLike={onToggleLike}
              ErrorEmpPage={
                !resources.white_papers.length && !isLoading
                  ? ErrorEmpPage
                  : Fragment
              }
            />
          )}
          {tab === "Case Studies" && (
            <CaseStudy
              resources={formatList(resources.case_studies)}
              onDownload={onDownload}
              onLike={onToggleLike}
              ErrorEmpPage={
                !resources.white_papers.length && !isLoading
                  ? ErrorEmpPage
                  : Fragment
              }
            />
          )}
          {tab === "White Papers" && (
            <WhitePaper
              resources={formatList(resources.white_papers)}
              onDownload={onDownload}
              onLike={onToggleLike}
              ErrorEmpPage={
                !resources.white_papers.length && !isLoading
                  ? ErrorEmpPage
                  : Fragment
              }
            />
          )}
          {tab === "News" && (
            <News
              onOpen={(d) => openPage("news-view", d)}
              resources={resources.news}
              onLoadData={updateQueryParam}
              isLoading={isLoading}
              ErrorEmpPage={
                !resources.news.length && !isLoading ? ErrorEmpPage : Fragment
              }
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

const ErrorEmpPage = () => (
  <div className="text-center" style={{ width: "100%" }}>
    <h5>Welp!! There is no data here.</h5>
  </div>
);

// const monitorStatusChange = (changedStatus, callback) => {
//   // while (!changedStatus) {
//   //   console.log("changedStatus", changedStatus);
//   //   if (changedStatus) callback();
//   // }
// };

export default ifOnboardedThenShow(Resources);
