/* eslint-disable no-multi-str */
import React, { useState, useContext, useEffect } from "react";
import { Row, Col, FormGroup, Button } from "reactstrap";
import Layout from "src/components/Layout/Layout";
import { IoIosArrowRoundForward } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import tefstoryBanner from "src/assets/img/storybanner.png";
import DefaultImage from "src/assets/img/Opportunity.jpeg";
// import { useTranslation } from "react-i18next";
import { Editor } from "@tinymce/tinymce-react";
import "./TefStories.scss";
import CreateTefStoryForm from "./CreateTefStoryForm";
import CreateTefStorySuccess from "./CreateTefStorySuccess";
import { getAllIndustries, postStory } from "src/requests/DiscoverStoryRequest";
import { Context } from "src/store";
import { isAllowedFiles, UploadFile } from "src/requests/fileUploadRequest";
import Loader from "src/components/Loader/Loader";
import _ from "lodash";
import { getAllTags } from "src/requests/DiscoverRequest";

const CreateTefStory = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const { state, dispatch } = useContext(Context);
  const data = {
    title: "",
    shortDescription: "",
    fullDescription: "",
    tefYearSelection: "",
    businessName: "",
    businessIndustry: "",
    businessAge: "",
    uploadType: "",
    youtubeLink: "",
    country: "",
    bannerUrl: DefaultImage,
    tags: [],
  };
  const history = useHistory();
  const [storyData, setStoryData] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [yearError, setYearError] = useState("");
  const [file, setFile] = useState([]);
  useEffect(() => {
    getAllIndustries(dispatch);
    getAllTags(dispatch);
  }, []);
  const { industries } = state.DiscoverStory;
  // console.log("industry", industries);
  const industryObj = industries.map((industry) => {
    return {
      label: industry.name,
      value: industry.id,
    };
  });
  const onDrop = (picture) => {
    const existingFiles = [...file];
    const validateFiles = isAllowedFiles(Array.from([...picture]));
    if (validateFiles.hasError) {
      validateFiles.errors.forEach((file) => {});
    } else {
      existingFiles.push(...picture);
      const uniqueFile = _.uniqBy(existingFiles, "name");
      setFile(uniqueFile);
    }
  };
  const submitStory = (e) => {
    e.preventDefault();
    if (!yearError) {
      setIsLoading(true);
      const selectTagLabel = storyData.tags.map((tag) => tag.value);
      storyData.tags = selectTagLabel;
      if (file.length > 0) {
        UploadFile(dispatch, true, file)
          .then((res) => {
            if (res && res.status === 200) {
              res.files.map((img) => (storyData.bannerUrl = img));
              _submit(storyData);
            }
          })
          .catch(() => {
            _submit(storyData);
          });
      } else {
        _submit(storyData);
      }
    }
  };

  const _submit = (data) => {
    data.tefYearSelection = Math.floor(data.tefYearSelection);
    postStory(dispatch, data).then((res) => {
      setIsLoading(false);
      if (res.status === 201) {
        setStoryData(data);
        setStoryData({
          title: "",
          shortDescription: "",
          fullDescription: "",
          tefYearSelection: "",
          businessName: "",
          businessIndustry: "",
          businessAge: "",
          uploadType: "",
          youtubeLink: "",
          country: "",
          bannerUrl: DefaultImage,
          tags: [],
        });
        toggle();
      }
    });
  };
  const handleChange = (selectedItem) => {
    setStoryData({
      ...storyData,
      tags: selectedItem,
    });
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;
    if (name === "tefYearSelection") {
      const regex = /^\d*[1-9]\d*$/;
      const res = regex.test(value);

      if (res) {
        setYearError("");
        setStoryData({ ...storyData, [name]: value });
      } else {
        setYearError("Invalid year format");
      }
    }
    setStoryData({ ...storyData, [name]: value });
  };

  const selectHandler = (name, value) => {
    setStoryData({ ...storyData, [name]: value });
  };
  const { tags } = state.Discovers;
  const tagsOption = tags.map((category) => {
    return {
      label: category.name,
      value: category.id,
      color: "#666666",
    };
  });
  return (
    <Layout hideLeft hideRight>
      <div className="mb-5">
        <div className="create-tef-story">
          <Link to="/discover" style={{ fontWeight: "bold" }}>
            {"TEF Stories"} <IoIosArrowRoundForward />
          </Link>
          <span>{"Submit your TEF Story"}</span>
        </div>
        <div className="create-tefstory-banner">
          <Row>
            <Col md={6}>
              <div>
                <h1>
                  Publish Your TEF Story{" "}
                  <span role="img" aria-label="hello">
                    👋
                  </span>
                </h1>
                <h6>Focus on features and value proposition.</h6>
              </div>
            </Col>
            <Col md={6}>
              <div className="create-tefstory-banner__logo">
                <img src={tefstoryBanner} alt="logo" />
              </div>
            </Col>
          </Row>
        </div>
        <form onSubmit={submitStory}>
          <CreateTefStoryForm
            industryObj={industryObj}
            toggle={toggle}
            selectHandler={selectHandler}
            inputHandler={inputHandler}
            onDrop={onDrop}
            storyData={storyData}
            tagsOption={tagsOption}
            handleChange={handleChange}
            yearError={yearError}
          />
          <div className="tefstory-editor mt-3">
            <Row form>
              <Col sm={12} md={12}>
                <FormGroup type="textarea">
                  <Editor
                    apiKey={process.env.REACT_APP_TINY_CLOUD_KEY}
                    init={{
                      height: 400,
                      branding: false,
                      statusbar: false,
                      placeholder: "Type your story here",
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help",
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
                    }}
                    onEditorChange={(content, editor) => {
                      setStoryData({
                        ...storyData,
                        fullDescription: content,
                      });
                    }}
                    value={storyData.fullDescription}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          <Row>
            <Col
              md={6}
              className="preview-btn text-center w-25"
              onClick={() => history.push("/discover")}
            >
              <span className="pr-2">
                <FaEye />
              </span>
              Cancel
            </Col>
            <Col md={6}>
              <Button
                className="submit-tef-story text-center"
                // onClick={submitArticle}
                disabled={isLoading}
                type="submit"
                style={{ width: "100%", display: "block" }}
              >
                {isLoading ? <Loader color="light" /> : "Post Story"}
              </Button>
            </Col>
          </Row>
        </form>
      </div>
      <CreateTefStorySuccess
        modal={modal}
        toggle={toggle}
        title={storyData.title}
      />
    </Layout>
  );
};

export default CreateTefStory;
