/* eslint-disable no-unused-vars */
import "./progressReport.scss";
import React, { useState } from "react";
import CModal from "src/modules/onboarding/components/CModal";
import icon from "src/assets/img/group-6-copy.png";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
const ProgressReport = ({
  showReport,
  onReportClose,
  percentageComplete,
  onSubmitClick,
  quizInfo,
  onQuizClick,
  close,
  onRetakeQuiz,
  getCumulativeAvgForModule,
  numberOfAttempts,
}) => {
  const qa = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    moduleId,
    courseId,
    lang,
    trainingEventId,
    title,
    quizId,
    moduleNo,
  } = queryString.parse(qa.search);

  if (!showReport) {
    return null;
  }
  const handleGoBack = () => {
    onRetakeQuiz(false);
    getCumulativeAvgForModule();
    const link = `/training/lms/course?courseId=${courseId}&moduleId=${moduleId}&quizId=${quizId}&trainingEventId=${trainingEventId}&lang=${lang}&title=${title}&moduleNo=${moduleNo}&lessonsCompleted=true`;
    history.push(link);
  };

  return (
    <CModal isOpen={showReport} closeModal={onReportClose}>
      <div className="report-c">
        <div className="i-div">
          <img src={icon} className="img-fluid" />
        </div>
        <div className="report-body-c">
          {Number(percentageComplete) > 50 ? (
            <h1 className="greeting">{t("Great-work")}!😃</h1>
          ) : null}
          <p>{t(`Your score is ${percentageComplete}%`)}</p>
        </div>
        <div className="report-continue-btn">
          <div className="submit-box-train">
            <div className="submit-btn" onClick={onSubmitClick}>
              {t("Continue")}
            </div>
            {quizInfo.allowedAttempt > numberOfAttempts && (
              <div className="submit-btn-link">
                <p style={{ cursor: "pointer" }} onClick={handleGoBack}>
                  retake quiz
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </CModal>
  );
};
ProgressReport.defaultProps = {
  showReport: false,
  onReportClose: () => {},
  percentageComplete: 40,
};
export default ProgressReport;
