import axios from "axios";
import { env } from "src/constants/env";

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    axios.defaults.headers.common.ClientId = env.REACT_APP_CLIENT_ID;
    axios.defaults.headers.common.ClientSecret = env.REACT_APP_CLIENT_SECRET;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

export default setAuthToken;
