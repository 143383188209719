import React, { Fragment } from "react";
import classes from "./TEFButton.module.css";

const TEFButton = ({ logo, label, bgColor, textColor }) => {
  return (
    <Fragment>
      <div
        className={classes.Button}
        style={{ backgroundColor: `${bgColor}`, color: `${textColor}` }}
      >
        <div className={classes.Logo}>
          <img src={logo} alt={`${label} logo`} width="16px" height="16px" />
        </div>
        <div className={classes.Label}>{label}</div>
      </div>
    </Fragment>
  );
};

export default TEFButton;
