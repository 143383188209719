import React from "react";
import ContentLoader from "react-content-loader";
import { Row } from "reactstrap";

const RenderContentLoader = () => {
  const arr = [1];
  const contentArr = arr.fill(1);
  const contLoadArr = contentArr.map((c, idx) => (
    <div
      key={idx}
      style={{
        backgroundColor: "#f3f3f3",
        height: "500px",
        width: "100%",
        marginTop: "10px",
      }}
    ></div>
  ));
  return <Row>{contLoadArr}</Row>;
};
const ArticleContentLoader = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div
        style={{ backgroundColor: "#f3f3f3", height: "20px", width: "100%" }}
      ></div>
      <div
        style={{
          backgroundColor: "white",
          padding: "10px",
        }}
        // className="loader-item"
      >
        <ContentLoader
          speed={2}
          // width={324}
          // height={150}
          viewBox="0 0 400 160"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="8" rx="3" ry="3" width="300" height="10" />
          <rect x="0" y="8" rx="3" ry="3" width="300" height="500" />

          {/* <circle cx="20" cy="20" r="20" /> */}
        </ContentLoader>
        {RenderContentLoader()}
        <div
          style={{
            backgroundColor: "#f3f3f3",
            height: "100px",
            width: "100%",
            marginTop: "10px",
          }}
        ></div>
      </div>
    </div>
  );
};

export default ArticleContentLoader;
