/* eslint-disable no-irregular-whitespace */
import React from "react";
import styles from "./TCmodal.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OffCycleTC = ({ toggle }) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.TCMain} notranslate`}>
      <h2 className={styles.TCMain__title}>{t("UNDP TC TITLE")}</h2>
      <ol className={styles.TCMain__structuredList}>
        <li>
          <span className={styles.TCMain__boldText}>
            {t("Definitions title")}
          </span>
          <p>{t("Definitions text")}</p>
          <p>
            <span className={styles.TCMain__boldText}>{`"${t(
              "Applicant title"
            )}"`}</span>{" "}
            {t("Applicant text")}
          </p>
          <p>
            <span className={styles.TCMain__boldText}>{`"${t(
              "Application title"
            )}"`}</span>{" "}
            {t("Application text")}
          </p>
          <p>
            <span className={styles.TCMain__boldText}>
              {`"${t("Application Window title")}"`}
            </span>
            {t("Application Window text")}
          </p>
          <p>
            <span className={styles.TCMain__boldText}>{`"${t(
              "Buffer List title"
            )}"`}</span>
            {t("Buffer List text")}
          </p>
          <p>
            <span className={styles.TCMain__boldText}>{`"${t(
              "Business title"
            )}"`}</span>
            {t("Business text")}
          </p>

          <p>
            <span className={styles.TCMain__boldText}>
              {`"${t("Business Bank Account title")}"`}
            </span>{" "}
            {t("Business Bank Account text")}
          </p>
          <p>
            <span className={styles.TCMain__boldText}>
              {`"${t("Business Idea title")}"`}{" "}
            </span>{" "}
            {t("Business Idea text")}
          </p>
          <p>
            <span className={styles.TCMain__boldText}>
              {" "}
              {`"${t("Business Partner title")}"`}{" "}
            </span>{" "}
            {t("Business Partner text")}
          </p>
          <p>
            <span className={styles.TCMain__boldText}>
              {" "}
              {`"${t("Business Plan title")}"`}{" "}
            </span>{" "}
            {t("Business Plan text")}
          </p>
          <p>
            <span className={styles.TCMain__boldText}>
              {`"${t("Immediate Family Members title")}"`}
            </span>{" "}
            {t("Immediate Family Members text")}
          </p>

          <p>
            <span className={styles.TCMain__boldText}>
              {t("Malian title")}{" "}
            </span>{" "}
            {t("Malian text")}
            <ol className={styles.TCMain__structuredList} type="I">
              <li>{t("Malian text-1")}</li>
              <li>{t("Malian text-2")}</li>
              <li>{t("Malian text-3")}</li>
            </ol>
          </p>
          <p>
            <span className={styles.TCMain__boldText}>{t("Mentor title")}</span>
             {t("Mentor text")}
          </p>

          <p>
            <span className={styles.TCMain__boldText}>
              {t("Pitching Competition title")}
            </span>
            {t("Pitching Competition text")}
          </p>
          <p>
            <span className={styles.TCMain__boldText}>
              {t("Programme Participants title")}
            </span>
            {t("Programme Participants text")}
          </p>
          <p>
            <span className={styles.TCMain__boldText}>
              {t("Programme title")}
            </span>
            {t("Programme text")}
          </p>

          <p>
            <span className={styles.TCMain__boldText}>
              {t("Promoter title")}
            </span>{" "}
            {t("Promoter text")}
          </p>
          <p>
            <span className={styles.TCMain__boldText}>
              {t("Seed Capital title")}
            </span>
            {t("Seed Capital text")}
          </p>
          <p>
            <span className={styles.TCMain__boldText}>
              {t("Selected Entrepreneurs title")}
            </span>
            {t("Selected Entrepreneurs text")}
          </p>
          <p>
            <span className={styles.TCMain__boldText}>
              {t("Startup Enterprise Toolkit title")}
            </span>
            {t("Startup Enterprise Toolkit text")}
          </p>
          <p>
            <span className={styles.TCMain__boldText}>
              {t("Successful Applicants title")}
            </span>
            {t("Successful Applicants text")}
          </p>
          <p>
            <span className={styles.TCMain__boldText}>{t("Alumni title")}</span>
            {t("Alumni text")}
          </p>

          <p>
            <span className={styles.TCMain__boldText}>
              {t("Alumni Network title")}
            </span>
            {t("Alumni Network text")}
          </p>
          <p>
            <span className={styles.TCMain__boldText}>
              {t("TEFConnect title")}
            </span>
            {t("TEFConnect text")}
          </p>
          <p>
            <span className={styles.TCMain__boldText}>
              {t("TEF Trainees title")}
            </span>
            {t("TEF Trainees text")}
          </p>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>
            {t("Terms and Conditions")}
          </span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("Terms and Conditions-1")}</li>
            <li>{t("Terms and Conditions-2")}</li>
            <li>{t("Terms and Conditions-3")}</li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>{t("Eligibility")}</span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("Eligibility-1")}</li>
            <li>{t("Eligibility-2")}</li>
            <li>{t("Eligibility-3")}</li>
            <li>
              {t("Eligibility-4")}
              <ol className={styles.TCMain__structuredList}>
                <li>{t("Eligibility-4-a")}</li>
                <li>{t("Eligibility-4-b")}</li>
                <li>{t("Eligibility-4-c")}</li>
                <li>{t("Eligibility-4-d")}</li>
              </ol>
            </li>
            <li>{t("Eligibility-5")}</li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>{t("Application")}</span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("Application-1")}</li>
            <li>{t("Application-2")}</li>
            <li>{t("Application-3")}</li>
            <li>{t("Application-4")}</li>
            <li>{t("Application-5")}</li>
            <li>{t("Application-6")}</li>
            <li>{t("Application-7")}</li>
            <li>{t("Application-8")}</li>
            <li>
              {t("Application-9")}
              <ol className={styles.TCMain__structuredList}>
                <li>{t("Application-9-1")}</li>
                <li>{t("Application-9-2")}</li>
                <li>{t("Application-9-3")}</li>
                <li>{t("Application-9-4")}</li>
                <li>{t("Application-9-5")}</li>
                <li>{t("Application-9-6")}</li>
                <li>{t("Application-9-7")}</li>
                <li>{t("Application-9-8")}</li>
                <li>
                  {t("Application-9-9")}
                  <ol className={styles.TCMain__structuredList}>
                    <li>{t("Application-9-9-1")}</li>
                    <li>{t("Application-9-9-2")}</li>
                    <li>{t("Application-9-9-3")}</li>
                    <li>D{t("Application-9-9-4")}</li>
                    <li>{t("Application-9-9-5")}</li>
                    <li>{t("Application-9-9-6")}</li>
                    <li>{t("Application-9-9-7")}</li>
                    <li>{t("Application-9-9-8")}</li>
                  </ol>
                </li>
                <li>{t("Application-10")}</li>
                <li>{t("Application-11")}</li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>
            {t("The Programme, Participation & Disqualification")}
          </span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("The Programme-1")}</li>
            <li>
              <span className={styles.TCMain__boldText}>
                {t("The Programme-2 title")}
              </span>{" "}
              <p>{t("The Programme-2 text")}</p>
            </li>
            <li>
              <span className={styles.TCMain__boldText}>
                {t("The Programme-3 title")}
              </span>{" "}
              <p>{t("The Programme-3 text")}</p>
            </li>
            <li>{t("The Programme-4")}</li>
            <li>
              <span className={styles.TCMain__boldText}>
                {t("The Programme-5 title")}
              </span>{" "}
              <p>{t("The Programme-5 text")}</p>
            </li>
            <li>{t("The Programme-6")}</li>
            <li>{t("The Programme-7")}</li>
            <li>
              <span className={styles.TCMain__boldText}>
                {t("The Programme-8 title")}
              </span>{" "}
              <p>{t("The Programme-8")}</p>
            </li>
            <li>{t("The Programme-9")}</li>
            <li>{t("The Programme-10")}</li>
            <li>{t("The Programme-11")}</li>
            <li>{t("The Programme-12")}</li>
            <li>
              <span className={styles.TCMain__boldText}>
                {t("The Programme-13 title")}
              </span>{" "}
              <p>{t("The Programme-13 text")}</p>
            </li>
            <li>{t("The Programme-14")}</li>
            <li>{t("The Programme-15")}</li>
            <li>{t("The Programme-16")}</li>
            <li>{t("The Programme-17-1")}</li>
            <li>
              <span className={styles.TCMain__boldText}>
                {t("The Programme-17 title")}
              </span>{" "}
              <p>{t("The Programme-17 text")}</p>
            </li>
            <li>{t("The Programme-18")}</li>
            <li>{t("The Programme-19")}</li>
            <li>{t("The Programme-20")}</li>
            <li>
              <span className={styles.TCMain__boldText}>
                {t("The Programme-21 title")}
              </span>
              <p>{t("The Programme-21 text")}</p>
            </li>
            <li>{t("The Programme-22")}</li>
            <li>{t("The Programme-23")}</li>
            <li>{t("The Programme-24")}</li>
            <li>{t("The Programme-25-1")}</li>
            <li>{t("The Programme-25")}</li>
            <li>{t("The Programme-26-1")}</li>
            <li>{t("The Programme-26")}</li>
            <li>
              <span className={styles.TCMain__boldText}>
                {t("The Programme-27 title")}
              </span>
              <p>{t("The Programme-27 text")}</p>
            </li>
            <li>{t("The Programme-28")}</li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>
            {t("Long Absence and Deferrals")}
          </span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("Long Absence and Deferrals-1")}</li>
            <li>{t("Long Absence and Deferrals-2")}</li>
            <li>{t("Long Absence and Deferrals-3")}</li>
            <li>{t("Long Absence and Deferrals-4")}</li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>
            {t("Removal and Disqualification")}
          </span>
          <ol className={styles.TCMain__structuredList}>
            <li>
              {t("Removal and Disqualification-1")}
              <ol className={styles.TCMain__structuredList}>
                <li>{t("Removal and Disqualification-1-1")}</li>
                <li>{t("Removal and Disqualification-1-2")}</li>
                <li>{t("Removal and Disqualification-1-3")}</li>
              </ol>
            </li>
            <li>
              {t("Removal and Disqualification-2")}
              <ol className={styles.TCMain__structuredList}>
                <li>{t("Removal and Disqualification-2-1")}</li>
                <li>{t("Removal and Disqualification-2-2")}</li>
                <li>{t("Removal and Disqualification-2-3")}</li>
              </ol>
            </li>
            <li>{t("Removal and Disqualification-3")}</li>
            <li>{t("Removal and Disqualification-4")}</li>
            <li>
              {t("Removal and Disqualification-5")}
              <ol className={styles.TCMain__structuredList}>
                <li>{t("Removal and Disqualification-5-1")}</li>
                <li>{t("Removal and Disqualification-5-2")}</li>
                <li>{t("Removal and Disqualification-5-3")}</li>
                <li>{t("Removal and Disqualification-5-4")}</li>
                <li>{t("Removal and Disqualification-5-5")}</li>
                <li>{t("Removal and Disqualification-5-6")}</li>
                <li>{t("Removal and Disqualification-5-7")}</li>
                <li>{t("Removal and Disqualification-5-8")}</li>
                <li>{t("Removal and Disqualification-5-9")}</li>
                <li>{t("Removal and Disqualification-5-10-1")}</li>
                <li>{t("Removal and Disqualification-5-10-2")}</li>
                <li>{t("Removal and Disqualification-5-10")}</li>
                <li>{t("Removal and Disqualification-5-11")}</li>
                <li>{t("Removal and Disqualification-5-12")}</li>
              </ol>
            </li>
          </ol>

          <li>
            {t("Removal and Disqualification-6")}
            <ol className={styles.TCMain__structuredList}>
              <li>{t("Removal and Disqualification-6-1")}</li>
              <li>{t("Removal and Disqualification-6-2")}</li>
              <li>{t("Removal and Disqualification-6-3")}</li>
              <li>{t("Removal and Disqualification-6-4")}</li>
              <li>{t("Removal and Disqualification-6-5")}</li>
              <li>{t("Removal and Disqualification-6-6")}</li>
              <li>{t("Removal and Disqualification-6-7")}</li>
            </ol>
          </li>
          <li>{t("Removal and Disqualification-7")}</li>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>
            {t("TEF Alumni Network")}
          </span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("TEF Alumni Network-1")}</li>
            <li>{t("TEF Alumni Network-2")}</li>
            <li>{t("TEF Alumni Network-3")}</li>
            <li>{t("TEF Alumni Network-4")}</li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>{t("Communication")}</span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("Communication-1")}</li>
            <li>{t("Communication-2")}</li>
            <li>{t("Communication-3")}</li>
            <li>{t("Communication-4")}</li>
            <li>{t("Communication-5")}</li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>
            {t("Publicity & Privacy")}
          </span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("Publicity & Privacy-1")}</li>
            <li>{t("Publicity & Privacy-2")}</li>
            <li>
              {t("Publicity & Privacy-3")}
              <Link
                target="_blank"
                to="/privacy"
                className={styles.TCMain__redText}
              >
                {t("Privacy Policy")}
              </Link>
            </li>
            <li>{t("Publicity & Privacy-4")}</li>
            <li>{t("Publicity & Privacy-5")}</li>
            <li>{t("Publicity & Privacy-6")}</li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>{t("Liability")}</span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("Liability-1")}</li>
            <li>{t("Liability-2")}</li>
            <li>{t("Liability-3")}</li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>{t("General")}</span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("General-1")}</li>
            <li>{t("General-2")}</li>
          </ol>
        </li>
      </ol>
      <div className={styles.TCMain__btn}>
        <Link className={styles.TCMain__rejectBtn} to="/programme/dashboard">
          {t("Reject")}
        </Link>
        <button className={styles.TCMain__acceptBtn} onClick={toggle}>
          {t("Accept")}
        </button>
      </div>
    </div>
  );
};

export default OffCycleTC;
