import axios from "axios";
import { asyncActions } from "src/utils/AsyncUtils";
import { discoverURL } from "src/constants";
import { toast } from "react-toastify";
import {
  GET_ALL_STORY,
  GET_ALL_STORY_INDUSTRIES,
  CREATE_STORY,
  CREATE_STORY_COMMENT,
  GET_ALL_STORY_COMMENTS,
} from "src/actions";

export const getAllStories = (dispatch, pageNumber, sortByString = "") => {
  dispatch(asyncActions(GET_ALL_STORY).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ALL_STORY_URL}?pageNumber=${pageNumber}&SortBy=${sortByString}`,
  });
};
export const getStories = (dispatch, id) => {
  dispatch(asyncActions(GET_ALL_STORY).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ALL_STORY_URL}/${id}`,
  });
};

export const getAllIndustries = (dispatch) => {
  dispatch(asyncActions(GET_ALL_STORY_INDUSTRIES).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ALL_STORY_INDUSTRIES}`,
  })
    .then((res) => {
      dispatch(asyncActions(GET_ALL_STORY_INDUSTRIES).loading(false));
      if (res.status === 200) {
        const { data } = res.data;
        dispatch(asyncActions(GET_ALL_STORY_INDUSTRIES).success(data));
        dispatch(asyncActions(GET_ALL_STORY_INDUSTRIES).loading(false));
      }
      return res;
    })
    .catch((err) => {
      dispatch(asyncActions(GET_ALL_STORY_INDUSTRIES).loading(false));
      dispatch(
        asyncActions(GET_ALL_STORY_INDUSTRIES).failure(true, err.response)
      );
      return err.response;
    });
};
export const postStory = (dispatch, payload) => {
  dispatch(asyncActions(CREATE_STORY).loading(true));
  return axios({
    method: "post",
    url: discoverURL.GET_ALL_STORY_URL,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(CREATE_STORY).loading(false));
      const { data } = response.data;
      if (response.status === 201) {
        dispatch(asyncActions(CREATE_STORY).success(data));
        dispatch(asyncActions(CREATE_STORY).loading(false));
        // toast.success("Group Successfully Created");
      }
      return response;
    })
    .catch((error) => {
      const {
        data: { message },
      } = error.response;
      const fail = "An error occurred, Please try again ";
      dispatch(asyncActions(CREATE_STORY).loading(false));
      dispatch(asyncActions(CREATE_STORY).failure(true, error.response));
      toast.error(message || fail);
      console.log(message, fail);
      return error.response;
    });
};

export const createStoryComment = (dispatch, payload) => {
  dispatch(asyncActions(CREATE_STORY_COMMENT).loading(true));
  return axios({
    method: "post",
    url: discoverURL.CREATE_STORY_COMMENT,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(CREATE_STORY_COMMENT).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(CREATE_STORY_COMMENT).success(response.data));
        dispatch(asyncActions(CREATE_STORY_COMMENT).loading(false));
        toast("Commented Successfully ✅");
      }
      return response;
    })
    .catch((error) => {
      console.log(error);
      dispatch(asyncActions(CREATE_STORY_COMMENT).loading(false));
      dispatch(
        asyncActions(CREATE_STORY_COMMENT).failure(true, error.response)
      );
      toast("An error occurred, Please try again ");
      return error.response;
    });
};
export const getAllComments = (dispatch, payload, pageNumber) => {
  dispatch(asyncActions(GET_ALL_STORY_COMMENTS).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ALL_STORY_URL}/${payload}/comments?pageNumber=${pageNumber}`,
  });
};
export const toggleLike = (dispatch, storyId) => {
  dispatch(asyncActions(GET_ALL_STORY_COMMENTS).loading(true));
  return axios({
    method: "post",
    url: `${discoverURL.GET_ALL_STORY_URL}/${storyId}/likes/user/toggle`,
  });
};

export const getAllLikeUser = (dispatch, storyId) => {
  dispatch(asyncActions(GET_ALL_STORY_COMMENTS).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ALL_STORY_URL}/${storyId}/likes`,
  });
};

export const getTotalComment = (dispatch, storyId) => {
  dispatch(asyncActions(GET_ALL_STORY_COMMENTS).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ALL_STORY_URL}/${storyId}/comments/count`,
  });
};

export const updateStory = (id, payload) => {
  return axios({
    method: "PUT",
    url: `${discoverURL.UPDATE_STORY}/${id}`,
    data: payload,
  });
};
