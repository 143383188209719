import DOMPurify from "dompurify";
import React from "react";
import Loader from "react-loader-spinner";
import homeIcon from "src/assets/img/homeIcon.png";
export const Overview = ({ overview, loading }) => {
  return (
    <div className="training-card">
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "300px",
          }}
          className="training-card__overview"
        >
          <Loader
            type="ThreeDots"
            height={50}
            width={50}
            // color="white"
            color="var(--tef-red)"
          />
        </div>
      )}
      {!loading && overview && (
        <div className="training-card__overview">
          <section className="training-card__overview--section-header">
            <img
              src={homeIcon}
              alt="overview icon"
              className="training-card__overview--section-header--icon"
            />
            <h1 className="training-card__overview--section-header--h1">
              Overview
            </h1>
          </section>
          <section className="training-card__overview--section-paragraph">
            <p
              style={{
                overflowWrap: "break-word",
                wordWrap: "break-word",
                hyphens: "auto",
              }}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(overview) }}
            ></p>
          </section>
        </div>
      )}
    </div>
  );
};
