import React, { useState, useEffect, useContext } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import previewClasses from "./PreviewApplication.module.scss";
import Bio from "./EditBio";
import ExperienceEdit from "./EditExperience";
import { getUserApplications } from "src/requests/ApplicationRequest";
import { Context } from "src/store";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { useTranslation } from "react-i18next";
// import { preViewBtns } from "src/modules/Application/personalInfo";

const EditApplication = ({ toggleEditModal }) => {
  const [activeTab, setActiveTab] = useState("1");
  const { dispatch } = useContext(Context);

  const { t } = useTranslation();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const user = useCurrentUser();

  useEffect(() => {
    getUserApplications(dispatch, user.userId);
  }, []);

  const _scrollToTop = () => {
    document.getElementById("form-top").scrollIntoView();
  };
  return (
    <div id="form-top">
      <h1 className={previewClasses.editApplcationHeading}>
        <span>{t("Edit Application Info")}</span>
      </h1>

      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            {t("Bio")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            {t("Idea and experience")}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <Bio
                toggleModal={toggleEditModal}
                toggleNext={toggle}
                scrollToTop={_scrollToTop}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              <ExperienceEdit toggleModal={toggleEditModal} />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};
export default EditApplication;
