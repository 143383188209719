/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from "react";
import "../../Flagship/personalInfo/common.scss";
import "../../Flagship/personalInfo/experience/experience.scss";
import { FormField, Select } from "src/modules/onboarding/components";
import { SelectBox } from "src/modules/Application/Flagship/personalInfo/components";
import { Multiselect } from "multiselect-react-dropdown";
import { FaUpload } from "react-icons/fa";

import { personalInfo } from "src/modules/Application/Flagship/personalInfo/personanlInfoObj";
import { Context } from "src/store";
import { displayError } from "src/modules/onboarding/helper";
import queryString from "query-string";

import "react-phone-input-2/lib/style.css";
import {
  // yearsOfStarupExperience,
  sdgs,
  experience,
  businessStages,
} from "src/modules/Application/Flagship/personalInfo/experience/options";
import Loader from "react-loader-spinner";
// import { numbers } from "src/modules/onboarding/tefMentorOnboarding/static";
import CLoader from "src/modules/Application/components/CLoader";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { sectionTwoTranslations } from "src/modules/Application/instructions";
import { useHistory } from "react-router-dom";
import { updateApplication } from "src/requests/ApplicationRequest";
import { useTranslation } from "react-i18next";
import {
  CustomCountryDropdown,
  CustomCountryRegionDropdown,
} from "src/components/CustomCountryRegionDropdown";
import { updateUserProfile } from "src/requests/ProfileRequest";
import { preViewBtns } from "src/modules/Application/personalInfo";
import { Input } from "reactstrap";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { updateQueryStringParameter } from "src/utils/helper";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useIndustryList } from "src/modules/Application/applicationHooksAll/useIndustryTypeList";
import { UploadFile } from "src/requests/fileUploadRequest";
import { FileViewerReadOnly } from "src/components/FileViewer/FileViewer";

function isValidFile(fileName) {
  const ext = fileName.split(".").pop();
  const validEx = ["pdf", "docx", "doc"];
  return validEx.includes(ext.toLowerCase());
}
const ExperienceEdit = ({ toggleModal }) => {
  const { state, dispatch } = useContext(Context);
  const [hasError, setHasError] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const history = useHistory();
  const [programmeType, setProgrammeType] = useState("flagship");
  const [updatingMessage, setUpdatingMessage] = useState("");
  const [isuploadingFile, setIsUploadingFile] = useState(false);
  const [fileError, setFileError] = useState("");

  const user = get_user_info_from_token().user;

  const { t, i18n } = useTranslation();

  const { applicationId, lang } = queryString.parse(history.location.search);

  const bStages = businessStages[lang || "en"] || businessStages.en;

  const btns = preViewBtns[lang || "en"] || preViewBtns.en;

  const currentUser = useCurrentUser();
  const language = lang || "en";
  // const language = JSON.parse(localStorage.getItem("language"));
  const ref = useRef();
  // const yearsOfExperience = yearsOfStarupExperience[language || "en"];
  const experienceObj = experience[language || "en"] || experience.en;
  const sdgGoals = sdgs[language || "en"] || sdgs.en;

  useEffect(() => {
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
  }, [lang]);

  const schema = Yup.object({
    aboutIdea: Yup.string().required(t("Required")),
    sdgs: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string(),
          label: Yup.string(),
        })
      )
      .min(3, t("Select minimum of three"))
      .required(t("Required")),

    isBusinessAddressGreenEconomyOrClimateChange: Yup.boolean().required(
      t("Please select")
    ),

    businessAddressGreenEconomyOrClimateChangeDescription: Yup.string().when(
      "isBusinessAddressGreenEconomyOrClimateChange",
      {
        is: true,
        then: () => Yup.string().required(t("Required")),
      }
    ),
    businessName: Yup.string(),
    businessIndustry: Yup.string().required(t("Required")),
    industrySubSector: Yup.string().required(t("Required")),
    businessCountryId: Yup.string().required(t("Required")),
    businessStage: Yup.string().required(t("Required")),
    businessState: Yup.string().required(t("Required")),
    businessCity: Yup.string().required(t("Required")),
    businessAddress: Yup.string().required(t("Required")),
    businessWebsite: Yup.string().url("Invalid url"),
    businessLocalGovernmentArea: Yup.string().when("businessCountryId", {
      is: "NG" || "ng",
      then: () => Yup.string().required(t("Required")),
    }),
    isBusinessRegistered: Yup.boolean().required(t("Required")),
    hasBusinessPartnerConsent: Yup.boolean().required(t("Required")),
    isBusinessRural: Yup.boolean().required(t("Required")),
    hasReceivedPriorTEFFunding: Yup.boolean().required(t("Required")),
    partnerConsentLetterUrl: Yup.string().when("hasBusinessPartnerConsent", {
      is: true,
      then: () => Yup.string().required(t("Required")),
    }),
    teamSize: Yup.string().required(t("Required")),
    haveBusinessPartners: Yup.boolean().required(t("Required")),
    isIdeaInWasteManagementSector: Yup.boolean().required(t("Required")),
    availableRevenue: Yup.string().required(t("Required")),
    hasMeasuredEnvironmentalImpact: Yup.boolean().required(t("Required")),
    hasReceivedPriorTEFFundingOnSameBusinessIdea: Yup.boolean().when(
      "hasReceivedPriorTEFFunding",
      {
        is: true,
        then: () => Yup.string().required(t("Required")),
      }
    ),
    businessRegistrationNumber: Yup.string().when("isBusinessRegistered", {
      is: true,
      then: () => Yup.string().required(t("Required")),
    }),
  }).required();

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    defaultValues: { ...personalInfo },
  });

  const { industryList, subSectorList } = useIndustryList(
    watch().businessIndustry,
    watch()?.languageId
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    const query = history.location.search;
    if (query) {
      const parsedQuery = queryString.parse(query);
      if (Object.keys(parsedQuery).length === 0 || !parsedQuery.applicationId) {
        history.replace("/programme/dashboard", {});
        history.go(1);
      }
    } else {
      history.replace("/programme/dashboard", {});
      history.go(1);
    }
    return () => {
      window.scrollTo(0, 0);
      const query = history.location.search;
      if (query) {
        const parsedQuery = queryString.parse(query);
        if (
          Object.keys(parsedQuery).length === 0 ||
          !parsedQuery.applicationId
        ) {
          history.replace("/programme/dashboard", {});
          history.go(1);
        }
      } else {
        history.replace("/programme/dashboard", {});
        history.go(1);
      }
    };
  }, [history.location.search]);

  const { programmes } = state.Programmes;

  useEffect(() => {
    if (programmes && programmes.length > 0) {
      const filtered = programmes.find((p) => {
        return p.programmeEvents.find((e) => {
          return e.id === applicationId;
        });
      });

      filtered && setProgrammeType(filtered.programmeType);
    }
  }, [programmes]);

  useEffect(() => {
    const { userApplications } = state.Application;

    const applicant =
      userApplications &&
      userApplications.filter(
        (applicant) => applicant.applicationId === applicationId
      );
    if (applicant && applicant.length > 0) {
      const defaultState = { ...getValues() };
      // setInfo({ ...defaultState, ...restructure(applicant[0]) });
      reset({ ...defaultState, ...restructure(applicant[0]) });
    }
  }, [state.Application.userApplications]);

  useEffect(() => {
    const errorsvalues = Object.values(errors);
    if (errorsvalues.length > 0) {
      const ref = errorsvalues?.[0]?.ref;
      if (ref) {
        document
          .getElementById(errorsvalues?.[0]?.ref.name)
          ?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [errors]);

  const restructure = (info) => {
    if (!info.haveBusinessPartners) {
      info.hasBusinessPartnerConsent = null;
    }

    const SDGS = info.sdgs;

    const goals = SDGS.map((g) => {
      return sdgGoals.find((s) => {
        return Number(s.value) === Number(g);
      });
    });

    info.sdgs = goals;
    return info;
  };

  const extractPayload = (data) => {
    const {
      aboutIdea,
      yearsOfStartupExperience,
      isIdeaInFieldOfExperience,
      sdgs,
      businessName,
      businessAddress,
      businessIndustry,
      businessState,
      businessCity,
      businessCountryId,
      industrySubSector,
      businessAge,
      isWorkingInTeam,
      teamSize,
      hoursPerWeekInvested,
      isIdeaProfitable,
      monthlyIncome,
      haveBusinessPartners,
      hasBusinessPartnerConsent,
      isBusinessRural,
      hasStableInternet,
      isBusinessAddressGreenEconomyOrClimateChange,
      businessAddressGreenEconomyOrClimateChangeDescription,
      businessWebsite,
      businessStage,
      businessRegistrationNumber,
      businessLocalGovernmentArea,
      isBusinessRegistered,
      hasReceivedPriorTEFFunding,
      hasReceivedPriorTEFFundingOnSameBusinessIdea,
      availableRevenue,
      hasMeasuredEnvironmentalImpact,
      isIdeaInWasteManagementSector,
    } = data;

    return {
      aboutIdea,
      yearsOfStartupExperience,
      isIdeaInFieldOfExperience,
      sdgs,
      businessName,
      businessAddress,
      businessIndustry,
      businessState,
      businessCity,
      businessCountryId,
      industrySubSector,
      businessAge,
      isWorkingInTeam,
      teamSize,
      hoursPerWeekInvested,
      isIdeaProfitable,
      monthlyIncome,
      haveBusinessPartners,
      hasBusinessPartnerConsent,
      isBusinessRural,
      hasStableInternet,
      isBusinessAddressGreenEconomyOrClimateChange,
      businessAddressGreenEconomyOrClimateChangeDescription,
      businessWebsite,
      businessStage,
      businessRegistrationNumber,
      businessLocalGovernmentArea,
      isBusinessRegistered,
      hasReceivedPriorTEFFunding,
      hasReceivedPriorTEFFundingOnSameBusinessIdea,
      availableRevenue,
      hasMeasuredEnvironmentalImpact,
      isIdeaInWasteManagementSector,
    };
  };

  const updateProfileAccount = (info) => {
    const profileDetails = [
      "businessName",
      "businessCity",
      "businessState",
      "businessAddress",
      "businessIndustry",
    ];

    let profileObj = {};
    profileDetails.forEach((key) => {
      if (info[key]) {
        profileObj = { ...profileObj, [key]: info[key] };
      }
    });

    if (!profileObj.businessCountry) {
      profileObj.businessCountry = info.businessCountryId;
    }

    if (!profileObj.aboutBusiness) {
      profileObj.aboutBusiness = info.aboutIdea;
    }

    if (!profileObj.businessRevenue) {
      profileObj.businessRevenue = info.monthlyIncome;
    }

    delete profileObj.businessCountryId;
    return updateUserProfile(dispatch, profileObj);
  };

  const _onDocChange = (e) => {
    const files = e.target.files;
    if (!isValidFile(files[0].name)) {
      setFileError(
        t("Invalid file format. Only doc, docx and pdf are allowed")
      );
      return;
    }

    setFileError("");
    setIsUploadingFile(true);
    UploadFile(dispatch, false, files)
      .then((res) => {
        const { files } = res;
        setValue("partnerConsentLetterUrl", files[0]);
        setIsUploadingFile(false);
      })
      .catch((e) => {
        setIsUploadingFile(false);
      });
  };

  const _handleSubmit = (info) => {
    // return console.log(info);
    if (loadingSave) return;
    setLoadingSave(true);
    setHasError(false);
    setUpdatingMessage(t("Saving application info..."));
    const data = _sanitizeData(info);
    // return console.log(data);
    return updateApplication(
      dispatch,
      data,
      info.applicationId,
      currentUser.userId,
      true,
      t("Application info updated successfully.")
    ).then(() => {
      setUpdatingMessage(t("Updating profile info..."));
      return updateProfileAccount(data).then(() => {
        setUpdatingMessage("");
        setLoadingSave(false);
        if (user["2020_applicant"]) {
          let path = location.pathname + location.search;
          path = updateQueryStringParameter(path, "validate", true);
          history.push(path);
        }
        toggleModal();
      });
    });
  };

  const _sanitizeData = (data) => {
    data = { ...data };
    delete data.validationErrors;
    delete data.skipTour;
    delete data.steps;
    delete data.timeTaken;
    delete data.score;
    delete data.status;
    delete data.progress;
    delete data.staticIdUrl;

    if (data.hasBusinessPartnerConsent === null) {
      data.hasBusinessPartnerConsent = false;
    }

    const sdgs = data.sdgs.map((goal) => {
      return goal.value;
    });
    const sanitized = { ...data, sdgs: sdgs };
    const extracted = extractPayload(sanitized);
    return extracted;
  };

  return (
    <div className="application-personal-info-c" style={{ marginTop: "15px" }}>
      <div style={{ marginBottom: "10px" }} className="back-btn-somewhere">
        <span
          className="back-2-somewhere"
          onClick={() => history.replace("/home", {})}
        >
          {t("Back to Home")}
        </span>
      </div>

      <div className="application-personal-info-c__form">
        <div className="p-info-form-heading">
          <h5>
            <span className="bold">{experienceObj.title}</span>{" "}
          </h5>
          <h4>{sectionTwoTranslations[language || "en"]}</h4>
        </div>
        <form ref={ref} onSubmit={handleSubmit(_handleSubmit)}>
          {hasError && (
            <span style={{ color: "red" }}>{experienceObj.requiredInfo}</span>
          )}
          <div className="row">
            <div className="col-md-12">
              <Controller
                name="aboutIdea"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      placeholder={experienceObj.businessSummary}
                      label={experienceObj.businessIdea}
                      type="textarea"
                      id="aboutIdea"
                      value={value}
                      onChange={(_, val) => onChange(val)}
                      name={name}
                      onBlur={onBlur}
                      error={error?.message}
                    />
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <br />

              <Controller
                name="sdgs"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={experienceObj.goals} id="sdgs">
                      <Multiselect
                        options={sdgGoals}
                        displayValue="label"
                        onSelect={(selectedList, selectedItem) =>
                          // onSdgSelect("sdgs", selectedList)
                          onChange(selectedList)
                        }
                        placeholder={t("Select")}
                        selectionLimit={3}
                        selectedValues={value}
                        closeOnSelect={false}
                        style={{
                          chips: {
                            background: "red",
                          },
                        }}
                        onBlur={onBlur}
                        onRemove={(selectedList, selectedItem) =>
                          onChange(selectedList)
                        }
                      />
                    </FormField>

                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <br />
              <Controller
                name="hasReceivedPriorTEFFunding"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.hasReceivedPriorTEFFunding}
                      id="hasReceivedPriorTEFFunding"
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            label={experienceObj.yes}
                            onSelect={(_, value) => {
                              onChange(value);
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            label={experienceObj.no}
                            onSelect={(_, value) => {
                              onChange(value);
                              setValue(
                                "hasReceivedPriorTEFFundingOnSameBusinessIdea",
                                false
                              );
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                      </div>
                      <>
                        {error?.message && displayError(`${t(error?.message)}`)}
                      </>
                    </FormField>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <br />
              <Controller
                name="hasReceivedPriorTEFFundingOnSameBusinessIdea"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={
                        experienceObj.hasReceivedPriorTEFFundingOnSameBusinessIdea
                      }
                      disabled={!watch().hasReceivedPriorTEFFunding}
                      id="hasReceivedPriorTEFFundingOnSameBusinessIdea"
                      // onChange={(_, val) => onChange(val)}
                      value={value}
                      name={name}
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            label={experienceObj.yes}
                            onSelect={(_, value) => {
                              onChange(value);
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            label={experienceObj.no}
                            onSelect={(_, value) => {
                              onChange(value);
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                      </div>
                    </FormField>

                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <br />
              <Controller
                name="isBusinessAddressGreenEconomyOrClimateChange"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={
                        experienceObj.isBusinessAddressGreenEconomyOrClimateChange
                      }
                      id="isBusinessAddressGreenEconomyOrClimateChange"
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            label={experienceObj.yes}
                            onSelect={(_, value) => {
                              onChange(value);
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            label={experienceObj.no}
                            onSelect={(_, value) => {
                              onChange(value);
                              setValue(
                                "businessAddressGreenEconomyOrClimateChangeDescription",
                                ""
                              );
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                      </div>
                      <>
                        {error?.message && displayError(`${t(error?.message)}`)}
                      </>
                    </FormField>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Controller
                name="businessAddressGreenEconomyOrClimateChangeDescription"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      disabled={
                        !watch().isBusinessAddressGreenEconomyOrClimateChange
                      }
                      label={
                        experienceObj.businessAddressGreenEconomyOrClimateChangeDescription
                      }
                      type="textarea"
                      id="businessAddressGreenEconomyOrClimateChangeDescription"
                      value={value}
                      onChange={(_, val) => onChange(val)}
                      name={name}
                      onBlur={onBlur}
                      error={error?.message}
                    />
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <br />
              <Controller
                name="isIdeaInWasteManagementSector"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.isIdeaInWasteManagementSector}
                      id="isIdeaInWasteManagementSector"
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            label={experienceObj.yes}
                            onSelect={(_, value) => {
                              onChange(value);
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            label={experienceObj.no}
                            onSelect={(_, value) => {
                              onChange(value);
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                      </div>
                      <>
                        {error?.message && displayError(`${t(error?.message)}`)}
                      </>
                    </FormField>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6" style={{ marginTop: "-5px" }}>
              <Controller
                name="businessName"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.businessName}
                      placeholder={experienceObj.namePlaceholder}
                      value={value}
                      onChange={(_, val) => onChange(val)}
                      name={name}
                      id="businessName"
                      onBlur={onBlur}
                      error={error?.message}
                    />
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                name="businessIndustry"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.industry}
                      id="businessIndustry"
                    >
                      <Select
                        defaultVal={experienceObj.select1}
                        list={industryList}
                        value={value}
                        onChange={(_, value) => {
                          onChange(value);
                          setValue("industrySubSector", "");
                        }}
                        name={name}
                        onBlur={onBlur}
                        error={error?.message}
                      />
                    </FormField>
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Controller
                name="industrySubSector"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.subSector}
                      id="industrySubSector"
                    >
                      <Input
                        type="select"
                        defaultValue={t("Select")}
                        onBlur={onBlur}
                        onChange={(e) => onChange(e.target.value)}
                        name={name}
                        value={value}
                      >
                        <option value="">{t("Select")}</option>
                        {subSectorList.map((e, i) => {
                          return (
                            <option key={i} value={e.value}>
                              {e.label}{" "}
                            </option>
                          );
                        })}
                      </Input>
                    </FormField>

                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                name="businessCountryId"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.businessCountry}
                      id="businessCountryId"
                    >
                      <CustomCountryDropdown
                        classes={`flex country-state-list`}
                        value={value}
                        name={name}
                        valueType="short"
                        onChange={(val) => {
                          onChange(val);
                          setValue("businessState", "");
                        }}
                        onBlur={onBlur}
                      />
                    </FormField>
                    {error?.message && (
                      <div
                        className="displayError"
                        style={{ marginLeft: "3px" }}
                      >
                        {error?.message && displayError(`${t(error?.message)}`)}
                      </div>
                    )}
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Controller
                name="businessState"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.businessState}
                      id="businessState"
                    >
                      <CustomCountryRegionDropdown
                        countryValueType="short"
                        defaultOptionLabel={experienceObj.select2}
                        country={watch().businessCountryId}
                        classes={`flex country-state-list $}`}
                        name={name}
                        value={value}
                        onChange={(val) => onChange(val)}
                        onBlur={onBlur}
                      />
                    </FormField>
                    {error?.message && (
                      <div
                        className="displayError"
                        style={{ marginLeft: "3px" }}
                      >
                        {error?.message && displayError(`${t(error?.message)}`)}
                      </div>
                    )}
                  </>
                )}
              />
            </div>
            <div className="col-md-6" style={{ marginTop: "-10px" }}>
              <Controller
                name="businessCity"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.businessCity}
                      placeholder={experienceObj.cityPlaceholder}
                      value={value}
                      name={name}
                      id="businessCity"
                      onChange={(_, val) => onChange(val)}
                      onBlur={onBlur}
                    />
                    {error?.message && displayError(error?.message)}
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Controller
                name="businessAddress"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.businessLocation}
                      placeholder="Enter Business address"
                      value={value}
                      onChange={(_, val) => onChange(val)}
                      name={name}
                      id="businessAddress"
                      onBlur={onBlur}
                      error={error?.message}
                    />
                    {error?.message && displayError(error?.message)}
                  </>
                )}
              />
            </div>

            <div className="col-md-6">
              <Controller
                name="businessWebsite"
                control={control}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.businessWebsite}
                      value={value}
                      onChange={(_, value) => onChange(value)}
                      name={name}
                      id="businessWebsite"
                      type={"url"}
                      error={error?.message}
                      onBlur={onBlur}
                    />
                    {error?.message && displayError(error?.message)}
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Controller
                name="businessLocalGovernmentArea"
                control={control}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.businessLocalGovernmentArea}
                      placeholder={experienceObj.businessLocalGovernmentArea}
                      value={value}
                      onChange={(_, val) => onChange(val)}
                      disabled={
                        watch().businessCountryId.toLowerCase() !== "ng"
                      }
                      name="businessLocalGovernmentArea"
                      id="businessLocalGovernmentArea"
                      onBlur={onBlur}
                      error={error?.message}
                    />

                    {error?.message && displayError(error?.message)}
                  </>
                )}
              />
            </div>

            <div className="col-md-6">
              <Controller
                name="businessStage"
                control={control}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.businessStage}
                      id="businessStage"
                    >
                      <Select
                        defaultVal={experienceObj.select1}
                        list={bStages}
                        value={value}
                        onChange={(_, val) => onChange(val)}
                        name="businessStage"
                        onBlur={onBlur}
                        error={error?.message}
                      />
                    </FormField>
                    {error?.message && displayError(error?.message)}
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <Controller
                name="isBusinessRegistered"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.isBusinessRegistered}
                      id="isBusinessRegistered"
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            label={experienceObj.yes}
                            onSelect={(target, value) => {
                              onChange(value);
                            }}
                            name="isBusinessRegistered"
                            selected={value}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            label={experienceObj.no}
                            onSelect={(target, value) => {
                              onChange(value);
                              setValue("businessRegistrationNumber", "");
                            }}
                            name="isBusinessRegistered"
                            selected={value}
                          />
                        </div>
                      </div>
                      {error?.message && displayError(error?.message)}
                    </FormField>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Controller
                name="businessRegistrationNumber"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      disabled={!watch().isBusinessRegistered}
                      label={experienceObj.businessRegistrationNumber}
                      id="businessRegistrationNumber"
                      value={value}
                      onChange={(_, v) => onChange(v)}
                      name="businessRegistrationNumber"
                      onBlur={onBlur}
                      error={error?.message}
                    />
                    {error?.message && displayError(error?.message)}
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <Controller
                name="teamSize"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.noOfPeople}
                      placeholder="e.g 20"
                      type="number"
                      min="1"
                      name="teamSize"
                      id="teamSize"
                      onChange={(_, val) => onChange(val)}
                      value={value.toString()}
                      onBlur={onBlur}
                    ></FormField>

                    {error?.message && displayError(error?.message)}
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <Controller
                name="availableRevenue"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.availableRevenue}
                      type="number"
                      min="1"
                      name="availableRevenue"
                      id="availableRevenue"
                      onChange={(_, val) => onChange(val)}
                      value={value?.toString()}
                      onBlur={onBlur}
                    ></FormField>

                    {error?.message && displayError(error?.message)}
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <br />
              <Controller
                name="hasMeasuredEnvironmentalImpact"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.hasMeasuredEnvironmentalImpact}
                      id="hasMeasuredEnvironmentalImpact"
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            label={experienceObj.yes}
                            onSelect={(_, value) => {
                              onChange(value);
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            label={experienceObj.no}
                            onSelect={(_, value) => {
                              onChange(value);
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                      </div>
                      <>
                        {error?.message && displayError(`${t(error?.message)}`)}
                      </>
                    </FormField>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <br />
              <Controller
                name="haveBusinessPartners"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.businessPartners}
                      id="haveBusinessPartners"
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            selected={value}
                            name="haveBusinessPartners"
                            label={experienceObj.yes}
                            onSelect={(target, value) => {
                              onChange(value);
                            }}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            selected={value}
                            name="haveBusinessPartners"
                            label={experienceObj.no}
                            onSelect={(target, value) => {
                              onChange(value);
                              setValue("hasBusinessPartnerConsent", false);
                            }}
                          />
                        </div>
                      </div>
                      {error?.message && displayError(error?.message)}
                    </FormField>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <br />
              <Controller
                name="hasBusinessPartnerConsent"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.partnerConsent}
                      id="hasBusinessPartnerConsent"
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            selected={value}
                            name="hasBusinessPartnerConsent"
                            label={experienceObj.yes}
                            onSelect={(target, value) => {
                              onChange(value);
                            }}
                            disabled={!watch().haveBusinessPartners}
                            nullable={!watch().hasBusinessPartnerConsent}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            selected={value}
                            name="hasBusinessPartnerConsent"
                            label={experienceObj.no}
                            onSelect={(target, value) => {
                              onChange(value);
                            }}
                            disabled={!watch().haveBusinessPartners}
                            nullable={!watch().haveBusinessPartners}
                          />
                        </div>
                      </div>
                    </FormField>
                    {error?.message && displayError(error?.message)}
                  </>
                )}
              />
            </div>
          </div>

          {watch().hasBusinessPartnerConsent && (
            <div className="row">
              <div className="col-md-12">
                <Controller
                  name="partnerConsentLetterUrl"
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value, onBlur },
                    fieldState: { error },
                  }) => (
                    <>
                      <FormField
                        label={experienceObj.partnerConsentLetterUrl}
                        type="file"
                        name="partnerConsentLetterUrl"
                        id="partnerConsentLetterUrl"
                        onBlur={onBlur}
                        error={value}
                      >
                        <div>
                          <div>
                            <div
                              className="id-upload-btn col-md-5"
                              onClick={() =>
                                document
                                  .getElementById(
                                    "previewPartnerConsentLetterUrl"
                                  )
                                  .click()
                              }
                            >
                              <span>
                                <FaUpload color="white" size={12} />
                              </span>
                              <span>{t("Choose file")}</span>
                              <span>
                                {isuploadingFile && (
                                  <Loader
                                    type="Oval"
                                    height={13}
                                    width={13}
                                    color="white"
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                          <input
                            type="file"
                            name="previewPartnerConsentLetterUrl"
                            id="previewPartnerConsentLetterUrl"
                            onChange={_onDocChange}
                            className="id-file-upload"
                            // accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          />
                        </div>

                        {watch().partnerConsentLetterUrl && (
                          <div className="">
                            <FileViewerReadOnly
                              files={[watch().partnerConsentLetterUrl]}
                            />
                          </div>
                        )}
                      </FormField>
                      {fileError && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {fileError}
                        </span>
                      )}
                      {error?.message && displayError(error?.message)}
                    </>
                  )}
                />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-md-6">
              <br />
              <Controller
                name="isBusinessRural"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={experienceObj.rural} id="isBusinessRural">
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            selected={value}
                            name="isBusinessRural"
                            label={experienceObj.yes}
                            onSelect={(target, value) => {
                              onChange(value);
                            }}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            selected={value}
                            name="isBusinessRural"
                            label={experienceObj.no}
                            onSelect={(target, value) => {
                              onChange(value);
                            }}
                          />
                        </div>
                      </div>
                      {error?.message && displayError(error?.message)}
                    </FormField>
                  </>
                )}
              />
            </div>
          </div>
          {/* {programmeType.toLowerCase() === "offcycle" && (
            <div className="row">
              <div className="col-md-6">
                <br />
                <FormField
                  label={experienceObj.isInternetStable}
                  id="hasStableInternet"
                >
                  <div className="row">
                    <div className="col-sm-6 gutter-v">
                      <SelectBox
                        value={true}
                        selected={info.hasStableInternet}
                        name="hasStableInternet"
                        label={experienceObj.yes}
                        onSelect={onChange}
                      />
                    </div>
                    <div className="col-sm-6 gutter-v">
                      <SelectBox
                        value={false}
                        selected={info.hasStableInternet}
                        name="hasStableInternet"
                        label={experienceObj.no}
                        onSelect={onChange}
                      />
                    </div>
                  </div>
                </FormField>
              </div>
            </div>
          )} */}

          <div className="submit-box">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <button className="submit-btn" type="submit">
                  <span>
                    {user["2020_applicant"] ? btns.completeVal : btns.updateExp}
                  </span>
                  {loadingSave && (
                    <Loader type="Oval" color="white" height={15} width={15} />
                  )}
                </button>
              </div>
              <div className="col-md-6 col-sm-12">
                <button
                  className="save-continue-btn"
                  type="button"
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  <span>{btns.discard}</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <>
        <CLoader
          loading={state.Application.loadingUserApplications || loadingSave}
          additonalInfo={loadingSave ? updatingMessage : ""}
        />
      </>
    </div>
  );
};
export default ExperienceEdit;
