import { get_user_info_from_token } from "./someTokenMenthods";

const isDMsUser = () => {
  const {
    user: { role },
  } = get_user_info_from_token();

  return role.includes("dms_user");
};

export default isDMsUser;
