import { IDENTITY_CONFIG, METADATA_OPEN_ID } from "./authConst";
import { Log, UserManager, WebStorageStateStore } from "oidc-client-ts";
import { store_openid_token } from "src/utils/someTokenMenthods";
import { logSignInEvent } from "src/utils/googleAnalytics";
import {
  getLoginProvider,
  LOGIN_METHOD_FORM_SSO,
  storeLoginMethod,
} from "src/utils/loginMethod";

const DEBUG = false;

if (DEBUG) {
  Log.setLogger(console);
  Log.setLevel(Log.DEBUG);
}

/** Manages OpenID authorization. */
class AuthService {
  UserManager;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({
        store: window.sessionStorage,
      }),
      metadata: METADATA_OPEN_ID,
    });

    this.UserManager.events.addSilentRenewError((e) => {
      console.error("silent renew error", e);
    });

    this.UserManager.events.addAccessTokenExpired(() => this.signinSilent());
  }

  // Called when sign-in succeeds.
  signinRedirectCallback = () => {
    storeLoginMethod(LOGIN_METHOD_FORM_SSO);
    const provider = getLoginProvider() || "FormV3";
    return this.UserManager.signinRedirectCallback()
      .then((user) => {
        store_openid_token(user);
        logSignInEvent(provider, "Success");
      })
      .catch((reason) => {
        logSignInEvent(provider, reason);
        console.error(reason);
      });
  };

  getUser = () => this.UserManager.getUser();

  // Redirects to the authorization server.
  signinRedirect = () => this.UserManager.signinRedirect();

  // Called when token needs refresh.
  signinSilent = () => {
    return this.UserManager.signinSilent()
      .then((user) => {
        store_openid_token(user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  signinSilentCallback = () => this.UserManager.signinSilentCallback();

  logout = () => {
    return this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem("id_token"),
    });
  };

  signoutRedirectCallback = () => {
    return this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      sessionStorage.clear();
    });
  };
}

const authService = new AuthService();
export default authService;
