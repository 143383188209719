import classes from "./MyOpportunities.module.scss";
import React, { useContext, useEffect } from "react";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import Layout from "src/components/Layout/Layout";
import { Link } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import OpportunityList from "src/modules/Opportunity/components/List/List";
import { Context } from "src/store";
import { getMyOpportunities } from "src/requests/OpportunityRequest";
import { useTranslation } from "react-i18next";

const MyOpportunies = (props) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Context);
  useEffect(() => {
    const _getBasicData = () => {
      getMyOpportunities(dispatch, {});
    };
    _getBasicData();
  }, []);

  const list = state.Opportunities.myOpportunities;

  return (
    <Layout hideLeft hideRight {...props}>
      <div className="create-opp-breadcrumb">
        <Link to="/home" style={{ fontWeight: "bold" }}>
          {t("Home")} <IoIosArrowRoundForward />
        </Link>
        <Link to="/opportunities">
          {t("Opportunities")} <IoIosArrowRoundForward />
        </Link>
        <span>{t("My Opportunities")}</span>
      </div>
      <div className={classes.myOpportunitiesContainer}>
        <OpportunityList list={list} />
      </div>
    </Layout>
  );
};

export default ifOnboardedThenShow(MyOpportunies);
