import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import classes from "./LandingPage.module.scss";

const GiveBack = () => {
  return (
    <div className={classes.giveBackContainer}>
      <div className={classes.giveBackContainer__left}>
        <h4>Giveback</h4>
        <h3>
          Inspire the next generation of African leaders with stories from your
          business journey
        </h3>
      </div>
      <div className={classes.giveBackContainer__right}>
        <Link to="/signup">
          <Button className={classes.l_btn}>Become a Mentor</Button>
        </Link>
      </div>
    </div>
  );
};

export default GiveBack;
