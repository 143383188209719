import React, { useState, useContext, useEffect } from "react";
import Layout from "src/components/Layout/Layout";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";

import HubBanner from "src/modules/Alumni/AlumniHub/component/HubBanner/index";
import { Row, Col, Container } from "reactstrap";
import classes from "./HubUsers.module.scss";

import HubUser from "src/modules/Alumni/AlumniHub/component/HubUsers/index";
import DefaultImage from "src/assets/img/bg/background_640-1.webp";
import { Context } from "src/store";

import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import HubUserSkeletalLoader from "src/modules/Alumni/AlumniHub/component/HubUsers/SkeletalLoader";
import { getHubUsers } from "src/modules/Alumni/Chat/helper/getHubUsers";

import { getSingleAlumniHub } from "src/requests/AlumniHubRequest";
import { useParams } from "react-router-dom";
import noUsersImage from "src/modules/Alumni/AlumniHub/assets/no-users.svg";

const HubUsers = () => {
  const userData = get_user_info_from_token().user;
  const [hubDetails, setHubDetails] = useState({});
  const [isloadingHubUsers, setIsLoadingHubUsers] = useState(true);
  const [hubUsers, setHubUsers] = useState([]);
  const { dispatch } = useContext(Context);
  const { hubId } = useParams();

  const _getHubUsers = getHubUsers(
    dispatch,
    userData,
    setHubUsers,
    setIsLoadingHubUsers
  );

  useEffect(() => {
    getSingleAlumniHub(dispatch, hubId)
      .then((response) => {
        const hubDetails = response.data.data;
        console.log("usuus", hubDetails);
        setHubDetails(hubDetails);
        return hubDetails;
      })
      .then((res) => {
        console.log("res", res);
        _getHubUsers(res.id);
      })
      .catch((getSingleAlumniError) => console.log(getSingleAlumniError));
  }, []);

  return (
    <Layout SideContent={<SidebarRight />}>
      <Row>
        <Col>
          <HubBanner
            hubBanner={{
              bannerUrl: hubDetails.bannerUrl,
              state: hubDetails.state,
            }}
          />
        </Col>
      </Row>
      <Container>
        <Row>
          <Col>
            <div className={classes["hub-users__wrapper"]}>
              <div className={classes["hub-users__title"]}>
                <h6 className="fs-16 fw-600 mb-3 border-bottom pb-2">
                  {hubDetails.state} Alumni Hub - Members
                </h6>
              </div>
              {isloadingHubUsers ? (
                <div className={classes["users-grid"]}>
                  {["Tunmise", "Ayo", "Dara", "wilson"].map((user) => (
                    <HubUserSkeletalLoader key={user} />
                  ))}
                </div>
              ) : (
                <>
                  <div className={classes["users-grid"]}>
                    {hubUsers.length > 0 &&
                      hubUsers.map((user, index) => (
                        <HubUser
                          src={DefaultImage}
                          key={user.userId}
                          userDetails={user.user}
                          index={index}
                        />
                      ))}
                  </div>
                  {hubUsers.length === 0 && (
                    <div className="d-flex align-items-center flex-column justify-content-center py-5">
                      <img src={noUsersImage} width="70" alt="no users image" />
                      <h5 className="mt-2">
                        No users in this hub at the moment
                      </h5>
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default HubUsers;
