import React, { useState, Fragment, useEffect, useContext } from "react";
import Layout from "src/components/Layout/Layout";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import { Row, Col, PopoverBody, Popover, Input } from "reactstrap";
import "./Discover.scss";
import TefStories from "./components/TefStory/TefStories";
import TefStoriesPartial from "./components/TefStory/TefStoriesPartial";
import TrendingArticles from "./components/Articles/TrendingArticles";
import ViewedVideos from "./components/ViewedVideos";
// import AllArticles from "./components/Articles/AllArticles";
import Videos from "./components/Videos/Videos";
import Groups from "./components/Groups/Group";
import TefMasterclass from "./components/TefMasterClass/TefMasterclass";
import Articles from "./components/Articles/Articles";
import People from "./components/People/People";
import TefNews from "./components/TefNews/News";
import TefLearningResources from "./components/TefLearningResources/TefLearningResources";
import { Context } from "src/store";
import {
  getAllCategories,
  getAllTags,
  searchDiscover,
} from "src/requests/DiscoverRequest";
import { FiMoreHorizontal } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import SearchPage from "./components/SearchPage/SearchPage";
const Discover = () => {
  const [tabName, setTabName] = useState("All");
  const [popOverOpen, setPopoverOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const { dispatch } = useContext(Context);
  const history = useHistory();

  const handleClick = (event) => {
    setSearchString("");
    setTabName(event.currentTarget.textContent);
  };
  const handleClickSeeAll = (str) => {
    setTabName(str);
  };
  useEffect(() => {
    getAllCategories(dispatch);
    getAllTags(dispatch);
  }, []);

  const _searchDiscover = async (str) => {
    if (str !== "") {
      const searchResult = await searchDiscover(str);
      const {
        data: { data },
      } = searchResult.data;
      data ? setSearchResult(data) : setSearchResult([]);
    }
  };
  const handleSearchDiscover = (e) => {
    setSearchString(e.target.value);
    _searchDiscover(e.target.value);
  };
  const handleMoreOption = () => setPopoverOpen(!popOverOpen);
  return (
    <Layout SideContent={<SidebarRight />}>
      <div className="DiscoverHead">
        <h1 className="DiscoverPageTitle">Discover</h1>
        <div className="SearchDiscover">
          <Input
            placeholder="Search Discover"
            name="searchDiscover"
            value={searchString}
            onChange={(e) => handleSearchDiscover(e)}
          />
          <span className="SearchIcon">
            <FaSearch />
          </span>
        </div>
      </div>
      <div className="ml-2">
        <Row>
          <Col
            md={12}
            style={{
              padding: "0",
            }}
          >
            <div className="discover-menu d-flex justify-content-between">
              <div
                onClick={handleClick}
                className={tabName === "All" ? "active" : null}
              >
                All
              </div>
              <div
                onClick={handleClick}
                className={tabName === "Articles" ? "active" : null}
              >
                Articles
              </div>
              <div
                onClick={handleClick}
                className={tabName === "TEF MasterClass" ? "active" : null}
              >
                TEF MasterClass
              </div>
              <div
                onClick={handleClick}
                className={
                  tabName === "TEF Learning Resources" ? "active" : null
                }
              >
                TEF Learning Resources
              </div>
              <div
                onClick={handleClick}
                className={tabName === "In the News" ? "active" : null}
              >
                In the News
              </div>
              <div
                onClick={handleClick}
                className={tabName === "TEF Stories" ? "active" : null}
              >
                TEF Stories
              </div>
              <div id="moreOptions">
                <FiMoreHorizontal />
              </div>
              <Popover
                trigger="legacy"
                placement="bottom"
                target="moreOptions"
                toggle={handleMoreOption}
                isOpen={popOverOpen}
              >
                <PopoverBody
                  style={{
                    width: "10rem",
                    padding: "0",
                  }}
                >
                  <div
                    className="MoreTopOptions"
                    onClick={() => history.push("/discover/bookmarks")}
                  >
                    Bookmarks
                  </div>
                </PopoverBody>
              </Popover>
            </div>
          </Col>
          {/* <Col md={3}>
            <div className="sort-dropdown">
              <span className="pr-2">Sort by:</span>
              <select className="text-dark select-dropdown w-50">
                <option value="latest">Latest</option>
              </select>
            </div>
          </Col> */}
        </Row>
        {searchString.trim() !== "" ? (
          <SearchPage list={searchResult} />
        ) : (
          <>
            <Row
              style={{
                padding: "0",
              }}
            >
              <Col
                md={9}
                className="MainDiscoverHomeColumn"
                style={{
                  padding: "0",
                }}
              >
                {tabName === "All" && (
                  <Fragment>
                    <Articles
                      handleSeeAll={handleClickSeeAll}
                      numberToReturn={4}
                      showMore
                    />
                    <TefStories handleSeeAll={handleClickSeeAll} />
                    <Groups />
                    <TefMasterclass
                      numberToReturn={4}
                      handleSeeAll={handleClickSeeAll}
                    />

                    <TefNews
                      numberToReturn={4}
                      handleSeeAll={handleClickSeeAll}
                    />
                    <TefLearningResources
                      numberToReturn={4}
                      handleSeeAll={handleClickSeeAll}
                    />
                    <People />
                    <Videos />
                  </Fragment>
                )}
                {tabName === "Articles" && (
                  <Articles tabName={tabName} filterOptions />
                )}
                {tabName === "TEF MasterClass" && (
                  <TefMasterclass filterOptions />
                )}
                {tabName === "TEF Stories" && (
                  <TefStoriesPartial tabName={tabName} filterOptions />
                )}
                {tabName === "In the News" && <TefNews filterOptions />}
                {tabName === "TEF Learning Resources" && (
                  <TefLearningResources filterOptions />
                )}
              </Col>
              <Col
                md={3}
                style={{
                  padding: "0",
                }}
              >
                <TrendingArticles />
                <ViewedVideos />
                {/* <ArticleCard /> */}
              </Col>
            </Row>
          </>
        )}
      </div>
    </Layout>
  );
};

export default ifOnboardedThenShow(Discover);
