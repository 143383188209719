import React, { useState, useContext } from "react";
import _ from "lodash";
import "./Photos.scss";
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from "reactstrap";
import { FileViewer } from "src/components/FileViewer/FileViewer";
import { isImageFile, UploadFile } from "src/requests/fileUploadRequest";
import { addPhoto } from "src/requests/ProfileRequest";
import { Context } from "src/store";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";

const AddPhoto = ({ isOpen, toggle, setImages, photos }) => {
  const { dispatch } = useContext(Context);
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const _handleFileChange = (e) => {
    const existingFiles = [...files];
    const validefiles = isImageFile(Array.from([...e.target.files]), true);
    if (validefiles.hasError) {
      setErrors(`${validefiles.errors[0].name} is not a valid file`);
    } else {
      setErrors(null);
      existingFiles.push(...e.target.files);
      const uniqueFile = _.uniqBy(existingFiles, "name");
      setFiles(uniqueFile);
    }
  };

  const _onRemoveFile = (file) => {
    const existingFiles = [...files];
    const filtered = existingFiles.filter((f) => {
      return f.name !== file;
    });
    setFiles(filtered);
  };

  const callback = (data) => {
    if (Array.isArray(data)) {
      const imgs = [...data, ...photos];
      setImages(imgs);
    }
  };

  const onPost = () => {
    setLoading(true);
    UploadFile(dispatch, true, files)
      .then((res) => {
        const { status, files } = res;
        if (status && (status === 200 || status === 201)) {
          addPhoto(dispatch, { imageUrls: files }, callback).then(() => {
            setLoading(false);
            toggle();
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast("There was a an error uploading your photo(s) please try again.");
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} onClosed={() => setFiles([])}>
      <ModalHeader toggle={toggle}>
        {" "}
        <h5 className="add-photo-title">Add New Photo(s)</h5>
      </ModalHeader>
      <ModalBody>
        {files.length < 1 && <h6 className="add-photo-title">Add photo</h6>}

        <input
          type="file"
          className="photo-file"
          id="photo-file"
          accept="image/x-png,image/gif,image/jpeg"
          multiple
          onChange={_handleFileChange}
        />
        <FileViewer
          files={files}
          write
          onFileRemoveClick={_onRemoveFile}
          onAddImageClick={() => document.getElementById("photo-file").click()}
        />
        {errors && (
          <span style={{ color: "red", fontSize: "12px" }}>{errors}</span>
        )}

        <br />
        <div style={{ fontSize: "13px", color: "var(--helper-text-color)" }}>
          {" "}
          valid format:jpg|jpeg|png
        </div>
      </ModalBody>
      <ModalFooter className="app-photo-btn-container">
        <Button
          color="default"
          // className="app-photo-btn-container"
          onClick={toggle}
        >
          Cancel
        </Button>
        <div>
          <Button
            outline
            onClick={() => document.getElementById("photo-file").click()}
            style={{ marginRight: "10px" }}
          >
            Choose Photo(s)
          </Button>
          <>
            <Button onClick={onPost} disabled={files.length < 1}>
              <span style={{ display: "flex" }}>
                Post{" "}
                {loading && (
                  <span style={{ marginLeft: "10px" }}>
                    {" "}
                    <Loader height={15} width={15} type="Oval" color="white" />
                  </span>
                )}
              </span>
            </Button>
          </>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AddPhoto;
