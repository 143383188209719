import { get_user_info_from_token } from "./someTokenMenthods";

const isAlumniUser = () => {
  const {
    user: { role },
  } = get_user_info_from_token();

  return role.includes("alumni");
};

export default isAlumniUser;
