import React from "react";
// import { Row, Col } from "reactstrap";
import "./People.scss";
// import { people } from "./PeopleList";
import Title from "src/modules/Discover/components/Title/Title";
// import { FaEllipsisV } from "react-icons/fa";
// import { BsChatDots } from "react-icons/bs";
// import { useCurrentUser } from "src/utils/Services/currentUser";
// import { Context } from "src/store";
// import { getSuggestions } from "src/requests/ConnectionRequest";
import { useHistory } from "react-router-dom";
import Suggestions from "src/modules/Connections/Suggestions/Suggestions";
// import Avatars from "src/components/Avatar/Avatar";

const People = () => {
  // const [connections, setConnections] = useState([]);

  // const user = useCurrentUser().userId;
  // const { dispatch } = useContext(Context);
  const history = useHistory();
  // useEffect(() => {
  //   _getSuggestions();
  // }, []);

  const handleShowMoreConnection = () => {
    history.push("/connections");
  };
  // const _getSuggestions = () => {
  //   getSuggestions(dispatch, user, 1).then((res) => {
  //     if (res.status === 200) {
  //       const { data } = res.data.data;
  //       setConnections(data);
  //     }
  //   });
  // };
  return (
    <div className="mt-3">
      <Title name="People" nameLength="4" />
      <div style={{ marginTop: "-1.5rem" }}>
        <Suggestions
          showMoreClick={handleShowMoreConnection}
          sliceContentCount={4}
          sliceContent={true}
        />
      </div>

      {/* <div className="video-list-item p-3 mt-2">
        <Row>
          {connections &&
            connections.map((item, index) => (
              <Col md={6} key={index}>
                <div className="mb-2 p-2 people-list">
                  <Row className="">
                    <Col md={5}>
                      <div>
                        <Avatars
                          dimension={40}
                          name={`${item && item.firstName} ${
                            item && item.lastName
                          }`}
                          src={item && item.imageUrl}
                          alt="avatar"
                          className="user-avartar"
                        />
                        <h1>
                          {item && item.firstName} {item && item.lastName}
                        </h1>
                        <h6>{item.username}</h6>
                      </div>
                    </Col>
                    <Col md={7}>
                      <div className="d-flex">
                        <div className="mr-2 h-25">
                          {item.status === "Connected" && (
                            <div className="text-center connected px-2">
                              {item.status}
                            </div>
                          )}
                          {item.status === "Pending" && (
                            <div className="text-center pending px-2">
                              {item.status}
                            </div>
                          )}
                          {item.status === "Connect" && (
                            <div className="text-center connect px-2">
                              {item.status}
                            </div>
                          )}
                        </div>
                        <div className="chat-icon h-25">
                          <BsChatDots />
                        </div>
                        <div style={{ color: "#919ba8" }}>
                          <FaEllipsisV />
                        </div>
                      </div>
                    </Col>
                    <p className="summary px-3">{item.summary}</p>

                    <div className="people-interests d-flex">
                      <span className="mr-2">Interests:</span>{" "}
                      {item.interests &&
                        item.interests.slice(0, 4).map((int) => (
                          <div className="interests text-center" key={int}>
                            {int}
                          </div>
                        ))}
                    </div>
                  </Row>
                </div>
              </Col>
            ))}
        </Row>
        <div
          onClick={handleShowAllConnections}
          className="mt-4 text-center w-25 show-more"
        >
          Show more
        </div>
      </div> */}
    </div>
  );
};

export default People;
