import React from "react";
import styles from "./assessment_summary.module.scss";

const Section = ({ title, children }) => {
  return (
    <section>
      <h4 className={styles.section_header}>{title}</h4>
      <section>{children}</section>
    </section>
  );
};

export default Section;
