import React, { useContext, useRef, useState } from "react";
import Avatars from "src/components/Avatar/Avatar";
import { useCurrentUser } from "src/utils/Services/currentUser";
import classes from "./CommentForm_v2.module.scss";
import { Button, Modal, ModalBody, Spinner } from "reactstrap";
import { EditorBox } from "src/components/Editor";
import { createComment } from "src/requests/CommentRequest";
import { Context } from "src/store";

const CommentForm_v2 = ({ postId, getComments }) => {
  const { firstName, lastName, imageUrl } = useCurrentUser();
  const [isModalOpen, setOpenModal] = useState(false);
  const [input, setInput] = useState("");
  const toggle = () => setOpenModal(!isModalOpen);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();
  const { dispatch } = useContext(Context);
  const onSubmit = () => {
    if (!inputRef.current.hasContent()) return;
    setLoading(true);
    const payload = {
      postId,
      content: input,
    };

    createComment(dispatch, payload)
      .then((res) => {
        setLoading(false);
        inputRef.current.clearInput();
        getComments();
        toggle();
      })
      .catch((_err) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <p className={classes.addCommentTextBtn} onClick={toggle}>
        Add Comment
      </p>

      <div className={classes.container}>
        <Modal isOpen={isModalOpen} toggle={toggle} size={"lg"}>
          <ModalBody>
            <div className={classes.container}>
              <div>
                <Avatars
                  name={`${firstName} ${lastName}`}
                  dimension={39}
                  src={imageUrl}
                />
              </div>
              <EditorBox
                ref={inputRef}
                onEditorChange={setInput}
                containerClassName={classes.inputContainer}
                useEmoji
                placeholder="Add a comment..."
                className={classes.input}
                optionsClassName={classes.inputOption}
                content={input}
              />
            </div>
            <div>
              <Button onClick={onSubmit} className={classes.shareCommentBtn}>
                Post{" "}
                {loading && (
                  <Spinner style={{ height: "15px", width: "15px" }} />
                )}
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default CommentForm_v2;
