import React, { useState, useContext } from "react";
import { Input, Label, FormGroup, FormText } from "reactstrap";
import ModalT from "src/components/Modal/Modal";
import { Context } from "src/store";
import {
  createPersonalGoal,
  getPersonalGoals,
} from "src/requests/PersonalGaolRequest";
import DatePicker from "react-datepicker";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const CreateGoalModal = ({ modal, className, buttonLabel, toggle }) => {
  const {
    user: { id },
  } = get_user_info_from_token();
  const { state, dispatch } = useContext(Context);
  const initialData = {
    title: "",
    description: "",
    dueDate: "",
    userId: "",
  };
  const [data, setData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const handleChange = (e) => {
    setIsDuplicate(_isExistingGoal(e.target.value));
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleDateChange = (date) => setData({ ...data, dueDate: date });

  const _isExistingGoal = (value) => {
    if (
      state.PersonalGoal &&
      state.PersonalGoal.goals &&
      state.PersonalGoal.goals.length > 0
    ) {
      const texts = state.PersonalGoal.goals.filter((g) => {
        return (
          g.title
            .replace(/[ ,.?]/g, "")
            .toLowerCase()
            .normalize() ===
          value
            .replace(/[ ,.?]/g, "")
            .toLowerCase()
            .normalize()
        );
      });

      return texts.length > 0;
    }
    return false;
  };

  const disableBtn = () => {
    return isDuplicate;
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const payload = {
      ...data,
      userId: id,
    };

    createPersonalGoal(dispatch, payload).then((res) => {
      setIsLoading(false);
      if (res.status === 201) {
        setData({
          ...data,
          title: "",
          description: "",
          dueDate: "",
        });
        getPersonalGoals(dispatch, id);
        toggle();
      }
    });
  };

  return (
    <ModalT
      title={"Create Personal Goal"}
      modal={modal}
      className={className}
      buttonLabel={buttonLabel}
      toggle={toggle}
      onSubmit={handleSubmit}
      loadingCreate={isLoading}
      disable={disableBtn()}
    >
      <FormGroup>
        <Label htmlFor="goalTitle">Title of your goal - Required</Label>
        <Input
          type="text"
          name="title"
          id="goalTitle"
          value={data.title}
          onChange={handleChange}
          placeholder="A short title of what you want to achieve"
          required
        />
        {isDuplicate && (
          <FormText color="secondary">
            You have already created a goal with this title
          </FormText>
        )}
      </FormGroup>

      <FormGroup>
        <Label htmlFor="goalDescription">Description - Optional</Label>
        <Input
          type="textarea"
          name="description"
          id="goalDescription"
          value={data.description}
          onChange={handleChange}
          placeholder="Give a brief overview of what you are trying to achieve "
          rows={5}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="goalDate">Due Date - Required</Label>
        <div>
          <DatePicker
            placeholderText="YYYY-MM-DD"
            dateFormat="yyyy-MM-dd"
            id="goalDate"
            minDate={new Date()}
            selected={data.dueDate}
            onChange={handleDateChange}
            required={true}
          />
        </div>
      </FormGroup>
    </ModalT>
  );
};

export default CreateGoalModal;
