import React from "react";
import { Button } from "reactstrap";
import { FiClock } from "react-icons/fi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import moment from "moment";

const EventContent = (item) => {
  const history = useHistory();

  const HumanDate = (startDate) => {
    return moment(startDate).format("DD MMMM, YYYY");
  };
  const truncate = (str, no_words) => {
    str = str.replace(/<\/?[^>]+(>|$)/g, "");

    return str.split(" ").splice(0, no_words).join(" ");
  };
  return (
    <div className="alumni-event__slider--box">
      <Button
        className="alumni-event-imgbox__btn--featured"
        onClick={() => history.push(`/alumni-events-detail/${item.id}`)}
      >
        Featured
      </Button>
      <div className="alumni-event-imgbox__title">
        <p>{item.title}</p>
        {/* Knowlege Through Print: an African State Perspective */}
      </div>
      <div className="alumni-event-imgbox__discription mobile-event-desc">
        <p className="event-short">{truncate(item.description, 15)}</p>
      </div>
      <div className="alumni-event-imgbox__metaData">
        <div className="meta-info">
          <div className="event-date">
            <span className="alumni-event-imgbox__icon">
              <FiClock size={15} />
            </span>
            <span className="deadline-fund opport-deadline-fund">
              Deadline: {HumanDate(item.startDate)}
            </span>
          </div>
          <div className="event-date">
            <span className="alumni-event-imgbox__icon">
              <FaMapMarkerAlt size={15} />
            </span>
            <span className="event-locate ">
              {item.isOnline ? "Online(Virtual)" : "Offline"}
            </span>
          </div>
        </div>

        <Button
          onClick={() => history.push(`/alumni-events-detail/${item.id}`)}
          className="alumni-event--cta-slide view-opportunity"
        >
          View Detail
        </Button>
      </div>
    </div>
  );
};

export default EventContent;
