// import axios from "src/utils/axios";
import axios from "axios";
import { toast } from "react-toastify";
import { asyncActions } from "src/utils/AsyncUtils";
import { OpportunityURL } from "src/constants";
import {
  GET_OPPORTUNITY,
  DELETE_OPPORTUNITY,
  GET_OPPORTUNITY_CATEGORIES,
  GET_OPPORTUNITY_TYPES,
  CREATE_OPPORTUNITY,
  GET_OPPORTUNITIES,
  FLAG_OPPORTUNITY,
  GET_RELATED_OPPORTUNITIES,
  GET_TOP_OPPORTUNITIES,
  TOGGLE_OPPORTUNITY_REMINDER,
  GET_OPPORTUNITY_COMMENTS,
  POST_OPPORTUNITY_COMMENT,
  LIKE_OPPORTUNITY_COMMENT,
  REPLY_OPPORTUNITY_COMMENT,
  GET_OPPORTUNITY_COMMENT_RESPONSE,
  GET_MY_OPPORTUNITES,
  EDIT_OPPORTUNITY,
  CANCEL_OPPORTUNITY_REMINDER,
  DELETE_OPPORTUNITY_COMMENT,
  INCREASE_OPPORTUNITY_VIEW,
  INCREASE_OPPORTUNITY_POPULARITY,
} from "src/actions";

export const getOpportunity = (dispatch, id) => {
  dispatch(asyncActions(GET_OPPORTUNITY).loading(true));
  return axios({
    method: "get",
    url: `${OpportunityURL.GET_OPPORTUNITY_URL}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_OPPORTUNITY).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_OPPORTUNITY).success(data));
        dispatch(asyncActions(GET_OPPORTUNITY).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_OPPORTUNITY).loading(false));
      dispatch(asyncActions(GET_OPPORTUNITY).failure(true, error.response));
      return error.response;
    });
};

export const deleteOpportunity = (dispatch, id, history) => {
  dispatch(asyncActions(DELETE_OPPORTUNITY).loading(true));
  return axios({
    method: "delete",
    url: `${OpportunityURL.DELETE_OPPORTUNITY_URL}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(DELETE_OPPORTUNITY).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(DELETE_OPPORTUNITY).loading(false));
        toast("Opportunity Deleted Successfully ✅");
        setTimeout(() => {
          history.replace("/opportunities", {});
        }, 1000);
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(DELETE_OPPORTUNITY).loading(false));
      dispatch(asyncActions(DELETE_OPPORTUNITY).failure(true, error.response));
      toast("An error occured, Please try again");
      return error.response;
    });
};

export const flagOpportunity = (dispatch, id, reason) => {
  dispatch(asyncActions(FLAG_OPPORTUNITY).loading(true));
  return axios({
    method: "POST",
    url: `${OpportunityURL.FLAG_OPPORTUNITY_URL}/${id}?reason=${reason}`,
  }).then((response) => {
    dispatch(asyncActions(FLAG_OPPORTUNITY).loading(false));
    if (response.status === 200) {
      const { data } = response.data;
      dispatch(asyncActions(FLAG_OPPORTUNITY).success(data));
      dispatch(asyncActions(FLAG_OPPORTUNITY).loading(false));
      toast("Opportunity has been flagged Successfully ✅");
    }
  });
};

export const flagOpportunityNofification = (id, url, reason) => {
  return axios({
    method: "POST",
    url: `${OpportunityURL.FLAG_OPPORTUNITY_NOTIFICATION_URL}`,
    data: {
      opportunityId: id,
      reason,
      url,
    },
  });
};

export const getRelatedOpportunities = (
  dispatch,
  payload,
  isAlumni = false
) => {
  dispatch(asyncActions(GET_RELATED_OPPORTUNITIES).loading(true));
  const ids = payload.join("&interestIds=");

  return axios({
    method: "get",
    url: `${OpportunityURL.GET_RELATED_OPPORTUNITY_URL}/?interestIds=${ids}&isAlumni=${isAlumni}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_RELATED_OPPORTUNITIES).loading(false));
      if (response.status === 200 || response.status === 201) {
        const { data } = response.data;
        dispatch(asyncActions(GET_RELATED_OPPORTUNITIES).success(data));
        dispatch(asyncActions(GET_RELATED_OPPORTUNITIES).loading(false));
      }

      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_RELATED_OPPORTUNITIES).loading(false));
      dispatch(
        asyncActions(GET_RELATED_OPPORTUNITIES).failure(true, error.response)
      );
      return error.response;
    });
};

export const getOpportunityCategories = (dispatch) => {
  dispatch(asyncActions(GET_OPPORTUNITY_CATEGORIES).loading(true));
  return axios({
    method: "get",
    url: `${OpportunityURL.GET_OPPORTUNITY_CATEGORIES}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_OPPORTUNITY_CATEGORIES).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_OPPORTUNITY_CATEGORIES).success(data));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_OPPORTUNITY_CATEGORIES).loading(false));
      dispatch(
        asyncActions(GET_OPPORTUNITY_CATEGORIES).failure(true, error.response)
      );
      return error.response;
    });
};

export const getOpportunityTypes = (dispatch) => {
  dispatch(asyncActions(GET_OPPORTUNITY_TYPES).loading(true));
  return axios({
    method: "get",
    url: `${OpportunityURL.GET_OPPORTUNITY_TYPES}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_OPPORTUNITY_TYPES).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_OPPORTUNITY_TYPES).success(data));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_OPPORTUNITY_TYPES).loading(false));
      dispatch(
        asyncActions(GET_OPPORTUNITY_TYPES).failure(true, error.response)
      );
      return error.response;
    });
};

export const postOpportunity = (dispatch, payload, history) => {
  const tPayload = { ...payload };
  tPayload.title = payload.opportunityTitle;
  dispatch(asyncActions(CREATE_OPPORTUNITY).loading(true));
  return axios({
    method: "post",
    url: `${OpportunityURL.CREATE_OPPORTUNITY}?isAlumni=${
      payload.isAlumni || false
    }`,
    data: tPayload,
  })
    .then((response) => {
      dispatch(asyncActions(CREATE_OPPORTUNITY).loading(false));
      const cloneResponse = { ...response };
      if (response.status === 200 || response.status === 201) {
        const { data } = response.data;
        console.log("service", response.data);

        // dispatch(asyncActions(CREATE_OPPORTUNITY).success(data));
        history.push(
          `${window.location.pathname}/success?id=${data}&title=${payload.title}`
        );
      }
      return cloneResponse;
    })
    .catch((error) => {
      dispatch(asyncActions(CREATE_OPPORTUNITY).loading(false));
      dispatch(asyncActions(CREATE_OPPORTUNITY).failure(true, error.response));
      return error.response;
    });
};

function updateQueryStringParameter(uri, key, value) {
  const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  const separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
}

export const getOpportunities = (dispatch, params) => {
  const {
    country,
    opportunityTypeId,
    categoryId,
    isAlumni,
    isFeatured,
  } = params;

  const query = {
    country,
    opportunityTypeId,
    categoryId,
    isAlumni,
    isFeatured,
  };
  Object.keys(query).forEach((key) => {
    if (!query[key]) {
      delete query[key];
    }
  });
  const filteredQuery = query;
  let queryString = "";
  const keys = Object.keys(filteredQuery);
  if (keys.length > 0) {
    keys.forEach((key) => {
      queryString = updateQueryStringParameter(
        queryString,
        key,
        filteredQuery[key]
      );
    });
  }

  dispatch(asyncActions(GET_OPPORTUNITIES).loading(true));
  return axios({
    method: "get",
    url: `${OpportunityURL.GET_OPPORTUNITY_URL}${queryString}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_OPPORTUNITIES).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_OPPORTUNITIES).success(data));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_OPPORTUNITIES).success([]));
      dispatch(asyncActions(GET_OPPORTUNITIES).loading(false));
      dispatch(asyncActions(GET_OPPORTUNITIES).failure(true, error.response));
      return error.response;
    });
};

export const getMyOpportunities = (dispatch) => {
  dispatch(asyncActions(GET_OPPORTUNITIES).loading(true));
  return axios({
    method: "get",
    url: `${OpportunityURL.GET_MY_OPPORTUNITES}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_OPPORTUNITIES).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_MY_OPPORTUNITES).success(data));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_OPPORTUNITIES).loading(false));
      dispatch(asyncActions(GET_OPPORTUNITIES).failure(true, error.response));
      return error.response;
    });
};

export const getTopOpportunities = (dispatch, length = 5) => {
  dispatch(asyncActions(GET_TOP_OPPORTUNITIES).loading(true));
  return axios({
    method: "get",
    url: `${OpportunityURL.GET_OPPORTUNITY_URL}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_TOP_OPPORTUNITIES).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        const verifiedData = data.filter((d) => d.isVerified);
        dispatch(
          asyncActions(GET_TOP_OPPORTUNITIES).success(
            verifiedData.slice(0, length)
          )
        );
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_TOP_OPPORTUNITIES).loading(false));
      dispatch(
        asyncActions(GET_TOP_OPPORTUNITIES).failure(true, error.response)
      );
      return error.response;
    });
};

export const postComment = (dispatch, payload) => {
  dispatch(asyncActions(POST_OPPORTUNITY_COMMENT).loading(true));
  return axios({
    method: "POST",
    url: `${OpportunityURL.POST_OPPORTUNITY_COMMENT_URL}`,
    data: {
      opportunityId: payload.opportunityId,
      message: payload.newComment,
      fileUploadNames: [""],
    },
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(asyncActions(POST_OPPORTUNITY_COMMENT).loading(false));
        // dispatch(asyncActions(POST_OPPORTUNITY_COMMENT).success(data));
        toast("Comment Added ✅");
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(POST_OPPORTUNITY_COMMENT).loading(false));
      dispatch(asyncActions(POST_OPPORTUNITY_COMMENT).failure(true));
      return error.response;
    });
};

export const postCommentNotification = (opportunityId, url) => {
  return axios({
    method: "POST",
    url: `${OpportunityURL.POST_OPPORTUNITY_COMMENT_NOTIFICATION_URL}`,
    data: {
      opportunityId,
      url,
    },
  });
};

export const getComments = (dispatch, id) => {
  dispatch(asyncActions(GET_OPPORTUNITY_COMMENTS).loading(true));
  return axios({
    method: "GET",
    url: `${OpportunityURL.GET_OPPORTUNITY_COMMENTS_URL}/${id}/discussions`,
  });
};

export const likeComment = (dispatch, commentId) => {
  dispatch(asyncActions(LIKE_OPPORTUNITY_COMMENT).loading(true));
  return axios({
    method: "PUT",
    url: `${OpportunityURL.LIKE_OPPORTUNITY_COMMENT_URL}/${commentId}/like-unlike`,
  });
};

export const likeCommentNotification = (dispatch, discussionId, url) => {
  return axios({
    method: "POST",
    url: `${OpportunityURL.LIKE_OPPORTUNITY_COMMENT_NOTIFICATION_URL}`,
    data: {
      discussionId,
      url,
    },
  });
};

export const postReply = (dispatch, payload) => {
  dispatch(asyncActions(REPLY_OPPORTUNITY_COMMENT).loading(true));
  return axios({
    method: "POST",
    url: `${OpportunityURL.REPLY_OPPORTUNITY_COMMENT_URL}`,
    data: {
      opportunityId: payload.opportunityId,
      discussionId: payload.commentId,
      note: payload.response,
      fileUploadNames: [""],
    },
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(asyncActions(REPLY_OPPORTUNITY_COMMENT).loading(false));
        toast("Response Added ✅");
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(REPLY_OPPORTUNITY_COMMENT).loading(false));
      dispatch(asyncActions(REPLY_OPPORTUNITY_COMMENT).failure(true));
      return error.response;
    });
};

export const getReply = (dispatch, id) => {
  dispatch(asyncActions(GET_OPPORTUNITY_COMMENT_RESPONSE).loading(true));
  return axios({
    method: "GET",
    url: `${OpportunityURL.GET_OPPORTUNITY_COMMENT_RESPONSE_URL}/${id}/replies?discussionId=${id}`,
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        const { data } = response.data;
        dispatch(asyncActions(GET_OPPORTUNITY_COMMENT_RESPONSE).loading(false));
        dispatch(asyncActions(GET_OPPORTUNITY_COMMENT_RESPONSE).success(data));
        dispatch(asyncActions(GET_OPPORTUNITY_COMMENT_RESPONSE).failure(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_OPPORTUNITY_COMMENT_RESPONSE).loading(false));
      dispatch(asyncActions(GET_OPPORTUNITY_COMMENT_RESPONSE).failure(true));
      toast("An error has occurred, please try again");
      return error.response;
    });
};
export const editOpportunity = (dispatch, payload, cb) => {
  dispatch(asyncActions(EDIT_OPPORTUNITY).loading(true));
  return axios({
    method: "patch",
    url: `${OpportunityURL.EDIT_OPPORTUNITY}`,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(EDIT_OPPORTUNITY).loading(false));
      if (response.status === 200 || response.status === 201) {
        return cb;
      }
      return response;
    })
    .catch((error) => {
      console.log(error.response);
      dispatch(asyncActions(EDIT_OPPORTUNITY).loading(false));
      dispatch(asyncActions(EDIT_OPPORTUNITY).failure(true, error.response));
      return error.response;
    });
};

export const deleteComment = (dispatch, id) => {
  dispatch(asyncActions(DELETE_OPPORTUNITY_COMMENT).loading(true));
  return axios({
    method: "delete",
    url: `${OpportunityURL.DELETE_OPPORTUNITY_COMMENT_URL}/${id}`,
  });
  // .then((response) => {
  //   dispatch(asyncActions(DELETE_OPPORTUNITY_COMMENT).loading(false));
  //   if (response.status === 200 || response.status === 201) {
  //     dispatch(asyncActions(DELETE_OPPORTUNITY_COMMENT).loading(false));
  //     toast("Comment Deleted Successfully ✅");
  //   }
  //   return response;
  // })
  // .catch((error) => {
  //   dispatch(asyncActions(DELETE_OPPORTUNITY_COMMENT).loading(false));
  //   dispatch(
  //     asyncActions(DELETE_OPPORTUNITY_COMMENT).failure(true, error.response)
  //   );
  //   toast("An error occured, Please try again");
  //   return error.response;
  // });
};

export const setReminder = (dispatch, id, reminderType) => {
  dispatch(asyncActions(TOGGLE_OPPORTUNITY_REMINDER).loading(true));
  return axios({
    method: "PUT",
    url: `${OpportunityURL.TOGGLE_OPPORTUNITY_REMINDER_URL}/${id}/${reminderType}`,
  })
    .then((response) => {
      dispatch(asyncActions(TOGGLE_OPPORTUNITY_REMINDER).loading(false));
      if (response.status === 200 || response.status === 201) {
        const { data } = response.data;
        dispatch(asyncActions(TOGGLE_OPPORTUNITY_REMINDER).loading(false));
        dispatch(asyncActions(TOGGLE_OPPORTUNITY_REMINDER).success(data));
        dispatch(asyncActions(TOGGLE_OPPORTUNITY_REMINDER).failure(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(TOGGLE_OPPORTUNITY_REMINDER).loading(false));
      dispatch(asyncActions(TOGGLE_OPPORTUNITY_REMINDER).failure(true));
      toast("An error has occurred, please try again");
      return error.response;
    });
};

export const cancelReminder = (dispatch, id) => {
  dispatch(asyncActions(CANCEL_OPPORTUNITY_REMINDER).loading(true));
  return axios({
    method: "PUT",
    url: `${OpportunityURL.CANCEL_OPPORTUNITY_REMINDER_URL}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(CANCEL_OPPORTUNITY_REMINDER).loading(false));
      if (response.status === 200 || response.status === 201) {
        const { data } = response.data;
        dispatch(asyncActions(CANCEL_OPPORTUNITY_REMINDER).loading(false));
        dispatch(asyncActions(CANCEL_OPPORTUNITY_REMINDER).success(data));
        dispatch(asyncActions(CANCEL_OPPORTUNITY_REMINDER).failure(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(CANCEL_OPPORTUNITY_REMINDER).loading(false));
      dispatch(asyncActions(CANCEL_OPPORTUNITY_REMINDER).failure(true));
      toast("An error has occurred while canceling reminder, please try again");
      return error.response;
    });
};

export const increaseView = (dispatch, id) => {
  dispatch(asyncActions(INCREASE_OPPORTUNITY_VIEW).loading(true));
  return axios({
    method: "POST",
    url: `${OpportunityURL.INCREASE_OPPORTUNITY_VIEW_URL}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(INCREASE_OPPORTUNITY_VIEW).loading(false));
      if (response.status === 200 || response.status === 201) {
        dispatch(asyncActions(INCREASE_OPPORTUNITY_VIEW).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(INCREASE_OPPORTUNITY_VIEW).loading(false));
      dispatch(
        asyncActions(INCREASE_OPPORTUNITY_VIEW).failure(true, error.response)
      );
      return error.response;
    });
};

export const increasePopularity = (dispatch, id) => {
  dispatch(asyncActions(INCREASE_OPPORTUNITY_POPULARITY).loading(true));
  return axios({
    method: "POST",
    url: `${OpportunityURL.INCREASE_OPPORTUNITY_POPULARITY_URL}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(INCREASE_OPPORTUNITY_POPULARITY).loading(false));
      if (response.status === 200 || response.status === 201) {
        dispatch(asyncActions(INCREASE_OPPORTUNITY_POPULARITY).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(INCREASE_OPPORTUNITY_POPULARITY).loading(false));
      dispatch(
        asyncActions(INCREASE_OPPORTUNITY_POPULARITY).failure(
          true,
          error.response
        )
      );
      return error.response;
    });
};

export const getCommenterDetails = (id) => {
  return axios({
    method: "POST",
    url: `${OpportunityURL.GET_COMMNENTER_DETAILS}`,
    data: [...id],
  });
};
export const getAllFeaturedOpportunities = ({ isAlumni, isFeatured }) => {
  return axios({
    method: "get",
    url: `${OpportunityURL.GET_OPPORTUNITY_URL}?isAlumni=${isAlumni}&isFeatured=${isFeatured}`,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
