/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FaCaretDown } from "react-icons/fa";
import "./Goal.scss";
import { _getStatusColor } from "src/modules/profile/components/GoalDetails/GoalDetails";
import { IoIosTrash } from "react-icons/io";
import EditGoalModal from "src/modules/profile/components/EditGoalModal/EditGoalModal";
// import {
//   GoalUpdateCard,
//   UpdateGoalStatus,
//   GoalMoreItem,
// } from "./goalUpdateCard";
import { Context } from "src/store";
// import { ProfileUrl } from "src/constants";
import { _truncateText } from "src/modules/profile/components/helper";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { useCurrentUser } from "src/utils/Services/currentUser";
import moment from "moment";
import { UncontrolledTooltip } from "reactstrap";

const Goal = ({
  title,
  status,
  id,
  goal,
  dueDate,
  description,
  reloadGoals,
}) => {
  const user = get_user_info_from_token(null, null, true).user;
  const { state } = useContext(Context);
  const [showGoalStatusCard, setGoalStatusCard] = useState(false);
  const [showGoalMoreCard, setShowGoalMoreCard] = useState(false);
  const [loadingStatusUpdate, setLoadingStatusUpdate] = useState(false);
  const [deleteGoalLoading, setDeleteGoalLoading] = useState(false);
  const [userImage, setUserImage] = useState("");
  const [userName, setUserName] = useState("");
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  const currentUser = useCurrentUser();

  const isMentor = () => {
    // eslint-disable-next-line no-prototype-builtins
    return user.hasOwnProperty("tef-mentor");
  };

  const history = useHistory();
  const viewGoal = () => {
    // const path = `/community/goals/${id}`;
    // history.push(path);
    toggleModal();
  };

  useEffect(() => {
    setLoadingStatusUpdate(state.Goals.requestingChangeGoalStatus);
  }, [state.Goals.requestingChangeGoalStatus]);

  useEffect(() => {
    setDeleteGoalLoading(state.Goals.requestingGoalDelete);
  }, [state.Goals.requestingGoalDelete]);

  const _onRecordUpdate = (data) => {
    reloadGoals();
  };

  return (
    <div
      className="g-card-c"
      style={{ paddingLeft: "20px", paddingRight: "20px" }}
    >
      <div className="g-card-c__head">
        <div
          className={`g-card-c__head--bar stat ${_getStatusColor(status)}`}
          onClick={() => {
            setGoalStatusCard(!showGoalStatusCard);
            setShowGoalMoreCard(false);
          }}
        >
          {status}

          {isMentor() && (
            <span>
              <FaCaretDown color="#fff" />
            </span>
          )}
        </div>
        {/* <span className="g-card-c__head--bar prog">TEF PROGRAM</span> */}
        {/* {createdBy === user.id && (
          <span
            style={{
              marginLeft: "5px",
              alignSelf: "center",
              cursor: "pointer",
            }}
          >
            <IoIosMore
              // color="#ccd3da"
              onClick={() => {
                setGoalStatusCard(false);
                setShowGoalMoreCard(!showGoalMoreCard);
              }}
            />
          </span>
        )} */}

        {/* <GoalUpdateCard show={showGoalMoreCard}>
          <GoalMoreItem
            setShowCard={setShowGoalMoreCard}
            goalId={id}
            loading={deleteGoalLoading}
            toggle={toggleModal}
          />
        </GoalUpdateCard> */}
      </div>
      <div className="g-card-c__title" onClick={viewGoal}>
        <h1 id="title" className="title_of_goal">
          {_truncateText(title, 25)}
        </h1>
      </div>
      <UncontrolledTooltip target="title">{title}</UncontrolledTooltip>
      <div onClick={viewGoal}>
        {/* <span className="due-text">Description</span> */}
        <p className="due-text">{_truncateText(description, 55)}</p>
      </div>
      <div className="due_and_delete">
        <div>
          <span className="">
            <span>
              <span className="due-text">Due Date</span>
              <div className="due">{moment(dueDate).fromNow()}</div>
            </span>
          </span>
        </div>

        {/* <div>
          <span>
            <IoIosTrash size={15} color="#92929d" />{" "}
            <span>{discussionCount}</span>
          </span>
        </div> */}

        {/* <span>
          {userName && isMentor() ? (
            <Avatar
              dimension="31px"
              name={userName}
              src={userImage}
              showBadge
            />
          ) : (
            <Avatar dimension="31px" name={userName} src={userImage} />
          )}
        </span> */}
      </div>
      {goal && (
        <EditGoalModal
          modal={modal}
          toggle={toggleModal}
          goal={goal}
          onRecordUpdate={_onRecordUpdate}
        />
      )}
    </div>
  );
};

Goal.propTypes = {};

export default Goal;
