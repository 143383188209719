export const opportunityObj = {
  opportunityTitle: "",
  shortSummary: "",
  country: "",
  fullDescription: "",
  totalPrice: 0,
  eligibilityRequirement: "",
  relatedTopicIds: [],
  deadline: "",
  categoryId: "",
  opportunityTypeId: "",
  opportunityLink: "",
  contactEmail: "",
  benefits: "",
  officialWebsite: "",
  fileName: "",
};

export const opportunityValidationErrors = {
  opportunityTitle: null,
  shortSummary: null,
  country: null,
  fullDescription: null,
  totalPrice: null,
  eligibilityRequirement: null,
  relatedTopicIds: null,
  deadline: null,
  categoryId: null,
  opportunityTypeId: null,
  opportunityLink: null,
  contactEmail: null,
  benefits: null,
  officialWebsite: null,
  fileName: null,
};
