import React, { useEffect } from "react";
import classes from "./Register_v2.module.scss";
import SocialSignupButtons from "src/Auth/components/SocialSignupButton/SocialSignupButtons";
import tefLogo from "src/assets/img/logo/logo_new.webp";
import { Link } from "react-router-dom";
import RegisterForm_v2 from "./RegisterForm/RegisterForm_v2";
import { useCarousel } from "src/utils/useCarousel";

import img1 from "src/assets/img/onbording/mentor.webp";
import img2 from "src/assets/img/bg/female-entrepreneur_1.webp";
import img3 from "src/assets/img/bg/male-entrepreneur.jpg";

import tefLogo_mobile from "src/assets/img/logo/logo_new_white.svg";
import { CarouselContent } from "src/Auth/Login/loginv2";

const slides = [
  {
    image: img1,
    title: "For Growing your business, we made an amazing, unique opportunity",
    // paragraph:
    // "For Growing your business, we made an amazing unique, unique opportunity",
  },
  {
    image: img2,
    title: "Access new markets",
    paragraph:
      "Gain access to new markets with over 1,000,000 users across all 54 African countries on our platform",
  },
  {
    image: img3,
    title: "Business Management Trainings",
    paragraph:
      "Undergo world-class business management training to equip you with relevant knowledge to building an impactful and profitable business",
  },
];

const Register_v2 = () => {
  const { currentIndex, snapToSlide } = useCarousel(slides.length, 3000);

  const _handleScoll = () => {
    const c_ss = document.getElementById("c_ss");
    const l_ss = document.getElementById("l_ss");
    if (c_ss) {
      const distanceTop_c_ss = c_ss.getBoundingClientRect().top;
      if (distanceTop_c_ss <= -443.859375) {
        l_ss.style.display = "none";
      } else {
        l_ss.style.display = "block";
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", _handleScoll);
    return () => window.addEventListener("scroll", _handleScoll);
  }, []);

  return (
    <div className={classes.login_container}>
      <div className={classes.formSection}>
        <div className={classes.logoContainter}>
          <Link to="/">
            <img src={tefLogo} alt="tef_logo" className={classes.tef_logo} />
          </Link>
        </div>

        <div className={classes.logoContainter_mobile} id="l_ss">
          <Link to="/">
            <img
              src={tefLogo_mobile}
              alt="tef_logo"
              className={classes.tef_logo_mobile}
            />
          </Link>
        </div>

        <div className={classes.carouselSS} id="c_ss">
          <CarouselContent
            slide={slides[currentIndex]}
            slides={slides}
            active={currentIndex}
            snapToSlide={snapToSlide}
          />
        </div>

        <div className={classes.formContainer}>
          <h2 className={classes.logintitle}>Create your Account</h2>
          <p className={classes.newUser}>
            Have an account already?{" "}
            <Link to={`/login`} className={classes.signupHere}>
              {" "}
              Log in
            </Link>
          </p>
          <RegisterForm_v2 />
          <SocialSignupButtons />
        </div>
      </div>

      {slides.map((slide, idx) => {
        if (currentIndex === idx) {
          return (
            <div
              key={idx}
              className={`${classes.carouselContainer} ${classes.fade}`}
              style={{ backgroundImage: `url(${slide.image})` }}
            >
              <div className={classes.carouselBS}>
                <CarouselContent
                  slide={slide}
                  slides={slides}
                  active={currentIndex}
                  snapToSlide={snapToSlide}
                />
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default Register_v2;
