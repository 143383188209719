/* eslint-disable no-prototype-builtins */
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import queryString from "query-string";
import {
  FETCH_USER_MENTORS,
  FETCH_MENTOR_FAQ,
  GET_REQUESTED_MENTORS,
  CONNECT,
  ACCEPT_REQUESTS,
  FETCH_ENTREPRENEURS,
  GET_CONNECTIONS,
  REJECT_REQUESTS,
  GET_SURVEY_QUESTIONS,
  SUBMIT_SURVEY_ANSWERS,
  REMOVE_MENTORSHIP_CONNECTION,
  GET_WEEKLY_SURVEY_QUESTIONS,
  CREATE_APPOINTMENT,
  GET_APPOINTMENTS,
  ACCEPT_INVITATION,
  DECLINE_INVITATION,
  DELETE_INVITATION,
  GET_TERMS_AND_CONDITION,
  GET_PROGRAMME_DETAILS,
  AGREE_TO_TERMS,
  // AGREE_TO_PARTICIPATE,
  // HAS_AGREED_TO_PARTICIPATE,
} from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import { MentorURL } from "src/constants";
import { updateQueryStringParameter } from "src/utils/helper";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const getMentorShipGroup = () => {
  const user = get_user_info_from_token() && get_user_info_from_token().user;
  let mentorshipGroup;
  if (user)
    mentorshipGroup =
      user && user.hasOwnProperty("tef-mentor")
        ? user["tef-mentor"]
        : user.hasOwnProperty("tef-mentee")
        ? user["tef-mentee"]
        : null;

  return mentorshipGroup;
};

export const getMentorshipProfiles = (dispatch, isMentor, payload) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  let params = {
    type: isMentor ? "tef-mentee" : "tef-mentor",
    MaxItem: 9,
    programmeEventId: mentorshipGroup[1],
  };
  Object.keys(payload).forEach((key) => {
    if (payload[key]) {
      if (key === "yearsOfExperience") {
        const range = payload[key].split("-");
        const max = Number(range[1]);
        const min = Number(range[0]);
        params = {
          ...params,
          MaxYearsOfExperience: max,
          MinYearsOfExperience: min,
        };
      } else {
        params = { ...params, [key]: payload[key] };
      }
    }
  });

  let url = `${MentorURL.FETCH_MENTORS}`;
  Object.keys(params).forEach((key) => {
    url = updateQueryStringParameter(url, key, params[key]);
  });

  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  // axios.defaults.paramsSerializer = function (params) {
  //   return queryString.stringify(params, { indices: false });
  // };

  return axios({ url, method: "get" })
    .then((response) => {
      if (response.status === 200) {
        // dispatch(asyncActions(FETCH_USER_MENTORS).success(response.data.data));
        // dispatch(asyncActions(FETCH_USER_MENTORS).loading(false));
        return response;
      }
      return response;
    })
    .catch((error) =>
      dispatch(asyncActions(FETCH_USER_MENTORS).failure(true, error))
    );
};

export const getEntrepreneurs = (dispatch) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(FETCH_ENTREPRENEURS).loading(true));
  axios
    .get(`${MentorURL.FETCH_MENTEES}&programmeEventId=${mentorshipGroup[1]}`)
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(FETCH_ENTREPRENEURS).success(response.data.data));
        dispatch(asyncActions(FETCH_ENTREPRENEURS).loading(false));
      }
    })
    .catch((error) =>
      dispatch(asyncActions(FETCH_ENTREPRENEURS).failure(true, error))
    );
};

export const getConnections = (dispatch) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(GET_CONNECTIONS).loading(true));
  return axios
    .get(`${MentorURL.GET_CONNECTIONS}`)
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(GET_CONNECTIONS).success(response.data.data));
        dispatch(asyncActions(GET_CONNECTIONS).loading(false));
      }
      return response;
    })
    .catch((error) =>
      dispatch(asyncActions(GET_CONNECTIONS).failure(true, error))
    );
};

export const getRequests = (dispatch) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(GET_REQUESTED_MENTORS).loading(true));
  axios
    .get(`${MentorURL.GET_UNCONNECTED}`)
    .then((response) => {
      if (response.status === 200) {
        dispatch(
          asyncActions(GET_REQUESTED_MENTORS).success(response.data.data)
        );
        dispatch(asyncActions(GET_REQUESTED_MENTORS).loading(false));
      }
    })
    .catch((error) =>
      dispatch(asyncActions(GET_REQUESTED_MENTORS).failure(true, error))
    );
};

export const acceptConnection = (dispatch, id) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(ACCEPT_REQUESTS).loading(true));
  return axios
    .get(`${MentorURL.ACCEPT_REQUESTS}/${id}`)
    .then((response) => {
      if (response.status === 200) {
        getConnections(dispatch).then(() => {
          toast("Connection request accepted");
        });
        dispatch(asyncActions(ACCEPT_REQUESTS).success(id));
        dispatch(asyncActions(ACCEPT_REQUESTS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(ACCEPT_REQUESTS).failure(true, error));
      toast("An error Occurred, Please try again");
    });
};

export const rejectConnection = (dispatch, id, reason) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(REJECT_REQUESTS).loading(true));
  return axios
    .patch(`${MentorURL.REJECT_REQUESTS}/${id}/${reason}`)
    .then((response) => {
      if (response.status === 200) {
        toast("Connection request rejected");
        dispatch(asyncActions(REJECT_REQUESTS).success(id));
        dispatch(asyncActions(REJECT_REQUESTS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(ACCEPT_REQUESTS).failure(true, error));
      toast("An error Occured, Please try again");
    });
};

export const connect = (dispatch, id, userDetails) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(CONNECT).loading(true));
  let url = `${MentorURL.CONNECT}/${id}`;

  const callbackUrl =
    window.location.origin +
    window.location.pathname +
    "?activeTab=connections";
  if (callbackUrl) {
    url = updateQueryStringParameter(url, "callbackUrl", callbackUrl);
  }

  return axios
    .post(url)
    .then((response) => {
      if (response.status === 200) {
        const connection = {
          requestId: response.data.data,
          namedId: id,
          createdDate: new Date().toISOString(),
          ownersRequest: true,
          otherDetails: userDetails,
        };
        dispatch(asyncActions(CONNECT).success(connection));
        dispatch(asyncActions(CONNECT).loading(false));
        toast("Connection request sent");
      }
      return response;
    })
    .catch((error) => dispatch(asyncActions(CONNECT).failure(true, error)));
};

export const getMentorFaq = (dispatch) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(FETCH_MENTOR_FAQ).loading(true));
  axios
    .get(`${MentorURL.FETCH_MENTOR_FAQ_URL}`)
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(FETCH_MENTOR_FAQ).success(response.data));
        dispatch(asyncActions(FETCH_MENTOR_FAQ).loading(false));
      }
    })
    .catch((error) =>
      dispatch(asyncActions(FETCH_MENTOR_FAQ).failure(true, error))
    );
};

export const getSurveyQuestions = (dispatch, userId) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(GET_SURVEY_QUESTIONS).loading(true));
  axios
    .get(`${MentorURL.GET_SURVEY_QUESTIONS}/${userId}/questions`)
    .then((response) => {
      if (response.status === 200) {
        dispatch(
          asyncActions(GET_SURVEY_QUESTIONS).success(response.data.data)
        );
        dispatch(asyncActions(GET_SURVEY_QUESTIONS).loading(false));
      }
    })
    .catch((error) =>
      dispatch(asyncActions(GET_SURVEY_QUESTIONS).failure(true, error))
    );
};
export const getWeeklySurveyQuestions = (dispatch, userId) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(GET_WEEKLY_SURVEY_QUESTIONS).loading(true));
  axios
    .get(`${MentorURL.GET_SURVEY_QUESTIONS}/${userId}/weekly-questions`)
    .then((response) => {
      if (response.status === 200) {
        dispatch(
          asyncActions(GET_WEEKLY_SURVEY_QUESTIONS).success(response.data.data)
        );
        dispatch(asyncActions(GET_WEEKLY_SURVEY_QUESTIONS).loading(false));
      }
    })
    .catch((error) =>
      dispatch(asyncActions(GET_WEEKLY_SURVEY_QUESTIONS).failure(true, error))
    );
};

export const submitSurveyQuestions = (dispatch, payload) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(SUBMIT_SURVEY_ANSWERS).loading(true));
  return axios({
    method: "post",
    url: `${MentorURL.SUBMIT_SURVEY_ANSWERS}/`,
    data: payload,
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(SUBMIT_SURVEY_ANSWERS).success(response.data));
        dispatch(asyncActions(SUBMIT_SURVEY_ANSWERS).loading(false));
      }

      return response;
    })
    .catch((error) =>
      dispatch(asyncActions(SUBMIT_SURVEY_ANSWERS).failure(true, error))
    );
};

export const removeConnect = (dispatch, reqId, userId, reason) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(REMOVE_MENTORSHIP_CONNECTION).loading(true));
  return axios
    .patch(
      `${MentorURL.REMOVE_MENTORSHIP_CONNECTION}/${reqId}/${userId}/${reason}`
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(asyncActions(REMOVE_MENTORSHIP_CONNECTION).success(reqId));
        dispatch(asyncActions(REMOVE_MENTORSHIP_CONNECTION).loading(false));
        toast("Connection has been removed");
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(REMOVE_MENTORSHIP_CONNECTION).failure(true, error));
      toast("There was an issue removing this connection");
      return error.response;
    });
};

export const createAppointment = (dispatch, payload) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(CREATE_APPOINTMENT).loading(true));
  return axios({
    method: "post",
    url: `${MentorURL.CREATE_APPOINTMENT}`,
    data: payload,
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(CREATE_APPOINTMENT).success(response.data));
        dispatch(asyncActions(CREATE_APPOINTMENT).loading(false));
      }

      return response;
    })
    .catch((error) =>
      dispatch(asyncActions(CREATE_APPOINTMENT).failure(true, error))
    );
};

export const getAppointments = (dispatch) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(GET_APPOINTMENTS).loading(true));
  return axios({
    method: "GET",
    url: `${MentorURL.GET_APPOINTMENTS}`,
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(GET_APPOINTMENTS).success(response.data.data));
        dispatch(asyncActions(GET_APPOINTMENTS).loading(false));
      }
      return response;
    })
    .catch((error) =>
      dispatch(asyncActions(GET_APPOINTMENTS).failure(true, error))
    );
};

export const acceptInvitation = (dispatch, id) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(ACCEPT_INVITATION).loading(true));
  return axios({
    method: "post",
    url: `${MentorURL.ACCEPT_INVITATION}`,
    data: {
      appointmentId: id,
      decision: 2,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(ACCEPT_INVITATION).success(response.data));
        dispatch(asyncActions(ACCEPT_INVITATION).loading(false));
      }
      return response;
    })
    .catch((error) =>
      dispatch(asyncActions(ACCEPT_INVITATION).failure(true, error))
    );
};

export const declineInvitation = (dispatch, id) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(DECLINE_INVITATION).loading(true));
  return axios({
    method: "post",
    url: `${MentorURL.ACCEPT_INVITATION}`,
    data: {
      appointmentId: id,
      decision: 3,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(DECLINE_INVITATION).success(response.data));
        dispatch(asyncActions(DECLINE_INVITATION).loading(false));
      }
      return response;
    })
    .catch((error) =>
      dispatch(asyncActions(DECLINE_INVITATION).failure(true, error))
    );
};

export const deleteInvitation = (dispatch, id) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(DELETE_INVITATION).loading(true));
  return axios({
    method: "delete",
    url: `${MentorURL.DELETE_INVITATION}/${id}`,
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(DELETE_INVITATION).success(response.data));
        dispatch(asyncActions(DELETE_INVITATION).loading(false));
      }
      return response;
    })
    .catch((error) =>
      dispatch(asyncActions(DELETE_INVITATION).failure(true, error))
    );
};

export const updateInvitation = (dispatch, id) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(DECLINE_INVITATION).loading(true));
  return axios({
    method: "patch",
    url: `${MentorURL.ACCEPT_INVITATION}`,
    data: {
      appointmentId: id,
      decision: 3,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(DECLINE_INVITATION).success(response.data));
        dispatch(asyncActions(DECLINE_INVITATION).loading(false));
      }
      return response;
    })
    .catch((error) =>
      dispatch(asyncActions(DECLINE_INVITATION).failure(true, error))
    );
};

export const getTermsAndCondition = (dispatch) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(GET_TERMS_AND_CONDITION).loading(true));
  return axios({
    method: "GET",
    url: `${MentorURL.GET_TERMS_AND_CONDITION_URL}`,
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(
          asyncActions(GET_TERMS_AND_CONDITION).success(response.data.data)
        );
        dispatch(asyncActions(GET_TERMS_AND_CONDITION).loading(false));
      }
      return response;
    })
    .catch((error) =>
      dispatch(asyncActions(GET_TERMS_AND_CONDITION).failure(true, error))
    );
};

export const getProgrammeDetails = (dispatch, id) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(GET_PROGRAMME_DETAILS).loading(true));
  return axios({
    method: "GET",
    url: `${MentorURL.GET_PROGRAMME_DETAILS_URL}/${id}`,
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(
          asyncActions(GET_PROGRAMME_DETAILS).success(response.data.data)
        );
        dispatch(asyncActions(GET_PROGRAMME_DETAILS).loading(false));
      }
      return response;
    })
    .catch((error) =>
      dispatch(asyncActions(GET_PROGRAMME_DETAILS).failure(true, error))
    );
};

export const agreeToTerms = (dispatch) => {
  const mentorshipGroup = getMentorShipGroup();
  if (!mentorshipGroup) return;
  axios.defaults.headers.common["mentorship-id"] = mentorshipGroup[1];
  dispatch(asyncActions(AGREE_TO_TERMS).loading(true));
  return axios({
    method: "POST",
    data: {
      agreement: true,
    },
    url: `${MentorURL.AGREE_TO_TERMS_URL}`,
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(asyncActions(AGREE_TO_TERMS).success(response.data.data));
        dispatch(asyncActions(AGREE_TO_TERMS).loading(false));
      }
      return response;
    })
    .catch((error) =>
      dispatch(asyncActions(AGREE_TO_TERMS).failure(true, error))
    );
};

export const getTrainingEventDetails = (programmeId, dispatch) => {
  const storage_name = "training_" + programmeId;
  let event;
  const storage = localStorage.getItem(storage_name);
  if (storage && storage !== "undefined") {
    event = JSON.parse(storage);
  }

  if (!storage) {
    getProgrammeDetails(dispatch, programmeId).then((res) => {
      if (res.data && res.data.data) {
        const prog = res.data.data;
        const eventDetail = prog.programmeEvents.find((event) => {
          return event.eventType.name.toLowerCase().includes("training");
        });
        localStorage.setItem(storage_name, JSON.stringify(eventDetail));
        event = eventDetail;
        window.location.reload();
      }
    });
  }
  return event;
};
