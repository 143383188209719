import React, { useState, useEffect, useContext } from "react";
import { Input, Label, FormGroup, Col, Row } from "reactstrap";
import BannerCard from "src/modules/mentorship/components/BannerCard/BannerCard";
import PageNav from "src/components/TabNavs/TabNavs";
import MainContent from "src/modules/mentorship/components/MainContent/MainContent";
import HowItWorks from "src/modules/mentorship/components/HowItWorks/HowItWorks";
import ModalT from "src/components/Modal/Modal";
import MultiSelect from "src/components/MultiSelect/MultiSelect";
import { createGoal } from "src/requests/GoalRequests";
import { Context } from "src/store";
// import { getInterests } from "src/requests/onboarding";

import "./Homepage.scss";
import TefScore from "src/modules/mentorship/components/TEFScore/tefScore";
import {
  getConnections,
  getSurveyQuestions,
} from "src/requests/MentorRequests";
// import { getUser } from "src/requests/AuthRequest";
import { isMentor } from "src/modules/mentorship/components/helper";
import SurveyModal from "src/modules/mentorship/components/SurveyModal/SurveyModal";
import { DatePickerInput } from "rc-datepicker";
import { useCurrentUser } from "src/utils/Services/currentUser";
import queryString from "query-string";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

export const _resolveRole = (groups) => {
  if (
    groups.includes("Tef-Mentee") ||
    groups.includes("Entrepreneur") ||
    groups.includes("tef-mentee") ||
    groups.includes("entrepreneur")
  ) {
    return "Entrepreneur";
  } else if (
    groups.includes("Tef-Mentor") ||
    groups.includes("Mentor") ||
    groups.includes("tef-mentor") ||
    groups.includes("mentor")
  ) {
    return "Mentor";
  } else {
    return "Entrepreneur";
  }
};

const HomePage = (props) => {
  const { state, dispatch } = useContext(Context);
  const [userRole, setUserRole] = useState("");
  const [survey, setSurvey] = useState(false);
  const [optionList, setOptionList] = useState([]);
  const [currentTab, setCurrentTab] = useState("My Board");
  const user = get_user_info_from_token().user;
  const titles = isMentor(user)
    ? [
        "My Board",
        "My Mentees",
        "Surveys",
        "Set Availability",
        "FAQ",
        "Calendar",
      ]
    : ["My Board", "My Mentors", "Surveys", "FAQ", "Calendar"];
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);
  const [mentorOptions, setMentorOptions] = useState([]);
  const [inputs, setInputs] = useState({
    interestIds: [],
    mentorId: "",
    menteeId: "",
  });
  const [error, setError] = useState({
    mentor: null,
    dueDate: null,
  });
  const [loadingCreate, setLoadingCreate] = useState(false);

  const currentUser = useCurrentUser();

  async function onEffect() {
    const groups =
      currentUser &&
      currentUser.userGroups &&
      currentUser.userGroups.map((org) => {
        return org.group.name;
      });
    setUserRole(_resolveRole(groups));
  }

  useEffect(() => {
    onEffect();
  }, []);

  useEffect(() => {
    getConnections(dispatch);
  }, []);

  useEffect(() => {
    const setActiveTabsFromQuery = (value) => {
      setCurrentTab(value);
    };
    const { activeTab } = queryString.parse(window.location.search);
    if (activeTab) {
      if (activeTab === "connections") {
        const tab =
          userRole && userRole === "Entrepreneur" ? "My Mentors" : "My Mentees";
        setActiveTabsFromQuery(tab);
      } else {
        setActiveTabsFromQuery(activeTab);
      }
    }
  }, [window.location.search, userRole]);

  const _populateConnections = async () => {
    const connections = state.Mentors.connected.map(async (connect) => {
      return {
        value: connect.namedId,
        label: `${connect.otherDetails.firstName} ${connect.otherDetails.lastName}`,
      };
    });
    let connects = await Promise.all(connections);
    connects = connects.filter(Boolean);
    setOptionList(connects);
  };

  useEffect(() => {
    if (state.Mentors.connected && state.Mentors.connected.length > 0) {
      _populateConnections();
      // setOptionList(connects);
    }
  }, [state.Mentors.connected]);

  // useEffect(() => {
  //   getInterests(dispatch);
  // }, []);

  useEffect(() => {
    getSurveyQuestions(dispatch, JSON.parse(localStorage.data).user.id);
  }, []);

  useEffect(() => {
    if (state.Mentors.survey && state.Mentors.survey.length !== 0) {
      setSurvey(false);
    }
  }, [state.Mentors.survey]);

  const toggle = () => setModal(!modal);

  const handleChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleDateChange = (jsDate, dateString) => {
    setInputs((inputs) => ({
      ...inputs,
      dueDate: dateString,
    }));
  };

  const clearInput = () => {
    setInputs({
      interestIds: [],
      mentorId: "",
      menteeId: "",
      title: "",
      dueDate: "",
      description: "",
    });
    setMentorOptions([]);
  };

  const handleMentorChange = (value) => {
    if (userRole === "Entrepreneur") {
      setInputs((inputs) => ({
        ...inputs,
        mentorId: value.value,
      }));
      setMentorOptions((mentorOptions) => ({
        ...mentorOptions,
        mentorIds: [value],
      }));
    }

    if (userRole === "Mentor") {
      setInputs((inputs) => ({
        ...inputs,
        menteeId: value.value,
      }));
      setMentorOptions((mentorOptions) => ({
        ...mentorOptions,
        mentorIds: [value],
      }));
    }
  };

  const handleSubmit = () => {
    const { menteeId, mentorId, dueDate } = inputs;
    if (!menteeId && !mentorId) {
      setError({ ...error, mentor: "Required" });
      return;
    }
    if (!dueDate) {
      setError({ ...error, dueDate: "Due date is Required" });
      return;
    }
    setError({ ...error, mentor: null });
    setLoadingCreate(!loadingCreate);
    createGoal(dispatch, inputs).then(() => {
      clearInput();
      clearErrors();
      setLoadingCreate(false);
      toggle();
    });
    // setCurrentTab("");
    // setTimeout(() => {
    //   setCurrentTab("My Board");
    // }, 500);
  };

  const clearErrors = () => {
    const errs = { ...error };
    Object.keys(errs).forEach((key) => {
      errs[key] = null;
    });
    setError(errs);
  };

  const updateCurrentTabUrl = (value) => {
    let activeTab = value;
    if (value === "My Mentors" || value === "My Mentees") {
      activeTab = "connections";
    }
    // setCurrentTab(value)
    props.history.push({
      pathname: window.location.pathname,
      search: `?activeTab=${activeTab}`,
    });
  };

  return (
    <React.Fragment>
      <SurveyModal
        survey={survey}
        setSurvey={setSurvey}
        questions={state.Mentors.survey && state.Mentors.survey}
      />
      <BannerCard
        setCurrentTab={updateCurrentTabUrl}
        role={userRole}
        isMentor={isMentor(get_user_info_from_token().user)}
      />
      <br />
      <PageNav
        activeTab={currentTab}
        setTab={updateCurrentTabUrl}
        titles={titles}
      />
      <br />

      <Row>
        <ModalT
          title={"Create Goal"}
          modal={modal}
          className={className}
          buttonLabel={buttonLabel}
          toggle={toggle}
          onSubmit={handleSubmit}
          loadingCreate={loadingCreate}
        >
          <FormGroup>
            <Label for="goalTitle">Title of your goal - Required</Label>
            <Input
              type="text"
              name="title"
              id="goalTitle"
              value={inputs.title}
              onChange={handleChange}
              placeholder="A short title of what you want to achieve"
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="goalDescription">Description - Optional</Label>
            <Input
              type="textarea"
              name="description"
              id="goalDescription"
              value={inputs.description}
              onChange={handleChange}
              placeholder="Give a brief overview of what you are trying to achieve "
              rows={5}
            />
          </FormGroup>
          <FormGroup>
            <Label for="goalDate">Due Date - Required</Label>

            <DatePickerInput
              onChange={handleDateChange}
              name="dueDate"
              value={inputs.dueDate}
              minDate={new Date()}
              required
              readOnly
              showOnInputClick
            />
            {error.dueDate && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {error.dueDate}
              </span>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="label">
              {userRole === "Entrepreneur" ? "Attach Mentor" : "Assign Mentee"}-
              Required
            </Label>
            <MultiSelect
              onChange={handleMentorChange}
              isMulti={false}
              options={optionList}
              value={mentorOptions.mentorIds}
              closeMenuOnSelect
              onBlur={() => {
                if (!mentorOptions.mentorIds) {
                  setError({ ...error, mentor: "Required" });
                } else {
                  setError({ ...error, mentor: null });
                }
              }}
            />
            {error.mentor && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {error.mentor}
              </span>
            )}
          </FormGroup>
        </ModalT>
        <Col
          md={currentTab && currentTab.toLowerCase() !== "calendar" ? 9 : 12}
        >
          <MainContent
            role={userRole}
            currentTab={currentTab}
            toggle={toggle}
            setCurrentTab={setCurrentTab}
          />
        </Col>
        {currentTab && currentTab !== "Calendar" ? (
          <Col md={3}>
            {userRole === "Entrepreneur" && user.training && (
              <div className="score-card">
                <TefScore
                  title="TEF Training Completion Score"
                  userId={currentUser.userId}
                />
              </div>
            )}
            <div style={{ position: "relative" }}>
              <HowItWorks />
            </div>
          </Col>
        ) : null}
      </Row>
    </React.Fragment>
  );
};

HomePage.propTypes = {};

export default HomePage;
