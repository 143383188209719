import { asyncActionName } from "src/utils/AsyncUtils";
import {
  VIEW_ALL_POSTS,
  CREATE_POST,
  GET_POST,
  DELETE_POST,
  CREATE_QUESTION,
  SHARE_POST,
  PLACE_VOTE,
  GET_LIKE_USERS,
  ADD_BOOKMARK,
  GET_ALL_BOOKMARKS,
} from "src/actions";

import { initialState } from "src/store";

const PostReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case asyncActionName(VIEW_ALL_POSTS).loading:
      return { ...state, loading: payload };
    case asyncActionName(VIEW_ALL_POSTS).success:
      return {
        ...state,
        posts: payload.data,
        pageDetails: payload.page,
        success: true,
      };
    case asyncActionName(VIEW_ALL_POSTS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(CREATE_POST).loading:
      return { ...state, loading: payload };
    case asyncActionName(CREATE_POST).success:
      return {
        ...state,
        post: payload,
        success: true,
      };
    case asyncActionName(CREATE_POST).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(DELETE_POST).loading:
      return { ...state, loading: payload };
    case asyncActionName(DELETE_POST).success:
      return {
        ...state,
        post: payload,
        success: true,
      };
    case asyncActionName(DELETE_POST).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(CREATE_QUESTION).loading:
      return { ...state, loading: payload };
    case asyncActionName(CREATE_QUESTION).success:
      return {
        ...state,
        post: payload,
        success: true,
      };
    case asyncActionName(CREATE_QUESTION).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_POST).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_POST).success:
      return {
        ...state,
        post: payload,
        success: true,
      };
    case asyncActionName(GET_POST).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(SHARE_POST).loading:
      return { ...state, loading: payload };
    case asyncActionName(SHARE_POST).success:
      return {
        ...state,
        post: payload,
        success: true,
      };
    case asyncActionName(SHARE_POST).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(PLACE_VOTE).loading:
      return { ...state, loading: payload };
    case asyncActionName(PLACE_VOTE).success:
      return {
        ...state,
        vote: payload.data,
        success: true,
      };
    case asyncActionName(PLACE_VOTE).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_LIKE_USERS).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_LIKE_USERS).success:
      return {
        ...state,
        likeUsers: payload,
        success: true,
      };
    case asyncActionName(GET_LIKE_USERS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(ADD_BOOKMARK).loading:
      return { ...state, loading: payload };
    case asyncActionName(ADD_BOOKMARK).success:
      return {
        ...state,
        bookmark: payload,
        success: true,
      };
    case asyncActionName(ADD_BOOKMARK).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_ALL_BOOKMARKS).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_ALL_BOOKMARKS).success:
      return {
        ...state,
        bookmarks: payload,
        success: true,
      };
    case asyncActionName(GET_ALL_BOOKMARKS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    default:
      return state;
  }
};

export default PostReducer;
