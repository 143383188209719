import React, { useContext, Fragment, useState, useEffect } from "react";
import styles from "./ViewThread.module.scss";
import Layout from "src/components/Layout/Layout";
import { FiShare2 } from "react-icons/fi";
import { Context } from "src/store";
import {
  FaReply,
  FaCaretDown,
  FaRegFlag,
  FaTrash,
  FaHeart,
  FaRegHeart,
} from "react-icons/fa";
import { BsArrowLeft } from "react-icons/bs";
import {
  deleteTopic,
  fetchTopicDetails,
  fetchTopicPosts,
  likeTopic,
} from "src/requests/TopicRequest";

import { useHistory, useLocation } from "react-router-dom";
import Loader from "src/components/Loader/Loader";
import moment from "moment";
import TopicBanner from "src/assets/img/topic-banner.webp";
import ClickOutside from "react-click-outside-component";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import { ReactComponent as Email } from "src/assets/img/home/email.svg";
import { ReactComponent as Share } from "src/assets/img/home/share.svg";
import PostAndComments from "./PostAndComments";
import ReplyTopic from "./ReplyTopic";
import ReactAvatar from "react-avatar";
import FlagPost from "src/modules/Community/components/Thread/FlagPost/FlagPost";
import DOMPurify from "dompurify";
import { stripHtmlTags } from "src/utils/helper";
import Swal from "sweetalert2";
import { useCurrentUser } from "src/utils/Services/currentUser";

const ViewThread = (props) => {
  const { userId } = useCurrentUser();
  const { state, dispatch } = useContext(Context);
  const [topicDetails, setTopicDetails] = useState("");
  const [topicPosts, setTopicPosts] = useState([]);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [isLoadingPosts, setIsLoadingPosts] = useState(false);
  const [toggleReplyTopic, settoggleReplyTopic] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [topicLiked, setTopicLiked] = useState(false);
  const location = useLocation();
  const topicId = location.pathname.split("/")[3];
  const [toggleShareOptions, setToggleShareOptions] = useState(false);
  const handleTopicReplyToggle = () => settoggleReplyTopic(!toggleReplyTopic);
  const handleShareToggle = () => setToggleShareOptions(!toggleShareOptions);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const toggle = () => {
    setShowModal(!showModal);
  };

  const getTopicDetails = async () => {
    setIsLoadingDetails(true);
    const res = await fetchTopicDetails(dispatch, topicId);
    if (res.data.status === "success") {
      setIsLoadingDetails(false);
      setTopicDetails(res.data.data);
    }
  };

  const getTopicPosts = async () => {
    setIsLoadingPosts(true);
    const res = await fetchTopicPosts(dispatch, topicId);
    if (res.data.status === "success") {
      setIsLoadingPosts(false);
      setTopicPosts(res.data.data.data);
    }
  };

  const handleLikeTopic = async () => {
    const payload = {
      userId,
      topicId,
    };
    const res = await likeTopic(dispatch, payload);
    if (res.data.status === "success") {
      setTopicLiked(true);
    }
  };

  useEffect(() => {
    getTopicDetails();
    getTopicPosts();
  }, []);

  const refreshDetailsAndPosts = () => {
    getTopicDetails();
    getTopicPosts();
  };

  const handleBack = () => {
    props.history.goBack();
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.value) {
        deleteTopic(dispatch, topicId).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              title: "Deleted!",
              text: "successfully deleted",
              icon: "success",
              confirmButtonColor: "red",
            });
            history.goBack();
          } else {
            Swal.fire({
              title: "Cancelled",
              text: "Could not delete, please try again",
              icon: "error",
              confirmButtonColor: "red",
            });
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Cancelled",
          text: "Delete cancelled",
          icon: "error",
          confirmButtonColor: "red",
        });
      }
    });
  };

  return (
    <Layout hideRight={true}>
      <p
        style={{ cursor: "pointer" }}
        className="mt-5 cursor-pointer text-black-50"
        onClick={handleBack}
      >
        <BsArrowLeft /> Go Back
      </p>
      <div>
        <img src={TopicBanner} className="img-fluid w-100" alt="" />
      </div>
      <div className="mt-3  p-4 bg-white">
        {isLoadingDetails ? (
          <Loader color="danger" />
        ) : (
          <Fragment>
            <h2>{topicDetails.name}</h2>
            <div>
              {topicDetails.user && topicDetails.user.imageUrl !== null ? (
                <img
                  src={topicDetails.user.imageUrl}
                  className="all-members-display-pic "
                  alt="display picture"
                />
              ) : (
                <ReactAvatar
                  name={
                    topicDetails.user
                      ? topicDetails.user.firstName +
                        " " +
                        topicDetails.user.lastName
                      : "Anonymous"
                  }
                  size={35}
                  round={true}
                />
              )}
              <span className="ml-2">
                {topicDetails.user
                  ? topicDetails.user.firstName +
                    " " +
                    topicDetails.user.lastName
                  : "Anonymous"}{" "}
              </span>
            </div>

            <small>
              <div className="d-flex justify-content-between align-items-center bg-white text-black-50">
                <li className="">
                  {topicDetails.category ? topicDetails.category.name : ""}
                </li>{" "}
                <div>
                  <span className="text-black-50">
                    {moment(topicDetails.createdOn).format("MMM DD, YYYY - LT")}
                  </span>{" "}
                </div>
              </div>
            </small>

            <p className={`mt-3 ${styles.textPreWrap}`}>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(topicDetails.about),
                }}
              ></div>
            </p>
            <div className="d-flex justify-content-between mt-4">
              <div className="d-flex align-items-center">
                {`${topicDetails.postCount} Replies`} <FaCaretDown />{" "}
              </div>
              <div className="d-flex justify-content-end align-items-center ">
                <span
                  onClick={handleLikeTopic}
                  className="text-gray font-weight-bold cursor-pointer mr-3"
                >
                  {topicDetails.likeCount ? topicDetails.likeCount : 0}
                  {topicLiked ? (
                    <FaHeart style={{ color: "#cf1d10", paddingLeft: "5px" }} />
                  ) : (
                    <FaRegHeart style={{ paddingLeft: "2px" }} />
                  )}
                </span>
                <span
                  onClick={handleTopicReplyToggle}
                  className="text-gray font-weight-bold cursor-pointer mr-3"
                >
                  <FaReply /> Reply
                </span>
                <span
                  onClick={handleShareToggle}
                  className="text-gray font-weight-bold cursor-pointer mr-4"
                >
                  Share <FiShare2 />
                </span>
                <span
                  onClick={toggle}
                  className="text-gray font-weight-bold cursor-pointer mr-4"
                >
                  Flag <FaRegFlag />
                </span>
                {topicDetails && topicDetails.user.userId === userId && (
                  <span
                    onClick={handleDelete}
                    className="text-gray font-weight-bold cursor-pointer mr-4"
                  >
                    Delete <FaTrash color="red" />
                  </span>
                )}
              </div>
            </div>

            {toggleReplyTopic && (
              <div className="col-sm-11 mt-4">
                <ReplyTopic
                  id={topicId}
                  dp={state.Profile.profile.imageUrl}
                  getPosts={refreshDetailsAndPosts}
                />
              </div>
            )}
          </Fragment>
        )}
      </div>
      <ClickOutside onClickOutside={() => setToggleShareOptions(false)}>
        <div
          className={
            toggleShareOptions ? "share-options" : "share-option hide-div"
          }
        >
          <ul className="share-options__lists">
            <li>
              <Share className="icon-tab" />
              Share on my timeline
            </li>
            <li>
              <Email className="icon-tab" />
              Send as Message
            </li>
            <li>
              <TwitterShareButton
                url="tefconnect.com"
                title={topicDetails.about && stripHtmlTags(topicDetails.about)}
              >
                <TwitterIcon size={20} round={true} className="icon-tab" />
                Share via Twitter
              </TwitterShareButton>
            </li>
            <li>
              <FacebookShareButton
                url="tefconnect.com"
                quote={topicDetails.about && stripHtmlTags(topicDetails.about)}
              >
                <FacebookIcon size={20} round={true} className="icon-tab" />
                Share via Facebook
              </FacebookShareButton>
            </li>
            <li>
              <LinkedinShareButton
                url="tefconnect.com"
                source={topicDetails.about && stripHtmlTags(topicDetails.about)}
              >
                <LinkedinIcon size={20} round={true} className="icon-tab" />
                Share via LinkedIn
              </LinkedinShareButton>
            </li>
          </ul>
        </div>
      </ClickOutside>

      {showModal && (
        <FlagPost
          toggle={toggle}
          show={showModal}
          details={{
            contentId: topicId,
            contentType: "topic",
          }}
          title="Report Post"
        />
      )}

      {/* Replies */}
      <div className="px-4 py-4 mb-5 bg-white mt-2">
        {isLoadingPosts ? (
          <Loader color="danger" />
        ) : (
          <Fragment>
            {topicPosts.length === 0 ? (
              <div className="text-center">
                <i className="no-post">
                  No post at the moment
                  <br />
                  <span className="no-post__sub">
                    Be the first to post here{" "}
                    <span role="img" aria-label="joy">
                      😊
                    </span>
                  </span>
                </i>
              </div>
            ) : (
              <Fragment>
                {topicPosts.map((post) => (
                  <PostAndComments key={post.id} post={post} />
                ))}
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </Layout>
  );
};

export default ViewThread;
