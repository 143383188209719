/* eslint-disable no-unused-vars */
import React, { useEffect, useContext } from "react";
import { Card, Button, Row, Col } from "reactstrap";
import { Context } from "src/store";
import { getPersonalGoals } from "src/requests/PersonalGaolRequest";
import Goal from "src/modules/profile/components/Goal/Goal";
import Empty from "src/assets/img/mentorship-empty.png";

import "./Goals.scss";
import { useCurrentUser } from "src/utils/Services/currentUser";
const Goals = ({ toggle }) => {
  const { state, dispatch } = useContext(Context);
  const user = useCurrentUser();
  useEffect(() => {
    _getGoals();
  }, []);

  const _getGoals = () => {
    getPersonalGoals(dispatch, user.userId);
  };

  const displayGoals = () => {
    return (
      state.PersonalGoal.goals &&
      state.PersonalGoal.goals.map((goal) => (
        <Col key={goal.id} md={6}>
          <Goal
            title={goal.title}
            likeCount={goal.likeCount}
            discussionCount={goal.discussionCount}
            status={goal.status}
            id={goal.id}
            createdBy={goal.userId}
            goal={goal}
            dueDate={goal.dueDate}
            description={goal.description}
            reloadGoals={_getGoals}
          />
        </Col>
      ))
    );
  };
  return (
    // <Row>
    <Card className="goals-card">
      <Col>
        <h3 className="goals-heading">All Goals</h3>
      </Col>
      <div className="goals-container">
        <Col md={12}>
          {state.Goals.goals && state.Goals.goals.length < 1 && (
            <Col style={{ textAlign: "center" }} md={{ size: 6, offset: 3 }}>
              <img src={Empty} />
              <h3 className="goals-heading">No Goal To Show</h3>
              <Button onClick={toggle}>Create a Goal</Button>
              <br />
              <br />
            </Col>
          )}
          <Row className="goals-rows">{displayGoals()}</Row>
          <br />
        </Col>
      </div>
    </Card>
    // </Row>
  );
};

Goals.propTypes = {};

export default Goals;
