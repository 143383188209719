import axios from "src/utils/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CREATE_POST,
  VIEW_ALL_POSTS,
  DELETE_POST,
  LIKE_POST,
  UNLIKE_POST,
  GET_USER_LIKE,
  CREATE_QUESTION,
  PLACE_VOTE,
  GET_POST,
  SHARE_POST,
  GET_USER_VOTE,
  GET_LIKE_USERS,
  ADD_BOOKMARK,
  GET_ALL_BOOKMARKS,
} from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import { StudyGroupPostUrl } from "src/constants";

export const createPost = (dispatch, payload, groupId) => {
  dispatch(asyncActions(CREATE_POST).loading(true));
  return axios({
    method: "post",
    url: `${StudyGroupPostUrl.CREATE_POST_URL}/group/${groupId}`,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(CREATE_POST).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(CREATE_POST).success(response.data));
        dispatch(asyncActions(CREATE_POST).loading(false));
        toast("Post Created Successfully ✅");
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(CREATE_POST).loading(false));
      dispatch(asyncActions(CREATE_POST).failure(true, error.response));
      toast("An error occurred, Please try again ");
      return error.response;
    });
};

export const getAllPosts = (dispatch, pageNumber) => {
  dispatch(asyncActions(VIEW_ALL_POSTS).loading(true));
  return axios({
    method: "get",
    url: `${StudyGroupPostUrl.VIEW_ALL_POSTS_URL}?pageNumber=${pageNumber}&maxItem=5`,
  })
    .then((response) => {
      dispatch(asyncActions(VIEW_ALL_POSTS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(VIEW_ALL_POSTS).success(data));
        dispatch(asyncActions(VIEW_ALL_POSTS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(VIEW_ALL_POSTS).loading(false));
      dispatch(asyncActions(VIEW_ALL_POSTS).failure(true, error.response));
      return error.response;
    });
};

export const getUserPosts = (dispatch, pageNumber, userId) => {
  dispatch(asyncActions(VIEW_ALL_POSTS).loading(true));
  return axios({
    method: "get",
    url: `${StudyGroupPostUrl.VIEW_ALL_POSTS_URL}/user/${userId}?pageNumber=${pageNumber}&maxItem=10`,
  })
    .then((response) => {
      dispatch(asyncActions(VIEW_ALL_POSTS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(VIEW_ALL_POSTS).success(data));
        dispatch(asyncActions(VIEW_ALL_POSTS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(VIEW_ALL_POSTS).loading(false));
      dispatch(asyncActions(VIEW_ALL_POSTS).failure(true, error.response));
      return error.response;
    });
};

export const deletePost = (dispatch, id) => {
  dispatch(asyncActions(DELETE_POST).loading(true));
  return axios({
    method: "delete",
    url: `${StudyGroupPostUrl.DELETE_POST_URL}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(DELETE_POST).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(DELETE_POST).success(data));
        dispatch(asyncActions(DELETE_POST).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(DELETE_POST).loading(false));
      dispatch(asyncActions(DELETE_POST).failure(true, error.response));
      toast("An error occurred, Please try again ");
      return error.response;
    });
};

export const likePost = (dispatch, payload) => {
  dispatch(asyncActions(LIKE_POST).loading(true));
  return axios({
    method: "post",
    url: `${StudyGroupPostUrl.LIKE_POST_URL}/${payload}/likes`,
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(LIKE_POST).success(response.data));
        dispatch(asyncActions(LIKE_POST).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(LIKE_POST).failure(error));
      return error.response;
    });
};

export const unlikePost = (dispatch, payload) => {
  dispatch(asyncActions(UNLIKE_POST).loading(true));
  return axios({
    method: "delete",
    url: `${StudyGroupPostUrl.UNLIKE_POST_URL}/${payload}/likes`,
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(UNLIKE_POST).success(response.data));
        dispatch(asyncActions(UNLIKE_POST).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(UNLIKE_POST).failure(error));
      return error.response;
    });
};

export const getUserLike = (dispatch, payload) => {
  dispatch(asyncActions(GET_USER_LIKE).loading(true));
  return axios({
    method: "get",
    url: `${StudyGroupPostUrl.GET_USER_LIKE_URL}/${payload}/likes/user`,
  })
    .then((response) => {
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_USER_LIKE).success(data));
        dispatch(asyncActions(GET_USER_LIKE).loading(false));
        return response;
      }
    })
    .catch((error) => {
      dispatch(asyncActions(GET_USER_LIKE).failure(error));
      return error.response;
    });
};

export const createQuestion = (dispatch, payload) => {
  dispatch(asyncActions(CREATE_QUESTION).loading(true));
  return axios({
    method: "post",
    url: StudyGroupPostUrl.CREATE_QUESTION_URL,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(CREATE_QUESTION).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(CREATE_QUESTION).success(response.data));
        dispatch(asyncActions(CREATE_QUESTION).loading(false));
        toast("Post Created Successfully ✅");
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(CREATE_QUESTION).loading(false));
      dispatch(asyncActions(CREATE_QUESTION).failure(true, error.response));
      toast("An error occurred, Please try again ");
      return error.response;
    });
};

export const voteQuestion = (dispatch, payload) => {
  dispatch(asyncActions(PLACE_VOTE).loading(true));
  return axios({
    method: "post",
    url: StudyGroupPostUrl.PLACE_VOTE_URL,
    data: payload,
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(PLACE_VOTE).success(response.data));
        dispatch(asyncActions(PLACE_VOTE).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(PLACE_VOTE).failure(error));
      return error.response;
    });
};

export const getPost = (dispatch, id) => {
  dispatch(asyncActions(GET_POST).loading(true));
  return axios({
    method: "get",
    url: `${StudyGroupPostUrl.GET_POST_URL}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_POST).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_POST).success(data));
        dispatch(asyncActions(GET_POST).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_POST).loading(false));
      dispatch(asyncActions(GET_POST).failure(true, error.response));
      return error.response;
    });
};

export const getUserVote = (dispatch, payload) => {
  dispatch(asyncActions(GET_USER_VOTE).loading(true));
  return axios({
    method: "get",
    url: `${StudyGroupPostUrl.GET_USER_VOTE_URL}/${payload.postId}/options/votes`,
  })
    .then((response) => {
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_USER_VOTE).success(data));
        dispatch(asyncActions(GET_USER_VOTE).loading(false));
        return response;
      }
    })
    .catch((error) => {
      dispatch(asyncActions(GET_USER_VOTE).failure(error));
      return error.response;
    });
};

export const sharePost = (dispatch, payload) => {
  dispatch(asyncActions(SHARE_POST).loading(true));
  return axios({
    method: "post",
    url: StudyGroupPostUrl.SHARE_POST_URL,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(SHARE_POST).loading(false));
      if (response.status === 201) {
        dispatch(asyncActions(SHARE_POST).success(response.data));
        dispatch(asyncActions(SHARE_POST).loading(false));
        toast("Post Shared Successfully ✅");
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(SHARE_POST).loading(false));
      dispatch(asyncActions(SHARE_POST).failure(true, error.response));
      toast("An error occurred, Please try again ");
      return error.response;
    });
};

export const getLikeUsers = (dispatch, payload) => {
  dispatch(asyncActions(GET_LIKE_USERS).loading(true));
  return axios({
    method: "get",
    url: `${StudyGroupPostUrl.GET_LIKE_USERS_URL}/${payload}/likes`,
  })
    .then((response) => {
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_LIKE_USERS).success(data));
        dispatch(asyncActions(GET_LIKE_USERS).loading(false));
        return response;
      }
    })
    .catch((error) => {
      dispatch(asyncActions(GET_LIKE_USERS).failure(error));
      return error.response;
    });
};

export const addBookmark = (dispatch, payload) => {
  dispatch(asyncActions(CREATE_POST).loading(true));
  return axios({
    method: "post",
    url: StudyGroupPostUrl.ADD_BOOKMARK_URL,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(ADD_BOOKMARK).loading(false));
      if (response.status === 200) {
        const {
          data: { message, data },
        } = response;
        dispatch(asyncActions(ADD_BOOKMARK).success(data));
        dispatch(asyncActions(ADD_BOOKMARK).loading(false));
        toast(message);
      }
      return response;
    })
    .catch((error) => {
      const {
        data: { message },
      } = error.response;
      const fail = "An error occurred, Please try again ";
      dispatch(asyncActions(ADD_BOOKMARK).loading(false));
      dispatch(asyncActions(ADD_BOOKMARK).failure(true, error.response));
      toast.error(message || fail);
      return error.response;
    });
};

export const getAllBookmarks = (dispatch, userId) => {
  dispatch(asyncActions(GET_ALL_BOOKMARKS).loading(true));
  return axios({
    method: "get",
    url: `${StudyGroupPostUrl.GET_ALL_BOOKMARKS_URL}/user/${userId}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_ALL_BOOKMARKS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_ALL_BOOKMARKS).success(data));
        dispatch(asyncActions(GET_ALL_BOOKMARKS).loading(false));
        return data;
      }
    })
    .catch((error) => {
      dispatch(asyncActions(GET_ALL_BOOKMARKS).loading(false));
      dispatch(asyncActions(GET_ALL_BOOKMARKS).failure(true, error.response));
      return error.response;
    });
};
