import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Row, Col, Button } from "reactstrap";
import {
  getSurveyQuestions,
  isMentor,
} from "src/modules/mentorship/components/helper";

import Empty from "src/assets/img/mentorship-empty.png";
import SurveyModal from "src/modules/mentorship/components/SurveyModal/SurveyModalGeneral";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const GeneralSurvey = () => {
  const userId = JSON.parse(localStorage.getItem("data")).user.id;
  const user = get_user_info_from_token().user;
  const issMentor = isMentor(user);
  console.log(issMentor);
  const audience = !issMentor ? "mentee" : "mentor";
  const [survey, setSurvey] = useState(false);
  const [surveyQuestion, setSurveyQuestion] = useState();

  useEffect(() => {
    getSurveyQuestions(
      userId,
      audience,
      issMentor ? user["tef-mentor"][1] : user["tef-mentee"][1]
    )
      .then((res) => {
        if (res.status && res.status === 200) {
          setSurveyQuestion(res.data.data);
          console.log("General", res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [userId]);

  const showSurveyModal = () => {
    setSurvey(!survey);
  };

  const showGeneralQuestions = () => {
    return (
      surveyQuestion &&
      surveyQuestion.map((question, i) => (
        <Col md={4} key={i}>
          <Card style={{ height: "150px" }}>
            <CardBody>
              <CardTitle>
                <b>{question.title.toUpperCase()}</b>
              </CardTitle>
              <Button onClick={showSurveyModal}>Give Feedback</Button>
            </CardBody>
          </Card>
          <SurveyModal
            survey={survey}
            setSurvey={setSurvey}
            questions={question}
            setQuestions={setSurveyQuestion}
            respondedToUserId={userId}
            general
          />
        </Col>
      ))
    );
  };

  return (
    <div className="text-centers">
      {surveyQuestion && surveyQuestion.length !== 0 ? (
        <Row>{showGeneralQuestions()}</Row>
      ) : (
        <Col
          style={{ textAlign: "center", marginTop: "5px" }}
          md={{ size: 6, offset: 3 }}
        >
          <img alt="empty" src={Empty} />
          <h3 className="goals-heading">No Survey To Show</h3>
          <br />
          <br />
        </Col>
      )}
    </div>
  );
};

GeneralSurvey.propTypes = {};

export default GeneralSurvey;
