import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { getAllGoalsAnalysis } from "src/requests/GoalRequests";
import StatsCard from "src/modules/mentorship/components/StatsCard/StatsCard";
import Skeleton from "react-loading-skeleton";
import { Context } from "src/store";
import "./MentorshipStats.scss";
import { isMentor } from "src/modules/mentorship/components/helper";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
const MentorshipStats = () => {
  const { state, dispatch } = useContext(Context);
  const [goalStats, setGoalStats] = useState(null);
  const user = get_user_info_from_token().user;
  const [mentorLockedGoals, setMentorLoackedGoals] = useState([]);
  useEffect(() => {
    getAllGoalsAnalysis(dispatch);
  }, [state.Goals.goals]);

  // console.log(user.hasOwnProperty("tef-mentee"));

  useEffect(() => {
    // const sortGoalStatus = () => {
    //   const lockedCompleted = [];
    //   const lockedUnderReview = [];
    //   let statsArray = [];

    //   if (state.Goals.goals && state.Goals.analysis) {
    //     for (let goal of state.Goals.goals) {
    //       if (
    //         goal.isActive === false &&
    //         goal.status.toLowerCase() === "under review"
    //       ) {
    //         lockedUnderReview.push(goal);
    //       }
    //       if (
    //         goal.isActive === false &&
    //         goal.status.toLowerCase() === "completed"
    //       ) {
    //         lockedCompleted.push(goal);
    //       }
    //     }
    //   }

    //   let underReview =
    //     state.Goals.analysis && state.Goals.analysis[2].totalGoals;
    //   let completed =
    //     state.Goals.analysis && state.Goals.analysis[0].totalGoals;
    //   statsArray[0] = completed - lockedCompleted.length;
    //   statsArray[1] = lockedUnderReview.length + lockedCompleted.length;
    //   statsArray[2] = underReview - lockedUnderReview.length;
    //   setGoalStats(statsArray);
    // };
    // if (state.Goals.analysis && state.Goals.goals) {
    //   sortGoalStatus();
    // }
    if (state.Goals.analysis) {
      const stats = [];
      stats[0] = state.Goals.analysis[0].totalGoals;
      stats[1] = state.Goals.analysis[1].totalGoals;
      stats[2] = state.Goals.analysis[2].totalGoals;
      stats[3] = state.Goals.analysis[3].totalGoals;
      setGoalStats(stats);
    }
  }, [state.Goals.analysis]);

  useEffect(() => {
    if (isMentor(user)) {
      if (state.Goals.analysis && state.Goals.goals) {
        getLockedGoalsByMentor();
      }
    }
  }, [state.Goals.analysis && state.Goals.goals]);

  const getLockedGoalsByMentor = () => {
    const lockedGoals = state.Goals.goals.filter((goal) => {
      return goal.isActive === false && goal.creator !== user.id;
    });
    setMentorLoackedGoals(lockedGoals);
  };

  const showLoackedGoals = () => {
    let returnenedvalue = goalStats[3];
    if (isMentor(user)) {
      const actual = goalStats[3] - mentorLockedGoals.length;
      returnenedvalue = Math.sign(actual) === -1 ? -1 * actual : actual;
    }
    return returnenedvalue;
  };

  return (
    <Row>
      <Col md={4}>
        <StatsCard
          number={goalStats ? goalStats[0] : <Skeleton />}
          text={" Goals Completed"}
          status="Completed"
        />
      </Col>
      <Col md={4}>
        <StatsCard
          number={goalStats ? showLoackedGoals() : <Skeleton />}
          text="Locked Goals"
          status="Locked"
          // status="Not started"
        />
      </Col>
      <Col md={4}>
        <StatsCard
          number={goalStats ? goalStats[2] : <Skeleton />}
          text=" Goal Under Review"
          status="under review"
        />
      </Col>
    </Row>
  );
};

MentorshipStats.propTypes = {};

export default MentorshipStats;
