import React from "react";
import styles from "./Sidebar.module.scss";
import SidebarItem from "src/modules/training/components/Layout/SidebarItem/SidebarItem";
import UserSection from "src/components/Layout/SideBar/UserSection/UserSection";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { Link } from "react-router-dom";
import { getLanguage } from "src/utils/helper";

const Sidebar = ({
  details,
  trainingEventId,
  lang,
  onQuizClick,
  lessonComplete,
  moduleComplete,
}) => {
  // console.log(details);
  const currentUser = useCurrentUser();
  const language = getLanguage() || "en";
  const display = details.map((data, index) => {
    const moduleCourse = data.moduleTitles.find(
      (title) => title.lang === language
    );

    if (moduleComplete.status && moduleComplete.no === data.id) {
      data.completedOn = new Date();
    }

    return (
      <SidebarItem
        key={data.id}
        menuTitle={(moduleCourse && moduleCourse.title) || ""}
        menuId={data.id}
        subMenus={data.moduleLessons}
        trainingEventId={trainingEventId}
        lang={lang}
        courseId={data.courseId}
        quiz={data.moduleQuiz}
        quizClicked={onQuizClick}
        moduleNo={data.moduleNo}
        completed={data.completedOn}
        started={data.startedOn}
        isLessonComplete={lessonComplete}
      />
    );
  });
  return (
    <div className={`${styles.Sidebar} notranslate`}>
      <div>
        <UserSection currentUser={currentUser} />
        {display}
      </div>
      <Link
        to={`/training/LMS?trainingEventId=${trainingEventId}`}
        className={styles.Sidebar__back}
      >
        back to dashboard
      </Link>
    </div>
  );
};

export default Sidebar;
