export const industryTypes = {
  en: [
    {
      label: "Agriculture (Agri-business, Farming, etc.)",
      value: "Agriculture",
    },
    { label: "Aviation", value: "Aviation" },

    {
      label: "Beauty & Wellness",
      value: "Beauty & Wellness",
    },
    { label: "Commercial/Retail", value: "Commercial/Retail" },
    { label: "Construction", value: "Construction" },
    { label: "Consulting", value: "Consulting" },
    { label: "Education and Training", value: "Education and Training" },
    {
      label: "Energy/Power Generation",
      value: "Energy/Power Generation",
    },
    { label: "Event Planning", value: "Event Planning" },
    { label: "Fashion", value: "Fashion" },
    { label: "Financial Services", value: "Financial Services" },
    { label: "FMCG", value: "FMCG" },
    { label: "Food & Beverages", value: "Food & Beverages" },
    { label: "Haulage/ Logistics", value: "Haulage/ Logistics" },
    { label: "Healthcare", value: "Healthcare" },
    { label: "ICT", value: "ICT" },
    {
      label: "Intellectual Property Services",
      value: "Intellectual Property Services",
    },
    { label: "Manufacturing", value: "Manufacturing" },
    {
      label: "Media and Entertainment",
      value: "Media and Entertainment",
    },
    { label: "Oil & Gas", value: "Oil & Gas" },
    // { label: "Other", value: "Other" },
    { label: "Professional Services", value: "Professional Services" },
    { label: "Telecommunication", value: "Telecommunication" },
    { label: "Textile", value: "Textile" },
    { label: "Tourism/Hospitality", value: "Tourism/Hospitality" },
    { label: "Transportation", value: "Transportation" },
    { label: "Waste Management", value: "Waste Management" },

    { label: "Legal Services / Law", value: "Legal Services / Law" },
    {
      label: "Forestry and Wood Processing",
      value: "Forestry and Wood Processing",
    },
    { label: "Mining", value: "Mining" },
  ],

  fr: [
    {
      label: "Agriculture (Agri-business, etc.)",
      value: "Agriculture",
    },
    {
      label: "Beauté et bien-être",
      value: "Beauty & Wellness",
    },
    { label: "Aviation", value: "Aviation" },
    { label: "Commerce de détail", value: "Commercial/Retail" },
    {
      label:
        "Construction de bâtiments ou travaux publics (maçonnerie électricité bâtiments, carrelage, peinture, plomberie, construction métallique, etc)",
      value: "Construction",
    },
    { label: "Conseil", value: "Consulting" },
    {
      label: "Éducation et enseignement",
      value: "Education and Training",
    },
    {
      label: "Énergie/Production d'électricité",
      value: "Energy/Power Generation",
    },
    { label: "Planification d'événements", value: "Event Planning" },
    { label: "Mode (coupe couture et stylisme)", value: "Fashion" },
    { label: "Finance et banque", value: "Financial Services" },
    { label: "Produits de grande consommation", value: "FMCG" },
    {
      label: "Alimentation et boissons (transformation agroalimentaire)",
      value: "Food & Beverages",
    },
    { label: "Transport et logistique", value: "Haulage/ Logistics" },
    { label: "Soins de santé", value: "Healthcare" },
    {
      label: "Technologies de l’informatique et de la communication",
      value: "ICT",
    },
    {
      label: "Service de Propriété Intellectuelle",
      value: "Intellectual Property Services",
    },
    { label: "Fabrication", value: "Manufacturing" },
    {
      label: "Média et divertissement",
      value: "Media and Entertainment",
    },
    { label: "Pétrole et gaz", value: "Oil & Gas" },
    // { label: "Autre", value: "autre" },
    { label: "Services professionnels", value: "Professional Services" },
    { label: "Télécommunication", value: "Telecommunication" },
    { label: "Textile", value: "Textile" },
    { label: "Tourisme/Hôtellerie", value: "Tourism/Hospitality" },
    { label: "Transport", value: "Transportation" },
    { label: "Gestion des déchets", value: "Waste Management" },
  ],

  pt: [
    {
      label: "Agricultura(Agri-negocio, etc.)",
      value: "Agriculture",
    },
    {
      label: "Beleza e Bem-Estar",
      value: "Beauty & Wellness",
    },
    { label: "Aviação", value: "Aviation" },
    { label: "Commercial/Retalhos", value: "Commercial/Retail" },
    { label: "Construção", value: "Construction" },
    { label: "Conselho", value: "Consulting" },
    { label: "Educação e Treinamento", value: "Education and Training" },
    {
      label: "Energia/ Produção de Electricidade",
      value: "Energy/Power Generation",
    },
    { label: "Planejamento dos Eventosg", value: "Event Planning" },
    { label: "Moda", value: "Fashion" },
    { label: "Serviços financeiros", value: "Financial Services" },
    { label: "Os bens de grande consumo", value: "FMCG" },
    { label: "Comidas e bebidas", value: "Food & Beverages" },
    { label: "Logísticas", value: "Haulage/ Logistics" },
    { label: "Cuidado médico", value: "Healthcare" },
    { label: "ICT", value: "ICT" },
    {
      label: "Serviços da Propriedade Intelectuais",
      value: "Intellectual Property Services",
    },
    { label: "Produção", value: "Manufacturing" },
    {
      label: "Média e Divertimento",
      value: "Media and Entertainment",
    },
    { label: "Petróleo", value: "Oil & Gas" },
    // { label: "Outros", value: "Outros" },
    { label: "Serviços Profissionais", value: "Professional Services" },
    { label: "Telecomunicações", value: "Telecommunication" },
    { label: "Têxtil ", value: "Textile" },
    { label: "Turismo/Hospitalidade", value: "Tourism/Hospitality" },
    { label: "Transporte", value: "Transportation" },
    { label: "Gerência de Lixos", value: "Waste Management" },
  ],

  ar: [
    {
      value: "Agriculture",
      label: "الأعمال التجارية الزراعية",
    },
    {
      value: "Beauty & Wellness",
      label: "مركز التجميل والعافي",
    },
    { value: "Aviation", label: "الطيران" },
    { value: "Commercial/Retail", label: "تجاري/ بيع بالتجزئة " },
    { value: "Construction", label: "البناء" },
    { value: "Consulting", label: "الاستشارة" },
    { value: "Education and Training", label: "التعليم والتدريب" },
    {
      value: "Energy/Power Generation",
      label: "توليد الطاقة/ الطاقة ",
    },
    { value: "Event Planning", label: "تخطيط الأحداث " },
    { value: "Fashion", label: "الموضة" },
    { value: "Financial Services", label: "الخدمات المالية" },
    { value: "FMCG", label: "FMCG" },
    { value: "Food & Beverages", label: "الطعام والمشروبات" },
    { value: "Haulage/ Logistics", label: "السحب/ اللوجستيات" },
    { value: "Healthcare", label: "الرعاية الصحية" },
    { value: "ICT", label: "تكنولوجيا المعلومات والاتصالات" },
    {
      value: "Intellectual Property Services",
      label: "خدمات الملكية الفكرية",
    },
    { value: "Manufacturing", label: "التصنيع" },
    {
      value: "Media and Entertainment",
      label: "وسائل الإعلام والترفيه ",
    },
    { value: "Oil & Gas", label: "النفط والغاز" },
    // { label: "Other", label: "Other" },
    { value: "Professional Services", label: "الخدمات المهنية" },
    { value: "Telecommunication", label: "الاتصالات السلكية واللاسلكية" },
    { value: "Textile", label: "النسيج" },
    { value: "Tourism/Hospitality", label: "السياحة/ الضيافة الإقامة" },
    { value: "Transportation", label: "النقل" },
    { value: "Waste Management", label: "إدارة النفايات" },

    { value: "Legal Services / Law", label: "الخدمات القانونية / القانون" },
    {
      value: "Forestry and Wood Processing",
      label: "الغابات ومعالجة الأخشاب",
    },
    { value: "Mining", label: "صناعة التعدين" },
  ],
};

// the inductry types above where commented out because of the current programme requirement and should be returned back later while the ones below remove
