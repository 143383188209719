/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { AiOutlineHeart } from "react-icons/ai";
import { BiCommentDots } from "react-icons/bi";
import { BsHeartFill, BsShare } from "react-icons/bs";
import { EditorContentDisplay } from "src/components/Editor";
import ImageGridGenerator from "src/utils/imageGridGenerator";
import Comment_v2 from "src/modules/homepage/components/Comment/Comment_v2";
import classes from "./PostCard_v2.module.scss";

import { Context } from "src/store";
import { likePost, unlikePost } from "src/requests/PostRequest";
import PhotoModal from "src/modules/homepage/components/ImageModal/PhotoModal/PhotoModal";
import { convertTextForSharing } from "src/modules/homepage/helper";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  FacebookShareCount,
} from "react-share";
import ClickOutside from "react-click-outside-component";

const PostCardContent = ({ post }) => {
  const {
    commentCount,
    content,
    likeCount,
    postFiles,
    isLiked,
    id: postId,
  } = post;
  const [likes, setLikes] = useState(likeCount);
  const [liked, setLiked] = useState(isLiked);
  const [showComments, setShowComments] = useState(false);
  const [counts, setCounts] = useState(commentCount);
  const [showImageModal, setShowImageModal] = useState(false);
  const [shareText, setShareText] = useState("");
  const [toggleShareOptions, setToggleShareOptions] = useState(false);

  const handleCommentToggle = () => setShowComments(!showComments);
  const handleShareToggle = () => setToggleShareOptions(!toggleShareOptions);

  const [showImages, setShowImages] = useState([]);

  const toggleImageModal = () => setShowImageModal(!showImageModal);
  const { dispatch } = useContext(Context);

  const handleShowImages = (images) => {
    toggleImageModal();
    setShowImages(images);
  };

  useEffect(() => {
    if (toggleShareOptions) {
      setShareText(convertTextForSharing(content));
    }
  }, [toggleShareOptions]);

  const handleLikePost = () => {
    if (liked) {
      setLiked(false);
      setLikes(likes - 1);
      return unlikePost(dispatch, postId);
    }
    setLiked(true);
    setLikes(likes + 1);
    return likePost(dispatch, postId);
  };

  return (
    <>
      <div className={classes.postBody}>
        <EditorContentDisplay
          content={content}
          readOnly
          subStringLength={200}
          className={classes.postContent}
        />
        <ImageGridGenerator
          imageLength={postFiles.length}
          images={postFiles.map(({ url }) => ({ url }))}
          handleClick={(images) => handleShowImages(images)}
        />
      </div>
      <div className={`${classes.actionBtnContainer} more-button`}>
        <span onClick={handleLikePost}>
          {liked ? (
            <BsHeartFill color="var(--tef-red)" />
          ) : (
            <AiOutlineHeart size={18} />
          )}{" "}
          <span> {(likes && likes.toLocaleString()) || 0}</span>
        </span>

        <span onClick={handleCommentToggle}>
          {<BiCommentDots size={18} />}{" "}
          <span> {(counts && counts.toLocaleString()) || 0}</span>
        </span>

        <span onClick={handleShareToggle}>
          <BsShare size={18} /> <span> Share</span>
        </span>

        <ClickOutside onClickOutside={() => setToggleShareOptions(false)}>
          <div
            className={
              toggleShareOptions ? "share-options" : "share-option hide-div"
            }
          >
            <ul className="share-options__lists">
              <li>
                <TwitterShareButton url="tefconnect.com" title={shareText}>
                  <TwitterIcon size={20} round={true} className="icon-tab" />
                  Share via Twitter
                </TwitterShareButton>
              </li>
              <li>
                <FacebookShareButton url="tefconnect.com" quote={shareText}>
                  <FacebookIcon size={20} round={true} className="icon-tab" />
                  Share via Facebook
                  <FacebookShareCount url="tefconnect.com" />
                </FacebookShareButton>
              </li>
              <li>
                <LinkedinShareButton url="tefconnect.com" source={shareText}>
                  <LinkedinIcon size={20} round={true} className="icon-tab" />
                  Share via LinkedIn
                </LinkedinShareButton>
              </li>
            </ul>
          </div>
        </ClickOutside>
      </div>

      <Comment_v2
        showComments={showComments}
        updateCommentCount={setCounts}
        postId={postId}
      />

      <PhotoModal
        open={showImageModal}
        toggle={toggleImageModal}
        image={showImages}
      />
    </>
  );
};
export default PostCardContent;
