import { getAlumniHubEventCount } from "src/requests/AlumniHubRequest";

export function getHubEventCount(
  dispatch,
  setHubEventCount,
  setIsLoadingHubEventCount
) {
  return (hubId) => {
    getAlumniHubEventCount(dispatch, hubId)
      .then((response) => setHubEventCount(response.data.data))
      .catch((fetchHubEventCountError) =>
        console.log("fetchHubEventCountError", fetchHubEventCountError)
      )
      .finally(() => setIsLoadingHubEventCount(false));
  };
}
