import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loader from "src/components/Loader/Loader";
import authService from "./authService";

const OpenIdCallback = ({ callback, route }) => {
  const history = useHistory();
  useEffect(() => {
    callback().then(() => {
      if (route) {
        history.push(route);
      }
    });
  }, []);
  return <Loader />;
};

export const SignInRedirectCallback = () => (
  <OpenIdCallback
    callback={() => authService.signinRedirectCallback()}
    route="/login/redirect"
  />
);

export const SignOutRedirectCallback = () => (
  <OpenIdCallback
    callback={() => authService.signoutRedirectCallback()}
    route="/signout"
  />
);

export const SignInSilentCallback = () => (
  <OpenIdCallback callback={() => authService.signinSilentCallback()} />
);

export const SignInRedirect = () => (
  <OpenIdCallback callback={() => authService.signinRedirect()} />
);
