export const instructions = {
  en: {
    title: "Guidelines for the Assessment.",
    subHeading: "Read the following carefully",
    instruction1:
      "The assessment is divided into 5 sections and would require between 45 -60 mins to complete. It is recommended that you take the assessment in an environment free of distractions and with optimal concentration",
    instruction2:
      "Please note that you are to answer all questions and you can only take this assessment once.",
    instruction3:
      "You will also be required to upload a form of ID (International passport, driver’s license, Residency or Citizen’s card, voters’ card, tax id). Please have your chosen form of ID scanned and ready for upload in pdf, jpeg, .jpg,.png,.gif or doc format before you begin.",
    instruction4:
      "The application opportunity targets individuals who have innovative ideas and existing businesses in Waste Management and in other Green Economy Sectors from Kenya, Nigeria, Morocco, South Africa, and Senegal.",
    list: "Automatic Disqualification criteria:",
    list1: "If you are under 18 years of age or above 40 years",
    list2:
      "If you have received a grant or seed capital from TEF in previous years",
    list4:
      "If you are not from Kenya, Nigeria, Senegal, South Africa, or Morocco",
    list5:
      "If your innovative idea or existing business is not in the green economy sector",
    list6:
      "If you are from Kenya, your innovative idea or existing business MUST be in the waste management sector",
    list7:
      "If your business is in existence for more than 5 years(excluding 2020 & 2021)",
    next: 'Click the "Next" button to begin.',
  },
  fr: {
    title: "Informations sur le processus de candidature",
    subHeading: "Veuillez lire attentivement les informations suivantes:",
    instruction1:
      "L’évaluation est divisée en 5 sections et nécessiterait entre 45 et 60 minutes. Il est recommandé de passer l’évaluation dans un environnement exempt de distractions et avec une concentration optimale.",
    instruction2:
      "Veuillez noter que vous devez répondre à toutes les questions et que vous ne pouvez passer cette évaluation qu’une seule fois",
    instruction3:
      "Vous devrez également télécharger une pièce d’identité (passeport international, permis de conduire, carte de résidence ou d’identité nationale, carte d’électeur, numéro d’identification fiscale). Veuillez faire numériser la pièce d’identité de votre choix et la préparer à être téléchargée au format pdf, jpeg, .jpg, .png, .gif ou doc. avant de commencer.",
    instruction4:
      "L’opportunité de candidature cible les personnes qui ont des idées innovantes et des entreprises existantes dans la gestion des déchets et dans d’autres secteurs de l’économie verte du Kenya, du Nigeria, du Maroc, de l’Afrique du Sud et du Sénégal. Pour comprendre et avoir une connaissance détaillée des secteurs et sous-secteurs de l’économie verte, veuillez cliquer sur le lien ci-dessous.",
    list:
      "Dans les deux cas suivants, votre candidature ne sera pas prise en compte:",
    list1: "Si vous avez moins de 18 ans ou plus de 40 ans",
    list2:
      "Si vous avez reçu une subvention ou un capital d’amorçage de TEF au cours des années précédentes.",
    list4:
      "Si vous n’êtes pas du Kenya, du Nigeria, du Sénégal, d’Afrique du Sud ou du Maroc",
    list5:
      "Si votre idée innovante ou votre entreprise existante n’est pas dans le secteur de l’économie verte",
    list6:
      "Si vous venez du Kenya, votre idée innovante ou votre entreprise existante DOIT être dans le secteur de la gestion des déchets",
    list7:
      "Si votre entreprise existe depuis plus de 5 ans (années COVID actualisées?)",
    next: "Cliquez sur le bouton « Suivant » pour commencer",
  },
  pt: {
    title: "Guia pela avaliação.",
    subHeading: "Léia cuidadosamente a seguinte",
    instruction1:
      "A avaliação está dividida em 5 seções e levará entre 45 e 60 minutos para ser concluída. É recomendável que você faça a avaliação em um ambiente livre de distrações e com concentração otimizada.",
    instruction2:
      "Observe que você deve responder a todas as perguntas e só pode fazer esta avaliação uma vez.",
    instruction3:
      "Você também precisará fazer o upload de um documento de identificação (passaporte internacional, carteira de motorista, cartão de residência ou de cidadão, cartão de eleitor, número de identificação fiscal). Tenha o documento de identificação escolhido digitalizado e pronto para o upload nos formatos PDF, JPEG, JPG, PNG, GIF ou DOC antes de começar.",
    instruction4:
      "A oportunidade de candidatura é voltada para indivíduos com ideias inovadoras e negócios existentes no setor de Gestão de Resíduos e em outros setores da Economia Verde do Quênia, Nigéria, Marrocos, África do Sul e Senegal. Para entender e obter um conhecimento detalhado dos setores e sub-setores da Economia Verde, clique no link abaixo.",
    list: "Critérios de Desqualificação Automática:",
    list1: "Se você tem menos de 18 anos ou mais de 40 anos",
    list2:
      "Se você recebeu uma bolsa ou capital inicial da TEF em anos anteriores",
    list4:
      "Se você não é do Quênia, Nigéria, Senegal, África do Sul ou Marrocos",
    list5:
      "Se sua ideia inovadora ou negócio existente não está no setor da economia verde",
    list6:
      "Se você é do Quênia, sua ideia inovadora ou negócio existente DEVE estar no setor de gestão de resíduos",
    list7:
      "Se o seu negócio existe há mais de 5 anos (anos de COVID excluídos)",
    next: 'Clique no botão "Próximo" para começar.',
  },
  ar: {
    title: "إرشادات التقييم..",
    subHeading: "اقرأ ما يلي بعناية.",
    instruction1:
      "التقييم مقسم إلى 5 أقسام وسيستغرق بين 45-60 دقيقة لإكماله. يُوصى بأن تجري التقييم في بيئة خالية من التشتيت وبتركيز مثلى.",
    instruction2:
      "يرجى ملاحظة أنه يجب عليك الإجابة على جميع الأسئلة ولا يمكنك إجراء هذا التقييم إلا مرة واحدة.",
    instruction3:
      "سيُطلب أيضًا منك تحميل نوع من وثائق الهوية (جواز سفر دولي، رخصة قيادة، بطاقة إقامة أو مواطن، بطاقة ناخب، رقم تعريف ضريبي). يُرجى تصوير وتجهيز نوع الهوية المختارة في صيغة pdf، jpeg، .jpg، .png، .gif، أو doc قبل أن تبدأ.",
    instruction4:
      "تستهدف الفرصة التقديمية الأفراد الذين لديهم أفكار مبتكرة وشركات قائمة في مجال إدارة النفايات وفي قطاعات أخرى للاقتصاد الأخضر في كينيا ونيجيريا والمغرب وجنوب أفريقيا والسنغال. لفهم والحصول على معرفة مفصلة حول القطاعات والقطاعات الفرعية للاقتصاد الأخضر، يُرجى النقر على الرابط أدناه.",
    list: "معايير الإقصاء التلقائي:",
    list1: "إذا كنت تحت سن 18 عامًا أو أكثر من 40 عامًا",
    list2: "إذا كنت قد تلقيت منحة أو رأس مال بذرة من TEF في السنوات السابقة",
    list4:
      "إذا لم تكن من كينيا أو نيجيريا أو السنغال أو جنوب أفريقيا أو المغرب",
    list5:
      "إذا كانت فكرتك المبتكرة أو شركتك القائمة ليست في قطاع الاقتصاد الأخضر",
    list6:
      "إذا كنت من كينيا، يجب أن تكون فكرتك المبتكرة أو شركتك القائمة في قطاع إدارة النفايات",
    list7:
      "إذا استمرت شركتك في الوجود لأكثر من 5 سنوات (السنوات خلال جائحة كوفيد غير محسوبة)",
    next: 'انقر على زر "التالي" للبدء.',
  },
  sw: {
    title: "Mwongozo kwa Ajili ya Tathmini",
    subHeading: "Soma yafuatayo kwa umakini.",
    instruction1:
      "Tathmini imegawanywa katika sehemu 5 na itachukua kati ya dakika 45 hadi 60 kukamilisha. Inashauriwa kufanya tathmini hii katika mazingira yasiyo na usumbufu na kwa umakini kamili.",
    instruction2:
      "Tafadhali kumbuka kuwa unapaswa kujibu maswali yote na unaweza kufanya tathmini hii mara moja tu.",
    instruction3:
      "Pia, utahitajika kupakia fomu ya kitambulisho (pasipoti ya kimataifa, leseni ya udereva, kadi ya makazi au uraia, kadi ya mpiga kura, kitambulisho cha kodi). Tafadhali andaa fomu yako ya kitambulisho iliyoscan kwa muundo wa pdf, jpeg, .jpg, .png, .gif, au doc kabla ya kuanza.",
    instruction4:
      "Fursa ya kuomba inalenga watu wenye mawazo yenye ubunifu na biashara zilizopo katika Usimamizi wa Taka na Sekta nyingine za Uchumi wa Kijani kutoka Kenya, Nigeria, Morocco, Afrika Kusini, na Senegal. Ili kuelewa na kupata ufahamu kamili wa Sekta na Sekta-Ndogo za Uchumi wa Kijani, tafadhali bonyeza kiungo hapa chini",
    list: "Vigezo vya Kufutwa Moja kwa Moja",
    list1: "Ikiwa una umri chini ya miaka 18 au zaidi ya miaka 40",
    list2:
      "Ikiwa umepokea ruzuku au mtaji wa mbegu kutoka TEF katika miaka ya nyuma",
    list4:
      "Ikiwa wewe si kutoka Kenya, Nigeria, Senegal, Afrika Kusini, au Morocco.",
    list5:
      "Ikiwa wazo lako lenye ubunifu au biashara yako iliyopo si katika sekta ya uchumi wa kijani",
    list6:
      "Ikiwa wewe ni kutoka Kenya, wazo lako lenye ubunifu au biashara yako iliyopo inapaswa kuwa katika sekta ya usimamizi wa taka.",
    list7:
      "Ikiwa biashara yako imekuwepo kwa zaidi ya miaka 5 (miaka ya Covid itasahihishwa?).",
    next: 'Bonyeza kitufe cha "Endelea" kuanza.',
  },
};

export const sectionOneTranslations = {
  en:
    "This set of questions are about you. Please ensure that the personal details you provide match the information on your proof of ID.",
  fr:
    "Cette série de questions est à propos de vous. Veuillez-vous assurer que les informations personnelles que vous fournissez correspondent aux informations figurant sur votre pièce d'identité.",
  pt:
    "Por favor, assegure que os detalhes pessoais que someteu contém as mesmas informações na sua ID.",
  ar:
    " هذه المجموعة من الأسئلة تتعلق بك. يرجى التأكد من تطابق التفاصيل الشخصية التي تقدمها مع المعلومات الواردة في إثبات الهوية.",
  sw:
    "Kundi hili la maswali linahusu wewe. Hakikisha kuwa maelezo yako ya kibinafsi unayotoa yanalingana na habari kwenye uthibitisho wako wa kitambulisho.",
};

export const sectionTwoTranslations = {
  en:
    "The next set of questions are about your business idea and experience. Please provide complete and accurate responses.",
  fr:
    "La prochaine série de questions porte sur votre idée d'entreprise et votre expérience. Veuillez fournir des réponses complètes et précises.",
  pt:
    "As próximas questões se tratam da sua idéia de negócio e a sua experiência. Você tem que escolher a resposta mais correta.",
  ar:
    " تتعلق مجموعة الأسئلة التالية بفكرة العمل وخبرته. يرجى تقديم إجابات كاملة ودقيقة.",
  sw:
    "Kundi la maswali lifuatalo linahusu wazo lako la biashara na uzoefu wako. Tafadhali jibu kwa ukamilifu na kwa usahihi.",
};

export const sectionThreeTranslations = {
  en:
    "In each of the following questions is a statement. You are to select the best option that describes your opinion of the statement. Please answer all questions honestly.",
  fr:
    "Dans chacune des questions suivantes se trouve une déclaration. Vous devez sélectionner la meilleure option qui décrit votre opinion sur la déclaration. Veuillez répondre honnêtement à toutes les questions.",
  pt:
    "Em cada uma das perguntas a seguir há uma declaração. Você deve selecionar a melhor opção que descreve sua opinião sobre a declaração. Por favor, responda todas as perguntas honestamente.",
  ar:
    "في كل سؤال من الأسئلة التالية عبارة. عليك تحديد الخيار الأفضل الذي يصف رأيك في العبارة.يرجى الإجابة عن كل الأسئلة بأمانة..",
  sw:
    "Katika kila moja ya maswali yafuatayo kuna taarifa. Unapaswa kuchagua chaguo bora kinachoelezea maoni yako kuhusu taarifa. Tafadhali jibu maswali yote kwa uaminifu.",
};

export const sectionFourTranslations = {
  en:
    "This section contains some business and financial questions. You are to choose the most correct answer.",
  fr:
    "Cette section contient quelque questions d’affaire de base. Vous choisirez la meilleure réponse de toutes les options chaque fois.",
  pt:
    "Está seção contém qualquer questões de negócios básicos e têm que escolher a melhor resposta de todas as opções cada vez.",
  ar:
    "يحتوي هذا القسم على بعض الأسئلة التجارية والمالية. عليك اختيار الإجابة الأكثر صحة.",
  sw:
    "Sehemu hii ina maswali kadhaa ya biashara na fedha. Unapaswa kuchagua jibu sahihi zaidi.",
};
