import "./progressReport.scss";
import React from "react";
import icon from "src/assets/img/group-6-copy.png";
import { useTranslation } from "react-i18next";
const ExceededQuizTrial = ({
  showReport,
  percentageComplete,
  onSubmitClick,
}) => {
  const { t } = useTranslation();
  if (!showReport) {
    return null;
  }
  return (
    <div className="training-report-c">
      <div className="i-div">
        <img src={icon} className="img-fluid" />
      </div>
      <div className="report-body-c">
        <h1 className="greeting">
          {t("Quiz trial exceeded")}!
          {Number(percentageComplete) > 50 && <>😃</>}{" "}
        </h1>
        <p>{t(`Your final score is ${percentageComplete}%`)}</p>
      </div>

      {/* <div className="report-body-c">
        {Number(percentageComplete) > 50 ? (
          <h1 className="greeting">{t("Quiz trial exceeded")}!😃</h1>
        ) : null}
        <p>{t(`Your final score is ${percentageComplete}%`)}</p>
      </div> */}
      <div className="report-continue-btn">
        <div className="submit-box">
          <div className="submit-btn" onClick={onSubmitClick}>
            {t("Continue")}
          </div>
        </div>
      </div>
    </div>
  );
};
ExceededQuizTrial.defaultProps = {
  showReport: false,
  percentageComplete: 40,
};
export default ExceededQuizTrial;
