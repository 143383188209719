/* eslint-disable no-multi-str */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useContext } from "react";

import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import Layout from "src/components/Layout/Layout";
import { Link, useHistory } from "react-router-dom";
import tefArticleBanner from "src/assets/img/discover/image2.png";
// import articleImage from "src/assets/img/discover/article.png";
import bannerBackground from "src/assets/img/discover/Vector5.png";
import { Editor } from "@tinymce/tinymce-react";
import "./opportunity.scss";
import CreateOpportunityForm from "./CreateOpportunityForm";
import CreateOpportunitySuccess from "./CreateOpportunitySucces";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
// import Loader from "src/components/Loader/Loader";
import { Select } from "src/components/CustomFormField/CustomFormField";
import CreatableMulti from "src/components/MultiSelect/CreatableSelect";
import ImageUploader from "react-images-upload";
import { RiImage2Fill } from "react-icons/ri";
import { FaLongArrowAltRight } from "react-icons/fa";
import {
  getOpportunityCategories,
  getOpportunityTypes,
} from "src/requests/OpportunityRequest";
import { postAlumniOpportunity } from "src/requests/AlumniOpportunityRequest";
import { Context } from "src/store";
import {
  // isAllowedFiles,
  UploadFile,
} from "src/requests/fileUploadRequest";
import Loader from "src/components/Loader/Loader";
import DefaultImage from "src/assets/img/Opportunity.jpeg";
import _ from "lodash";
import { getInterests } from "src/requests/onboarding";
import moment from "moment";
import ValidateJs from "src/utils/Validations/validation_wrapper";
import { hasDecimal } from "src/utils/helper";
import CurrencyList from "currency-list";

const CreateOpportunity = () => {
  const data = {
    title: "",
    country: "",
    totalPrice: 0,
    opportunityLink: "",
    deadline: "",
    opportunityTypeId: "",
    eligibilityRequirement: "",
    // contactEmail: "",
    benefits: "",
    shortSummary: "",
    fullDescription: "",
    fileName: DefaultImage,
    categoryId: "",
    // officialWebsite: "",
    relatedTopicIds: [],
    currency: "",
  };
  const opportunityValidationErrors = {
    title: null,
    shortSummary: null,
    country: null,
    fullDescription: null,
    totalPrice: null,
    eligibilityRequirement: null,
    relatedTopicIds: null,
    deadline: null,
    categoryId: null,
    opportunityTypeId: null,
    opportunityLink: null,
    // contactEmail: null,
    benefits: null,
    fileName: null,
    currency: null,
  };
  const [modal, setModal] = useState(false);
  const { state, dispatch } = useContext(Context);
  const [relatedTopics, setRelatedTopics] = useState([]);
  const [opportunityData, setOpportunityData] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [validationErrors, setValidationErrors] = useState(
    opportunityValidationErrors
  );
  const history = useHistory();
  const [file, setFile] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const toggle = () => setModal(!modal);

  const getCurrencyList = () => {
    const list = CurrencyList.getAll();
    const defaultLocale = list.default.en;
    const entries = Object.entries(defaultLocale);

    const options = [];
    for (const [key, value] of entries) {
      options.push({ label: value.symbol, value: value.symbol });
      setCurrencies(options);
    }
  };

  useEffect(() => {
    getCurrencyList();
    const _getBasicData = () => {
      getOpportunityCategories(dispatch);
      getOpportunityTypes(dispatch);
      getInterests(dispatch).then((res) => {
        const { status, data } = res;
        if (status === 200) {
          const relatedtopicss = data.map((i) => {
            return { value: i.id, label: i.name };
          });
          setRelatedTopics(relatedtopicss);
        } else {
          setRelatedTopics([]);
        }
      });
    };
    _getBasicData();
  }, []);
  const { categories, types, tags } = state.Opportunities;
  const categoryObj =
    categories &&
    categories.map((category) => {
      return {
        label: category.name,
        value: category.id,
      };
    });
  const typesObj = types.map((t) => {
    return {
      label: t.name,
      value: t.id,
    };
  });

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setOpportunityData({ ...opportunityData, [name]: value });
  };

  const handleDeadline = (val) => {
    const deadline = moment(val).format("YYYY-MM-DDTHH:mm:ss.SSS");

    setOpportunityData({ ...opportunityData, deadline });
  };

  const selectHandler = (name, value) => {
    setOpportunityData({ ...opportunityData, [name]: value });
  };
  const onDrop = (picture) => {
    const existingFiles = [...file];
    existingFiles.push(...picture);
    const uniqueFile = _.uniqBy(existingFiles, "name");
    setFile(uniqueFile);
  };

  const submitOpportunity = (e) => {
    e.preventDefault();
    const errors = _hasValidationError(opportunityData);
    if (errors.confirmed) {
      document.getElementById(errors.errors[0]).scrollIntoView();
    } else {
      const selectTagLabel = opportunityData.relatedTopicIds.map(
        (tag) => tag.value
      );
      opportunityData.relatedTopicIds = selectTagLabel;
      opportunityData.totalPrice = Number(opportunityData.totalPrice);
      opportunityData.isAlumni = true;
      setIsLoading(true);
      if (file.length > 0) {
        UploadFile(dispatch, true, file).then((res) => {
          if (res && res.status === 200) {
            res.files.map((img) => (opportunityData.fileName = img));

            _submit(opportunityData);
          }
        });
      } else {
        _submit(opportunityData);
      }
    }
  };

  const _submit = (data) => {
    console.log(data);
    postAlumniOpportunity(dispatch, data).then((res) => {
      setIsLoading(false);
      if (res) {
        setResponseData(res);
        setOpportunityData({
          title: "",
          country: "",
          totalPrice: 0,
          opportunityLink: "",
          deadline: "",
          opportunityTypeId: "",
          eligibilityRequirement: "",
          // contactEmail: "",
          benefits: "",
          currency: "",
          shortSummary: "",
          fullDescription: "",
          fileName: DefaultImage,
          categoryId: "",
          // officialWebsite: "",
          relatedTopicIds: [],
        });
        toggle();
      }
    });
  };
  const handleChange = (selectedItem) => {
    setOpportunityData({
      ...opportunityData,
      relatedTopicIds: selectedItem,
    });
  };

  const _handleValidationErrors = (target, value) => {
    setValidationErrors({ ...validationErrors, [target]: value });
  };
  const _hasValidationError = (data) => {
    const whitelist = [
      "officialWebsite",
      "opportunityLink",
      "benefits",
      "contactEmail",
    ];
    data = { ...data };
    let errors = {};
    const errorList = [];
    Object.keys(data).forEach((key) => {
      errors = { ...errors, [key]: ValidateJs(key, data[key]) };
    });

    whitelist.forEach((key) => {
      if (data[key] === "") {
        delete errors[key];
      }
    });

    Object.keys(errors).forEach((key) => {
      if (errors[key] !== null) {
        errorList.push(key);
      }
    });
    setValidationErrors(errors);
    return { confirmed: errorList.length > 0, errors: errorList };
  };

  const customHandler = (key, val) => {
    if (key === "deadline") {
      val = moment(val).format("YYYY-MM-DDTHH:mm:ss.SSS");
    }
    setOpportunityData({ ...opportunityData, [key]: val });
  };

  return (
    <Layout SideContent={<SidebarRight />}>
      <div className="mb-5">
        <div className="create-opportunity">
          <Link to="/alumni-opportunity" style={{ fontWeight: "bold" }}>
            <span className="mr-3">Alumni Opportunities</span>
          </Link>
          <FaLongArrowAltRight color="#919BA8" size={12} />
          <span className="ml-3">{"Create an Opportunity"}</span>
        </div>
        <Row className="mt-4">
          <Col>
            <div className="create-event-header">
              <h5 className="create-header-title">Create An Opportunity</h5>
              <span
                className="create-header-cancel"
                onClick={() => history.push("/alumni-opportunity")}
              >
                Cancel Create Opportunity
              </span>
            </div>
          </Col>
        </Row>
        <div className="create-tefstory-banner create-opp-banner">
          <Row>
            <Col md={9}>
              <div className="create-para">
                <p className="create-para-desc left-create-para mb-4 ">
                  TEFConnect is committed to sharing as many relevant
                  opportunities for African entrepreneurs as much as possible.
                </p>
                <p className=" create-para-desc left-create-para mb-4">
                  Kindly please note it takes 24-48 hours to verify your post
                  after which it would be published.
                </p>
                <p className="create-para-desc left-create-para ">
                  We invite you to share with us! We would have a look and get
                  it published if we think its cool – Its FREE!
                </p>
                <p className=" create-para-desc left-create-para">
                  You are only allowed to post once a week to the platform.
                </p>
              </div>
            </Col>
            <Col md={3}>
              <div className="create-tefstory-banner__logo">
                <img
                  src={bannerBackground}
                  alt="background"
                  style={{ width: "200px", top: "0px" }}
                  className="position-relative"
                />
                <img
                  src={tefArticleBanner}
                  alt="logo"
                  style={{ width: "65px", top: "0px" }}
                  className="position-absolute"
                />
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col sm={12} md={8}>
            <form onSubmit={submitOpportunity}>
              <CreateOpportunityForm
                categories={categoryObj}
                tagsOption={tags}
                toggle={toggle}
                selectHandler={selectHandler}
                handleChange={handleChange}
                inputHandler={inputHandler}
                onDrop={onDrop}
                opportunityData={opportunityData}
                handleDeadline={handleDeadline}
                customHandler={customHandler}
                validationErrors={validationErrors}
                _handleValidationErrors={_handleValidationErrors}
              />
              <div className="tefstory-editor mt-3 p-3">
                <Row>
                  <Col sm={12} md={12}>
                    <Label className="create-event-label" htmlFor="articleTags">
                      Full Description
                      <span className="required required-tag"> - Required</span>
                    </Label>
                    <FormGroup type="textarea">
                      <Editor
                        apiKey={process.env.REACT_APP_TINY_CLOUD_KEY}
                        init={{
                          height: 400,
                          branding: false,
                          statusbar: false,
                          placeholder:
                            "Please provide us with detail description of this opportunity",
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
                        }}
                        onEditorChange={(content, editor) => {
                          setOpportunityData({
                            ...opportunityData,
                            fullDescription: content,
                          });
                        }}
                        required
                        id="fullDescription"
                        className="no-border"
                        value={opportunityData.fullDescription}
                        onBlur={() =>
                          _handleValidationErrors(
                            "fullDescription",
                            ValidateJs(
                              "fullDescription",
                              opportunityData.fullDescription
                            )
                          )
                        }
                      />
                      {validationErrors.fullDescription &&
                        displayError(validationErrors.fullDescription)}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12}>
                    <Label className="create-event-label" htmlFor="articleTags">
                      Eligibility Requirement
                      <span className="required required-tag"> - Required</span>
                    </Label>
                    <FormGroup type="textarea">
                      <Editor
                        className="no-border"
                        apiKey={process.env.REACT_APP_TINY_CLOUD_KEY}
                        init={{
                          height: 400,
                          branding: false,
                          statusbar: false,
                          placeholder: "Who is this opportunity open to?",
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
                        }}
                        onEditorChange={(content, editor) => {
                          setOpportunityData({
                            ...opportunityData,
                            eligibilityRequirement: content,
                          });
                        }}
                        id="eligibilityRequirement"
                        value={opportunityData.eligibilityRequirement}
                        onBlur={() =>
                          _handleValidationErrors(
                            " eligibilityRequirement",
                            ValidateJs(
                              " eligibilityRequirement",
                              opportunityData.eligibilityRequiremen
                            )
                          )
                        }
                      />
                      {validationErrors.eligibilityRequirement &&
                        displayError(validationErrors.eligibilityRequirement)}
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col sm={12} md={12}>
                    <FormGroup>
                      <Label className="create-event-label" htmlFor="category">
                        Opportunity Type{" "}
                        <span className="required required-tag">
                          - Required
                        </span>
                      </Label>
                      <Select
                        defaultVal="Select OpportunityType"
                        list={typesObj}
                        value={opportunityData.opportunityTypeId}
                        id="opportunityTypeId"
                        name="opportunityTypeId"
                        onChange={selectHandler}
                        className="event__input no-border"
                        onBlur={() =>
                          _handleValidationErrors(
                            "opportunityTypeId",
                            ValidateJs(
                              "opportunityTypeId",
                              opportunityData.opportunityTypeId
                            )
                          )
                        }
                      />
                      {validationErrors.opportunityTypeId &&
                        displayError(validationErrors.opportunityTypeId)}
                    </FormGroup>
                  </Col>
                  {/* <Col sm={12} md={6}>
                    <FormGroup>
                      <Label
                        className="create-event-label"
                        htmlFor="storyTitle"
                      >
                        Contact Email
                        <span className="required required-tag">
                          {" "}
                          - Required
                        </span>
                      </Label>
                      <Input
                        type="text"
                        name="contactEmail"
                        id="contactEmail"
                        placeholder="Contact Email"
                        maxLength={40}
                        className="event__input no-border"
                        onChange={inputHandler}
                        value={opportunityData.contactEmail}
                        onBlur={() =>
                          _handleValidationErrors(
                            "contactEmail",
                            ValidateJs(
                              "contactEmail",
                              opportunityData.contactEmail
                            )
                          )
                        }
                      />
                      {validationErrors.contactEmail &&
                        displayError(validationErrors.contactEmail)}
                    </FormGroup>
                  </Col> */}
                </Row>

                <Row form>
                  <Col sm={12} md={12}>
                    <FormGroup>
                      <Label
                        className="create-event-label"
                        htmlFor="storyTitle"
                      >
                        Opportunity Link
                        {/* <span className="required required-tag">
                          {" "}
                          - Required
                        </span> */}
                      </Label>
                      <Input
                        type="text"
                        name="opportunityLink"
                        id="opportunityLink"
                        placeholder="Event Title"
                        maxLength={40}
                        className="event__input no-border"
                        onChange={inputHandler}
                        value={opportunityData.opportunityLink}
                        onBlur={() =>
                          opportunityData.opportunityLink.length > 0
                            ? _handleValidationErrors(
                                "opportunityLink",
                                ValidateJs(
                                  "opportunityLink",
                                  opportunityData.opportunityLink
                                )
                              )
                            : _handleValidationErrors("opportunityLink", "")
                        }
                      />
                      {validationErrors.opportunityLink &&
                        displayError(validationErrors.opportunityLink)}
                    </FormGroup>
                  </Col>
                </Row>
                {/* <Row>
                  <Col sm={12} md={12}>
                    <Label className="create-event-label" htmlFor="articleTags">
                      Benefit
                    </Label>
                    <FormGroup type="textarea">
                      <Editor
                        className="no-border"
                        apiKey={process.env.REACT_APP_TINY_CLOUD_KEY}
                        init={{
                          height: 400,
                          branding: false,
                          statusbar: false,
                          placeholder:
                            "What do applicant stand to benefit by applying for this opportunity?",
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
                        }}
                        onEditorChange={(content, editor) => {
                          setOpportunityData({
                            ...opportunityData,
                            benefits: content,
                          });
                        }}
                        value={opportunityData.benefits}
                      />
                    </FormGroup>
                  </Col>
                </Row> */}

                <Row form>
                  <Col sm={5} md={5}>
                    <FormGroup>
                      <Label
                        className="create-event-label"
                        htmlFor="storyTitle"
                      >
                        Currency
                      </Label>
                      <Input
                        type="select"
                        name="currency"
                        id="currency"
                        placeholder="Total Price"
                        required
                        className="event__input no-border"
                        onChange={inputHandler}
                        value={opportunityData.currency}
                      >
                        <option value={""}>{"Select Currency"}</option>

                        {currencies.map((currency, i) => (
                          <option key={i} value={currency.value}>
                            {currency.label}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm={7} md={7}>
                    <FormGroup>
                      <Label
                        className="create-event-label"
                        htmlFor="storyTitle"
                      >
                        Total Price
                      </Label>
                      <Input
                        type="number"
                        name="totalPrice"
                        id="totalPrice"
                        placeholder="Total Price"
                        maxLength={40}
                        required
                        className="event__input no-border"
                        onChange={inputHandler}
                        value={opportunityData.totalPrice}
                        // onInput={}
                        min={0}
                        onInput={(e) =>
                          (e.target.value = hasDecimal(e.target.value)
                            ? Number(e.target.value).toFixed(2)
                            : e.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {/* <Row form>
                  <Col sm={12} md={12}>
                    <FormGroup>
                      <Label
                        className="create-event-label"
                        htmlFor="officialWebsite"
                      >
                        Official Website
                        <span className="required required-tag"> </span>
                      </Label>
                      <Input
                        type="text"
                        name="officialWebsite"
                        id="officialWebsite"
                        placeholder="Official Website"
                        maxLength={40}
                        className="event__input no-border"
                        onChange={inputHandler}
                        value={opportunityData.officialWebsite}
                      />
                    </FormGroup>
                  </Col>
                </Row> */}
                <Row form>
                  <Col sm={12} md={12}>
                    <FormGroup>
                      <Label
                        className="create-event-label"
                        htmlFor="articleTags"
                      >
                        Related Topics
                      </Label>

                      <CreatableMulti
                        className="event__input no-border"
                        options={relatedTopics}
                        onChange={handleChange}
                        isMulti={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <div className="tefstory-editor mt-3 p-3">
                <Row form>
                  <Col sm={6} md={12}>
                    <FormGroup className="mb-5">
                      <Label htmlFor="articleImage">
                        Upload Banner or Cover Image
                      </Label>
                      <div className="upload-wrapper">
                        <div
                          className="opp-create-form__image-c"
                          style={{ backgroundColor: "#F0F0F0" }}
                        >
                          <div className="photo-icon-box">
                            <span className="opp-form-img-box__default-img">
                              {" "}
                              <RiImage2Fill color="#8EE67D" size={100} />
                            </span>
                          </div>
                          <ImageUploader
                            withIcon={false}
                            required
                            fileContainerStyle={{
                              background: "none",
                              margin: 0,
                              width: "100%",
                              padding: "50px",
                            }}
                            singleImage={true}
                            withLabel={true}
                            className="no-border"
                            withPreview={true}
                            labelClass="upload-image-label"
                            buttonClassName="upload-image-btn"
                            maxFileSize={5242880}
                            buttonText="Browse your files"
                            imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                            onChange={onDrop}
                            label="Upload photo"
                          />
                        </div>
                        <div className="fileformat">
                          Recommended Format: 5MB PNG, GIF, JPG, JPEG,
                          {/* <br />
                          Recommended Size: 544 X 166 */}
                        </div>
                      </div>

                      {validationErrors.fileName &&
                        displayError(validationErrors.fileName)}
                    </FormGroup>
                    <div className="float-right mt-5">
                      <Button type="button" className="cancel-send mr-3">
                        Cancel
                      </Button>
                      <Button type="submit">
                        {" "}
                        {isLoading ? (
                          <Loader color="light" />
                        ) : (
                          "Create Opportunity"
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              {/* <Row className="mt-5">
                <Col>
                  <div className="float-right">
                    <Button type="button" className="cancel-send mr-3">Cancel</Button>
                    <Button>
                      {" "}
                      {isLoading ? (
                        <Loader color="light" />
                      ) : (
                        "Create Opportunity"
                      )}
                    </Button>
                  </div>
                </Col>
              </Row> */}
            </form>
          </Col>
          <Col sm={12} md={4} className="mt-3">
            <div className="opportunity__feature">
              <h6 className="opportunity__content-tip">
                Helpful Tip To Get Your Opportunity{" "}
                <span className="special-feature">Featured </span>
              </h6>
              <div className="opportunity__feature__tip">
                <h6 className="opportunity__feature--title">
                  Detailed Description
                </h6>
                <p className="opportunity__feature--para">
                  Make sure both the summaryand full descriptions are carefully
                  worded and outlined
                </p>
              </div>
              <div className="opportunity__feature__tip">
                <h6 className="opportunity__feature--title">
                  Used Clear Feature banner
                </h6>
                <p className="opportunity__feature--para">
                  Make sure both the summaryand full descriptions are carefully
                  worded and outlined
                </p>
              </div>
              <div className="opportunity__feature__tip">
                <h6 className="opportunity__feature--title">
                  Provide Valid Link
                </h6>
                <p className="opportunity__feature--para">
                  Make sure both the summaryand full descriptions are carefully
                  worded and outlined
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <CreateOpportunitySuccess
        modal={modal}
        title={opportunityData.title}
        resultId={responseData}
        toggle={toggle}
      />
    </Layout>
  );
};

export default CreateOpportunity;

export const displayError = (error) => {
  // const { t } = useTranslation();
  return (
    <span
      style={{
        color: "red",
        marginTop: "-5px",
        marginLeft: "10px",
        fontFamily: "Poppins",
        fontSize: "12px",
      }}
    >
      {error}
    </span>
  );
};
