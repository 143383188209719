import React, { useState, useContext } from "react";
import moment from "moment";
import { AiOutlineWallet } from "react-icons/ai";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { Card, CardImg, CardBody, CardText, Spinner } from "reactstrap";
import DefaultImage from "src/assets/img/bg/background_640-1.webp";
import { FiClock } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import {
  likeOpportunity,
  getAlumninOpportunity,
} from "src/requests/AlumniOpportunityRequest";
import { Context } from "src/store";

export const OpportunityCard = ({ opportunity }) => {
  const copyOpportunity = Object.assign({}, opportunity);

  // const {
  //   createdDate,
  //   title,
  //   totalPrice,
  //   deadline,
  //   isVerified,
  //   fileName,
  //   id,

  //   opportunityLikeCount,
  // } = copyOpportunity;
  const [isLoadingLikeOpportunity, setIsLoadingLikeOpportunity] = useState(
    false
  );
  const [singleOpportunity, setSingleOpportunity] = useState(copyOpportunity);
  const history = useHistory();
  const { dispatch } = useContext(Context);
  const style = {
    backgroundImage: `linear-gradient(
      to right bottom,
      rgba(0,0,0,0.3),
      rgba(0,0,0,0.4)
  ), url(${singleOpportunity?.fileName || DefaultImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  };

  const deadLineHumanDate = (deadline) => {
    return moment(deadline).format("DD MMM YYYY");
  };
  const postedHumanDate = (createdDate) => {
    return moment(createdDate).fromNow();
  };

  const handleLikeOpportunity = (id) => {
    setIsLoadingLikeOpportunity(true);
    likeOpportunity(dispatch, id).then((res) => {
      const { data } = res.data;
      if (data) {
        getAlumninOpportunity(dispatch, id).then((res) => {
          if (res.status === 200) {
            const {
              data: { data },
            } = res;
            setIsLoadingLikeOpportunity(false);
            console.log("data", data, singleOpportunity);
            setSingleOpportunity(data);
          }
        });
      }
    });
  };

  return (
    <div
      sm="2"
      md="4"
      className="mb-3 alumni-opp-list-item"
      key={singleOpportunity.id}
    >
      <Link>
        <Card className="alumni__opportunity">
          <CardImg
            top
            width="100%"
            className="opportunity__img"
            style={style}
          />
          {singleOpportunity.isAdminCreated && (
            <div className="alumni__only-mobile">Admin Verified</div>
          )}
          {singleOpportunity.isAdminCreated && (
            <div
              style={{ backgroundColor: "#d42316" }}
              className="alumni__only"
            >
              Admin Verified
            </div>
          )}
          <CardBody className="opp-card-txt">
            <CardText>
              <div className="opportunity__posted">
                <span className="opportunity__time">
                  Posted {postedHumanDate(singleOpportunity.createdDate)}
                </span>
                <div
                  onClick={() => handleLikeOpportunity(singleOpportunity.id)}
                >
                  {isLoadingLikeOpportunity ? (
                    <Spinner size="sm" />
                  ) : singleOpportunity.opportunityLikeCount > 0 ? (
                    <FaHeart
                      size={19}
                      style={{ color: "#cf1d10", cursor: "pointer" }}
                    />
                  ) : (
                    <FaRegHeart
                      size={19}
                      color="#919BA8"
                      style={{ cursor: "pointer", marginRight: "10px" }}
                    />
                  )}
                </div>

                {/* <span>
                {" "}
                <FaRegHeart color="#919BA8" />
              </span> */}
              </div>
            </CardText>
            <CardText>
              <p className="opportunity__title title-mobile">
                {/* {truncateWithEllipses(title, 20)} */}
                {singleOpportunity.title}
              </p>
              <p
                className="opportunity__title title-full"
                onClick={() =>
                  history.push(
                    `/alumni-opportunity-detail/${singleOpportunity.id}`
                  )
                }
              >
                {singleOpportunity.title}
              </p>
              <div className="opp-meta-deaddline">
                <div className="opp-meta-opportunity__icon">
                  <span className="opportunity__icon">
                    <FiClock color="#919BA8" />
                  </span>
                  <span className="deadline-fund">
                    <span className="deadline-date-val">
                      {deadLineHumanDate(singleOpportunity.deadline)}
                    </span>
                    <span className="info-badge deadline-date-text">
                      Deadline{" "}
                    </span>
                  </span>
                </div>
                <div className="opp-meta-opportunity__icon">
                  <span className="opportunity__icon">
                    <AiOutlineWallet color="#919BA8" />
                  </span>
                  <span className="deadline-fund">
                    <span
                      className="deadline-date-val"
                      style={{
                        overflowWrap: "break-word",
                        wordWrap: "break-word",
                      }}
                    >
                      ${singleOpportunity.totalPrice}
                    </span>
                    <span className="info-badge deadline-date-text">
                      Total Price{" "}
                    </span>
                  </span>
                </div>
              </div>
            </CardText>
          </CardBody>
        </Card>
      </Link>
    </div>
  );
};
