import React, { useEffect, useState, useContext } from "react";
import { Col, Row } from "reactstrap";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import VerticalProgress from "src/modules/Tef-Dms/components/Progress/VerticalProgress";
import CurrentStage from "src/modules/Tef-Dms/components/Lists/CurrentStage/CurrentStage";
import classes from "./WelcomeTab.module.css";
import { Context } from "src/store";
import jwt_decode from "src/utils/jwt-decode";

const WelcomeTab = ({ activeTab }) => {
  const [dueDilligenceProgress, setDueDilligenceProgress] = useState([]);
  const [seedCapitalProgress, setSeedCapitalProgress] = useState([]);
  const [businessplanProgress, setBusinessPlanProgress] = useState([]);
  const [documents, setBusinessPlanDocuments] = useState([]);
  const [currentCycleCount, setCurrentCycleCount] = useState("");
  const [programCycleCount, setProgramCycleCount] = useState("");
  const [userName, setUserName] = useState("");

  const { state } = useContext(Context);
  const stageProgress = [
    {
      id: 1,
      name: "Submitted For Review",
      currentStage: false,
    },
    {
      id: 2,
      name: "At Reviewer",
      currentStage: false,
    },
    {
      id: 3,
      name: "At Quality Assurance",
      currentStage: false,
    },
    {
      id: 4,
      name: "Received and Acknowledged",
      currentStage: false,
    },
    {
      id: 5,
      name: "Phase Completed",
      currentStage: false,
    },
  ];

  useEffect(() => {
    const dueDiligenceData = localStorage.getItem("dueDilligence");
    if (dueDiligenceData !== "undefined") {
      const dueDilligence = JSON.parse(dueDiligenceData);
      if (dueDilligence) {
        const { stageProgresses } = dueDilligence;
        const getDueDilligenceCurrentProgress = () => {
          const result = stageProgresses.find(
            (stage) => stage.isCompleted === false
          );

          if (result) {
            const final = stageProgress.find(
              (stage) => stage.id === result.sortOrder
            );

            if (final) {
              const newStagePosition =
                stageProgress &&
                stageProgress.map((stage) =>
                  stage.id === final.id
                    ? {
                        id: stage.id,
                        name: stage.name,
                        currentStage: !stage.currentStage,
                      }
                    : stage
                );

              setDueDilligenceProgress(newStagePosition);
            }
          }
        };

        getDueDilligenceCurrentProgress();
      }
    }
  }, [state.dms.dueDilligence]);

  useEffect(() => {
    const seedCapital = JSON.parse(localStorage.getItem("seedCapital"));
    if (seedCapital) {
      const { stageProgresses } = seedCapital;
      const getSeedCapitalCurrentProgress = () => {
        const result = stageProgresses.find(
          (stage) => stage?.isCompleted === false
        );

        if (result) {
          const final = stageProgress.find(
            (stage) => stage.id === result.sortOrder
          );

          if (final) {
            const newStagePosition =
              stageProgress &&
              stageProgress.map((stage) =>
                stage.id === final.id
                  ? {
                      id: stage.id,
                      name: stage.name,
                      currentStage: !stage.currentStage,
                    }
                  : stage
              );

            setSeedCapitalProgress(newStagePosition);
          }
        }
      };

      getSeedCapitalCurrentProgress();
    }
  }, [state.dms.seedCapital]);

  useEffect(() => {
    const businessPlan =
      localStorage.getItem("businessPlan") !== "undefined"
        ? JSON.parse(localStorage.getItem("businessPlan"))
        : null;
    if (businessPlan) {
      const { stageProgresses } = businessPlan;
      console.log(businessPlan);
      setBusinessPlanDocuments(businessPlan.documents);
      setProgramCycleCount(businessPlan.programCycleCount);
      setCurrentCycleCount(businessPlan.currentCycleCount);
      const getBusinessPlanProgress = () => {
        const result = stageProgresses.find(
          (stage) => stage?.isCompleted === false
        );

        if (result) {
          const final = stageProgress.find(
            (stage) => stage.id === result.sortOrder
          );

          if (final) {
            const newStagePosition =
              stageProgress &&
              stageProgress.map((stage) =>
                stage.id === final.id
                  ? {
                      id: stage.id,
                      name: stage.name,
                      currentStage: !stage.currentStage,
                    }
                  : stage
              );

            setBusinessPlanProgress(newStagePosition);
          }
        }
      };

      getBusinessPlanProgress();
    }
  }, [state.dms.businessPlan]);

  const getCyclePercentage = (current, total) => {
    return (current / total) * 100;
  };

  useEffect(() => {
    const getTokenInfo = () => {
      const tkn = localStorage.getItem("data");
      const token = JSON.parse(tkn).token;
      const { firstName } = jwt_decode(token);

      return setUserName(firstName);
    };
    getTokenInfo();
  }, []);

  const renderCurrentStage = () => {
    switch (activeTab) {
      case "1":
        return (
          <CurrentStage
            activeTab={activeTab}
            stageIndex={1}
            stageName="Due Dilligence"
          />
        );
      case "2":
        return (
          <CurrentStage
            activeTab={activeTab}
            stageIndex={2}
            stageName="Business Plans"
          />
        );
      case "3":
        return (
          <CurrentStage
            activeTab={activeTab}
            stageIndex={3}
            stageName="Seed Capital"
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Row className="mb-5 mt-3">
        <Col md={6} xs={6}>
          <p style={{ lineHeight: "1.1em" }}>
            Program Status <br />{" "}
            <span style={{ color: "#178829", fontWeight: "700" }}>
              Active - Qualified
            </span>
          </p>
        </Col>
        <Col md={6} xs={6} className={classes.CurrentStage}>
          <Row style={{ lineHeight: "1.1em" }}>
            <span style={{ paddingRight: "0.8em" }}>Current Stage</span>
          </Row>
          <Row>{renderCurrentStage()}</Row>
        </Col>
      </Row>
      <h2>Welcome to your DMS, {userName}</h2>
      <p className="mt-4">
        We recommend you complete {""}
        <span style={{ color: "#DA291C" }}>every form</span> and upload all
        <span style={{ color: "#DA291C" }}> required documents </span>before
        submitting for review
      </p>

      <div className="mt-5">
        <Row>
          {activeTab === "1" && (
            <Col md={6} xs={6}>
              <p style={{ fontWeight: "700", fontSize: "12px" }}>
                Current Stage
              </p>
              {dueDilligenceProgress.map((stage) => (
                <VerticalProgress
                  name={stage.name}
                  active={stage.currentStage}
                  stageIndex={stage.id}
                  key={stage.id}
                />
              ))}
            </Col>
          )}
          {activeTab === "2" && (
            <Col md={6} xs={6}>
              <p style={{ fontWeight: "700", fontSize: "12px" }}>
                Current Stage
              </p>
              {businessplanProgress.map((stage) => (
                <VerticalProgress
                  name={stage.name}
                  active={stage.currentStage}
                  stageIndex={stage.id}
                  key={stage.id}
                />
              ))}
            </Col>
          )}
          {activeTab === "3" && (
            <Col md={6} xs={6}>
              <p style={{ fontWeight: "700", fontSize: "12px" }}>
                Current Stage
              </p>
              {seedCapitalProgress.map((stage) => (
                <VerticalProgress
                  name={stage.name}
                  active={stage.currentStage}
                  stageIndex={stage.id}
                  key={stage.id}
                />
              ))}
            </Col>
          )}
          {activeTab !== "2" ? null : (
            <Col
              md={6}
              xs={6}
              style={
                documents.length === 0
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              <Row className={classes.CurrentStageContainers}>
                <p
                  className="d-flex flex-row-reverse bd-highlight pr-3"
                  style={{
                    fontWeight: "700",
                    fontSize: "12px",
                  }}
                >
                  Current Cycle
                </p>
              </Row>
              <Row className={`pr-3 ${classes.CurrentStageContainers}`}>
                <div
                  style={{
                    width: "50%",
                    height: "50%",
                  }}
                >
                  <CircularProgressbarWithChildren
                    value={getCyclePercentage(
                      currentCycleCount,
                      programCycleCount
                    )}
                    styles={buildStyles({
                      pathColor: "#DA291C",
                      textColor: "#212529",
                    })}
                  >
                    <span>
                      <strong>
                        {state &&
                          state.dms &&
                          state.dms.businessPlan &&
                          state.dms.businessPlan.currentCycleCount}
                      </strong>
                      {state &&
                        state.dms &&
                        state.dms.businessPlan &&
                        state.dms.businessPlan.currentCycleCount &&
                        state.dms.businessPlan.currentCycleCount === 1 && (
                          <sup>st</sup>
                        )}
                      {state &&
                        state.dms &&
                        state.dms.businessPlan &&
                        state.dms.businessPlan.currentCycleCount &&
                        state.dms.businessPlan.currentCycleCount === 2 && (
                          <sup>nd</sup>
                        )}
                      {state &&
                        state.dms &&
                        state.dms.businessPlan &&
                        state.dms.businessPlan.currentCycleCount &&
                        state.dms.businessPlan.currentCycleCount === 3 && (
                          <sup>rd</sup>
                        )}
                      {state &&
                        state.dms &&
                        state.dms.businessPlan &&
                        state.dms.businessPlan.currentCycleCount &&
                        state.dms.businessPlan.currentCycleCount > 3 && (
                          <sup>th</sup>
                        )}
                    </span>
                  </CircularProgressbarWithChildren>
                </div>
              </Row>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default WelcomeTab;
