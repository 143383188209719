import React, { useState, useContext, Fragment } from "react";
import { BiPlus, BiSearch } from "react-icons/bi";
import { TiMessages } from "react-icons/ti";
import { FaEllipsisH } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import Loader from "src/components/Loader/Loader";
import { Context } from "src/store";
import { joinGroup, leaveGroup } from "src/requests/StudyGroupsRequest";
import queryString from "query-string";
import { useCurrentUser } from "src/utils/Services/currentUser";
import AllMembers from "src/modules/training/StudyGroups/components/AllMembers/AllMembers";
import MoreOptions from "src/modules/training/StudyGroups/components/MoreOptions/MoreOptions";
import ClickOutside from "react-click-outside-component";
import InviteFriendsModal from "src/modules/training/StudyGroups/components/InviteFriends/InviteFriendsModal";
import EditTrainingStudyGroup, {
  EditStudyGroupModal,
} from "src/modules/training/StudyGroups/components/CreateCommunityGroup/EditTrainingStudyGroup";
import { isStudyGroupAdmin } from "src/modules/training/StudyGroups/helper";

const MobileSideInfo = ({
  creatorId,
  member,
  membersCount,
  groupType,
  groupLogo,
  groupTitle,
  createdBy,
  about,
  NoOfPosts,
  creatorPhoneNumber,
  creatorEmail,
  // groupCategory={groupInfo.category.name}
  createdDate,
  createdByDp,
  getGroupDetails,
  groupLikeCount,
  hasLikedGroup,
  programmeEventId,
  programmeId,
  courseId,
}) => {
  const [joined, setJoined] = useState(member);
  // eslint-disable-next-line no-unused-vars
  const [left, setLeft] = useState(false);
  const [isJoinLoading, setIsJoinLoading] = useState(false);
  const [count, setCount] = useState(membersCount);
  const { dispatch } = useContext(Context);
  const { userId } = useCurrentUser();
  const qa = useLocation();
  const { groupId } = queryString.parse(qa.search);
  const [showMembers, setShowMembers] = useState(false);
  const [toggleMoreOptions, setToggleMoreOptions] = useState(false);
  const [toggleInviteFriendsModal, setToggleInviteFriendsModal] = useState(
    false
  );

  const [openEditStudyGroup, setOpenEditStudyGroup] = useState(false);
  const toggleEditGroup = () => setOpenEditStudyGroup(!openEditStudyGroup);

  const handleMoreToggle = () => setToggleMoreOptions(!toggleMoreOptions);

  const handleJoinGroup = () => {
    setIsJoinLoading(true);
    joinGroup(dispatch, { groupId, userId }).then((res) => {
      setIsJoinLoading(false);
      setJoined(true);
      setLeft(false);
      setCount(count + 1);
    });
  };
  const handleLeaveGroup = () => {
    setIsJoinLoading(true);
    leaveGroup(dispatch, { groupId, userId }).then((res) => {
      setIsJoinLoading(false);
      setLeft(true);
      setJoined(false);
      setCount(count - 1);
    });
  };

  const handleClick = () => {
    joined ? handleLeaveGroup() : handleJoinGroup();
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between bg-white">
        {userId !== creatorId && (
          <div className="text-center text-secondary mx-auto">
            <BiPlus />
            <div className="" onClick={handleClick}>
              {isJoinLoading ? (
                <Loader color="light" />
              ) : member ? (
                "Leave Group"
              ) : (
                "Join Group"
              )}
            </div>
          </div>
        )}

        <div className="text-center mx-auto">
          <TiMessages />
          <div>
            {" "}
            <Link to={`/messages/thread/new?userId=${creatorId}`}>Message</Link>
          </div>
        </div>
        <div
          onClick={() => setShowMembers(!showMembers)}
          className="text-center mx-auto"
        >
          <BiSearch />
          <div>Members</div>
        </div>
        {isStudyGroupAdmin(creatorId, userId) && (
          <div onClick={handleMoreToggle} className="text-center mx-auto">
            <FaEllipsisH />
            <div>More</div>
            <ClickOutside onClickOutside={() => setToggleMoreOptions(false)}>
              <MoreOptions
                userId={userId}
                groupOwnerId={creatorId}
                show={toggleMoreOptions}
                setToggleInviteFriendsModal={setToggleInviteFriendsModal}
                groupId={groupId}
                toggleEditClick={toggleEditGroup}
              />
            </ClickOutside>
          </div>
        )}
      </div>

      {showMembers && (
        <Fragment>
          {groupType.toLowerCase() === "private" && !member ? (
            ""
          ) : (
            <div>
              <AllMembers
                backgroundColor="#fff"
                belongsToId={creatorId}
                userId={userId}
              />
            </div>
          )}
        </Fragment>
      )}
      <div>
        <InviteFriendsModal
          isOpen={toggleInviteFriendsModal}
          toggle={() => setToggleInviteFriendsModal(!toggleInviteFriendsModal)}
        />
      </div>

      <>
        <EditStudyGroupModal
          isOpen={openEditStudyGroup}
          toggle={toggleEditGroup}
        >
          <EditTrainingStudyGroup
            cancel={toggleEditGroup}
            groupData={{
              name: groupTitle,
              imageUrl: groupLogo,
              about,
              userId: creatorId,
              privacy: groupType,
              programmeId: programmeId,
              programmeEventId: programmeEventId,
              courseId,
            }}
            getGroupDetails={getGroupDetails}
          />
        </EditStudyGroupModal>
      </>
    </Fragment>
  );
};

export default MobileSideInfo;
