/* eslint-disable no-unused-vars */
import React, { useState, useContext, useCallback, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Context } from "src/store";
import {
  joinGroup,
  leaveGroup,
  joinPrivateGroup,
} from "src/requests/CommunityRequest";
import queryString from "query-string";
import Loader from "src/components/Loader/Loader";
import Avatar from "react-avatar";
import { ReactComponent as More } from "src/assets/img/home/more.svg";
import "./SideInfo.scss";
import MoreOptions from "src/modules/Community/components/MoreOptions/MoreOptions";
import ClickOutside from "react-click-outside-component";
import InviteFriendsModal from "src/modules/Community/components/InviteFriends/InviteFriendsModal";
import AllMembers from "src/modules/Community/components/AllMembers/AllMembers";
import { useCurrentUser } from "src/utils/Services/currentUser";
import sendMessage from "src/utils/NewMessageHelper";
import { toast } from "react-toastify";
import { isRequest } from "src/modules/Community/components/SuggestedGroup/SuggestedGroupCard/SuggestedGroupCard";
import { _truncateText } from "src/utils/helper";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const SideInfo = ({
  groupTitle,
  groupType,
  createdBy,
  about,
  // creatorPhoneNumber,
  // creatorEmail,
  groupCategory,
  NoOfPosts,
  membersCount,
  groupLogo,
  member,
  createdDate,
  creatorId,
  createdByDp,
  inviteStatus,
  getGroupDetails,
}) => {
  const { userId, firstName, lastName, email } = useCurrentUser();
  const { dispatch } = useContext(Context);
  const [joined, setJoined] = useState(member);
  const [left, setLeft] = useState(false);
  const [requested, setRequested] = useState(isRequest(inviteStatus));
  const [isJoinLoading, setIsJoinLoading] = useState(false);
  const [count, setCount] = useState(membersCount);
  const qa = useLocation();
  const history = useHistory();
  const { groupId } = queryString.parse(qa.search);
  const [toggleMoreOptions, setToggleMoreOptions] = useState(false);
  const [toggleInviteFriendsModal, setToggleInviteFriendsModal] = useState(
    false
  );
  const [showMoreAbout, setShowMoreAbout] = useState(false);
  const toggleShowMoreAbout = () => setShowMoreAbout(!showMoreAbout);
  const [buttonText, setButtonText] = useState("join Group");

  const handleMoreToggle = () => setToggleMoreOptions(!toggleMoreOptions);
  const handleJoinGroup = () => {
    setIsJoinLoading(true);
    setCount(count + 1);

    joinGroup(dispatch, { groupId, userId }).then((res) => {
      setIsJoinLoading(false);
      setJoined(true);
      setLeft(false);
      getGroupDetails();
    });
  };
  const handleLeaveGroup = () => {
    setIsJoinLoading(true);
    setCount(count - 1);
    leaveGroup(dispatch, { groupId, userId }).then((res) => {
      setIsJoinLoading(false);
      setLeft(true);
      setJoined(false);
      getGroupDetails();
    });
  };

  const handleRequestJoinGroup = () => {
    const payload = {
      groupId,
      inviteedByUserId: creatorId,
      inviteeUserId: userId,
      inviteeEmail: email,
    };
    setIsJoinLoading(true);
    joinPrivateGroup(dispatch, payload).then((res) => {
      setIsJoinLoading(false);
      setJoined(false);
      setLeft(false);
      if (res.status === 201) {
        setRequested(true);
        toast(
          "A request has been sent to the admin of this group '" +
            groupTitle +
            "'"
        );
      }
    });
  };

  const toggleInviteModal = useCallback(() => {
    setToggleInviteFriendsModal(!toggleInviteFriendsModal);
  }, [toggleInviteFriendsModal]);

  const handleClick = () => {
    joined
      ? handleLeaveGroup()
      : groupType === "private"
      ? handleRequestJoinGroup()
      : handleJoinGroup();
  };

  useEffect(() => {
    if (joined) {
      setButtonText("leave group");
    }
    if (left) {
      setButtonText("join group");
    }
    if (requested) {
      setButtonText("requested");
    }
  }, [joined, left, requested]);

  return (
    <div className="side-info-container">
      <div className="side-info-container__group-details">
        <More
          onClick={handleMoreToggle}
          className="side-info-container__group-details--more"
        />
        <ClickOutside onClickOutside={() => setToggleMoreOptions(false)}>
          <MoreOptions
            userId={userId}
            groupOwnerId={creatorId}
            show={toggleMoreOptions}
            setToggleInviteFriendsModal={setToggleInviteFriendsModal}
            groupId={groupId}
            groupTitle={groupTitle}
            firstName={firstName}
            lastName={lastName}
            groupType={groupType}
          />
        </ClickOutside>
        {groupLogo && groupLogo !== "string" ? (
          <img
            src={groupLogo}
            className="side-info-container__group-details--icon"
            alt=""
          />
        ) : (
          <Avatar
            name={groupTitle}
            className="side-info-container__group-details--icon"
          />
        )}
        <h1 className="side-info-container__group-details--title">
          {groupTitle}
        </h1>
        <h4 className="side-info-container__group-details--sub-title">
          {groupType}
        </h4>
        <div className="side-info-container__group-details--info">
          <div className="side-info">
            <h1 className="side-info__posts">{NoOfPosts}</h1>
            <p className="side-info__label">posts</p>
          </div>

          <div className="side-info">
            <h1 className="side-info__posts">{count}</h1>
            <p className="side-info__label">
              {count > 1 ? "Members" : "Member"}
            </p>
          </div>
        </div>

        {userId !== creatorId && (
          <button
            className="side-info-container__group-details--button"
            onClick={handleClick}
            disabled={isJoinLoading || requested}
          >
            {isJoinLoading ? <Loader color="light" /> : buttonText}
          </button>
        )}
      </div>

      <div className="created-by-info">
        <p className="created-by-info__title">Group Created by</p>
        <div className="created-by-info__details">
          {createdByDp ? (
            <img
              src={createdByDp}
              alt=""
              className="created-by-info__details--image"
            />
          ) : (
            <Avatar name={createdBy} round={true} size={42} />
          )}
          <div className="created-by-info__details--name-details">
            <h2>{createdBy}</h2>
            <h6>{createdDate}</h6>
          </div>
          <button
            className={
              creatorId === userId
                ? "created-by-info__details--button details-disabled-btn"
                : "created-by-info__details--button"
            }
            onClick={() => sendMessage(creatorId, history)}
          >
            Send Message
          </button>
        </div>
      </div>

      <div className="group-about">
        <h3 className="group-about__title">About</h3>
        <div className="group-about__details">
          <h4 className="group-about__details--info" id="about">
            {_truncateText(about, 55)}{" "}
            {about.length > 55 && (
              <span
                className="info-span"
                style={{ fontWeight: "bold", cursor: "pointer" }}
                onClick={toggleShowMoreAbout}
              >
                See more
              </span>
            )}
          </h4>

          <Modal isOpen={showMoreAbout} toggle={toggleShowMoreAbout} centered>
            <ModalHeader toggle={toggleShowMoreAbout}>About Group</ModalHeader>
            <ModalBody>{about}</ModalBody>
          </Modal>

          {/* <div className="group-about__details--info-group"> */}
          {/* <h4>Phone Number</h4> */}
          {/* <h6>{creatorPhoneNumber}</h6> */}
          {/* </div> */}
          {/* <div className="group-about__details--info-group"> */}
          {/* <h4>Email</h4> */}
          {/* <h6>{creatorEmail}</h6> */}
          {/* </div> */}
          <div className="group-about__details--info-group">
            <h4>Category</h4>
            <h6>{groupCategory}</h6>
          </div>
        </div>
      </div>

      {groupType.toLowerCase() === "private" && !member ? (
        ""
      ) : (
        <div>
          <AllMembers
            backgroundColor="#fff"
            belongsToId={creatorId}
            userId={userId}
            setToggleInviteFriendsModal={setToggleInviteFriendsModal}
            adminId={creatorId}
          />
        </div>
      )}

      <div>
        <InviteFriendsModal
          isOpen={toggleInviteFriendsModal}
          toggle={toggleInviteModal}
        />
      </div>
    </div>
  );
};

export default SideInfo;
