import React from "react";
import { Col, Row, Card } from "reactstrap";
import {
  ImFilePdf,
  ImFilePicture,
  ImFileWord,
  ImFileExcel,
} from "react-icons/im";
import moment from "moment";

const CycleCard = ({ document }) => {
  const convertDate = (dateString) => {
    // eslint-disable-next-line
    let newDate = moment(dateString);
    newDate = newDate.format("YYYY-MM-DD");

    return newDate;
  };

  const lastThreeCharacters = (stringInQuestion) => {
    let result = "";
    result = stringInQuestion.substr(stringInQuestion.length - 4);
    switch (result) {
      case ".pdf":
        return <ImFilePdf color="#7F7F7F" className="ml-4 mt-2" size={60} />;
      case ".doc":
        return <ImFileWord color="#7F7F7F" className="ml-4 mt-2" size={60} />;
      case "docx":
        return <ImFileWord color="#7F7F7F" className="ml-4 mt-2" size={60} />;
      case ".xls":
        return <ImFileExcel color="#7F7F7F" className="ml-4 mt-2" size={60} />;
      case "xlsx":
        return <ImFileExcel color="#7F7F7F" className="ml-4 mt-2" size={60} />;
      default:
        return (
          <ImFilePicture color="#7F7F7F" className="ml-4 mt-2" size={60} />
        );
    }
  };
  return (
    <Col md={6} xs={6}>
      <Card className="rounded mb-3">
        <div>
          <Row>
            <small style={{ color: "#BDC3CC" }} className="ml-4 mt-2">
              {convertDate(document.createdOn)}
            </small>
          </Row>
          <Row>{lastThreeCharacters(document.documentUrl)}</Row>
          <div className="ml-2 mt-3">
            <p
              style={{
                fontWeight: "700",
                fontSize: "12px",
                lineHeight: "1.1em",
              }}
            >
              {document.title} <br />
              <small
                style={{
                  lineHeight: "0em",
                  color: "#BDC3CC",
                  marginTop: "5px",
                }}
              >
                {document.stageRequiredDocumentTitle}
              </small>
            </p>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default CycleCard;
