/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from "react";
import "../common.scss";
import "./bio.scss";
import { FormField, Select } from "src/modules/onboarding/components";
import PhoneInput from "react-phone-input-2";
import { FaUpload, FaMale, FaFemale, FaSave } from "react-icons/fa";
import { SelectBox } from "src/modules/Application/Flagship/personalInfo/components";

import {
  retrieveTempUserApplication,
  storeTempUserApplication,
  _setSteps,
} from "src/modules/Application/helper";
import { personalInfo } from "src/modules/Application/Flagship/personalInfo/personanlInfoObj";
import { Context } from "src/store";
import { displayError } from "src/modules/onboarding/helper";
import {
  formatMaxBirthYear,
  validateBirthYear,
} from "src/modules/onboarding/stepTwo/Sections";
import "react-phone-input-2/lib/style.css";
import { degreeTypes } from "src/modules/Application/Flagship/personalInfo/degreeTypes.js";
import { idTypes } from "src/modules/Application/Flagship/personalInfo/idTypes.js";
import { getCountryAlpha2Code } from "src/modules/Application/Flagship/personalInfo/helper";
import Loader from "react-loader-spinner";
import CLoader from "src/modules/Application/components/CLoader";
import { howYouHearAboutUs } from "src/modules/Application/Flagship/personalInfo/howYouHeardAboutUs";
import queryString from "query-string";
import { UploadFile, isImageFile } from "src/requests/fileUploadRequest";
import { toast } from "react-toastify";
import InstructionModal from "src/modules/Application/InstructionModal/InstructionModal";
import {
  instructions,
  sectionOneTranslations,
} from "src/modules/Application/instructions";
import { bioInfo } from "src/modules/Application/personalInfo";
import { useTranslation } from "react-i18next";
import {
  CustomCountryDropdown,
  CustomCountryRegionDropdown,
} from "src/components/CustomCountryRegionDropdown";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { DatePickerInput } from "rc-datepicker";
import { updateUserProfile } from "src/requests/ProfileRequest";
import { refreshToken } from "src/utils/someTokenMenthods";
import { ageRange } from "src/modules/Application/Flagship/personalInfo/ageRange";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDisabilityList } from "src/modules/Application/applicationHooksAll/useDisabilityList";

const setExistingInfo = (info, profile) => {
  info = JSON.parse(JSON.stringify(info));
  const profileInfo = [
    "gender",
    "dateOfBirth",
    "yearsOfExperience",
    "state",
    "city",
    "address",
    "imageUrl",
    "firstName",
    "lastName",
    "email",
    "levelOfEducation",
  ];

  // userInfo.forEach((uInfo) => {
  //   if (!info[uInfo]) {
  //     info[uInfo] = user[uInfo];
  //   }
  // });

  profileInfo.forEach((pInfo) => {
    if (!info[pInfo]) {
      info[pInfo] = profile[pInfo];
    }
  });

  if (!info.phoneNumber) {
    info.phoneNumber = profile.phone;
  }

  if (!info.dateOfBirth) {
    info.dateOfBirth =
      new Date(profile.dateOfBirth) === "Invalid Date" // ||
        ? new Date(formatMaxBirthYear(18))
        : profile.dateOfBirth;
  }

  if (!info.countryId) {
    info.countryId = getCountryAlpha2Code(profile.country);
  }

  return info;
};
const Bio = (props) => {
  const { applicationId, endDate, lang } = queryString.parse(
    props.history.location.search
  );
  // const [info] = useState(personalInfo);
  const [isPersonalInfoInit, setIsPersonalInfoInit] = useState(false);
  const { dispatch } = useContext(Context);
  const [language, setLanguage] = useState(lang);
  const [isSavingAndContinue, setIsSavingAndContinue] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const ref = useRef();
  const [fileError, setFileError] = useState("");
  const [isuploadingId, setIsUploadingId] = useState(false);

  const [showInstructionModal, setShowInstructionModal] = useState(true);
  const instructionObj = instructions[language || "en"] || instructions.en;
  const bioObj = bioInfo[language || "en"] || bioInfo.en;
  const aboutUs = howYouHearAboutUs[language || "en"] || howYouHearAboutUs.en;
  const idtypes = idTypes[language || "en"] || idTypes.en;
  const degreeObj = degreeTypes[language || "en"] || degreeTypes.en;
  const ageRangeObj = ageRange[language || "en"] || ageRange.en;
  const { t, i18n } = useTranslation();
  const [updatingMessage, setUpdatingMessage] = useState("");

  const schema = Yup.object({
    firstName: Yup.string().required(t("Please enter first name")),
    lastName: Yup.string().required(t("Please enter last name")),
    middleName: Yup.string().required(t("Please enter middle name")),
    gender: Yup.string().required(t("Please select gender")),
    ageRange: Yup.string().required(t("Please select age range")),
    email: Yup.string().email(),
    phoneNumber: Yup.string()
      .min(7, "Please enter valid number")
      .required(t("Please enter valid number")),
    levelOfEducation: Yup.string().required(
      t("Please select level of education")
    ),
    countryId: Yup.string().required(t("Please select country of residence")),
    state: Yup.string().required(t("Please select region")),
    city: Yup.string().required(t("please enter city of residence")),
    address: Yup.string().required(t("please enter residential address")),
    isResidentialRural: Yup.boolean().required(t("Please select")),
    appliedForOtherFoundationGrant: Yup.boolean().required(t("Please select")),
    nameOfOrganization: Yup.string().when("appliedForOtherFoundationGrant", {
      is: true,
      then: () => Yup.string().required(t("Please Enter name of organization")),
    }),
    typeOfId: Yup.string().required(
      t("Please select type of id you wish to upload")
    ),
    idNumber: Yup.string().required(t("Please enter ID Number")),
    idUrl: Yup.string().required(t("Please upload mode of identification")),
    howYouHearAboutUs: Yup.string().required(t("Please select")),
    isDisabledPerson: Yup.boolean().required(t("Please select")),
    // isDisabledPerson: Yup.string().required(t("Please select")),
    typeOfDisability: Yup.string().when("isDisabledPerson", {
      is: true,
      then: () => Yup.string().required(t("Please select")),
    }),

    otherDisabilities: Yup.string().when("typeOfDisability", {
      is: "Other disability",
      then: () =>
        Yup.string().required(t("Please enter other type of disability")),
    }),
    requiredAdjustmentToParticipateInTEF: Yup.string().when(
      "isDisabledPerson",
      {
        is: true,
        then: () => Yup.string().required(t("Please specify")),
      }
    ),
    isMemberOfYunitok: Yup.boolean().required(t("Please select")),
    isMemberOfYoma: Yup.boolean().required(t("Please select")),
    hasParticipatedInImaGenVenturesYouthChallenge: Yup.boolean().required(
      t("Please select")
    ),
  }).required();

  const { disabilityList } = useDisabilityList();

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    defaultValues: { ...personalInfo },
  });

  useEffect(() => {
    const errorsvalues = Object.values(errors);
    if (errorsvalues.length > 0) {
      const ref = errorsvalues?.[0]?.ref;
      if (ref) {
        document
          .getElementById(errorsvalues?.[0]?.ref.name)
          ?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [errors]);

  const toggleInstructionModal = () =>
    setShowInstructionModal(!showInstructionModal);

  const currentUser = useCurrentUser();

  useEffect(() => {
    const lng = lang || "en";
    setLanguage(lng);
    setValue("languageId", lng);
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
  }, [lang]);

  useEffect(() => {
    const query = props.history.location.search;
    if (query) {
      const parsedQuery = queryString.parse(query);
      if (Object.keys(parsedQuery).length === 0 || !parsedQuery.applicationId) {
        props.history.replace("/programme/dashboard", {});
        props.history.go(1);
      }
    } else {
      props.history.replace("/programme/dashboard", {});
      props.history.go(1);
    }
  }, [props.history.location.search]);

  useEffect(() => {
    window.scrollTo(0, 0);
    retrieveTempUserApplication(applicationId)
      .then((application) => {
        if (!application || application === null) {
          props.history.replace("/application/tour", {});
          props.history.go(1);
        } else {
          if (!application.applicationId) {
            props.history.replace("/home", {});
            props.history.go(1);
          } else {
            reset({ ...personalInfo, ...application });
            setIsPersonalInfoInit(true);
            return application;
          }
        }
      })
      .then((aplcn) => {
        const { endDate, applicationId, lang } = queryString.parse(
          window.location.search
        );
        const currentLocation =
          window.location.pathname +
          "?applicationId=" +
          applicationId +
          "&endDate=" +
          endDate +
          "&lang=" +
          lang;

        if (
          aplcn &&
          aplcn.steps.previousStep !== currentLocation &&
          aplcn.steps.nextStep !== ""
        ) {
          props.history.push(aplcn.steps.nextStep);
        }
      });
  }, []);

  useEffect(() => {
    if (currentUser && isPersonalInfoInit) {
      let application = { ...getValues() };
      application = setExistingInfo(application, currentUser);
      // setInfo(application);
      reset({ ...application });
    }
  }, [currentUser, isPersonalInfoInit]);

  const _onIdImageChange = (e) => {
    const files = e.target.files;

    if (files && files[0] && files[0].name && files[0].name.endsWith(".gif")) {
      setFileError("Invalid file format. Only jpeg and png are allowed");
      return;
    }
    if (isImageFile(files && files, false).hasError) {
      setFileError("Invalid file format. Only jpeg and png are allowed");
      return;
    }

    setFileError("");
    setIsUploadingId(true);
    setPreviewImage(files);
    UploadFile(dispatch, false, files)
      .then((res) => {
        const { files } = res;
        setValue("idUrl", files[0]);

        setIsUploadingId(false);
      })
      .catch((e) => {
        setIsUploadingId(false);
      });

    // .finally(() =>
    //   reset({ ...getValues(), idUrl: "https://placehold.co/600x400" })
    // );
    // the finally block above is left here for testing purpose as the endpoint to upolad file fail dev. DO NOT push this to dev or prod uncommented
  };

  const updateProfileAccount = (info) => {
    const profileDetails = [
      "gender",
      "dateOfBirth",
      "yearsOfExperience",
      "state",
      "city",
      "address",
      "firstName",
      "lastName",
      "email",
      "phoneNumber",
      "countryId",
      "levelOfEducation",
      // "languageId",
    ];
    let profileObj = {};
    profileDetails.forEach((key) => {
      if (info[key]) {
        profileObj = { ...profileObj, [key]: info[key] };
      }
    });
    if (!profileObj.phone) {
      profileObj.phone = info.phoneNumber;
    }

    if (!profileObj.country) {
      profileObj.country = info.countryId;
    }

    // if (!profileObj.language) {
    //   profileObj.language = info.languageId;
    // }

    delete profileObj.countryId;
    delete profileObj.phoneNumber;
    return updateUserProfile(dispatch, profileObj).then(() => {
      return refreshToken();
    });
  };

  const _onSubmit = (values) => {
    if (loadingSave) return;
    if (
      values.typeOfDisability.toLowerCase().includes("") &&
      values.otherDisabilities
    ) {
      values.typeOfDisability = values.otherDisabilities;
    }
    setLoadingSave(true);
    setUpdatingMessage(t("Saving your application progress..."));
    const appInfo = JSON.parse(JSON.stringify(values));
    const currentStep = window.location.pathname;
    const userInfo = _setSteps(
      appInfo,
      currentStep,
      `/application/personalinfo/experience?applicationId=${values.applicationId}&endDate=${endDate}&lang=${values.languageId}`,
      ""
    );

    // applicationId is misnamed - this is really programmeEventId
    // TODO(TP-2083): rename to programmeEventId.
    return storeTempUserApplication(userInfo, values.applicationId)
      .then((app) => {
        setUpdatingMessage(t("Updating profile information..."));
        return updateProfileAccount(userInfo).then(() => {
          setLoadingSave(false);
          setUpdatingMessage("");
          return props.history.push({
            pathname: "/application/personalinfo/experience",
            search: `?applicationId=${values.applicationId}&endDate=${endDate}&lang=${values.languageId}`,
          });
        });
      })
      .catch((e) => {
        setLoadingSave(false);
      });
    // _scrollToRef(ref);
  };

  const _saveAndContinue = (e) => {
    e.preventDefault();
    if (isSavingAndContinue) return;
    setIsSavingAndContinue(true);
    const appInfo = JSON.parse(JSON.stringify(getValues()));

    const currentStep =
      window.location.pathname +
      `?applicationId=${applicationId}&endDate=${endDate}&lang=${lang}`;
    const userInfo = _setSteps(appInfo, currentStep, currentStep, "");
    setUpdatingMessage("Saving your application progress...");
    // applicationId is misnamed - this is really programmeEventId
    // TODO(TP-2083): rename to programmeEventId.
    storeTempUserApplication(userInfo, userInfo.applicationId).then((app) => {
      setUpdatingMessage("Updating profile information...");
      return updateProfileAccount(userInfo).then(() => {
        toast(`${t("your application progress has been saved")}`);
        setIsSavingAndContinue(false);
        // _scrollToRef(ref);
        setUpdatingMessage("");
        window.scrollTo(0, 0);
      });
    });
  };

  return (
    <div className="application-personal-info-c notranslate">
      <div className="application-personal-info-c__heading">
        <h1>{t("TEF Entrepreneurship Application")}</h1>
        <p>{t("Application_Overview")}</p>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <span
          className="back-2-somewhere"
          onClick={() => props.history.replace("/home", {})}
        >
          {t("Back to Home")}
        </span>
      </div>
      <div className="application-personal-info-c__form">
        <div className="p-info-form-heading">
          <h5>
            <span className="bold">{bioObj.title1}</span>
            {/* <span className="little-badge">1 of 3</span> */}
          </h5>
          <h4>{sectionOneTranslations[language || "en"]}</h4>
        </div>
        <form ref={ref} onSubmit={handleSubmit(_onSubmit)}>
          <div className="row">
            <div className="col-md-6">
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      placeholder="e.g Jane"
                      label={bioObj.firstname}
                      id="firstName"
                      name={name}
                      value={value}
                      onChange={(_, value) => onChange(value)}
                      onBlur={onBlur}
                      error={error?.message}
                    />
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      placeholder="e.g Doe"
                      label={bioObj.lastname}
                      id="lastName"
                      name={name}
                      value={value}
                      onChange={(_, value) => onChange(value)}
                      onBlur={onBlur}
                      error={error?.message}
                    />
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>

          <Controller
            name="middleName"
            control={control}
            rules={{ required: true }}
            render={({
              field: { onChange, value, onBlur, name },
              fieldState: { error },
            }) => (
              <>
                <FormField
                  placeholder="e.g John doe"
                  label={bioObj.middleName}
                  id="middleName"
                  name={name}
                  value={value}
                  onChange={(_, value) => onChange(value)}
                  onBlur={onBlur}
                  error={error?.message}
                />
                <>{error?.message && displayError(`${t(error?.message)}`)}</>
              </>
            )}
          />

          <div className="" style={{ display: "flex", flexWrap: "wrap" }}>
            <div className="f1 dob">
              <Controller
                name="gender"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={bioObj.gender} id="gender">
                      <div
                        style={{
                          marginTop: "-1px",
                          display: "flex",
                          // flexWrap: "wrap",
                        }}
                      >
                        <SelectBox
                          value="male"
                          // className="selected-box"
                          selected={value}
                          icon={<FaMale />}
                          name={name}
                          onSelect={(_, value) => onChange(value)}
                          label={bioObj.male}
                        />
                        <SelectBox
                          value="female"
                          icon={<FaFemale />}
                          name={name}
                          selected={value}
                          onSelect={(_, value) => onChange(value)}
                          label={bioObj.female}
                        />
                      </div>
                    </FormField>

                    {error?.message && (
                      <div
                        className="displayError"
                        style={{ marginLeft: "5px" }}
                      >
                        {displayError(error?.message)}
                      </div>
                    )}
                  </>
                )}
              />
            </div>
            <div className="f1">
              <Controller
                name="dateOfBirth"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      placeholder="e.g Bond"
                      label={bioObj.birthday}
                      name="dateOfBirth"
                      id="dateOfBirth"
                    >
                      <div
                        className={`rc-dp${
                          error?.message ? " default-error-state" : ""
                        }`}
                      >
                        <DatePickerInput
                          className="default-error-state"
                          readOnly
                          maxDate={new Date(formatMaxBirthYear(18))}
                          startDate={new Date(formatMaxBirthYear(18))}
                          onChange={(_, dateString) =>
                            validateBirthYear(
                              name,
                              dateString,
                              (target, value) => {
                                onChange(value);
                              }
                            )
                          }
                          placeholder="MM/DD/YYYY"
                          onHide={onBlur}
                          value={value}
                          showOnInputClick
                          autoClose={false}
                        />
                      </div>
                    </FormField>
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Controller
                name="ageRange"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={bioObj.ageRange} id="ageRange">
                      <Select
                        list={ageRangeObj}
                        defaultVal={bioObj.select1}
                        name={name}
                        onChange={(_, value) => onChange(value)}
                        value={value}
                        onBlur={onBlur}
                        error={error?.message}
                      />
                    </FormField>
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Controller
                name="phoneNumber"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={bioObj.phoneNumber} id="phoneNumber">
                      <div style={{ marginTop: "7.5px" }}>
                        <PhoneInput
                          countryCodeEditable={false}
                          enableSearch
                          country={"ng"}
                          placeholder={t(
                            "Please provide a valid phone number i ideally it’s your WhatsApp number or another phone number for texts"
                          )}
                          inputClass="phone-input"
                          name={name}
                          onChange={(phone) => onChange(phone)}
                          autoFormat={false}
                          inputStyle={{
                            width: "100%",
                            marginTop: "8px 10px",
                            borderColor: error?.message ? "red" : "",
                          }}
                          onBlur={onBlur}
                          value={value}
                        />
                      </div>
                    </FormField>
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
            <div className="col-md-6" style={{ marginTop: "-7px" }}>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, name }, fieldState: { error } }) => (
                  <>
                    <FormField
                      type="email"
                      placeholder={bioObj.email}
                      label={bioObj.email}
                      name={name}
                      value={value}
                      disabled
                    />
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Controller
                name="levelOfEducation"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { value, name, onBlur, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={bioObj.education} id="levelOfEducation">
                      <Select
                        list={degreeObj}
                        defaultVal={bioObj.select2}
                        name={name}
                        onChange={(_, value) => onChange(value)}
                        value={value}
                        onBlur={onBlur}
                        error={error?.message}
                      />
                    </FormField>

                    <>
                      {error?.message && (
                        <div
                          className="displayError"
                          style={{ marginLeft: "5px" }}
                        >
                          {displayError(error?.message)}
                        </div>
                      )}
                    </>
                  </>
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Controller
                name="countryId"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { value, name, onBlur, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={bioObj.country} id="countryId">
                      <CustomCountryDropdown
                        classes={`flex country-state-list ${
                          error?.message ? "country-state-list-error-state" : ""
                        }`}
                        value={value}
                        name={name}
                        valueType="short"
                        onChange={(val) => {
                          onChange(val);
                          setValue("state", "");
                        }}
                        onBlur={onBlur}
                      />
                    </FormField>

                    <>
                      {error?.message && (
                        <div
                          className="displayError"
                          style={{ marginLeft: "3px" }}
                        >
                          {displayError(error?.message)}
                        </div>
                      )}
                    </>
                  </>
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                name="state"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { value, name, onBlur, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={bioObj.state} id="state">
                      <CustomCountryRegionDropdown
                        countryValueType="short"
                        defaultOptionLabel={bioObj.select2}
                        country={watch().countryId}
                        classes={`flex country-state-list ${
                          error?.message ? "country-state-list-error-state" : ""
                        }`}
                        name={name}
                        value={value}
                        onChange={(val) => onChange(val)}
                        onBlur={onBlur}
                      />
                    </FormField>

                    <>
                      {error?.message && (
                        <div
                          className="displayError"
                          style={{ marginLeft: "3px" }}
                        >
                          {displayError(error?.message)}
                        </div>
                      )}
                    </>
                  </>
                )}
              />
            </div>
          </div>
          <div>
            <Controller
              name="city"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, name, onBlur, onChange },
                fieldState: { error },
              }) => (
                <>
                  <FormField
                    label={bioObj.city}
                    placeholder={bioObj.cityPlaceholder}
                    value={value}
                    name={name}
                    onChange={(_, val) => onChange(val)}
                    id="city"
                    onBlur={onBlur}
                    error={error?.message}
                  />

                  <>{error?.message && displayError(error?.message)}</>
                </>
              )}
            />
          </div>
          <div>
            <Controller
              name="address"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, name, onBlur, onChange },
                fieldState: { error },
              }) => (
                <>
                  <FormField
                    label={bioObj.address}
                    type="textarea"
                    placeholder={t("Type in your residential address")}
                    value={value}
                    name={name}
                    onChange={(_, val) => onChange(val)}
                    id="address"
                    onBlur={onBlur}
                    error={error?.message}
                  />
                  <>{error?.message && displayError(error?.message)}</>
                </>
              )}
            />
          </div>
          <div className="row">
            <div className="col-md-8">
              <Controller
                name="isResidentialRural"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { value, name, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={bioObj.area} id="isResidentialRural">
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            selected={value}
                            onSelect={(_, val) => onChange(val)}
                            name={name}
                            label={t("Rural area")}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            selected={value}
                            onSelect={(_, val) => onChange(val)}
                            name={name}
                            label={t("Urban center")}
                          />
                        </div>
                      </div>
                    </FormField>
                    <>{error?.message && displayError(error?.message)}</>
                  </>
                )}
              />
            </div>
          </div>
          {/* fo begreen programme */}
          <div className="row">
            <div className="col-md-6">
              <Controller
                name="isDisabledPerson"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { value, name, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={t(
                        "Do you consider yourself as a person with disability?"
                      )}
                      id="isDisabledPerson"
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            selected={value}
                            onSelect={(_, val) => onChange(val)}
                            name={name}
                            label={bioObj.yes}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            selected={value}
                            onSelect={(_, val) => {
                              onChange(val);
                              reset({
                                ...getValues(),
                                otherDisabilities: "",
                                typeOfDisability: "",
                              });
                            }}
                            name={name}
                            label={bioObj.no}
                          />
                        </div>
                      </div>
                    </FormField>
                    <>{error?.message && displayError(error?.message)}</>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Controller
                name="typeOfDisability"
                control={control}
                render={({
                  field: { onChange, value, onBlur, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={t("If yes, please select any of the following")}
                      id="typeOfDisability"
                    >
                      <Select
                        disabled={!watch().isDisabledPerson}
                        list={disabilityList}
                        defaultVal={bioObj.select1}
                        name={name}
                        onChange={(_, value) => {
                          onChange(value);
                          if (!value.toLowerCase().includes("other")) {
                            setValue("otherDisabilities", "");
                          }
                        }}
                        value={value}
                        onBlur={onBlur}
                        error={error?.message}
                      />
                    </FormField>
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>

          {watch().typeOfDisability.toLowerCase().includes("other") && (
            <Controller
              name="otherDisabilities"
              control={control}
              render={({
                field: { onChange, value, onBlur, name },
                fieldState: { error },
              }) => (
                <>
                  <FormField
                    label={t("Please enter other type of disability")}
                    id="otherDisabilities"
                    onChange={(_, val) => onChange(val)}
                    value={value}
                    onBlur={onBlur}
                    name={name}
                  ></FormField>
                  <>{error?.message && displayError(`${t(error?.message)}`)}</>
                </>
              )}
            />
          )}

          <Controller
            name="requiredAdjustmentToParticipateInTEF"
            control={control}
            render={({
              field: { onChange, value, onBlur, name },
              fieldState: { error },
            }) => (
              <>
                <FormField
                  label={t(
                    "As a person with disability, what form of reasonable accommodation or adjustment will you require to meaningfully participate in the TEF programme if selected"
                  )}
                  id="requiredAdjustmentToParticipateInTEF"
                  onChange={(_, val) => onChange(val)}
                  value={value}
                  onBlur={onBlur}
                  name={name}
                  type={"textarea"}
                  disabled={!watch().isDisabledPerson}
                ></FormField>
                <>{error?.message && displayError(`${t(error?.message)}`)}</>
              </>
            )}
          />

          <Controller
            name="isMemberOfYunitok"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <>
                <FormField
                  label={t("Are you a member of Yunitok/ U-Report")}
                  id="isMemberOfYunitok"
                >
                  <div className="row">
                    <div className="col-sm-3 gutter-v">
                      <SelectBox
                        value={true}
                        selected={value}
                        onSelect={(_, val) => onChange(val)}
                        name={name}
                        label={bioObj.yes}
                      />
                    </div>
                    <div className="col-sm-3 gutter-v">
                      <SelectBox
                        value={false}
                        selected={value}
                        onSelect={(_, val) => {
                          onChange(val);
                        }}
                        name={name}
                        label={bioObj.no}
                      />
                    </div>
                  </div>
                </FormField>
                <>{error?.message && displayError(`${t(error?.message)}`)}</>
              </>
            )}
          />

          <div className="row mt-3 px-2">
            <div className="col-sm-12 text-info">
              <p>{t("If no, send the word join to 20204")}</p>
            </div>
          </div>

          <Controller
            name="isMemberOfYoma"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <>
                <FormField
                  label={t("Are you a member of Yoma")}
                  id="isMemberOfYoma"
                >
                  <div className="row">
                    <div className="col-sm-3 gutter-v">
                      <SelectBox
                        value={true}
                        selected={value}
                        onSelect={(_, val) => onChange(val)}
                        name={name}
                        label={bioObj.yes}
                      />
                    </div>
                    <div className="col-sm-3 gutter-v">
                      <SelectBox
                        value={false}
                        selected={value}
                        onSelect={(_, val) => {
                          onChange(val);
                        }}
                        name={name}
                        label={bioObj.no}
                      />
                    </div>
                  </div>
                </FormField>
                <>{error?.message && displayError(`${t(error?.message)}`)}</>
              </>
            )}
          />

          <div className="row mt-3 px-2">
            <div className="col-sm-12 text-info">
              <p onClick={() => window.open("http://www.Yoma.world", "_blank")}>
                {t("If no, signup at www.Yoma.world")}
              </p>
            </div>
          </div>

          <Controller
            name="hasParticipatedInImaGenVenturesYouthChallenge"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <>
                <FormField
                  label={t(
                    "Did you participate in imaGen Ventures Youth Challenge"
                  )}
                  id="hasParticipatedInImaGenVenturesYouthChallenge"
                >
                  <div className="row">
                    <div className="col-sm-3 gutter-v">
                      <SelectBox
                        value={true}
                        selected={value}
                        onSelect={(_, val) => onChange(val)}
                        name={name}
                        label={bioObj.yes}
                      />
                    </div>
                    <div className="col-sm-3 gutter-v">
                      <SelectBox
                        value={false}
                        selected={value}
                        onSelect={(_, val) => {
                          onChange(val);
                        }}
                        name={name}
                        label={bioObj.no}
                      />
                    </div>
                  </div>
                </FormField>
                <>{error?.message && displayError(`${t(error?.message)}`)}</>
              </>
            )}
          />

          {/* fo begreen programme */}

          <div className="row">
            <div className="col-md-6">
              <Controller
                name="appliedForOtherFoundationGrant"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { value, name, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={bioObj.applied}
                      id="appliedForOtherFoundationGrant"
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            selected={value}
                            onSelect={(_, value) => onChange(value)}
                            name={name}
                            label={bioObj.yes}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            selected={value}
                            onSelect={(_, value) => {
                              onChange(value);
                              setValue("nameOfOrganization", "");
                            }}
                            name={name}
                            label={bioObj.no}
                          />
                        </div>
                      </div>
                    </FormField>
                    <>{error?.message && displayError(error?.message)}</>
                  </>
                )}
              />
            </div>
          </div>
          <div>
            <Controller
              name="nameOfOrganization"
              control={control}
              render={({
                field: { value, name, onChange, onBlur },
                fieldState: { error },
              }) => (
                <>
                  <FormField
                    label={bioObj.ifYes}
                    placeholder={bioObj.orgName}
                    name={name}
                    value={value}
                    onChange={(_, val) => onChange(val)}
                    id="nameOfOrganization"
                    disabled={!watch().appliedForOtherFoundationGrant}
                    onBlur={onBlur}
                    error={error?.message}
                  />

                  <>{error?.message && displayError(error?.message)}</>
                </>
              )}
            />
          </div>
          <div className="row">
            <div className="col-md-6">
              <Controller
                name="typeOfId"
                control={control}
                render={({
                  field: { value, name, onChange, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={bioObj.typeOfId} id="typeOfId">
                      <Select
                        list={idtypes}
                        name={name}
                        value={value}
                        onChange={(_, val) => onChange(val)}
                        onBlur={onBlur}
                        error={error?.message}
                      />
                    </FormField>

                    <>{error?.message && displayError(error?.message)}</>
                  </>
                )}
              />
            </div>

            <div className="col-md-6" style={{ marginTop: "-5px" }}>
              <Controller
                name="idNumber"
                control={control}
                render={({
                  field: { value, name, onChange, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={bioObj.idNumber}
                      id="idNumber"
                      type="text"
                      onChange={(_, val) => onChange(val)}
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      placeholder={bioObj.idNumber}
                    ></FormField>
                    <>{error?.message && displayError(error?.message)}</>
                  </>
                )}
              />
            </div>
          </div>

          <Controller
            name="idUrl"
            control={control}
            render={({
              field: { value, name, onBlur },
              fieldState: { error },
            }) => (
              <>
                <FormField
                  label={bioObj.idScan}
                  type="file"
                  name={name}
                  id="idUrl"
                  value={previewImage}
                  onChange={_onIdImageChange}
                  onBlur={onBlur}
                  error={error?.message}
                >
                  <div>
                    <div>
                      <div
                        className="id-upload-btn col-md-5"
                        onClick={() =>
                          document.getElementById("previewImage").click()
                        }
                      >
                        <span>
                          <FaUpload color="white" size={12} />
                        </span>
                        <span>{t("Choose file")}</span>
                        <span>
                          {isuploadingId && (
                            <Loader
                              type="Oval"
                              height={13}
                              width={13}
                              color="white"
                            />
                          )}
                        </span>
                      </div>
                    </div>
                    <input
                      type="file"
                      // value={previewImage}
                      name="previewImage"
                      id="previewImage"
                      onChange={_onIdImageChange}
                      className="id-file-upload"
                      accept="image/x-png,image/jpeg"
                    />
                    {/* <span>loading</span> */}
                  </div>

                  {watch().idUrl && (
                    <div className="id-upload" data-testid="id-picture">
                      <img
                        src={value}
                        alt="avatar"
                        // className="img-fluid"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  )}
                </FormField>
                {fileError && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {fileError}
                  </span>
                )}

                <>{error?.message && displayError(error?.message)}</>
              </>
            )}
          />

          <div>
            <Controller
              name="howYouHearAboutUs"
              control={control}
              render={({
                field: { value, name, onChange, onBlur },
                fieldState: { error },
              }) => (
                <>
                  <FormField label={bioObj.programme} id="howYouHearAboutUs">
                    <Select
                      list={aboutUs}
                      name={name}
                      value={value}
                      onChange={(_, val) => onChange(val)}
                      onBlur={onBlur}
                      error={error?.message}
                    />
                  </FormField>

                  <>{error?.message && displayError(error?.message)}</>
                </>
              )}
            />
          </div>
          <br />
          <div className="submit-box">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <button
                  className="submit-btn"
                  // onClick={_onSubmit}
                  data-testid="bio-next"
                >
                  <span>{bioObj.continue}</span>
                  {loadingSave && (
                    <Loader type="Oval" color="white" height={15} width={15} />
                  )}
                </button>
              </div>
              <div className="col-md-6 col-sm-12">
                <button
                  className="save-continue-btn"
                  onClick={_saveAndContinue}
                >
                  {isSavingAndContinue ? (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        outline: "none",
                      }}
                    >
                      <span> {t("saving progress")}</span>
                      <span>
                        <Loader
                          type="Oval"
                          color="red"
                          height={15}
                          width={15}
                        />
                      </span>
                    </span>
                  ) : (
                    <span>
                      <span>
                        <FaSave />{" "}
                      </span>{" "}
                      {t("Save to continue later")}
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <CLoader
        loading={!isPersonalInfoInit || loadingSave || isSavingAndContinue}
        additonalInfo={updatingMessage}
      />
      <InstructionModal
        open={showInstructionModal}
        toggle={toggleInstructionModal}
        instructionsObj={instructionObj}
      />
    </div>
  );
};
export default Bio;
