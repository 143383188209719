import React, { useState, useContext } from "react";
import check from "src/assets/img/signup/check.svg";
import "../ResetPassword/ResetPassword.scss";
import "./ConfirmationMessage.scss";
import { Link } from "react-router-dom";
import { reSendLink } from "src/requests/AuthRequest";
import { Context } from "src/store";
import Loader from "src/components/Loader/Loader";
// import NewAuthLayout from "src/Auth/NewLayout";
import NewAuthLayout_v2 from "src/Auth/NewLayout/NewAuthLayout_v2";

const ConfirmationMessage = (props) => {
  const { email } = props.match.params;
  const { dispatch } = useContext(Context);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleResendEmail = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const userInfo = {
      email: email,
    };

    reSendLink(dispatch, userInfo).then((res) => {
      setIsLoading(false);
      const { status } = res;
      if (status === 200) {
        setSuccess(true);
      }
    });
  };

  return (
    <NewAuthLayout_v2>
      <div className="main-wrapper__form-div">
        <div className="">
          <div className="check-div" data-testid="success">
            <img src={check} className="check-div__check" alt="check" />
            <p className="message-1">
              A confirmation email has been sent to{" "}
              <span className="message-1__email">{email}</span>
            </p>
            <p className="message-2">
              If you do not receive any email from us within 30 minutes, please
              check your SPAM folder.
            </p>

            <div className="action-btn">
              <button
                disabled={success || isLoading}
                className="message-button resend-btn"
                onClick={handleResendEmail}
              >
                {isLoading ? (
                  <Loader color="danger" />
                ) : success ? (
                  "Sent"
                ) : (
                  "Resend Link"
                )}
              </button>
              <Link to="/login" className="message-button">
                Back to login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </NewAuthLayout_v2>
  );
};

export default ConfirmationMessage;
