import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GET_PERSONALITY_QUESTIONS,
  FETCH_APPLICATION_FAQ,
  GET_MCQ_QUESTIONS,
  GET_USER_APPLICATIONS,
  SUBMIT_DECLARATION,
  SUBMIT_ASSESSMENT,
  VERIFY_RESULT,
} from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import { ApplicationUrl } from "src/constants";

const questionGroupIds = {
  personality: "c45b1237-d975-4b32-9488-a5a9d7234ac8",
  mcq: "61fa54b9-c720-4d2f-ab67-ece1da2b0e2e",
};

export const extractq = (qns) => {
  let questions = qns.find((q) => q.answerableQuestions.length > 0) || [];

  if (questions) {
    questions = questions.answerableQuestions;
  } else {
    questions = [];
  }
  return questions;
};

export const getApplicationFaq = (dispatch) => {
  dispatch(asyncActions(FETCH_APPLICATION_FAQ).loading(true));
  axios
    .get(`${ApplicationUrl.FETCH_APPLICATION_FAQ_URL}`)
    .then((response) => {
      if (response.status === 200) {
        dispatch(
          asyncActions(FETCH_APPLICATION_FAQ).success(response.data.data)
        );
        dispatch(asyncActions(FETCH_APPLICATION_FAQ).loading(false));
      }
    })
    .catch((error) =>
      dispatch(asyncActions(FETCH_APPLICATION_FAQ).failure(true, error))
    );
};

export const getPersonalityQuestions = (dispatch, appId, language) => {
  axios.defaults.headers.common["application-id"] = appId;
  dispatch(asyncActions(GET_PERSONALITY_QUESTIONS).loading(true));
  return axios
    .get(
      `${ApplicationUrl.GET_PERSONALITY_QUESTIONS_URL}/${questionGroupIds.personality}/${appId}/testquestions?language=${language}`
    )
    .then((response) => {
      if (response.status === 200) {
        dispatch(
          asyncActions(GET_PERSONALITY_QUESTIONS).success(response.data.data)
        );

        localStorage.personality = JSON.stringify(extractq(response.data.data));
        dispatch(asyncActions(GET_PERSONALITY_QUESTIONS).loading(false));
      }
    })
    .catch((error) =>
      dispatch(asyncActions(GET_PERSONALITY_QUESTIONS).failure(true, error))
    );
};

export const getMcqQuestions = (dispatch, appId, language) => {
  axios.defaults.headers.common["application-id"] = appId;

  dispatch(asyncActions(GET_MCQ_QUESTIONS).loading(true));
  return axios
    .get(
      `${ApplicationUrl.GET_MCQ_QUESTIONS_URL}/${questionGroupIds.mcq}/${appId}/testquestions?language=${language}`
    )
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(GET_MCQ_QUESTIONS).success(response.data.data));
        localStorage.mcq = JSON.stringify(
          response.data.data.answerableQuestions
        );
        dispatch(asyncActions(GET_MCQ_QUESTIONS).loading(false));
      }
    })
    .catch((error) =>
      dispatch(asyncActions(GET_MCQ_QUESTIONS).failure(true, error))
    );
};

export const getUserApplications = (dispatch, userId) => {
  dispatch(asyncActions(GET_USER_APPLICATIONS).loading(true));
  return axios
    .get(`${ApplicationUrl.GET_USER_APPLICATIONS}/${userId}`)
    .then((response) => {
      if (response.status === 200) {
        dispatch(
          asyncActions(GET_USER_APPLICATIONS).success(response.data.data)
        );
        dispatch(asyncActions(GET_USER_APPLICATIONS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_USER_APPLICATIONS).failure(true, error));
      dispatch(asyncActions(GET_USER_APPLICATIONS).loading(false));
    });
};
export const submitDeclaration = (dispatch, payload, applicationId) => {
  axios.defaults.headers.common["application-id"] = applicationId;

  dispatch(asyncActions(SUBMIT_DECLARATION).loading(true));
  return axios({
    method: "post",
    url: ApplicationUrl.SUBMIT_DECLARATION_URL,
    data: payload,
  })
    .then((response) => {
      if (response.status === 201) {
        dispatch(asyncActions(SUBMIT_DECLARATION).success(response.data));
        dispatch(asyncActions(SUBMIT_DECLARATION).loading(false));
      }
      return response;
    })
    .catch((error) =>
      dispatch(asyncActions(SUBMIT_DECLARATION).failure(true, error))
    );
};

export const submitAssessment = (dispatch, payload, time, applicationId) => {
  axios.defaults.headers.common["application-id"] = applicationId;

  dispatch(asyncActions(SUBMIT_ASSESSMENT).loading(true));
  return axios({
    method: "patch",
    url: `${ApplicationUrl.SUBMIT_ASSESSMENT_URL}/${time}`,
    data: payload,
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(asyncActions(SUBMIT_ASSESSMENT).success(response.data));
        dispatch(asyncActions(SUBMIT_ASSESSMENT).loading(false));
      }
      return response;
    })
    .catch((error) =>
      dispatch(asyncActions(SUBMIT_ASSESSMENT).failure(true, error))
    );
};

export const verifyResult = (dispatch, applicationId) => {
  axios.defaults.headers.common["application-id"] = applicationId;

  dispatch(asyncActions(VERIFY_RESULT).loading(true));
  return axios({
    method: "get",
    url: ApplicationUrl.VERIFY_RESULT_URL,
  })
    .then((response) => {
      dispatch(asyncActions(VERIFY_RESULT).success(response.data));
      dispatch(asyncActions(VERIFY_RESULT).loading(false));
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(VERIFY_RESULT).failure(true, error));
    });
};

export const getFaq = () => {
  return axios({
    method: "GET",
    url: `${ApplicationUrl.FETCH_APPLICATION_FAQ_URL}`,
  });
};

export const updateApplication = (
  dispatch,
  payload,
  applicationId,
  userId,
  showMessage = true,
  message
) => {
  axios.defaults.headers.common["application-id"] = applicationId;
  return axios({
    method: "patch",
    url: `${ApplicationUrl.UPDATE_USER_APPLICATION}/${applicationId}`,
    data: payload,
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        getUserApplications(dispatch, userId).then(() => {
          showMessage && message && toast(message);
        });
      }
      return response;
    })
    .catch((error) => {
      console.log(error.response);
      toast("We could not update your record");
      return error.response;
    });
};
