import React, { Fragment } from "react";
import { Row, Col } from "reactstrap";
import "./ArticleNotFound.scss";
import Empty from "src/assets/img/mentorship-empty.png";

const ArticleNotFound = () => {
  return (
    <Fragment>
      <Row>
        <Col md={12} sm={12}>
          <div className="text-center">
            <img src={Empty} alt="Not found" style={{ width: "50%" }} />
            <div>
              <h5 className="font-weight-bolder">
                Couldn&apos;t find anything
              </h5>
            </div>
            <div>
              <p
                className="font-weight-bold"
                style={{ color: "#919ba8", fontSize: "14px" }}
              >
                Got some nice articles? You can publish your articles on TEF
                Connect
              </p>
            </div>
            <div>
              <button className="publish-btn">Publish on TEFConnect</button>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ArticleNotFound;
