import React, { useContext } from "react";
import { _getStatusColor } from "src/modules/mentorship/components/GoalDetails/GoalDetails";
import { FiTrash } from "react-icons/fi";
import { TiPencil } from "react-icons/ti";
import { updateGoalStatus, deleteGoal } from "src/requests/GoalRequests";
import { Context } from "src/store";
import Loader from "react-loader-spinner";
import { useOuterClick, onHandleDelete } from "src/utils/helper";

export const GoalUpdateCard = (props) => {
  return (
    <>
      {props.show ? (
        <div className="goal-update-card" id="mouse-down">
          {props.children}
        </div>
      ) : null}
    </>
  );
};

export const UpdateGoalStatus = ({ setShowCard, goalId, loading }) => {
  // const status = [1001, 1002, 1003];
  const { dispatch } = useContext(Context);

  const status = {
    inProgress: 1001,
    underReview: 1003,
    completed: 1002,
  };

  const updateStatus = (status) => {
    updateGoalStatus(dispatch, goalId, status).then(() => {
      setShowCard(false);
    });
  };

  const outterClick = useOuterClick(() => {
    setShowCard(false);
  });

  return (
    <div
      className="goal-status-update-card-items"
      id="mouse-down-update"
      ref={outterClick}
    >
      {loading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader type="Rings" color="var(--tef-red)" height={30} width={30} />
        </div>
      )}
      <div className="goal-status-update-head">
        <span>Select Status</span>
        <span style={{ cursor: "pointer" }} onClick={setShowCard}>
          X
        </span>
      </div>
      <div className="status-list">
        {/* <span
          onClick={() => {
            updateStatus(status.inProgress);
          }}
          className={`status-list__item ${_getStatusColor("Not started")}`}
        >
          Not Started
        </span> */}
        <span
          onClick={() => {
            updateStatus(status.underReview);
          }}
          className={`status-list__item ${_getStatusColor("under review")}`}
        >
          Under Review
        </span>
        <span
          onClick={() => {
            updateStatus(status.completed);
          }}
          className={`status-list__item ${_getStatusColor("Completed")}`}
        >
          Completed
        </span>
      </div>
    </div>
  );
};

export const GoalMoreItem = ({ setShowCard, goalId, loading, toggle }) => {
  const { dispatch } = useContext(Context);

  const outterClick = useOuterClick(() => {
    setShowCard(false);
  });

  const _deleteGoal = () => {
    // deleteGoal(dispatch, goalId).then(() => {
    //   setShowCard(false);
    // });
    onHandleDelete(deleteGoal, dispatch, goalId);
  };

  return (
    <div className="goal-more-list" id="mouse-down" ref={outterClick}>
      {loading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader type="Rings" color="var(--tef-red)" height={30} width={30} />
        </div>
      )}
      <div className="goal-status-update-head">
        <span></span>
        <span style={{ cursor: "pointer" }} onClick={() => setShowCard(false)}>
          X
        </span>
      </div>
      <div className="goal-more-list__item" onClick={toggle}>
        <TiPencil
          size={"14px"}
          color="#26292d"
          style={{ marginRight: "10px", alignSelf: "center" }}
        />{" "}
        Edit
      </div>
      {/* <div className="goal-more-list__item" onClick={() => onHandleDelete()}> */}
      <div className="goal-more-list__item" onClick={_deleteGoal}>
        {/* <span> */}
        <FiTrash
          size={"14px"}
          color="#26292d"
          style={{ marginRight: "10px", alignSelf: "center" }}
        />{" "}
        {/* </span> */}
        Delete
      </div>
    </div>
  );
};
