import React from "react";
import classes from "./Tags.module.css";

const Tags = ({ tags }) => {
  return (
    <>
      {tags &&
        tags.map((tag) => (
          <span className={classes.TagContainer} key={tag.id}>
            <span className={classes.TagTitle}>{tag.name}</span>
          </span>
        ))}
    </>
  );
};

export default Tags;
