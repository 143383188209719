import { asyncActionName } from "src/utils/AsyncUtils";
import { initialState } from "src/store";
import {
  CREATE_STORY,
  GET_ALL_STORY_INDUSTRIES,
  GET_ALL_STORY_COUNTRIES,
  CREATE_STORY_COMMENT,
} from "src/actions";

const DiscoverReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case asyncActionName(GET_ALL_STORY_INDUSTRIES).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_ALL_STORY_INDUSTRIES).success:
      return {
        ...state,
        industries: payload,
        success: true,
      };
    case asyncActionName(GET_ALL_STORY_INDUSTRIES).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_ALL_STORY_COUNTRIES).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_ALL_STORY_COUNTRIES).success:
      return {
        ...state,
        industries: payload,
        success: true,
      };
    case asyncActionName(GET_ALL_STORY_COUNTRIES).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(CREATE_STORY).loading:
      return { ...state, loading: payload };
    case asyncActionName(CREATE_STORY).success:
      return {
        ...state,
        group: payload,
        success: true,
      };
    case asyncActionName(CREATE_STORY).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(CREATE_STORY_COMMENT).loading:
      return { ...state, loading: payload };
    case asyncActionName(CREATE_STORY_COMMENT).success:
      return {
        ...state,
        group: payload,
        success: true,
      };
    case asyncActionName(CREATE_STORY_COMMENT).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    default:
      return state;
  }
};
export default DiscoverReducer;
