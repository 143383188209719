/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { Modal, ModalBody } from "reactstrap";
import TCmodal from "./TCmodal";
import styles from "./TCMain.module.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import OffCycleTC from "./OffCycleTC";
import { Context } from "src/store";
import { getProgrammeEventsContents } from "src/requests/ProgrammeEventRequest";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { Code } from "react-content-loader";

const MyCodeLoader = () => (
  <>
    <Code />
    <Code />
    <Code />
  </>
);

const TCMainIndex = ({ show, toggle }) => {
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState("");
  const { dispatch } = useContext(Context);
  const history = useHistory();
  const { programmeEventId, lang } = queryString.parse(history.location.search);
  const { t } = useTranslation();

  useEffect(() => {
    if (show) {
      const payload = {
        id: programmeEventId,
        lang: lang,
        type: "terms-conditions",
      };
      getProgrammeEventsContents(dispatch, payload).then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          const { data } = res.data;
          if (data.length > 0) {
            const { content } = data[0];
            setContent(content);
          }
        }
      });
    }
  }, [show]);

  return (
    <Modal
      size="lg"
      isOpen={show}
      toggle={toggle}
      contentClassName={styles.TCMain}
      backdrop="static"
    >
      <ModalBody>
        {isLoading ? (
          <MyCodeLoader />
        ) : content !== "" ? (
          <>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
            <div className={styles.TCMain__btn}>
              <Link
                className={styles.TCMain__rejectBtn}
                to="/programme/dashboard"
              >
                {t("Reject")}
              </Link>
              <button
                className={styles.TCMain__acceptBtn}
                onClick={toggle}
                data-testid="tc-accept"
              >
                {t("Accept")}
              </button>
            </div>
          </>
        ) : pathname.toLowerCase().includes("offcycle") ? (
          <OffCycleTC toggle={toggle} />
        ) : (
          <TCmodal toggle={toggle} />
        )}
      </ModalBody>
    </Modal>
  );
};

export default TCMainIndex;
