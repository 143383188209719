import PropTypes from "prop-types";
import React from "react";
import { Spinner } from "reactstrap";

const Loader = ({ color = "primary", size }) => {
  return (
    <div className="cr-page-spinner">
      <Spinner
        color={color}
        style={{ width: size && size, height: size && size }}
      />
    </div>
  );
};

Loader.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
  ]),
  size: PropTypes.number,
};

export default Loader;
