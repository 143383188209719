export const defaultUserInfo = {
  groupId: "", // id
  role: "",
  bio: "",
  industryRole: "",
  yearsOfExperience: 0,
  gender: "",
  maritalStatus: "",
  imageUrl: "",
  dateOfBirth: "", // date time
  countryPhoneCode: "",
  phone: "",
  language: "",
  languages: [],
  facebookUrl: "",
  twitterUrl: "",
  websiteUrl: "",
  youtubeUrl: "",
  instagramUrl: "",
  linkedinUrl: "",
  businessIndustry: "",
  businessName: "",
  businessWebsite: "",
  businessRevenue: 0,
  aboutBusiness: "",
  businessAddress: "",
  businessCountry: "",
  businessState: "",
  businessCity: "",
  country: "",
  state: "",
  city: "",
  address: "",
  levelOfEducation: "",
  userSkills: [
    // {
    //   // skillId: "" // id
    // }
  ],
  newSkills: [
    // {
    //   name: ""
    // }
  ],
  educations: [
    // {
    //   month: "",
    //   year: "",
    //   school: "",
    //   course: "",
    //   degreeType: ""
    // }
  ],
  achievements: [
    // {
    //   title: "",
    //   url: "",
    //   descripton: ""
    // }
  ],
  newMentorshipAreas: [
    // {
    //   area: ""
    // }
  ],
  userMentorshipAreas: [
    // {
    //   // mentorshipAreaId: "" //
    // }
  ],
  isTefMentor: false,
  newInterests: [
    // {
    //   name:""
    // }
  ],
  userInterests: [
    // {
    //   interestId:""
    // }
  ],
  tefMentor: {
    isAspiringMentor: true,
    title: "",
    country: "",
    organization: "",
    religion: "",
    profileUrl: "",
    mentorshipLanguage: [],
    languages: [],
    qualities: [],
    industries: [],
    isATefMentor: false,
    yearOfTefMentorship: "",
    recommendationLevel: 0,
    reasonForRating: "",
    noOfPeopleMentored: "",
    yearOfMentorship: "",
    isAvailableForMentoring: true,
    availabilityHour: "",
    motivation: "",
    maxNoOfEntrepreneurs: 0,
    howYouFindUs: "",
    isAvailableForOneYear: true,
    preferedModeOfCommunication: "",
  },
  investor: {
    role: [],
    previousInvestment: [],
    folioSize: "",
    experience: "",
    priorInvestment: [
      // {
      //   name: "",
      //   year: "",
      //   stage: "",
      // },
    ],
  },
  validationErrors: {
    bio: null,
    gender: null,
    dateOfBirth: null,
    phone: null,
    facebookUrl: null,
    twitterUrl: null,
    websiteUrl: null,
    linkedinUrl: null,
    businessWebsite: null,
    youtubeUrl: null,
    instagramUrl: null,
    businessIndustry: null,
    businessName: null,
    businessRevenue: null,
    aboutBusiness: null,
    businessAddress: null,
    businessCountry: null,
    businessState: null,
    businessCity: null,
    country: null,
    state: null,
    city: null,
    address: null,
    // url: null,
    profileUrl: null,

    // tefMentor
    title: null,
    organization: null,
    religion: null,
    mentorshipLanguage: null,
    languages: null,
    qualities: null,
    industries: null,
    yearOfTefMentorship: null,
    recommendationLevel: null,
    reasonForRating: null,
    noOfPeopleMentored: null,
    yearOfMentorship: null,
    availabilityHour: null,
    motivation: null,
    maxNoOfEntrepreneurs: null,
    howYouFindUs: null,
    preferedModeOfCommunication: null,

    // investor
    role: null,
    previousInvestment: null,
    folioSize: null,
  },

  steps: {
    completedSteps: [
      // { stepOne: "/onboarding", completed: false },
      // { stepTwo: "/onboarding/step-two", completed: false },
      // { steplast: "onboarding/interests", completed: false },
    ],
    previousStep: "",
    currentCompletedStep: "",
    nextStep: "",
  },
};
