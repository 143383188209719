import moment from "moment";
import React from "react";
import Empty from "src/modules/mentorship/Calendar/utils/Empty";
import { imageValid } from "src/modules/Discover/helper";
import classes from "./SearchPage.module.css";
import { useHistory } from "react-router-dom";

const SearchPage = ({ list }) => {
  const history = useHistory();
  const handlePageLoad = (id, type) => {
    switch (type) {
      case "tef_masterclass":
        history.push(`/discover/masterclass-details/${id}`);
        break;
      case "tef_article":
        history.push(`/discover/article/${id}`);
        break;
      case "tef_learningresource":
        history.push(`/discover/learning-resource/${id}`);
        break;
      case "tef_story":
        history.push(`/discover/story/${id}`);
        break;
      case "tef_news":
        history.push(`/discover/news/${id}`);
        break;
      default:
        break;
    }
  };
  return (
    <div>
      {list.length > 0 ? (
        list.map((item, index) => (
          <div
            key={index}
            className={classes.Item}
            onClick={() => handlePageLoad(item.id, item.type)}
          >
            <div className={classes.ItemImageContainer}>
              <img
                src={
                  imageValid(item.bannerUrl)
                    ? item.bannerUrl
                    : "https://cdn.pixabay.com/photo/2017/04/19/13/16/computer-2242264_1280.jpg"
                }
                alt="Item banner"
                className={classes.ItemImage}
              />
            </div>
            <div className={classes.Details}>
              <div className={classes.Title}>{item && item.title}</div>
              <div className="mb-1">
                <span>Posted on: </span>
                <span>{moment(item.createdOn).format("DD-MMM-YY")}</span>
              </div>
              <div className={classes.Description}>{item.shortDescription}</div>
            </div>
          </div>
        ))
      ) : (
        <Empty message="No result found" />
      )}
    </div>
  );
};

export default SearchPage;
