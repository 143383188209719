import React, { useContext, useEffect, useState } from "react";
import classes from "./Requests.module.scss";
import "../Main.scss";
// import classnames from "classnames";
import RequestItem from "./RequestItem";
import { Context } from "src/store";
import {
  getGroupInvitesSent,
  getGroupInvitesReceived,
} from "src/requests/StudyGroupsRequest";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import Loader from "react-loader-spinner";
import { getManyUserLightProfile } from "src/requests/ProfileRequest";
import ResultPagination from "src/modules/training/StudyGroups/components/ResultPagination/ResultPagination";

// import queryString from "query-string";

/**
 *
 *
 * @returns
 */
const Requests = () => {
  const { state, dispatch } = useContext(Context);
  const [active] = useState("received");
  // const toggleActive = (value) => setActive(value);
  const user = get_user_info_from_token().user;
  const [sentInvites, setSentInvites] = useState([]);
  // const { course } = queryString.parse(location.search);
  const [receivedInvites, setReceivedInvites] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [users_sents, set_users_sent] = useState([]);
  const [users_received, set_users_received] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [pagingSent, setPagingSent] = useState({
    hasNextPage: false,
    pageCount: 0,
    pageNumber: 1,
  });
  const [pagingReceived, setPagingReceived] = useState({
    hasNextPage: false,
    pageCount: 0,
    pageNumber: 1,
  });

  const _getSentInvites = (pageNumber) => {
    setisLoading(true);
    getGroupInvitesSent(dispatch, user.id, pageNumber)
      .then((res) => {
        if (
          ((res && res.status === 200) || res.status === 201) &&
          res.data.data
        ) {
          const userIds = _extractUserIds(res.data.data.data, "inviteeUserId");
          _getProfiles(userIds);
        }
        setisLoading(false);
      })
      .catch((e) => {
        console.log(e.response);
      });
  };

  const _getReceivedInvites = (pageNumber) => {
    setisLoading(true);
    getGroupInvitesReceived(dispatch, user.id, "pending_invite", pageNumber)
      .then((res) => {
        if (
          ((res && res.status === 200) || res.status === 201) &&
          res.data.data
        ) {
          const userIds = _extractUserIds(
            res.data.data.data,
            "inviteedByUserId"
          );
          _getProfiles(userIds);
        }
        setisLoading(false);
      })
      .catch((e) => {
        console.log(e.response);
      });
  };

  const methods = {
    sent: {
      getList: (pageNumber) => _getSentInvites(pageNumber),
      list: sentInvites,
      users: users_sents,
      setUsers: (values) => set_users_sent(values),
      paging: pagingSent,
    },
    received: {
      getList: (pageNumber) => _getReceivedInvites(pageNumber),
      list: receivedInvites,
      users: users_received,
      setUsers: (value) => set_users_received(value),
      paging: pagingReceived,
    },
  };

  const _extractUserIds = (list, field) => {
    return list.map((l) => {
      return l[field];
    });
  };

  const _getProfiles = (ids) => {
    setLoadingUsers(true);
    getManyUserLightProfile(ids).then((res) => {
      if (res.data && res.data.data && res.data.data.length > 0) {
        getMethods(active).setUsers(res.data.data);
      } else {
        getMethods(active).setUsers([]);
      }
      setLoadingUsers(false);
    });
  };

  const mapUserToGroup = (userId) => {
    return getMethods(active).users.find((u) => u.userId === userId);
  };

  const getMethods = (active) => {
    return methods[active];
  };

  useEffect(() => {
    const method = getMethods(active);
    method.getList(method.paging.pageNumber);
  }, [active]);

  useEffect(() => {
    if (state.Training.sentRequest) {
      setSentInvites(state.Training.sentRequest.data);
      setPagingSent({
        ...pagingSent,
        ...state.Training.sentRequest.pager,
      });
    }
  }, [state.Training.sentRequest]);

  useEffect(() => {
    if (state.Training.receivedRequest) {
      setReceivedInvites(state.Training.receivedRequest.data);
      setPagingReceived({
        ...pagingReceived,
        ...state.Training.receivedRequest.pager,
      });
    }
  }, [state.Training.receivedRequest]);

  const onPageChange = (value) => {
    value = value.selected + 1;
    getMethods(active).getList(value);
  };

  return (
    <div className={classes.RequestC}>
      <div className={classes.heading}>
        <h5>Requests</h5>
      </div>

      {/* <div className={classes.toggles}>
        <span
          className={classnames({ [`${classes.active}`]: active === "sent" })}
          onClick={() => toggleActive("sent")}
        >
          Sent
        </span>
        <span
          className={classnames({
            [`${classes.active}`]: active === "received",
          })}
          onClick={() => toggleActive("received")}
        >
          Received
        </span>
      </div> */}
      {(isLoading || loadingUsers) && (
        <Loader
          type="ThreeDots"
          height={35}
          width={35}
          color="var(--tef-red)"
          style={{ margin: "0 auto" }}
        />
      )}
      <div className={classes.listWrapper}>
        {getMethods(active).list.length > 0 &&
          getMethods(active).list.map((item) => {
            return (
              <RequestItem
                groupInfo={item}
                key={item.id}
                isSent={item.inviteedByUserId === user.id}
                // isSent={active === "sent"}
                getList={getMethods(active).getList}
                loadingUser={loadingUsers}
                userInfo={mapUserToGroup(
                  item.inviteedByUserId === user.id
                    ? item.inviteeUserId
                    : item.inviteedByUserId
                  // active === "sent" ? item.inviteeUserId : item.inviteedByUserId
                )}
                currentPage={getMethods(active).paging.pageNumber}
              />
            );
          })}
      </div>
      {!isLoading && getMethods(active).list.length === 0 && (
        <div>No available {`${active}`} request</div>
      )}
      {(getMethods(active).paging.hasNextPage ||
        getMethods(active).paging.hasPreviousPage) && (
        <ResultPagination
          pageCount={getMethods(active).paging.pageCount || 0}
          onSelect={onPageChange}
          pageRange={5}
          current={getMethods(active).paging.pageNumber || 1}
        />
      )}
    </div>
  );
};

export default Requests;
