import React, { useEffect, useContext, useState } from "react";
import { Context } from "src/store";
import { getAllProgrammes } from "src/requests/ProgrammeRequest";
import "./ProgramDashboard.scss";
import { DashboardCard } from "src/modules/Program/DashboardCard/DashboardCard";
import moment from "moment";
import queryString from "query-string";
import { useHistory, Link } from "react-router-dom";
// import classes from "src/modules/Program/ApplicantDataUpdate/ApplicationUpdate.module.scss";
import {
  isEligible,
  extractUserGroup,
} from "src/modules/Application/applicationHooksOld";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { useTranslation } from "react-i18next";
import Loader from "src/components/Loader/Loader";
import WalthroughWizard from "src/components/WalkthroughWizard/WalkthroughWizard";
// import { Alert } from "reactstrap";
// import PromptApplicantUpdate from "src/modules/Program/ApplicantDataUpdate/Prompt";
// import PromptApplicant2020Update from "src/modules/Program/ApplicantDataUpdate/Prompt2020Applicant";
import { getUserApplications } from "src/requests/ApplicationRequest";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

/**
 * Dashboard of user applications. Requires a full user profile saved in Redux state.
 */
const ProgramDashboard = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Context);
  let applicationDetails;
  const history = useHistory();
  const user = useCurrentUser();
  const [isLoading, setIsLoading] = useState(true);
  // const userTokenInfo = get_user_info_from_token().user;

  const { programmeId } = queryString.parse(history.location.search);
  const tourSteps = [
    {
      selector: ".about-program-0",
      content: `Application opens to all Entrepreneurs from January 1st to March 31st, 2020`,
    },
    {
      selector: ".apply-program-0",
      content: "Click here to apply",
    },
  ];
  const [isTourOpen, setIsTourOpen] = useState(false);
  const removeTour = () => {
    setIsTourOpen(false);
  };
  useEffect(() => {
    getAllProgrammes(dispatch).then((_) => {
      setIsLoading(false);
      const displayTour = localStorage.getItem("showProgramDashboardTour");
      const disableWalkthrough = process.env.REACT_APP_DISABLE_WALKTHROUGH;
      if (disableWalkthrough === "false") {
        if (!displayTour) {
          setIsTourOpen(true);
          localStorage.setItem("showProgramDashboardTour", 1);
        }
      }
    });
  }, []);

  /**
   * Fetch user applications in the background and saves into Redux state.
   */
  useEffect(() => {
    const userFromToken = get_user_info_from_token();
    if (userFromToken) {
      getUserApplications(dispatch, userFromToken.user.id);
    }
  }, []);

  const { programmes } = state.Programmes;
  if (programmes.length > 0) {
    if (programmeId) {
      applicationDetails = programmes
        .filter((prog) => prog.id === programmeId)
        .map((detail, index) => {
          const programmeEventsId = detail.programmeEvents.find(
            (programmeEvent) =>
              programmeEvent.eventType.name.toLowerCase() ===
              "application event"
          );
          return (
            <DashboardCard
              key={index}
              cardWidth="250px"
              title={detail.title}
              overview={detail.overview}
              startDate={moment(detail.startDate).format("LL")}
              endDate={moment(detail.endDate).format("LL")}
              id={detail.id}
              imageSrc={detail.image}
              programmeType={detail.programmeType}
              status={detail.status && detail.status.toLowerCase()}
              isEligible={isEligible(extractUserGroup(user), detail.userGroup)}
              programmeEventId={programmeEventsId ? programmeEventsId.id : null}
              removeTour={removeTour}
              isTourOpen={isTourOpen}
            />
          );
        });
    } else {
      applicationDetails = programmes.map((detail, index) => {
        const programmeEventsId = detail.programmeEvents.find(
          (programmeEvent) =>
            programmeEvent.eventType.name.toLowerCase() === "application event"
        );
        return (
          <DashboardCard
            key={index}
            index={index}
            cardWidth="250px"
            title={detail.title}
            overview={detail.overview}
            startDate={moment(detail.startDate).format("LL")}
            endDate={moment(detail.endDate).format("LL")}
            id={detail.id}
            imageSrc={detail.image}
            programmeType={detail.programmeType}
            status={detail.status && detail.status.toLowerCase()}
            isEligible={isEligible(extractUserGroup(user), detail.userGroup)}
            programmeEventId={programmeEventsId ? programmeEventsId.id : null}
            removeTour={removeTour}
            isTourOpen={isTourOpen}
          />
        );
      });
    }
  }

  return (
    <>
      {/* {!isLoading && <PromptApplicantUpdate />} */}
      {/* {!isLoading && <PromptApplicant2020Update />} */}
      {/* 
      {!Object.prototype.hasOwnProperty.call(userTokenInfo, "application") && (
        <div className={classes.alertClass}>
          <Alert color="grey">
            <strong>Feature Update:</strong> 2021 Applicants that have started
            their application (not submitted) and were unable to edit their
            information can now do so. Open your application form and edit as
            needed.
          </Alert>
        </div>
      )} */}

      {isLoading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader color="danger" />
        </div>
      ) : (
        <div>
          {programmeId && (
            <Link to="/programme/dashboard" className="view-all-prog">
              {t("View All")}
            </Link>
          )}

          <div className="program-dashboard">{applicationDetails}</div>
        </div>
      )}
      <WalthroughWizard
        tourStep={tourSteps}
        isOpen={isTourOpen}
        setIsTourOpen={setIsTourOpen}
      />
    </>
  );
};

export default React.memo(ProgramDashboard);
