import React, { Fragment } from "react";
import { Input, Row, Col, Label, FormGroup } from "reactstrap";

import { Select } from "src/components/CustomFormField/CustomFormField";
// import { industryTypes } from "src/onboarding/stepTwo/industryType.js";
// import { useTranslation } from "react-i18next";
import { CountryDropdown } from "react-country-region-selector";
import { DatePickerInput } from "rc-datepicker";
import "./opportunity.scss";
import ValidateJs from "src/utils/Validations/validation_wrapper";

const displayError = (error) => {
  // const { t } = useTranslation();
  return (
    <span
      style={{
        color: "red",
        marginTop: "-5px",
        marginLeft: "10px",
        fontFamily: "Poppins",
        fontSize: "12px",
      }}
    >
      {error}
    </span>
  );
};

const CreateOpportunityForm = ({
  categories,
  opportunityData,
  inputHandler,
  selectHandler,
  customHandler,
  validationErrors,
  _handleValidationErrors,
}) => {
  // const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  // const [language, setLanguage] = useState();
  // const industryObj = industryTypes[language || "en"];
  return (
    <Fragment>
      <div className="create-tefstory-form mt-3 p-3">
        <Row form>
          <Col sm={12} md={12}>
            <FormGroup>
              <Label className="create-event-label" htmlFor="storyTitle">
                Title
                <span className="required"> - Required</span>
              </Label>
              <div className="create-input">
                <Input
                  type="text"
                  name="title"
                  className="event__input no-border"
                  id="title"
                  placeholder="Opportunity Title"
                  maxLength={100}
                  onChange={inputHandler}
                  value={opportunityData.title}
                  onBlur={() =>
                    _handleValidationErrors(
                      "title",
                      ValidateJs("title", opportunityData.title)
                    )
                  }
                />
                <span className="input-maxlength count-length">
                  {opportunityData.title.length}/80
                </span>
              </div>

              {validationErrors.title && displayError(validationErrors.title)}
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={12} md={12}>
            <FormGroup>
              <Label className="create-event-label" htmlFor="storyDescription">
                Short description <span className="required"> - Required</span>
              </Label>
              <div className="create-input">
                <Input
                  type="text"
                  name="shortSummary"
                  id="shortSummary"
                  placeholder="A simple description of opportunity"
                  maxLength={100}
                  className="event__input no-border"
                  onChange={inputHandler}
                  value={opportunityData.shortSummary}
                  onBlur={() =>
                    _handleValidationErrors(
                      "shortSummary",
                      ValidateJs("shortSummary", opportunityData.shortSummary)
                    )
                  }
                />
                <span className="input-maxlength count-length">
                  {opportunityData.shortSummary.length}/100
                </span>
              </div>

              {validationErrors.shortSummary &&
                displayError(validationErrors.shortSummary)}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12}>
            <FormGroup>
              <Label className="create-event-label" htmlFor="storyDescription">
                Country <span className="required"> - Required</span>
              </Label>
              <CountryDropdown
                classes={`flex country-state-list no-border country-background-color`}
                value={opportunityData.country}
                name="country"
                id="country"
                valueType="short"
                onChange={(val) => customHandler("country", val)}
                onBlur={() =>
                  _handleValidationErrors(
                    "country",
                    ValidateJs("country", opportunityData.country)
                  )
                }
              />
              {validationErrors.country &&
                displayError(validationErrors.country)}
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col sm={12} md={6}>
            <FormGroup>
              <Label className="create-event-label" htmlFor="category">
                Category <span className="required">- Required</span>
              </Label>
              <Select
                defaultVal="Select Category"
                list={categories}
                value={opportunityData.categoryId}
                id="categoryId"
                name="categoryId"
                onChange={selectHandler}
                className="event__input no-border"
                onBlur={() =>
                  _handleValidationErrors(
                    "categoryId",
                    ValidateJs("categoryId", opportunityData.categoryId)
                  )
                }
              />
              {validationErrors.categoryId &&
                displayError(validationErrors.categoryId)}
            </FormGroup>
          </Col>
          <Col sm={12} md={6}>
            <Label htmlFor="articleTags">
              Deadline<span className="required"> - Required</span>
            </Label>
            <FormGroup label="Deadline*" id="deadline">
              <div style={{ marginTop: "8px" }}>
                <DatePickerInput
                  readOnly
                  style={{
                    border: "none",
                    backgroundColor: "#EFEFEF !important",
                  }}
                  className="no-border"
                  onChange={(val) => customHandler("deadline", val)}
                  value={opportunityData.deadline}
                  id="deadline"
                  minDate={new Date()}
                  placeholder="MM/DD/YYYY"
                  showOnInputClick
                  onBlur={() =>
                    _handleValidationErrors(
                      "deadline",
                      ValidateJs("deadline", opportunityData.deadline)
                    )
                  }

                  // onHide={() =>
                  //   _handleValidationErrors(
                  //     "deadline",
                  //     ValidateJs("deadline", opportunityData.deadline)
                  //   )
                  // }
                />
              </div>
              {validationErrors.deadline &&
                displayError(validationErrors.deadline)}
            </FormGroup>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default CreateOpportunityForm;
