import React from "react";
import Loader from "src/components/Loader/Loader";
import "./index.scss";
import { Link } from "react-router-dom";
import SuggestedGroupCard from "./SuggestedGroupCard/SuggestedGroupCard";
// import { useCurrentUser } from "src/modules/utils/Services/currentUser";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const SuggestedGroup = ({
  usersGroups,
  isLoading,
  allGroups,
  responsiveSize,
}) => {
  // const { userId } = useCurrentUser();
  const suggestedGroups =
    allGroups.length > 0 &&
    allGroups.slice(0, 7).map((suggestedGroup, index) => {
      // const belongs = Boolean(
      //   Number(
      //     usersGroups.filter((group) => group.id === suggestedGroup.id).length
      //   )
      // );
      return (
        <SuggestedGroupCard
          key={index}
          id={suggestedGroup.id}
          groupLogo={suggestedGroup.imageUrl}
          title={suggestedGroup.name}
          members={suggestedGroup.membersCount}
          location={suggestedGroup.location}
          belongs={suggestedGroup.isMember}
          creatorId={suggestedGroup.userId}
          privacy={suggestedGroup.privacy}
          likeCount={suggestedGroup.likeCount}
        />
      );
    });

  const course = new URLSearchParams(location.search).get("course");

  return (
    <div className="suggested-group-main" style={{ position: "relative" }}>
      <div className="suggested-group-heading">
        <div className="suggested-group-heading__text">
          <h1 className="text-h1">suggested study groups</h1>
          <h3 className="text-h3">Groups you might be interested in.</h3>
        </div>
        <Link
          to={`/training/studygroups/all-groups?course=${course}`}
          className="suggested-group-heading__link"
        >
          see all
        </Link>
      </div>
      {isLoading ? (
        <div className="suggested-group-cards__loader">
          <Loader color="danger" />
        </div>
      ) : (
        <>
          {suggestedGroups.length > 0 ? (
            <Carousel responsive={responsiveSize}>{suggestedGroups}</Carousel>
          ) : (
            <div
              style={{
                position: "absolute",
                top: "150px",
                textAlign: "center",
                left: 0,
                right: 0,
              }}
            >
              Be the first to create a study group for this course
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SuggestedGroup;
