import React from "react";

const Details = ({ title }) => {
  return (
    <h6
      style={{
        borderBottom: "1px solid #A4A5A6",
        paddingBottom: "5px",
        fontWeight: "700",
      }}
    >
      {title}
    </h6>
  );
};

export default Details;
