import { asyncActionName } from "src/utils/AsyncUtils";
import {
  GET_ALL_TOOLS_CATEGORIES,
  GET_ALL_TOOLS,
  GET_TOOL_CATEGORY,
  FILTER_TOOL_LIST,
  CREATE_TOOL,
  GET_TOOL_DETAILS,
  POST_TOOL_COMMENT,
  GET_TOOL_COMMENTS,
  LIKE_TOOL_COMMENT,
  DELETE_TOOL_COMMENT,
} from "src/actions";
import { initialState } from "src/store";

const ToolsReducer = (state = initialState.tools, action) => {
  switch (action.type) {
    case asyncActionName(GET_ALL_TOOLS_CATEGORIES).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(GET_ALL_TOOLS_CATEGORIES).success:
      return {
        ...state,
        categories: action.payload,
        success: true,
      };
    case asyncActionName(GET_ALL_TOOLS_CATEGORIES).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(GET_ALL_TOOLS).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(GET_ALL_TOOLS).success:
      return {
        ...state,
        tools: action.payload,
        success: true,
      };
    case asyncActionName(GET_ALL_TOOLS).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(GET_TOOL_DETAILS).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(GET_TOOL_DETAILS).success:
      return {
        ...state,
        tool: action.payload,
        success: true,
      };
    case asyncActionName(GET_TOOL_DETAILS).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(GET_TOOL_CATEGORY).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(GET_TOOL_CATEGORY).success:
      return {
        ...state,
        tool: action.payload,
        success: true,
      };
    case asyncActionName(GET_TOOL_CATEGORY).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(FILTER_TOOL_LIST).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(FILTER_TOOL_LIST).success:
      return {
        ...state,
        tools: action.payload,
        success: true,
      };
    case asyncActionName(FILTER_TOOL_LIST).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(CREATE_TOOL).loading:
      return {
        ...state,
        loadingTool: action.payload,
      };

    case asyncActionName(CREATE_TOOL).failure:
      return {
        ...state,
        error: action.payload,
        success: false,
      };
    case asyncActionName(POST_TOOL_COMMENT).loading:
      return {
        ...state,
        loadingTool: action.payload,
      };

    case asyncActionName(POST_TOOL_COMMENT).failure:
      return {
        ...state,
        error: action.payload,
        success: false,
      };
    case asyncActionName(GET_TOOL_COMMENTS).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(GET_TOOL_COMMENTS).success:
      return {
        ...state,
        tools: action.payload,
        success: true,
      };
    case asyncActionName(GET_TOOL_COMMENTS).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(LIKE_TOOL_COMMENT).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(LIKE_TOOL_COMMENT).success:
      return {
        ...state,
        likeToolComment: true,
        allLikers: action.payload,
        success: true,
      };
    case asyncActionName(LIKE_TOOL_COMMENT).failure:
      return {
        ...state,
        likeToolComment: false,
        success: false,
      };
    case asyncActionName(DELETE_TOOL_COMMENT).loading:
      return {
        ...state,
        loading: action.payload,
      };
    case asyncActionName(DELETE_TOOL_COMMENT).success:
      return {
        ...state,
        success: true,
      };
    case asyncActionName(DELETE_TOOL_COMMENT).failure:
      return {
        ...state,
        success: false,
      };
    default:
      return state;
  }
};

export default ToolsReducer;
