/* eslint-disable no-dupe-keys */
import React from "react";
import { Input } from "reactstrap";
import CountryRegionData from "src/utils/countryList.json";
import PropTypes from "prop-types";

const Constants = {
  SHORT: "short",
  FULL: "full",
};

export const CustomCountryDropdown = ({
  id,
  onBlur,
  onChange,
  classes,
  value,
  valueType,
  disabled,
  name,
  defaultOptionLabel,
  required,
  invalid,
  ariaLabel,
  dataTestId,
}) => {
  const _getDefaultOptions = () => {
    if (defaultOptionLabel) {
      return <option value="">{defaultOptionLabel}</option>;
    }
    return <option value="">Select Country</option>;
  };

  const _getCountryList = () => {
    return CountryRegionData.map((c, index) => {
      return (
        <option
          key={index}
          value={
            valueType === Constants.SHORT ? c.countryShortCode : c.countryName
          }
        >
          {c.countryName}
        </option>
      );
    });
  };

  const setArributes = () => {
    const attr = {
      id,
      onChange: (e) => onChange(e.target.value, e),
      onBlur: (e) => onBlur(e),
      disabled,
      className: classes,
      name,
      required,
      invalid,
    };
    if (id) {
      attr.id = id;
    }

    if (classes) {
      attr.className = classes;
    }

    return attr;
  };

  return (
    <>
      <Input
        {...setArributes()}
        type="select"
        value={value}
        style={{ outline: "none" }}
        aria-label={ariaLabel}
        data-testid={dataTestId}
      >
        {_getDefaultOptions()}
        {_getCountryList()}
      </Input>
    </>
  );
};

CustomCountryDropdown.propTypes = {
  id: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  classes: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueType: PropTypes.oneOf([Constants.FULL, Constants.SHORT]),
  disabled: PropTypes.bool,
  name: PropTypes.string,
  defaultOptionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  invalid: PropTypes.bool,
};

CustomCountryDropdown.defaultProps = {
  id: "",
  onBlur: () => {},
  onChange: () => {},
  classes: "",
  value: "",
  valueType: Constants.SHORT,
  disabled: false,
  name: "crcsc",
  defaultOptionLabel: "Select Country",
  invalid: false,
  required: false,
};
