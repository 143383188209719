import React from "react";
import classes from "./RightSideCard.module.css";

const RightSideCard = () => {
  const rightsideContent = [
    {
      title: "Quality",
      content:
        "Your tool should be of great use to anyone who’s interested in building a business.",
    },
    {
      title: "Use Catchy Description",
      content:
        "Consider catchy yet detailed description and well organized topic categories in your tool.",
    },
    {
      title: "Logo Assets",
      content:
        "Upload only the recommended size of the logo of the product to help its discovery.",
    },
  ];
  return (
    <>
      {rightsideContent &&
        rightsideContent.map((c) => (
          <div className={classes.RightSideCardContainer} key={c.title}>
            <div className={classes.CardTitle}>{c.title}</div>
            <div className={classes.CardContent}>{c.content}</div>
          </div>
        ))}
    </>
  );
};

export default RightSideCard;
