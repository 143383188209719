import React, { useState, useEffect, useContext } from "react";
import styles from "./NotificationNav.module.scss";
import { FaChevronDown, FaUser, FaRegBell } from "react-icons/fa";
import { BsChatDots } from "react-icons/bs";
import { useHistory, Link } from "react-router-dom";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { Logout } from "src/requests/logout";
import NotificationBody from "./NotificationBody";
import NotificationCounter from "./NotificationCounter";
import { getNotifications } from "src/requests/NotificationRequest";
import { Context } from "src/store";
import { useChangeLanguage } from "src/utils/translation";
import { LanguageDropdown } from "src/components/LanguageDropdown/LanguageDropdown";

const NotificationNav = () => {
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(null);
  const { dispatch } = useContext(Context);
  const path = window.location.pathname;
  const user = useCurrentUser();
  const history = useHistory();
  const { changeLanguage } = useChangeLanguage();

  useEffect(() => {
    getNotifications(dispatch)
      .then((res) => {
        if (res.status === 200) {
          const {
            notificationDetails,
            numberOfUnreadNotification,
          } = res.data.data;
          setNotifications(notificationDetails);
          setUnreadNotifications(numberOfUnreadNotification);
        } else {
          setNotifications([]);
          setUnreadNotifications(0);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSelect = (value) => {
    changeLanguage(value);
    // localStorage.setItem("i18nextLng", value);
    // return ChangeLang(value);
  };

  // eslint-disable-next-line no-unused-vars
  const [show] = useState(false);

  return (
    <nav className={styles.NotificationNav}>
      {/* <Link */}
      {/* to="#" */}
      {/* className={`${styles.NotificationNav__icon} ${styles.NotificationNav__iconHide}`} */}
      {/* > */}
      {/* <TiUserAddOutline */}
      {/* size={24} */}
      {/* className={styles.NotificationNav__iconInActive} */}
      {/* /> */}
      {/* </Link> */}
      <Link
        to="/messages"
        className={`${styles.NotificationNav__icon} ${styles.NotificationNav__iconHide}`}
      >
        <BsChatDots
          size={24}
          className={
            path.includes("/messages")
              ? styles.NotificationNav__iconActive
              : styles.NotificationNav__iconInActive
          }
        />
      </Link>
      <Link className={styles.NotificationNav__icon} to="#">
        {unreadNotifications > 0 && (
          <NotificationCounter notificationCount={unreadNotifications} />
        )}
        <FaRegBell
          size={24}
          id="NotificationPopover"
          className={styles.NotificationNav__iconInActive}
        />
        <UncontrolledPopover
          trigger="legacy"
          placement="bottom"
          target="NotificationPopover"
        >
          <div
            className="NotificationContainer"
            style={{
              maxHeight: "70vh",
              overflowY: "scroll",
            }}
          >
            <div
              className="NotificationHeading"
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                borderBottom: "1px solid #000",
                padding: "0.5rem 0.5rem 0 0.5rem",
              }}
            >
              Notifications
            </div>
            <PopoverBody style={{ padding: 0 }}>
              <NotificationBody notifications={notifications} />
            </PopoverBody>
          </div>
        </UncontrolledPopover>
      </Link>

      <Link
        to="#"
        className={styles.NotificationNav__account}
        id="PopoverLegacy"
        type="button"
      >
        <FaUser className={styles.NotificationNav__accountProfile} />
        <span className={styles.NotificationNav__accountProfileText}>
          Account
        </span>
        <FaChevronDown
          size={12}
          className={styles.NotificationNav__accountProfileText}
        />
        <UncontrolledPopover
          trigger="legacy"
          placement="bottom"
          target="PopoverLegacy"
        >
          <Link to={`/profile/${user.userId}`}>
            <PopoverBody className={styles.accountIcon}>Profile</PopoverBody>
          </Link>
          <Link to="/bookmarks">
            <PopoverBody className={styles.accountIcon}>
              My Bookmarks
            </PopoverBody>
          </Link>
          {/* <Link to="#" onClick={toggle}>
            <PopoverHeader className={styles.accountIcon}>
              <span style={{ marginRight: "10px" }}>Language</span>
              <FaChevronDown size={12} />
            </PopoverHeader>

          </Link> */}
          <LanguageDropdown />

          {show ? (
            <>
              <PopoverBody
                className={styles.accountIcon}
                onClick={() => handleSelect("en")}
              >
                English
              </PopoverBody>
              <PopoverBody
                className={styles.accountIcon}
                onClick={() => handleSelect("fr")}
              >
                French
              </PopoverBody>
              <PopoverBody
                className={styles.accountIcon}
                onClick={() => handleSelect("pt")}
              >
                Portuguese
              </PopoverBody>
            </>
          ) : (
            ""
          )}
          <Link to="#" onClick={() => Logout(history)}>
            <PopoverBody className={styles.accountIcon}>Logout</PopoverBody>
          </Link>
        </UncontrolledPopover>
      </Link>
    </nav>
  );
};

export default NotificationNav;
