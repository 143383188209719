/* eslint-disable node/handle-callback-err */
/* eslint-disable no-unused-vars */
/* eslint no-unneeded-ternary: "error" */
import React, { useState, useContext, useCallback } from "react";
import { Context } from "src/store";
import { useLocation, useHistory } from "react-router-dom";
import {
  joinGroup,
  joinPrivateGroup,
  leaveGroup,
  likeStudyGroup,
  studyGroupAcceptInvite,
  studyGroupCancelInvite,
} from "src/requests/StudyGroupsRequest";
import queryString from "query-string";
import Loader from "src/components/Loader/Loader";
import Avatar from "react-avatar";
import { ReactComponent as More } from "src/assets/img/home/more.svg";
import "./SideInfo.scss";
import MoreOptions from "src/modules/training/StudyGroups/components/MoreOptions/MoreOptions";
import ClickOutside from "react-click-outside-component";
import InviteFriendsModal from "src/modules/training/StudyGroups/components/InviteFriends/InviteFriendsModal";
import AllMembers from "src/modules/training/StudyGroups/components/AllMembers/AllMembers";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { isStudyGroupAdmin } from "src/modules/training/StudyGroups/helper";
import EditTrainingStudyGroup, {
  EditStudyGroupModal,
} from "src/modules/training/StudyGroups/components/CreateCommunityGroup/EditTrainingStudyGroup";
import { toast } from "react-toastify";
import { FaThumbsUp } from "react-icons/fa";
import sendMessage from "src/utils/NewMessageHelper";
import { Button, ButtonGroup } from "reactstrap";
import LoaderSpinner from "react-loader-spinner";

const SideInfo = ({
  groupTitle,
  groupType,
  createdBy,
  about,
  programmeEventId,
  programmeId,
  // creatorPhoneNumber,
  // creatorEmail,
  groupCategory,
  NoOfPosts,
  membersCount,
  groupLogo,
  member,
  createdDate,
  creatorId,
  createdByDp,
  getGroupDetails,
  groupLikeCount,
  hasLikedGroup,
  courseId,
}) => {
  const { userId, firstName, lastName, email } = useCurrentUser();
  const { dispatch } = useContext(Context);
  const [joined, setJoined] = useState(member);
  // eslint-disable-next-line no-unused-vars
  const [left, setLeft] = useState(false);
  const [isJoinLoading, setIsJoinLoading] = useState(false);
  const [count, setCount] = useState(membersCount);
  const qa = useLocation();
  const history = useHistory();
  const { groupId, acceptance, invite } = queryString.parse(qa.search);
  const [toggleMoreOptions, setToggleMoreOptions] = useState(false);
  const [toggleInviteFriendsModal, setToggleInviteFriendsModal] = useState(
    false
  );
  const [likedGroup, setLikedGroup] = useState(hasLikedGroup);
  const [likedGroupCount, setLikedGroupCount] = useState(groupLikeCount);
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingDecline, setLoadingDecline] = useState(false);

  const handleMoreToggle = () => setToggleMoreOptions(!toggleMoreOptions);
  const handleJoinGroup = () => {
    setIsJoinLoading(true);
    joinGroup(dispatch, { groupId, userId }).then((res) => {
      setIsJoinLoading(false);
      setJoined(true);
      setLeft(false);
      setCount(count + 1);
      toast("Successfully joined " + groupTitle);
    });
  };
  const handleLeaveGroup = () => {
    setIsJoinLoading(true);
    leaveGroup(dispatch, { groupId, userId }).then((res) => {
      setIsJoinLoading(false);
      setLeft(true);
      setJoined(false);
      setCount(count - 1);
      toast("Successfully left " + groupTitle);
    });
  };

  const handleRequestJoinGroup = () => {
    setIsJoinLoading(true);
    joinPrivateGroup(dispatch, {
      groupId,
      inviteeUserId: userId,
      inviteedByUserId: userId,
      inviteeEmail: email,
    }).then((res) => {
      console.log(res.status);
      setIsJoinLoading(false);
      setJoined(false);
      setLeft(false);
      // setNoOfMembers(noOfMembers + 1);
      toast(
        "A request has been sent to the admin of this group '" +
          groupTitle +
          "'"
      );
    });
  };

  const toggleInviteModal = useCallback(() => {
    setToggleInviteFriendsModal(!toggleInviteFriendsModal);
  }, [toggleInviteFriendsModal]);

  const handleClick = () => {
    joined
      ? handleLeaveGroup()
      : groupType === "private"
      ? handleRequestJoinGroup()
      : handleJoinGroup();
  };

  const [openEditStudyGroup, setOpenEditStudyGroup] = useState(false);

  const toggleEditGroup = () => setOpenEditStudyGroup(!openEditStudyGroup);

  const toggleLikeStudyGroup = () => {
    if (likedGroup) {
      setLikedGroup(false);
      setLikedGroupCount(likedGroupCount - 1);
    }
    if (!likedGroup) {
      setLikedGroup(true);
      setLikedGroupCount(likedGroupCount + 1);
    }
    likeStudyGroup(groupId, userId).then(() => {
      getGroupDetails();
    });
  };

  const _acceptInvite = () => {
    setLoadingAccept(true);

    studyGroupAcceptInvite(invite).then((res) => {
      if (res.status === 200 || res.status === 201) {
        getGroupDetails().then(() => {
          history.replace({
            pathname: window.location.pathname,
            search: `groupId=${groupId}&course=${courseId}`,
          });
        });
      }
      setLoadingAccept(false);
    });
  };

  const _declineInvite = () => {
    setLoadingDecline(true);
    studyGroupCancelInvite(invite, userId).then((res) => {
      if (res.status === 200 || res.status === 201) {
        return history.goBack();
      }
    });
    setLoadingDecline(true);
  };

  return (
    <div className="side-info-container">
      <div className="side-info-container__group-details">
        {isStudyGroupAdmin(creatorId, userId) && (
          <>
            <More
              onClick={handleMoreToggle}
              className="side-info-container__group-details--more"
            />
            <ClickOutside onClickOutside={() => setToggleMoreOptions(false)}>
              <MoreOptions
                userId={userId}
                groupOwnerId={creatorId}
                show={toggleMoreOptions}
                setToggleInviteFriendsModal={setToggleInviteFriendsModal}
                groupId={groupId}
                groupTitle={groupTitle}
                firstName={firstName}
                lastName={lastName}
                toggleEditClick={toggleEditGroup}
              />
            </ClickOutside>
          </>
        )}

        {groupLogo && groupLogo !== "string" ? (
          <img
            src={groupLogo}
            className="side-info-container__group-details--icon"
            alt=""
          />
        ) : (
          <Avatar
            name={groupTitle}
            className="side-info-container__group-details--icon"
          />
        )}
        <h1 className="side-info-container__group-details--title">
          {groupTitle}
        </h1>
        <h4 className="side-info-container__group-details--sub-title">
          {groupType}
        </h4>
        <div className="side-info-container__group-details--info">
          <div className="side-info">
            <h1 className="side-info__posts">{NoOfPosts}</h1>
            <p className="side-info__label">posts</p>
          </div>

          <div className="side-info">
            <h1 className="side-info__posts">{count}</h1>
            <p className="side-info__label">
              {count > 1 ? "Members" : "Member"}
            </p>
          </div>

          <div className="side-info">
            <h1 className="side-info__posts">{likedGroupCount}</h1>
            <p className="side-info__label pointer">
              {/* likes */}
              <FaThumbsUp
                color={likedGroup ? "var(--tef-red)" : ""}
                size={20}
                onClick={toggleLikeStudyGroup}
              />
            </p>
          </div>
        </div>

        {userId !== creatorId && (
          <>
            {acceptance && acceptance === "true" ? (
              // <button
              //   className="side-info-container__group-details--button"
              //   onClick={() => alert("accepted")}
              //   disabled={isJoinLoading}
              // >
              //   {loadingAccept ? <Loader color="light" /> : "Accept"}
              // </button>
              <ButtonGroup>
                <Button
                  onClick={_acceptInvite}
                  outline
                  disabled={loadingAccept || loadingDecline}
                >
                  Accept{" "}
                  {loadingAccept && (
                    <LoaderSpinner
                      color="white"
                      height={20}
                      width={20}
                      type="Oval"
                      style={{ float: "right", marginLeft: "5px" }}
                    />
                  )}
                </Button>
                <Button
                  onClick={_declineInvite}
                  color="primary"
                  outline
                  disabled={loadingAccept || loadingDecline}
                >
                  Decline
                  {loadingDecline && (
                    <LoaderSpinner
                      color="white"
                      height={20}
                      width={20}
                      type="Oval"
                      style={{ float: "right", marginLeft: "5px" }}
                    />
                  )}
                </Button>
              </ButtonGroup>
            ) : (
              <button
                className="side-info-container__group-details--button"
                onClick={handleClick}
                disabled={isJoinLoading}
              >
                {isJoinLoading ? (
                  <Loader color="light" />
                ) : member ? (
                  "Leave Group"
                ) : (
                  "Join Group"
                )}
              </button>
            )}
          </>
        )}
      </div>

      <div className="created-by-info">
        <p className="created-by-info__title">Group Created by</p>
        <div className="created-by-info__details">
          {createdByDp ? (
            <img
              src={createdByDp}
              alt=""
              className="created-by-info__details--image"
            />
          ) : (
            <Avatar name={createdBy} round={true} size={42} />
          )}
          <div className="created-by-info__details--name-details">
            <h2>{createdBy}</h2>
            <h6>{createdDate}</h6>
          </div>
          {userId !== creatorId && (
            <button
              className={
                creatorId === userId
                  ? "created-by-info__details--button details-disabled-btn"
                  : "created-by-info__details--button"
              }
              onClick={() => sendMessage(creatorId, history)}
            >
              Send Message
            </button>
          )}
        </div>
      </div>

      <div className="group-about">
        <h3 className="group-about__title">About</h3>
        <div className="group-about__details">
          <h4 className="group-about__details--info">{about}</h4>
          {/* <div className="group-about__details--info-group"> */}
          {/* <h4>Phone Number</h4> */}
          {/* <h6>{creatorPhoneNumber}</h6> */}
          {/* </div> */}
          {/* <div className="group-about__details--info-group"> */}
          {/* <h4>Email</h4> */}
          {/* <h6>{creatorEmail}</h6> */}
          {/* </div> */}
          <div className="group-about__details--info-group">
            {/* <h4>Category</h4>
            <h6>{groupCategory}</h6> */}
          </div>
        </div>
      </div>

      {groupType.toLowerCase() === "private" && !member ? (
        ""
      ) : (
        <div>
          <AllMembers
            backgroundColor="#fff"
            belongsToId={creatorId}
            userId={userId}
          />
        </div>
      )}

      <div>
        <InviteFriendsModal
          isOpen={toggleInviteFriendsModal}
          toggle={toggleInviteModal}
        />
      </div>
      <>
        <EditStudyGroupModal
          isOpen={openEditStudyGroup}
          toggle={toggleEditGroup}
        >
          <EditTrainingStudyGroup
            cancel={toggleEditGroup}
            groupData={{
              name: groupTitle,
              imageUrl: groupLogo,
              about,
              userId: creatorId,
              privacy: groupType,
              programmeId: programmeId,
              programmeEventId: programmeEventId,
              courseId,
            }}
            getGroupDetails={getGroupDetails}
          />
        </EditStudyGroupModal>
      </>
    </div>
  );
};

export default SideInfo;
