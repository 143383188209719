/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from "react";
import "../../Flagship/personalInfo/common.scss";
import "../../Flagship/personalInfo/Bio/bio.scss";
import { FormField, Select } from "src/modules/onboarding/components";
import PhoneInput from "react-phone-input-2";
// import { SelectBox } from "src/modules/Application/components";
import { FaMale, FaFemale, FaUpload } from "react-icons/fa";
import { personalInfo } from "src/modules/Application/Flagship/personalInfo/personanlInfoObj";
import { Context } from "src/store";
import { displayError } from "src/modules/onboarding/helper";
import "react-phone-input-2/lib/style.css";
import { degreeTypes } from "src/modules/Application/Flagship/personalInfo/degreeTypes";
import { idTypes } from "src/modules/Application/Flagship/personalInfo/idTypes.js";
import Loader from "react-loader-spinner";
import CLoader from "src/modules/Application/components/CLoader";
import { howYouHearAboutUs } from "src/modules/Application/Flagship/personalInfo/howYouHeardAboutUs.js";
import queryString from "query-string";

import { UploadFile, isImageFile } from "src/requests/fileUploadRequest";
import { sectionOneTranslations } from "src/modules/Application/instructions";
import { SelectBox } from "src/modules/Application/Flagship/personalInfo/components";
import { useHistory, useLocation } from "react-router-dom";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { updateApplication } from "src/requests/ApplicationRequest";
import { bioInfo, preViewBtns } from "src/modules/Application/personalInfo";
import { useTranslation } from "react-i18next";
import {
  CustomCountryRegionDropdown,
  CustomCountryDropdown,
} from "src/components/CustomCountryRegionDropdown";
import { updateUserProfile } from "src/requests/ProfileRequest";
import { refreshToken } from "src/utils/someTokenMenthods";
import { DatePickerInput } from "rc-datepicker";
import {
  formatMaxBirthYear,
  validateBirthYear,
} from "src/modules/onboarding/stepTwo/Sections";
import { ageRange } from "src/modules/Application/Flagship/personalInfo/ageRange";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDisabilityList } from "src/modules/Application/applicationHooksAll/useDisabilityList";

const BioEdit = ({ toggleModal, toggleNext, scrollToTop }) => {
  const { state, dispatch } = useContext(Context);
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const { lang } = queryString.parse(location.search);
  const language = lang || "en";
  const btns = preViewBtns[lang || "en"];

  const [loadingSave, setLoadingSave] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const ref = useRef();
  const [fileError, setFileError] = useState("");
  const [isuploadingId, setIsUploadingId] = useState(false);
  const { applicationId } = queryString.parse(window.location.search);
  // const instructionObj = instructions[language || "en"];
  const bioObj = bioInfo[language || "en"] || bioInfo.en;
  const aboutUs = howYouHearAboutUs[language || "en"] || howYouHearAboutUs.en;
  const idtypes = idTypes[language || "en"] || idTypes.en;
  const degreeObj = degreeTypes[language || "en"] || degreeTypes.en;
  const [programmeType, setProgrammeType] = useState("flagship");
  const [updatingMessage, setUpdatingMessage] = useState("");
  const ageRangeObj = ageRange[language || "en"] || ageRange.en;

  const history = useHistory();

  useEffect(() => {
    const query = history.location.search;
    if (query) {
      const parsedQuery = queryString.parse(query);
      if (Object.keys(parsedQuery).length === 0 || !parsedQuery.applicationId) {
        history.replace("/programme/dashboard", {});
        history.go(1);
      }
    } else {
      history.replace("/programme/dashboard", {});
      history.go(1);
    }
  }, [history.location.search]);

  const user = useCurrentUser();

  useEffect(() => {
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
  }, [lang]);

  const schema = Yup.object({
    firstName: Yup.string().required(t("Please enter first name")),
    lastName: Yup.string().required(t("Please enter last name")),
    middleName: Yup.string().required(t("Please enter middle name")),
    gender: Yup.string().required(t("Please select gender")),
    ageRange: Yup.string().required(t("Please select age range")),
    email: Yup.string().email(),
    phoneNumber: Yup.string()
      .min(7, "Please enter valid number")
      .required(t("Please enter valid number")),
    levelOfEducation: Yup.string().required(
      t("Please select level of education")
    ),
    countryId: Yup.string().required(t("Please select country of residence")),
    state: Yup.string().required(t("Please select region")),
    city: Yup.string().required(t("please enter city of residence")),
    address: Yup.string().required(t("please enter residential address")),
    isResidentialRural: Yup.boolean().required(t("Please select")),
    appliedForOtherFoundationGrant: Yup.boolean().required(t("Please select")),
    nameOfOrganization: Yup.string().when("appliedForOtherFoundationGrant", {
      is: true,
      then: () => Yup.string().required(t("Please Enter name of organization")),
    }),
    typeOfId: Yup.string().required(
      t("Please select type of id you wish to upload")
    ),
    idNumber: Yup.string().required(t("Please enter ID Number")),
    idUrl: Yup.string().required(t("Please upload mode of identification")),
    howYouHearAboutUs: Yup.string().required(t("Please select")),
    isDisabledPerson: Yup.boolean().required(t("Please select")),
    // isDisabledPerson: Yup.string().required(t("Please select")),
    typeOfDisability: Yup.string().when("isDisabledPerson", {
      is: true,
      then: () => Yup.string().required(t("Please select")),
    }),

    otherDisabilities: Yup.string().when("typeOfDisability", {
      is: "Other disability",
      then: () =>
        Yup.string().required(t("Please enter other type of disability")),
    }),
    requiredAdjustmentToParticipateInTEF: Yup.string().when(
      "isDisabledPerson",
      {
        is: true,
        then: () => Yup.string().required(t("Please specify")),
      }
    ),
    isMemberOfYunitok: Yup.boolean().required(t("Please select")),
    isMemberOfYoma: Yup.boolean().required(t("Please select")),
    hasParticipatedInImaGenVenturesYouthChallenge: Yup.boolean().required(
      t("Please select")
    ),
  }).required();

  const { disabilityList } = useDisabilityList();
  const { userApplications } = state.Application;

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    defaultValues: { ...personalInfo },
  });

  useEffect(() => {
    const errorsvalues = Object.values(errors);
    if (errorsvalues.length > 0) {
      const ref = errorsvalues?.[0]?.ref;
      if (ref) {
        document
          .getElementById(errorsvalues?.[0]?.ref.name)
          ?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [errors]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // getUserApplications(dispatch, user.userId);
  }, []);

  const { programmes } = state.Programmes;

  useEffect(() => {
    if (programmes && programmes.length > 0) {
      const filtered = programmes.find((p) => {
        return p.programmeEvents.find((e) => {
          return e.id === applicationId;
        });
      });

      filtered && setProgrammeType(filtered.programmeType);
    }
  }, [programmes]);

  useEffect(() => {
    const applicant =
      userApplications &&
      userApplications.filter(
        (applicant) => applicant.applicationId === applicationId
      );
    if (applicant && applicant.length > 0) {
      const defaultState = { ...getValues() };
      reset({ ...defaultState, ...applicant[0] });
    }
  }, [state.Application.userApplications]);

  const _onIdImageChange = (e) => {
    const files = e.target.files;
    if (files && files[0] && files[0].name && files[0].name.endsWith(".gif")) {
      setFileError("Invalid file format. Only jpeg and png are allowed");
      return;
    }
    if (isImageFile(files && files, false).hasError) {
      setFileError("Invalid file format. Only jpeg and png are allowed");
      return;
    }

    setFileError("");
    setIsUploadingId(true);
    setPreviewImage(files);
    UploadFile(dispatch, false, files)
      .then((res) => {
        const { files } = res;
        setValue("idUrl", files[0]);
        setIsUploadingId(false);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((e) => {
        setIsUploadingId(false);
      });
  };

  const extractPayload = (data) => {
    const {
      firstName,
      lastName,
      middleName,
      gender,
      dateOfBirth,
      phoneNumber,
      email,
      levelOfEducation,
      yearsOfExperience,
      state,
      city,
      address,
      appliedForOtherFoundationGrant,
      nameOfOrganization,
      typeOfId,
      idUrl,
      howYouHearAboutUs,
      kinFirstName,
      kinLastName,
      kinMiddleName,
      kinPhone,
      kinEmail,
      mentorFirstName,
      mentorLastName,
      mentorMiddleName,
      mentorPhone,
      mentorEmail,
      mentorGender,
      countryId,
      isResidentialRural,
      motivation,
      idNumber,
      ageRange,
      isDisabledPerson,
      typeOfDisability,
      requiredAdjustmentToParticipateInTEF,
      isMemberOfYunitok,
      isMemberOfYoma,
      hasParticipatedInImaGenVenturesYouthChallenge,
    } = data;

    return {
      firstName,
      lastName,
      middleName,
      gender,
      dateOfBirth,
      phoneNumber,
      email,
      levelOfEducation,
      yearsOfExperience,
      state,
      city,
      address,
      appliedForOtherFoundationGrant,
      nameOfOrganization,
      typeOfId,
      idUrl,
      howYouHearAboutUs,
      kinFirstName,
      kinLastName,
      kinMiddleName,
      kinPhone,
      kinEmail,
      mentorFirstName,
      mentorLastName,
      mentorMiddleName,
      mentorPhone,
      mentorEmail,
      mentorGender,
      countryId,
      isResidentialRural,
      motivation,
      idNumber,
      ageRange,
      isDisabledPerson,
      typeOfDisability,
      requiredAdjustmentToParticipateInTEF,
      isMemberOfYunitok,
      isMemberOfYoma,
      hasParticipatedInImaGenVenturesYouthChallenge,
    };
  };
  const _sanitizeData = (data) => {
    data = { ...data };
    delete data.validationErrors;
    delete data.skipTour;
    delete data.steps;
    delete data.timeTaken;
    delete data.score;
    delete data.status;
    delete data.progress;
    delete data.staticIdUrl;

    const sanitized = { ...data };
    const formatted = extractPayload(sanitized);

    return formatted;
  };
  const updateProfileAccount = (info) => {
    const profileDetails = [
      "gender",
      "dateOfBirth",
      "yearsOfExperience",
      "state",
      "city",
      "address",
      "firstName",
      "lastName",
      "email",
      "phoneNumber",
      "countryId",
      "levelOfEducation",
      // "languageId",
    ];
    let profileObj = {};
    profileDetails.forEach((key) => {
      if (info[key]) {
        profileObj = { ...profileObj, [key]: info[key] };
      }
    });
    if (!profileObj.phone) {
      profileObj.phone = info.phoneNumber;
    }

    if (!profileObj.country) {
      profileObj.country = info.countryId;
    }

    // if (!profileObj.language) {
    //   profileObj.language = info.languageId;
    // }

    delete profileObj.countryId;
    delete profileObj.phoneNumber;
    return updateUserProfile(dispatch, profileObj).then(() => {
      return refreshToken();
    });
  };

  const _onSubmit = (info) => {
    if (loadingSave) return;
    setLoadingSave(true);
    setUpdatingMessage(t("Saving application info..."));

    const userInfo = _sanitizeData(info);
    return updateApplication(
      dispatch,
      userInfo,
      info.applicationId,
      user.userId,
      true,
      t("Application info updated successfully.")
    ).then(() => {
      setUpdatingMessage(t("Updating profile info..."));
      return updateProfileAccount(userInfo).then(() => {
        setLoadingSave(false);
        // document.getElementById("top-bio").scrollIntoView();
        scrollToTop();
        // toggleModal(e);
        toggleNext("2");
      });
    });
  };

  return (
    <div
      className="application-personal-info-c"
      style={{ marginTop: "15px" }}
      id="top-bio"
    >
      <div style={{ marginBottom: "10px" }} className="back-btn-somewhere">
        <span
          className="back-2-somewhere"
          onClick={() => history.replace("/home", {})}
        >
          {t("Back to Home")}
        </span>
      </div>
      <div className="application-personal-info-c__form">
        <div className="p-info-form-heading">
          <h5>
            <span className="bold">{bioObj.title1}</span>{" "}
          </h5>
          <h4>{sectionOneTranslations[language || "en"]}</h4>
        </div>

        <form ref={ref} onSubmit={handleSubmit(_onSubmit)}>
          <div className="row">
            <div className="col-md-6">
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      placeholder="e.g Jane"
                      label={bioObj.firstname}
                      id="firstName"
                      name={name}
                      value={value}
                      onChange={(_, value) => onChange(value)}
                      onBlur={onBlur}
                      error={error?.message}
                    />
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      placeholder="e.g Doe"
                      label={bioObj.lastname}
                      id="lastName"
                      name={name}
                      value={value}
                      onChange={(_, value) => onChange(value)}
                      onBlur={onBlur}
                      error={error?.message}
                    />
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>

          <Controller
            name="middleName"
            control={control}
            rules={{ required: true }}
            render={({
              field: { onChange, value, onBlur, name },
              fieldState: { error },
            }) => (
              <>
                <FormField
                  placeholder="e.g John doe"
                  label={bioObj.middleName}
                  id="middleName"
                  name={name}
                  value={value}
                  onChange={(_, value) => onChange(value)}
                  onBlur={onBlur}
                  error={error?.message}
                />
                <>{error?.message && displayError(`${t(error?.message)}`)}</>
              </>
            )}
          />

          <div className="" style={{ display: "flex", flexWrap: "wrap" }}>
            <div className="f1 dob">
              <Controller
                name="gender"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={bioObj.gender} id="gender">
                      <div
                        style={{
                          marginTop: "-1px",
                          display: "flex",
                          // flexWrap: "wrap",
                        }}
                      >
                        <SelectBox
                          value="male"
                          // className="selected-box"
                          selected={value}
                          icon={<FaMale />}
                          name={name}
                          onSelect={(_, value) => onChange(value)}
                          label={bioObj.male}
                        />
                        <SelectBox
                          value="female"
                          icon={<FaFemale />}
                          name={name}
                          selected={value}
                          onSelect={(_, value) => onChange(value)}
                          label={bioObj.female}
                        />
                      </div>
                    </FormField>

                    {error?.message && (
                      <div
                        className="displayError"
                        style={{ marginLeft: "5px" }}
                      >
                        {displayError(error?.message)}
                      </div>
                    )}
                  </>
                )}
              />
            </div>
            <div className="f1">
              <Controller
                name="dateOfBirth"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      placeholder="e.g Bond"
                      label={bioObj.birthday}
                      name="dateOfBirth"
                      id="dateOfBirth"
                    >
                      <div
                        className={`rc-dp${
                          error?.message ? " default-error-state" : ""
                        }`}
                      >
                        <DatePickerInput
                          className="default-error-state"
                          readOnly
                          maxDate={new Date(formatMaxBirthYear(18))}
                          startDate={new Date(formatMaxBirthYear(18))}
                          onChange={(_, dateString) =>
                            validateBirthYear(
                              name,
                              dateString,
                              (target, value) => {
                                onChange(value);
                              }
                            )
                          }
                          placeholder="MM/DD/YYYY"
                          onHide={onBlur}
                          value={value}
                          showOnInputClick
                          autoClose={false}
                        />
                      </div>
                    </FormField>
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Controller
                name="ageRange"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={bioObj.ageRange} id="ageRange">
                      <Select
                        list={ageRangeObj}
                        defaultVal={bioObj.select1}
                        name={name}
                        onChange={(_, value) => onChange(value)}
                        value={value}
                        onBlur={onBlur}
                        error={error?.message}
                      />
                    </FormField>
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Controller
                name="phoneNumber"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={bioObj.phoneNumber} id="phoneNumber">
                      <div style={{ marginTop: "7.5px" }}>
                        <PhoneInput
                          countryCodeEditable={false}
                          enableSearch
                          country={"ng"}
                          placeholder={t(
                            "Please provide a valid phone number i ideally it’s your WhatsApp number or another phone number for texts"
                          )}
                          inputClass="phone-input"
                          name={name}
                          onChange={(phone) => onChange(phone)}
                          autoFormat={false}
                          inputStyle={{
                            width: "100%",
                            marginTop: "8px 10px",
                            borderColor: error?.message ? "red" : "",
                          }}
                          onBlur={onBlur}
                          value={value}
                        />
                      </div>
                    </FormField>
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
            <div className="col-md-6" style={{ marginTop: "-7px" }}>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, name }, fieldState: { error } }) => (
                  <>
                    <FormField
                      type="email"
                      placeholder={bioObj.email}
                      label={bioObj.email}
                      name={name}
                      value={value}
                      disabled
                    />
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Controller
                name="levelOfEducation"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { value, name, onBlur, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={bioObj.education} id="levelOfEducation">
                      <Select
                        list={degreeObj}
                        defaultVal={bioObj.select2}
                        name={name}
                        onChange={(_, value) => onChange(value)}
                        value={value}
                        onBlur={onBlur}
                        error={error?.message}
                      />
                    </FormField>

                    <>
                      {error?.message && (
                        <div
                          className="displayError"
                          style={{ marginLeft: "5px" }}
                        >
                          {displayError(error?.message)}
                        </div>
                      )}
                    </>
                  </>
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Controller
                name="countryId"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { value, name, onBlur, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={bioObj.country} id="countryId">
                      <CustomCountryDropdown
                        classes={`flex country-state-list ${
                          error?.message ? "country-state-list-error-state" : ""
                        }`}
                        value={value}
                        name={name}
                        valueType="short"
                        onChange={(val) => {
                          onChange(val);
                          setValue("state", "");
                        }}
                        onBlur={onBlur}
                      />
                    </FormField>

                    <>
                      {error?.message && (
                        <div
                          className="displayError"
                          style={{ marginLeft: "3px" }}
                        >
                          {displayError(error?.message)}
                        </div>
                      )}
                    </>
                  </>
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                name="state"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { value, name, onBlur, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={bioObj.state} id="state">
                      <CustomCountryRegionDropdown
                        countryValueType="short"
                        defaultOptionLabel={bioObj.select2}
                        country={watch().countryId}
                        classes={`flex country-state-list ${
                          error?.message ? "country-state-list-error-state" : ""
                        }`}
                        name={name}
                        value={value}
                        onChange={(val) => onChange(val)}
                        onBlur={onBlur}
                      />
                    </FormField>

                    <>
                      {error?.message && (
                        <div
                          className="displayError"
                          style={{ marginLeft: "3px" }}
                        >
                          {displayError(error?.message)}
                        </div>
                      )}
                    </>
                  </>
                )}
              />
            </div>
          </div>
          <div>
            <Controller
              name="city"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, name, onBlur, onChange },
                fieldState: { error },
              }) => (
                <>
                  <FormField
                    label={bioObj.city}
                    placeholder={bioObj.cityPlaceholder}
                    value={value}
                    name={name}
                    onChange={(_, val) => onChange(val)}
                    id="city"
                    onBlur={onBlur}
                    error={error?.message}
                  />

                  <>{error?.message && displayError(error?.message)}</>
                </>
              )}
            />
          </div>
          <div>
            <Controller
              name="address"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, name, onBlur, onChange },
                fieldState: { error },
              }) => (
                <>
                  <FormField
                    label={bioObj.address}
                    type="textarea"
                    placeholder={t("Type in your residential address")}
                    value={value}
                    name={name}
                    onChange={(_, val) => onChange(val)}
                    id="address"
                    onBlur={onBlur}
                    error={error?.message}
                  />
                  <>{error?.message && displayError(error?.message)}</>
                </>
              )}
            />
          </div>
          <div className="row">
            <div className="col-md-8">
              <Controller
                name="isResidentialRural"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { value, name, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={bioObj.area} id="isResidentialRural">
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            selected={value}
                            onSelect={(_, val) => onChange(val)}
                            name={name}
                            label={t("Rural area")}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            selected={value}
                            onSelect={(_, val) => onChange(val)}
                            name={name}
                            label={t("Urban center")}
                          />
                        </div>
                      </div>
                    </FormField>
                    <>{error?.message && displayError(error?.message)}</>
                  </>
                )}
              />
            </div>
          </div>
          {/* fo begreen programme */}
          <div className="row">
            <div className="col-md-6">
              <Controller
                name="isDisabledPerson"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { value, name, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={t(
                        "Do you consider yourself as a person with disability?"
                      )}
                      id="isDisabledPerson"
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            selected={value}
                            onSelect={(_, val) => onChange(val)}
                            name={name}
                            label={bioObj.yes}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            selected={value}
                            onSelect={(_, val) => {
                              onChange(val);
                              reset({
                                ...getValues(),
                                otherDisabilities: "",
                                typeOfDisability: "",
                              });
                            }}
                            name={name}
                            label={bioObj.no}
                          />
                        </div>
                      </div>
                    </FormField>
                    <>{error?.message && displayError(error?.message)}</>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Controller
                name="typeOfDisability"
                control={control}
                render={({
                  field: { onChange, value, onBlur, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={t("If yes, please select any of the following")}
                      id="typeOfDisability"
                    >
                      <Select
                        disabled={!watch()?.isDisabledPerson}
                        list={disabilityList}
                        defaultVal={bioObj.select1}
                        name={name}
                        onChange={(_, value) => {
                          onChange(value);
                          if (!value.toLowerCase().includes("other")) {
                            setValue("otherDisabilities", "");
                          }
                        }}
                        value={value}
                        onBlur={onBlur}
                        error={error?.message}
                      />
                    </FormField>
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>

          {watch()?.typeOfDisability?.toLowerCase().includes("other") && (
            <Controller
              name="otherDisabilities"
              control={control}
              render={({
                field: { onChange, value, onBlur, name },
                fieldState: { error },
              }) => (
                <>
                  <FormField
                    label={t("Please enter other type of disability")}
                    id="otherDisabilities"
                    onChange={(_, val) => onChange(val)}
                    value={value}
                    onBlur={onBlur}
                    name={name}
                  ></FormField>
                  <>{error?.message && displayError(`${t(error?.message)}`)}</>
                </>
              )}
            />
          )}

          <Controller
            name="requiredAdjustmentToParticipateInTEF"
            control={control}
            render={({
              field: { onChange, value, onBlur, name },
              fieldState: { error },
            }) => (
              <>
                <FormField
                  label={t(
                    "As a person with disability, what form of reasonable accommodation or adjustment will you require to meaningfully participate in the TEF programme if selected"
                  )}
                  id="requiredAdjustmentToParticipateInTEF"
                  onChange={(_, val) => onChange(val)}
                  value={value}
                  onBlur={onBlur}
                  name={name}
                  type={"textarea"}
                  disabled={!watch().isDisabledPerson}
                ></FormField>
                <>{error?.message && displayError(`${t(error?.message)}`)}</>
              </>
            )}
          />

          <Controller
            name="isMemberOfYunitok"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <>
                <FormField
                  label={t("Are you a member of Yunitok/ U-Report")}
                  id="isMemberOfYunitok"
                >
                  <div className="row">
                    <div className="col-sm-3 gutter-v">
                      <SelectBox
                        value={true}
                        selected={value}
                        onSelect={(_, val) => onChange(val)}
                        name={name}
                        label={bioObj.yes}
                      />
                    </div>
                    <div className="col-sm-3 gutter-v">
                      <SelectBox
                        value={false}
                        selected={value}
                        onSelect={(_, val) => {
                          onChange(val);
                        }}
                        name={name}
                        label={bioObj.no}
                      />
                    </div>
                  </div>
                </FormField>
                <>{error?.message && displayError(`${t(error?.message)}`)}</>
              </>
            )}
          />

          <div className="row mt-3 px-2">
            <div className="col-sm-12 text-info">
              <p>{t("If no, send the word join to 20204")}</p>
            </div>
          </div>

          <Controller
            name="isMemberOfYoma"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <>
                <FormField
                  label={t("Are you a member of Yoma")}
                  id="isMemberOfYoma"
                >
                  <div className="row">
                    <div className="col-sm-3 gutter-v">
                      <SelectBox
                        value={true}
                        selected={value}
                        onSelect={(_, val) => onChange(val)}
                        name={name}
                        label={bioObj.yes}
                      />
                    </div>
                    <div className="col-sm-3 gutter-v">
                      <SelectBox
                        value={false}
                        selected={value}
                        onSelect={(_, val) => {
                          onChange(val);
                        }}
                        name={name}
                        label={bioObj.no}
                      />
                    </div>
                  </div>
                </FormField>
                <>{error?.message && displayError(`${t(error?.message)}`)}</>
              </>
            )}
          />

          <div className="row mt-3 px-2">
            <div className="col-sm-12 text-info">
              <p onClick={() => window.open("http://www.Yoma.world", "_blank")}>
                {t("If no, signup at www.Yoma.world")}
              </p>
            </div>
          </div>

          <Controller
            name="hasParticipatedInImaGenVenturesYouthChallenge"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <>
                <FormField
                  label={t(
                    "Did you participate in imaGen Ventures Youth Challenge"
                  )}
                  id="hasParticipatedInImaGenVenturesYouthChallenge"
                >
                  <div className="row">
                    <div className="col-sm-3 gutter-v">
                      <SelectBox
                        value={true}
                        selected={value}
                        onSelect={(_, val) => onChange(val)}
                        name={name}
                        label={bioObj.yes}
                      />
                    </div>
                    <div className="col-sm-3 gutter-v">
                      <SelectBox
                        value={false}
                        selected={value}
                        onSelect={(_, val) => {
                          onChange(val);
                        }}
                        name={name}
                        label={bioObj.no}
                      />
                    </div>
                  </div>
                </FormField>
                <>{error?.message && displayError(`${t(error?.message)}`)}</>
              </>
            )}
          />

          {/* fo begreen programme */}

          <div className="row">
            <div className="col-md-6">
              <Controller
                name="appliedForOtherFoundationGrant"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { value, name, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={bioObj.applied}
                      id="appliedForOtherFoundationGrant"
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            selected={value}
                            onSelect={(_, value) => onChange(value)}
                            name={name}
                            label={bioObj.yes}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            selected={value}
                            onSelect={(_, value) => {
                              onChange(value);
                              setValue("nameOfOrganization", "");
                            }}
                            name={name}
                            label={bioObj.no}
                          />
                        </div>
                      </div>
                    </FormField>
                    <>{error?.message && displayError(error?.message)}</>
                  </>
                )}
              />
            </div>
          </div>
          <div>
            <Controller
              name="nameOfOrganization"
              control={control}
              render={({
                field: { value, name, onChange, onBlur },
                fieldState: { error },
              }) => (
                <>
                  <FormField
                    label={bioObj.ifYes}
                    placeholder={bioObj.orgName}
                    name={name}
                    value={value}
                    onChange={(_, val) => onChange(val)}
                    id="nameOfOrganization"
                    disabled={!watch().appliedForOtherFoundationGrant}
                    onBlur={onBlur}
                    error={error?.message}
                  />

                  <>{error?.message && displayError(error?.message)}</>
                </>
              )}
            />
          </div>
          <div className="row">
            <div className="col-md-6">
              <Controller
                name="typeOfId"
                control={control}
                render={({
                  field: { value, name, onChange, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={bioObj.typeOfId} id="typeOfId">
                      <Select
                        list={idtypes}
                        name={name}
                        value={value}
                        onChange={(_, val) => onChange(val)}
                        onBlur={onBlur}
                        error={error?.message}
                      />
                    </FormField>

                    <>{error?.message && displayError(error?.message)}</>
                  </>
                )}
              />
            </div>

            <div className="col-md-6" style={{ marginTop: "-5px" }}>
              <Controller
                name="idNumber"
                control={control}
                render={({
                  field: { value, name, onChange, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={bioObj.idNumber}
                      id="idNumber"
                      type="text"
                      onChange={(_, val) => onChange(val)}
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      placeholder={bioObj.idNumber}
                    ></FormField>
                    <>{error?.message && displayError(error?.message)}</>
                  </>
                )}
              />
            </div>
          </div>

          <Controller
            name="idUrl"
            control={control}
            render={({
              field: { value, name, onBlur },
              fieldState: { error },
            }) => (
              <>
                <FormField
                  label={bioObj.idScan}
                  type="file"
                  name={name}
                  id="idUrl"
                  value={previewImage}
                  onChange={_onIdImageChange}
                  onBlur={onBlur}
                  error={error?.message}
                >
                  <div>
                    <div>
                      <div
                        className="id-upload-btn col-md-5"
                        onClick={() =>
                          document.getElementById("previewImage").click()
                        }
                      >
                        <span>
                          <FaUpload color="white" size={12} />
                        </span>
                        <span>{t("Choose file")}</span>
                        <span>
                          {isuploadingId && (
                            <Loader
                              type="Oval"
                              height={13}
                              width={13}
                              color="white"
                            />
                          )}
                        </span>
                      </div>
                    </div>
                    <input
                      type="file"
                      // value={previewImage}
                      name="previewImage"
                      id="previewImage"
                      onChange={_onIdImageChange}
                      className="id-file-upload"
                      accept="image/x-png,image/jpeg"
                    />
                    {/* <span>loading</span> */}
                  </div>

                  {watch().idUrl && (
                    <div className="id-upload" data-testid="id-picture">
                      <img
                        src={value}
                        alt="avatar"
                        // className="img-fluid"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  )}
                </FormField>
                {fileError && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {fileError}
                  </span>
                )}

                <>{error?.message && displayError(error?.message)}</>
              </>
            )}
          />

          <div>
            <Controller
              name="howYouHearAboutUs"
              control={control}
              render={({
                field: { value, name, onChange, onBlur },
                fieldState: { error },
              }) => (
                <>
                  <FormField label={bioObj.programme} id="howYouHearAboutUs">
                    <Select
                      list={aboutUs}
                      name={name}
                      value={value}
                      onChange={(_, val) => onChange(val)}
                      onBlur={onBlur}
                      error={error?.message}
                    />
                  </FormField>

                  <>{error?.message && displayError(error?.message)}</>
                </>
              )}
            />
          </div>

          {programmeType.toLowerCase() === "offcycle" && (
            <div>
              <Controller
                name="motivation"
                control={control}
                render={({
                  field: { value, name, onChange, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={bioObj.motivation}
                      hasCounter
                      maxLength={1000}
                      type="textarea"
                      placeholder={t(
                        "Tell us about what motivates you to apply for the program"
                      )}
                      value={value}
                      name={name}
                      onChange={onChange}
                      id="motivation"
                      onBlur={onBlur}
                      error={error?.message}
                    />

                    <>{error?.message && displayError(error?.message)}</>
                  </>
                )}
              />
            </div>
          )}

          <br />

          <br />
          <div className="submit-box">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <button className="submit-btn" type="submit">
                  <span>{bioObj.continue}</span>
                  {loadingSave && (
                    <Loader type="Oval" color="white" height={15} width={15} />
                  )}
                </button>
              </div>
              <div className="col-md-6 col-sm-12">
                <button
                  className="save-continue-btn"
                  type="button"
                  onClick={toggleModal}
                >
                  <span>{btns.discard}</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <CLoader
        loading={state.Application.loadingUserApplications || loadingSave}
        additonalInfo={loadingSave ? updatingMessage : ""}
      />
    </div>
  );
};
export default BioEdit;
