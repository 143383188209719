import React from "react";
import {
  Col,
  Card,
  CardTitle,
  CardText,
  CardImg,
  CardBody,
  CardSubtitle,
  Media,
} from "reactstrap";
import "./index.scss";

const NewsCard = ({
  img,
  title,
  content,
  author,
  date,
  alt,
  className,
  style,
  isListMode,
  md,
  onClick,
}) => {
  return (
    <Col
      className="news-card-board"
      md={md || 0}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <div className="col-md-12 pl-1 pr-1">
        {isListMode ? (
          <Media className="news-card-board__list-view-box">
            <Media left top href="#">
              <Media
                object
                width={80}
                height={60}
                src={img}
                alt={alt || "News Card Img"}
              />
            </Media>
            <Media className="news-card-board__list-view-box__list_body" body>
              <Media className="news_card_title pt-2" heading>
                {title}
              </Media>
              <h6 className="mb-2 news_card_sub_title">{date}</h6>
            </Media>
          </Media>
        ) : (
          <Card className="news-card-board__card-box">
            <CardImg
              top
              width="100%"
              height={150}
              src={img}
              alt={alt || "News Card Img"}
            />
            <CardBody className="news-card-board__card-box__body">
              <CardTitle tag="h5" className="news_card_title">
                {title}
              </CardTitle>
              <CardSubtitle tag="h6" className="mb-2 news_card_sub_title">
                By <b>{author.toUpperCase()}</b> -- {date}
              </CardSubtitle>
              <CardText className="news-card-board__card-box__content">
                {" "}
                {content}
              </CardText>
            </CardBody>
          </Card>
        )}
      </div>
    </Col>
  );
};

export default NewsCard;
