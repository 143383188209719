import React, { useEffect, useState } from "react";
import { Button, CardGroup } from "reactstrap";
import { RiHeart3Line, RiHeart3Fill } from "react-icons/ri";
import Card from "src/modules/Alumni/Resources/components/Card/index";
import SecondaryButton from "src/modules/Alumni/Components/SecondaryButton/index";

import "./index.scss";
import { _truncateText } from "src/utils/helper";
import ReactTooltip from "react-tooltip";

const VideoTab = ({ resources, onLike, onOpen, ErrorEmpPage }) => {
  // const [vidoeLength, setVideoLength]= useState

  // const getVidlength = (src) => {
  //   let length = "0.00";
  //   const vidElement = document.createElement("video");
  //   vidElement.src = src;
  //   vidElement.addEventListener("loadedmetadata", () => {
  //     length = vidElement.duration;
  //   });
  //   return length;
  // };

  const [cardWith, setCardWidth] = useState(0);

  useEffect(() => {
    window.addEventListener("load", () => {
      _setCartWidth();
    });

    window.addEventListener("resize", () => {
      _setCartWidth();
    });
  }, []);

  const _setCartWidth = () => {
    const elem = document.getElementById("vid-cont");
    if (elem) {
      setCardWidth(elem.offsetWidth);
    }
  };

  return (
    <CardGroup className={"mb-2"}>
      {resources.length > 0 ? (
        resources.map((data) => {
          const titleTipId = data.id;

          return (
            <>
              <Card
                id="vid-cont"
                md={3}
                key={data.id}
                img={
                  data.coverPageUrl ||
                  "https://www.pcclean.io/wp-content/uploads/2020/4/MzUfam.jpg"
                }
                TopRightEle={
                  <Button color="tef-grey" outline onClick={() => onLike(data)}>
                    {!data.isLiked ? (
                      <RiHeart3Line color="fff" style={{ fontSize: 15 }} />
                    ) : (
                      <RiHeart3Fill color={"da291c"} style={{ fontSize: 15 }} />
                    )}
                  </Button>
                }
                BottomLeftEle={
                  <SecondaryButton
                    buttonText="Watch Video"
                    style={{ borderRadius: 10, height: 30, fontSize: 7 }}
                    buttonAction={() => onOpen(data)}
                  />
                }
                // BottomRightEle={
                //   <>
                //     {cardWith < 199 ? null : (
                //       <div>
                //         <Badge
                //           onClick={() => getVidlength(data.fileUrl)}
                //           color="h1-black-new"
                //           pill
                //           style={{ opacity: 0.9, padding: 10 }}
                //         >
                //           {getVidlength(data.fileUrl)}
                //         </Badge>
                //       </div>
                //     )}
                //   </>
                // }
                content={
                  <div
                    className="normal_card_container text-bold"
                    style={{ marginTop: cardWith < 199 ? "-10px" : "" }}
                    data-tip
                    data-for={titleTipId}
                  >
                    <ReactTooltip id={titleTipId} place="top" effect="solid">
                      {data.title}
                    </ReactTooltip>
                    {_truncateText(data.title, 20)}
                  </div>
                }
              />
            </>
          );
        })
      ) : (
        <ErrorEmpPage />
      )}
    </CardGroup>
  );
};

export default VideoTab;
