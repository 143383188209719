import React, { Fragment, useEffect, useContext, useState } from "react";
import classes from "./ViewComments.module.css";
import UserReactions from "src/modules/Tools/components/UserReaction/UserReactions";
import Avatar from "src/components/Avatar/Avatar";
import { getComments } from "src/requests/ToolsRequest";
import { Context } from "src/store";
import { EditorContentDisplay } from "src/components/Editor";
// import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { getCommenterDetails } from "src/requests/OpportunityRequest";

const ViewComments = ({ toolId, reloadComments }) => {
  const { dispatch } = useContext(Context);
  const [comments, setComments] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    _getComments();
  }, [toolId, reloadComments, reload]);

  const reloadHandler = () => {
    setReload(!reload);
  };

  const _getComments = async () => {
    try {
      const comments = await getComments(dispatch, toolId);
      const {
        data: { data },
      } = comments;
      if (data) {
        const commentCreators = data.map((cc) => cc.createdBy);
        const commenters = await _getCommenterDetails(commentCreators);
        const mappedComment = data.map((t1) => ({
          ...t1,
          ...commenters.filter((t2) => t2.userId === t1.createdBy),
        }));
        // console.log(mappedComment);
        setComments(mappedComment);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
        confirmButtonColor: "#FC5A5A",
      });
    }
  };

  const _getCommenterDetails = async (id) => {
    const commenter = await getCommenterDetails(id);
    const {
      data: { data },
    } = commenter;
    return data;
  };

  return (
    <div className={classes.ViewComment}>
      {comments &&
        comments.map((comment) => (
          <Fragment key={comment.id}>
            <div className={classes.UserInfo}>
              <div className={classes.UserImage}>
                {comment && (
                  <Avatar
                    src={comment.imageUrl}
                    dimension="30px"
                    name={`${comment["0"].firstName} ${comment["0"].lastName}`}
                  />
                )}
              </div>
              <div className={classes.Comment}>
                <div className={classes.UserDetails}>
                  <div
                    className={`${classes.UserName} notranslate`}
                  >{`${comment["0"].firstName} ${comment["0"].lastName}`}</div>
                </div>
                <div className={classes.CommentBody}>
                  <EditorContentDisplay content={comment.message} readOnly />
                </div>
                <UserReactions
                  message={comment.message}
                  commentId={comment.id}
                  totalLikes={comment.totalLikes}
                  allLikesIds={comment.allLikesIds}
                  totalReplies={comment.totalReplies}
                  createdBy={comment.createdBy}
                  toolId={toolId}
                  reload={reloadHandler}
                />
              </div>
            </div>
          </Fragment>
        ))}
    </div>
  );
};

export default ViewComments;
