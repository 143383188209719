import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import PropTypes from "prop-types";
import { AiFillCheckCircle, AiFillWarning } from "react-icons/ai";
import classes from "./StatusModal.module.scss";
import Loader from "src/components/Loader/Loader";

const StatusModal = ({
  status,
  heading,
  text,
  onButtonClick,
  open,
  showButton,
  buttonText,
  toggle,
  loading,
}) => {
  // const _renderContent=()=>{
  //   switch
  // }
  return (
    <Modal isOpen={open} centered size="sm" toggle={toggle}>
      <ModalBody className={classes.contentContainer}>
        {(status === "error" || status === "danger") && (
          <AiFillWarning
            size={120}
            color="var(--tef-red)"
            className={classes.icon}
          />
        )}
        {status === "success" && (
          <AiFillCheckCircle
            size={120}
            color="var(--pre-green)"
            className={classes.icon}
          />
        )}

        {heading && <h2 className={classes.heading}>{heading}</h2>}

        {text && <p className={classes.text}>{text}</p>}

        {showButton && (
          <Button
            onClick={onButtonClick}
            className={`${classes.button}`}
            disabled={loading}
          >
            {buttonText}
          </Button>
        )}
        {loading && <Loader size={20} />}
      </ModalBody>
    </Modal>
  );
};

StatusModal.defaultProps = {
  status: "success",
  heading: "",
  text: "",
  onButtonClick: () => {},
  open: false,
  showButton: true,
  buttonText: "Confirm",
};

StatusModal.propTypes = {
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  open: PropTypes.bool,
  onButtonClick: PropTypes.func,
  text: PropTypes.string,
  heading: PropTypes.string,
  status: PropTypes.oneOf(["warning", "success", "error"]).isRequired,
  toggle: PropTypes.func,
};

export default StatusModal;
