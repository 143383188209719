/* eslint-disable no-new */
import React from "react";

import { Link } from "react-router-dom";
import "./index.scss";

const NewAuthLayout = ({ children }) => {
  return (
    <main className="auth-layout">
      <div className="auth-layout__main">{children}</div>
      <ul className="auth-layout__link-main">
        <li>
          <Link to="#" className="main-link-text">
            &copy; {new Date().getFullYear()}
          </Link>
        </li>
        <li>
          <a
            href="https://www.tonyelumelufoundation.org/"
            target="_blank"
            rel="noreferrer"
            className="main-link-text"
          >
            About TEF
          </a>
        </li>
        <li>
          <Link to="/terms-of-use" className="main-link-text">
            Terms Of Use
          </Link>
        </li>
        <li>
          <Link to="/privacy" className="main-link-text">
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link to="/feedback" className="main-link-text">
            Send Feedback
          </Link>
        </li>
      </ul>
    </main>
  );
};

export default NewAuthLayout;
