import axios from "axios";
import { AlumniRequestURL } from "src/constants";

export const createChatGroup = (dispatch, formData) => {
  return axios({
    method: "post",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_CHAT_URL}/groups`,
    data: formData,
  });
};

// export const getChatBookmarks = (dispatch) => {
//   return axios({
//     method: "get",
//     url: `${AlumniRequestURL.DEFAULT_ALUMNI_CHAT_URL}/bookmarks`,
//   });
// };
export const getChatBookmarks = (dispatch, pageNumber) => {
  return axios({
    method: "get",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_CHAT_URL}/bookmarks/v2?PageNumber=${pageNumber}&MaxItem=50`,
  });
};
// export const getChatBookmarks = (dispatch) => {
//   return axios({
//     method: "get",
//     url: `${AlumniRequestURL.DEFAULT_ALUMNI_CHAT_URL}/bookmarks`,
//   });
// };

export const postGroupChatMessage = (dispatch, data) => {
  return axios({
    method: "post",
    data: data,
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_CHAT_URL}/messages/group`,
  });
};

export const postPersonalChatMessage = (dispatch, data) => {
  return axios({
    method: "post",
    data: data,
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_CHAT_URL}/messages/user`,
  });
};

export const getPersonalMessageHistory = (dispatch, userId) => {
  return axios({
    method: "get",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_CHAT_URL}/messages/user/${userId}`,
  });
};

export const getGroupMessageHistory = (dispatch, groupId) => {
  return axios({
    method: "get",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_CHAT_URL}/messages/group/${groupId}`,
  });
};

export const addUserToGroup = (dispatch, data) => {
  return axios({
    method: "post",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_CHAT_URL}/groups/users`,
    data: data,
  });
};

export const getUserGroups = (dispatch) => {
  return axios({
    method: "get",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_CHAT_URL}/user/groups`,
  });
};
export const getUserProfile = (param) => {
  return axios({
    method: "get",
    url: `${AlumniRequestURL.GET_USER_PROFILE}/${param}`,
  });
};

export const getGroupUsers = (id) => {
  return axios({
    method: "get",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_CHAT_URL}/group/${id}/users`,
  });
};
export const joinUserToGroup = (payload) => {
  return axios({
    method: "post",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_CHAT_URL}/groups/users`,
    data: payload,
  });
};

export const markAsRead = (dispatch, data) => {
  return axios({
    method: "post",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_CHAT_URL}/messages/hasread`,
    data: data,
  });
};

export const restrictPosting = (payload) => {
  return axios({
    method: "post",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_CHAT_URL}/restrict/toggle`,
    data: payload,
  });
};

export const getMessageViewers = (chatMessageId) => {
  return axios({
    method: "get",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_CHAT_URL}/messages/users/group/${chatMessageId}`,
  });
};

export const fromUserFromGroup = (data) => {
  return axios({
    method: "delete",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_CHAT_URL}/groups/users/${data.groupId}`,
    data: data.userIds,
  });
};

export const markAllMessageAsRead = (id) => {
  return axios({
    method: "post",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_CHAT_URL}/mark-all-read/${id}`,
  });
};
