import React from "react";
import { Card, Button, Row, Col } from "reactstrap";
import { FaRegHeart, FaPlusCircle } from "react-icons/fa";
import MentorshipStats from "src/modules/mentorship/components/MentorshipStats/MentorshipStats";
import Goals from "src/modules/mentorship/components/Goals/Goals";
import MentorGoalsView from "src/modules/mentorship/components/Goals/MentorGoalsView";
import FindMentors from "src/modules/mentorship/components/Mentors/FindMentors";
import Mentors from "src/modules/mentorship/components/Mentors/Mentors";
import { TabContent, Tabs } from "src/components/CustomTab/CustomTab";
import Request from "src/modules/mentorship/components/Requests/Requests";
import "./MainContent.scss";
import FaqList from "src/modules/mentorship/components/FaqList/FaqList";
import { isMentor } from "src/modules/mentorship/components/helper";
import Availabilty from "src/modules/mentorship/Availability/Availabilty";
import Survey from "src/modules/mentorship/components/Survey/Survey";
import GeneralSurvey from "src/modules/mentorship/components/Survey/GeneralSurvey";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import Calendar from "src/modules/mentorship/Calendar/CalendarHome";

const MainContent = ({ currentTab, toggle, setCurrentTab, role }) => {
  const user = get_user_info_from_token().user;

  const renderTabContent = () => {
    return (
      <Tabs activeTab={currentTab}>
        <TabContent title="My Board">
          <Card className="main-card">
            <div className="main-card-container">
              <Row className="a-center">
                <Col md={6}>
                  <h3 className="my-board-heading">
                    My Board{"  "}
                    <span className="a-info">
                      {"  "}
                      <FaRegHeart color="var(--new-faded-white)" />
                    </span>
                  </h3>
                </Col>

                <Col md={6}>
                  <Button
                    color="secondary"
                    outline
                    className="header-button-white goal-button float-right"
                    onClick={() => toggle()}
                  >
                    New Goal <FaPlusCircle />
                  </Button>
                </Col>
              </Row>
              <MentorshipStats />
              {isMentor(user) ? <MentorGoalsView /> : <Goals toggle={toggle} />}
              <br />
            </div>
          </Card>
        </TabContent>

        <TabContent
          title={role === "Entrepreneur" ? "My Mentors" : "My Mentees"}
        >
          <Card className="main-card">
            <Request role={role} setCurrentTab={setCurrentTab} />

            <div className="main-card-container">
              <Row>
                <h3 className="my-board-heading">
                  {role === "Entrepreneur" ? "My Mentors" : "My Mentees"}
                </h3>
                <Col md={6} className="top-row-icon">
                  <span className="top-row-text">
                    {/* Show: <b>All Mentors</b> <FaCaretDown /> */}
                  </span>
                </Col>
                <Col md={3} className="">
                  {/* <Input
                  className="mentor-search"
                  type="search"
                  placeholder="Search for mentors"
                /> */}
                </Col>
              </Row>
              <Mentors setCurrentTab={setCurrentTab} role={role} />
            </div>
          </Card>
        </TabContent>

        <TabContent title="FAQ">
          <Card className="main-card">
            <div className="main-card-container">
              <FaqList />
            </div>
          </Card>
        </TabContent>

        <TabContent
          title={role === "Entrepreneur" ? "Find A Mentor" : "Find A Mentee"}
        >
          <Card className="main-card">
            <div className="main-card-container">
              <Row>
                {/* <h3 className="my-board-heading">
                  {" "}
                  {role === "Entrepreneur" && "Find A Mentor"}{" "}
                  {role === "Mentor" && "Find A Mentee"}
                </h3> */}
                <Col md={6} className="top-row-icon">
                  {/* <span className="top-row-text">
                  Show: <b>All Mentors</b> <FaCaretDown />
                </span> */}
                </Col>
                {/* <Col md={3} className="">
                <Input
                  className="mentor-search"
                  type="search"
                  placeholder="Search for mentors"
                />
              </Col> */}
              </Row>
              <FindMentors setCurrentTab={setCurrentTab} role={role} />
            </div>
          </Card>
        </TabContent>

        <TabContent title="Activity">
          <Card className="main-card">
            <div className="main-card-container">
              <Row>
                <h3 className="my-board-heading">All Activity</h3>
              </Row>
              {/* <Activity /> */}
              <h4>Coming Soon</h4>
            </div>
          </Card>
        </TabContent>

        <TabContent title="Surveys">
          <Card className="main-card">
            <div className="main-card-container">
              <h3 className="my-board-heading">General Assessment</h3>
              <GeneralSurvey />
            </div>
          </Card>
          <Card className="main-card">
            <div className="main-card-container">
              <h3 className="my-board-heading">
                {role === "Entrepreneur" && "My mentors"}{" "}
                {role === "Mentor" && "My mentees"}
              </h3>
              <Survey />
            </div>
          </Card>
        </TabContent>

        <TabContent title="Set Availability">
          <Availabilty />
        </TabContent>
        <TabContent title="Calendar" className="calendar_page">
          <Calendar />
        </TabContent>
      </Tabs>
    );
  };
  return <div>{renderTabContent()}</div>;
};

MainContent.propTypes = {};

export default MainContent;
