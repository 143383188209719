/* eslint-disable no-unused-vars */
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { CookiesProvider } from "react-cookie";
import JavascriptTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import * as serviceWorker from "./serviceWorker";
import { i18nInit } from "./i18n";
import { initSentry } from "./utils/sentry";
import {
  FLAG_DISABLE_THIRD_PARTY_NETWORK_CALLS,
  getBooleanFlagValue,
  processUrlFlagParams,
} from "./modules/Flags/urlFlagParams";
import { initGoogleAnalytics } from "./utils/googleAnalytics";

const loader = document.querySelector(".loader");
// const loaderText = document.querySelector(".loaderText");

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove("loader--hide");

const hideLoader = () => loader.classList.add("loader--hide");
// loaderText.classList.add("loaderText--hide");

JavascriptTimeAgo.addLocale(en);

processUrlFlagParams();

(async () => {
  if (!getBooleanFlagValue(FLAG_DISABLE_THIRD_PARTY_NETWORK_CALLS)) {
    initGoogleAnalytics();
    initSentry();
  }

  await i18nInit;

  ReactDOM.render(
    <Suspense fallback={<div>Loading...</div>}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </Suspense>,
    document.getElementById("root")
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
