/* eslint-disable no-unused-vars */
import moment from "moment";
import React, { useState, useContext, useEffect } from "react";
import { BsShare } from "react-icons/bs";
import { EditorContentDisplay } from "src/components/Editor";
import PollCardResult from "./PollCardResult";
import PollCardPreviewOPtions from "./PollCardPreview";
import classes from "./PollCard_v2.module.scss";
import { voteQuestion } from "src/requests/PostRequest";
import { Context } from "src/store";
import { useCurrentUser } from "src/utils/Services/currentUser";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  FacebookShareCount,
} from "react-share";
import ClickOutside from "react-click-outside-component";
import { convertTextForSharing } from "src/modules/homepage/helper";

const PollCardContent = ({ post, index }) => {
  const {
    content,
    hasExpired,
    postOptions,
    totalVoteCount,
    userVotes,
    // group,
    expireDate,
    id,
  } = post;
  const { dispatch } = useContext(Context);
  const loggedInUser = useCurrentUser();

  const [showResult, setShowResult] = useState(false);
  const [votes, setVotes] = useState(totalVoteCount);
  const [sendingVote, setSendingVote] = useState(false);
  const [toggleShareOptions, setToggleShareOptions] = useState(false);
  const [userVotesState, setUserVotesState] = useState(userVotes);
  const [options, setOptions] = useState(postOptions);

  // const [showLikes, setShowLikes] = useState(false);
  const handleShareToggle = () => setToggleShareOptions(!toggleShareOptions);
  const [shareText, setShareText] = useState("");

  useEffect(() => {
    if (toggleShareOptions) {
      setShareText(convertTextForSharing(content));
    }
  }, [toggleShareOptions]);

  const userHasVoted = () => {
    const hasVoted = userVotesState.some(
      (vote) => vote.userId === loggedInUser.userId
    );
    if (hasVoted) {
      setShowResult(true);
    }
  };

  useEffect(() => {
    userHasVoted();
  }, [userVotesState, loggedInUser]);

  const handleVote = (e) => {
    const voteInfo = {
      postOptionId: e.target.value,
      userId: loggedInUser.userId,
    };

    setSendingVote(true);

    voteQuestion(dispatch, voteInfo)
      .then((res) => {
        setVotes(votes + 1);
        setUserVotesState([...userVotesState, voteInfo]);
        incrementVoteCount(voteInfo.postOptionId);
      })
      .finally(() => {
        setSendingVote(false);
      });
  };

  const incrementVoteCount = (optionId) => {
    const pOptions = [...options];
    const optionIndex = pOptions.findIndex((option) => option.id === optionId);
    pOptions[optionIndex].voteCount += 1;
    setOptions(pOptions);
  };

  return (
    <div className={classes.container}>
      <EditorContentDisplay content={content} className={classes.question} />
      <span className={classes.info}>You can see how people vote.</span>

      {!hasExpired && !showResult && (
        <PollCardPreviewOPtions
          options={options}
          onVote={handleVote}
          loadingSubmit={sendingVote}
        />
      )}

      {(hasExpired || showResult) && (
        <PollCardResult options={options} total={votes} />
      )}

      <div className={classes.actionBtns}>
        <span className={classes.vote}>{votes} Vote(s) </span> <span>|</span>
        <span>
          {!hasExpired
            ? `${moment(expireDate || new Date()).toNow(true)} left`
            : "Expired"}
        </span>{" "}
        <span>|</span>{" "}
        <span
          id={`tray_id${index}`}
          onClick={handleShareToggle}
          className="more-button"
        >
          <BsShare />
          <span> Share</span>
        </span>
        <ClickOutside onClickOutside={() => setToggleShareOptions(false)}>
          <div
            className={
              toggleShareOptions ? "share-options" : "share-option hide-div"
            }
          >
            <ul className="share-options__lists">
              <li>
                <TwitterShareButton url="tefconnect.com" title={shareText}>
                  <TwitterIcon size={20} round={true} className="icon-tab" />
                  Share via Twitter
                </TwitterShareButton>
              </li>
              <li>
                <FacebookShareButton url="tefconnect.com" quote={shareText}>
                  <FacebookIcon size={20} round={true} className="icon-tab" />
                  Share via Facebook
                  <FacebookShareCount url="tefconnect.com" />
                </FacebookShareButton>
              </li>
              <li>
                <LinkedinShareButton url="tefconnect.com" source={shareText}>
                  <LinkedinIcon size={20} round={true} className="icon-tab" />
                  Share via LinkedIn
                </LinkedinShareButton>
              </li>
            </ul>
          </div>
        </ClickOutside>
      </div>
    </div>
  );
};

export default PollCardContent;
