import React from "react";

const Empty = ({ message }) => {
  const messageStyle = {
    color: "#ABB2BC",
    fontWeight: "500",
    fontStyle: "italic",
  };

  const emptyContainer = {
    margin: "5em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  };

  return (
    <div style={emptyContainer}>
      <small style={messageStyle}>{message}</small>
    </div>
  );
};

export default Empty;
