import { asyncActionName } from "src/utils/AsyncUtils";
import { FETCH_MENTOR_FAQ } from "src/actions";
import { initialState } from "src/store";

const MentorReducer = (state = initialState.FAQs, action) => {
  switch (action.type) {
    case asyncActionName(FETCH_MENTOR_FAQ).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(FETCH_MENTOR_FAQ).success:
      return {
        ...state,
        FAQs: action.payload.data,
      };
    case asyncActionName(FETCH_MENTOR_FAQ).failure:
      return {
        ...state,
        error: action.payload.status,
      };
    default:
      return state;
  }
};
export default MentorReducer;
