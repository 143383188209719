import React from "react";
import classes from "./MainContent_v2.module.scss";
import { Button } from "reactstrap";
import mega_woman from "src/assets/img/home/lady-with-megaphone.svg";
import { useTranslation } from "react-i18next";

const NoticeBoard = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.noticeBoard}>
      <div md={4} className={classes.noticeBoard__banner}>
        <Button color="secondary">{t("NOTICE BOARD")}</Button>

        <img src={mega_woman} alt="lady-with-megaphone" className="img-fluid" />
      </div>
      <div md={8} className={classes.noticeBoard__info}>
        <div className={classes.carouselContent}>
          <h4>Dear Applicant,</h4>
          <p>
            The 2023 TEF Flagship Business Management Training ends on the 21st
            of August, 2023. Kindly complete your training module and assessment
            on or before this date.
          </p>
          <p>
            Please note that the 2023 TEFxUNICEF BeGreen Entrepreneurship
            Programme has commenced. All applicants will be notified of their
            eligibility status on or before 20th of October, 2023.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NoticeBoard;
