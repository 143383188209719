import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./_header.scss";
import logo from "src/assets/img/logo/logo_200.png";
import { IoMdArrowDropdown } from "react-icons/io";
import { Logout } from "src/requests/logout";
import ReactAvatar from "react-avatar";
import { useOuterClick } from "src/utils/helper";

const Header = (props) => {
  const [responsive, setResponsive] = useState("topnav");
  const [showDropdown, setShowDropdown] = useState(false);

  const _handleSetResponsive = () => {
    let res = responsive;
    if (res === "topnav") {
      res += " responsive";
      setResponsive(res);
    } else {
      res = "topnav";
      setResponsive(res);
    }
  };
  const data = JSON.parse(localStorage.data).user;

  const toggleRef = useOuterClick(() => {
    setShowDropdown(!showDropdown);
  });

  const _dropDownContent = () => {
    return (
      <>
        {showDropdown ? (
          <div
            className="dropdown-content1"
            style={{ zIndex: 99999 }}
            ref={toggleRef}
          >
            <Link
              to="/"
              onClick={() => {
                localStorage.removeItem("userInfo");
                Logout(props.history);
              }}
            >
              Log out
            </Link>
          </div>
        ) : null}
      </>
    );
  };

  const _dropDownContent2 = () => {
    return (
      <>
        {showDropdown ? (
          <div
            className="dropdown-content2"
            style={{ zIndex: 99999 }}
            ref={toggleRef}
          >
            <Link
              to="/"
              onClick={() => {
                localStorage.removeItem("userInfo");
                Logout(props.history);
              }}
            >
              Log out
            </Link>
          </div>
        ) : null}
      </>
    );
  };
  return (
    <div className={responsive} id="myTopnav">
      <Link to="/home" className="logo">
        <img src={logo} alt="logo" className="img-fluid" />
      </Link>
      {_dropDownContent()}

      <div className="dropdown">
        <button
          className="dropbtn"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <ReactAvatar
            round
            size={34}
            name={`${data.firstName} ${data.lastName}`}
          />
          <IoMdArrowDropdown />
        </button>

        {_dropDownContent2()}
      </div>

      <Link to="#0" className="icon" onClick={_handleSetResponsive}>
        &#9776;
      </Link>
    </div>
  );
};

export default Header;
