import React, { useState, useContext, useEffect } from "react";
import { Card, Row, Col, Button } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import "./ProfileBanner.scss";
import TabNavs from "src/modules/profile/components/ProfileTabNav/ProfileTabNav";
import MainContent from "src/modules/profile/components/MainContent/MainContent";
import { Context } from "src/store";
import { getUserProfile } from "src/requests/ProfileRequest";
// import ReactAvatar from "react-avatar";
import { FiEdit2 } from "react-icons/fi";
import CModal from "src/modules/onboarding/components/CModal";
import { ProfileImageUpdate } from "src/modules/profile/ProfileEdit";
import { useCurrentUser } from "src/utils/Services/currentUser";
import queryString from "query-string";
import sendMessage from "src/utils/NewMessageHelper";
import Avatars from "src/components/Avatar/Avatar";
// import { _resolveRole } from "src/modules/mentorship/components/HomePage/HomePage";

const ProfileBanner = (props) => {
  const { state, dispatch } = useContext(Context);
  const [user, setUser] = useState(null);
  const currentUser = useCurrentUser();
  const [currentTab, setCurrentTab] = useState("About");
  const { id } = useParams();
  const history = useHistory();
  const titles = [
    "Timeline",
    "About",
    "Connections",
    "Photos",
    id === currentUser.userId && "Personal Goals",
  ];

  useEffect(() => {
    if (id !== currentUser.userId) {
      setUser(null);
      getUserProfile(dispatch, id);
    } else {
      setUser(currentUser);
    }
  }, [id, currentUser]);

  useEffect(() => {
    if (state.Profile.profile && id !== currentUser.userId) {
      setUser(state.Profile.profile);
    }
  }, [state.Profile.profile]);

  useEffect(() => {
    const setActiveTabsFromQuery = (value) => {
      setCurrentTab(value);
    };
    const { active } = queryString.parse(window.location.search);
    if (active) {
      setActiveTabsFromQuery(active);
    }
  }, [window.location.search]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openEditModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeEditModal = () => {
    setIsModalOpen(false);
  };

  const updateCurrentTabUrl = (value) => {
    const activeTab = value;
    history.push({
      pathname: window.location.pathname,
      search: `?active=${activeTab}`,
    });
  };

  return (
    <div>
      <Card>
        <div className="profile-banner notranslate">
          <Row>
            <Col md={3}>
              <div className="profile-banner__avatar-container">
                {
                  <Avatars
                    src={user && user?.imageUrl}
                    dimension={156}
                    showBadge={
                      user && user.userGroups && user.userGroups.length
                        ? user.userGroups
                            .map((g) => g.group.name)
                            // .toLowerCase()
                            .includes("tef-mentor")
                        : false
                    }
                    name={`${user && user.firstName} ${user && user.lastName}`}
                    badgeText="Mentor"
                  />
                }
                {id === currentUser.userId && (
                  <span className="edit-imag-icon" onClick={openEditModal}>
                    <FiEdit2 />
                  </span>
                )}
              </div>
            </Col>
            <Col md={4}>
              <div className="profile-banner__name-area">
                <h1>
                  {" "}
                  {user && user.firstName} {user && user.lastName}
                </h1>
              </div>
            </Col>
            <Col md={5}>
              <div className="profile-banner__button-area">
                {id !== currentUser.userId && (
                  <Button color="info" onClick={() => sendMessage(id, history)}>
                    Send Message
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <Col md={{ size: 9, offset: 3 }}>
            <TabNavs
              activeTab={currentTab}
              setTab={updateCurrentTabUrl}
              titles={titles}
            />
          </Col>
        </div>
      </Card>
      <>
        <CModal isOpen={isModalOpen} closeModal={closeEditModal}>
          <ProfileImageUpdate
            imageUrl={user && user.imageUrl ? user && user.imageUrl : ""}
            onCancel={closeEditModal}
          />
        </CModal>
      </>
      <MainContent
        currentTab={currentTab}
        setCurrentTab={updateCurrentTabUrl}
      />
    </div>
  );
};

ProfileBanner.propTypes = {};

export default ProfileBanner;
