import React, { useState, useContext, useEffect } from "react";
import classes from "./AddToolForm.module.css";
import { FormField } from "src/components/CustomFormField/CustomFormField";
import { toolsValidationErrors } from "src/modules/Tools/components/Helper/Helper";
import { displayError } from "src/modules/onboarding/helper";
import ValidateJs from "src/utils/Validations/validation_wrapper";
import ReactSelect from "react-select";
import { Row, Col } from "reactstrap";
import { FaRegFileImage } from "react-icons/fa";
import { UploadFile } from "src/requests/fileUploadRequest";
import Loader from "react-loader-spinner";
import { Context } from "src/store";
import { getToolsCategories } from "src/requests/ToolsRequest";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const AddToolForm = ({ data, onChange, onSubmit }) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);
  const [validationErrors, setValidationErrors] = useState(
    toolsValidationErrors
  );
  const [previewImage, setPreviewImage] = useState("");
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonText] = useState("+ Add Tool");
  const [toolCategories, setToolsCategories] = useState([]);

  useEffect(() => {
    _getToolCategories();
  }, []);

  const _getToolCategories = () => {
    getToolsCategories(dispatch)
      .then((res) => {
        if (res.status && res.status === 200) {
          const { data } = res.data;
          const categories = data.map((opt) => {
            return {
              value: opt.id,
              label: opt.name,
            };
          });
          setToolsCategories(categories);
        }
      })
      .catch(() =>
        toast(
          "An error has occurred while retrieving categories. Please try again"
        )
      );
  };

  const _handleValidationErrors = (target, value) => {
    setValidationErrors({ ...validationErrors, [target]: value });
  };

  const _onImageChange = (e) => {
    const file = e.target.files[0];
    const files = e.target.files;

    if (file) {
      const img = new Image();
      const url = URL.createObjectURL(file);
      const allowedSize = 1; // in megabite
      const fileSize = file.size / 1024 / 1024;

      img.onload = () => {
        if (fileSize > allowedSize) {
          setPreviewImage("");
          _handleValidationErrors("fileName", "Maximum file size exceeded");
        } else {
          setPreviewImage(url);
          setFile(files);
          _handleValidationErrors("fileName", "");
        }
      };
      img.src = url;
    }
  };

  const sanitizeData = (data) => {
    data = { ...data };
    const toolTopics = data.toolTopics.map((topic) => {
      return topic.value;
    });

    data.toolTopics = toolTopics;
    return data;
  };

  const _handleSubmit = (e) => {
    e.preventDefault();
    const errors = _hasValidationError(data);

    if (errors.confirmed) {
      // document.getElementById(errors.errors[0]).scrollIntoView();
    } else {
      setLoading(true);
      const sanitizedData = sanitizeData(data);
      if (previewImage) {
        UploadFile(dispatch, false, file).then((res) => {
          const { files } = res;
          const upload = files ? files[0] : "";
          sanitizedData.fileName = upload;
          onSubmit(sanitizedData);
        });
      } else {
        onSubmit(sanitizedData);
      }
      setLoading(false);
    }
  };

  const _hasValidationError = (data) => {
    const whitelist = ["toolTopics"];

    data = { ...data };
    let errors = {};
    const errorList = [];
    Object.keys(data).forEach((key) => {
      errors = { ...errors, [key]: ValidateJs(key, data[key]) };
    });

    whitelist.forEach((key) => {
      if (data[key] === "") {
        delete errors[key];
      }
    });

    if (previewImage || data.fileName) {
      delete errors.fileName;
    }

    Object.keys(errors).forEach((key) => {
      if (errors[key] !== null) {
        errorList.push(key);
      }
    });
    setValidationErrors(errors);

    return { confirmed: errorList.length > 0, errors: errorList };
  };

  const _handleTopicsChange = (target, topics) => {
    if (!topics || topics.length === 0) {
      return onChange(target, []);
    }
    return onChange(target, topics.slice(0, 4));
  };

  return (
    <div>
      <form className={classes.AddToolForm}>
        <div>
          <FormField
            label="Name of the tool"
            hasCounter
            maxLength={40}
            labelInfo="Required"
            placeholder="Type the name of the tool"
            value={data.toolTitle}
            name="toolTitle"
            onChange={onChange}
            id="toolTitle"
            error={validationErrors.toolTitle}
            onBlur={() =>
              _handleValidationErrors(
                "toolTitle",
                ValidateJs("toolsTitle", data.toolTitle)
              )
            }
          />
          {validationErrors.toolTitle &&
            displayError(validationErrors.toolTitle)}
        </div>
        <div>
          <FormField
            label="Short description"
            hasCounter
            maxLength={250}
            labelInfo="Required"
            placeholder="A simple overview of the tool"
            value={data.toolShortDescription}
            name="toolShortDescription"
            onChange={onChange}
            id="toolShortDescription"
            error={validationErrors.toolShortDescription}
            onBlur={() =>
              _handleValidationErrors(
                "toolsShortDescription",
                ValidateJs("toolsShortDescription", data.toolShortDescription)
              )
            }
          />
          {validationErrors.toolShortDescription &&
            displayError(validationErrors.toolShortDescription)}
        </div>
        <div>
          <FormField
            label="Topics"
            placeholder="Add topics e.g. Health, Technology"
            id="toolTopics"
            labelInfo="Requires at least 1 category"
            error={validationErrors.toolTopics}
          >
            <ReactSelect
              value={data.toolTopics}
              options={toolCategories && toolCategories}
              isMulti
              closeMenuOnSelect={false}
              onChange={(topics) => _handleTopicsChange("toolTopics", topics)}
              placeholder="Add topics e.g. Health, Technology"
            />
            <div>
              <span
                style={{
                  float: "right",
                  fontSize: "14px",
                  color: "var(--helper-text-color)",
                }}
              >
                {data.toolTopics.length}/4
              </span>
              <div style={{ clear: "both" }}></div>
            </div>
          </FormField>
        </div>

        <div>
          <FormField
            label="Webisite Link"
            placeholder="Provide link for more information, please include http:// or https://"
            id="toolOfficialWebsite"
            name="toolOfficialWebsite"
            labelInfo="Required"
            value={data.toolOfficialWebsite}
            onChange={onChange}
            type="url"
            error={validationErrors.toolOfficialWebsite}
            onBlur={() =>
              data.toolOfficialWebsite.length > 0
                ? _handleValidationErrors(
                    "toolOfficialWebsite",
                    ValidateJs("toolOfficialWebsite", data.toolOfficialWebsite)
                  )
                : _handleValidationErrors("toolOfficialWebsite", "")
            }
          ></FormField>
          {validationErrors.toolofficialwebsite &&
            displayError(validationErrors.toolofficialwebsite)}
        </div>
        <div>
          <FormField label="Upload Feature Photo" id="fileName">
            <Row>
              <Col md={6}>
                <div className="opp-create-form__image-c">
                  <div className="opp-form-img-box">
                    {!previewImage && !data.fileName ? (
                      <span className="opp-form-img-box__default-img">
                        {" "}
                        <FaRegFileImage />
                      </span>
                    ) : (
                      <>
                        <img src={previewImage || data.fileName} alt="logo" />
                      </>
                    )}
                  </div>
                  <div className="opp-form-btn-box">
                    <span>Upload photo</span>
                    <div
                      className="opp-form-btn-box__button"
                      onClick={() =>
                        document.getElementById("previewImage").click()
                      }
                    >
                      {t("Browse your files")}
                    </div>
                  </div>
                </div>
                {validationErrors.fileName &&
                  displayError(validationErrors.fileName)}

                <input
                  type="file"
                  // value={previewImage}
                  name="previewImage"
                  id="previewImage"
                  onChange={_onImageChange}
                  className="opp-file-upload"
                  accept="image/x-png,image/jpeg,image/gif"
                />
              </Col>

              <Col md={6}>
                <div className="opp-create-form-file-info">
                  {t("Recommended Dimension: 460 x 220")} <br />
                  {t("Max. size: 1MB")} <br />
                  {t("Format: PNG, GIF, JPG")}{" "}
                </div>
              </Col>
            </Row>
          </FormField>
        </div>
        <Row>
          <Col md={6}>
            <div className="submit-box">
              <div
                className="submit-btn"
                onClick={!loading ? (e) => _handleSubmit(e) : () => {}}
              >
                {buttonText}
                {loading && (
                  <span>
                    <Loader height={15} width={15} type="Oval" color="white" />
                  </span>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default AddToolForm;
