import React, { Fragment } from "react";
import { Input, Row, Col, Label, FormGroup } from "reactstrap";
import { FaRegFileImage } from "react-icons/fa";
import { Select } from "src/components/CustomFormField/CustomFormField";
// import { industryTypes } from "src/modules/onboarding/stepTwo/industryType.js";
// import { useTranslation } from "react-i18next";
import ImageUploader from "react-images-upload";
import "../TefStory/TefStories.scss";
import CreatableMulti from "src/components/MultiSelect/CreatableSelect";

const CreateArticleForm = ({
  toggle,
  inputHandler,
  selectHandler,
  industryObj,
  tagsOption,
  handleChange,
  onDrop,
  articleData,
}) => {
  // const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  // const [language, setLanguage] = useState();
  // const industryObj = industryTypes[language || "en"];
  return (
    <Fragment>
      <div className="create-tefstory-form mt-3">
        {/* <Row
          className="justify-content-end mb-2"
          style={{ paddingRight: "1.2rem" }}
        >
          <Col md={2} className="preview-btn text-center mr-3 w-25">
            <span className="pr-2">
              <FaEye />
            </span>
            Preview
          </Col>
          <Col
            md={2}
            className="submit-tef-story text-center w-25"
            onClick={toggle}
          >
            Publish Article
          </Col>
        </Row> */}
        <Row form>
          <Col sm={12} md={12}>
            <FormGroup>
              <Label htmlFor="storyTitle">
                Title of your article{" "}
                <span className="required"> - Required</span>
              </Label>
              <Input
                type="text"
                name="title"
                id="articleTitle"
                placeholder="Type the title of your article"
                maxLength={40}
                required
                onChange={inputHandler}
                value={articleData.title}
              />
              <span className="input-maxlength float-right">
                {articleData.title.length || 0}/40
              </span>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={12} md={12}>
            <FormGroup>
              <Label htmlFor="storyDescription">
                Short description <span className="required"> - Required</span>
              </Label>
              <Input
                type="text"
                name="shortDescription"
                id="storyDescription"
                placeholder="A simple description of your article"
                maxLength={60}
                required
                onChange={inputHandler}
                value={articleData.shortDescription}
              />
              <span className="input-maxlength float-right">
                {articleData.shortDescription.length || 0}/60
              </span>
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col sm={6} md={12}>
            <FormGroup>
              <Label htmlFor="category">
                Select category <span className="required">- Required</span>
              </Label>
              <Select
                defaultVal="Select Category"
                list={industryObj}
                value={articleData.categoryId}
                id="category"
                name="categoryId"
                onChange={selectHandler}
                required
              />
            </FormGroup>
          </Col>
          {/* <Col sm={6} md={3}>
            <FormGroup>
              <Label htmlFor="subCategory">
                Select sub category <span className="required">- Required</span>
              </Label>
              <Select
                defaultVal="Select sub category"
                list={industryObj}
                value=""
                id="subCategory"
                name="subCategory"
              />
            </FormGroup>
          </Col> */}
        </Row>

        <Row form>
          <Col sm={12} md={12}>
            <FormGroup>
              <Label htmlFor="articleTags">
                Add tags <span className="required"> - Optional</span>
              </Label>
              {/* <Input
                placeholder="Add tags"
                type="text"
                //   value=""
                name="articleTags"
                id="articleTags"
              /> */}
              <CreatableMulti
                options={tagsOption}
                onChange={handleChange}
                isMulti={true}
                required
              />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col sm={6} md={12}>
            <FormGroup>
              <Label htmlFor="articleImage">
                Upload Banner or Cover Image
                <span className="required"> - Optional</span>
              </Label>
              <div className="opp-create-form__image-c">
                <div className="opp-form-img-box">
                  <span className="opp-form-img-box__default-img">
                    {" "}
                    <FaRegFileImage />
                  </span>
                </div>
                <ImageUploader
                  withIcon={false}
                  singleImage={true}
                  withPreview={true}
                  buttonText="Choose image"
                  imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                  onChange={onDrop}
                  label="Recommended size: 144 x 144, Format: PNG, GIF, JPG Max. size: 1MB"
                />
              </div>
            </FormGroup>
          </Col>

          {/* <Col sm={6} md={3}>
            <FormGroup>
              <div className="opp-create-form-file-info pt-4">
                {"Recommended size: 144 x 144"} <br />
                {"Max. size: 1MB"} <br />
                Format: PNG, GIF, JPG{" "}
              </div>
            </FormGroup>
          </Col> */}
        </Row>
      </div>
    </Fragment>
  );
};

export default CreateArticleForm;
