/* eslint-disable node/handle-callback-err */
/* eslint-disable no-unused-vars */
/* eslint no-unneeded-ternary: "error" */
import React, { useContext, useEffect, useState } from "react";
import classes from "./LMSCourseCard.module.scss";
import { useHistory } from "react-router-dom";
import {
  // getUserCourses,
  hasAgreedToParticipate,
  // userStartsCourse,
} from "src/requests/TrainingRequest";
import { Context } from "src/store";
import { Progress, Popover, PopoverHeader, PopoverBody } from "reactstrap";
// import { Button } from "src/modules/training/components";

// import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { stringToColour, _truncateText } from "src/utils/helper";
import Loader from "react-loader-spinner";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { setCurrentUser, useCurrentUser } from "src/utils/Services/currentUser";
import TCMainIndex from "src/modules/training/components/TCmodal/TCMainIndex";
import ReactStars from "react-stars";
import entrepreneurCategories from "src/modules/training/components/entrepreneurCat.json";
import Swal from "sweetalert2";

const LMSCourseCard = ({
  title,
  imageUrl,
  programmeEventId,
  programmeId,
  courseId,
  lang,
  overview,
  segment,
  aveRating,
  totalModules,
  modulesCompleted,
  index,
}) => {
  const { dispatch } = useContext(Context);
  const history = useHistory();
  const [isRedirecting, setisRedirecting] = useState(false);
  const user = get_user_info_from_token().user;
  const [showModal, setShowModal] = useState(false);
  const [hasAgreed, setHasAgreed] = useState(false);
  const currentUser = useCurrentUser();
  // const [totalModules, setTotalModule] = useState(0);
  const [generalCoursesCat] = useState([entrepreneurCategories.Preparatory]);
  const [openPopOverInfo, setOpenPopOverInfo] = useState(false);

  const toggleModalTAndC = () => {
    hasAgreed ? redirectToCourse() : setShowModal(!showModal);
  };

  const redirectUrl = () => {
    return `/training/pid/${programmeId}/event/${programmeEventId}/course/${courseId}`;
  };

  const redirectToCourse = () => {
    return history.push(redirectUrl());
  };

  // useEffect(() => {
  //   getUserCourses(dispatch, programmeEventId, courseId).then((res) => {
  //     const { status, data } = res;
  //     if (status === 200 || status === 201) {
  //       if (data.data) {
  //         setCourseProgress(data.data.progress);
  //         setTotalModule(data.data.totalModules || 0);
  //       }
  //     }
  //   });
  // }, []);
  // }, [courseId]);

  const getAgreedToParticipation = () => {
    const payload = {
      userId: currentUser.userId,
      programmeEventId: user.training[1],
    };

    hasAgreedToParticipate(dispatch, payload).then((res) => {
      if ((res && res.status === 200) || (res && res.status === 201)) {
        const { agreement } = res.data;
        const hasAgreed = agreement;
        currentUser.hasAgreedToTraining = hasAgreed;
        setCurrentUser(currentUser);
        if (!hasAgreed) {
          setHasAgreed(hasAgreed);
          setisRedirecting(false);
          return setShowModal(true);
        }
        return redirectToCourse();
      }
    });
  };

  const computeProgress = () => {
    return modulesCompleted === Number(0)
      ? 0
      : Number((Number(modulesCompleted) / Number(totalModules)) * 100).toFixed(
          2
        );
  };

  const handleRedirect = () => {
    setisRedirecting(true);

    if (generalCoursesCat.indexOf(segment) >= 0) {
      return redirectToCourse();
    }

    if (generalCoursesCat.indexOf(segment) === -1 && !user.training) {
      Swal.fire({
        timer: 2000,
        title: "Forbidden",
        text: "You are not eligible to take this course",
      });
    }

    if (generalCoursesCat.indexOf(segment) === -1 && user.training) {
      if (
        Object.prototype.hasOwnProperty.call(currentUser, "hasAgreedToTraining")
      ) {
        const hasAgreedToTraining = currentUser.hasAgreedToTraining;
        if (hasAgreedToTraining) {
          return redirectToCourse();
        }
        setisRedirecting(false);
        return setShowModal(true);
      } else {
        return getAgreedToParticipation();
      }
    }
  };

  // const { training } = get_user_info_from_token().user;
  // const _userStartsCourse = (courseId) => {
  //   userStartsCourse(dispatch, trainingEventId, courseId);
  // };

  // const togglePopOverInfo = () => setOpenPopOverInfo(!openPopOverInfo);
  // const popOverId = shortid.generate().toString();

  const flagshipText = `For ${new Date().getFullYear()} Shortlisted Entrepreneurs`;
  const prepText = "General Business Management Toolkit";

  return (
    <>
      <div
        className={classes.LMSCourseCard}
        onClick={(e) => {
          handleRedirect();
          e.stopPropagation();
        }}
        id={`course${index}`}
        onMouseEnter={() => setOpenPopOverInfo(true)}
        onMouseLeave={() => setOpenPopOverInfo(false)}
      >
        <div
          className={classes.logoContainer}
          style={{ position: "relative", padding: "2px" }}
        >
          <img
            src={
              imageUrl
                ? imageUrl
                : "https://cdn.pixabay.com/photo/2017/04/19/13/16/computer-2242264_1280.jpg"
            }
            alt="Course Logo"
            className={`${classes.Logo} img-fluid`}
          />
          <div className={classes.shade}>
            {isRedirecting && (
              <Loader color="white" height={30} width={30} type="Circles" />
            )}
          </div>
        </div>

        <div className={classes.card_content_below}>
          <div className={classes.CardWithoutImage}>
            <div className={classes.Title}>{_truncateText(title, 45)}</div>

            <div className={classes.percentageCompletedText}>
              <span
                style={{
                  color: stringToColour(
                    segment === entrepreneurCategories.Preparatory
                      ? segment
                      : flagshipText
                  ),
                }}
              >
                {segment === entrepreneurCategories.Preparatory
                  ? prepText
                  : flagshipText}
              </span>
            </div>
            <Progress
              value={Math.round(computeProgress()).toString()}
              color="#0db884"
              className={classes.pBar}
            />
            <div className={classes.percentageCompletedText}>
              <div className={classes.percentageCompletedTextC}>
                <span>{Math.round(computeProgress())}% Complete</span>
                <span>{totalModules} modules</span>
              </div>
            </div>

            <div className={classes.percentageCompletedText}>
              <span
                className="float-right"
                style={{
                  color: "rgb(187, 179, 179)",
                  marginTop: "2px",
                  marginLeft: "2px",
                }}
              >
                {aveRating && aveRating > 0
                  ? `(${Number(aveRating).toFixed(2)})`
                  : ""}
              </span>
              <span className="float-right">
                <ReactStars
                  count={5}
                  value={Number(aveRating).toFixed(2)}
                  size={17}
                  color2={"#ffb319"}
                  half
                  edit={false}
                  // className="training-course-rating"
                  color1={"rgb(187, 179, 179)"}
                />
              </span>
            </div>
          </div>
        </div>
      </div>

      <Popover
        placement="right"
        isOpen={openPopOverInfo}
        target={`course${index}`}
        // toggle={togglePopOverInfo}
      >
        <PopoverHeader style={{ background: "var(--tef-red)", color: "white" }}>
          {title}
        </PopoverHeader>
        <PopoverBody>
          {segment.toLowerCase() ===
          entrepreneurCategories.Preparatory.toLowerCase()
            ? "This is an optional course available to ALL TEFConnect subscribers(Ungraded)."
            : "This is a mandatory and graded TEF Programme training course for entrepreneurs in this category."}
        </PopoverBody>
      </Popover>

      <TCMainIndex
        show={showModal}
        userId={currentUser.userId}
        toggle={toggleModalTAndC}
        redirectUrl={redirectUrl()}
      />
    </>
  );
};

export default LMSCourseCard;
