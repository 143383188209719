import React, { useState, useContext, useEffect, Fragment } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import ReactAvatar from "react-avatar";
import "./AllMembers.scss";
import { getAllGroupUsers, removeUser } from "src/requests/StudyGroupsRequest";
import queryString from "query-string";
import { useLocation, Link } from "react-router-dom";
import { Context } from "src/store";
import Skeleton from "react-loading-skeleton";
import { FaTimesCircle } from "react-icons/fa";
import { onHandleDelete } from "src/utils/helper";
import ResultPagination from "src/modules/training/StudyGroups/components/ResultPagination/ResultPagination";
import classnames from "classnames";
import { TabContent, Tabs } from "src/components/CustomTab/CustomTab";
import GroupRequests from "src/modules/training/StudyGroups/components/Group/component/Requests/Requests";

const AllMembers = ({ backgroundColor, belongsToId, userId }) => {
  const [searchparam, setSearchparam] = useState("");
  const { state, dispatch } = useContext(Context);
  const qa = useLocation();
  const { groupId } = queryString.parse(qa.search);
  const isAdmin = belongsToId === userId;
  const [paging, setPaging] = useState({
    hasNextPage: false,
    pageCount: 0,
    pageNumber: 1,
  });

  const [selectedTab, setSelectedTab] = useState("1");

  const getGroupUsers = (pageNumber) => {
    getAllGroupUsers(dispatch, groupId, searchparam, pageNumber);
  };

  useEffect(() => {
    getGroupUsers(paging.pageNumber);
  }, [searchparam, state.Communities.removeUser, paging.pageNumber]);

  const handleDelete = (id) => {
    const payload = {
      userId: id,
      groupId,
    };
    onHandleDelete(removeUser, dispatch, payload);
  };

  useEffect(() => {
    setPaging({
      ...paging,
      ...state.Communities.groupUsers.pager,
    });
  }, [state.Communities.groupUsers]);

  const _memberToggler = (value) => setSelectedTab(value);

  const onPageChange = (value) => {
    if (value) {
      value = value.selected + 1;
      getGroupUsers(value);
    }
  };

  return (
    <div
      style={{ backgroundColor: backgroundColor || "#f9fbfd" }}
      className="mb-5 px-2 py-4"
    >
      <div className="members-toggler-C">
        <Label
          onClick={() => _memberToggler("1")}
          className={`mb-2 ${classnames({
            "membeers-toggler-C__active": selectedTab === "1",
          })}`}
        >
          Members
        </Label>
        {isAdmin && (
          <Label
            onClick={() => _memberToggler("2")}
            className={`mb-2 ${classnames({
              "membeers-toggler-C__active": selectedTab === "2",
            })}`}
          >
            Requests
          </Label>
        )}
      </div>

      <Tabs activeTab={selectedTab}>
        <TabContent title="1">
          <React.Fragment>
            <Form>
              <FormGroup>
                <Input
                  type="search"
                  name="searchparam"
                  id="searchMembers"
                  placeholder="Find a Member"
                  value={searchparam}
                  onChange={(e) => setSearchparam(e.target.value)}
                />
              </FormGroup>
            </Form>

            {state.Communities.groupUsersLoading ? (
              <Fragment>
                <div className="d-flex align-items-center px-3">
                  <div className="mr-2">
                    <Skeleton circle={true} height={35} width={35} />
                  </div>
                  <div>
                    <Skeleton width={100} />
                  </div>
                </div>
                <div className="d-flex align-items-center px-3">
                  <div className="mr-2">
                    <Skeleton circle={true} height={35} width={35} />
                  </div>
                  <div>
                    <Skeleton width={100} />
                  </div>
                </div>
                <div className="d-flex align-items-center px-3">
                  <div className="mr-2">
                    <Skeleton circle={true} height={35} width={35} />
                  </div>
                  <div>
                    <Skeleton width={100} />
                  </div>
                </div>
              </Fragment>
            ) : (
              <div className="member-display-area">
                {state.Communities &&
                  state.Communities.groupUsers &&
                  state.Communities.groupUsers.data &&
                  state.Communities.groupUsers.data.map((member, index) => (
                    <div key={index} className="all-members-list">
                      <Link to={`/profile/${member.userId}`}>
                        {member.imageUrl && member.imageUrl !== "string" ? (
                          <img
                            src={member.imageUrl}
                            className="all-members-display-pic "
                            alt="display"
                          />
                        ) : (
                          <ReactAvatar
                            name={member.firstName + " " + member.lastName}
                            size={35}
                            round={true}
                          />
                        )}
                        <small className="all-members-list-name">
                          {member.firstName + " " + member.lastName}{" "}
                          {/* {isAdmin && ( */}
                          {/* // <span className="all-members-list-name-admin"> */}
                          {/* {" "} */}
                          {/* - admin{" "} */}
                          {/* </span> */}
                          {/* // ) */}
                        </small>
                      </Link>

                      {isAdmin && member.userId !== belongsToId && (
                        <FaTimesCircle
                          color="red"
                          onClick={() => handleDelete(member.userId)}
                          style={{ cursor: "pointer" }}
                          title="Remove"
                        />
                      )}
                    </div>
                  ))}
              </div>
            )}
            {(paging.hasNextPage || paging.hasPreviousPage) && (
              <ResultPagination
                pageRange={5}
                pageCount={paging.pageCount}
                current={paging.pageNumber}
                onSelect={onPageChange}
              />
            )}
          </React.Fragment>
        </TabContent>
        {/* {isAdmin && ( */}
        <TabContent title="2">
          <GroupRequests groupId={groupId} getGroupUsers={getGroupUsers} />
        </TabContent>
        {/* )} */}
      </Tabs>
    </div>
  );
};

export default React.memo(AllMembers);
