import { asyncActionName } from "src/utils/AsyncUtils";
import { GET_USERS_GROUP } from "src/actions";
import { initialState } from "src/store";

const UserGroupReducer = (
  state = initialState.userGroups,
  { type, payload }
) => {
  switch (type) {
    case asyncActionName(GET_USERS_GROUP).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_USERS_GROUP).success:
      return {
        ...state,
        groups: payload,
        success: true,
      };
    case asyncActionName(GET_USERS_GROUP).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    default:
      return state;
  }
};

export default UserGroupReducer;
