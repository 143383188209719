import { asyncActionName } from "src/utils/AsyncUtils";
import { initialState } from "src/store";
import * as types from "src/actions";

const InterestReducer = (state = initialState.interests, action) => {
  switch (action.type) {
    // interests
    case asyncActionName(types.GET_INTERESTS).loading:
      return { ...state, requestingInterests: action.payload };
    case asyncActionName(types.GET_INTERESTS).success:
      return {
        ...state,
        interests: action.payload,
      };
    case asyncActionName(types.GET_INTERESTS).failure:
      return {
        ...state,
        requestingInteretsError: action.payload.status,
      };

    case asyncActionName(types.GET_SINGLE_INTEREST).loading:
      return { ...state, requestingInterest: action.payload };
    case asyncActionName(types.GET_SINGLE_INTEREST).success:
      return {
        ...state,
        interest: action.payload,
      };
    case asyncActionName(types.GET_SINGLE_INTEREST).failure:
      return {
        ...state,
        requestingInteretError: action.payload.status,
      };

    default:
      return state;
  }
};
export default InterestReducer;
