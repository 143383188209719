import React from "react";
import "./Articles/Article.scss";

const Video = ({ video, title }) => {
  return (
    <div className="article-detail">
      <video src={video} controls className="article-detail__video" />
      <div className="article-detail__details">
        <h1 className="Article-title">{title}</h1>
      </div>
    </div>
  );
};

export default Video;
