import React, { useEffect, useState, useContext } from "react";
import Layout from "src/components/Layout/Layout";

import "../CreateOpportunity/CreateOpportunity.scss";
import {
  TipsContainer,
  TipsItem,
} from "src/modules/Opportunity/components/CreateOpportunityTips/Tips";
import CreateOpportunityForm from "src/modules/Opportunity/components/OpportunityForm/OpportunityForm";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Link, useHistory, useParams } from "react-router-dom";
import { opportunityObj } from "src/modules/Opportunity/components/Helpers/opportunityObj";
import { CastValue } from "src/modules/onboarding/helper";
import { Context } from "src/store";
import {
  getOpportunity,
  editOpportunity,
} from "src/requests/OpportunityRequest";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import { _truncateText } from "src/utils/helper";
import { getInterestById } from "src/requests/InterestRequest";
import { useTranslation } from "react-i18next";

const EditOpportunity = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState(opportunityObj);
  const { state, dispatch } = useContext(Context);
  const [previousDataState, setPreviousDataState] = useState(opportunityObj);

  const { id } = useParams();

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    const _getData = () => {
      getOpportunity(dispatch, id);
    };
    _getData();
  }, []);

  const onChange = (target, value) => {
    setData({ ...data, [target]: CastValue(target, value) });
  };

  const { opportunity } = state.Opportunities;

  useEffect(() => {
    if (opportunity && Object.keys(opportunity).length > 0) {
      setPreviousDataState(opportunity);
    }
  }, [opportunity]);

  useEffect(() => {
    const oppObj = { ...previousDataState };
    if (previousDataState && previousDataState.relatedTopicIds.length > 0) {
      retrieveRelatedTopics(previousDataState.relatedTopicIds).then(
        (topics) => {
          oppObj.relatedTopicIds = topics;
        }
      );
    }
    if (
      previousDataState &&
      previousDataState.category &&
      Object.keys(previousDataState.category).length > 0
    ) {
      oppObj.categoryId = previousDataState.category.id;
    }
    if (
      previousDataState &&
      previousDataState.opportunityType &&
      Object.keys(previousDataState.opportunityType).length > 0
    ) {
      oppObj.opportunityTypeId = previousDataState.opportunityType.id;
    }

    setData(oppObj);
  }, [previousDataState]);

  const retrieveRelatedTopics = async (ids) => {
    const interests = ids.map(async (id) => {
      const res = await getInterestById(dispatch, id);
      const { data } = res.data;
      return { value: data.id, label: data.name };
    });
    const relatedInterest = await Promise.all(interests);
    return Promise.resolve(relatedInterest);
  };

  const onSubmit = (payload) => {
    const {
      id,
      title,
      shortSummary,
      country,
      fullDescription,
      totalPrice,
      eligibilityRequirement,
      relatedTopicIds,
      deadline,
      categoryId,
      opportunityTypeId,
      opportunityLink,
      contactEmail,
      benefits,
      officialWebsite,
      fileName,
    } = payload;

    const obj = {
      id,
      title,
      shortSummary,
      country,
      fullDescription,
      totalPrice,
      eligibilityRequirement,
      relatedTopicIds,
      deadline,
      categoryId,
      opportunityTypeId,
      opportunityLink,
      contactEmail,
      benefits,
      officialWebsite,
      fileName,
    };

    editOpportunity(dispatch, obj, history.goBack());
  };

  return (
    <Layout hideLeft hideRight {...props}>
      <div className="create-opp-breadcrumb">
        <Link to="/home" style={{ fontWeight: "bold" }}>
          {t("Home")} <IoIosArrowRoundForward />
        </Link>
        <Link to="/opportunities" style={{ fontWeight: "bold" }}>
          {t("Opportunities")} <IoIosArrowRoundForward />
        </Link>
        <Link
          to="/opportunities/my-opportunities"
          style={{ fontWeight: "bold" }}
        >
          {t("My Opportunities")} <IoIosArrowRoundForward />
        </Link>
        <Link to={"/opportunity-details/" + opportunity.id}>
          {opportunity &&
            opportunity.title &&
            _truncateText(opportunity.title, 25)}{" "}
          <IoIosArrowRoundForward />
        </Link>
        <span>{t("Edit Opportunity")}</span>
      </div>

      <div
        className="create-opp-banner-holder"
        style={{ backgroundColor: "white", padding: "10px" }}
      >
        {/* <BannerCreateOpportunity /> */}
        <span>
          {t("Edit")} -{" "}
          {opportunity && opportunity.title ? `"${opportunity.title}"` : ""}
        </span>
      </div>
      <div className="create-opp-form-container">
        <div className="create-opp-form-container__left">
          <CreateOpportunityForm
            data={data}
            onChange={onChange}
            onSubmit={onSubmit}
          />
        </div>
        <div className="create-opp-form-container__right">
          <h1>{t("Helpful Tips to Get Your Opportunity Featured")}</h1>
          <TipsContainer>
            <TipsItem
              title={
                <h1>
                  {t("Detailed")}{" "}
                  <span className="red">{t("Descriptions")}</span>
                </h1>
              }
              description={t(
                "Make sure both the summaryand full descriptions are carefully worded and outlined"
              )}
            />
            <TipsItem
              title={
                <h1>
                  {t("Use Clear ")}
                  <span className="red">{t("Feature Banner")}</span>
                </h1>
              }
              description={t(
                "It’s important to use catchy poster image as the cover banner for the opportunity to be featured."
              )}
            />
            <TipsItem
              title={
                <h1>
                  {t("Provide")} <span className="red">{t("Valid Links")}</span>
                </h1>
              }
              description={t(
                "Provide the appropriate links needed for interested applicants to find more information"
              )}
            />
          </TipsContainer>
        </div>
      </div>
    </Layout>
  );
};

export default ifOnboardedThenShow(EditOpportunity);
