import React from "react";
import PollCardButton from "./PollCardButton";
import classes from "./PollCard_v2.module.scss";

const PollCardPreviewOPtions = ({ options, onVote, loadingSubmit }) => {
  return (
    <div className={classes.optionContainer}>
      {loadingSubmit && (
        <span className={classes.loadingVote}>Sending your vote...</span>
      )}
      {options &&
        options.length > 0 &&
        options.map((option, i) => {
          return (
            <PollCardButton
              key={i}
              value={option.content}
              id={option.id}
              onClick={onVote}
              loading={loadingSubmit}
            />
          );
        })}
    </div>
  );
};

export default PollCardPreviewOPtions;
