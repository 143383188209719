import axios from "src/utils/axios";
import "react-toastify/dist/ReactToastify.css";
import { GET_ALL_PROGRAMMES } from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import { ProgrammeUrl } from "src/constants";

export const getAllProgrammes = (dispatch) => {
  dispatch(asyncActions(GET_ALL_PROGRAMMES).loading(true));
  return axios({
    method: "get",
    url: ProgrammeUrl.GET_ALL_PROGRAMMES_URL,
  })
    .then((response) => {
      dispatch(asyncActions(GET_ALL_PROGRAMMES).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_ALL_PROGRAMMES).success(data));
        dispatch(asyncActions(GET_ALL_PROGRAMMES).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_ALL_PROGRAMMES).loading(false));
      dispatch(asyncActions(GET_ALL_PROGRAMMES).failure(true, error.response));
      return error.response;
    });
};
