import React from "react";
import Loader from "react-loader-spinner";
import "./index.scss";

const CustomLoader = ({ fullScreen, size }) => {
  return (
    <div
      className={
        fullScreen === true
          ? "overlay d-flex justify-content-center"
          : "custom-loading-bar"
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5rem",
        }}
      >
        <Loader
          type="Oval"
          color="#FF0000"
          height={size || 50}
          width={size || 50}
        />
      </div>
    </div>
  );
};

export default CustomLoader;
