import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "../Articles/Article.scss";
// import { articles } from "src/modules/Discover/articles";
import { FaRegBookmark } from "react-icons/fa";
import Title from "src/modules/Discover/components/Title/Title";
import { getAllStories } from "src/requests/DiscoverStoryRequest";
import { Context } from "src/store";
import ContentLoader from "react-content-loader";
import moment from "moment";
// import Avatar from "src/components/Avatar/Avatar";
import ArticleNotFound from "src/modules/Discover/components/NotFound/ArticleNotFound";
import { imageValid } from "src/modules/Discover/helper";
import EmptyImage from "src/assets/img/courseImage.jpg";
import Avatars from "src/components/Avatar/Avatar";
import Swal from "sweetalert2";
import classes from "./TefStory.module.css";
import { useCurrentUser } from "src/utils/Services/currentUser";
import {
  createAndDeleteBookmark,
  deleteStory,
  getAllBookmarks,
} from "src/requests/DiscoverRequest";
import { RiDeleteBin5Line } from "react-icons/ri";
import PrimaryButton from "src/modules/Alumni/Components/PrimaryButton";
// import {
//   FacebookIcon,
//   FacebookShareButton,
//   LinkedinIcon,
//   LinkedinShareButton,
//   TwitterIcon,
//   TwitterShareButton,
// } from "react-share";
// import readingTime from "reading-time";

const ReactContentLoader = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div
        style={{ backgroundColor: "#f3f3f3", height: "200px", width: "100%" }}
      ></div>
      <div
        style={{
          backgroundColor: "white",
          padding: "10px",
        }}
        // className="loader-item"
      >
        <ContentLoader
          speed={2}
          // width={324}
          // height={150}
          viewBox="0 0 400 160"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="8" rx="3" ry="3" width="300" height="30" />
          <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
          <rect x="0" y="56" rx="3" ry="3" width="300" height="6" />
          <rect x="0" y="72" rx="3" ry="3" width="300" height="6" />
          <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
          {/* <circle cx="20" cy="20" r="20" /> */}
        </ContentLoader>
      </div>
    </div>
  );
};
const TefStories = ({ tabName, tab, handleSeeAll, filterOptions }) => {
  const [stories, setStories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [loading] = useState(false);
  const [pageDetails, setPageDetails] = useState({
    pageNumber: 1,
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const [bookmarked, setBookmarked] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [sortString, setSortString] = useState("");
  const userId = useCurrentUser().userId;
  const { dispatch } = useContext(Context);

  useEffect(() => {
    _getAllStories(1, false);
    getAllBookmarked();
  }, [refresh]);

  useEffect(() => {
    _getAllStories(1, false, sortString);
  }, [sortString]);

  const _getAllStories = async (pageNumber, loadMore, sortBy) => {
    try {
      setIsLoading(true);
      const stories = await getAllStories(dispatch, pageNumber, sortBy);
      const {
        data: { data, pager },
      } = stories.data;
      setPageDetails(pager);
      if (data) {
        setStories(data);
        setIsLoading(false);
      } else {
        setStories([]);
      }
    } catch ({ response }) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          response && response.data
            ? response.data
            : "Something went wrong while fetching Stories",
        confirmButtonColor: "#da291c",
      }).then(() => setRefresh(!refresh));
    }
  };
  const history = useHistory();
  const RenderContentLoader = () => {
    const arr = [1, 1, 1, 1];
    const contentArr = arr.fill(4);
    const contLoadArr = contentArr.map((c, idx) => (
      <Col md={6} key={idx}>
        <ReactContentLoader />
      </Col>
    ));
    return <Row>{contLoadArr}</Row>;
  };

  const humanDate = (item) => {
    return moment.utc(item.createdOn).local().format("MMMM Do YYYY");
  };

  const handleBookmark = async (storyId, title) => {
    Swal.fire({
      title: "Bookmark?",
      text: "Are you sure you want to bookmark this story?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#da291c",
      cancelButtonColor: "#63666a",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        createAndDeleteBookmark(userId, storyId, title, "TEF-Story").then(
          (res) => {
            if (res.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Bookmarked!",
                text: "Story Bookmarked!",
                confirmButtonColor: "#da291c",
              }).then(() => setRefresh(!refresh));
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Unable to bookmark story at this time, try again!",
                confirmButtonColor: "#da291c",
              }).then(() => setRefresh(!refresh));
            }
          }
        );
      }
    });
  };

  const getAllBookmarked = async () => {
    const bookmarked = await getAllBookmarks(userId);
    const {
      data: { data },
    } = bookmarked;
    setBookmarked(data);
  };
  const getSortBy = (str) => {
    setSortString(str);
  };

  const handleNextPage = () => {
    // implement pagination
    console.log("next");
  };

  const handlePreviousPage = () => {
    // implement pagination
    console.log("previous");
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Delete?",
      text: "Are you sure you want to delete this story?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#da291c",
      cancelButtonColor: "#63666a",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteStory(id).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Story Deleted!",
              confirmButtonColor: "#da291c",
            }).then(() => setRefresh(!refresh));
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Unable to bookmark story at this time, try again!",
              confirmButtonColor: "#da291c",
            }).then(() => setRefresh(!refresh));
          }
        });
      }
    });
  };

  return (
    <>
      {isLoading ? (
        RenderContentLoader()
      ) : stories.length === 0 ? (
        <ArticleNotFound />
      ) : (
        <div className="mt-4">
          <Title
            name="TEF Stories"
            nameLength={stories.length}
            seeAll="See All"
            menuTitle={tabName}
            rightTitle="Submit your TEF Story"
            seeAllAction={() => handleSeeAll("TEF Story")}
            handlePage={() => history.push("/discover/tefstory")}
            showFilterOptions={filterOptions}
            sortBy={getSortBy}
          />
          <div className="mt-3">
            <Row>
              {stories.slice(0, 1).map((item, index) => (
                <Col md={12} key={index}>
                  <div className="post-item">
                    <div className="category-image position-relative">
                      <img
                        src={
                          imageValid(item.bannerUrl)
                            ? item.bannerUrl
                            : EmptyImage
                        }
                        alt="TEF Story"
                        className={classes.StoryBanner}
                        onClick={() =>
                          history.push(`/discover/story/${item.id}`)
                        }
                      />

                      <div className="position-absolute post-item__1">
                        <div
                          className="border-bottom"
                          onClick={() =>
                            history.push(`/discover/story/${item.id}`)
                          }
                        >
                          <h3>Industry</h3>
                          <h1 className="text-white">{item.title}</h1>
                        </div>
                        <div className="py-2 mt-2">
                          <Row>
                            <Col md={7}>
                              <div
                                className="d-flex"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                {item.imageUrl ? (
                                  <img
                                    src={item.imageUrl}
                                    alt="avatar"
                                    className="writer-avatar mr-2"
                                  />
                                ) : (
                                  <Avatars
                                    name={item.createdBy}
                                    dimension={30}
                                    src={item.imageUrl}
                                  />
                                )}

                                <h2 className="mr-1 ml-3">{item.createdBy}</h2>
                                <h4>{humanDate(item)}</h4>
                              </div>
                            </Col>
                            {/* <Col md={3}>
                          </Col> */}
                            <Col md={5}>
                              <div
                                className="d-flex justify-content-end pt-1"
                                style={{ color: "#919ba8", cursor: "pointer" }}
                              >
                                <span
                                  onClick={() => {
                                    handleBookmark(item.id, item.title);
                                  }}
                                  className={
                                    bookmarked.find(
                                      (b) => b.contentId === item.id
                                    )
                                      ? classes.Bookmarked
                                      : ""
                                  }
                                  style={{
                                    marginRight: "0.5rem",
                                  }}
                                >
                                  <FaRegBookmark size="0.8em" />
                                </span>
                                {userId === item.user.id && (
                                  <span onClick={() => handleDelete(item.id)}>
                                    <RiDeleteBin5Line />
                                  </span>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            <Row>
              {stories.slice(1).map((item, index) => (
                <Col md={6} key={index}>
                  <div className="post-list-item">
                    <div
                      className="post-list-item__image"
                      onClick={() => history.push(`/discover/story/${item.id}`)}
                    >
                      <img
                        src={
                          imageValid(item.bannerUrl)
                            ? item.bannerUrl
                            : EmptyImage
                        }
                        alt="TEF Story"
                        className={classes.StoryBanner}
                      />
                    </div>
                    <div className="px-3 py-2">
                      <div
                        className="border-bottom"
                        onClick={() =>
                          history.push(`/discover/story/${item.id}`)
                        }
                      >
                        <h3>{item && item.businessIndustry}</h3>
                        <h1 className="userFirstName">{item && item.title}</h1>
                      </div>
                      <div className="py-2">
                        <Row>
                          <Col md={4}>
                            <div
                              className="d-flex"
                              style={{ alignItems: "center" }}
                            >
                              {/* <img
                                src={item.userAvatar}
                                alt="avatar"
                                className="user-avartar mr-1"
                              /> */}
                              <Avatars name={item.createdBy} dimension={25} />

                              <h2 className="userFirstName">
                                {item && item.createdBy}
                              </h2>
                            </div>
                          </Col>
                          <Col md={5}>
                            <div>
                              <h4 style={{ marginBottom: "0" }}>Published: </h4>
                              <h4>{humanDate(item)}</h4>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div
                              className="d-flex justify-content-between pt-1"
                              style={{ color: "#919ba8", alignItems: "center" }}
                            >
                              <span
                                onClick={() => {
                                  handleBookmark(item.id, item.title);
                                }}
                                className={
                                  bookmarked.find(
                                    (b) => b.contentId === item.id
                                  )
                                    ? classes.Bookmarked
                                    : ""
                                }
                              >
                                <FaRegBookmark size="0.8em" />
                              </span>
                              {userId === item.user.id && (
                                <span onClick={() => handleDelete(item.id)}>
                                  <RiDeleteBin5Line />
                                </span>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            {/* {pageDetails.hasNextPage && (
              <Row>
                <Button
                  disabled={loading}
                  onClick={() => handleSeeAll("TEF Story")}
                  className="loadmore-btn"
                >
                  Show more
                </Button>
              </Row>
            )} */}
            <Row>
              {pageDetails.hasNextPage && (
                <PrimaryButton
                  buttonText="Next"
                  buttonAction={() => handleNextPage()}
                />
              )}
              {pageDetails.hasPreviousPage && (
                <PrimaryButton
                  buttonText="Previous"
                  buttonAction={() => handlePreviousPage()}
                />
              )}
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default TefStories;
