import "./progressReport.scss";
import React from "react";
import CModal from "src/modules/onboarding/components/CModal";
import icon from "src/assets/img/group-6-copy.png";
import { useTranslation } from "react-i18next";
const ProgressReport = ({
  showReport,
  onReportClose,
  percentageComplete,
  onSubmitClick,
}) => {
  const { t } = useTranslation();
  if (!showReport) {
    return null;
  }
  return (
    <CModal isOpen={showReport} closeModal={onReportClose}>
      <div className="report-c">
        <div className="i-div">
          <img src={icon} className="img-fluid" />
        </div>
        <div className="report-body-c">
          <h1 className="greeting">{t("Great-work")}!😃</h1>
          <p>{t(`Your application is ${percentageComplete}% complete`)}</p>
        </div>
        <div className="report-continue-btn">
          <div className="submit-box">
            <div
              className="submit-btn"
              onClick={onSubmitClick}
              data-testid="progress-next"
            >
              {t("Continue")}
            </div>
          </div>
        </div>
      </div>
    </CModal>
  );
};
ProgressReport.defaultProps = {
  showReport: false,
  onReportClose: () => {},
  percentageComplete: 40,
};
export default ProgressReport;
