import React, { useState } from "react";
import { ifNotOnboardedThenShow } from "src/utils/Services/isOnboarded";
import OnboardingForm from "./onboardingForm";
import styles from "./StepTwo_v2.module.scss";
import tef_logo from "src/assets/img/logo/logo_new.webp";
import { Spinner } from "reactstrap";

const texts = {
  onboarding: "Onboarding",
  onboarding_paragraph:
    "  Complete your account setup in just few steps and start enjoying fantastic experience",
};

const StepTwoOnboarding_v2 = () => {
  const [loading, setLoading] = useState(true);
  return (
    <div className={styles.container}>
      {loading && (
        <div className={styles.loader_container}>
          <Spinner size="30" color="white" style={{ height: 70, width: 70 }} />
        </div>
      )}

      <div className={styles.side_bar}>
        <img src={tef_logo} alt="tef_logo" className={styles.tef_logo} />
        <h4>{texts.onboarding}</h4>
        <p>{texts.onboarding_paragraph}</p>
      </div>
      <div className={styles.form_section}>
        <img src={tef_logo} alt="tef_logo" className={styles.tef_logo_mobile} />
        <div className={styles.headings_mobile}>
          <div className={styles.headings_mobile_onboarding_text}>
            <h4>{texts.onboarding}</h4>
            <p>{texts.onboarding_paragraph}</p>
          </div>
          <h4 className={styles.headings_mobile_step}>
            Step <span className={styles._step_1}>2</span>
            <span className={styles._step_2}> of 2</span>
          </h4>
        </div>
        <OnboardingForm setLoading={setLoading} />
      </div>
      <div className={styles.right_bar}>
        <h4 className={styles._step}>
          Step <span className={styles._step_1}>2</span>
          <span className={styles._step_2}> of 2</span>
        </h4>
      </div>
    </div>
  );
};

export default ifNotOnboardedThenShow(StepTwoOnboarding_v2);
