import axios from "src/utils/axios";
import "react-toastify/dist/ReactToastify.css";
import {
  GET_ALL_PROGRAMME_EVENTS,
  GET_PROGRAMME_EVENT_CONTENTS,
  GET_PROGRAMME_EVENT_TYPES,
} from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import { ProgrammeUrl } from "src/constants";

export const getAllProgrammeEvents = (dispatch) => {
  dispatch(asyncActions(GET_ALL_PROGRAMME_EVENTS).loading(true));
  return axios({
    method: "get",
    url: ProgrammeUrl.GET_ALL_PROGRAMME_EVENTS,
  })
    .then((response) => {
      dispatch(asyncActions(GET_ALL_PROGRAMME_EVENTS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_ALL_PROGRAMME_EVENTS).success(data));
        dispatch(asyncActions(GET_ALL_PROGRAMME_EVENTS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_ALL_PROGRAMME_EVENTS).loading(false));
      dispatch(
        asyncActions(GET_ALL_PROGRAMME_EVENTS).failure(true, error.response)
      );
      return error.response;
    });
};

export const getProgrammeEventTypes = (dispatch) => {
  dispatch(asyncActions(GET_PROGRAMME_EVENT_TYPES).loading(true));
  return axios({
    method: "get",
    url: `${ProgrammeUrl.GET_PROGRAMME_EVENT_TYPES}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_PROGRAMME_EVENT_TYPES).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_PROGRAMME_EVENT_TYPES).success(data));
        dispatch(asyncActions(GET_PROGRAMME_EVENT_TYPES).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_PROGRAMME_EVENT_TYPES).loading(false));
      dispatch(
        asyncActions(GET_PROGRAMME_EVENT_TYPES).failure(true, error.response)
      );
      return error.response;
    });
};

export const getProgrammeEventsContents = (dispatch, payload) => {
  const url = payload.type
    ? `${ProgrammeUrl.GET_PROGRAMME_EVENT_CONTENTS_URL}/${payload.id}?lang=${payload.lang}&componentType=${payload.type}`
    : `${ProgrammeUrl.GET_PROGRAMME_EVENT_CONTENTS_URL}/${payload.id}?lang=${payload.lang}`;

  dispatch(asyncActions(GET_PROGRAMME_EVENT_CONTENTS).loading(true));
  return axios({
    method: "get",
    url: url,
  })
    .then((response) => {
      dispatch(asyncActions(GET_PROGRAMME_EVENT_CONTENTS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_PROGRAMME_EVENT_CONTENTS).success(data));
        dispatch(asyncActions(GET_PROGRAMME_EVENT_CONTENTS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_PROGRAMME_EVENT_CONTENTS).loading(false));
      dispatch(
        asyncActions(GET_PROGRAMME_EVENT_CONTENTS).failure(true, error.response)
      );
      return error.response;
    });
};
