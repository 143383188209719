import React, { useContext, useEffect, useState } from "react";
import { getUserApplications } from "src/requests/ApplicationRequest";
import PreviewApplication from "./PreviewApplication";
import queryString from "query-string";
import CLoader from "src/modules/Application/components/CLoader";
import { Context } from "src/store";
import { useLocation } from "react-router-dom";
import { useCurrentUser } from "src/utils/Services/currentUser";
import classes from "./PreviewApplication.module.scss";

const PreviewAppIndex = (props) => {
  const location = useLocation();
  const query = location.search;
  const [loadingApp, setLoadingApp] = useState(false);
  const { applicationId, lang, endDate } = queryString.parse(query);
  const { userId } = useCurrentUser();

  const { state, dispatch } = useContext(Context);
  const togglePreview = () => {
    return props.history.replace(
      `/application/tests/personality?applicationId=${applicationId}&endDate=${endDate}&lang=${
        lang || "en"
      }`,
      {}
    );
  };

  useEffect(() => {
    if (query) {
      const parsedQuery = queryString.parse(query);
      if (Object.keys(parsedQuery).length === 0 || !parsedQuery.applicationId) {
        props.history.replace("/programme/dashboard", {});
      }
    } else {
      props.history.replace("/programme/dashboard", {});
      props.history.go(1);
    }
    // setLoader(false);
  }, [query]);

  useEffect(() => {
    setLoadingApp(true);
    getUserApplications(dispatch, userId).then(() => {
      setLoadingApp(false);
    });
  }, []);

  const { userApplications } = state.Application;
  const applicant =
    userApplications &&
    userApplications.filter(
      (applicant) => applicant.applicationId === applicationId
    );

  // useEffect(() => {
  //   if (applicant && applicant.length > 0) {
  //     const { progress } = applicant[0];
  //     if (progress === 100) {
  //       setShowModal(true);
  //     }
  //     setLoader(false);
  //   }
  // }, [applicant]);

  return (
    <div className={`${classes.prevMain} notransalate`}>
      {!loadingApp && applicant.length > 0 && (
        <PreviewApplication
          application={applicant[0]}
          togglePreview={togglePreview}
        />
      )}

      <CLoader loading={loadingApp && applicant.length > 0} />
    </div>
  );
};
export default PreviewAppIndex;
