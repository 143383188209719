/* eslint-disable array-callback-return */
import React, { useState, useContext, useEffect, useCallback } from "react";
import { Context } from "src/store";
import { FaPlus, FaSearch } from "react-icons/fa";
import "./ThreadNav.scss";
import CreateThreadIndex from "src/modules/Community/components/Thread/CreateThread";
import Threads from "src/modules/Community/components/Thread/Threads/Threads";
import { getAllTopics } from "src/requests/TopicRequest";
import ResultPagination from "src/modules/Community/components/ResultPagination/ResultPagination";
// import SearchBar from "src/components/SearchBar/SearchBar";

const ThreadNav = ({ categories }) => {
  const categoriesOptions = [];
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [categoryId, setCategoryId] = useState(null);
  const { state, dispatch } = useContext(Context);
  const [searchData, setSearchData] = useState("");
  const [paging, setPaging] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    pageNumber: 1,
    pageCount: 1,
  });

  const toggle = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const onCategoryChange = useCallback((value) => {
    setCategoryId(value);
  }, []);

  if (categories.length > 0) {
    categories.map((category) => {
      const { id, name } = category;
      const option = { value: id, label: name };
      categoriesOptions.push(option);
    });
  }

  useEffect(() => {
    _getTopics(paging.pageNumber);
  }, [state.Topics.topic, categoryId, searchData]);

  const options = categoriesOptions.map((category, index) => (
    <option key={index} value={category.value}>
      {category.label}
    </option>
  ));

  const _getTopics = (pageNumber) => {
    setIsLoading(true);
    const payload = {
      categoryId,
      pageNumber,
      maxItem: 10,
      search: searchData,
    };
    getAllTopics(dispatch, payload).then((res) => {
      if (res.status === 200) {
        const { data } = res.data;
        setIsLoading(false);
        setData(data.data);
        setPaging({ ...paging, ...data.pager });
      }
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    _getTopics(1);
  };

  // const next = () => {
  //   _getTopics(Number(paging.pageNumber + 1));
  // };

  // const prev = () => {
  //   _getTopics(Number(paging.pageNumber - 1));
  // };

  const onPaginate = (value) => {
    const page = Number(value.selected + 1);
    _getTopics(page);
  };

  return (
    <div>
      <div className="thread-nav">
        <div className="thread-nav__left">
          <select
            id="category"
            className="thread-nav__left--select"
            required
            name="categoryId"
            onChange={(e) => onCategoryChange(e.target.value)}
          >
            <option value="">All Categories</option>
            {options}
          </select>
          <button className="thread-btn thread-nav__left--btn">latest</button>
          <button className="thread-btn thread-nav__left--btn">top</button>
          {/* <SearchBar */}
          {/* // placeholder="Search topics" // setSearchData={setSearchData} */}
          {/* // /> */}
          <form onSubmit={handleSearch} className="search-container">
            <input
              type="text"
              placeholder="Search Topics"
              name="search"
              className="thread-nav__left--search"
              onChange={(e) => setSearchData(e.target.value)}
              value={searchData}
            />
            <button
              type="submit"
              disabled={searchData === ""}
              className="thread-nav__left--icons"
            >
              <FaSearch />
            </button>
          </form>
        </div>
        <button className="thread-btn thread-nav__new-btn" onClick={toggle}>
          <FaPlus />
          &nbsp; new topic
        </button>
        <CreateThreadIndex
          categories={categories}
          show={showModal}
          toggle={toggle}
        />
      </div>
      <Threads
        data={data}
        loading={isLoading}
        onCategoryChange={onCategoryChange}
        // onNextClick={next}
        // onPreviousClick={prev}
        // pageDetails={paging}
      />
      <div className={"pgin_c"}>
        {(paging.hasNextPage || paging.hasPreviousPage) && (
          <ResultPagination
            pageCount={paging.pageCount}
            pageRange={5}
            current={paging.pageNumber}
            onSelect={onPaginate}
          />
        )}
      </div>
    </div>
  );
};

export default ThreadNav;
