import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import defaultAchievementImage from "src/assets/img/defaultAchievementImage.png";
import { _truncateText } from "src/modules/mentorship/components/helper";
// import shortid from "shortid";

const DescriptionBox = ({ maxLength, description }) => {
  const [descriptionMaxLength, setDescriptionMaxLength] = useState(maxLength);

  const toggleDescriptionMaxLength = () => {
    if (descriptionMaxLength === null) {
      setDescriptionMaxLength(maxLength);
    } else {
      setDescriptionMaxLength(null);
    }
  };

  return (
    <>
      <p>
        {_truncateText(description, descriptionMaxLength)}{" "}
        {descriptionMaxLength !== null &&
          description.length > descriptionMaxLength && (
            <span
              onClick={toggleDescriptionMaxLength}
              style={{ fontWeight: "bold", cursor: "pointer" }}
            >
              Show More
            </span>
          )}
        {descriptionMaxLength == null && (
          <span
            style={{ fontWeight: "bold", cursor: "pointer" }}
            onClick={toggleDescriptionMaxLength}
          >
            Show Less
          </span>
        )}
      </p>
    </>
  );
};
const Achievements = ({ projects }) => {
  const displayAchievements = () =>
    projects &&
    projects.map((project, i) => (
      <Col className="about-item" md={6} key={i}>
        <Row>
          <Col className="about-item__logo d-none d-md-block" md={2}>
            <img src={defaultAchievementImage} className="img-fluid" />
          </Col>
          <Col
            className="about-item__details"
            md={10}
            style={{ marginLeft: "-30px" }}
          >
            <h3>{project.title}</h3>

            {/* <p>{project.description}</p> */}
            <DescriptionBox description={project.description} maxLength={100} />
            <p
              onClick={() => window.open(project.url)}
              style={{ cursor: "pointer", color: "var(--tef-blue)" }}
            >
              {project.url}
            </p>
          </Col>
        </Row>
        <hr />
      </Col>
    ));
  return (
    <Col>
      <Row>{displayAchievements()}</Row>
    </Col>
  );
};

Achievements.propTypes = {};

export default Achievements;
