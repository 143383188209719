import React from "react";
import Sidebar from "react-sidebar";
import ChatHistory from "src/modules/Alumni/Chat/Components/ChatHistory/index";

// import GroupChatForm from "./GroupChatForm/index";

const MobileChatBox = ({
  isOpen,
  children,
  setIsOpen,
  secondaryButtonAction,
  currentChatHistory,
  dropdownOpenMobile,
  toggleGroupMemberMobile,
  showEmojis,
  handleSubmit,
  removeChat,
  currentChat,
  toggleAddUserToGroupModalOpen,
  classes,
  toggle,
  styles,
  emojiRef,
  addEmoji,
  handleChange,
  text,
  setShowEmojis,
  toggleAttachmantModal,
  toggleChatBox,
  toggleMembersModal,
  isGroupAdmin,
  toggleMessageViewer,
  leaveGroupChat,
  restrictPosting,
  showChatTextarea,
  renderChatTextareaFunc,
}) => {
  const style = {
    root: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: "hidden",
    },
    sidebar: {
      zIndex: 100,
      position: "absolute",

      top: 0,
      bottom: 0,
      transition: "transform .3s ease-out",
      WebkitTransition: "-webkit-transform .3s ease-out",
      willChange: "transform",
      overflowY: "auto",
      overflowX: "hidden",
      background: "#fff",
      // padding: 30,
      width: "100%",
    },
    // content: {
    //   position: "absolute",
    //   top: 0,
    //   left: 0,
    //   right: 0,
    //   bottom: 0,
    //   overflowY: "auto",
    //   WebkitOverflowScrolling: "touch",
    //   transition: "left .3s ease-out, right .3s ease-out",
    // },
    overlay: {
      zIndex: 1,
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0,
      visibility: "hidden",
      transition: "opacity .3s ease-out, visibility .3s ease-out",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    dragHandle: {
      zIndex: 1,
      position: "fixed",
      top: 0,
      bottom: 0,
    },
  };

  return (
    <Sidebar
      sidebar={
        <ChatHistory
          currentChatHistory={currentChatHistory}
          dropdownOpen={dropdownOpenMobile}
          showEmojis={showEmojis}
          handleSubmit={handleSubmit}
          toggleMembersModal={toggleMembersModal}
          removeChat={removeChat}
          currentChat={currentChat}
          toggleAddUserToGroupModalOpen={toggleAddUserToGroupModalOpen}
          classes={classes}
          toggle={toggleGroupMemberMobile}
          styles={styles}
          emojiRef={emojiRef}
          addEmoji={addEmoji}
          handleChange={handleChange}
          text={text}
          setShowEmojis={setShowEmojis}
          toggleAttachmantModal={toggleAttachmantModal}
          toggleChatBox={toggleChatBox}
          toggleGroupMemberMobile={toggleGroupMemberMobile}
          isGroupAdmin={isGroupAdmin}
          toggleMessageViewer={toggleMessageViewer}
          leaveGroupChat={leaveGroupChat}
          restrictPosting={restrictPosting}
          showChatTextarea={showChatTextarea}
          renderChatTextareaFunc={renderChatTextareaFunc}
        />
      }
      open={isOpen}
      onSetOpen={setIsOpen}
      styles={style}
      pullRight={true}
    >
      {children}
    </Sidebar>
  );
};
export default MobileChatBox;
