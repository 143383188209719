import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
  CarouselControl,
} from "reactstrap";
import CaretLeft from "src/assets/img/caretleft.png";
import CaretRight from "src/assets/img/caretright.png";
// import DefaultImage from "src/assets/img/Opportunity.jpeg";

const FeaturedEvent = ({
  list,
  SliderContent,
  showControl,
  carouselHeight,
  mobileRadius = 20,
  desktopRadius = 20,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === list.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? list.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides =
    list.length &&
    list.map((t, idx) => {
      return (
        <CarouselItem
          className="custom-carol"
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={t.bannerUrl}
        >
          <img
            src={t.bannerUrl || t.fileName}
            alt={t.altText}
            width={t.width}
            height={t.height}
            className="img-slide d-block w-100"
          />

          {SliderContent(t)}

          <CarouselCaption captionText={t.caption} captionHeader={t.caption} />
        </CarouselItem>
      );
    });

  return (
    <div>
      <style>
        {`
        .carousel-item, .carousel-item.active {
          align-items:center;
          height:100%
      }
      .carousel-item::before {
        content: "";
        width: 100%;
        height: 100%;
        background-color:rgba(0, 0, 0, 0.4);
        position: absolute;
    }
          .custom-indicator {
            margin-right: 5%;
            right: 0;
            left:auto;
          }
          .custom-control span {
            background-color:rgba(255, 255, 255, 0.6);
            width: 30px;
            height:30px;
            border-radius: 50%;
            top: 25%;
            position: absolute;
          }
          .carousel-inner{
            height: ${carouselHeight}px;
           
          }
          .custom-indicator li {
                width: 10px;
                height: 10px;
                border-radius:100%;
                box-sizing: border-box;
              }
              .carousel-indicators .active  {
                background-color:#F85959;
              }
              .carousel-inner {
               
               border-radius:${desktopRadius}px;
            
              }
              .carousel-control-prev-icon {
                background-image: url(${CaretLeft})
              }
              .carousel-control-next-icon {
                background-image: url(${CaretRight})
              }
              @media screen and (max-width: 790px) {
                .carousel-control-next {
                display: none;
                }
                .carousel-item, .carousel-item.active {
                  background-image: linear-gradient(
                    to right bottom,
                    rgba(0,0,0,0.3),
                    rgba(0,0,0,0.4)
                )
                }
                .carousel {
                  border-radius: 60px;
                }
                .custom-indicator {
                  margin-right: 5%;
                  right: 0;
                  left: 0%;
                  top: 90%;
                }
              
                .carousel-inner {
                  height: 450px;
                 border-radius:${mobileRadius}px;
                  background-image: linear-gradient(
                    to right bottom,
                    rgba(0,0,0,0.3),
                    rgba(0,0,0,0.4)
                )
                }
              }
              `}
      </style>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators
          items={list}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
          className="custom-indicator"
        />
        {slides}
        {showControl && (
          <>
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
              className="custom-control"
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
              className="custom-control"
            />
          </>
        )}
      </Carousel>
    </div>
  );
};

export default FeaturedEvent;
