import axios from "src/utils/axios";
import {
  REGISTER_USER,
  FORGET_PASSWORD,
  VERIFY_ACCOUNT,
  SEND_VERIFY_LINK,
  CHANGE_PASSWORD,
  RESET_PASSWORD,
  GET_USER,
  SEARCH_MEMBER,
} from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import { AuthURL } from "src/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { env } from "src/constants/env";

export const registerUser = (dispatch, payload) => {
  dispatch(asyncActions(REGISTER_USER).loading(true));
  return axios({
    method: "post",
    url: AuthURL.REGISTER_USER_URL,
    data: payload,
    auth: {
      username: env.REACT_APP_CLIENT_ID,
      password: env.REACT_APP_CLIENT_SECRET,
    },
  })
    .then((response) => {
      dispatch(asyncActions(REGISTER_USER).loading(false));
      if (response.status === 201) {
        dispatch(asyncActions(REGISTER_USER).success(response.data));
        dispatch(asyncActions(REGISTER_USER).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(REGISTER_USER).loading(false));
      dispatch(asyncActions(REGISTER_USER).failure(true, error.response));
      return error.response;
    });
};

export const forgetPassword = (dispatch, payload) => {
  dispatch(asyncActions(FORGET_PASSWORD).loading(true));
  return axios({
    method: "post",
    url: AuthURL.FORGET_PASSWORD_URL,
    data: payload,
    auth: {
      username: env.REACT_APP_CLIENT_ID,
      password: env.REACT_APP_CLIENT_SECRET,
    },
  })
    .then((response) => {
      dispatch(asyncActions(FORGET_PASSWORD).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(FORGET_PASSWORD).success(response.data));
        dispatch(asyncActions(FORGET_PASSWORD).loading(false));
      }

      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(FORGET_PASSWORD).loading(false));
      dispatch(asyncActions(FORGET_PASSWORD).failure(true, error.response));
      return error.response;
    });
};

export const verifyAccount = (dispatch, payload) => {
  dispatch(asyncActions(VERIFY_ACCOUNT).loading(true));
  return axios({
    method: "get",
    url: `${AuthURL.VERIFY_ACCOUNT_URL}?userId=${payload.userId}&token=${payload.token}`,
    auth: {
      username: env.REACT_APP_CLIENT_ID,
      password: env.REACT_APP_CLIENT_SECRET,
    },
  })
    .then((response) => {
      dispatch(asyncActions(VERIFY_ACCOUNT).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(VERIFY_ACCOUNT).success(response.data));
        dispatch(asyncActions(VERIFY_ACCOUNT).loading(false));
      }

      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(VERIFY_ACCOUNT).loading(false));
      dispatch(asyncActions(VERIFY_ACCOUNT).failure(true, error.response));
      return error.response;
    });
};

export const reSendLink = (dispatch, payload) => {
  dispatch(asyncActions(SEND_VERIFY_LINK).loading(true));
  return axios({
    method: "get",
    url: `${AuthURL.SEND_VERIFY_LINK_URL}?email=${payload.email}`,
    auth: {
      username: env.REACT_APP_CLIENT_ID,
      password: env.REACT_APP_CLIENT_SECRET,
    },
  })
    .then((response) => {
      dispatch(asyncActions(SEND_VERIFY_LINK).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(SEND_VERIFY_LINK).success(response.data));
        dispatch(asyncActions(SEND_VERIFY_LINK).loading(false));
        toast("Confirmation Email Sent");
      }

      return response;
    })
    .catch((error) => {
      let res = "An error occurred, Please try again";
      if (error.response.status === 400) {
        const {
          data: { message },
        } = error.response;
        res = message;
      }
      dispatch(asyncActions(SEND_VERIFY_LINK).loading(false));
      dispatch(asyncActions(SEND_VERIFY_LINK).failure(true, error.response));
      toast(res);
      return error.response;
    });
};

export const changePassword = (dispatch, payload) => {
  dispatch(asyncActions(CHANGE_PASSWORD).loading(true));
  return axios({
    method: "patch",
    url: AuthURL.CHANGE_PASSWORD_URL,
    data: payload,
    auth: {
      username: env.REACT_APP_CLIENT_ID,
      password: env.REACT_APP_CLIENT_SECRET,
    },
  })
    .then((response) => {
      dispatch(asyncActions(CHANGE_PASSWORD).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(CHANGE_PASSWORD).success(response.data));
        dispatch(asyncActions(CHANGE_PASSWORD).loading(false));
      }

      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(CHANGE_PASSWORD).loading(false));
      dispatch(asyncActions(CHANGE_PASSWORD).failure(true, error.response));
      return error.response;
    });
};

export const resetPassword = (dispatch, payload) => {
  dispatch(asyncActions(RESET_PASSWORD).loading(true));
  return axios({
    method: "patch",
    url: AuthURL.RESET_PASSWORD_URL,
    data: payload,
    auth: {
      username: env.REACT_APP_CLIENT_ID,
      password: env.REACT_APP_CLIENT_SECRET,
    },
  })
    .then((response) => {
      dispatch(asyncActions(RESET_PASSWORD).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(RESET_PASSWORD).success(response.data));
        dispatch(asyncActions(RESET_PASSWORD).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(RESET_PASSWORD).loading(false));
      dispatch(asyncActions(RESET_PASSWORD).failure(true, error.response));
      return error.response;
    });
};

export const getUser = (dispatch, payload) => {
  dispatch(asyncActions(GET_USER).loading(true));
  return axios({
    method: "get",
    url: `${AuthURL.GET_USER_URL}/${payload}`,
    auth: {
      username: env.REACT_APP_CLIENT_ID,
      password: env.REACT_APP_CLIENT_SECRET,
    },
  })
    .then((response) => {
      dispatch(asyncActions(GET_USER).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(GET_USER).success(response.data.data));
        dispatch(asyncActions(GET_USER).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_USER).loading(false));
      dispatch(asyncActions(GET_USER).failure(true, error.response));
      return error.response;
    });
};

export const searchMember = (dispatch, payload) => {
  dispatch(asyncActions(SEARCH_MEMBER).loading(true));
  return axios({
    method: "get",
    url: `${AuthURL.SEARCH_USER_URL}/${payload}`,
  })
    .then((response) => {
      dispatch(asyncActions(SEARCH_MEMBER).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(SEARCH_MEMBER).success(response.data.data));
        dispatch(asyncActions(SEARCH_MEMBER).loading(false));
      }
      return response.data.data;
    })
    .catch((error) => {
      dispatch(asyncActions(SEARCH_MEMBER).loading(false));
      dispatch(asyncActions(SEARCH_MEMBER).failure(true, error.response));
      return error.response.data.data;
    });
};
