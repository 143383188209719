import React from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import Avatars from "src/components/Avatar/Avatar";
import styles from "./UserSectionHeader.module.scss";
import { _truncateText } from "src/utils/helper";
const UserSectionHeader = ({ currentUser }) => {
  return (
    // <div className="notranslate">
    <div
      outline="true"
      color="warning"
      className={`${styles.container} notranslate`}
    >
      <Avatars
        name={`${currentUser.firstName} ${currentUser.lastName}`}
        src={currentUser.imageUrl}
        dimension={32}
      />
      <h6 className={styles.container__name}>
        {_truncateText(`${currentUser.firstName} ${currentUser.lastName}`, 30)}
      </h6>
      <IoMdArrowDropdown size={25} className={styles.container__ddp} />
    </div>
    // </div>
  );
};

export default UserSectionHeader;
