import React, { useContext, useEffect, useState } from "react";
import Layout from "src/components/Layout/Layout";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import HubBanner from "src/modules/Alumni/AlumniHub/component/HubBanner/index";
import { Row, Col, Container } from "reactstrap";
import classes from "./Gallery.module.scss";
import GalleryImage from "src/modules/Alumni/AlumniHub/component/GalleryImage/index";
import DefaultImage from "src/assets/img/bg/background_640-1.webp";
import { Context } from "src/store";
import { useParams } from "react-router-dom";
import { getSingleAlumniHub } from "src/requests/AlumniHubRequest";
import HUbGallerySkeletalLoader from "src/modules/Alumni/AlumniHub/component/GalleryImage/SkeletalLoader";
import { getAlumniHubGalleryById } from "src/modules/Alumni/AlumniHub/containers/helper/getAlumniHubGalleryById";
import noPhotos from "src/modules/Alumni/AlumniHub/assets/no-gallery.svg";

const HubGallery = () => {
  const [hubDetails, setHubDetails] = useState({});
  const { hubId } = useParams();
  const { dispatch } = useContext(Context);
  const [isLoadingHubPhotos, setIsLoadingHubPhotos] = useState(true);
  const [hubPhotos, setHubPhotos] = useState(true);

  const _getAllumniGalleryById = getAlumniHubGalleryById(
    dispatch,
    setHubPhotos,
    setIsLoadingHubPhotos
  );

  useEffect(() => {
    getSingleAlumniHub(dispatch, hubId)
      .then((response) => {
        const hubDetails = response.data.data;
        setHubDetails(hubDetails);
      })
      .catch((getSingleAlumniError) => console.log(getSingleAlumniError));
    _getAllumniGalleryById(hubId);
  }, []);

  return (
    <Layout SideContent={<SidebarRight />}>
      <Row>
        <Col>
          <HubBanner
            hubBanner={{
              bannerUrl: hubDetails.bannerUrl,
              state: hubDetails.state,
            }}
          />
        </Col>
      </Row>
      <Container>
        <Row className="mb-4">
          <Col>
            <div className={classes["gallery-box__wrapper"]}>
              <div className={classes["gallery-box__title"]}>
                <h6 className="fs-16 fw-600 mb-3 border-bottom pb-2">
                  {hubDetails.state} Alumni Hub - Photo Gallery
                </h6>
              </div>
              <div className={classes["gallery-grid"]}>
                {isLoadingHubPhotos
                  ? [1, 2, 3, 4, 5, 6, 7, 8].map((event) => (
                      <HUbGallerySkeletalLoader key={event} />
                    ))
                  : hubPhotos.length > 0 &&
                    hubPhotos.map((photo) => (
                      <GalleryImage src={DefaultImage} key={photo.id} />
                    ))}
              </div>
              {!hubPhotos.length && hubPhotos.length === 0 && (
                <div className="d-flex align-items-center flex-column justify-content-center py-5">
                  <img src={noPhotos} width="70" alt="no users image" />
                  <h5 className="mt-2">No photos in this hub at the moment</h5>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default HubGallery;
