import React, { useState } from "react";
import styles from "./Layout.module.scss";
import Header from "./Header/Header";
import Content from "./Content/Content";
import SideDrawer from "./SideDawer/SideDrawer";

const Layout = ({
  hideRight,
  withoutRIghtSideBar,
  SideContent,
  heading,
  children,
}) => {
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
  return (
    <div className={styles.Layout}>
      <Header onOpen={toggle} />
      <SideDrawer show={show} onClose={toggle} />
      <Content
        hideRightSideBar={hideRight}
        rightSideContent={SideContent}
        title={heading}
        withoutRIghtSideBar={withoutRIghtSideBar}
      >
        {children}
      </Content>
    </div>
  );
};

export default Layout;
