/* eslint-disable no-unused-vars */
import React, { useState, useContext, Fragment } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { flagATopic } from "src/requests/CommunityRequest";
import { Context } from "src/store";
import Loader from "src/components/Loader/Loader";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const FlagPost = ({ show, toggle, className, title, details }) => {
  const { dispatch } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState("");
  const { user } = get_user_info_from_token();

  const handleFlag = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      reportedByUserId: user.id,
      contentType: details.contentType,
      contentId: details.contentId,
      description: reason,
    };
    await flagATopic(dispatch, payload);
    toggle();
    setIsLoading(false);
  };

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <form onSubmit={(e) => handleFlag(e)} className="program-form">
            <div className="feedback-input-div">
              <label>
                Reason <span className="required-field">*</span>
              </label>
              <textarea
                value={reason}
                type="text"
                name="reason"
                onChange={(e) => setReason(e.target.value)}
                required
              />
            </div>

            <div className="feedback-input-div">
              <button
                disabled={isLoading}
                className="submit-programme-btn border-0"
              >
                {isLoading ? (
                  <Loader color="light" />
                ) : (
                  <Fragment>
                    <span className="text-white">Submit</span>
                  </Fragment>
                )}
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default FlagPost;
