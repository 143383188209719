import React from "react";

const AlumniContent = (t) => {
  return (
    <div className="alumni__slide--text">
      <div className="alumni__title">
        <p className="alumni__title--para">{t.title}</p>
      </div>

      <div className="alumni__discription">
        <p className="alumni__discription--para">{t.description}</p>
      </div>
    </div>
  );
};

export default AlumniContent;
