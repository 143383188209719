import React from "react";
import Skeleton from "react-loading-skeleton";

const HubUserSkeletalLoader = () => {
  return (
    <div>
      <div>
        <Skeleton height={150} />
      </div>
      <div className="text-center">
        <Skeleton width={100} />
      </div>
      <div className="text-center">
        <Skeleton width={200} />
      </div>
    </div>
  );
};

export default HubUserSkeletalLoader;
