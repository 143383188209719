import React from "react";

const ExecutiveSummaryTemplate = ({ executiveSummary }) => {
  return (
    <div>
      <h4 className="text-center font-weight-bold mb-5">Executive Summary</h4>
      <p>
        {" "}
        {executiveSummary.businessName === ""
          ? "[BUSINESS NAME]"
          : executiveSummary.businessName}{" "}
        is a{" "}
        {executiveSummary.stageOfBusiness === ""
          ? "[STAGE OF BUSINESS]"
          : executiveSummary.stageOfBusiness}{" "}
        stage business located at{" "}
        {executiveSummary.addressOfBusiness === ""
          ? "[ADDRESS OF BUSINESS]"
          : executiveSummary.addressOfBusiness}
        .
      </p>
      <p>
        {executiveSummary.valueProposition === ""
          ? "[VALUE PROPOSITION]"
          : executiveSummary.valueProposition}
        .
      </p>
      <p>
        Our target market is{" "}
        {executiveSummary.targetMarket === ""
          ? "[TARGET MARKET]"
          : executiveSummary.targetMarket}
        .
      </p>
      <p>
        The approach to promote{" "}
        {executiveSummary.businessName === ""
          ? "[BUSINESS NAME]"
          : executiveSummary.businessName}{" "}
        will be through{" "}
        {executiveSummary.marketApproach === ""
          ? "[MARKETING APPROACH]"
          : executiveSummary.marketApproach}
        .{" "}
        {executiveSummary.businessName === ""
          ? "[BUSINESS NAME]"
          : executiveSummary.businessName}{" "}
        will{" "}
        {executiveSummary.objective === ""
          ? "[OBJECTIVE]"
          : executiveSummary.objective}
        .{" "}
        {executiveSummary.diffrentiation === ""
          ? "[DIFFERENTIATION]"
          : executiveSummary.diffrentiation}
        .
      </p>
      <p>
        The proposed business would operate under the{" "}
        {executiveSummary.typeOfOwnership === ""
          ? "[TYPE OF BUSINESS OWNERSHIP]"
          : executiveSummary.typeOfOwnership}{" "}
        business ownership structure and will be managed by{" "}
        {executiveSummary.businessOwner.map((owner, index) => (
          <span key={index}>{owner + ", "}</span>
        ))}{" "}
        .
      </p>
      <p>
        Our vision is{" "}
        {executiveSummary.visionStatement === ""
          ? "[VISION STATEMENT]"
          : executiveSummary.visionStatement}
        . And our mission is{" "}
        {executiveSummary.missionStatement === ""
          ? "[MISSION STATEMENT]"
          : executiveSummary.missionStatement}
        .
      </p>
      <p>
        We are seeking the total sum of{" "}
        {executiveSummary.fundingRequirement === ""
          ? "[FUNDING REQUIREMENT]"
          : executiveSummary.fundingRequirement}
        . This will be used for{" "}
        {executiveSummary.useOfFundingRequirement === ""
          ? "[USE OF FUNDING REQUIREMENT]"
          : executiveSummary.useOfFundingRequirement}
        . We anticipate a sales turnover of{" "}
        {executiveSummary.salesTurnoverAmount === ""
          ? "[SALES TURNOVER AMOUNT]"
          : executiveSummary.salesTurnoverAmount}
        , a gross profit of{" "}
        {executiveSummary.grossProfitAmount === ""
          ? "[GROSS PROFIT AMOUNT]"
          : executiveSummary.grossProfitAmount}{" "}
        and a net profit after tax of{" "}
        {executiveSummary.netProfit === ""
          ? "[NET PROFIT]"
          : executiveSummary.netProfit}{" "}
        by our 1st year of commencement of operation.
      </p>
      <p>
        The risks involved in this kind of business are:
        <br />
        <ol style={{ marginLeft: "1em" }}>
          {executiveSummary.risks.map((risk, index) => (
            <li key={index}>{risk}</li>
          ))}
        </ol>
      </p>
    </div>
  );
};

export default ExecutiveSummaryTemplate;
