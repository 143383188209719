/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import attach from "src/assets/img/home/attachments.svg";
import "./CreatePostModal.scss";
import "../CreatePost.scss";
import { Context } from "src/store";
import { createPost } from "src/requests/StudyGroupPostRequest";
import Loader from "src/components/Loader/Loader";
import "emoji-mart/css/emoji-mart.css";
import ReactAvatar from "react-avatar";
import { FileViewer } from "src/components/FileViewer/FileViewer";
import { isAllowedFiles, UploadFile } from "src/requests/fileUploadRequest";
import _ from "lodash";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { updateQueryStringParameter } from "src/utils/helper";
import { EditorBox } from "src/components/Editor";

const CreatePostModal = ({ modal, toggle, user, dp, groupId }) => {
  const [postInput, setPostInput] = useState("");
  const { dispatch } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [alertDetails, setAlertDetails] = useState({
    alertMessage: "",
    alertType: "",
  });
  const [useFileViewerUpload, setUseFileViewerUpload] = useState(false);
  const [files, setFiles] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const [urlFiles, setUrlFiles] = useState([]);
  const [clearContent, setClearContent] = useState(false);

  const { images, shareInPost } = queryString.parse(location.search);

  useEffect(() => {
    // if (shareInPost) {
    if (images) {
      const parsed = JSON.parse(images);
      if (Array.isArray(parsed)) {
        if (parsed.length > 0) {
          setUrlFiles([...parsed]);
        }
      } else {
        setUrlFiles([parsed]);
      }
    }
    // }
  }, [images]);

  const onEditorChange = (value) => {
    setPostInput(value);
  };

  const _onUploadFinish = (files) => {
    console.log(files);
  };

  const _onRemoveFile = (file) => {
    const existingFiles = [...files];
    const filtered = existingFiles.filter((f) => {
      return f.name !== file;
    });
    setFiles(filtered);
  };

  const _onRemoveUrlImages = (url) => {
    const urls = [...urlFiles];
    const filtered = urls.filter((uri) => {
      return url === uri;
    });
    setUrlFiles(filtered);

    const updatedUrl = updateQueryStringParameter(
      location.pathname + location.search,
      "images",
      JSON.stringify(filtered)
    );

    history.push(updatedUrl);
  };

  const _handleFileChange = (e) => {
    const existingFiles = [...files];
    const validateFiles = isAllowedFiles(Array.from([...e.target.files]), true);
    if (validateFiles.hasError) {
      validateFiles.errors.forEach((_file) => {});
    } else {
      existingFiles.push(...e.target.files);
      const uniqueFile = _.uniqBy(existingFiles, "name");
      setFiles(uniqueFile);
    }
  };

  const handlePost = (e) => {
    e.preventDefault();
    setIsLoading(true);

    setAlertDetails({
      ...alertDetails,
      alertMessage: "",
      alertType: "",
    });

    const payload = {
      post: {
        content: postInput,
        accessibleBy: 0,
      },
      files: urlFiles.length > 0 ? updatePayloadUrl() : [],
      groupId,
    };

    if (files.length > 0) {
      UploadFile(dispatch, true, files)
        .then((res) => {
          if (res && res.status === 200) {
            // eslint-disable-next-line array-callback-return
            res.files.map((img) => {
              const newObj = { url: img, type: "image" };
              payload.files.push(newObj);
            });
            _handleSubmit(payload);
          }
        })
        .catch((_err) => {
          _handleSubmit(payload);
        });
    } else {
      _handleSubmit(payload);
    }
    clearQueryUrlParam();
  };

  const updatePayloadUrl = () => {
    return urlFiles.map((img) => {
      return { url: img, type: "image" };
    });
  };

  const clearQueryUrlParam = () => {
    if (shareInPost) {
      let url = window.location.pathname;
      url = updateQueryStringParameter(url, "shareInPost", false);
      url = updateQueryStringParameter(url, "images", JSON.stringify([]));
      history.push(url);
      // return;
    }
  };

  const _handleSubmit = (data) => {
    createPost(dispatch, data, groupId)
      .then((res) => {
        const { status, data } = res;
        if (status !== 200) {
          setIsLoading(false);
          const { message } = data;
          setAlertDetails({
            ...alertDetails,
            alertMessage: message,
            alertType: "danger",
          });
        } else {
          setIsLoading(false);
          setAlertDetails({
            ...alertDetails,
            alertMessage: res.data.message,
            alertType: "success",
          });
          setPostInput("");
          setFiles([]);
          toggle();
        }
      })
      .catch((_error) => {
        setIsLoading(false);
        setAlertDetails({
          ...alertDetails,
          alertMessage: "Something went wrong, please contact support",
          alertType: "danger",
        });
      });
  };

  const FileIcon = () => {
    return (
      <div className="create-post-modal__bottom-actions">
        <div className="icons-div">
          <span>
            <label htmlFor="img">
              {" "}
              <img src={attach} alt="attachment" />
            </label>
            <input
              type="file"
              id="img"
              hidden
              name="img"
              accept="image/*"
              multiple
              onChange={_handleFileChange}
            />
          </span>
        </div>
        <button
          disabled={
            (postInput === "" && urlFiles.length === 0 && files.length === 0) ||
            isLoading
          }
        >
          {isLoading ? <Loader color="light" /> : "share"}
        </button>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="create-post-modal"
        // onClosed={onModalClose}
      >
        <ModalHeader toggle={toggle}>
          <div className="create-post-modal__top-actions">
            <div>CREATE POST</div>
          </div>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handlePost}>
            <div className="create-post-modal__main-actions notranslate">
              <div className="create-post-modal__image-div">
                {dp === null || dp === undefined || dp === "" ? (
                  <ReactAvatar name={user} size={45} round={true} />
                ) : (
                  <img src={dp} alt="profile" className="card-div__dp" />
                )}
              </div>
              <EditorBox
                content={postInput}
                useEmoji={true}
                onEditorChange={onEditorChange}
                className="content-editorBox"
                styles={{
                  boxShadow: "rgb(245, 247, 250) 0px -4px 0px 0px inset",
                }}
                clearContent={clearContent}
                // eslint-disable-next-line react/jsx-key
                addtionalOptions={[<FileIcon />]}
                additionalOptionStyle={{ marginLeft: "-40px" }}
                optionsRightContentStyle={{ flex: "1" }}
              />
            </div>
            <div style={{ marginLeft: "70px" }}>
              {urlFiles.length > 0 && (
                <FileViewer
                  write
                  showAddMore={false}
                  files={urlFiles}
                  onFileRemoveClick={_onRemoveUrlImages}
                />
              )}

              <FileViewer
                write
                files={files}
                onFileRemoveClick={_onRemoveFile}
                upload={useFileViewerUpload}
                onUploadFinish={_onUploadFinish}
                onAddImageClick={() => document.getElementById("img").click()}
              />
            </div>
            {/* <div className="create-post-modal__publish"> */}
            {/* <p> */}
            {/* Publish Articles <span>on TEFConnect</span> */}
            {/* </p> */}
            {/* </div> */}
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default CreatePostModal;
