import React from "react";
import { isLoggedIn } from "./utils/isLoggedIn";
import { usePageView } from "./utils/usePageViewHook";
import SentryRoute from "./SentryRoute";
import { SignInRedirect } from "./Auth/OpenId/OpenIdCallbacks";

const PrivateRoute = ({ component: Component, ...rest }) => {
  usePageView();

  return (
    <SentryRoute
      {...rest}
      render={(props) =>
        isLoggedIn() ? <Component {...props} /> : <SignInRedirect />
      }
    />
  );
};

export default PrivateRoute;
