import React, { useEffect, useContext } from "react";
import { getRelatedOpportunities } from "src/requests/OpportunityRequest";
import { Context } from "src/store";
import { Row, Col } from "reactstrap";
import { OpportunityCard } from "src/modules/Alumni/Components/Cards/OpportunityCard";
import ArticleNotFound from "src/modules/Alumni/Components/NotFound/ArticleNotFound";
const RelatedOpportunity = ({ relatedOpportunitiesIds, history }) => {
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    if (relatedOpportunitiesIds && relatedOpportunitiesIds.length > 0) {
      getRelatedOpportunities(dispatch, relatedOpportunitiesIds, true);
    }
  }, [relatedOpportunitiesIds && relatedOpportunitiesIds.length]);
  const { relatedOpportunities } = state.Opportunities;

  // const oddOpportunityList = () => {
  //   let classTitle;

  //   if (relatedOpportunitiesIds.length > 1 && relatedOpportunitiesIds % 2 > 0) {
  //     classTitle = "alumni-opp-list";
  //   } else {
  //     classTitle = "alumni-opp-list-no-justify";
  //   }
  //   return classTitle;
  // };

  return (
    <div className="bg-gray">
      <h5 className="opportunity-related mt-5">Related opportunities</h5>
      {relatedOpportunities && relatedOpportunities.length === 0 ? (
        <Row>
          <Col>
            <ArticleNotFound text="No Related Opportunity" />
          </Col>
        </Row>
      ) : (
        <div className={`alumni-opp-list bg-gray`}>
          {relatedOpportunities &&
            relatedOpportunities.map((opportunity, idx) => (
              <OpportunityCard
                key={idx}
                opportunity={opportunity}
                hsitory={history}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default RelatedOpportunity;
