import React, { useEffect, useState, useContext } from "react";
import Layout from "src/components/Layout/Layout";

import "./CreateOpportunity.scss";
import { BannerCreateOpportunity } from "src/modules/Opportunity/components/Banner/Banner";
import {
  TipsContainer,
  TipsItem,
} from "src/modules/Opportunity/components/CreateOpportunityTips/Tips";
import CreateOpportunityForm from "src/modules/Opportunity/components/OpportunityForm/OpportunityForm";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Link, useHistory } from "react-router-dom";
import { opportunityObj } from "src/modules/Opportunity/components/Helpers/opportunityObj";
import { CastValue } from "src/modules/onboarding/helper";
import { Context } from "src/store";
import { postOpportunity } from "src/requests/OpportunityRequest";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import { useTranslation } from "react-i18next";

const CreateOpportunity = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState(opportunityObj);
  const { dispatch } = useContext(Context);

  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChange = (target, value) => {
    setData({ ...data, [target]: CastValue(target, value) });
  };

  const onSubmit = (payload) => {
    postOpportunity(dispatch, payload, history);
  };

  return (
    <Layout hideLeft hideRight {...props}>
      <div className="create-opp-breadcrumb">
        <Link to="/home" style={{ fontWeight: "bold" }}>
          {t("Home")} <IoIosArrowRoundForward />
        </Link>
        <Link to="/opportunities">
          {t("Opportunities")} <IoIosArrowRoundForward />
        </Link>
        <span>{t("Submit Opportunity")}</span>
      </div>

      <div className="create-opp-banner-holder">
        <BannerCreateOpportunity />
      </div>
      <div className="create-opp-form-container">
        <div className="create-opp-form-container__left">
          <CreateOpportunityForm
            data={data}
            onChange={onChange}
            onSubmit={onSubmit}
          />
        </div>
        <div className="create-opp-form-container__right">
          <h1>{t("Helpful Tips to Get Your Opportunity Featured")}</h1>
          <TipsContainer>
            <TipsItem
              title={
                <h1>
                  {t("Detailed")}{" "}
                  <span className="red">{t("Descriptions")}</span>
                </h1>
              }
              description={t(
                "Make sure both the summaryand full descriptions are carefully worded and outlined"
              )}
            />
            <TipsItem
              title={
                <h1>
                  {t("Use Clear")}{" "}
                  <span className="red">{t("Feature Banner")}</span>
                </h1>
              }
              description={t(
                "It’s important to use catchy poster image as the cover banner for the opportunity to be featured."
              )}
            />
            <TipsItem
              title={
                <h1>
                  {t("Provide")} <span className="red">{t("Valid Links")}</span>
                </h1>
              }
              description={t(
                "Provide the appropriate links needed for interested applicants to find more information"
              )}
            />
          </TipsContainer>
        </div>
      </div>
    </Layout>
  );
};

export default ifOnboardedThenShow(CreateOpportunity);
