import React from "react";
import SuccessImage from "src/assets/img/success-tef.png";
import "./index.scss";

const FixedAlertBox = ({ title, description, children }) => (
  <div className="overlay d-flex justify-content-center">
    <div className="overlay__container col-md-4 text-center">
      <img src={SuccessImage} alt="Icon" />
      {title && <h5 className="overlay__title">{title}</h5>}
      {description && <h6 className="overlay__desc">{description}</h6>}
      <br />
      {children}
    </div>
  </div>
);

export default FixedAlertBox;
