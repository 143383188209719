import { getAlumniHubGallery } from "src/requests/AlumniHubRequest";

export function getAlumniHubGalleryById(
  dispatch,
  setHubPhotos,
  setIsLoadingHubPhotos
) {
  return (hubId) => {
    getAlumniHubGallery(dispatch, hubId)
      .then((response) => setHubPhotos(response.data.data.data))
      .catch((getAlumniGalleryError) => console.log(getAlumniGalleryError))
      .finally(() => setIsLoadingHubPhotos(false));
  };
}
