export const FETCH_USER_MENTORS = "FETCH_USER_MENTORS";
export const FETCH_ENTREPRENEURS = "FETCH_ENTREPRENEURS";
export const FETCH_MENTOR_FAQ = "FETCH_MENTOR_FAQ";
export const FETCH_USER_GOALS = "FETCH_USER_GOALS";
export const REGISTER_USER = "REGISTER_USER";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const VERIFY_ACCOUNT = "VERIFY_ACCOUNT";
export const SEND_VERIFY_LINK = "SEND_VERIFY_LINK";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";

export const GET_SKILLS = "GET_SKILLS";
export const GET_ORGANIZATIONS = "GET_ORGANIZATIONS";
export const GET_MENTORSHIP_AREAS = "GET_MENTORSHIP_AREAS";
export const GET_INTERESTS = "GET_INTERESTS";
export const GET_SINGLE_INTEREST = "GET_SINGLE_INTEREST";
export const SUBMIT_ONBOARDING_DATA = "SUBMIT_ONBOARDING_DATA";

export const CREATE_POST = "CREATE_POST";
export const VIEW_ALL_POSTS = "VIEW_ALL_POSTS";
export const UPDATE_POST = "UPDATE_POST";
export const DELETE_POST = "DELETE_POST";
export const LIKE_POST = "LIKE_POST";
export const UNLIKE_POST = "UNLIKE_POST";
export const GET_USER_LIKE = "GET_USER_LIKE";
export const CREATE_COMMENT = "CREATE_COMMENT";
export const GET_COMMENTS = "GET_COMMENTS";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const LIKE_COMMENT = "LIKE_COMMENT";
export const GET_POST = "GET_POST";
export const GET_USER_LIKE_COMMENT = "GET_USER_LIKE_COMMENT";
export const GET_USER_VOTE = "GET_USER_VOTE";
export const SHARE_POST = "SHARE_POST";
export const GET_LIKE_USERS = "GET_LIKE_USERS";
export const ADD_BOOKMARK = "ADD_BOOKMARK";
export const REMOVE_BOOKMARK = "REMOVE_BOOKMARK";
export const GET_ALL_BOOKMARKS = "GET_ALL_BOOKMARKS";

export const CREATE_QUESTION = "CREATE_QUESTION";
export const GET_QUESTIONS = "GET_QUESTIONS";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const LIKE_QUESTION = "LIKE_QUESTION";
export const PLACE_VOTE = "PLACE_VOTE";

export const CREATE_GOAL = "CREATE_GOAL";
export const VIEW_GOAL = "VIEW_GOAL";
export const VIEW_ALL_GOALS = "VIEW_ALL_GOALS";
export const VIEW_ALL_GOALS_ANALYSIS = "VIEW_ALL_GOALS_ANALYSIS";
export const UPDATE_GOAL = "UPDATE_GOAL";
export const DELETE_GOAL = "DELETE_GOAL";
export const CREATE_PERSONAL_GOAL = "CREATE_PERSONAL_GOAL";
export const VIEW_PERSONAL_GOAL = "VIEW_PERSONAL_GOAL";
export const VIEW_PERSONAL_GOALS = "VIEW_PERSONAL_GOALS";
export const UPDATE_PERSONAL_GOAL = "UPDATE_PERSONAL_GOAL";
export const DELETE_PERSONAL_GOAL = "DELETE_PERSONAL_GOAL";
export const RATE_GOAL = "RATE_GOAL";
export const UPDATE_GOAL_REMINDER = "UPDATE_GOAL_REMINDER";

export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER = "GET_USER";
export const GET_REQUESTED_MENTORS = "GET_REQUESTED_MENTORS";
export const GET_REQUESTED_MENTEES = "GET_REQUESTED_MENTEES";
export const GET_USER_MENTORS = "GET_USER_MENTORS";
export const CONNECT = "CONNECT";
export const GET_CONNECTIONS = "GET_CONNECTIONS";
export const GET_CONNECTED_MENTEES = "GET_CONNECTED_MENTEES";
export const REMOVE_MENTORSHIP_CONNECTION = "REMOVE_MENTORSHIP_CONNECTION";

export const ACCEPT_REQUESTS = "ACCEPT_REQUESTS";
export const REJECT_REQUESTS = "REJECT_REQUESTS";

export const GET_GOAL_DISCUSSIONS = "GET_GOAL_DISCUSSIONS";
export const CREATE_DISCUSSION_ON_GOAL = "CREATE_DISCUSSION_ON_GOAL";
export const LIKE_DISCUSSION = "LIKE_DISCUSSION";
export const GET_DISCUSSION_DETAILS = "GET_DISCUSSION_DETAILS";
export const GET_DISCUSSION_REPLIES = "GET_DISCUSSION_REPLIES";
export const CREATE_DISCUSSION_REPLY = "CREATE_DISCUSSION_REPLIES";
export const LIKE_REPLY = "LIKE_REPLY";
export const GET_REPLY_DETAILS = "GET_REPLY_DETAILS";
export const CHANGE_GOAL_STATUS = "CHANGE_GOAL_STATUS";
export const CHANGE_MENTEE_GOAL_STATUS = "CHANGE_MENTEE_GOAL_STATUS";

// APPLICANT ACTIONS
export const FETCH_APPLICATION_FAQ = "FETCH_APPLICATION_FAQ";
export const GET_PERSONALITY_QUESTIONS = "GET_PERSONALITY_QUESTIONS";
export const GET_MCQ_QUESTIONS = "GET_MCQ_QUESTIONS";
export const SEND_FEEDBACK = "SEND_FEEDBACK";
export const GET_USER_APPLICATIONS = "GET_USER_APPLICATIONS";
export const SUBMIT_DECLARATION = "SUBMIT_DECLARATION";
export const SUBMIT_ASSESSMENT = "SUBMIT_ASSESSMENT";
export const VERIFY_RESULT = "VERIFY_RESULT";

export const CREATE_PROGRAMME = "CREATE_PROGRAMME";
export const GET_ALL_PROGRAMMES = "GET_ALL_PROGRAMMES";
export const DELETE_PROGRAMME = "DELETE_PROGRAMME";

export const GET_USERS_GROUP = "GET_USERS_GROUP";

// file upload
export const FILE_UPLOAD = "FILE_UPLOAD";
export const FILE_UPLOAD_PROGRESS = "FILE_UPLOAD_PROGRESS";

// tef-mentor
export const UPDATE_MENTOR_PROFILE = "SET_AVAILABILITY";

// user profile update
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";

export const GET_SURVEY_QUESTIONS = "GET_SURVEY_QUESTIONS";
export const GET_WEEKLY_SURVEY_QUESTIONS = "GET_WEEKLY_SURVEY_QUESTIONS";

export const SUBMIT_SURVEY_ANSWERS = "SUBMIT_SURVEY_ANSWERS";

export const USER_SKILL_ADD = "USER_SKILL_ADD";
export const USER_SKILL_DELETE = "USER_SKILL_DELETE";
export const USER_ACHIEVEMENT_ADD = "USER_ACHIEVEMENT_ADD";
export const USER_ACHIEVEMENT_DELETE = "USER_ACHIEVEMENT_DELETE";
export const USER_EDUCATION_ADD = "USER_EDUCATION_ADD";
export const USER_EDUCATION_DELETE = "USER_EDUCATION_DELETE";
export const SEND_DATA_ACCESS_RIGHT = "SEND_DATA_ACCESS_RIGHT";

export const USER_PHOTO_ADD = "USER_PHOTO_ADD";
export const USER_PHOTO_DELETE = "USER_PHOTO_DELETE";
export const USER_PHOTO_LIKE = "USER_PHOTO_LIKE";
export const HIDE_PROFLE_FIELDS = "HIDE_PROFLE_FIELDS";

// invite
export const SEND_INVITE = "SEND_INVITE";

// Opportunity
export const GET_OPPORTUNITY = "GET_OPPORTUNITY";
export const DELETE_OPPORTUNITY = "DELETE_OPPORTUNITY";
export const CREATE_OPPORTUNITY = "CREATE_OPPORTUNITY";
export const EDIT_OPPORTUNITY = "EDIT_OPPORTUNITY";
export const GET_OPPORTUNITIES = "GET_OPPORTUNITIES";
export const GET_TOP_OPPORTUNITIES = "GET_TOP_OPPORTUNITIES";
export const GET_OPPORTUNITY_CATEGORIES = "GET_OPPORTUNITY_CATEGORIES";
export const GET_OPPORTUNITY_TYPES = "GET_OPPORTUNITY_TYPES";
export const FLAG_OPPORTUNITY = "FLAG_OPPORTUNITY";
export const GET_RELATED_OPPORTUNITIES = "GET_RELATED_OPPORTUNITIES";
export const TOGGLE_OPPORTUNITY_REMINDER = "OPPORTUNITY_REMINDER";
export const GET_OPPORTUNITY_COMMENTS = "GET_OPPORTUNITY_COMMENTS";
export const POST_OPPORTUNITY_COMMENT = "POST_OPPORTUNITY_COMMENT";
export const LIKE_OPPORTUNITY_COMMENT = "LIKE_OPPORTUNITY_COMMENT";
export const REPLY_OPPORTUNITY_COMMENT = "REPLY_OPPORTUNITY_COMMENT";
export const GET_OPPORTUNITY_COMMENT_RESPONSE =
  "GET_OPPORTUNITY_COMMENT_RESPONSE";
export const GET_MY_OPPORTUNITES = "GET_MY_OPPORTUNITES";
export const GET_OPPORTUNITY_INTEREST = "GET_OPPORTUNITY_INTEREST";
export const DELETE_OPPORTUNITY_COMMENT = "  DELETE_OPPORTUNITY_COMMENT";
export const INCREASE_OPPORTUNITY_VIEW = "INCREASE_OPPORTUNITY_VIEW";
export const INCREASE_OPPORTUNITY_POPULARITY =
  "INCREASE_OPPORTUNITY_POPULARITY";
export const CANCEL_OPPORTUNITY_REMINDER = "CANCEL_OPPORTUNITY_REMINDER";

// Community
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_CATEGORY_GROUPS = "GET_CATEGORY_GROUPS";
export const CREATE_GROUP = "CREATE_GROUP";
export const GET_ALL_GROUPS = "GET_ALL_GROUPS";
export const GET_GROUP = "GET_GROUP";
export const IS_GROUP_MEMBER = "IS_GROUP_MEMBER";
export const JOIN_GROUP = "JOIN_GROUP";
export const LEAVE_GROUP = "LEAVE_GROUP";
export const GET_GROUP_POSTS = "GET_GROUP_POSTS";
export const DELETE_GROUP = "DELETE_GROUP";
export const GET_GROUP_USERS = "GET_GROUP_USERS";
export const GROUP_INVITE = "GROUP_INVITE";
export const SEARCH_MEMBER = "SEARCH_MEMBER";
export const CREATE_TOPIC = "CREATE_TOPIC";
export const GET_ALL_TOPICS = "GET_ALL_TOPICS";
export const GET_TOPIC_DETAILS = "GET_TOPIC_DETAILS";
export const GET_TOPIC_POSTS = "GET_TOPIC_POSTS";
export const GET_USER_GROUPS = "GET_USER_GROUPS";
export const SEARCH_GROUPS = "SEARCH_GROUPS";
export const REMOVE_USER = "REMOVE_USER";
export const FLAG_A_TOPIC = "FLAG_A_TOPIC";
export const GET_CONNECTIONS_GROUPS = "GET_CONNECTIONS_GROUPS";
export const DELETE_TOPIC = "DELETE_TOPIC";
export const LIKE_TOPIC = "LIKE_TOPIC";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const GET_COMMUNITY_CONNECTIONS = "GET_COMMUNITY_CONNECTIONS";
export const REQUEST_COMMUNITY_CONNECTION = "REQUEST_COMMUNITY_CONNECTION";
export const ACCEPT_COMMUNITY_CONNECTION = "ACCEPT_COMMUNITY_CONNECTION";
export const REJECT_COMMUNITY_CONNECTION = "REJECT_COMMUNITY_CONNECTION";
export const GET_COMMUNITY_SUGGESTIONS = "GET_COMMUNITY_SUGGESTIONS";
export const GET_COMMUNITY_PENDING_SENT_CONNECTIONS =
  "GET_COMMUNITY_PENDING_SENT_CONNECTIONS";
export const GET_COMMUNITY_PENDING_RECEIVED_CONNECTIONS =
  "GET_COMMUNITY_PENDING_RECEIVED_CONNECTIONS";
export const GET_GROUP_INVITATION_RECEIVED = "GET_GROUP_INVITATION_RECEIVED";
export const ACCEPT_GROUP_INVITATION_RECEIVED =
  "ACCEPT_GROUP_INVITATION_RECEIVED";
export const REJECT_GROUP_INVITATION_RECEIVED =
  "REJECT_GROUP_INVITATION_RECEIVED";
export const ACCEPT_ALL_GROUP_INVITATION_RECEIVED =
  "ACCEPT_ALL_GROUP_INVITATION_RECEIVED";

// training
export const GET_MENTEE_TRAINING_SCORE = "GET_MENTEE_TRAINING_SCORE";
export const GET_ALL_TRAINING_COURSES = "GET_ALL_TRAINING_COURSES";
export const GET_QUIZ_FOR_MODULE = "GET_QUIZ_FOR_MODULE";
export const SUBMIT_LMS_ASSESSMENT = "SUBMIT_LMS_ASSESSMENT";
export const GET_ALL_TRAINING_MODULES = "GET_ALL_TRAINING_MODULES";
export const GET_LESSON_CONTENT = "GET_LESSON_CONTENT";
export const USER_STARTS_COURSE = "USER_STARTS_COURSE";
export const GET_COURSE_OVERVIEW = "GET_COURSE_OVERVIEW";
export const GET_USER_REGISTERED_COURSES = "GET_USER_REGISTERED_COURSES";
export const USER_STARTS_LESSON = "USER_STARTS_LESSON";
export const USER_COMPLETES_LESSON = "USER_COMPLETES_LESSON";
export const HAS_COMPLETED_LESSON = "HAS_COMPLETED_LESSON";
export const GET_USER_TRAINING_MODULES = "GET_USER_TRAINING_MODULES";
export const GET_TRAINING_QUESTION_AND_ANSWER =
  "GET_TRAINING_QUESTION_AND_ANSWER";
export const USER_STARTS_MODULE = "USER_STARTS_MODULE";
export const USER_COMPLETES_MODULE = "USER_COMPLETES_MODULE";
export const USER_COMPLETES_COURSE = "USER_COMPLETES_COURSE";
export const CLEAR_QANDA_POST_STATE = "CLEAR_QANDA_POST_STATE";
export const GET_STUDY_GROUP_COURSE_MATES = "GET_STUDY_GROUP_COURSE_MATES";
export const GET_STUDY_GROUP_INVITATION_SENT =
  "GET_STUDY_GROUP_INVITATION_SENT";
export const GET_STUDY_GROUP_INVITATION_RECEIVED =
  "GET_STUDY_GROUP_INVITATION_RECEIVED";
export const GET_TRAINING_SURVEY_QUESTIONS = "GET_TRAINING_SURVEY_QUESTIONS";
export const SUBMIT_TRAINING_SURVEY_QUESTIONS =
  "SUBMIT_TRAINING_SURVEY_QUESTIONS";
export const GET_TRAINING_COURSE_ASSESSMENT = "GET_TRAINING_COURSE_ASSESSMENT";

// tools
export const GET_ALL_TOOLS_CATEGORIES = "GET_ALL_TOOLS_CATEGORIES";
export const GET_ALL_TOOLS = "GET_ALL_TOOLS";
export const GET_TOOL_CATEGORY = "GET_TOOL_CATEGORY";
export const FILTER_TOOL_LIST = "FILTER_TOOL_LIST";
export const CREATE_TOOL = "CREATE_TOOL";
export const GET_TOOL_DETAILS = "GET_TOOL_DETAILS";
export const POST_TOOL_COMMENT = "POST_TOOL_COMMENT";
export const GET_TOOL_COMMENTS = "GET_TOOL_COMMENTS";
export const LIKE_TOOL_COMMENT = "LIKE_TOOL_COMMENT";
export const DELETE_TOOL_COMMENT = "DELETE_TOOL_COMMENT";
export const INCREASE_TOOL_VIEW = "INCREASE_TOOL_VIEW";
export const INCREASE_TOOL_POPULARITY = "INCREASE_TOOL_POPULARITY";
export const GET_TOOLS_BASED_ON_POPULARITY = "GET_TOOLS_BASED_ON_POPULARITY";
export const GET_CATEGORY_NAME = "GET_CATEGORY_NAME";
export const GET_TOOL_VIEWERS = "GET_TOOL_VIEWERS";
export const ADD_TOOL_NOTIFICATION = "ADD_TOOL_NOTIFICATION";

// messaging
export const READ_MESSAGE = "READ_MESSAGE";
export const GET_ALL_MESSAGES = "GET_ALL_MESSAGES";
export const GET_USER_MESSAGES = "GET_USER_MESSAGES";
export const SEND_MESSAGE = "SEND_MESSAGE";

export const CREATE_PROGRAMME_EVENT = "CREATE_PROGRAMME_EVENT";
export const EDIT_PROGRAMME_EVENT = "EDIT_PROGRAMME_EVENT";
export const GET_ALL_PROGRAMME_EVENTS = "GET_ALL_PROGRAMME_EVENTS";
export const DELETE_PROGRAMME_EVENT = "DELETE_PROGRAMME_EVENT";
export const STOP_PROGRAMME_EVENT = "STOP_PROGRAMME_EVENT";
export const START_PROGRAMME_EVENT = "START_PROGRAMME_EVENT";

export const GET_PROGRAMME_EVENT_TYPES = "GET_PROGRAMME_EVENT_TYPES";

export const SEND_EMAIL = "SEND_EMAIL";
export const CREATE_APPOINTMENT = "CREATE_APPOINTMENT";
export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
export const ACCEPT_INVITATION = "ACCEPT_INVITATION";
export const DECLINE_INVITATION = "DECLINE_INVITATION";
export const DELETE_INVITATION = "DELETE_INVITATION";
export const UPDATE_INVITATION = "UPDATE_INVITATION";
export const GET_TERMS_AND_CONDITION = "GET_TERMS_AND_CONDITION";
export const GET_PROGRAMME_DETAILS = "GET_PROGRAMME_DETAILS";
export const AGREE_TO_TERMS = "AGREE_TO_TERMS";
export const AGREE_TO_PARTICIPATE = "AGREE_TO_PARTICIPATE";
export const HAS_AGREED_TO_PARTICIPATE = "HAS_AGREED_TO_PARTICIPATE";

export const GET_USER_LIGHT_PROFILE = "GET_USER_LIGHT_PROFILE";

export const GET_PROGRAMME_EVENT_CONTENTS = "GET_PROGRAMME_EVENT_CONTENTS";

export const GET_ALL_ARTICLES_CATEGORIES = "GET_ALL_ARTICLES_CATEGORIES";
export const GET_ALL_ARTICLES_TAGS = "GET_ALL_ARTICLES_TAGS";
export const GET_ALL_ARTICLES = "GET_ALL_ARTICLES";
export const CREATE_ARTICLES = "CREATE_ARTICLES";
export const GET_ARTICLES_LIKES = "GET_ARTICLES_LIKES";
export const GET_ARTICLE = "GET_ARTICLE";
// Discover masterclass

export const GET_ALL_MASTER_CLASS_TAGS = "GET_ALL_MASTER_CLASS_TAGS";
export const GET_ALL_MASTER_CLASS = "GET_ALL_MASTER_CLASS";
// Discover stories
export const CREATE_STORY = "CREATE_DISCOVER_STORY";
export const GET_ALL_STORY = "GET_ALL_DISCOVER_STORY";
export const GET_ALL_STORY_INDUSTRIES = "GET_ALL_STORY_INDUSTRIES";
export const GET_ALL_STORY_COUNTRIES = "GET_ALL_STORY_COUNTRIES";
export const CREATE_ARTICLE_COMMENT = "CREATE_ARTICLE_COMMENT";
export const GET_ALL_ARTICLE_COMMENTS = "GET_ALL_ARTICLE_COMMENTS";
export const GET_ALL_LEARNING_RESOURCE = "GET_ALL_LEARNING_RESOURCE";
export const CREATE_STORY_COMMENT = "CREATE_STORY_COMMENT";
export const GET_ALL_STORY_COMMENTS = "GET_ALL_STORY_COMMENTS";
export const GET_TOP_ARTICLES = "GET_TOP_ARTICLES";
export const INCREASE_ARTICLE_VIEW = "INCREASE_ARTICLE_VIEW";

//  Discover News
export const GET_ALL_NEWS = "GET_ALL_NEWS";
export const GET_ALL_NEWS_COMMENTS = "GET_ALL_NEWS_COMMENTS";
export const GET_NEWS = " GET_NEWS";
export const CREATE_NEWS_COMMENT = "CREATE_NEWS_COMMENT";
export const GET_DISCOVER_MODULE_VIDEOS = "GET_DISCOVER_MODULE_VIDEOS";
export const UPDATE_ARTICLE = "UPDATE_ARTICLE";

// Alumni Opportunity
export const GET_ALL_ALUMNI_OPPORTUNITIES = "GET_ALL_ALUMNI_OPPORTUNITIES";
export const GET_ALUMNI_OPPORTUNITIES = "GET_ALUMNI_OPPORTUNITIES";
export const GET_ALL_ALUMNI_OPPORTUNITY_DISCUSSION =
  "GET_ALL_ALUMNI_OPPORTUNITY_DISCUSSION";

// Alumni hub
export const CREATE_ALUMNI_HUB = "CREATE_ALUMNI_HUB";
export const GET_ALUMNI_HUBS = "GET_ALUMNI_HUBS";

// Alumni Events
export const CREATE_ALUMNI_EVENT = "CREATE_ALUMNI_EVENT";
export const GET_ALUMNI_EVENT = "GET_ALUMNI_EVENT";
export const GET_ALL_ALUMNI_EVENTS = "GET_ALL_ALUMNI_EVENTS";
export const DELETE_EVENT_COMMENT = "DELETE_EVENT_COMMENT";
export const GET_ALL_ALUMNI_EVENT_COMMENTS = "GET_ALL_ALUMNI_EVENT_COMMENTS";
export const POST_EVENT_COMMENT = "POST_EVENT_COMMENT";
export const POST_EVENT_RSVP = "POST_EVENT_RSVP";

// Notification
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const READ_NOTIFICATION = "READ_NOTIFICATION";

// DMS
export const GET_ALL_SEEDCAPITAL_DOCUMENTS = "GET_ALL_SEEDCAPITAL_DOCUMENTS";
export const POST_DUEDILLIGENCE_DOCUMENTS = "POST_DUEDILLIGENCE_DOCUMENTS";
export const POST_SEEDCAPITAL_DOCUMENTS = "POST_SEEDCAPITAL_DOCUMENTS";
export const POST_BANKDETAILS = "POST_BANKDETAILS";
export const GET_BANKDETAILS = "GET_BANKDETAILS";
export const GET_ALL_DUEDILLIGENCE_DOCUMENTS =
  "GET_ALL_DUEDILLIGENCE_DOCUMENTS";
export const GET_ALL_BUSINESSPLAN_DOCUMENTS = "GET_ALL_BUSINESSPLAN_DOCUMENTS";
export const GET_DUEDILLIGENCE_REQUIRED_DOCUMENTS =
  "GET_DUEDILLIGENCE_REQUIRED_DOCUMENTS";
export const GET_SEEDCAPITAL_REQUIRED_DOCUMENTS =
  "GET_SEEDCAPITAL_REQUIRED_DOCUMENTS";
export const GET_BUSINESSPLANS_REQUIRED_DOCUMENTS =
  "GET_BUSINESSPLANS_REQUIRED_DOCUMENTS";
