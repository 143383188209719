import React, { useState } from "react";
import classes from "./FullDescription.module.css";
import SubHeading from "src/modules/Alumni/Components/SubHeading/SubHeading";
import DOMPurify from "dompurify";
import { BsListCheck } from "react-icons/bs";
import { FcWebcam } from "react-icons/fc";
import { useTranslation } from "react-i18next";

const EventFullDescription = ({
  fullDescription,
  privacy,
  officialWebsite,
  otherClassName,
  title,
}) => {
  const [expanded, setExpanded] = useState(false);

  const continueReading = () => {
    setExpanded(!expanded);
  };
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div
        className={
          expanded
            ? `${classes.specialText}-expanded`
            : `${classes.specialText}`
        }
      >
        <div>
          <h3 className="opportunity-summary"> {title || "Full Summary"}</h3>
          <div
            className={otherClassName}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(fullDescription),
            }}
          ></div>
        </div>
        <div>
          <SubHeading text="Privacy" icon={<BsListCheck color={`#313645`} />} />
          <div
            className={otherClassName}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(privacy),
            }}
          ></div>
        </div>

        <div>
          <SubHeading text="Official Website" icon={<FcWebcam color="red" />} />
          <div
            className={otherClassName}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(officialWebsite),
            }}
          ></div>
        </div>
      </div>
      <button
        className={`${classes.expandButton} show-more-btn`}
        onClick={continueReading}
      >
        {expanded ? `${t("Show Less Details")}` : `${t("Show All Details")}`}
      </button>
    </div>
  );
};

export default EventFullDescription;
