import React, { Fragment, useEffect, useContext, useState } from "react";
import classes from "./ViewComment.module.css";
import UserReactions from "./UserReactions";
import Avatar from "src/components/Avatar/Avatar";
import {
  getComments,
  getCommenterDetails,
} from "src/requests/OpportunityRequest";
import { Context } from "src/store";
import { EditorContentDisplay } from "src/components/Editor";
import Swal from "sweetalert2";
import { showLoading } from "src/modules/Opportunity/components/List/List";

const ViewComment = ({ opportunityId, reloadComponent }) => {
  const { dispatch } = useContext(Context);
  const [comments, setComments] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    _getComments();
  }, [reload, reloadComponent]);

  const _getComments = async () => {
    setLoading(true);
    try {
      const comments = await getComments(dispatch, opportunityId);
      const {
        data: { data },
      } = comments;
      if (data) {
        const commentCreators = data.map((cc) => cc.createdBy);
        const commenters = await _getCommenterDetails(commentCreators);
        const mappedComment = data.map((t1) => ({
          ...t1,
          ...commenters.filter((t2) => t2.userId === t1.createdBy),
        }));
        setComments(mappedComment);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error,
        confirmButtonColor: "#FC5A5A",
      });
    }
  };
  const _getCommenterDetails = async (id) => {
    const commenter = await getCommenterDetails(id);
    const {
      data: { data },
    } = commenter;
    return data;
  };

  const reloadHandler = () => {
    setReload(!reload);
  };

  return (
    <>
      {loading ? (
        showLoading
      ) : (
        <div className={classes.ViewComment}>
          {comments &&
            comments.map((comment) => (
              <Fragment key={comment.id}>
                <div className={classes.UserInfo}>
                  <div className={classes.UserImage}>
                    <Avatar
                      src={comment["0"].imageUrl}
                      dimension="2rem"
                      name={`${comment["0"].firstName} ${comment["0"].lastName}`}
                    />
                  </div>
                  <div className={classes.Comment}>
                    <div className={classes.UserDetails}>
                      <div
                        className={classes.UserName}
                      >{`${comment["0"].firstName} ${comment["0"].lastName}`}</div>
                    </div>
                    <div className={classes.CommentBody}>
                      <EditorContentDisplay
                        content={comment.message}
                        readOnly
                      />
                    </div>
                    <UserReactions
                      message={comment.message}
                      commentId={comment.id}
                      totalLikes={comment.totalLikes}
                      allLikesIds={comment.allLikesIds}
                      totalReplies={comment.totalReplies}
                      createdBy={comment.createdBy}
                      opportunityId={opportunityId}
                      reload={reloadHandler}
                    />
                  </div>
                </div>
              </Fragment>
            ))}
        </div>
      )}
    </>
  );
};

export default ViewComment;
