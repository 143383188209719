import React, { useState } from "react";
import classes from "./ChatBubble.module.scss";
import { GrDocumentText } from "react-icons/gr";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

import moment from "moment";
import { RiDeleteBin6Line } from "react-icons/ri";
import "./chat.scss";

import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const user = get_user_info_from_token();

let id;
if (user) {
  id = user.user.id;
}
function dateToFromNowDaily(myDate) {
  // get from-now for this date
  // eslint-disable-next-line
  const fromNow = moment(myDate).fromNow();

  // ensure the date is displayed with today and yesterday
  return moment(myDate).calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: "[Last] dddd",
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    // when the date is further away, use from-now functionality
    sameElse: function () {
      return "[" + fromNow + "]";
    },
  });
}
const Chatubble = ({ chatHistory, removeChat }) => {
  const dateSeperator = (chatHistory, chat, index) => {
    const currentChatDate = moment(chat.createdOn).format("YYYY-MM-DD");

    let previousChatDate = chatHistory[index - 1]?.createdOn;
    previousChatDate = moment(previousChatDate).format("YYYY-MM-DD");
    const res = moment(currentChatDate).isSame(previousChatDate, "day");
    return res;
  };
  return (
    <>
      {chatHistory.length > 0 &&
        chatHistory.map((chat, index) => (
          <ChatBubbleList
            chat={chat}
            key={JSON.stringify(chat)}
            removeChat={removeChat}
            dateSeperator={dateSeperator(chatHistory, chat, index)}
          />
        ))}
    </>
  );
};

const ChatBubbleList = ({ chat, removeChat, dateSeperator }) => {
  const [showIcon, setShowIcon] = useState(false);
  const isUser = (chat) => {
    return chat.fromUser === id;
  };
  const loggedInUserChat = (chat) => {
    const chatPosition = isUser(chat) ? "moveChatRight" : "moveChatLeft";
    return chatPosition;
  };
  const loggedInUserChatMessage = (chat) => {
    const chatPosition = isUser(chat) ? "moveChatRight-bg" : "moveChatLeft-bg";
    return chatPosition;
  };

  const userInfo = (chat) => {
    chat.senderProfile.fullName = chat.senderProfile.fullName
      ? chat.senderProfile.fullName
      : chat.senderProfile?.firstName + chat.senderProfile?.lastName;
    chat.senderProfile.fullName = chat.senderProfile.fullName
      ? chat.senderProfile?.fullName
      : chat.receiverProfile?.firstName + chat.receiverProfile?.lastName;
    const userDetail =
      chat.fromUser === chat.senderProfile?.userId
        ? chat.senderProfile
        : chat.receiverProfile;
    return userDetail;
  };
  const toggleIcon = () => setShowIcon((prev) => !prev);

  const downloadImage = (imgUrl) => {
    const element = document.createElement("a");
    element.href = imgUrl;
    element.click();
  };
  return (
    <>
      {!dateSeperator && (
        <div className="chat-date-seperator">
          <span className="date-seperator-value">
            {dateToFromNowDaily(chat?.createdOn)}
          </span>
        </div>
      )}

      <div
        className={`${
          classes["chat-bubble__wrapper"]
        } chat-bubble-list ${loggedInUserChat(chat)}`}
        key={chat.id}
        onClick={toggleIcon}
      >
        {userInfo(chat)?.imageUrl ? (
          <img
            src={`${userInfo(chat)?.imageUrl}`}
            className="chat-user-img"
            width="40"
            height="40"
          />
        ) : (
          <div className="user-no-img">
            <span>{`${userInfo(chat)?.fullName[0]}`}</span>
          </div>
        )}

        <div className={classes["chat-bubble__body"]}>
          {chat.message && (
            <p
              className={`${
                classes["chat-bubble__message"]
              } ${loggedInUserChatMessage(chat)}`}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(chat.message),
              }}
            ></p>
          )}

          <p className={classes["chat-bubble__meta"]}>
            <span>{`${userInfo(chat)?.fullName} `}</span>
            <span className="px-1">|</span>
            <span>
              <time>{moment(chat.createdOn).fromNow()}</time>
            </span>
          </p>
          <div className="chat-img-box">
            {chat.attachment.length > 0 &&
              chat.attachment.map((img, key) => (
                <Attachment key={key} img={img} downloadImage={downloadImage} />
              ))}
          </div>
        </div>
        {showIcon && (
          <div className="chat-icons">
            <span className="del-chat" onClick={() => removeChat(chat.id)}>
              <RiDeleteBin6Line color="#bec4cb" />
            </span>
          </div>
        )}
      </div>
    </>
  );
};

const Attachment = (props) => {
  const attach = props.img.slice(-3);

  if (
    attach?.toLowerCase().startsWith("j") ||
    attach.toLowerCase().startsWith("p") ||
    attach.toLowerCase().startsWith("gif")
  ) {
    return (
      <Link onClick={() => props.downloadImage(props.img)} to="#">
        <img src={props.img} className="imgs-attached" width="100" />
      </Link>
    );
  } else if (attach?.toLowerCase().startsWith("m")) {
    return (
      <div style={{ width: "100%" }}>
        <video
          controls
          preload="auto"
          style={{ width: "100%" }}
          src={props.img}
        >
          <source src={props.img} type="video/mp4" />
          <p>Your browser does not support the video type</p>
        </video>
      </div>
    );
  } else {
    return (
      <Link onClick={() => props.downloadImage(props.img)} to="#">
        <GrDocumentText size={50} />
      </Link>
    );
  }
};
export default Chatubble;
