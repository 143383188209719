/* eslint-disable node/handle-callback-err */
import React, { useContext, useState } from "react";
import Avatar from "react-avatar";
import { Context } from "src/store";
import { createPost } from "src/requests/PostRequest";
import Loader from "src/components/Loader/Loader";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const ReplyTopic = ({ id, dp, getPosts }) => {
  const [postInput, setPostInput] = useState("");
  const { dispatch } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = get_user_info_from_token();

  const handleInputChange = (e) => {
    setPostInput(e.target.value);
  };

  const handlePost = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      post: {
        content: postInput,
        accessibleBy: 0,
      },
      files: [],
      topicId: id,
    };

    createPost(dispatch, payload)
      .then((res) => {
        setIsLoading(false);
        setPostInput("");
        getPosts();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <div className="comment-form-div">
      {dp === "" || dp === undefined || dp === null ? (
        <Avatar
          round={true}
          name={user.firstName + " " + user.lastName}
          size={50}
        />
      ) : (
        <img src={dp} alt="profile" className="card-div__dp" />
      )}
      <form onSubmit={handlePost}>
        <input
          type="text"
          placeholder="What do you think?"
          value={postInput}
          onChange={handleInputChange}
          className="comment-input"
          required={true}
          autoFocus
        />
        <button className="comment-button" disabled={isLoading}>
          {isLoading ? <Loader color="light" /> : "post"}
        </button>
      </form>
    </div>
  );
};

export default ReplyTopic;
