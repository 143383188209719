import React, { useState, useEffect } from "react";
import { EditorState, convertFromRaw, ContentState } from "draft-js";
import Editor, { createEditorStateWithText } from "draft-js-plugins-editor";
import createMentionPlugin from "draft-js-mention-plugin";
import createHashtagPlugin from "draft-js-hashtag-plugin";
import createLinkifyPlugin from "draft-js-linkify-plugin";
import createEmojiPlugin from "draft-js-emoji-plugin";
import classes from "./EditorBox.module.css";
import mentionsStyles from "./theme.module.css";
import { positionSuggestions } from "./CutomMentionEntry";
import "draft-js-emoji-plugin/lib/plugin.css";
import PropTypes from "prop-types";
// import { ReactTinyLink } from "react-tiny-link";
// import draftjsToHTML from "draftjs-to-html";

const EditorContentDisplay = ({
  readOnly,
  className,
  content,
  style,
  onClick,
  subStringLength,
}) => {
  const [charCount, setCharCount] = useState(subStringLength || null);

  const toggleCharCounter = () => {
    // return subStringLength ? setCharCount(subStringLength) : setCharCount(null);
    if (subStringLength) {
      if (!charCount) {
        setCharCount(subStringLength);
      } else {
        setCharCount(null);
      }
    }
  };

  useEffect(() => {
    if (subStringLength) {
      setCharCount(subStringLength);
    }
  }, [subStringLength]);

  const initializeEditorState = (content) => {
    if (content) {
      if (typeof content === "string") {
        if (content.length > 0) {
          try {
            const incomingContent = JSON.parse(content);

            // if (subStringLength && subStringLength > 0) {
            //   const { blocks } = incomingContent;
            //   const [block] = blocks;
            //   const text = incomingContent.blocks[0].text.substring(
            //     0,
            //     subStringLength
            //   );
            //   const newBlock = {
            //     ...block,
            //     text,
            //   };
            //   incomingContent = { ...incomingContent, blocks: [newBlock] };
            // }

            const type = typeof incomingContent;
            if (type === "object") {
              return EditorState.createWithContent(
                convertFromRaw(incomingContent)
              );
            }
          } catch (error) {
            return createEditorStateWithText(content);
          }
        } else {
          return EditorState.createEmpty();
        }
      } else {
        throw new Error(
          `Expected content of type string but got ${typeof content}`
        );
      }
    } else {
      return EditorState.createEmpty();
    }
  };

  const editorContent = initializeEditorState(content);

  const [editorState, setEditorState] = useState(editorContent);

  // useEffect(() => {
  //   getFirstLink(editorState);
  // }, []);

  useEffect(() => {
    const editorContent = initializeEditorState(content);
    setEditorState(editorContent);
  }, [content]);

  // initialize
  const [mentionPlugin] = useState(
    createMentionPlugin({
      entityMutability: "IMMUTABLE",
      theme: mentionsStyles,
      positionSuggestions,
      mentionPrefix: "@",
      supportWhitespace: true,
      // mentions,
      mentionComponent: (mentionProps) => (
        <span
          className={mentionProps.className}
          // eslint-disable-next-line no-alert
          onClick={() =>
            (window.location.href = `${mentionProps.mention.link}`)
          }
        >
          {mentionProps.children}
        </span>
      ),
    })
  );

  // initialize link
  const linkifyPlugin = createLinkifyPlugin({
    target: "_blank",
    component: (props) => {
      const { contentState, ...rest } = props;
      return (
        <a
          {...rest}
          onClick={() => window.open(props.href)}
          target="_blank"
          style={{ color: "#4a85bb", cursor: "pointer" }}
        />
      );
    },
  });

  const truncate = (editorState, charCount) => {
    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlockMap();

    let count = 0;
    let isTruncated = false;
    const truncatedBlocks = [];
    blocks.forEach((block) => {
      if (!isTruncated) {
        const length = block.getLength();
        if (count + length > charCount) {
          isTruncated = true;
          const truncatedText = block.getText().slice(0, charCount - count);
          const state = ContentState.createFromText(
            `${truncatedText}...     See More`
          );
          truncatedBlocks.push(state.getFirstBlock());
        } else {
          truncatedBlocks.push(block);
        }
        count += length + 1;
      }
    });

    if (isTruncated) {
      const state = ContentState.createFromBlockArray(truncatedBlocks);
      return EditorState.createWithContent(state);
    }

    return editorState;
  };

  // initialize emoji
  const [emojiPlugin] = useState(
    createEmojiPlugin({
      useNativeArt: true,
    })
  );

  // initialize hashtags
  const [hashTagPlugin] = useState(
    createHashtagPlugin({
      theme: classes.hashtag,
    })
  );

  // const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
  // const { MentionSuggestions } = mentionPlugin;
  const plugins = [mentionPlugin, linkifyPlugin, hashTagPlugin, emojiPlugin];

  const _onEditorChange = (editorState) => {
    setEditorState(editorState);
  };

  // const init = (cont) => {
  //   try {
  //     const contentState = JSON.parse(cont);
  //     const markup = draftjsToHTML(JSON.parse(contentState));
  //     return markup;
  //   } catch (e) {
  //     return <p>{cont}</p>;
  //   }
  // };

  const click = () => {
    // onClick();
    toggleCharCounter();
  };

  return (
    <>
      <div className={className} style={style} onClick={click}>
        <Editor
          editorState={
            charCount && charCount > 10
              ? truncate(editorState, charCount)
              : editorState
          }
          // ref={elem => editor === elem}
          onChange={_onEditorChange}
          plugins={plugins}
          readOnly={readOnly}
        />
        {/* {init(content)} */}
      </div>
    </>
  );
};

EditorContentDisplay.defaultProps = {
  // mentions: [],
  readOnly: true,
  className: "",
  content: "",
  onClick: () => {},
};

EditorContentDisplay.propTypes = {
  // mentions: PropTypes.instanceOf(Array),
  readOnly: PropTypes.bool.isRequired,
  className: PropTypes.string,
  content: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  subStringLength: PropTypes.number,
};
export default EditorContentDisplay;
