import React from "react";

import { Button, Spinner } from "reactstrap";
import Swal from "sweetalert2";
// import classes from "./SubmitDocumentModal.module.css";
// import successImg from "src/assets/img/success-tef.png";

const SubmitDocumentModal = ({
  loader,
  handleSubmitForReview,
  isShowButton,
  isEditing,
}) => {
  const popUp = () => {
    Swal.fire({
      title: "Are you sure?",
      text:
        "Please make sure you have uploaded all required documents for this stage",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#da291c",
      cancelButtonColor: "#eddcd2",
      confirmButtonText: "Yes, I have",
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmitForReview();
      }
    });
  };

  return (
    <React.Fragment>
      {isShowButton && (
        <Button onClick={() => popUp()}>
          {isEditing ? "Edit" : "Submit"}
          {loader && (
            <Spinner
              color="#ffffff"
              style={{ height: "1em", width: "1em", marginLeft: "1em" }}
            />
          )}
        </Button>
      )}
      {/* <Modal
        isOpen={modalLaunch}
        toggle={!modalLaunch}
        className={`modal-dialog-centered ${classes.ModalContainer}`}
      >
        <div className={classes.Content}>
          <div>
            <img src={successImg} alt="success" />
          </div>
          <div className="mt-4 mb-4">
            <h5>Successful!</h5>
            <p>
              <small>
                Your documents have been submitted for review <br />
                You will be notified via email of the outcome.
              </small>
            </p>
          </div>
          <Button>Continue</Button>
        </div>
      </Modal> */}
    </React.Fragment>
  );
};

export default SubmitDocumentModal;
