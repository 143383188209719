import { subSectors } from "./experience/options";
import countryAndRegion from "./dump.json";

export const getIndustrySubSector = (filter, lang) => {
  // const filter=()=>{
  //   // const language = localStorage.getItem("i18nextLng");
  //   // const lang =
  //   //   !language || language == null || language === undefined ? "en" : language;

  // }
  const sectorObj = subSectors[lang];
  const iSubSectors = sectorObj[filter] || [];
  return iSubSectors.map((sector) => {
    return { value: sector, label: sector };
  });
};

export const getCountryAlpha2Code = (filter) => {
  const code = countryAndRegion.find((country) => {
    return (
      country.countryName.toLocaleLowerCase() === filter.toLocaleLowerCase() ||
      country.countryShortCode.toLocaleLowerCase() ===
        filter.toLocaleLowerCase()
    );
  });
  return code.countryShortCode || "NG";
};
