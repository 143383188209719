import React from "react";
import classes from "./PollCard_v2.module.scss";

const PollCardButton = ({ value, id, onClick, loading }) => {
  return (
    <button
      value={id}
      onClick={onClick}
      className={classes.pollcardButton}
      disabled={loading}
    >
      {value}
    </button>
  );
};

export default PollCardButton;
