import React, { useState, useContext, useEffect } from "react";
import ShareInput from "src/modules/mentorship/components/ShareInput/ShareInput";
import {
  Col,
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  postQAQuestionComment,
  getQAQuestionComment,
  // getQAQuestionCommentUserLike,
  deleteQAnAPostComment,
  upateQAnAPostComment,
} from "src/requests/TrainingRequest";
import Empty from "src/assets/img/mentorship-empty.png";
import { EditorBox } from "src/components/Editor";

import "./_mainContent.scss";
import QAndAPostCard from "src/modules/training/components/QAndAPostCard";
import { Context } from "src/store";
import { useParams } from "react-router-dom";
import { loader } from "./components";
import { useCurrentUser } from "src/utils/Services/currentUser";
import Loader from "react-loader-spinner";

const QAndAComment = ({ post }) => {
  const [message, setmessage] = useState("");
  const { dispatch } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const [clearContent, setClearContent] = useState(false);
  const { eventId } = useParams();
  const [comments, setComments] = useState([]);
  const currentUser = useCurrentUser();
  const [deletedItems, SetDeletedItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [deletingPost, setDeletingPost] = useState(false);
  const [showUpdateComment, setShowUpdatingComment] = useState(false);
  const [updatingComment, setUpdatingComment] = useState(false);

  const toggleEditComment = () => {
    setShowUpdatingComment(!showUpdateComment);
  };

  // useEffect(() => {
  //   if (selectedDiscussion === discussion.id) {
  //     getDiscussionReplies(dispatch, discussion.id, true);
  //   }
  // }, []);

  useEffect(() => {
    setLoadingComments(true);
    _getQAQuestionComment();
  }, []);

  const _getQAQuestionComment = () => {
    return getQAQuestionComment(dispatch, eventId, post.id).then((res) => {
      const { data } = res;
      data && data.data && setComments(data.data.data);
      setLoadingComments(false);
    });
  };

  const _onChange = (value) => {
    setmessage(value);
  };

  const _submit = () => {
    const replyObj = {
      postId: post.id,
      mentionedUserIds: [],
      content: message,
    };
    setLoading(true);
    setClearContent(true);
    postQAQuestionComment(dispatch, eventId, replyObj).then((res) => {
      // console.log(res.data.data);
      const { status, data } = res;
      if (status === 200 || status === 201) {
        const newComment = data.data;
        newComment.user = {
          imageUrl: currentUser.imageUrl,
          userId: currentUser.userId,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
        };

        const exisitngComments = comments;
        const newComments = [newComment].concat([...exisitngComments]);
        setComments(newComments);
      }
      setLoading(false);
    });
  };

  const _triggerDelete = (post) => {
    setSelectedPost(post);
    toggleDeleteModal();
  };

  const _onDeletePost = () => {
    setDeletingPost(true);
    deleteQAnAPostComment(selectedPost.id, eventId).then(() => {
      const deletedIds = [...deletedItems];
      deletedIds.push(selectedPost.id);
      SetDeletedItems(deletedIds);
      setDeletingPost(false);
      toggleDeleteModal();
    });
  };

  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

  const _onEditClick = (comment) => {
    toggleEditComment();
    setSelectedPost(comment);
    setmessage(comment.content);
  };

  const _upateComment = () => {
    setUpdatingComment(true);
    const payload = {
      postId: post.id,
      mentionedUserIds: [],
      content: selectedPost.content,
    };
    upateQAnAPostComment(selectedPost.id, eventId, payload).then(() => {
      setUpdatingComment(false);
      toggleEditComment();

      const comms = [...comments];
      const commentIdx = comms.findIndex((e) => e.id === selectedPost.id);
      comms[commentIdx].content = payload.content;
      setComments(comms);
      setSelectedPost(null);
    });
  };

  const _handleUpdateCommentChange = (value) => {
    const selectedComment = { ...selectedPost };
    selectedComment.content = value;
    setSelectedPost(selectedComment);
  };

  return (
    <div className="reply-container">
      <div className="reply-container__input">
        <h6>Answers</h6>
        <ShareInput
          value={message}
          onChange={_onChange}
          onSumit={_submit}
          clearContent={clearContent}
          setClearContent={setClearContent}
          // files={[1]}
          loading={loading}
          placeholder="Answer Question"
        />
      </div>
      {loadingComments && <>{loader("20px")}</>}

      <div
        className="reply-container__replies"
        style={{ marginBottom: "50px" }}
      >
        {comments && comments.length > 0 ? (
          comments.map((comment) => {
            if (deletedItems.includes(comment.id)) return null;
            return (
              <QAndAPostCard
                key={comment?.id}
                createdDate={comment?.createdOn}
                likeCount={comment?.likeCount}
                message={comment?.content}
                firstName={comment?.user?.firstName || "Anonymous"}
                lastName={comment?.user?.lastName || ""}
                imageUrl={comment?.user?.imageUrl || ""}
                senderId={comment?.user?.userId || comment?.userId}
                itemId={comment?.id}
                // onLikeClick={() => _likePost(comment.id)}
                eventId={eventId}
                // onReplyClick={() => toggleComment(comment.id)}
                // onUnLikeClick={() => _unlikePost(comment.id)}
                // getLikes={getQAQuestionCommentUserLike}
                showLikes={false}
                onDeleteClick={() => _triggerDelete(comment)}
                onEditClick={() => _onEditClick(comment)}
              />
            );
          })
        ) : (
          <Col
            style={{ textAlign: "center", marginTop: "20px" }}
            md={{ size: 6, offset: 3 }}
          >
            {!loadingComments && (
              <>
                {" "}
                <img src={Empty} />
                <h3 className="goals-heading">
                  Be the first to answer this question
                </h3>
                <br />
                <br />
              </>
            )}
          </Col>
        )}
      </div>

      <>
        <Modal isOpen={showDeleteModal} centered>
          <ModalHeader toggle={toggleDeleteModal}>Are you sure?</ModalHeader>
          <ModalFooter>
            <Button color="primary" outline onClick={toggleDeleteModal}>
              Cancel
            </Button>
            <Button className="d-flex" onClick={_onDeletePost}>
              Proceed{" "}
              {deletingPost && (
                <Loader
                  color="white"
                  type="Oval"
                  height={15}
                  width={15}
                  style={{ marginLeft: "5px" }}
                />
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </>

      <>
        <Modal isOpen={showUpdateComment} centered>
          <ModalHeader toggle={toggleEditComment}>Modify answer</ModalHeader>
          <ModalBody>
            {/* <ShareInput
              value={message}
              onChange={_onChange}
              onSumit={_submit}
              clearContent={clearContent}
              setClearContent={setClearContent}
              // files={[1]}
              loading={loading}
              placeholder="Answer Question"
            /> */}
            <EditorBox
              content={selectedPost && selectedPost.content}
              onEditorChange={_handleUpdateCommentChange}
              // additionalOptionStyle={{ marginTop: "50px" }}
              useEmoji
              // useMentions
              // mentions={[{ link: "line", name: "name" }]}
              styles={{
                borderBottom: "1px solid red",
                minHeight: "50px",
                marginBottom: "10px",
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              outline
              onClick={toggleEditComment}
              disabled={updatingComment}
            >
              Cancel
            </Button>
            <Button
              className="d-flex"
              onClick={_upateComment}
              disabled={updatingComment}
            >
              Submit{" "}
              {updatingComment && (
                <Loader
                  color="white"
                  type="Oval"
                  height={15}
                  width={15}
                  style={{ marginLeft: "5px" }}
                />
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    </div>
  );
};

const Reply = ({ post, selectedPost }) => {
  const [postId, setPostId] = useState(null);
  useEffect(() => {
    post && setPostId(post.id);
  }, []);
  const showReply = () => {
    // const classN = selectedPost === post.id ? "" : "hide-comment";
    return (
      <div>
        <QAndAComment post={post} />
      </div>
    );
  };

  return <>{postId && selectedPost === postId && showReply()}</>;
};

export default Reply;
