/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";

import { Col, Row } from "reactstrap";
import { Context } from "src/store";
import CycleCard from "src/modules/Tef-Dms/components/Cycle/CycleCards";
import ReviewersCommentsModal from "src/modules/Tef-Dms/components/Modals/ReviewersCommentsModal/ReviewersComments";

// import classes from "./Cycle.module.css";

const Cycle = ({ documents, index }) => {
  const [reviewersComments, setReviewersComments] = useState("");

  const { state } = useContext(Context);
  const submittedDocuments = documents.documents;
  const cycleNumber = index + 1;

  const isCycleOpen = (endDate) => {
    const cycleEndDate = new Date(endDate);
    const today = new Date();
    if (today > cycleEndDate) {
      return <span style={{ color: "#DA291C" }}>Closed</span>;
    } else {
      return <span style={{ color: "#DA291C" }}>Open</span>;
    }
  };

  useEffect(() => {
    const reviewerComments =
      state.dms && state.dms.businessPlan.reviewersComment;

    setReviewersComments(reviewerComments);
  }, []);

  return (
    <>
      <Col md={8}>
        <Row className="mt-5">
          <Col md={12}>
            <p style={{ fontSize: "0.75rem", fontWeight: "700" }}>
              {cycleNumber === 1 && (
                <>
                  {cycleNumber}
                  <sup>st</sup>
                </>
              )}
              {cycleNumber === 2 && (
                <>
                  {cycleNumber}
                  <sup>nd</sup>
                </>
              )}
              {cycleNumber === 3 && (
                <>
                  {cycleNumber}
                  <sup>rd</sup>
                </>
              )}
              {cycleNumber > 3 && (
                <>
                  {cycleNumber}
                  <sup>th</sup>
                </>
              )}{" "}
              Cycle - {isCycleOpen(documents.cycleEndDate)}
            </p>
          </Col>
        </Row>
        <Row>
          {submittedDocuments.map((document, index) => (
            <CycleCard document={document} key={index} />
          ))}
        </Row>
      </Col>
      <Col md={4}>
        <Row style={{ marginTop: "50%" }}>
          {/* <ReviewersCommentsModal comments={reviewersComments} /> */}
        </Row>
      </Col>
    </>
  );
};

export default Cycle;
