import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import previewClasses from "./PreviewApplication.module.scss";
import { Row, Col, Button } from "reactstrap";
import { Context } from "src/store";
import Loader from "react-loader-spinner";
import { capitalize_Words, getCountryFromCode } from "src/utils/helper";
import PreviewModal from "./PreviewModal";
import EditApplication from "./EditApplication";
import {
  // sdgs,
  experience,
} from "src/modules/Application/Flagship/personalInfo/experience/options";
// import shortid from "shortid";
import { preViewBtns, bioInfo } from "src/modules/Application/personalInfo";

// import html2PDF from "jspdf-html2canvas";
import JsPDF from "jspdf";
import { useCurrentUser } from "src/utils/Services/currentUser";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { getAllProgrammes } from "src/requests/ProgrammeRequest";
// import { get_user_info_from_token } from "src/utils/someTokenMenthods";
// const language = JSON.parse(localStorage.getItem("language"));

const PreviewApplication = forwardRef(
  ({ application, togglePreview, showValidateBtn }, ref) => {
    const { t } = useTranslation();
    const { lang, applicationId } = queryString.parse(window.location.search);
    let language = lang;
    // const sdgGoals = sdgs[language || "en"];
    const infoObj = bioInfo[language || "en"];
    const experienceObj = experience[language || "en"];
    const [programmeType, setProgrammeType] = useState("flagship");
    const preViewBtn = preViewBtns[lang || "en"];
    // const u = get_user_info_from_token().user;

    const { state, dispatch } = useContext(Context);

    useEffect(() => {
      getAllProgrammes(dispatch);
    }, []);

    // useEffect(() => {
    //   if (toggleEdit && toggleEdit === true) {
    //     setOpenEdit(true);
    //   } else {
    //     setOpenEdit(false);
    //   }
    // }, [toggleEdit]);

    const { programmes } = state.Programmes;

    useEffect(() => {
      if (programmes && programmes.length > 0) {
        const filtered = programmes.find((p) => {
          return p.programmeEvents.find((e) => {
            return e.id === applicationId;
          });
        });

        filtered && setProgrammeType(filtered.programmeType);
      }
    }, [programmes]);

    useEffect(() => {
      language = lang;
    }, [lang]);

    const [openEdit, setOpenEdit] = useState(false);
    const user = useCurrentUser();

    useImperativeHandle(ref, () => ({
      toggleOpenEditFromParent() {
        setOpenEdit(!openEdit);
      },
    }));

    const toggleOpenEdit = (e) => {
      // e.preventDefault();
      setOpenEdit(!openEdit);
      // if (u["2020_applicant"]) {
      //   // togglePreview();
      // }
    };

    const displaySdgs = (data) => {
      return null;
      // const info = { ...data };

      // const SDGS = info.sdgs;
      // const goals = SDGS.map((g) => {
      //   return sdgGoals.find((s) => {
      //     return Number(s.value) === Number(g);
      //   });
      // });
      // // info.sdgs = goals;
      // // return info;

      // if (goals.length > 0) {
      //   return goals.map((goal) => {
      //     return (
      //       <h4 key={shortid.generate()} className={previewClasses.headingh4}>
      //         {goal.label}
      //       </h4>
      //     );
      //   });
      // } else {
      //   return null;
      // }
    };

    // function getBase64Image(img) {
    //   const canvas = document.createElement("canvas");
    //   canvas.width = img.width;
    //   canvas.height = img.height;
    //   const ctx = canvas.getContext("2d");
    //   ctx.drawImage(img, 0, 0);
    //   const dataURL = canvas.toDataURL("image/png");
    //   return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    // }

    const downloadPdf = () => {
      // // alert("downloaded");
      const page = document.getElementById("pdf");
      const heading = document.getElementById("heading");
      heading.style = "color:black;fontSize:35px;marginBottom:30px;";
      // html2PDF(page, {
      //   jsPDF: {
      //     format: "a4",
      //   },
      //   imageType: "image/jpeg",
      //   output: "./pdf/generate.pdf",
      //   success: function (pdf) {
      //     pdf.save(this.output);
      //   },
      // });
      // const imgFile = document.getElementById("idUrl");
      // Image.setAttribute("crossOrigin", "anonymous");

      // const img = new Image();
      // img.src = idUrl;
      // img.crossOrigin = "anonymous";

      // const base64 = getBase64Image(img);

      // return console.log(base64);

      const specialElementHandlers = {
        "#btns": function () {
          return true;
        },
      };

      const jsPDF = new JsPDF();
      // jsPDF.addImage(base64, "image/jpeg", 15, 15, 100, 100);
      jsPDF.fromHTML(
        page,
        15,
        15,
        {
          width: 550, // was 7.5, max width of content on PDF
          elementHandlers: specialElementHandlers,
        },
        () => {
          // return doc.ready;
          jsPDF.save(
            `${user.firstName}_${
              user.lastName
            }_tef_application_form_${new Date().getFullYear()}.pdf`.trim()
          );
          heading.style = "color:white;fontSize:28px;";
        }
      );
    };

    return (
      <div className="notranslate">
        {application ? (
          <div className={previewClasses.main} id="pdf">
            <div className={previewClasses.main__heading}>
              {/* <div className={previewClasses.logoContainer}>
          <img src={logo} color="white" />
        </div> */}
              <h1 className={previewClasses.headingh1} id="heading">
                {infoObj.title1}
              </h1>
            </div>
            <div className={previewClasses.content}>
              <div className={previewClasses.headingRow}>
                <div>
                  <span className={previewClasses.headingSpan}>
                    {preViewBtn.fullAppDetails}
                  </span>
                  <h2 className={previewClasses.title}>
                    {capitalize_Words(
                      `${application.firstName} ${application.lastName} ${application.middleName}`
                    )}
                  </h2>
                </div>
                <div className={previewClasses.modeOfIdentication}>
                  <img
                    src={application.idUrl}
                    alt="idphotot"
                    className="img-fluid"
                    id="idUrl"
                    // crossOrigin="anonymous"
                    // crossOrigin
                  />
                </div>
              </div>
              <div
                className={`${previewClasses.personalInfo} ${previewClasses.info}`}
              >
                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {infoObj.gender}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {capitalize_Words(application.gender)}
                    </h4>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {infoObj.birthday}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {new Date(application.dateOfBirth).toDateString()}
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {infoObj.country}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {capitalize_Words(
                        getCountryFromCode(application.countryId)
                      )}
                    </h4>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {infoObj.state}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.state}
                    </h4>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {infoObj.address}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.address}
                    </h4>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {infoObj.city}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.city}
                    </h4>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {infoObj.area}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.isResidentialRural
                        ? infoObj.yes
                        : infoObj.no}
                    </h4>
                  </Col>

                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {infoObj.typeOfId}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {capitalize_Words(application.typeOfId) || "-"}
                    </h4>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {infoObj.idNumber}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.idNumber}
                    </h4>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {infoObj.phoneNumber}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.phoneNumber}
                      {/* +{application.phoneNumber} */}
                    </h4>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {infoObj.email}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.email}
                    </h4>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {infoObj.education}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.levelOfEducation || "-"}
                    </h4>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {infoObj.ageRange}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.ageRange || "-"}
                    </h4>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {infoObj.applied}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.appliedForOtherFoundationGrant
                        ? "Yes"
                        : "No"}
                    </h4>
                  </Col>

                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {infoObj.ifYes}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.nameOfOrganization || "-"}
                    </h4>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {infoObj.programme}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.howYouHearAboutUs}
                      {/* {capitalize_Words(application.howYouHearAboutUs)} */}
                    </h4>
                  </Col>
                  {/* <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {infoObj.title3}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.kinFirstName || "-"}{" "}
                      {application.kinLastName || "-"}{" "}
                      {application.kinMiddleName || "-"}
                    </h4>
                  </Col> */}
                </Row>

                {/* <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {infoObj.kinEmail}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.kinEmail || "-"}
                    </h4>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {infoObj.kinPhoneNumber}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.kinPhone || "-"}
                    </h4>
                  </Col>
                </Row> */}

                {programmeType.toLowerCase() === "offcycle" && (
                  <Row>
                    <Col sm={12} md={12} lg={12}>
                      <span className={previewClasses.headingSpan2}>
                        {infoObj.motivation}
                      </span>
                      <h4 className={previewClasses.headingh4}>
                        {application.motivation || "-"}
                      </h4>
                    </Col>
                  </Row>
                )}

                {/* mentor details */}
                {/* <h4 className={previewClasses.headingh1}>{infoObj.title4}</h4> */}
                {/* <Row>
                <Col sm={12} md={6} lg={6}>
                  <span className={previewClasses.headingSpan2}>
                    {infoObj.mentorName}
                  </span>
                  <h4 className={previewClasses.headingh4}>
                    {application.mentorFirstName || "-"}{" "}
                    {application.mentorLastName || "-"}{" "}
                    {application.mentorMiddleName || "-"}
                  </h4>
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <span className={previewClasses.headingSpan2}>
                    {infoObj.mentorPhoneNumber}
                  </span>
                  <h4 className={previewClasses.headingh4}>
                    {application.mentorPhone || "-"}
                  </h4>
                </Col>
              </Row> */}

                {/* <Row>
                <Col sm={12} md={6} lg={6}>
                  <span className={previewClasses.headingSpan2}>
                    {infoObj.mentorEmail}
                  </span>
                  <h4 className={previewClasses.headingh4}>
                    {application.mentorEmail || "-"}
                  </h4>
                </Col>

                <Col sm={12} md={6} lg={6}>
                  <span className={previewClasses.headingSpan2}>
                    {infoObj.gender}
                  </span>
                  <h4 className={previewClasses.headingh4}>
                    {application.mentorGender || "-"}
                  </h4>
                </Col>
              </Row> */}
              </div>

              {/* experience */}
              <h1
                className={previewClasses.headingh1}
                style={{ marginTop: "30px" }}
              >
                {experienceObj.title}
              </h1>
              <div
                className={`${previewClasses.experience} ${previewClasses.info}`}
              >
                <Row>
                  <Col sm={12} md={12} lg={12}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.businessIdea}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.aboutIdea}
                    </h4>
                  </Col>
                </Row>
                {/* <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.howManyYears}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.yearsOfStartupExperience}
                    </h4>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.idea}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.isIdeaInFieldOfExperience
                        ? experienceObj.yes
                        : experienceObj.no}
                    </h4>
                  </Col>
                </Row> */}

                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {
                        experienceObj.isBusinessAddressGreenEconomyOrClimateChange
                      }
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.isBusinessAddressGreenEconomyOrClimateChange
                        ? experienceObj.yes
                        : experienceObj.no}
                    </h4>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {
                        experienceObj.businessAddressGreenEconomyOrClimateChangeDescription
                      }
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {
                        application.businessAddressGreenEconomyOrClimateChangeDescription
                      }
                    </h4>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={12} lg={12}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.goals}
                    </span>
                    {/* <h4 className={previewClasses.headingh4}>
                    {application.sdgs}
                  </h4> */}
                    {displaySdgs(application)}
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.businessName}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.businessName}
                    </h4>
                  </Col>

                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.businessLocalGovernmentArea}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.businessLocalGovernmentArea}
                    </h4>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.businessWebsite}
                    </span>
                    {/* <h4 className={previewClasses.headingh4}>
                    {application.sdgs}
                  </h4> */}
                    {application.businessWebsite}
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.businessStage}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.businessStage}
                    </h4>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.isBusinessRegistered}
                    </span>
                    {/* <h4 className={previewClasses.headingh4}>
                    {application.sdgs}
                  </h4> */}
                    {application.isBusinessRegistered
                      ? experienceObj.yes
                      : experienceObj.no}
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.businessRegistrationNumber}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.businessRegistrationNumber}
                    </h4>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.businessLocation}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.businessAddress}
                    </h4>
                  </Col>

                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.industry}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.businessIndustry || "-"}
                    </h4>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.subSector}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.industrySubSector || "-"}
                    </h4>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.businessCountry}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {getCountryFromCode(application.businessCountryId)}
                    </h4>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.businessState}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.businessState || "-"}
                    </h4>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.businessCity}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.businessCity || "-"}
                    </h4>
                  </Col>
                </Row>

                {/* <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.businessAge}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.businessAge}
                    </h4>
                  </Col>

                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.team}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.isWorkingInTeam
                        ? experienceObj.yes
                        : experienceObj.no}
                    </h4>
                  </Col>
                </Row> */}

                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.noOfPeople}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.teamSize}
                    </h4>
                  </Col>
                  {/* <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.workHours}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.hoursPerWeekInvested}{" "}
                    </h4>
                  </Col> */}
                </Row>

                {/* <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.revenue}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.isIdeaProfitable
                        ? experienceObj.yes
                        : experienceObj.no}
                    </h4>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.ifYes}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.monthlyIncome.toLocaleString()}
                    </h4>
                  </Col>
                </Row> */}
                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.businessPartners}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.haveBusinessPartners
                        ? experienceObj.yes
                        : experienceObj.no}{" "}
                    </h4>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.partnerConsent}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.hasBusinessPartnerConsent
                        ? experienceObj.yes
                        : experienceObj.no}
                    </h4>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <span className={previewClasses.headingSpan2}>
                      {experienceObj.rural}
                    </span>
                    <h4 className={previewClasses.headingh4}>
                      {application.isBusinessRural
                        ? experienceObj.yes
                        : experienceObj.no}
                    </h4>
                  </Col>

                  {/* <Col sm={12} md={6} lg={6}>
                  <span className={previewClasses.headingSpan2}>Gender</span>
                  <h4 className={previewClasses.headingh4}>
                    {application.mentorGender || "-"}
                  </h4>
                </Col> */}
                </Row>

                {programmeType.toLowerCase() === "offcycle" && (
                  <Row>
                    <Col sm={12} md={12} lg={12}>
                      <span className={previewClasses.headingSpan2}>
                        {experienceObj.isInternetStable}
                      </span>
                      <h4 className={previewClasses.headingh4}>
                        {application.hasStableInternet
                          ? experienceObj.yes
                          : experienceObj.no}
                      </h4>
                    </Col>
                  </Row>
                )}
              </div>
              <div className={previewClasses.btnBox} id="btns">
                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <Button
                      onClick={toggleOpenEdit}
                      style={{ marginTop: "2px", marginLeft: "5px" }}
                    >
                      {preViewBtn.edit}
                    </Button>
                    {showValidateBtn && (
                      <Button
                        style={{ marginLeft: "5px", marginTop: "2px" }}
                        onClick={togglePreview}
                      >
                        {preViewBtn.validate}
                      </Button>
                    )}
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <Button onClick={() => downloadPdf()}>
                      {t("Download pdf")}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        ) : (
          <Loader type="Oval" height={20} width={20} />
        )}

        <PreviewModal openPreviewModal={openEdit} toggle={toggleOpenEdit}>
          <EditApplication toggleEditModal={toggleOpenEdit} />
        </PreviewModal>
      </div>
    );
  }
);

PreviewApplication.defaultProps = {
  showValidateBtn: true,
};

export default PreviewApplication;
