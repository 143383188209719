import React from "react";

const imageGridGenerator = ({ imageLength, images, handleClick }) => {
  if (imageLength === 1) {
    return (
      <div className="one-image-display">
        <img
          src={images[0].url}
          onClick={() => handleClick(images)}
          alt="post"
          className="image"
        />
      </div>
    );
  } else if (imageLength === 2) {
    return (
      <div className="two-image-display">
        <div className="left">
          <img
            src={images[0].url}
            onClick={() => handleClick(images)}
            className="image"
            alt="post"
          />
        </div>
        <div className="right">
          <img
            src={images[1].url}
            onClick={() => handleClick(images)}
            className="image"
            alt="post"
          />
        </div>
      </div>
    );
  } else if (imageLength === 3) {
    return (
      <div className="three-image-display">
        <div className="left">
          <img
            src={images[0].url}
            onClick={() => handleClick(images)}
            className="image"
            alt="post"
          />
        </div>
        <div className="right">
          <div className="top">
            <img
              src={images[1].url}
              onClick={() => handleClick(images)}
              className="image"
              alt="post"
            />
          </div>
          <div className="bottom">
            <img
              src={images[2].url}
              onClick={() => handleClick(images)}
              className="image"
              alt="post"
            />
          </div>
        </div>
      </div>
    );
  } else if (imageLength === 4) {
    return (
      <div className="four-image-display">
        <div className="left">
          <div className="top">
            <img
              src={images[0].url}
              onClick={() => handleClick(images)}
              className="image"
              alt="post"
            />
          </div>
          <div className="bottom">
            <img
              src={images[1].url}
              onClick={() => handleClick(images)}
              className="image"
              alt="post"
            />
          </div>
        </div>
        <div className="right">
          <div className="top">
            <img
              src={images[2].url}
              onClick={() => handleClick(images)}
              className="image"
              alt="post"
            />
          </div>
          <div className="bottom">
            <img
              src={images[3].url}
              onClick={() => handleClick(images)}
              className="image"
              alt="post"
            />
          </div>
        </div>
      </div>
    );
  } else if (imageLength >= 5) {
    return (
      <div className="five-image-display">
        <div className="top">
          <div className="left">
            <img
              src={images[0].url}
              onClick={() => handleClick(images)}
              className="image"
              alt="post"
            />
          </div>
          <div className="right">
            <img
              src={images[1].url}
              onClick={() => handleClick(images)}
              className="image"
              alt="post"
            />
          </div>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={images[2].url}
              onClick={() => handleClick(images)}
              className="image"
              alt="post"
            />
          </div>
          <div className="middle">
            <img
              src={images[3].url}
              onClick={() => handleClick(images)}
              className="image"
              alt="post"
            />
          </div>
          <div className="right">
            <img
              src={images[4].url}
              onClick={() => handleClick(images)}
              className={`image ${imageLength > 5 && "image-dim"}`}
              alt="post"
            />
            {imageLength > 5 && (
              <div onClick={() => handleClick(images)} className="number-div">
                <p className="number-left">+{imageLength - 5}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return "";
  }
};

export default imageGridGenerator;
