import React, { useState, useEffect, useContext } from "react";
import classes from "./AppointmentsContainer.module.css";
// import './CreateAppointment.css';
import ReceivedEvents from "./ReceivedEvents";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import { DatePickerInput } from "rc-datepicker";
import TimePicker from "react-time-picker";
import { appointmentValidationErrors } from "src/modules/mentorship/Calendar/Helpers/appointmentObj";
import ValidateJs from "src/utils/Validations/validation_wrapper";
import { displayError } from "src/modules/onboarding/helper";
import Loader from "react-loader-spinner";
import {
  getConnections,
  createAppointment,
  getAppointments,
} from "src/requests/MentorRequests";
import { Context } from "src/store";
import SentEvents from "./SentEvents";
import { toast } from "react-toastify";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const AppointmentsContainer = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedMentor, setSelectedMentor] = useState([]);
  const [location, setLocation] = useState("");
  const [onlineEvent, setOnlineEvent] = useState(false);
  const [appointmentStartDate, setAppointmentStartDate] = useState("");
  const [appointmentEndDate, setAppointmentEndDate] = useState("");
  const [appointmentStartTime, setStartTime] = useState("22:00");
  const [appointmentEndTime, setEndTime] = useState("23:00");
  const [modal, setModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState(
    appointmentValidationErrors
  );
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [connections, setConnections] = useState([]);
  const [sentInvitations, setSentInvitations] = useState([]);
  const [receivedInvitations, setReceivedInvitations] = useState([]);
  // const [topSentInvitations, setTopSentInvitations] = useState([]);
  // const [topReceivedInvitations, setTopRecievedInvitations] = useState([]);
  // const [showMore, setShowMore] = useState(false);
  const [reload, setReload] = useState(false);
  const [invite, setInvite] = useState(false);

  const onStartTimeChange = (time) => setStartTime(time);
  const onEndTimeChange = (time) => setEndTime(time);
  const toggle = () => setModal(!modal);

  const { dispatch } = useContext(Context);
  const user = get_user_info_from_token().user;

  // const todayString = new Date().toISOString().split("T")[0];

  const _handleValidationErrors = (target, value) => {
    setValidationErrors({ ...validationErrors, [target]: value });
  };

  useEffect(() => {
    _getConnections();
    _getAppointments();
  }, [reload]);

  const reloadHandler = () => setReload(!reload);

  const _getConnections = () => {
    getConnections(dispatch)
      .then((res) => {
        if (res && res.status === 200) {
          const { data } = res.data;
          setConnections(data);
        }
      })
      .catch((error) => console.log(error));
  };

  const _getAppointments = () => {
    getAppointments(dispatch).then((res) => {
      if (res && res.status === 200) {
        const { data } = res.data;
        // console.log(data);
        const { sentInvitations } = data;
        const { receivedInvitations } = data;
        setReceivedInvitations(receivedInvitations);
        setSentInvitations(sentInvitations);

        // const top3SentInvitations = sentInvitations.slice(0, 3);
        // const top3ReceivedInvitations = receivedInvitations.slice(0, 3);
        // setTopSentInvitations(top3SentInvitations);
        // setTopRecievedInvitations(top3ReceivedInvitations);
      }
    });
  };

  const _createAppointment = (appt) => {
    createAppointment(dispatch, appt).then((res) => {
      setLoadingCreate(true);
      if (res && res.status !== 201) {
        toast(res.message);
      }
      if (res && res.status === 201) {
        setLoadingCreate(false);
        toggle();
        setReload(!reload);
      }
    });
  };

  const _updateAppointment = () => {
    toggle();
  };

  const attachTime = (appointmentDate, time) => {
    const dateWithoutTime = appointmentDate.toISOString().split("T")[0];
    const dateWithTime = `${dateWithoutTime}T${time}`;
    return dateWithTime;
  };
  const submitHandler = () => {
    const appointment = {};
    if (appointmentStartDate !== "" && appointmentEndDate !== "") {
      if (
        !validationErrors.title &&
        !validationErrors.description &&
        !validationErrors.selectMentor &&
        !validationErrors.location &&
        !validationErrors.appointmentStartDate &&
        !validationErrors.appointmentEndDate
      ) {
        appointment.title = title;
        appointment.description = description;
        appointment.invitees = [selectedMentor];
        appointment.link = location;
        appointment.startDate = attachTime(
          appointmentStartDate,
          appointmentStartTime
        );
        appointment.endDate = attachTime(
          appointmentEndDate,
          appointmentEndTime
        );
        appointment.startTime = appointmentStartTime;
        appointment.endTime = appointmentEndTime;
        appointment.isOnline = onlineEvent;

        _createAppointment(appointment);
      }
    }
  };

  return (
    <div className={classes.AppointmentsContainer}>
      <div className={classes.AppointmentHeader}>
        <h2 className={classes.CalendarInvites}>Calendar Invites</h2>
        <div className={classes.InvitationsHeader}>
          <h4
            className={`${classes.Sent} ${invite ? "" : classes.Active}`}
            onClick={() => setInvite(false)}
          >
            Sent Invitations
          </h4>
          <h4
            className={`${classes.Sent} ${invite ? classes.Active : ""}`}
            onClick={() => setInvite(true)}
          >
            Received Invitations
          </h4>
        </div>
        <h2 className={classes.CreateAppointment} onClick={toggle}>
          +Create Appointment
        </h2>
      </div>
      <div className={classes.MyAppointments}>
        {invite ? (
          <ReceivedEvents
            receivedInvitations={receivedInvitations && receivedInvitations}
            reload={reloadHandler}
          />
        ) : (
          <SentEvents
            sentInvitations={sentInvitations && sentInvitations}
            reload={reloadHandler}
            updateAppointment={_updateAppointment}
          />
        )}
        {/* {showMore ? (
          <div className={classes.ShowMore}>
            <div className="show-more-interest-btn btn btn-secondary">
              show more
            </div>
          </div>
        ) : (
          ""
        )} */}
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {user["tef-mentor"]
            ? "Book a mentee appointment"
            : "Book a mentor appointment"}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={submitHandler}>
            <FormGroup>
              <Label for="title">Title of the appointment</Label>
              <Input
                type="text"
                name="title"
                id="title"
                placeholder="Title of Appointment"
                value={title}
                error={validationErrors.title}
                onChange={(e) => setTitle(e.target.value)}
                onBlur={() =>
                  _handleValidationErrors(
                    "title",
                    ValidateJs("appointmentTitle", title)
                  )
                }
              />
              {validationErrors.title && displayError(validationErrors.title)}
            </FormGroup>
            <FormGroup>
              <Label for="description">Description</Label>
              <Input
                type="textarea"
                name="description"
                id="description"
                placeholder="Give a brief overview of what the group is about"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                error={validationErrors.description}
                onBlur={() =>
                  _handleValidationErrors(
                    "description",
                    ValidateJs("appointmentDescription", description)
                  )
                }
              />
              {validationErrors.description &&
                displayError(validationErrors.description)}
            </FormGroup>
            <FormGroup>
              <Label for="selectMentor">
                {user["tef-mentor"] ? "Select a mentee" : "Select a mentor"}
              </Label>
              <Input
                type="select"
                name="selectMentor"
                id="selectMentor"
                value={selectedMentor}
                onChange={(e) => setSelectedMentor(e.target.value)}
                error={validationErrors.selectMentor}
                onBlur={() =>
                  _handleValidationErrors(
                    "selectMentor",
                    ValidateJs("appointmentSelectedMentor", selectedMentor)
                  )
                }
              >
                <option value="">
                  {user["tef-mentor"] ? "Select a mentee" : "Select a mentor"}
                </option>
                {connections &&
                  connections.map((connection) => (
                    <option key={connection.namedId} value={connection.namedId}>
                      {connection && connection.otherDetails.firstName}{" "}
                      {connection && connection.otherDetails.lastName}
                    </option>
                  ))}
              </Input>
              {validationErrors.selectMentor &&
                displayError(validationErrors.selectMentor)}
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="onlineEvent"
                  id="onlineEvent"
                  value={onlineEvent}
                  onChange={(e) => setOnlineEvent(e.target.checked)}
                />{" "}
                <span className={classes.OnlineEvent}>Online Appointment</span>
              </Label>
            </FormGroup>
            <FormGroup>
              <Label for="location">Add Location/Link </Label>
              <Input
                type="text"
                name="location"
                id="location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                error={validationErrors.location}
                onBlur={() =>
                  onlineEvent
                    ? _handleValidationErrors(
                        "location",
                        ValidateJs("appointmentMeetingUrl", location)
                      )
                    : ""
                }
              />
              {validationErrors.location &&
                onlineEvent &&
                displayError(validationErrors.location)}
            </FormGroup>
            <Row>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label for="startDate">Start date</Label>
                  <DatePickerInput
                    readOnly
                    placeholder="MM/DD/YYYY"
                    value={appointmentStartDate}
                    onChange={(date) => setAppointmentStartDate(date)}
                    minDate={new Date()}
                    showOnInputClick
                    autoClose={true}
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label for="endDate">End date</Label>
                  <DatePickerInput
                    readOnly
                    placeholder="MM/DD/YYYY"
                    value={appointmentEndDate}
                    onChange={(date) => setAppointmentEndDate(date)}
                    minDate={new Date()}
                    showOnInputClick
                    autoClose={true}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label for="startTime">Start time</Label>
                  <TimePicker
                    onChange={onStartTimeChange}
                    value={appointmentStartTime}
                    className="timePicker"
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label for="startTime">End time</Label>
                  <TimePicker
                    onChange={onEndTimeChange}
                    value={appointmentEndTime}
                    className="timePicker"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter className={classes.ModalFooter}>
          <Button color="#d8dce6" onClick={toggle} disabled={loadingCreate}>
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={submitHandler}
            disabled={loadingCreate}
          >
            <span style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Book</span>
              {loadingCreate && (
                <span style={{ marginLeft: "2px" }}>
                  {" "}
                  <Loader height={15} width={15} color="white" type="Oval" />
                </span>
              )}
            </span>
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AppointmentsContainer;
