/* eslint-disable no-new */
import React from "react";
import styles from "./Header_v2.module.scss";
import { Link } from "react-router-dom";
import tefLOgo from "src/assets/img/logo/logo_new.webp";
import NotificationNav_v2 from "./NotificationNav/NotificationNav_v2";
import HeaderNav_v2 from "./HeaderNav/HeaderNav_v2";
import { LanguageDropdown } from "src/components/LanguageDropdown/LanguageDropdown";

const Header_v2 = ({ onToggleDrawer }) => {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.left_l1}>
          <Link to="/home">
            <img src={tefLOgo} alt="Logo" className={styles.logo} />
          </Link>
          <HeaderNav_v2 />
        </div>
        <LanguageDropdown />
      </div>
      <div className={styles.right}>
        <NotificationNav_v2 toggleDrawaer={onToggleDrawer} />
      </div>
    </div>
  );
};

export default Header_v2;
