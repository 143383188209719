import React from "react";
import { TabContent, TabPane } from "reactstrap";
import DueDilligence from "src/modules/Tef-Dms/DueDilligence/DueDilligence";
import BusinessPlans from "src/modules/Tef-Dms/BusinessPlans/BusinessPlans";
import SeedCapital from "src/modules/Tef-Dms/SeedCapital/SeedCapital";
import BankDetails from "src/modules/Tef-Dms/BankDetails/BankDetails";
import Certificates from "src/modules/Tef-Dms/Certificates/Certificates";

import "./NavContent.css";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const NavContent = ({
  activeTab,
  handleBuilder,
  isUseBuilder,
  handleUpload,
  modal,
  loader,
  uploadLoader,
  submitDueDilligenceDocumentForReview,
  submitSeedCapitalDocumentForReview,
  submitBusinessPlansDocumentsForReview,
  handleSaveSeedCapitalForLater,
  handleSaveDueDilligenceForLater,
  handleSaveBusinessPlanForLater,
  handleBusinessPlanSelect,
  handleDocumentSelect,
  handleSeedCapitalDocumentSelect,
}) => {
  return (
    <TabContent activeTab={activeTab}>
      <TabPane tabId="1">
        <DueDilligence
          handleDocumentSelect={handleDocumentSelect}
          handleSubmitForReview={submitDueDilligenceDocumentForReview}
          handleSaveForLater={handleSaveDueDilligenceForLater}
          handleUpload={handleUpload}
          modalLaunch={modal}
          loader={loader}
          uploadLoader={uploadLoader}
        />
      </TabPane>
      <TabPane tabId="2">
        <BusinessPlans
          isUseBuilder={isUseBuilder}
          handleBuilder={handleBuilder}
          handleSaveForLater={handleSaveBusinessPlanForLater}
          loader={loader}
          uploadLoader={uploadLoader}
          handleSubmitForReview={submitBusinessPlansDocumentsForReview}
          handleDocumentSelect={handleBusinessPlanSelect}
          handleUpload={handleUpload}
        />
      </TabPane>
      {get_user_info_from_token().user.dms_user_seed_capital && (
        <>
          <TabPane tabId="3">
            <SeedCapital
              handleDocumentSelect={handleSeedCapitalDocumentSelect}
              handleSubmitForReview={submitSeedCapitalDocumentForReview}
              handleSaveForLater={handleSaveSeedCapitalForLater}
              handleUpload={handleUpload}
              modalLaunch={modal}
              loader={loader}
              uploadLoader={uploadLoader}
            />
          </TabPane>
          <TabPane tabId="4">
            <BankDetails />
          </TabPane>
          <TabPane tabId="5">
            <Certificates />
          </TabPane>
        </>
      )}
    </TabContent>
  );
};

export default NavContent;
