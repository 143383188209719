import React from "react";
import styles from "./RequestCard.module.scss";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";

const RequestCard = ({ user, inviteId, accept, cancel }) => {
  const linkTo = `/profile/${user.userId}`;

  return (
    <div className={styles.RequestCard}>
      <div className={styles.RequestCard__image}>
        <Link to={linkTo}>
          {user.imageUrl ? (
            <img
              src={user.imageUrl}
              className={styles.RequestCard__image1}
              alt="group logo"
            />
          ) : (
            <Avatar name={`${user.firstName} ${user.lastName}`} size="100%" />
          )}
        </Link>
      </div>

      <div className={styles.RequestCard__details}>
        <h1 className={styles.RequestCard__name}>
          <Link to={linkTo}>{`${user.firstName} ${user.lastName}`}</Link>
        </h1>

        <div className={styles.RequestCard__buttons}>
          <button
            onClick={() => accept(inviteId)}
            className={`${styles.RequestCard__btn} ${styles.RequestCard__acceptBtn}`}
          >
            admit
          </button>
          <button
            onClick={() => cancel(inviteId)}
            className={` ${styles.RequestCard__btn} ${styles.RequestCard__rejectBtn}`}
          >
            reject
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestCard;
