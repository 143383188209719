import React, { useEffect, useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { toast } from "react-toastify";
import {
  Col,
  Row,
  FormGroup,
  Form,
  Label,
  Button,
  Input,
  Spinner,
} from "reactstrap";
import NewAuthLayout_v2 from "src/Auth/NewLayout/NewAuthLayout_v2";
import { contact_us } from "src/requests/FeedbackRequest";
import classes from "./Contactus_v2.module.scss";
import ContactUsMap from "./Map";

const Contactus_v2 = () => {
  const [inputs, setInputs] = useState({
    email: "",
    fullName: "",
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  console.log(inputs);
  const [loading, setLoading] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { status } = await contact_us(inputs);
      if (status === 200 || status === 201) {
        toast("Thanks for reaching out to us");
        setInputs({ email: "", fullName: "", message: "" });
      }
    } catch (error) {
      toast("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <NewAuthLayout_v2>
      <Row className={classes.contact_us_container}>
        <Col md={6} className={classes.map_container}>
          <ContactUsMap />
        </Col>
        <Col md={6} className={classes.formSection}>
          <h4>Get in touch</h4>
          <p className={classes.msg}>Leave us a message</p>
          <Form className={classes.formContainer} onSubmit={submit}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className={classes.f_label}>Name *</Label>
                  <Input
                    className={classes.f_input}
                    placeholder="Full name"
                    name="fullName"
                    required
                    onChange={handleInputChange}
                    value={inputs.fullName}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className={classes.f_label}>Email *</Label>
                  <Input
                    placeholder="Email"
                    className={classes.f_input}
                    required
                    onChange={handleInputChange}
                    name="email"
                    type="email"
                    value={inputs.email}
                  />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label className={classes.f_label}>Message *</Label>
              <Input
                className={classes.f_input_area}
                type="textarea"
                placeholder="Enter your message"
                // size="158"
                // bsSize="lg"
                onChange={handleInputChange}
                name="message"
                required
                value={inputs.message}
                // style={{ minHeight: "158px" }}
              />
            </FormGroup>
            <Button className={classes.sub_btn} disabled={loading}>
              Send Message{" "}
              {loading && (
                <Spinner
                  color="white"
                  style={{ height: "20px", width: "20px" }}
                />
              )}
            </Button>
          </Form>

          <div className={classes.q_support_container}>
            <h4>Quick Support</h4>
            <p className={classes.msg}>You can contact us directly</p>

            <Row>
              <Col md={4} className={classes.q_support_info}>
                <FaMapMarkerAlt size={24} color="var(--tef-red)" />
                <div>
                  <h5>Visit us</h5>
                  <h6>1, Macgregor Road, Ikoyi, Lagos</h6>
                </div>
              </Col>
              <Col md={4} className={classes.q_support_info}>
                <AiOutlineMail size={24} color="var(--tef-red)" />
                <div>
                  <h5>Email us</h5>
                  <h6>enquires@tonyelumelufoundation.org</h6>
                </div>
              </Col>
              <Col md={4} className={classes.q_support_info}>
                <FiPhone size={24} color="var(--tef-red)" />
                <div>
                  <h5>Call us / Whatsapp us</h5>
                  <h6>{process.env.REACT_APP_WHATSAPP_CONTACT_NUMBER}</h6>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </NewAuthLayout_v2>
  );
};

export default Contactus_v2;
