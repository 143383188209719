import React, { useContext, useEffect } from "react";
import classes from "./MainContent_v2.module.scss";
import NoticeBoard from "./NoticeBoard";
import CreatePost_v2 from "src/modules/homepage/components/CreatePost/CreatePost_v2";
import PostCard_v2 from "src/modules/homepage/components/PostCard/PostCard_v2";
import createPersistedState from "use-persisted-state";
import { Context } from "src/store";
import { getAllPosts, getUserPosts } from "src/requests/PostRequest";
import "./MainContent.scss";
import PageLoader from "src/modules/homepage/components/PageLoader/PageLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "src/components/Loader/Loader";
import ErrorBoundaryComponentLevel from "src/utils/Services/ErrorBoundaryComponentLevel";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { useParams } from "react-router-dom";

const usePostState = createPersistedState("posts");
const usePageDetailsState = createPersistedState("pageDetails");

const MainContent_v2 = ({ pageName, timeline, userId }) => {
  const currentUser = useCurrentUser();
  const params = useParams();

  const uID = params.id;

  let postCards;
  const { state, dispatch } = useContext(Context);

  const [posts, setPost] = usePostState([]);
  const [pageDetails, setPageDetails] = usePageDetailsState({
    firstItemOnPage: 1,
    hasNextPage: true,
    hasPreviousPage: false,
    lastItemOnPage: 0,
    pageCount: 0,
    pageNumber: 1,
    pageSize: 5,
    totalItemCount: 0,
  });

  const fetchPosts = (pageNumber) => {
    timeline
      ? getUserPosts(dispatch, pageNumber, userId)
          .then((post) => {
            if (post.status === 200) {
              const {
                data: {
                  data: { data },
                },
              } = post;
              setPost(data);
            }
          })
          .catch((e) => {
            console.log(e && e.response);
          })
      : getAllPosts(dispatch, pageNumber)
          .then((post) => {
            if (post && post.status === 200) {
              const {
                data: {
                  data: { data, page },
                },
              } = post;
              setPost(data);
              setPageDetails(page);
            }
          })
          .catch((e) => {
            console.log(e && e.response);
          });
  };

  const handleLoadMore = (pageNumber) => {
    timeline
      ? getUserPosts(dispatch, pageNumber)
          .then((post) => {
            if (post && post.status === 200) {
              const {
                data: {
                  data: { data, page },
                },
              } = post;
              const updatedPosts = [...posts];
              updatedPosts.push(...data);
              setPost(updatedPosts);
              setPageDetails(page);
            }
          })
          .catch((e) => {
            console.log(e && e.response);
          })
      : getAllPosts(dispatch, pageNumber)
          .then((post) => {
            if (post.status === 200) {
              const {
                data: {
                  data: { data, page },
                },
              } = post;
              const updatedPosts = [...posts];
              updatedPosts.push(...data);
              setPost(updatedPosts);
              setPageDetails(page);
            }
          })
          .catch((e) => {
            console.log(e && e.response);
          });
  };

  useEffect(() => {
    fetchPosts(1);
  }, [state.Posts.post]);

  if (posts.length > 0) {
    postCards = posts.map((post, index) => {
      return (
        <ErrorBoundaryComponentLevel key={post.id}>
          <PostCard_v2 post={post} index={index} />
        </ErrorBoundaryComponentLevel>
      );
    });
  }

  return (
    <>
      <div className={classes.container} id="post_div">
        <div className={classes.createPostContainer}>
          <NoticeBoard />
          {location.pathname.includes("/profile") &&
          currentUser.userId !== uID ? null : (
            <CreatePost_v2 />
          )}
        </div>

        <section className={classes.postSection} id="post_div">
          {postCards ? (
            <InfiniteScroll
              dataLength={posts.length + 1}
              next={() => handleLoadMore(pageDetails.pageNumber + 1)}
              hasMore={pageDetails.hasNextPage}
              loader={
                <div className="homepage-main-content__loader-container">
                  <Loader color="danger" />
                </div>
              }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
              // scrollableTarget="post_div"
              // inverse
            >
              {postCards}
            </InfiniteScroll>
          ) : (
            <PageLoader />
          )}
        </section>
      </div>
    </>
  );
};

export default MainContent_v2;
