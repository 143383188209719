/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-irregular-whitespace */
import React from "react";
import styles from "./TCmodal.module.scss";
import { Link } from "react-router-dom";

const TCmodal = ({ toggle }) => {
  return (
    <div className={styles.TCMain}>
      <h2 className={styles.TCMain__title}>
        TERMS &amp; CONDITIONS FOR APPLYING AND PARTICIPATING IN THE TONY
        ELUMELU FOUNDATION ENTREPRENEURSHIP PROGRAMME
      </h2>
      <ol className={styles.TCMain__structuredList}>
        <li>
          <span className={styles.TCMain__boldText}>Definitions</span>
          <p>
            The following definitions shall apply unless the context otherwise
            requires:
          </p>
          <p>
            <span className={styles.TCMain__boldText}>“African(s)”</span> means
            African citizens or nationals who may or may not be resident in
            Africa; This includes legal residents of an African country with the
            rights to work and do business in that country.
          </p>
          <p>
            <span className={styles.TCMain__boldText}>“Applicant”</span> means
            an African individual that submits an Application for participation
            in the Programme;
          </p>
          <p>
            <span className={styles.TCMain__boldText}>“Application”</span> means
            the online entry form completed and submitted by an individual on
            TEF Connect to participate in the Programme;
          </p>
          <p>
            <span className={styles.TCMain__boldText}>
              “Application Window”
            </span>
             means the period commencing from 1 January to 1 March when
            Applications can be submitted to participate in the Programme;
          </p>
          <p>
            <span className={styles.TCMain__boldText}>“Business Idea”</span>
             means a proposed business or existing business of not more than 3
            years that is attributable to the Applicant and which the Applicant
            is applying to further develop to a viable business with the
            assistance of the Programme;
          </p>
          <p>
            <span className={styles.TCMain__boldText}>“Business Partner”</span>{" "}
            means any individual or entity with which an Applicant is doing or
            intends to do business whether in the form of an unincorporated
            joint venture, incorporated joint venture, business agreement,
            partnership or such other form of agreement to carry on a business
            together and share in the profits or proceeds arising therefrom;
          </p>
          <p>
            <span className={styles.TCMain__boldText}>“Entity”</span> means a
            company, sole proprietorship, or other legal entity incorporated or
            registered in Africa with an African or Africans together owning
            directly/indirectly more than 50% of the voting or ownership rights
            in the entity;
          </p>
          <p>
            <span className={styles.TCMain__boldText}>“Mentor”</span> means an
            individual selected for their expertise, passion and alignment to
            the Programme’s vision and engaged to support and guide the TEF
            Entrepreneurs on the Programme;
          </p>
          <p>
            <span className={styles.TCMain__boldText}>“Pitching Exercise”</span>{" "}
            means the pitching of Business Ideas to a panel of Judges by
            Shortlisted Entrepreneurs;
          </p>
          <p>
            <span className={styles.TCMain__boldText}>“Programme”</span> or{" "}
            <span className={styles.TCMain__boldText}>
              “TEF Entrepreneurship Programme”
            </span>
             means The Tony Elumelu Foundation Entrepreneurship Programme;
          </p>
          <p>
            <span className={styles.TCMain__boldText}>“Promoter”</span> or{" "}
            <span className={styles.TCMain__boldText}>“the Foundation”</span>
            means The Tony Elumelu Foundation;
          </p>
          <p>
            <span className={styles.TCMain__boldText}>“Seed Capital”</span>
             means funding made available to only Selected Entrepreneurs. The
            first stage is a non-returnable investment of the equivalent of
            $5,000 (based on the Central Bank of Nigeria exchange rate as at the
            date of Selection), which is paid into the Business bank accounts of
            Selected Entrepreneurs; the second stage is a returnable investment,
            which entrepreneurs will apply for and be considered for based on
            the viability of their business.
          </p>
          <p>
            <span className={styles.TCMain__boldText}>
              “Selected Entrepreneurs”
            </span>{" "}
            means Applicants who are selected after the Pitching Exercise to
            receive the Seed Capital;
          </p>
          <p>
            <span className={styles.TCMain__boldText}>
              “Shortlisted Entrepreneurs”
            </span>{" "}
            means Applicants who are shortlisted after the training to
            participate in the Pitching Exercise;
          </p>
          <p>
            <span className={styles.TCMain__boldText}>
              “Startup Enterprise Toolkit”
            </span>{" "}
            means the mandatory 8-week interactive online training programme
            designed to equip Applicants with the necessary skills required to
            launch, run and scale their Business Idea;
          </p>
          <p>
            <span className={styles.TCMain__boldText}>“TEF Alumni”</span> means
            all existing Alumni of the Programme as at 31 December 2019 and from
            1 January 2020, all Selected Entrepreneurs who have completed all
            the Programme learning, submitted a validated business plan and
            received the Seed Capital;
          </p>
          <p>
            <span className={styles.TCMain__boldText}>
              “TEF Alumni Network”
            </span>{" "}
            is the community of TEF Alumni who stay actively connected to the
            Foundation for continuous engagement and opportunities;
          </p>
          <p>
            <span className={styles.TCMain__boldText}>“TEF Connect”</span> is
            the world's largest digital platform, connecting African
            entrepreneurs and the entrepreneurship ecosystem to opportunities
            and resources for business growth developed and managed by the
            Foundation;
          </p>
          <p>
            <span className={styles.TCMain__boldText}>“TEF Entrepreneurs”</span>{" "}
            means all existing TEF Alumni as at 31 December 2019 and from 1
            January 2020, all TEF Graduates, Shortlisted Entrepreneurs and
            Selected Entrepreneurs who have completed key aspects of the
            Programme
          </p>
          <p>
            <span className={styles.TCMain__boldText}>
              “TEF Entrepreneurship Forum”
            </span>{" "}
            means the largest gathering of African entrepreneurs organised by
            the Promoter annually in Nigeria or any location as specified by the
            Promoter, featuring live skills training, keynote speeches and
            networking with investors, influencers, experts and entrepreneurs;
          </p>
          <p>
            <span className={styles.TCMain__boldText}>“TEF Graduates”</span>{" "}
            means those Applicants who have completed the Startup Enterprise
            Toolkit and been awarded a training certificate.
          </p>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>Terms and Conditions</span>
          <ol className={styles.TCMain__structuredList}>
            <li>
              Applicants shall be subject to these Terms & Conditions, the
              Participation Agreement and other rules and guidelines published
              by the Foundation from time to time for the running of the
              Programme. By submitting an application to participate in this
              Programme, all Applicants will be deemed to have accepted to be
              bound accordingly.
            </li>
            <li>
              All Applicants must sign a Participation Agreement before
              commencing the Programme. The signed Agreement must be uploaded
              within 5 working days of notification, otherwise the application
              lapses.
            </li>
            <li>
              The Promoter reserves the right to cancel or amend all or any part
              of these Terms & Conditions without notice. Any changes will be
              posted on the Programme’s website. It is the responsibility of all
              Applicants and TEF Entrepreneurs to keep themselves informed as to
              any changes to the Terms & Conditions. The Promoter shall not be
              liable for any loss suffered by an Applicant or a TEF Entrepreneur
              due to any changes to the Terms & Conditions, including failure to
              complete some or all tasks under the Programme due to such change.
            </li>
            <li>
              If for any reason the Programme is not capable of running as
              planned for reasons including but not limited to tampering,
              unauthorised intervention, fraud, technical failures, force
              majeure events or any other causes beyond the control of the
              Promoter which corrupt or affect the administration, security,
              fairness, integrity or proper conduct of this Programme, the
              Promoter reserves the right to cancel, terminate, modify or
              suspend the Programme and disqualify any Applicant or TEF
              Entrepreneur. The Promoter shall not be liable for any loss
              arising from modification, suspension or cancellation of the
              Programme.
            </li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>Application</span>
          <ol className={styles.TCMain__structuredList}>
            <li>
              Applicants are not required to make any purchase or payment in
              order to access TEF Connect, submit an Application or become
              eligible for the Programme. For the avoidance of doubt, the
              Programme does not include any costs of a personal nature not
              explicitly set out in these Terms and Conditions.
            </li>
            <li>
              Applicants must certify that they have all the applicable rights,
              licenses, authorisations and consents necessary to submit their
              Business Ideas or Entity. Presenting a Business Idea that the
              Applicant has no applicable rights, licenses, authorisations and
              consents to shall be a ground for disqualification.
            </li>
            <li>
              Only one Application, Business Idea or Entity is permitted per
              Applicant. An Applicant shall not make multiple entries under
              different names, email identifications, different Business Ideas
              or Entities. Multiple entries by an Applicant or multiple entries
              for the same Entity shall be a ground for disqualification.
            </li>
            <li>
              TEF Entrepreneurs or their Business Partners shall not reapply for
              the Programme in any subsequent year, even for a different
              Business Idea or Entity – this constitutes multiple applications
              and is grounds for disqualification, and termination of Alumni
              status.
            </li>
            <li>
              All submissions must be made directly by the Applicant behind the
              Businesses Idea or Entity entering the Programme. Entries made
              online using methods such as a macro, script, using automated
              devices or processors are not allowed and shall be disqualified.
            </li>
            <li>
              The Promoter accepts no responsibility for late, lost, misdirected
              damaged or delayed Applications as a result of any network,
              computer hardware or software failure of any kind.
            </li>
            <li>Bulk and third-party entries shall not be permitted.</li>
            <li>
              The Promoter reserves the right to verify the validity of entries
              and to disqualify any Applicant for tampering with the entry
              process or failing to make entries as specified by the Terms &
              Conditions.
            </li>
            <li>
              No corruption of, defect in, failure or delay in the delivery of
              any email, text or other form of communication to or from the
              Promoter will entitle an Applicant to an extension of any
              deadline, or otherwise entitle an Applicant to make any form of
              claim
            </li>
            <li>
              By applying for this Programme, each Applicant attests that their
              Application:
              <ol className={styles.TCMain__structuredList}>
                <li>Is true and correct in all material respects;</li>
                <li>Is their own original work;</li>
                <li>
                  Does not infringe or violate the rights of any third party,
                  including but not limited to, ownership, copyrights,
                  trademarks, patents, logos, licensing rights, rights of
                  publicity or privacy or any other intellectual property
                  rights.
                </li>
                <li>Is not contrary to any applicable laws.</li>
              </ol>
            </li>
            <li>
              The Promoter shall reject applications which, in the reasonable
              opinion of the Promoter:
              <ol className={styles.TCMain__structuredList}>
                <li>
                  Are deemed to be franchises, import/export business or
                  government contractors;
                </li>
                <li>
                  Contain any content that is likely to be considered offensive
                  by the Promoter, or could reflect negatively the name,
                  reputation, or goodwill of the Promoter or entities associated
                  with the Promoter and other parties acting on its behalf;
                </li>
                <li>
                  Include trademarks, logos, or copyrighted material not owned
                  by the Applicant or used without the right holder’s prior
                  written permission (including famous names, company names,
                  etc.);
                </li>
                <li>Defames, misrepresents, or insults third parties;</li>
                <li>
                  Poses adverse risks to the environment, human lives or
                  property;
                </li>
                <li>Promotes or is sympathetic to any political agenda;</li>
                <li>Are affiliated to terrorist or other illegal activity;</li>
                <li>Ought to be disqualified.</li>
              </ol>
            </li>
            <li>
              By applying, Applicants acknowledge that other Applicants may have
              created ideas and concepts that may be similar to their entry, and
              that Applicants will not be entitled to any compensation or right
              to negotiate with the Promoter over such matters.
            </li>
            <li>
              The Applicants are responsible for ensuring they are able to
              participate in the Programme as set out in these Terms &
              Conditions. They must also (in the case of
              companies/partnerships/co-ownerships/joint ventures) provide proof
              of consent from partners, directors and shareholders for their
              participation as representatives of an Entity.
            </li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>Eligibility</span>
          <ol className={styles.TCMain__structuredList}>
            <li>
              Due Diligence shall be carried out on Applicants to verify
              eligibility prior to advancing past certain stages in the
              Programme. Applicants that do not meet all or some of the
              eligibility criteria will not be eligible to move further in the
              Programme
            </li>
            <li>
              The Programme is open to all Africans operating businesses in
              Africa who are aged 18 years and above, and who must not be
              considered a minor in their country of residence.
            </li>
            <li>
              Applicants must have the legal right to work in the African
              country where their business is located.
            </li>
            <li>
              Applicants must create an account and complete an online entry
              form through the TEF Connect to apply for the Programme.
            </li>
            <li>
              The proposed business must operate in Africa and Applicants must
              be legal residents of any one of the 54 African countries.
            </li>
            <li>
              All businesses must be Business Ideas or an early stage registered
              entity, in the range of 0 to 3 years from date operations
              commenced.
            </li>
            <li>
              Business Ideas must be for profit, focused on one business only
              and must be the original work of those making the submission.
            </li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>
            Ineligibility/Disclosure of Conflict of Interest
          </span>
          <ol className={styles.TCMain__structuredList}>
            <li>
              Employees from either the Tony Elumelu Foundation, companies
              within the Heirs Holdings (HH) Group, affiliates of HH and their
              immediate family members (parents, siblings and spouses), are not
              eligible to apply for the Programme, either individually or as
              part of a team or Entity.
            </li>
            <li>
              Research institutions, faith-based organisations, government
              agencies and businesses not located in Africa or are over three
              years old will not be eligible to apply for the Programme.
            </li>
            <li>
              The Promoter’s consultants and agents involved in the creation and
              administration of this Programme are excluded from participation.
            </li>
            <li>
              Staff and immediate family members of Partners may apply however,
              they can only receive Seed capital from the Foundation’s funding
              contribution and not from their employer’s funding contribution.
            </li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>
            The Business/Business Idea
          </span>
          <ol className={styles.TCMain__structuredList}>
            <li>
              Applicants must demonstrate that their Business Idea is or will be
              set up and based in Africa.
            </li>
            <li>
              Applicants are solely responsible for the structure of their
              business and the team members within it. If selected to receive
              Mentoring, the assigned Mentor to the Applicant can provide
              guidance regarding team relations and individual roles within the
              business but are not responsible for dispute resolution. The
              Promoter will not be responsible for or get involved in any team
              dispute resolution and shall communicate only with the person
              authorised to represent a team or Entity until it receives an
              instruction from authorised representatives nominating a different
              person.
            </li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>Application Process</span>
          <ol className={styles.TCMain__structuredList}>
            <li>
              All Applications must be submitted online through the TEF Connect,
              which will open from 1 January and close on 1 March, annually (the
              <span className={styles.TCMain__boldText}>
                “Application Window”
              </span>
              ).
            </li>
            <li>
              The Applicants must complete an application form by answering a
              series of mandatory questions, upload required documents and
              identification materials. Personal information supplied in the
              application form such as names, addresses and date of birth must
              tally with identification documents provided.
            </li>
            <li>
              The Applicant must provide current correspondence address, email
              and telephone numbers and keep the Promoter informed of any
              changes.
            </li>
            <li>
              Upon submission of the Application, an email confirming receipt
              will be sent to the Applicant within 1 business day. Once
              submitted, Applications cannot be altered or amended.
            </li>
            <li>
              Review of applications will commence immediately, and Applicants
              will be categorised into the most suitable training modules, based
              on their application. Notification of allocated training module
              will take place following the close of the Application Window on 1
              March.
            </li>
            <li>
              The categorisation will be carried out by the Promoter using
              computer algorithms based on predetermined categorisation
              criteria.
            </li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>
            The Programme, Participation & Disqualification
          </span>
          <ol className={styles.TCMain__structuredList}>
            <li>
              A Programme cycle equates to (i) completing the application
              process; (ii) completing the assigned Startup Enterprise Toolkit
              training, evidenced by the receipt of a training certificate and
              becoming a TEF Graduate; (iii) where shortlisted, developing a
              business plan and participating in the Pitching Exercise; (iv)
              where selected, participating in the TEF Entrepreneurship Forum in
              Nigeria; and (v) receipt of the Seed Capital funding.
            </li>
            <li>
              Only one representative from each business will have access to the
              TEF Connect to undergo the allocated training and to participate
              physically in the Pitching Exercise (where shortlisted). However,
              it will be possible to share the training points with business
              partners to institutionalise our training to support the success
              of the business.
            </li>
            <li>
              Upon completion of the Startup Enterprise Toolkit training,
              Applicants will be scored and ranked. The top-ranking Applicants
              will be shortlisted to participate in the Pitching Exercise where
              the Shortlisted Entrepreneurs will pitch their Business Ideas to a
              panel of Judges.
            </li>
            <li>
              The selection of successful Entrepreneurs from the Pitching
              Exercise will be carried out by the Promoter, the panel of Judges
              and an independent application review consultant appointed by the
              Promoter, using specified selection criteria. These criteria are:
              <ol className={styles.TCMain__structuredList}>
                <li>
                  Feasibility: content of the Business Idea. A good business
                  model that has clear and compelling mission to grow a
                  sustainable, commercially viable business and is effectively
                  communicated;
                </li>
                <li>
                  Market Opportunity: knowledge and understanding of the market,
                  customers and competitors for their idea/business;
                </li>
                <li>
                  Financial Understanding: understanding of the basic financial
                  requirements of running a business, costs and revenues;
                </li>
                <li>
                  Scalability: Demonstrates potential for replication and growth
                  of their product or service to create jobs and wealth;
                </li>
                <li>
                  Leadership Potential and Entrepreneurial Skills: Applicant has
                  demonstrated leadership potential, capable of attracting
                  people, customers and resources. Applicant also exhibits
                  strong passion and commitment for the business.
                </li>
              </ol>
            </li>
            <li>
              Selected Entrepreneurs would be announced at the TEF
              Entrepreneurship Forum and on the website and also notified via
              the contact method indicated in their application.
            </li>
            <li>
              The Promoter will select at least 1000 Applicants annually to
              receive Seed Capital. funding. The disbursement of the first stage
              Seed Capital funding will be by payment into the Selected
              Entrepreneur’s business bank account. The Promoter is unable to
              pay the funding into a personal bank account. To receive the Seed
              Capital, all Selected Entrepreneurs must comply with all
              stipulated requirements, including provision of certain documents
              and opening of bank accounts in specified banks.
            </li>
            <li>
              The first stage Seed Capital will be paid based on the Naira
              equivalent value of $5,000 converted at the official Central Bank
              of Nigeria exchange rate as at the date of selection for the
              Programme. Banking charges and recipient country exchange rates
              will also apply. The actual amount received may therefore be less
              than US$5,000.
            </li>
            <li>
              There will be a waiting list made up of Shortlisted Entrepreneurs
              who have points closest to the Selected Entrepreneurs. Funding
              slots which subsequently become available shall be filled from the
              waiting list (buffer). Shortlisted Entrepreneurs who are on the
              waiting list will not be informed that they are on a waiting list
              until they receive an offer due to a slot becoming available.
            </li>
            <li>
              The Selected Entrepreneurs that have progressed their business
              plans sufficiently may also apply for the second stage capital
              investment. The specific terms of qualification and application
              shall be made available at the relevant time.
            </li>
            <li>
              <span className={styles.TCMain__boldText}>
                Participation & Disqualification
              </span>
              All TEF Entrepreneurs must be available to commit to relevant
              aspects of the annual Programme cycle. Any TEF Entrepreneur who is
              not committed to the time frame to be advised by the Promoter will
              be disqualified from the Programme.
            </li>
            <li>
              Communication with TEF Entrepreneurs shall be by email to the
              address provided on the Application form, and via TEF Connect. TEF
              Entrepreneurs are therefore advised to store the Promoters contact
              details in their email address books to avoid the email from being
              delivered to their spam folder. The Promoter shall not be
              responsible for the reliability or otherwise, delayed receipt or
              non-receipt of any email communication.{" "}
            </li>
            <li>
              If a TEF Entrepreneur fails to (a) complete the tasks assigned in
              the Programme within the stipulated timelines (b) put in the
              minimum required number of contact hours with their assigned
              Mentors, or (c) submit the business plan within the review
              timeline and participate in the Pitching Exercise (if
              shortlisted), the Promoter reserves the right to disqualify such
              TEF Entrepreneur from the Programme.
            </li>
            <li>
              Any TEF Entrepreneur who does not fulfil all the commitment
              requirements will not be awarded the Seed Capital or other
              on-going support, as applicable. Access to programme materials and
              Mentors will also be denied to a TEF Entrepreneur who drops off at
              any stage before the completion of the Programme without obtaining
              the consent of the Promoter. The Promoter also reserves the right
              to disqualify such TEF Entrepreneur.{" "}
            </li>
            <li>
              Request for any proposed long-term absence from the Programme must
              be agreed to by the Promoter in writing prior to the TEF
              Entrepreneur taking the time out of the Programme and shall only
              be allowed in extraordinary circumstances. Requests shall however
              be assessed by the Promoter on a case-by-case basis.
            </li>
            <li>
              If the Promoter deems that any TEF Entrepreneur has brought the
              Programme and the Promoter into disrepute, their funding will be
              withheld and placement in the Programme will be immediately
              terminated.
            </li>
            <li>
              The Promoter reserves the right, in its absolute discretion to
              disqualify any TEF Entrepreneur at any point during the Programme,
              without granting any opportunity for challenge, if it has
              reasonable grounds to believe that:
              <ol className={styles.TCMain__structuredList}>
                <li>
                  The Application was made fraudulently, or contains any false
                  or misleading statement;
                </li>
                <li>
                  The TEF Entrepreneur has breached any of the Terms &
                  Conditions or the Participation Agreement;
                </li>
                <li>
                  The TEF Entrepreneur has infringed on any intellectual
                  property of any other person;
                </li>
                <li>
                  TEF Entrepreneur made an Application in breach of any
                  applicable law or the application process; or
                </li>
                <li>
                  It is in the interest of the Programme to disqualify such TEF
                  Entrepreneur.
                </li>
              </ol>
            </li>
            <li>
              By entering, TEF Entrepreneurs agree to participate in any
              reasonable publicity arranged by the Promoter e.g. photo shoots,
              videos and interviews and waive their rights to any images of
              themselves taken as a result of applying or being selected under
              the Programme. The Promoter shall be entitled to use all such
              photographs, films, audio or other recording in any medium
              including digital, electronic, print, television, film, radio or
              other media in furtherance of promotion of the Programme or the
              Promoter or its affiliates. Where necessary, the Promoter may
              require a signed image rights waiver form from the TEF
              Entrepreneur in furtherance of this waiver. The Promoter may
              invite TEF Entrepreneurs to take part in promotional activities at
              the Promoter’s expense.
            </li>
            <li>
              TEF Entrepreneurs are not permitted to speak or write publicly
              about the internal workings of the process or to purport to be
              knowledgeable of the reasons why the Promoter has selected and
              supported them.
            </li>
            <li>
              TEF Entrepreneurs are expected to proactively engage with the
              Promoter (while not imposing on work commitments) in terms of
              marketing communications activity to amplify their experience
              throughout the Programme.
            </li>
            <li>
              The Promoter and any person acting on its behalf will not be
              responsible for any damage, loss, injury suffered by any entrant
              entering the Programme. Nothing shall exclude the Promoter’s
              liability for death or personal injury as a result of its
              negligence.
            </li>
            <li>
              If for any reason the Programme is not capable of running as
              planned due to reasons beyond the reasonable control of the
              Promoter, the Promoter shall not be liable for failures to comply
              with these Terms & Conditions and shall endeavour to communicate
              with all affected parties at the earliest possible opportunity.
            </li>
            <li>
              The decision of the Promoter shall be final, and binding and no
              correspondence will be entered into on the same.
            </li>
            <li>
              The Promoter assumes no responsibility for damages, losses or
              injuries that result from participation in this Programme or
              acceptance to be part of the Programme.
            </li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>
            Travel and Visa expenses
          </span>
          <ol className={styles.TCMain__structuredList}>
            <li>
              Some TEF Entrepreneurs may wish to travel to attend the yearly TEF
              Entrepreneurship Forum upon receipt of invitation from the
              Promoter to attend. All costs relating to travel and where
              applicable, obtaining a visa to attend the Forum shall be borne by
              such TEF Entrepreneur save where the Foundation elects to fund
              such travel costs.
            </li>
            <li>
              TEF Entrepreneurs from Non-ECOWAS countries (with few exceptions)
              will be required to obtain a Nigerian Visa to enable them attend
              the TEF Entrepreneurship Forum. The Promoter shall provide an
              invitation letter and will liaise with TEF Entrepreneurs to ensure
              they have all documentation and information required to enable the
              process of obtaining a visa. Such TEF Entrepreneurs shall however
              have sole responsibility for applying for and securing their
              Nigerian Visas. Attending TEF Entrepreneurs are therefore advised
              to commence the visa application process immediately they confirm
              attendance to the TEF Entrepreneurship Forum or at least 4-6 weeks
              prior to the date.
            </li>
            <li>
              Attending TEF Entrepreneurs are responsible for and must comply
              with any health advice/regulations/inoculations required by
              respective African Countries. Any associated costs for this are
              the responsibility of the TEF Entrepreneur.
            </li>
            <li>
              The Promoter will not be liable for any injury, loss or damage to
              property or life arising from attending the TEF Entrepreneurship
              Forum provided that such injury, loss or damage did not arise from
              the negligence of the Foundation. Each attending TEF Entrepreneur
              is responsible for ensuring their safety during the TEF
              Entrepreneurship Forum. The Promoter shall not be liable for any
              loss arising from attending TEF Entrepreneurs leaving the TEF
              Entrepreneurship Forum venue or for engaging in any activity
              outside the activities designated by the Promoter
            </li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>Privacy Policy</span>
          <ol className={styles.TCMain__structuredList}>
            <li>
              By applying to the Programme, Applicants consent to have details
              of their Application – other than commercially sensitive
              information – featured in any media or promotional activity for
              the Programme being carried out by the Promoter. The Promoter will
              contact TEF Entrepreneurs in advance of any media request for
              interviews. TEF Entrepreneurs shall not grant interviews
              independently of the Promoter and are required to inform the
              Promoter of any media request for interviews they receive
              directly. Failure to notify the Promoter may result in
              disqualification from the Programme.
            </li>
            <li>
              The Promoter will only use the personal details supplied for the
              administration of this Programme, and for no other purpose except
              with the Applicant’s consent. Our detailed privacy policy, which
              is also applicable to all TEF Entrepreneurs can be accessed here{" "}
              <Link
                target="_blank"
                to="/privacy"
                className={styles.TCMain__redText}
              >
                Privacy Policy
              </Link>
              .
            </li>
            <li>
              The Mentors have a non- disclosure obligation as it relates to
              personal information which may come into their possession during
              the Programme and are committed to maintaining the highest ethical
              standards.
            </li>
            <li>
              The name, country and profile of each Applicant will be made
              available for public relations purposes globally. Each individual
              will be asked to provide a profile and photo once they join the
              Programme and this will be held on the closed group Programme
              website, as well as the Promoter’s public website.
            </li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>General</span>
          <ol className={styles.TCMain__structuredList}>
            <li>
              {" "}
              Internet access and adequate English language skills are required
              for the Application process. Candidates with no English reading or
              speaking skills need to translate the application content at own
              cost.
            </li>
            <li>
              All Applicants are required to respond promptly to requests for
              information from the Promoter.
            </li>
            <li>
              This Programme is governed by the laws of the Federal Republic of
              Nigeria and Applicants submit to the exclusive jurisdiction of the
              Nigerian Courts.
            </li>
          </ol>
        </li>
      </ol>
      <div className={styles.TCMain__btn}>
        <Link className={styles.TCMain__rejectBtn} to="/programme/dashboard">
          reject
        </Link>
        <button className={styles.TCMain__acceptBtn} onClick={toggle}>
          accept
        </button>
      </div>
    </div>
  );
};

export default TCmodal;
