import React, { useEffect } from "react";
import SideBar from "src/modules/Application/components/Layout/SideBar/SideBar";
import styles from "./Content.module.scss";
// import RightSideBar from "src/modules/Application/components/Layout/RightSideBar/RightSideBar";

const Content = ({
  hideRightSideBar,
  withoutRIghtSideBar,
  rightSideContent,
  title,
  children,
}) => {
  useEffect(() => {
    const content_container = document.getElementById("app_content_container");
    if (content_container) {
      content_container.scrollIntoView();
    }
  }, []);
  return (
    <div className={styles.Content}>
      <SideBar />
      <div
        className={`${
          withoutRIghtSideBar
            ? styles.Content__mainContainer_withoutSideRight
            : styles.Content__mainContainer
        }`}
        id="app_content_container"
      >
        {title && (
          <h1 className={styles.Content__mainContainerTitle}>{title}</h1>
        )}
        {children}
      </div>
      {/* <RightSideBar hide={hideRightSideBar}>{rightSideContent}</RightSideBar> */}
    </div>
  );
};

export default Content;
