import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import AllChatsTab from "./ChatTabs/AllChatsTab";
import PersonalChatTab from "./ChatTabs/PersonalChatTab";
import GroupChatTab from "./ChatTabs/GroupChatTab";
import classnames from "classnames";
import classes from "./ChatList.module.scss";

const ChatList = ({
  chatBookmarks,
  switchDefaultActiveChat,
  curentChats,
  personalChat,
  groupChat,
  switchDefaultGroupChat,
  mobile,
  bookmarkChatPageDetails,
  handleLoadMore,
}) => {
  const allChatBookmarks = [...chatBookmarks];
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className={`${classes["chats-tab"]} ${mobile}`}>
      <Nav tabs className="border-0 px-2">
        <NavItem
          className={`${activeTab === "1" ? classes["active-nav"] : ""}
          ${classes["nav-item"]}`}
        >
          <NavLink
            className={`${classnames({ active: activeTab === "1" })} 
            ${classes["custom-tab"]}`}
            onClick={() => {
              toggle("1");
            }}
          >
            All Chats
          </NavLink>
        </NavItem>
        <NavItem
          className={`${activeTab === "2" ? classes["active-nav"] : ""} 
          ${classes["nav-item"]}`}
        >
          <NavLink
            className={`${classnames({ active: activeTab === "2" })} 
            ${classes["custom-tab"]}`}
            onClick={() => {
              toggle("2");
            }}
          >
            Personal Chat
          </NavLink>
        </NavItem>
        <NavItem
          className={`${activeTab === "3" ? classes["active-nav"] : ""}
          ${classes["nav-item"]}`}
        >
          <NavLink
            className={`${classnames({ active: activeTab === "3" })} 
            ${classes["custom-tab"]}`}
            onClick={() => {
              toggle("3");
            }}
          >
            Group Chat
          </NavLink>
        </NavItem>
      </Nav>
      <div className={classes["chat-tab__content"]}>
        <TabContent activeTab={activeTab}>
          {/* all chats tab */}
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <AllChatsTab
                  allChatBookmarks={allChatBookmarks}
                  switchDefaultActiveChat={switchDefaultActiveChat}
                  groupChat={groupChat}
                  personalChat={personalChat}
                  bookmarkChatPageDetails={bookmarkChatPageDetails}
                  handleLoadMore={handleLoadMore}
                />
              </Col>
            </Row>
          </TabPane>
          {/* personal chat tab */}
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <PersonalChatTab
                  personalChat={personalChat}
                  switchDefaultActiveChat={switchDefaultActiveChat}
                />
              </Col>
            </Row>
          </TabPane>
          {/* group chat */}
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <GroupChatTab
                  groupChat={groupChat}
                  switchDefaultGroupChat={switchDefaultGroupChat}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default ChatList;
