/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */

import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Button,
  Card,
  Badge,
  Form,
  Modal,
  ModalBody,
  FormGroup,
} from "reactstrap";
import { Context } from "src/store";
import StepWizard from "react-step-wizard";
import Countdown, { zeroPad, calcTimeDelta } from "react-countdown";
import {
  getMcqQuestions,
  submitAssessment,
  verifyResult,
} from "src/requests/ApplicationRequest";
import moment from "moment";
import queryString from "query-string";
import Cloader from "src/modules/Application/components/CLoader";
import ApplicationProgressReport from "src/modules/Application/components/ApplicantProgressReport/ProgressReport";

import { sectionFourTranslations } from "src/modules/Application/instructions";
import InstructionModalImg from "src/assets/img/instruction-illustration.png";
import { useTranslation } from "react-i18next";
import { returnTranslationOption } from "src/modules/Application/Offcycle/Declaration/DeclarationTranslations";
import Layout from "src/modules/Application/components/Layout/Layout";

const CognitiveTest = (props) => {
  const { t } = useTranslation();
  const initialState = {
    answers: [],
  };
  // if (!localStorage.mcq || localStorage.mcq === undefined) {
  //   localStorage.mcq = "[]";
  // }

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const [levelModal, setLevelModal] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [answers, setAnswers] = useState(initialState.answers);
  const [instructionModal, setInstructionModal] = React.useState(true);
  const prevAnswer = usePrevious({ answers, setAnswers });
  const { state, dispatch } = useContext(Context);
  const query = props.history.location.search;
  const { applicationId, endDate, lang, title } = queryString.parse(
    props.history.location.search
  );
  const language = lang || "en";
  const declarationTranslations = returnTranslationOption()[language];

  function compare(arr1, arr2) {
    if (!arr1 || !arr2) return;

    let result;

    arr1.forEach((e1, i) =>
      arr2.forEach((e2) => {
        if (e1.length > 1 && e2.length) {
          result = compare(e1, e2);
        } else if (e1 !== e2) {
          result = false;
        } else {
          result = true;
        }
      })
    );

    return result;
  }

  useEffect(() => {
    if (answers && answers.length > 0) {
      if (
        localStorage.mcq &&
        compare(JSON.parse(localStorage.mcq), answers) === false
      ) {
        updateLocalStorage();
      }
    }
  }, [answers]);

  const _onContinueClick = () => {
    return props.history.push({
      pathname: `/application/offcycle/declaration`,
      search: `?applicationId=${applicationId}&endDate=${endDate}&lang=${language}&title=${title}`,
    });
  };
  useEffect(() => {
    if (query) {
      const parsedQuery = queryString.parse(query);
      if (Object.keys(parsedQuery).length === 0 || !parsedQuery.applicationId) {
        props.history.replace("/programme/dashboard", {});
      }
    } else {
      props.history.replace("/programme/dashboard", {});
      props.history.go(1);
    }
    setLoader(false);
  }, [props.history.location.search]);

  let date;
  if (localStorage.date) {
    date = calcTimeDelta(JSON.parse(localStorage.date));
  }
  const startAssessment = () => {
    localStorage.start = true;
    setInstructionModal(!instructionModal);
  };
  useEffect(() => {
    verifyResult(dispatch, applicationId).then((res) => {
      const {
        data: { data },
      } = res;
      if (data.status === "Completed") {
        props.history.replace(
          `/application/offcycle/declaration?applicationId=${applicationId}&endDate=${endDate}&lang=${language}&title=${title}`,
          {}
        );
      } else {
        if (localStorage.mcq && JSON.parse(localStorage.mcq).length > 0) {
          setAnswers(JSON.parse(localStorage.mcq));
        } else {
          if (localStorage.mcq && JSON.parse(localStorage.mcq).length > 0) {
            setAnswers(JSON.parse(localStorage.mcq));
          } else {
            getMcqQuestions(dispatch, applicationId, language);
          }
          getMcqQuestions(dispatch, applicationId, language);
        }
      }
    });
  }, [language]);

  const handleSubmit = () => {
    const payload = JSON.parse(localStorage.mcq);
    // const currentTime = Math.round(new Date().getTime() / 1000);
    const answerObject = [
      {
        answerableQuestions: payload,
      },
    ];
    localStorage.removeItem("start");
    localStorage.removeItem("count");
    localStorage.removeItem("date");
    submitAssessment(dispatch, answerObject, date.total, applicationId).then(
      (res) => {
        if (res.status === 200) {
          setLevelModal(true);
        }
      }
    );
    localStorage.removeItem("personality");
    localStorage.removeItem("mcq");
  };

  // const mcqQuestions = localStorage.mcq && JSON.parse(localStorage.mcq);
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      // return <Completionist />;
      handleSubmit();
    } else {
      // Render a countdown
      return (
        <span className="timer">
          {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };
  const showTimer = () => {
    if (!localStorage.count) {
      localStorage.count = true;
      localStorage.date = moment(Date.now()).add(48, "h").valueOf();
    }
    if (date && date.completed === false) {
      return <Countdown date={Date.now() + date.total} renderer={renderer} />;
    }
  };

  const { userApplications } = state.Application;
  // eslint-disable-next-line no-unused-vars
  const applicant =
    userApplications &&
    userApplications.filter(
      (applicant) => applicant.applicationId === applicationId
    );

  // useEffect(() => {
  //   if (applicant && applicant.length > 0) {
  //     const { languageId } = applicant[0];
  //     localStorage.language = JSON.stringify(languageId);
  //   }
  // }, [applicant]);
  useEffect(() => {
    if (state.Application.Mcq) {
      setAnswers(state.Application.Mcq.answerableQuestions);
    }
  }, [state.Application]);

  const updateLocalStorage = () => {
    localStorage.mcq = JSON.stringify(answers);
  };

  const handleInputChange = (optionId, optionIndex) => {
    const index = answers.findIndex((answers) =>
      answers.options.find((option) => option.id === optionId)
    );
    const question = answers.find((answers) =>
      answers.options.find((option) => option.id === optionId)
    );

    const selectedOption = {
      ...question,
    };
    selectedOption.options.forEach((opt) => {
      opt.isSelected = false;
    });
    selectedOption.options[optionIndex].isSelected = true;

    // {
    //   ...option.options[optionIndex],
    //   isSelected: true,
    // },
    if (answers[index]) {
      // setAnswers([(answers[index] = selectedOption)]);
      const existingQuestions = [...answers];
      existingQuestions[index] = selectedOption;
      setAnswers(existingQuestions);
    }
  };

  const DisplayQuestions = ({
    question,
    options,
    questionIndex,
    nextStep,
    previousStep,
    currentStep,
    lastStep,
    totalSteps,
  }) => {
    const checkedOption =
      prevAnswer.answers &&
      prevAnswer.answers.length !== 0 &&
      prevAnswer.answers[questionIndex].options.find(
        (option) => option.isSelected === true
      );
    return (
      <Col>
        {showTimer()}
        <Row>
          <Badge color="light">
            {" "}
            {questionIndex + 1} of {answers && answers.length}
          </Badge>
        </Row>
        <Row>
          {/* <p>
            {question} <br />
          </p> */}
          <div
            className="notranslate"
            dangerouslySetInnerHTML={{ __html: `${question}` }}
          ></div>{" "}
          <br />
        </Row>

        <Row>
          <form className="form">
            {/* <h2>Checkboxes</h2>
              <div class="inputGroup">
                <input id="option1" name="option1" type="checkbox" />
                <label for="option1">Option One</label>
              </div>

              <div class="inputGroup">
                <input id="option2" name="option2" type="checkbox" />
                <label for="option2">Option Two</label>
              </div>

              <h2>Radio Buttons</h2> */}
            {options &&
              options.map((option, index) => (
                <div className="inputGroup">
                  <input
                    id={option.optionContent + question}
                    name="radio"
                    type="radio"
                    onChange={(e) => handleInputChange(option.id, index)}
                    checked={
                      checkedOption ? checkedOption.id === option.id : false
                    }
                  />
                  <label htmlFor={option.optionContent + question}>
                    {option.optionContent}
                  </label>
                </div>
              ))}
          </form>
        </Row>
        <Row>
          {currentStep > 1 && (
            <Button
              color="secondary"
              onClick={previousStep}
              style={{ marginRight: "5%" }}
            >
              {t("Previous")}
            </Button>
          )}
          {currentStep !== totalSteps && (
            <Button color="secondary" onClick={nextStep}>
              {t("Next")}
            </Button>
          )}
          {currentStep === totalSteps && (
            <div className="btn btn-outline-secondary" onClick={handleSubmit}>
              {t("Continue to Declarations")}
            </div>
          )}
        </Row>
      </Col>
    );
  };

  const custom = {
    enterRight: "none",
    enterLeft: "none",
    exitRight: "none",
    exitLeft: "none",
  };

  return (
    <Layout>
      <Col md={12} sm={12} xs={12} lg={12}>
        <h2 className="personality-heading">{t("Cognitive Test")}</h2>
        <p className="personality-text">{declarationTranslations.welcome}</p>

        <Card className="personality-card">
          {answers.length < 1 ? (
            <Cloader loading={loader} />
          ) : (
            <span>
              <Row>
                <h3 className="personality-title">
                  <b>{t("MultiChoice")}</b> {t("Questions")}
                </h3>
              </Row>
              <Row>
                <p className="personality-sub">
                  {sectionFourTranslations[language || "en"]}
                </p>
              </Row>
              <StepWizard transitions={custom}>
                {
                  language &&
                    localStorage.start &&
                    answers &&
                    answers.map((question, index) => (
                      <DisplayQuestions
                        question={question.questionContent}
                        options={question.options}
                        questionIndex={index}
                      />
                    ))
                  // questions && (<QuestionsLoop />)[1]
                }
              </StepWizard>
            </span>
          )}
        </Card>
        <ApplicationProgressReport
          showReport={levelModal}
          onReportClose={_onContinueClick}
          onSubmitClick={_onContinueClick}
          percentageComplete={90}
        />
        <Modal
          isOpen={!localStorage.start && instructionModal}
          toggle={() => setInstructionModal(!instructionModal)}
          backdrop={"static"}
          size="lg"
        >
          {/* <ModalHeader style={{ borderBottom: "none" }} toggle={() => setInstructionModal(!instructionModal)}></ModalHeader> */}
          <ModalBody
            style={{ textAlign: "center", padding: "2rem" }}
            className="notranslate"
          >
            <div className="modalImg">
              <img src={InstructionModalImg} className="img-fluid" />
            </div>
            <span></span>
            <h4 style={{ marginBottom: "1.2rem" }}>{/* Great work!😃 */}</h4>
            <span style={{ textAlign: "left" }}>
              <p>{t("testInstruction")}</p>
              {/* <p>Please read the following carefully.</p> */}
              {/* <p>
                The next section contain Financial and Business Acumen
                Questions.
              </p>
              <p>
                This is a timed assessment. You have 48 hours to complete this
                section.
              </p>
              <p>
                It is recommended that you take the assessment in an environment
                with stable internet connection, free of distractions and with
                optimal concentration.
              </p>

              <p>
                Note: You can only take this assessment once after you click
                start and you cannot Save &amp; Continue
              </p>
              <p>Click the "Start" button to begin.</p> */}
              {/* <p>
                {t(
                  'Please read the following carefully.\n\nThe next two (2) sections contain Personality questions, Financial and Business Acumen Questions.\n\nThis is a timed assessment. You have 45mins to complete both sections.\n\nIt is recommended that you take the assessment in an environment with stable internet connection, free of distractions and with optimal concentration.\n\nNote: You can only take this assessment once after you click start and you cannot Save & Continue\n\nClick the "Start" button to begin.'
                )}
              </p> */}

              {/* <p>Click the “Next” button to begin.</p> */}
            </span>
            <Form>
              {/* <FormGroup  style={{textAlign:"left"}}>
                                    <Label for="reminderEmail" style={{fontWeight:"bold"}}>Enter email address</Label>
                                    <Input
                                        id="reminderEmail"
                                        placeholder="name@teffoundation.org"
                                    />
                                </FormGroup> */}
              <FormGroup style={{ textAlign: "left", marginTop: "10%" }}>
                <Button color="secondary" onClick={() => startAssessment()}>
                  {t("Start")}
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={() =>
                    props.history.replace("/programme/dashboard", {})
                  }
                >
                  {t("Save now and Continue Later")}
                </Button>{" "}
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </Col>
    </Layout>
  );
};
CognitiveTest.propTypes = {};

export default CognitiveTest;
