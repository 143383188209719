/**
 * Exports environment variables as constants to ease development
 * because any changes will immediately take effect, while changes in environment variables
 * require to kill and re-run `yarn start`.
 */

/**
 * Developers: To change the value of the env variables, set them here, e.g.
 * REACT_APP_CLIENT_ID: "29ba0823-0809-473e-b40d-8550c88f9a32".
 *
 * Don't forget to remove before submitting.
 */
export const env = {
  REACT_APP_CLIENT_ID: null,
  REACT_APP_CLIENT_SECRET: null,
  REACT_APP_ALLOW_DEBUG_URL_FLAGS: null,
  REACT_APP_BASE_URL: null,
  REACT_APP_CORE_BASE_URL: null,
};

for (const key of Object.keys(env)) {
  // Make sure to stick to process.env in production.
  if (env[key] === null || process.env.NODE_ENV === "production") {
    env[key] = process.env[key];
  }
}
