import React from "react";
import classes from "./Description.module.css";

const Description = ({ description }) => {
  return (
    <div className={classes.Description}>
      <div className={classes.Label}>Description</div>
      <div className={classes.Value}>{description}</div>
    </div>
  );
};

export default Description;
