import React from "react";
import { Redirect } from "react-router-dom";
import isDMsUser from "./utils/isDms";
import { isLoggedIn } from "./utils/isLoggedIn";
import { usePageView } from "./utils/usePageViewHook";
import SentryRoute from "./SentryRoute";

const DMSRoute = ({ component: Component, ...rest }) => {
  usePageView();
  return (
    <SentryRoute
      {...rest}
      render={(props) =>
        isLoggedIn() && isDMsUser() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default DMSRoute;
