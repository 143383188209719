import React, { useEffect, useState } from "react";
import Avatar from "src/components/Avatar/Avatar";
import { FaRegCommentAlt, FaRegHeart } from "react-icons/fa";
import axios from "axios";
import { ProfileUrl } from "src/constants";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import ReactAvatar from "react-avatar";
import { FileViewerReadOnly } from "src/components/FileViewer/FileViewer";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { EditorContentDisplay } from "src/components/Editor";

const retrieveUserProfile = async (id) => {
  const res = await axios.get(`${ProfileUrl.GET_USER_PROFILE_URL}/${id}`);
  return res;
};

const DiscussionChat = ({
  type,
  data,
  onLikeClick,
  onReplyClick,
  files,
  senderDetails,
  senderId,
}) => {
  const [userProfile, setUserProfile] = useState("");
  const [loadingUserInfo, setLoadingUserInfo] = useState(false);
  const history = useHistory();

  const user = useCurrentUser();

  const _setProfile = async () => {
    if (senderId === user.userId) {
      setUserProfile(user);
    } else {
      if (senderDetails && Object.keys(senderDetails).length > 0) {
        setUserProfile(senderDetails);
      } else {
        await _getProfile();
      }
    }
  };
  const _getProfile = async () => {
    setLoadingUserInfo(true);
    const res = await retrieveUserProfile(data.createdBy);
    setUserProfile(res.data.data);
    setLoadingUserInfo(false);
  };

  useEffect(() => {
    _setProfile();
  }, []);

  const displayFiles = () => {
    const allFiles = files.filter((file) => file && file.length > 1);
    return <FileViewerReadOnly files={allFiles} />;
  };

  const viewUser = () => {
    history.push(`/profile/${data.createdBy}`);
  };

  return (
    <div className="chat-container">
      <div className="chat-container__chat-item">
        <div className="image-container">
          {!userProfile.imageUrl ? (
            <ReactAvatar
              name={`${userProfile.firstName} ${userProfile.lastName}`}
              round
              size={24}
            />
          ) : (
            <Avatar
              dimension="24px"
              src={userProfile.imageUrl}
              onClick={() => viewUser()}
            />
          )}
        </div>
        <div className="content-container">
          <h1
            onClick={() => {
              viewUser();
            }}
            className="user-name"
          >
            {loadingUserInfo ? (
              <Skeleton />
            ) : (
              `${userProfile.firstName} ${userProfile.lastName}`
            )}

            {/* {`${userInfo.firstName} ${userInfo.lastName}`} <span>mentor</span> */}
          </h1>
          {/* <p>{data.message || data.note}</p> */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              overflow: "auto",
            }}
            className="chat-text"
          >
            <EditorContentDisplay
              content={data.message || data.note}
              className="chat-text"
            />
          </div>
          <>{files && displayFiles()}</>
          <div className="like-reply-container">
            <span className="like">
              <FaRegHeart onClick={onLikeClick} className="like-reply-icon" />{" "}
              <span>{data.totalLikes} Likes</span>
            </span>
            {type === "discussion" && (
              <span className="reply">
                <FaRegCommentAlt
                  onClick={onReplyClick}
                  className="like-reply-icon"
                />{" "}
                <span>{data.totalReplies || 0} Replies</span>
              </span>
            )}

            {type === "discussion" && (
              <span className="discussion-date">
                <span>{new Date(data.createdDate).toDateString()}</span>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

DiscussionChat.propTypes = {};
DiscussionChat.defaultProps = {
  onLikeClick: () => {},
  onReplyClick: () => {},
  files: null,
};

export default DiscussionChat;
