import React, { useContext, useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useParams } from "react-router";
import StepWizard from "react-step-wizard";
import { toast } from "react-toastify";
import { Button, ButtonGroup } from "reactstrap";
import {
  getSurveyQuestions,
  submitSurveyQuestions,
} from "src/requests/TrainingSurveyRequest";
import { Context } from "src/store";
import { getLanguage, _breakInChunck } from "src/utils/helper";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { CheckMarker } from "./checker";
import classes from "./survey.module.scss";
const Survey = ({ survey, toggle, onSumbit }) => {
  const { state, dispatch } = useContext(Context);
  const { eventId } = useParams();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1);
  const [answers, setAnswers] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const user = get_user_info_from_token().user;

  const _getSurveyQuestions = () => {
    getSurveyQuestions(dispatch, getLanguage() || "en", eventId, survey.id);
  };

  useEffect(() => {
    if (survey) {
      _getSurveyQuestions();
    }
  }, [survey]);

  const _handleSubmit = () => {
    setLoadingSubmit(true);
    submitSurveyQuestions(eventId, answers)
      .then((res) => {
        if ((res && res.status === 201) || res.status === 200) {
          toggle();
          toast("Thank you for taking to time to take the survey");
          onSumbit?.();
        }
      })
      .catch(() => {
        toast("An error occured when trying to submit the survey");
        setLoadingSubmit(false);
      });
  };

  const chunckSize = 5;
  const QuestionBox = (props) => {
    const {
      currentStep,
      nextStep,
      previousStep,
      children,
      currentQuestions,
      setCurrentStepIndex,
      totalSteps,
    } = props;

    const _disabled = () => {
      if (currentQuestions) {
        return currentQuestions.some(
          (q) => !answers.find((a) => a.surveyQuestionId === q.id)
        );
      }
    };

    useEffect(() => {
      setCurrentStepIndex(currentStep);
    }, [currentStep]);

    const _handleNext = () => {
      isLastStep() ? _handleSubmit() : nextStep();
      if (!isLastStep()) {
        document.getElementById("top").scrollIntoView();
      }
    };

    const isLastStep = () => {
      return currentStep >= totalSteps;
    };

    const _previousStep = () => {
      previousStep();
      document.getElementById("top").scrollIntoView();
    };

    return (
      <>
        {children}
        <ButtonGroup className="float-right">
          <Button
            color="primary"
            outline
            onClick={_previousStep}
            disabled={loadingSubmit}
          >
            Previous
          </Button>
          <Button
            onClick={_handleNext}
            disabled={
              _disabled() ||
              loadingSubmit ||
              state.Training?.loadingGettingTrainingSurveyQuestions
            }
          >
            {`${isLastStep() && answers.length > 0 ? "Submit" : "Next"}`}
            {loadingSubmit && (
              <Loader
                className="float-right"
                height={20}
                width={20}
                color="white"
                type="Oval"
                style={{ marginLeft: "5px" }}
              />
            )}
          </Button>
        </ButtonGroup>
        {currentQuestionIndex && (
          <span className={`${classes.stepCounter} float-right`}>
            step {currentQuestionIndex} of {totalSteps}
          </span>
        )}

        <div style={{ clear: "both" }}></div>
      </>
    );
  };

  const _onCheckOption = (question, answer) => {
    const selectedAnswers = [...answers];
    const answerIndex = selectedAnswers.findIndex(
      (a) => a.surveyQuestionId === question
    );
    if (answerIndex <= -1) {
      selectedAnswers.push({
        userId: user.id,
        optionId: answer,
        surveyQuestionId: question,
        surveyId: survey.id,
        paragraphAnswer: "",
      });
    }
    if (answerIndex >= 0) {
      selectedAnswers[answerIndex] = {
        userId: user.id,
        optionId: answer,
        surveyQuestionId: question,
        surveyId: survey.id,
        paragraphAnswer: "",
      };
    }
    setAnswers(selectedAnswers);
  };

  return (
    <div className={classes.surveyHome} id="top">
      <StepWizard
        transitions={{
          enterRight: "none",
          enterLeft: "none",
          exitRight: "none",
          exitLeft: "none",
        }}
      >
        {survey && (
          <QuestionBox
            setCurrentStepIndex={setCurrentQuestionIndex}
            // question={}
          >
            <div>
              <h5>Instructions</h5>
              <section
                dangerouslySetInnerHTML={{
                  __html: survey?.overview?.instruction,
                }}
              ></section>
            </div>
          </QuestionBox>
        )}

        {state.Training?.surveyQuestions?.length > 0 &&
          _breakInChunck(state.Training?.surveyQuestions, chunckSize).map(
            (chunck, i) => {
              return (
                <QuestionBox
                  setCurrentStepIndex={setCurrentQuestionIndex}
                  key={i}
                  currentQuestions={chunck}
                >
                  {chunck.map((q) => {
                    return (
                      <>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: q?.questionContent,
                          }}
                          key={q.id}
                          className={classes.questionItem}
                        ></p>
                        {q.surveyQuestionOptions &&
                          q.surveyQuestionOptions.length > 0 &&
                          q.surveyQuestionOptions.map((option) => {
                            return (
                              <CheckMarker
                                label={option.optionContent}
                                value={option.id}
                                name={q.id}
                                key={option.id}
                                onChange={_onCheckOption}
                                checked={
                                  answers.findIndex(
                                    (answer) => answer.optionId === option.id
                                  ) >= 0
                                }
                              />
                            );
                          })}
                      </>
                    );
                  })}
                </QuestionBox>
              );
            }
          )}
      </StepWizard>
      {state.Training?.loadingGettingTrainingSurveyQuestions && (
        <span>loading survey questions...</span>
      )}
    </div>
  );
};

export default Survey;
