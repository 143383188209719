import "./Achievement.scss";
import React, { useState, useContext } from "react";
import defaultAchievementImage from "src/assets/img/defaultAchievementImage.png";
import { FormField } from "src/modules/onboarding/components/formField";
import { Btn } from "src/modules/profile/ProfileEdit/common";
import ValidateJs from "src/utils/Validations/validation_wrapper";
import { displayError } from "src/modules/onboarding/helper";
import shortId from "shortid";
import { addAchievement, deleteAchievement } from "src/requests/ProfileRequest";
import { Context } from "src/store";
import Loader from "react-loader-spinner";
// import Swal from "sweetalert2";
import { Col } from "reactstrap";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const AchievementList = ({ items, onDeleteClick, deleteId }) => {
  const [showPromptFor, setShowPromptFor] = useState(null);
  return (
    <div className="achievement-list pad-inner">
      {items.map((item, index) => {
        return (
          <div className="achievement-list__item t" key={shortId.generate()}>
            <div className="achievement-list__item--image-container">
              <img
                src={defaultAchievementImage}
                alt="achievement-icon"
                className="img-fluid"
              />
            </div>
            <div className="achievement-list__item--main">
              <div className="achievement-list__item--main--header">
                <h1>{item.title}</h1>
              </div>
              <p
                style={{
                  fontSize: "12px",
                  color: "#63666a",
                  marginLeft: "17px",
                  wordWrap: "break-word",
                  hyphens: "auto",
                }}
              >
                {item.description}
                <br />
                <a
                  href={
                    item.url.startsWith("http://") ||
                    item.url.startsWith("https://")
                      ? item.url
                      : `http://${item.url}`
                  }
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#0f6eff" }}
                >
                  {item.url}
                </a>
              </p>
              <div>
                <Col style={{ display: "flex", alignItems: "center" }}>
                  <span
                    className="a-d-confirm-btn delete-a-btn"
                    onClick={() => setShowPromptFor(item.id)}
                  >
                    {deleteId === item.id ? "Deleting" : "Delete"}
                    {/* {deleteId === item.id && (
                    <Loader color="red" height={15} width={15} type="Oval" /> */}
                  </span>
                  {deleteId === item.id && (
                    <span>
                      {" "}
                      <Loader color="red" height={15} width={15} type="Oval" />
                    </span>
                  )}
                </Col>
                {showPromptFor === item.id && deleteId !== item.id && (
                  <>
                    <Col md={6} sm={12}>
                      <span className="a-d-confirm-btn">
                        Are you sure you want to delete this?
                      </span>
                    </Col>{" "}
                    <Col md={6} sm={12}>
                      <span
                        className="a-d-confirm-btn delete-a-btn"
                        onClick={() => onDeleteClick(item.id)}
                      >
                        Yes
                      </span>
                      <span
                        className="a-d-confirm-btn delete-a-btn-r"
                        onClick={() => {
                          setShowPromptFor(null);
                          // onDeleteClick(null);
                        }}
                      >
                        No, it was a mistake
                      </span>
                    </Col>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const AchievementEdit = (props) => {
  const { onCancel, achievements, callbacks } = props;
  const [data, setData] = useState({ title: "", url: "", description: "" });
  const [addAch, setAddAch] = useState(false);
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(Context);
  const [validationError, setValidationErrors] = useState({ url: null });
  const [deleteId, setDeleteId] = useState(null);
  const [_getDecoupledProfileParts] = callbacks;
  const user = get_user_info_from_token().user;

  const [errorDesc, setErrorDesc] = useState("");

  // const [existingAchiements, setExistingAchievements] = useState([]);

  // useEffect(() => {
  //   setExistingAchievements(achievements);
  // }, [achievements]);

  const _handleChange = (target, value) => {
    setData({ ...data, [target]: value });
  };
  const validate = () => {
    return (
      data.title.length === 0 ||
      validationError.url !== null ||
      data.url.length === 0 ||
      data.description.length < 50
    );
  };

  const _handleErrorChange = (target, value) => {
    const obj = { ...validationError, [target]: value };
    setValidationErrors(obj);
  };
  const cancel = () => {
    if (addAch) {
      setAddAch(false);
    } else {
      onCancel();
    }
  };

  const _onDelete = (id) => {
    setDeleteId(id);

    deleteAchievement(dispatch, [{ achievementId: id }]).then(() => {
      _getDecoupledProfileParts(user.id);
      setDeleteId(null);
    });
  };

  const onSubmit = () => {
    const payload = { ...data };
    if (!payload.url || payload.url === "") {
      payload.url = null;
    }
    setLoading(true);
    addAchievement(dispatch, [payload]).then(() => {
      setLoading(false);
      setAddAch(false);
      setData({ title: "", url: "", description: "" });
      _getDecoupledProfileParts(user.id);
    });
  };
  return (
    <div>
      <div className="edit-profile-header">
        <h1>Edit Achievements</h1>
      </div>
      <hr />
      <form>
        {!addAch ? (
          <>
            {achievements && achievements.length > 0 && (
              <>
                <h1 className="heading-h1">Your Achievements</h1>
                <AchievementList
                  items={achievements}
                  deleteId={deleteId}
                  onDeleteClick={_onDelete}
                />
              </>
            )}
            <div className="btn-box">
              <Btn
                className="btn-choose-photo"
                value="Add New Achievement"
                onClick={() => setAddAch(!addAch)}
              />
            </div>
          </>
        ) : (
          <>
            <FormField
              className="flex"
              type="text"
              label="Add Title*"
              // labelInfo="Optional"
              placeholder="Title of the project or achievement"
              name="title"
              value={data.title}
              // value={enforceWordCountValue(
              //   wordCounter(data.title),
              //   data.title,
              //   20
              // )}
              onChange={_handleChange}
              hasCounter
              maxLength={70}
            />
            <FormField
              className="flex"
              type="text"
              label="Add URL*"
              // labelInfo="Optional"
              placeholder="e.g www.techpoint.africa/janedoe"
              name="url"
              value={data.url}
              onChange={_handleChange}
              onBlur={
                data.url.length > 0
                  ? () => _handleErrorChange("url", ValidateJs("url", data.url))
                  : () => {
                      _handleErrorChange("url", null);
                    }
              }
              error={validationError.url}
            />
            {validationError.url && displayError(validationError.url)}
            <FormField
              className="flex"
              type="textarea"
              label="Add Description*"
              // labelInfo="Optional"
              placeholder="A short overview of the goal of the project/achievement"
              name="description"
              // value={enforceWordCountValue(
              //   wordCounter(data.description),
              //   data.description,
              //   70
              // )}
              value={data.description}
              onChange={_handleChange}
              hasCounter
              maxLength={500}
              onBlur={() => {
                if (data.description.length < 50) {
                  setErrorDesc(
                    "your description should not be less than 50 characters"
                  );
                } else {
                  setErrorDesc("");
                }
              }}
            />
            {errorDesc && displayError(errorDesc)}
          </>
        )}
      </form>

      <hr />
      <div className="btn-box">
        <Btn className="btn-cancel" value="Cancel" onClick={cancel} />
        <div style={{ flex: 1 }}></div>

        {addAch && (
          <Btn
            className="btn-update"
            value="Save"
            loading={loading}
            onClick={() => onSubmit()}
            disabled={validate()}
          />
        )}

        {/* </div> */}
      </div>
    </div>
  );
};
