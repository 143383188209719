import React, { useState, useContext, useEffect, useCallback } from "react";
import Layout from "src/components/Layout/Layout";
import MessageList from "./MessageList/MessageList";
import { Context } from "src/store";
import styles from "./MessageIndex.module.scss";
import MessageDisplay from "./MessageDisplay/MessageDisplay";
import { getAllMessages } from "src/requests/MessageRequest";
import { getUserProfile } from "src/requests/ProfileRequest";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { FaPaperPlane } from "react-icons/fa";
import NewMessage from "./NewMessage/NewMessage";

const MessageIndex = (props) => {
  const { id } = props.match.params;
  const { dispatch } = useContext(Context);
  const [allMessageList, setAllMessageList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { userId } = useCurrentUser();
  const [userToDetails, setUserToDetails] = useState({});
  let arr = [];
  const [showNewMessage, setShowNewMessage] = useState(false);
  const toggleShowNewMessage = useCallback(() => {
    setShowNewMessage(!showNewMessage);
  }, [showNewMessage]);

  useEffect(() => {
    setIsLoading(true);
    getAllMessages(dispatch).then((response) => {
      if (response.status === 200) {
        if (response.data.data.length <= 0) {
          setIsLoading(false);
        }
        // eslint-disable-next-line array-callback-return
        response.data.data.map((messageList) => {
          const id =
            messageList.to === userId ? messageList.from : messageList.to;
          getUserProfile(dispatch, id).then((res) => {
            if (res.status === 200) {
              setIsLoading(false);
              const {
                data: { userId, firstName, lastName, imageUrl },
              } = res.data;

              const messageListDetail = {
                ...messageList,
                user: { userId, firstName, lastName, imageUrl },
              };
              arr = [...arr, messageListDetail];
              setAllMessageList(arr);
            }
          });
        });

        if (id) {
          const userExists = allMessageList.find(
            (convoList) => convoList.user.userId === id
          );
          if (!userExists || userExists === undefined) {
            setIsLoading(true);
            getUserProfile(dispatch, id).then((res) => {
              if (res.status === 200) {
                setIsLoading(false);
                const {
                  data: { userId, firstName, lastName, imageUrl },
                } = res.data;
                const newCl = {
                  conversationId: id,
                  createdOn: new Date(),
                  from: "",
                  to: "",
                  user: {
                    userId: userId,
                    firstName: firstName,
                    lastName: lastName,
                    imageUrl: imageUrl,
                  },
                };
                arr.unshift(newCl);
                setAllMessageList(arr);
                setUserToDetails({
                  name: `${firstName} ${lastName}`,
                  userId: userId,
                });
              }
            });
          }
        }
      }
    });
  }, []);

  return (
    <Layout hideRight={true}>
      <div className={styles.messageContainer}>
        <div className={styles.messageContainer__list}>
          <MessageList
            list={allMessageList}
            loading={isLoading}
            onclick={(e, details) => setUserToDetails(details)}
            toggle={toggleShowNewMessage}
          />
        </div>
        <div className={styles.messageContainer__messageDisplay}>
          {id ? (
            <MessageDisplay
              id={id}
              userToInfo={userToDetails}
              currentUserId={userId}
              list={allMessageList}
            />
          ) : (
            <div className={styles.messageContainer__messageDisplayIntro}>
              <div
                className={styles.messageContainer__messageDisplayInnerIntro}
              >
                <FaPaperPlane size={50} />
                <h2>Your messages</h2>
                <h5>
                  Choose one from your existing messages, or start a new one.
                </h5>
                <button onClick={toggleShowNewMessage}>send message</button>
              </div>
            </div>
          )}
        </div>
      </div>
      <NewMessage
        list={allMessageList}
        isOpen={showNewMessage}
        toggle={toggleShowNewMessage}
        updateUserDetails={setUserToDetails}
      />
    </Layout>
  );
};

export default MessageIndex;
