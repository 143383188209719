import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CategoryCard from "./CategoryCard/CategoryCard";
import "./index.scss";
import Loader from "src/components/Loader/Loader";

const CategoryIndex = ({ categories, loading, responsiveSize }) => {
  const categoriesCards = categories.map((categoryCard, index) => (
    <CategoryCard
      key={index}
      id={categoryCard.id}
      groupLogo={categoryCard.imageUrl}
      title={categoryCard.name}
      noOfGroups={categoryCard.groupCount}
    />
  ));

  return (
    <div className="communities-categories-main">
      <div className="communities-categories-main__heading">
        <h1 className="text-h1">Categories</h1>
        <h3 className="text-h3">Groups you might be interested in.</h3>
      </div>
      {loading ? (
        <div className="communities-categories-main__main">
          <Loader color="danger" />
        </div>
      ) : (
        <Carousel responsive={responsiveSize}>{categoriesCards}</Carousel>
      )}
    </div>
  );
};

export default CategoryIndex;
