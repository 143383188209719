import React from "react";

import classes from "./SelectOptions.module.css";

const SelectOptions = ({ documentTypes, handleDocumentSelect }) => {
  return (
    <div className={classes.CustomSelect}>
      <select onChange={(e) => handleDocumentSelect(e)}>
        <option value="">--Select Document type--</option>
        {documentTypes.map((type) => (
          <option key={type.id} value={type.id}>
            {type.title}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectOptions;
