import React, { useEffect } from "react";
import "./_success.scss";
import { Button } from "src/modules/training/components";
import SuccessImage from "src/assets/img/success-tef.png";
import Header from "src/modules/onboarding/components/Header";
import { refreshToken } from "src/utils/someTokenMenthods";
import { useTranslation } from "react-i18next";
// import queryString from "query-string";
// import { useLocation } from "react-router-dom";

const SuccessPageOnboarding = (props) => {
  // const location = useLocation();
  // const { ent } = queryString.parse(location.search);
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.removeItem("userInfo");

    window.addEventListener("popstate", function () {
      props.history.replace("/onboarding", {});
      // props.history.go(-1);
      props.history.push("/onboarding/success");
    });

    refreshToken();
  }, []);

  // const toggleMessage1 = () => {
  //   return ent && ent.toLowerCase() === "true" ? (
  //     <p>{t("msg1OnboardingSuccess")}</p>
  //   ) : (
  //     <p>{t("msg1OnboardingSuccess")}</p>
  //   );
  // };

  return (
    <div className="success-onboarding">
      <Header {...props} />
      <div className="success-onboarding__content">
        <div className="success-onboarding__content--image-container">
          <img src={SuccessImage} alt="icon" className="img-fluid" />
        </div>
        <div className="success-onboarding__content--message">
          <h1>{t("You're almost there!")} 👏🏿</h1>
          {/* {toggleMessage1()} */}
          <br />
          <div>
            <Button
              value={t("Proceed to Dashboard")}
              height="48px"
              width="204px"
              fontSize="16px"
              onClick={() =>
                props.history.push({ pathname: "/home", search: "" })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPageOnboarding;
