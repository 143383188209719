import React from "react";
import Layout from "src/components/Layout/Layout";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import MyGroups from "./MyGroups";

const MyGroupIndex = (props) => {
  return (
    <Layout SideContent={<SidebarRight />}>
      <MyGroups />
    </Layout>
  );
};

export default ifOnboardedThenShow(MyGroupIndex);
