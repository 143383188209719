import React, { useState, useEffect, useContext } from "react";
import CommentForm_v2 from "./CommentForm/CommentForm_v2";
import CommentItem from "./Comments/Comment_item";
import classes from "./Comment_v2.module.scss";
import { getAllComments } from "src/requests/CommentRequest";
import { Context } from "src/store";
import { Facebook } from "react-content-loader";

const Comment_v2 = ({ showComments, updateCommentCount, postId }) => {
  const [comments, setComments] = useState([]);
  const [loadingComments, setLoadigComments] = useState(false);
  const { dispatch } = useContext(Context);

  useEffect(() => {
    if (showComments) {
      triggerGetComments();
    }
  }, [showComments]);

  //  const getComments = () => {
  //   handleCommentToggle();
  //   triggerGetComments();
  // };

  const triggerGetComments = () => {
    setLoadigComments(true);
    getAllComments(dispatch, postId)
      .then((res) => {
        const { data } = res.data.data;
        updateCommentCount(data.length);
        setComments(data);
      })
      .finally(() => {
        setLoadigComments(false);
      });
  };

  return (
    <div className={classes.container}>
      {showComments && (
        <>
          {comments.map((comment, idx) => {
            return <CommentItem key={idx} comment={comment} />;
          })}

          {loadingComments && comments.length === 0 && <Facebook />}

          {!loadingComments && comments.length === 0 && (
            <p className={classes.no_comment}>No Comment</p>
          )}
        </>
      )}

      <br />

      <CommentForm_v2 postId={postId} getComments={triggerGetComments} />
    </div>
  );
};

export default Comment_v2;
