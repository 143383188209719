import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Context } from "src/store";
import { FaAngleLeft } from "react-icons/fa";
import {
  // isGroupMember,
  searchForGroups,
} from "src/requests/StudyGroupsRequest";
import "../Results/Results.scss";
import SuggestedGroupCard from "src/modules/training/StudyGroups/components/SuggestedGroup/SuggestedGroupCard/SuggestedGroupCard";
import Loader from "src/components/Loader/Loader";
import ResultPagination from "src/modules/training/StudyGroups/components/ResultPagination/ResultPagination";
import Layout from "src/components/Layout/Layout";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import queryString from "query-string";
import NotFoundImage from "src/assets/img/mentorship-empty.png";

const SearchResult = () => {
  const { state, dispatch } = useContext(Context);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [pager, setPager] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    pageNumber: 1,
  });

  const { q, course } = queryString.parse(location.search);

  const onPageSelect = (e) => {
    if (e) {
      _getResult(e.selected + 1);
    }
  };

  const _getResult = (pageNumber) => {
    setIsPageLoading(true);

    searchForGroups(dispatch, q, course, pageNumber).then((res) => {
      if (res !== undefined && res.status === 200) {
        const {
          data: { pager },
        } = res.data;
        setPager(pager);
        setIsPageLoading(false);
      }
    });
  };

  useEffect(() => {
    _getResult(pager.pageNumber);
  }, []);

  const { groupSearchResults } = state.Communities;
  const cards = groupSearchResults.map((group, index) => {
    return (
      <SuggestedGroupCard
        key={index}
        id={group.id}
        groupLogo={group.imageUrl}
        title={group.name}
        members={group.membersCount}
        location={group.location}
        belongs={group.isMember}
        creatorId={group.userId}
        privacy={group.privacy}
        likeCount={group.likeCount}
      />
    );
  });

  const history = useHistory();
  return (
    <Layout hideRight={true}>
      <div className="results-main">
        <div className="results-main__heading">
          <Link
            // to="/training/studygroups"
            onClick={() => history.goBack()}
            className="results-main__heading--back"
          >
            <FaAngleLeft /> &nbsp; Back
          </Link>
          <h1 className="results-main__heading--title">Search results</h1>
        </div>
        {isPageLoading ? (
          <div className="results-main__loader">
            <Loader color="danger" />
          </div>
        ) : (
          <div className="results-main__results">
            {groupSearchResults.length > 0 ? (
              cards
            ) : (
              <div className="results-main__results--no-group">
                <img src={NotFoundImage} alt="Not found" />
                <h4
                  className="results-main__results--no-group"
                  style={{ textAlign: "center", marginTop: "10px" }}
                >
                  No group found
                </h4>
              </div>
            )}
          </div>
        )}
        {(pager.hasNextPage || pager.hasPreviousPage) && (
          <div className="results-main__pagination results-main__pagination--bottom">
            <ResultPagination
              pageCount={pager.pageCount}
              pageRange={1}
              onSelect={onPageSelect}
              current={pager.pageNumber}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ifOnboardedThenShow(SearchResult);
