import { asyncActionName } from "src/utils/AsyncUtils";
import {
  FETCH_APPLICATION_FAQ,
  GET_PERSONALITY_QUESTIONS,
  GET_MCQ_QUESTIONS,
  GET_USER_APPLICATIONS,
  SUBMIT_DECLARATION,
  VERIFY_RESULT,
} from "src/actions";
import { initialState } from "src/store";

const ApplicationReducer = (state = initialState.application, action) => {
  switch (action.type) {
    case asyncActionName(FETCH_APPLICATION_FAQ).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(FETCH_APPLICATION_FAQ).success:
      return {
        ...state,
        FAQ: action.payload,
      };
    case asyncActionName(FETCH_APPLICATION_FAQ).failure:
      return {
        ...state,
        error: action.payload.status,
      };

    case asyncActionName(GET_PERSONALITY_QUESTIONS).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(GET_PERSONALITY_QUESTIONS).success:
      return {
        ...state,
        Personality: action.payload,
        PersonalityAnswers: action.payload,
      };
    case asyncActionName(GET_PERSONALITY_QUESTIONS).failure:
      return {
        ...state,
        error: action.payload.status,
      };

    case asyncActionName(GET_MCQ_QUESTIONS).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(GET_MCQ_QUESTIONS).success:
      return {
        ...state,
        Mcq: action.payload,
      };
    case asyncActionName(GET_MCQ_QUESTIONS).failure:
      return {
        ...state,
        error: action.payload.status,
      };

    case asyncActionName(GET_USER_APPLICATIONS).loading:
      return { ...state, loadingUserApplications: action.payload };
    case asyncActionName(GET_USER_APPLICATIONS).success:
      return {
        ...state,
        userApplications: action.payload,
      };
    case asyncActionName(GET_USER_APPLICATIONS).failure:
      return {
        ...state,
        userApplicationsError: action.payload.status,
      };

    case asyncActionName(SUBMIT_DECLARATION).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(SUBMIT_DECLARATION).success:
      return {
        ...state,
        declaration: action.payload,
      };
    case asyncActionName(SUBMIT_DECLARATION).failure:
      return {
        ...state,
        error: action.payload.status,
      };

    case asyncActionName(VERIFY_RESULT).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(VERIFY_RESULT).success:
      return {
        ...state,
        result: action.payload,
      };
    case asyncActionName(VERIFY_RESULT).failure:
      return {
        ...state,
        error: action.payload.status,
      };

    default:
      return state;
  }
};
export default ApplicationReducer;
