import React, { useEffect, useState } from "react";
import classes from "./AddChatAttachment.module.scss";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
// import ImageUploader from "react-images-upload";
// import {useDropzone} from 'react-dropzone';
// import Dropzone from "react-dropzone";
import { useDropzone } from "react-dropzone";
import { RiImage2Fill } from "react-icons/ri";
import "./Attachment.scss";

const AddChatAttachment = ({
  toggleAttachmantModal,
  isAttachmentModalOpen,
  attachnmentInputChange,
  chatMessage,
  submitChatWithImage,
  onDrop,
}) => {
  const maxSize = 1000000;
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    maxSize,
    onDrop: (acceptedFiles) => {
      onDrop(acceptedFiles);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const isFileTooLarge =
    fileRejections.length > 0 && fileRejections[0].file.size > maxSize;
  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const thumbs = files.map((file) => {
    const fileType = file.type.split("/")[0];
    if (fileType === "image") {
      return (
        <div style={thumb} key={file.name}>
          <div style={thumbInner}>
            <img src={file.preview} style={img} />
          </div>
        </div>
      );
    } else {
      return (
        <li key={file.path}>
          {file.path} - {file.size} bytes
        </li>
      );
    }
  });
  // const onDropHandle = (file) => {
  //   console.log("filessss", file);
  //   setFiles(file);
  // };

  useEffect(() => {
    console.log("files", files);
  });

  useEffect(
    () => () => {
      console.log("use", files);
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <Modal
      isOpen={isAttachmentModalOpen}
      toggle={toggleAttachmantModal}
      centered
    >
      <ModalHeader toggle={toggleAttachmantModal}>
        Add chat attachment
      </ModalHeader>
      <ModalBody>
        <div className="fileupload-wrapper">
          <div className="fileupload-box">
            <div className="container">
              <div
                {...getRootProps({
                  className: "dropzone",
                  onDrop: (event) => event.stopPropagation(),
                })}
                style={{ display: "flex", marginLeft: "20%" }}
              >
                <input {...getInputProps()} />

                <span className="fileupload-icon">
                  <RiImage2Fill color="#8EE67D" size={100} />
                </span>

                <p className="fileupload-subtext">
                  <span className="fileupload-title">
                    Upload Files <small>(Max file size: 5mb)</small>
                  </span>
                  <button className="fileupload-btn">Browse Your file</button>
                </p>
              </div>
            </div>
          </div>
          <aside style={thumbsContainer}>{thumbs}</aside>
          {isFileTooLarge && (
            <div style={{ color: "red", textAlign: "center" }}>
              File is too large
            </div>
          )}

          <div className="fileupload-form">
            <div className={classes["current-chat__action-input__box"]}>
              <form onSubmit={submitChatWithImage}>
                <textarea
                  className="form-control"
                  style={{ resize: "none" }}
                  value={chatMessage}
                  onChange={attachnmentInputChange}
                  placeholder="Type Message"
                ></textarea>
              </form>
            </div>
          </div>
        </div>

        {/* <ImageUploader
          withIcon={false}
          fileContainerStyle={{ backgroundColor: "#f0f0f0" }}
          buttonText="Choose images"
          onChange={onDrop}
          label="Max file size: 1mb, accepted: jpg, png, jpeg, gif"
          withPreview={true}
          imgExtension={[
            ".jpg",
            ".JPEG",
            "PNG",
            "JPG",
            ".png",
            ".gif",
            ".jpeg",
          ]}
          maxFileSize={5242880}
        /> */}

        {/* <Input
          className={classes["atachment-preview__input"]}
          defaultValue={chatInputText}
          onChange={attachnmentInputChange}
        /> */}
      </ModalBody>
      <ModalFooter className="border-0">
        <Button type="button" color="primary" onClick={toggleAttachmantModal}>
          Cancel
        </Button>{" "}
        <Button
          type="submit"
          disabled={isFileTooLarge}
          color="secondary"
          onClick={submitChatWithImage}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddChatAttachment;
