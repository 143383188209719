import jwtDecode from "src/utils/jwt-decode";
import setAuthToken from "./AuthTokenUtils";
import queryString from "query-string";
import axios from "axios";
import { env } from "src/constants/env";

const extract_token_info = (token, refreshToken) => {
  const { id, firstName, lastName, email, username, imageUrl } = jwtDecode(
    token
  );
  return {
    user: {
      id,
      firstName,
      lastName,
      email,
      username,
      imageUrl,
    },
    token,
    refreshToken,
  };
};

export const store_openid_token = (user) => {
  if (!user) {
    return;
  }

  const { access_token, refresh_token } = user;
  const token_info = extract_token_info(access_token, refresh_token);
  localStorage.setItem("data", JSON.stringify(token_info));
  setAuthToken(access_token);
};

/**
 * Extracts user info from the `decodedToken`.
 *
 * Removes some fields if they are empty - not sure why, but the original method did so and this method is used all over the code.
 */
export const get_user_info = (decodedToken) => {
  if (!decodedToken) {
    return undefined;
  }

  const user = { ...decodedToken };

  for (const key of [
    "tef-mentee",
    "tef-mentor",
    "pitching",
    "training",
    "alumni",
    "application",
    "2020_applicant",
    "dms_user",
    "dms_user_seed_capital",
  ]) {
    if (!user[key]) {
      delete user[key];
    }
  }

  return user;
};

export const get_user_info_from_token = () => {
  const dt = JSON.parse(localStorage.getItem("data"));
  if (dt?.token) {
    return { token: dt.token, user: get_user_info(jwtDecode(dt.token)) };
  }
};

/** We use the information contained in the token in many places, e.g. to determine if onboarding finished. */
export const refreshToken = () => {
  const token_data = JSON.parse(localStorage.getItem("data"));

  return axios({
    method: "post",
    url: `${env.REACT_APP_BASE_URL}/connect/token`,
    data: queryString.stringify({
      grant_type: "refresh_token",
      refresh_token: token_data.refreshToken,
    }),
    auth: {
      username: env.REACT_APP_CLIENT_ID,
      password: env.REACT_APP_CLIENT_SECRET,
    },
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  }).then((res) => {
    store_openid_token(res.data);
  });
};
