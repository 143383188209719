import React, { useContext, useEffect, useState } from "react";
import styles from "./TCmodal.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  agreeToParticipate,
  getPaticipationAgreement,
} from "src/requests/TrainingRequest";
import { Context } from "src/store";
import { Spinner } from "reactstrap";
import { GiBinoculars } from "react-icons/gi";

import { setCurrentUser, useCurrentUser } from "src/utils/Services/currentUser";
import Loader from "react-loader-spinner";
import { getLanguage } from "src/utils/helper";

const TCmodal = ({ toggle, cancel, programmeId, trainingEventId, userId }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);
  const current_user = useCurrentUser();
  const [TandC, setTandC] = useState("");
  const [loadingContent, setLoadingContent] = useState(false);
  const [hasContent, setHasContent] = useState(true);
  const lang = getLanguage() || "en";

  const handleParticipation = () => {
    setLoading(true);
    const payload = {
      // userId,
      // programId: programmeId,
      // programEventId: trainingEventId,
      agreement: true,
    };

    agreeToParticipate(dispatch, payload, trainingEventId).then((res) => {
      if ((res && res.status === 200) || (res && res.status === 201)) {
        current_user.hasAgreedToTraining = true;
        setCurrentUser(current_user);
        setLoading(false);
        toggle();
      } else {
        setLoading(false);
        alert("Sorry! We couldn't find your application record");
      }
    });
  };

  useEffect(() => {
    _getParticipationAgreement();
  }, []);

  const _getParticipationAgreement = () => {
    setLoadingContent(true);
    getPaticipationAgreement(trainingEventId, lang, "terms-conditions").then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          const content =
            res.data.data &&
            res.data.data.length > 0 &&
            res.data.data[0].content;
          if (content) {
            setHasContent(true);
            setTandC(content);
          } else {
            setHasContent(false);
          }
        } else {
          setHasContent(false);
        }
        setLoadingContent(false);
      }
    );
  };

  return (
    <div className={styles.TCMain}>
      <div dangerouslySetInnerHTML={{ __html: TandC }}></div>
      {loadingContent && (
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "50%",
          }}
        >
          <Loader type="Oval" height={30} width={30} color="red" />
          <p>Getting terms and agreement</p>
        </div>
      )}

      {!hasContent && (
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "30%",
          }}
        >
          <GiBinoculars size={300} />
          <p>Failed to load participation agreement</p>
        </div>
      )}
      {/* <h2 className={styles.TCMain__title}>{t("training-title")}</h2>
      <h2 className={styles.TCMain__title} style={{ textAlign: "center" }}>
        {t("training-title-2")}
      </h2>
      <h2
        className={styles.TCMain__title}
        style={{ textAlign: "center", marginBottom: "10px" }}
      >
        {t("training-title-3")}
      </h2>

      <p>{t("training-1")}</p>
      <p>{t("training-2")}</p>
      <p>{t("training-3")}</p>
      <p>{t("training-and")} </p>
      <p>{t("training-4")}</p>
      <p>{t("training-5")}</p>
      <ol className={styles.TCMain__structuredList}>
        <span className={styles.TCMain__boldText}>{t("training-whereas")}</span>
        <li>{t("training-whereas-1")}</li>
        <li>{t("training-whereas-2")}</li>
        <li>{t("training-whereas-3")}</li>
        <li>{t("training-whereas-4")}</li>
      </ol>

      <p>{t("training-whereas-p")}</p>

      <ol className={styles.TCMain__structuredList}>
        <li>
          <span className={styles.TCMain__boldText}>
            {t("Definitions title")}
          </span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("training-def-1")}</li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>
            {t("agreement-to-participate")}
          </span>
          {t("agreement-to-participate-1")}{" "}
          <Link
            target="_blank"
            to="/terms-of-use"
            className={styles.TCMain__redText}
          >
            {t("Terms and Conditions")}
          </Link>
          {t("agreement-to-participate-2")}
        </li>

        <li>
          <span className={styles.TCMain__boldText}>
            {t("obligation-to-participate")}
          </span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("obligation-to-participate-1")}</li>
            <li>{t("obligation-to-participate-2")}</li>
            <li>{t("obligation-to-participate-3")}</li>
            <li>{t("obligation-to-participate-4")}</li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>
            {t("representation-title")}
          </span>
          {t("representation-text")}
          <ol className={styles.TCMain__structuredList}>
            <li>{t("representation-1")}</li>
            <li>{t("representation-2")}</li>
            <li>{t("representation-3")}</li>
            <li>{t("representation-4")}</li>
            <li>{t("representation-5")}</li>
            <li>{t("representation-6")}</li>
            <li>{t("representation-7")}</li>
            <li>{t("representation-8")}</li>
            <li>{t("representation-9")}</li>
            <li>{t("representation-10")}</li>
            <li>{t("representation-11")}</li>
            <li>{t("representation-12")}</li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>{t("anti-bribery")}</span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("anti-bribery-1")}</li>
            <li>{t("anti-bribery-2")}</li>
            <li>{t("anti-bribery-3")}</li>
            <li>{t("anti-bribery-4")}</li>
            <li>{t("anti-bribery-5")}</li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>{t("media-title")}</span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("media-1")}</li>
            <li>{t("media-2")}</li>
            <li>{t("media-3")}</li>
            <li>{t("media-4")}</li>
            <li>
              {t("media-5-1")}{" "}
              <Link
                target="_blank"
                to="/privacy"
                className={styles.TCMain__redText}
              >
                {t("Privacy Policy")}
              </Link>{" "}
              {t("media-5-2")}
            </li>
            <li>{t("media-6")}</li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>
            {t("intellectual-property")}
          </span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("intellectual-property-1")}</li>
            <li>{t("intellectual-property-2")}</li>
            <li>{t("intellectual-property-3")}</li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>
            {t("limitation-title")}
          </span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("limitation-1")}</li>
            <li>{t("limitation-2")}</li>
            <li>{t("limitation-3")}</li>
            <li>{t("limitation-4")}</li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>
            {t("term-and-termination")}
          </span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("term-and-termination-1")}</li>
            <li>{t("term-and-termination-2")}</li>
            <li>{t("term-and-termination-3")}</li>
            <li>{t("term-and-termination-4")}</li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>{t("notice-title")}</span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("notice-1")}</li>
            <li>{t("notice-2")}</li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>{t("amendments")}</span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("amendments-1")}</li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>{t("assignment")}</span>
          {t("assignment-text")}
        </li>

        <li>
          <span className={styles.TCMain__boldText}>{t("severablity")}</span>
          {t("severablity-text")}
        </li>

        <li>
          <span className={styles.TCMain__boldText}>{t("waiver")}</span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("waiver-1")}</li>
            <li>{t("waiver-2")}</li>
          </ol>
        </li>

        <li>
          <span className={styles.TCMain__boldText}>
            {t("status-of-relationship")}
          </span>
          {t("status-of-relationship-text")}
        </li>

        <li>
          <span className={styles.TCMain__boldText}>{t("survival")}</span>
          {t("survival-text")}
        </li>

        <li>
          <span className={styles.TCMain__boldText}>
            {t("governing-jurisdiction")}
          </span>
          <ol className={styles.TCMain__structuredList}>
            <li>{t("governing-jurisdiction-1")}</li>
            <li>{t("governing-jurisdiction-2")}</li>
          </ol>
        </li>
      </ol> */}
      <div className={styles.TCMain__btn}>
        <Link className={styles.TCMain__rejectBtn} to="#" onClick={cancel}>
          {t("Reject")}
        </Link>
        <button
          className={styles.TCMain__acceptBtn}
          onClick={handleParticipation}
          disabled={loadingContent || !hasContent}
        >
          {loading ? <Spinner /> : `${t("Accept")}`}
        </button>
      </div>
    </div>
  );
};

export default TCmodal;
