import React from "react";
import classes from "./hubs.module.scss";
import ReactCountryFlag from "react-country-flag";
import { Link } from "react-router-dom";
const HubCard = ({ hubDetails }) => {
  return (
    <div className={classes["hub-card"]}>
      <div className={classes["hub-card__flag"]}>
        <ReactCountryFlag
          countryCode={hubDetails.country}
          svg
          style={{
            width: "100%",
            height: "100px",
            objectFit: "cover",
          }}
          title={hubDetails.country}
        />
      </div>
      <div className={classes["hub-card__details"]}>
        <h6 className={classes["hub-name"]}>TEF {hubDetails.name}</h6>
        <div className="d-flex justify-content-between mt-3">
          <small className={classes["hub-country"]}>{hubDetails.country}</small>
          <small className={classes["hub-members__count"]}>
            {hubDetails.usersCount} members
          </small>
        </div>
      </div>
      <Link
        to={`/alumni/hubs/${hubDetails.id}/details`}
        className="mt-3 text-center py-2 d-block"
        style={{ background: "#c4c2c2" }}
      >
        <span className="fs-15">view hub</span>
      </Link>
    </div>
  );
};

export default HubCard;
