import { asyncActionName } from "src/utils/AsyncUtils";
import { initialState } from "src/store";
import * as types from "src/actions";

const updateDiscusionsOnLikeOrReply = (discussions, filter) => {
  const discussionArray = [...discussions];
  const index = discussionArray.findIndex(
    (discussion) => discussion.id === filter.id
  );
  discussionArray[index] = filter;
  return discussionArray;
};

const updateReplysOnLikeOrReply = (replies, filter) => {
  const repliesArray = [...replies];
  const index = repliesArray.findIndex((reply) => reply.id === filter.id);
  repliesArray[index] = filter;
  return repliesArray;
};

const postDiscussion = (previousState, payload) => {
  if (
    !previousState ||
    (Array.isArray(previousState) && previousState.length === 0)
  ) {
    return [{ ...payload }];
  } else {
    return [{ ...payload }, ...previousState];
  }
};

const discussionReducer = (state = initialState.discussions, action) => {
  switch (action.type) {
    case asyncActionName(types.GET_GOAL_DISCUSSIONS).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(types.GET_GOAL_DISCUSSIONS).success:
      return {
        ...state,
        discussions: action.payload,
      };
    case asyncActionName(types.GET_GOAL_DISCUSSIONS).failure:
      return {
        ...state,
        error: action.payload.status,
      };

    case asyncActionName(types.CREATE_DISCUSSION_ON_GOAL).loading:
      return { ...state, loadingPostDiscussion: action.payload };
    case asyncActionName(types.CREATE_DISCUSSION_ON_GOAL).success:
      return {
        ...state,
        discussions: postDiscussion(state.discussions, action.payload),
        // discussions: [{ ...action.payload }, ...state.discussions],
      };
    case asyncActionName(types.CREATE_DISCUSSION_ON_GOAL).failure:
      return {
        ...state,
        PostDiscussionError: action.payload.status,
      };

    // like
    case asyncActionName(types.LIKE_DISCUSSION).loading:
      return { ...state, loading: false };
    case asyncActionName(types.LIKE_DISCUSSION).success:
      return {
        ...state,
        discussions: updateDiscusionsOnLikeOrReply(
          state.discussions,
          action.payload
        ),
      };
    case asyncActionName(types.LIKE_DISCUSSION).failure:
      return {
        ...state,
        error: action.payload.status,
      };

    // discussion detail
    case asyncActionName(types.GET_DISCUSSION_DETAILS).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(types.GET_DISCUSSION_DETAILS).success:
      return {
        ...state,
        discussionDetail: action.payload,
      };
    case asyncActionName(types.GET_DISCUSSION_DETAILS).failure:
      return {
        ...state,
        error: action.payload.status,
      };

    // replies
    case asyncActionName(types.GET_DISCUSSION_REPLIES).loading:
      return { ...state, requestingReplies: action.payload };
    case asyncActionName(types.GET_DISCUSSION_REPLIES).success:
      return {
        ...state,
        replies: action.payload,
      };
    case asyncActionName(types.GET_DISCUSSION_REPLIES).failure:
      return {
        ...state,
        repliesError: action.payload.status,
      };

    // create reply
    case asyncActionName(types.CREATE_DISCUSSION_REPLY).loading:
      return { ...state, loading: false };
    case asyncActionName(types.CREATE_DISCUSSION_REPLY).success:
      return {
        ...state,
        discussions: updateDiscusionsOnLikeOrReply(
          state.discussions,
          action.payload
        ),
      };
    case asyncActionName(types.CREATE_DISCUSSION_REPLY).failure:
      return {
        ...state,
        error: action.payload.status,
      };

    case asyncActionName(types.LIKE_REPLY).loading:
      return { ...state, loading: false };
    case asyncActionName(types.LIKE_REPLY).success:
      return {
        ...state,
        replies: updateReplysOnLikeOrReply(state.replies, action.payload),
      };
    case asyncActionName(types.LIKE_REPLY).failure:
      return {
        ...state,
        error: action.payload.status,
      };

    default:
      return state;
  }
};
export default discussionReducer;
