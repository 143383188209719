import React, { useContext, useEffect, useState } from "react";
import styles from "./StepOne_v2.module.scss";
import { ifNotOnboardedThenShow } from "src/utils/Services/isOnboarded";
import tef_logo from "src/assets/img/logo/logo_new_white.svg";
import { getOrganizations } from "src/requests/onboarding";
import { Context } from "src/store";
import {
  retrieveUserTempProfile,
  storeOnboardingProgress,
} from "src/modules/onboarding/helper";
import { defaultUserInfo } from "./userInfo";
import { _setCurrentStep } from "src/modules/onboarding/stepThree/stepThree";
import { Spinner } from "reactstrap";
const StepOneOnboarding_v2 = (props) => {
  const [userInfo, setUserInfo] = useState(defaultUserInfo);
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);

  const { state, dispatch } = useContext(Context);

  const checkTokenStorage = () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    if (!token) {
      return window.location.reload();
    }
  };

  const nextStep = (newUserInfo) => {
    return new Promise((resolve, reject) => {
      // Deep copy user info.
      let info = JSON.parse(JSON.stringify(newUserInfo));
      info = _setCurrentStep(
        info,
        window.location.pathname,
        "/onboarding/step-two"
      );
      return storeOnboardingProgress(JSON.stringify(info)).then((status) => {
        if (status === "success") {
          return resolve();
        }
      });
    });
  };

  const _setSelectedItem = (name, id) => {
    setLoading(true);
    const obj = { ...userInfo, groupId: id, role: name };
    nextStep(obj).then(() => {
      setLoading(false);
      props.history.push({
        pathname: "/onboarding/step-two",
        search: "",
      });
    });
  };

  useEffect(() => {
    // setLoading(true);

    checkTokenStorage();
    getOrganizations(dispatch);
    retrieveUserTempProfile()
      .then((profile) => {
        if (!profile || profile === null) {
          setUserInfo(defaultUserInfo);
          return defaultUserInfo;
        } else {
          setUserInfo(profile);
          return profile;
        }
      })
      .then((profile) => {
        if (
          profile.steps.previousStep !== window.location.pathname &&
          profile.steps.nextStep !== "" &&
          profile.groupId
        ) {
          props.history.push(profile.steps.nextStep);
        }
      });
  }, []);

  const updateRoles = () => {
    const roless = [];
    state.Onboarding.organizations.forEach((org) => {
      if (
        org.name.toLowerCase() ===
        process.env.REACT_APP_ENTREPRENEUR_KEYWORD.toLowerCase()
      ) {
        roless[0] = org;
      } else if (
        org.name.toLowerCase() ===
        process.env.REACT_APP_MENTOR_KEYWORD.toLowerCase()
      ) {
        roless[1] = org;
      }
    });
    setRoles(roless);
  };
  useEffect(() => {
    state.Onboarding.organizations &&
      state.Onboarding.organizations.length > 0 &&
      updateRoles();
  }, [state.Onboarding.organizations]);

  useEffect(() => {
    if (userInfo.groupId !== null && roles.length) {
      setLoading(false);
    }
  }, [userInfo, roles]);

  return (
    <div className={styles.container}>
      {loading && (
        <div className={styles.loader_container}>
          <Spinner size="30" color="white" style={{ height: 70, width: 70 }} />
        </div>
      )}
      <div className={styles.headings}>
        <div className={styles.headings_left}>
          <img src={tef_logo} alt="tef_logo" className={styles.tef_logo} />
        </div>
        <div className={styles.headings_right}>
          <h4 className={styles._step}>
            Step <span className={styles._step_1}>1</span>
            <span className={styles._step_2}> of 2</span>
          </h4>
          <div>
            <h4 className={styles.choose_text}>Choose Profile type</h4>
            <p className={styles.choose_text_paragraph}>
              Kindly select the profile type you would like to register with
            </p>
          </div>
        </div>
      </div>

      {roles.length && (
        <>
          <div
            className={styles.left}
            onClick={() => _setSelectedItem(roles[0].name, roles[0].id)}
            data-testid="entrepreneur"
          >
            <div className={styles.role_container}>
              <h4 className={styles.role_container__text}>Entrepreneur</h4>
            </div>
          </div>
          <div
            className={styles.right}
            onClick={() => _setSelectedItem(roles[1].name, roles[1].id)}
            data-testid="mentor"
          >
            <div className={styles.role_container}>
              <h4 className={styles.role_container__text}>Mentor</h4>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ifNotOnboardedThenShow(StepOneOnboarding_v2);
