import axios from "axios";
import { asyncActions } from "src/utils/AsyncUtils";
import { OpportunityURL } from "src/constants";
import { toast } from "react-toastify";
import {
  GET_ALL_ALUMNI_OPPORTUNITIES,
  GET_ALUMNI_OPPORTUNITIES,
  GET_ALL_ALUMNI_OPPORTUNITY_DISCUSSION,
  CREATE_OPPORTUNITY,
  GET_OPPORTUNITY_COMMENT_RESPONSE,
} from "src/actions";

export const getAlumninOpportunities = (dispatch, pageNumber, isAlumni) => {
  dispatch(asyncActions(GET_ALL_ALUMNI_OPPORTUNITIES).loading(true));
  return axios({
    method: "get",
    url: `${OpportunityURL.GET_OPPORTUNITY_URL}?IsAlumni=${isAlumni}`,
  });
};

export const getAlumninOpportunity = (dispatch, id) => {
  dispatch(asyncActions(GET_ALUMNI_OPPORTUNITIES).loading(true));
  return axios({
    method: "get",
    url: `${OpportunityURL.GET_OPPORTUNITY_URL}/${id}`,
  });
};

export const getTotalComment = (dispatch, payload, pageNumber) => {
  dispatch(asyncActions(GET_ALL_ALUMNI_OPPORTUNITY_DISCUSSION).loading(true));
  return axios({
    method: "get",
    url: `${OpportunityURL.GET_OPPORTUNITY_URL}/${payload}/discussions?`,
  });
};

export const postAlumniOpportunity = (dispatch, payload, history) => {
  dispatch(asyncActions(CREATE_OPPORTUNITY).loading(true));
  return axios({
    method: "post",
    url: `${OpportunityURL.CREATE_OPPORTUNITY}?isAlumni=${
      payload.isAlumni || false
    }`,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(CREATE_OPPORTUNITY).loading(false));

      if (response.status === 200 || response.status === 201) {
        const { data } = response.data;
        return data;
      }
    })
    .catch((error) => {
      const {
        data: { message },
      } = error.response;
      const fail = "An error occurred, Please try again ";
      dispatch(asyncActions(CREATE_OPPORTUNITY).loading(false));
      dispatch(asyncActions(CREATE_OPPORTUNITY).failure(true, error.response));
      toast.error(message || fail);
    });
};

export const getReply = (dispatch, param) => {
  dispatch(asyncActions(GET_OPPORTUNITY_COMMENT_RESPONSE).loading(true));
  return axios({
    method: "GET",
    url: `${OpportunityURL.GET_OPPORTUNITY_COMMENT_RESPONSE_URL}/${param.id}/replies?discussionId=${param.discussionId}`,
  });
};

export const likeOpportunity = (dispatch, opportunityId) => {
  return axios({
    method: "GET",
    url: `${OpportunityURL.GET_OPPORTUNITY_URL}/${opportunityId}/like-unlike`,
  });
};
