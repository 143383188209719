import React, { Fragment } from "react";
import classes from "./TEFButton.module.css";

const SocialMediaButton = ({ logo, label, bgColor, textColor }) => {
  return (
    <Fragment>
      <div
        className={classes.Button}
        style={{ backgroundColor: `${bgColor}`, color: `${textColor}` }}
      >
        <div className={classes.Logo}>{logo}</div>
        <div className={classes.Label}>{label}</div>
      </div>
    </Fragment>
  );
};

export default SocialMediaButton;
