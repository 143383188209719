import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Context } from "src/store";
import { FaAngleLeft } from "react-icons/fa";
import {
  // isGroupMember,
  searchForGroups,
} from "src/requests/CommunityRequest";
import "../Results/Results.scss";
import SuggestedGroupCard from "src/modules/Community/components/SuggestedGroup/SuggestedGroupCard/SuggestedGroupCard";
import Loader from "src/components/Loader/Loader";
import ResultPagination from "src/modules/Community/components/ResultPagination/ResultPagination";
// import { useCurrentUser } from "src/utils/Services/currentUser";
import Layout from "src/components/Layout/Layout";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import queryString from "query-string";
import NotFoundImage from "src/assets/img/mentorship-empty.png";

const SearchResult = () => {
  // const { userId } = useCurrentUser();
  const { state, dispatch } = useContext(Context);
  // const [usersGroups, setUsersGroups] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [pager, setPager] = useState({
    firstItemOnPage: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    lastItemOnPage: 1,
    pageCount: 1,
    pageNumber: 1,
    pageSize: 1,
    totalItemCount: 1,
  });
  const [pageDetails, setPageDetails] = useState({
    pageSize: 16,
    pageNumber: pager.pageNumber,
  });
  const qa = useLocation();
  const { q } = queryString.parse(qa.search);

  const onPageSelect = (e) => {
    setIsPageLoading(true);
    setPageDetails({
      ...pageDetails,
      pageNumber: e.selected + 1,
    });
  };

  useEffect(() => {
    setIsPageLoading(true);
    const payload = {
      search: q.toLowerCase(),
      pageNumber: pageDetails.pageNumber,
      maxItem: pageDetails.pageSize,
    };
    searchForGroups(dispatch, payload).then((res) => {
      if (res !== undefined && res.status === 200) {
        const {
          data: { pager },
        } = res.data;
        setPager(pager);
        setIsPageLoading(false);

        // isGroupMember(dispatch, userId).then((res) => {
        //   if (res.status === 200) {
        //     const {
        //       data: { data },
        //     } = res.data;
        //     setUsersGroups(data);
        //     setIsPageLoading(false);
        //   }
        // });
      }
    });
  }, [pageDetails]);

  const { groupSearchResults } = state.Communities;
  const cards = groupSearchResults.map((group, index) => {
    // const belongs = Boolean(
    //   Number(
    //     usersGroups.filter((userGroup) => userGroup.id === group.id).length
    //   )
    // );
    return (
      <SuggestedGroupCard
        key={index}
        id={group.id}
        groupLogo={group.imageUrl}
        title={group.name}
        members={group.membersCount}
        location={group.privacy}
        belongs={group.isAMember}
        ownerId={group.userId}
        inviteStatus={group.inviteStatus}
      />
    );
  });

  return (
    <Layout hideRight={true}>
      <div className="results-main">
        <div className="results-main__heading">
          <Link to="/community" className="results-main__heading--back">
            <FaAngleLeft /> &nbsp; Back
          </Link>
          <h1 className="results-main__heading--title">{q} Groups</h1>
        </div>
        {isPageLoading ? (
          <div className="results-main__loader">
            <Loader color="danger" />
          </div>
        ) : (
          <div className="results-main__results">
            {groupSearchResults.length > 0 ? (
              cards
            ) : (
              <div className="results-main__results--no-group">
                <img src={NotFoundImage} alt="Not found" />
                <h4
                  className="results-main__results--no-group"
                  style={{ textAlign: "center", marginTop: "10px" }}
                >
                  No group found
                </h4>
              </div>
            )}
          </div>
        )}
        {groupSearchResults.length > 0 ? (
          <div className="results-main__pagination results-main__pagination--bottom">
            <ResultPagination
              pageCount={pager.pageCount}
              pageRange={1}
              onSelect={onPageSelect}
              current={pager.pageNumber}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </Layout>
  );
};

export default ifOnboardedThenShow(SearchResult);
