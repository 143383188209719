/* eslint-disable no-unused-vars */
import axios from "axios";
import _ from "lodash";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { ApplicationUrl } from "src/constants";
import * as Sentry from "@sentry/browser";

/** Retrieves temporary data for a programme event from thhe back end.
 *  Back end returns a JSON in the format
 *  { "tempApplications": [
 *     {
 *      "userId": "guid",
 *      "applicationId": "guid",
 *      "data": "encoded token",
 *      "jsonUserString": "string",
 *      }
 *   ]
 *  }
 *
 *  This list should always have 0 or 1 entries, since back end always stores a single
 *  application per user and programme event ID.
 *  "jsonUserString" is a string that can be parsed into JSON representing custom
 *  data saved into the DB. It is opaque to the back end. "encoded token" in the "data"
 *  field is a legacy field representing exactly the same content as "jsonUserString"
 *  but encoded as a JWT token. It will be retired once we fully migrate the back end
 *  to the new temp applications endpoint.
 *
 *  We parse the encoded jsonUserString and return it as value.
 */
export const retrieveTempUserApplication = async (programmeEventId) => {
  const url = ApplicationUrl.GET_TEMP_APPLICATIONS;
  if (!programmeEventId) {
    // TODO(TP-2117): This is happening regularly. Let's return null and save a server call.
    return null;
  }
  try {
    const res = await axios.get(url, {
      params: { programmeEventId: programmeEventId },
    });
    const { data } = res;
    if (data?.tempApplications?.length > 0) {
      if (data.tempApplications.length > 1) {
        Sentry.captureMessage(
          `Invalid back end return. Multiple applications received for programme event ${programmeEventId}.`
        );
        console.warn(
          `Multiple applications received for programme event ${programmeEventId}. This indicates a problem with the back end. Using the first value.`
        );
      }
      const tempApplication = data.tempApplications[0];
      if (tempApplication.jsonUserString) {
        return JSON.parse(tempApplication.jsonUserString);
      }
    }
  } catch (e) {
    // TODO(TP-2082): throw an exception instead once we verified that this is not happening regularly.
    Sentry.captureException(e);
    console.error(e);
  }
  // If we got here, we failed to find the user application data. Returning undefined to match what callers expect.
  // TODO(TP-2082): consider returning null more explicitly.
};

/** Stores temporary data for application associated with programmeEventId.
 *
 * @param {personalInfo} userInfo: the data to store. See personalInfoObj.js.
 * @param {string} programmeEventId: the programme event ID associated with the application.
 *   We assume that back end will enforce the constraint of no more than 1 application per user and programmeEventId.
 * @returns: the stored object if the call succeeded or null otherwise.
 */
export const storeTempUserApplication = async (userInfo, programmeEventId) => {
  const url = ApplicationUrl.UPDATE_TEMP_APPLICATION;
  if (!programmeEventId) {
    // TODO(TP-2082): throw an exception instead once we verified that this is not happening regularly.
    Sentry.captureMessage("Empty programmeEventId in storeTempUserApplication");
    console.error(
      "Non-empty programme event ID is required in storeTempUserApplication"
    );
    return null;
  }

  const reqBody = {
    jsonUserString: JSON.stringify(userInfo),
    programmeEventId: programmeEventId,
    gender: userInfo.gender,
    country: userInfo.countryId,
    language: userInfo.languageId,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    email: userInfo.email,
    isResidentialRural: userInfo.isResidentialRural,
    businessIndustry: userInfo.businessIndustry,
  };

  try {
    const res = await axios.patch(url, reqBody);
    return res.data;
  } catch (e) {
    // TODO(TP-2082): throw an exception instead once we verified that this is not happening regularly.
    Sentry.captureException(e);
    console.error(e);
  }
  return null;
};

export const getUserCurrentApplication = async (url) => {
  const { user } = get_user_info_from_token();
  let applications;
  try {
    const res = await axios.get(`${url}/${user.id}`);
    const { data } = res;
    applications = data.data;
  } catch (e) {
    console.log(e);
  }
  return applications;
};

export const _setSteps = (info, currentStepUrl, nextStepUrl, previousStep) => {
  const completd = info.steps.completedSteps;
  completd.push(currentStepUrl);
  info.steps.completedSteps = _.uniq(completd);
  info.steps.currentCompletedStep = currentStepUrl;
  info.steps.nextStep = nextStepUrl;
  info.steps.previousStep = previousStep;
  return info;
};

export const yearsDropdown = (startYear, endYear) => {
  const result = [];
  for (let i = endYear; i >= startYear; i--) {
    result.push({ label: i, value: 1 });
  }
  return result;
};

export const _sanitizeData = (data, extractUserGroup) => {
  data = { ...data };
  delete data.validationErrors;
  delete data.skipTour;
  data.userGroupIds = extractUserGroup();
  data.hoursPerWeekInvested = Math.round(data.hoursPerWeekInvested);
  data.teamSize = Math.round(data.teamSize);
  if (data.businessRegistrationNumber) {
    data.businessRegistrationNumber = data.businessRegistrationNumber.split(
      "\\"
    )[0];
  }

  if (data.hasBusinessPartnerConsent === null) {
    data.hasBusinessPartnerConsent = false;
  }

  const sdgs = data.sdgs.map((goal) => {
    return goal.value;
  });
  const sanitized = { ...data, sdgs: sdgs };
  sanitized.businessLocation = sanitized.businessAddress;
  return sanitized;
};
