import React, { useState } from "react";
import { Alert } from "reactstrap";

const AlertDiv = ({ children, color, isVisible }) => {
  const [visible, setVisible] = useState(isVisible);
  const onDismiss = () => setVisible(false);

  return (
    <Alert color={color} isOpen={visible} toggle={onDismiss}>
      {children}
    </Alert>
  );
};

export default AlertDiv;
