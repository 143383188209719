import React, { useEffect } from "react";
import "../../../Opportunity/CreateOpportunitySuccess/CreateOpportunitySuccess.scss";
import SuccessImage from "src/assets/img/success-tef.png";
import Layout from "src/components/Layout/Layout";
import { Link } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
// import { MdKeyboardArrowRight } from "react-icons/md";
import { Row } from "reactstrap";
// import queryString from "query-string";
import { useTranslation } from "react-i18next";

const AddToolSuccess = () => {
  useEffect(() => {}, []);
  // const { id } = queryString.parse(window.location.search);

  const { t } = useTranslation();

  return (
    <Layout hideRight hideLeft>
      <div className="create-opp-breadcrumb">
        <Link to="/home" style={{ fontWeight: "bold" }}>
          {t("Home")} <IoIosArrowRoundForward />
        </Link>
        <Link to="/tools" style={{ fontWeight: "bold" }}>
          {t("Tools")} <IoIosArrowRoundForward />
        </Link>
        <Link to="/add-tool">
          {t("Add Tool")} <IoIosArrowRoundForward />
        </Link>
        <span>{t("Completed")}</span>
      </div>
      <div className="success-create-opp">
        <div className="success-create-opp__content">
          <div className="success-create-opp__content--image-container">
            <img src={SuccessImage} alt="icon" className="img-fluid" />
          </div>
          <div className="success-create-opp__content--message">
            <h1>{t("Congratulations")} 👏</h1>
            <p>{t("Your Tool has been added to the Tools board.")}</p>
            <p>
              {t(
                "This Tool will be further reviewed by the admin for futher actions."
              )}
            </p>
            <p>
              {t(
                "Please be informed that this tool will not be available for use until an admin has certified it okay for this platform"
              )}
            </p>
            <p>{t("Thank you.")}</p>
            <br />
            <Row className="mb-4"></Row>
            {/* <Link
              to={`/tool-details/${id}`}
              className="create-opp-success-view-opp"
            >
              {t(" View Tool")} <MdKeyboardArrowRight />
            </Link> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddToolSuccess;
