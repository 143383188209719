import {
  getAlumniHubEvent,
  // getSingleAlumniHub,
} from "src/requests/AlumniHubRequest";

export function getHubEventsById(dispatch, setHubEvents, setIsLoadingHubEvent) {
  return (hubId) => {
    getAlumniHubEvent(dispatch, hubId)
      .then((response) => setHubEvents(response.data.data.data))
      .catch((fetchHubEventError) =>
        console.log("fetchHubEventError", fetchHubEventError)
      )
      .finally(() => setIsLoadingHubEvent(false));
  };
}
// export function getHubAlumniById(dispatch, setAlumniHub, setIsLoadingHubEvent) {
//   return (hubId) => {
//     getSingleAlumniHub(dispatch, hubId)
//       .then((response) => setAlumniHub(response.data.data.data))
//       .catch((fetchHubEventError) =>
//         console.log("fetchHubEventError", fetchHubEventError)
//       )
//       .finally(() => setIsLoadingHubEvent(false));
//   };
// }
