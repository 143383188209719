/* eslint-disable no-multi-str */
import React, { useEffect, useState, useContext } from "react";
import { Row, Col, FormGroup, Button } from "reactstrap";
import Layout from "src/components/Layout/Layout";
import { IoIosArrowRoundForward } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import _ from "lodash";
import { Link, useHistory, useParams } from "react-router-dom";
import tefArticleBanner from "src/assets/img/discover/image2.png";
import articleImage from "src/assets/img/discover/article.png";
import bannerBackground from "src/assets/img/discover/Vector5.png";
import { Editor } from "@tinymce/tinymce-react";
import "../TefStory/TefStories.scss";
import {
  getAllCategories,
  getAllTags,
  getArticle,
  updateArticle,
} from "src/requests/DiscoverRequest";
import { Context } from "src/store";
import { isAllowedFiles, UploadFile } from "src/requests/fileUploadRequest";
import Loader from "src/components/Loader/Loader";
import DefaultImage from "src/assets/img/defaultAchievementImage.png";
import EditArticleSuccess from "./EditArticleSuccess";
import EditArticleForm from "./EditArticleForm";
import Swal from "sweetalert2";

const EditArticle = () => {
  const [modal, setModal] = useState(false);
  const { state, dispatch } = useContext(Context);
  const history = useHistory();
  const toggle = () => setModal(!modal);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [details, setDetails] = useState({});
  const [existingBanner, setExistingBanner] = useState("");
  const { id } = useParams();
  useEffect(() => {
    getAllCategories(dispatch);
    getAllTags(dispatch);
    getArticleDetails();
  }, []);

  useEffect(() => {}, [details]);
  const { categories, tags } = state.Discovers;
  const industryObj = categories.map((category) => {
    return {
      label: category.name,
      value: category.id,
    };
  });

  const tagsOption = tags.map((category) => {
    return {
      label: category.name,
      value: category.id,
      color: "#666666",
    };
  });
  const handleChange = (selectedItem) => {
    setDetails({
      ...details,
      tags: selectedItem,
    });
  };
  const onDrop = (picture) => {
    const existingFiles = [...file];
    const validateFiles = isAllowedFiles(Array.from([...picture]));
    if (validateFiles.hasError) {
      validateFiles.errors.forEach((file) => {});
    } else {
      existingFiles.push(...picture);
      const uniqueFile = _.uniqBy(existingFiles, "name");
      setFile(uniqueFile);
    }
  };
  const submitArticle = (e) => {
    e.preventDefault();
    const selectTagLabel = details.tags.map((tag) => tag.value);
    details.tags = selectTagLabel || ["business"];
    setIsLoading(true);
    if (file.length > 0) {
      UploadFile(dispatch, true, file)
        .then((res) => {
          if (res && res.status === 200) {
            res.files.map((img) => (details.bannerUrl = img));
            _submit(details);
          }
        })
        .catch(() => {
          // _submit(articleData);
        });
    } else {
      _submit(details, existingBanner);
    }
  };

  const _submit = (data, banner) => {
    updateArticle(dispatch, data, id, banner).then((res) => {
      setIsLoading(false);
      if (res.status === 201 || res.status === 200) {
        setDetails(data);
        setDetails({
          title: "",
          shortDescription: "",
          fullDescription: "",
          bannerUrl: DefaultImage,
          categoryId: "",
          tags: [],
        });
        toggle();
      }
    });
  };

  const getArticleDetails = async () => {
    try {
      const details = await getArticle(dispatch, id);
      const {
        data: { data },
      } = details;
      setDetails(data);
      setExistingBanner(data.bannerUrl);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Cannot Retrieve Article details this time, try again",
        confirmButtonColor: "#da291c",
      });
    }
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const selectHandler = (name, value) => {
    setDetails({ ...details, [name]: value });
  };
  return (
    <Layout hideLeft hideRight>
      <div className="mb-5">
        <div className="create-tef-story">
          <Link to="/discover" style={{ fontWeight: "bold" }}>
            {"TEF Articles"} <IoIosArrowRoundForward />
          </Link>
          <span>{"Create an Article"}</span>
        </div>
        <div className="create-tefstory-banner">
          <Row>
            <Col md={10}>
              <div>
                <h1>
                  Edit Article{" "}
                  <span>
                    <img src={articleImage} alt="logo" />
                  </span>
                </h1>
                <h6>Tell your story to us in details</h6>
              </div>
            </Col>
            <Col md={2}>
              <div className="create-tefstory-banner__logo">
                <img
                  src={bannerBackground}
                  alt="background"
                  style={{ width: "200px", top: "-32px", right: "80px" }}
                  className="position-relative"
                />
                <img
                  src={tefArticleBanner}
                  alt="logo"
                  style={{ width: "65px", top: "-20px" }}
                  className="position-absolute"
                />
              </div>
            </Col>
          </Row>
        </div>
        <form onSubmit={submitArticle}>
          <EditArticleForm
            industryObj={industryObj}
            tagsOption={tagsOption}
            toggle={toggle}
            selectHandler={selectHandler}
            handleChange={handleChange}
            inputHandler={inputHandler}
            onDrop={onDrop}
            articleDetails={details}
          />
          <div className="tefstory-editor mt-3">
            <Row>
              <Col sm={12} md={12}>
                <FormGroup type="textarea">
                  <Editor
                    apiKey={process.env.REACT_APP_TINY_CLOUD_KEY}
                    init={{
                      height: 400,
                      branding: false,
                      statusbar: false,
                      placeholder: "Start Typing...",
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help",
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
                    }}
                    onEditorChange={(content, editor) => {
                      setDetails({
                        ...details,
                        fullDescription: content,
                      });
                    }}
                    value={details.fullDescription}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                className="preview-btn text-center w-25"
                onClick={() => history.push("/discover")}
              >
                <span className="pr-2">
                  <FaEye />
                </span>
                Cancel
              </Col>
              <Col md={6}>
                <Button
                  className="submit-tef-story text-center"
                  // onClick={submitArticle}
                  disabled={isLoading}
                  type="submit"
                  style={{ width: "100%", display: "block" }}
                >
                  {isLoading ? <Loader color="light" /> : "Update Article"}
                </Button>
              </Col>
            </Row>
          </div>
        </form>
      </div>
      <EditArticleSuccess modal={modal} title={details.title} toggle={toggle} />
    </Layout>
  );
};

export default EditArticle;
