import React, { useState, useEffect, useContext } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import ExampleControlSlot from "./utils/ExampleControlSlot";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./CustomCalendar.css";
import { Context } from "src/store";
import { getAppointments } from "src/requests/MentorRequests";

const propTypes = {};

const Selectable = () => {
  const { dispatch } = useContext(Context);
  const [events, setEvents] = useState([]);
  // const [selectedEvent, setSelectedEvent] = useState("");

  useEffect(() => {
    _getAppointments();
  }, []);

  const _getAppointments = () => {
    getAppointments(dispatch).then((res) => {
      if (res && res.status === 200 && res && res.data !== undefined) {
        const { data } = res.data;
        const allEvents = [
          ...data.sentInvitations,
          ...data.receivedInvitations,
        ];
        const transformEventToCalendarFormat = allEvents.map((ev) => {
          return {
            id: ev.id,
            title: ev.title,
            start: new Date(ev.startDate),
            end: new Date(ev.endDate),
          };
        });
        setEvents(transformEventToCalendarFormat);
      }
    });
  };

  return (
    <div className="Selectable">
      <ExampleControlSlot.Entry waitForOutlet>
        <strong>
          Click an event to see more info, or drag the mouse over the calendar
          to select a date/time range.
        </strong>
      </ExampleControlSlot.Entry>

      <Calendar
        selectable
        localizer={momentLocalizer(moment)}
        events={events && events}
        // defaultView={Views.MONTH}
        scrollToTime={new Date(1970, 1, 1, 6)}
        defaultDate={new Date()}
        // onSelectEvent={(event) => setSelectedEvent(event.id)}
        onSelectSlot={() => {}}
        style={{ height: 500, backgroundColor: "#fff", marginBottom: "5rem" }}
        // popup={false}
        messages={{
          showMore: (target) => (
            <span
              className="ml-2"
              role="presentation"
              onClick={() => console.log("pope")}
            >
              {" "}
              ...{target} more
            </span>
          ),
        }}
      />
    </div>
  );
};

Selectable.propTypes = propTypes;

export default Selectable;
