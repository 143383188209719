class FormFieldModel {
  constructor(name, isRequired, formType, category = "general", regex = "") {
    this.name = name;
    this.isRequired = isRequired;
    this.formType = formType;
    this.regex = "";
    this.value =
      formType === "array" ? [] : formType === "boolean" ? false : "";
    this.errorMsg = "";
    this.previewUrl = ""; // for file types alone
    this.category = category;
    this.isValid = true;
    this.allowedFileSize = 10;

    return this;
  }

  setAllowedFileSize(size) {
    // enter size in megabyte
    this.allowedFileSize = size;
    return this;
  }

  uploadFileData(file) {
    const url = URL.createObjectURL(file);
    const fileSize = file.size / 1024 / 1024;

    if (fileSize > this.allowedFileSize) {
      this.value = null;
      this.previewUrl = url;
      this.errorMsg = "Maximum file size exceeded";
      this.isValid = false;
    } else {
      this.value = file;
      this.previewUrl = url;
      this.errorMsg = "";
      this.isValid = true;
    }
    return this;
  }

  setValue(val) {
    this.value = val;
    return this;
  }

  setValueAtIndex(i, val) {
    this.value[i] = val;
    return this;
  }

  setPreviewUrl(url) {
    this.previewUrl = url;
    return this;
  }

  updateFormType(val) {
    this.formType = val;
    return this;
  }

  setErrorMsg(msg = "Field cannot be blank") {
    this.isValid = false;
    this.errorMsg = msg;
    return this;
  }

  setValidity(bool) {
    this.isValid = bool;
    return this;
  }
}

export default FormFieldModel;
