import axios from "axios";
import { ProfileUrl, AuthURL, MentorURL } from "src/constants";
import { env } from "src/constants/env";

export const _truncateText = (value, maxLength) => {
  if (maxLength === null) {
    return value;
  } else {
    if (value.length > maxLength) {
      return value.substring(0, maxLength) + " ...";
    }
    return value;
  }
};

export const retrieveUserProfile = async (id) => {
  try {
    const res = await axios.get(`${ProfileUrl.GET_USER_PROFILE_URL}/${id}`);
    if (res && res.status === 200) {
      return Promise.resolve(res);
    }
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const getSurveyQuestions = async (id, audience, mentorshipId) => {
  try {
    const res = await axios({
      url: `${MentorURL.GET_SURVEY_QUESTIONS}/${id}/questions/audience/${audience}`,
      headers: {
        "mentorship-id": mentorshipId,
      },
    });
    if (res && res.status === 200) {
      return Promise.resolve(res);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getWeeklySurveyQuestions = async (id, audience) => {
  try {
    const res = await axios.get(
      `${MentorURL.GET_SURVEY_QUESTIONS}/${id}/weekly-questions/audience/${audience}`
    );
    if (res && res.status === 200) {
      return Promise.resolve(res);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const submitSurveyQuestion = async (payload) => {
  try {
    const res = await axios.post(MentorURL.SUBMIT_SURVEY_ANSWERS, payload);
    if (res && res.status === 201) {
      return Promise.resolve(res);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const retrieveUserInfo = async (id) => {
  const res = await axios.get(`${AuthURL.GET_USER_URL}/${id}`, {
    auth: {
      username: env.REACT_APP_CLIENT_ID,
      password: env.REACT_APP_CLIENT_SECRET,
    },
  });
  return res;
};

export const isMentor = (user) => {
  // if (user && user.scope) {
  //   let scope = user.scope;
  //   if (typeof scope === "string") {
  //     scope = scope.split(",");
  //     scope = [...scope];
  //     return scope.includes("tef-mentor");
  //   }
  //   return scope.includes("tef-mentor");
  // }

  // return user.hasOwnProperty("tef-mentor");
  return Object.prototype.hasOwnProperty.call(user, "tef-mentor");
};
