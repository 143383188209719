import React from "react";
// import DOMPurify from "dompurify";
import { AzureMP } from "react-azure-mp";

export const CONTENT_TYPES = {
  general: "general",
  azureMediaService: "azureMediaService",
  slide: "slide",
  video: "video",
};
const RenderContent = ({ content, contentType }) => {
  const render = () => {
    switch (contentType) {
      case CONTENT_TYPES.general:
        return (
          <section
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          ></section>
        );

      case CONTENT_TYPES.azureMediaService:
        return (
          <AzureMP
            src={[
              {
                src: content,
                type: "application/vnd.ms-sstr+xml",
              },
            ]}
          />
        );

      case CONTENT_TYPES.slide:
        return (
          <iframe
            style={{
              border: "1px solid #CCC",
              borderWidth: "1px",
              marginBottom: "5px",
              maxWidth: "100%",
              width: "100%",
              height: "500px",
            }}
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${content}`}
            frameBorder="0"
            marginWidth="0"
            marginHeight="0"
            scrolling="no"
            allowFullScreen="allowfullscreen"
          ></iframe>
        );

      case CONTENT_TYPES.video:
        return (
          <video controls="controls" width={"100%"}>
            <source src={content} type="video/mp4" />
            <source src={content} type="video/ogg" />
          </video>
        );

      default:
        return (
          <section
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          ></section>
        );
    }
  };
  return <>{render()}</>;
};

export default RenderContent;
