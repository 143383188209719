/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import { FaCheck } from "react-icons/fa";
import queryString from "query-string";
import styles from "./Content.module.scss";
import { useLocation } from "react-router-dom";
import { Context } from "src/store";
import {
  completedModule,
  completeLesson,
  getLessonContent,
  hasCompletedLesson,
  startLesson,
  startModule,
  getModuleCumulativeScore,
} from "src/requests/TrainingRequest";
import LMSQuestions from "src/modules/training/components/CognitiveTest/CognitiveTest";
import { Code } from "react-content-loader";
import { useCurrentUser } from "src/utils/Services/currentUser";
import DOMPurify from "dompurify";
import RenderContent from "./RenderContent";

const Content = ({
  quizInfo,
  clicked,
  onQuizClick,
  setLessonComplete,
  setModuleComplete,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showTest, setShowTest] = useState(false);
  const [contentDetails, setContentDetails] = useState({});
  const [lessonStatus, setLessonStatus] = useState(false);
  const [moduleScore, setModuleScore] = useState(0);
  const [numberOfAttempts, setNumberOfAttempts] = useState(0);
  const user = useCurrentUser();
  const qa = useLocation();
  const {
    moduleId,
    lessonId,
    lang,
    trainingEventId,
    title,
    quizId,
    moduleNo,
    lessonNo,
    started,
    lessonsCompleted,
    last,
    courseId,
  } = queryString.parse(qa.search);
  const { dispatch } = useContext(Context);

  const userStartLesson = async () => {
    const payload = {
      lessonId,
      trainingEventId,
    };
    setLessonStatus(false);
    const response = await startLesson(dispatch, payload);
    if (response.status === 200) {
      setLessonStatus(false);
    }
  };

  const isLessonCompleted = async () => {
    setLessonStatus(false);
    const payload = {
      lessonId,
      trainingEventId,
    };
    const response = await hasCompletedLesson(dispatch, payload);
    if (response.status === 200) {
      const {
        data: { completedOn },
      } = response.data;
      if (completedOn) {
        setLessonStatus(true);
        return true;
      }
    }

    return false;
  };

  const userCompleteLesson = async () => {
    try {
      const payload = {
        lessonId,
        trainingEventId,
      };
      if (!lessonStatus) {
        const response = await completeLesson(dispatch, payload);
        if (response.status === 200) {
          setLessonStatus(true);
          setLessonComplete({ status: true, no: lessonId });
          if (last) {
            await userCompleteModule();
          } else {
            setTimeout(() => {
              clicked(moduleNo, lessonNo, lessonsCompleted);
            }, 1000);
          }
        }
      } else {
        if (
          lessonsCompleted &&
          lessonsCompleted === "true" &&
          last &&
          last === "1"
        ) {
          await userCompleteModule();
        } else {
          setTimeout(() => {
            clicked(moduleNo, lessonNo, lessonsCompleted);
          }, 1000);
        }
      }
    } catch (error) {
      console.log(">>>>>>>userCompleteLesson Error", error);
    }
  };

  const userStartModule = async () => {
    try {
      const payload = {
        moduleId,
        trainingEventId,
      };
      await startModule(dispatch, payload);
    } catch (error) {
      console.log(">>>>>>>userStartModule Error", error);
    }
  };

  const userCompleteModule = async () => {
    try {
      const payload = {
        moduleId,
        trainingEventId,
      };
      const response = await completedModule(dispatch, payload);
      if (response.status === 200) {
        setModuleComplete({ status: true, no: moduleId });
        setTimeout(() => {
          quizId
            ? clicked(moduleNo, null, lessonsCompleted, true)
            : clicked(moduleNo, lessonNo, lessonsCompleted);
        }, 1000);
      } else {
        quizId
          ? clicked(moduleNo, null, lessonsCompleted, true)
          : clicked(moduleNo, lessonNo, lessonsCompleted);
      }
    } catch (error) {
      console.log("userCompleteModule Error", error);
    }
  };

  useEffect(() => {
    if (started === "0" || !started) {
      userStartModule();
    }
  }, [lessonId]);

  useEffect(() => {
    setIsLoading(true);
    if (!quizId) {
      onQuizClick({});
    }
    setShowTest(false);
    isLessonCompleted().then((result) => {
      const payload = { moduleId, lessonId, lang, trainingEventId };
      getLessonContent(dispatch, payload).then((res) => {
        const { data, status } = res;
        setContentDetails(data);
        if (status === 200 && !result) {
          userStartLesson();
        }
        setIsLoading(false);
      });
    });
  }, [moduleId, lessonId]);

  const getCumulativeAvgForModule = () => {
    getModuleCumulativeScore(user.userId, moduleId, trainingEventId)
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          setModuleScore(data.data.maxScore);
          setNumberOfAttempts(data.data.numberOfAttempts);
        } else {
          setModuleScore(0);
        }
      })
      .catch((e) => setModuleScore(0));
  };

  useEffect(() => {
    if (moduleId) {
      getCumulativeAvgForModule();
    }
  }, [moduleId]);

  return (
    <div className={`${styles.Content} notranslate`}>
      {!moduleId ? (
        ""
      ) : isLoading ? (
        <>
          <Code
            speed={1}
            backgroundColor={"#C0C0C0"}
            foregroundColor={"#F2F2F2"}
          />
          <Code
            speed={1}
            backgroundColor={"#C0C0C0"}
            foregroundColor={"#F2F2F2"}
          />
        </>
      ) : showTest ? (
        <LMSQuestions
          onContinue={userCompleteModule}
          changeState={setShowTest}
          clicked={clicked}
          quizInfo={quizInfo}
          onQuizClick={onQuizClick}
          getCumulativeAvgForModule={getCumulativeAvgForModule}
          numberOfAttempts={numberOfAttempts}
        />
      ) : (
        <>
          <div className={`${styles.Content__breadcrumbs} notranslate`}>
            TEF Training{" "}
            <span className={styles.Content__bread}>
              {" "}
              -&gt; {title && title} -&gt;{" "}
              {(contentDetails && contentDetails.title) || "quiz"}
            </span>
          </div>
          {quizId && (
            <div className="notranslate">
              Your cumulative score for this module :{" "}
              <strong>{Math.round(Number(moduleScore))}</strong>
            </div>
          )}
          <main className={`${styles.Content__main} notranslate`}>
            {Object.keys(quizInfo).length > 0 && quizId && (
              <>
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(quizInfo.instruction),
                  }}
                  className={styles.Content__instruction}
                ></p>
                <button
                  style={{ marginRight: "auto", marginLeft: "auto" }}
                  className={styles.Content__btn}
                  onClick={() => setShowTest(true)}
                >
                  {quizInfo.allowedAttempt > numberOfAttempts &&
                  numberOfAttempts > 0
                    ? "Retake quiz"
                    : "Proceed to quiz"}
                </button>
              </>
            )}
            {contentDetails && contentDetails.content && (
              <RenderContent
                content={contentDetails.content}
                contentType={contentDetails.contentType}
              />
            )}

            {Object.keys(quizInfo).length === 0 && (
              <div className={styles.Content__btnContainer}>
                <button
                  onClick={userCompleteLesson}
                  className={styles.Content__btn}
                >
                  go to next lesson
                </button>
                {lessonStatus && (
                  <span className={styles.Content__completed}>
                    <FaCheck color="#0db884" style={{ marginRight: "10px" }} />
                    completed
                  </span>
                )}
              </div>
            )}
          </main>
        </>
      )}
    </div>
  );
};

export default Content;
