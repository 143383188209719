import React from "react";
// import ContentLoader from "react-content-loader";
// import skeletonColor from "./SkeletonColor";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Col, Card, CardBody, Row } from "reactstrap";

export const RenderContentLoader = () => {
  const arr = [1, 1, 1];
  const contentArr = arr.fill(3);
  const contLoadArr = contentArr.map((c, idx) => (
    <Col
      key={idx}
      sm="12"
      md="4"
      className="mb-3"
      onClick={() => history.push("/alumni-opportunity-detail")}
    >
      <Card className="alumni__opportunity">
        <SkeletonTheme
        // color={skeletonColor.primary}
        // highlightColor={skeletonColor.secondary}
        >
          {" "}
          <Skeleton
            width={"100%"}
            height={150}
            // color={skeletonColor.secondary}
          />
        </SkeletonTheme>

        <CardBody className="p-1">
          <SkeletonTheme
          // color={skeletonColor.primary}
          // highlightColor={skeletonColor.secondary}
          >
            <Skeleton width={"100%"} height={10} />
            <Skeleton width={"100%"} height={10} />
            <Skeleton width={"100%"} height={10} />
            <Skeleton width={"100%"} height={10} />
          </SkeletonTheme>
        </CardBody>
      </Card>
    </Col>
  ));
  return <Row>{contLoadArr}</Row>;
};
const OpportunityContentLoader = () => {
  return (
    <>
      <SkeletonTheme
      // color={skeletonColor.primary}
      // highlightColor={skeletonColor.secondary}
      >
        <div className="absolute">
          <Skeleton width={"100%"} height={230} />
        </div>
      </SkeletonTheme>
      {RenderContentLoader()}
    </>
  );
};

export default OpportunityContentLoader;
