import React from "react";
import { Col } from "reactstrap";
import { TabContent, Tabs } from "src/components/CustomTab/CustomTab";
import "./MainContent.scss";
import AllConnections from "src/modules/Connections/AllConnections/AllConnections";
import Suggestions from "src/modules/Connections/Suggestions/Suggestions";
import Requests from "src/modules/Connections/Requests/Requests";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
// import { useHistory } from "react-router-dom";
// import SearchBar from "src/components/SearchBar/SearchBar";
// import { get_user_info_from_token } from "utils/someTokenMenthods";

const MainContent = ({
  currentTab,
  // toggle,
  // setCurrentTab,
  // role,
}) => {
  const user = get_user_info_from_token().user;
  // const history = useHistory();

  const renderTabContent = () => {
    return (
      <Tabs activeTab={currentTab}>
        <TabContent title="Suggestions">
          <Suggestions
          // sliceContentCount={6}
          // sliceContent
          // showMoreClick={() => history.push("/home")}
          />
        </TabContent>
        <TabContent title="Sent Requests">
          <Requests currentTab={currentTab} />
        </TabContent>
        <TabContent title="Received Requests">
          <Requests currentTab={currentTab} />
        </TabContent>
        <TabContent title="My Connections">
          <AllConnections userId={user.id} />
        </TabContent>
      </Tabs>
    );
  };

  return (
    <Col>
      <Col md={{ offset: 8 }}>
        {/* <SearchBar
          style={{ backgroundColor: "#fff" }}
          searchData={searchData}
          setSearchData={setSearchData}
        /> */}
      </Col>
      {renderTabContent()}
    </Col>
  );
};

MainContent.propTypes = {};

export default MainContent;
