import React, { Fragment } from "react";
import classes from "./ViewComment.module.css";

import Avatar from "src/components/Avatar/Avatar";

// import { Context } from "src/store";
import { EditorContentDisplay } from "src/components/Editor";
// import UserReact from "./UserReact";
// import ReplyComment from "./ReplyComment";
// import { postReply } from "src/requests/OpportunityRequest";

const ViewReplies = ({ opportunityId, userReplies: discussions }) => {
  return (
    <>
      <div className={classes.ViewComment}>
        {discussions &&
          discussions.map((comment, idx) => (
            <Fragment key={comment.id}>
              <div className={classes.UserInfo}>
                <div className={classes.UserImage}>
                  {comment.otherDetails && (
                    <Avatar
                      src={comment.otherDetails.imageUrl}
                      dimension="30px"
                      name={`${comment.otherDetails.firstName} ${comment.otherDetails.lastName}`}
                    />
                  )}
                </div>
                <div className={classes.Comment}>
                  <div className={classes.UserDetails}>
                    <div className={classes.UserName}>{`${
                      comment.otherDetails && comment.otherDetails.firstName
                    } ${
                      comment.otherDetails && comment.otherDetails.lastName
                    }`}</div>
                  </div>
                  <div className={classes.CommentBody}>
                    <EditorContentDisplay content={comment.note} readOnly />
                  </div>

                  {/* <UserReact
                    userReact={comment}
                    toggleReply={toggleReply}
                    key={comment.id}
                    idx={comment.id}
                  />
                  <ReplyComment
                    showReplyButton={showReplyButton}
                    idx={comment.id}
                    submitHandler={submitReply}
                    inputHandler={inputHandler}
                    commentData={replyData}
                    postingReply={postingReply}
                  /> */}
                </div>
              </div>
            </Fragment>
          ))}
      </div>
    </>
  );
};

export default ViewReplies;
