import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import "./TefMasterclass.scss";
// import { events } from "./TefMasterclassList";
import Title from "src/modules/Discover/components/Title/Title";
import { useHistory } from "react-router-dom";
import { getAllMasterClass } from "src/requests/MasterClassRequest";
import { Context } from "src/store";
import moment from "moment";
import ContentLoader from "react-content-loader";
import { imageValid } from "src/modules/Discover/helper";
import EmptyImage from "src/assets/img/courseImage.jpg";
import classes from "./TefMasterclass.module.css";
import Swal from "sweetalert2";
import ArticleNotFound from "src/modules/Alumni/Components/NotFound/ArticleNotFound";

const ReactContentLoader = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div
        style={{ backgroundColor: "#f3f3f3", height: "200px", width: "100%" }}
      ></div>
      <div
        style={{
          backgroundColor: "white",
          padding: "10px",
        }}
        // className="loader-item"
      >
        <ContentLoader
          speed={2}
          // width={324}
          // height={150}
          viewBox="0 0 400 160"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="8" rx="3" ry="3" width="300" height="30" />
          <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
          <rect x="0" y="56" rx="3" ry="3" width="300" height="6" />
          <rect x="0" y="72" rx="3" ry="3" width="300" height="6" />
          <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
          {/* <circle cx="20" cy="20" r="20" /> */}
        </ContentLoader>
      </div>
    </div>
  );
};
const TefMasterclass = ({ numberToReturn, handleSeeAll, filterOptions }) => {
  const history = useHistory();
  const [masterClasses, setMasterClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loading] = useState(false);
  const [pageDetails, setPageDetails] = useState({
    pageNumber: 1,
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const [masterclassLength, setMasterclassLength] = useState(0);
  const [sortString, setSortString] = useState("");
  const [refresh, setRefresh] = useState(false);
  const { dispatch } = useContext(Context);

  const handleMasterclassDetails = (item) => {
    history.push(`/discover/masterclass-details/${item.id}`);
  };

  useEffect(() => {
    _getAllMasterClass(1, false);
  }, [refresh]);

  useEffect(() => {
    _getAllMasterClass(1, false, sortString);
  }, [sortString]);

  const _onLoadMoreClick = () => {
    const pageNum = pageDetails.pageNumber + 1;
    _getAllMasterClass(pageNum, true);
  };
  const humanDate = (masterClass) => {
    return new Date(masterClass.date).toDateString();
  };
  const shortenMonthDate = (masterClass) => {
    return moment(masterClass.date, "YYY-MM-DD").format("MMM");
  };
  const shortenDayDate = (masterClass) => {
    return moment(masterClass.date, "YYY-MM-DD").format("DD");
  };
  const _getAllMasterClass = async (pageNumber, loadMore, sortString) => {
    try {
      const masterclasses = await getAllMasterClass(
        dispatch,
        pageNumber,
        sortString
      );
      const {
        data: { data, pager },
      } = masterclasses.data;
      if (data && data.length > 0) {
        setMasterclassLength(data.length);
        const renderedData = numberToReturn
          ? data.slice(0, numberToReturn)
          : data;
        if (loadMore) {
          const previousMasterClass = [...masterClasses];
          const newMasterClass = [...previousMasterClass, ...data];
          setMasterClasses(newMasterClass);
        } else {
          setMasterClasses(renderedData);
        }
        setPageDetails({ ...pageDetails, ...pager });
      } else {
        setMasterclassLength(0);
        setMasterClasses([]);
      }
      setIsLoading(false);
    } catch ({ response }) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          response && response.data
            ? response.data
            : "Something went wrong while fetching News",
        confirmButtonColor: "#da291c",
      }).then(() => setRefresh(!refresh));
    }
  };

  const getSortBy = (str) => {
    setSortString(str);
  };

  const RenderContentLoader = () => {
    const arr = [1, 1, 1, 1];
    const contentArr = arr.fill(4);
    const contLoadArr = contentArr.map((c, idx) => (
      <Col md={6} key={idx}>
        <ReactContentLoader />
      </Col>
    ));
    return <Row>{contLoadArr}</Row>;
  };
  return (
    <>
      {isLoading ? (
        RenderContentLoader()
      ) : masterClasses.length <= 0 ? (
        <ArticleNotFound />
      ) : (
        <div className="mt-3">
          <Title
            name="TEF Masterclass"
            seeAll="See All"
            seeAllAction={() => handleSeeAll("TEF MasterClass")}
            nameLength={masterclassLength}
            showFilterOptions={filterOptions}
            sortBy={getSortBy}
          />

          <div className="video-list-item p-3 mt-2">
            <Row>
              {masterClasses.map((item, index) => (
                <Col
                  md={6}
                  key={index}
                  onClick={() => handleMasterclassDetails(item)}
                >
                  <div className="mb-2 event-list">
                    <div className="post-list-item__image">
                      <img
                        src={
                          imageValid(item.featureImageUrl)
                            ? item.featureImageUrl
                            : EmptyImage
                        }
                        alt="Masterclass event"
                        className={classes.MasterclassBanner}
                      />
                    </div>
                    <Row className="p-3">
                      <Col md={3}>
                        <p className="event-month">{shortenMonthDate(item)}</p>
                        <h1 style={{ fontSize: "1.2rem" }}>
                          {shortenDayDate(item)}
                        </h1>
                      </Col>
                      <Col md={9}>
                        <div className="">
                          <h1>{item.title}</h1>
                        </div>
                        <div className="event-date">
                          <span>{humanDate(item)}</span>
                          <span className="px-1" style={{ fontSize: "1rem" }}>
                            &#46;
                          </span>
                          {/* <span>{item.location}</span> */}
                        </div>
                        {/* <div className="d-flex">
                          <img
                            src={item.userAvatar}
                            alt="avatar"
                            className="user-avartar mr-1"
                          />
                          <img
                            src={item.userAvatar}
                            alt="avatar"
                            className="user-avartar mr-1"
                          />
                          <img
                            src={item.userAvatar}
                            alt="avatar"
                            className="user-avartar mr-1"
                          />
                          <p className="mutual-count text-center pt-1">+3</p>
                        </div> */}
                      </Col>
                    </Row>
                  </div>
                </Col>
              ))}
            </Row>
            {pageDetails.hasNextPage && (
              <Row>
                <Button
                  disabled={loading}
                  onClick={_onLoadMoreClick}
                  className="loadmore-btn"
                >
                  Show more
                </Button>
              </Row>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TefMasterclass;
