import React, { useState, useContext, useEffect, useRef } from "react";
import { Col, Row, Button, Card, Badge } from "reactstrap";
import { Context } from "src/store";
import StepWizard from "react-step-wizard";
import Countdown, { zeroPad, calcTimeDelta } from "react-countdown";
import {
  getMcqQuestions,
  submitAssessment,
  verifyResult,
} from "src/requests/ApplicationRequest";
import moment from "moment";
import "./CognitiveTest.scss";
import queryString from "query-string";
// import Cloader from "src/modules/Application/components/CLoader";

import ApplicationProgressReport from "src/modules/Application/components/ApplicantProgressReport/ProgressReport";

import { sectionFourTranslations } from "src/modules/Application/instructions";
import { useTranslation } from "react-i18next";
import PageLoader from "src/modules/homepage/components/PageLoader/PageLoader";
import Loader from "react-loader-spinner";
import Layout from "src/modules/Application/components/Layout/Layout";
import CLoader from "src/modules/Application/components/CLoader";
import { _breakInChunck } from "src/utils/helper";
const CognitiveTest = (props) => {
  const { t } = useTranslation();
  // if (!localStorage.mcq || localStorage.mcq === undefined) {
  //   localStorage.mcq = "[]";
  // }

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const [levelModal, setLevelModal] = React.useState(false);
  // const [loader, setLoader] = React.useState(false);
  const [answers, setAnswers] = useState([]);
  const prevAnswer = usePrevious({ answers, setAnswers });
  const { state, dispatch } = useContext(Context);
  const query = props.history.location.search;
  const { applicationId, endDate, lang } = queryString.parse(
    props.history.location.search
  );
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const language = lang || "en";
  // const [submitLoaderColor, setSubmitLoaderColor] = useState("red");
  // const language = JSON.parse(localStorage.getItem("language"));
  // const { id: userId } = JSON.parse(localStorage.getItem("data")).user;

  function compare(arr1, arr2) {
    if (!arr1 || !arr2) return;

    let result;

    arr1.forEach((e1, i) =>
      arr2.forEach((e2) => {
        if (e1.length > 1 && e2.length) {
          result = compare(e1, e2);
        } else if (e1 !== e2) {
          result = false;
        } else {
          result = true;
        }
      })
    );

    return result;
  }

  useEffect(() => {
    if (answers && answers.length > 0) {
      if (
        localStorage.mcq &&
        compare(JSON.parse(localStorage.mcq), answers) === false
      ) {
        updateLocalStorage();
      }
    }
  }, [answers]);

  const _onContinueClick = () => {
    return props.history.push({
      pathname: `/application/declaration`,
      search: `?applicationId=${applicationId}&endDate=${endDate}&lang=${language}`,
    });
  };
  useEffect(() => {
    if (query) {
      const parsedQuery = queryString.parse(query);
      if (Object.keys(parsedQuery).length === 0 || !parsedQuery.applicationId) {
        props.history.replace("/programme/dashboard", {});
      }
    } else {
      props.history.replace("/programme/dashboard", {});
      props.history.go(1);
    }
    // setLoader(false);
  }, [props.history.location.search]);

  let date;
  if (localStorage.date) {
    date = calcTimeDelta(JSON.parse(localStorage.date));
  }
  // const startAssessment = () => {
  //   localStorage.start = true;
  //   setInstructionModal(!instructionModal);
  // };
  useEffect(() => {
    verifyResult(dispatch, applicationId).then((res) => {
      const {
        data: { data },
      } = res;
      if (data.status === "Completed") {
        props.history.replace(
          `/application/declaration?applicationId=${applicationId}&endDate=${endDate}&lang=${language}`,
          {}
        );
      } else {
        if (localStorage.mcq && JSON.parse(localStorage.mcq).length > 0) {
          setAnswers(JSON.parse(localStorage.mcq));
        } else {
          setLoadingQuestions(true);
          getMcqQuestions(dispatch, applicationId, language).then(() => {
            setLoadingQuestions(false);
          });
        }
      }
    });
  }, [language]);

  const handleSubmit = () => {
    setLoadingSubmit(true);
    const payload = JSON.parse(localStorage.personality).concat(
      JSON.parse(localStorage.mcq)
    );
    // const currentTime = Math.round(new Date().getTime() / 1000);
    const answerObject = [
      {
        answerableQuestions: payload,
      },
    ];
    localStorage.removeItem("start");
    localStorage.removeItem("count");
    localStorage.removeItem("date");
    submitAssessment(dispatch, answerObject, date.total, applicationId).then(
      (res) => {
        if (res.status === 200) {
          setLevelModal(true);
        }
        setLoadingSubmit(false);
        localStorage.removeItem("personality");
        localStorage.removeItem("mcq");
      }
    );
  };

  // const mcqQuestions = localStorage.mcq && JSON.parse(localStorage.mcq);
  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      // return <Completionist />;
      handleSubmit();
    } else {
      // Render a countdown
      return (
        <span className="timer">
          {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };
  const showTimer = () => {
    if (!localStorage.count) {
      localStorage.count = true;
      localStorage.date = moment(Date.now()).add(45, "m").valueOf();
    }
    if (date && date.completed === false) {
      return <Countdown date={Date.now() + date.total} renderer={renderer} />;
    }
  };

  const { userApplications } = state.Application;
  // eslint-disable-next-line no-unused-vars
  const applicant =
    userApplications &&
    userApplications.filter(
      (applicant) => applicant.applicationId === applicationId
    );

  // useEffect(() => {
  //   if (applicant && applicant.length > 0) {
  //     const { languageId } = applicant[0];
  //     localStorage.language = JSON.stringify(languageId);
  //   }
  // }, [applicant]);
  useEffect(() => {
    if (state.Application.Mcq) {
      setAnswers(state.Application.Mcq.answerableQuestions);
    }
  }, [state.Application]);

  const updateLocalStorage = () => {
    localStorage.mcq = JSON.stringify(answers);
  };

  const handleInputChange = (optionId, optionIndex) => {
    const index = answers.findIndex((answers) =>
      answers.options.find((option) => option.id === optionId)
    );
    const question = answers.find((answers) =>
      answers.options.find((option) => option.id === optionId)
    );

    const selectedOption = {
      ...question,
    };
    selectedOption.options.forEach((opt) => {
      opt.isSelected = false;
    });
    selectedOption.options[optionIndex].isSelected = true;

    // {
    //   ...option.options[optionIndex],
    //   isSelected: true,
    // },
    if (answers[index]) {
      // setAnswers([(answers[index] = selectedOption)]);
      const existingQuestions = [...answers];
      existingQuestions[index] = selectedOption;
      setAnswers(existingQuestions);
    }
  };

  const DisplayWrapper = (props) => {
    const { nextStep, previousStep, currentStep, totalSteps, children } = props;

    return (
      <>
        {children}
        <Row>
          {currentStep > 1 && (
            <Button
              color="secondary"
              onClick={previousStep}
              style={{ marginRight: "5%" }}
            >
              Previous
            </Button>
          )}
          {currentStep !== totalSteps && (
            <Button color="secondary" onClick={nextStep}>
              {t("Next")}
            </Button>
          )}
          {currentStep === totalSteps && (
            <button
              disabled={loadingSubmit}
              className="btn btn-outline-secondary subtest-btn"
              onClick={loadingSubmit ? () => {} : () => handleSubmit()}
              data-testid="continue-to-declarations"
              // onMouseEnter={() => setSubmitLoaderColor("white")}
              // onMouseLeave={() => setSubmitLoaderColor("red")}
            >
              {t("Continue to Declarations")}{" "}
              {loadingSubmit && (
                <span style={{ marginLeft: "5px" }}>
                  <Loader
                    type="Oval"
                    height={15}
                    width={15}
                    color="white"
                    className="loadingCogSubmit"
                  />
                </span>
              )}
            </button>
          )}
        </Row>
      </>
    );
  };

  const DisplayQuestions = ({ question, options, questionIndex }) => {
    const checkedOption =
      prevAnswer.answers &&
      prevAnswer.answers.length !== 0 &&
      prevAnswer.answers[questionIndex].options.find(
        (option) => option.isSelected === true
      );
    return (
      <Col>
        <Row>
          <Badge color="light">
            {" "}
            {questionIndex + 1} of {answers && answers.length}
          </Badge>
        </Row>
        <Row>
          {/* <p>
            {question} <br />
          </p> */}
          <div dangerouslySetInnerHTML={{ __html: `${question}` }}></div> <br />
        </Row>

        <Row>
          <form className="form">
            {/* <h2>Checkboxes</h2>
              <div class="inputGroup">
                <input id="option1" name="option1" type="checkbox" />
                <label for="option1">Option One</label>
              </div>

              <div class="inputGroup">
                <input id="option2" name="option2" type="checkbox" />
                <label for="option2">Option Two</label>
              </div>

              <h2>Radio Buttons</h2> */}
            {options &&
              options.map((option, index) => (
                <div className="inputGroup" key={index}>
                  <input
                    id={option.optionContent + question}
                    name="radio"
                    type="radio"
                    onChange={(e) => handleInputChange(option.id, index)}
                    checked={
                      checkedOption ? checkedOption.id === option.id : false
                    }
                  />
                  <label htmlFor={option.optionContent + question}>
                    {option.optionContent}
                  </label>
                </div>
              ))}
          </form>
        </Row>
      </Col>
    );
  };

  const custom = {
    enterRight: "none",
    enterLeft: "none",
    exitRight: "none",
    exitLeft: "none",
  };

  return (
    <Layout>
      <Col md={12} sm={12} xs={12} lg={12}>
        <h2 className="personality-heading">Cognitive Test</h2>
        <p className="personality-text">{t("Application_Overview")}</p>

        <Card className="personality-card">
          {answers.length < 1 ? (
            <PageLoader counts={1} />
          ) : (
            <span>
              <Row>
                <h3 className="personality-title">
                  <b>MultiChoice</b> Questions
                </h3>
              </Row>
              <Row>
                <p className="personality-sub">
                  {sectionFourTranslations[language || "en"]}
                </p>
              </Row>
              {loadingQuestions && <PageLoader />}
              <StepWizard transitions={custom}>
                {
                  language &&
                    localStorage.start &&
                    answers &&
                    _breakInChunck(answers, 5).map((questions, index) => {
                      return (
                        <DisplayWrapper key={index}>
                          {showTimer()}
                          {questions.map((question, i) => {
                            return (
                              <DisplayQuestions
                                key={i}
                                question={question.questionContent}
                                options={question.options}
                                questionIndex={answers.findIndex(
                                  (i) => i.id === question.id
                                )}
                              />
                            );
                          })}
                        </DisplayWrapper>
                      );
                    })
                  // questions && (<QuestionsLoop />)[1]
                }
              </StepWizard>
            </span>
          )}
        </Card>
        <ApplicationProgressReport
          showReport={levelModal}
          onReportClose={_onContinueClick}
          onSubmitClick={_onContinueClick}
          percentageComplete={90}
        />
        <CLoader
          additonalInfo="Please wait while the system process your response"
          loading={loadingSubmit}
        />
      </Col>
    </Layout>
  );
};
CognitiveTest.propTypes = {};

export default CognitiveTest;
