import React, { useEffect, useContext, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { getConnections } from "src/requests/ConnectionRequest";
import { Context } from "src/store";
// import ConnectionCard from "src/modules/Connections/Mentor/ConnectionCard";
// import shortid from "shortid";

import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import ConnectionCard from "src/modules/Connections/ConnectionCard/ConnectionCard";
import Empty from "src/assets/img/mentorship-empty.png";
import { ContentLoaderC } from "src/modules/Connections/Suggestions/Suggestions";
import createPersistedState from "use-persisted-state";

const useConnectionState = createPersistedState("myConnections");
const usePageDetailsState = createPersistedState("myConnectionsPageDetails");

const AllConnections = ({ userId }) => {
  const { dispatch } = useContext(Context);
  const [connections, setConnections] = useConnectionState([]);
  const [pageDetails, setPageDetails] = usePageDetailsState({
    pageNumber: 1,
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const [loading, setLoading] = useState(false);
  const data = get_user_info_from_token();
  const { id } = data.user;

  const _getConnections = (pageNumber, loadmore) => {
    setLoading(true);
    getConnections(dispatch, userId, pageNumber)
      .then((res) => {
        const { status, data } = res;
        if (status === 200 || status === 201) {
          if (loadmore) {
            const conns = [...connections];
            const updated = conns.concat(data.data.data);
            setConnections(updated);
          } else {
            setConnections(data.data.data);
          }
          setPageDetails({ ...pageDetails, ...data.data.page });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (userId !== id) {
      setConnections([]);
      setLoading(true);
    }
    _getConnections(1, false);
  }, [userId]);

  const fetchMoreConnections = () => {
    const pageNum = pageDetails.pageNumber + 1;
    _getConnections(pageNum, true);
  };

  const renderConnections = () => {
    return connections.length > 0 ? (
      connections.map((connection, i) => (
        <ConnectionCard
          key={i}
          userId={connection.userId}
          isConnected={true}
          connectionStatus={"Connected"}
          onConnect={() => {}}
          userDetails={{
            userId: connection.userId,
            firstName: connection.user.firstName,
            lastName: connection.user.lastName,
            email: connection.user.email,
            industryRole: connection.user.industryRole,
            country: connection.user.country,
            state: connection.user.state,
            city: connection.user.city,
            address: connection.user.address,
            language: connection.user.language,
            gender: connection.user.gender,
            imageUrl: connection.user.imageUrl,
            phone: connection.user.phone,
            createdOn: connection.user.createdOn,
            userInterests: connection.user.userInterests,
            bio: connection.user.bio,
          }}
          connectionId={connection.id}
          id={userId}
          loadingSendingRequest={false}
          showOptionDD={userId === id}
          showChatIcon={id !== connection.userId}
          refreshConnection={_getConnections}
        />
      ))
    ) : (
      <>
        {!loading && (
          <Col
            style={{ textAlign: "center", width: "100%" }}
            md={{ size: 6, offset: 3 }}
          >
            <br />
            <br />

            <img src={Empty} alt={"error"} />
            <h3 className="goals-heading">No Connections</h3>
            <br />
            <br />
          </Col>
        )}
      </>
    );
  };
  return (
    <div className="conn-main">
      {/* <Col> */}
      <Row className="conn-list">{renderConnections()}</Row>
      {loading && <ContentLoaderC />}

      {pageDetails.hasNextPage && (
        <Row>
          <Button
            disabled={loading}
            onClick={fetchMoreConnections}
            className="loadmore-btn"
          >
            Show more
          </Button>
        </Row>
      )}
    </div>
  );
};

AllConnections.propTypes = {};

export default AllConnections;
