import React, { useState, useEffect, useContext } from "react";
import Layout from "src/components/Layout/Layout";
import RightBar from "src/modules/profile/components/RightBar/RightBar";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import { Label } from "reactstrap";
import classes from "./Tools.module.css";
import ToolCard from "./components/ToolCard/ToolCard";
import { useHistory } from "react-router-dom";
import { Context } from "src/store";
import {
  getToolsCategories,
  filterToolList,
  getAllTools,
  getToolsBasedOnPopularity,
  getCategoryName,
} from "src/requests/ToolsRequest.js";
import { showLoading } from "./components/CLoader";
import { useTranslation } from "react-i18next";
import Empty from "src/modules/mentorship/Calendar/utils/Empty";
// import Slider from "./components/Slider/Slider";
import Swal from "sweetalert2";
import logoCluster from "src/assets/img/tools/toolsLogoClusters.png";

const Tools = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [tools, setTools] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noTools, setNoTools] = useState(false);

  useEffect(() => {
    _getAllCategories();
    _getToolsList();
  }, [reload]);

  const _getCategoryName = (categoryId) => {
    getCategoryName(dispatch, categoryId)
      .then((res) => {
        if (res && res.status === 200) {
          const { data } = res.data;
          setSelectedCategoryName(data.name);
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Somthing went wrong!",
          text: "Please try again later",
          confirmButtonColor: "#FC5A5A",
        });
      });
  };

  const _getAllCategories = () => {
    getToolsCategories(dispatch)
      .then((res) => {
        if (res.status && res.status === 200) {
          const { data } = res.data;
          const allCategories = [...data];
          const topCategories = allCategories.slice(0, 20);
          setCategories(topCategories.sort());
          setAllCategories(data);
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Somthing went wrong!",
          text: "Please try again later",
          confirmButtonColor: "#FC5A5A",
        });
      });
  };

  const _getToolsList = (pageNumber, pageSize) => {
    setLoading(true);
    getAllTools(dispatch, (pageNumber = 1), (pageSize = 100))
      .then((res) => {
        if (
          (res.status && res.status === 404) ||
          (res.status && res.status === 403)
        ) {
          setNoTools(true);
          setLoading(false);
        }
        if (res.status && res.status === 200) {
          const { data } = res.data;
          if (data.length < 1) {
            setNoTools(true);
            setLoading(false);
          } else {
            setTools(data);
            setLoading(false);
          }
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Somthing went wrong!",
          text: "Please try again later",
          confirmButtonColor: "#FC5A5A",
        });
      });
  };

  const _filterToolList = (categoryId) => {
    setSelectedCategory(categoryId);
    setNoTools(false);
    filterToolList(dispatch, categoryId)
      .then((res) => {
        if (res.status && res.status === 200) {
          const { data } = res.data;
          if (data.length > 0) {
            setTools(data);
          } else {
            setNoTools(true);
          }
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong!",
          text: "Please try again later",
          confirmButtonColor: "#FC5A5A",
        });
      });
    _getCategoryName(categoryId);
  };

  const handleSelectCategory = (e) => {
    // console.log(e.target.value)
    _filterToolList(e.target.value);
  };

  const _addTool = () => {
    history.push("/add-tool");
  };

  const refreshPage = () => {
    setReload(!reload);
    setSelectedCategory("");
  };

  const loadAllCategories = () => {
    setCategories(allCategories);
  };

  const _getToolsBasedOnPopularity = (e) => {
    if (e.target.value === "popularity") {
      setLoading(true);
      getToolsBasedOnPopularity(dispatch).then((res) => {
        if (res && res.status === 200) {
          const { data } = res.data;
          setTools(data);
          setLoading(false);
        }
      });
    } else {
      _getToolsList();
    }
  };

  return (
    <Layout SideContent={<RightBar />}>
      <div className={classes.MainContent}>
        <h1 className={classes.PageHeader}>{t("Tools")}</h1>
        <div className={classes.TopRow}>
          <div className={classes.TopRowLeft}>
            <h3 className={classes.HeaderText}>
              {" "}
              {t("A curated directory of usable tools to")}{" "}
              <span className={classes.HeaderTextRed}>{t("build")}</span>{" "}
              {t("your business")}
            </h3>
            <div className={classes.CtaButtons}>
              <div
                className={`${classes.GeneralButton} ${classes.RedButton}`}
                onClick={_addTool}
              >
                + {t("Add a Resource")}
              </div>
              {/* <div className={`${classes.GeneralButton} ${classes.OutlineButton}`}>
								{t('Start Exploring')}
							</div> */}
            </div>
          </div>
          <div className={classes.TopRowRight}>
            <img
              src={logoCluster}
              style={{ width: "100%" }}
              alt="Tools Logos"
            />
          </div>
          {/* <div className={classes.TopRowRight}>
            <Slider itemList={tools && tools} />
          </div> */}
        </div>
        {/* {noTools ? (
          <Empty message="No Tool has been added yet" />
        ) : ( */}
        <div className={classes.BottomRow}>
          <ul className={classes.CategoriesListColumn}>
            <li className={classes.Category} onClick={refreshPage}>
              All
            </li>
            {categories &&
              categories.map((cat) => (
                <li
                  key={cat.id}
                  className={`${classes.Category}
                            ${
                              selectedCategory &&
                              cat.id === selectedCategory &&
                              `${classes.SelectedCategory}`
                            }`}
                  onClick={() => _filterToolList(cat.id)}
                >
                  {cat.name} ({cat.numberOfTools})
                </li>
              ))}
            <li className={classes.Category} onClick={loadAllCategories}>
              Others
            </li>
          </ul>

          {noTools ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                left: "20rem",
              }}
            >
              <Empty message="List is empty" />
            </div>
          ) : (
            <div>
              <div className={classes.ToolTableTop}>
                <div
                  style={{ marginLeft: "0.5rem" }}
                  className={classes.ToolsInCategory}
                >
                  {selectedCategory === "" ? "All" : selectedCategoryName} (
                  {tools && tools.length.toLocaleString()})
                </div>
                <Label className={`${classes.Label} ${classes.BigScreen}`}>
                  <select
                    className={classes.NoOutline}
                    onChange={handleSelectCategory}
                  >
                    <option onClick={refreshPage}>All</option>
                    {allCategories &&
                      allCategories.map((category) => (
                        <option value={category.id} key={category.id}>
                          {category.name}
                        </option>
                      ))}
                  </select>
                </Label>
                <Label className={classes.Label} style={{ display: "flex" }}>
                  <span className={classes.SmallScreen}>Sort by:</span>
                  <select
                    className={classes.NoOutline}
                    onChange={_getToolsBasedOnPopularity}
                  >
                    <option value="recently" key="recently">
                      {t("Recently Added")}
                    </option>
                    <option value="popularity" key="popularity">
                      {t("Popularity")}
                    </option>
                  </select>
                </Label>
              </div>
              <div className={classes.ToolCards}>
                {/* {noTools ? <Empty message="No Tool has been added yet" /> : ""} */}
                {loading ? (
                  showLoading()
                ) : (
                  <ToolCard tools={tools} noTools={noTools} />
                )}
              </div>
            </div>
          )}
        </div>
        {/* )} */}
      </div>
    </Layout>
  );
};

export default ifOnboardedThenShow(Tools);
