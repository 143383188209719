import React from "react";
import { Link } from "react-router-dom";
import classes from "./LandingPage.module.scss";
import img1 from "src/assets/img/landingPage/map_girl_2_clipped.webp";
// import img2 from "src/assets/img/landingPage/slide_2.webp";
// import img3 from "src/assets/img/landingPage/slide_3.webp";
// import { BsArrowRight } from "react-icons/bs";
import { useCarousel } from "src/utils/useCarousel";
import { GrFacebookOption, GrLinkedinOption } from "react-icons/gr";
import { IoLogoTwitter, IoLogoWhatsapp } from "react-icons/io";
import { RiInstagramFill } from "react-icons/ri";
// import { Button } from "reactstrap";

const slides = [
  {
    title: "Empowering African Entrepreneurs",
    p:
      "Build capacity and connect with over 1million African entrepreneurs operationalise and scale your business.",
    img: img1,
    link: "/login",
    linkText: "APPLY NOW",
    // "APPLY FOR THE 2022 TONY ELUMELU FOUNDATION ENTREPRENEURSHIP PROGRAMME",
    redirect_page: "/programme/dashboard",
  },
  // {
  //   title: "Boundless Marketplace",
  //   p:
  //     "Access the African market through the TEFConnect Marketplace; connecting African entrepreneurs to businesses beyond borders.",
  //   img: img2,
  //   link: "/login",
  //   linkText: "GO TO THE MARKETPLACE",
  // },
  // {
  //   title: "Connect with Global Business Leaders",
  //   p:
  //     "Teach, learn and connect with a vast and diverse ecosystem of Mentors, Investors and Entrepreneurs, all in one place.",
  //   img: img3,
  //   link: "/login",
  //   linkText: "CONNECT NOW",
  // },
];

const LandingPageCarousel = () => {
  const { currentIndex, classname, snapToSlide } = useCarousel(
    slides.length,
    3000,
    classes.fadeOut,
    classes.fade
  );

  return (
    <div className={classes.carouselContainer}>
      {slides &&
        slides.length > 0 &&
        slides.map((slide, idx) => {
          if (idx === currentIndex) {
            return (
              <div
                key={idx}
                className={`${classes.slides} 
                ${classname}
                `}
              >
                <div className={classes.slides__left}>
                  <h4>{slide.title}</h4>
                  <p>{slide.p}</p>

                  <Link
                    to={slide.link}
                    onClick={() => {
                      localStorage.setItem(
                        "lastPageVisited",
                        slide.redirect_page
                      );
                    }}
                  ></Link>
                </div>
                <div
                  className={classes.slides__right}
                  style={{
                    backgroundImage: `url(${slide.img})`,
                  }}
                ></div>
              </div>
            );
          }
          return null;
        })}

      <div className={classes.indicatorContainer}>
        <div className={`${classes.indicatorC}`}>
          {slides.length > 1 &&
            slides.map((slide, idx) => {
              return (
                <div
                  className={`${classes.indicatorItem} ${
                    currentIndex === idx ? classes.active_indicator : ""
                    // classes.active_indicator
                  } `}
                  key={idx}
                  onClick={() => snapToSlide(idx)}
                >
                  <span>{idx + 1}</span>
                </div>
              );
            })}
        </div>

        <div style={{ clear: "both" }}></div>
        <ul className={classes.socialContainer}>
          <li>
            <a
              href="https://www.facebook.com/thetonyelumelufoundation"
              target="_blank"
              rel="noreferrer"
            >
              <GrFacebookOption size={24} />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/TonyElumeluFDN"
              target="_blank"
              rel="noreferrer"
            >
              <IoLogoTwitter size={24} />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/tonyelumelufoundation"
              target="_blank"
              rel="noreferrer"
            >
              <RiInstagramFill size={24} />
            </a>
          </li>
          <li>
            <a
              href="https://wa.me/23417006713"
              target="_blank"
              rel="noreferrer"
            >
              <IoLogoWhatsapp size={24} />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/tony-elumelu-foundation"
              target="_blank"
              rel="noreferrer"
            >
              <GrLinkedinOption size={24} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LandingPageCarousel;
