import React from "react";
import { Card } from "reactstrap";
import { FiEdit2 } from "react-icons/fi";

import "./ProfileCard.scss";
import { useParams } from "react-router-dom";
import Loader from "react-loader-spinner";
import { useCurrentUser } from "src/utils/Services/currentUser";
const ProfileCard = ({ title, children, onEditClick, loading, showEdit }) => {
  const user = useCurrentUser();
  const { id } = useParams();
  return (
    <Card className="profile-card">
      <div style={{}}>
        <h1>{title}</h1>
        {id === user.userId && showEdit && (
          <span className="edit-icon" onClick={onEditClick}>
            <FiEdit2 />
          </span>
        )}
      </div>

      <hr />
      {!loading ? (
        <div className="children-c">{children}</div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            margin: "20px",
          }}
        >
          <Loader type="Oval" height={45} width={45} color="red" />
        </div>
      )}
    </Card>
  );
};

ProfileCard.propTypes = {};
ProfileCard.defaultProps = {
  onEditClick: () => {},
  showEdit: true,
};

export default ProfileCard;
