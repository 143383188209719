import React from "react";
import PollCardContent from "src/modules/homepage/components/PollCard/PollCardV2/PollCardContent";
import PostCardContent from "./PostCardContent";
import classes from "./PostCard_v2.module.scss";
import PostHeader from "./PostHeader";

const PostCard_v2 = ({ post, index }) => {
  const { postType, isBookmarked, userId, profile, id, createdOn } = post;
  return (
    <div className={classes.container}>
      <PostHeader
        profile={profile}
        post_id={id}
        userId={userId}
        isBookmarked={isBookmarked}
        createdOn={createdOn}
        post={post.content}
      />
      {postType === "question" ? (
        <PollCardContent post={post} index={index} />
      ) : (
        <PostCardContent post={post} />
      )}
    </div>
  );
};
export default PostCard_v2;
