import React from "react";
import Layout from "src/components/Layout/Layout";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import Results from "./Results";

const Community = (props) => {
  return (
    <Layout hideRight={true}>
      <Results />
    </Layout>
  );
};

export default ifOnboardedThenShow(Community);
