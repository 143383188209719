import React from "react";
import Avatars from "src/components/Avatar/Avatar";
import classes from "./MasterclassUser.module.css";

const MasterclassUser = ({
  instructorFullName,
  instructorImageUrl,
  position,
  commentDate,
}) => {
  return (
    <div className={classes.MasterclassUser}>
      <div>
        <Avatars
          name={instructorFullName}
          dimension={40}
          src={instructorImageUrl}
        />
      </div>
      <div className={classes.UserText}>
        <div className={`notranslate ${classes.Username}`}>
          {instructorFullName}
        </div>
        <div className={classes.Position}>{position || commentDate}</div>
      </div>
    </div>
  );
};

export default MasterclassUser;
