import React, { useState, useEffect } from "react";
import { MdInsertPhoto, MdAttachFile } from "react-icons/md";

import { RiPencilLine } from "react-icons/ri";
import CreatePostForm from "./CreatePostModal/CreatePostForm";
import CreatePostImage from "./CreatePostModal/CreatePostImage";
import formStyles from "./CreatePostModal/CreatePostForm.module.scss";
import classes from "./CreatePost_v2.module.scss";
import { EditorBox } from "src/components/Editor";
import { Button, ModalBody, Modal } from "reactstrap";
import Avatars from "src/components/Avatar/Avatar";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { BsEmojiSmile } from "react-icons/bs";
import CreateQuestionForm from "src/modules/homepage/components/CreateQuestion/CreateQuestion_v2";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

const CreatePost_v2 = () => {
  const [activeOption, setActiveOption] = useState(0);
  const { firstName, lastName, imageUrl } = useCurrentUser();
  const [files, setFiles] = useState([]);
  const [openPostModal, setOpenPostModal] = useState(false);
  const [content, setContent] = useState("");

  const location = useLocation();

  const { shareInPost } = queryString.parse(location.search);

  const togglePostModal = (idx = null) => {
    setOpenPostModal(!openPostModal);
    _toggle_option(idx);
  };
  const clearFiles = () => {
    setFiles([]);
  };

  const _toggle_option = (idx) => {
    setActiveOption(idx);
  };
  const isActive = (idx) => {
    return activeOption === idx;
  };

  useEffect(() => {
    shareInPost && togglePostModal(0);
  }, [shareInPost]);

  const forms = {
    0: (
      <CreatePostForm
        files={files}
        switchUplaod={() => _toggle_option(1)}
        clearFiles={clearFiles}
        content={content}
        setContent={setContent}
        togglePostModal={togglePostModal}
      />
    ),
    1: (
      <CreatePostImage
        files={files}
        setFiles={setFiles}
        switchPost={() => _toggle_option(0)}
      />
    ),

    2: <CreateQuestionForm togglePostModal={togglePostModal} />,
  };
  const menuOptions = [
    {
      title: "Share an update",
      icon: (
        <RiPencilLine
          size={15}
          color="var(--tef-red)"
          className={`${classes.optionIcon} ${
            activeOption === 0 ? classes.activeOptionIcon : ""
          }`}
        />
      ),
    },
    {
      title: "Upload Media",
      icon: (
        <MdInsertPhoto
          size={15}
          className={`${classes.optionIcon} ${
            activeOption === 1 ? classes.activeOptionIcon : ""
          }`}
          color="#FFAC0F"
        />
      ),
    },
    {
      title: "Create a poll",
      icon: (
        <RiPencilLine
          size={15}
          color="var(--tef-red)"
          className={`${classes.optionIcon} ${
            activeOption === 2 ? classes.activeOptionIcon : ""
          }`}
        />
      ),
    },
  ];
  return (
    <div className={classes.container}>
      <div className={classes.optionContainer}>
        {menuOptions.map((menu, idx) => {
          return (
            <h4
              key={idx}
              className={`${idx === 0 ? classes.activeOption : ""}`}
              onClick={() => togglePostModal(idx)}
            >
              {menu.icon} {menu.title}
            </h4>
          );
        })}
      </div>

      <div
        className={formStyles.post__form}
        onClick={(e) => {
          // e.stopPropagation();
          togglePostModal(0);
        }}
      >
        <div className={formStyles.img}>
          <Avatars
            dimension={34}
            name={`${firstName} ${lastName}`}
            src={imageUrl}
          />
        </div>

        <EditorBox
          onEditorChange={() => {}}
          placeholder="What’s on your mind?"
          useEmoji
          readOnly
          className={formStyles.textField}
          optionsClassName={formStyles.options}
        />
      </div>
      <hr />

      <div
        style={{
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <div className={formStyles.buttons}>
          <BsEmojiSmile
            size={21}
            className={formStyles.attachF}
            style={{ transform: "none" }}
            onClick={() => togglePostModal(0)}
          />
          <MdAttachFile
            className={formStyles.attachF}
            size={21}
            onClick={() => togglePostModal(1)}
          />
          <Button onClick={() => togglePostModal(0)}>Post</Button>
        </div>
      </div>

      <Modal
        isOpen={openPostModal}
        size="lg"
        toggle={() => togglePostModal(null)}
      >
        <ModalBody>
          <div className={classes.optionContainer}>
            {menuOptions.map((menu, idx) => {
              return (
                <h4
                  key={idx}
                  className={`${isActive(idx) ? classes.activeOption : ""}`}
                  onClick={() => _toggle_option(idx)}
                >
                  {menu.icon} {menu.title}
                </h4>
              );
            })}
          </div>
          {forms[activeOption]}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CreatePost_v2;
