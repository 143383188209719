import React from "react";
import { Col, Row } from "reactstrap";
const MentorArea = ({ areas }) => {
  const displayAreas = () =>
    areas &&
    areas.map((area) => (
      <Col key={area.id} className="skills-item" md={6}>
        <Row>
          <Col className="skills-item__details" md={10}>
            <h3>{area.area}</h3>
          </Col>
        </Row>
        <hr />
      </Col>
    ));
  return (
    <Col>
      <Row>{displayAreas()}</Row>
    </Col>
  );
};

MentorArea.propTypes = {};

export default MentorArea;
