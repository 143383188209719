import React from "react";
import { usePageView } from "./utils/usePageViewHook";
import SentryRoute from "./SentryRoute";

const NoAuthRoute = ({ component: Component, ...rest }) => {
  usePageView();
  return <SentryRoute {...rest} render={(props) => <Component {...props} />} />;
};

export default NoAuthRoute;
