/* eslint-disable no-unused-vars */
import React, { useState, useContext, Fragment, useEffect } from "react";
import { BiPlus, BiSearch } from "react-icons/bi";
import { TiMessages } from "react-icons/ti";
import { FaEllipsisH } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "src/components/Loader/Loader";
import { Context } from "src/store";
import {
  joinGroup,
  leaveGroup,
  joinPrivateGroup,
} from "src/requests/CommunityRequest";
import queryString from "query-string";
import { useCurrentUser } from "src/utils/Services/currentUser";
import AllMembers from "src/modules/Community/components/AllMembers/AllMembers";
import MoreOptions from "src/modules/Community/components/MoreOptions/MoreOptions";
import ClickOutside from "react-click-outside-component";
import InviteFriendsModal from "src/modules/Community/components/InviteFriends/InviteFriendsModal";
import sendMessage from "src/utils/NewMessageHelper";
import { toast } from "react-toastify";
import { isRequest } from "src/modules/Community/components/SuggestedGroup/SuggestedGroupCard/SuggestedGroupCard";

const MobileSideInfo = ({
  creatorId,
  member,
  membersCount,
  groupType,
  groupTitle,
  inviteStatus,
  getGroupDetails,
}) => {
  const [joined, setJoined] = useState(member);
  const [left, setLeft] = useState(false);
  const [requested, setRequested] = useState(isRequest(inviteStatus));
  const [isJoinLoading, setIsJoinLoading] = useState(false);
  const [count, setCount] = useState(membersCount);
  const { dispatch } = useContext(Context);
  const { userId, email } = useCurrentUser();
  const qa = useLocation();
  const { groupId } = queryString.parse(qa.search);
  const [showMembers, setShowMembers] = useState(false);
  const [toggleMoreOptions, setToggleMoreOptions] = useState(false);
  const [toggleInviteFriendsModal, setToggleInviteFriendsModal] = useState(
    false
  );
  const history = useHistory();

  const handleMoreToggle = () => setToggleMoreOptions(!toggleMoreOptions);
  const [buttonText, setButtonText] = useState("Join Group");

  useEffect(() => {
    if (joined) {
      setButtonText("leave group");
    }
    if (left) {
      setButtonText("join group");
    }
    if (requested) {
      setButtonText("requested");
    }
  }, [joined, left, requested]);

  const handleJoinGroup = () => {
    setCount(count + 1);
    setIsJoinLoading(true);
    joinGroup(dispatch, { groupId, userId }).then((res) => {
      setIsJoinLoading(false);
      setJoined(true);
      setLeft(false);
      getGroupDetails();
    });
  };
  const handleLeaveGroup = () => {
    setCount(count - 1);
    setIsJoinLoading(true);
    leaveGroup(dispatch, { groupId, userId }).then((res) => {
      setIsJoinLoading(false);
      setLeft(true);
      setJoined(false);
      getGroupDetails();
    });
  };

  const handleRequestJoinGroup = () => {
    const payload = {
      groupId,
      inviteedByUserId: creatorId,
      inviteeUserId: userId,
      inviteeEmail: email,
    };
    setIsJoinLoading(true);
    joinPrivateGroup(dispatch, payload).then((res) => {
      setIsJoinLoading(false);
      setJoined(false);
      setLeft(false);
      if (res.status === 201) {
        setRequested(true);
        toast(
          "A request has been sent to the admin of this group '" +
            groupTitle +
            "'"
        );
      }
    });
  };

  const handleClick = () => {
    if (requested) return;
    joined
      ? handleLeaveGroup()
      : groupType === "private"
      ? handleRequestJoinGroup()
      : handleJoinGroup();
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between bg-white">
        <div className="text-center text-secondary mx-auto">
          <BiPlus />
          <div className="" onClick={handleClick}>
            {isJoinLoading ? <Loader color="light" /> : buttonText}
          </div>
        </div>
        <div className="text-center mx-auto">
          <TiMessages />
          <div onClick={() => sendMessage(creatorId, history)}> Message</div>
        </div>
        <div
          onClick={() => setShowMembers(!showMembers)}
          className="text-center mx-auto"
        >
          <BiSearch />
          <div>Members</div>
        </div>
        <div onClick={handleMoreToggle} className="text-center mx-auto">
          <FaEllipsisH />
          <div>More</div>
          <ClickOutside onClickOutside={() => setToggleMoreOptions(false)}>
            <MoreOptions
              userId={userId}
              groupOwnerId={creatorId}
              show={toggleMoreOptions}
              setToggleInviteFriendsModal={setToggleInviteFriendsModal}
              groupId={groupId}
            />
          </ClickOutside>
        </div>
      </div>

      {showMembers && (
        <Fragment>
          {groupType.toLowerCase() === "private" && !member ? (
            ""
          ) : (
            <div>
              <AllMembers
                backgroundColor="#fff"
                belongsToId={creatorId}
                userId={userId}
              />
            </div>
          )}
        </Fragment>
      )}
      <div>
        <InviteFriendsModal
          isOpen={toggleInviteFriendsModal}
          toggle={() => setToggleInviteFriendsModal(!toggleInviteFriendsModal)}
        />
      </div>
    </Fragment>
  );
};

export default MobileSideInfo;
