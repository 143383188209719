import React, { useState, useContext, useEffect } from "react";
/* eslint-disable no-unused-vars */

import { Row, Col, Button, Spinner } from "reactstrap";
import BankDetailsForm from "src/modules/Tef-Dms/components/Form/index";
import { Context } from "src/store";
import { postBankDetails, getBankDetails } from "src/requests/DmsRequest";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import jwt_decode from "src/utils/jwt-decode";
import Swal from "sweetalert2";
import NoDocuments from "src/assets/img/mentorship-empty.png";

import classes from "./BankDetails.module.css";
import { useCurrentUser } from "src/utils/Services/currentUser";

const BankDetails = () => {
  const [loader, setLoader] = useState(false);
  const [approvalDisplay] = useState(false);
  const { tefId, businessCountry, firstName, lastName } = useCurrentUser();
  const [formState, setFormState] = useState({
    bankName: "",
    bankAddress: "",
    beneficiaryAccountName: `${firstName} ${lastName}`,
    beneficiaryAccountNo: "",
    beneficiaryAddress: "",
    routingNumber: "",
    swiftCode: "",
    abaNumber: "",
    ibanNumber: "",
    sortCode: "",
    intermediaryBankName: "",
    intermediaryBankAddress: "",
    intermediaryBankSwiftCode: "",
    accountType: "",
    country: "",
    beneficiaryCountry: businessCountry,
    // tefId: tefId && tefId,
    additionalInfo: "",
    intermediaryBankAccountNo: "",
  });

  const { state, dispatch } = useContext(Context);

  const handleBankDetails = (e) => {
    e.preventDefault();
    setLoader(true);
    const tkn = localStorage.getItem("data");
    const token = JSON.parse(tkn).token;
    const { dms_user } = jwt_decode(token);
    const programId = dms_user[0];
    const payload = {
      ...formState,
      programId: programId,
      tefId: tefId,
    };

    postBankDetails(dispatch, payload)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setLoader(false);
          toast("Bank details updated successfully!! ✅");
          getBankDetails(dispatch);
        }
        return response;
      })
      .catch((error) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.status,
        });
        return error;
      });
  };

  const handleChange = (e) => {
    return setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    getBankDetails(dispatch);
  }, []);

  useEffect(() => {
    const bankDetails = state.dms && state.dms.bankDetails;
    if (bankDetails) {
      setFormState({
        ...formState,
        bankName: bankDetails.bankName,
        bankAddress: bankDetails.bankAddress,
        beneficiaryAccountName: bankDetails.beneficiaryAccountName,
        beneficiaryAccountNo: bankDetails.beneficiaryAccountNo,
        beneficiaryAddress: bankDetails.beneficiaryAddress,
        routingNumber: bankDetails.routingNumber,
        swiftCode: bankDetails.swiftCode,
        abaNumber: bankDetails.abaNumber,
        ibanNumber: bankDetails.ibanNumber,
        sortCode: bankDetails.sortCode,
        intermediaryBankName: bankDetails.intermediaryBankName,
        intermediaryBankAddress: bankDetails.intermediaryBankAddress,
        intermediaryBankSwiftCode: bankDetails.intermediaryBankSwiftCode,
        accountType: bankDetails.accountType,
        country: bankDetails.country,
        beneficiaryCountry: bankDetails.beneficiaryCountry,
        additionalInfo: bankDetails.additionalInfo,
        tefId: bankDetails.tefId,
        intermediaryBankAccountNo: bankDetails.intermediaryBankAccountNo,
      });
    }
  }, [state.dms.bankDetails]);

  // useEffect(() => {
  //   const seedCapitalApprovalStatus =
  //     state.dms && state.dms.seedCapital.approvalStatus;

  //     // remove block of code just below, no approval needed
  //   if (seedCapitalApprovalStatus === "APPROVED") {
  //     setApprovalDisplay(false);
  //   } else {
  //     setApprovalDisplay(true);
  //   }
  // }, [state.dms.seedCapital]);

  return (
    <>
      <div>
        {approvalDisplay && (
          <div className={classes.NoDocumentsContainer}>
            <img src={NoDocuments} className={classes.NoDocuments} />
            <h6>Your Seed Capital documents have not been approved yet.</h6>
          </div>
        )}
      </div>

      {approvalDisplay === false && (
        <Row>
          <Col md={12}>
            <div className="mt-5">
              <p
                className="pt-4"
                style={{ fontSize: "16px", fontWeight: "700" }}
              >
                Provide Business Account Details
              </p>
              <p>
                <small style={{ color: "#BDC3CC" }}>
                  You are required to fill in your valid business account
                  details. <br /> Please ensure all details are accurate and
                  complete.
                </small>
              </p>
              <p style={{ color: "var(--tef-red)" }}>
                Kindly adhere to the listed instructions to enable us disburse
                your seed capital seamlessly
              </p>
            </div>
            <form onSubmit={(e) => handleBankDetails(e)}>
              <div>
                <BankDetailsForm
                  getFormInputValue={handleChange}
                  bankName={formState.bankName}
                  beneficiaryAccountName={formState.beneficiaryAccountName}
                  accountType={formState.accountType}
                  bankAddress={formState.bankAddress}
                  beneficiaryAccountNo={formState.beneficiaryAccountNo}
                  beneficiaryAddress={formState.beneficiaryAddress}
                  routingNumber={formState.routingNumber}
                  abaNumber={formState.abaNumber}
                  sortCode={formState.sortCode}
                  intermediaryBankAddress={formState.intermediaryBankAddress}
                  swiftCode={formState.swiftCode}
                  ibanNumber={formState.ibanNumber}
                  intermediaryBankName={formState.intermediaryBankName}
                  intermediaryBankSwiftCode={
                    formState.intermediaryBankSwiftCode
                  }
                  country={formState.country}
                  beneficiaryCountry={formState.beneficiaryCountry}
                  additionalInfo={formState.additionalInfo}
                  intermediaryBankAccountNo={
                    formState.intermediaryBankAccountNo
                  }
                  // tefId={formState.tefId}
                />
              </div>

              <Row className="mt-5">
                <div className={classes.SubmitDocument}>
                  <Button type="submit" disabled={loader}>
                    {state.dms.bankDetails &&
                    Object.keys(state.dms.bankDetails).length > 0
                      ? "Edit"
                      : "Submit"}
                    {loader && (
                      <Spinner
                        color="#ffffff"
                        style={{
                          height: "1em",
                          width: "1em",
                          marginLeft: "1em",
                        }}
                      />
                    )}
                  </Button>
                </div>
              </Row>
            </form>
          </Col>
        </Row>
      )}
    </>
  );
};

export default BankDetails;
