import React, { useState, useContext } from "react";
import Avatar from "react-avatar";
import { Context } from "src/store";
import {
  joinGroup,
  leaveGroup,
  joinPrivateGroup,
} from "src/requests/StudyGroupsRequest";
import Loader from "src/components/Loader/Loader";
import "./SuggestedGroupCard.scss";
import { Link } from "react-router-dom";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { toast } from "react-toastify";
import queryString from "query-string";

const SuggestedGroupCard = ({
  groupLogo,
  id,
  title,
  members,
  location,
  belongs,
  creatorId,
  privacy,
  likeCount,
}) => {
  const { course } = queryString.parse(window.location.search);
  const { dispatch } = useContext(Context);
  const [joined, setJoined] = useState(belongs);
  // eslint-disable-next-line no-unused-vars
  const [left, setLeft] = useState(false);
  const [noOfMembers, setNoOfMembers] = useState(members);
  const linkTo = `/training/studygroups/group?groupId=${id}&course=${course}`;
  const [isJoinLoading, setIsJoinLoading] = useState(false);
  const { userId, email } = useCurrentUser();

  const handleJoinGroup = () => {
    setIsJoinLoading(true);
    joinGroup(dispatch, { groupId: id, userId }).then((_) => {
      setIsJoinLoading(false);
      setJoined(true);
      setLeft(false);
      setNoOfMembers(Number(noOfMembers + 1));

      toast("Successfully joined " + title);
    });
  };

  const handleRequestJoinGroup = () => {
    setIsJoinLoading(true);
    joinPrivateGroup(dispatch, {
      groupId: id,
      inviteeUserId: userId,
      inviteedByUserId: userId,
      inviteeEmail: email,
    }).then((res) => {
      setIsJoinLoading(false);
      setJoined(false);
      setLeft(false);
      // setNoOfMembers(noOfMembers + 1);
      toast(
        "A request has been sent to the admin of this group '" + title + "'"
      );
    });
  };

  const handleLeaveGroup = () => {
    setIsJoinLoading(true);
    leaveGroup(dispatch, { groupId: id, userId }).then((_) => {
      setIsJoinLoading(false);
      setLeft(true);
      setJoined(false);
      setNoOfMembers(Number(noOfMembers - 1));
      toast("Successfully left " + title);
    });
  };

  const handleClick = () => {
    joined
      ? handleLeaveGroup()
      : privacy === "private"
      ? handleRequestJoinGroup()
      : handleJoinGroup();
  };

  return (
    <div className="suggested-group-card">
      <div className="suggested-group-card__image">
        <Link to={linkTo}>
          {groupLogo && groupLogo !== "string" ? (
            <LazyLoadImage
              wrapperClassName="group-image-1"
              alt="group icon"
              effect="blur"
              src={groupLogo}
            />
          ) : (
            <Avatar name={title} size="100%" />
          )}
        </Link>
      </div>
      <div className="suggested-group-card__details">
        <h1 className="suggested-group-details-h1">
          <Link to={linkTo}>{title}</Link>
        </h1>
        <h3 className="suggested-group-details-h3">{location}</h3>
        <h3 className="suggested-group-details-h3">
          {" "}
          {noOfMembers > 1 ? `${noOfMembers} Members` : `${noOfMembers} Member`}
        </h3>
        <h3
          className="suggested-group-details-h3"
          style={{ textTransform: "lowercase" }}
        >
          {likeCount} like(s)
        </h3>

        <p className="suggested-group-details-h3">{privacy}</p>
        {userId !== creatorId && (
          <button
            className={`suggested-group-details-button ${
              joined
                ? "suggested-group-button-active"
                : "suggested-group-button-inactive"
            }`}
            onClick={handleClick}
            disabled={isJoinLoading}
          >
            {isJoinLoading ? (
              <Loader color="light" />
            ) : joined ? (
              "leave group"
            ) : privacy === "private" ? (
              "Request to join"
            ) : (
              "join group"
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default SuggestedGroupCard;
