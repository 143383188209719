import React, { useState, useEffect } from "react";
import { FormField } from "src/modules/onboarding/components";
import cancelIcon from "src/assets/img/cancel.png";
import ReactSelect from "react-select";

import shortId from "shortid";
import _ from "lodash";

import {
  getRanndomColor,
  isExistingSearchedItem,
} from "src/modules/onboarding/helper";
import { Button } from "reactstrap";
import { capitalize_Words } from "src/utils/helper";
import { useTranslation } from "react-i18next";

export const SuggestionBox = ({ suggestions, onItemClick, field }) => {
  return (
    <div className="filtered-items">
      {suggestions.length > 0 &&
        suggestions
          // .sort((a, b) => a[field] - b[field])
          .map((s) => {
            return (
              <span
                key={shortId.generate()}
                onClick={() => onItemClick(s)}
                style={{ color: getRanndomColor(["#0f6eff", "#40a386"]) }}
              >
                {s[field]}
                <hr />
              </span>
            );
          })}
    </div>
  );
};

export const NewItem = ({ onItemClick, value, buttonText }) => {
  return (
    <div className="new-item-c" onClick={onItemClick}>
      {value}
      <div className="add-new">+ Add new {buttonText}</div>
    </div>
  );
};

export const Skills = ({ userInfo, onChange, skills }) => {
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [newSkill, setNewSkill] = useState({ name: "" });
  const [showNewItemAdd, setShowNewItemAdd] = useState(false);
  const [options, setOptions] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (skills && skills.length > 0) {
      const sks = skills.map((skill) => {
        return { label: t(`${skill.name}`), value: skill.id };
      });
      setOptions([...sks, { label: t("Others"), value: "others" }]);
    }
  }, [skills]);

  const _onSuggestedItemClick = (value) => {
    const sgs = [...selectedSuggestions];
    const selectedSuggestionsIds = [...userInfo.userSkills];
    sgs.push(value);
    selectedSuggestionsIds.push({ skillId: value.id, name: value.name });

    setSelectedSuggestions(_.uniqBy(sgs, "id"));
    onChange("userSkills", _.uniqBy(selectedSuggestionsIds, "skillId"));
    setSearchString("");
  };

  const _onRemoveItemClick = (value) => {
    const skillIds = userInfo.userSkills.filter((skill) => {
      return skill.skillId !== value;
    });
    const filterSuggestion = selectedSuggestions.filter((s) => {
      return s.id !== value;
    });
    setSelectedSuggestions(filterSuggestion);
    onChange("userSkills", skillIds);
  };

  const _addNewSkill = () => {
    if (!searchString) {
      return;
    }
    const isExisitngUskill = isExistingSearchedItem(
      skills,
      searchString,
      "name"
    );
    if (isExisitngUskill) {
      _onSuggestedItemClick(isExisitngUskill);
      setNewSkill({ ...newSkill, name: "" });
      setSearchString("");
      setShowNewItemAdd(false);
    } else {
      const existingSkills = [...userInfo.newSkills];
      existingSkills.push({ name: capitalize_Words(searchString) });
      onChange("newSkills", _.uniqBy(existingSkills, "name"));
      setNewSkill({ ...newSkill, name: "" });
      setSearchString("");
      setShowNewItemAdd(false);
    }
  };

  const onAddNewSkillChange = (target, value) => {
    console.log(target);
    setSearchString(value);
  };

  const _onRemoveNewSkill = (index) => {
    const existingSkills = [...userInfo.newSkills];
    const filtered = existingSkills.filter((item, i) => {
      console.log(item !== i);
      return index !== i;
    });
    onChange("newSkills", _.uniqBy(filtered, "name"));
  };

  const _onSkillChange = (selected) => {
    const { label, value } = selected;
    const values = { id: value, name: label };
    if (values.id === "others") {
      setShowNewItemAdd(true);
    } else {
      _onSuggestedItemClick(values);
    }
  };

  return (
    <div className="pad-inner">
      <div className="filter-block">
        <FormField label="">
          <ReactSelect
            options={options}
            value={null}
            placeholder={t("Select")}
            onChange={_onSkillChange}
          />
        </FormField>
        {showNewItemAdd && (
          <div>
            <FormField
              // className="flex-grow"
              labelInfo="Optional"
              label="Add new"
              placeholder="Add new skill"
              // onChange={_handleSearchSkills}
              onChange={onAddNewSkillChange}
              value={searchString}
              onBlur={() => {
                if (searchString.length === 0) {
                  setShowNewItemAdd(false);
                }
              }}
            />
            <FormField label="">
              <Button
                className="secondary"
                style={{ marginTop: "-15px" }}
                onClick={_addNewSkill}
              >
                Add Skill
              </Button>
            </FormField>
          </div>
        )}
      </div>

      <div style={{ marginTop: "5px" }}></div>

      {userInfo.newSkills.length > 0 && (
        <div className="added-topics-items">
          {userInfo.newSkills.map((skill, i) => {
            return (
              <div
                className="added-topics-items__item"
                key={shortId.generate()}
              >
                <span>{skill.name}</span>
                <span
                  style={{
                    width: "12px",
                    height: "12px",
                    marginLeft: "19px",
                    cursor: "pointer",
                  }}
                  onClick={() => _onRemoveNewSkill(i)}
                >
                  <img
                    src={cancelIcon}
                    alt="icon"
                    className="img-fluid"
                    // style={{ alignSelf: "center" }}
                  />
                </span>
              </div>
            );
          })}
        </div>
      )}

      {userInfo.userSkills.length > 0 && (
        <div className="added-topics-items">
          {userInfo.userSkills.map((skill) => {
            return (
              <div
                className="added-topics-items__item"
                key={shortId.generate()}
              >
                <span>{skill.name}</span>
                <span
                  style={{
                    width: "12px",
                    height: "12px",
                    marginLeft: "19px",
                    cursor: "pointer",
                  }}
                  onClick={() => _onRemoveItemClick(skill.skillId)}
                >
                  <img src={cancelIcon} alt="icon" className="img-fluid" />
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
