import React, { useEffect, useContext, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import {
  getSuggestions,
  createConnection,
} from "src/requests/ConnectionRequest";
import { Context } from "src/store";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import ConnectionCard from "src/modules/Connections/ConnectionCard/ConnectionCard";
import Empty from "src/assets/img/mentorship-empty.png";
import { Facebook } from "react-content-loader";

import createPersistedState from "use-persisted-state";
import Swal from "sweetalert2";
import ErrorBoundaryComponentLevel from "src/utils/Services/ErrorBoundaryComponentLevel";
const useSuggestionState = createPersistedState("suggestedConnections");
const usePageDetailsState = createPersistedState(
  "suggestedConnectionsPageDetails"
);

export const ContentLoaderC = () => {
  const count = Array(4).fill();
  const loaders = count.map((_, index) => <Facebook key={index} />);
  return <div className="">{loaders}</div>;
};
const Suggestions = ({ sliceContent, sliceContentCount, showMoreClick }) => {
  const { dispatch } = useContext(Context);
  const [connected] = useState(false);
  const [suggestions, setSuggestions] = useSuggestionState([]);
  const [pageDetails, setPageDetails] = usePageDetailsState({
    pageNumber: 1,
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const data = get_user_info_from_token();
  const { id } = data.user;
  const [loading, setLoading] = useState(false);
  const [loadingConnect, setLoadingConnect] = useState(null);
  const [sentRequest, setSentRequest] = useState([]);

  const _getSuggestions = (pageNumber, loadmore) => {
    setLoading(true);
    getSuggestions(dispatch, id, pageNumber)
      .then((res) => {
        const { status, data } = res;
        if (status === 200 || status === 201) {
          if (loadmore) {
            const suggtns = [...suggestions];
            const updated = suggtns.concat(data.data.data);
            setSuggestions(updated);
          } else {
            setSuggestions(data.data.data);
          }
          setPageDetails({ ...pageDetails, ...data.data.page });
        }
        setLoading(false);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response
            ? err.response.data
            : "Something went wrong while getting suggestions",
          confirmButtonColor: "#FC5A5A",
        });
      });
  };

  useEffect(() => {
    _getSuggestions(1, false);
  }, []);

  const _onLoadMoreClick = () => {
    const pageNum = pageDetails.pageNumber + 1;
    _getSuggestions(pageNum, true);
  };
  const connect = (toUserId) => {
    setLoadingConnect(toUserId);

    createConnection(dispatch, id, toUserId).then((res) => {
      const sent = [...sentRequest];
      sent.push(toUserId);
      setSentRequest(sent);
      if (res.status === 200) {
        _getSuggestions(1, false);
        setLoadingConnect(null);
      }
    });
  };

  const renderConnections = () => {
    return suggestions && suggestions.length > 0 ? (
      <>
        {sliceContent && sliceContentCount
          ? suggestions.slice(0, sliceContentCount).map((connection, i) => {
              return (
                <ConnectionCard
                  key={i}
                  userId={connection.userId}
                  isConnected={connected}
                  onConnect={() => connect(connection.userId)}
                  userDetails={{
                    userId: connection.userId,
                    firstName: connection.firstName,
                    lastName: connection.lastName,
                    email: connection.email,
                    // industryRole: connection.industryRole,
                    // country: connection.country,
                    // state: connection.state,
                    // city: connection.city,
                    // address: connection.address,
                    // language: connection.language,
                    gender: connection.gender,
                    imageUrl: connection.imageUrl,
                    phone: connection.phone,
                    createdOn: connection.createdOn,
                    userInterests: connection.userInterests,
                    bio: connection.bio,
                  }}
                  loadingSendingRequest={loadingConnect === connection.userId}
                  connectionStatus={
                    sentRequest.includes(connection.userId)
                      ? "pending"
                      : "connect"
                  }
                />
              );
            })
          : suggestions.map((connection, i) => {
              return (
                <ErrorBoundaryComponentLevel key={i} buttonText={"reload"}>
                  <ConnectionCard
                    key={i}
                    userId={connection.userId}
                    isConnected={connected}
                    onConnect={() => connect(connection.userId)}
                    userDetails={{
                      userId: connection.userId,
                      firstName: connection.firstName,
                      lastName: connection.lastName,
                      email: connection.email,
                      // industryRole: connection.industryRole,
                      // country: connection.country,
                      // state: connection.state,
                      // city: connection.city,
                      // address: connection.address,
                      // language: connection.language,
                      gender: connection.gender,
                      imageUrl: connection.imageUrl,
                      phone: connection.phone,
                      createdOn: connection.createdOn,
                      userInterests: connection.userInterests,
                      bio: connection.bio,
                    }}
                    loadingSendingRequest={loadingConnect === connection.userId}
                    connectionStatus={
                      sentRequest.includes(connection.userId)
                        ? "pending"
                        : "connect"
                    }
                  />
                </ErrorBoundaryComponentLevel>
              );
            })}
      </>
    ) : (
      <>
        {!loading && (
          <Col style={{ textAlign: "center" }} md={{ size: 6, offset: 3 }}>
            <br />
            <br />

            <img src={Empty} alt={"error"} />
            <h3 className="goals-heading">No Suggestions</h3>
            <br />
            <br />
          </Col>
        )}
      </>
    );
  };
  return (
    <div className="conn-main">
      {/* <Col> */}
      <Row className="conn-list">{renderConnections()}</Row>
      {loading && <ContentLoaderC />}

      {pageDetails.hasNextPage && !sliceContentCount && (
        <Row>
          <Button
            disabled={loading}
            onClick={_onLoadMoreClick}
            className="loadmore-btn"
          >
            Show more
          </Button>
        </Row>
      )}

      {sliceContent && (
        <Row>
          <Button
            disabled={loading}
            onClick={showMoreClick}
            className="loadmore-btn"
          >
            Show more
          </Button>
        </Row>
      )}
      {/* </Col> */}
    </div>
  );
};

Suggestions.propTypes = {};
Suggestions.defaultProps = {
  sliceContent: false,
};

export default Suggestions;
