import React, { useState, useEffect, useContext } from "react";

import { Row, Col, Spinner } from "reactstrap";
import UploadDocument from "src/modules/Tef-Dms/components/UploadDocument";
import SubmitDocument from "src/modules/Tef-Dms/components/SubmitDocument/SubmitDocument";
import Documents from "src/modules/Tef-Dms/components/Documents";
import SubmitDocumentModal from "src/modules/Tef-Dms/components/Modals/SubmitDocumentModal/SubmitDocumentModal";
import { Context } from "src/store";
import NoDocuments from "src/assets/img/mentorship-empty.png";
import { getSettings } from "src/requests/DmsRequest";
import jwt_decode from "src/utils/jwt-decode";
import Swal from "sweetalert2";

import classes from "./SeedCapital.module.css";

const SeedCapital = ({
  handleUpload,
  handleDocumentSelect,
  handleSubmitForReview,
  loader,
  uploadLoader,
}) => {
  const [seedCapitalDocuments, setSeedCapitalDocuments] = useState([]);
  const [displaySeedCapital, setDisplaySeedCapital] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [seedCapitalStart, setStartDate] = useState("");
  const [seedCapitalEnd, setEndDate] = useState("");
  const [todaysDate, setToday] = useState("");
  const [seedCapitalDetail, setSeedCapitalDetail] = useState(null);

  const [
    seedCapitalRequiredDocuments,
    setSeedCapitalRequiredDocuments,
  ] = useState([]);

  const { state } = useContext(Context);
  useEffect(() => {
    const seedCapital = state.dms && state.dms.seedCapital;
    const seedCapitalDocuments =
      state.dms &&
      state.dms.seedCapital &&
      state.dms.seedCapital.seedCapitalDocuments;
    if (seedCapital) {
      setSeedCapitalDetail(seedCapital);
    }
    if (seedCapital && seedCapitalDocuments) {
      setSeedCapitalDocuments(seedCapitalDocuments);
    }
  }, [state.dms.seedCapital]);

  useEffect(() => {
    const seedCapitalRequiredDocuments =
      state.dms && state.dms.seedCapitalRequiredDocuments;
    if (seedCapitalRequiredDocuments) {
      setSeedCapitalRequiredDocuments(seedCapitalRequiredDocuments);
    }
  }, [state.dms.seedCapitalRequiredDocuments]);

  useEffect(() => {
    const tkn = localStorage.getItem("data");
    const token = JSON.parse(tkn).token;
    const { dms_user } = jwt_decode(token);
    const programId = dms_user[0];

    const _getSettings = async () => {
      try {
        const payload = {
          programId,
          submissionType: "SeedCapital",
        };
        const response = await getSettings(payload);
        const { data } = response.data;
        if (data && data.length <= 0) {
          setError(true);
          return;
        } else {
          const startDate = new Date(data[0].startDate);
          const endDate = new Date(data[0].endDate);
          const today = new Date();

          if (startDate && endDate) {
            setToday(today);
            setEndDate(endDate);
            setStartDate(startDate);
            if (startDate > today) {
              setDisplaySeedCapital(false);
              localStorage.setItem("seedCapitalStatus", "Not Started");
            } else {
              setDisplaySeedCapital(true);
              localStorage.setItem("seedCapitalStatus", "In Progress");
            }
          }

          return response;
        }
      } catch (error) {
        setError(true);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "The Admin has not provided the start and end date for seed capital phase",
          confirmButtonColor: "#da291c",
        });

        return error;
      }
    };

    _getSettings();
  }, []);

  const renderApprovaLoader = () => {
    return (
      <React.Fragment>
        {state.dms && state.dms.loadingBusinessPlans === true && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderApprovalMessage = () => {
    return (
      <React.Fragment>
        {state.dms &&
          state.dms.loadingBusinessPlans === false &&
          state.dms.businessPlan &&
          state.dms.businessPlan.approvalStatus !== "APPROVED" &&
          !error && (
            <div className={classes.NoDocumentsContainer}>
              <img src={NoDocuments} className={classes.NoDocuments} />
              <h6>Your Business Plans documents have not been approved yet.</h6>
              <h6>
                <span style={{ fontWeight: "700" }}>Status:</span>{" "}
                {state.dms.businessPlan.approvalStatus}
              </h6>
            </div>
          )}
      </React.Fragment>
    );
  };

  const renderSeedCapital = () => {
    return (
      <React.Fragment>
        {/* {state.dms &&
          !state.dms.loadingBusinessPlans &&
          state.dms.businessPlan &&
          state.dms.businessPlan.approvalStatus === "APPROVED" && ( */}
        <div>
          <Row>
            <Col md={12}>
              <UploadDocument
                handleDocumentSelect={handleDocumentSelect}
                documentTypes={seedCapitalRequiredDocuments}
                handleUpload={handleUpload}
                uploadLoader={uploadLoader}
                tabId={3}
              />
            </Col>
          </Row>
          {seedCapitalDocuments.length < 1 &&
            state.dms.loadingSeedCapitalDocuments && (
              <div className={classes.NoDocumentsContainer}>
                <Spinner color="dark" />
              </div>
            )}
          {!state.dms.loadingSeedCapitalDocuments &&
            seedCapitalDocuments.length < 1 && (
              <div className={classes.NoDocumentsContainer}>
                <img src={NoDocuments} className={classes.NoDocuments} />
                <h6>No documents available!</h6>
              </div>
            )}
          {seedCapitalDocuments.length > 0 &&
            !state.dms.loadingSeedCapitalDocuments && (
              <Row>
                <Col sm="12">
                  <Row className="mt-5">
                    {seedCapitalDocuments.map((document, index) => (
                      <Documents key={index} document={document} />
                    ))}
                  </Row>
                </Col>
              </Row>
            )}
          <Row className="mt-5">
            {isEndDatePassed(seedCapitalEnd, todaysDate) ? (
              <SubmitDocument
                buttonText="Submit"
                message="This phase has ended"
              />
            ) : (
              <SubmitDocument>
                <SubmitDocumentModal
                  loader={loader}
                  handleSubmitForReview={handleSubmitForReview}
                  isShowButton={showSubmitForReviewButton()}
                  isEditing={
                    seedCapitalDetail &&
                    seedCapitalDetail.approvalStatus &&
                    seedCapitalDetail.approvalStatus.toLowerCase() ===
                      "approved"
                  }
                />
              </SubmitDocument>
            )}
          </Row>
        </div>
        {/* )} */}
      </React.Fragment>
    );
  };

  const isWithinStartEndDate = (start, today) => {
    if (today >= start) {
      return true;
    }

    return false;
  };

  const showSubmitForReviewButton = () => {
    const seedCapitalDocuments =
      state.dms && state.dms.seedCapital.seedCapitalDocuments;
    const seedCapitalRequiredDocuments =
      state.dms && state.dms.seedCapitalRequiredDocuments;

    if (seedCapitalDocuments && seedCapitalRequiredDocuments) {
      if (
        seedCapitalDocuments?.length === seedCapitalRequiredDocuments.length
      ) {
        return true;
      }
    }

    return false;
  };

  const isEndDatePassed = (end, today) => {
    if (today > end) {
      return true;
    }

    return false;
  };

  return (
    <>
      <div>
        {displaySeedCapital === false && (
          <div className={classes.NoDocumentsContainer}>
            <img src={NoDocuments} className={classes.NoDocuments} />
            <h6>This phase has not commenced yet</h6>
          </div>
        )}
      </div>

      <div>
        {error && (
          <div className={classes.NoDocumentsContainer}>
            <img src={NoDocuments} className={classes.NoDocuments} />
            <h6>This phase has not commenced yet</h6>
          </div>
        )}
      </div>

      {isWithinStartEndDate(seedCapitalStart, todaysDate) && (
        <>
          <div>{renderApprovaLoader()}</div>
          <div>{renderApprovalMessage()}</div>
          <div>{renderSeedCapital()}</div>
        </>
      )}
    </>
  );
};

export default SeedCapital;
