import React, { useEffect } from "react";
import classes from "./ImageSelectModal.module.scss";
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from "reactstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { FileViewer } from "src/components/FileViewer/FileViewer";

const ImageSelectModal = ({
  isOpen,
  triggerExplorer,
  toggle,
  files,
  onRemoveFile,
  switchPost,
  setFiles,
}) => {
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        triggerExplorer();
      }, 300);
    }
  }, [isOpen]);

  const onSubmit = () => {
    toggle();
    switchPost();
  };

  const onCancel = () => {
    setFiles([]);
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      className={classes.container}
      // centered
      size="lg"
      toggle={toggle}
    >
      <ModalHeader className={classes.header}>
        <h4>SELECT / EDIT YOUR MEDIA</h4>
        <AiFillCloseCircle
          color="var(--tef-red)"
          className={classes.closeIcon}
          onClick={onCancel}
        />
        <div style={{ clear: "both" }}></div>
      </ModalHeader>
      <ModalBody className={classes.file_info}>
        {files.length === 0 && (
          <h5 onClick={triggerExplorer}>Select images to share</h5>
        )}
        <FileViewer
          files={files}
          write
          showAddMore
          onAddImageClick={triggerExplorer}
          onFileRemoveClick={onRemoveFile}
        />
        <span>Upload a Thumbnail (jpg, gif, png only)</span>
      </ModalBody>
      <ModalFooter className={classes.footer}>
        <Button onClick={onSubmit}>Done</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ImageSelectModal;
