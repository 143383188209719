import { asyncActionName } from "src/utils/AsyncUtils";
import { FILE_UPLOAD, FILE_UPLOAD_PROGRESS } from "src/actions";
import { initialState } from "src/store";

const FileUpload = (state = initialState.fileUpload, action) => {
  switch (action.type) {
    case asyncActionName(FILE_UPLOAD).loading:
      return { ...state, requestingFileUpload: action.payload };
    case asyncActionName(FILE_UPLOAD).success:
      return {
        ...state,
        files: action.payload,
      };

    case asyncActionName(FILE_UPLOAD_PROGRESS).success:
      return {
        ...state,
        uploadPrgoress: action.payload,
      };
    case asyncActionName(FILE_UPLOAD).failure:
      return {
        ...state,
        fileUploadError: action.payload.status,
      };

    default:
      return state;
  }
};
export default FileUpload;
