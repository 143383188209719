import React from "react";

import FormInput from "src/modules/Tef-Dms/components/Form/FormInput";
import { Button } from "reactstrap";
import FormSelect from "src/modules/Tef-Dms/components/Form/FormSelectOption";

const ExecutiveSummaryForm = ({ executiveSummary, setExecutiveSummary }) => {
  const handleChange = (e) => {
    setExecutiveSummary({
      ...executiveSummary,
      [e.target.name]: e.target.value,
    });
  };

  const handleDynamicInput = (key, i, e) => {
    const newArray = key;
    newArray[i] = e.target.value;
    setExecutiveSummary({
      ...executiveSummary,
      [key]: newArray,
    });
  };

  const addRow = (key, value) => {
    const newArray = key;
    newArray.push(value);
    setExecutiveSummary({
      ...executiveSummary,
      [key]: newArray,
    });
  };

  const stageOfBusiness = [
    { title: "Idea (Concept)" },
    { title: "Testing (Research and Development)" },
    { title: "Infancy (Market Entry)" },
    { title: "Growth" },
  ];

  const typeOfBusinessOwnership = [
    { title: "Sole Proprietorship" },
    { title: "Partnership" },
    { title: "Limited Liability Company" },
    { title: "Corporation" },
  ];

  return (
    <React.Fragment>
      <FormInput
        label="Business Name"
        getFormInputValue={(e) => handleChange(e)}
        value={executiveSummary.businessName}
        name="businessName"
        placeholder="Type Business Name here"
      />

      <FormSelect
        label="Stage of Business"
        getFormInputValue={(e) => handleChange(e)}
        placeholder="--Select Stage--"
        name="stageOfBusiness"
        value={executiveSummary.stageOfBusiness}
        options={stageOfBusiness}
      />

      <FormInput
        label="Address of Business"
        getFormInputValue={(e) => handleChange(e)}
        value={executiveSummary.addressOfBusiness}
        name="addressOfBusiness"
        placeholder="Type Address of Business here"
      />

      <FormInput
        label="Value Proposition"
        getFormInputValue={(e) => handleChange(e)}
        value={executiveSummary.valueProposition}
        name="valueProposition"
        placeholder="Type Value Proposition here"
      />

      <FormInput
        label="Target Market"
        getFormInputValue={(e) => handleChange(e)}
        value={executiveSummary.targetMarket}
        name="targetMarket"
        placeholder="Type Target Market here"
      />

      <FormInput
        label="Marketing Approach"
        getFormInputValue={(e) => handleChange(e)}
        value={executiveSummary.marketApproach}
        name="marketApproach"
        placeholder="Type Market Approach here"
      />

      <FormInput
        label="Objective"
        getFormInputValue={(e) => handleChange(e)}
        value={executiveSummary.objective}
        name="objective"
        placeholder="Type Objectives here"
      />

      <FormInput
        label="Differentiation"
        getFormInputValue={(e) => handleChange(e)}
        value={executiveSummary.diffrentiation}
        name="diffrentiation"
        placeholder="Type Differentiation here"
      />

      <FormSelect
        label="Type of Business Ownership:"
        getFormInputValue={(e) => handleChange(e)}
        placeholder="--Type of Ownership--"
        name="typeOfOwnership"
        value={executiveSummary.typeOfOwnership}
        options={typeOfBusinessOwnership}
      />

      <div className="mb-3">
        {executiveSummary.businessOwner.map((item, index) => (
          <FormInput
            key={index}
            getFormInputValue={(e) =>
              handleDynamicInput(executiveSummary.businessOwner, index, e)
            }
            label="Business Owner"
            placeholder=""
          />
        ))}
        {executiveSummary.businessOwner.length > 0 && (
          <Button
            type="button"
            onClick={() => addRow(executiveSummary.businessOwner, "")}
            style={{ fontSize: "0.75em" }}
            className="btn btn-primary text-white px-2 py-1 border-0 mt-2"
          >
            Add Another Owner
          </Button>
        )}
        {executiveSummary.businessOwner.length < 1 && (
          <Button
            type="button"
            onClick={() => addRow(executiveSummary.businessOwner, "")}
            style={{ fontSize: "0.75em" }}
            className="btn btn-primary text-white px-2 py-1 border-0 mt-2"
          >
            Add Owner
          </Button>
        )}
      </div>

      <div className="mb-3">
        {executiveSummary.businessOwnerProfile.map((item, index) => (
          <FormInput
            key={index}
            label="Business Owner(s) Profile"
            getFormInputValue={(e) =>
              handleDynamicInput(
                executiveSummary.businessOwnerProfile,
                index,
                e
              )
            }
            value={item}
            placeholder=""
          />
        ))}

        <Button
          type="button"
          onClick={() => addRow(executiveSummary.businessOwnerProfile, "")}
          style={{ fontSize: "0.75em" }}
          className="btn btn-primary text-white px-2 py-1 border-0 mt-2"
        >
          Add Owner Profile
        </Button>
      </div>

      <FormInput
        label="Vision Statement"
        getFormInputValue={(e) => handleChange(e)}
        value={executiveSummary.visionStatement}
        name="visionStatement"
        placeholder=""
      />
      <FormInput
        label="Mission Statement"
        getFormInputValue={(e) => handleChange(e)}
        value={executiveSummary.missionStatement}
        name="missionStatement"
        placeholder=""
      />
      <FormInput
        label="Funding Requirement"
        getFormInputValue={(e) => handleChange(e)}
        value={executiveSummary.fundingRequirement}
        name="fundingRequirement"
        placeholder=""
      />
      <FormInput
        label="Use of Funding Requirement"
        getFormInputValue={(e) => handleChange(e)}
        value={executiveSummary.useOfFundingRequirement}
        name="useOfFundingRequirement"
        placeholder=""
      />
      <FormInput
        label="Sales Turnover Amount"
        getFormInputValue={(e) => handleChange(e)}
        value={executiveSummary.salesTurnoverAmount}
        name="salesTurnoverAmount"
        placeholder=""
      />
      <FormInput
        label="Gross Profit Amount"
        getFormInputValue={(e) => handleChange(e)}
        value={executiveSummary.grossProfitAmount}
        name="grossProfitAmount"
        placeholder=""
      />
      <FormInput
        label="Net Profit"
        getFormInputValue={(e) => handleChange(e)}
        value={executiveSummary.netProfit}
        name="netProfit"
        placeholder=""
      />
      <div className="mb-3">
        {executiveSummary.risks.map((item, index) => (
          <FormInput
            key={index}
            getFormInputValue={(e) =>
              handleDynamicInput(executiveSummary.risks, index, e)
            }
            value={item}
            label="Risks"
            placeholder=""
          />
        ))}

        <Button
          type="button"
          onClick={() => addRow(executiveSummary.risks, "")}
          style={{ fontSize: "0.75em" }}
          className="btn btn-primary text-white px-2 py-1 border-0 mt-2"
        >
          Add Risks
        </Button>
      </div>
    </React.Fragment>
  );
};

export default ExecutiveSummaryForm;
