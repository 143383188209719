import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { RiArrowRightFill } from "react-icons/ri";
import { useParams, useHistory } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import { toast } from "react-toastify";
import Layout from "src/components/Layout/Layout";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
// import SecondaryButton from "src/modules/Alumni/Components/SecondaryButton/index";
import OurPick from "./components/OurPick";
import Avatar from "src/components/Avatar/Avatar";
import CustomLoader from "src/modules/Alumni/Components/CustomLoader/index";
import { getResourceById, getResources } from "src/requests/AlumniRequest";
import CommentBody from "src/modules/Alumni/Resources/components/CommentBody/index";
import ResourceModel from "src/modules/Alumni/models/ResourceModel";

import "./NewsView.scss";

const NewsView = () => {
  const history = useHistory();
  const { resourceId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [resources, setResources] = useState([]);
  const [isRecommendedLoading, setIsRecommendedLoading] = useState(false);
  const [resource, setResource] = useState(new ResourceModel());
  const localDomain = "https://tefconnect.com";
  let isMounted;

  useEffect(() => {
    isMounted = true;
    _getLearningResourceById();
    return () => (isMounted = false);
  }, [resourceId]);

  const _getRecommendedResources = () => {
    if (isMounted === true) setIsRecommendedLoading(true);
    getResources("FilteredBy=news&SearchBy=Our Picks&MaxItem=3")
      .then((res) => {
        const { data } = res.data.data;
        const __resources = [];

        data.forEach((res) => {
          if (res.id !== resourceId) {
            const _resource = new ResourceModel();
            _resource.updateProperties(res);
            __resources.push(_resource);
          }
        });

        if (isMounted === true) {
          setResources(__resources);
          // setPageCount(pager.pageCount);
          setIsRecommendedLoading(false);
        }
      })
      .catch(() => {
        if (isMounted === true) setIsRecommendedLoading(false);
        toast("Something went wrong, try again", { type: toast.TYPE.ERROR });
      });
  };

  const _getLearningResourceById = () => {
    if (isMounted === true) setIsLoading(true);
    getResourceById(resourceId)
      .then((res) => {
        const { data } = res.data;
        const newResource = new ResourceModel();
        newResource.updateProperties(data);

        if (isMounted === true) setResource(newResource);
        _getRecommendedResources();
        if (isMounted === true) setIsLoading(false);
      })
      .catch((e) => {
        if (isMounted === true) setIsLoading(false);
        history.push(`/alumni/resources`);
        toast("Something went wrong, try again");
      });
  };

  return (
    <Layout SideContent={<SidebarRight />}>
      {isLoading && <CustomLoader fullScreen={true} />}
      <div className="news-view-body">
        <div className="navigation">
          <span className="navigation__content">
            <b
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/alumni/resources?tab=News")}
            >
              Alumni Resource News
            </b>
            <RiArrowRightFill style={{ marginRight: 5 }} />
            {resource.title}
          </span>
        </div>

        <Row style={{ marginTop: 15 }}>
          <Col md={8}>
            <div className="page_description">
              <h3>{resource.title}</h3>
              <div className="d-flex news-author-container">
                <Avatar dimension={40} />
                <span className="pl-2 author-text">
                  By <b> {resource.author || "KATE MBALA"}</b> -{" "}
                  {resource.createdOn.toLocaleDateString()}
                </span>
                <label className="author-comment-count">
                  {resource.commentCount} comments
                </label>
              </div>
              <div className="news-view-share-buttons text-center d-flex mb-2">
                <FacebookShareButton
                  url={localDomain}
                  quote={"news.shortDecription"}
                >
                  <FacebookIcon size={20} />
                  <span className="share_buttons_text">Tweet</span>
                </FacebookShareButton>
                <TwitterShareButton
                  url={localDomain}
                  title={"news.shortDecription"}
                >
                  <TwitterIcon size={20} />
                  <span className="share_buttons_text">Tweet</span>
                </TwitterShareButton>
                <LinkedinShareButton
                  url={localDomain}
                  title={"news.shortDecription"}
                >
                  <LinkedinIcon size={20} />
                  <span className="share_buttons_text">Tweet</span>
                </LinkedinShareButton>
              </div>
              <div className="news-view-actual-content">
                <img src={resource.fileUrl} width="100%" height={300} />
                <p
                  style={{ marginTop: "20" }}
                  dangerouslySetInnerHTML={{ __html: resource.description }}
                ></p>
                {/* <Badge color="tef-grey" style={{ padding: 10 }}>
                  09:32
                </Badge> */}
              </div>
            </div>
            {/* COMMENT SECTION */}
            <div className="news-comment-area">
              <CommentBody />
            </div>
          </Col>
          <Col md={4} className="news-recommended-container pl-0 pr-0">
            <OurPick
              resources={resources}
              singlePostPerLine={true}
              onLoadData={() => {}}
              isLoading={isRecommendedLoading}
              onClick={(data) =>
                history.push(`/alumni/resources/news-view/${data.id}`)
              }
              ErrorEmpPage={() => (
                <div className="mt-2">
                  <span>No Data Here</span>
                </div>
              )}
            />
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default ifOnboardedThenShow(NewsView);
