import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useOuterClick } from "src/utils/helper";
export const OptionsDropDown = ({ children, show, toggle }) => {
  const [openDD, setOpenDD] = useState(false);
  const clickOut = useOuterClick(() => {
    toggle();
  });

  return (
    <>
      {show && (
        <div className="op-fab-c" ref={clickOut}>
          <div className="op-fab" onClick={() => setOpenDD(!openDD)}>
            <BsThreeDotsVertical />
          </div>
          {openDD && (
            <div className="op-dd-c">
              <div className="op-dd">
                <ul>{children}</ul>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
