import React from "react";

export const SelectBox = ({
  icon,
  value,
  className,
  selected,
  onSelect,
  name,
  label,
  disabled,
  nullable,
}) => {
  return (
    <>
      {nullable ? (
        <div
          className={`select-box-c `}
          onClick={
            disabled
              ? () => {}
              : () => {
                  onSelect(name, value);
                }
          }
        >
          {label.substr(0, 1).toUpperCase() + label.substr(1)} {icon && icon}
        </div>
      ) : (
        <div
          className={`select-box-c ${className} ${
            selected.toString().toLowerCase() === value.toString().toLowerCase()
              ? "selected-box"
              : ""
          }`}
          onClick={
            disabled
              ? () => {}
              : () => {
                  onSelect(name, value);
                }
          }
        >
          {label.substr(0, 1).toUpperCase() + label.substr(1)} {icon && icon}
        </div>
      )}
    </>
  );
};

SelectBox.defaultProps = {
  selected: "",
  value: "",
  onSelect: () => {},
  label: "",
  disabled: false,
  nullable: false,
};
