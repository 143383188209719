/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Input,
  Card,
  Collapse,
} from "reactstrap";
import NewAuthLayout_v2 from "src/Auth/NewLayout/NewAuthLayout_v2";
import classes from "./faq.css";
import bg from "src/assets/img/bg/faq_bg.webp";
import { BiMinus, BiPlus } from "react-icons/bi";

const AccordionItem = ({ active, idx, open, question, answer }) => {
  return (
    <Card
      onClick={() => open(idx)}
      style={{
        backgroundColor: "transparent",
        border: "none !important",
        borderColor: "transparent",
        // borderBottom: "1px solid black",
        borderRadius: "0px",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p
          style={{
            flex: 1,
            color: "#31303A",
            font: "normal normal medium 20px/30px Poppins",
          }}
        >
          {idx + 1}. {question}
        </p>
        <div>
          {active ? (
            <BiMinus color="var(--tef-red)" />
          ) : (
            <BiPlus color="var(--tef-red)" />
          )}
        </div>
      </div>
      <hr />

      <Collapse isOpen={active} style={{ border: "none" }}>
        {answer}
      </Collapse>
    </Card>
  );
};

const General_faq = () => {
  const [active, setActive] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const contents = [
    {
      question: "How is TEFConnect connected with the Tony Elumelu Foundation?",
      answer: (
        <p
          style={{
            font: "normal normal normal 20px/30px Poppins",
            color: "#31303A",
          }}
        >
          TEFConnect is a proprietary, in-house built networking and empowerment
          platform of the Tony Elumelu Foundation. It is the largest digital
          networking platform for African Entrepreneurs.
        </p>
      ),
    },

    {
      question:
        "Do I have to register on the platform to have access to its features?",
      answer: (
        <p
          style={{
            font: "normal normal normal 20px/30px Poppins",
            color: "#31303A",
          }}
        >
          Yes. TEFConnect is a gated community and requires all users to
          register.
        </p>
      ),
    },

    {
      question: "What are the benefits of joining the TEFConnect Community?",
      answer: (
        <ul style={{ paddingLeft: "30px" }}>
          <li
            style={{
              font: "normal normal normal 20px/30px Poppins",
              color: "#31303A",
            }}
          >
            The platform offers networking/connections with over 1million
            African entrepreneurs
          </li>

          <li
            style={{
              font: "normal normal normal 20px/30px Poppins",
              color: "#31303A",
            }}
          >
            Hundreds of learning content on Finance, Marketing, Branding, Talent
            Management, Technology and many more…
          </li>

          <li
            style={{
              font: "normal normal normal 20px/30px Poppins",
              color: "#31303A",
            }}
          >
            The Marketplace creates access to the African market and offers our
            Alumni the opportunity to showcase your business products and
            services for increased visibility
          </li>

          <li
            style={{
              font: "normal normal normal 20px/30px Poppins",
              color: "#31303A",
            }}
          >
            View and apply for global business opportunities made available by
            our network of Partners
          </li>

          <li
            style={{
              font: "normal normal normal 20px/30px Poppins",
              color: "#31303A",
            }}
          >
            View and access business tools to ease the management of your
            business
          </li>
        </ul>
      ),
    },

    {
      question:
        "Do I have to pay an agent to apply for the annual Entrepreneurship Programme?",
      answer: (
        <p
          style={{
            font: "normal normal normal 20px/30px Poppins",
            color: "#31303A",
          }}
        >
          The Tony Elumelu Foundation is not associated with any agent for its
          entrepreneurship programme application purposes and condemns such act.
          If you know of anyone asking for money in exchange for application
          assistance, please report it by sending an email to{" "}
          <a
            href="mailto:info@tonyelumelufoundation.org"
            style={{ color: "blue", fontSize: "18px" }}
          >
            info@tonyelumelufoundation.org
          </a>
        </p>
      ),
    },

    {
      question: (
        <span
          style={{
            font: "normal normal  18px/30px Poppins",
            color: "#31303A",
          }}
        >
          What is the difference between{" "}
          <a
            href="https://tefconnect.com"
            target="_blank"
            rel="noreferrer"
            style={{
              color: "blue",
              fontSize: "18px",
            }}
          >
            tefconnect.com
          </a>{" "}
          and{" "}
          <a
            href="https://tefconnect.net"
            target="_blank"
            rel="noreferrer"
            style={{
              color: "blue",
              fontSize: "18px",
            }}
          >
            tefconnect.net
          </a>
          ?
        </span>
      ),
      answer: (
        <p
          style={{
            font: "normal normal normal 20px/30px Poppins",
            color: "#31303A",
          }}
        >
          TEFConnect.com is the fist version of TEFConnect which is gradually
          morphing into the new version{" "}
          <a
            href="https://tefconnect.net"
            target="_blank"
            rel="noreferrer"
            style={{ color: "blue", fontSize: "18px" }}
          >
            {" "}
            tefconenct.net
          </a>{" "}
          . For now, they are both owned and managed by the Tony Elumelu
          Foundation and used interchangeably as appropriate.
        </p>
      ),
    },
    {
      question: "How do I apply for the Entrepreneurship Programme?",
      answer: (
        <p
          style={{
            font: "normal normal normal 20px/30px Poppins",
            color: "#31303A",
          }}
        >
          Register/Login to TEFConnect, on the HomePage, click on the
          Applications link. Select the Programme you would like to apply for
          and follow the instructions. The application portal opens on the 1st
          of January of each year. It’s free to apply. All the best!
        </p>
      ),
    },
  ];

  return (
    <NewAuthLayout_v2>
      <div
        // className={classes.faqContainer}
        className={classes.faqContainer}
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
          backgroundSize: "100% 100%",
          padding: "93px 6%",
        }}
      >
        <Row>
          <Col md={6}></Col>
          <Col md={6} className={classes.faqForm}>
            <h4
              style={{
                font: "normal normal bold 45px/60px Poppins",
                marginBottom: "20px",
              }}
            >
              Find a little more about us.
            </h4>
            {/* <Form style={{ marginBottom: "30px" }}>
              <InputGroup>
                <Input
                  placeholder="Search for any keyword"
                  style={{
                    background: "#E8E8E8 0% 0% no-repeat padding-box",
                    height: "50px",
                  }}
                />
                <Button style={{ padding: "0 16px", marginLeft: "14px" }}>
                  Search
                </Button>
              </InputGroup>
            </Form> */}

            {contents.map((content, i) => {
              return (
                <AccordionItem
                  key={i}
                  idx={i}
                  active={active === i}
                  open={(value) => setActive(value)}
                  question={content.question}
                  answer={content.answer}
                />
              );
            })}
          </Col>
        </Row>
      </div>
    </NewAuthLayout_v2>
  );
};

export default General_faq;
