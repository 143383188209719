import { asyncActionName } from "src/utils/AsyncUtils";
import {
  CREATE_TOPIC,
  DELETE_TOPIC,
  GET_ALL_TOPICS,
  GET_TOPIC_DETAILS,
  GET_TOPIC_POSTS,
  LIKE_TOPIC,
} from "src/actions";
import { initialState } from "src/store";

const TopicReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case asyncActionName(CREATE_TOPIC).loading:
      return { ...state, loading: payload };
    case asyncActionName(CREATE_TOPIC).success:
      return {
        ...state,
        topic: payload,
        success: true,
      };
    case asyncActionName(CREATE_TOPIC).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_ALL_TOPICS).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_ALL_TOPICS).success:
      return {
        ...state,
        topics: payload,
        topicDetails: payload.page,
        success: true,
      };
    case asyncActionName(GET_ALL_TOPICS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_TOPIC_DETAILS).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_TOPIC_DETAILS).success:
      return {
        ...state,
        topicDetails: payload,
        success: true,
      };
    case asyncActionName(GET_TOPIC_DETAILS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_TOPIC_POSTS).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_TOPIC_POSTS).success:
      return {
        ...state,
        topicPosts: payload,
        success: true,
      };
    case asyncActionName(GET_TOPIC_POSTS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(DELETE_TOPIC).loading:
      return { ...state, loading: payload };
    case asyncActionName(DELETE_TOPIC).success:
      return {
        ...state,
        topic: payload,
        success: true,
      };
    case asyncActionName(DELETE_TOPIC).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(LIKE_TOPIC).loading:
      return { ...state, loading: payload };
    case asyncActionName(LIKE_TOPIC).success:
      return {
        ...state,
        likes: payload,
        success: true,
      };
    case asyncActionName(LIKE_TOPIC).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    default:
      return state;
  }
};
export default TopicReducer;
