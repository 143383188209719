import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "reactstrap";
import "./OpportunityForm.scss";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import { Editor } from "@tinymce/tinymce-react";
import ReactSelect from "react-select";
import {
  FormField,
  Select,
} from "src/components/CustomFormField/CustomFormField";
import { FaRegFileImage } from "react-icons/fa";
import { opportunityValidationErrors } from "src/modules/Opportunity/components/Helpers/opportunityObj";
import { CountryDropdown } from "react-country-region-selector";
import { Context } from "src/store";
import {
  getOpportunityCategories,
  getOpportunityTypes,
} from "src/requests/OpportunityRequest";
import { getInterests } from "src/requests/onboarding";
import { displayError } from "src/modules/onboarding/helper";
import ValidateJs from "src/utils/Validations/validation_wrapper";
import { UploadFile } from "src/requests/fileUploadRequest";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";

const CreateOpportunityForm = ({ data, onChange, onSubmit }) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Context);
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [relatedTopics, setRelatedTopics] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [file, setFile] = useState("");
  const [buttonText, setButtonText] = useState("+ Submit Opportunity");
  const [loading, setLoading] = useState(false);

  const [validationErrors, setValidationErrors] = useState(
    opportunityValidationErrors
  );
  useEffect(() => {
    const _getBasicData = () => {
      getOpportunityCategories(dispatch);
      getOpportunityTypes(dispatch);
      getInterests(dispatch).then((res) => {
        const { status, data } = res;
        if (status === 200) {
          const relatedtopicss = data.map((i) => {
            return { value: i.id, label: i.name };
          });
          setRelatedTopics(relatedtopicss);
        } else {
          setRelatedTopics([]);
        }
      });
    };
    _getBasicData();
  }, []);

  useEffect(() => {
    if (state.Opportunities.loadingPostOpportunity) {
      setButtonText("Submitting...");
    } else {
      setButtonText("+ Submit Opportunity");
    }
  }, [loading]);

  useEffect(() => {
    if (
      state.Opportunities.categories &&
      state.Opportunities.categories.length > 0
    ) {
      const oppCategories = state.Opportunities.categories.map((cat) => {
        return { value: cat.id, label: cat.name };
      });

      setCategories(oppCategories);
    }
  }, [state.Opportunities.categories]);

  useEffect(() => {
    if (state.Opportunities.types && state.Opportunities.types.length > 0) {
      const oppTypes = state.Opportunities.types.map((cat) => {
        return { value: cat.id, label: cat.name };
      });

      setTypes(oppTypes);
    }
  }, [state.Opportunities.types]);

  const _handleTopicsChange = (target, topics) => {
    if (!topics || topics.length === 0) {
      return onChange(target, []);
    }
    return onChange(target, topics.slice(0, 4));
  };

  const _handleValidationErrors = (target, value) => {
    setValidationErrors({ ...validationErrors, [target]: value });
  };

  // const emailValidation = (string) => {
  //   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return re.test(String(string).toLowerCase());
  // };

  const _onImageChange = (e) => {
    const file = e.target.files[0];
    const files = e.target.files;

    if (file) {
      const url = URL.createObjectURL(file);
      setPreviewImage(url);
      setFile(files);
    }
  };
  const _hasValidationError = (data) => {
    const whitelist = ["officialWebsite"];
    // const whitelist = ["officialWebsite", "contactEmail"];

    data = { ...data };

    let errors = {};
    const errorList = [];
    Object.keys(data).forEach((key) => {
      errors = { ...errors, [key]: ValidateJs(key, data[key]) };
    });

    whitelist.forEach((key) => {
      if (data[key] === "") {
        delete errors[key];
      }
    });

    if (previewImage || data.fileName) {
      delete errors.fileName;
    }

    Object.keys(errors).forEach((key) => {
      if (errors[key] !== null) {
        errorList.push(key);
      }
    });
    setValidationErrors(errors);

    return { confirmed: errorList.length > 0, errors: errorList };
  };

  const sanitizeData = (data) => {
    data = { ...data };
    const relatedTopicIds = data.relatedTopicIds.map((topic) => {
      return topic.value;
    });

    data.relatedTopicIds = relatedTopicIds;
    return data;
  };

  const _handleSubmit = (e) => {
    e.preventDefault();
    const errors = _hasValidationError(data);

    if (errors.confirmed) {
      document.getElementById(errors.errors[0]).scrollIntoView();
    } else {
      setLoading(!loading);
      const sanitizedData = sanitizeData(data);
      if (previewImage) {
        UploadFile(dispatch, false, file).then((res) => {
          const { files } = res;
          const upload = files ? files[0] : "";
          sanitizedData.fileName = upload;
          onSubmit(sanitizedData);
        });
      } else {
        onSubmit(sanitizedData);
      }
    }
  };

  return (
    <>
      <form className="opp-create-form">
        <div>
          <FormField
            label="Title*"
            hasCounter
            maxLength={60}
            labelInfo="Required"
            placeholder="e.g Youth Summer Exchange Program 2020"
            value={data.opportunityTitle}
            name="opportunityTitle"
            onChange={onChange}
            id="opportunityTitle"
            error={validationErrors.opportunityTitle}
            onBlur={() =>
              _handleValidationErrors(
                "opportunityTitle",
                ValidateJs("opportunityTitle", data.opportunityTitle)
              )
            }
          />
          {validationErrors.opportunityTitle &&
            displayError(validationErrors.opportunityTitle)}
        </div>

        <div>
          <FormField
            label="Short Summary*"
            type="textarea"
            hasCounter
            maxLength={500}
            labelInfo="Required"
            placeholder="Give a summary"
            value={data.shortSummary}
            name="shortSummary"
            onChange={onChange}
            id="shortSummary"
            error={validationErrors.shortSummary}
            onBlur={() =>
              _handleValidationErrors(
                "shortSummary",
                ValidateJs("shortSummary", data.shortSummary)
              )
            }
          />
          {validationErrors.shortSummary &&
            displayError(validationErrors.shortSummary)}
        </div>

        <div>
          <Row>
            <Col sm={12} md={6}>
              <FormField label="Category*" id="categoryId">
                <Select
                  onChange={onChange}
                  name="categoryId"
                  value={data.categoryId}
                  list={categories}
                  error={validationErrors.categoryId}
                  onBlur={() =>
                    _handleValidationErrors(
                      "categoryId",
                      ValidateJs("categoryId", data.categoryId)
                    )
                  }
                />
              </FormField>
              {validationErrors.categoryId &&
                displayError(validationErrors.categoryId)}
            </Col>
            <Col sm={12} md={6}>
              <FormField label="Deadline*" id="deadline">
                <div style={{ marginTop: "8px" }}>
                  <DatePickerInput
                    readOnly
                    onChange={(jsDate, dateString) => {
                      console.log(jsDate);
                      onChange("deadline", dateString.split("T")[0]);
                    }}
                    value={data.deadline}
                    minDate={new Date()}
                    placeholder="MM/DD/YYYY"
                    onHide={() =>
                      _handleValidationErrors(
                        "deadline",
                        ValidateJs("deadline", data.deadline)
                      )
                    }
                    showOnInputClick
                    autoClose={false}
                  />
                </div>
              </FormField>
              {validationErrors.deadline &&
                displayError(validationErrors.deadline)}
            </Col>
          </Row>
        </div>

        <FormField
          // className="col-md-6 "
          label="Country*"
          id="country"
        >
          <CountryDropdown
            classes={`flex country-state-list ${
              validationErrors.country ? "country-state-list-error-state" : ""
            }`}
            value={data.country}
            name="country"
            valueType="short"
            onChange={(val) => onChange("country", val)}
            onBlur={() =>
              _handleValidationErrors(
                "country",
                ValidateJs("countryAll", data.country)
              )
            }
          />
        </FormField>
        {validationErrors.country && displayError(validationErrors.country)}

        <div>
          <FormField
            type="textarea"
            label="Full Description of opportunity*"
            id="fullDescription"
          >
            <Editor
              apiKey={process.env.REACT_APP_TINY_CLOUD_KEY}
              // plugins="wordcount"
              // init=
              init={{
                // height: 200,

                // menubar: false,
                placeholder: "Full Description of Opportunity",
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat | help",
              }}
              onEditorChange={(content, editor) => {
                console.log(editor);
                onChange("fullDescription", content);
              }}
              value={data.fullDescription}
              onBlur={() =>
                _handleValidationErrors(
                  "fullDescription",
                  ValidateJs("fullDescription", data.fullDescription)
                )
              }
            />
          </FormField>
          {validationErrors.fullDescription &&
            displayError(validationErrors.fullDescription)}
        </div>

        <div>
          <Row>
            <Col sm={12} md={6}>
              <FormField label="Opportunity type" id="opportunityTypeId">
                <Select
                  placeholder="e.g. Full-funded, Volunteerism"
                  value={data.opportunityTypeId}
                  name="opportunityTypeId"
                  onChange={onChange}
                  list={types}
                />
                {/* <ReactSelect /> */}
              </FormField>
            </Col>
            <Col sm={12} md={6}>
              <FormField
                label="Contact Email*"
                placeholder="e.g name@tefconnect.com"
                value={data.contactEmail}
                name="contactEmail"
                onChange={onChange}
                id="contactEmail"
                error={validationErrors.contactEmail}
                onBlur={
                  () =>
                    // data.contactEmail.length > 0
                    _handleValidationErrors(
                      "contactEmail",
                      ValidateJs("contactEmail", data.contactEmail)
                    )
                  // : _handleValidationErrors("contactEmail", "")
                }
              ></FormField>
              {validationErrors.contactEmail &&
                displayError(validationErrors.contactEmail)}
            </Col>
          </Row>
        </div>
        <div>
          <FormField
            id="opportunityLink"
            label="Opportunity Link*"
            placeholder="Share application link"
            name="opportunityLink"
            onChange={onChange}
            error={validationErrors.opportunityLink}
            value={data.opportunityLink}
            onBlur={() =>
              _handleValidationErrors(
                "opportunityLink",
                ValidateJs("opportunityLink", data.opportunityLink)
              )
            }
          ></FormField>
          {validationErrors.opportunityLink &&
            displayError(validationErrors.opportunityLink)}
        </div>

        <div>
          <FormField
            label="Eligibility Requirement*"
            placeholder="Who is eligible to apply? What are the criteria for selection?"
            id="eligibilityRequirement"
          >
            <Editor
              apiKey={process.env.REACT_APP_TINY_CLOUD_KEY}
              // plugins="wordcount"
              // init=
              init={{
                // height: 200,
                placeholder:
                  "Who is eligible to apply? What are the criteria for selection?",
                // menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat | help",
              }}
              onEditorChange={(content, editor) => {
                console.log(editor);
                onChange("eligibilityRequirement", content);
              }}
              value={data.eligibilityRequirement}
              onBlur={() =>
                _handleValidationErrors(
                  "eligibilityRequirement",
                  ValidateJs(
                    "eligibilityRequirement",
                    data.eligibilityRequirement
                  )
                )
              }
            />
          </FormField>
          {validationErrors.eligibilityRequirement &&
            displayError(validationErrors.eligibilityRequirement)}
        </div>

        <div>
          <FormField
            label="Total Prize"
            placeholder="If any - including total prizes, salary, perks, scholarship value etc"
            id="totalPrice"
            onChange={onChange}
            name="totalPrice"
            type="number"
            min="0"
            value={data.totalPrice.toString()}
          ></FormField>
        </div>

        <div>
          <FormField label="Benefits" id="benefits">
            <Editor
              apiKey={process.env.REACT_APP_TINY_CLOUD_KEY}
              // plugins="wordcount"
              // init=
              init={{
                // height: 200,
                placeholder:
                  "What are the benefits associated with this opportunity",
                // menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat | help",
              }}
              onEditorChange={(content, editor) => {
                console.log(editor);
                onChange("benefits", content);
              }}
              value={data.benefits}
            />
          </FormField>
        </div>

        <div>
          <FormField
            label="Official Website"
            placeholder="Provide link for more information"
            id="officialWebsite"
            name="officialWebsite"
            value={data.officialWebsite}
            onChange={onChange}
            type="url"
            onBlur={() =>
              data.officialWebsite.length > 0
                ? _handleValidationErrors(
                    "officialWebsite",
                    ValidateJs("officialWebsite", data.officialWebsite)
                  )
                : _handleValidationErrors("officialWebsite", "")
            }
          ></FormField>
          {validationErrors.officialWebsite &&
            displayError(validationErrors.officialWebsite)}
        </div>

        <div>
          <FormField
            label="Related Topics"
            placeholder="Add topics e.g. Health, Technology"
            id="relatedTopicIds"
          >
            <ReactSelect
              value={data.relatedTopicIds}
              options={relatedTopics}
              isMulti
              closeMenuOnSelect={false}
              onChange={(topics) =>
                _handleTopicsChange("relatedTopicIds", topics)
              }
              placeholder="Add topics e.g. Health, Technology"
            />
            <div>
              <span
                style={{
                  float: "right",
                  fontSize: "14px",
                  color: "var(--helper-text-color)",
                }}
              >
                {data.relatedTopicIds.length} /4
              </span>
              <div style={{ clear: "both" }}></div>
            </div>
          </FormField>
        </div>

        <div>
          <FormField label="Upload Feature Photo" id="fileName">
            <Row>
              <Col md={6}>
                <div className="opp-create-form__image-c">
                  <div className="opp-form-img-box">
                    {!previewImage && !data.fileName ? (
                      <span className="opp-form-img-box__default-img">
                        {" "}
                        <FaRegFileImage />
                      </span>
                    ) : (
                      <>
                        <img src={previewImage || data.fileName} alt="logo" />
                      </>
                    )}
                  </div>
                  <div className="opp-form-btn-box">
                    <span>{t("Upload photo")}</span>
                    <div
                      className="opp-form-btn-box__button"
                      onClick={() =>
                        document.getElementById("previewImage").click()
                      }
                    >
                      {t("Browse your files")}
                    </div>
                  </div>
                </div>
                {validationErrors.fileName &&
                  displayError(validationErrors.fileName)}

                <input
                  type="file"
                  // value={previewImage}
                  name="previewImage"
                  id="previewImage"
                  onChange={_onImageChange}
                  className="opp-file-upload"
                  accept="image/x-png,image/jpeg,image/gif"
                />
              </Col>

              <Col md={6}>
                <div className="opp-create-form-file-info">
                  {/* {t("Recommended Dimension: 460 x 220")} <br />
                  {t("Max. size: 1MB")} <br /> */}
                  Format: PNG, GIF, JPG{" "}
                </div>
              </Col>
            </Row>
          </FormField>
        </div>
        <Row>
          <Col md={6}>
            <div className="submit-box">
              <div
                className="submit-btn"
                onClick={!loading ? (e) => _handleSubmit(e) : () => {}}
              >
                {buttonText}
                {loading && (
                  <span>
                    <Loader height={15} width={15} type="Oval" color="white" />
                  </span>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default CreateOpportunityForm;
