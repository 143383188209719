import React from "react";
import "./CategoryCard.scss";
import { Link } from "react-router-dom";
import imageIcon from "src/assets/img/home/article2.jpg";

const CategoryCard = ({ groupLogo, title, noOfGroups, id }) => {
  const url = `/community/groups?categoryId=${id}&title=${encodeURIComponent(
    title
  )}`;
  return (
    <Link to={url}>
      <div className="community-category-card">
        <div className="community-category-card__image">
          <img className="group-image-1" src={imageIcon} alt="group icon" />
        </div>
        <div className="community-category-card__details">
          <h1 className="category-card-details-h1">{title}</h1>
          <h3 className="category-card-details-h3">{noOfGroups} Groups</h3>
        </div>
      </div>
    </Link>
  );
};

export default CategoryCard;
