import { asyncActionName } from "src/utils/AsyncUtils";
import { initialState } from "src/store";
import { CREATE_NEWS_COMMENT } from "src/actions";

const TefNewsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case asyncActionName(CREATE_NEWS_COMMENT).loading:
      return { ...state, loading: payload };
    case asyncActionName(CREATE_NEWS_COMMENT).success:
      return {
        ...state,
        group: payload,
        success: true,
      };
    case asyncActionName(CREATE_NEWS_COMMENT).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };

    default:
      return state;
  }
};
export default TefNewsReducer;
