/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-key */
import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Button,
  Card,
  Badge,
  ModalBody,
  Modal,
  Form,
  FormGroup,
} from "reactstrap";
import moment from "moment";
import { Context } from "src/store";
import StepWizard from "react-step-wizard";
import Countdown, { zeroPad, calcTimeDelta } from "react-countdown";
import queryString from "query-string";
import "./PersonalityTest.scss";
import {
  getPersonalityQuestions,
  verifyResult,
  getUserApplications,
  extractq,
} from "src/requests/ApplicationRequest";
import InstructionModalImg from "src/assets/img/instruction-illustration.png";
// import Cloader from "src/modules/Application/components/CLoader";
import CategoryModal from "src/modules/Application/components/CategoryModal/CategoryModal";
import { sectionThreeTranslations } from "src/modules/Application/instructions";
import { useTranslation } from "react-i18next";
import PageLoader from "src/modules/homepage/components/PageLoader/PageLoader";
import Layout from "src/modules/Application/components/Layout/Layout";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { _breakInChunck } from "src/utils/helper";
const PersonalityTest = (props) => {
  const { t } = useTranslation();
  const initialState = {
    answers: [],
  };
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  // const [loader, setLoader] = React.useState(false);
  const [answers, setAnswers] = useState(initialState.answers);
  const [instructionModal, setInstructionModal] = React.useState(true);
  const prevAnswer = usePrevious({ answers, setAnswers });
  const { state, dispatch } = useContext(Context);
  const query = props.history.location.search;
  const { applicationId, endDate, lang } = queryString.parse(
    props.history.location.search
  );
  const language = lang || "en";
  // const language = JSON.parse(localStorage.getItem("language"));
  const {
    user: { id: userId },
  } = get_user_info_from_token();
  const [loadingQuestion, setLoadingQuestions] = useState(false);

  function compare(arr1, arr2) {
    if (!arr1 || !arr2) return;

    let result;

    arr1.forEach((e1) =>
      arr2.forEach((e2) => {
        if (e1.length > 1 && e2.length) {
          result = compare(e1, e2);
        } else if (e1 !== e2) {
          result = false;
        } else {
          result = true;
        }
      })
    );

    return result;
  }
  useEffect(() => {
    if (answers && answers.length > 0) {
      if (compare(JSON.parse(localStorage.personality), answers) === false) {
        updateLocalStorage();
      }
    }
  }, [answers]);

  useEffect(() => {
    if (query) {
      const parsedQuery = queryString.parse(query);
      if (Object.keys(parsedQuery).length === 0 || !parsedQuery.applicationId) {
        props.history.replace("/programme/dashboard", {});
      }
    } else {
      props.history.replace("/programme/dashboard", {});
      props.history.go(1);
    }
    // setLoader(false);
  }, [props.history.location.search]);

  let date;
  if (localStorage.date) {
    date = calcTimeDelta(JSON.parse(localStorage.date));
  }
  const startAssessment = () => {
    localStorage.start = true;
    setInstructionModal(!instructionModal);
  };

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      // props.history.replace(
      //   `/application/mcq?applicationId=${
      //     queryString.parse(query).applicationId
      //   }`,
      //   {}
      // );
    } else {
      // Render a countdown
      return (
        <span className="timer">
          {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };
  const showTimer = () => {
    if (!localStorage.count) {
      localStorage.count = true;
      localStorage.date = moment(Date.now()).add(45, "m").valueOf();
    }
    if (date && date.completed === false) {
      return <Countdown date={Date.now() + date.total} renderer={renderer} />;
    }
  };
  // const { userApplications } = state.Application;

  useEffect(() => {
    if (
      state.Application.Personality &&
      state.Application.Personality.length > 0
    ) {
      setAnswers(extractq(state.Application.Personality));
    }
  }, [state.Application]);

  const updateLocalStorage = () => {
    localStorage.personality = JSON.stringify(answers);
  };
  useEffect(() => {
    verifyResult(dispatch, queryString.parse(query).applicationId).then(
      (res) => {
        const {
          data: { data },
        } = res;
        if (data.status === "Completed") {
          props.history.replace(
            `/application/declaration?applicationId=${applicationId}&endDate=${endDate}&lang=${language}`,
            {}
          );
        } else {
          if (
            localStorage.personality &&
            JSON.parse(localStorage.personality).length > 0
          ) {
            setAnswers(JSON.parse(localStorage.personality));
          } else {
            setLoadingQuestions(true);
            getPersonalityQuestions(dispatch, applicationId, language).then(
              () => {
                setLoadingQuestions(false);
              }
            );
          }
        }
      }
    );
    getUserApplications(dispatch, userId);
  }, [language]);

  // useEffect(() => {
  //   if (applicant && applicant.length > 0) {
  //     const { languageId } = applicant[0];
  //     localStorage.language = JSON.stringify(languageId);
  //   }
  // }, [applicant]);
  const handleInputChange = (optionId, optionIndex) => {
    const index = answers.findIndex((answers) =>
      answers.options.find((option) => option.id === optionId)
    );
    const question = answers.find((answers) =>
      answers.options.find((option) => option.id === optionId)
    );

    // console.log(index);
    const selectedOption = {
      ...question,
    };
    selectedOption.options.forEach((opt) => {
      opt.isSelected = false;
    });
    selectedOption.options[optionIndex].isSelected = true;

    // {
    //   ...option.options[optionIndex],
    //   isSelected: true,
    // },
    if (answers[index]) {
      // setAnswers([(answers[index] = selectedOption)]);
      const existingQuestions = [...answers];
      existingQuestions[index] = selectedOption;
      setAnswers(existingQuestions);
    }
  };

  const DisplayWrapper = (props) => {
    const { nextStep, previousStep, currentStep, totalSteps, children } = props;

    return (
      <>
        {children}
        <Row>
          {currentStep > 1 && (
            <Button
              color="secondary"
              onClick={previousStep}
              style={{ marginRight: "5%" }}
            >
              Previous
            </Button>
          )}
          {currentStep !== totalSteps && (
            <Button color="secondary" onClick={nextStep}>
              {t("Next")}
            </Button>
          )}
          {currentStep === totalSteps && (
            <a
              className="btn btn-outline-secondary"
              href={`/application/tests/mcq?applicationId=${applicationId}&endDate=${endDate}&lang=${language}`}
              data-testid="continue-to-cognitive"
            >
              {t("Continue to Cognitive Assessment")}
            </a>
          )}
        </Row>
      </>
    );
  };
  const DisplayQuestions = ({ question, options, questionIndex }) => {
    const checkedOption =
      prevAnswer.answers &&
      prevAnswer.answers.length !== 0 &&
      prevAnswer.answers[questionIndex].options.find(
        (option) => option.isSelected === true
      );
    return (
      <Col>
        <CategoryModal />
        <Row>
          <Badge color="light">
            {" "}
            {questionIndex + 1} of {answers && answers.length}
          </Badge>
        </Row>
        <Row>
          {/* <p>
            {question} <br />
          </p> */}
          <div dangerouslySetInnerHTML={{ __html: `${question}` }}></div>
          <br />
        </Row>

        <Row>
          <form className="form">
            {/* <h2>Checkboxes</h2>
              <div class="inputGroup">
                <input id="option1" name="option1" type="checkbox" />
                <label for="option1">Option One</label>
              </div>

              <div class="inputGroup">
                <input id="option2" name="option2" type="checkbox" />
                <label for="option2">Option Two</label>
              </div>

              <h2>Radio Buttons</h2> */}
            {options &&
              options.map((option, index) => (
                <div className="inputGroup">
                  <input
                    id={option.id}
                    name="radio"
                    type="radio"
                    onChange={() => handleInputChange(option.id, index)}
                    checked={
                      checkedOption ? checkedOption.id === option.id : false
                    }
                  />
                  <label htmlFor={option.id}>{option.optionContent}</label>
                </div>
              ))}
          </form>
        </Row>
      </Col>
    );
  };

  const custom = {
    enterRight: "none",
    enterLeft: "none",
    exitRight: "none",
    exitLeft: "none",
  };

  return (
    <Layout>
      <Col md={12} sm={12} xs={12} lg={12}>
        <h2 className="personality-heading">Personality Test</h2>
        <p className="personality-text">{t("Application_Overview")}</p>
        <Card className="personality-card">
          {answers.length < 1 ? (
            <PageLoader counts={1} />
          ) : (
            <span>
              <Row>
                <h3 className="personality-title">
                  <b>Personality</b> Rank-Order Task
                </h3>
              </Row>
              <Row>
                <p className="personality-sub">
                  {sectionThreeTranslations[language || "en"]}
                </p>
              </Row>
              {loadingQuestion && <PageLoader counts={1} />}
              <StepWizard transitions={custom}>
                {
                  language &&
                    localStorage.start &&
                    answers &&
                    _breakInChunck(answers, 5).map((questions, index) => {
                      return (
                        <DisplayWrapper>
                          {showTimer()}
                          {questions.map((question, i) => {
                            return (
                              <DisplayQuestions
                                key={1}
                                question={question.questionContent}
                                options={question.options}
                                questionIndex={answers.findIndex(
                                  (i) => i.id === question.id
                                )}
                              />
                            );
                          })}
                        </DisplayWrapper>
                      );
                    })
                  // answers.map((question, index) => (
                  //   <DisplayQuestions
                  //     question={question.questionContent}
                  //     options={question.options}
                  //     questionIndex={index}
                  //   />
                  // ))
                  // questions && (<QuestionsLoop />)[1]
                }
              </StepWizard>
            </span>
          )}
        </Card>
        <Modal
          isOpen={!localStorage.start && instructionModal}
          toggle={() => setInstructionModal(!instructionModal)}
          backdrop={"static"}
          size="lg"
        >
          {/* <ModalHeader style={{ borderBottom: "none" }} toggle={() => setInstructionModal(!instructionModal)}></ModalHeader> */}
          <ModalBody style={{ textAlign: "center", padding: "2rem" }}>
            <div className="modalImg">
              <img src={InstructionModalImg} className="img-fluid" />
            </div>
            <span></span>
            <h4 style={{ marginBottom: "1.2rem" }}>{/* Great work!😃 */}</h4>
            <span style={{ textAlign: "left" }}>
              <p>Please read the following carefully.</p>
              <p>
                The next two (2) sections contain Personality questions,
                Financial and Business Acumen Questions.
              </p>
              <p>
                This is a timed assessment. You have 45mins to complete both
                sections.
              </p>
              <p>
                It is recommended that you take the assessment in an environment
                with stable internet connection, free of distractions and with
                optimal concentration.
              </p>

              <p>
                Note: You can only take this assessment once after you click
                start and you cannot Save &amp; Continue
              </p>
              <p>Click the "Start" button to begin.</p>

              {/* <p>Click the “Next” button to begin.</p> */}
            </span>
            <Form>
              {/* <FormGroup  style={{textAlign:"left"}}>
                                    <Label for="reminderEmail" style={{fontWeight:"bold"}}>Enter email address</Label>
                                    <Input
                                        id="reminderEmail"
                                        placeholder="name@teffoundation.org"
                                    />
                                </FormGroup> */}
              <FormGroup style={{ textAlign: "left", marginTop: "10%" }}>
                <Button
                  color="secondary"
                  onClick={() => startAssessment()}
                  data-testid="start"
                >
                  Start
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={() =>
                    props.history.replace("/programme/dashboard", {})
                  }
                >
                  Save now and Continue Later
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={() =>
                    props.history.push(
                      `/preview-application?applicationId=${applicationId}&endDate=${endDate}&lang=${language}`
                    )
                  }
                >
                  Update bio and experience data
                </Button>{" "}
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </Col>
    </Layout>
  );
};

PersonalityTest.propTypes = {};

export default PersonalityTest;
