import React, { useEffect, useContext } from "react";
import { Col, Row } from "reactstrap";
import { getRequests } from "src/requests/MentorRequests";
import { Context } from "src/store";
import PageLoader from "src/modules/homepage/components/PageLoader/PageLoader";
import Request from "./Request";
import "./Requests.scss";

const Requests = ({ setCurrentTab, role }) => {
  const { state, dispatch } = useContext(Context);
  useEffect(() => {
    getRequests(dispatch);
  }, []);

  const displayRequests = () => {
    return state.Mentors.unconnected &&
      state.Mentors.unconnected.length !== 0 ? (
      state.Mentors.unconnected.map((user, i) => {
        return (
          <Col key={i}>
            <Request user={user} setCurrentTab={setCurrentTab} role={role} />
          </Col>
        );
      })
    ) : (
      <Row>
        <Col>
          <span>No pending requests</span>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <div>
        <div className="main-card-container">
          {/* <Col> */}
          <h3 className="my-board-heading">Connection Request</h3>
          <hr />
          {displayRequests()}
          {/* </Col> */}
        </div>
        {!state.Mentors.unconnected && (
          <PageLoader type="Facebook" counts={2} />
        )}
        <hr />
      </div>
    </div>
  );
};

Requests.propTypes = {};

export default Requests;
