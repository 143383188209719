import React from "react";
import { FormGroup, Input } from "reactstrap";
import classes from "./bankdetailsform.module.css";

const FormInput = ({
  label,
  status,
  placeholder,
  getFormInputValue,
  value,
  name,
  required,
  disabled,
}) => {
  return (
    <FormGroup>
      <div className={`${classes.formgroupcontainer}`}>
        <p>
          {label} {""}
          <em>
            <small style={{ color: "#BDC3CC" }}>{status}</small>
          </em>
        </p>
        <Input
          className={`shadow-none ${classes.bankdetailsinput}`}
          type="text"
          name={name}
          id="exampleText"
          style={{ backgroundColor: "#f0f0f0", border: "none" }}
          placeholder={placeholder}
          value={value}
          onChange={(e) => getFormInputValue(e)}
          required={required}
          disabled={disabled}
        />
      </div>
    </FormGroup>
  );
};

const FormSelectInput = ({
  label,
  status,
  placeholder,
  getFormInputValue,
  value,
  name,
  required,
  disabled,
  optionList,
}) => {
  return (
    <FormGroup>
      <div className={`${classes.formgroupcontainer}`}>
        <p>
          {label} {""}
          <em>
            <small style={{ color: "#BDC3CC" }}>{status}</small>
          </em>
        </p>
        <Input
          className={`shadow-none ${classes.bankdetailsinput}`}
          type="select"
          name={name}
          id="exampleText"
          style={{ backgroundColor: "#f0f0f0", border: "none" }}
          placeholder={placeholder}
          value={value}
          onChange={(e) => getFormInputValue(e)}
          required={required}
          disabled={disabled}
        >
          <option value={null}>Select</option>
          {optionList.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </Input>
      </div>
    </FormGroup>
  );
};

FormSelectInput.defaultProps = {
  optionList: [],
};

export { FormSelectInput };
export default FormInput;
