/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { submitSurveyQuestion } from "src/modules/mentorship/components/helper";
import { toast } from "react-toastify";

const SurveyModal = ({
  className,
  survey,
  setSurvey,
  questions,
  setQuestions,
  respondedToUserId,
  userInfo,
}) => {
  const userId = localStorage.data && JSON.parse(localStorage.data).user.id;

  const toggle = () => setSurvey(!survey);

  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );
  const [answers, setAnswers] = useState([]);

  const handleInputChange = (e, questionId, groupId) => {
    const newObj = {
      userId,
      questionOptionIds: [],
      paragraph: e.target.value,
      questionId,
      groupId,
      respondedToUserId,
    };

    const check = answers.find(
      (answer) => answer.questionId === newObj.questionId
    );
    const checkIndex = answers.findIndex(
      (answer) => answer.questionId === newObj.questionId
    );
    if (check) {
      answers[checkIndex] = newObj;
      const existingAnswers = [...answers];
      existingAnswers[check] = newObj;
      setAnswers(existingAnswers);
    } else {
      // answers.push(newObj);
      setAnswers([...answers, newObj]);
    }
  };

  const handleRadioChange = (e, questionId, groupId, questionOptionIds) => {
    const newObj = {
      userId,
      questionOptionIds: [questionOptionIds],
      paragraph: "",
      questionId,
      groupId,
      respondedToUserId,
    };

    const check = answers.find(
      (answer) => answer.questionId === newObj.questionId
    );
    const checkIndex = answers.findIndex(
      (answer) => answer.questionId === newObj.questionId
    );
    if (check) {
      answers[checkIndex] = newObj;
      const existingAnswers = [...answers];
      existingAnswers[check] = newObj;
      setAnswers(existingAnswers);
    } else {
      // answers.push(newObj);
      setAnswers([...answers, newObj]);
    }
  };

  const handleCheckboxChange = (e, questionId, groupId, questionOptionIds) => {
    const newObj = {
      userId,
      questionOptionIds: [questionOptionIds],
      paragraph: "",
      questionId,
      groupId,
      respondedToUserId,
    };

    const check = answers.find(
      (answer) => answer.questionId === newObj.questionId
    );
    const checkIndex = answers.findIndex(
      (answer) => answer.questionId === newObj.questionId
    );
    console.log(check);
    if (check) {
      // answers[checkIndex] = newObj;
      // const existingAnswers = [...answers];
      // const existingOptions = [...existingAnswers[checkIndex].questionOptionIds, ]
      // console.log(existingOptions, "djkdj")
      // // existingAnswers[checkIndex].questionOptionIds = existingOptions;
      // // setAnswers(existingAnswers);
      answers[checkIndex] = newObj;
      const existingAnswers = [...answers];
      existingAnswers[check] = newObj;
      setAnswers(existingAnswers);
    } else {
      answers.push(newObj);
      setAnswers([...answers, newObj]);
    }
  };

  const submitSurvey = (e) => {
    e.preventDefault();
    return submitSurveyQuestion(answers)
      .then((res) => {
        if (res.status && res.status === 201) {
          toggle();
          toast("Survey Submitted successfully");
        }
        setQuestions([]);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const displayQuestions = () => {
    return (
      questions &&
      questions.length !== 0 &&
      questions.questions.map((question, i) => (
        <FormGroup row className="mb-5" key={i}>
          <h5 style={{ fontWeight: "600" }} className=" col-md-12 mb-3">
            {question.title.charAt(0).toUpperCase() + question.title.slice(1)}
          </h5>
          <Col sm={12}>
            {question.questionOptions.length > 0 ? (
              question.questionOptions.map((options, i) => (
                <React.Fragment key={i}>
                  {question.isMultiple && (
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name={question.id}
                          checked={
                            answers.find(
                              (answer) => answer.questionId === question.id
                            ) &&
                            answers.find(
                              (answer) => answer.questionId === question.id
                            ).questionOptionIds[0] === options.id
                          }
                          onChange={(e) =>
                            handleCheckboxChange(
                              e,
                              question.id,
                              questions.id,
                              options.id
                            )
                          }
                        />{" "}
                        {options.option.charAt(0).toUpperCase() +
                          options.option.slice(1)}
                      </Label>
                    </FormGroup>
                  )}
                  {!question.isMultiple && (
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          checked={
                            answers.find(
                              (answer) => answer.questionId === question.id
                            ) &&
                            answers.find(
                              (answer) => answer.questionId === question.id
                            ).questionOptionIds[0] === options.id
                          }
                          name={question.id}
                          onChange={(e) =>
                            handleRadioChange(
                              e,
                              question.id,
                              questions.id,
                              options.id
                            )
                          }
                          required
                        />{" "}
                        {options.option.charAt(0).toUpperCase() +
                          options.option.slice(1)}
                      </Label>
                    </FormGroup>
                  )}
                </React.Fragment>
              ))
            ) : (
              <FormGroup>
                <Input
                  type="textarea"
                  name="text"
                  rows="4"
                  onChange={(e) =>
                    handleInputChange(e, question.id, questions.id)
                  }
                  value={
                    answers.find(
                      (answer) => answer.questionId === question.id
                    ) &&
                    answers.find((answer) => answer.questionId === question.id)
                      .paragraph
                  }
                  required
                />
              </FormGroup>
            )}
          </Col>
        </FormGroup>
      ))
    );
  };

  return (
    <div>
      <Modal
        size={"lg"}
        isOpen={survey}
        toggle={toggle}
        className={className}
        backdrop="static"
      >
        <ModalHeader toggle={toggle} close={closeBtn}>
          <h4 style={{ fontWeight: "900" }}>
            Ratings for {userInfo && userInfo.firstName}{" "}
            {userInfo && userInfo.lastName}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={(e) => submitSurvey(e)}>
            {displayQuestions()}
            <div>
              <Button type="submit" className="float-right">
                Submit
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SurveyModal;
