import React from "react";
import { Col, Row } from "reactstrap";
import Biz from "src/assets/img/profile/business-details.svg";
import "./BusinessDetail.scss";

const BusinessDetails = ({
  businessName,
  businessCategory,
  businessWebsite,
  businessRevenue,
  businessAddress,
  aboutBusiness,
  state,
  city,
  country,
}) => {
  return (
    <Col>
      <Row>
        <Col className="about-item" md={6}>
          <Row>
            <Col className="about-item__logo d-none d-md-block" md={1}>
              <img src={Biz} alt={"briefcase"} />
            </Col>
            <Col className="about-item__details" md={10}>
              <h3>{businessName}</h3>
              <p>Business Name</p>
            </Col>
          </Row>
          <hr />
        </Col>

        <Col className="about-item" md={6}>
          <Row>
            {/* <Col className="about-item__logo" md={2}></Col> */}
            <Col className="about-item__details" md={10}>
              <h3>{businessCategory}</h3>
              <p>Industry Category</p>
            </Col>
          </Row>
          <hr />
        </Col>

        <Col className="about-item" md={6}>
          <Row>
            {/* <Col className="about-item__logo" md={2}></Col> */}
            <Col className="about-item__details" md={10}>
              <h3>{businessWebsite}</h3>
              <p>Business Website</p>
            </Col>
          </Row>
          <hr />
        </Col>

        <Col className="about-item" md={6}>
          <Row>
            {/* <Col className="about-item__logo" md={2}></Col> */}
            <Col className="about-item__details" md={10}>
              <h3>${businessRevenue}</h3>
              <p>Revenue Generated</p>
            </Col>
          </Row>
          <hr />
        </Col>

        <Col className="about-item" md={12}>
          <Row>
            {/* <Col className="about-item__logo" md={1}></Col> */}
            <Col className="about-item__details" md={10}>
              <h3>{aboutBusiness}</h3>
              <p>About Business</p>
            </Col>
          </Row>
          <hr />
        </Col>

        <Col className="about-item" md={12}>
          <Row>
            {/* <Col className="about-item__logo" md={1}></Col> */}
            <Col className="about-item__details" md={10}>
              <h3>{businessAddress}</h3>
              <p>Business Address</p>
            </Col>
          </Row>
          <hr />
        </Col>

        <Col className="about-item" md={12}>
          <Row>
            {/* <Col className="about-item__logo" md={1}></Col> */}
            <Col className="about-item__details" md={10}>
              <h3>
                {city}, {state}, {country}
              </h3>
              <p>Location</p>
            </Col>
          </Row>
          <hr />
        </Col>
      </Row>
    </Col>
  );
};

BusinessDetails.propTypes = {};

export default BusinessDetails;
