import React, { useState, useEffect, useRef, useContext } from "react";
import shortId from "shortid";
import { Button } from "src/modules/training/components";
import "./_mentorOnboarding.scss";
import {
  FormField,
  Select,
  Radio,
  CheckBox,
} from "src/modules/onboarding/components";
import Header from "src/modules/onboarding/components/Header";
import ValidateJs from "src/utils/Validations/validation_wrapper";
import ReactSelect from "react-select";
import {
  languages,
  qualities,
  industryOfExperience,
  numbers,
  noOfPeopleMentored,
  periodMentored,
  canMentorHours,
  yearsOfTefMentor,
  motivations,
  meansOfInformation,
  trueAndFalse,
  preferedMeansOfCom,
  titles,
  religions,
} from "./static";
import {
  isCheckedCheckBox,
  CastValue,
  isCheckedRadio,
  displayError,
  retrieveUserTempProfile,
  storeOnboardingProgress,
} from "src/modules/onboarding/helper";
import { defaultUserInfo } from "src/modules/onboarding/stepOne/userInfo";
import { useTranslation } from "react-i18next";
import {
  AvatarOnboarding,
  formatMaxBirthYear,
  UserName,
  validateBirthYear,
} from "src/modules/onboarding/stepTwo/Sections";
import _ from "lodash";
import { getMentorshipAreas } from "src/requests/onboarding";
import { Context } from "src/store";
import { DatePickerInput } from "rc-datepicker";
import PhoneInput from "react-phone-input-2";
import { gender, postions } from "src/modules/onboarding/stepTwo/Sections/Bio";
import degreeObj from "src/modules/onboarding/stepTwo/degreeTypes2.json";
import { ifNotOnboardedThenShow } from "src/utils/Services/isOnboarded";
import {
  CustomCountryRegionDropdown,
  CustomCountryDropdown,
} from "src/components/CustomCountryRegionDropdown";

const TefMentorOnboarding = (props) => {
  const [info, setInfo] = useState(defaultUserInfo);
  const [tefMentor, setTefMentor] = useState(info.tefMentor);
  const [areas, setAreas] = useState([]);
  const { dispatch } = useContext(Context);

  const ref = useRef();
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("data")).user;

  useEffect(() => {
    window.scrollTo(0, 0);
    retrieveUserTempProfile()
      .then((profile) => {
        if (!profile || profile === null) {
          props.history.push({ pathname: "/onboarding", search: "" });
        } else {
          if (!profile.groupId || profile.groupId === null) {
            props.history.go(-1);
          } else {
            if (profile && profile.groupId) {
              if (!profile.tefMentor.country) {
                profile.tefMentor.country = profile.country;
              }
              if (!profile.tefMentor.organization) {
                profile.tefMentor.organization = profile.businessName;
              }
            }
            setInfo(profile);
            setTefMentor(profile.tefMentor);
            return profile;
          }
        }
      })
      .then((profile) => {
        if (
          profile.steps.previousStep !== window.location.pathname &&
          profile.steps.nextStep !== ""
        ) {
          props.history.push(profile.steps.nextStep);
        }
      });
    getMentorshipAreas(dispatch).then((res) => {
      const { status, data } = res;
      if (status === 200) {
        const ars = data.map((a) => {
          return { value: a.id, label: t(`${a.area}`) };
        });
        setAreas(ars);
      } else {
        setAreas([]);
      }
    });
  }, []);

  const _handleChange = (target, value) => {
    const tefObj = { ...tefMentor, [target]: CastValue(target, value) };
    if (target === "isATefMentor" && value.toString() === "false") {
      tefObj.reasonForRating = "";
      tefObj.recommendationLevel = 0;
      tefObj.yearOfTefMentorship = "";
    }
    setTefMentor(tefObj);
    setInfo({ ...info, tefMentor: { ...tefObj } });
  };

  const _handleValidationErrorChanges = (target, value) => {
    const obj = { ...info.validationErrors, [target]: value };
    setInfo({ ...info, validationErrors: obj });
  };

  const _breakInChunck = (list, chunkSize = 4) => {
    const listChunk = [];
    for (let i = 0; i < list.length; i += chunkSize) {
      listChunk.push(list.slice(i, i + chunkSize));
    }
    return listChunk;
  };

  const _handleCheckBoxChange = (e, target) => {
    const value = e.target.value;
    const list = [...tefMentor[target]];
    if (list.includes(value)) {
      const index = list.indexOf(value);
      list.splice(index, 1);
      // _handleValidationErrorChanges(target, ValidateJs(target, list));
    } else {
      list.push(value);
      // _handleValidationErrorChanges(target, ValidateJs(target, list));
    }
    _handleChange(target, list);
  };

  const _handleBasicInfoChange = (target, value) => {
    if (target === "country" || target === "businessCountry") {
      if (target === "country") {
        const tefOb = { ...tefMentor, [target]: CastValue(target, value) };
        setTefMentor(tefOb);
        setInfo({ ...info, [target]: value, state: "", tefMentor: tefOb });
      }
      if (target === "businessCountry") {
        setInfo({ ...info, [target]: value, businessState: "" });
      }
    } else {
      setInfo({ ...info, [target]: CastValue(target, value) });
    }
    // setInfo({ ...info, [target]: CastValue(target, value) });
  };

  const nextStep = (userInfo) => {
    return new Promise((resolve, reject) => {
      const info = JSON.parse(JSON.stringify(userInfo));
      storeOnboardingProgress(JSON.stringify(info)).then((status) => {
        if (status === "success") {
          return resolve();
        }
        // return reject("failed to submit");
      });
      // window.localStorage.setItem("userInfo", JSON.stringify(info))
    });
  };

  const _saveAndContinueClick = (userInfo) => {
    // save data
    userInfo = _setCurrentStep(
      userInfo,
      window.location.pathname,
      "/onboarding/interests",
      ""
    );
    nextStep(userInfo).then(() => {
      props.history.push("/onboarding/interests");
    });
  };

  const _onGoBackClick = () => {
    let uInfo = JSON.parse(JSON.stringify(info));
    Object.keys(uInfo.validationErrors).forEach((key) => {
      uInfo.validationErrors[key] = null;
    });
    uInfo = _setCurrentStep(
      uInfo,
      uInfo.steps.currentCompletedStep,
      window.location.pathname,
      "/onboarding"
    );
    nextStep(uInfo).then(() => {
      props.history.replace("/onboarding", {});
      props.history.go(1);
    });
  };

  const _setCurrentStep = (info, currentStepUrl, nextStepUrl, previousStep) => {
    const completd = info.steps.completedSteps;
    completd.push(currentStepUrl);
    info.steps.completedSteps = _.uniq(completd);
    info.steps.currentCompletedStep = currentStepUrl;
    info.steps.nextStep = nextStepUrl;
    info.steps.previousStep = previousStep;
    return info;
  };

  const hasValidationError = (data) => {
    const isATefMentor = data.tefMentor.isATefMentor;
    const exclude = [
      "isATefMentor",
      "isAvailableForMentoring",
      "isAvailableForOneYear",
      "isAspiringMentor",
    ];

    const basicInfo = [
      "bio",
      "dateOfBirth",
      "phone",
      "gender",
      "country",
      "state",
    ];

    let { tefMentor } = data;
    tefMentor = { ...tefMentor };
    for (const key of exclude) {
      delete tefMentor[key];
    }

    let errors = {};
    const errorList = [];
    Object.keys(tefMentor).forEach((key) => {
      errors = { ...errors, [key]: ValidateJs(key, tefMentor[key]) };
      if (key === "motivation" && !tefMentor[key]) {
        errors = { ...errors, [key]: "Please select" };
      }
    });

    basicInfo.forEach((item) => {
      errors = { ...errors, [item]: ValidateJs(item, data[item]) };
    });

    if (!isATefMentor) {
      delete errors.yearOfTefMentorship;
      delete errors.recommendationLevel;
      delete errors.reasonForRating;
    }

    Object.keys(errors).forEach((key) => {
      if (errors[key] !== null) {
        errorList.push(key);
      }
    });
    const errObj = { ...info, validationErrors: errors };
    setInfo(errObj);

    return {
      confirmed: errorList.length > 0,
      errors: errorList,
    };
  };

  const _handleSubmit = () => {
    const hasErrors = hasValidationError(info);
    if (hasErrors.confirmed) {
      // _scrollToRef(ref);
      document.getElementById(hasErrors.errors[0]).scrollIntoView();
      return;
    }
    return _saveAndContinueClick(info);
  };

  const _onChangeRoleButtonClick = () => {
    let uInfo = JSON.parse(JSON.stringify(info));
    Object.keys(uInfo.validationErrors).forEach((key) => {
      uInfo.validationErrors[key] = null;
    });
    uInfo = _setCurrentStep(
      uInfo,
      uInfo.steps.currentCompletedStep,
      window.location.pathname,
      "/onboarding"
    );
    nextStep(uInfo).then(() => {
      props.history.replace("/onboarding", {});
      props.history.go(1);
    });
  };

  return (
    <div>
      <Header {...props} />
      <div className="mentor-page-container">
        <div className="mentor-page-container__banner">
          <div
            className="custom-row"
            style={{
              justifyContent: "space-between",
              display: "flex",
              marginTop: "-55px",
              marginBottom: "44px",
            }}
          >
            <span className="backSkipButtons" onClick={_onGoBackClick}>
              {`< ${t("Back")}`}
            </span>
          </div>
          <h1>Complete your TEF Mentorship Registration</h1>
          <p>
            Thank you for your interest in the Tony Elumelu Foundation
            Mentorship Programme
          </p>
        </div>

        {/* <form> */}
        <section className="mentor-page-container__form-section">
          <div className="mentor-page-container__form-section--left">
            <AvatarOnboarding
              className="onboarding-avatar-image2"
              info={info}
              onChange={_handleBasicInfoChange}
            />

            <br />
            <div style={{ marginLeft: "-5px" }}>
              <UserName
                lastName={user.lastName}
                firstName={user.firstName}
                onChangeRoleButtonClick={_onChangeRoleButtonClick}
                role="Mentor"
              />
            </div>

            {/* <span className="pad all-fields-required">
              All fields are required{" "}
              {hasError && (
                <span style={{ color: "red" }}>
                  - Please fill all required fields
                </span>
              )}
            </span> */}
            <br />
            <form ref={ref}>
              <div style={{ marginTop: "17px", display: "flex" }}>
                <div
                  style={{
                    width: "38px",
                    height: "24px",
                    backgroundColor: tefMentor.isAspiringMentor
                      ? "#0db884"
                      : "#eaecef",
                    borderRadius: "10px",
                    paddingTop: "2px",
                    // transition: "all 0.2s",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    _handleChange(
                      "isAspiringMentor",
                      !tefMentor.isAspiringMentor
                    )
                  }
                >
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "100%",
                      backgroundColor: "#fff",
                      float: tefMentor.isAspiringMentor ? "right" : "left",
                      alignSelf: "center",
                      flex: 1,
                      margin: "0px 3px",
                    }}
                  ></div>
                </div>
                <span
                  style={{
                    alignSelf: "center",
                    marginTop: "-3px",
                    marginLeft: "15px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    color: "#26292d",
                  }}
                >
                  {t("I want to be a TEF Mentor")}
                </span>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FormField
                    labelInfo="Required"
                    label="Title"
                    className="flex"
                    id="title"
                  >
                    <Select
                      list={titles}
                      name="title"
                      onChange={_handleChange}
                      value={tefMentor.title}
                      className="flex"
                      // style={{ width: "250px" }}
                      onBlur={() =>
                        _handleValidationErrorChanges(
                          "title",
                          ValidateJs("title", tefMentor.title)
                        )
                      }
                      error={info.validationErrors.title}
                    />

                    {info.validationErrors.title ? (
                      <div className="displayError">
                        {displayError(info.validationErrors.title)}
                      </div>
                    ) : (
                      <div className="displayError">{displayError("")}</div>
                    )}
                  </FormField>
                </div>

                <div className="col-md-6">
                  <FormField
                    className="flex"
                    label="Gender"
                    labelInfo="Required"
                    required
                    id="gender"
                    // isEmpty={!userInfo["gender"]}
                  >
                    <Select
                      list={gender}
                      value={info.gender}
                      onChange={_handleBasicInfoChange}
                      name="gender"
                      className="flex"
                      onBlur={() =>
                        _handleValidationErrorChanges(
                          "gender",
                          ValidateJs("gender", info.gender)
                        )
                      }
                      error={info.validationErrors.gender}
                    />
                  </FormField>
                </div>
              </div>

              <div>
                <FormField
                  className="flex"
                  label="Bio"
                  labelInfo="Required"
                  type="textarea"
                  placeholder={t("Type in a brief overview of yourself")}
                  onChange={_handleBasicInfoChange}
                  name="bio"
                  hasCounter
                  maxLength={1000}
                  value={info.bio} // comment if word counter is needed
                  // value={enforceWordCountValue(
                  //   wordCounter(userInfo.bio),
                  //   userInfo.bio,
                  //   160
                  // )} //remove comment if word counter is needed
                  required
                  // isEmpty={!userInfo["bio"]}
                  onBlur={() =>
                    _handleValidationErrorChanges(
                      "bio",
                      ValidateJs("bio", info.bio)
                    )
                  }
                  error={info.validationErrors.bio}
                  id="bio"
                />
                {info.validationErrors.bio &&
                  displayError(info.validationErrors.bio)}
              </div>

              <div className="row">
                <div className="col-md-6">
                  <FormField
                    className="flex"
                    label="Date of Birth"
                    labelInfo="Required"
                    error={info.validationErrors.dateOfBirth}
                    id="dateOfBirth"
                  >
                    <div
                      className={`rc-dp${
                        info.validationErrors.dateOfBirth
                          ? " default-error-state"
                          : ""
                      }`}
                    >
                      <DatePickerInput
                        className="default-error-state"
                        readOnly
                        maxDate={new Date(formatMaxBirthYear(18))}
                        startDate={new Date(formatMaxBirthYear(18))}
                        onChange={(jsDate, dateString) =>
                          validateBirthYear(
                            "dateOfBirth",
                            dateString,
                            _handleBasicInfoChange
                          )
                        }
                        // locale={calenderLocale}
                        placeholder="MM/DD/YYYY"
                        onHide={() =>
                          _handleValidationErrorChanges(
                            "dateOfBirth",
                            ValidateJs("dateOfBirth", info.dateOfBirth)
                          )
                        }
                        value={info.dateOfBirth}
                        showOnInputClick
                        autoClose={false}
                      />
                    </div>
                  </FormField>

                  {info.validationErrors.dateOfBirth
                    ? displayError(info.validationErrors.dateOfBirth)
                    : displayError("")}
                </div>

                <FormField
                  className="col-md-6 pad-inner"
                  label="Phone"
                  labelInfo="Required"
                  required
                  // isEmpty={!info["phone"]}
                  id="phone"
                >
                  <div style={{ marginTop: "9.5px" }}>
                    <PhoneInput
                      enableSearch
                      country={"ng"}
                      value={info.phone}
                      inputClass="phone-input"
                      name="phone"
                      onChange={(phone) =>
                        _handleBasicInfoChange("phone", phone)
                      }
                      autoFormat={false}
                      inputStyle={{
                        width: "100%",
                        marginTop: "8px 10px",
                        borderColor: info.validationErrors.phone ? "red" : "",
                      }}
                      onBlur={() =>
                        _handleValidationErrorChanges(
                          "phone",
                          ValidateJs("phone", info.phone)
                        )
                      }
                    />
                    {info.validationErrors.phone
                      ? displayError(info.validationErrors.phone)
                      : displayError("")}
                  </div>
                </FormField>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <FormField
                    label="Name of Organization"
                    labelInfo="Required"
                    onChange={_handleChange}
                    value={tefMentor.organization}
                    name="organization"
                    id="organization"
                    onBlur={() =>
                      _handleValidationErrorChanges(
                        "organization",
                        ValidateJs("organization", tefMentor.organization)
                      )
                    }
                    error={info.validationErrors.organization}
                  />
                  {info.validationErrors.organization &&
                    displayError(info.validationErrors.organization)}
                </div>
                <div className="col-md-6">
                  <FormField
                    label="Position in Organisation"
                    labelInfo="Optional"
                  >
                    <Select
                      list={postions}
                      value={info.industryRole}
                      onChange={_handleBasicInfoChange}
                      name="industryRole"
                      className="flex"
                    />
                  </FormField>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <FormField
                    className="flex"
                    labelInfo="Required"
                    label="Country of Residence"
                    id="country"
                  >
                    <CustomCountryDropdown
                      classes={`flex country-state-list ${
                        info.validationErrors.country
                          ? "country-state-list-error-state"
                          : ""
                      }`}
                      value={info.country}
                      name="country"
                      valueType="short"
                      onChange={(val) => _handleBasicInfoChange("country", val)}
                      onBlur={() =>
                        // _handleValidationErrorChanges(
                        //   "country",
                        //   ValidateJs("country", tefMentor["country"])
                        // )
                        _handleValidationErrorChanges(
                          "country",
                          ValidateJs("country", info.country)
                        )
                      }
                    />
                    {info.validationErrors.country ? (
                      <div className="displayError">
                        {displayError(info.validationErrors.country)}
                      </div>
                    ) : (
                      <div className="displayError">{displayError("")}</div>
                    )}
                  </FormField>
                </div>

                <div className="col-md-6">
                  <FormField
                    label="State"
                    labelInfo="Required"
                    className="flex"
                    id="state"
                  >
                    <CustomCountryRegionDropdown
                      countryValueType="short"
                      defaultOptionLabel="Select please"
                      country={info.country}
                      classes={`flex country-state-list ${
                        info.validationErrors.state
                          ? "country-state-list-error-state"
                          : ""
                      }`}
                      name="state"
                      value={info.state}
                      onChange={(val) => _handleBasicInfoChange("state", val)}
                      onBlur={() =>
                        _handleValidationErrorChanges(
                          "state",
                          ValidateJs("state", info.state)
                        )
                      }
                    />
                    {info.validationErrors.state ? (
                      <div className="displayError">
                        {displayError(info.validationErrors.state)}
                      </div>
                    ) : (
                      <div className="displayError">{displayError("")}</div>
                    )}
                  </FormField>
                </div>
              </div>

              <FormField
                id="levelOfEducation"
                label="Highest level of education"
              >
                <Select
                  list={degreeObj}
                  defaultVal={"Select"}
                  name="levelOfEducation"
                  onChange={_handleBasicInfoChange}
                  value={info.levelOfEducation}
                />
              </FormField>

              <FormField label="Religion" labelInfo="Required" id="religion">
                <Select
                  list={religions}
                  onChange={_handleChange}
                  value={tefMentor.religion}
                  name="religion"
                  onBlur={() =>
                    _handleValidationErrorChanges(
                      "religion",
                      ValidateJs("religion", tefMentor.religion)
                    )
                  }
                  error={info.validationErrors.religion}
                />

                {info.validationErrors.religion && (
                  <div className="displayError">
                    {displayError(info.validationErrors.religion)}
                  </div>
                )}
              </FormField>

              <div className="row">
                <div className="col-md-6">
                  <FormField
                    label="Add professional profile"
                    labelInfo="Required"
                    placeholder="e.g http://personal-profile.com"
                    name="profileUrl"
                    id="profileUrl"
                    onChange={_handleChange}
                    value={tefMentor.profileUrl}
                    onBlur={() =>
                      _handleValidationErrorChanges(
                        "profileUrl",
                        ValidateJs("profileUrl", tefMentor.profileUrl)
                      )
                    }
                    error={info.validationErrors.profileUrl}
                  />
                  {info.validationErrors.profileUrl &&
                    displayError(info.validationErrors.profileUrl)}
                </div>
                <div className="col-md-6">
                  <FormField
                    className="flex"
                    type="url"
                    label="Linkedin Link"
                    labelInfo="Optional"
                    placeholder="e.g. https://linkedin.com/janedoe"
                    name="linkedinUrl"
                    id="linkedinUrl"
                    onChange={_handleBasicInfoChange}
                    value={info.linkedinUrl}
                    onBlur={
                      info.linkedinUrl.length > 0
                        ? () =>
                            _handleValidationErrorChanges(
                              "linkedinUrl",
                              ValidateJs("linkedinUrl", info.linkedinUrl)
                            )
                        : () => {
                            _handleValidationErrorChanges("linkedinUrl", null);
                          }
                    }
                    error={info.validationErrors.linkedinUrl}
                  />
                  {info.validationErrors.linkedinUrl &&
                    displayError(info.validationErrors.linkedinUrl)}
                </div>
              </div>

              <FormField
                label="Which language(s) would you like to mentor in?"
                labelInfo="Required"
                id="mentorshipLanguage"
              >
                {info.validationErrors.mentorshipLanguage && (
                  <div className="displayError">
                    {displayError(info.validationErrors.mentorshipLanguage)}
                  </div>
                )}
                <br />

                {languages.map((lang) => {
                  return (
                    <CheckBox
                      key={lang.value}
                      label={lang.label}
                      name={lang.value}
                      value={lang.value}
                      onChange={(e) =>
                        _handleCheckBoxChange(e, "mentorshipLanguage")
                      }
                      checked={isCheckedCheckBox(
                        tefMentor.mentorshipLanguage,
                        lang.value
                      )}
                    />
                  );
                })}

                {/* <Select
                  list={languages}
                  name="mentorshipLanguage"
                  onChange={_handleChange}
                  value={tefMentor.mentorshipLanguage}
                  onBlur={() =>
                    _handleValidationErrorChanges(
                      "mentorshipLanguage",
                      ValidateJs(
                        "mentorshipLanguage",
                        tefMentor["mentorshipLanguage"]
                      )
                    )
                  }
                  error={info.validationErrors.mentorshipLanguage}
                />

                {info["validationErrors"]["mentorshipLanguage"] && (
                  <div className="displayError">
                    {displayError(
                      info["validationErrors"]["mentorshipLanguage"]
                    )}
                  </div>
                )} */}
              </FormField>

              <FormField
                label="Which of these languages do you speak fluently?"
                labelInfo="Required"
                id="languages"
              >
                {info.validationErrors.languages && (
                  <div className="displayError">
                    {displayError(info.validationErrors.languages)}
                  </div>
                )}
                <br />

                {languages.map((lang) => {
                  return (
                    <CheckBox
                      key={lang.value}
                      label={lang.label}
                      name={lang.value}
                      value={lang.value}
                      onChange={(e) => _handleCheckBoxChange(e, "languages")}
                      checked={isCheckedCheckBox(
                        tefMentor.languages,
                        lang.value
                      )}
                    />
                  );
                })}
                {/* <div className="custom-row">
                  <CheckBox
                    name="Other"
                    label=""
                    value="other"
                    checked={isOtherLang}
                    onChange={() => {}}
                    disabled
                  />
                  <input
                    type="text"
                    name="other"
                    placeholder="Other - start typing"
                    style={{
                      outline: "none",
                      padding: "3px 10px",
                      borderRadius: "3px",
                      border: "solid 1px #c4cdd5",
                    }}
                  />
                </div> */}
              </FormField>

              <FormField
                label="Select qualities that most relate to you?"
                labelInfo="Required"
                id="qualities"
              >
                {info.validationErrors.qualities && (
                  <div className="displayError">
                    {displayError(info.validationErrors.qualities)}
                  </div>
                )}
                <br />

                {qualities.map((quality) => {
                  return (
                    <CheckBox
                      key={quality.value}
                      label={quality.label}
                      name={quality.value}
                      value={quality.value}
                      onChange={(e) => _handleCheckBoxChange(e, "qualities")}
                      checked={isCheckedCheckBox(
                        tefMentor.qualities,
                        quality.value
                      )}
                    />
                  );
                })}
              </FormField>

              <FormField
                label="What industries do you have experience in?"
                labelInfo="Required"
                id="industries"
                // className="custom-row"
              >
                {info.validationErrors.industries && (
                  <div className="displayError">
                    {displayError(info.validationErrors.industries)}
                  </div>
                )}
                <br />

                {_breakInChunck(industryOfExperience, 2).map((chunk) => {
                  return (
                    <div className="custom-row n-wr" key={shortId.generate()}>
                      {chunk.map((industry) => {
                        return (
                          <div className="flex" key={shortId.generate()}>
                            <CheckBox
                              // className="flex"
                              key={industry.value}
                              label={industry.label}
                              name={industry.value}
                              value={industry.value}
                              onChange={(e) =>
                                _handleCheckBoxChange(e, "industries")
                              }
                              checked={isCheckedCheckBox(
                                tefMentor.industries,
                                industry.value
                              )}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
                {/* <div className="custom-row">
                  <CheckBox name="Other" label="" value="other" />
                  <input
                    type="text"
                    name="other"
                    placeholder="Other"
                    style={{
                      outline: "none",
                      padding: "3px 10px",
                      borderRadius: "3px",
                      border: "solid 1px #c4cdd5",
                    }}
                  />
                </div> */}
              </FormField>

              <FormField
                label="Are you currently a Tony Elumelu Foundation Mentor?"
                labelInfo="Required"
                id="isATefMentor"
              >
                <br />

                {trueAndFalse.map((value) => {
                  return (
                    <Radio
                      name="isATefMentor"
                      label={value.label}
                      value={value.value}
                      key={shortId.generate()}
                      onChange={_handleChange}
                      checked={isCheckedRadio(
                        tefMentor.isATefMentor,
                        value.value
                      )}
                    />
                  );
                })}
              </FormField>

              {tefMentor.isATefMentor && (
                <FormField
                  label="If yes, how long have you been a TEF Mentor for"
                  labelInfo="Required"
                  id="yearOfTefMentorship"
                >
                  {info.validationErrors.yearOfTefMentorship && (
                    <div className="displayError">
                      {displayError(info.validationErrors.yearOfTefMentorship)}
                    </div>
                  )}
                  <br />

                  {yearsOfTefMentor.map((year) => {
                    return (
                      <Radio
                        name="yearOfTefMentorship"
                        label={year.label}
                        value={year.value}
                        key={shortId.generate()}
                        onChange={_handleChange}
                        checked={isCheckedRadio(
                          tefMentor.yearOfTefMentorship,
                          year.value
                        )}
                      />
                    );
                  })}
                </FormField>
              )}

              <FormField
                label="Based on your experience so far, how likely are you to recommend TEF to others ?"
                // labelInfo="Required"
                id="recommendationLevel"
                // className="custom-row"
              >
                {info.validationErrors.recommendationLevel && (
                  <div className="displayError">
                    {displayError(info.validationErrors.recommendationLevel)}
                  </div>
                )}
                <br />

                <div className="custom-row">
                  {numbers(10).map((value) => {
                    return (
                      <Radio
                        name="recommendationLevel"
                        label={value.label}
                        value={value.value}
                        className="flex"
                        key={shortId.generate()}
                        onChange={_handleChange}
                        checked={isCheckedRadio(
                          tefMentor.recommendationLevel,
                          value.value
                        )}
                      />
                    );
                  })}
                  <Radio
                    name="recommendationLevel"
                    label={"N/A"}
                    value={0}
                    className="flex"
                    onChange={_handleChange}
                    checked={isCheckedRadio(tefMentor.recommendationLevel, 0)}
                  />
                </div>
              </FormField>

              <FormField
                label="What is the reason for your rating?"
                // labelInfo="Required"
                placeholder="Enter answer"
                onChange={_handleChange}
                value={tefMentor.reasonForRating}
                name="reasonForRating"
                id="reasonForRating"
                // onBlur={() =>
                //   _handleValidationErrorChanges(
                //     "reasonForRating",
                //     ValidateJs("reasonForRating", tefMentor["reasonForRating"])
                //   )
                // }
                error={info.validationErrors.reasonForRating}
              />
              {info.validationErrors.reasonForRating &&
                displayError(info.validationErrors.reasonForRating)}

              <FormField
                label="How many people have you mentored?"
                labelInfo="Required"
                id="noOfPeopleMentored"
              >
                {info.validationErrors.noOfPeopleMentored && (
                  <div className="displayError">
                    {displayError(info.validationErrors.noOfPeopleMentored)}
                  </div>
                )}
                <br />

                {noOfPeopleMentored.map((num) => {
                  return (
                    <Radio
                      name="noOfPeopleMentored"
                      label={num.label}
                      value={num.value}
                      key={shortId.generate()}
                      onChange={_handleChange}
                      checked={isCheckedRadio(
                        tefMentor.noOfPeopleMentored,
                        num.value
                      )}
                    />
                  );
                })}
              </FormField>

              <FormField
                label="How long have you been a mentor?"
                labelInfo="Required"
                id="yearOfMentorship"
              >
                {info.validationErrors.yearOfMentorship && (
                  <div className="displayError">
                    {displayError(info.validationErrors.yearOfMentorship)}
                  </div>
                )}
                <br />

                {periodMentored.map((num) => {
                  return (
                    <Radio
                      name="yearOfMentorship"
                      label={num.label}
                      value={num.value}
                      key={shortId.generate()}
                      onChange={_handleChange}
                      checked={isCheckedRadio(
                        tefMentor.yearOfMentorship,
                        num.value
                      )}
                    />
                  );
                })}
              </FormField>

              <FormField
                label="Are you willing to participate in monitoring and evaluation surveys?"
                labelInfo="Required"
                id="isAvailableForMentoring"
              >
                <br />

                {trueAndFalse.map((value) => {
                  return (
                    <Radio
                      name="isAvailableForMentoring"
                      label={value.label}
                      value={value.value}
                      key={shortId.generate()}
                      checked={isCheckedRadio(
                        tefMentor.isAvailableForMentoring,
                        value.value
                      )}
                      onChange={_handleChange}
                    />
                  );
                })}
              </FormField>

              <FormField
                label="How many hours a month are you available to mentor?"
                labelInfo="Required"
                id="availabilityHour"
              >
                {info.validationErrors.availabilityHour && (
                  <div className="displayError">
                    {displayError(info.validationErrors.availabilityHour)}
                  </div>
                )}
                <br />

                {canMentorHours.map((hour) => {
                  return (
                    <Radio
                      name="availabilityHour"
                      label={hour.label}
                      value={hour.value}
                      key={shortId.generate()}
                      checked={isCheckedRadio(
                        tefMentor.availabilityHour,
                        hour.value
                      )}
                      onChange={_handleChange}
                    />
                  );
                })}
              </FormField>

              <FormField
                label="What is your main motivation for wanting to become a mentor to the Tony Elumelu Foundation Entrepreneurship Programme?"
                labelInfo="Required"
                id="motivation"
              >
                {info.validationErrors.motivation && (
                  <div className="displayError">
                    {displayError(info.validationErrors.motivation)}
                  </div>
                )}
                <br />

                {motivations.map((motivation) => {
                  return (
                    <Radio
                      name="motivation"
                      label={motivation.label}
                      value={motivation.value}
                      key={shortId.generate()}
                      checked={isCheckedRadio(
                        tefMentor.motivation,
                        motivation.value
                      )}
                      onChange={_handleChange}
                    />
                  );
                })}
              </FormField>

              <FormField
                label="What is the maximum number of entrepreneurs you would like to mentor?"
                labelInfo="Required"
                id="maxNoOfEntrepreneurs"
              >
                {info.validationErrors.maxNoOfEntrepreneurs && (
                  <div className="displayError">
                    {displayError(info.validationErrors.maxNoOfEntrepreneurs)}
                  </div>
                )}
                <br />

                <div className="custom-row">
                  {numbers(5).map((value) => {
                    return (
                      <Radio
                        name="maxNoOfEntrepreneurs"
                        label={value.label}
                        value={value.value}
                        className="flex"
                        key={shortId.generate()}
                        checked={isCheckedRadio(
                          tefMentor.maxNoOfEntrepreneurs,
                          value.value
                        )}
                        onChange={_handleChange}
                      />
                    );
                  })}
                </div>
              </FormField>

              <FormField
                label="How did you find out about the Mentorship opportunity?"
                labelInfo="Required"
                id="howYouFindUs"
              >
                {info.validationErrors.howYouFindUs && (
                  <div className="displayError">
                    {displayError(info.validationErrors.howYouFindUs)}
                  </div>
                )}
                <br />

                {meansOfInformation.map((means) => {
                  return (
                    <Radio
                      name="howYouFindUs"
                      label={means.label}
                      value={means.value}
                      key={shortId.generate()}
                      checked={isCheckedRadio(
                        tefMentor.howYouFindUs,
                        means.value
                      )}
                      onChange={_handleChange}
                    />
                  );
                })}
              </FormField>

              <FormField
                label="Are you able to dedicate a minimum of 1 year to the Programme?"
                labelInfo="Required"
                id="isAvailableForOneYear"
              >
                <br />

                {trueAndFalse.map((value) => {
                  return (
                    <Radio
                      name="isAvailableForOneYear"
                      label={value.label}
                      value={value.value}
                      key={shortId.generate()}
                      checked={isCheckedRadio(
                        tefMentor.isAvailableForOneYear,
                        value.value
                      )}
                      onChange={_handleChange}
                    />
                  );
                })}
              </FormField>

              <FormField
                label="What is your preferred mode of communication?"
                labelInfo="Required"
                id="preferedModeOfCommunication"
              >
                {info.validationErrors.preferedModeOfCommunication && (
                  <div className="displayError">
                    {displayError(
                      info.validationErrors.preferedModeOfCommunication
                    )}
                  </div>
                )}
                <br />

                {preferedMeansOfCom.map((means) => {
                  return (
                    <Radio
                      name="preferedModeOfCommunication"
                      label={t(`${means.label}`)}
                      value={means.value}
                      key={shortId.generate()}
                      checked={isCheckedRadio(
                        tefMentor.preferedModeOfCommunication,
                        means.value
                      )}
                      onChange={_handleChange}
                    />
                  );
                })}
              </FormField>

              <div className="filter-block">
                <FormField
                  className="flex"
                  type="text"
                  label="Which of these areas will you say you have expertise in?"
                  labelInfo="Optional"
                  // onChange={_handleSearch}
                  // value={searchString}
                >
                  <ReactSelect
                    isMulti
                    options={areas}
                    value={info.userMentorshipAreas}
                    onChange={(val) =>
                      _handleBasicInfoChange("userMentorshipAreas", val)
                    }
                    closeMenuOnSelect={false}
                    placeholder={t("Select")}
                  />
                </FormField>
              </div>

              <div
                className="custom-row pad-inner-center"
                style={{ marginTop: "48px", marginBottom: "70px" }}
              >
                <div className="flex-grow">
                  <Button
                    value={t("Save & Continue")}
                    width="303px"
                    height="54px"
                    fontSize="16px"
                    onClick={_handleSubmit}
                  />
                </div>
                <div className="flex-grow steps-count">{t("Step 2 of 3")}</div>
              </div>
            </form>
          </div>
          <div className="mentor-page-container__form-section--right"></div>
        </section>
        {/* </form> */}
      </div>
    </div>
  );
};

// export default TefMentorOnboarding;
export default ifNotOnboardedThenShow(TefMentorOnboarding);
