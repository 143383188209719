export const personalInfo = {
  applicationId: "",
  firstName: "",
  lastName: "",
  middleName: "",
  gender: "",
  dateOfBirth: "",
  phoneNumber: "",
  email: "",
  levelOfEducation: "",
  yearsOfExperience: "",
  state: "",
  city: "",
  address: "",
  appliedForOtherFoundationGrant: undefined,
  nameOfOrganization: "",
  typeOfId: "",
  idUrl: "",
  staticIdUrl: "",
  howYouHearAboutUs: "",
  kinFirstName: "",
  kinLastName: "",
  kinMiddleName: "",
  kinPhone: "",
  kinEmail: "",
  aboutIdea: "",
  yearsOfStartupExperience: "",
  isIdeaInFieldOfExperience: undefined,
  sdgs: [],
  businessName: "",
  businessAddress: "",
  businessIndustry: "",
  businessState: "",
  businessCity: "",
  idNumber: "",
  businessCountryId: "",
  industrySubSector: "",
  businessAge: 0,
  isWorkingInTeam: undefined,
  teamSize: 0,
  hoursPerWeekInvested: 0,
  isIdeaProfitable: undefined,
  monthlyIncome: 0,
  haveBusinessPartners: undefined,
  hasBusinessPartnerConsent: undefined,
  partnerConsentLetterUrl: "",
  // hasBusinessPartnerConsent: false,
  status: "Ongoing",
  languageId: "",
  countryId: "",
  isBusinessRural: undefined,
  isResidentialRural: undefined,
  skipTour: false,
  ageRange: "",
  isBusinessAddressGreenEconomyOrClimateChange: undefined,
  businessAddressGreenEconomyOrClimateChangeDescription: "",
  businessWebsite: "",
  businessStage: "",
  businessRegistrationNumber: "",
  businessLocalGovernmentArea: "",
  isBusinessRegistered: undefined,
  isDisabledPerson: undefined,
  typeOfDisability: "",
  requiredAdjustmentToParticipateInTEF: "",
  isMemberOfYunitok: undefined,
  isMemberOfYoma: undefined,
  hasParticipatedInImaGenVenturesYouthChallenge: undefined,
  hasReceivedPriorTEFFunding: undefined,
  hasReceivedPriorTEFFundingOnSameBusinessIdea: undefined,
  availableRevenue: "",
  hasMeasuredEnvironmentalImpact: undefined,
  isIdeaInWasteManagementSector: undefined,

  validationErrors: {
    firstName: null,
    lastName: null,
    ageRange: null,

    // middleName: null,
    gender: null,
    dateOfBirth: null,
    phoneNumber: null,
    email: null,
    levelOfEducation: null,
    yearsOfExperience: null,
    state: null,
    city: null,
    address: null,
    nameOfOrganization: null,
    typeOfId: null,
    idUrl: null,
    howYouHearAboutUs: null,
    idNumber: null,
    partnerConsentLetterUrl: null,
    kinPhone: null,
    // kinPhone: "",
    kinEmail: null,
    // kinEmail: "",
    // mentorPhone: null,
    // mentorEmail: null,
    // mentorEmail: "",
    aboutIdea: null,
    yearsOfStartupExperience: null,
    sdgs: null,
    businessName: null,
    businessAddress: null,
    businessIndustry: null,
    businessState: null,
    businessCity: null,
    businessCountryId: null,
    industrySubSector: null,
    languageId: null,
    countryId: null,
    monthlyIncome: null,
    teamSize: null,
    hasBusinessPartnerConsent: null,
    businessAge: null,
    businessAddressGreenEconomyOrClimateChangeDescription: null,
    businessWebsite: null,
    businessStage: null,
    businessRegistrationNumber: null,
    businessLocalGovernmentArea: null,
    isResidentialRural: null,
    appliedForOtherFoundationGrant: null,
    isIdeaInFieldOfExperience: null,
    isWorkingInTeam: null,
    isIdeaProfitable: null,
    haveBusinessPartners: null,
    isBusinessRural: null,
    isBusinessAddressGreenEconomyOrClimateChange: null,
    isBusinessRegistered: null,
  },

  steps: {
    completedSteps: [],
    previousStep: "",
    currentCompletedStep: "",
    nextStep: "",
  },
};
