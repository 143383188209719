import { asyncActionName } from "src/utils/AsyncUtils";
import { initialState } from "src/store";
import { SEND_MESSAGE, GET_ALL_MESSAGES, GET_USER_MESSAGES } from "src/actions";

const MessageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case asyncActionName(SEND_MESSAGE).loading:
      return { ...state, loading: payload };
    case asyncActionName(SEND_MESSAGE).success:
      return {
        ...state,
        message: payload,
        success: true,
      };
    case asyncActionName(SEND_MESSAGE).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_ALL_MESSAGES).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_ALL_MESSAGES).success:
      return {
        ...state,
        messages: payload,
        success: true,
      };
    case asyncActionName(GET_ALL_MESSAGES).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_USER_MESSAGES).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_USER_MESSAGES).success:
      return {
        ...state,
        userMessages: payload,
        success: true,
      };
    case asyncActionName(GET_USER_MESSAGES).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    default:
      return state;
  }
};

export default MessageReducer;
