import { getConversationId } from "./Services/getConversationId";

const sendMessage = async (id, history) => {
  const res = await getConversationId(id);
  let convoId;
  if (res.status === 404) {
    history.push(`/messages/thread/${id}`);
  } else {
    convoId = res.conversationId;
    history.push(`/messages/thread/${convoId}`);
  }
};

export default sendMessage;
