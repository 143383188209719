import "./Skills.scss";
import "../common.scss";
import React, { useState, useContext, useEffect } from "react";

import { Btn, filterSkill } from "src/modules/profile/ProfileEdit/common";
import { addSkills, deleteSkill } from "src/requests/ProfileRequest";
import { Context } from "src/store";

import { FormField } from "src/modules/onboarding/components/formField";
import { GoTrashcan } from "react-icons/go";
import Loader from "react-loader-spinner";
import { SuggestionBox } from "src/modules/onboarding/stepTwo/Sections";
import { getSkills } from "src/requests/onboarding";
import _ from "lodash";
import shortId from "shortid";
import cancelIcon from "src/assets/img/cancel.png";
import { capitalize_Words } from "src/utils/helper";
import ReactSelect from "react-select";
import { Button, Col } from "reactstrap";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const ExistingSkillItem = ({ id, name, loading, onDeleteClick }) => {
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  return (
    <>
      <div className="edit-list-item">
        <span>{name}</span>
        <div style={{ display: "flex" }}>
          <span style={{ cursor: "pointer" }}>
            <GoTrashcan
              onClick={() => setShowDeletePrompt(!showDeletePrompt)}
            />
          </span>
          {loading && (
            <span>
              <Loader color="red" type="Oval" height={14} width={14} />
            </span>
          )}
        </div>
      </div>
      {showDeletePrompt && (
        <>
          <Col md={6} sm={12}>
            <span className="a-d-confirm-btn">
              Are you sure you want to delete this?
            </span>
          </Col>{" "}
          <Col md={6} sm={12}>
            <span
              className="a-d-confirm-btn delete-a-btn"
              onClick={() => onDeleteClick(id)}
            >
              Yes
            </span>
            <span
              className="a-d-confirm-btn delete-a-btn-r"
              onClick={() => {
                setShowDeletePrompt(false);
              }}
            >
              No, it was a mistake
            </span>
          </Col>
        </>
      )}
    </>
  );
};

export const SkillEdit = (props) => {
  const { cancel, callbacks } = props;
  const [loading, setLoading] = useState(false);
  const [newSkills, setNewSkills] = useState([]);
  const [userSkills, setUserSkills] = useState([]);
  const [existingSkills, setExistingSkills] = useState([]);
  const [skillsToSearchFrom, setSkillsToSearchFrom] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [skills, setSkills] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions] = useState([]);
  const [showNewItemAdd, setShowNewItemAdd] = useState(false);
  const [options, setOptions] = useState([]);
  const [_getDecoupledProfileParts] = callbacks;

  const user = get_user_info_from_token().user;

  useEffect(() => {
    setExistingSkills(props.skills);
  }, [props.skills]);

  useEffect(() => {
    if (existingSkills) {
      const existingSkillsId = existingSkills.map((skill) => {
        return skill.skillId;
      });

      const filtered = filterSkill(skills, existingSkillsId);
      if (filtered && filtered.length > 0) {
        const sks = filtered.map((skill) => {
          return { label: skill.name, value: skill.id };
        });
        setOptions([...sks, { label: "Other", value: "other" }]);
      }
      setSkillsToSearchFrom(filtered);
    }
  }, [existingSkills, skills]);

  useEffect(() => {
    getSkills(dispatch).then((res) => {
      const { status, data } = res;
      if (status === 200) {
        // if (data && data.length > 0) {
        //   const sks = data.map((skill) => {
        //     return { label: skill.name, value: skill.id };
        //   });
        //   setOptions([...sks, { label: "Other", value: "other" }]);
        // }
        setSkills(data);
      } else {
        setSkills([]);
      }
    });
  }, []);

  const { dispatch } = useContext(Context);

  const _handleSubmit = () => {
    setLoading(true);

    const uSkills = userSkills.map((skill) => {
      return { skillId: skill.skillId };
    });

    addSkills(dispatch, { userSkills: uSkills, newSkills }).then(() => {
      _getDecoupledProfileParts(user.id);
      onCancel();
      setLoading(false);
    });
    // updateUserProfile(dispatch, { bio }).then(() => {
    //   onCancel();
    //   setLoading(false);
    // });
  };

  const _onSuggestedItemClick = (value) => {
    const selectedSuggestions = [...userSkills];
    selectedSuggestions.push({ skillId: value.id, name: value.name });
    setUserSkills(_.uniqBy(selectedSuggestions, "skillId"));
    setSearchString("");
    setShowSuggestions(false);
  };

  const _onRemoveItemClick = (value) => {
    const filtered = userSkills.filter((skill) => {
      return skill.skillId !== value;
    });
    setUserSkills(filtered);
  };

  const _addNewSkill = () => {
    if (!searchString) {
      return;
    }
    const isExisitngUskill = skillsToSearchFrom.filter((skill) => {
      return skill.name.toLowerCase() === searchString.toLowerCase();
    });

    if (isExisitngUskill.length > 0) {
      _onSuggestedItemClick(isExisitngUskill[0]);
      setSearchString("");
      setShowNewItemAdd(false);
    } else {
      const existingSkills = [...newSkills];
      existingSkills.push({ name: capitalize_Words(searchString) });
      setNewSkills(_.uniqBy(existingSkills, "name"));
      setSearchString("");
      setShowNewItemAdd(false);
    }
  };

  const onCancel = () => {
    cancel();
  };

  const _onDeleteClick = (id) => {
    setDeleteId(id);
    deleteSkill(dispatch, [{ userSkillId: id }]).then(() => {
      _getDecoupledProfileParts(user.id);
      setDeleteId(null);
    });
  };

  const _onRemoveNewSkill = (index) => {
    const existingSkills = [...newSkills];
    const filtered = existingSkills.filter((item, i) => {
      return index !== i;
    });
    setNewSkills(_.uniqBy(filtered, "name"));
  };

  const _onSkillChange = (selected) => {
    const { label, value } = selected;
    const values = { id: value, name: label };
    if (values.id === "other") {
      setShowNewItemAdd(true);
    } else {
      _onSuggestedItemClick(values);
    }
  };

  const onAddNewSkillChange = (target, value) => {
    setSearchString(value);
  };

  return (
    <div className="">
      <div className="edit-profile-header">
        <h1>Edit Skills</h1>
      </div>
      <hr />

      {existingSkills && existingSkills.length > 0 && (
        <h1 className="heading-h1">Your skills</h1>
      )}

      {existingSkills && existingSkills.length > 0 && (
        <div className="edit-list-container">
          {existingSkills.map((skill) => {
            return (
              <ExistingSkillItem
                name={skill.name}
                id={skill.id}
                key={skill.id}
                onDeleteClick={_onDeleteClick}
                loading={deleteId === skill.id}
              />
            );
          })}
        </div>
      )}
      <br />
      <h1 className="heading-h1">Add New Skills</h1>
      <div className="search-item-c">
        <FormField>
          <ReactSelect
            options={options}
            value={null}
            placeholder="Select"
            onChange={_onSkillChange}
          />
        </FormField>

        {showSuggestions && (
          <SuggestionBox
            suggestions={suggestions}
            field="name"
            onItemClick={_onSuggestedItemClick}
          />
        )}
        {showNewItemAdd && (
          <div>
            <FormField
              // className="flex-grow"
              labelInfo="Optional"
              label="Add new Skill"
              placeholder="Add new skill"
              // onChange={_handleSearchSkills}
              onChange={onAddNewSkillChange}
              value={searchString}
              onBlur={() => {
                if (searchString.length === 0) {
                  setShowNewItemAdd(false);
                }
              }}
            />
            <FormField>
              <Button
                className="secondary"
                style={{ marginTop: "-15px" }}
                onClick={_addNewSkill}
              >
                Add Skill
              </Button>
            </FormField>
          </div>
        )}
        <div style={{ marginTop: "5px" }}></div>

        {newSkills.length > 0 && (
          <div className="added-topics-items">
            {newSkills.map((skill, i) => {
              return (
                <div
                  className="added-topics-items__item"
                  key={shortId.generate()}
                >
                  <span>{skill.name}</span>
                  <span
                    style={{
                      width: "12px",
                      height: "12px",
                      marginLeft: "19px",
                      cursor: "pointer",
                    }}
                    onClick={() => _onRemoveNewSkill(i)}
                  >
                    <img
                      src={cancelIcon}
                      alt="icon"
                      className="img-fluid"
                      // style={{ alignSelf: "center" }}
                    />
                  </span>
                </div>
              );
            })}
          </div>
        )}

        {userSkills.length > 0 && (
          <div className="added-topics-items">
            {userSkills.map((skill, i) => {
              return (
                <div
                  className="added-topics-items__item"
                  key={shortId.generate()}
                >
                  <span>{skill.name}</span>
                  <span
                    style={{
                      width: "12px",
                      height: "12px",
                      marginLeft: "19px",
                      cursor: "pointer",
                    }}
                    onClick={() => _onRemoveItemClick(skill.skillId)}
                  >
                    <img src={cancelIcon} alt="icon" className="img-fluid" />
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <hr />
      <div className="btn-box">
        <Btn className="btn-cancel" value="Cancel" onClick={onCancel} />
        <div style={{ flex: 1 }}></div>

        {newSkills.length > 0 || userSkills.length > 0 ? (
          <Btn
            className="btn-update"
            value="Save"
            loading={loading}
            onClick={() => _handleSubmit()}
          />
        ) : null}

        {/* </div> */}
      </div>
    </div>
  );
};
