import React from "react";
import BannerCard from "src/modules/mentorship-application/components/BannerCard/BannerCard";

import "./Homepage.scss";

const HomePage = () => {
  return (
    <React.Fragment>
      <BannerCard />
      <br />
    </React.Fragment>
  );
};

export default HomePage;
