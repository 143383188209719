import React from "react";
import { Button } from "reactstrap";
import { FiClock } from "react-icons/fi";
// import { FaMapMarkerAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import moment from "moment";

const OpportunityContent = (item) => {
  const history = useHistory();

  // const HumanDate = (startDate) => {
  //   return moment(startDate).format("DD MMMM, YYYY");
  // };
  // const truncate = (str, no_words) => {
  //   str = str.replace(/<\/?[^>]+(>|$)/g, "");

  //   return str.split(" ").splice(0, no_words).join(" ");
  // };

  const deadLineHumanDate = (deadline) => {
    return moment(deadline).format("ll");
  };
  return (
    <div className="opportunity__slide-wrap">
      <Button
        className="alumni-opportunity-imgbox__btn--featured"
        onClick={() => history.push(`/alumni-opportunity-detail/${item.id}`)}
      >
        Featured
      </Button>
      <div className="alumni-opportunity-imgbox__title">
        <p className="alumni-opportunity-imgbox__title--para">{item.title}</p>
      </div>
      <div className="alumni-opportunity-imgbox__metaData">
        <div className="meta-info">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "white",
              fontSize: "12px",
              marginBottom: "10px",
            }}
          >
            <span className="alumni-opportunity-imgbox__icon">
              <FiClock size={16} />
            </span>
            <span className="opport-deadline-fund">
              Deadline: {deadLineHumanDate(item.deadline)}
            </span>
          </div>
        </div>

        <Button
          className="view-opportunity"
          style={{ fontSize: "12px" }}
          onClick={() => history.push(`/alumni-opportunity-detail/${item.id}`)}
        >
          View Detail
        </Button>
      </div>
    </div>
  );
};

export default OpportunityContent;
