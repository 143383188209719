import { env } from "src/constants/env";
import { FRONTEND_URL } from "src/utils/frontendUrl";

const OPEN_ID_URL = env.REACT_APP_BASE_URL;

export const IDENTITY_CONFIG = {
  authority: OPEN_ID_URL, // The URL of the OIDC provider.
  client_id: env.REACT_APP_CLIENT_ID,
  client_secret: env.REACT_APP_CLIENT_SECRET,
  redirect_uri: FRONTEND_URL + "/callback", // The URI of your client application to receive a response from the OIDC provider.
  login: OPEN_ID_URL + "/login",
  automaticSilentRenew: true, // Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  loadUserInfo: false, // Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  // TODO(Jakub): Test silent renew.
  silent_redirect_uri: FRONTEND_URL + "/silentrenew", // (string): The URL for the page containing the code handling the silent renew.
  audience: "https://example.com", // is there a way to specify the audience when making the jwt
  scope: [
    "openid",
    "profile",
    "offline_access",
    "core",
    "application",
    "mentorship",
    "community",
    "identity",
    "opportunity",
    "postoffice",
    "tools",
    "cron-jobs",
    "storage",
    "training",
    "explorer",
  ].join(" "),
};

export const METADATA_OPEN_ID = {
  issuer: "https://identityserver",
  jwks_uri: OPEN_ID_URL + "/.well-known/openid-configuration/jwks",
  authorization_endpoint: OPEN_ID_URL + "/connect/authorize",
  token_endpoint: OPEN_ID_URL + "/connect/token",
  userinfo_endpoint: OPEN_ID_URL + "/connect/userinfo",
  end_session_endpoint: OPEN_ID_URL + "/connect/endsession",
  check_session_iframe: OPEN_ID_URL + "/connect/checksession",
  revocation_endpoint: OPEN_ID_URL + "/connect/revocation",
  introspection_endpoint: OPEN_ID_URL + "/connect/introspect",
};
