import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import CreateThread from "./CreateThread";

const CreateThreadIndex = ({ show, toggle, className, categories }) => {
  return (
    <Modal isOpen={show} toggle={toggle} className={className}>
      <ModalHeader toggle={toggle}>Create a new topic</ModalHeader>
      <ModalBody>
        <CreateThread cancel={toggle} categories={categories} />
      </ModalBody>
    </Modal>
  );
};

export default React.memo(CreateThreadIndex);
