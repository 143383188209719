import { asyncActionName } from "src/utils/AsyncUtils";
import {
  CREATE_PROGRAMME_EVENT,
  GET_ALL_PROGRAMME_EVENTS,
  DELETE_PROGRAMME_EVENT,
  START_PROGRAMME_EVENT,
  STOP_PROGRAMME_EVENT,
  GET_PROGRAMME_EVENT_TYPES,
  EDIT_PROGRAMME_EVENT,
  GET_PROGRAMME_EVENT_CONTENTS,
} from "src/actions";
import { initialState } from "src/store";

const updateProgrammeonEvent = (previousState, payload) => {
  const previous = [...previousState];
  const index = previous.findIndex((state) => state.id === payload.id);
  previous[index] = payload;
  return previous;
};
const ProgrammeEventReducer = (
  state = initialState.programmeEvents,
  { type, payload }
) => {
  switch (type) {
    case asyncActionName(CREATE_PROGRAMME_EVENT).loading:
      return { ...state, loadingCreateProgrammeEvent: payload };
    case asyncActionName(CREATE_PROGRAMME_EVENT).success:
      return {
        ...state,
        programmeEvents: [...state.programmeEvents, payload],
        success: true,
      };
    case asyncActionName(CREATE_PROGRAMME_EVENT).failure:
      return {
        ...state,
        errorCreatingProgrammeEvent: payload.status,
        success: false,
      };
    case asyncActionName(EDIT_PROGRAMME_EVENT).loading:
      return { ...state, loadingEditProgrammeEvent: payload };
    case asyncActionName(EDIT_PROGRAMME_EVENT).success:
      return {
        ...state,
        programmeEvents: updateProgrammeonEvent(state.programmeEvents, payload),
        success: true,
      };
    case asyncActionName(EDIT_PROGRAMME_EVENT).failure:
      return {
        ...state,
        errorEditingProgrammeEvent: payload.status,
        success: false,
      };
    case asyncActionName(GET_ALL_PROGRAMME_EVENTS).loading:
      return { ...state, loadinggetProgrammeEvents: payload };
    case asyncActionName(GET_ALL_PROGRAMME_EVENTS).success:
      return {
        ...state,
        programmeEvents: payload,
        success: true,
      };
    case asyncActionName(GET_ALL_PROGRAMME_EVENTS).failure:
      return {
        ...state,
        errorgettingProgrammeEvents: payload.status,
        success: false,
      };
    case asyncActionName(DELETE_PROGRAMME_EVENT).loading:
      return { ...state, requestDeleteProgrammeEvent: payload };
    case asyncActionName(DELETE_PROGRAMME_EVENT).success:
      return {
        ...state,
        programmeEvents: state.programmeEvents.filter((e) => e.id !== payload),
        success: true,
      };
    case asyncActionName(DELETE_PROGRAMME_EVENT).failure:
      return {
        ...state,
        errorDeletingProgrammeEvent: payload.status,
        success: false,
      };
    case asyncActionName(START_PROGRAMME_EVENT).loading:
      return { ...state, requestStartProgrammeEvent: payload };
    case asyncActionName(START_PROGRAMME_EVENT).success:
      return {
        ...state,
        programmeEvents: updateProgrammeonEvent(state.programmeEvents, payload),
        success: true,
      };
    case asyncActionName(START_PROGRAMME_EVENT).failure:
      return {
        ...state,
        errorStartingProgrammeEvent: payload.status,
        success: false,
      };
    case asyncActionName(STOP_PROGRAMME_EVENT).loading:
      return { ...state, requestStopProgrammeEvent: payload };
    case asyncActionName(STOP_PROGRAMME_EVENT).success:
      return {
        ...state,
        programmeEvents: updateProgrammeonEvent(state.programmeEvents, payload),
        success: true,
      };
    case asyncActionName(STOP_PROGRAMME_EVENT).failure:
      return {
        ...state,
        errorStopingProgrammeEvent: payload.status,
        success: false,
      };
    case asyncActionName(GET_PROGRAMME_EVENT_TYPES).success:
      return {
        ...state,
        programmeEventTypes: payload,
        success: true,
      };
    case asyncActionName(GET_PROGRAMME_EVENT_CONTENTS).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_PROGRAMME_EVENT_CONTENTS).success:
      return {
        ...state,
        programmeEventContents: payload,
      };
    case asyncActionName(GET_PROGRAMME_EVENT_CONTENTS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };

    default:
      return state;
  }
};

export default ProgrammeEventReducer;
