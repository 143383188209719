import React, { useState, useContext } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import classes from "./FlagOpportunityModal.module.css";
import {
  flagOpportunity,
  flagOpportunityNofification,
} from "src/requests/OpportunityRequest";
import { Context } from "src/store";
import FeedbackModal from "src/modules/Opportunity/components/Feedback/FeedbackModal";
import { FiHelpCircle } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const FlagOpportunityModal = ({ modal, toggle, opportunityId, currentUrl }) => {
  const { dispatch } = useContext(Context);
  const [selectedOption, setSelectedOption] = useState("");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const { t } = useTranslation();

  const Flags = [
    {
      keyword: "It’s suspicious or fraudulent",
      value: "It’s suspicious or fraudulent",
    },
    {
      keyword: "It’s spam or contains wrong information",
      value: "It’s spam or contains wrong information",
    },
    {
      keyword:
        "It’s expresses abusive and hateful notion against a protected category (e.g., race, religion, gender, orientation, disability)",
      value:
        "It’s expresses abusive and hateful notion against a protected category (e.g., race, religion, gender, orientation, disability)",
    },
    {
      keyword: "Threatening or inciting violence or physical harm",
      value: "Threatening or inciting violence or physical harm",
    },
  ];
  const handleSubmit = (e) => {
    e.preventDefault();
    flagOpportunity(dispatch, opportunityId, selectedOption).then((res) =>
      flagOpportunityNofification(opportunityId, currentUrl, selectedOption)
    );
    toggle();
    toggleFeedbackModal();
  };
  const handleChange = (e) => {
    setSelectedOption(e.currentTarget.dataset.id);
    // setIsSelected(e.currentTarget);
    // console.log(e.currentTarget.dataset.id);
  };
  const toggleFeedbackModal = () => setShowFeedbackModal(!showFeedbackModal);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader className={classes.ModalHeader} toggle={toggle}>
          <span>{t("Flag Opportunity")}</span>
        </ModalHeader>
        <ModalBody className={classes.ModalBody}>
          <h6 className={classes.ModalLabel}>
            {t("Help us understand the problem")} <br />
            {t("What is wrong with this opportunity?")}
          </h6>
          <ul>
            {Flags.map((flag) => {
              return (
                <li
                  className={`${classes.Option} ${
                    selectedOption === flag.keyword ? classes.isSelected : ""
                  }`}
                  key={flag.keyword}
                  data-id={flag.keyword}
                  onClick={handleChange}
                >
                  {flag.value}
                </li>
              );
            })}
          </ul>
        </ModalBody>
        <ModalFooter className={classes.ModalFooter}>
          <div className={classes.LearnMore}>
            <FiHelpCircle size="15" style={{ opacity: "0.8" }} />
            {` `}
            {t("Learn more about")}{" "}
            <span className={classes.CommunityGuideline}>
              {t("community guidelines.")}
            </span>
          </div>
          <div className={classes.Cta}>
            <Button color="secondary" onClick={(e) => handleSubmit(e)}>
              {t("Flag")}
            </Button>{" "}
            <Button color="primary" onClick={toggle}>
              {t("Cancel")}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <FeedbackModal modal={showFeedbackModal} toggle={toggleFeedbackModal} />
    </div>
  );
};

export default FlagOpportunityModal;
