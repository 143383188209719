/* eslint-disable prefer-const */
import React, { useState, useEffect, useContext } from "react";
import styles from "./Bookmarks.module.scss";
import Layout from "src/components/Layout/Layout";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { Context } from "src/store";
import { getAllBookmarks, getPost } from "src/requests/PostRequest";
// import NewPollCard from "src/modules/homepage/components/PollCard/NewPollCard/NewPollCard";
// import PostCard from "src/modules/homepage/components/PostCard/PostCard";
// import moment from "moment";
import PageLoader from "src/modules/homepage/components/PageLoader/PageLoader";
import SideRight from "src/modules/homepage/components/SideRight/SideRight";
import ErrorBoundaryComponentLevel from "src/utils/Services/ErrorBoundaryComponentLevel";
import PostCard_v2 from "src/modules/homepage/components/PostCard/PostCard_v2";

const Bookmarks = () => {
  const { userId } = useCurrentUser();
  const [bookmarks, setBookmarks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { state, dispatch } = useContext(Context);
  const bookmarkList = [];
  // const voteResult = [];
  let postCards;

  useEffect(() => {
    getAllBookmarks(dispatch, userId).then((response) => {
      if (response.length > 0) {
        response.forEach((res) => {
          getPost(dispatch, res.contentId).then((response) => {
            if (response.status === 200) {
              const { data } = response.data;
              bookmarkList.push(data);
              setBookmarks(bookmarkList);
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          });
        });
      } else {
        setIsLoading(false);
      }
    });
  }, [state.Posts.bookmark]);

  postCards = bookmarks.map((post, index) => {
    return (
      <ErrorBoundaryComponentLevel key={index}>
        <PostCard_v2 post={post} index={index} />
      </ErrorBoundaryComponentLevel>
    );
    // const user = post.profile ? post.profile : "";
    // const userDp =
    //   user.imageUrl === "" ||
    //   user.imageUrl === null ||
    //   user.imageUrl === undefined
    //     ? ""
    //     : user.imageUrl;
    // if (post.postType === "question") {
    //   const { postOptions, totalVoteCount } = post;
    //   postOptions.map((postOption) => {
    //     const { postId, content, voteCount } = postOption;
    //     const total = (voteCount / totalVoteCount) * 100 || 0;
    //     const score = total % 1 === 0 ? total : total.toFixed(1);
    //     const result = {
    //       score: score,
    //       option: content,
    //       optionId: postId,
    //       count: voteCount,
    //     };
    //     voteResult.push(result);
    //     return voteResult;
    //   });

    //   return (
    //     <NewPollCard
    //       key={post.id}
    //       postId={post.id}
    //       name={user ? user.firstName + " " + user.lastName : "Anonymous"}
    //       postDate={moment(post.createdOn).format("MMMM Do YYYY, h:mm:ss a")}
    //       post={post.content}
    //       likeCount={post.likeCount}
    //       commentCount={post.commentCount}
    //       shareCount={post.shareCount}
    //       options={post.postOptions}
    //       voteCount={post.totalVoteCount}
    //       id={userId}
    //       userId={user ? user.userId : ""}
    //       voteResult={voteResult}
    //       profilePic={userDp}
    //       expiryDate={moment(post.expireDate).format("LLL")}
    //       hasExpired={post.hasExpired}
    //     />
    // );
    // }

    //   return (
    //     <PostCard
    //       key={post.id}
    //       postId={post.id}
    //       name={user ? user.firstName + " " + user.lastName : "Anonymous"}
    //       postDate={moment(post.createdOn).format("MMMM Do YYYY, h:mm:ss a")}
    //       post={post.content}
    //       likeCount={post.likeCount}
    //       commentCount={post.commentCount}
    //       shareCount={post.shareCount}
    //       userId={user ? user.userId : ""}
    //       profilePic={userDp}
    //       postImages={post.postFiles}
    //       groupName={post.group ? post.group.name : null}
    //       groupId={post.group ? post.group.id : null}
    //       isBookmarked={post.isBookmarked}
    //     />
    //   );
  });

  return (
    <Layout SideContent={<SideRight />}>
      <div className={`col-md-12 ${styles.BookmarksContainer}`}>
        <h1>My Bookmarks</h1>
        {isLoading ? (
          <PageLoader />
        ) : postCards.length > 0 ? (
          postCards
        ) : (
          <h1 className={styles.BookmarksContainer__noContent}>No Bookmark</h1>
        )}
      </div>
    </Layout>
  );
};

export default Bookmarks;
