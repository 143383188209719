import React from "react";
import classes from "./MoreViewers.module.css";

const MoreViewers = ({ howManyMore }) => {
  return (
    <div className={classes.MoreViewers}>
      <div className={classes.HowManyMore}>+ {howManyMore}</div>
    </div>
  );
};

export default MoreViewers;
