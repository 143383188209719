import axios from "src/utils/axios";
import { messageURL } from "src/constants";

export const getConversationId = async (userId) => {
  return axios
    .get(`${messageURL.GET_CONVERSATION_ID}/${userId}`)
    .then((response) => {
      const { data } = response.data;
      return data;
    })
    .catch((e) => e.response);
};
