import React, { useEffect, useState } from "react";
import { hasDecimal } from "src/utils/helper";
import styles from "./assessment_summary.module.scss";

const ModuleSummaryTable = ({ moduleSummary, modules }) => {
  const [modulesObject, setModuleObj] = useState({});

  useEffect(() => {
    setModuleObj(
      moduleSummary.reduce(
        (obj, item) => ({ ...obj, [item.moduleId]: item }),
        {}
      )
    );
  }, [modules, moduleSummary]);

  console.log(modulesObject);

  const renderStatus = (startedOn, completedOn) => {
    const text = !startedOn
      ? "Not Started"
      : startedOn && !completedOn
      ? "In progress"
      : startedOn && completedOn
      ? "Completed"
      : "In progress";

    const color = (text) => {
      return text === "Completed"
        ? styles.started
        : text === "Not Started"
        ? styles.notStarted
        : styles.inProgress;
    };
    return <span className={color(text)}>{text}</span>;
  };

  return (
    <div className={styles.tcontainer}>
      <table className={styles.summaryTable}>
        <thead className={styles.theader}>
          <tr>
            <th>Modules</th>
            <th>Status</th>
            <th>No. of Attempts</th>
            <th>Lowest Score</th>
            <th>Final Score</th>
          </tr>
        </thead>
        <tbody>
          {modules
            .sort((a, b) => a.moduleNo - b.moduleNo)
            .map((mod, i) => {
              return (
                <tr key={i}>
                  <td>{mod?.title}</td>
                  <td>{renderStatus(mod?.startedOn, mod?.completedOn)}</td>
                  <td>
                    {hasDecimal(modulesObject[mod.id]?.noOfAttempts)
                      ? Number(modulesObject[mod.id]?.noOfAttempts).toFixed(1)
                      : modulesObject[mod.id]?.noOfAttempts || 0}
                  </td>
                  <td>
                    {hasDecimal(modulesObject[mod.id]?.lowestScore)
                      ? Number(modulesObject[mod.id]?.lowestScore).toFixed(1)
                      : modulesObject[mod.id]?.lowestScore || 0}
                  </td>
                  <td>
                    {hasDecimal(modulesObject[mod.id]?.finalScore)
                      ? Number(modulesObject[mod.id]?.finalScore).toFixed(1)
                      : modulesObject[mod.id]?.finalScore || 0}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ModuleSummaryTable;
