import React, { Fragment, useState, useContext } from "react";
import { AiOutlineMail, AiOutlineWhatsApp } from "react-icons/ai";
import { BiMessage } from "react-icons/bi";
import { GrClose } from "react-icons/gr";
import "./ContactUs.scss";
import Avatar from "react-avatar";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { isLoggedIn } from "src/utils/isLoggedIn";
import { Context } from "src/store";
import { sendFeedback } from "src/requests/FeedbackRequest";
import Loader from "react-loader-spinner";

const ContactUs = () => {
  const initialState = {
    feedBackType: "Comment",
    description: "",
    firstName: JSON.parse(localStorage.getItem("data"))
      ? JSON.parse(localStorage.getItem("data")).user.firstName
      : null,
    lastName: JSON.parse(localStorage.getItem("data"))
      ? JSON.parse(localStorage.getItem("data")).user.lastName
      : null,
    email: JSON.parse(localStorage.getItem("data"))
      ? JSON.parse(localStorage.getItem("data")).user.email
      : null,
  };

  const [isLoading, setisLoading] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const [mailSuportOpen, setMailSuportOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [feedbackData, setFeedbackData] = useState(initialState);
  const { dispatch } = useContext(Context);

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFeedbackData({
      ...feedbackData,
      [name]: value,
    });
  };

  const resetForm = () => {
    setFeedbackData({
      ...feedbackData,
      feedBackType: "Comment",
      description: "",
      firstName: JSON.parse(localStorage.getItem("data"))
        ? JSON.parse(localStorage.getItem("data")).user.firstName
        : null,
      lastName: JSON.parse(localStorage.getItem("data"))
        ? JSON.parse(localStorage.getItem("data")).user.lastName
        : null,
      email: JSON.parse(localStorage.getItem("data"))
        ? JSON.parse(localStorage.getItem("data")).user.email
        : null,
    });
  };

  const sendMail = async (e) => {
    e.preventDefault();
    setisLoading(true);

    sendFeedback(dispatch, feedbackData).then((res) => {
      setisLoading(false);
      if (res.data.status === "success") {
        resetForm();
        setSuccess(true);
      }
    });
  };

  return (
    <Fragment>
      <div className="position-fixed contact-icon-container">
        {!mailSuportOpen && (
          <Fragment>
            {chatOpen ? (
              <div className="slide-top">
                <a
                  href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP_CONTACT_NUMBER}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="bg-white p-2 mb-3 rounded-circle icon-background cursor-pointer contact-icon-c">
                    <AiOutlineWhatsApp size={35} />
                  </div>
                </a>
                <div
                  onClick={() => setMailSuportOpen(true)}
                  className="bg-white p-2 rounded-circle icon-background cursor-pointer contact-icon-c"
                >
                  <AiOutlineMail size={35} />
                </div>
                <div
                  onClick={() => setChatOpen(false)}
                  className="bg-white p-2 mb-3 mt-4 rounded-circle icon-background cursor-pointer contact-icon-c"
                >
                  <GrClose size={35} color="white" />
                </div>
              </div>
            ) : (
              <div
                onClick={() => setChatOpen(true)}
                className="bg-white p-2 mb-3 rounded-circle icon-background cursor-pointer contact-icon-c"
              >
                <BiMessage size={35} />
              </div>
            )}
          </Fragment>
        )}

        {mailSuportOpen && (
          <div
            style={{ width: "350px" }}
            className="support-container bounce-in-bottom bg-white"
          >
            <div className="bg-secondary text-white  px-4 py-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className="">
                  <span>
                    <Avatar
                      className="img-fluid text-secondary font-weight-bold bg-white mr-2"
                      round={true}
                      name={"TEF"}
                      size={40}
                      textSizeRatio={2}
                    />
                  </span>
                  <span>TEF Feedback Form</span>
                </div>
                <div>
                  <IoMdCloseCircleOutline
                    size={30}
                    onClick={() => {
                      setMailSuportOpen(false);
                      setSuccess(false);
                      resetForm();
                    }}
                  />
                </div>
              </div>
              <h2 className="mt-2 ml-2">
                Hi {isLoggedIn() ? feedbackData.firstName : null},
              </h2>
              <p className="ml-2">
                We would love to hear your thoughts, concerns and problems with
                anything we can improve
              </p>
            </div>

            <form onSubmit={sendMail} className="px-4 py-2">
              {!success ? (
                <Fragment>
                  {isLoggedIn() ? null : (
                    <div className="py-2">
                      <input
                        required
                        className="w-100 p-2"
                        placeholder="First Name"
                        value={feedbackData.firstName}
                        name="firstName"
                        onChange={handleInputChange}
                      />
                    </div>
                  )}
                  {isLoggedIn() ? null : (
                    <div className="py-2">
                      <input
                        required
                        className="w-100 p-2"
                        placeholder="Last Name"
                        value={feedbackData.lastName}
                        name="lastName"
                        onChange={handleInputChange}
                      />
                    </div>
                  )}
                  {isLoggedIn() ? null : (
                    <div className="py-2">
                      <input
                        required
                        type="email"
                        className="w-100 p-2"
                        placeholder="Email Address"
                        value={feedbackData.email}
                        name="email"
                        onChange={handleInputChange}
                      />
                    </div>
                  )}
                  <div className="py-2 d-flex justify-content-between text-center">
                    <div>
                      <input
                        id="feedback-type"
                        type="radio"
                        placeholder="Comments"
                        name="feedBackType"
                        value="Comment"
                        checked={feedbackData.feedBackType === "Comment"}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="feedback-type">Comment</label>
                    </div>
                    <div>
                      <input
                        id="feedback-type"
                        type="radio"
                        placeholder="Bug Report"
                        name="feedBackType"
                        value="Bug Report"
                        checked={feedbackData.feedBackType === "Bug Report"}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="feedback-type">Bug Report</label>
                    </div>
                    <div>
                      <input
                        id="feedback-type"
                        type="radio"
                        placeholder="Question"
                        name="feedBackType"
                        value="Question"
                        checked={feedbackData.feedBackType === "Question"}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="feedback-type">Question</label>
                    </div>
                  </div>
                  <div className="py-2">
                    <textarea
                      required
                      className="w-100 p-2"
                      placeholder="Describe Feedback"
                      value={feedbackData.description}
                      name="description"
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="d-flex justify-content-end">
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="btn btn-secondary mr-4 my-2 d-flex"
                    >
                      {isLoading && (
                        <Loader
                          type="Oval"
                          color="white"
                          height={15}
                          width={15}
                          className="mr-2"
                        />
                      )}{" "}
                      Send Feedback
                    </button>
                  </div>
                </Fragment>
              ) : (
                <div className="font-weight-bold py-5 my-5 text-center bounce-in-bottom">
                  <p>
                    Thanks for your feedback. Feedback will be addressed shortly{" "}
                  </p>
                  <p>
                    <button
                      onClick={() => setSuccess(false)}
                      className="btn btn-outline-secondary "
                    >
                      Go Back
                    </button>
                  </p>
                </div>
              )}
            </form>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ContactUs;
