import React from "react";
import "./BorderedAvatar.scss";
import Avatar from "react-avatar";
import EmptyImage from "src/assets/img/courseImage.jpg";
import { imageValid } from "src/modules/Discover/helper";

const BorderedAvatar = ({
  src,
  height,
  width,
  border,
  onClick,
  showBadge,
  name,
}) => {
  return (
    <div
      style={{
        height: height,
        width: width,
        border: border,
        borderRadius: "50%",
      }}
      className="profile-avatar-container__image-c"
    >
      {src ? (
        <span>
          <img
            src={imageValid(src) ? src : EmptyImage}
            alt="profile"
            className="img-fluid"
            style={{ borderRadius: "50%", height: "100%", width: "100%" }}
            onClick={onClick}
          />
          {showBadge && (
            <span className={`profile-avatar-container__image-c--badge`}>
              Mentor
            </span>
          )}
        </span>
      ) : (
        <span>
          <Avatar
            className="img-fluid"
            round={true}
            name={name}
            size={width}
            onClick={onClick}
            src={imageValid(src) ? src : EmptyImage}
          />
          {showBadge && (
            <span className={`profile-avatar-container__image-c--badge`}>
              Mentor
            </span>
          )}
        </span>
      )}
    </div>
  );
};

BorderedAvatar.propTypes = {};

export default BorderedAvatar;
