import React from "react";

const CompetitionTemplate = ({ competition }) => {
  return (
    <div>
      <h4 className="text-center font-weight-bold mb-5">Competition</h4>

      <p>
        <span className="mr-4">4.1</span>
        <span>COMPETITIVE ANALYSIS</span>
      </p>
      <p className="ml-5">
        {competition.competitiveAnalysis === ""
          ? "[COMPETITIVE ADVANTAGE]"
          : competition.competitiveAnalysis}
      </p>

      <p>
        <span className="mr-4">4.2</span>
        <span>CUSTOMERS RELATIONSHIPS</span>
      </p>
      <p className="ml-5">
        {competition.productOffers === ""
          ? "[PRODUCT/SERVICE OFFERS]"
          : competition.productOffers}
      </p>

      <p className="mt-4">
        <table className="table table-bordered text-center">
          <tbody>
            {/* <tr>
        <td colSpan="4" className="p-2 text-left font-weight-bold">
          Competitive Analysis
        </td>
      </tr> */}
            <tr>
              <th scope="row">Name of Competitors</th>
              <td>
                {competition.nameOfCompetitors[0] === ""
                  ? "[BUSINESS NAME]"
                  : competition.nameOfCompetitors[0]}
              </td>
              <td>
                {competition.nameOfCompetitors[1] === ""
                  ? "[COMPETITION 1]"
                  : competition.nameOfCompetitors[1]}
              </td>
              <td>
                {competition.nameOfCompetitors[2] === ""
                  ? "[COMPETITION 2]"
                  : competition.nameOfCompetitors[2]}
              </td>
            </tr>
            <tr>
              <th scope="row">Location of Competitors</th>
              <td>
                {competition.locationOfCompetitors[0] === ""
                  ? "[COMP-AN-LOC-C1]"
                  : competition.locationOfCompetitors[0]}
              </td>
              <td>
                {competition.locationOfCompetitors[1] === ""
                  ? "[COMP-AN-LOC-C2]"
                  : competition.locationOfCompetitors[1]}
              </td>
              <td>
                {competition.locationOfCompetitors[2] === ""
                  ? "[COMP-AN-LOC-C3]"
                  : competition.locationOfCompetitors[2]}
              </td>
            </tr>
            <tr>
              <th scope="row">Products and services offered</th>
              <td>
                {competition.productsOffered[0] === ""
                  ? "[COMP-AN-PSO1]"
                  : competition.productsOffered[0]}
              </td>
              <td>
                {competition.productsOffered[1] === ""
                  ? "[COMP-AN-PSO2]"
                  : competition.productsOffered[1]}
              </td>
              <td>
                {competition.productsOffered[2] === ""
                  ? "[COMP-AN-PSO3]"
                  : competition.productsOffered[2]}
              </td>
            </tr>
            <tr>
              <th scope="row">Pricing Strategy</th>
              <td>
                {competition.pricingStrategy[0] === ""
                  ? "[COMP-AN-PS1]"
                  : competition.pricingStrategy[0]}
              </td>
              <td>
                {competition.pricingStrategy[1] === ""
                  ? "[COMP-AN-PS2]"
                  : competition.pricingStrategy[1]}
              </td>
              <td>
                {competition.pricingStrategy[2] === ""
                  ? "[COMP-AN-PS3]"
                  : competition.pricingStrategy[2]}
              </td>
            </tr>
            <tr>
              <th scope="row">What are their uniqueness?</th>
              <td>
                {competition.uniqueness[0] === ""
                  ? "[COMP-UNIQ-1]"
                  : competition.uniqueness[0]}
              </td>
              <td>
                {competition.uniqueness[1] === ""
                  ? "[COMP-UNIQ-2]"
                  : competition.uniqueness[1]}
              </td>
              <td>
                {competition.uniqueness[2] === ""
                  ? "[COMP-UNIQ-3]"
                  : competition.uniqueness[2]}
              </td>
            </tr>
            <tr>
              <th scope="row">What is their other strength?</th>
              <td>
                {competition.strength[0] === ""
                  ? "[COMPETITOR STRENGTHS 1]"
                  : competition.strength[0]}
              </td>
              <td>
                {competition.strength[1] === ""
                  ? "[COMPETITOR STRENGTHS 2]"
                  : competition.strength[1]}
              </td>
              <td>
                {competition.strength[2] === ""
                  ? "[COMPETITOR STRENGTHS 3]"
                  : competition.strength[2]}
              </td>
            </tr>
            <tr>
              <th scope="row">What are their weaknesses?</th>
              <td>
                {competition.weaknesses[0] === ""
                  ? "[COMPETITOR WEAKNESSES 1]"
                  : competition.weaknesses[0]}
              </td>
              <td>
                {competition.weaknesses[1] === ""
                  ? "[COMPETITOR WEAKNESSES 2]"
                  : competition.weaknesses[1]}
              </td>
              <td>
                {competition.weaknesses[2] === ""
                  ? "[COMPETITOR WEAKNESSES 3]"
                  : competition.weaknesses[2]}
              </td>
            </tr>
            <tr>
              <th scope="row">How long have they been existing?</th>
              <td>
                {competition.durationOfExistence[0] === ""
                  ? "[COMPETITOR HISTORY 1]"
                  : competition.durationOfExistence[0]}
              </td>
              <td>
                {competition.durationOfExistence[1] === ""
                  ? "[COMPETITOR HISTORY 2]"
                  : competition.durationOfExistence[1]}
              </td>
              <td>
                {competition.durationOfExistence[2] === ""
                  ? "[COMPETITOR HISTORY 3]"
                  : competition.durationOfExistence[2]}
              </td>
            </tr>
            <tr>
              <th scope="row">Customers segments</th>
              <td>
                {competition.customerSegments[0] === ""
                  ? "[COMPETITOR CUSTOMER SEGMENTS 1]"
                  : competition.customerSegments[0]}
              </td>
              <td>
                {competition.customerSegments[1] === ""
                  ? "[COMPETITOR CUSTOMER SEGMENTS 2]"
                  : competition.customerSegments[1]}
              </td>
              <td>
                {competition.customerSegments[2] === ""
                  ? "[COMPETITOR CUSTOMER SEGMENTS 3]"
                  : competition.customerSegments[2]}
              </td>
            </tr>
          </tbody>
        </table>
      </p>
    </div>
  );
};

export default CompetitionTemplate;
