import React from "react";
import Avatar from "react-avatar";
import Loader from "src/components/Loader/Loader";
import "./CoverPhoto.scss";

const CoverPhoto = ({
  coverPhoto,
  groupTitle,
  showButton,
  handleCoverPhotoChange,
  loading,
}) => {
  return (
    <div className="cover-photo">
      {coverPhoto && coverPhoto !== "null" ? (
        <img src={coverPhoto} className="cover-photo__image" alt="cover" />
      ) : (
        <Avatar name={groupTitle} size="100%" />
      )}
      {showButton && (
        <form className="cover-photo__form">
          <label htmlFor="coverPhoto" className="cover-photo__label">
            {loading ? <Loader color="light" /> : "Cover Photo"}
          </label>
          <input
            type="file"
            hidden
            id="coverPhoto"
            onChange={handleCoverPhotoChange}
            name="coverPhoto"
            accept="image/png, image/jpeg, image/jpg"
            disabled={loading}
          />
        </form>
      )}
    </div>
  );
};

CoverPhoto.defaultProps = {
  showButton: true,
};

export default React.memo(CoverPhoto);
