import React from "react";

const VerticalProgress = ({ active, stageIndex, name }) => {
  return (
    <div style={{ position: "relative" }}>
      <div
        className="mt-3 ml-0"
        style={{
          position: "relative",
          height: "50px",
          fontFamily: "Poppins",
          marginRight: "15px",
        }}
      >
        {active && (
          <div
            className="pt-1"
            style={{
              height: "50px",
              position: "absolute",
              width: "100%",
              borderRadius: "50px",
              backgroundColor: "#DEE1E5",
            }}
          >
            <p className="ml-5 mt-3" style={{ fontSize: "0.523rem" }}>
              {name}
            </p>
          </div>
        )}
        {!active && (
          <div className="pt-1">
            <p className="ml-5 mt-3" style={{ fontSize: "0.523rem" }}>
              {name}
            </p>
          </div>
        )}
        <div
          style={
            active
              ? {
                  width: "1.5em",
                  height: "1.5em",
                  borderRadius: "50%",
                  position: "absolute",
                  left: "10px",
                  top: "15px",
                  backgroundColor: "#ffffff",
                  zIndex: "2",
                  textAlign: "center",
                  color: "#DA291C",
                }
              : {
                  width: "1.5em",
                  height: "1.5em",
                  borderRadius: "50%",
                  position: "absolute",
                  left: "10px",
                  top: "15px",
                  backgroundColor: "#ffffff",
                  zIndex: "2",
                  boxShadow: "2px 5px 5px rgba(0,0,0,0.2)",
                  textAlign: "center",
                  color: "#DA291C",
                }
          }
        >
          {stageIndex}
        </div>
        <div
          style={{
            borderLeft: "1px solid black",
            borderColor: "#707070",
            width: "4em",
            height: "4em",
            position: "absolute",
            left: "20px",
            top: "30px",
            zIndex: "1",
          }}
        ></div>
      </div>
      <div
        style={
          active
            ? {
                position: "absolute",
                right: "0",
                top: "22px",
                width: 0,
                height: 0,
                borderTop: "6px solid transparent",
                borderRight: "11px solid #DEE1E5",
                borderBottom: "6px solid transparent",
              }
            : {
                display: "none",
              }
        }
      ></div>
    </div>
  );
};

export default VerticalProgress;
