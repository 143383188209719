import React, { useState, useEffect, useContext } from "react";
import Layout from "src/components/Layout/Layout";
import { Link, useParams } from "react-router-dom";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import { Row, Col, Button, Label, FormGroup, Input, Spinner } from "reactstrap";
import "./EventDetail.scss";
import DefaultImage from "src/assets/img/bg/background_640-1.webp";
import { FiClock } from "react-icons/fi";
import { FaMapMarkerAlt, FaHeart, FaRegHeart } from "react-icons/fa";
// import SummaryCard from "src/modules/Alumni/Components/SummaryCard/SummaryCard";
// import { RiWallet2Line } from "react-icons/ri";
import EventFullDescription from "src/modules/Alumni/Components/FullDescription/EventFullDescription";
import classes from "./Event.module.css";
import ViewCommentWithRate from "src/modules/Alumni/Components/Comments/ViewCommentWithRate";
import StarRatingComponent from "react-star-rating-component";
import { Context } from "src/store";
import Swal from "sweetalert2";
// import Title from "src/modules/Alumni/components/Title/Title";
import { IoIosArrowRoundForward } from "react-icons/io";
import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  FacebookShareButton,
} from "react-share";
import moment from "moment";
import jwt_decode from "src/utils/jwt-decode";
import AddToCalendarHOC, { SHARE_SITES } from "react-add-to-calendar-hoc";
import CalenderModal, {
  Buttons,
} from "src/modules/Alumni/Components/CalenderRemainder/CalenderModal";

import {
  getAlumniEvent,
  getTotalComment,
  likeEvent,
  postComment,
  postRsvp,
  userlikedEvent,
  requestVisaLetter,
  // requestedVisaLetter,
  getRsvp,
  checkVisaLetterStatus,
} from "src/requests/AlumniEventRequest";
// import { getInterests } from "src/requests/onboarding";
import Loader from "src/components/Loader/Loader";
import OpportunityDetailContentLoader from "src/modules/Alumni/Components/SkeletonLoader/opportunityDetailLoader";
import { FRONTEND_URL } from "src/utils/frontendUrl";

const AddToCalendarDropdown = AddToCalendarHOC(Buttons, CalenderModal);

const EventDetail = () => {
  const params = useParams();
  const [alumniEvent, setAlumniEvent] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [hasRequestedVisaLetter, setHasRequestedVisaLetter] = useState(0);
  const [, setLoadingComment] = useState(false);
  const { dispatch } = useContext(Context);
  const [postingComment, setPostingComment] = useState(false);
  // const [popoverOpen, setPopoverOpen] = useState(false);
  // const [interestTags, setInterestTags] = useState([]);
  const [rating, setRating] = useState(0);
  const [isLikeEvent, setIsLikeEvent] = useState(false);
  const [commentDecription, setCommentDecription] = useState("");
  const [countEventLike, setCountEventLike] = useState([]);
  const [calenderPayload, setCalenderPayload] = useState({});
  const [requestingLetter, setRequestingLetter] = useState(false);
  const [requestingRsvp, setRequestingRsvp] = useState(false);
  // const [visaLetterStatus, setVisaLetterStatus] = useState(0);
  const [rsvpStatus, setRsvpStatus] = useState(0);

  const localDomain = `${FRONTEND_URL}/alumni-events-detail/${params.id}`;

  const cUser = localStorage.getItem("current_user");
  const current_user = jwt_decode(cUser).userId;

  // const [isLoadingLike, setIsLoadingLike] = useState(false);
  const [isLoadingLikeEvent, setIsLoadingLikeEvent] = useState(false);
  const styleHeader = (alumni) => ({
    backgroundImage: `linear-gradient(
      to right bottom,
      rgba(0,0,0,0.3),
      rgba(0,0,0,0.4)),
    url(${alumni.bannerUrl || DefaultImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    paddingTop: "60px",
  });

  useEffect(() => {
    _getEvent();
    _getTotalComment();
    _getUserLike();
  }, [params.id]);

  useEffect(() => {
    __requestedVisaLetter();
    __getRsvp();
  }, [params.id, requestingLetter, requestingRsvp]);

  const HumanDate = (date) => {
    return moment(date).format("DD MMMM, YYYY hh:mma");
  };

  const islikeEventFunc = (data) => {
    const exist = data.find((item) => item.eventId === params.id);

    if (exist) return true;
    return false;
  };

  const onStarClick = (nextValue, prevValue, name) => {
    setRating(nextValue);
  };
  const postEventComment = (e) => {
    e.preventDefault();
    const payload = {
      eventId: alumniEvent.id,
      comment: commentDecription,
      ratings: rating,
      userId: current_user,
    };

    setPostingComment(true);
    postComment(dispatch, payload).then((res) => {
      setPostingComment(false);
      setCommentDecription("");
      setRating(0);
      _getComments(dispatch, alumniEvent.id, 1);
    });
  };
  const __getRsvp = () => {
    getRsvp(dispatch, params.id).then((res) => {
      const {
        data: { data },
      } = res;

      setRsvpStatus(data);
    });
  };
  const __requestedVisaLetter = () => {
    // requestedVisaLetter(dispatch, params.id).then((res) => {
    //   const {
    //     data: { data },
    //   } = res;
    //   setVisaLetterStatus(data);
    // });
    checkVisaLetterStatus(dispatch, params.id).then((res) => {
      const {
        data: { hasRequestedVisaLetter },
      } = res;
      setHasRequestedVisaLetter(hasRequestedVisaLetter);
    });
  };
  const _getEvent = () => {
    getAlumniEvent(dispatch, params.id).then((res) => {
      if (res.status === 200) {
        const {
          data: { data },
        } = res;

        const endDate = moment(data.endDate);
        const startDate = moment(data.startDate);
        const endDatetime = moment(data.endDate).format("YYYYMMDDTHHmmssZ");

        const startDatetime = moment(data.startDate).format("YYYYMMDDTHHmmssZ");
        setCalenderPayload({
          description: data.description,
          endDatetime,
          startDatetime,
          title: data.title,
          duration: moment.duration(endDate.diff(startDate)).asHours(),
          location: data.isOnline ? "Online(Virtual)" : "offline",
        });
        setIsLoading(false);
        setAlumniEvent(data);
      }
    });
  };

  const inputHandler = (e) => {
    const { value } = e.target;
    setCommentDecription(value);
  };
  const _getComments = (dispatch, id, pageNum) => {
    getTotalComment(dispatch, id, pageNum).then((res) => {
      const {
        data: { data },
      } = res.data;

      setComments(data);
    });
  };

  const _getUserLike = () => {
    userlikedEvent(dispatch, params.id).then((res) => {
      const {
        data: { data },
      } = res;

      setIsLoadingLikeEvent(false);

      if (islikeEventFunc(data)) {
        setIsLikeEvent(true);
      } else {
        setIsLikeEvent(false);
      }

      const userLike =
        data && data.map((user) => user.eventsLikesByUser.imageUrl);
      setCountEventLike(userLike);
    });
  };
  // useEffect(() => {
  //   getInterests(dispatch).then((res) => {
  //     const { status, data } = res;
  //     if (status === 200 || status === 201) {
  //       const interests = data.map((interest) => {
  //         return {
  //           interestId: interest.id,
  //           interestName: interest.name,
  //         };
  //       });
  //       setInterestTags(interests);
  //     } else {
  //       setInterestTags([]);
  //     }
  //   });
  // }, []);
  const handleLikeEvent = () => {
    setIsLoadingLikeEvent(true);
    likeEvent(dispatch, params.id).then((res) => {
      const { message } = res.data;
      setIsLoadingLikeEvent(false);
      if (message) {
        _getUserLike();
      }
    });
  };
  const _getTotalComment = () => {
    setLoadingComment(true);
    getTotalComment(dispatch, params.id).then((res) => {
      if (res.status === 200) {
        const {
          data: { data },
        } = res.data;

        setLoadingComment(false);
        setComments(data);
      }
    });
  };

  const visaLetterHandler = () => {
    const payload = {
      eventId: params.id,
      userId: current_user,
    };
    setRequestingLetter(true);
    requestVisaLetter(dispatch, payload).then((res) => {
      const { message } = res.data;
      setRequestingLetter(false);
      Swal.fire("Visa Letter Status", `${message}`);
    });
  };

  const rsvpHandler = () => {
    setRequestingRsvp(true);
    postRsvp(dispatch, params.id).then((res) => {
      const { message } = res.data;
      setRequestingRsvp(false);
      Swal.fire("Event Status", `${message}`);
    });
  };
  const checkVisaLetterStatusFunc = () => {
    const status = {
      statusText: "",
      statusClass: "",
    };
    if (hasRequestedVisaLetter === 1 && !alumniEvent.status) {
      status.statusText = "Requested For Visa Letter";
      status.statusClass = "pending-visa";
    } else if (
      hasRequestedVisaLetter === 1 &&
      alumniEvent.status === "Approve"
    ) {
      status.statusText = "Visa Letter Approved";
      status.statusClass = "approved-visa";
    } else if (
      hasRequestedVisaLetter === 1 &&
      alumniEvent.status === "Decline"
    ) {
      status.statusText = "Visa Letter Declined";
      status.statusClass = "declined-visa";
    } else {
      status.statusText = "Request Visa Letter";
      status.statusClass = "";
    }
    return status;
  };

  const eventExpired = (endDate) => {
    const formattedEndDate = moment(endDate).format("MMMM DD YYYY");

    const eventdate = moment(formattedEndDate);
    const today = moment();
    if (eventdate >= today) {
      return `${eventdate.diff(today, "days")} days left`;
    } else {
      return "Expired!";
    }
  };
  console.log(typeof visaLetterHandler, typeof checkVisaLetterStatusFunc);
  return (
    <Layout SideContent={<SidebarRight />}>
      {isLoading ? (
        <OpportunityDetailContentLoader />
      ) : (
        <>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <figure className="event-detail" style={styleHeader(alumniEvent)}>
                <div className="event-alumni-breadcrumb">
                  <Link
                    to="/alumni-events"
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#fff",
                      textTransform: "capitalize",
                    }}
                  >
                    Alumni Event <IoIosArrowRoundForward />
                  </Link>
                  <Link
                    to="#"
                    style={{
                      fontSize: "12px",
                      textTransform: "capitalize",
                      color: "#fff",
                    }}
                  >
                    {alumniEvent.title}…{" "}
                  </Link>
                </div>
                <div className="event-detail__title event-detail__title">
                  <p className="event-detail-para">
                    {alumniEvent.title}{" "}
                    <span className="alumni__event-only only-full">
                      Alumni Only
                    </span>
                  </p>
                </div>

                <div className="event-detail__metaData">
                  <div
                    style={{
                      display: "flex",

                      alignItems: "center",
                      color: "white",
                    }}
                  >
                    <span className="event__icon">
                      <FaMapMarkerAlt />
                    </span>
                    <span className="deadline-fund">
                      {" "}
                      {alumniEvent.isOnline ? "Online (virtual)" : "Onsite"}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",

                      alignItems: "center",
                      color: "white",
                    }}
                    onClick={handleLikeEvent}
                  >
                    {isLoadingLikeEvent ? (
                      <Spinner size="sm" />
                    ) : isLikeEvent ? (
                      <>
                        <FaHeart
                          size={19}
                          style={{ color: "#cf1d10", cursor: "pointer" }}
                        />{" "}
                        <span className="ml-2">I liked this</span>
                      </>
                    ) : (
                      <>
                        <FaRegHeart
                          size={19}
                          color="#fff"
                          style={{ cursor: "pointer", marginRight: "10px" }}
                        />{" "}
                        <span className="ml-2">I like this</span>
                      </>
                    )}
                  </div>
                  {/* <div
                style={{
                  display: "flex",

                  alignItems: "center",
                  color: "white",
                }}
              >
                <span>
                  {" "}
                  <FaHeart color="#fff" /> I like this
                </span>
              </div> */}
                </div>
              </figure>
            </Col>
          </Row>

          <Row>
            <Col sm="12" md="9">
              <div className="event__main">
                {/* <SummaryCard
                  icon={<RiWallet2Line size={30} color={"rgb(248, 89, 89)"} />}
                  info={` FREE`}
                  details="Price per person"
                  otherClassName="event--price"
                  detailPad="event-detail-bx"
                  infoPad="event-detail-info"
                /> */}

                <div className={classes.FullDescription}>
                  <EventFullDescription
                    title="Description"
                    fullDescription={alumniEvent.description}
                    officialWebsite={alumniEvent.link}
                    privacy={alumniEvent.privacy}
                  />
                </div>
              </div>
              {/* <section className="event__contact">
                <h6> How can I contact the organizer? </h6>
              </section> */}

              <div className="event-detail--card event-rsvp event-rsvp-mobile">
                {alumniEvent.isClosed ? (
                  <Button disabled>RSVP CLOSED</Button>
                ) : (
                  <Button
                    onClick={rsvpHandler}
                    className="event-cta event-detail--rsvp"
                  >
                    {rsvpStatus === 1 ? "RSVPd" : "RSVP NOW"}
                  </Button>
                )}

                {/* <Button
                  onClick={visaLetterHandler}
                  className={`event-cta event-detail--visa ${
                    checkVisaLetterStatusFunc().statusClass
                  }`}
                >
                  {checkVisaLetterStatusFunc().statusText}

                </Button> */}
                {countEventLike && countEventLike.length > 0 && (
                  <div className="user__likes">
                    <div className="user-list">
                      {countEventLike.map((item, idx) => (
                        <img src={item} className="user__list" key={idx} />
                      ))}
                    </div>
                    <span className="user__like-count">
                      {" "}
                      +{countEventLike.length} going{" "}
                    </span>
                    <span className="user__like-val">
                      <FaHeart color="red" size={19} /> {countEventLike.length}
                    </span>
                  </div>
                )}
              </div>

              <div className="event-detail--card event-remainder event-rsvp-mobile">
                <h4 className="event-detail-tag-title">Date & Time</h4>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                    fontSize: "13px !important",
                  }}
                >
                  <span className="alumni-event__icon">
                    <FiClock color="green" />
                  </span>
                  <span className="remainder-date">
                    {HumanDate(alumniEvent.startDate)}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "25px",
                    fontSize: "13px",
                  }}
                >
                  <span className="alumni-event__icon">
                    <FiClock color="red" />
                  </span>
                  <span className="remainder-date">
                    {HumanDate(alumniEvent.endDate)}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "25px",
                    fontSize: "13px",
                  }}
                >
                  <span className="alumni-event__icon">
                    <FiClock color="blue" />
                  </span>
                  <span className="remainder-date">
                    {eventExpired(alumniEvent.endDate)}
                  </span>
                </div>
                <AddToCalendarDropdown
                  event={calenderPayload}
                  className="list-calender-agt"
                  linkProps={{
                    className: "calender-item",
                  }}
                  items={[
                    SHARE_SITES.GOOGLE,
                    SHARE_SITES.ICAL,
                    SHARE_SITES.OUTLOOK,
                  ]}
                />
              </div>
              <div className="event-detail--card  mb-3 event-rsvp-mobile">
                <h4 className="event-detail-tag-title">Tags</h4>

                <div className="event-tag">
                  {alumniEvent &&
                    alumniEvent.tags &&
                    alumniEvent.tags
                      // interestTags &&
                      // interestTags
                      //   .filter((relevantTag) =>
                      //     alumniEvent.interestIds.includes(relevantTag.tagId)
                      // )
                      .map((tag, idx) => (
                        <p key={idx} className="event-tag--item">
                          #{tag.name}
                        </p>
                      ))}
                </div>
              </div>
              <div className="event-detail--card event-rsvp-mobile mb-4">
                <h4 className="event-detail-tag-title">Share with friends</h4>
                <div className="event-detail-share">
                  <span className="social-item">
                    <TwitterShareButton
                      url={localDomain}
                      title={alumniEvent.description}
                    >
                      <TwitterIcon
                        size={50}
                        round={true}
                        className="icon-tab"
                      />
                    </TwitterShareButton>
                    {/* <TwitterIcon size={25} round={true} className="icon-tab" /> */}
                  </span>
                  <span className="social-item">
                    <FacebookShareButton
                      url={localDomain}
                      quote={alumniEvent.description}
                    >
                      <FacebookIcon
                        size={50}
                        round={true}
                        className="icon-tab"
                      />
                    </FacebookShareButton>
                    {/* <FacebookIcon size={25} round={true} className="icon-tab" /> */}
                  </span>
                  <span className="social-item">
                    <LinkedinShareButton
                      url={localDomain}
                      title={alumniEvent.description}
                    >
                      <LinkedinIcon
                        size={50}
                        round={true}
                        className="icon-tab"
                      />
                    </LinkedinShareButton>
                    {/* <LinkedinIcon size={25} round={true} className="icon-tab" /> */}
                  </span>
                </div>
              </div>

              <section className="comment__section">
                <h4 className="event-detail-tag-title">
                  Comment{" "}
                  {comments === null || comments.length === 0
                    ? ""
                    : `(${comments.length})`}
                </h4>

                <ViewCommentWithRate comments={comments} />
              </section>

              <section className="event__comment">
                <h6 className="event__comment--add-comment">Add Comment</h6>
                <div className="rate__comment">
                  <p className="rate__title">Rating </p>
                  <div className="star__rate ml-5">
                    <StarRatingComponent
                      name="rate1"
                      starCount={5}
                      marginTop={90}
                      marginLeft={300}
                      padding={0}
                      editing={true}
                      onStarClick={onStarClick}
                      starColor="rgb(252, 90, 90)"
                      emptyStarColor={"gray"}
                      value={rating}
                    />
                  </div>
                </div>
                <form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label
                          className="event__detail--message"
                          htmlFor="storyTitle"
                        >
                          Comment/Message
                        </Label>
                        <Input
                          type="textarea"
                          name="commentDecription"
                          id="commentDecription"
                          required
                          className="event__input"
                          onChange={inputHandler}
                          value={commentDecription}
                        />

                        <Button
                          className="post__comment--btn"
                          disabled={postingComment}
                          type="submit"
                          onClick={postEventComment}
                        >
                          {" "}
                          {postingComment ? (
                            <Loader color="light" />
                          ) : (
                            "Comment"
                          )}
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </form>
              </section>
            </Col>

            <Col sm="12" md="3" className="event-rsvp-full">
              <div className="event-detail--card event-rsvp event-rsvp-full">
                {alumniEvent.isClosed ? (
                  <Button disabled>RSVP CLOSED</Button>
                ) : (
                  <Button
                    onClick={rsvpHandler}
                    className="event-cta event-detail--rsvp"
                  >
                    {rsvpStatus === 1 ? "RSVPd" : "RSVP NOW"}
                  </Button>
                )}

                {/* <Button
                  onClick={visaLetterHandler}

                  className={`event-cta event-detail--visa ${
                    checkVisaLetterStatusFunc().statusClass
                  }`}
                >
                  {checkVisaLetterStatusFunc().statusText}
                </Button> */}
                {countEventLike && countEventLike.length > 0 && (
                  <div className="user__likes">
                    <div className="user-list">
                      {countEventLike.map((item, idx) => (
                        <img src={item} className="user__list" key={idx} />
                      ))}
                    </div>
                    <span className="user__like-count">
                      {" "}
                      +{countEventLike.length} going{" "}
                    </span>
                    <span className="user__like-count">
                      <FaHeart color="red" /> {countEventLike.length}
                    </span>
                  </div>
                )}
              </div>

              <div className="event-detail--card event-remainder event-remainder-full">
                <h4 className="event-detail-tag-title">Date & Time</h4>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "5px",
                    fontSize: "13px",
                  }}
                >
                  <span className="alumni-event__icon">
                    <FiClock color="red" />
                  </span>
                  <span className="remainder-date">
                    {HumanDate(alumniEvent.startDate)}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "25px",
                    fontSize: "13px",
                  }}
                >
                  <span className="alumni-event__icon">
                    <FiClock color="green" />
                  </span>
                  <span className="remainder-date">
                    {HumanDate(alumniEvent.endDate)}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "25px",
                    fontSize: "13px",
                  }}
                >
                  <span className="alumni-event__icon">
                    <FiClock color="blue" />
                  </span>
                  <span className="remainder-date">
                    {eventExpired(alumniEvent.endDate)}
                  </span>
                </div>
                <AddToCalendarDropdown
                  event={calenderPayload}
                  className="list-calender-agt"
                  linkProps={{
                    className: "calender-item",
                  }}
                  items={[
                    SHARE_SITES.GOOGLE,
                    SHARE_SITES.ICAL,
                    SHARE_SITES.OUTLOOK,
                  ]}
                />
              </div>
              <div className="event-detail--card mb-3">
                <h4 className="event-detail-tag-title">Tags</h4>

                <div className="event-tag">
                  {alumniEvent &&
                    alumniEvent.tags &&
                    alumniEvent.tags
                      // interestTags &&
                      // interestTags
                      //   .filter((relevantTag) =>
                      //     alumniEvent.interestIds.includes(relevantTag.tagId)
                      //   )
                      .map((tag, idx) => (
                        <p key={idx} className="event-tag--item">
                          #{tag.name}
                        </p>
                      ))}
                </div>
              </div>
              <div className="event-detail--card">
                <h4 className="event-detail-tag-title">Share with friends</h4>
                <div className="event-detail-share">
                  <span className="social-item">
                    <TwitterShareButton
                      url={localDomain}
                      title={alumniEvent.description}
                    >
                      <TwitterIcon
                        size={25}
                        round={true}
                        className="icon-tab"
                      />
                    </TwitterShareButton>
                    {/* <TwitterIcon size={25} round={true} className="icon-tab" /> */}
                  </span>
                  <span className="social-item">
                    <FacebookShareButton
                      url={localDomain}
                      quote={alumniEvent.description}
                    >
                      <FacebookIcon
                        size={25}
                        round={true}
                        className="icon-tab"
                      />
                    </FacebookShareButton>
                    {/* <FacebookIcon size={25} round={true} className="icon-tab" /> */}
                  </span>
                  <span className="social-item">
                    <LinkedinShareButton
                      url={localDomain}
                      title={alumniEvent.description}
                    >
                      <LinkedinIcon
                        size={25}
                        round={true}
                        className="icon-tab"
                      />
                    </LinkedinShareButton>
                    {/* <LinkedinIcon size={25} round={true} className="icon-tab" /> */}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Layout>
  );
};

export default ifOnboardedThenShow(EventDetail);
