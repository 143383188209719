import React, { useState, useEffect } from "react";
import "./fileViewer.scss";
import {
  AiOutlineDownload,
  AiFillFileExcel,
  AiOutlineFileWord,
  AiOutlineFilePdf,
  AiOutlineFilePpt,
  AiOutlineFileText,
} from "react-icons/ai";
import { IoIosAddCircleOutline } from "react-icons/io";
import ImageGridGenerator from "src/utils/imageGridGenerator";
// import { _truncateText } from "src/utils/helper";

function formatFileSize(bytes, decimalPoint) {
  if (bytes === 0) return "0 Bytes";
  const k = 1000;
  const dm = decimalPoint || 2;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const ImageViewer = ({ write, url, onFileRemoveClick }) => {
  return (
    <div className="fileItem-image">
      {write && (
        <div onClick={onFileRemoveClick} className="remove-file">
          x
        </div>
      )}
      <img src={url} className="img-fluid image-item-style" />
    </div>
  );
};

const DocFileViewer = ({
  write,
  file,
  onFileRemoveClick,
  onDownloadClick,
  fileName,
}) => {
  const showFilename = () => {
    if (fileName) {
      // return _truncateText(fileName, 50);
      return fileName;
    }
    return file;
    // return _truncateText(file, 50);
  };
  return (
    <div className="fileitem-doc">
      {write && (
        <div onClick={onFileRemoveClick} className="remove-file">
          x
        </div>
      )}
      <div>
        <span>{DocIcon(file.name || file)} </span>
        {file.name || showFilename()}
        {/* {_truncateText(file.name, 50) || showFilename()} */}
      </div>
      {file && file.size ? (
        <div style={{ textAlign: "center", display: "block" }}>
          {formatFileSize(file.size, 2)}
        </div>
      ) : (
        <div></div>
      )}

      {!write ? (
        <div style={{ cursor: "pointer" }}>
          <span>
            <AiOutlineDownload />
          </span>{" "}
          <span onClick={onDownloadClick}>Download </span>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

DocFileViewer.defaultProps = {
  onDownloadClick: () => {},
};

const DocIcon = (file) => {
  if (file.match(/\.(docx|doc)$/)) {
    return <AiOutlineFileWord color="blue" />;
  } else if (file.match(/\.(pdf)$/)) {
    return <AiOutlineFilePdf color="red" />;
  } else if (file.match(/\.(xls|xlsx)$/)) {
    return <AiFillFileExcel color="green" />;
  } else if (file.match(/\.(txt|csv)$/)) {
    return <AiOutlineFileText />;
  } else if (file.match(/\.(ppt)$/)) {
    return <AiOutlineFilePpt color="orange" />;
  } else {
    return null;
  }
};

const FileViewer = ({
  write,
  files,
  onFileRemoveClick,
  upload,
  onUploadFinish,
  onAddImageClick,
  showAddMore,
}) => {
  const [images, setImages] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);

  useEffect(() => {
    filterFiles();
  }, [files]);

  const filterFiles = () => {
    const imgFiles = files.filter((file) => {
      return file.name
        ? file.name.match(/\.(jpg|jpeg|png|gif)$/)
        : file.match(/\.(jpg|jpeg|png|gif)$/);
    });
    setImages(imgFiles);
    const otherFileType = files.filter((file) => {
      return file.name
        ? file.name.match(/\.(rtf|docx|doc|pdf|xml|bmp|ppt|xls|xlsx|csv|txt)$/)
        : file.match(/\.(rtf|docx|doc|pdf|xml|bmp|ppt|xls|txt)$/);
    });
    setOtherFiles(otherFileType);
  };

  const fileObj = (file) => {
    return file && file.name ? URL.createObjectURL(file) : file;
  };

  return (
    <>
      {images && images.length > 0 && (
        <div className="file-viewerContainer">
          {images.map((file, i) => {
            return (
              <ImageViewer
                key={i}
                url={write ? fileObj(file) : file}
                write={write}
                onFileRemoveClick={() => onFileRemoveClick(file.name)}
              />
            );
          })}
          {write && showAddMore && (
            <div className="add-file-btn" onClick={onAddImageClick}>
              <IoIosAddCircleOutline size={42} color="#c9c9c9" />
            </div>
          )}
        </div>
      )}
      {otherFiles && otherFiles.length > 0 && (
        <div className="file-viewerContainerDoc">
          {otherFiles.map((file, i) => {
            return (
              <DocFileViewer
                key={i}
                write
                file={file}
                onFileRemoveClick={() => onFileRemoveClick(file.name)}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

const FileViewerReadOnly = ({ files }) => {
  const [images, setImages] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);

  const filterFiles = () => {
    const imgFiles = files
      .filter((file) => {
        return file.match(/\.(jpg|jpeg|png|gif)$/);
      })
      .map((f) => {
        return {
          url: f,
        };
      });

    setImages(imgFiles);
    const otherFileType = files.filter((file) => {
      return file.match(/\.(rtf|docx|doc|pdf|xml|bmp|ppt|xls|xlsx|csv|txt)$/);
    });
    setOtherFiles(otherFileType);
  };
  useEffect(() => {
    filterFiles();
  }, []);

  const onDownloadCick = (url) => {
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  // ImageGridGenerator
  return (
    // <div className="image-row">
    //   {files.map((file) => {
    //     return (
    //       <div className="image-row-item" key={shortid.generate()}>
    //         <img src={file} />
    //       </div>
    //     );
    //   })}
    // </div>

    <>
      {images && images.length > 0 && (
        <div style={{ margin: "0 0 5px 0" }}>
          <ImageGridGenerator
            imageLength={images.length}
            images={images}
            handleClick={() => {}}
          />
        </div>
      )}
      {otherFiles && otherFiles.length > 0 && (
        <div className="file-viewerContainerDoc">
          {otherFiles.map((file, i) => {
            return (
              <DocFileViewer
                key={i}
                file={file}
                onDownloadClick={() => onDownloadCick(file)}
                fileName={`Document File ${i + 1}`}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

FileViewer.defaultProps = {
  write: false,
  upload: false,
  showAddMore: true,
  onFileRemoveClick: () => {},
  onUploadFinish: () => {},
  onAddImageClick: () => {},
};

export { FileViewer, FileViewerReadOnly };
