import React from "react";
import classes from "./NotificationCounter.module.css";

const NotificationCounter = ({ notificationCount }) => {
  return (
    <>
      {" "}
      {notificationCount && (
        <div className={classes.NotificationCounter}>
          {notificationCount > 0 && <div>{notificationCount}</div>}
        </div>
      )}
    </>
  );
};

export default NotificationCounter;
