import React, { useContext, useEffect, useRef, useState } from "react";
import "./connSearch.scss";
import ConnectionCard from "src/modules/Connections/ConnectionCard/ConnectionCard";
import { Row, Button, Col } from "reactstrap";
import {
  getConnections,
  getSuggestions,
  // getPendingConnectionReceived,
  // getPendingConnectionSent,
  createConnection,
} from "src/requests/ConnectionRequest";
import { Context } from "src/store";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import Loader from "react-loader-spinner";
// import { ContentLoaderC } from "src/modules/Connections/Suggestions/Suggestions";

const resourses = {
  MY_CONNECTION: "My Connections",
  SUGGESTED_CONNECTIONS: "Suggestions",
  SENT_REQUEST: "Sent Requests",
  RECEIVED_REQUEST: "Received Requests",
};

const ConnectionsSearch = ({ currentTab, toggleSearch }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageDetail, setPageDetail] = useState({
    hasNexPage: false,
    hasPreviousPage: false,
    pageNumber: 1,
  });
  const { dispatch } = useContext(Context);
  const data = get_user_info_from_token();
  const { id } = data.user;
  const [searchString, setSearchString] = useState("");
  const [loadingConnect, setLoadingConnect] = useState(null);
  const [sentRequest, setSentRequest] = useState([]);

  const focusRef = useRef();

  useEffect(() => {
    focusRef.current.focus();
  }, []);

  const onSearchChange = (e) => {
    const value = e.target.value;
    setSearchString(value);
  };
  const _getConnections = (pageNumber, merge) => {
    setLoading(true);
    getConnections(dispatch, id, pageNumber, searchString)
      .then((res) => {
        const { status, data } = res;
        if (status === 200 || status === 201) {
          if (merge) {
            const conns = [...list];
            const updated = conns.concat(data.data.data);
            setList(updated);
          } else {
            setList(data.data.data);
          }
          setPageDetail({ ...pageDetail, ...data.data.page });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const _getSuggestions = (pageNumber, merge) => {
    setLoading(true);
    getSuggestions(dispatch, id, pageNumber, searchString)
      .then((res) => {
        const { status, data } = res;
        if (status === 200 || status === 201) {
          if (merge) {
            const suggtns = [...list];
            const updated = [...suggtns, ...data.data.data];
            setList(updated);
          } else {
            setList(data.data.data);
          }

          setPageDetail({ ...pageDetail, ...data.data.page });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (searchString && searchString.length >= 1) {
      _getSearches(1, false);
    } else {
      setList([]);
      setPageDetail({ ...pageDetail, hasNexPage: false });
    }
  }, [searchString]);

  const _getSearches = (pageNumber, merge) => {
    switch (currentTab) {
      case resourses.SUGGESTED_CONNECTIONS:
        _getSuggestions(pageNumber, merge);
        break;
      case resourses.MY_CONNECTION:
        _getConnections(pageNumber, merge);
        break;
      // case "Sent Requests":
      //   _getPendingConnectionSent(
      //     dispatch,
      //     id,
      //     pageNumber,
      //     searchString,
      //     merge
      //   );
      //   break;

      // case "Received Requests":
      //   _getPendingConnectionReceived(
      //     dispatch,
      //     id,
      //     pageNumber,
      //     searchString,
      //     merge
      //   );
      // break;

      default:
        break;
    }
  };

  const _onLoadMoreClick = () => {
    const pageNum = pageDetail.pageNumber + 1;
    _getSearches(pageNum, true);
  };

  const connect = (toUserId) => {
    if (toUserId === id) {
      return;
    }
    setLoadingConnect(toUserId);

    createConnection(dispatch, id, toUserId).then((res) => {
      const sent = [...sentRequest];
      sent.push(toUserId);
      setSentRequest(sent);
      if (res.status === 200) {
        _getSuggestions(1, false);
        setLoadingConnect(null);
      }
    });
  };

  const _renderContent = () => {
    return (
      <>
        {list.length > 0 ? (
          list.map((item, i) => {
            return (
              <ConnectionCard
                key={i}
                userId={item.userId || item.user.userId}
                isConnected={currentTab !== resourses.SUGGESTED_CONNECTIONS}
                onConnect={
                  currentTab === resourses.SUGGESTED_CONNECTIONS
                    ? () => connect(item.userId || item.user.userId)
                    : () => {}
                }
                userDetails={{
                  userId: item.userId || item.user.userId,
                  firstName: item.firstName || item.user.firstName,
                  lastName: item.lastName || item.user.lastName,
                  // email: item.email || item.user.email,
                  // industryRole: item.industryRole || item.user.industryRole,
                  // country: item.country || item.user.country,
                  // state: item.state || item.user.state,
                  // city: item.city || item.user.city,
                  // address: item.address || item.user.address,
                  // language: item.language || item.user.language,
                  // gender: item.gender || item.user.gender,
                  // imageUrl:
                  //   (item.imageUrl && item.imageUrl) ||
                  //   (item.user.imageUrl && item.user.imageUrl),
                  // phone: item.phone || item.user.phone,
                  createdOn: item.createdOn,
                  userInterests:
                    (item.userInterests && item.userInterests) ||
                    (item.user.userInterests && item.user.userInterests),
                  bio:
                    (item.bio && item.bio) ||
                    (item.user && item.user.bio && item.user.bio) ||
                    "",
                }}
                loadingSendingRequest={
                  loadingConnect ===
                  ((item.userId && item.userId) ||
                    (item.user.userId && item.user.userId))
                }
                connectionStatus={
                  sentRequest.includes(
                    (item.userId && item.userId) ||
                      (item.user.userId && item.user.userId)
                  )
                    ? "pending"
                    : "connect"
                }
              />
            );
          })
        ) : (
          <>
            <Col md={12}>
              {!loading && list.length === 0 && searchString && (
                <h4 className="conn-search-b__t">No result found</h4>
              )}
            </Col>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div className="conn-search-c" style={{ position: "sticky" }}>
        <input
          type="text"
          onChange={onSearchChange}
          placeholder={`Search ${currentTab}`}
          value={searchString}
          ref={focusRef}
        />
        <div className="conn-search-c__cl">
          {loading && (
            <div style={{ marginRight: "5px" }}>
              {" "}
              <Loader height={25} width={25} color="red" type="Oval" />
            </div>
          )}
          <span onClick={toggleSearch}>X</span>
        </div>
      </div>

      {/* {loading && <h4 className="conn-search-b__t">loading...</h4>} */}

      <div className="conn-search-b">
        <Row className="conn-list" style={{ marginBottom: "150px" }}>
          {_renderContent()}
        </Row>
        {pageDetail.hasNextPage && (
          <Row>
            <Button
              disabled={loading}
              onClick={_onLoadMoreClick}
              className="loadmore-btn"
            >
              Show more
            </Button>
          </Row>
        )}
        {!loading && !searchString && (
          <h4 className="conn-search-b__t">
            Your search result will show here
          </h4>
        )}
      </div>
    </>
  );
};
export default ConnectionsSearch;
