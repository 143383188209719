/* eslint-disable no-prototype-builtins */
import React, { useState } from "react";
import { Table } from "reactstrap";
import styles from "./Threads.module.scss";
import Loader from "src/components/Loader/Loader";
import { Link } from "react-router-dom";
// import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const Threads = ({
  data,
  loading,
  onCategoryChange,
  // onNextClick,
  // onPreviousClick,
  // pageDetails,
}) => {
  const [sortType, setSortType] = useState(true);

  const onSort = (event, sortKey, order = sortType) => {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(sortKey) || !b.hasOwnProperty(sortKey)) {
        return 0;
      }

      const varA =
        typeof a[sortKey] === "string" ? a[sortKey].toUpperCase() : a[sortKey];
      const varB =
        typeof b[sortKey] === "string" ? b[sortKey].toUpperCase() : b[sortKey];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      setSortType(!sortType);
      return !order ? comparison * -1 : comparison;
    };
  };

  const displayTipMessage = (count, action) =>
    `This topic has ${count} ${action}`;

  const tableData = data.map((td, index) => {
    const category = td.category ? td.category : "";
    return (
      <tr key={index}>
        <td>
          <Link
            to={`/community/topic/${td.id}`}
            className="font-weight-bold text-black"
          >
            {td.name}
          </Link>
          <div
            className={styles.tableCategory}
            onClick={() => onCategoryChange(category.id)}
          >
            {category.name}
          </div>
        </td>
        <td
          style={{ textAlign: "center" }}
          title={displayTipMessage(td.postCount, "replies")}
        >
          {td.postCount}
        </td>
        <td
          style={{ textAlign: "center" }}
          title={displayTipMessage(td.likeCount, "likes")}
        >
          {td.likeCount}
        </td>
        <td
          style={{ textAlign: "center" }}
          title={displayTipMessage(td.sharesCount, "shares")}
        >
          {td.sharesCount}
        </td>
      </tr>
    );
  });

  return (
    <div className={styles.threadsMain}>
      {/* {pageDetails.hasPreviousPage && (
        <div className={styles.leftArrow} onClick={onPreviousClick}>
          <FaArrowLeft />
        </div>
      )}

      {pageDetails.hasNextPage && (
        <div className={styles.rightArrow} onClick={onNextClick}>
          <FaArrowRight />
        </div>
      )} */}

      {/* <div style={{ clear: "both" }}></div> */}

      {/* <div className={styles.loadingMore}>loading more</div> */}

      {loading ? (
        <div className={styles.threadsMainLoader}>
          <Loader color="danger" />
        </div>
      ) : (
        <Table borderless className={styles.table}>
          <thead>
            <tr>
              <th onClick={(e) => data.sort(onSort(e, "name"))}>Topic</th>
              <th onClick={(e) => data.sort(onSort(e, "postCount"))}>
                Replies
              </th>
              <th>Likes</th>
              <th>Shares</th>
            </tr>
          </thead>
          <tbody>{tableData}</tbody>
        </Table>
      )}
    </div>
  );
};

export default React.memo(Threads);
