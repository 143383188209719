import React from "react";
import { FaRegEdit } from "react-icons/fa";
import styles from "./MessageList.module.scss";
import ContactCard from "src/modules/Message/ContactCard/ContactCard";
import Loader from "src/components/Loader/Loader";

const MessageList = ({ list, onclick, loading, toggle }) => {
  const display = list.map((li) => {
    const {
      user: { userId, imageUrl, firstName, lastName },
      createdOn: date,
      message,
      conversationId,
    } = li;
    const name =
      `${firstName} ${lastName}`.length > 20
        ? `${lastName}`
        : `${firstName} ${lastName}`;

    return (
      <ContactCard
        key={conversationId}
        pic={imageUrl}
        name={name}
        date={date}
        message={message}
        conversationId={conversationId || userId}
        clicked={onclick}
        userId={userId}
      />
    );
  });
  return (
    <div className={styles.messageList}>
      <header className={styles.messageList__header}>
        <h4 className={styles.messageList__h4}>messages</h4>
        <FaRegEdit
          size={15}
          color="red"
          style={{ cursor: "pointer" }}
          onClick={toggle}
        />
      </header>
      <input
        type="text"
        className={styles.messageList__search}
        placeholder="search message"
      />
      <div className={styles.messageList__list}>
        {loading ? (
          <div className={styles.messageList__loader}>
            <Loader color="danger" />
          </div>
        ) : list.length > 0 ? (
          display
        ) : (
          <h4 className={styles.messageList__noChat}>
            Select a connection to chat with
          </h4>
        )}
      </div>
    </div>
  );
};

export default MessageList;
