import React, { useState, useContext } from "react";
import PhoneInput from "react-phone-input-2";
import { FaAngleLeft } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { Input, FormGroup, Label } from "reactstrap";
import moment from "moment";
import tefDark from "src/assets/img/logo/logo_200.png";
import "react-phone-input-2/lib/style.css";
import "./SARForm.scss";
import "./Document.scss";
import "../../components/FeedbackForm/FeedbackForm.scss";
import { sendDataAccessRight } from "src/requests/FeedbackRequest";
import { Context } from "src/store";
import Loader from "src/components/Loader/Loader";

const SARForm = () => {
  const history = useHistory();
  const initialState = {
    accept: false,
    byProxy: false,
    inPerson: false,
    inPersonFullName: "",
    inPersonDateOfBirth: "",
    inPersonTelephone: "",
    inPersonContactAddress: "",
    proxySurname: "",
    proxyFirstName: "",
    proxyEmail: "",
    proxyDateOfBirth: "",
    proxyTelephone: "",
    proxyContactAddress: "",
    relationShipToDataSubject: "",
    otherInformation: "",
    rightToAccess: false,
    rightToObject: false,
    rightToRectification: false,
    rightToErasure: false,
    rightToPortablity: false,
    rightToRestrictionToProcess: false,
    detailsOfRequest: "",
    preferredMediumOfFeedback: "",
  };

  const [data, setData] = useState(initialState);
  const [emailValid, setEmailValid] = useState(true);
  const { state, dispatch } = useContext(Context);

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });

    if (e.target.name === "proxyEmail") {
      validateEmail(e.target.value);
    }
  };

  const handleCheck = (e) =>
    setData({ ...data, [e.target.name]: e.target.checked });

  const validateEmail = (value) => {
    setEmailValid(value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    sendDataAccessRight(dispatch, data).then((res) => {
      setData({
        accept: false,
        byProxy: false,
        inPerson: false,
        inPersonFullName: "",
        inPersonDateOfBirth: "",
        inPersonTelephone: "",
        inPersonContactAddress: "",
        proxySurname: "",
        proxyFirstName: "",
        proxyEmail: "",
        proxyDateOfBirth: "",
        proxyTelephone: "",
        proxyContactAddress: "",
        relationShipToDataSubject: "",
        otherInformation: "",
        rightToAccess: false,
        rightToObject: false,
        rightToRectification: false,
        rightToErasure: false,
        rightToPortablity: false,
        rightToRestrictionToProcess: false,
        detailsOfRequest: "",
        preferredMediumOfFeedback: "",
      });
    });
  };

  const onBackClick = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <div className="feedback-container">
      <img src={tefDark} alt="TEF Logo" />
      <Link to="#" onClick={onBackClick}>
        <h6 className="declaration-h6">
          <FaAngleLeft /> &nbsp; back
        </h6>
      </Link>
      <h3 className="heading center bolder-text">
        data subject access right form
      </h3>
      <p className="note bolder-text">
        note: by completing this form, you consent that The Tony Elumelu
        Foundation. (TEF) would use your personal data to process your request
        and provide you with relevant response to your inquires
      </p>

      <div className="feedback-form-div">
        <form style={{ margin: "0 auto" }} onSubmit={handleSubmit}>
          <div className="feedback-input-div">
            <label>
              Your rights as a data subject can be exercised by completing this
              form and submitting via an email or the address at the bottom of
              this form
            </label>
            <div className="feedback-radio-container">
              <div className="feedback-radio-div">
                <label>In person</label>{" "}
                <input
                  type="checkbox"
                  onChange={handleCheck}
                  checked={data.inPerson}
                  name="inPerson"
                />
              </div>
              <div className="feedback-radio-div">
                <label>By proxy</label>{" "}
                <input
                  type="checkbox"
                  onChange={handleCheck}
                  checked={data.byProxy}
                  name="byProxy"
                />
              </div>
            </div>
          </div>

          <div className="feedback-input-div">
            <label>details of the person requesting information</label>
            <div className="feedback-input-div email">
              <label htmlFor="fullName">full name</label>
              <input
                required
                type="text"
                onChange={handleInputChange}
                value={data.inPersonFullName}
                id="fullName"
                name="inPersonFullName"
                placeholder="John Doe"
              />
            </div>

            <div className="feedback-name-container">
              <div className="feedback-name-div">
                <Input
                  type="date"
                  name="inPersonDateOfBirth"
                  id="dob"
                  max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                  required
                  onChange={handleInputChange}
                  value={data.inPersonDateOfBirth}
                />
                <label htmlFor="dob">date of birth</label>
              </div>
              <div className="feedback-name-div">
                <PhoneInput
                  inputProps={{
                    name: "inPersonTelephone",
                    required: true,
                    id: "phone",
                  }}
                  onChange={(phone) =>
                    setData({ ...data, inPersonTelephone: phone })
                  }
                  country="ng"
                  value={data.inPersonTelephone}
                />
                <label htmlFor="phone">telephone no</label>{" "}
              </div>
            </div>

            <div className="feedback-input-div">
              <label htmlFor="address">contact address</label>
              <Input
                type="textarea"
                onChange={handleInputChange}
                value={data.inPersonContactAddress}
                name="inPersonContactAddress"
                id="address"
                required
              />
            </div>
          </div>

          <div className="feedback-input-div">
            <label>details of the proxy (if applicable)</label>
            <div className="feedback-input-div email">
              <label htmlFor="surname">surname/ family name</label>
              <input
                required
                type="text"
                value={data.proxySurname}
                onChange={handleInputChange}
                id="surname"
                name="proxySurname"
                placeholder="Doe"
              />
            </div>

            <div className="feedback-name-container">
              <div className="feedback-name-div">
                <input
                  onChange={handleInputChange}
                  value={data.proxyFirstName}
                  required
                  type="text"
                  id="firstName"
                  name="proxyFirstName"
                  placeholder="John"
                />
                <label htmlFor="firstName">first name(s)/ forenames</label>
              </div>
              <div className="feedback-name-div">
                <PhoneInput
                  inputProps={{
                    name: "proxyTelephone",
                    id: "proxyPhone",
                    required: true,
                  }}
                  value={data.proxyTelephone}
                  onChange={(phone) =>
                    setData({ ...data, proxyTelephone: phone })
                  }
                  country="ng"
                />
                <label htmlFor="proxyPhone">telephone no</label>
              </div>
            </div>

            <div className="feedback-name-container">
              <div className="feedback-name-div">
                <Input
                  type="date"
                  name="proxyDateOfBirth"
                  id="dob-2"
                  max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                  required
                  onChange={handleInputChange}
                  value={data.proxyDateOfBirth}
                />
                <label htmlFor="dob-2">date of birth</label>
              </div>
              <div className="feedback-name-div">
                <input
                  required
                  onChange={handleInputChange}
                  value={data.proxyEmail}
                  type="email"
                  id="email"
                  name="proxyEmail"
                  placeholder="johndoe@email.com"
                  className={emailValid ? "email-pure" : "email-error"}
                />
                <label htmlFor="email">email address</label>{" "}
              </div>
            </div>
            <div className="feedback-input-div">
              <label htmlFor="proxyAddress">contact address</label>
              <Input
                type="textarea"
                onChange={handleInputChange}
                value={data.proxyContactAddress}
                name="proxyContactAddress"
                id="proxyAddress"
                required
              />
            </div>
            <div className="feedback-input-div email">
              <label htmlFor="relationship">
                relationship to the data subject
              </label>
              <input
                required
                onChange={handleInputChange}
                value={data.relationShipToDataSubject}
                type="text"
                id="relationship"
                name="relationShipToDataSubject"
              />
            </div>

            <p>
              a proxy must enclose a copy of a power of attorney or data
              subject&apos;s written authority and proof of the data
              subject&apos;s identity (such as Passport, driving licence,
              national identity card, birth certificate etc)
            </p>

            <div className="feedback-input-div">
              <label htmlFor="otherInfo">
                any other information that may help us
              </label>
              <textarea
                id="otherInfo"
                onChange={handleInputChange}
                value={data.otherInformation}
                name="otherInformation"
              ></textarea>
            </div>

            <div className="feedback-input-div">
              <label className="bolder-text">
                please tick the appropriate box and read the instruction which
                follows it
              </label>
              <div className="feedback-radio-container">
                <div className="feedback-input-div">
                  <div className="feedback-radio-div">
                    <label htmlFor="rightOfAccess">Right of access</label>{" "}
                    <input
                      type="checkbox"
                      name="rightToAccess"
                      checked={data.rightToAccess}
                      onChange={handleCheck}
                      id="rightOfAccess"
                      value="right of access"
                    />
                  </div>
                  <div className="feedback-radio-div">
                    <label htmlFor="rightToErasure">Right to Erasure</label>{" "}
                    <input
                      type="checkbox"
                      name="rightToErasure"
                      checked={data.rightToErasure}
                      onChange={handleCheck}
                      id="rightToErasure"
                      value="Right to Erasure"
                    />
                  </div>
                  <div className="feedback-radio-div">
                    <label htmlFor="rightToObject">Right to Object</label>{" "}
                    <input
                      type="checkbox"
                      name="rightToObject"
                      checked={data.rightToObject}
                      onChange={handleCheck}
                      id="rightToObject"
                      value="Right to Object"
                    />
                  </div>
                </div>
                <div className="feedback-input-div">
                  <div className="feedback-radio-div">
                    <label htmlFor="rightToPortability">
                      Right to Portability
                    </label>{" "}
                    <input
                      type="checkbox"
                      name="rightToPortablity"
                      checked={data.rightToPortablity}
                      onChange={handleCheck}
                      id="rightToPortability"
                      value="Right to Portability"
                    />
                  </div>
                  <div className="feedback-radio-div">
                    <label htmlFor="rightToRectification">
                      Right to Rectification
                    </label>{" "}
                    <input
                      type="checkbox"
                      name="rightToRectification"
                      checked={data.rightToRectification}
                      onChange={handleCheck}
                      id="rightToRectification"
                      value="Right to Rectification"
                    />
                  </div>
                  <div className="feedback-radio-div">
                    <label htmlFor="rightToRestrictionOfProcess">
                      Right to Restriction of Process
                    </label>{" "}
                    <input
                      type="checkbox"
                      name="rightToRestrictionToProcess"
                      checked={data.rightToRestrictionToProcess}
                      onChange={handleCheck}
                      id="rightToRestrictionOfProcess"
                      value="Right to Restriction of Process"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="feedback-input-div">
              <label htmlFor="detailsOfRequest">
                <span className="bolder-text">Details of Request:</span> Please
                describe the information you are seeking. Please provide any
                relevant details you think will help us to identify the
                information you require.
              </label>
              <Input
                type="textarea"
                onChange={handleInputChange}
                required
                name="detailsOfRequest"
                id="detailsOfRequest"
              />
            </div>

            <div className="feedback-input-div">
              <label className="bolder-text">
                Preferred Medium of Feedback
              </label>
              <p>Please tick the appropriate box below:</p>
              <div className="feedback-input-div">
                <div className="feedback-radio-div">
                  <input
                    required
                    onChange={handleInputChange}
                    checked={data.preferredMediumOfFeedback === "email"}
                    type="radio"
                    name="preferredMediumOfFeedback"
                    id="emailFeedback"
                    value="email"
                  />
                  <label htmlFor="emailFeedback">
                    Email as provided in our database
                  </label>
                </div>
                <div className="feedback-radio-div">
                  <input
                    required
                    onChange={handleInputChange}
                    checked={data.preferredMediumOfFeedback === "letter"}
                    type="radio"
                    name="preferredMediumOfFeedback"
                    id="letterFeedback"
                    value="letter"
                  />
                  <label htmlFor="letterFeedback">
                    Formal letter dispatched to Correspondence Address as
                    provided in our database
                  </label>
                </div>
                <div className="feedback-radio-div">
                  <input
                    required
                    onChange={handleInputChange}
                    checked={data.preferredMediumOfFeedback === "TEF Office"}
                    type="radio"
                    name="preferredMediumOfFeedback"
                    id="TEFOffice"
                    value="TEF Office"
                  />
                  <label htmlFor="TEFOffice">
                    <span>The Tony Elumelu Foundation.</span> Office
                  </label>
                </div>
              </div>
            </div>

            <div className="feedback-input-div">
              <FormGroup check inline>
                <Label check>
                  <Input
                    onChange={handleCheck}
                    checked={data.accept}
                    name="accept"
                    type="checkbox"
                  />{" "}
                  By checking this box, I confirm that I have read and
                  understood The Tony Elumelu Foundation Data Subject Access
                  Request Policy and the Data Privacy and Protection Policy. In
                  consideration of all the information stated herein, I certify
                  that the information provided in this form is correct to the
                  best of my knowledge and that I am the person to whom it
                  relates.
                </Label>
              </FormGroup>
            </div>
          </div>

          <div className="feedback-input-div">
            <button
              name="submit"
              disabled={!data.accept || !emailValid}
              className="submit-button"
            >
              {state.Feedback.loading ? <Loader color="light" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SARForm;
