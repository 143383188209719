/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import Loader from "react-loader-spinner";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import ReactAvatar from "react-avatar";
import Avatars from "src/components/Avatar/Avatar";
import { getUserProfileFull } from "src/requests/ProfileRequest";
import { setCurrentUser } from "src/utils/Services/currentUser";
import { Context } from "src/store";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import classes from "./login_v2.module.scss";

const LoginRedirect = (props) => {
  const { dispatch } = useContext(Context);
  const { user } = get_user_info_from_token();

  const lastPageVisited = () => {
    const lastPage = localStorage.lastPageVisited;
    if (lastPage && lastPage !== "") {
      localStorage.removeItem("lastPageVisited");
      return lastPage === "/" ? "/home" : lastPage;
    }
    return "/home";
  };

  useEffect(() => {
    getUserProfileFull(dispatch, user.id).then((response) => {
      const { data } = response;
      const redirectTo = data.data ? lastPageVisited() : "/onboarding";
      data.data && setCurrentUser(data.data);
      setTimeout(() => {
        props.history.push(redirectTo);
      }, 1000);
    });
  }, []);

  return (
    <div className={classes.loginRedirect}>
      <div className={classes.loginRedirect__inner}>
        {user.imageUrl ? (
          // {user.imageUrl || user.imageUrl.toLowerCase() !== "nil" ? (
          <Avatars
            src={user.imageUrl}
            dimension={100}
            name={`${user.firstName} ${user.lastName}`}
          />
        ) : (
          <ReactAvatar
            round
            size={100}
            name={`${user.firstName} ${user.lastName}`}
          />
        )}

        <br />
        <h4>
          Welcome,
          <span style={{ fontWeight: "bold" }}>
            {" "}
            {user.firstName} {user.lastName}{" "}
          </span>
        </h4>
      </div>
      <Loader type="ThreeDots" color="red" />
    </div>
  );
};

export default ifOnboardedThenShow(LoginRedirect);
