import React from "react";
import classes from "./SummaryCard.module.css";

const SummaryCard = ({
  icon,
  info,
  details,
  otherClassName,
  iconClass,
  detailPad,
  infoPad,
}) => {
  return (
    <div className={`${classes.SummaryCard} ${otherClassName}`}>
      <div className={classes.IconContainer}>
        <div className={`${classes.Icon} ${iconClass}`}>{icon}</div>
      </div>
      <div className={`${classes.OtherDetails} ${detailPad}`}>
        <div className={`${classes.Info} ${infoPad}`}>{info}</div>
        <div className={`${classes.Details} ${otherClassName}`}>{details}</div>
      </div>
    </div>
  );
};

export default SummaryCard;
