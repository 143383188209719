import React, { useState, useContext } from "react";
import "./About.scss";
import { Btn } from "src/modules/profile/ProfileEdit/common";
import ValidateJs from "src/utils/Validations/validation_wrapper";
import { displayError } from "src/modules/onboarding/helper";
import { FormField } from "src/modules/onboarding/components/formField";
import "react-phone-input-2/lib/style.css";

import "react-datepicker/dist/react-datepicker.css";
import { Context } from "src/store";
import { updateUserProfile } from "src/requests/ProfileRequest";

const SocialMedia = ({ onCancel, onChange, userInfo }) => {
  const [validationErrors, setValidationErrors] = useState({
    facebookUrl: null,
    linkedinUrl: null,
    instagramUrl: null,
    youtubeUrl: null,
    twitterUrl: null,
    websiteUrl: null,
  });

  const { dispatch } = useContext(Context);
  const [loading, setLoading] = useState(false);

  const checkURL = (url, stateString) => {
    let string = url;
    if (!~string.indexOf("http")) {
      string = `https://${string}`;
      onChange(stateString, string);
    }
    return url;
  };

  const _hasValidationErrors = (data) => {
    let errors = {};
    const errorList = [];
    // get not null or not empty fields

    const fieldNames = Object.keys(data);

    const notEmptyField = [];
    for (const key of fieldNames) {
      if (data[key] && data[key].length > 0) {
        notEmptyField.push(key);
      }
    }

    notEmptyField.forEach((key) => {
      errors = { ...errors, [key]: ValidateJs(key, data[key]) };
    });

    Object.keys(errors).forEach((key) => {
      if (errors[key] !== null) {
        errorList.push(key);
      }
    });
    setValidationErrors(errors);

    return errorList.length > 0;
  };

  const handleValidationErrorChanges = (target, value) => {
    setValidationErrors({ ...validationErrors, [target]: value });
  };

  const onSubmit = () => {
    const {
      facebookUrl,
      linkedinUrl,
      youtubeUrl,
      instagramUrl,
      websiteUrl,
      twitterUrl,
    } = userInfo;
    const payload = {
      facebookUrl,
      linkedinUrl,
      youtubeUrl,
      instagramUrl,
      websiteUrl,
      twitterUrl,
    };
    if (_hasValidationErrors(payload)) return;
    return _handleSubmit(payload);
  };
  const _handleSubmit = (payload) => {
    setLoading(true);
    Object.keys(payload).forEach((key) => {
      if (!payload[key]) {
        payload[key] = null;
      }
    });
    updateUserProfile(dispatch, payload)
      .then(() => {
        setLoading(false);
        onCancel();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="col-md-6">
            <FormField
              className="flex"
              type="url"
              label="Facebook Link"
              // labelInfo="Optional"
              placeholder="e.g. https://facebook.com/janedoe"
              name="facebookUrl"
              onChange={onChange}
              value={userInfo.facebookUrl}
              onBlur={
                userInfo &&
                userInfo.facebookUrl &&
                checkURL(userInfo.facebookUrl, "facebookUrl") &&
                userInfo.facebookUrl.length > 0
                  ? () =>
                      handleValidationErrorChanges(
                        "facebookUrl",
                        ValidateJs("facebookUrl", userInfo.facebookUrl)
                      )
                  : () => {
                      handleValidationErrorChanges("facebookUrl", null);
                    }
              }
              error={validationErrors.facebookUrl}
            />
            {validationErrors.facebookUrl &&
              displayError(validationErrors.facebookUrl)}
          </div>
          <div className="col-md-6">
            <FormField
              className="flex"
              type="url"
              label="Linkedin Link"
              // labelInfo="Optional"
              placeholder="e.g. https://linkedin.com/janedoe"
              name="linkedinUrl"
              onChange={onChange}
              value={userInfo.linkedinUrl}
              onBlur={
                userInfo &&
                userInfo.linkedinUrl &&
                checkURL(userInfo.linkedinUrl, "linkedinUrl") &&
                userInfo.linkedinUrl.length > 0
                  ? () =>
                      handleValidationErrorChanges(
                        "linkedinUrl",
                        ValidateJs("linkedinUrl", userInfo.linkedinUrl)
                      )
                  : () => {
                      handleValidationErrorChanges("linkedinUrl", null);
                    }
              }
              error={validationErrors.linkedinUrl}
            />
            {validationErrors.linkedinUrl &&
              displayError(validationErrors.linkedinUrl)}
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <FormField
              className="flex"
              type="url"
              label="Twitter Link"
              // labelInfo="Optional"
              placeholder="e.g. https://twitter.com/janedoe"
              name="twitterUrl"
              onChange={onChange}
              value={userInfo.twitterUrl}
              onBlur={
                userInfo &&
                userInfo.twitterUrl &&
                checkURL(userInfo.twitterUrl, "twitterUrl") &&
                userInfo.twitterUrl.length > 0
                  ? () =>
                      handleValidationErrorChanges(
                        "twitterUrl",
                        ValidateJs("twitterUrl", userInfo.twitterUrl)
                      )
                  : () => {
                      handleValidationErrorChanges("twitterUrl", null);
                    }
              }
              error={validationErrors.twitterUrl}
            />
            {validationErrors.twitterUrl &&
              displayError(validationErrors.twitterUrl)}
          </div>
          <div className="col-md-6">
            <FormField
              className="flex"
              type="url"
              label="Personal Website Link"
              // labelInfo="Optional"
              placeholder="e.g. https://janedoe.com"
              name="websiteUrl"
              onChange={onChange}
              value={userInfo.websiteUrl}
              onBlur={
                userInfo &&
                userInfo.websiteUrl &&
                checkURL(userInfo.websiteUrl, "websiteUrl") &&
                userInfo.websiteUrl.length > 0
                  ? () =>
                      handleValidationErrorChanges(
                        "websiteUrl",
                        ValidateJs("websiteUrl", userInfo.websiteUrl)
                      )
                  : () => {
                      handleValidationErrorChanges("websiteUrl", null);
                    }
              }
              error={validationErrors.websiteUrl}
            />
            {validationErrors.websiteUrl &&
              displayError(validationErrors.websiteUrl)}
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <FormField
              className="flex"
              type="url"
              label="Youtube Link"
              // labelInfo="Optional"
              placeholder="e.g. https://your-youtubechannel.com"
              name="youtubeUrl"
              onChange={onChange}
              value={userInfo.youtubeUrl}
              onBlur={
                userInfo &&
                userInfo.youtubeUrl &&
                checkURL(userInfo.youtubeUrl, "youtubeUrl") &&
                userInfo.youtubeUrl.length > 0
                  ? () =>
                      handleValidationErrorChanges(
                        "youtubeUrl",
                        ValidateJs("youtubeUrl", userInfo.youtubeUrl)
                      )
                  : () => {
                      handleValidationErrorChanges("youtubeUrl", null);
                    }
              }
              error={validationErrors.youtubeUrl}
            />
            {validationErrors.youtubeUrl &&
              displayError(validationErrors.youtubeUrl)}
          </div>
          <div className="col-md-6">
            <FormField
              className="flex"
              type="url"
              label="Instagram Link"
              // labelInfo="Optional"
              placeholder="e.g. https://your-instagram-channel-link.com"
              name="instagramUrl"
              onChange={onChange}
              value={userInfo.instagramUrl}
              onBlur={
                userInfo &&
                userInfo.instagramUrl &&
                checkURL(userInfo.instagramUrl, "instagramUrl") &&
                userInfo.instagramUrl.length > 0
                  ? () =>
                      handleValidationErrorChanges(
                        "instagramUrl",
                        ValidateJs("instagramUrl", userInfo.instagramUrl)
                      )
                  : () => {
                      handleValidationErrorChanges("instagramUrl", null);
                    }
              }
              error={validationErrors.instagramUrl}
            />
            {validationErrors.instagramUrl &&
              displayError(validationErrors.instagramUrl)}
          </div>
        </div>
        <div className="btn-box">
          <hr />
          <Btn className="btn-cancel" value="Cancel" onClick={onCancel} />
          <div style={{ flex: 1 }}></div>
          <Btn
            className="btn-update"
            value="Save Social Media Links"
            loading={loading}
            onClick={() => onSubmit()}
          />
        </div>
      </form>
    </div>
  );
};
export default SocialMedia;
