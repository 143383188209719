import { asyncActionName } from "src/utils/AsyncUtils";
import {
  VIEW_ALL_GOALS_ANALYSIS,
  VIEW_ALL_GOALS,
  VIEW_GOAL,
  CHANGE_GOAL_STATUS,
  DELETE_GOAL,
  CHANGE_MENTEE_GOAL_STATUS,
} from "src/actions";
import { initialState } from "src/store";

const updateGoalOnStatusChange = (goal, filter) => {
  if (Array.isArray(goal)) {
    const goalsArray = [...goal];
    const index = goalsArray.findIndex((goal) => goal.id === filter.id);
    goalsArray[index] = filter;
    return goalsArray;
  } else {
    const copy = { ...goal };
    if (copy.id === filter.id) {
      copy.status = filter.status;
      return copy;
    }
  }
};

const updateMenteeGoalOnStatusChange = (goal, filter) => {
  if (Array.isArray(goal)) {
    const goalsArray = [...goal];
    const index = goalsArray.findIndex((goal) => goal.id === filter.id);
    goalsArray[index] = filter;
    return goalsArray;
  } else {
    const copy = { ...goal };
    if (copy.id === filter.id) {
      copy.hasMenteeCompleted = filter.hasMenteeCompleted;
      return copy;
    }
  }
};

const deleteGoal = (goals, filter) => {
  if (Array.isArray(goals)) {
    const goalsArray = [...goals];
    return goalsArray.filter((goal) => {
      return goal.id !== filter;
    });
  } else {
    const copy = { ...goals };
    if (copy.id === filter) {
      copy.status = "deleted";
      return copy;
    }
    return goals;
  }
};

const GoalReducer = (state = initialState.goals, action) => {
  switch (action.type) {
    case asyncActionName(VIEW_ALL_GOALS_ANALYSIS).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(VIEW_ALL_GOALS_ANALYSIS).success:
      return {
        ...state,
        analysis: action.payload,
        success: true,
      };
    case asyncActionName(VIEW_ALL_GOALS_ANALYSIS).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(VIEW_ALL_GOALS).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(VIEW_ALL_GOALS).success:
      return {
        ...state,
        goals: action.payload,
        success: true,
      };
    case asyncActionName(VIEW_ALL_GOALS).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(VIEW_GOAL).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(VIEW_GOAL).success:
      return {
        ...state,
        goal: action.payload,
        success: true,
      };
    case asyncActionName(VIEW_GOAL).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };

    // update goal status
    case asyncActionName(CHANGE_GOAL_STATUS).loading:
      return { ...state, requestingChangeGoalStatus: action.payload };
    case asyncActionName(CHANGE_GOAL_STATUS).success:
      return {
        ...state,
        goals: updateGoalOnStatusChange(state.goals, action.payload),
        goal: updateGoalOnStatusChange(state.goal, action.payload),
      };
    case asyncActionName(CHANGE_GOAL_STATUS).failure:
      return {
        ...state,
        error: action.payload.status,
      };

    // update goal status
    case asyncActionName(CHANGE_MENTEE_GOAL_STATUS).loading:
      return { ...state, requestingChangeGoalStatus: action.payload };
    case asyncActionName(CHANGE_MENTEE_GOAL_STATUS).success:
      return {
        ...state,
        goals: updateMenteeGoalOnStatusChange(state.goals, action.payload),
        goal: updateMenteeGoalOnStatusChange(state.goal, action.payload),
      };
    case asyncActionName(CHANGE_MENTEE_GOAL_STATUS).failure:
      return {
        ...state,
        error: action.payload.status,
      };

    // delete goal
    case asyncActionName(DELETE_GOAL).loading:
      return { ...state, requestingGoalDelete: action.payload };
    case asyncActionName(DELETE_GOAL).success:
      return {
        ...state,
        goals: deleteGoal(state.goals, action.payload),
        goal: deleteGoal(state.goal, action.payload),
      };
    case asyncActionName(DELETE_GOAL).failure:
      return {
        ...state,
        error: action.payload.status,
      };
    default:
      return state;
  }
};
export default GoalReducer;
