import axios from "axios";
import { AlumniRequestURL } from "src/constants";

export const createRecommendationLetter = (formData) => {
  return axios({
    method: "post",
    url: `${AlumniRequestURL.CREATE_RECOMMENDATION_LETTER}`,
    data: formData,
  });
};

export const getResourceById = (resId) => {
  return axios({
    method: "GET",
    url: `${AlumniRequestURL.DEFAULT_RESOURCES_URL}/${resId}`,
  });
};

export const getResources = (query) => {
  return axios({
    method: "GET",
    url: `${AlumniRequestURL.DEFAULT_RESOURCES_URL}?${query}`,
  });
};

export const registerDownload = (resourceId) => {
  return axios({
    method: "POST",
    url: `${AlumniRequestURL.DEFAULT_RESOURCES_URL}/download`,
    data: { resourceId },
  });
};

export const getFeaturedResources = () => {
  return axios({
    method: "GET",
    url: `${AlumniRequestURL.DEFAULT_RESOURCES_URL}/feature`,
  });
};

export const toggleResourceLike = (resourceId) => {
  return axios({
    method: "POST",
    url: `${AlumniRequestURL.DEFAULT_RESOURCES_URL}/${resourceId}/likes/user/toggle`,
  });
};

export const fetchComments = (resourceId) => {
  return axios({
    method: "GET",
    url: `${AlumniRequestURL.DEFAULT_RESOURCES_URL}/${resourceId}/comments`,
  });
};

export const getUserProfile = (userIds) => {
  return axios({
    method: "POST",
    url: `${AlumniRequestURL.USER_PROFILE_URL}/many-light`,
    data: userIds,
  });
};

export const createComment = (resourceId, content) => {
  return axios({
    method: "POST",
    url: `${AlumniRequestURL.DEFAULT_RESOURCES_URL}/comment`,
    data: {
      resourceId,
      content,
    },
  });
};

// export const createBusinessSurvey = (data) => {
// 	return axios({
// 		method: 'POST',
//     url: `${alumniUrl.BUSINESS_REPORTING_SURVEY_URL}`,
//     data,
// 	});
// };

// export const editBusinessSurvey = (id, data) => {
//   return axios({
// 		method: 'PUT',
//     url: `${alumniUrl.BUSINESS_REPORTING_SURVEY_URL}/${id}`,
//     data,
// 	});
// }

// export const getSurveyById = (id) => {
// 	return axios({
// 		method: 'GET',
//     url: `${alumniUrl.BUSINESS_REPORTING_SURVEY_URL}/${id}`,
// 	});
// };

// export const getSubmittedReports = (surveyId) => {
// 	return axios({
// 		method: 'GET',
//     url: `${alumniUrl.BUSINESS_REPORTING_SURVEY_SUBMISSIONS_URL}/${surveyId}`,
// 	});
// };

export const fetchBusinessSurveys = () => {
  return axios({
    method: "GET",
    url: `${AlumniRequestURL.BUSINESS_REPORTING_SURVEY_URL}`,
  });
};

export const getSurveyById = (id) => {
  return axios({
    method: "GET",
    url: `${AlumniRequestURL.BUSINESS_REPORTING_SURVEY_URL}/${id}`,
  });
};

export const submitBusinessReport = (data) => {
  return axios({
    method: "POST",
    url: `${AlumniRequestURL.SUBMIT_BUSINESS_REPORT_URL}`,
    data,
  });
};

export const getBusinessReportsStatus = (surveyId) => {
  return axios({
    method: "GET",
    url: `${AlumniRequestURL.CONFIRM_SURVEY_SUBMISSION_STATUS}/${surveyId}`,
  });
};

export const fetchDashboardData = () => {
  return axios({
    method: "GET",
    url: `${AlumniRequestURL.GET_DASHBOARD_DATA}`,
  });
};

export const getBusinessReportById = (surveyId) => {
  return axios({
    method: "GET",
    url: `${AlumniRequestURL.SUBMIT_BUSINESS_REPORT_URL}/${surveyId}/user`,
  });
};
