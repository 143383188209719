import React from "react";

import FormInput from "src/modules/Tef-Dms/components/Form/FormInput";
import { Button } from "reactstrap";

const IndustryAndMarketAnalysis = ({
  industryMarketAnalysis,
  setIndustryMarketAnalysis,
}) => {
  console.log(
    "industryMarketAnalysis.opportunity: ",
    industryMarketAnalysis.opportunity
  );
  const handleChange = (e) => {
    setIndustryMarketAnalysis({
      ...industryMarketAnalysis,
      [e.target.name]: e.target.value,
    });
  };

  const handleDynamicInput = (key, i, e) => {
    const newArray = key;
    newArray[i] = e.target.value;
    setIndustryMarketAnalysis({
      ...industryMarketAnalysis,
      [key]: newArray,
    });
  };

  const addRow = (key, value) => {
    const newArray = key;
    newArray.push(value);
    setIndustryMarketAnalysis({
      ...industryMarketAnalysis,
      [key]: newArray,
    });
  };

  return (
    <React.Fragment>
      <FormInput
        label="Description Of Industry"
        getFormInputValue={(e) => handleChange(e)}
        value={industryMarketAnalysis.descriptionOfIndustry}
        name="descriptionOfIndustry"
        placeholder=""
      />
      <FormInput
        label="Description of Market:"
        getFormInputValue={(e) => handleChange(e)}
        value={industryMarketAnalysis.descriptionOfMarket}
        name="descriptionOfMarket"
        placeholder=""
      />

      <div className="mb-3">
        {industryMarketAnalysis.competitors.map((item, index) => (
          <FormInput
            key={index}
            label="Competitor:"
            getFormInputValue={(e) =>
              handleDynamicInput(industryMarketAnalysis.competitors, index, e)
            }
            value={item}
            placeholder=""
          />
        ))}

        <Button
          type="button"
          onClick={() => addRow(industryMarketAnalysis.competitors, "")}
          style={{ fontSize: "0.75em" }}
          className="btn btn-primary text-white px-2 py-1 border-0 mt-2"
        >
          Add Competitor
        </Button>
      </div>

      <div className="mb-3">
        {industryMarketAnalysis.challengesInBusiness.map((item, index) => (
          <FormInput
            key={index}
            label="Challenges in Business Industry:"
            getFormInputValue={(e) =>
              handleDynamicInput(
                industryMarketAnalysis.challengesInBusiness,
                index,
                e
              )
            }
            value={item}
            placeholder=""
          />
        ))}

        <Button
          type="button"
          onClick={() =>
            addRow(industryMarketAnalysis.challengesInBusiness, "")
          }
          style={{ fontSize: "0.75em" }}
          className="btn btn-primary text-white px-2 py-1 border-0 mt-2"
        >
          Add Challenges
        </Button>
      </div>

      <div className="mb-3">
        {industryMarketAnalysis.mitigationOfChallenges.map((item, index) => (
          <FormInput
            key={index}
            label="Mitigation for the above challenges:"
            getFormInputValue={(e) =>
              handleDynamicInput(
                industryMarketAnalysis.mitigationOfChallenges,
                index,
                e
              )
            }
            value={item}
            placeholder=""
          />
        ))}

        <Button
          type="button"
          onClick={() =>
            addRow(industryMarketAnalysis.mitigationOfChallenges, "")
          }
          style={{ fontSize: "0.75em" }}
          className="btn btn-primary text-white px-2 py-1 border-0 mt-2"
        >
          Add Mitigation
        </Button>
      </div>

      <div className="mb-3">
        {industryMarketAnalysis.opportunity.map((item, index) => (
          <FormInput
            key={index}
            label="Opportunities:"
            getFormInputValue={(e) =>
              handleDynamicInput(industryMarketAnalysis.opportunity, index, e)
            }
            value={item}
            placeholder=""
          />
        ))}

        <Button
          type="button"
          onClick={() => addRow(industryMarketAnalysis.opportunity, "")}
          style={{ fontSize: "0.75em" }}
          className="btn btn-primary text-white px-2 py-1 border-0 mt-2"
        >
          Add opportunity
        </Button>
      </div>

      <div className="mb-3">
        {industryMarketAnalysis.targetMarket.map((item, index) => (
          <FormInput
            key={index}
            label="Target Market:"
            getFormInputValue={(e) =>
              handleDynamicInput(industryMarketAnalysis.targetMarket, index, e)
            }
            value={item}
            placeholder=""
          />
        ))}

        <Button
          type="button"
          onClick={() => addRow(industryMarketAnalysis.targetMarket, "")}
          style={{ fontSize: "0.75em" }}
          className="btn btn-primary text-white px-2 py-1 border-0 mt-2"
        >
          Add Target Market
        </Button>
      </div>
    </React.Fragment>
  );
};

export default IndustryAndMarketAnalysis;
