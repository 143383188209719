/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { IoMdArrowForward, IoMdArrowBack } from "react-icons/io";
import { Row, Col, Input, FormGroup, Button } from "reactstrap";
import moment from "moment";
import Avatar from "src/components/Avatar/Avatar";
import readingTime from "reading-time";
import More from "src/assets/img/home/more.svg";
import "./Article.scss";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import { Context } from "src/store";
import Loader from "src/components/Loader/Loader";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import {
  FaComment,
  FaBookmark,
  FaHeart,
  FaFlag,
  FaDownload,
  FaLink,
  FaEye,
  FaRegHeart,
} from "react-icons/fa";
import DownloadArticle from "./DownloadArticle";
import ReportArticle from "./ReportArticle";
import {
  getArticle,
  createArticleComment,
  getAllComments,
  toggleLike,
  getAllLikeUser,
  getTotalComment,
  getStoryView,
  // getUserArticleLike,
} from "src/requests/DiscoverRequest";
import DOMPurify from "dompurify";
import ViewComment from "src/modules/Discover/components/components/Comments/ViewComment";
import Layout from "src/components/Layout/Layout";
import RightBar from "src/modules/profile/components/RightBar/RightBar";
import ArticleContentLoader from "src/modules/Discover/components/components/ArticleSkeleton";
import ShareArticleModal from "./ShareArticleModal/ShareOpportunityModal";
import { FRONTEND_URL } from "src/utils/frontendUrl";

const ViewArticle = () => {
  const history = useHistory();
  const params = useParams();
  const [article, setArticle] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [liked, setLiked] = useState(false);
  const [modal, setModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [double] = useState(false);
  const [countLikes, setCountLikes] = useState(0);
  const [tempCountLikes, setTempCountLikes] = useState(0);
  const [comments, setComments] = useState([]);
  const [totalComments, setTotalComments] = useState([]);
  const toggleDownlaod = () => setModal(!modal);
  const toggleReport = () => setReportModal(!reportModal);
  const { dispatch } = useContext(Context);
  const [commentData, setCommentData] = useState("");
  const [loading] = useState(false);
  const [reload, setReload] = useState(false);
  const [postingComment, setPostingComment] = useState(false);
  const [likeUsers] = useState([]);
  const [viewCount, setViewCount] = useState(0);
  const [pageDetails, setPageDetails] = useState({
    pageNumber: 1,
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const [showShareOnTEFModal, setShowShareOnTEFModal] = useState(false);

  const localDomain = `${FRONTEND_URL}/discover/article/${params.id}`;
  useEffect(() => {
    _getArticle();
    _getComments(dispatch, params.id, 1);
    _getTotalComment();
    _getViewCount();
  }, [likeUsers.length]);
  useEffect(() => {}, [article, commentData]);
  useEffect(() => {
    _getLikedUser();
  }, []);
  useEffect(() => {
    // setCountLikes(countLikes);
  }, [countLikes]);
  useEffect(() => {}, [tempCountLikes]);
  useEffect(() => {
    _getComments(dispatch, params.id, 1);
  }, [reload]);

  const humanDate = (article) => {
    return moment
      .utc(article && article.createdOn)
      .local()
      .format("MMMM DD YYYY");
  };
  const _getLikedUser = () => {
    getAllLikeUser(dispatch, params.id).then((res) => {
      if (res.status === 200) {
        const { data } = res.data;
        setTempCountLikes(data.length);
        const likeIds = data.map((x) => x.userId).includes(current_user);

        setCountLikes(data.length);
        likeIds ? setLiked(true) : setLiked(false);
      }
    });
  };
  const _getArticle = () => {
    getArticle(dispatch, params.id).then((res) => {
      if (res.status === 200) {
        const {
          data: { data },
        } = res;
        setIsLoading(false);
        setArticle(data);
      }
    });
  };
  const _getViewCount = () => {
    getStoryView(dispatch, params.id).then((res) => {
      if (res.status === 200) {
        const {
          data: { data },
        } = res;
      }
    });
  };
  const _getTotalComment = () => {
    getTotalComment(dispatch, params.id).then((res) => {
      if (res.status === 200) {
        const {
          data: { data },
        } = res;
        setTotalComments(data);
      }
    });
  };
  const _getComments = (dispatch, id, pageNum) => {
    getAllComments(dispatch, id, pageNum).then((res) => {
      if (res.status === 200) {
        const {
          data: { data },
        } = res.data;
        setIsLoading(false);
        setComments(data);
      }
    });
  };
  let timeToRead = null;
  if (article && article.fullDescription) {
    timeToRead = readingTime(article.fullDescription);
  }
  const handleLikePost = () => {
    toggleLike(dispatch, article.id).then((res) => {
      // setLiked((prev) => !prev);
      const { message } = res.data;
      if (message === "like") {
        setLiked(true);
      } else {
        setLiked(false);
      }
    });

    const totalLikeCount =
      countLikes <= tempCountLikes ? tempCountLikes - 1 : tempCountLikes;
    liked ? setCountLikes(totalLikeCount) : setCountLikes(tempCountLikes + 1);
  };
  const current_user = get_user_info_from_token()?.user?.id ?? "";

  const postComment = (e) => {
    e.preventDefault();
    const payload = {
      articleId: article.id,
      content: commentData,
    };
    setPostingComment(true);
    createArticleComment(dispatch, payload).then((res) => {
      setPostingComment(false);
      setCommentData("");
      _getComments(dispatch, article.id, 1);
    });
  };
  const _getAllComments = (pageNumber, loadMore) => {
    getAllComments(dispatch, article.id, pageNumber).then((res) => {
      if (res.status === 200) {
        const {
          data: { data, pager },
        } = res.data;
        if (loadMore) {
          const previousComment = [...comments];
          const newComments = [...previousComment, ...data];
          setComments(newComments);
        } else {
          setComments(data);
        }
        setPageDetails({ ...pageDetails, ...pager });
        setIsLoading(false);
      }
    });
  };
  const _onLoadMoreClick = () => {
    const pageNum = comments.page.pageNumber + 1;
    _getAllComments(pageNum, true);
  };
  const inputHandler = (e) => {
    const { value } = e.target;
    setCommentData(value);
  };
  const handleShareOnTEFConnect = () =>
    setShowShareOnTEFModal(!showShareOnTEFModal);
  const refreshPageFromChild = () => {
    setReload(!reload);
  };
  return (
    <Layout SideContent={<RightBar />}>
      {isLoading ? (
        <ArticleContentLoader />
      ) : (
        <div className="view-tef-article container-fluid px-md-5">
          <div
            onClick={() => history.push("/discover")}
            className="font-weight-bolder"
          >
            <span className="cursor-pointer">
              <IoMdArrowBack />
              Back
            </span>
            {/* <span className="px-2">TEF Articles</span>
            <IoMdArrowForward />

            <span>{article && article.title}</span> */}
          </div>
          <div className="text-center text-justify mt-5">
            <h2 className="text-capitalize">{article && article.title}</h2>
            <p className="Article-short-description">
              {article && article.shortDecription}
            </p>
            <div className="mt-4">
              <div className="d-flex">
                <Col md={12}>
                  <div className="d-flex pl-md-5">
                    {article && article.imageUrl ? (
                      <img
                        src={article.imageUrl}
                        alt="avatar"
                        className="writer-avatar mr-2"
                      />
                    ) : (
                      <Avatar
                        name={article && article.createdBy}
                        dimension={30}
                      />
                    )}
                    <p
                      className="text-justify font-weight-bolder"
                      style={{ marginLeft: "20px" }}
                    >
                      {article && article.createdBy} <br />
                      <span
                        className="font-weight-normal"
                        style={{ fontSize: "12px" }}
                      >
                        {humanDate(article)} ({timeToRead && timeToRead.text})
                      </span>
                    </p>
                  </div>
                </Col>
              </div>
            </div>
          </div>
          <div className="text-center mt-4">
            <img
              src={article.bannerUrl}
              alt="article"
              className="ArticleBanner"
            />
          </div>
          <div className="">
            <Row noGutters className="offset-1 mt-5">
              <Col md={10}>
                <div className="text-justify px-md-5 order-sm-1">
                  <div
                    className="Article-short-description"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(article.fullDescription),
                    }}
                  />
                  <div className="d-flex">
                    <button
                      className="svg-actions__like-btn"
                      style={{ marginRight: "25px" }}
                    >
                      <FaEye /> <span>{article.viewCount}</span>
                    </button>
                    <button
                      className="svg-actions__like-btn"
                      style={{ marginRight: "25px" }}
                    >
                      <FaComment /> <span>{comments.length || 0}</span>
                    </button>
                    <button
                      className="svg-actions__like-btn"
                      onClick={handleLikePost}
                      disabled={double}
                    >
                      {liked ? (
                        <FaHeart style={{ color: "#cf1d10" }} />
                      ) : (
                        <FaRegHeart />
                      )}
                      <span>{countLikes} Like</span>
                    </button>
                  </div>
                  <div className="d-flex mt-4">
                    {article &&
                      article.tags &&
                      article.tags.length > 0 &&
                      article.tags.map((item, idx) => (
                        <p key={idx} className="article-tag mr-3">
                          {item.name}
                        </p>
                      ))}
                  </div>
                  {/* <p className="font-weight-bold mt-3">#Tags</p>
                  <div className="d-flex">
                    <p className="article-tag">Tech</p>
                    <p className="article-tag mx-5">Startups</p>
                    <p className="article-tag">Music</p>
                  </div>
                  <p>Posted on October 20, 2020</p>  {/* <p className="font-weight-bold mt-3">#Tags</p>
                  <div className="d-flex">
                    <p className="article-tag">Tech</p>
                    <p className="article-tag mx-5">Startups</p>
                    <p className="article-tag">Music</p>
                  </div>
                  <p>Posted on October 20, 2020</p> */}
                  <h5 className="font-weight-bolder mt-4">Responses</h5>
                  <div>
                    <form onSubmit={postComment}>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <Input
                              type="textarea"
                              rows="3"
                              name="content"
                              value={commentData}
                              id="articleComment"
                              placeholder="Share your thoughts"
                              required
                              onChange={inputHandler}
                            />
                          </FormGroup>
                          <Button
                            className="float-right"
                            disabled={isLoading}
                            type="submit"
                          >
                            {postingComment ? (
                              <Loader color="light" />
                            ) : (
                              "Comment"
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                  {/* <ViewComment articleId={id} /> */}
                  {/* {comments &&
                    comments.map((com, idx) => (
                      <ArticleComment key={idx} comment={com} />
                    ))} */}
                  <ViewComment
                    articleId={params.id}
                    commentDa={comments}
                    refresh={refreshPageFromChild}
                  />
                  {pageDetails.hasNextPage && (
                    <Row>
                      <Button
                        disabled={loading}
                        onClick={_onLoadMoreClick}
                        className="loadmore-btn"
                      >
                        Show more
                      </Button>
                    </Row>
                  )}
                </div>
              </Col>
              <Col md={2}>
                <div className="order-sm-2 d-flex flex-column cursor-pointer">
                  <p>Share</p>
                  <div className="mb-3">
                    <p>
                      <FacebookShareButton
                        url={localDomain}
                        quote={article.shortDecription}
                      >
                        <FacebookIcon size={20} />
                      </FacebookShareButton>
                    </p>
                    <p>
                      <TwitterShareButton
                        url={localDomain}
                        title={article.shortDecription}
                      >
                        <TwitterIcon size={20} />
                      </TwitterShareButton>
                    </p>
                    <p>
                      <LinkedinShareButton
                        url={localDomain}
                        title={article.shortDecription}
                      >
                        <LinkedinIcon size={20} />
                      </LinkedinShareButton>
                    </p>
                    <p className="share-icon" onClick={handleShareOnTEFConnect}>
                      <FaLink />
                    </p>
                  </div>
                  <ShareArticleModal
                    toggle={handleShareOnTEFConnect}
                    modal={showShareOnTEFModal}
                    banner={article.bannerUrl}
                  />
                  {/* <p className="share-icon">
                    <FaHeart />
                  </p>
                  <p className="share-icon">
                    <FaBookmark />
                  </p>
                  <p className="share-icon">
                    <FaComment />
                  </p>
                  <p className="share-icon" onClick={toggleDownlaod}>
                    <FaDownload />
                  </p>
                  <p className="share-icon" onClick={toggleReport}>
                    <FaFlag />
                  </p> */}
                </div>
              </Col>
            </Row>
          </div>
          <DownloadArticle modal={modal} toggle={toggleDownlaod} />
          <ReportArticle modal={reportModal} toggle={toggleReport} />
        </div>
      )}
    </Layout>
  );
};
export default ViewArticle;
