import React from "react";
import PollCardResultButton from "./PollCardResultButton";
import classes from "./PollCard_v2.module.scss";

const PollCardResult = ({ total, options }) => {
  // useEffect(()=>{

  // },[total])
  return (
    <div className={classes.optionContainer} data-testid="PollCardResult">
      {options &&
        options.length > 0 &&
        options.map((option, i) => {
          const percentageCount = (option.voteCount / total) * 100 || 0;
          const score =
            percentageCount % 1 === 0
              ? percentageCount
              : percentageCount.toFixed(1);
          return (
            <PollCardResultButton
              key={i}
              option={option.content}
              percentageCount={score}
            />
          );
        })}
    </div>
  );
};

export default PollCardResult;
