import React from "react";
import classes from "./LandingPage.module.scss";
import people from "src/assets/img/landingPage/landingPagePeople.webp";
import email from "src/assets/img/landingPage/landingPageEmail.webp";
import hands from "src/assets/img/landingPage/landingPageHands.webp";
import map from "src/assets/img/landingPage/landingPageMap.webp";
import { _breakInChunck } from "src/utils/helper";
import { Row, Col } from "reactstrap";

const CountDown = () => {
  // const [countDown]

  const details = [
    {
      icon: email,
      title: "Subscribers",
      count: "1.2M",
    },
    {
      icon: people,
      title: "Mentors",
      count: "9000+",
    },
    {
      icon: hands,
      title: "Partnership Opportunities",
      count: "25+",
    },
    {
      icon: map,
      title: "African Countries",
      count: "54",
    },
  ];

  return (
    <div className={classes.countDownContainer}>
      {_breakInChunck(details, 2).map((chunk, idx) => {
        return (
          <Row key={idx} style={{ flex: 1 }}>
            {chunk.map((item, idx) => {
              return (
                <Col
                  md={6}
                  key={idx}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginBottom: "50px",
                  }}
                >
                  <img src={item.icon} alt={item.title} />
                  <h2>{item.count}</h2>
                  <span>{item.title}</span>
                </Col>
              );
            })}
          </Row>
        );
      })}
    </div>
  );
};

export default CountDown;
