import React, { useContext } from "react";
// import alarmIcon from "src/assets/img/icon-color.png";
import { Button } from "src/modules/training/components";
import { useHistory, useParams } from "react-router-dom";
// import alarmIcon2 from "src/assets/img/alarmIcon2.png";
import folderIcon from "src/assets/img/folderIcon.png";
import playIcon from "src/assets/img/play_icon_grey.png";
import { _truncateText } from "src/utils/helper";
import { Context } from "src/store";
import { userStartsCourse } from "src/requests/TrainingRequest";
// import { getUserCourseModules } from "src/requests/TrainingRequest";

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  if (color.toLowerCase() === "#fff" || color.toLowerCase() === "#ffffff") {
    return getRandomColor();
  }
  return color;
}

const ModuleItem = ({
  title,
  lessonCount,
  moduleNo,
  moduleId,
  courseId,
  eventId,
  startedOn,
  completedOn,
}) => {
  const { state, dispatch } = useContext(Context);
  const history = useHistory();
  const course = state.Training.userCourseModules;

  const redirectToTrainingContent = () => {
    history.push(
      `/training/lms/course?trainingEventId=${eventId}&courseId=${courseId}`
    );
  };

  const startCourse = () => {
    if (!course.startDate) {
      return userStartsCourse(dispatch, eventId, courseId).then((res) => {
        // setStartingCourse(false);
        if (res.status === 200 || res.status === 201) {
          return redirectToTrainingContent();
        }
      });
    }
    return redirectToTrainingContent();
  };

  return (
    <div className="training-module__content--item">
      <div
        className="training-module__content--item-label"
        style={{ backgroundColor: getRandomColor() }}
      >
        {Number(moduleNo < 10 && "0")}
        {moduleNo}
      </div>
      <div className="training-module__content--item-title">
        <h6>{_truncateText(title, 20)} </h6>
        <span>
          {!startedOn
            ? "Not Started"
            : startedOn && !completedOn
            ? "Started"
            : startedOn && completedOn
            ? "Completed"
            : "Started"}
          {/* <span style={{ color: "#0db884" }}>2%</span> */}
        </span>
      </div>
      {/* <div className="training-module__content--item-timer">
        <img
          src={alarmIcon2}
          style={{
            height: "14.4px",
            width: "14.4px",
            marginRight: "12px",
          }}
          alt="timer"
        />
        <span className="span-content">{`3 mins`}</span>
      </div> */}
      <div className="training-module__content--item-lecture-count">
        <img
          src={folderIcon}
          alt="folder"
          style={{
            height: "14.4px",
            width: "14.4px",
            marginRight: "12px",
          }}
        />
        <span className="span-content">{lessonCount} Lessions</span>
      </div>
      <Button
        value="View All"
        width="85px"
        height="29px"
        bgColor="#fff"
        borderColor="rgba(234, 236, 239, 0.43)"
        textColor="#919ba8"
        fontSize="12px"
        hasIcon
        icon={playIcon}
        onClick={startCourse}
      />
    </div>
  );
};

export const TrainingModule = ({ modules, courseProgress }) => {
  const { state, dispatch } = useContext(Context);
  const { courseId, eventId } = useParams();
  const history = useHistory();
  const lessionUrl = `/training/lms/course?trainingEventId=${eventId}&courseId=${courseId}`;
  const course = state.Training.userCourseModules;
  const redirectToTrainingContent = () => {
    history.push(lessionUrl);
  };

  const startCourse = () => {
    if (!course.startDate) {
      return userStartsCourse(dispatch, eventId, courseId).then((res) => {
        if (res.status === 200 || res.status === 201) {
          return redirectToTrainingContent();
        }
      });
    }
    return redirectToTrainingContent();
  };

  return (
    <div className="" id="training_module">
      <div className="training-module">
        <div className="training-module--header">
          <h1>
            Training Modules{" "}
            <span className="training-module--header__count-modules">
              ({(modules && modules.length) || 0})
            </span>
          </h1>
          <Button
            value="Proceed to training!"
            width="135px"
            height="32px"
            fontSize="11.5px"
            onClick={startCourse}
          />
        </div>
        <section className="training-module__content">
          {modules
            .slice(0, 9)
            .sort((a, b) => a.moduleNo - b.moduleNo)
            .map((md, i) => {
              return (
                <ModuleItem
                  key={i}
                  title={md.title}
                  moduleId={md.id}
                  lessonCount={md.totalLessons}
                  completedOn={md.completedOn !== null}
                  courseId={courseId}
                  moduleNo={md.moduleNo}
                  eventId={eventId}
                  startedOn={md.startedOn !== null}
                />
              );
            })}
        </section>
      </div>
    </div>
  );
};

// const FAQS = () => {
//   return (
//     <div className="faq">
//       <FAQ
//         faqs={[
//           { question: "how are you", solution: "am fine o" },
//           { question: "how old are you", solution: "am 39.85 years old" },
//         ]}
//       />
//     </div>
//   );
// };

// const FreeResourceItem = () => {
//   return (
//     <div style={{ marginTop: "24px" }}>
//       <div className="free-resources__item">
//         <div className="free-resources__item--icon">
//           <img
//             src={pdfIcon}
//             alt="icon"
//             style={{ height: "inherit", width: "inherit" }}
//           />
//         </div>
//         <div className="free-resources__item--link">
//           <p>Learning New Development..pdf</p>
//         </div>
//         <div className="free-resources__item--size">
//           <p>Size: 8mb</p>
//         </div>
//         <div className="free-resources__item--download">
//           <img
//             src={downloadIcon}
//             alt="download icon"
//             style={{ width: "12px", height: "12px", marginRight: "8px" }}
//           />
//           <p className="free-resources__item--download-text">Download</p>
//         </div>
//       </div>
//       <hr style={{ marginTop: "-10px" }} />
//     </div>
//   );
// };

// const FreeResources = () => {
//   return (
//     <div className="free-resources">
//       <h6>
//         FREE RESOURCES <span> (34)</span>
//       </h6>
//       <FreeResourceItem />
//       <FreeResourceItem />
//       <FreeResourceItem />
//       <FreeResourceItem />
//     </div>
//   );
// };
