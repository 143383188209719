import React from "react";
import AuthLayoutFooter from "./AuthLayoutFooter";
import AuthLayoutHeader from "./AuthLayoutHeader";
import classes from "./NewAuthLayout_v2.module.scss";

const NewAuthLayout_v2 = ({ children }) => {
  return (
    <div className={classes.container}>
      <AuthLayoutHeader />
      {children}
      <AuthLayoutFooter />
    </div>
  );
};

export default NewAuthLayout_v2;
