import React, { Fragment } from "react";
import classes from "./TEFButton.module.css";

const TEFButton = ({ logo, label, bgColor, textColor, rPad }) => {
  return (
    <Fragment>
      <div
        className={`${classes.Button} ${rPad}`}
        style={{ backgroundColor: `${bgColor}`, color: `${textColor}` }}
      >
        <div className={classes.Logo}>
          <img src={logo} alt={`${label} logo`} width="13px" height="13px" />
        </div>
        <div className={classes.Label}>{label}</div>
      </div>
    </Fragment>
  );
};

export default TEFButton;
