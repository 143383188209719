import React from "react";
import Layout from "src/components/Layout/Layout";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import Main from "./components/Main/Main";

import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const StudyGroup = (props) => {
  const user = get_user_info_from_token() && get_user_info_from_token().user;
  const trainingEventId = user && user.training ? user.training[1] : null;
  return (
    <Layout SideContent={<SidebarRight />}>
      {trainingEventId ? <Main /> : <p>No Studygroups to show here</p>}
    </Layout>
  );
};

export default ifOnboardedThenShow(StudyGroup);
