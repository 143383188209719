/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Layout from "src/components/Layout/Layout";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ListGroup,
  ListGroupItem,
  Spinner,
  Row,
  Col,
  Button,
} from "reactstrap";
import { CountryRegionData } from "react-country-region-selector";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import "./AlumniHub.scss";

import DefaultImage from "src/assets/img/bg/background_640-1.webp";
import HubBanner from "./component/HubBanner";
import EventCard from "./component/EventCard/index";

import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { Context } from "src/store";
import { getUserHub } from "src/requests/AlumniHubRequest";

import { getHubUsers } from "src/modules/Alumni/Chat/helper/getHubUsers";
import Loader from "react-loader-spinner";
import noEventImage from "./assets/no-event.svg";
import noUsersImage from "./assets/no-users.svg";
import noHubLeadImage from "./assets/hub-lead.svg";
import noGalleryImage from "./assets/no-gallery.svg";

import { getUserProfile } from "src/requests/AlumniChatRequest";
import { getHubEventCount } from "./containers/helper/getAlumniHubEventCount";
import { getHubEventsById } from "./containers/helper/getHubEventsById";
import { getAlumniHubGalleryById } from "./containers/helper/getAlumniHubGalleryById";
import { FaGlobe } from "react-icons/fa";
import { BiChat } from "react-icons/bi";
import Skeleton from "react-loading-skeleton";
import HubCard from "./component/hubs/index";
import moment from "moment";
// import { selectColor } from "./helper/selectColor";

const AlumniHub = () => {
  // const colorPicker = selectColor();
  const userData = get_user_info_from_token().user;
  const history = useHistory();
  const [currentHub, setCurrentHub] = useState({});
  const [otherUserHubs, setOtherUserHubs] = useState([]);
  const [userProfile, setUserProfile] = useState({});
  const [, setUserHubs] = useState({});
  const { dispatch } = useContext(Context);
  const [isOpen, setIsOpen] = useState(false);
  const [hubUsers, setHubUsers] = useState([]);
  const [isloadingHubUsers, setIsLoadingHubUsers] = useState(true);
  const [hubEventCount, setHubEventCount] = useState(null);
  const [hubEvents, setHubEvents] = useState([]);
  const [isLoadingHubEventCount, setIsLoadingHubEventCount] = useState(true);
  const [isLoadingHubEvent, setIsLoadingHubEvent] = useState(true);
  const [isLoadingHubPhotos, setIsLoadingHubPhotos] = useState(true);
  const [hubPhotos, setHubPhotos] = useState(true);
  const [isLoadingUserProfile, setIsLoadingUserProfile] = useState(false);

  const toggleUserProfile = () => setIsOpen((prev) => !prev);
  const openUserProfileModal = (id) => {
    setIsOpen(true);
    _getUserProfile(id);
  };
  const getCountryByCode = (code) => {
    const val = getCountries().find((item) => item.value === code);
    return val?.label;
  };
  function getCountries() {
    return CountryRegionData.map((item) => {
      return {
        label: item[0],
        value: item[1],
      };
    });
  }
  const _getUserProfile = (id) => {
    setIsLoadingUserProfile(true);

    getUserProfile(id)
      .then((res) => {
        const {
          data: { data },
        } = res;
        setIsLoadingUserProfile(false);
        setUserProfile(data);
      })
      .catch(() => {
        setIsLoadingUserProfile(false);
      });
  };

  const _getHubUsers = getHubUsers(
    dispatch,
    userData,
    setHubUsers,
    setIsLoadingHubUsers
  );

  const _getAlumniHubEventsCount = getHubEventCount(
    dispatch,
    setHubEventCount,
    setIsLoadingHubEventCount
  );

  const _getAllumniGalleryById = getAlumniHubGalleryById(
    dispatch,
    setHubPhotos,
    setIsLoadingHubPhotos
  );

  const _getAlumniHubEvents = getHubEventsById(
    dispatch,
    setHubEvents,
    setIsLoadingHubEvent
  );

  useEffect(() => {
    getUserHub(dispatch).then((response) => {
      const hubDetailsArray = response.data.data.data;
      console.log("arra", hubDetailsArray);

      let _currentHub;
      if (hubDetailsArray.length === 1) {
        _currentHub = hubDetailsArray[0];
      } else {
        _currentHub = hubDetailsArray.find((hub) => hub.isCurrent === true);
      }

      const _otherHubs = hubDetailsArray.filter(
        (hubs) => hubs.id !== _currentHub.id
      );

      if (_currentHub) {
        setOtherUserHubs(_otherHubs);
        setCurrentHub(_currentHub);
        _getHubUsers(_currentHub.id);
        _getAlumniHubEventsCount(_currentHub.id);
        _getAlumniHubEvents(_currentHub.id);
        _getAllumniGalleryById(_currentHub.id);
      }

      setUserHubs(response.data.data.data);
      setUserHubs(hubDetailsArray);
    });
  }, []);

  return (
    <Layout SideContent={<SidebarRight />}>
      <Row>
        <Col>
          <HubBanner
            hubBanner={{
              bannerUrl: currentHub.bannerUrl,
              state: currentHub.state,
              name: currentHub.name,
            }}
          />
        </Col>
      </Row>
      <Modal
        isOpen={isOpen}
        toggle={toggleUserProfile}
        className="modal-container"
        centered={true}
      >
        <ModalHeader toggle={toggleUserProfile}>
          {isLoadingUserProfile ? <></> : <>{userProfile.firstName} Profile</>}
        </ModalHeader>
        <ModalBody>
          {isLoadingUserProfile ? (
            <div style={{ marginLeft: "auto", width: "50%" }}>
              <Spinner />
            </div>
          ) : (
            <div style={{ height: "80vh", overflowY: "auto" }}>
              <div
                className="user-background"
                style={{
                  backgroundColor: "green",
                }}
              >
                {userProfile.imageUrl ? (
                  <img
                    src={`${userProfile?.imageUrl}`}
                    className="user-hub-img"
                  />
                ) : (
                  <div className="hub-user-no-img">
                    <span>{`${userProfile?.firstName?.charAt(
                      0
                    )}${userProfile?.lastName?.charAt(0)}`}</span>
                  </div>
                )}
              </div>

              <div className="user-full-detail mb-4">
                <ListGroup>
                  <ListGroupItem>
                    First Name: {userProfile?.firstName}
                  </ListGroupItem>
                  <ListGroupItem>
                    Last Name: {userProfile?.lastName}
                  </ListGroupItem>
                  <ListGroupItem>
                    Country: {getCountryByCode(userProfile?.country ?? "")}
                  </ListGroupItem>
                  <ListGroupItem>Gender: {userProfile?.gender}</ListGroupItem>
                  <ListGroupItem>Email: {userProfile?.email}</ListGroupItem>
                  <ListGroupItem>Phone: {userProfile?.phone}</ListGroupItem>
                  <ListGroupItem>City: {userProfile?.city}</ListGroupItem>
                  <ListGroupItem>
                    Business Name: {userProfile?.businessName}
                  </ListGroupItem>
                  <ListGroupItem>
                    Business Industry: {userProfile?.businessIndustry}
                  </ListGroupItem>
                  <ListGroupItem>
                    Business Description: {userProfile?.aboutBusiness}
                  </ListGroupItem>
                </ListGroup>
              </div>
              <Button
                className="float-right"
                onClick={() => history.push("/alumni/chat")}
              >
                Connect with {userProfile?.firstName}
              </Button>
            </div>
          )}
        </ModalBody>
      </Modal>

      <Row className="alumniHub__main-content">
        {/* hub lead */}
        <Col md={3} className="mt-4">
          <section className="alumniHub__profile">
            {currentHub && currentHub.hubLeadProfile !== null ? (
              <figure className="alumniHub__image-box">
                <img src={DefaultImage} className="alumniHub__user-photo" />
              </figure>
            ) : null}
            <div
              className={
                currentHub.hubLeadProfile !== null
                  ? "alumniHub__detail-box"
                  : "alumniHub__detail-box mt-2"
              }
            >
              {currentHub && currentHub.hubLeadProfile !== null ? (
                <>
                  <h5 className="user--name">{`${
                    currentHub.hubLeadProfile?.firstName ?? ""
                  } ${currentHub.hubLeadProfile?.lastName ?? ""}`}</h5>
                  <h6 className="user--position">
                    <strong>Hub Lead</strong>
                  </h6>
                  <h6 className="user--year">
                    Year:{" "}
                    {moment(currentHub.hubLeadProfile?.createdOn).format(
                      "YYYY"
                    )}
                  </h6>
                  <h6 className="user--year">
                    Contact: {currentHub.hubLeadProfile?.email}
                  </h6>
                </>
              ) : (
                <>
                  <img src={noHubLeadImage} alt="no gallery image" width="60" />
                  <span className="hub-name mt-2 mb-2">
                    No hub lead has been selected
                  </span>
                </>
              )}
              <div className="alumniHub__total">
                <div className="alumni-count alumniHub__total--first pr-2">
                  <h6 className="hub-figure">{hubUsers.length}</h6>
                  <Link
                    to={`/alumni/hubs/${currentHub.id}/users`}
                    className="hub-name"
                  >
                    Hub Members
                  </Link>
                </div>
                <div className=" alumni-count alumniHub__total--second pl-2">
                  {isLoadingHubEventCount ? (
                    <Loader type="Oval" color="red" height={20} width={20} />
                  ) : (
                    <>
                      <h6 className="hub-figure">{hubEventCount}</h6>
                      <Link
                        to={`/alumni/hubs/${currentHub.id}/events`}
                        className="hub-name"
                      >
                        Hub Events
                      </Link>
                    </>
                  )}
                </div>
              </div>
              {Object.keys(currentHub).length > 0 && (
                <>
                  <div className="d-flex align-items-center w-100 mt-4">
                    <p className="fs-14">
                      <span className="pr-2">
                        <BiChat />
                      </span>
                      Alumni Hub chat
                    </p>
                  </div>
                  <div className="d-flex align-items-center w-100 mb-3">
                    <Link
                      to={`/alumni/hubs/${currentHub.id}/other-hubs`}
                      className="fs-14"
                    >
                      <span className="pr-2">
                        <FaGlobe />
                      </span>
                      Explore other Hubs
                    </Link>
                  </div>
                </>
              )}
            </div>
          </section>

          {/* hub photos */}
          <section className="alumniHub__image-wrapper">
            <h6 className="hub-photo-title">Hub Photos</h6>
            <div className="alumniHub__imageCollection">
              {isLoadingHubPhotos
                ? [1, 2, 3, 4, 5, 6].map((image) => (
                    <Skeleton height={70} key={image} />
                  ))
                : hubPhotos.length > 0 &&
                  hubPhotos.map((photo) => (
                    <img
                      src={DefaultImage}
                      className="img-hub"
                      key={photo.id}
                    />
                  ))}
            </div>
            {!isLoadingHubPhotos && hubPhotos.length === 0 && (
              <div className="card border-0 rounded">
                <div className="card-body p-3 d-flex align-items-center flex-column w-100 justify-conent-center">
                  <img src={noGalleryImage} alt="no gallery image" width="60" />
                  <h6 className="mt-2 text-center">
                    No photo has been uploaded to this hub
                  </h6>
                </div>
              </div>
            )}
            {!isLoadingHubPhotos && hubPhotos.length > 0 && (
              <div className="text-center my-3">
                <Button
                  color="grey"
                  onClick={() =>
                    history.push(`/alumni/hubs/${currentHub.id}/gallery`)
                  }
                >
                  See All Photos
                </Button>
              </div>
            )}
          </section>
        </Col>

        <Col md={9}>
          <div className="alumnHub__main-welcome">
            <div className="alumniHub__welcome">
              <h5 className="alumniHub__welcome--title">
                Welcome to {currentHub.state} state AlumnHub.
              </h5>
              <p className="alumniHub__welcome--para">
                This is an amazing opportunity for you to connect and engage
                with TEF entrepreneurs within your community. We invite you to
                begin connecting with your appointed Hub Lead and fellow Alumni
                by joining events and your country/state’s chat group.
              </p>
            </div>

            {/* alumni members */}
            <div className="alumniHub__user--item">
              {isloadingHubUsers ? (
                <div className="d-flex justify-content-center pt-5 mt-3 text-center">
                  <Loader type="Oval" color="red" height={40} width={40} />
                </div>
              ) : (
                <>
                  <div className="alumni-box mb-2">
                    <h6 className="alumni-box__category alumniHub--list">
                      {currentHub.name} &nbsp;
                      <span className="tef-alumni-count">
                        ({currentHub.usersCount - 1})
                      </span>
                    </h6>
                    <Link
                      to={`/alumni/hubs/${currentHub.id}/users`}
                      className="alumni-box__more hub__more"
                    >
                      See All
                    </Link>
                  </div>
                  <div className="hub__user-items">
                    {hubUsers.length > 0 ? (
                      hubUsers.map((user) => (
                        <div
                          className="event-box user-item--list"
                          key={user.userId}
                          onClick={() => openUserProfileModal(user.userId)}
                        >
                          <div className="media media-cd">
                            {user.user.imageUrl ? (
                              <img
                                className="event-box__photo hub-photo-radius"
                                src={user.user.imageUrl}
                                alt="user"
                              />
                            ) : (
                              <div className="user-no-img">
                                <span>{`${user.user.firstName[0]}${user.user.lastName[0]}`}</span>
                              </div>
                            )}
                          </div>
                          <div className="event-box__detail-box">
                            <h6 className="event-box__title hub-box__title">
                              {`${user.user.firstName} ${user.user.lastName}`}
                            </h6>
                            <p className="event-box__time hub-box__time">
                              {moment(user.user.createdOn).format("YYYY")}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="d-flex align-items-center flex-column w-100 justify-conent-center my-3">
                        <img
                          src={noUsersImage}
                          width="70"
                          alt="no users image"
                        />
                        <h5 className="mt-2">
                          No users in this hub at the moment
                        </h5>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          {/* hub events */}
          <div className="alumniHub-event">
            {isLoadingHubEvent ? (
              <div className="d-flex justify-content-center pt-5 mt-1 text-center">
                <Loader type="Oval" color="red" height={40} width={40} />
              </div>
            ) : (
              <>
                <div className="alumni-box mx-2">
                  <h6 className="alumni-box__category alumniHub--list">
                    Upcoming Events
                  </h6>
                  <Link
                    to={`/alumni/hubs/${currentHub.id}/events`}
                    className="alumni-box__more"
                  >
                    See All
                  </Link>
                </div>
                <div className="hubEvent__upcoming-event">
                  {hubEvents.length > 0 ? (
                    hubEvents.map((eventDetails) => (
                      <EventCard
                        eventDetails={eventDetails}
                        key={eventDetails.id}
                      />
                    ))
                  ) : (
                    <div className="d-flex align-items-center flex-column w-100 justify-conent-center my-3">
                      <img src={noEventImage} width="70" alt="no event image" />
                      <h5 className="mt-2">
                        No event in this hub at the moment
                      </h5>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          {/* other user hubs */}
          <div className="row my-4">
            {otherUserHubs && otherUserHubs.length > 0 && (
              <div className="col-12 mb-3">
                <h5>Other Alumni Hubs</h5>
              </div>
            )}
            {otherUserHubs &&
              otherUserHubs.map((hub) => (
                <div className="col-md-4" key={hub.id}>
                  <HubCard hubDetails={hub} />
                </div>
              ))}
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default ifOnboardedThenShow(AlumniHub);
