import React, { Fragment, useState } from "react";
import styles from "./SideNavItem.module.scss";
import { Link } from "react-router-dom";
import ReactAvatar from "react-avatar";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

const SideNavItem = ({
  Icon,
  isActive,
  navName,
  linkTo, // should be set to null for link that has a dropdown
  externalLink,
  redirectInApp = false,
  dropDown,
  dropDownList,
  otherClassName,
}) => {
  const [dropDownOpen, setdropDownOpen] = useState(false);
  return (
    <Fragment>
      {externalLink ? (
        <Fragment>
          <a
            className={`${otherClassName} ${styles.SideNavItem} ${
              isActive ? styles.SideItemActive : styles.SideItemInActive
            } `}
            target="_blank"
            rel="noreferrer"
            href={linkTo}
            onClick={() => setdropDownOpen(!dropDownOpen)}
          >
            <li>
              {Icon ? (
                <Icon
                  size={20}
                  className={styles.SideNavItem__icon}
                  alt="icon"
                />
              ) : (
                <ReactAvatar
                  className={styles.SideNavItem__avatarIcon}
                  size={32}
                  name={navName}
                />
              )}
              <span className={styles.SideNavItem__link}>
                {navName}{" "}
                <span>
                  {dropDown && (
                    <span>
                      {dropDownOpen ? (
                        <IoMdArrowDropup />
                      ) : (
                        <IoMdArrowDropdown />
                      )}
                    </span>
                  )}
                </span>
              </span>
            </li>
          </a>
          {dropDownOpen && (
            <div>
              {dropDownList.map((item, index) => (
                <div key={index} className={`${styles.SideNavItem}  py-2 pl-5`}>
                  {redirectInApp ? (
                    <Link
                      rel="noreferrer"
                      to={item.link}
                      className="pb-0 ml-0 ml-md-3"
                    >
                      {item.name}
                    </Link>
                  ) : (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={item.link}
                      className="pb-0 ml-0 ml-md-3"
                    >
                      {item.name}
                    </a>
                  )}
                </div>
              ))}
            </div>
          )}
        </Fragment>
      ) : (
        <Link
          className={` ${otherClassName} ${styles.SideNavItem} ${
            isActive ? styles.SideItemActive : styles.SideItemInActive
          }`}
          to={linkTo}
        >
          <li>
            {Icon ? (
              <Icon
                size={20}
                className={styles.SideNavItem__icon}
                alt="icon"
                // color="#6E6E70"
              />
            ) : (
              <ReactAvatar
                className={styles.SideNavItem__avatarIcon}
                size={32}
                name={navName}
              />
            )}
            <span className={styles.SideNavItem__link}>{navName}</span>
          </li>
        </Link>
      )}
    </Fragment>
  );
};

export default SideNavItem;
