export function selectColor() {
  return (colorIndex) => {
    const colors = [
      { background: "#326367", color: "#50b1ba" },
      { background: "#ff9922", color: "#333333" },
      { background: "#7e76cc", color: "#dcdaf9" },
      { background: "#ff9922", color: "#333333" },
      { background: "#00a6bf", color: "#92eafa" },
      { background: "#59d5ac", color: "#47695e" },
    ];

    return colors[colorIndex];
  };
}
