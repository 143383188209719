import React from "react";
import "./index.scss";

const Letter = ({
  firstName,
  lastName,
  country,
  gender,
  passportNum,
  expiryDate,
  issueDate,
}) => {
  return (
    <div
      className="col-md-12 bg-white"
      style={{
        paddingTop: 20,
        paddingRight: 20,
        paddingLeft: 20,
        paddingBottom: 50,
      }}
    >
      <h6 className="text-right">
        <b>Date</b> <u className="ml-1">{new Date().toLocaleDateString()}</u>
      </h6>

      <div className="title-container">
        <h6 className="text-left title-container__greetings-text">
          Dear Sir/Madam,
        </h6>
      </div>

      <div className="title-container">
        <h5 className="text-center title-container__header-text">
          Letter for Visa Application
        </h5>
      </div>

      <div className="body-container">
        <p>
          The Tony Elumelu Foundation was founded in 2010 and remains committed
          to a brighter and more prosperous Africa. Our focus on
          entrepreneurship is rooted in our inclusive philosophy of
          Africapitalism which recognizes economically empowering Africa&apos;s
          youth our future wealth creators, as one of the most pressing issues
          of our time. The Foundation&apos;s initiatives do not only foster
          broad-based growth but contribute significantly to stability and
          security in the region and globally.
        </p>

        <p>
          The <b>2021</b> edition of the Tony Elumelu Foundation
          Entrepreneurship Forum will take place at the Transcorp Hilton Hotel
          Abuja, Nigeria from <b>5th March 2021</b>.
        </p>

        <p>
          This document confirms that{" "}
          <b>
            {lastName && firstName ? `${firstName} ${lastName}` : "[Your Name]"}
          </b>{" "}
          will be traveling to Abuja, Nigeria to participate in this seminar
          organized by our organization. <br />
          Passport and identification details are as below:
        </p>
        <br />
        <br />
        <p>
          <span>Last name:</span>{" "}
          <b style={{ textTransform: "capitalize" }}>
            {lastName || "[Insert Last Name]"}
          </b>
          <br />
          <span>First name:</span>{" "}
          <b style={{ textTransform: "capitalize" }}>
            {firstName || "[Insert First Name]"}
          </b>
          <br />
          <span>Gender:</span>{" "}
          <b style={{ textTransform: "capitalize" }}>
            {gender || "[Insert Gender]"}
          </b>
          <br />
          <span>Nationality:</span>{" "}
          <b style={{ textTransform: "capitalize" }}>
            {country || "[Insert Country]"}
          </b>
          <br />
          <span>Passport number:</span>{" "}
          <b style={{ textTransform: "capitalize" }}>
            {passportNum || "[Insert Passport Number]"}
          </b>
          <br />
          <span>Date of issue:</span>{" "}
          <b style={{ textTransform: "capitalize" }}>
            {issueDate || "[Insert Date of Issue]"}
          </b>
          <br />
          <span>Date of expiry:</span>{" "}
          <b style={{ textTransform: "capitalize" }}>
            {expiryDate || "[Insert Date of Expiry]"}
          </b>
        </p>

        <p>
          We kindly ask that you provide the necessary assistance in obtaining a
          visa. Please do not hesitate to contact us if additional information
          or clarification is required.
        </p>

        <br />
        <p>Your sincerely,</p>
        <p>
          Ifeyinwa Ugochukwu
          <br />
          CEO, The Tony Elumelu Foundation.
        </p>
      </div>
    </div>
  );
};
export default Letter;
