/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import {
  Input,
  Label,
  FormGroup,
  ModalBody,
  ModalFooter,
  Modal,
  Form,
  ModalHeader,
  Col,
  Row,
  Button,
  ButtonGroup,
} from "reactstrap";
// import ModalT from "src/components/Modal/Modal";
import { Context } from "src/store";
import {
  getPersonalGoals,
  updatePersonalGoal,
  deletePersonalGoal,
} from "src/requests/PersonalGaolRequest";
import classes from "./goalDetails.module.scss";
import moment from "moment";
import DatePicker from "react-datepicker";
import Loader from "react-loader-spinner";

const EditGoalModal = ({ modal, toggle, goal, onRecordUpdate }) => {
  const { id } = JSON.parse(localStorage.getItem("data")).user;
  const { dispatch } = useContext(Context);

  const [data, setData] = useState(goal);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });
  const handleDateChange = (date) => setData({ ...data, dueDate: date });

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      ...data,
      userId: id,
    };

    updatePersonalGoal(dispatch, goal.id, payload).then((res) => {
      setIsLoading(false);
      if (res.status === 200) {
        onRecordUpdate(payload);
        toggle();
      }
    });
  };

  const _onDeleteGoal = () => {
    setIsLoadingDelete(true);
    deletePersonalGoal(dispatch, goal.id).then(() => {
      onRecordUpdate(data);
      setIsLoadingDelete(false);
      toggle();
    });
  };

  return (
    // <ModalT
    //   title={"Edit Goal"}
    //   modal={modal}
    //   className={className}
    //   buttonLabel={buttonLabel}
    //   toggle={toggle}
    //   onSubmit={handleSubmit}
    //   loadingCreate={isLoading}
    //   buttonText="Update Goal"
    // >
    //   <FormGroup>
    //     <Label htmlFor="goalTitle">Title of your goal - Required</Label>
    //     <Input
    //       type="text"
    //       name="title"
    //       id="goalTitle"
    //       value={data.title}
    //       onChange={handleChange}
    //       placeholder="A short title of what you want to achieve"
    //       required
    //     />
    //   </FormGroup>

    //   <FormGroup>
    //     <Label htmlFor="goalDescription">Description - Optional</Label>
    //     <Input
    //       type="textarea"
    //       name="description"
    //       id="goalDescription"
    //       value={data.description}
    //       onChange={handleChange}
    //       placeholder="Give a brief overview of what you are trying to achieve "
    //       rows={5}
    //     />
    //   </FormGroup>
    //   <FormGroup>
    //     <Label htmlFor="goalDate">Due Date - Required</Label>
    //     <DatePicker
    //       placeholderText="YYYY-MM-DD"
    //       dateFormat="yyyy-MM-dd"
    //       id="goalDate"
    //       minDate={new Date()}
    //       selected={Date.parse(data.dueDate)}
    //       onChange={handleDateChange}
    //       required={true}
    //     />
    //   </FormGroup>
    // </ModalT>
    <>
      <Modal isOpen={modal} toggle={toggle} centered size="lg">
        <ModalHeader toggle={toggle}>
          <Button
            className="float-right"
            outline
            disabled={isLoadingDelete || isLoading}
            onClick={_onDeleteGoal}
          >
            Delete Goal
            {isLoadingDelete && (
              <span
                style={{
                  float: "right",
                  marginTop: "5px",
                  marginLeft: "5px",
                }}
              >
                <Loader type="Oval" color="white" height={15} width={15} />
              </span>
            )}
          </Button>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label>Title</Label>
              <Input
                className={`${classes.transInput}`}
                type="text"
                value={data.title}
                required
                onChange={handleChange}
                name="title"
              />
            </FormGroup>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Created on</Label>
                  <Input
                    className={`${classes.transInput}`}
                    type="text"
                    value={moment(data.createdOn).format("YYYY-MM-DD")}
                    // required
                    disabled
                  />
                </FormGroup>
              </Col>

              <Col md={6} className="d-flex">
                <FormGroup>
                  <Label>Due date</Label>
                  <DatePicker
                    placeholderText="YYYY-MM-DD"
                    dateFormat="yyyy-MM-dd"
                    id="goalDate"
                    minDate={new Date()}
                    selected={Date.parse(data.dueDate)}
                    onChange={handleDateChange}
                    required={true}
                    className={classes.dueCalender}
                  />
                  <div className="due">{moment(data.dueDate).fromNow()}</div>
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label>Description</Label>
              <Input
                type="textarea"
                className={`${classes.transInput}`}
                value={data.description}
                required
                onChange={handleChange}
                name="description"
              />
            </FormGroup>
            <div className="float-right">
              <ButtonGroup>
                <Button
                  outline
                  onClick={toggle}
                  disabled={isLoadingDelete || isLoading}
                >
                  {" "}
                  Cancel
                </Button>
                <Button type="submit" disabled={isLoadingDelete || isLoading}>
                  Update
                  {isLoading && (
                    <span
                      style={{
                        float: "right",
                        marginTop: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      <Loader
                        type="Oval"
                        color="white"
                        height={15}
                        width={15}
                      />
                    </span>
                  )}
                </Button>
              </ButtonGroup>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditGoalModal;
