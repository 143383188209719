import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Card } from "reactstrap";
import "./Photos.scss";
import AddPhoto from "./addPhoto";
import { useParams } from "react-router-dom";
import { FaTrashAlt } from "react-icons/fa";
import { useCurrentUser } from "src/utils/Services/currentUser";
import ViewPhoto from "./ViewPhoto";
import {
  AiOutlineCloudDownload,
  AiOutlineHeart,
  AiTwotoneHeart,
} from "react-icons/ai";
import { Context } from "src/store";
import {
  deletePhoto,
  likeOrUnlikeUserPhoto,
  getUserPhotos,
} from "src/requests/ProfileRequest";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import { env } from "src/constants/env";

const Photos = (props) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const { dispatch } = useContext(Context);
  const [loading, setLoading] = useState(false);

  const [images, setImages] = useState([]);

  const [preview, setPreview] = useState(null);
  const { id } = useParams();

  const user = useCurrentUser();
  // const history = useHistory();
  // const location = useLocation();

  useEffect(() => {
    setLoading(true);
    getUserPhotos(id).then((res) => {
      const { status, data } = res;
      if (status === 200 || status === 201) {
        console.log(data.data);
        setImages(data.data);
      }
      setLoading(false);
    });
  }, []);

  // useEffect(() => {
  //   if (id === user.userId) {
  //     setImages(user.galleries);
  //   }
  // }, [id, user]);

  // useEffect(() => {
  //   if (state.Profile.profile) {
  //     setImages(state.Profile.profile.galleries);
  //   }
  // }, [state.Profile.profile]);

  const downloadImage = (url) => {
    fetch(`https://cors-proxie.herokuapp.com/${url}`, {
      method: "GET",
      headers: {
        ClientId: env.REACT_APP_CLIENT_ID,
        ClientSecret: env.REACT_APP_CLIENT_SECRET,
      },
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); // or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const callback = (filter) => {
    const existingImages = [...images];
    const filtered = existingImages.filter((img) => img.id !== filter);
    setImages(filtered);
  };

  const deleteUserPhoto = (id) => {
    Swal.fire({
      title: "Hello, Are you sure?",
      text: "You will not be able to recover this",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.value) {
        deletePhoto(dispatch, id, callback).then((res) => {
          res.status === 200
            ? Swal.fire({
                title: "Deleted!",
                text: "Successfully deleted",
                icon: "success",
                confirmButtonColor: "red",
              })
            : Swal.fire({
                title: "Cancelled",
                text: "Could not delete, please try again",
                icon: "error",
                confirmButtonColor: "red",
              });
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Cancelled",
          text: "Delete cancelled",
          icon: "error",
          confirmButtonColor: "red",
        });
      }
    });
  };

  const like = (imageId, isLiked) => {
    const isLoggedInUser = id === user.userId;
    likeOrUnlikeUserPhoto(dispatch, imageId, isLoggedInUser);
    const existingImages = [...images];
    const index = existingImages.findIndex((img) => img.id === imageId);

    const likeCount = existingImages[index].like;
    const likers = existingImages[index].galleryLikes;
    if (!isLiked) {
      existingImages[index].galleryLikes.unshift(user.userId);
      existingImages[index].like = likeCount + 1;
    } else {
      existingImages[index].galleryLikes = likers.filter(
        (liker) => liker !== user.userId
      );
      existingImages[index].like = likeCount - 1;
    }
    setImages(existingImages);
  };

  // const like = (imageId, isLiked) => {
  //   const isLoggedInUser = id === user.userId;
  //   likeOrUnlikeUserPhoto(dispatch, imageId, isLoggedInUser);
  //   const existingImages = [...images];
  //   const index = existingImages.findIndex((img) => img.id === imageId);

  //   const likeCount = existingImages[index].like;
  //   const likers = existingImages[index].galleryLikes;
  //   if (!isLiked) {
  //     existingImages[index].galleryLikes.unshift({ likeId: user.userId });
  //     existingImages[index].like = likeCount + 1;
  //   } else {
  //     existingImages[index].galleryLikes = likers.filter(
  //       (liker) => liker.likeId !== user.userId
  //     );
  //     existingImages[index].like = likeCount - 1;
  //   }
  //   setImages(existingImages);
  // };

  const ImageItem = ({ url, likeCount, imageId, isLiked }) => {
    return (
      <Col className="photo__pic" md={4}>
        <img
          src={url}
          alt="user-photos"
          style={{ width: "100%" }}
          onClick={() => setPreview(url)}
        />

        <div className="faded-option">
          <div className="photo-option">
            <div className="photo-texts">
              <p>{likeCount} like(s)</p>
              {/* {user.userId === id && (
                <p
                  onClick={() => {
                    history.push({
                      pathname: location.pathname,
                      search: `?active=Timeline&shareInPost=${true}&images=${JSON.stringify(
                        [url]
                      )}`,
                    });
                  }}
                >
                  <span>&#x2022;</span> share
                </p>
              )} */}
            </div>
            <div
              className="photo-delete-option"
              style={{ marginRight: "5px" }}
              onClick={() => like(imageId, isLiked)}
            >
              {isLiked ? (
                <AiTwotoneHeart size={15} />
              ) : (
                <AiOutlineHeart size={15} />
              )}
            </div>
            <div
              className="photo-delete-option"
              style={{ marginRight: "5px" }}
              onClick={() => downloadImage(url)}
            >
              <AiOutlineCloudDownload size={15} />
            </div>
            {user.userId === id && (
              <div
                className="photo-delete-option"
                onClick={() => deleteUserPhoto(imageId)}
              >
                <FaTrashAlt size={15} />
              </div>
            )}
          </div>
        </div>
      </Col>
    );
  };

  const toggleAddPhoto = () => setIsAddModalOpen(!isAddModalOpen);
  return (
    <div>
      <Card className="photo">
        <Col>
          <Row>
            <Col md={8}>
              <h1 className="photo__h1">Photos</h1>
            </Col>
            <Col md={4}>
              {user.userId === id && (
                <span onClick={toggleAddPhoto} className="add-photo-btn-1">
                  {/* Sort by: <span>Newest Post</span> */}+ Add Photo
                </span>
              )}
            </Col>
          </Row>
        </Col>
      </Card>
      <br />
      <Col>
        {!loading && (
          <Row>
            {images && images.length > 0 ? (
              images.map((img, i) => {
                return (
                  <ImageItem
                    url={img.imageUrl}
                    imageId={img.id}
                    key={i}
                    likeCount={img.like}
                    isLiked={
                      img.galleryLikes &&
                      img.galleryLikes.findIndex(
                        (liker) => liker === user.userId
                      ) >= 0
                    }
                  />
                );
              })
            ) : (
              <div className="empty-image-state"> Nothing to show</div>
            )}
          </Row>
        )}
        {loading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader type="ThreeDots" height={50} width={50} color="red" />
          </div>
        )}
      </Col>
      <br />

      <AddPhoto
        isOpen={isAddModalOpen}
        toggle={toggleAddPhoto}
        photos={images}
        setImages={setImages}
      />
      <ViewPhoto
        isOpen={preview !== null}
        onClose={() => setPreview(null)}
        photos={[
          {
            src: preview,
            w: 900,
            h: 900,
            title: "",
          },
        ]}
      />
    </div>
  );
};

Photos.propTypes = {};

export default Photos;
