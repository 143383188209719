export function validateSignup(values) {
  const errors = {};
  if (!values.firstName || values.firstName.trim() === "") {
    errors.firstName = "first name is required";
  } else if (
    !/^([a-zA-Z',.-]+( [a-zA-Z',.-]+)*){2,30}$/.test(values.firstName.trim())
  ) {
    errors.firstName = "can only contain an (a-z) or ('.-)";
  } else if (values.firstName.trim().length > 30) {
    errors.firstName = "not more than 30 characters";
  }

  if (!values.lastName || values.lastName.trim() === "") {
    errors.lastName = "last name is required";
  } else if (
    !/^([a-zA-Z',.-]+( [a-zA-Z',.-]+)*){2,30}$/.test(values.lastName.trim())
  ) {
    errors.lastName = "can only contain an (a-z) or ('.-)";
  } else if (values.lastName.trim().length > 30) {
    errors.lastName = "not more than 30 characters";
  }

  if (!values.email || values.email.trim() === "") {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password || values.password.trim() === "") {
    errors.password = "password is required";
  } else if (
    !/^(?=.*[@$!#_%.*?&-])(?=.*[a-z])(?=.*[A-Z])(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%_*#.?&-]{8,}$/.test(
      values.password
    )
  ) {
    errors.password =
      "password must be at least 8 characters and contain an upper case (A-Z), lower case (a-z), number (0-9) and a special character (e.g. !@#$%^&*)";
  }

  // if (!values.confirmPassword || values.confirmPassword.trim() === "") {
  //   errors.confirmPassword = "confirm password is required";
  // } else if (values.password !== values.confirmPassword) {
  //   errors.confirmPassword = "passwords don't match";
  //   errors.password = "passwords don't match";
  // }

  return errors;
}

export function validateSignin(values) {
  const errors = {};

  if (!values.email || values.email.trim() === "") {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password || values.password.trim() === "") {
    errors.password = "password is required";
  } else if (values.password.length < 8) {
    errors.password = "password must be at least 8 characters";
  }

  return errors;
}

export function validateEmail(values) {
  const errors = {};

  if (!values.email || values.email.trim() === "") {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  return errors;
}

export function validatePasswordResetFields(values) {
  const errors = {};

  if (!values.password || values.password.trim() === "") {
    errors.password = "password is required";
  } else if (
    !/^(?=.*[@$!#_%.*?&-])(?=.*[a-z])(?=.*[A-Z])(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%_.*#?&-]{8,}$/.test(
      values.password
    )
  ) {
    errors.password =
      "password must be at least 8 characters and contain an upper case (A-Z), lower case (a-z), number (0-9) and a special character (e.g. !@#$%^&*)";
  }
  if (!values.confirmPassword || values.confirmPassword.trim() === "") {
    errors.confirmPassword = "confirm password is required";
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "passwords don't match";
    errors.password = "passwords don't match";
  }
  return errors;
}

export function validateChangePasswordFields(values) {
  const errors = {};

  if (!values.oldPassword || values.oldPassword.trim() === "") {
    errors.oldPassword = "Old password is required";
  } else if (!values.newPassword || values.newPassword.trim() === "") {
    errors.newPassword = "New password is required";
  } else if (
    !/^(?=.*[@$!#_%.*?&-])(?=.*[a-z])(?=.*[A-Z])(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%_*.#?&-]{8,}$/.test(
      values.newPassword
    )
  ) {
    errors.newPassword =
      "password must be at least 8 characters and contain an upper case (A-Z), lower case (a-z), number (0-9) and a special character (e.g. !@#$%^&*)";
  }
  if (!values.confirmPassword || values.confirmPassword.trim() === "") {
    errors.confirmPassword = "confirm password is required";
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "passwords don't match";
    errors.newPassword = "passwords don't match";
  }
  return errors;
}

export function validateFeedbackFields(values) {
  const errors = {};
  if (!values.firstName || values.firstName.trim() === "") {
    errors.firstName = "first name is required";
  } else if (
    !/^([a-zA-Z',.-]+( [a-zA-Z',.-]+)*){2,30}$/.test(values.firstName.trim())
  ) {
    errors.firstName = "can only contain an (a-z) or ('.-)";
  } else if (values.firstName.trim().length > 30) {
    errors.firstName = "not more than 30 characters";
  }

  if (!values.lastName || values.lastName.trim() === "") {
    errors.lastName = "last name is required";
  } else if (
    !/^([a-zA-Z',.-]+( [a-zA-Z',.-]+)*){2,30}$/.test(values.lastName.trim())
  ) {
    errors.lastName = "can only contain an (a-z) or ('.-)";
  } else if (values.lastName.trim().length > 30) {
    errors.lastName = "not more than 30 characters";
  }

  if (!values.email || values.email.trim() === "") {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  return errors;
}
