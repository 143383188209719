import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";

const useGeoCode = (address) => {
  const [coords, setCoords] = useState(null);
  const getLocation = async () => {
    try {
      const { status, data } = await axios({
        url: "https://maps.googleapis.com/maps/api/geocode/json",
        method: "get",
        params: {
          address,
          key: `${process.env.REACT_APP_GOOGLE_API}`,
        },
      });

      if (status === 200) {
        const coords = data.results[0].geometry.location;
        setCoords(coords);
      }
    } catch (error) {
      console.log("the error", error);
      setCoords(null);
    }
  };

  useMemo(async () => await getLocation(), [address]);

  return {
    coords,
  };
};

useGeoCode.propTypes = {
  address: PropTypes.string.isRequired,
};

export default useGeoCode;
