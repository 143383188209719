import React from "react";
import Skeleton from "react-loading-skeleton";

const AlumniListSkeletalLoader = () => {
  return (
    <div className="d-flex my-3 align-items-center">
      <div className="w-25 mr-2">
        <Skeleton circle={true} height={50} width={50} />
      </div>
      <div className="w-75">
        <div className="mb-0 pb-0 w-100">
          <Skeleton width={"100%"} height={12} />
        </div>
        <div className="mb-0 pb-1 w-100">
          <Skeleton width={"80%"} height={10} />
        </div>
      </div>
    </div>
  );
};

export default AlumniListSkeletalLoader;
