import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

export const initSentry = () => {
  Sentry.init({
    dsn:
      "https://06a0f2d688464a97a78c95c6fe548b47@o1140410.ingest.sentry.io/6197586",
    integrations: [new BrowserTracing()],
    release: process.env.REACT_APP_BUILD_VERSION,
    environment: process.env.NODE_ENV,

    // Capture 1% of transactions for performance monitoring.
    tracesSampleRate: 0.001,

    // Ignore errors from Google Translate. They are the most numerous.
    // Most of them contain "closure_" in description and translate_http in call stack.
    // I am not sure `ignoreUrls` will work.
    ignoreErrors: [
      // From Google Translate.
      "closure_",
      // See TP-2080: Probably also caused by Google Translate when it replaces texts by <font> tags.
      "Failed to execute 'insertBefore' on 'Node'",
      "Failed to execute 'removeChild' on 'Node'",
    ],
    ignoreUrls: [/translate_http/],
  });
};
