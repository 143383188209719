import React from "react";
import "./Layout.scss";
import SideBar from "src/modules/Application/components/SideBar/SideBar";
import OffcycleSidebar from "src/modules/Application/components/OffcycleSideBar/OffcycleSideBar";
import { RightSideRar } from "src/modules/Application/components/RightSideBar/RightSideRar";
import GeneralLayout from "src/components/GeneralLayout/GeneralLayout";
import { useRouteMatch } from "react-router-dom";

const Layout = ({ children }) => {
  const match = useRouteMatch({
    path: "/application/offcycle/",
    strict: true,
    sensitive: true,
  });

  return (
    <GeneralLayout
      sidebar={match ? <OffcycleSidebar /> : <SideBar />}
      rightSideRar={<RightSideRar />}
    >
      {children}
    </GeneralLayout>
  );
};

export default Layout;
