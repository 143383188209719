/* eslint-disable no-unused-vars */
import { asyncActionName } from "src/utils/AsyncUtils";
import { initialState } from "src/store";
import {
  GET_ALL_CATEGORIES,
  GET_CATEGORY_GROUPS,
  CREATE_GROUP,
  GET_ALL_GROUPS,
  GET_GROUP,
  JOIN_GROUP,
  LEAVE_GROUP,
  IS_GROUP_MEMBER,
  GET_GROUP_POSTS,
  DELETE_GROUP,
  GET_GROUP_USERS,
  GROUP_INVITE,
  SEARCH_MEMBER,
  GET_USER_GROUPS,
  SEARCH_GROUPS,
  REMOVE_USER,
  FLAG_A_TOPIC,
  GET_CONNECTIONS_GROUPS,
  UPDATE_GROUP,
  GET_GROUP_INVITATION_RECEIVED,
  ACCEPT_GROUP_INVITATION_RECEIVED,
  REJECT_GROUP_INVITATION_RECEIVED,
  ACCEPT_ALL_GROUP_INVITATION_RECEIVED,
} from "src/actions";

const CommunityReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case asyncActionName(GET_ALL_CATEGORIES).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_ALL_CATEGORIES).success:
      return {
        ...state,
        categories: payload,
        success: true,
      };
    case asyncActionName(GET_ALL_CATEGORIES).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(CREATE_GROUP).loading:
      return { ...state, loading: payload };
    case asyncActionName(CREATE_GROUP).success:
      return {
        ...state,
        group: payload,
        success: true,
      };
    case asyncActionName(CREATE_GROUP).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_ALL_GROUPS).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_ALL_GROUPS).success:
      return {
        ...state,
        groups: payload.data,
        groupDetails: payload.pager,
        success: true,
      };
    case asyncActionName(GET_ALL_GROUPS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(JOIN_GROUP).loading:
      return { ...state, loading: payload };
    case asyncActionName(JOIN_GROUP).success:
      return {
        ...state,
        joinGroup: payload,
        leaveGroup: false,
        success: true,
      };
    case asyncActionName(JOIN_GROUP).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(LEAVE_GROUP).loading:
      return { ...state, loading: payload };
    case asyncActionName(LEAVE_GROUP).success:
      return {
        ...state,
        leaveGroup: payload,
        joinGroup: false,
        success: true,
      };
    case asyncActionName(LEAVE_GROUP).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(IS_GROUP_MEMBER).loading:
      return { ...state, loading: payload };
    case asyncActionName(IS_GROUP_MEMBER).success:
      return {
        ...state,
        isGroupMember: payload,
        success: true,
      };
    case asyncActionName(IS_GROUP_MEMBER).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_GROUP).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_GROUP).success:
      return {
        ...state,
        group: payload,
        success: true,
      };
    case asyncActionName(GET_GROUP).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_GROUP_POSTS).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_GROUP_POSTS).success:
      return {
        ...state,
        groupPosts: payload.data,
        success: true,
      };
    case asyncActionName(GET_GROUP_POSTS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_CATEGORY_GROUPS).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_CATEGORY_GROUPS).success:
      return {
        ...state,
        groups: payload.data,
        groupDetails: payload.pager,
        success: true,
      };
    case asyncActionName(GET_CATEGORY_GROUPS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(DELETE_GROUP).loading:
      return { ...state, loading: payload };
    case asyncActionName(DELETE_GROUP).success:
      return {
        ...state,
        group: payload,
        success: true,
      };
    case asyncActionName(DELETE_GROUP).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_GROUP_USERS).loading:
      return { ...state, groupUsersLoading: payload };
    case asyncActionName(GET_GROUP_USERS).success:
      return {
        ...state,
        groupUsers: payload,
        groupUsersSuccess: true,
        groupUsersLoading: false,
      };
    case asyncActionName(GET_GROUP_USERS).failure:
      return {
        ...state,
        error: payload.status,
        groupUsersSuccess: false,
        groupUsersLoading: false,
      };
    case asyncActionName(SEARCH_MEMBER).loading:
      return { ...state, membersLoading: payload };
    case asyncActionName(SEARCH_MEMBER).success:
      return {
        ...state,
        members: payload,
        membersSuccess: true,
      };
    case asyncActionName(SEARCH_MEMBER).failure:
      return {
        ...state,
        membersError: payload.status,
        membersSuccess: false,
      };
    case asyncActionName(GROUP_INVITE).loading:
      return { ...state, inviteLoading: payload };
    case asyncActionName(GROUP_INVITE).success:
      return {
        ...state,
        invite: payload,
        inviteSuccess: true,
      };
    case asyncActionName(GROUP_INVITE).failure:
      return {
        ...state,
        inviteError: payload.status,
        inviteSuccess: false,
      };
    case asyncActionName(GET_USER_GROUPS).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_USER_GROUPS).success:
      return {
        ...state,
        userGroups: payload,
        success: true,
      };
    case asyncActionName(GET_USER_GROUPS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(SEARCH_GROUPS).loading:
      return { ...state, loading: payload };
    case asyncActionName(SEARCH_GROUPS).success:
      return {
        ...state,
        groupSearchResults: payload,
        success: true,
      };
    case asyncActionName(SEARCH_GROUPS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(REMOVE_USER).loading:
      return { ...state, loading: payload };
    case asyncActionName(REMOVE_USER).success:
      return {
        ...state,
        removeUser: true,
        success: true,
      };
    case asyncActionName(REMOVE_USER).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(FLAG_A_TOPIC).loading:
      return { ...state, loading: payload };
    case asyncActionName(FLAG_A_TOPIC).success:
      return {
        ...state,
        success: true,
      };
    case asyncActionName(FLAG_A_TOPIC).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_CONNECTIONS_GROUPS).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_CONNECTIONS_GROUPS).success:
      return {
        ...state,
        connectionGroups: payload.data,
        connectionGroupDetails: payload.pager,
        success: true,
      };
    case asyncActionName(GET_CONNECTIONS_GROUPS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(UPDATE_GROUP).loading:
      return { ...state, loading: payload };
    case asyncActionName(UPDATE_GROUP).success:
      return {
        ...state,
        group: payload,
        success: true,
      };
    case asyncActionName(UPDATE_GROUP).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_GROUP_INVITATION_RECEIVED).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_GROUP_INVITATION_RECEIVED).success:
      return {
        ...state,
        groupInvites: payload,
        success: true,
      };
    case asyncActionName(GET_GROUP_INVITATION_RECEIVED).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(ACCEPT_GROUP_INVITATION_RECEIVED).loading:
      return { ...state, loading: payload };
    case asyncActionName(ACCEPT_GROUP_INVITATION_RECEIVED).success:
      return {
        ...state,
        acceptGroupInvites: payload,
        success: true,
      };
    case asyncActionName(ACCEPT_GROUP_INVITATION_RECEIVED).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(REJECT_GROUP_INVITATION_RECEIVED).loading:
      return { ...state, loading: payload };
    case asyncActionName(REJECT_GROUP_INVITATION_RECEIVED).success:
      return {
        ...state,
        rejectGroupInvites: payload,
        success: true,
      };
    case asyncActionName(REJECT_GROUP_INVITATION_RECEIVED).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(ACCEPT_ALL_GROUP_INVITATION_RECEIVED).loading:
      return { ...state, loading: payload };
    case asyncActionName(ACCEPT_ALL_GROUP_INVITATION_RECEIVED).success:
      return {
        ...state,
        rejectGroupInvites: payload,
        success: true,
      };
    case asyncActionName(ACCEPT_ALL_GROUP_INVITATION_RECEIVED).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    default:
      return state;
  }
};

export default CommunityReducer;
