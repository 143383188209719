import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import TabHeader from "src/modules/Alumni/Components/TabHeader/index";
import MainTab from "./components/MainTab";
import OurPick from "./components/OurPick";
import CardContentLoader from "src/modules/Alumni/Components/CardContentLoader/index";

import "./index.scss";

const News = ({ onLoadData, resources, isLoading, ErrorEmpPage, onOpen }) => {
  const [tab, setTab] = useState("ALL");
  useEffect(() => {
    const searchBy = tab !== "ALL" ? tab.toLowerCase() : "";
    onLoadData(
      {
        SearchBy: searchBy,
        Category: "main",
      },
      "SearchBy"
    );
  }, [tab]);

  return (
    <div className="news_page_body container">
      <Row>
        <Col md={8} className="pl-2 pr-2">
          <div className="news-nav-header">
            <TabHeader
              onChange={setTab}
              currentTab={tab}
              tabStyle={{ backgroundColor: "transparent" }}
              tabs={["ALL", "OPINIONS", "BUSINESS", "WORLD"]}
              preTabSize={4}
              tabSize={8}
              preTabContent={
                <h4 className="news-header-title">LATEST ARTICLES</h4>
              }
            />
          </div>

          {isLoading ? (
            <Row className="mb-2 mt-0 pt-0 pb-0">
              {[1, 2].map((i) => (
                <Col md={6} lg={6} sm={6} xs={7} key={i}>
                  <CardContentLoader />
                </Col>
              ))}
            </Row>
          ) : resources.main.length > 0 ? (
            <MainTab resources={resources.main} onClick={onOpen} />
          ) : (
            <div className="mt-2">
              <ErrorEmpPage />
            </div>
          )}
        </Col>
        <Col md={4} className="pl-2 pr-2">
          <div>
            {resources.main.length > 0 && (
              <OurPick
                onLoadData={onLoadData}
                resources={resources.ourPicks}
                isLoading={isLoading}
                onClick={onOpen}
                ErrorEmpPage={ErrorEmpPage}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default News;
