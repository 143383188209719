import React from "react";
import { useHistory } from "react-router-dom";
import EmptyImage from "src/assets/img/emptyFolder.svg";

const Empty = ({ message, linkText, linkTo, goToNext }) => {
  const history = useHistory();
  const redirectTo = () => {
    linkTo ? history.push(linkTo) : goToNext();
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <img src={EmptyImage} alt="Error" width="100px" />
      <h6>{message}</h6>
      {linkText && (
        <button
          style={{
            marginTop: "10px",
            padding: "10px",
            borderRadius: " 4px",
            backgroundColor: " var(--tef-red)",
            fontFamily: " Poppins",
            fontSize: " 16px",
            fontWeight: " 500",
            fontStretch: " normal",
            fontStyle: " normal",
            lineHeight: " normal",
            letterSpacing: " 0.23px",
            color: " var(--white)",
            display: " flex",
            justifyContent: " center",
            alignItems: " center",
            border: " 0",
            textTransform: "capitalize",
          }}
          onClick={redirectTo}
        >
          {linkText}
        </button>
      )}
    </div>
  );
};

export default Empty;
