import React, { useEffect, useContext, useState } from "react";
import classes from "./MentorshipTC.module.css";
import { useHistory, useLocation } from "react-router-dom";
import OffcycleTC from "./OffcycleTC";
import { Context } from "src/store";

import { getProgrammeDetails, agreeToTerms } from "src/requests/MentorRequests";
import FlagshipTC from "./FlagshipTC";
import { Spinner } from "reactstrap";
import { setCurrentUser, useCurrentUser } from "src/utils/Services/currentUser";
import Loader from "react-loader-spinner";

const AgreementStatus = {
  HAS_AGREED: true,
  HAS_DISAGREED: false,
};

const MentorshipTC = () => {
  const { dispatch } = useContext(Context);
  const [programmeType, setProgrammeType] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingProgramme, setLoadingProgramme] = useState(false);
  const currentUser = useCurrentUser();

  useEffect(() => {
    _getProgrammeType();
  }, []);

  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const programmeId = useQuery().get("p");
  const _getProgrammeType = () => {
    setLoadingProgramme(true);
    getProgrammeDetails(dispatch, programmeId).then((res) => {
      if (res && res.status === 200) {
        const { programmeType } = res.data.data;
        setProgrammeType(programmeType);
        setLoadingProgramme(false);
      }
    });
  };

  const handleAgreeTerms = () => {
    setLoading(true);
    agreeToTerms(dispatch).then((res) => {
      if ((res && res.status === 200) || (res && res.status === 201)) {
        const copyUser = { ...currentUser };
        copyUser.hasAcceptedMentorshipTerms = AgreementStatus.HAS_AGREED;
        setCurrentUser(copyUser);
        setLoading(false);

        history.push("/mentorship");
      }
    });
  };

  const handleDisagreeTerms = () => {
    history.goBack();
  };

  return (
    <div className={classes.MentorshipTCContainer}>
      <div className={classes.MentorshipTC}>
        <div className={classes.Top}>
          <div className={classes.AgreeButton} onClick={handleAgreeTerms}>
            {loading ? <Spinner /> : "Agree"}
          </div>
          <div className={classes.DisagreeButton} onClick={handleDisagreeTerms}>
            Disagree
          </div>
        </div>
        <hr />
        {!loadingProgramme && (
          <div className={classes.Body}>
            {programmeType && programmeType !== "offcycle" ? (
              <FlagshipTC />
            ) : (
              <OffcycleTC />
            )}
          </div>
        )}
        {loadingProgramme && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "inherit" }}
          >
            <Loader height={30} width={30} type="Oval" color="var(--tef-red)" />
          </div>
        )}
      </div>
    </div>
  );
};

export default MentorshipTC;
