import React from "react";
import classes from "./ToolPoster.module.css";
import Avatar from "src/components/Avatar/Avatar";
import moment from "moment";

const ToolPoster = ({ poster, datePoster }) => {
  return (
    <div className={classes.ToolPoster}>
      <div className={classes.Posted}>Posted by:</div>
      <div className={classes.PosterImage}>
        <Avatar
          src={poster && poster.imageUrl}
          dimension="1.5rem"
          name={`${(poster && poster.firstName) || "System"} ${
            (poster && poster.lastName) || "Admin"
          }`}
        />
      </div>
      <div className={`${classes.PosterName} notranslate`}>{`${
        poster ? poster.firstName : "System"
      } ${poster ? poster.lastName : "Admin"}`}</div>{" "}
      <span className="mr-3">|</span>
      <div className={classes.Posted}>Date Posted:</div>
      <div className={classes.Duration}>
        {" "}
        {moment(datePoster).format("MMM YYYY")}
      </div>
    </div>
  );
};

export default ToolPoster;
