import React, { Fragment } from "react";
import { Input, Row, Col, Label, FormGroup } from "reactstrap";
import { FaRegFileImage, FaBook } from "react-icons/fa";
// import { SelectBox } from "src/modules/Application/Offcycle/personalInfo/components";
import { Select } from "src/components/CustomFormField/CustomFormField";
// import { industryTypes } from "src/modules/onboarding/stepTwo/industryType.js";
// import { useTranslation } from "react-i18next";
// import { CustomCountryDropdown } from "src/components/CustomCountryRegionDropdown";
import { numbers } from "src/modules/onboarding/tefMentorOnboarding/static";
// import Cookies from "js-cookie";
import ImageUploader from "react-images-upload";
import CountryRegionData from "src/utils/countryList.json";
import CreatableMulti from "src/components/MultiSelect/CreatableSelect";
import "./TefStories.scss";

const countries = CountryRegionData.map((cont, idx) => {
  return {
    label: cont.countryName,
    value: idx + 1,
  };
});

const CreateTefStoryForm = ({
  toggle,
  inputHandler,
  selectHandler,
  industryObj,
  tagsOption,
  handleChange,
  onDrop,
  storyData,
  yearError,
}) => {
  return (
    <Fragment>
      <div className="create-tefstory-form mt-3">
        <div className="d-flex mb-3">
          <div className="form-step">
            <div className="text-center pt-1">
              <FaBook />
            </div>
          </div>
          <p className="ml-3" style={{ fontSize: "12px" }}>
            <span style={{ color: "#da291c" }}>Step 1/2</span>
            <br />
            <span>Tell us about yourself</span>
          </p>
        </div>
        <Row form>
          <Col sm={12} md={8}>
            <Label htmlFor="country">
              Country
              <span className="required">- Required</span>
            </Label>
            <Select
              defaultVal="Select Country"
              list={countries}
              value={storyData.country}
              id="country"
              name="country"
              onChange={selectHandler}
              required
            />
          </Col>
        </Row>
        <Row form>
          <Col sm={12} md={8}>
            <FormGroup>
              <Label htmlFor="storyTitle">
                TEF Selection Year <span className="required">- Required</span>
              </Label>

              <Input
                type="number"
                inputMode="number"
                name="tefYearSelection"
                id=""
                placeholder=""
                required
                step="1"
                pattern="\d+"
                value={storyData.tefYearSelection}
                onChange={inputHandler}
              />
              <span style={{ color: "red" }}>{yearError}</span>
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col sm={12} md={8}>
            <FormGroup>
              <Label htmlFor="businessName">
                Business Name <span className="required">- Required</span>
              </Label>
              <Input
                placeholder="Your business name"
                type="text"
                value={storyData.businessName}
                name="businessName"
                id="businessName"
                onChange={inputHandler}
                required
              />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col sm={6} md={4}>
            <FormGroup>
              <Label htmlFor="businessName">
                Business Industry/Sector{" "}
                <span className="required">- Required</span>
              </Label>
              <Select
                defaultVal="Select Sector"
                list={industryObj}
                value={storyData.businessIndustry}
                id="businessIndustry"
                name="businessIndustry"
                onChange={selectHandler}
                required
              />
            </FormGroup>
          </Col>
          <Col sm={6} md={4}>
            <FormGroup>
              <Label htmlFor="businessName">
                Age of Business(Years){" "}
                <span className="required">- Required</span>
              </Label>
              <Select
                list={numbers(30)}
                name="businessAge"
                value={storyData.businessAge}
                onChange={selectHandler}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <div className="d-flex my-3">
          <div className="form-step">
            <div className="text-center pt-1">
              <FaBook />
            </div>
          </div>
          <p className="ml-3" style={{ fontSize: "12px" }}>
            <span style={{ color: "#da291c" }}>Step 2/2</span>
            <br />
            <span>Tell us your business story</span>
          </p>
        </div>
        <Row form>
          <Col sm={12} md={8}>
            <FormGroup>
              <Label htmlFor="storyTitle">
                Title of your story <span className="required">- Required</span>
              </Label>
              <Input
                type="text"
                name="title"
                id="storyTitle"
                value={storyData.title}
                placeholder="Type the title of your story"
                maxLength={40}
                required
                onChange={inputHandler}
              />
              <span className="input-maxlength float-right">
                {storyData.title.length || 0}/40
              </span>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={12} md={8}>
            <FormGroup>
              <Label htmlFor="storyDescription">
                Short description <span className="required">- Required</span>
              </Label>
              <Input
                type="text"
                name="shortDescription"
                id="storyDescription"
                placeholder="A simple description of your story"
                maxLength={60}
                required
                value={storyData.shortDescription}
                onChange={inputHandler}
              />
              <span className="input-maxlength float-right">
                {storyData.shortDescription.length || 0}/60
              </span>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={12} md={8}>
            <FormGroup>
              <Label htmlFor="articleTags">
                Add tags <span className="required"></span>
              </Label>
              {/* <Input
                placeholder="Add tags"
                type="text"
                //   value=""
                name="articleTags"
                id="articleTags"
              /> */}
              <CreatableMulti
                options={tagsOption}
                onChange={handleChange}
                isMulti={true}
              />
            </FormGroup>
          </Col>
        </Row>

        {/* <Label htmlFor="tefalumni">Are you a TEF Alumni?</Label>
        <Row form>
          <Col md={4}>
            <FormGroup>
              <SelectBox value={true} label="Yes" name="" />
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <SelectBox value={false} label="No" name="" />
            </FormGroup>
          </Col>
        </Row> */}

        {/* <Row form className="ml-2">
          <Col sm={12} md={4}>
            <FormGroup>
              <Label htmlFor="upload">
                <Input
                  type="radio"
                  name="uploadType"
                  id=""
                  onChange={inputHandler}
                  value="banner"
                />
                Upload File/Banner
              </Label>
            </FormGroup>
          </Col>
          <Col sm={12} md={4}>
            <FormGroup>
              <Label htmlFor="upload">
                <Input
                  type="radio"
                  name="uploadType"
                  id=""
                  onChange={inputHandler}
                  value="youtube"
                />
                Youtube Video
              </Label>
            </FormGroup>
          </Col>
        </Row> */}
        <Row form>
          <Col sm={12} md={8}>
            <FormGroup>
              <Label htmlFor="youtubeLink"> Youtube Link to Video</Label>
              <Input
                type="text"
                name="youtubeLink"
                id=""
                placeholder="Paste link to your video"
                onChange={inputHandler}
                value={storyData.youtubeLink}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={6} md={4}>
            <FormGroup>
              <Label htmlFor="storyImage">Upload Banner or Cover Image</Label>
              <div className="opp-create-form__image-c">
                <div className="opp-form-img-box">
                  <span className="opp-form-img-box__default-img">
                    {" "}
                    <FaRegFileImage />
                  </span>
                </div>
              </div>
              <ImageUploader
                withIcon={false}
                singleImage={true}
                withPreview={true}
                buttonText="Choose image"
                imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                onChange={onDrop}
                label="Recommended size: 144 x 144, Format: PNG, GIF, JPG Max. size: 1MB"
              />
            </FormGroup>
          </Col>

          <Col sm={6} md={4}>
            <FormGroup>
              <div className="opp-create-form-file-info pt-4">
                {"Recommended size: 144 x 144"} <br />
                {"Max. size: 1MB"} <br />
                Format: PNG, GIF, JPG{" "}
              </div>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default CreateTefStoryForm;
