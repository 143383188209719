import React from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import useGeoCode from "src/utils/useGeoCode";

const containerStyle = {
  width: "100%",
  height: "85vh",
};

const center = { lat: 6.4529174, lng: 3.4208897 };

function ContactUsMap() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API}`,
  });
  // const [marker, setMarker] = useState(null);

  const [map, setMap] = React.useState(null);
  const { coords } = useGeoCode("1, Macgregor Road, Ikoyi, Lagos");

  console.log(map);

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds();
    // // bounds.extend(marker);
    // map.fitBounds(bounds);
    // setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  // const onCenterChanged = () => {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   map.fitBounds(bounds);
  //   setMap(map);
  // };

  // useEffect(() => {
  //   if (marker) {
  //     // const bounds = new window.google.maps.LatLngBounds();
  //     // bounds.extend(marker);
  //     map.fitBounds(bounds);
  //     setMap(map);
  //   }
  // }, [marker]);
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={20}
      onLoad={onLoad}
      onUnmount={onUnmount}
      // onCenterChanged={onCenterChanged}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <Marker position={coords} />
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(ContactUsMap);
