import React, { useState, useEffect, useRef } from "react";
import InvestmentIcon from "src/assets/img/investmentIcon.png";
import { Button } from "src/modules/training/components";
import Header from "src/modules/onboarding/components/Header";
import "./_investorOnboarding.scss";
import {
  FormField,
  Select,
  CheckBox,
  SectionContainer,
} from "src/modules/onboarding/components";
import {
  investorRole,
  previousInvestmentHistory,
  folioSize,
  investmentStages,
  allYear,
} from "./static";
import {
  isCheckedCheckBox,
  CastValue,
  displayError,
  retrieveUserTempProfile,
  storeOnboardingProgress,
} from "src/modules/onboarding/helper";
import _ from "lodash";
import shortid from "shortid";
import { defaultUserInfo } from "src/modules/onboarding/stepOne/userInfo";
import ValidateJs from "src/utils/Validations/validation_wrapper";
import { useTranslation } from "react-i18next";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const InvestorOnboarding = (props) => {
  const [info, setInfo] = useState(defaultUserInfo);
  const [investor, setInvestor] = useState(info.investor);
  const [hasError, setHasErrors] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    retrieveUserTempProfile()
      .then((profile) => {
        if (!profile || profile === null) {
          props.history.push({ pathname: "/onboarding", search: "" });
        } else {
          if (!profile.groupId || profile.groupId === null) {
            props.history.go(-1);
          } else {
            setInfo(profile);
            setInvestor(profile.investor);
            return profile;
          }
        }
      })
      .then((profile) => {
        if (
          profile.steps.previousStep !== window.location.pathname &&
          profile.steps.nextStep !== ""
        ) {
          props.history.push(profile.steps.nextStep);
        }
      });
  }, []);

  const { user } = get_user_info_from_token();

  const [data, setData] = useState({ name: "", year: "", stage: "" });

  const ref = useRef(null);
  const _handleChange = (target, value) => {
    const investorObj = { ...investor, [target]: CastValue(target, value) };
    setInvestor(investorObj);
    setInfo({ ...info, investor: { ...investorObj } });
  };

  const _handleCheckBoxChange = (e, target) => {
    const value = e.target.value;
    const list = [...investor[target]];
    if (list.includes(value)) {
      const index = list.indexOf(value);
      console.log(index);
      list.splice(index, 1);
    } else {
      list.push(value);
    }
    _handleChange(target, list);
  };

  const nextStep = (userInfo) => {
    return new Promise((resolve, reject) => {
      const info = JSON.parse(JSON.stringify(userInfo));
      storeOnboardingProgress(JSON.stringify(info))
        .then((status) => {
          if (status === "success") {
            return resolve();
          }
          // return reject("failed to submit");
        })
        .catch((e) => {
          return reject(e);
        });
      // window.localStorage.setItem("userInfo", JSON.stringify(info))
    });
  };

  const _onGoBackClick = () => {
    let uInfo = JSON.parse(JSON.stringify(info));
    Object.keys(uInfo.validationErrors).forEach((key) => {
      uInfo.validationErrors[key] = null;
    });
    uInfo = _setCurrentStep(
      uInfo,
      uInfo.steps.currentCompletedStep,
      window.location.pathname,
      "/onboarding/step-two"
    );
    nextStep(uInfo).then(() => {
      props.history.replace("/onboarding/step-two", {});
      props.history.go(1);
    });
  };

  const _setCurrentStep = (info, currentStepUrl, nextStepUrl, previousStep) => {
    const completd = info.steps.completedSteps;
    completd.push(currentStepUrl);
    info.steps.completedSteps = _.uniq(completd);
    info.steps.currentCompletedStep = currentStepUrl;
    info.steps.nextStep = nextStepUrl;
    info.steps.previousStep = previousStep;
    return info;
  };

  const _onAddPriorInvestment = () => {
    const investments = [...investor.priorInvestment];
    if (data.name !== "" && data.stage !== "" && data.year !== "") {
      investments.push({ ...data });
      _handleChange("priorInvestment", investments);
      setData({ name: "", year: "", stage: "" });
    }
  };

  const _onRemovePriorInvestment = (i) => {
    const investments = [...investor.priorInvestment];
    const filtered = investments.filter((item, index) => {
      console.log(item);
      return index !== i;
    });
    _handleChange("priorInvestment", filtered);
  };

  const _handleAddPriorInvestmentChange = (target, value) => {
    setData({ ...data, [target]: value });
  };

  const _saveAndContinueClick = (userInfo) => {
    userInfo = _setCurrentStep(
      userInfo,
      window.location.pathname,
      "/onboarding/interests",
      ""
    );
    nextStep(userInfo).then(() => {
      props.history.push("/onboarding/interests");
    });
  };

  const hasValidationError = (data) => {
    const noNeededValidations = ["experience", "priorInvestment"];
    let { investor } = data;
    investor = { ...investor };
    for (const key of noNeededValidations) {
      delete investor[key];
    }

    let errors = {};
    const errorList = [];
    Object.keys(investor).forEach((key) => {
      errors = { ...errors, [key]: ValidateJs(key, investor[key]) };
    });

    Object.keys(errors).forEach((key) => {
      if (errors[key] !== null) {
        errorList.push(key);
      }
    });
    const errObj = { ...info, validationErrors: errors };
    setInfo(errObj);

    return { confirmed: errorList.length > 0, errors: errorList };
  };

  const _handleValidationErrorChanges = (target, value) => {
    const obj = { ...info.validationErrors, [target]: value };
    setInfo({ ...info, validationErrors: obj });
  };

  const InvestmentList = ({ name, year, stage, onRemove }) => {
    return (
      <div className="investment-list__item">
        <div className="investment-list__item--image-container">
          <img src={InvestmentIcon} alt="icon" className="img-fluid" />
        </div>
        <div className="investment-list__item--content-right">
          <h1>{name}</h1>
          <span>
            {stage} - {year}
          </span>
        </div>
        <span className="remove-item" onClick={onRemove}>
          Remove
        </span>
      </div>
    );
  };
  const _handleSubmit = () => {
    const hasErrors = hasValidationError(info);
    if (hasErrors.confirmed) {
      // _scrollToRef(ref);
      document.getElementById(hasErrors.errors[0]).scrollIntoView();
      setHasErrors(true);
      return;
    }
    return _saveAndContinueClick(info);
  };

  return (
    <div>
      <Header {...props} />
      <div className="investor-page-container" id="investor">
        <div className="investor-page-container__banner">
          <div
            className="custom-row"
            style={{
              justifyContent: "space-between",
              display: "flex",
              marginTop: "-55px",
              marginBottom: "44px",
            }}
          >
            <span className="backSkipButtons" onClick={_onGoBackClick}>
              {`< ${t("Back")}`}
            </span>
          </div>
          <h1>Describe your Investing experience</h1>
          <p>
            Sharing your investment history will help us evaluate your
            application. <br />A detailed profile gives you exposure to more
            than 200,000 TEF startups across Africa
          </p>
        </div>

        {/* <form> */}
        <section className="investor-page-container__form-section">
          <div className="investor-page-container__form-section--left">
            <form ref={ref}>
              {hasError && (
                <span
                  className="pad all-fields-required"
                  style={{ color: "red", marginLeft: "10px" }}
                >
                  {" "}
                  Please fill all required fields
                </span>
              )}
              <SectionContainer>
                <FormField
                  label="Describe your role as an Investor"
                  labelInfo="Required"
                  id="role"
                >
                  {info.validationErrors.role && (
                    <div className="displayError">
                      {displayError(info.validationErrors.role)}
                    </div>
                  )}

                  {investorRole.map((role) => {
                    return (
                      <CheckBox
                        label={role.label}
                        name={role.value}
                        value={role.value}
                        key={shortid.generate()}
                        onChange={(e) => _handleCheckBoxChange(e, "role")}
                        checked={isCheckedCheckBox(investor.role, role.value)}
                      />
                    );
                  })}
                  {/* <div className="custom-row">
                    <CheckBox name="Other" label="" value="other" />
                    <input
                      type="text"
                      name="other"
                      placeholder="Others - Describe your prior investment roles"
                      style={{
                        outline: "none",
                        padding: "3px 10px",
                        borderRadius: "3px",
                        border: "solid 1px #c4cdd5",
                        flex: 1,
                      }}
                    />
                  </div> */}
                </FormField>
                <br />

                <FormField
                  label="Describe your previous investment history"
                  labelInfo="Required"
                  id="previousInvestment"
                >
                  {info.validationErrors.previousInvestment && (
                    <div className="displayError">
                      {displayError(info.validationErrors.previousInvestment)}
                    </div>
                  )}
                  {previousInvestmentHistory.map((history) => {
                    return (
                      <CheckBox
                        label={history.label}
                        name={history.value}
                        value={history.value}
                        key={shortid.generate()}
                        onChange={(e) =>
                          _handleCheckBoxChange(e, "previousInvestment")
                        }
                        checked={isCheckedCheckBox(
                          investor.previousInvestment,
                          history.value
                        )}
                      />
                    );
                  })}
                </FormField>
              </SectionContainer>
              <SectionContainer
                title="List your prior investment(s)"
                subTitle="Kindly provide the name of at least one startup or fund you've invested"
              >
                <div style={{ position: "relative" }}>
                  <span
                    style={{
                      // position: "absolute",
                      // left: "10px",
                      // top: "-5px",
                      color: "#26292d",
                      fontWeight: "bold",
                      marginTop: "15px",
                      marginLeft: "10px",
                    }}
                    className="toggle-add-investment-title"
                  >
                    Add Investment
                    <span
                      style={{
                        color: "#919ba8",
                        fontWeight: "100",
                        marginLeft: "9px",
                      }}
                    >
                      - Optional
                    </span>
                  </span>
                  <div className="row toggle1">
                    <div className="col-md-4" style={{ marginTop: "-8px" }}>
                      <FormField
                        // className="flex"
                        type="text"
                        placeholder="Company name"
                        value={data.name}
                        onChange={_handleAddPriorInvestmentChange}
                        name="name"
                      />
                    </div>

                    <div className="col-md-4">
                      <FormField>
                        <Select
                          defaultVal="year"
                          list={allYear()}
                          value={data.year}
                          onChange={_handleAddPriorInvestmentChange}
                          name="year"
                          // className="flex"
                        />
                      </FormField>
                    </div>
                    <div className="col-md-4">
                      <FormField>
                        <Select
                          defaultVal="Stage"
                          list={investmentStages}
                          value={data.stage}
                          onChange={_handleAddPriorInvestmentChange}
                          name="stage"
                          // className="flex"
                        />
                      </FormField>
                    </div>
                  </div>

                  {/* show this on certain screen */}
                  <div className="toggle2">
                    <FormField
                      className="flex"
                      type="text"
                      placeholder="Company name"
                      value={data.name}
                      onChange={_handleAddPriorInvestmentChange}
                      name="name"
                    />

                    <FormField>
                      <Select
                        defaultVal="year"
                        list={allYear()}
                        value={data.year}
                        onChange={_handleAddPriorInvestmentChange}
                        name="year"
                        className="flex"
                      />
                    </FormField>
                    <FormField>
                      <Select
                        defaultVal="Stage"
                        list={investmentStages}
                        value={data.stage}
                        onChange={_handleAddPriorInvestmentChange}
                        name="stage"
                        // className="flex"
                      />
                    </FormField>
                  </div>
                  {/* show this on certain screen */}

                  <span
                    className="add-new"
                    style={{ marginLeft: "9px" }}
                    onClick={_onAddPriorInvestment}
                  >
                    + Add new investment
                  </span>
                </div>

                {investor.priorInvestment.length > 0 && (
                  <div className="investment-list">
                    {investor.priorInvestment.map((investment, i) => {
                      return (
                        <InvestmentList
                          key={shortid.generate()}
                          name={investment.name}
                          year={investment.year}
                          stage={investment.stage}
                          onRemove={() => _onRemovePriorInvestment(i)}
                        />
                      );
                    })}
                  </div>
                )}
              </SectionContainer>
              <SectionContainer
                title={`Tell us about yourself, ${user.firstName}`}
              >
                <FormField
                  labelInfo="Required"
                  label="How much of your investment portfolio do you plan to allocate to startups on the TEFConnect platform over the next 12 months?"
                  className="flex"
                  id="folioSize"
                >
                  <Select
                    style={{ width: "220px" }}
                    defaultVal="Select folio size"
                    list={folioSize}
                    onChange={_handleChange}
                    value={investor.folioSize}
                    name="folioSize"
                    onBlur={() =>
                      _handleValidationErrorChanges(
                        "folioSize",
                        ValidateJs("folioSize", investor.folioSize)
                      )
                    }
                    error={info.validationErrors.folioSize}
                  />

                  {info.validationErrors.folioSize && (
                    <div className="displayError">
                      {displayError(info.validationErrors.folioSize)}
                    </div>
                  )}
                </FormField>
                <br />
                <br />
                <FormField
                  type="textarea"
                  label="Briefly describe your work and investing experience"
                  labelInfo="Optional"
                  placeholder="e.g. I am a senior vice president at WaveNigeria and before that I started a fintech com"
                  name="experience"
                  onChange={_handleChange}
                  value={investor.experience}
                />
              </SectionContainer>
              <div
                className="custom-row pad-inner"
                style={{ marginTop: "48px", marginBottom: "70px" }}
              >
                <div className="flex-grow">
                  <Button
                    value="Save & Continue"
                    width="303px"
                    height="54px"
                    fontSize="16px"
                    onClick={_handleSubmit}
                  />
                </div>
                <div className="flex-grow steps-count">Step 3 of 4</div>
              </div>
            </form>
          </div>
          <div className="investor-page-container__form-section--right"></div>
        </section>
        {/* </form> */}
      </div>
    </div>
  );
};

export default InvestorOnboarding;
