import axios from "axios";
import { asyncActions } from "src/utils/AsyncUtils";
import { discoverURL } from "src/constants";
import { GET_ALL_MASTER_CLASS } from "src/actions";

export const getAllMasterClass = (dispatch, pageNumber, sortString = "") => {
  dispatch(asyncActions(GET_ALL_MASTER_CLASS).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ALL_MASTER_CLASS_URL}?pageNumber=${pageNumber}&SortBy=${sortString}`,
  });
};
export const getMasterClass = (dispatch, id) => {
  dispatch(asyncActions(GET_ALL_MASTER_CLASS).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ALL_MASTER_CLASS_URL}/${id}`,
  });
};
