import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import "./Article.scss";

const DownloadArticle = ({ modal, toggle }) => {
  return (
    <div className="">
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="modal-title"
        className="px-4"
      >
        <ModalHeader toggle={toggle} className="">
          <div className="">Download Article</div>
        </ModalHeader>

        <ModalBody>
          <p>Select File Format to Download</p>

          <div className="download-format">
            <FormGroup check>
              <Label check className="font-weight-normal">
                <Input type="radio" name="radio1" className="danger" /> PDF
              </Label>
            </FormGroup>
          </div>
          <div className="download-format">
            <FormGroup check>
              <Label check className="font-weight-normal">
                <Input type="radio" name="radio1" /> MS WORD
              </Label>
            </FormGroup>
          </div>
          <div className="download-format">
            <FormGroup check>
              <Label check className="font-weight-normal">
                <Input type="radio" name="radio1" /> HTML
              </Label>
            </FormGroup>
          </div>

          <div className="d-flex justify-content-between mt-5 cursor-pointer">
            <div className="cancel-download-btn">Cancel</div>
            <div className="download-article-btn">Download</div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DownloadArticle;
