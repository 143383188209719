import React from "react";
import { Row, Col } from "reactstrap";
import classes from "./NewAuthLayout_v2.module.scss";
import tef_logo from "src/assets/img/logo/logo_new.webp";
import { Link } from "react-router-dom";
import { GrFacebookOption, GrLinkedinOption } from "react-icons/gr";
import { IoLogoTwitter, IoLogoWhatsapp } from "react-icons/io";
import { RiInstagramFill } from "react-icons/ri";
import { GOOGLE_TRANSLATE_IN_HEADER_MIN_WIDTH } from "./AuthLayoutHeader";
import useWindowDimensions from "src/utils/useWindowDimensions";
import { LanguageDropdown } from "src/components/LanguageDropdown/LanguageDropdown";

const AuthLayoutFooter = () => {
  const { width: viewportWidth } = useWindowDimensions();

  return (
    <div className={classes.footerContainer}>
      <Row className={classes.footerTop}>
        <Col md={3} lg={3} sm={12}>
          <img src={tef_logo} className={classes.footer_logo} />
          <p className={classes.f_p}>Empowering African Entrepreneurs</p>
          {viewportWidth < GOOGLE_TRANSLATE_IN_HEADER_MIN_WIDTH && (
            <LanguageDropdown />
          )}
        </Col>

        <Col md={3} lg={3} sm={6}>
          <h4 className={classes.footer_title}>Company</h4>
          <ul className={classes.footer_menu_list}>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact us</Link>
            </li>
            <li>
              <a
                href="https://www.tonyelumelufoundation.org/about-us"
                target="_blank"
                rel="noreferrer"
              >
                About us
              </a>
            </li>
            <li>
              <a
                href="https://www.tonyelumelufoundation.org/careers"
                target="_blank"
                rel="noreferrer"
              >
                Careers
              </a>
            </li>
            <li>
              <a href="#" target="_blank" rel="noreferrer">
                Awards
              </a>
            </li>
            <li>
              <a
                href="https://tonyelumelufoundation.org/partners"
                target="_blank"
                rel="noreferrer"
              >
                Partnerships
              </a>
            </li>
            <li>
              <a
                href="https://marketplace.tefconnect.com"
                target="_blank"
                rel="noreferrer"
              >
                Marketplace
              </a>
            </li>
          </ul>
        </Col>

        <Col md={3} lg={3} sm={6}>
          <h4 className={classes.footer_title}>Resources</h4>
          <ul className={classes.footer_menu_list}>
            <li>
              <a
                href="https://www.tonyelumelufoundation.org/articles"
                target="_blank"
                rel="noreferrer"
              >
                Blog
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/user/tonyelumelufdn"
                target="_blank"
                rel="noreferrer"
              >
                Webinars
              </a>
            </li>
            <li>
              <a
                href="https://www.tonyelumelufoundation.org/tef-entrepreneurship-programme/faqs"
                target="_blank"
                rel="noreferrer"
              >
                Guides
              </a>
            </li>
            <li>
              <a
                href="https://www.tonyelumelufoundation.org/success-stories"
                target="_blank"
                rel="noreferrer"
              >
                Success Stories
              </a>
            </li>
          </ul>
        </Col>

        <Col md={3} lg={3} sm={12}>
          <h4 className={classes.footer_title}>Connect with us</h4>
          <ul className={classes.social_icon_list}>
            <li>
              <a
                href="https://www.facebook.com/thetonyelumelufoundation"
                target="_blank"
                rel="noreferrer"
              >
                <GrFacebookOption size={24} />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/TonyElumeluFDN"
                target="_blank"
                rel="noreferrer"
              >
                <IoLogoTwitter size={24} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/tonyelumelufoundation"
                target="_blank"
                rel="noreferrer"
              >
                <RiInstagramFill size={24} />
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/23417006713"
                target="_blank"
                rel="noreferrer"
              >
                <IoLogoWhatsapp size={24} />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/tony-elumelu-foundation"
                target="_blank"
                rel="noreferrer"
              >
                <GrLinkedinOption size={24} />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
      <hr className={classes.hr} />

      <div className={classes.footer_bottom}>
        <p className={classes.f_bottom_p}>
          {new Date().getFullYear()} – TEFConnect. All rights reserved.
        </p>

        <ul className={classes.f_bottom_list}>
          <li>
            <Link to="/terms-of-use">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy Policy</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AuthLayoutFooter;
