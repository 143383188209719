import React from "react";
import styles from "./Message.module.scss";
import { EditorContentDisplay } from "src/components/Editor";
import { useCurrentUser } from "src/utils/Services/currentUser";
import Avatar from "react-avatar";

const Message = ({ dp, name, time, message, userId }) => {
  const { userId: id } = useCurrentUser();
  const isUser = id === userId;

  return (
    <div className={`${styles.Message} ${isUser && styles.UserMessage}`}>
      {!isUser &&
        (dp ? (
          <img src={dp} alt="dp" className={styles.Message__dp} />
        ) : (
          <Avatar
            name={name}
            size={30}
            round={true}
            className={styles.Message__dp}
          />
        ))}
      <div className={styles.Message__messageDisplay}>
        <div
          className={`${styles.MessageCard} ${
            isUser ? styles.MessageUser : styles.MessageGuest
          }`}
        >
          <EditorContentDisplay content={message} readOnly linkColor="#fff" />
        </div>
        <div className={styles.Message__NameTime}>
          {/* <h3 className={styles.Message__name}>{name}</h3> */}
          <h3 className={styles.Message__time}>{time}</h3>
        </div>
      </div>
    </div>
  );
};

export default Message;
