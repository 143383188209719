import React from "react";

const OperationalPlansTemplate = ({ operationalPlans }) => {
  return (
    <div>
      <h4 className="text-center font-weight-bold mb-5">Operational Plans</h4>
      <p>
        <span className="mr-4">6.2</span>
        <span>DESCRIPTION OF OPERATIONS</span>
      </p>
      <p className="ml-5">
        {operationalPlans.descriptionOfOperations === ""
          ? "[DESCRIPTION OF OPERATIONS]"
          : operationalPlans.descriptionOfOperations}{" "}
      </p>

      <p>
        <span className="mr-4">6.4</span>
        <span>Key Resources:</span>
      </p>
      <p className="ml-5">
        {" "}
        <ul>
          {operationalPlans.keyResources.map((resource, index) => (
            <li key={index}>{resource}</li>
          ))}
        </ul>
      </p>
      <p>
        <span className="mr-4">6.5</span>
        <span>Suppliers;</span>
      </p>
      <p className="ml-5">
        <ul>
          {operationalPlans.suppliers.map((supplier, index) => (
            <li key={index}>{supplier}</li>
          ))}
        </ul>
      </p>

      <p>
        <span className="mr-4">6.6</span>
        <span>RAW MATERIALS/CONSUMABLES NEEDED/SUPPLIES</span>
      </p>

      <table className="table table-bordered text-center">
        <thead>
          <tr>
            <th scope="row">Materials</th>
            <th>Description/ uses</th>
            <th>Key Supplier/Source</th>
          </tr>
        </thead>
        <tbody>
          {operationalPlans.rawMaterials.map((material, index) => (
            <tr key={index}>
              <td>{material.materials}</td>
              <td>{material.description}</td>
              <td>{material.source}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <p className="ml-5">
        List of Capital Equipment, description, uses and suppliers.
      </p>

      <table className="table table-bordered text-center">
        <thead>
          <tr>
            <th scope="row">Equipment</th>
            <th>Description/ uses</th>
            <th>Supplier</th>
          </tr>
        </thead>
        <tbody>
          {operationalPlans.capitalEquipments.map((material, index) => (
            <tr key={index}>
              <td>{material.equipment}</td>
              <td>{material.description}</td>
              <td>{material.source}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <p className="ml-5">List of Consumables</p>

      <table className="table table-bordered text-center">
        <thead>
          <tr>
            <th scope="row">Materials</th>
            <th>Description/ uses</th>
            <th>Supplier</th>
          </tr>
        </thead>
        <tbody>
          {operationalPlans.consumablesList.map((item, index) => (
            <tr key={index}>
              <td>{item.materials}</td>
              <td>{item.description}</td>
              <td>{item.source}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OperationalPlansTemplate;
