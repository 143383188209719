import React, { useContext } from "react";
import { FaTrash, FaPenAlt } from "react-icons/fa";
import { Context } from "src/store";
import { deleteGroup } from "src/requests/StudyGroupsRequest";
import "./MoreOptions.scss";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
// import {
//   FacebookShareButton,
//   LinkedinShareButton,
//   TwitterShareButton,
//   FacebookIcon,
//   LinkedinIcon,
//   TwitterIcon,
// } from "react-share";
// import { ReactComponent as Email } from "src/modules/assets/img/home/email.svg";
// import { ReactComponent as Share } from "src/modules/assets/img/home/share.svg";

const MoreOptions = ({
  show,
  groupOwnerId,
  userId,
  groupId,
  setToggleInviteFriendsModal,
  groupTitle,
  lastName,
  firstName,
  toggleEditClick,
}) => {
  const { dispatch } = useContext(Context);
  const history = useHistory();

  // const message = `Hello, \n${
  //   firstName + " " + lastName
  // } is inviting you to contribute to the ${groupTitle} discussions. Click on the link below to join.`;

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.value) {
        deleteGroup(dispatch, groupId).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              title: "Deleted!",
              text: "Group successfully deleted",
              icon: "success",
              confirmButtonColor: "red",
            });
            history.goBack();
          } else {
            Swal.fire({
              title: "Cancelled",
              text: "Could not delete, please try again",
              icon: "error",
              confirmButtonColor: "red",
            });
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Cancelled",
          text: "Delete cancelled",
          icon: "error",
          confirmButtonColor: "red",
        });
      }
    });
  };

  return (
    <div className={show ? "more-options" : "more-options hide-options"}>
      <button onClick={toggleEditClick} className="more-options__btn">
        <FaPenAlt />
        <span className="more-options__btn--span">Edit Group</span>
      </button>

      {/* {groupOwnerId === userId ? (
        <button
          className="more-options__btn"
          onClick={() => setToggleInviteFriendsModal(true)}
        >
          <FaBolt />
          <span className="more-options__btn--span">Invite members</span>
        </button>
      ) : (
        ""
      )} */}
      {/* {groupOwnerId === userId ? (
        <button className="more-options__btn">
          <FaDesktop />
          <span className="more-options__btn--span">Moderate Group</span>
        </button>
      ) : (
        ""
      )} */}
      {groupOwnerId === userId ? (
        <button onClick={handleDelete} className="more-options__btn">
          <FaTrash />
          <span className="more-options__btn--span">Delete Group</span>
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default MoreOptions;
