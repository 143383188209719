import React from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
import Loader from "react-loader-spinner";

const ModalT = ({
  modal,
  toggle,
  className,
  title,
  children,
  onSubmit,
  loadingCreate,
  buttonText,
  disable,
}) => {
  const handleSubmit = (e) => {
    onSubmit();
    e.preventDefault();
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
        unmountOnClose={false}
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <Form onSubmit={handleSubmit}>
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            <Button type="submit" color="secondary" disabled={disable}>
              <span
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span>{buttonText || "Create Goal"} </span>
                {loadingCreate && (
                  <span style={{ marginLeft: "2px" }}>
                    {" "}
                    <Loader height={15} width={15} color="white" type="Oval" />
                  </span>
                )}
              </span>
            </Button>{" "}
            <Button color="#d8dce6" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};
ModalT.defaultProps = {
  loadingCreate: false,
};

export default ModalT;
