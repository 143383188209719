import React from "react";

const ManagementTemplate = ({ management }) => {
  return (
    <div>
      <h4 className="text-center font-weight-bold mb-5">Management</h4>
      <p>
        <span className="mr-4">8.1</span>
        <span>ORGANIZATIONAL AND MANAGEMENT PLAN</span>
      </p>
      <p className="ml-5">OWNERSHIP OF THE BUSINESS</p>
      <p className="ml-5">
        Our form of business ownership is [TYPE OF BUSINESS OWNERSHIP]. We
        choose this form of business ownership because{" "}
        {management.reason === ""
          ? "[REASON FOR TYPE OF BUSINESS OWNERSHIP]"
          : management.reason}
      </p>

      <p>
        <span className="mr-4">8.2</span>
        <span>PROFILE OF THE PROMOTER</span>
      </p>
      <p className="ml-5">[PERSONAL PROFILE]</p>
      <p className="ml-5">[ADDITIONAL PROFILE OF ANY OTHER FOUNDER]</p>
      <p>
        <span className="mr-4">8.3</span>
        <span>DETAILS OF EMPLOYEE</span>
      </p>
      <p className="ml-5">
        {management.detailsOfEmployee === ""
          ? "[DETAILS OF MANAGEMENT AND EMPLOYEES]"
          : management.detailsOfEmployee}
      </p>

      <p>
        <span className="mr-4 mt-4"></span>
        <span>Key Management Team</span>
      </p>

      <table className="table table-bordered text-center">
        <thead>
          <tr>
            <th scope="row">Position</th>
            <th>Skills, qualification and experience required</th>
            <th>Functions and responsibility</th>
          </tr>
        </thead>
        <tbody>
          {management.keyManagementTeam.map((member, index) => (
            <tr key={index}>
              <td>{member.Position}</td>
              <td>{member.Skills}</td>
              <td>{member.responsibility}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <p className="ml-5">
        {management.operatingHours === ""
          ? "[OPERATING HOURS]"
          : management.operatingHours}
      </p>

      <p>
        <span className="mr-4">8.4</span>
        <span>Organizational Structure</span>
      </p>
      <p className="ml-5">[UPLOAD ORG STRUCTURE IMAGE]</p>
    </div>
  );
};

export default ManagementTemplate;
