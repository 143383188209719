import React from "react";
import Layout from "src/components/Layout/Layout";
import ConstrtuctionPage from "src/modules/UnderConstruction";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";

const MarketPlace = (props) => {
  return (
    <Layout {...props} SideContent={<SidebarRight />} heading="Market Place">
      <ConstrtuctionPage />
    </Layout>
  );
};

export default ifOnboardedThenShow(MarketPlace);
