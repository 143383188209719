import React from "react";

const IndustryMarketAnalysisTemplate = ({ industryMarketAnalysis }) => {
  return (
    <div>
      <h4 className="text-center font-weight-bold mb-5">
        Industry and Market Analysis
      </h4>
      <p>
        <span className="mr-4">3.1</span>
        <span>DESCRIPTION OF THE BUSINESS INDUSTRY/ INDUSTRY ANALYSIS.</span>
      </p>
      <p className="ml-5">
        {industryMarketAnalysis.descriptionOfIndustry === ""
          ? "[DESCRIPTION OF INDUSTRY]"
          : industryMarketAnalysis.descriptionOfIndustry}{" "}
        <br />
        {industryMarketAnalysis.descriptionOfMarket === ""
          ? "[DESCRIPTION OF MARKET]"
          : industryMarketAnalysis.descriptionOfMarket}{" "}
        <br />
        The big players in this sector include{" "}
        {industryMarketAnalysis.competitors.map((competitor, index) => (
          <span key={index}>{competitor}, </span>
        ))}
        .
      </p>
      <p className="ml-5">Potential Challenges in the Business Industry</p>
      <p className="ml-5">
        <ul>
          {industryMarketAnalysis.challengesInBusiness.map(
            (challenge, index) => (
              <li key={index}>{challenge}</li>
            )
          )}
        </ul>
      </p>
      <p className="ml-5">Mitigation for the above challenges;</p>
      <p className="ml-5">
        <ul>
          {industryMarketAnalysis.mitigationOfChallenges.map(
            (mitigant, index) => (
              <li key={index}>{mitigant}</li>
            )
          )}
        </ul>
      </p>
      <p className="ml-5">The Opportunity</p>
      <p className="ml-5">
        <ul>
          {industryMarketAnalysis.opportunity.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </p>

      <p>
        <span className="mr-4">3.2</span>
        <span>TARGET MARKET.</span>
      </p>
      <p className="ml-5">
        <ul>
          {industryMarketAnalysis.targetMarket.map((market, index) => (
            <li key={index}>{market}</li>
          ))}
        </ul>
      </p>
    </div>
  );
};

export default IndustryMarketAnalysisTemplate;
