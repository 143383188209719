import jwt_decode from "jwt-decode";

const safe_jwt_decode = (token) => {
  if (!token) {
    throw new Error("token is falsy");
  }
  return jwt_decode(token);
};

export default safe_jwt_decode;
