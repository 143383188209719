import React from "react";
import classes from "./OpportunityPoster.module.css";
import moment from "moment";
import Avatar from "src/components/Avatar/Avatar";

const OpportunityPoster = ({ name, posterPhoto, deadline, category }) => {
  const daysLeft = () => {
    const eventdate = moment(deadline);
    const today = moment();
    if (eventdate >= today) {
      return eventdate.diff(today, "days");
    } else {
      return null;
    }
  };
  return (
    <div className={classes.PostedBy}>
      <p className={classes.Posted}>Posted: </p>
      <div className={classes.PosterImage}>
        <Avatar src={posterPhoto} dimension="1rem" name={name} />
      </div>
      <p className={classes.PosterName}>{name}</p>
      <p className={classes.OpportunityType}>{category}</p>
      <p className={classes.Posted}>
        {daysLeft() ? `${daysLeft()} days left` : `Expired!`}
      </p>
    </div>
  );
};

export default OpportunityPoster;
