import React, { useState, useEffect, useContext } from "react";
import "./availability.scss";
import { isMentor } from "src/modules/mentorship/components/helper";
import {
  getMentorProfile,
  updateMentorProfile,
} from "src/requests/ProfileRequest";
import { Context } from "src/store";
import Loader from "react-loader-spinner";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { useCurrentUser } from "src/utils/Services/currentUser";

const Day = ({ value, label, isSelected, onSelect }) => {
  return (
    <div
      className={`available-day-item ${isSelected && "selected-day"}`}
      onClick={() => {
        onSelect(value);
      }}
    >
      <span>{label}</span>
    </div>
  );
};

const Availabilty = () => {
  const [selectedDays, setSelectedDays] = useState([]);
  const data = get_user_info_from_token();
  const { state, dispatch } = useContext(Context);
  const [loadingData, setLoadingData] = useState(false);

  const currentUser = useCurrentUser();

  const _getMentorProfile = () => {
    setLoadingData(true);
    return getMentorProfile(currentUser.userId).then((res) => {
      const { data, status } = res;
      if (status === 200 || (status === 201 && data && data.data)) {
        const profile = data.data;
        const availability = profile.availabilityDays;
        setSelectedDays(availability || []);
        setLoadingData(false);
      }
    });
  };
  useEffect(() => {
    if (isMentor(data.user)) {
      _getMentorProfile();
    }
  }, [currentUser]);

  const isSelected = (value) => {
    return selectedDays.indexOf(value) >= 0;
  };
  const onSelectDay = (value) => {
    let selected = [...selectedDays];
    if (isSelected(value)) {
      const filtered = selected.filter((day) => {
        return day !== value;
      });
      selected = filtered;
      setSelectedDays(selected);
    } else {
      selected.push(value);
      // console.log(selected);
      setSelectedDays(selected);
    }
  };
  const _onSubmit = () => {
    updateMentorProfile(
      dispatch,
      { availabilityDays: selectedDays },
      _getMentorProfile
    );
  };

  return (
    <div className="mentor-availabilty-container">
      <div className="mentor-availabilty-container__heading">
        <h1>My Availabilty</h1>
        <span>Let your mentees know your availability</span>
        <div>{loadingData && <span>Synching updates...</span>}</div>
      </div>

      <div className="available-days-container">
        <div className="days-container">
          <Day
            label="Monday"
            value="mon"
            isSelected={isSelected("mon")}
            onSelect={onSelectDay}
          />
          <Day
            label="Tuesday"
            value="tue"
            isSelected={isSelected("tue")}
            onSelect={onSelectDay}
          />
          <Day
            label="Wednesday"
            value="wed"
            isSelected={isSelected("wed")}
            onSelect={onSelectDay}
          />
          <Day
            label="Thursday"
            value="thur"
            isSelected={isSelected("thur")}
            onSelect={onSelectDay}
          />
          <Day
            label="Friday"
            value="fri"
            isSelected={isSelected("fri")}
            onSelect={onSelectDay}
          />
          <Day
            label="Saturday"
            value="sat"
            isSelected={isSelected("sat")}
            onSelect={onSelectDay}
          />
          <Day
            label="Sunday"
            value="sun"
            isSelected={isSelected("sun")}
            onSelect={onSelectDay}
          />
          <div className="submit-box">
            <div
              className="submit-btn"
              onClick={
                state.Profile.loadingSetAvailability
                  ? () => {}
                  : () => _onSubmit()
              }
            >
              Set Availabilty{" "}
              {state.Profile.loadingSetAvailability ? (
                <Loader type="Oval" width={20} height={20} color="white" />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div style={{ flex: 1 }}></div>
      </div>
    </div>
  );
};

export default Availabilty;
