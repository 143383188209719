import React from "react";
import classes from "./AlumniList.module.scss";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { selectColor } from "src/modules/Alumni/Components/helper/selectColor";

const user = get_user_info_from_token();

let id;
if (user) {
  id = user.user.id;
}

const AlumniMember = ({ user: { user }, switchDefaultActiveChat, idx }) => {
  const isUser = (user) => {
    return user.userId === id;
  };
  const colorPicker = selectColor();
  return (
    <div
      className={classes["alumni-member__wrapper"]}
      onClick={() => switchDefaultActiveChat(user.userId)}
    >
      <div className={classes["alumni-image"]}>
        {user.imageUrl ? (
          <img src={user.imageUrl} alt="" />
        ) : (
          <div
            className="user-no-img"
            style={{
              backgroundColor: colorPicker(idx)?.background,
              color: colorPicker(idx)?.color,
            }}
          >
            <span>{`${user?.firstName[0]}`}</span>
          </div>
        )}
      </div>
      <div className={classes["alumni-details"]}>
        <div className={classes["alumni-name"]}>
          {`${user.firstName} ${user.lastName} ${isUser(user) ? "(You)" : ""}`}
        </div>
        <div className={classes["alumni-email"]}>{user.email}</div>
      </div>
    </div>
  );
};

export default AlumniMember;
