import React from "react";

import FormInput from "src/modules/Tef-Dms/components/Form/FormInput";

const SwotAnalysis = ({ swot, setSwot }) => {
  const handleChange = (e) => {
    setSwot({
      ...swot,
      [e.target.name]: e.target.value,
    });
  };

  const handleDynamicInput = (key, i, e) => {
    const newArray = key;
    newArray[i] = e.target.value;
    setSwot({
      ...swot,
      [key]: newArray,
    });
  };

  const addRow = (key, value) => {
    const newArray = key;
    newArray.push(value);
    setSwot({
      ...swot,
      [key]: newArray,
    });
  };
  return (
    <React.Fragment>
      <div className="pr-2">
        <div className="mt-3" style={{ fontSize: "0.75rem" }}>
          <label>SWOT Analysis </label>
          <div className="pr-3">
            <FormInput
              label="Strength"
              getFormInputValue={(e) => handleChange(e)}
              value={swot.strength}
              name="strength"
              placeholder="Strength"
            />
            <FormInput
              label="Weakness"
              getFormInputValue={(e) => handleChange(e)}
              value={swot.weakness}
              name="weakness"
              placeholder="Weakness"
            />
            <FormInput
              label="Opportunities"
              getFormInputValue={(e) => handleChange(e)}
              value={swot.opportunity}
              name="opportunity"
              placeholder="Opportunities"
            />
            <FormInput
              label="Threats"
              getFormInputValue={(e) => handleChange(e)}
              value={swot.threats}
              name="threats"
              placeholder="Threats"
            />
          </div>
        </div>
        <div className="mt-3">
          <label>Risk: </label>
          {swot.risks.map((item, index) => (
            <FormInput
              key={index}
              getFormInputValue={(e) =>
                handleDynamicInput(swot.risks, index, e)
              }
              value={item}
              label="Risks"
              placeholder=""
            />
          ))}
          <button
            type="button"
            onClick={() => addRow(swot.risks, "")}
            className="btn btn-primary text-white px-2 py-1 border-0 mt-2"
          >
            Add Risk
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SwotAnalysis;
