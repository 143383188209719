import React from "react";
import { Col, Row } from "reactstrap";
import "./Skills.scss";
const Skills = ({ skills }) => {
  const displaySkills = () =>
    skills &&
    skills.map((skill) => (
      <Col key={skill.id} className="skills-item" md={6}>
        <Row>
          <Col className="skills-item__details" md={10}>
            <h3>{skill.name}</h3>
          </Col>
        </Row>
        <hr />
      </Col>
    ));
  return (
    <Col>
      <Row>{displaySkills()}</Row>
    </Col>
  );
};

Skills.propTypes = {};

export default Skills;
