import React from "react";
import { CardGroup, Button } from "reactstrap";
import Card from "src/modules/Alumni/Resources/components/Card/index";
import PrimaryButton from "src/modules/Alumni/Components/PrimaryButton/index";
import "./index.scss";

const forDownloads = ["case_studies", "white_papers", "documents"];

const TopCardList = ({ data, onDownload, onOpenLink }) => {
  return (
    <div className="mt-4">
      <CardGroup>
        {data.map((resource, i) => (
          <Card
            key={i}
            md={4}
            img={
              resource.resourceCategory.toLowerCase() === "news"
                ? resource.fileUrl
                : resource.coverPageUrl ||
                  "https://www.pcclean.io/wp-content/uploads/2020/4/MzUfam.jpg"
            }
            TopLeftEle={
              <Button
                color="new-faded-white"
                className="card-list-button-style"
                outline
              >
                {resource.resourceCategory.replace("_", " ")}
              </Button>
            }
            BottomLeftEle={
              <PrimaryButton
                buttonText={
                  forDownloads.indexOf(
                    resource.resourceCategory.toLowerCase()
                  ) > -1
                    ? "Download"
                    : "View"
                }
                style={{ borderRadius: 30, height: 30, fontSize: 10 }}
                buttonAction={() => {
                  if (
                    forDownloads.indexOf(
                      resource.resourceCategory.toLowerCase()
                    ) > -1
                  )
                    onDownload(resource);
                  else {
                    onOpenLink(
                      resource.resourceCategory === "videos"
                        ? "video-view"
                        : "news-view",
                      resource
                    );
                  }
                }}
              />
            }
            content={
              <span
                className="bold_card_container"
                style={{ textTransform: "capitalize" }}
              >
                {resource.title}
              </span>
            }
          />
        ))}
      </CardGroup>
    </div>
  );
};

export default TopCardList;
