import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import classes from "./Feedback.module.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FeedbackModal = ({ modal, toggle }) => {
  const history = useHistory();

  const handleSubmit = () => {
    toggle();
    history.push("/opportunities");
  };

  const { t } = useTranslation();

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader className={classes.ModalHeader} toggle={toggle}>
          <div>{t("Flag Opportunity")}</div>
        </ModalHeader>
        <ModalBody className={classes.ModalBody}>
          <h4 className={classes.ModalLabel}>
            {t("Thanks for letting us know")}
          </h4>
          <p>
            {t(
              "TEFConnect is a place to share ideas and information, connect with your communities, and see the world around you. In order to protect the very best parts of that experience, we provide tools designed to help you control what you see and what others can see about you, so that you can express yourself on TEFConnect with confidence"
            )}
          </p>
          <br />
          <h4>{t("Here’s how to control your TEFConnect experience")}</h4>
          <h6>{t("Control what you see")}</h6>
          <p>
            {" "}
            {t(
              "We make it easy for you to take action on a Post. Tap the  icon at the bottom right of any Post, right from your Home timeline, to quickly access options like unfollow, block, report, and more."
            )}
          </p>
          <br />
          <h6>{t("Remove connection")}</h6>
          <p>
            {t(
              "Removing connection is the simplest action you can take to stop seeing someone’s Post on your Home timeline."
            )}{" "}
          </p>
        </ModalBody>
        <ModalFooter className={classes.ModalFooter}>
          <Button
            className={classes.Done}
            color="secondary"
            onClick={handleSubmit}
          >
            {t("Done")}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FeedbackModal;
