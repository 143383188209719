import React, { useContext, useEffect, useState } from "react";
import Layout from "src/components/Layout/Layout";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import HubBanner from "src/modules/Alumni/AlumniHub/component/HubBanner/index";
import { Row, Col, Container } from "reactstrap";
import classes from "./Events.module.scss";
import EventCard from "src/modules/Alumni/Components/Cards/EventCard";
import { useParams } from "react-router-dom";
import { Context } from "src/store";
import { getSingleAlumniHub } from "src/requests/AlumniHubRequest";
import { getHubEventsById } from "src/modules/Alumni/AlumniHub/containers/helper/getHubEventsById";
import noEventsImage from "src/modules/Alumni/AlumniHub/assets/no-event.svg";
import Loader from "react-loader-spinner";

const HubEvents = () => {
  const { hubId } = useParams();
  const { dispatch } = useContext(Context);
  const [isLoadingHubEvent, setIsLoadingHubEvent] = useState(true);
  const [hubDetails, setHubDetails] = useState({});
  const [hubEvents, setHubEvents] = useState([]);

  const _getAlumniHubEvents = getHubEventsById(
    dispatch,
    setHubEvents,
    setIsLoadingHubEvent
  );

  useEffect(() => {
    getSingleAlumniHub(dispatch, hubId)
      .then((response) => {
        const hubDetails = response.data.data;
        setHubDetails(hubDetails);
      })
      .catch((getSingleAlumniError) => console.log(getSingleAlumniError));
    _getAlumniHubEvents(hubId);
  }, []);

  return (
    <Layout SideContent={<SidebarRight />}>
      <Row>
        <Col>
          <HubBanner
            hubBanner={{
              bannerUrl: hubDetails.bannerUrl,
              state: hubDetails.state,
            }}
          />
        </Col>
      </Row>
      <Container>
        <Row className="mb-5">
          <Col>
            <div className={classes["event-box__wrapper"]}>
              <div className={classes["event-box__title"]}>
                <h6 className="fs-16 fw-600 mb-3 border-bottom pb-2">
                  {hubDetails.state} Alumni Hub - Upcoming Events
                </h6>
              </div>
              {isLoadingHubEvent ? (
                <div className="w-100 text-center">
                  <Loader type="Oval" color="red" height={50} width={50} />
                </div>
              ) : (
                <>
                  <div className="alumni-event-list">
                    {hubEvents.length > 0 &&
                      hubEvents.map((event) => (
                        <EventCard key={event.id} alumniEvent={event} />
                      ))}
                  </div>
                  {hubEvents.length === 0 && (
                    <div className="d-flex align-items-center flex-column justify-content-center py-5">
                      <img
                        src={noEventsImage}
                        width="70"
                        alt="no events image"
                      />
                      <h5 className="mt-2">
                        No events in this hub at the moment
                      </h5>
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default HubEvents;
