import React, { useCallback, useState, useEffect } from "react";
import classes from "./CreatePostForm.module.scss";
import { Button } from "reactstrap";
import { useDropzone } from "react-dropzone";
import ImageSelectModal from "./ImageSelectModal";
import { isAllowedFiles } from "src/requests/fileUploadRequest";
import { uniqBy } from "lodash";
import { FileViewer } from "src/components/FileViewer/FileViewer";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { updateQueryStringParameter } from "src/utils/helper";

const CreatePostImage = ({ files, setFiles, switchPost }) => {
  const [openImageSelector, setOpeneImageSelector] = useState(false);
  const _onRemoveFile = (file) => {
    const existingFiles = [...files];
    const filtered = existingFiles.filter((f) => {
      return f.name !== file;
    });
    setFiles(filtered);
  };

  const location = useLocation();
  const [urlFiles, setUrlFiles] = useState([]);
  const { images } = queryString.parse(location.search);

  const _onRemoveUrlImages = (url) => {
    const urls = [...urlFiles];
    const filtered = urls.filter((uri) => {
      return url === uri;
    });
    setUrlFiles(filtered);

    const updatedUrl = updateQueryStringParameter(
      location.pathname + location.search,
      "images",
      JSON.stringify(filtered)
    );

    history.push(updatedUrl);
  };

  useEffect(() => {
    if (images) {
      const parsed = JSON.parse(images);
      if (Array.isArray(parsed)) {
        if (parsed.length > 0) {
          setUrlFiles([...parsed]);
        }
      } else {
        setUrlFiles([parsed]);
      }
    }
  }, [images]);

  const onDrop = useCallback((acceptedFiles) => {
    const existingFiles = [...files];
    const validefiles = isAllowedFiles(Array.from([...acceptedFiles]), true);
    if (validefiles.hasError) {
      validefiles.errors.forEach((file) => {
        console.log(file.name);
      });
    } else {
      existingFiles.push(...acceptedFiles);
      const uniqueFile = uniqBy(existingFiles, "name");
      setFiles(uniqueFile);
      switchPost();
    }
  }, []);

  const { getRootProps, isDragActive } = useDropzone({ onDrop });
  const imageExplorer = () => {
    document.getElementById("img_upload_div").click();
  };

  const _handleFileChange = (e) => {
    const existingFiles = [...files];
    const validefiles = isAllowedFiles(Array.from([...e.target.files]), true);
    if (validefiles.hasError) {
      validefiles.errors.forEach((file) => {
        console.log(file.name);
      });
    } else {
      existingFiles.push(...e.target.files);
      const uniqueFile = uniqBy(existingFiles, "name");
      setFiles(uniqueFile);
    }
  };

  const _toggleImageSelector = () => {
    setOpeneImageSelector(!openImageSelector);
  };

  // const clearQueryUrlParam = () => {
  //   if (shareInPost) {
  //     let url = window.location.pathname;
  //     url = updateQueryStringParameter(url, "shareInPost", false);
  //     url = updateQueryStringParameter(url, "images", JSON.stringify([]));
  //     history.push(url);
  //     // return;
  //   }
  // };

  return (
    <>
      <div className={classes.container_dd} {...getRootProps()}>
        {files.length === 0 && (
          <div
            className={`${classes.dropArea} ${
              isDragActive ? classes.activeDrag : ""
            }`}
            onClick={_toggleImageSelector}
          >
            <span>Drag & drop here</span>
            <span>or</span>
            <span>
              Select Media to share{" "}
              <span className={classes.format}>(Mp4, wmv, gif, jpg, png)</span>
            </span>
          </div>
        )}

        {urlFiles.length > 0 && (
          <FileViewer
            write
            showAddMore={false}
            files={urlFiles}
            onFileRemoveClick={_onRemoveUrlImages}
          />
        )}

        <FileViewer
          write
          files={files}
          onFileRemoveClick={_onRemoveFile}
          // showAddMore={false}
          onAddImageClick={_toggleImageSelector}
        />

        <br />
        <hr />
        <input
          type="file"
          hidden
          name="img"
          accept="image/*"
          multiple
          id="img_upload_div"
          onChange={_handleFileChange}
        />

        <Button className={classes.p_i_btn} onClick={switchPost}>
          Done
        </Button>
      </div>
      <ImageSelectModal
        isOpen={openImageSelector}
        triggerExplorer={imageExplorer}
        toggle={_toggleImageSelector}
        files={files}
        onRemoveFile={_onRemoveFile}
        setFiles={setFiles}
        switchPost={switchPost}
      />
    </>
  );
};

export default CreatePostImage;
