import React, { useEffect, useContext, useState } from "react";
import { selectColor } from "src/modules/Alumni/AlumniHub/helper/selectColor";
import Participant from "src/modules/Alumni/Chat/Components/Participant";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ListGroup,
  Input,
  Spinner,
  Button,
} from "reactstrap";
import Swal from "sweetalert2";
import { getUserHub, getAlumniHubUsers } from "src/requests/AlumniHubRequest";
import { joinUserToGroup } from "src/requests/AlumniChatRequest";
import { Context } from "src/store";

const AdUserToGroup = ({
  isAddUserToGroupModalOpen,
  toggleAddUserToGroupModalOpen,
  selectedGroupId,
  refreshPage,
  className,
  groupUsers,
  isMobile,
}) => {
  const [alumniHubs, setAlumniHubs] = useState([]);
  const [, setLoadingHubs] = useState(false);
  const [loadingHubUsers, setLoadingHubUsers] = useState(false);
  const [alumniHubUsers, setAlumniHubUsers] = useState([]);
  const [selectedHubId, setSelectedHubId] = useState("");
  // const [selectedGroupId, setSelectedGroupId] = useState(selectedGroupId)
  const { dispatch } = useContext(Context);
  const [userIdCollections, setUserIdCollection] = useState([]);

  const addToList = (id) => {
    if (!userIdCollections.includes(id)) {
      const newIds = [...userIdCollections, id];
      setUserIdCollection(newIds);
    }
  };

  const removeFromList = (id) => {
    if (userIdCollections.includes(id)) {
      const newIds = userIdCollections.filter((idx) => idx !== id);
      setUserIdCollection(newIds);
    }
  };

  useEffect(() => {
    _getAlumniHubs();
  }, [selectedGroupId]);
  const _getAlumniHubs = () => {
    setLoadingHubs(true);
    getUserHub(dispatch)
      .then((res) => {
        const {
          data: { data },
        } = res.data;

        setLoadingHubs(false);

        setAlumniHubs(data);
      })
      .catch(() => {
        setLoadingHubs(false);
      });
  };

  useEffect(() => {
    _getAlumniHubUsers();
  }, [selectedHubId]);

  const _getAlumniHubUsers = () => {
    if (selectedHubId) {
      setLoadingHubUsers(true);
      getAlumniHubUsers(dispatch, selectedHubId)
        .then((res) => {
          const {
            data: { data },
          } = res.data;

          const removeExistingGroupUser = data.filter(
            (item) =>
              !groupUsers.map((user) => user.userId).includes(item.userId)
          );
          setAlumniHubUsers(removeExistingGroupUser);
          setLoadingHubUsers(false);
        })
        .finally(() => {
          setLoadingHubUsers(false);
        });
    }
  };

  const hubHandler = (e) => {
    setSelectedHubId(e.target.value);
  };

  const joinUsersToGroup = () => {
    Swal.fire({
      title: "Add user to group?",

      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#da291c",
      cancelButtonColor: "#eddcd2",
      confirmButtonText: "Approve",
      // iconHtml: "&#128683;",
      customClass: {
        icon: "swal-icon",
        confirmButton: "swal-confirm",
        cancelButton: "swal-cancel",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const param = {
          groupId: selectedGroupId,
          userIds: userIdCollections,
        };
        joinUserToGroup(param).then((res) => {
          Swal.fire("Joined", "Users joined to the group", "success");
          setAlumniHubUsers([]);
          refreshPage();
        });
      }
    });
  };

  const colorPicker = selectColor();
  return (
    <div className={`${isMobile}`}>
      <Modal
        isOpen={isAddUserToGroupModalOpen}
        toggle={toggleAddUserToGroupModalOpen}
        className={`modal-container ${isMobile}`}
        centered={true}
      >
        <ModalHeader toggle={toggleAddUserToGroupModalOpen}>
          <Button
            color="secondary"
            style={{ fontSize: 13, float: "right" }}
            onClick={joinUsersToGroup}
          >
            Add Participant
          </Button>
        </ModalHeader>
        <ModalBody>
          <div className="mb-4">
            <Input
              type="select"
              style={{
                border: "none",
                borderRadius: 0,
              }}
              name="alumniHub"
              onChange={hubHandler}
              id="filterUpdate"
              className="status-search"
            >
              <option value="">Select Hub</option>
              {alumniHubs.length > 0 &&
                alumniHubs.map((item, idx) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </Input>
          </div>

          <ListGroup flush>
            {loadingHubUsers ? (
              <div style={{ marginLeft: "auto", width: "50%" }}>
                <Spinner />
              </div>
            ) : (
              alumniHubUsers.length > 0 &&
              alumniHubUsers.map((userHub, idx) => (
                <Participant
                  colorPicker={colorPicker}
                  userInfo={userHub.user}
                  addToList={addToList}
                  removeFromList={removeFromList}
                  key={userHub.id}
                  idx={idx}
                />
              ))
            )}
          </ListGroup>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AdUserToGroup;
