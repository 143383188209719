import React, { useContext, useEffect, useState } from "react";
import "./_homepage.scss";
import { MainContent } from "src/modules/training/components/applicant/MainContent/MainContent";
import { Context } from "src/store";
import { useParams } from "react-router-dom";
import { getQAPost, getUserCourseModules } from "src/requests/TrainingRequest";
import { getLanguage } from "src/utils/helper";
import { Banner } from "../../components/applicant/Banner/Banner";

const HomePageContainer = (props) => {
  const { state, dispatch } = useContext(Context);
  const [courseProgress, setCourseProgress] = useState(0);
  const lang = getLanguage() || "en";

  const { eventId, courseId, programmeId } = useParams();

  useEffect(() => {
    _getTrainingModules();
    _getQAPost();
  }, [lang]);

  const _getTrainingModules = () => {
    getUserCourseModules(dispatch, { trainingEventId: eventId, courseId });
  };

  const _getQAPost = () => {
    getQAPost(dispatch, eventId, { pageNumber: 1 }, courseId, true);
  };

  const _setCourseProgress = (value) => {
    setCourseProgress(value);
  };

  return (
    <div className="home-wrapper">
      <div>
        <div style={{ display: "flex" }}>
          <h1
            className="primary-heading"
            style={{ alignSelf: "center", marginTop: "7px" }}
          >
            TEF Training
          </h1>
          <span
            className="primary-heading"
            style={{
              padding: "5px 10px",
              backgroundColor: "#da291c",
              borderRadius: "4px",
              color: "white",
              alignSelf: "center",
              cursor: "pointer",
            }}
            onClick={() => props.history.goBack()}
          >
            Go back
          </span>
          <span
            className="primary-heading"
            style={{
              padding: "5px 10px",
              backgroundColor: "#da291c",
              borderRadius: "4px",
              color: "white",
              alignSelf: "center",
              cursor: "pointer",
            }}
            onClick={() =>
              props.history.push(
                `/training/studygroups?course=${courseId}&cat=${state.Training?.userCourseModules?.segment}&pid=${programmeId}&event=${eventId}`
              )
            }
          >
            View study groups
          </span>
        </div>

        <Banner {...props} setCourseProgress={_setCourseProgress} />
        <MainContent {...props} courseProgress={courseProgress} />
      </div>
    </div>
  );
};

export default HomePageContainer;
