import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import { FaCaretRight } from "react-icons/fa";

const EventSubCard = ({
  key,
  item: { bannerUrl, title, isOnline, startDate, id },
}) => {
  const history = useHistory();
  const humanDate = (date) => {
    return moment(date).format("DD MMMM, YYYY ha");
  };
  return (
    <div
      className="event-box"
      onClick={() => history.push(`/alumni-events-detail/${id}`)}
    >
      <div className="media media-cd">
        <img className="event-box__photo" src={bannerUrl} alt="user" />
      </div>
      <div className="event-box__detail-box">
        <h6 className="event-box__title">{title}</h6>
        <p className="event-box__location">
          {isOnline ? "Online(Virtual Event)" : "Offline"}
        </p>
        <p className="event-box__time">{humanDate(startDate)}</p>
      </div>
      <span className="view-alumni">View</span>
      <span className="view-alumni-arr">
        <BsChevronRight size={19} />
      </span>
    </div>
  );
};

export const ResourceSubCard = ({
  item: { title, resourceCategory, description },
}) => {
  const history = useHistory();
  return (
    <p
      className="resource__title"
      onClick={() => history.push("/alumni/resources")}
    >
      <span>
        <FaCaretRight color="#d42316" size={13} />
      </span>
      <span className="resoure-text-box">
        <span className="resource-text">{resourceCategory}</span>
        <span className="resource__description">{title}</span>
      </span>
      <span className="view-alumni-arr">
        <BsChevronRight size={19} />
      </span>
    </p>
  );
};

export const NewsSubCard = ({ item: { fileUrl, title, createdOn, id } }) => {
  const history = useHistory();
  return (
    <div
      className="event-box"
      onClick={() => history.push(`alumni/resources/news-view/${id}`)}
    >
      <div className="media media-cd">
        <img className="event-box__photo" src={fileUrl} alt="user" />
      </div>
      <div className="event-box__detail-box">
        <h6 className="event-box__title mb-2">{title}</h6>
        <p className="event-box__location">
          {moment(createdOn).format("DD MMMM YYYY")}
        </p>
      </div>
    </div>
  );
};

export default EventSubCard;
