import React from "react";
import { Button } from "reactstrap";
import classes from "./index.scss";

const PrimaryButton = ({
  buttonText,
  buttonAction,
  style,
  children,
  beforeIcon,
  type,
  disabled,
}) => {
  return (
    <Button
      style={style}
      disabled={disabled}
      className={classes.PrimaryButton}
      onClick={buttonAction}
      type={type}
    >
      {beforeIcon} {buttonText}
      {children}
    </Button>
  );
};

export default PrimaryButton;
