import React, { useState } from "react";
import { Col, Row, Button } from "reactstrap";
import bg from "src/assets/img/application/screen-2.jpg";
import "../ScreenThree/ScreenThree.scss";
import { FaArrowRight } from "react-icons/fa";
import TCMainIndex from "src/modules/Application/components/TCmodal/TCMainIndex";
import { useTranslation } from "react-i18next";
const ScreenTwo = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);
  const toggle = () => setShow(!show);

  return (
    <Col md={{ size: 6 }}>
      <Row
        style={{
          backgroundColor: "white",
          borderRadius: 5,
          textAlign: "center",
          padding: "50px 0px 0px 0px",
        }}
      >
        <Col md={12}>
          <div className="tour-menu-title">
            <span style={{ color: "#26292d" }}>
              {t("Empowering African Entrepreneurs")}
            </span>
          </div>
        </Col>
        <Col md={12}>
          <img className="tourImg" src={bg} alt="Tour" />
        </Col>
        <Col>
          <div className="tourBottom">
            <Row>
              <Col md={12} style={{ marginTop: "-1rem" }}>
                {t(
                  "Looking to get funding? The TEF Foundation will help you reach new heights"
                )}
              </Col>
            </Row>
            <Row className=" bottomFooter" style={{ textAlign: "center" }}>
              <Col
                onClick={props.onPreviousClick}
                className="back backLink"
                style={{
                  display: "none",
                  marginTop: "20px",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
                md={3}
              >
                {t("Go back")}
              </Col>
              <Col md={9}>
                <Button
                  color=""
                  style={{ backgroundColor: "white", color: "#26292" }}
                  onClick={props.onNextClick}
                >
                  {t("Next")}
                  {"  "}
                  <span style={{ paddingLeft: "5px", opacity: "0.5" }}>
                    <FaArrowRight />
                  </span>
                </Button>
              </Col>
              <Col
                className="skip"
                md={3}
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                <div onClick={props.onSkipClick} className="skipLink">
                  {t("skip-tour")}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <TCMainIndex show={show} toggle={toggle} />
    </Col>
  );
};

export default ScreenTwo;
