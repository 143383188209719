import React from "react";
import { Col, Row, Container, NavLink, Button } from "reactstrap";
import "./Tour.scss";
import PhoneImg from "src/assets/img/tour/phone-copy.png";
import Layout from "src/components/Layout/Layout";
import { FaArrowRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Tour = (props) => {
  const { t } = useTranslation();
  return (
    <Layout hideRight hideLeft>
      <br />
      <br />
      <br />
      <Container
        style={{
          display: "flex",
          margin: "auto",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          justifyItems: "center",
        }}
      >
        <Row
          style={{
            backgroundColor: "white",
            borderRadius: 5,
            textAlign: "center",
            padding: "50px 0px 0px 0px",
          }}
        >
          <Col md={12}>
            <div className="tour-menu-title" style={{ color: "#da291c" }}>
              <span>
                {t("super")}{" "}
                <span style={{ color: "#26292d" }}>{t("easy")}</span>
              </span>
            </div>
            <div className="tour-menu-title">
              <span style={{ color: "#26292d" }}>
                {t("application-process")}
              </span>
            </div>
          </Col>
          <Col md={12}>
            <img className="tourImg" src={PhoneImg} alt="Phone Image" />
          </Col>
          <Col>
            <Container className="tourBottom">
              <Row>
                <Col md={12} style={{ marginTop: "-1rem" }}>
                  {t("tourCompleteApplication")}
                </Col>
              </Row>
              <Row className=" bottomFooter" style={{ textAlign: "center" }}>
                <Col
                  onClick={props.back}
                  className="back backLink"
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    cursor: "pointer",
                  }}
                  md={3}
                >
                  {t("Go back")}
                </Col>
                <Col md={6}>
                  <Button
                    color=""
                    style={{ backgroundColor: "white", color: "#26292" }}
                    onClick={props.handleClick}
                  >
                    {t("Next")}
                    {"  "}
                    <span style={{ paddingLeft: "5px", opacity: "0.5" }}>
                      <FaArrowRight />
                    </span>
                  </Button>
                </Col>
                <Col
                  className="skip"
                  md={3}
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                  <NavLink to="/profile" className="skipLink">
                    {t("skip-tour")}
                  </NavLink>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

Tour.propTypes = {};

export default Tour;
