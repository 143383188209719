/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import ScreenOne from "./screenOne/screenOne";
import ScreenTwo from "./screen2/ScreenTwo";
import ScreenThree from "./ScreenThree/ScreenThree";
import Cloader from "src/modules/Application/components/CLoader";
import queryString from "query-string";
import { personalInfo } from "src/modules/Application/Offcycle/personalInfo/personanlInfoObj";
import {
  retrieveTempUserApplication,
  storeTempUserApplication,
  getUserCurrentApplication,
  _setSteps,
} from "src/modules/Application/helper";
import "./index.scss";
import { useChangeLanguage } from "src/utils/translation";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { getCountryAlpha2Code } from "src/utils/helper";
import { formatMaxBirthYear } from "src/modules/onboarding/stepTwo/Sections";
const setExistingInfo = (info, profile) => {
  info = JSON.parse(JSON.stringify(info));
  const profileInfo = [
    "gender",
    "dateOfBirth",
    "yearsOfExperience",
    "state",
    "city",
    "address",
    "imageUrl",
    "firstName",
    "lastName",
    "email",
    "levelOfEducation",
  ];

  // userInfo.forEach((uInfo) => {
  //   if (!info[uInfo]) {
  //     info[uInfo] = user[uInfo];
  //   }
  // });

  profileInfo.forEach((pInfo) => {
    if (!info[pInfo]) {
      info[pInfo] = profile[pInfo];
    }
  });

  if (!info.phoneNumber) {
    info.phoneNumber = profile.phone;
  }

  if (!info.dateOfBirth) {
    info.dateOfBirth =
      new Date(profile.dateOfBirth) === "Invalid Date" // ||
        ? new Date(formatMaxBirthYear(18))
        : profile.dateOfBirth;
  }

  if (!info.countryId) {
    info.countryId = getCountryAlpha2Code(profile.country);
  }

  return info;
};

const OffcycleOnboardingSteps = (props) => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(personalInfo);
  const { changeLanguage } = useChangeLanguage();
  const [selectedLang, setSetSelctedLang] = useState(null);
  const currentUser = useCurrentUser();

  const { applicationId, endDate, lang, title } = queryString.parse(
    props.history.location.search
  );

  const _onNextStep = () => {
    if (!info.skipTour) {
      return setStep(step + 1);
    }
    _onSkipClick();
  };

  const _previousStep = () => {
    if (step <= 1) return;
    setStep(step - 1);
  };

  const _onSkipClick = () => {
    const appInfo = JSON.parse(JSON.stringify(info));
    appInfo.skipTour = true;
    const currentStep = window.location.pathname + window.location.search;
    let userInfo = _setSteps(
      appInfo,
      currentStep,
      `/application/offcycle/personalinfo/bio?applicationId=${applicationId}&endDate=${endDate}&lang=${appInfo.languageId}&title=${title}`,
      ""
    );
    // applicationId is misnamed - this is really programmeEventId
    // TODO(TP-2083): rename to programmeEventId.
    userInfo = setExistingInfo(userInfo, currentUser);
    storeTempUserApplication(userInfo, applicationId).then((app) => {
      props.history.push(
        `/application/offcycle/personalinfo/bio?applicationId=${applicationId}&endDate=${endDate}&lang=${appInfo.languageId}&title=${title}`
      );
    });
  };

  const _onLanguageSelect = (value) => {
    changeLanguage(value);
    setInfo({ ...info, languageId: value, applicationId: applicationId });
  };
  // check if the user is already on this application

  useEffect(() => {
    setLoading(true);
    getUserCurrentApplication(
      `${process.env.REACT_APP_APPLICATION_BASE_URL}/applicant`
    ).then((userApplications) => {
      const foundApplication = userApplications.filter((app) => {
        return app.applicationId === applicationId;
      });

      if (foundApplication.length > 0) {
        const app = foundApplication[0];
        if (app.progress === 40) {
          return props.history.push({
            pathname: "/application/offcycle/tests/personality",
            search: `?applicationId=${applicationId}&endDate=${endDate}&lang=${app.languageId}&title=${title}`,
          });
        }
        if (app.progress > 40 || app.progress === 90) {
          return props.history.push({
            pathname: "/application/offcycle/declaration",
            search: `?applicationId=${applicationId}&endDate=${endDate}&lang=${app.languageId}&title=${title}`,
          });
        }
      } else {
        retrieveTempUserApplication(applicationId)
          .then((application) => {
            if (!application || application === null) {
              setInfo(personalInfo);
              setLoading(false);
              return personalInfo;
            } else {
              setInfo(application);
              setLoading(false);
              return application;
            }
          })
          .then((aplcn) => {
            const currentLocation =
              window.location.pathname + window.location.search;
            if (
              aplcn.steps.previousStep !== currentLocation &&
              aplcn.steps.nextStep !== ""
            ) {
              props.history.push(aplcn.steps.nextStep);
            }
          });
      }
    });
  }, []);

  useEffect(() => {
    const query = props.history.location.search;
    if (query) {
      const parsedQuery = queryString.parse(query);
      if (Object.keys(parsedQuery).length === 0 || !parsedQuery.applicationId) {
        props.history.replace("/programme/dashboard", {});
        props.history.go(1);
      }
    } else {
      props.history.replace("/programme/dashboard", {});
      props.history.go(1);
    }
  }, [props.history.location.search]);

  useEffect(() => {
    setSetSelctedLang(lang);
  }, [lang]);

  useEffect(() => {
    if (selectedLang) {
      setInfo({
        ...info,
        languageId: lang,
        applicationId: applicationId,
      });
    }
  }, [selectedLang]);

  const _renderSteps = () => {
    switch (step) {
      case 1:
        return (
          <ScreenOne
            onNextClick={_onNextStep}
            onPreviousClick={_previousStep}
            onLanguageSelect={_onLanguageSelect}
            info={info}
          />
        );
      case 2:
        return (
          <ScreenTwo
            onNextClick={_onNextStep}
            onPreviousClick={_previousStep}
            onSkipClick={_onSkipClick}
            info={info}
          />
        );
      case 3:
        return (
          <ScreenThree
            onNextClick={_onSkipClick}
            onPreviousClick={_previousStep}
            info={info}
          />
        );
      default:
        return (
          <ScreenOne
            onNextClick={_onNextStep}
            info={info}
            onPreviousClick={_previousStep}
            onLanguageSelect={_onLanguageSelect}
          />
        );
    }
  };
  return (
    <div className="application-onboarding-c notranslate">
      {_renderSteps()}
      <Cloader loading={loading} />
    </div>
  );
};

export default OffcycleOnboardingSteps;
// notranslate
