import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { RiArrowRightFill } from "react-icons/ri";
import Layout from "src/components/Layout/Layout";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import CustomLoader from "src/modules/Alumni/Components/CustomLoader";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import { useHistory, useParams } from "react-router-dom";
import { getBusinessReportById } from "src/requests/AlumniRequest";
import "./View.scss";

let isMounted;
const View = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { surveyId } = useParams();
  const [questionAnswer, setQuestionAnswer] = useState({});
  const history = useHistory();

  useEffect(() => {
    // set default value for FormFields
    isMounted = true;
    loadQuestionAndAnswer();

    return () => (isMounted = false);
  }, []);

  const loadQuestionAndAnswer = async () => {
    if (isMounted) setIsLoading(true);
    try {
      const res = await getBusinessReportById(surveyId);
      if (res.status === 200) {
        const { data } = res.data;
        console.log("data", data);
        setQuestionAnswer({ ...questionAnswer, ...data });
      }
      if (isMounted) setIsLoading(false);
    } catch (e) {
      if (isMounted) setIsLoading(false);
      history.push("/alumni/business-reporting");
    }
  };

  return (
    <Layout
      SideContent={<SidebarRight />}
      smallHeader={
        <div className="navigation">
          <span className="navigation__content">
            <b
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/alumni/business-reporting")}
            >
              Alumni Business Reporting
            </b>
            <RiArrowRightFill style={{ marginRight: 5 }} />
            Alumni Business Reporting
          </span>
        </div>
      }
    >
      {isLoading && <CustomLoader fullScreen={true} />}
      <div className="mt-3 pt-2" style={{ borderRadius: 5 }}>
        <Row>
          {questionAnswer.getAnswersDTO
            ? questionAnswer.getAnswersDTO.map((answer, i) => (
                <Col md={6} key={i} style={{ marginTop: 5, marginBottom: 5 }}>
                  <div className="report">
                    <h4>{answer.questionName}:</h4>
                    <span>{answer.optionName || answer.value}</span>
                  </div>
                </Col>
              ))
            : null}
        </Row>
      </div>
    </Layout>
  );
};

export default ifOnboardedThenShow(View);
