import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/reduction.scss";
import { Provider } from "./store";
import ErrorBoundary from "./utils/Services/ErrorBoundary";
import { toast } from "react-toastify";
import checkRequests from "./utils/Services/checkRequet";
import ContactUs from "./components/ContactUs/ContactUs";
import history from "./utils/history";
import { GoogleTranslateProvider } from "./utils/translation";
import { RouteSwitch } from "./RouteSwitch";

toast.configure({ hideProgressBar: true });

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const el = document.querySelector(".loader");
    if (el) {
      el.remove(); // removing the spinner element
      setLoading(false); // showing the app
    }
  }, []);

  if (loading) {
    return null;
  }

  return (
    <Provider>
      <ErrorBoundary>
        <GoogleTranslateProvider>
          <Router history={history}>
            <div className="App">
              <RouteSwitch />
              <ContactUs />
            </div>
          </Router>
        </GoogleTranslateProvider>
      </ErrorBoundary>
    </Provider>
  );
}

export default checkRequests(App);
