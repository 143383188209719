import React from "react";
import underContruction from "src/assets/img/underconstruction.png";

const Undercontruction = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        height: "100%",
        paddingTop: "20%",
      }}
    >
      <div style={{ marginTop: "calc(100vh-30px)" }}>
        <img src={underContruction} alt="construction" />
      </div>
      <p>Under Construction.</p>
    </div>
  );
};

export default Undercontruction;
