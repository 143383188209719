import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Label, Input, FormGroup, Button } from "reactstrap";
import queryString from "query-string";
import { FaSave } from "react-icons/fa";
import { Context } from "src/store";
import {
  submitDeclaration,
  getUserApplications,
  verifyResult,
  updateApplication,
} from "src/requests/ApplicationRequest";
import "./Declaration.scss";
import Cloader from "src/modules/Application/components/CLoader";
import CategoryModal from "src/modules/Application/components/CategoryModal/CategoryModal";
import { refreshToken } from "src/utils/someTokenMenthods";
import { DeclarationTranslations } from "./DeclarationTranslations";
import PreviewModal from "src/modules/Application/components/PreviewApplication/PreviewModal";
import PreviewApplication from "src/modules/Application/components/PreviewApplication/PreviewApplication";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import Layout from "src/modules/Application/components/Layout/Layout";
import { yearsDropdown } from "src/modules/Application/helper";

const Declaration = ({ props }) => {
  const query = props.history.location.search;
  const { userId } = useCurrentUser();
  const initialData = {
    hasPreviouslyApplied: false,
    yearPreviouslyApplied: "",
    hasReceivedSeedFunding: false,
    yearPreviouslyReceivedSeedFunding: "",
    hasAlumniBusinessPartners: false,
    isSanctionedRelated: false,
    hasSanctionedDealingRelated: false,
    hasSanctionedAssetInArea: false,
    isPoliticalExposed: false,
    isBusinessTerroristAccused: false,
    isBusinessFinancialAccused: false,
    isBusinessCrimeAccused: false,
    isInTefStaffOrAffiliate: false,
    isInCurrentOrPreviousTefStaffOrAffiliate: false,
  };
  const [declarationData, setDeclarationData] = useState(initialData);
  const [loader, setLoader] = useState(true);
  const [score, setScore] = useState(0);
  const { state, dispatch } = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  const { endDate } = queryString.parse(props.history.location.search);
  const [language, setLanguage] = useState("en");
  const declarationTranslations = DeclarationTranslations[language || "en"];
  const [showPreview, setShowPreview] = useState(false);
  const { applicationId, lang } = queryString.parse(query);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const { t } = useTranslation();

  const yearsDropdownStartValue = new Date().getFullYear();

  useEffect(() => {
    if (query) {
      const parsedQuery = queryString.parse(query);
      if (Object.keys(parsedQuery).length === 0 || !parsedQuery.applicationId) {
        props.history.replace("/programme/dashboard", {});
      }
    } else {
      props.history.replace("/programme/dashboard", {});
      props.history.go(1);
    }
    // setLoader(false);
  }, [props.history.location.search]);

  const user = useCurrentUser();

  useEffect(() => {
    verifyResult(dispatch, applicationId).then((res) => {
      const {
        data: {
          data: { status, score },
        },
      } = res;
      if (status === "Incompleted") {
        props.history.replace(
          `/application/tests/personality?applicationId=${applicationId}&endDate=${endDate}&lang=${
            lang || "en"
          }`,
          {}
        );
      } else {
        setScore(score);
        setLoader(false);
      }
    });
    getUserApplications(dispatch, userId);
  }, []);

  const handleChange = (e) => {
    e.persist();
    const value =
      e.target.value === "true"
        ? true
        : e.target.value === "false"
        ? false
        : e.target.value;
    setDeclarationData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: value,
      };
    });
  };

  const { userApplications } = state.Application;
  const applicant =
    userApplications &&
    userApplications.filter(
      (applicant) => applicant.applicationId === applicationId
    );

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingSubmit(true);
    const { id: applicantId } = applicant[0];

    const payload = {
      ...declarationData,
      applicantId,
    };
    submitDeclaration(dispatch, payload, applicationId).then(() => {
      setLoadingSubmit(false);
      refreshToken();
      setShowModal(!showModal);
    });
  };

  useEffect(() => {
    if (applicant && applicant.length > 0) {
      const { progress } = applicant[0];
      if (progress === 100) {
        setShowModal(true);
      }
      setLoader(false);
    }
  }, [applicant]);

  useEffect(() => {
    setLanguage(lang || "en");
    updateApplication(
      dispatch,
      { languageId: lang },
      applicationId,
      user.userId,
      false
    );
  }, [lang]);

  const _ContinueAfterScoreClick = () => {
    return props.history.replace("/home", {});
  };

  const togglePreviewModal = () => {
    setShowPreview(!showPreview);
  };

  return (
    <Layout>
      <div className="declaration-content">
        {loader ? (
          <Cloader loading={loader} />
        ) : (
          <React.Fragment>
            <h1 className="declaration-h1">{declarationTranslations.title}</h1>
            <h3 className="declaration-h3">{t("Application_Overview")}</h3>

            <Row>
              <Col style={{ marginBottom: "10px" }} md={12}>
                <span style={{ marginRight: "10px" }}>
                  <Button
                    onClick={() => props.history.replace("/home", {})}
                    className="btn-font"
                    size="small"
                  >
                    {t("Back to Home")}
                  </Button>
                </span>
                <span>
                  <Button
                    onClick={togglePreviewModal}
                    className="btn-font"
                    size="small"
                  >
                    {declarationTranslations.preview}
                  </Button>
                </span>
              </Col>
            </Row>
            <form onSubmit={handleSubmit}>
              <div className="declaration-main-content">
                <h6 className="declaration-main-content__instruction">
                  {declarationTranslations.instruction}
                </h6>
                <Row style={{ marginBottom: "30px" }}>
                  <Col md={7}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Label for="hasPreviouslyApplied">
                            {declarationTranslations.one}
                          </Label>
                          <div className="radio-toolbar">
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="true"
                                  name="hasPreviouslyApplied"
                                  required
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.yes}
                                </span>
                              </Label>
                            </FormGroup>
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="false"
                                  name="hasPreviouslyApplied"
                                  required
                                  data-testid="declaration-no"
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.no}
                                </span>
                              </Label>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <Label for="yearPreviouslyApplied">
                        {declarationTranslations.oneSub}
                      </Label>
                      <Input
                        type="select"
                        onChange={handleChange}
                        name="yearPreviouslyApplied"
                        id="yearPreviouslyApplied"
                        disabled={!declarationData.hasPreviouslyApplied}
                        required={declarationData.hasPreviouslyApplied}
                      >
                        <option value="">Select</option>

                        {yearsDropdown(2010, yearsDropdownStartValue - 1).map(
                          (item, i) => (
                            <option key={i} value={item.value}>
                              {item.label}
                            </option>
                          )
                        )}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "30px" }}>
                  <Col md={7}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Label for="hasReceivedSeedFunding">
                            {declarationTranslations.two}
                          </Label>
                          <div className="radio-toolbar">
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="true"
                                  name="hasReceivedSeedFunding"
                                  required
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.yes}
                                </span>
                              </Label>
                            </FormGroup>
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="false"
                                  name="hasReceivedSeedFunding"
                                  required
                                  data-testid="declaration-no"
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.no}
                                </span>
                              </Label>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <Label for="yearPreviouslyReceivedSeedFunding">
                        {declarationTranslations.twoSub}
                      </Label>
                      <Input
                        type="select"
                        onChange={handleChange}
                        name="yearPreviouslyReceivedSeedFunding"
                        id="yearPreviouslyReceivedSeedFunding"
                        disabled={!declarationData.hasReceivedSeedFunding}
                        required={declarationData.hasReceivedSeedFunding}
                      >
                        <option value="">Select</option>
                        {yearsDropdown(2010, yearsDropdownStartValue - 1).map(
                          (item, i) => (
                            <option key={i} value={item.value}>
                              {item.label}
                            </option>
                          )
                        )}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "30px" }}>
                  <Col md={12}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Label for="hasAlumniBusinessPartners">
                            {declarationTranslations.three}
                          </Label>
                          <div className="radio-toolbar">
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="true"
                                  name="hasAlumniBusinessPartners"
                                  required
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.yes}
                                </span>
                              </Label>
                            </FormGroup>
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="false"
                                  name="hasAlumniBusinessPartners"
                                  required
                                  data-testid="declaration-no"
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.no}
                                </span>
                              </Label>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>

                  <Col md={12}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Label for="isSanctionedRelated">
                            {declarationTranslations.four}
                          </Label>
                          <div className="radio-toolbar">
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="true"
                                  name="isSanctionedRelated"
                                  required
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.yes}
                                </span>
                              </Label>
                            </FormGroup>
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="false"
                                  name="isSanctionedRelated"
                                  required
                                  data-testid="declaration-no"
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.no}
                                </span>
                              </Label>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "30px" }}>
                  <Col md={12}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Label for="hasSanctionedDealingRelated">
                            {declarationTranslations.five}
                          </Label>
                          <div className="radio-toolbar">
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="true"
                                  name="hasSanctionedDealingRelated"
                                  required
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.yes}
                                </span>
                              </Label>
                            </FormGroup>
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="false"
                                  name="hasSanctionedDealingRelated"
                                  required
                                  data-testid="declaration-no"
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.no}
                                </span>
                              </Label>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>

                  <Col md={12}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Label for="hasSanctionedAssetInArea">
                            {declarationTranslations.six}
                          </Label>
                          <div className="radio-toolbar">
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="true"
                                  name="hasSanctionedAssetInArea"
                                  required
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.yes}
                                </span>
                              </Label>
                            </FormGroup>
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="false"
                                  name="hasSanctionedAssetInArea"
                                  required
                                  data-testid="declaration-no"
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.no}
                                </span>
                              </Label>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "30px" }}>
                  <Col md={12}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Label for="isPoliticalExposed">
                            {declarationTranslations.seven}
                          </Label>
                          <div className="radio-toolbar">
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="true"
                                  name="isPoliticalExposed"
                                  required
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.yes}
                                </span>
                              </Label>
                            </FormGroup>
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="false"
                                  name="isPoliticalExposed"
                                  required
                                  data-testid="declaration-no"
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.no}
                                </span>
                              </Label>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Label for="isBusinessTerroristAccused">
                            {declarationTranslations.thirteen}
                          </Label>
                          <div className="radio-toolbar">
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="true"
                                  name="isBusinessTerroristAccused"
                                  required
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.yes}
                                </span>
                              </Label>
                            </FormGroup>
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="false"
                                  name="isBusinessTerroristAccused"
                                  required
                                  data-testid="declaration-no"
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.no}
                                </span>
                              </Label>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "30px" }}>
                  <Col md={12}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Label for="isBusinessFinancialAccused">
                            {declarationTranslations.fourteen}
                          </Label>
                          <div className="radio-toolbar">
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="true"
                                  name="isBusinessFinancialAccused"
                                  required
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.yes}
                                </span>
                              </Label>
                            </FormGroup>
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="false"
                                  name="isBusinessFinancialAccused"
                                  required
                                  data-testid="declaration-no"
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.no}
                                </span>
                              </Label>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Label for="isBusinessCrimeAccused">
                            {declarationTranslations.fifteen}
                          </Label>
                          <div className="radio-toolbar">
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="true"
                                  name="isBusinessCrimeAccused"
                                  required
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.yes}
                                </span>
                              </Label>
                            </FormGroup>
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="false"
                                  name="isBusinessCrimeAccused"
                                  required
                                  data-testid="declaration-no"
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.no}
                                </span>
                              </Label>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "30px" }}>
                  <Col md={12}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Label for="isInTefStaffOrAffiliate">
                            {declarationTranslations.eleven}
                          </Label>
                          <div className="radio-toolbar">
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="true"
                                  name="isInTefStaffOrAffiliate"
                                  required
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.yes}
                                </span>
                              </Label>
                            </FormGroup>
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="false"
                                  name="isInTefStaffOrAffiliate"
                                  required
                                  data-testid="declaration-no"
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.no}
                                </span>
                              </Label>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Label for="isInCurrentOrPreviousTefStaffOrAffiliate">
                            {declarationTranslations.twelve}
                          </Label>
                          <div className="radio-toolbar">
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="true"
                                  name="isInCurrentOrPreviousTefStaffOrAffiliate"
                                  required
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.yes}
                                </span>
                              </Label>
                            </FormGroup>
                            <FormGroup style={{ display: "inline" }} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChange}
                                  value="false"
                                  name="isInCurrentOrPreviousTefStaffOrAffiliate"
                                  required
                                  data-testid="declaration-no"
                                />{" "}
                                <span
                                  style={{
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {declarationTranslations.no}
                                </span>
                              </Label>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <Row className="declaration-btn-holder">
                <Col sm={12} md={6} lg={6}>
                  <Button
                    onClick={togglePreviewModal}
                    className="submit-declaration-btn"
                    size="sm"
                  >
                    {declarationTranslations.preview}
                  </Button>
                  <div style={{ marginTop: "20px" }}></div>
                </Col>

                <Col sm={12} md={6} lg={6}>
                  <button
                    className="submit-declaration-btn"
                    disabled={loadingSubmit}
                    data-testid="declaration-next"
                  >
                    {" "}
                    <FaSave style={{ marginRight: "10px" }} />{" "}
                    {declarationTranslations.button}
                    {loadingSubmit && (
                      <span style={{ marginLeft: "5px" }}>
                        <Loader
                          color="white"
                          type="Oval"
                          height={15}
                          width={15}
                        />
                      </span>
                    )}
                  </button>
                  <div style={{ marginTop: "20px" }}></div>
                </Col>
              </Row>
            </form>
          </React.Fragment>
        )}
        <PreviewModal
          openPreviewModal={showPreview}
          toggle={togglePreviewModal}
        >
          <PreviewApplication
            application={applicant[0]}
            togglePreview={togglePreviewModal}
          />
        </PreviewModal>
        <CategoryModal
          levelModal={showModal}
          setLevelModal={_ContinueAfterScoreClick}
          score={score}
          onContinueClick={_ContinueAfterScoreClick}
          lang={lang}
        />
        <Cloader
          loading={loadingSubmit}
          additonalInfo={loadingSubmit ? "Submitting your application..." : ""}
        />
      </div>
    </Layout>
  );
};

export default Declaration;
