/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import { Context } from "src/store";
import { useCurrentUser } from "src/utils/Services/currentUser";
import {
  getAllProgrammeEvents,
  getProgrammeEventTypes,
} from "src/reducers/ProgrammeEventRequest";
import moment from "moment";

const stopped = "stopped";
const running = "running";
const notstarted = "notstarted";
const applicantion = "application";

export const filterEventByProgramme = (events, programmeId) => {
  return events.filter((e) => {
    return e.programmeId === programmeId;
  });
};

export const extractUserGroup = (currentUser) => {
  const userGroups = currentUser.userGroups.map((group) => {
    return group.group.id;
  });
  return userGroups;
};

export const retriveEvenType = (events) => {
  const applicationEvent = events.filter((e) =>
    e.eventType.name.toLowerCase().includes(applicantion)
  );
  return applicationEvent;
};

export const isEligible = (userGroup, eligibleGroup) => {
  return eligibleGroup.some((g) => userGroup.some((ug) => ug === g));
};

export const useProgrammeDetails = ({ programmeId, programmeTitle }) => {
  const [applicationEvent, setApplicationEvent] = useState(null);
  const [loadingApplicationEvent, setLoadingApplicationEvent] = useState(true);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(false);
  const { state, dispatch } = useContext(Context);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const { programmes } = state.Programmes;

  const user = useCurrentUser();

  const setDetails = (error, message, showModal, loading, record) => {
    setError(error);
    setMessage(message);
    setShowErrorModal(showModal);
    setLoadingApplicationEvent(loading);
    setApplicationEvent(record);
  };

  useEffect(() => {
    if (programmes && programmes.length > 0) {
      const programme = programmes.filter((prog) => prog.id === programmeId);
      const e = programme[0].programmeEvents;

      if (e.length <= 0) {
        return setDetails(
          true,
          "Application is yet to open for this programme.",
          true,
          false,
          null
        );
      }
      const appEvent = retriveEvenType(e);

      if (!appEvent || appEvent.length <= 0) {
        return setDetails(
          true,
          "Application is yet to open for this programme.",
          true,
          false,
          null
        );
      }

      // if event is found
      // check check user eligiblity

      const isUserEligible = isEligible(
        extractUserGroup(user),
        appEvent[0].userGroup
      );

      if (!isUserEligible) {
        return setDetails(
          true,
          "You are not eligible to apply for this programme",
          true,
          false,
          null
        );
      }
      // // check application status

      const status = appEvent[0].status.toLowerCase();

      if (status.replace(" ", "") === notstarted) {
        return setDetails(
          true,
          `Application has not started. Please check back later`,
          true,
          false,
          null
        );
      }

      if (status === stopped || new Date(appEvent[0].endDate) < new Date()) {
        return setDetails(
          true,
          `The ${programmeTitle} application window is closed`,
          true,
          false,
          appEvent[0]
        );
      }

      return setDetails(
        false,
        "Application is currently running",
        false,
        false,
        appEvent[0]
      );
    }
    // else {
    //   console.log("won't proceed");
    //   return setDetails(
    //     true,
    //     "Application is yet to open for this programme.",
    //     true,
    //     false,
    //     null
    //   );
    // }
  }, [programmes]);

  return {
    applicationEvent, // get event id from here lile so applicationEvent.id
    loadingApplicationEvent,
    message,
    error,
    showErrorModal,
  };
};

useProgrammeDetails.defaultProps = {
  getProgrammeEvents: true,
};
