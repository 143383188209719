import { asyncActionName } from "src/utils/AsyncUtils";
import { initialState } from "src/store";
import {
  CREATE_ARTICLES,
  GET_ALL_ARTICLES_CATEGORIES,
  GET_ALL_ARTICLES_TAGS,
  GET_ARTICLES_LIKES,
  CREATE_ARTICLE_COMMENT,
  GET_ALL_ARTICLE_COMMENTS,
} from "src/actions";

const DiscoverReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case asyncActionName(GET_ALL_ARTICLES_CATEGORIES).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_ALL_ARTICLES_CATEGORIES).success:
      return {
        ...state,
        categories: payload,
        success: true,
      };
    case asyncActionName(GET_ALL_ARTICLES_CATEGORIES).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_ALL_ARTICLES_TAGS).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_ALL_ARTICLES_TAGS).success:
      return {
        ...state,
        tags: payload,
        success: true,
      };
    case asyncActionName(GET_ALL_ARTICLES_TAGS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(CREATE_ARTICLES).loading:
      return { ...state, loading: payload };
    case asyncActionName(CREATE_ARTICLES).success:
      return {
        ...state,
        group: payload,
        success: true,
      };
    case asyncActionName(CREATE_ARTICLES).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_ARTICLES_LIKES).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_ARTICLES_LIKES).success:
      return {
        ...state,
        likeUsers: payload,
        success: true,
      };
    case asyncActionName(GET_ARTICLES_LIKES).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(CREATE_ARTICLE_COMMENT).loading:
      return { ...state, loading: payload };
    case asyncActionName(CREATE_ARTICLE_COMMENT).success:
      return {
        ...state,
        group: payload,
        success: true,
      };
    case asyncActionName(CREATE_ARTICLE_COMMENT).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(GET_ALL_ARTICLE_COMMENTS).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_ALL_ARTICLE_COMMENTS).success:
      return {
        ...state,
        comments: payload,
        success: true,
      };
    case asyncActionName(GET_ALL_ARTICLE_COMMENTS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    default:
      return state;
  }
};
export default DiscoverReducer;
