import FormFieldModel from "src/modules/Alumni/models/FormField";

const questionModel = () => ({
  id: Math.random(),
  title: new FormFieldModel("title", true, "text"),
  isRequired: new FormFieldModel("isRequired", true, "boolean"),
  inputType: new FormFieldModel("inputType", true, "text").setValue("text"),
  options: new FormFieldModel("options", true, "array").setValue([
    { label: "Option 1", id: Math.random() },
  ]),
});

export default questionModel;
