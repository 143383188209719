import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCurrentUser } from "src/utils/Services/currentUser";
import styles from "./ProfileStatus.module.scss";

const ProfileStatus = () => {
  const user = useCurrentUser();
  const [key, setKey] = useState("");

  const fieldsToCheck = [
    "dateOfBirth",
    "phone",
    "country",
    "state",
    "city",
    "address",
    "maritalStatus",
    "levelOfEducation",
  ];

  const keyMapper = {
    dateOfBirth: "Date of Birth",
    phone: "Phone Number",
    country: "Country",
    state: "State of Origin",
    city: "City",
    address: "Residential Address",
    maritalStatus: "Marital Status",
    levelOfEducation: "Level of Education",
  };
  const getFirstEmptyField = () => {
    for (const key of fieldsToCheck) {
      if (!user[key]) {
        setKey(key);
        break;
      }
    }
  };

  useEffect(() => {
    getFirstEmptyField();
  }, []);

  return (
    <div className={styles.container}>
      {key && (
        <div className={styles.statusCard}>
          <p>
            Members with {keyMapper[key]} in their profile get 3 times more
            profile views
          </p>
          <Link to={`/profile/${user.userId}?target_prop=${key}`}>
            Add your {keyMapper[key]}
          </Link>
        </div>
      )}
    </div>
  );
};

export default ProfileStatus;
