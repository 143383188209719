import React, { useContext, useState, useEffect } from "react";
import classes from "./ViewComment.module.css";
import Avatar from "src/components/Avatar/Avatar";
// import { selectColor } from "src/modules/Alumni/Components/helper/selectColor";

import { Context } from "src/store";
import { EditorContentDisplay } from "src/components/Editor";
import UserReact from "./UserReact";
import ReplyComment from "./ReplyComment";
import { postReply } from "src/requests/OpportunityRequest";
import { getReply } from "src/requests/AlumniOpportunityRequest";
import { getUserProfile } from "src/requests/AlumniChatRequest";

const CommentDetail = ({
  comment,
  opportunityId,
  getComments,
  discussions,
  deleteUserComment,
  isLoadingLike,
  handleLikeLoader,
  idx,
}) => {
  const [, setFetchingProfile] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  // const colorPicker = selectColor();

  useEffect(() => {
    _getUserProfile(comment.createdBy);
  }, []);

  const _getUserProfile = (userId) => {
    setFetchingProfile(true);
    getUserProfile(userId).then((res) => {
      const {
        data: { data },
      } = res;
      setFetchingProfile(false);
      setUserDetails(data);
    });
  };
  const [showReplyButton, setShowReply] = useState(false);
  const [postingReply, setPostingReply] = useState(false);
  const [replyData, setReplyData] = useState("");
  const [replies, setReplies] = useState("");
  const [loadingReplies, setLoadingReplies] = useState(false);
  const { dispatch } = useContext(Context);

  const inputHandler = (e) => {
    const { value } = e.target;

    setReplyData(value);
  };
  const submitReply = (e) => {
    e.preventDefault();
    const val = e.target.dataset.commentid;

    const payload = {
      opportunityId: opportunityId,
      commentId: val,
      response: replyData,
    };

    setPostingReply(true);
    postReply(dispatch, payload).then((res) => {
      setPostingReply(false);
      setReplyData("");

      getReply(dispatch, { discussionId: val, id: opportunityId }).then(
        (res) => {
          const { data } = res.data;

          setLoadingReplies(false);
          document.querySelector(
            `.countPlaceholder-${val}`
          ).innerHTML = data.length ? `${data.length} reply` : "reply";
          setReplies(data);
        }
      );
    });
  };

  const toggleReply = (e) => {
    setLoadingReplies(true);

    const val = e.currentTarget.dataset.replyid;
    const payload = { discussionId: val, id: opportunityId };

    getReply(dispatch, payload).then((res) => {
      const { data } = res.data;
      setLoadingReplies(false);
      document.querySelector(`.countPlaceholder-${val}`).innerHTML = data.length
        ? `${data.length} reply`
        : "reply";
      setReplies(data);
    });

    const elements = document.querySelector(`[data-formid=form-${val}]`);

    if (elements.classList.contains("showReplyButton")) {
      elements.classList.remove("showReplyButton");
    } else {
      elements.classList.add("showReplyButton");
    }
    setShowReply((prev) => !prev);
  };
  return (
    <>
      <div className={classes.UserInfo}>
        <div className={classes.UserImage}>
          {userDetails ? (
            <Avatar
              src={userDetails.imageUrl}
              dimension="30px"
              name={`${userDetails?.firstName} ${userDetails?.lastName}`}
            />
          ) : (
            <div
              className="group-no-img"
              // style={{
              //   backgroundColor: colorPicker(idx).background,
              //   color: colorPicker(idx).color,
              // }}
            >
              <span>{`${userDetails?.firstName?.charCode(
                0
              )}${userDetails?.lastName?.charCode(0)}`}</span>
            </div>
          )}
        </div>
        <div className={classes.Comment}>
          <div className={classes.UserDetails}>
            <div className={classes.UserName}>{`${
              userDetails && userDetails.firstName
            } ${userDetails && userDetails.lastName}`}</div>
          </div>
          <div className={classes.CommentBody}>
            <EditorContentDisplay content={comment.message} readOnly />
          </div>

          <UserReact
            userReact={comment}
            toggleReply={toggleReply}
            key={comment.id}
            idx={comment.id}
            replies={replies}
            getComments={getComments}
            handleLikeLoader={handleLikeLoader}
            isLoadingLike={isLoadingLike}
            deleteUserComment={deleteUserComment}
            opportunityId={opportunityId}
          />
          <ReplyComment
            showReplyButton={showReplyButton}
            idx={comment.id}
            submitHandler={submitReply}
            inputHandler={inputHandler}
            commentData={replyData}
            postingReply={postingReply}
            loadingReplies={loadingReplies}
            replies={replies}
          />
        </div>
      </div>
    </>
  );
};

export default CommentDetail;
