import React, { useState } from "react";
import { Progress, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "src/components/Layout/Layout";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import InputField from "src/modules/Alumni/Components/InputField/index";
import Letter from "./components/Letter/index";
import FixedAlertBox from "./components/FixedAlertBox/index";
import SecondaryButton from "src/modules/Alumni/Components/SecondaryButton/index";
import PrimaryButton from "src/modules/Alumni/Components/PrimaryButton/index";
import CustomLoader from "src/modules/Alumni/Components/CustomLoader/index";
import { createRecommendationLetter } from "src/requests/AlumniRequest";
import "./index.scss";

const COUNTRIES = require("../assets/countries");

const _fields = () => ({
  firstName: "",
  lastName: "",
  nationality: "",
  gender: "",
  passportNo: "",
  issueDate: "",
  expiryDate: "",
  mailingAddress: "",
  templateUrl: "__",
});

const RecommendationLetter = () => {
  const history = useHistory();
  const PROGRESS_FIGURE = 16.67;
  const [progress, setProgress] = useState(0);
  const [fields, setFields] = useState(_fields());
  const [error, setError] = useState({
    invalid: false,
    message: "",
  });
  const [letterType, setLetterType] = useState("visa_letter_request");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const { dispatch } = useContext(Context);

  const updateError = (state, message) =>
    setError({
      ...error,
      message: message,
      invalid: state,
    });

  const onInputChange = (e) =>
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });

  const validateFormField = () => {
    let isValid = true;
    for (let i = 0; i < Object.keys(fields).length; i++) {
      const field = fields[Object.keys(fields)[i]];
      if (field === "" && progress === i * PROGRESS_FIGURE) {
        updateError(true, "Field cannot be empty");
        isValid = false;
        break;
      }
    }

    return isValid;
  };

  const _createRecommendationLetter = async () => {
    setIsLoading(true);
    try {
      const res = await createRecommendationLetter(fields);

      if (res.status === 200 || res.status === 201) {
        setFields(_fields());
        setShowModal(true);
      } else {
        toast("Oops, we couldn't complete this request", {
          type: toast.TYPE.ERROR,
        });
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toast("Something went wrong, try again", { type: toast.TYPE.ERROR });
    }
  };

  return (
    <Layout SideContent={<SidebarRight />}>
      <div className="ml-2">
        {isLoading && <CustomLoader fullScreen={true} />}
        {showModal && (
          <FixedAlertBox
            title={"Request Completed"}
            description={
              "Your TEF Letter of Recommendation Request has been completed. You will be notified via email of the approval status of your request."
            }
          >
            <PrimaryButton
              buttonText="Return To Homepage"
              buttonAction={() => history.push("/")}
            />
          </FixedAlertBox>
        )}
        <Row className="tab-heading mainContainer">
          <Col md={5} style={{ paddingLeft: 0 }}>
            <h2 className="mainContainer__title">
              Alumni Recommendation Letter
            </h2>
            <span className="mainContainer__desc">
              TefConnect is committed to sharing as many relevant opportunities
              for African entrepreneurs as much as possible. <br />
              Kindly fill the form below.
            </span>
          </Col>
          <Col md={7} style={{ paddingRight: 0 }}>
            <InputField
              title="Letter Type"
              name="letterType"
              id="letterType"
              type="select"
              options={[
                { value: "visa_letter_request", label: "Visa Letter Request" },
              ]}
              value={letterType}
              onChange={(e) => setLetterType(e.target.value)}
            />
          </Col>
        </Row>

        <div className="container-box">
          <Row>
            <Col md={5}>
              <div>
                <h6 style={{ fontWeight: 600, fontSize: 12 }}>
                  Draft Progress{" "}
                  <span className="ml-3 text-danger">
                    {Math.round(progress)}%
                  </span>
                </h6>
                <Progress value={progress} color="tef-red" />
              </div>
              <div className="mt-4">
                {progress === 0 && (
                  <InputField
                    title="What is your First Name"
                    name="firstName"
                    id="firstName"
                    autoFocus={true}
                    invalid={error.invalid}
                    value={fields.firstName}
                    placeholder="Type Your First Name"
                    onChange={onInputChange}
                  />
                )}
                {progress === PROGRESS_FIGURE * 1 && (
                  <InputField
                    title="What is your Last Name"
                    name="lastName"
                    id="lastName"
                    autoFocus={true}
                    invalid={error.invalid}
                    value={fields.lastName}
                    placeholder="Type Your Last Name"
                    onChange={onInputChange}
                  />
                )}
                {progress === PROGRESS_FIGURE * 2 && (
                  <InputField
                    title="What is your Country"
                    name="nationality"
                    id="nationality"
                    type="select"
                    invalid={error.invalid}
                    value={fields.nationality}
                    placeholder="Type Your Nationality"
                    onChange={onInputChange}
                    options={[
                      { value: "", label: "Select a Country" },
                      ...COUNTRIES.map((c) => ({
                        value: c.name,
                        label: c.name,
                      })),
                    ]}
                  />
                )}
                {progress === PROGRESS_FIGURE * 3 && (
                  <InputField
                    title="What is your Gender"
                    name="gender"
                    id="gender"
                    type="select"
                    value={fields.gender}
                    invalid={error.invalid}
                    placeholder="Type Your Gender"
                    onChange={onInputChange}
                    options={[
                      { value: "", label: "Select a Gender" },
                      { value: "male", label: "Male" },
                      { value: "female", label: "Female" },
                      { value: "other", label: "Other" },
                    ]}
                  />
                )}
                {progress === PROGRESS_FIGURE * 4 && (
                  <InputField
                    title="What is your International Passport Number"
                    name="passportNo"
                    id="passportNo"
                    autoFocus={true}
                    type="text"
                    invalid={error.invalid}
                    value={fields.passportNo}
                    placeholder="Enter Passport Number"
                    onChange={onInputChange}
                  />
                )}
                {progress === PROGRESS_FIGURE * 5 && (
                  <>
                    <InputField
                      title="What is your International Passport Issue Date"
                      name="issueDate"
                      id="issueDate"
                      autoFocus={true}
                      type="date"
                      value={fields.issueDate}
                      invalid={error.invalid}
                      placeholder="Enter Passport Issuance Date"
                      onChange={onInputChange}
                    />
                    <InputField
                      title="What is your International Passport Expiry Date"
                      name="expiryDate"
                      id="expiryDate"
                      autoFocus={true}
                      type="date"
                      invalid={error.invalid}
                      value={fields.expiryDate}
                      placeholder="Enter Passport Expiry Date"
                      onChange={onInputChange}
                    />
                  </>
                )}
                {progress === PROGRESS_FIGURE * 6 && (
                  <InputField
                    title="What is Mailing Address of Nigerian Embassy in Your Country"
                    name="mailingAddress"
                    id="mailingAddress"
                    autoFocus={true}
                    type="text"
                    value={fields.mailingAddress}
                    invalid={error.invalid}
                    placeholder="Enter Mailing Address"
                    onChange={onInputChange}
                  />
                )}
                {error.invalid === true && (
                  <span className="text-danger text-sm" style={{ fontSize: 9 }}>
                    {" "}
                    {error.message}
                  </span>
                )}
              </div>
              <Row className="mt-4 mb-2">
                <Col md={6} className="text-left">
                  {progress >= PROGRESS_FIGURE && (
                    <SecondaryButton
                      buttonText="Back"
                      buttonAction={() =>
                        setProgress(progress - PROGRESS_FIGURE)
                      }
                    />
                  )}
                </Col>
                <Col md={6} className="text-right">
                  {progress === PROGRESS_FIGURE * 6 ? (
                    <PrimaryButton
                      buttonText="Submit Request"
                      buttonAction={() => {
                        if (validateFormField() === true) {
                          updateError(false, "");
                          _createRecommendationLetter();
                        }
                      }}
                    />
                  ) : (
                    <PrimaryButton
                      buttonText="Next"
                      buttonAction={() => {
                        if (validateFormField() === true) {
                          updateError(false, "");
                          setProgress(progress + PROGRESS_FIGURE);
                        }
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <Col md={7}>
              <h6 style={{ fontWeight: 600, fontSize: 12 }}>Preview</h6>
              <Letter
                lastName={fields.lastName}
                firstName={fields.firstName}
                country={fields.nationality}
                gender={fields.gender}
                passportNum={fields.passportNo}
                expiryDate={fields.expiryDate}
                issueDate={fields.issueDate}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default ifOnboardedThenShow(RecommendationLetter);
