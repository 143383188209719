import React from "react";
import "./Article.scss";
import { useHistory } from "react-router-dom";

const ArticleDetail = ({ category, title, datePosted, articleId }) => {
  const history = useHistory();
  return (
    <div
      className="article-detail border-bottom"
      onClick={() => history.push(`/discover/article/${articleId}`)}
    >
      <div className="article-detail__details">
        <h1 className="Article-title">{title}</h1>
        <div className="d-flex article-detail__details">
          <h6 className="Article-category" style={{ verticalAlign: "inherit" }}>
            {category}
          </h6>{" "}
          <span className="tab-border mx-2"></span>
          <h6 className="Article-category">{datePosted}</h6>
        </div>
      </div>
    </div>
  );
};

export default ArticleDetail;
