export const convertTextForSharing = (text) => {
  const incomingContent = JSON.parse(text).blocks;
  const value = incomingContent
    .map((block) => (!block.text.trim() && "\n") || block.text)
    .join("\n");

  return value;
};

export const isStudyGroupAdmin = (creatorId, userId) => {
  return creatorId === userId;
};
