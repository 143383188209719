import React, { Fragment } from "react";
import { Input, Row, Col, Label, FormGroup } from "reactstrap";
import { Select } from "src/components/CustomFormField/CustomFormField";
import ImageUploader from "react-images-upload";
import { RiImage2Fill } from "react-icons/ri";
import "./AlumniEvent.scss";
import ValidateJs from "src/utils/Validations/validation_wrapper";

const CreateEventForm = ({
  eventData,
  inputHandler,
  selectHandler,
  privacyList,
  userGroupObj,
  alumniHub,
  onDrop,
  _handleValidationErrors,
}) => {
  return (
    <Fragment>
      <div className="create-tefstory-form mt-3 p-4">
        <Row form>
          <Col sm={12} md={12}>
            <FormGroup>
              <Label htmlFor="storyTitle">
                Title of the Event
                <span className="required required-tag"> - Required</span>
              </Label>
              <div className="create-input event-">
                <Input
                  type="text"
                  name="title"
                  id="eventitle"
                  placeholder="Event Title"
                  maxLength={40}
                  required
                  className="event__input"
                  onChange={inputHandler}
                  value={eventData.title}
                  onBlur={() =>
                    _handleValidationErrors(
                      "title",
                      ValidateJs("title", eventData.title)
                    )
                  }
                />
                <span className="input-maxlength count-length ">0/40</span>
              </div>
            </FormGroup>
          </Col>
          <Col sm={12} md={12}>
            <FormGroup>
              <Label className="create-event-label" htmlFor="category">
                Hub Name
                <span className="required required-tag">- Required</span>
              </Label>
              <Select
                defaultVal="Select Hub Name"
                list={alumniHub}
                value={eventData.hubId}
                id="HubId"
                name="hubId"
                className="event__input"
                onChange={selectHandler}
                required
                onBlur={() =>
                  _handleValidationErrors(
                    "hubId",
                    ValidateJs("hubId", eventData.hubId)
                  )
                }
              />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col sm={12} md={6}>
            <FormGroup>
              <Label className="create-event-label" htmlFor="privacy">
                Privacy{" "}
                <span className="required required-tag">- Required</span>
              </Label>
              <Select
                defaultVal="Select Privacy"
                list={privacyList}
                value={eventData.privacy}
                id="privacy"
                name="privacy"
                className="event__input"
                style={{ border: "none" }}
                onChange={selectHandler}
                required
                onBlur={() =>
                  _handleValidationErrors(
                    "privacy",
                    ValidateJs("privacy", eventData.privacy)
                  )
                }
              />
            </FormGroup>
          </Col>
          <Col sm={12} md={6}>
            <FormGroup>
              <Label className="create-event-label" htmlFor="category">
                User Group{" "}
                <span className="required required-tag">- Required</span>
              </Label>
              <Select
                defaultVal="Select User Group"
                list={userGroupObj}
                value={eventData.userGroup}
                id="userGroup"
                name="userGroup"
                className="event__input"
                onChange={selectHandler}
                required
                onBlur={() =>
                  _handleValidationErrors(
                    "userGroup",
                    ValidateJs("userGroup", eventData.userGroup)
                  )
                }
              />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col sm={6} md={12}>
            <FormGroup>
              <Label className="create-event-label" htmlFor="articleImage">
                Upload Event Banner
              </Label>
              <div className="event-upload-wrapper">
                <div
                  className="opp-create-form__image-c"
                  style={{ backgroundColor: "#F0F0F0" }}
                >
                  <div className="event-photo-icon-box">
                    <span className="opp-form-img-box__default-img">
                      {" "}
                      <RiImage2Fill color="#8EE67D" size={100} />
                    </span>
                  </div>
                  <ImageUploader
                    withIcon={false}
                    required
                    fileContainerStyle={{
                      background: "none",
                      margin: 0,
                      width: "100%",
                      padding: "50px",
                    }}
                    singleImage={true}
                    withLabel={true}
                    className="no-border"
                    withPreview={true}
                    labelClass="event-upload-image-label"
                    buttonClassName="upload-image-btn"
                    maxFileSize={5242880}
                    buttonText="Browse your files"
                    imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                    onChange={onDrop}
                    label="Upload your banner image"
                  />
                </div>
                <div className="fileformat">
                  Recommended Format: 5MB PNG, GIF, JPG, JPEG
                  <br />
                  Recommended Size: 544 X 166
                </div>
              </div>
              {/* <div className="opp-create-form__image-c">
                <div className="opp-form-img-box">
                  <span className="opp-form-img-box__default-img">
                    {" "}
                    <FaRegFileImage />
                  </span>
                </div>
                <ImageUploader
                  withIcon={false}
                  singleImage={true}
                  withPreview={true}
                  buttonText="Choose image"
                  imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                  onChange={onDrop}
                  label="Recommended size: 144 x 144, Format: PNG, GIF, JPG Max. size: 5MB"
                />
              </div> */}
            </FormGroup>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default CreateEventForm;
