import { asyncActionName } from "src/utils/AsyncUtils";
import { SEND_INVITE } from "src/actions";
import { initialState } from "src/store";

const InviteReducer = (state = initialState.invites, { type, payload }) => {
  switch (type) {
    case asyncActionName(SEND_INVITE).loading:
      return { ...state, loading: payload };
    case asyncActionName(SEND_INVITE).success:
      return {
        ...state,
        invite: payload.data,
      };
    case asyncActionName(SEND_INVITE).failure:
      return {
        ...state,
        error: payload,
        success: false,
      };
    default:
      break;
  }
};

export default InviteReducer;
