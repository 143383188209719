/* eslint-disable no-irregular-whitespace */

export const DeclarationTranslationsBadea = {
  en: {
    welcome:
      "Welcome to the 2022 TEF-BADEA Entrepreneurship Programme. Please complete your application with accurate information.",
    title: "Declaration",
    instruction:
      "The next set of questions are declaration statements. Please answer with Yes or No",
    one: "Have you applied for the TEFConnect Programme previously?",
    oneSub: "If yes, which year(s)?",
    two:
      "Have you received seed capital from the TEFConnect Programme in previous cycles?",
    twoSub: "If yes, which year(s)?",
    three:
      "Do you have business partners or collaborators who are Alumnus of the Programme?",
    four:
      "Are you a sanctioned person or / a business partner of / or a close relative/ or a friend of a sanctioned person or group? (Sanction Meaning: Punitive or restrictive measures imposed on a country, person or business)",
    five:
      "Do you, your business partner or your company have any direct/indirect dealings involving a sanctioned country or a sanctioned person/group?",
    six:
      "Are you a politically exposed person or a close relative/friend of a politically exposed person? (Political Exposed Person Meaning: one who has held prominent public/political office or publicly shown interest in holding prominent public/political office)",
    seven:
      "Have you or your business partner(s) ever been investigated, arrested or penalised for any crime or terrorist related charges?",
    eight: "",
    nine: "",
    ten: "",
    eleven:
      "Are you in business with any TEF staff or staff of any TEF affiliated company (TEF affiliated companies Meaning: includes any company in the Heirs Holdings investment portfolio)?",
    twelve:
      "Are you a current or previous staff of any TEF affiliated company?",
    button: "submit application",
    preview: "Preview Bio and Experience info",
    yes: "yes",
    no: "no",
    thirteen:
      "Have you or your business partner(s) ever been investigated, arrested or penalized for any terrorist-related charges",
    fourteen:
      "Have you or your business partner(s) ever been investigated, arrested, or penalised for any financial related offence",
    fifteen:
      "Have you or your business partner(s) ever been investigated, arrested or convicted of any crime?",
    select: "Select",
  },

  fr: {
    welcome:
      "Bienvenue au Programme Entrepreneuriat TEF-BADEA 2022. Veuillez compléter votre demande avec des informations exactes",
    title: "PAGE DES DÉCLARATIONS",
    instruction:
      "La prochaine série de questions concerne les déclarations. Veuillez répondre par oui ou par non",
    one:
      "Avez-vous déjà soumis votre candidature pour le programme TEFConnect ?",
    oneSub: "Si oui, en quelle(s) année(s)",
    two:
      "Avez-vous reçu des capitaux d'amorçage du programme TEFConnect au cours des cycles précédents ?",
    twoSub: "Si oui, en quelle(s) année(s)",
    three:
      "Avez-vous des partenaires commerciaux ou des collaborateurs qui sont des anciens du programme TEFConnect?",
    four:
      "Êtes-vous une personne sanctionnée ou / un partenaire commercial d'une personne ou d'un groupe sanctionné, ou un proche parent ou un ami de cette personne ou de ce groupe ? (Signification de la sanction : Mesures punitives ou restrictives imposées à un pays, une personne ou une entreprise) ;",
    five:
      "Avez-vous, votre partenaire commercial ou votre entreprise, des relations directes/indirectes avec un pays sanctionné ou une personne/un groupe sanctionné ?",
    six:
      "Êtes-vous une personne politiquement exposée ou un proche parent ou ami d'une personne politiquement exposée (par &quot;personne politiquement exposée&quot;, on entend une personne qui a occupé une fonction publique ou politique importante ou qui a manifesté publiquement son intérêt à occuper une fonction publique ou politique importante) ?",
    seven:
      "Avez-vous, vous-même ou votre (vos) partenaire(s) commercial(s), déjà fait l'objet d'une enquête judiciaire, d'une arrestation ou d'une sanction pour un crime ou des accusations liées au terrorisme ?",
    eight: "",
    nine: "",
    ten: "",
    eleven:
      "Êtes-vous en affaires avec un membre du personnel de TEF ou du personnel d'une société affiliée à TEF (sociétés affiliées à TEF - ce qui signifie : comprend toute société du portefeuille d'investissement de Heirs Holdings) ?",
    twelve:
      "Faites-vous partie du personnel actuel ou précédent d'une société affiliée au TEF ?",
    button: "présenter une demande",
    preview: "Aperçu de la biographie et de l'expérience",
    yes: "Oui",
    no: "Non",
    thirteen:
      "Vous ou vos partenaires commerciaux avez-vous déjà fait l'objet d'une enquête, d'une arrestation ou d'une sanction pour des accusations liées au terrorisme?",
    fourteen:
      "Vous ou vos partenaires commerciaux avez-vous déjà fait l’objet d’une enquête, d’une arrestation ou d’une sanction pour une infraction financière",
    fifteen:
      "Avez-vous déjà fait l'objet d'une enquête, d'une arrestation ou d'une condamnation pour un crime, vous ou vos partenaires commerciaux?",
    select: "Sélectionner",
  },
  pt: {
    welcome:
      "Bem-vindo ao Programa de Empreendedorismo TEF-BADEA 2022. Preencha sua inscrição com informações precisas.",
    title: "Declaração no fim das questões de avaliação",
    instruction: 'As questões com respostas "Si o Não"',
    one: "Você têm postulado para o programa de TEF antigamente?",
    oneSub: "Se a resposta é si em que ano?",
    two: "Têm recebido o capital do Programa de TEF nos ciclos antigos?",
    twoSub: "Se a resposta é si em que ano?",
    three:
      "Você têm parceiros o colaboradores que são beneficiários antigos do Programa?",
    four:
      "Você é uma pessoa sancionada o tinha sancionado o seu parceiro/ um parente próximo/ o têm relações com uma pessoa o um grupo sancionado? (Sanção quer dizer: Medidas punitivas o restritiva imponha a um país, uma pessoa e um negócio)",
    five:
      "Você o seu parceiro o a sua empresa têm relações diretas e indiretas com um país sancionado,  uma pessoa sancionada o um grupo sancionado.",
    six:
      "Você é uma pessoa exposta o têm um parente o amigo que é politicamente espostas? (Politicamente exposta significa: alguém que têm ocupado um posto público importante o têm mostrado um interesse forte para alcançar a um)",
    seven:
      "Você têm parceiros sob a investigação, parceiros presos o condenados por um crime o atividades terroristicas?",
    eight: "",
    nine: "",
    ten: "",
    eleven:
      "Você está negociando com algum funcionário de TEF o um funcionário de uma empresa afiliada ao TEF?  (As empresas afilhadas ao TEF significa: compreende qualquer empresa sob o grupo Heirs Holdings)?",
    twelve:
      "Você é um funcionário actual o antigo de uma empresa afilliada ao TEF?",
    button: "enviar inscrição",
    preview: "Visualizar informações de biografia e experiência",
    yes: "Si",
    no: "Não",
    thirteen:
      "Você ou seu (s) parceiro (s) de negócios já foi investigado, preso ou penalizado por quaisquer acusações relacionadas ao terrorismo?",
    fourteen:
      "Você ou seu (s) parceiro (s) de negócios já foram investigados, presos ou penalizados por qualquer ofensa financeira relacionada?",
    fifteen:
      "Você ou seu (s) parceiro (s) de negócios já foram investigados, presos ou condenados por algum crime?",
    select: "Selecione",
  },
};

/**
 * for undp Mali, uncomment this code below
 */
export const DeclarationTranslationsUndp = {
  en: {
    welcome:
      "Welcome to the TEF-UNDP Mali Entrepreneurship Programme. Please complete your application with accurate information.",
    title: "Declaration",
    instruction:
      "The next set of questions are declaration statements. Please answer with Yes or No",
    one: "Have you applied for the TEFConnect Programme previously?",
    oneSub: "If yes, which year(s)?",
    two:
      "Have you received seed capital from the TEFConnect Programme in previous cycles?",
    twoSub: "If yes, which year(s)?",
    three:
      "Do you have business partners or collaborators who are Alumnus of the Programme?",
    four:
      "Are you a sanctioned person or / a business partner of / or a close relative/ or a friend of a sanctioned person or group? (Sanction Meaning: Punitive or restrictive measures imposed on a country, person or business)",
    five:
      "Do you, your business partner or your company have any direct/indirect dealings involving a sanctioned country or a sanctioned person/group?",
    six:
      "Are you a politically exposed person or a close relative/friend of a politically exposed person? (Political Exposed Person Meaning: one who has held prominent public/political office or publicly shown interest in holding prominent public/political office)",
    seven:
      "Have you or your business partner(s) ever been investigated, arrested or penalised for any crime or terrorist related charges?",
    eight: "",
    nine: "",
    ten: "",
    eleven:
      "Are you in business with any TEF staff or staff of any TEF affiliated company (TEF affiliated companies Meaning: includes any company in the Heirs Holdings investment portfolio)?",
    twelve:
      "Are you a current or previous staff of any TEF affiliated company?",
    button: "submit application",
    preview: "Preview Bio and Experience info",
    yes: "yes",
    no: "no",
    thirteen:
      "Have you or your business partner(s) ever been investigated, arrested or penalized for any terrorist-related charges",
    fourteen:
      "Have you or your business partner(s) ever been investigated, arrested, or penalised for any financial related offence",
    fifteen:
      "Have you or your business partner(s) ever been investigated, arrested or convicted of any crime?",
    select: "Select",
  },

  fr: {
    welcome:
      "Bienvenue au Programme TEF-PNUD pour l'entreprenariat des jeunes au Mali ! Veuillez compléter votre candidature avec des informations précises.",
    title: "PAGE DES DÉCLARATIONS",
    instruction:
      "La prochaine série de questions concerne les déclarations. Veuillez répondre par oui ou par non",
    one:
      "Avez-vous déjà soumis votre candidature pour le programme TEFConnect ?",
    oneSub: "Si oui, en quelle(s) année(s)",
    two:
      "Avez-vous reçu des capitaux d'amorçage du programme TEFConnect au cours des cycles précédents ?",
    twoSub: "Si oui, en quelle(s) année(s)",
    three:
      "Avez-vous des partenaires commerciaux ou des collaborateurs qui sont des anciens du programme TEFConnect?",
    four:
      "Êtes-vous une personne sanctionnée ou / un partenaire commercial d'une personne ou d'un groupe sanctionné, ou un proche parent ou un ami de cette personne ou de ce groupe ? (Signification de la sanction : Mesures punitives ou restrictives imposées à un pays, une personne ou une entreprise) ;",
    five:
      "Avez-vous, votre partenaire commercial ou votre entreprise, des relations directes/indirectes avec un pays sanctionné ou une personne/un groupe sanctionné ?",
    six:
      "Êtes-vous une personne politiquement exposée ou un proche parent ou ami d'une personne politiquement exposée (par &quot;personne politiquement exposée&quot;, on entend une personne qui a occupé une fonction publique ou politique importante ou qui a manifesté publiquement son intérêt à occuper une fonction publique ou politique importante) ?",
    seven:
      "Avez-vous, vous-même ou votre (vos) partenaire(s) commercial(s), déjà fait l'objet d'une enquête judiciaire, d'une arrestation ou d'une sanction pour un crime ou des accusations liées au terrorisme ?",
    eight: "",
    nine: "",
    ten: "",
    eleven:
      "Êtes-vous en affaires avec un membre du personnel de TEF ou du personnel d'une société affiliée à TEF (sociétés affiliées à TEF - ce qui signifie : comprend toute société du portefeuille d'investissement de Heirs Holdings) ?",
    twelve:
      "Faites-vous partie du personnel actuel ou précédent d'une société affiliée au TEF ?",
    button: "présenter une demande",
    preview: "Aperçu de la biographie et de l'expérience",
    yes: "Oui",
    no: "Non",
    thirteen:
      "Vous ou vos partenaires commerciaux avez-vous déjà fait l'objet d'une enquête, d'une arrestation ou d'une sanction pour des accusations liées au terrorisme?",
    fourteen:
      "Vous ou vos partenaires commerciaux avez-vous déjà fait l’objet d’une enquête, d’une arrestation ou d’une sanction pour une infraction financière",
    fifteen:
      "Avez-vous déjà fait l'objet d'une enquête, d'une arrestation ou d'une condamnation pour un crime, vous ou vos partenaires commerciaux?",
    select: "Sélectionner",
  },
  pt: {
    welcome:
      "Bem-vindo ao Programa de Empreendedorismo TEF-PNUD em Mali. Por favor, preencha seu formulário com informações precisas.",
    title: "Declaração no fim das questões de avaliação",
    instruction: 'As questões com respostas "Si o Não"',
    one: "Você têm postulado para o programa de TEF antigamente?",
    oneSub: "Se a resposta é si em que ano?",
    two: "Têm recebido o capital do Programa de TEF nos ciclos antigos?",
    twoSub: "Se a resposta é si em que ano?",
    three:
      "Você têm parceiros o colaboradores que são beneficiários antigos do Programa?",
    four:
      "Você é uma pessoa sancionada o tinha sancionado o seu parceiro/ um parente próximo/ o têm relações com uma pessoa o um grupo sancionado? (Sanção quer dizer: Medidas punitivas o restritiva imponha a um país, uma pessoa e um negócio)",
    five:
      "Você o seu parceiro o a sua empresa têm relações diretas e indiretas com um país sancionado,  uma pessoa sancionada o um grupo sancionado.",
    six:
      "Você é uma pessoa exposta o têm um parente o amigo que é politicamente espostas? (Politicamente exposta significa: alguém que têm ocupado um posto público importante o têm mostrado um interesse forte para alcançar a um)",
    seven:
      "Você têm parceiros sob a investigação, parceiros presos o condenados por um crime o atividades terroristicas?",
    eight: "",
    nine: "",
    ten: "",
    eleven:
      "Você está negociando com algum funcionário de TEF o um funcionário de uma empresa afiliada ao TEF?  (As empresas afilhadas ao TEF significa: compreende qualquer empresa sob o grupo Heirs Holdings)?",
    twelve:
      "Você é um funcionário actual o antigo de uma empresa afilliada ao TEF?",
    button: "enviar inscrição",
    preview: "Visualizar informações de biografia e experiência",
    yes: "Si",
    no: "Não",
    thirteen:
      "Você ou seu (s) parceiro (s) de negócios já foi investigado, preso ou penalizado por quaisquer acusações relacionadas ao terrorismo?",
    fourteen:
      "Você ou seu (s) parceiro (s) de negócios já foram investigados, presos ou penalizados por qualquer ofensa financeira relacionada?",
    fifteen:
      "Você ou seu (s) parceiro (s) de negócios já foram investigados, presos ou condenados por algum crime?",
    select: "Selecione",
  },
};

export const returnTranslationOption = () => {
  const title = new URLSearchParams(window.location.search).get("title");
  if (title.toLowerCase().includes("undp")) return DeclarationTranslationsUndp;
  return DeclarationTranslationsBadea;
};
