import React, { useState, useEffect, useContext } from "react";

import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "./faqmodal.css";
import { Modal } from "reactstrap";

import classes from "./FaqModal.module.css";
import { Context } from "src/store";

const FaqModal = () => {
  const [modal, setModal] = useState(false);
  const [faqs, setDmsFaqs] = useState([]);

  const { state } = useContext(Context);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    const faqs = state.Application && state.Application.FAQ;
    if (faqs) {
      setDmsFaqs(faqs);
    }
  }, [state.Application.FAQ]);

  return (
    <React.Fragment>
      <div
        className="d-flex justify-content-end mb-4"
        style={{ fontSize: "14px", color: "#DA291C", fontWeight: "700" }}
      >
        <span onClick={toggle} style={{ cursor: "pointer" }}>
          Frequency Asked Questions
          <FaLongArrowAltRight className="ml-2" />
        </span>
      </div>
      <Modal isOpen={modal} toggle={toggle} className={classes.ModalContainer}>
        <div style={{ padding: "1em", cursor: "pointer" }}>
          <span
            onClick={toggle}
            style={{ fontSize: "14px", color: "#DA291C", fontWeight: "700" }}
          >
            <FaLongArrowAltLeft className="mr-1" /> Back
          </span>
        </div>
        <div className={classes.Content}>
          <h6>Frequently Asked Questions</h6>
          <Accordion>
            {faqs.map((faq) => (
              <AccordionItem key={faq.id}>
                <AccordionItemHeading>
                  <AccordionItemButton>{faq.question}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>{faq.solution}</p>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default FaqModal;
