import React, { useState, useContext, useEffect, useRef } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { Context } from "src/store";
import styles from "./MessageDisplay.module.scss";
import { FaPaperPlane } from "react-icons/fa";
import EditorBox from "src/components/Editor/EditorBox";
import Message from "./Message/Message";
import {
  getAllMessagesFromUser,
  sendMessage,
} from "src/requests/MessageRequest";
import Loader from "src/components/Loader/Loader";
import moment from "moment";
import { toast } from "react-toastify";
import { messageURL } from "src/constants";

const MessageDisplay = ({ id, currentUserId, userToInfo }) => {
  const { dispatch } = useContext(Context);
  const [content, setContent] = useState("");
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clearContent, setClearContent] = useState(false);
  const latestChat = useRef(null);
  latestChat.current = messages;
  const payload = {
    from: "",
    isRead: false,
    message: "",
    to: "",
  };

  const toggleClearContent = () => setClearContent(!clearContent);

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(messageURL.OPEN_CONNECTION)
      .withAutomaticReconnect()
      .build();
    connection
      .start()
      .then(() => {
        connection.on("ReceiveMessage", (message) => {
          const updatedChat = [...latestChat.current];
          updatedChat.push(message);
          setMessages(updatedChat);
        });
      })
      .catch((e) => {
        toast.error('"Connection failed, try again');
      });
    return () => {
      connection.off("ReceiveMessage");
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getAllMessagesFromUser(dispatch, id).then((response) => {
      if (response.status === 200) {
        setIsLoading(false);
        setMessages(response.data.data);
      }
    });
    return () => {
      setMessages([]);
    };
  }, [id]);

  const onEditorChange = (value) => {
    setContent(value);
  };

  const onSendMessage = async () => {
    const details = {
      ...payload,
      from: currentUserId,
      isRead: false,
      message: content,
      to: userToInfo.userId,
    };

    await sendMessage(dispatch, details);
    toggleClearContent();
  };

  const messagesDetails = messages.map((message, index) => {
    const {
      createdOn,
      from: { firstName, imageUrl, lastName, userId },
      message: text,
    } = message;

    return (
      <Message
        key={index}
        dp={imageUrl}
        name={`${firstName} ${lastName}`}
        time={moment(createdOn).format("MMM Do")}
        message={text}
        userId={userId}
      />
    );
  });

  return (
    <div className={styles.MessageDisplay}>
      <div className={styles.MessageDisplay__header}>
        <h4 className={styles.MessageDisplay__name}>
          {Object.keys(userToInfo).length > 0 ? `${userToInfo.name}` : ""}
        </h4>
        {/* <FaEllipsisH /> */}
      </div>
      <div className={styles.MessageDisplay__message}>
        {isLoading ? (
          <div className={styles.MessageDisplay__loader}>
            <Loader color="danger" />
          </div>
        ) : (
          messagesDetails
        )}
      </div>
      <div className={styles.MessageDisplay__editor}>
        <EditorBox
          content={content}
          useEmoji={true}
          onEditorChange={onEditorChange}
          className={styles.MessageDisplay__editorBox}
          clearContent={clearContent}
        />
        <FaPaperPlane
          onClick={onSendMessage}
          className={styles.MessageDisplay__sendBtn}
          size={20}
        />
      </div>
    </div>
  );
};

export default MessageDisplay;
