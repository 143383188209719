import "./BusinessEdit.scss";
import React, { useState, useContext, useEffect } from "react";
import { FormField, Select } from "src/modules/onboarding/components/formField";
import { Btn } from "src/modules/profile/ProfileEdit/common";
import ValidateJs from "src/utils/Validations/validation_wrapper";
import { displayError, CastValue } from "src/modules/onboarding/helper";
import { updateUserProfile } from "src/requests/ProfileRequest";
import { Context } from "src/store";
import { industryTypes } from "src/modules/onboarding/stepTwo/industryType.js";

import "react-phone-input-2/lib/style.css";
import { getCountryAlpha2Code, getLanguage } from "src/utils/helper";
import {
  CustomCountryRegionDropdown,
  CustomCountryDropdown,
} from "src/components/CustomCountryRegionDropdown";

export const BusinessEdit = (props) => {
  const { onCancel, profile } = props;
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(props.profile);
  const [validationErrors, setValidationErrors] = useState({
    businessName: null,
    businessIndustry: null,
    businessCity: null,
    businessState: null,
    businessCountry: null,
    businessWebsite: null,
    aboutBusiness: null,
    businessAddress: null,
  });
  const language = getLanguage();
  const industryObj = industryTypes[language || "en"];

  const { dispatch } = useContext(Context);

  useEffect(() => {
    if (profile) {
      setInfo(profile);
    }
  }, [profile]);

  const onChange = (target, value) => {
    if (target === "country" || target === "businessCountry") {
      if (target === "country") {
        setInfo({ ...info, [target]: value, state: "" });
      }
      if (target === "businessCountry") {
        setInfo({ ...info, [target]: value, businessState: "" });
      }
    } else {
      setInfo({ ...info, [target]: CastValue(target, value) });
    }
  };

  const handleValidationErrorChanges = (target, value) => {
    setValidationErrors({ ...validationErrors, [target]: value });
  };

  const hasValidationError = (data) => {
    if (!data.businessWebsite) {
      delete data.businessWebsite;
    }

    let errors = {};
    const errorList = [];
    Object.keys(data).forEach((key) => {
      errors = { ...errors, [key]: ValidateJs(key, data[key]) };
    });

    Object.keys(errors).forEach((key) => {
      if (errors[key] !== null) {
        errorList.push(key);
      }
    });
    setValidationErrors(errors);

    return errorList.length > 0;
  };

  const _handleSubmit = (payload) => {
    setLoading(true);
    updateUserProfile(dispatch, payload).then(() => {
      onCancel();
      setLoading(false);
    });
  };

  const onSubmit = () => {
    const {
      businessAddress,
      businessCity,
      businessCountry,
      businessIndustry,
      businessName,
      businessWebsite,
      aboutBusiness,
      businessState,
      businessRevenue,
    } = info;
    const payload = {
      businessAddress,
      businessCity,
      businessCountry,
      businessIndustry,
      businessName,
      businessWebsite,
      aboutBusiness,
      businessState,
      businessRevenue,
    };
    if (!payload.businessWebsite || payload.businessWebsite === "") {
      payload.businessWebsite = null;
    }
    if (hasValidationError(payload)) {
      return;
    }
    return _handleSubmit(payload);
  };

  // const onCancel = () => {
  //   cancel();
  // };

  return (
    <div className="">
      <div className="edit-profile-header">
        <h1>Edit Business Detail</h1>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-6">
          <FormField
            // className="flex"
            label="Industry Category*"
            // labelInfo="Optional"
            // required
            // isEmpty={!info["businessIndustry"]}
          >
            <Select
              list={industryObj}
              value={info.businessIndustry}
              name="businessIndustry"
              onChange={onChange}
              onBlur={() =>
                handleValidationErrorChanges(
                  "businessIndustry",
                  ValidateJs("businessIndustry", info.businessIndustry)
                )
              }
              error={validationErrors.businessIndustry}
            />
            {
              validationErrors.businessIndustry && (
                <span className="displayError">
                  {displayError(validationErrors.businessIndustry)}
                </span>
              )
              // : (
              //   <span className="displayError">{displayError("")}</span>
              // )
            }
          </FormField>
        </div>

        <div className="col-md-6">
          <FormField
            // className="pad-form"
            type="text"
            label="Business Name*"
            // labelInfo="Optional"
            placeholder="Type in business name"
            name="businessName"
            onChange={onChange}
            value={info.businessName}
            // required
            // isEmpty={!info["businessName"]}
            onBlur={() =>
              handleValidationErrorChanges(
                "businessName",
                ValidateJs("businessName", info.businessName)
              )
            }
            error={validationErrors.businessName}
          />
          {
            validationErrors.businessName && (
              <div style={{ marginLeft: "4px" }} className="displayError">
                {displayError(validationErrors.businessName)}
              </div>
            )
            // : (
            //   <div className="displayError">{displayError("")}</div>
            // )
          }
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <FormField
            // className="flex"
            label=" Business Website"
            // labelInfo="Optional"
            type="text"
            placeholder="https://mybusiness-site.com"
            name="businessWebsite"
            onChange={onChange}
            value={info.businessWebsite}
            onBlur={
              info.businessWebsite && info.businessWebsite.length > 0
                ? () =>
                    handleValidationErrorChanges(
                      "businessWebsite",
                      ValidateJs("businessWebsite", info.businessWebsite)
                    )
                : () => {
                    handleValidationErrorChanges("businessWebsite", null);
                  }
              // handleValidationErrorChanges(
              //   "businessWebsite",
              //   ValidateJs("businessWebsite", info["businessWebsite"])
              // )
            }
            error={validationErrors.businessWebsite}
          />
        </div>

        <div className="col-md-12">
          <FormField
            // className="flex pad-form"
            type="number"
            label="Revenue per month (in $)"
            // labelInfo="Optional"
            placeholder="$0.00"
            name="businessRevenue"
            onChange={onChange}
            value={
              info && info.businessRevenue && info.businessRevenue.toString()
            }
          />
        </div>
      </div>
      {validationErrors.businessWebsite &&
        displayError(validationErrors.businessWebsite)}

      <FormField
        className="flex"
        type="textarea"
        label="About business*"
        // labelInfo="Optional"
        placeholder="Type in a brief overview of your business"
        name="aboutBusiness"
        onChange={onChange}
        // value={enforceWordCountValue(
        //   wordCounter(info.aboutBusiness),
        //   info.aboutBusiness,
        //   160
        // )}
        value={info.aboutBusiness}
        hasCounter
        maxLength={1000}
        onBlur={() =>
          handleValidationErrorChanges(
            "aboutBusiness",
            ValidateJs("aboutBusiness", info.aboutBusiness)
          )
        }
        error={validationErrors.aboutBusiness}
      />
      {validationErrors.aboutBusiness &&
        displayError(validationErrors.aboutBusiness)}

      <div className="row">
        <div className="col-md-6">
          <FormField
            label="Country*"
            className="flex"
            // labelInfo="Required"
            // isEmpty={!info["businessCountry"]}
          >
            <CustomCountryDropdown
              valueType="short"
              classes={`flex country-state-list ${
                validationErrors.businessCountry
                  ? "country-state-list-error-state"
                  : ""
              }`}
              value={
                info.businessCountry &&
                info.businessCountry &&
                getCountryAlpha2Code(info.businessCountry)
              }
              name="businessCountry"
              onChange={(val) => onChange("businessCountry", val)}
              onBlur={() =>
                handleValidationErrorChanges(
                  "businessCountry",
                  ValidateJs("businessCountry", info.businessCountry)
                )
              }
            />
            {validationErrors.businessCountry && (
              <div className="displayError">
                {displayError(validationErrors.businessCountry)}
              </div>
            )}
          </FormField>
        </div>
        <div className="col-md-6">
          <FormField label="State*" className="flex">
            <CustomCountryRegionDropdown
              countryValueType="short"
              defaultOptionLabel="Select please"
              country={info.businessCountry}
              classes={`flex country-state-list ${
                validationErrors.businessState
                  ? "country-state-list-error-state"
                  : ""
              }`}
              name="businessState"
              value={info.businessState}
              onChange={(val) => onChange("businessState", val)}
              onBlur={() =>
                handleValidationErrorChanges(
                  "businessState",
                  ValidateJs("businessState", info.businessState)
                )
              }
            />
            {validationErrors.businessState && (
              <div className="displayError">
                {displayError(validationErrors.businessState)}
              </div>
            )}
          </FormField>
        </div>
      </div>

      <FormField
        label="City*"
        placeholder="Please enter the city your business is located in."
        name="businessCity"
        className="flex"
        required
        onChange={onChange}
        value={info.businessCity}
        onBlur={() =>
          handleValidationErrorChanges(
            "businessCity",
            ValidateJs("businessCity", info.businessCity)
          )
        }
        error={validationErrors.businessCity}
      />
      {validationErrors.businessCity
        ? displayError(validationErrors.businessCity)
        : displayError("")}

      <FormField
        // className="flex"
        type="textarea"
        label="Business Address"
        // labelInfo="Optional"
        placeholder="Type in the full address of your business location"
        name="businessAddress"
        onChange={onChange}
        value={info.businessAddress}
        onBlur={() =>
          handleValidationErrorChanges(
            "businessAddress",
            ValidateJs("businessAddress", info.businessAddress)
          )
        }
        error={validationErrors.businessAddress}
      />
      {validationErrors.businessAddress
        ? displayError(validationErrors.businessAddress)
        : displayError("")}
      <hr />
      <div className="btn-box">
        <Btn className="btn-cancel" value="Cancel" onClick={onCancel} />
        <div style={{ flex: 1 }}></div>

        <Btn
          className="btn-update"
          value="Save"
          loading={loading}
          onClick={() => onSubmit()}
        />
        {/* </div> */}
      </div>
    </div>
  );
};
