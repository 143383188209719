import React from "react";
import { Row, Col } from "reactstrap";
import "./index.scss";

const TabHeader = ({
  onChange,
  tabs,
  currentTab,
  tabSize,
  tabClassName,
  tabStyle,
  optionClassName,
  postTabSize,
  postTabContent,
  preTabSize,
  preTabContent,
}) => (
  <Row className="tab-heading" style={{ paddingTop: 0 }}>
    {preTabContent && <Col md={preTabSize || 0}>{preTabContent}</Col>}
    <Col md={tabSize || 12} style={{ paddingLeft: 0, paddingRight: 0 }}>
      <div
        className={`discover-menu d-flex justify-content-between ${
          tabClassName || ""
        }`}
        style={tabStyle || {}}
      >
        {tabs &&
          tabs.map((tab, i) => (
            <div
              key={i}
              onClick={() => onChange(tab)}
              className={currentTab === tab ? "active" : "inactive"}
            >
              {tab}
            </div>
          ))}
      </div>
    </Col>
    {postTabContent && <Col md={postTabSize || 0}>{postTabContent}</Col>}
  </Row>
);

export default TabHeader;
