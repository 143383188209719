import React, { useCallback, useState } from "react";
import "./Search.scss";
import { FaSearch } from "react-icons/fa";
import CreateCommunity from "src/modules/Community/components/CreateCommunityGroup/CreateCommunity";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";

const Search = ({ categories }) => {
  const [searchData, setSearchData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const toggle = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(`community/groups/search?q=${searchData.toLowerCase()}`);
  };

  return (
    <div className="community-search-div">
      <h2>discover communities</h2>
      <div className="community-search-div__search">
        <form onSubmit={handleSubmit} className="search-container">
          <input
            type="text"
            placeholder="Search Groups"
            name="search"
            className="search-community"
            onChange={(e) => setSearchData(e.target.value)}
          />
          <button
            type="submit"
            disabled={searchData === ""}
            className="search-community-icon"
          >
            <FaSearch />
          </button>
        </form>

        <div>
          <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropDown}>
            <DropdownToggle caret>Manage Groups</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={toggle}>Add New</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
                <Link to="/community/groups/my-groups">My Groups</Link>
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </div>
      </div>
      <CreateCommunity
        categories={categories}
        show={showModal}
        toggle={toggle}
      />
    </div>
  );
};

export default Search;
