import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useIndustryList = (sector, lang) => {
  const { t } = useTranslation();

  const industries = [
    "Low-carbon Energy",
    "Land and Ocean Management",
    "Transportation",
    "Water and Waste Management",
    "Built Environment",
    "Other",
  ];

  const subSectior = {
    "Low-carbon Energy": ["Energy Efficiency and Storage", "Renewable Energy"],
    "Land and Ocean Management": [
      "Sustainable Agriculture and Aquaculture",
      "Sustainable Forestry",
    ],
    Transportation: ["Sustainable Transportation"],
    "Water and Waste Management": [
      "Water Management",
      "Waste Management and Circular Economy",
    ],
    "Built Environment": ["Green Buildings"],
    Other: ["Disaster Management", "Cleaner Fuels", "Eco-tourism"],
  };

  const memoized = useMemo(() => {
    return industries.map((item) => {
      return {
        value: item,
        label: t(item),
      };
    });
  }, [lang]);

  const momiozedSubSectiors = useMemo(() => {
    const sub = subSectior?.[sector];
    if (!sub) return [];
    return sub.map((item) => {
      return {
        value: item,
        label: t(item),
      };
    });
  }, [sector, lang]);
  return { industryList: memoized, subSectorList: momiozedSubSectiors };
};
