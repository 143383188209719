import React from "react";
import { Col } from "reactstrap";
import Interests from "src/modules/mentorship/components/Interests/Interests";
import InviteForm from "src/modules/homepage/components/InviteForm/InviteForm";
import "./SideRight.scss";

const SideRight = () => {
  return (
    <div className="homepage-side-right">
      <Col>
        <h6 className="sideright-heading" style={{ fontWeight: "bolder" }}>
          FOLLOW TOP INTERESTS
        </h6>
        <Interests />
      </Col>
      <Col>
        <InviteForm backgroundColor="#f9fbfd" />
        {/* <InviteForm backgroundColor="#f9fbfd" /> */}
      </Col>
    </div>
  );
};

export default SideRight;
