/* eslint-disable node/handle-callback-err */
import React, { useState, useContext } from "react";
import ImageUploader from "react-images-upload";
import _ from "lodash";
import { getName } from "country-list";
import "./CreateCommunityGroup.scss";
import FormImage from "src/assets/img/community/group.png";
import { Context } from "src/store";
import { createGroup } from "src/requests/CommunityRequest";
import Loader from "src/components/Loader/Loader";
import { isAllowedFiles, UploadFile } from "src/requests/fileUploadRequest";
import { useCurrentUser } from "src/utils/Services/currentUser";

const CreateCommunityGroup = ({ cancel, categories }) => {
  const titleLimit = 25;
  const descriptionLimit = 100;
  const { userId, email, phone, country } = useCurrentUser();
  const [groupInfo, setGroupInfo] = useState({
    name: "",
    about: "",
    privacy: "",
    categoryId: "",
    imageUrl: "",
    userId: userId,
    email: email,
    phoneNumber: phone,
    location: getName(country),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState([]);
  const { dispatch } = useContext(Context);

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setGroupInfo({
      ...groupInfo,
      [name]: value,
    });
  };

  const categoriesOptions = categories.map((category) => {
    const { id, name } = category;
    return { value: id, label: name };
  });

  const options = categoriesOptions.map((category, index) => (
    <option key={index} value={category.value}>
      {category.label}
    </option>
  ));

  const onDrop = (picture) => {
    const existingFiles = [...file];
    const validateFiles = isAllowedFiles(Array.from([...picture]));
    if (validateFiles.hasError) {
      validateFiles.errors.forEach((file) => {});
    } else {
      existingFiles.push(...picture);
      const uniqueFile = _.uniqBy(existingFiles, "name");
      setFile(uniqueFile);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (file.length > 0) {
      UploadFile(dispatch, true, file)
        .then((res) => {
          if (res && res.status === 200) {
            res.files.map((img) => (groupInfo.imageUrl = img));
            _submit(groupInfo);
          }
        })
        .catch((err) => {
          _submit(groupInfo);
        });
    } else {
      _submit(groupInfo);
    }
  };

  const _submit = (data) => {
    createGroup(dispatch, data).then((res) => {
      setIsLoading(false);
      if (res.status === 201) {
        cancel();
      }
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    cancel();
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="form-instruction">
        <img src={FormImage} alt="group" className="form-instruction__image" />
        <p className="form-instruction__instruction">
          TEF Groups are great for staying in touch with entrepreneurs with
          similar interests. Share photos and videos, have conversations, create
          innovation & more.
        </p>
      </div>
      <div className="form-div">
        <div className="community-form-div">
          <label className="community-form-div__label">
            Name of the group <span>- Required</span>
          </label>
          <div className="community-form-div__input-div">
            <input
              name="name"
              type="text"
              className="community-form-input"
              required
              onChange={handleInputChange}
              maxLength={
                groupInfo.name.replace(/ /g, "").length === titleLimit ? 0 : 100
              }
            />
            <label className="community-form-label">
              {groupInfo.name.replace(/ /g, "").length}/{titleLimit}
            </label>
          </div>
        </div>
        <div className="community-form-div">
          <label className="community-form-div__label">
            Description <span>- Required</span>
          </label>
          <div className="community-form-div__input-div">
            <textarea
              name="about"
              className="community-form-textarea"
              required
              onChange={handleInputChange}
              placeholder="Give a brief overview of what the group is about"
              maxLength={
                groupInfo.about.replace(/ /g, "").length === descriptionLimit
                  ? 0
                  : 400
              }
            ></textarea>
            <label className="community-form-label">
              {groupInfo.about.replace(/ /g, "").length}/{descriptionLimit}
            </label>
          </div>
        </div>
        <div className="community-form-div">
          <label className="community-form-div__label">
            Select privacy <span>- Required</span>
          </label>
          <div className="community-form-div__input-div">
            <select
              className="community-form-input"
              required
              name="privacy"
              onChange={handleInputChange}
            >
              <option value="" disabled selected hidden>
                Group type
              </option>
              <option value="public">Public</option>
              <option value="private">Private</option>
            </select>
          </div>
        </div>
        <div className="community-form-div">
          <label className="community-form-div__label">
            Select Category <span>- Required</span>
          </label>
          <div className="community-form-div__input-div">
            <select
              className="community-form-input"
              required
              name="categoryId"
              onChange={handleInputChange}
            >
              <option value="" disabled selected hidden>
                Select Category
              </option>
              {options}
            </select>
          </div>
        </div>
        <div className="community-form-div">
          <label className="community-form-div__label">Upload logo</label>
          <div className="community-form-div__input-div">
            <ImageUploader
              withIcon={false}
              singleImage={true}
              withPreview={true}
              buttonText="Choose image"
              imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
              onChange={onDrop}
            />
            <p style={{ margin: "15px" }}>
              Recommended size: 144 x 144 Format: PNG, GIF, JPG, JPEG
            </p>
          </div>
        </div>
      </div>
      <div className="community-buttons">
        <button
          className="community-button community-cancel-button"
          onClick={handleCancel}
        >
          cancel
        </button>
        <button
          className="community-button community-create-button"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <Loader color="light" /> : "create"}
        </button>
      </div>
    </form>
  );
};

export default CreateCommunityGroup;
