import React from "react";
import { Redirect, Switch } from "react-router-dom";
import "./styles/reduction.scss";
import ResetPasswordMessage from "./Auth/ResetPassword/ResetPasswordMessage";
import Mentorship from "./modules/mentorship/components";
import MentorshipApplication from "./modules/mentorship-application/components";
import MentorshipTC from "./modules/mentorship/components/MentorshipTC/MentorshipTC";
import ConfirmationMessage from "./Auth/ConfirmationMessage/ConfirmationMessage";
import StepOneOnboarding_v2 from "./modules/onboarding/stepOne/StepOne_v2";
import StepTwoOnboarding_v2 from "./modules/onboarding/stepTwo/StepTwo_v2";
import StepThreeOnboarding from "./modules/onboarding/stepThree/stepThree";
import TefMentorOnboarding from "./modules/onboarding/tefMentorOnboarding/mentorOnboarding";
import InvestorOnboarding from "./modules/onboarding/investorOnboarding/InvestorOnboarding";
import SuccessPageOnboarding from "./modules/onboarding/successfulOnboarding/Success";
import HomePage from "./modules/homepage/homepageFix";
import Profile from "./modules/profile/components";
import GoalDetails from "./modules/mentorship/components/GoalDetails/GoalDetails";
import PrivateGoalDetails from "./modules/profile/components/GoalDetails/GoalDetails";
import VerifyUser from "./Auth/VerifyUser/VerifyUser";
import ChangePassword from "./Auth/ChangePassword/ChangePassword";
import Connection from "./modules/Connections";
import Topics from "./modules/Topics";
import AllStars from "./modules/AllStars";
import MarketPlace from "./modules/MarketPlace";
import Event from "./modules/Event";
import Community from "./modules/Community";
import CommunityGroup from "./modules/Community/components/Group/Group";
import Discover from "./modules/Discover";
import PrivateRoute from "./PrivateRoute";
import AlumniRoute from "./AlumniRoute";
import DMSRoute from "./DmsRoute";
import { isLoggedIn } from "./utils/isLoggedIn";
import ProgramDashboard from "./modules/Program";
import FrequentlyAskedQuestions from "./modules/FAQ/faq";
import TefDMS from "./modules/Tef-Dms/index.js";

// application routes here
import Tour from "./modules/profile/components/Tour/Tour";
import Personality from "./modules/Application/components/PersonalityTest/PersonalityTest";
import Cognitive from "./modules/Application/components/CognitiveTest/CognitiveTest";
import ScreenTwo from "./modules/Application/Flagship/onboarding/screen2/ScreenTwo";
import ScreenThree from "./modules/Application/Flagship/onboarding/ScreenThree/ScreenThree";
import Layout from "./modules/Application/Layout/Layout";
import DeclarationIndex from "./modules/Application/main/DeclarationIndex";

import OnboardingIndex from "./modules/Application/main/OnboardingIndex";
import Faq from "./modules/Application/components/Faq/Faq";
import OffcycleFaq from "./modules/Application/components/Faq/OffcycleFaq";
import ApplicationBio from "./modules/Application/Flagship/personalInfo/Bio";
import ApplicationExperience from "./modules/Application/Flagship/personalInfo/experience";

import OffcycleOnboardingSteps from "./modules/Application/Offcycle/onboarding";
import ApplicationOffcycleBio from "./modules/Application/Offcycle/personalInfo/Bio";
import ApplicationOffcycleExperience from "./modules/Application/Offcycle/personalInfo/experience";
import OffCycleDeclaration from "./modules/Application/Offcycle/Declaration/Declaration";
import OffcycleCognitive from "./modules/Application/Offcycle/CognitiveTest/CognitiveTest";
// Imports LMS components
import TrainingApplicantHome from "./modules/training/applicant/home/Homepage";
import StudyGroups from "./modules/training/StudyGroups";
import StudyGroup from "./modules/training/StudyGroups/components/Group/Group";
import StudyResults from "./modules/training/StudyGroups/components/Results";
import CategoryStudyGroups from "./modules/training/StudyGroups/components/CategoryGroups";
import MyStudyGroupIndex from "./modules/training/StudyGroups/components/MyGroups/MyGroupIndex";
import StudyGroupSearchResult from "./modules/training/StudyGroups/components/Search/SearchResult";
import StudyGroupsIndex from "./modules/training/StudyGroups/components/ConnectionsGroups/ConnectionsGroupsIndex";
import LMSDashboard from "./modules/training/components/LMSDashboard/LMSDashboard";
import LMSLayout from "./modules/training/components/Layout/Layout";
// documents routs here
import Privacy from "./modules/Documents/Privacy";
import TermsOfUse from "./modules/Documents/TermOfUse";
import FeedbackForm from "./components/FeedbackForm/FeedbackForm";
import SARForm from "./modules/Documents/SARForm";

import EntrepreneurRoute from "./utils/EntrepreneurRoute";
import OpportunityDetails from "./modules/Opportunity/OpportunityDetails/OpportunityDetails";
import FlagOpportunityModal from "./modules/Opportunity/components/FlagOpportunityModal/FlagOpportunityModal";

import OpportunityHome from "./modules/Opportunity/Hompage/index";
import CreateOpportunity from "./modules/Opportunity/CreateOpportunity/CreateOpportunity";
import CreateOpportunitySuccess from "./modules/Opportunity/CreateOpportunitySuccess/CreateOpportunitySuccess";
import MyOpportunities from "./modules/Opportunity/MyOpportunities/MyOpportunities";
import EditOpportunity from "./modules/Opportunity/EditOpportunity/EditOpportunity";

import SignoutPage from "./components/SignoutPage/SignoutPage";
import Results from "./modules/Community/components/Results";
import CategoryGroups from "./modules/Community/components/CategoryGroups";
import MessageIndex from "./modules/Message/MessageIndex";
import ViewThread from "./modules/Community/components/Thread/ViewThread/ViewThread";
import MyGroupIndex from "./modules/Community/components/MyGroups/MyGroupIndex";
import SearchResult from "./modules/Community/components/Search/SearchResult";

// Tools
import Tools from "./modules/Tools/Tools";
import AddTool from "./modules/Tools/components/AddTool/AddTool";
import ToolDetails from "./modules/Tools/components/ToolDetails/ToolDetails";
import AddToolSuccess from "./modules/Tools/components/AddToolSuccess/AddToolSuccess";
import Bookmarks from "./modules/Bookmarks/Bookmarks";

// Discover
import CreateTefStory from "./modules/Discover/components/TefStory/CreateTefStory";
import CreateArticle from "./modules/Discover/components/Articles/CreateArticle";
import ViewStory from "./modules/Discover/components/TefStory/ViewStory";
import ViewArticle from "./modules/Discover/components/Articles/ViewArticle";
import ConnectionsGroupsIndex from "./modules/Community/components/ConnectionsGroups/ConnectionsGroupsIndex";
import MasterclassDetails from "./modules/Discover/components/TefMasterClass/MasterclassDetails/MasterclassDetails";
import LearningResourceDetails from "./modules/Discover/components/TefLearningResources/LearningResourcesDetail/LearningResourceDetails";
import RedirectMentorship from "./modules/mentorship/components/MentorshipTC/MentorshipRedirect";
import ViewNews from "./modules/Discover/components/TefNews/ViewNews";
import LoginRedirect from "./Auth/Login/loginRedirect";
import PreviewAppIndex from "./modules/Application/components/PreviewApplication/prevIndex";

// Alumni
import Alumni from "./modules/Alumni/alumni";
import AlumniOpportunity from "./modules/Alumni/Opportunity/Opportunity";
import OpportunityDetail from "./modules/Alumni/Opportunity/OpportunityDetail";
import AlumniCreateOpportunity from "./modules/Alumni/Opportunity/CreateOpportunity";
import AlumniEvent from "./modules/Alumni/Events/AlumniEvent";
import CreateEvent from "./modules/Alumni/Events/CreateEvent";
import EventDetail from "./modules/Alumni/Events/EventDetail";
import AlumniHub from "./modules/Alumni/AlumniHub/AlumniHub";
import AppPreviewUpdate from "./modules/Program/ApplicantDataUpdate";
import Chat from "./modules/Alumni/Chat/Chat";
import HubUsers from "./modules/Alumni/AlumniHub/containers/HubUsers";

// ALUMNI COMPONENTS
import BusinessReportingIndex from "./modules/Alumni/BusinessReporting/index";
import ShareBusinessUpdate from "./modules/Alumni/BusinessReporting/ShareBusinessUpdate";
import BusinessReportingView from "./modules/Alumni/BusinessReporting/View";
import RecommendationLetter from "./modules/Alumni/RecommendationLetter";
import ResourcesIndex from "./modules/Alumni/Resources";
import ResourcesVideoView from "./modules/Alumni/Resources/components/Tabs/Video/VideoView";
import ResourcesNewsView from "./modules/Alumni/Resources/components/Tabs/News/NewsView";

import HubGallery from "./modules/Alumni/AlumniHub/containers/Gallery/index";
import HubsListing from "./modules/Alumni/AlumniHub/containers/Hubs/index";
import HubDetails from "./modules/Alumni/AlumniHub/containers/HubDetails/index";
import HubEvents from "./modules/Alumni/AlumniHub/containers/Events/index";

import ApplicantUpdateLanguageSelect from "./modules/Program/ApplicantDataUpdate/ApplicantUpdateSelectLang";

import EditArticle from "./modules/Discover/components/Articles/EditArticle";
import DiscoverBookmarks from "./modules/Discover/components/Bookmarks/Bookmarks";
import EditTefStory from "./modules/Discover/components/TefStory/EditTefStory";
import InvitedUserRequest from "./modules/Community/InvitedUserRequest/InvitedUserRequest";
import NoAuthRoute from "./NoAuthRoute";
import Loginv2 from "./Auth/Login/loginv2";
import ResetPasswordv2 from "./Auth/ResetPassword/ResetPassword_v2";
import ResetPasswordForm_v2 from "./Auth/ResetPassword/ResetPasswordForm_v2";
import Register_v2 from "./Auth/Register/Register_v2";
import LandingPage from "./Auth/LandingPage";
import Contactus_v2 from "./modules/Contactus_v2/Contactus_v2";
import General_faq from "./modules/FAQ/General_faq_v2";
import { moodleRoute } from "./modules/Flags/urlFlagParams";
import SentryRoute from "./SentryRoute";
import {
  SignInRedirectCallback,
  SignInSilentCallback,
  SignOutRedirectCallback,
} from "./Auth/OpenId/OpenIdCallbacks";

const BecomeAPartner = React.lazy(() => import("./Auth/BecomeAPartner"));

export const RouteSwitch = () => (
  <Switch>
    <NoAuthRoute
      exact
      path="/"
      component={isLoggedIn() ? HomePage : LandingPage}
    />

    <NoAuthRoute exact path="/signup" component={Register_v2} />
    <NoAuthRoute exact path="/home/faq" component={FrequentlyAskedQuestions} />
    <PrivateRoute exact path="/login/redirect" component={LoginRedirect} />

    <NoAuthRoute
      exact
      path="/confirmed/:email"
      component={ConfirmationMessage}
    />
    <NoAuthRoute exact path="/Auth/ComfirmEmail" component={VerifyUser} />
    <NoAuthRoute exact path="/login" component={Loginv2} />
    <NoAuthRoute exact path="/reset-password" component={ResetPasswordv2} />
    <NoAuthRoute
      exact
      path="/Auth/ResetPassword"
      component={ResetPasswordForm_v2}
    />
    <NoAuthRoute
      exact
      path="/reset-password-sent"
      component={ResetPasswordMessage}
    />

    <NoAuthRoute exact path="/contact-us" component={Contactus_v2} />
    <PrivateRoute exact path="/change-password" component={ChangePassword} />
    <PrivateRoute exact path="/mentorship" component={Mentorship} />
    <PrivateRoute
      exact
      path="/mentorship-application"
      component={MentorshipApplication}
    />
    <PrivateRoute
      path="/mentorship-terms-and-condition"
      component={MentorshipTC}
    />
    <PrivateRoute path="/mentorship-redirects" component={RedirectMentorship} />
    <PrivateRoute
      exact
      path="/mentorship/goals/:goalId"
      component={GoalDetails}
    />
    <PrivateRoute
      exact
      path="/community/goals/:goalId"
      component={PrivateGoalDetails}
    />
    <PrivateRoute
      exact
      path="/training/pid/:programmeId/event/:eventId/course/:courseId"
      component={TrainingApplicantHome}
    />
    <PrivateRoute exact path="/training/studygroups" component={StudyGroups} />
    <PrivateRoute
      exact
      path="/training/studygroups/group"
      component={StudyGroup}
    />
    <PrivateRoute
      exact
      path="/training/studygroups/all-groups"
      component={StudyResults}
    />
    <PrivateRoute
      exact
      path="/training/studygroups/connections-groups"
      component={StudyGroupsIndex}
    />
    <PrivateRoute
      exact
      path="/training/studygroups/groups"
      component={CategoryStudyGroups}
    />
    <PrivateRoute
      exact
      path="/training/studygroups/my-groups"
      component={MyStudyGroupIndex}
    />
    <PrivateRoute exact path="/training/lms/course" component={LMSLayout} />
    <PrivateRoute exact path="/training/LMS" component={LMSDashboard} />
    <PrivateRoute path="/training" component={moodleRoute} />
    <PrivateRoute exact path="/onboarding" component={StepOneOnboarding_v2} />
    <PrivateRoute
      exact
      path="/onboarding/step-two"
      component={StepTwoOnboarding_v2}
    />
    <PrivateRoute
      exact
      path="/onboarding/tef-mentor"
      component={TefMentorOnboarding}
    />
    <PrivateRoute
      exact
      path="/onboarding/investor"
      component={InvestorOnboarding}
    />
    <PrivateRoute
      exact
      path="/onboarding/interests"
      component={StepThreeOnboarding}
    />
    <PrivateRoute
      exact
      path="/onboarding/success"
      component={SuccessPageOnboarding}
    />
    <PrivateRoute exact path="/home" component={HomePage} />
    <PrivateRoute exact path="/profile" component={Profile} />
    <PrivateRoute exact path="/profile/:id" component={Profile} />
    <PrivateRoute exact path="/connections" component={Connection} />
    <PrivateRoute exact path="/events" component={Event} />
    <PrivateRoute exact path="/all-stars" component={AllStars} />
    <PrivateRoute exact path="/discover" component={Discover} />
    <PrivateRoute exact path="/community" component={Community} />
    <DMSRoute exact path="/tef-dms" component={TefDMS} />
    <PrivateRoute exact path="/community/group" component={CommunityGroup} />
    <PrivateRoute exact path="/community/all-groups" component={Results} />
    <PrivateRoute
      exact
      path="/community/groups/search"
      component={SearchResult}
    />
    <PrivateRoute
      exact
      path="/training/studygroups/groups/search"
      component={StudyGroupSearchResult}
    />
    <PrivateRoute exact path="/community/groups" component={CategoryGroups} />
    <PrivateRoute exact path="/community/topic/:id" component={ViewThread} />
    <PrivateRoute exact path="/community/group" component={CommunityGroup} />
    <PrivateRoute exact path="/community/all-groups" component={Results} />
    <PrivateRoute
      exact
      path="/community/connections-groups"
      component={ConnectionsGroupsIndex}
    />
    <PrivateRoute exact path="/community/groups" component={CategoryGroups} />
    <PrivateRoute
      exact
      path="/community/groups/my-groups"
      component={MyGroupIndex}
    />
    <PrivateRoute
      exact
      path="/community/group/requests"
      component={InvitedUserRequest}
    />
    <PrivateRoute exact path="/opportunities" component={OpportunityHome} />
    <PrivateRoute
      exact
      path="/opportunities/create"
      component={CreateOpportunity}
    />
    <PrivateRoute
      exact
      path="/opportunities/:id/edit"
      component={EditOpportunity}
    />
    <PrivateRoute
      exact
      path="/opportunities/create/success"
      component={CreateOpportunitySuccess}
    />
    <PrivateRoute
      exact
      path="/opportunities/my-opportunities"
      component={MyOpportunities}
    />
    <PrivateRoute
      path="/opportunity-details/:id"
      component={OpportunityDetails}
    />
    <PrivateRoute path="/flag-opportunity" component={FlagOpportunityModal} />
    <PrivateRoute exact path="/topics" component={Topics} />
    <PrivateRoute
      exact
      path="/programme/dashboard"
      component={ProgramDashboard}
    />
    <PrivateRoute exact path="/market-place" component={MarketPlace} />
    <PrivateRoute exact path="/tools" component={Tools} />
    <PrivateRoute path="/add-tool" component={AddTool} />
    <PrivateRoute path="/tool-details/:id" component={ToolDetails} />
    <PrivateRoute path="/add-tool-success" component={AddToolSuccess} />
    <PrivateRoute exact path="/bookmarks" component={Bookmarks} />
    <PrivateRoute exact path="/discover/tefstory" component={CreateTefStory} />
    <PrivateRoute
      exact
      path="/discover/bookmarks"
      component={DiscoverBookmarks}
    />
    <PrivateRoute exact path="/discover/story/:id" component={ViewStory} />
    <PrivateRoute
      exact
      path="/discover/edit-story/:id"
      component={EditTefStory}
    />
    <PrivateRoute
      exact
      path="/discover/create-article"
      component={CreateArticle}
    />
    <PrivateRoute exact path="/discover/article/:id" component={ViewArticle} />
    <PrivateRoute
      exact
      path="/discover/edit-article/:id"
      component={EditArticle}
    />
    <PrivateRoute
      exact
      path="/discover/masterclass-details/:id"
      component={MasterclassDetails}
    />
    <PrivateRoute
      exact
      path="/discover/learning-resource/:id"
      component={LearningResourceDetails}
    />
    <PrivateRoute exact path="/discover/news/:id" component={ViewNews} />
    <AlumniRoute exact path="/alumni" component={Alumni} />
    <AlumniRoute
      exact
      path="/alumni-opportunity"
      component={AlumniOpportunity}
    />
    <AlumniRoute
      exact
      path="/alumni-opportunity/create"
      component={AlumniCreateOpportunity}
    />
    <AlumniRoute
      path="/alumni-opportunity-detail/:id"
      component={OpportunityDetail}
    />

    <AlumniRoute exact path="/alumni-events" component={AlumniEvent} />
    <AlumniRoute exact path="/alumni-events/create" component={CreateEvent} />
    <AlumniRoute
      exact
      path="/alumni-events-detail/:id"
      component={EventDetail}
    />
    <AlumniRoute exact path="/alumni-hub/" component={AlumniHub} />
    <AlumniRoute
      exact
      path="/alumni/hubs/:hubId/gallery"
      component={HubGallery}
    />

    <AlumniRoute exact path="/alumni/hubs/:hubId/users" component={HubUsers} />

    <AlumniRoute
      exact
      path="/alumni/hubs/:hubId/other-hubs"
      component={HubsListing}
    />

    <NoAuthRoute
      exact
      path="/500"
      component={() => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          Internal server error
        </div>
      )}
    />
    <NoAuthRoute
      exact
      path="/408"
      component={() => <div>Request Timeout</div>}
    />
    <PrivateRoute exact path="/application" component={Layout} />
    <SentryRoute exact path="/onboarding-2" component={ScreenTwo} />
    <SentryRoute exact path="/onboarding-3" component={ScreenThree} />
    <SentryRoute exact path="/tour" component={Tour} />
    <EntrepreneurRoute
      exact
      path="/application/tests/personality"
      component={Personality}
    />
    <EntrepreneurRoute
      exact
      path="/application/tests/mcq"
      component={Cognitive}
    />
    <EntrepreneurRoute exact path="/application/faq" component={Faq} />
    <EntrepreneurRoute
      exact
      path="/application/offcycle/faq"
      component={OffcycleFaq}
    />
    <EntrepreneurRoute path="/application/tour" component={OnboardingIndex} />
    <EntrepreneurRoute
      exact
      path="/application/personalinfo/bio"
      component={ApplicationBio}
    />
    <EntrepreneurRoute
      exact
      path="/application/personalinfo/experience"
      component={ApplicationExperience}
    />
    <EntrepreneurRoute
      exact
      path="/application/declaration"
      component={DeclarationIndex}
    />
    <EntrepreneurRoute
      path="/application/offcycle/tour"
      component={OffcycleOnboardingSteps}
    />
    <EntrepreneurRoute
      exact
      path="/application/offcycle/personalinfo/bio"
      component={ApplicationOffcycleBio}
    />
    <EntrepreneurRoute
      exact
      path="/application/offcycle/personalinfo/experience"
      component={ApplicationOffcycleExperience}
    />
    <EntrepreneurRoute
      exact
      path="/application/offcycle/tests/mcq"
      component={OffcycleCognitive}
    />
    <EntrepreneurRoute
      exact
      path="/application/offcycle/declaration"
      component={OffCycleDeclaration}
    />
    <AlumniRoute
      exact
      path="/alumni/business-reporting"
      component={BusinessReportingIndex}
    />
    <AlumniRoute
      exact
      path="/alumni/business-reporting/view/:surveyId"
      component={BusinessReportingView}
    />
    <AlumniRoute
      exact
      path="/alumni/business-reporting/:surveyName/:surveyId"
      component={ShareBusinessUpdate}
    />
    <AlumniRoute
      path="/alumni/recommendation-letter/"
      component={RecommendationLetter}
    />
    <AlumniRoute exact path="/alumni/resources/" component={ResourcesIndex} />
    <AlumniRoute
      exact
      path="/alumni/resources/video-view/:resourceId"
      component={ResourcesVideoView}
    />
    <AlumniRoute
      exact
      path="/alumni/resources/news-view/:resourceId"
      component={ResourcesNewsView}
    />
    <NoAuthRoute exact path="/privacy" component={Privacy} />
    <NoAuthRoute exact path="/terms-of-use" component={TermsOfUse} />
    <NoAuthRoute exact path="/feedback" component={FeedbackForm} />
    <NoAuthRoute exact path="/dsar" component={SARForm} />
    <PrivateRoute exact path="/messages" component={MessageIndex} />
    <PrivateRoute exact path="/messages/thread/:id" component={MessageIndex} />
    <PrivateRoute
      exact
      path="/preview-application"
      component={PreviewAppIndex}
    />
    <AlumniRoute exact path="/alumni/chat" component={Chat} />

    <AlumniRoute
      exaxt
      path="/alumni/hubs/:hubId/details"
      component={HubDetails}
    />

    <AlumniRoute
      exaxt
      path="/alumni/hubs/:hubId/events"
      component={HubEvents}
    />

    <PrivateRoute
      exact
      path="/applicant/preview-application"
      component={AppPreviewUpdate}
    />
    <PrivateRoute
      exact
      path="/applicant/lang-update"
      component={ApplicantUpdateLanguageSelect}
    />
    <NoAuthRoute exact path="/signout" component={SignoutPage} />
    <NoAuthRoute exact path="/faq" component={General_faq} />
    <NoAuthRoute exact path="/become-a-partner" component={BecomeAPartner} />

    {/* OpenId related routes: */}
    <NoAuthRoute exact path="/callback" component={SignInRedirectCallback} />
    <NoAuthRoute
      exact
      path="/signout/callback"
      component={SignOutRedirectCallback}
    />
    <NoAuthRoute exact path="/silentrenew" component={SignInSilentCallback} />

    <Redirect to="/" />
  </Switch>
);
