import React, { Fragment } from "react";
import ContentLoader from "react-content-loader";
import shortid from "shortid";

export const ReactContentLoader = () => {
  return (
    <span
      style={{
        backgroundColor: "white",
        margin: "8px",
        height: "250px",
        width: "12.5rem",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "10px",
        }}
      >
        <ContentLoader
          speed={2}
          // width={324}
          // height={150}
          viewBox="0 0 400 160"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <circle cx="30" cy="30" r="30" />
          <rect x="80" y="8" rx="3" ry="3" width="350" height="50" />
          <rect x="80" y="45" rx="3" ry="3" width="350" height="10" />
          <rect x="0" y="96" rx="3" ry="3" width="500" height="10" />
          <rect x="0" y="116" rx="3" ry="3" width="500" height="10" />
          <rect x="0" y="136" rx="3" ry="3" width="500" height="10" />
          <rect x="0" y="156" rx="3" ry="3" width="500" height="10" />
          {/* <circle cx="20" cy="20" r="20" /> */}
        </ContentLoader>
      </div>
    </span>
  );
};

const loaders = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 1, 2, 3, 4, 5, 6];
export const showLoading = () =>
  loaders.map((loader) => (
    <Fragment key={shortid.generate()}>
      <ReactContentLoader />
    </Fragment>
  ));
