import React, { useEffect, useState } from "react";
import { ListGroupItem } from "reactstrap";
import classes from "./ChatBlock.module.scss";
import { selectColor } from "src/modules/Alumni/Components/helper/selectColor";
import moment from "moment";
import { getGroupUsers } from "src/requests/AlumniChatRequest";

const ChatBlock = ({
  isGroup,
  chatDetails = {},
  currentChat,
  switchDefaultActiveChat,
}) => {
  const [groupUsers, setGroupUser] = useState([]);
  const [, setIsLoadingHubUsers] = useState(false);
  useEffect(() => {
    _getGroupUser(chatDetails.id);
  }, []);
  const _getGroupUser = (id) => {
    setIsLoadingHubUsers(true);
    if (chatDetails.isGroup)
      getGroupUsers(id).then((res) => {
        const {
          data: { data },
        } = res.data;

        setIsLoadingHubUsers(false);
        setGroupUser(data);
      });
  };

  return (
    <ListGroupItem
      className={`border-top-0 border-right-0 border-left-0 bg-transparent d-flex flex-column px-3 
        ${classes["chat-block__wrapper"]} 
        ${currentChat?.name === chatDetails?.name ? "bg-white" : ""}`}
      onClick={() =>
        switchDefaultActiveChat(chatDetails.withId || chatDetails.id)
      }
    >
      <div className={classes["user-details__wrapper"]}>
        <div className={classes["user-image"]}>
          {/* {chatDetails.} */}
          {chatDetails.imageUrl || chatDetails.bannerUrl ? (
            <img
              src={chatDetails.imageUrl || chatDetails.bannerUrl}
              width="35"
              height="35"
              style={{ borderRadius: "50%" }}
            />
          ) : (
            <div className="group-no-img">
              <span>{`${chatDetails.name[0]}`}</span>
            </div>
          )}
        </div>
        <div className={classes["user-online__status"]}>
          <span></span>
        </div>
        <div className={classes["user-name"]}>
          <p className="msg_box">
            <span className="msg_name">{chatDetails?.name}</span>
            {chatDetails?.unread > 0 && (
              <span className="msg_badge">{chatDetails?.unread}</span>
            )}
          </p>{" "}
        </div>
        <div className={classes["last-chat__time"]}>
          <p>
            {moment(chatDetails.date).fromNow()}
            {/* <ReactTimeAgo
              timeStyle="round-minute"
              locale="en-US"
              date={new Date(chatDetails.date)}
            /> */}
          </p>
        </div>
      </div>
      <div className={classes["last-message__wrapper"]}>
        <div style={{ flexBasis: "18%" }}></div>
        <p className={classes["last-chat__message"]}>
          {chatDetails?.lastMessage}
        </p>
      </div>
      {chatDetails.isGroup && (
        <div className={classes["group-members__wrapper"]}>
          <div style={{ flexBasis: "18%" }}></div>
          <div className={classes["group-members"]}>
            <div
              className={`d-flex align-items-center position-relative py-3 group-users ${classes["group-members__image-array"]}`}
            >
              {groupUsers.length > 0 &&
                groupUsers
                  .slice(0, 3)
                  .map((item, idx) => (
                    <Img key={item.id} idx={idx} info={item} />
                  ))}
            </div>
            <div className={classes["group-icon"]}>
              <img src={chatDetails.bannerUrl} alt="" height="30" widh="30" />
              <p className="mb-0">{chatDetails?.name}</p>
            </div>
          </div>
        </div>
      )}
    </ListGroupItem>
  );
};
const Img = ({ info: { user }, idx }) => {
  const colorPicker = selectColor();
  return (
    <>
      {user && user.imageUrl ? (
        <img
          src={user.imageUrl}
          className={classes["group-member__image"]}
          width="30"
          height="30"
          alt=""
        />
      ) : (
        <div
          className="group-no-img"
          style={{
            backgroundColor: colorPicker(idx).background,
            color: colorPicker(idx).color,
          }}
        >
          <span>{`${user?.firstName?.charAt(0)}`}</span>
        </div>
      )}
    </>
  );
};

export default ChatBlock;
