import React from "react";
import { Card, Button, CardSubtitle, Row, Col } from "reactstrap";
import "./BannerCard.scss";
const BannerCard = () => {
  return (
    <Card className="banner-card" body>
      <Row>
        <Col sm="12">
          <CardSubtitle tag="h5" className="banner-heading">
            Apply to become a mentor for the 2023 TEF Entrepreneurship programme
          </CardSubtitle>
        </Col>
      </Row>
      <Row>
        <Button
          color="secondary"
          onClick={(e) => {
            window.open("https://www.surveymonkey.com/r/Q36PTNM", "_blank");
          }}
          className="header-button-red"
        >
          Apply
        </Button>
      </Row>
    </Card>
  );
};

BannerCard.propTypes = {};

export default BannerCard;
