import React from "react";
import classes from "./SubHeading.module.css";

const SubHeading = ({ text, icon }) => {
  return (
    <div className={classes.SubHeading}>
      <div className={classes.SubHeadingLogo}>{icon}</div>
      <h6 className={classes.SubHeadingText}>{text}</h6>
    </div>
  );
};

export default SubHeading;
