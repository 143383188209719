import React from "react";
import Select, { components } from "react-select";
import { MdClose } from "react-icons/md";
import { FaCaretDown } from "react-icons/fa";

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaCaretDown color="#ACB3BD" />
    </components.DropdownIndicator>
  );
};
const Drawer = ({
  drawerToggle,
  selectHandler,
  categoryObj,
  handlerSearchParam,
  typesObj,
  countryObj,
}) => {
  return (
    <div className="drawer-search">
      <div className="drawer-content">
        <div className="drawer-header">
          <span className="close-drawer" size onClick={drawerToggle}>
            <span>
              <MdClose color="#fff" size={35} />{" "}
            </span>
          </span>
          <span className="drawer-filter">Filter</span>
        </div>
      </div>
      <div className="drawer-body">
        <div className="search-select">
          <div className="search-boxes mr-3">
            <span className="search-cate opp-search-cate">Category:</span>
            <Select
              isClearable
              isSearchable
              name="color"
              placeholder="All"
              className="custom-opp-select"
              classNamePrefix="opp-select"
              options={categoryObj}
              components={{ DropdownIndicator }}
              onChange={(val) => selectHandler(val, "categoryId")}
              styles={{
                menu: (base) => ({
                  ...base,
                  marginBottom: 76,
                  width: "100%",
                  border: "none",
                  borderRadius: 0,
                }),
              }}
            />
          </div>
          <div className="search-boxes mr-3">
            <span className="search-cate opp-search-cate">Country</span>
            <Select
              isClearable
              isSearchable
              name="color"
              placeholder="All"
              className="custom-opp-select"
              classNamePrefix="opp-select"
              components={{ DropdownIndicator }}
              options={countryObj}
              onChange={(val) => selectHandler(val, "country")}
              styles={{
                menu: (base) => ({
                  ...base,
                  marginBottom: 76,
                  width: "100%",
                  border: "none",
                  borderRadius: 0,
                }),
              }}
            />
          </div>
          <div className="search-boxes mr-3">
            <span className="search-cate opp-search-cate">Type:</span>
            <Select
              isClearable
              isSearchable
              name="color"
              placeholder="All"
              className="custom-opp-select"
              classNamePrefix="opp-select"
              components={{ DropdownIndicator }}
              options={typesObj}
              onChange={(val) => selectHandler(val, "opportunityTypeId")}
              styles={{
                menu: (base) => ({
                  ...base,
                  marginBottom: 76,
                  width: "100%",
                  border: "none",
                  borderRadius: 0,
                }),
              }}
            />
          </div>
          <div className="search-boxes mr-3">
            <span className="search-cate opp-search-cate">Sort by:</span>
            <Select
              isClearable
              isSearchable
              name="color"
              placeholder="Latest"
              className="custom-opp-select"
              classNamePrefix="opp-select"
              components={{ DropdownIndicator }}
              options={categoryObj}
              styles={{
                menu: (base) => ({
                  ...base,
                  marginBottom: 76,
                  width: "100%",
                  border: "none",
                  borderRadius: 0,
                }),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
