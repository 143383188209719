/* eslint-disable no-new */
import React from "react";
import styles from "./Header.module.scss";
import tefLOgo from "src/assets/img/logo/logo_200.png";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";

const Header = ({ onOpen }) => {
  return (
    <div className={styles.Header}>
      <div className={styles.Header__menuIcon}>
        <FaBars onClick={onOpen} size={30} color="#000" />
      </div>
      <Link to="/home" className={styles.Header__logo}>
        <img src={tefLOgo} alt="Logo" className={styles.Header__logoImg} />
      </Link>
    </div>
  );
};

export default Header;
