/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from "react";

import UploadDocument from "src/modules/Tef-Dms/components/UploadDocument";
import Documents from "src/modules/Tef-Dms/components/Documents";
import SubmitDocumentModal from "src/modules/Tef-Dms/components/Modals/SubmitDocumentModal/SubmitDocumentModal";
import { Row, Col, Spinner } from "reactstrap";
import classes from "./DueDilligence.module.css";
import SubmitDocument from "src/modules/Tef-Dms/components/SubmitDocument/SubmitDocument";
import { Context } from "src/store";
import NoDocuments from "src/assets/img/mentorship-empty.png";
import ReviewersCommentsModal from "src/modules/Tef-Dms/components/Modals/ReviewersCommentsModal/ReviewersComments";
import { getSettings } from "src/requests/DmsRequest";
import jwt_decode from "src/utils/jwt-decode";
import Swal from "sweetalert2";

const DueDilligence = ({
  handleUpload,
  handleDocumentSelect,
  handleSubmitForReview,
  loader,
  uploadLoader,
}) => {
  const [dueDilligenceDocuments, setDueDilligenceDocuments] = useState([]);
  const [displayDueDilligence, setDisplayDueDilligence] = useState(undefined);
  const [reviewersComments, setReviewersComments] = useState("");
  const [dueDilligenceEndDate, setEndate] = useState("");
  const [todaysDate, setToday] = useState("");
  const [error, setError] = useState(undefined);
  const [submitted, setSubmitted] = useState(false);

  const [
    dueDilligenceRequiredDocuments,
    setDueDilligenceRequiredDocuments,
  ] = useState([]);

  const { state } = useContext(Context);
  useEffect(() => {
    const dueDilligence = state.dms && state.dms.dueDilligence;
    const dueDilligenceDocuments =
      state.dms &&
      state.dms.dueDilligence &&
      state.dms.dueDilligence.dueDilligenceDocuments;
    const reviewersComments =
      state.dms &&
      state.dms.dueDilligence &&
      state.dms.dueDilligence.reviewerComment;
    if (dueDilligence && dueDilligenceDocuments) {
      setDueDilligenceDocuments(dueDilligenceDocuments);
      setReviewersComments(reviewersComments);
    }
    const submitted =
      state.dms &&
      state.dms.dueDilligence &&
      state.dms.dueDilligence.stageProgresses;
    if (submitted && submitted.length >= 1) setSubmitted(true);
  }, [state.dms.dueDilligence]);

  useEffect(() => {
    const dueDilligenceRequiredDocuments =
      state.dms && state.dms.dueDilligenceRequiredDocuments;
    if (dueDilligenceRequiredDocuments) {
      setDueDilligenceRequiredDocuments(dueDilligenceRequiredDocuments);
    }
  }, [state.dms.dueDilligenceRequiredDocuments]);

  useEffect(() => {
    const tkn = localStorage.getItem("data");
    const token = JSON.parse(tkn).token;
    const { dms_user } = jwt_decode(token);
    const programId = dms_user[0];

    const _getSettings = async () => {
      try {
        const payload = {
          programId,
          submissionType: "DueDilligence",
        };

        const response = await getSettings(payload);
        const { data } = response.data;
        if (data && data.length <= 0) {
          setError(true);
          return;
        } else {
          const startDate = new Date(data[0].startDate);
          const endDate = new Date(data[0].endDate);
          const today = new Date();

          if (startDate && endDate) {
            setEndate(endDate);
            setToday(today);
            if (startDate > today) {
              setDisplayDueDilligence(false);
              localStorage.setItem("DueDilligenceStatus", "Not Started");
            } else {
              setDisplayDueDilligence(true);
              localStorage.setItem("DueDilligenceStatus", "In Progress");
            }
            return response;
          }
        }
      } catch (error) {
        setError(true);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "The Admin has not provided the start and end date for due diligence phase",
          confirmButtonColor: "#da291c",
        });

        return error;
      }
    };

    _getSettings();
  }, []);

  // const uploadedDocu = state.dms.dueDilligenceRequiredDocuments;
  // console.log(uploadedDocu);
  // console.log(dueDilligenceRequiredDocuments);

  // const filteredDueDiligenceRequiredDocuments = state.dms.dueDilligenceRequiredDocuments.filter(
  //   (d) => d.isRequired
  // );

  // const canSubmit = uploadedDocu.map((x) =>
  //   filteredDueDiligenceRequiredDocuments.some((y) => y.name === x.name)
  // );

  // console.log(`canSubmit ${canSubmit}`);
  // console.log(filteredDueDiligenceRequiredDocuments);

  const showSubmitForReviewButton = () => {
    const dueDilligenceDocuments =
      state.dms &&
      state.dms.dueDilligence &&
      state.dms.dueDilligence.dueDilligenceDocuments;
    const dueDilligenceRequiredDocuments =
      state.dms &&
      state.dms.dueDilligenceRequiredDocuments &&
      state.dms.dueDilligenceRequiredDocuments.filter((d) => d.isRequired);

    if (dueDilligenceDocuments && dueDilligenceRequiredDocuments) {
      if (
        dueDilligenceDocuments?.length >= dueDilligenceRequiredDocuments.length
      ) {
        return true;
      }
    }

    return false;
  };

  const isEndDatePassed = (end, today) => {
    if (today > end) {
      return true;
    }

    return false;
  };

  return (
    <>
      <div>
        {displayDueDilligence === false && (
          <div className={classes.NoDocumentsContainer}>
            <img src={NoDocuments} className={classes.NoDocuments} />
            <h6>This phase has not started yet</h6>
          </div>
        )}
      </div>

      <div>
        {error && (
          <div className={classes.NoDocumentsContainer}>
            <img src={NoDocuments} className={classes.NoDocuments} />
            <h6>This phase has not commenced yet</h6>
          </div>
        )}
      </div>

      {displayDueDilligence && (
        <div>
          <Row>
            <Col md={12}>
              <UploadDocument
                handleDocumentSelect={handleDocumentSelect}
                documentTypes={dueDilligenceRequiredDocuments}
                handleUpload={handleUpload}
                uploadLoader={uploadLoader}
                tabId={1}
                canUpload={submitted}
              />
            </Col>
          </Row>
          {dueDilligenceDocuments.length < 1 && state.dms.loadingDueDilligence && (
            <div className={classes.NoDocumentsContainer}>
              <Spinner color="dark" />
            </div>
          )}
          {!state.dms.loadingDueDilligence &&
            dueDilligenceDocuments.length < 1 && (
              <div className={classes.NoDocumentsContainer}>
                <img src={NoDocuments} className={classes.NoDocuments} />
                <h6>No documents available!</h6>
              </div>
            )}
          {dueDilligenceDocuments.length > 0 &&
            !state.dms.loadingDueDilligence && (
              <>
                <Row>
                  <Col sm="12">
                    <Row className="mt-5">
                      {dueDilligenceDocuments.map((document, index) => (
                        <Documents key={index} document={document} />
                      ))}
                      <Col md={4} xs={6}>
                        <Row className="ml-0 mt-3">
                          {/* <ReviewersCommentsModal
                            comments={reviewersComments}
                          /> */}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          <Row className="mt-5">
            {isEndDatePassed(dueDilligenceEndDate, todaysDate) && (
              <SubmitDocument
                buttonText="Submit"
                message="This phase has ended"
              />
            )}
            {!submitted && (
              <SubmitDocument>
                <SubmitDocumentModal
                  loader={loader}
                  handleSubmitForReview={handleSubmitForReview}
                  isShowButton={showSubmitForReviewButton()}
                />
              </SubmitDocument>
            )}
          </Row>
        </div>
      )}
    </>
  );
};

export default DueDilligence;
