import React, { useState } from "react";
import TefRedButton from "src/modules/Opportunity/components/Button/TefRedButton";
import classes from "./InputComment.module.css";
// import jwt_decode from "src/utils/jwt-decode";
import Avatar from "src/components/Avatar/Avatar";
import {
  postComment,
  postCommentNotification,
} from "src/requests/OpportunityRequest";
import { useCurrentUser } from "src/utils/Services/currentUser";

const InputComment = ({ dispatch, opportunityId, reload }) => {
  const [newComment, setNewComment] = useState("");
  const current_user = useCurrentUser();

  const submitCommentHandler = (e) => {
    e.preventDefault();
    const payload = {
      opportunityId,
      newComment,
    };
    postComment(dispatch, payload);
    postCommentNotification(opportunityId, location.pathname);
    setNewComment("");
    reload();
  };

  return (
    <form onSubmit={submitCommentHandler}>
      <div className={classes.InputComment}>
        <div className={classes.UserImage}>
          <Avatar
            src={current_user.imageUrl}
            dimension={30}
            name={`${current_user.firstName} ${current_user.lastName}`}
          />
        </div>
        <div className={classes.UserInput}>
          <textarea
            placeholder="What do you think of this opportunity?  You can @mention a connect."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            className={classes.commentForm}
            maxLength="500"
          />
          {/* <EditorBox
            onEditorChange={setNewComment}
            content={newComment}
            useEmoji
            // useMentions
            mentions={[
              { name: "brown", link: "https//google.com" },
              { name: "John Doe", link: "http://facebook.com" },
            ]}
            placeholder="What do you think of this opportunity?  You can @mention a connect."
          /> */}
        </div>
        {newComment.trim() !== "" ? (
          <div className={`${classes.ShareButton}`}>
            <TefRedButton text={`Share`} />
          </div>
        ) : (
          <div className={`${classes.ShareButton} ${classes.Disabled}`}>
            <TefRedButton text={`Share`} />
          </div>
        )}
      </div>
    </form>
  );
};

export default InputComment;
