import React from "react";
import { Card, Progress, Row, Col } from "reactstrap";
import "./ProfileStatus.scss";
const ProfileStatus = (props) => {
  const { percentageCompletion } = props;
  return (
    <Card className="profile-status">
      <h3 className="profile-status-heading">Complete Your Profile</h3>
      <div className="profile-progress-bar">
        <Row>
          <Col md={9}>
            <Progress
              // style={{ height: "6px" }}
              color={"secondary"}
              value={percentageCompletion}
            />
          </Col>
          <Col md={3}>
            <div className="profile-status-percentage">
              {percentageCompletion}%
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

ProfileStatus.propTypes = {};

export default ProfileStatus;
