import "./Bio.scss";
import React, { useState, useContext } from "react";
import { FormField } from "src/modules/onboarding/components/formField";
import { Btn } from "src/modules/profile/ProfileEdit/common";
import ValidateJs from "src/utils/Validations/validation_wrapper";
import { displayError } from "src/modules/onboarding/helper";
import { updateUserProfile } from "src/requests/ProfileRequest";
import { Context } from "src/store";
// import {
//   setCurrentUser,
//   useCurrentUser,
// } from "src/utils/Services/currentUser";

export const BioEdit = (props) => {
  const { cancel } = props;
  const [loading, setLoading] = useState(false);
  const [bio, setBio] = useState(props.bio);
  const [validationErrors, setValidationErrors] = useState({
    bio: null,
  });
  const { dispatch } = useContext(Context);
  // const currentUser = useCurrentUser();

  const onSubmit = () => {
    if (hasValidationError()) {
      return;
    }
    return _handleSubmit();
  };

  const onChange = (target, value) => {
    setBio(value);
  };

  const handleValidationErrorChanges = (target, value) => {
    setValidationErrors({ ...validationErrors, [target]: value });
  };

  const hasValidationError = () => {
    const bioError = ValidateJs("bio", bio);
    handleValidationErrorChanges("bio", bioError);
    if (!bioError) {
      return false;
    }
    return true;
  };

  const _handleSubmit = () => {
    setLoading(true);
    updateUserProfile(dispatch, { bio }).then(() => {
      onCancel();
      setLoading(false);
    });
  };

  // const updateCallback = (values) => {
  //   setCurrentUser({...values,  ...currentUser,  });
  // };

  const onCancel = () => {
    handleValidationErrorChanges("bio", null);
    cancel();
  };

  return (
    <div className="">
      <div className="edit-profile-header">
        <h1>Edit Bio</h1>
      </div>
      <hr />
      <div>
        <FormField
          // value={enforceWordCountValue(wordCounter(bio), bio, 160)}
          value={bio}
          type="textarea"
          label="Bio"
          labelInfo="Required"
          placeholder="Type in a brief overview of yourself"
          maxLength={1000}
          hasCounter
          onChange={onChange}
          name="bio"
          required
          onBlur={() =>
            handleValidationErrorChanges("bio", ValidateJs("bio", bio))
          }
          error={validationErrors.bio}
        />
        {validationErrors.bio && displayError(validationErrors.bio)}
      </div>
      <hr />
      <div className="btn-box">
        <Btn className="btn-cancel" value="Cancel" onClick={onCancel} />
        <div style={{ flex: 1 }}></div>

        <Btn
          className="btn-update"
          value="Save Bio"
          loading={loading}
          onClick={() => onSubmit()}
        />
        {/* </div> */}
      </div>
    </div>
  );
};
