import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useCurrentUser, setCurrentUser } from "src/utils/Services/currentUser";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { getTermsAndCondition } from "src/requests/MentorRequests";
import { Context } from "src/store";
import Loader from "react-loader-spinner";

const RedirectMentorship = () => {
  const user = get_user_info_from_token().user;
  const history = useHistory();
  const current_user = useCurrentUser();
  const { dispatch } = useContext(Context);
  const programmeId = user["tef-mentor"][0];
  const programmeEventId = user["tef-mentor"][1];
  const mentorshipPathToLoad = () => {
    if (
      Object.prototype.hasOwnProperty.call(
        current_user,
        "hasAcceptedMentorshipTerms"
      )
    ) {
      // if (current_user.hasOwnProperty("hasAcceptedMentorshipTerms")) {
      const hasAgreement = current_user.hasAcceptedMentorshipTerms;
      if (hasAgreement) {
        return history.replace("/mentorship", {});
      }
      // const programmeId = user["tef-mentor"][0];
      // const programmeEventId = user["tef-mentor"][1];
      return history.replace(
        `/mentorship-terms-and-condition?p=${programmeId}&e=${programmeEventId}`,
        {}
      );
    } else {
      try {
        getTermsAndCondition(dispatch)
          .then((res) => {
            if (res && res.status === 200) {
              const { agreement } = res.data;
              const copy = { ...current_user };
              copy.hasAcceptedMentorshipTerms = agreement;
              setCurrentUser(copy);
              if (!agreement) {
                return history.replace(
                  `/mentorship-terms-and-condition?p=${programmeId}&e=${programmeEventId}`,
                  {}
                );
              }
              return history.replace("/mentorship", {});
            }
          })
          .catch((e) => {
            console.log(e.response);
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    mentorshipPathToLoad();
  }, [user]);
  return (
    <div>
      {" "}
      <div className="loading_spinner">
        <Loader
          type="ThreeDots"
          color="red"
          height={100}
          width={100}
          // timeout={3000} //3 secs
        />
      </div>
    </div>
  );
};

export default ifOnboardedThenShow(RedirectMentorship);
