import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import i18n from "src/i18n";
import { getLanguage } from "./helper";
import {
  FLAG_DISABLE_THIRD_PARTY_NETWORK_CALLS,
  getBooleanFlagValue,
} from "src/modules/Flags/urlFlagParams";

/** Hook which exports a method to change language from custom language switcher UIs. */
export const useChangeLanguage = () => {
  const history = useHistory();

  const changeLanguage = (lang) => {
    if (getLanguage() === lang) {
      return;
    }
    i18n.changeLanguage(lang);

    localStorage.setItem("i18nextLng", lang);

    const location = new URL(window.location.href);
    // Several places in the code use `lang` from URL.
    location.searchParams.set("lang", lang);

    // Translation with the Translate Element Widget can be triggered automatically when the
    // page's url ends with a special fragment.
    // #googtrans(<target-language>) or #googtrans/<source-language>/<target-language></target-language>
    const hash = `#googtrans(${lang})`;
    const { pathname, search } = location;
    const newLocation = pathname + search + hash;
    history.push(newLocation, {});

    // In order for Google Translate to notice the change in the cookie value
    // and switch to the new language, we need to completely reload the website.
    window.location.reload(true);
  };

  return { changeLanguage };
};

/**
 * Component which initializes Google Translate and updates it's cookie to i18next language.
 * This is useful when the user opens the app for the first time because i18next
 * detects the browser language.
 */
export const GoogleTranslateProvider = ({ children }) => {
  const googleTranslateElementInit = () => {
    /* eslint-disable no-new */
    new window.google.translate.TranslateElement(
      {
        includedLanguages: "en,fr,pt,ar,rw,sw",
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    if (getBooleanFlagValue(FLAG_DISABLE_THIRD_PARTY_NETWORK_CALLS)) {
      return;
    }
    const lang = getLanguage();
    // First set the cookie, before initializing Google Translate.
    Cookies.set("googtrans", `/auto/${lang}`);

    const addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return <>{children}</>;
};
