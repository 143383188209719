import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import CreateStudyGroup from "./CreateStudyGroup";

const CreateGroup = ({
  show,
  toggle,
  className,
  categories,
  courseId,
  getGroups,
}) => {
  return (
    <Modal isOpen={show} toggle={toggle} className={className}>
      <ModalHeader toggle={toggle}>Create A Study Group</ModalHeader>
      <ModalBody>
        <CreateStudyGroup
          categories={categories}
          cancel={toggle}
          courseId={courseId}
          getGroups={getGroups}
        />
      </ModalBody>
    </Modal>
  );
};

export default React.memo(CreateGroup);
