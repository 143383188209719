import React from "react";
import moment from "moment";
import "./Comment.scss";
import Comments from "./Comments/Comments";
import CommentForm from "./CommentForm/CommentForm";
import { Facebook } from "react-content-loader";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const Comment = ({
  comments,
  count,
  id,
  style,
  dp,
  getComments,
  hideCommentForm,
}) => {
  const { user } = get_user_info_from_token();
  let commentCard;
  const styleName = style ? "comment" : "hide-comment";

  if (comments.length > 0) {
    commentCard = comments.map((comm) => {
      const user = comm.profile ? comm.profile : "";
      return (
        <Comments
          key={comm.id}
          commentId={comm.id}
          commentName={
            user ? user.firstName + " " + user.lastName : "Anonymous"
          }
          commentPost={comm.content}
          commentDate={moment(comm.createdOn).format("MMMM Do YYYY, h:mm:ss a")}
          commentLikes={comm.likeCount}
          dp={user.imageUrl}
        />
      );
    });
  }

  return (
    <div className={styleName}>
      <h2>
        <span>Show:</span> All Comments ({count})
      </h2>
      {hideCommentForm ? (
        ""
      ) : (
        <div className="comment-form">
          <CommentForm
            id={id}
            name={user.firstName + " " + user.lastName}
            dp={dp}
            trigger={getComments}
          />
        </div>
      )}
      <div className="comments-div">
        {count === 0 ? commentCard : commentCard || <Facebook />}
      </div>
      {count > 0 ? (
        ""
      ) : (
        <p className="more-comments">
          {count > 10 ? "Show more comments" : "No comments"}
        </p>
      )}
    </div>
  );
};

export default Comment;
