import axios from "src/utils/axios";
import "react-toastify/dist/ReactToastify.css";
import {
  GET_MENTEE_TRAINING_SCORE,
  GET_ALL_TRAINING_COURSES,
  GET_QUIZ_FOR_MODULE,
  SUBMIT_LMS_ASSESSMENT,
  GET_ALL_TRAINING_MODULES,
  USER_STARTS_COURSE,
  GET_USER_REGISTERED_COURSES,
  USER_STARTS_LESSON,
  USER_COMPLETES_LESSON,
  HAS_COMPLETED_LESSON,
  GET_USER_TRAINING_MODULES,
  GET_TRAINING_QUESTION_AND_ANSWER,
  USER_STARTS_MODULE,
  USER_COMPLETES_MODULE,
  USER_COMPLETES_COURSE,
  HAS_AGREED_TO_PARTICIPATE,
  AGREE_TO_PARTICIPATE,
  GET_TRAINING_COURSE_ASSESSMENT,
} from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import {
  ApplicationUrl,
  trainingURL,
  ParticipationAgreementUrl,
} from "src/constants";
import { updateQueryStringParameter } from "src/utils/helper";

export const getMenteeTrainingScore = (dispatch, payload) => {
  const { userId, trainingEventId } = payload;
  dispatch(asyncActions(GET_MENTEE_TRAINING_SCORE).loading(true));
  return axios({
    method: "get",
    url: `${ApplicationUrl.GET_MENTEE_TRAINING_SCORE}/${trainingEventId}/user/${userId}/score`,
    // data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(GET_MENTEE_TRAINING_SCORE).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_MENTEE_TRAINING_SCORE).success(data));
        dispatch(asyncActions(GET_MENTEE_TRAINING_SCORE).loading(false));
      }
      return response;
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 404) {
        dispatch(asyncActions(GET_MENTEE_TRAINING_SCORE).success(null));
      }
      dispatch(asyncActions(GET_MENTEE_TRAINING_SCORE).loading(false));
      dispatch(
        asyncActions(GET_MENTEE_TRAINING_SCORE).failure(true, error.response)
      );
      return error.response;
    });
};

export const submitAssessment = (
  dispatch,
  trainingEventId,
  quizId,
  payload
) => {
  dispatch(asyncActions(SUBMIT_LMS_ASSESSMENT).loading(true));
  return axios({
    method: "patch",
    url: `${trainingURL.SUBMIT_LMS_ASSESSMENT_URL}?quizId=${quizId}`,
    data: payload,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

export const getQuestionsForModule = (
  dispatch,
  trainingEventId,
  quizId,
  lang,
  questionGroupsId
) => {
  dispatch(asyncActions(GET_QUIZ_FOR_MODULE).loading(true));
  return axios({
    method: "get",
    url: `${trainingURL.GET_QUIZ_FOR_MODULE_URL}/${questionGroupsId}/${trainingEventId}/${quizId}/testquestions?lang=${lang}`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

export const getUserRegisteredCourses = (dispatch, trainingEventId) => {
  dispatch(asyncActions(GET_USER_REGISTERED_COURSES).loading(true));
  return axios({
    method: "get",
    url: `${trainingURL.GET_USER_REGISTERED_COURSES_URL}`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

export const getCoursesWithLang = (dispatch, trainingEventId, lang) => {
  dispatch(asyncActions(GET_ALL_TRAINING_COURSES).loading(true));
  return axios({
    method: "get",
    url: `${trainingURL.GET_ALL_TRAINING_COURSES_WITH_LANG_URL}/${lang}`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

export const getCourses = (dispatch, trainingEventId, lang) => {
  dispatch(asyncActions(GET_ALL_TRAINING_COURSES).loading(true));
  return axios({
    method: "get",
    // url: `${trainingURL.GET_ALL_TRAINING_COURSES_URL}`,
    url: `${trainingURL.GET_ALL_TRAINING_COURSES_URL}/user-courses?lang=${lang}`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

export const getTrainingModule = (dispatch, payload) => {
  const { language, trainingEventId, courseId } = payload;
  dispatch(asyncActions(GET_ALL_TRAINING_MODULES).loading(true));
  return axios({
    method: "get",
    url: `${trainingURL.GET_ALL_TRAINING_MODULES_URL}/${courseId}?lang=${language}`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  })
    .then((response) => {
      dispatch(asyncActions(GET_ALL_TRAINING_MODULES).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_ALL_TRAINING_MODULES).success(data));
        dispatch(asyncActions(GET_ALL_TRAINING_MODULES).loading(false));
        return data;
      }
      return response;
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 404) {
        dispatch(asyncActions(GET_ALL_TRAINING_MODULES).success(null));
      }
      dispatch(asyncActions(GET_ALL_TRAINING_MODULES).loading(false));
      dispatch(
        asyncActions(GET_ALL_TRAINING_MODULES).failure(true, error.response)
      );
      return error.response;
    });
};

export const getLessonContent = (dispatch, payload) => {
  const { lang, trainingEventId, lessonId } = payload;
  dispatch(asyncActions(GET_ALL_TRAINING_MODULES).loading(true));
  return axios({
    method: "get",
    url: `${trainingURL.GET_LESSON_CONTENT_URL}/${lessonId}/contents?lang=${lang}`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  })
    .then((response) => {
      dispatch(asyncActions(GET_ALL_TRAINING_MODULES).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_ALL_TRAINING_MODULES).success(data));
        dispatch(asyncActions(GET_ALL_TRAINING_MODULES).loading(false));
        return { status: response.status, data };
      }
      return response;
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 404) {
        dispatch(asyncActions(GET_ALL_TRAINING_MODULES).success(null));
      }
      dispatch(asyncActions(GET_ALL_TRAINING_MODULES).loading(false));
      dispatch(
        asyncActions(GET_ALL_TRAINING_MODULES).failure(true, error.response)
      );
      return error.response;
    });
};

export const userStartsCourse = (dispatch, trainingEventId, courseId) => {
  dispatch(asyncActions(USER_STARTS_COURSE).loading(true));
  return axios({
    method: "POST",
    url: `${trainingURL.USER_STARTS_COURSE_URL}`,
    data: {
      courseId,
    },
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

export const startLesson = (dispatch, payload) => {
  const { trainingEventId, lessonId } = payload;
  dispatch(asyncActions(USER_STARTS_LESSON).loading(true));
  return axios({
    method: "post",
    url: `${trainingURL.USER_STARTS_LESSON_URL}/${lessonId}`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  })
    .then((response) => {
      dispatch(asyncActions(USER_STARTS_LESSON).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(USER_STARTS_LESSON).success(data));
        dispatch(asyncActions(USER_STARTS_LESSON).loading(false));
      }
      return response;
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 404) {
        dispatch(asyncActions(USER_STARTS_LESSON).success(null));
      }
      dispatch(asyncActions(USER_STARTS_LESSON).loading(false));
      dispatch(asyncActions(USER_STARTS_LESSON).failure(true, error.response));
      return error.response;
    });
};

export const completeLesson = (dispatch, payload) => {
  const { trainingEventId, lessonId } = payload;
  dispatch(asyncActions(USER_COMPLETES_LESSON).loading(true));
  return axios({
    method: "patch",
    url: `${trainingURL.USER_COMPLETES_LESSON_URL}/${lessonId}`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  })
    .then((response) => {
      dispatch(asyncActions(USER_COMPLETES_LESSON).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(USER_COMPLETES_LESSON).success(data));
        dispatch(asyncActions(USER_COMPLETES_LESSON).loading(false));
      }
      return response;
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 404) {
        dispatch(asyncActions(USER_COMPLETES_LESSON).success(null));
      }
      dispatch(asyncActions(USER_COMPLETES_LESSON).loading(false));
      dispatch(
        asyncActions(USER_COMPLETES_LESSON).failure(true, error.response)
      );
      return error.response;
    });
};

export const hasCompletedLesson = (dispatch, payload) => {
  const { trainingEventId, lessonId } = payload;
  dispatch(asyncActions(HAS_COMPLETED_LESSON).loading(true));
  return axios({
    method: "get",
    url: `${trainingURL.HAS_COMPLETED_LESSON_URL}/${lessonId}/lesson`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  })
    .then((response) => {
      dispatch(asyncActions(HAS_COMPLETED_LESSON).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(HAS_COMPLETED_LESSON).success(data));
        dispatch(asyncActions(HAS_COMPLETED_LESSON).loading(false));
      }
      return response;
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 404) {
        dispatch(asyncActions(HAS_COMPLETED_LESSON).success(null));
      }
      dispatch(asyncActions(HAS_COMPLETED_LESSON).loading(false));
      dispatch(
        asyncActions(HAS_COMPLETED_LESSON).failure(true, error.response)
      );
      return error.response;
    });
};

export const getUserCourseModules = (dispatch, payload) => {
  const { trainingEventId, courseId } = payload;

  dispatch(asyncActions(GET_USER_TRAINING_MODULES).loading(true));
  return axios({
    method: "get",
    url: `${trainingURL.Get_TRAINING_COURSE_MODULES}/${courseId}`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  })
    .then((response) => {
      dispatch(asyncActions(GET_USER_TRAINING_MODULES).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        // console.log(data);
        dispatch(asyncActions(GET_USER_TRAINING_MODULES).success(data));
        dispatch(asyncActions(GET_USER_TRAINING_MODULES).loading(false));
      }
      return response;
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 404) {
        dispatch(asyncActions(GET_USER_TRAINING_MODULES).success(null));
      }
      dispatch(asyncActions(GET_USER_TRAINING_MODULES).loading(false));
      dispatch(
        asyncActions(GET_USER_TRAINING_MODULES).failure(true, error.response)
      );
      return error.response;
    });
};

export const getUserCourses = (dispatch, trainingEventId, courseId) => {
  // const user = get_user_info_from_token().user;
  // dispatch(asyncActions(USER_STARTS_COURSE).loading(true));
  return axios({
    method: "GET",
    url: `${trainingURL.GET_USER_COURSE_PROGRESS}/${courseId}`,
    // url: `${trainingURL.USER_STARTS_COURSE_URL}/${user.id}/progress/${courseId}`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

// question and answer
export const postQAQuestion = (
  dispatch,
  trainingEventId,
  payload,
  courseId
) => {
  return axios({
    method: "POST",
    url: `${trainingURL.QUESTION_AND_ANSWER}/course/${courseId}`,
    data: payload,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

export const getQAPost = (
  dispatch,
  trainingEventId,
  payload,
  courseId,
  loading = true
) => {
  const { pageNumber, searchkey, clearInitialState } = payload;

  let url = `${trainingURL.QUESTION_AND_ANSWER}/course/${courseId}?PageNumber=${pageNumber}&maxItem=10`;
  if (searchkey)
    url = updateQueryStringParameter(url, "searchContent", searchkey);
  if (pageNumber === 1)
    dispatch(
      asyncActions(GET_TRAINING_QUESTION_AND_ANSWER).success({
        data: [],
        page: { pageNumber },
        clearInitialState: true,
      })
    );

  // dispatch(asyncActions(GET_TRAINING_QUESTION_AND_ANSWER).loading(loading));
  return axios({
    method: "GET",
    url,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  })
    .then((response) => {
      dispatch(asyncActions(GET_TRAINING_QUESTION_AND_ANSWER).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        // console.log({ ...data, clearInitialState });
        dispatch(
          asyncActions(GET_TRAINING_QUESTION_AND_ANSWER).success({
            ...data,
            clearInitialState,
          })
        );
        dispatch(asyncActions(GET_TRAINING_QUESTION_AND_ANSWER).loading(false));
        return { status: response.status, data };
      }
      return response;
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 404) {
        dispatch(asyncActions(GET_TRAINING_QUESTION_AND_ANSWER).success(null));
      }
      dispatch(asyncActions(GET_TRAINING_QUESTION_AND_ANSWER).loading(false));
      dispatch(
        asyncActions(GET_TRAINING_QUESTION_AND_ANSWER).failure(
          true,
          error.response
        )
      );
      return error.response;
    });
};

export const getQAndALikes = (postId, trainingEventId) => {
  return axios({
    method: "GET",
    url: `${trainingURL.QUESTION_AND_ANSWER}/${postId}/likes/user`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

export const deleteQAnAPost = (postId, trainingEventId) => {
  return axios({
    method: "delete",
    url: `${trainingURL.QUESTION_AND_ANSWER}/${postId}`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};
export const deleteQAnAPostComment = (commentId, trainingEventId) => {
  return axios({
    method: "delete",
    url: `${trainingURL.QUESTION_AND_ANSWER}/comments/${commentId}`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

export const upateQAnAPostComment = (commentId, trainingEventId, payload) => {
  return axios({
    method: "patch",
    url: `${trainingURL.QUESTION_AND_ANSWER}/comments/${commentId}`,
    data: payload,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};
export const likeQAndAPost = (postId, trainingEventId) => {
  return axios({
    method: "POST",
    url: `${trainingURL.QUESTION_AND_ANSWER}/${postId}/likes`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

export const unLikeQAndAPost = (postId, trainingEventId) => {
  return axios({
    method: "delete",
    url: `${trainingURL.QUESTION_AND_ANSWER}/${postId}/likes`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

export const postQAQuestionComment = (dispatch, trainingEventId, payload) => {
  return axios({
    method: "POST",
    url: `${trainingURL.QUESTION_AND_ANSWER}/comments`,
    data: payload,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

export const getQAQuestionComment = (dispatch, trainingEventId, postId) => {
  return axios({
    method: "GET",
    url: `${trainingURL.QUESTION_AND_ANSWER}/${postId}/comments`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

export const getQAQuestionCommentUserLike = (commentId, trainingEventId) => {
  return axios({
    method: "GET",
    url: `${trainingURL.QUESTION_AND_ANSWER}/comments/${commentId}/like/user`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};
export const likeQAQuestionComment = (commentId, trainingEventId) => {
  return axios({
    method: "POST",
    url: `${trainingURL.QUESTION_AND_ANSWER}/comments/${commentId}/like`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

// export const unlikeQAQuestionComment = (commentId, trainingEventId) => {
//   return axios({
//     method: "DELETE",
//     url: `${trainingURL.QUESTION_AND_ANSWER}/comments/${commentId}/like`,
//     headers: {
//       "trainingevent-id": `${trainingEventId}`,
//     },
//   });
// };

export const getPaticipationAgreement = (
  programEventId,
  lang,
  componentType
) => {
  return axios({
    method: "get",
    url: `${ParticipationAgreementUrl.GET_PARTICIPATION_AGREEMENT}/${programEventId}?componentType=${componentType}&lang=${lang}`,
  });
};
export const startModule = (dispatch, payload) => {
  const { trainingEventId, moduleId } = payload;
  dispatch(asyncActions(USER_STARTS_MODULE).loading(true));
  return axios({
    method: "post",
    url: trainingURL.USER_STARTS_MODULE_URL,
    data: {
      moduleId,
    },
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  })
    .then((response) => {
      dispatch(asyncActions(USER_STARTS_MODULE).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(USER_STARTS_MODULE).success(data));
        dispatch(asyncActions(USER_STARTS_MODULE).loading(false));
      }
      return response;
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 404) {
        dispatch(asyncActions(USER_STARTS_MODULE).success(null));
      }
      dispatch(asyncActions(USER_STARTS_MODULE).loading(false));
      dispatch(asyncActions(USER_STARTS_MODULE).failure(true, error.response));
      return error.response;
    });
};

export const completedModule = (dispatch, payload) => {
  const { trainingEventId, moduleId } = payload;
  dispatch(asyncActions(USER_COMPLETES_MODULE).loading(true));
  return axios({
    method: "put",
    url: `${trainingURL.USER_COMPLETES_MODULE_URL}/${moduleId}`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  })
    .then((response) => {
      dispatch(asyncActions(USER_COMPLETES_MODULE).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(USER_COMPLETES_MODULE).success(data));
        dispatch(asyncActions(USER_COMPLETES_MODULE).loading(false));
      }
      return response;
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 404) {
        dispatch(asyncActions(USER_COMPLETES_MODULE).success(null));
      }
      dispatch(asyncActions(USER_COMPLETES_MODULE).loading(false));
      dispatch(
        asyncActions(USER_COMPLETES_MODULE).failure(true, error.response)
      );
      return error.response;
    });
};

export const completedCourse = (dispatch, payload) => {
  const { trainingEventId, courseId } = payload;
  dispatch(asyncActions(USER_COMPLETES_COURSE).loading(true));
  return axios({
    method: "POST",
    url: `${trainingURL.USER_COMPLETES_COURSE_URL}/${courseId}`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  })
    .then((response) => {
      dispatch(asyncActions(USER_COMPLETES_COURSE).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(USER_COMPLETES_COURSE).success(data));
        dispatch(asyncActions(USER_COMPLETES_COURSE).loading(false));
      }
      return response;
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 404) {
        dispatch(asyncActions(USER_COMPLETES_COURSE).success(null));
      }
      dispatch(asyncActions(USER_COMPLETES_COURSE).loading(false));
      dispatch(
        asyncActions(USER_COMPLETES_COURSE).failure(true, error.response)
      );
      return error.response;
    });
};

export const agreeToParticipate = (dispatch, payload, trainingEventId) => {
  dispatch(asyncActions(AGREE_TO_PARTICIPATE).loading(true));
  return axios({
    method: "POST",
    data: payload,
    url: trainingURL.AGREE_TO_PARTICIPATE_URL,
    headers: {
      "application-id": `${trainingEventId}`,
    },
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(
          asyncActions(AGREE_TO_PARTICIPATE).success(response.data.data)
        );
        dispatch(asyncActions(AGREE_TO_PARTICIPATE).loading(false));
      }
      return response;
    })
    .catch((error) =>
      dispatch(asyncActions(AGREE_TO_PARTICIPATE).failure(true, error))
    );
};

export const hasAgreedToParticipate = (dispatch, payload) => {
  const { programmeEventId } = payload;

  dispatch(asyncActions(HAS_AGREED_TO_PARTICIPATE).loading(true));
  return axios({
    method: "GET",
    url: `${trainingURL.HAS_AGREED_TO_PARTICIPATE_URL}`,
    headers: {
      "application-id": `${programmeEventId}`,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(
          asyncActions(HAS_AGREED_TO_PARTICIPATE).success(response.data.data)
        );
        dispatch(asyncActions(HAS_AGREED_TO_PARTICIPATE).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(HAS_AGREED_TO_PARTICIPATE).failure(true, error));
    });
};

export const getUserRatingOnCourse = (courseId, userId, trainingEventId) => {
  return axios({
    method: "get",
    url: `${trainingURL.TRAINING_GENERAL}/course-rating/user/${userId}/course/${courseId}`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

export const getRatingOnCourse = (courseId, trainingEventId) => {
  return axios({
    method: "get",
    url: `${trainingURL.TRAINING_GENERAL}/course-rating/course/${courseId}/average-rating`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

export const rateCourse = (payload, trainingEventId) => {
  return axios({
    method: "post",
    url: `${trainingURL.TRAINING_GENERAL}/course-rating`,
    data: payload,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

export const updateCourseRating = (payload, trainingEventId, id) => {
  return axios({
    method: "put",
    url: `${trainingURL.TRAINING_GENERAL}/course-rating/${id}`,
    data: payload,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

export const deleteUserCourseRating = (trainingEventId, id) => {
  return axios({
    method: "delete",
    url: `${trainingURL.TRAINING_GENERAL}/course-rating/${id}`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  });
};

export const getFaq = () => {
  return axios({
    method: "get",
    url: `${trainingURL.TRAINING_GENERAL}/faq`,
  });
};

export const getModuleCumulativeScore = (userId, moduleId, eventId) => {
  return axios({
    method: "get",
    url: `${trainingURL.TRAINING_GENERAL}/quiz/user/${userId}/module/${moduleId}/score`,
    headers: {
      "trainingevent-id": `${eventId}`,
    },
  });
};

export const getCourseCumulativeScore = (userId, courseId, eventId) => {
  return axios({
    method: "get",
    url: `${ApplicationUrl.GET_USER_TRAINING_SCORE}/user/${userId}/course/${courseId}/score`,
    headers: {
      "trainingevent-id": `${eventId}`,
    },
  });
};

export const getCourseAssessmentSummary = (
  trainingEventId,
  courseId,
  dispatch
) => {
  return axios({
    method: "get",
    url: `${trainingURL.TRAINING_GENERAL}/quiz/course/${courseId}/summary`,
    headers: {
      "trainingevent-id": `${trainingEventId}`,
    },
  })
    .then((res) => {
      dispatch(
        asyncActions(GET_TRAINING_COURSE_ASSESSMENT).success(res.data?.data)
      );
    })
    .catch((error) => {
      dispatch(
        asyncActions(GET_TRAINING_COURSE_ASSESSMENT).failure(true, error)
      );
    });
};
