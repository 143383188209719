/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Layout from "src/components/Layout/Layout";
import {
  getPrivateGroupInvitesReceived,
  AcceptInvite,
  CancelInvite,
  AcceptAllInvites,
} from "src/requests/CommunityRequest";
import { Context } from "src/store";
import SideRight from "src/modules/homepage/components/SideRight/SideRight";
import styles from "./InvitedUserRequest.module.scss";
import queryString from "query-string";
import { getUserProfile } from "src/requests/ProfileRequest";
import RequestCard from "./RequestCard/RequestCard";
import Loader from "src/components/Loader/Loader";
import ResultPagination from "src/modules/Community/components/ResultPagination/ResultPagination";
import { FaAngleLeft } from "react-icons/fa";

const InvitedUserRequest = () => {
  const { dispatch } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const [acceptAllLoading, setAcceptAllLoading] = useState(false);
  const [receivedInvites, setReceivedInvites] = useState([]);
  const [userProfiles, setUserProfiles] = useState([]);
  const qa = useLocation();
  const { groupId } = queryString.parse(qa.search);
  const [pager, setPager] = useState({
    firstItemOnPage: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    lastItemOnPage: 1,
    pageCount: 1,
    pageNumber: 1,
    pageSize: 1,
    totalItemCount: 0,
  });

  const [pageDetails, setPageDetails] = useState({
    pageSize: 16,
    pageNumber: pager.pageNumber,
  });

  const onPageSelect = (e) => {
    setIsLoading(true);
    setPageDetails({
      ...pageDetails,
      pageNumber: e.selected + 1,
    });
  };

  const _getReceivedInvites = () => {
    setIsLoading(true);
    getPrivateGroupInvitesReceived(
      dispatch,
      groupId,
      "pending_invite",
      pageDetails
    ).then((res) => {
      const {
        data: {
          data: { data, pager },
        },
      } = res;
      if (data.length === 0) setIsLoading(false);
      setPager(pager);
      setReceivedInvites(data);
    });
  };

  const getUserInfo = async (invites) => {
    const profileAsyncData = [];
    const profileData = [];

    invites.forEach((data) => {
      profileAsyncData.push(getUserProfile(dispatch, data.inviteeUserId));
    });

    const resp = await Promise.all(profileAsyncData);
    resp.forEach((res) => {
      const { data } = res.data;
      profileData.push(data);
    });

    setUserProfiles(profileData);
  };

  const handleAdmitUser = (id) => {
    AcceptInvite(dispatch, id).then((res) => {
      if (res.status === 201) {
        const invs = receivedInvites.filter((invites) => invites.id !== id);
        setReceivedInvites(invs);
      }
    });
  };

  const handleRejectUser = (id) => {
    CancelInvite(dispatch, id).then((res) => {
      if (res.status === 201) {
        const invs = receivedInvites.filter((invites) => invites.id !== id);
        setReceivedInvites(invs);
      }
    });
  };

  const handleAcceptAll = () => {
    setAcceptAllLoading(true);
    AcceptAllInvites(dispatch, groupId).then((res) => {
      if (res.status === 200) {
        setReceivedInvites([]);
        setAcceptAllLoading(false);
        setIsLoading(false);
        setPager({ ...pager, totalItemCount: 0 });
      }
    });
  };

  useEffect(() => {
    _getReceivedInvites();
  }, [pageDetails]);

  useEffect(() => {
    getUserInfo(receivedInvites);
  }, [receivedInvites]);

  useEffect(() => {
    if (userProfiles.length > 0) setIsLoading(false);
  }, [userProfiles]);

  let requestCards;

  if (userProfiles.length > 0) {
    requestCards = userProfiles.map((profile, index) => {
      const inviteId = receivedInvites[index] && receivedInvites[index].id;
      return (
        <RequestCard
          key={profile.userId}
          user={profile}
          inviteId={inviteId}
          accept={handleAdmitUser}
          cancel={handleRejectUser}
        />
      );
    });
  }

  return (
    <Layout SideContent={<SideRight />}>
      <div className={styles.InvitedUserRequest}>
        <Link
          to={`/community/group?groupId=${groupId}`}
          className="results-main__heading--back"
        >
          <FaAngleLeft /> &nbsp; Back
        </Link>
        <h1 className={styles.InvitedUserRequest__h1}>Pending Requests</h1>
        <div className={styles.InvitedUserRequest__btn}>
          <button
            onClick={handleAcceptAll}
            disabled={isLoading || receivedInvites.length === 0}
            className={styles.InvitedUserRequest__acceptAllBtn}
          >
            {acceptAllLoading ? (
              <Loader color="light" />
            ) : (
              `Admit All (${pager.totalItemCount})`
            )}
          </button>
        </div>
        {isLoading ? (
          <div className="results-main__loader">
            <Loader color="danger" />
          </div>
        ) : requestCards && requestCards.length > 0 ? (
          <>
            <section className={styles.InvitedUserRequest__section}>
              {requestCards}
            </section>
            <div className="results-main__pagination results-main__pagination--bottom">
              <ResultPagination
                pageCount={pager.pageCount}
                pageRange={1}
                onSelect={onPageSelect}
                current={pager.pageNumber}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </Layout>
  );
};

export default InvitedUserRequest;
