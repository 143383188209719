import React from "react";
import "./_CModal.scss";
import { MdCancel } from "react-icons/md";
const Modal = (props) => {
  const { closeModal, isOpen } = props;

  const closeicon = () => {
    return (
      <MdCancel
        style={{
          color: "#000000",
          // padding: "10px",
          cursor: "pointer",
          backgroundColor: "transparent",
          border: 0,
          position: "absolute",
          top: "0.3rem",
          right: "0.5rem",
          fontSize: "1.3rem",
        }}
        onClick={closeModal}
      />
    );
  };
  return (
    <>
      {isOpen && (
        <div className="overlay">
          <div className="content">
            {closeicon()}
            {props.children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
