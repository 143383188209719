import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import Content from "./Content/Content";
import classes from "./Layout.module.scss";
import Sidebar from "./Sidebar/Sidebar";
import SideDrawer from "./SideDrawer/SideDrawer";
import { Context } from "src/store";
import {
  completedCourse,
  getTrainingModule,
} from "src/requests/TrainingRequest";
import Empty from "src/modules/mentorship/Calendar/utils/Empty";
import Swal from "sweetalert2";
import Loader from "src/components/Loader/Loader";
import ReactLoader from "react-loader-spinner";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { confirmUserSurvey } from "src/requests/TrainingSurveyRequest";
import Survey from "../applicant/Banner/Survey/Survey";
import { getDefaultGUID, getLanguage } from "src/utils/helper";
import Header_v2 from "src/components/Layout/Header/Header_v2";

const surveyType = "post-training";

const Layout = ({ children, onCont }) => {
  const language = getLanguage() || "en";
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [trainingContent, setTrainingContent] = useState(null);
  const [quizDetails, setQuizDetails] = useState({});
  const [lessonComplete, setLessonComplete] = useState({
    status: false,
    no: 0,
  });
  const [moduleComplete, setModuleComplete] = useState({
    status: false,
    no: null,
  });
  const qa = useLocation();
  const history = useHistory();
  const { courseId, trainingEventId } = queryString.parse(qa.search);
  const { dispatch } = useContext(Context);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [loadingUserSurvey, setLoadingUserSurvey] = useState(false);
  const [survey, setSurvey] = useState(null);
  const _toggleSurveyModal = () => setShowSurveyModal(!showSurveyModal);

  const toggle = () => setShow(!show);

  useEffect(() => {
    const payload = {
      language,
      courseId,
      trainingEventId,
    };
    getTrainingModule(dispatch, payload).then((res) => {
      setTrainingContent(res);
      setIsLoading(false);
      if (res.length > 0) {
        getFirstPage(res);
      }
    });
  }, []);

  const onSubmitSurvey = () => {
    history.push(`/training/LMS?trainingEventId=${trainingEventId}`);
  };

  const _confirmUserSurvey = () => {
    setLoadingUserSurvey(true);
    confirmUserSurvey(language, trainingEventId, surveyType)
      .then((res) => {
        if (res.status === 200 && res.data.data.data) {
          const result = res.data.data.data;
          setSurvey(result);
          _toggleSurveyModal();
        }
        setLoadingUserSurvey(false);
      })
      .catch(() => {
        setLoadingUserSurvey(false);
      });
  };

  const onQuizClicked = (value) => setQuizDetails(value);
  const onNextClicked = (currentModuleNo, currentLessonNo, status, cont) => {
    if (cont) {
      const nextModuleDetails = trainingContent.find(
        (content) => content.moduleNo === parseInt(currentModuleNo) + 1
      );

      if (nextModuleDetails) {
        if (nextModuleDetails.moduleLessons.length === 0) {
          if (nextModuleDetails.moduleQuiz) {
            setQuizDetails(nextModuleDetails.moduleQuiz);
            const link = `/training/lms/course?courseId=${courseId}&moduleId=${nextModuleDetails.id}&quizId=${nextModuleDetails.moduleQuiz.quizId}&trainingEventId=${trainingEventId}&lang=${language}&title=${nextModuleDetails.moduleTitles[0].title}&moduleNo=${nextModuleDetails.moduleNo}&lessonsCompleted=true`;
            history.push(link);
          }
        } else {
          const lessonDetails = nextModuleDetails.moduleLessons.find(
            (lesson) => lesson.lessonNo === 1
          );
          const totalLessons = nextModuleDetails.moduleLessons.length;
          if (lessonDetails) {
            const link =
              totalLessons === lessonDetails.lessonNo &&
              !nextModuleDetails.moduleQuiz
                ? `/training/lms/course?courseId=${nextModuleDetails.courseId}&moduleId=${nextModuleDetails.id}&lessonId=${lessonDetails.lessonId}&trainingEventId=${trainingEventId}&lang=${language}&title=${nextModuleDetails.moduleTitles[0].title}&moduleNo=${nextModuleDetails.moduleNo}&lessonNo=${lessonDetails.lessonNo}&lessonsCompleted=${status}&last=1`
                : `/training/lms/course?courseId=${nextModuleDetails.courseId}&moduleId=${nextModuleDetails.id}&lessonId=${lessonDetails.lessonId}&trainingEventId=${trainingEventId}&lang=${language}&title=${nextModuleDetails.moduleTitles[0].title}&moduleNo=${nextModuleDetails.moduleNo}&lessonNo=${lessonDetails.lessonNo}&lessonsCompleted=${status}`;
            history.push(link);
          } else {
            if (nextModuleDetails.moduleQuiz) {
              setQuizDetails(nextModuleDetails.moduleQuiz);
              const link = `/training/lms/course?courseId=${courseId}&moduleId=${nextModuleDetails.id}&quizId=${nextModuleDetails.moduleQuiz.quizId}&trainingEventId=${trainingEventId}&lang=${language}&title=${nextModuleDetails.moduleTitles[0].title}&moduleNo=${nextModuleDetails.moduleNo}&lessonsCompleted=${status}`;
              history.push(link);
            }
          }
        }
      } else {
        const modulesCompleted = trainingContent.find(
          (content) => content.completedOn === null
        );

        if (!modulesCompleted) {
          completedCourse(dispatch, { trainingEventId, courseId }).then(
            (res) => {
              Swal.fire({
                icon: "success",
                title: "Congratulations",
                text: "You have successfully completed this course",
                confirmButtonColor: "red",
              }).then((result) => {
                if (trainingEventId !== getDefaultGUID()) {
                  return _confirmUserSurvey();
                } else {
                  history.push(
                    `/training/LMS?trainingEventId=${trainingEventId}`
                  );
                }
              });
            }
          );
        } else {
          Swal.fire({
            icon: "success",
            title: "Congratulations",
            text: "You have successfully completed this course",
            confirmButtonColor: "red",
          }).then((result) => {
            if (trainingEventId !== getDefaultGUID()) {
              return _confirmUserSurvey();
            } else {
              history.push(`/training/LMS?trainingEventId=${trainingEventId}`);
            }
          });
        }
      }
    } else {
      const moduleDetails = trainingContent.find(
        (content) => content.moduleNo === parseInt(currentModuleNo)
      );
      const totalLessons = moduleDetails.moduleLessons.length;
      const lessonDetails = moduleDetails.moduleLessons.find(
        (lesson) => lesson.lessonNo === parseInt(currentLessonNo) + 1
      );
      if (lessonDetails) {
        const link =
          totalLessons === lessonDetails.lessonNo && !moduleDetails.moduleQuiz
            ? `/training/lms/course?courseId=${moduleDetails.courseId}&moduleId=${moduleDetails.id}&lessonId=${lessonDetails.lessonId}&trainingEventId=${trainingEventId}&lang=${language}&title=${moduleDetails.moduleTitles[0].title}&moduleNo=${moduleDetails.moduleNo}&lessonNo=${lessonDetails.lessonNo}&lessonsCompleted=${status}&last=1`
            : `/training/lms/course?courseId=${moduleDetails.courseId}&moduleId=${moduleDetails.id}&lessonId=${lessonDetails.lessonId}&trainingEventId=${trainingEventId}&lang=${language}&title=${moduleDetails.moduleTitles[0].title}&moduleNo=${moduleDetails.moduleNo}&lessonNo=${lessonDetails.lessonNo}&lessonsCompleted=${status}`;
        history.push(link);
      } else {
        if (moduleDetails.moduleQuiz) {
          setQuizDetails(moduleDetails.moduleQuiz);
          const link = `/training/lms/course?courseId=${courseId}&moduleId=${moduleDetails.id}&quizId=${moduleDetails.moduleQuiz.quizId}&trainingEventId=${trainingEventId}&lang=${language}&title=${moduleDetails.moduleTitles[0].title}&moduleNo=${moduleDetails.moduleNo}&lessonsCompleted=${status}`;
          history.push(link);
        } else {
          const nextModuleDetails = trainingContent.find(
            (content) => content.moduleNo === parseInt(currentModuleNo) + 1
          );
          if (nextModuleDetails) {
            if (nextModuleDetails.moduleLessons.length === 0) {
              if (nextModuleDetails.moduleQuiz) {
                setQuizDetails(nextModuleDetails.moduleQuiz);
                const link = `/training/lms/course?courseId=${courseId}&moduleId=${nextModuleDetails.id}&quizId=${nextModuleDetails.moduleQuiz.quizId}&trainingEventId=${trainingEventId}&lang=${language}&title=${nextModuleDetails.moduleTitles[0].title}&moduleNo=${nextModuleDetails.moduleNo}&lessonsCompleted=true`;
                history.push(link);
              }
            } else {
              const lessonDetails = nextModuleDetails.moduleLessons[0];
              const totalLessons = nextModuleDetails.moduleLessons.length;

              if (lessonDetails) {
                const link =
                  totalLessons === lessonDetails.lessonNo &&
                  !moduleDetails.moduleQuiz
                    ? `/training/lms/course?courseId=${nextModuleDetails.courseId}&moduleId=${nextModuleDetails.id}&lessonId=${lessonDetails.lessonId}&trainingEventId=${trainingEventId}&lang=${language}&title=${nextModuleDetails.moduleTitles[0].title}&moduleNo=${nextModuleDetails.moduleNo}&lessonNo=${lessonDetails.lessonNo}&lessonsCompleted=${status}&last=1`
                    : `/training/lms/course?courseId=${nextModuleDetails.courseId}&moduleId=${nextModuleDetails.id}&lessonId=${lessonDetails.lessonId}&trainingEventId=${trainingEventId}&lang=${language}&title=${nextModuleDetails.moduleTitles[0].title}&moduleNo=${nextModuleDetails.moduleNo}&lessonNo=${lessonDetails.lessonNo}&lessonsCompleted=${status}`;
                history.push(link);
              } else {
                if (nextModuleDetails.moduleQuiz) {
                  setQuizDetails(nextModuleDetails.moduleQuiz);
                  const link = `/training/lms/course?courseId=${courseId}&moduleId=${nextModuleDetails.id}&quizId=${nextModuleDetails.moduleQuiz.quizId}&trainingEventId=${trainingEventId}&lang=${language}&title=${nextModuleDetails.moduleTitles[0].title}&moduleNo=${nextModuleDetails.moduleNo}&lessonsCompleted=${status}`;
                  history.push(link);
                }
              }
            }
          } else {
            completedCourse(dispatch, { trainingEventId, courseId }).then(
              (res) => {
                Swal.fire({
                  icon: "success",
                  title: "Congratulations",
                  text: "You have successfully completed this course",
                  confirmButtonColor: "red",
                }).then((result) => {
                  if (trainingEventId !== getDefaultGUID()) {
                    _confirmUserSurvey();
                  } else {
                    history.push(
                      `/training/LMS?trainingEventId=${trainingEventId}`
                    );
                  }
                });
              }
            );
          }
        }
      }
    }
  };

  const getFirstPage = (data) => {
    const firstModuleDetails = data[0];
    if (firstModuleDetails && firstModuleDetails.moduleLessons.length === 0) {
      if (firstModuleDetails.moduleQuiz) {
        setQuizDetails(firstModuleDetails.moduleQuiz);
        const link = `/training/lms/course?courseId=${courseId}&moduleId=${firstModuleDetails.id}&quizId=${firstModuleDetails.moduleQuiz.quizId}&trainingEventId=${trainingEventId}&lang=${language}&title=${firstModuleDetails.moduleTitles[0].title}&moduleNo=${firstModuleDetails.moduleNo}&lessonsCompleted=true`;
        history.push(link);
      }
    } else {
      const lessonLength = firstModuleDetails.moduleLessons.length;
      const moduleQuiz =
        firstModuleDetails.moduleQuiz &&
        Object.keys(firstModuleDetails.moduleQuiz).length;
      const firstLessonDetails = firstModuleDetails.moduleLessons[0];
      const title =
        firstModuleDetails.moduleTitles.length > 0
          ? firstModuleDetails.moduleTitles[0].title
          : "title";

      const link =
        lessonLength === 1 && (moduleQuiz === null || moduleQuiz === 0)
          ? `/training/lms/course?courseId=${
              firstModuleDetails.courseId
            }&moduleId=${firstModuleDetails.id}&lessonId=${
              firstLessonDetails.lessonId
            }&trainingEventId=${trainingEventId}&lang=${language}&title=${title}&moduleNo=${
              firstModuleDetails.moduleNo
            }&lessonNo=${
              firstLessonDetails.lessonNo
            }&lessonsCompleted=${!!firstLessonDetails.completedOn}&last=1`
          : `/training/lms/course?courseId=${
              firstModuleDetails.courseId
            }&moduleId=${firstModuleDetails.id}&lessonId=${
              firstLessonDetails.lessonId
            }&trainingEventId=${trainingEventId}&lang=${language}&title=${title}&moduleNo=${
              firstModuleDetails.moduleNo
            }&lessonNo=${
              firstLessonDetails.lessonNo
            }&lessonsCompleted=${!!firstLessonDetails.completedOn}`;
      history.push(link);
    }
  };

  return (
    <div className={`notranslate ${classes.Layout}`}>
      <Header_v2 onToggleDrawer={toggle} />
      {isLoading ? (
        <div className={classes.Layout__loader}>
          <Loader color="danger" />
        </div>
      ) : trainingContent && trainingContent.length > 0 ? (
        <>
          <SideDrawer
            show={show}
            onClose={toggle}
            details={trainingContent}
            trainingEventId={trainingEventId}
            onQuizClick={onQuizClicked}
            lessonComplete={lessonComplete}
            moduleComplete={moduleComplete}
          />
          <div className={`${classes.Layout__container} notranslate`}>
            <Sidebar
              details={trainingContent}
              trainingEventId={trainingEventId}
              lang={language}
              onQuizClick={onQuizClicked}
              lessonComplete={lessonComplete}
              moduleComplete={moduleComplete}
            />
            <div className={`${classes.Layout__mainContent} notranslate`}>
              {children || (
                <Content
                  quizInfo={quizDetails}
                  clicked={onNextClicked}
                  onQuizClick={onQuizClicked}
                  setLessonComplete={setLessonComplete}
                  setModuleComplete={setModuleComplete}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={classes.Layout__emptyContent}>
          <Empty
            message="No lessons has been added to this course yet"
            linkText="back to dashboard"
            linkTo={`/training/LMS?trainingEventId=${trainingEventId}`}
          />
        </div>
      )}

      <React.Fragment>
        <Modal isOpen={showSurveyModal} size="lg">
          <ModalHeader>
            <span style={{ fontWeight: "bolder", fontSize: "18px" }}>
              {survey && survey.overview.title}
            </span>
          </ModalHeader>
          <ModalBody>
            <Survey
              survey={survey}
              toggle={_toggleSurveyModal}
              onSumbit={onSubmitSurvey}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>

      <React.Fragment>
        <Modal isOpen={loadingUserSurvey} centered size="md">
          <ModalBody style={{ display: "flex", justifyContent: "center" }}>
            <ReactLoader
              type="Oval"
              color="var(--tef-red)"
              height={35}
              width={35}
              style={{ margin: "0 auto" }}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>
    </div>
  );
};

export default Layout;
