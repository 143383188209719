// add fields you need to validate here
const validation = {
  email: {
    presence: {
      message: "^Please enter an email address",
    },
    email: {
      message: "^Please enter a valid email address",
    },
  },

  password: {
    presence: {
      message: "^Please enter a password",
    },
    length: {
      minimum: 8,
      message: "^Your password must be at least 8 characters",
    },
  },

  middleName: {
    presence: {
      message: "^Please enter middle name",
    },
    length: {
      minimum: 1,
      message: "^Please enter middle name",
    },
  },

  firstName: {
    presence: {
      message: "^Please enter first name",
    },
    length: {
      minimum: 1,
      message: "^Please enter first name",
    },
  },

  lastName: {
    presence: {
      message: "^Please enter last name",
    },
    length: {
      minimum: 1,
      message: "^Please enter last name",
    },
  },

  //  <=====onboarding validations =====>
  bio: {
    presence: {
      message: "^Please enter bio",
    },
    length: {
      minimum: 50,
      message: "^Your bio must be at least 50 characters",
      // message: "^Please enter bio",
    },
  },
  gender: {
    presence: {
      message: "^Please select gender",
    },
    length: {
      minimum: 1,
      message: "^Please select gender",
      // message: "^Please enter bio",
    },
  },
  dateOfBirth: {
    presence: {
      message: "^Please select Date of Birth",
    },
    length: {
      minimum: 1,
      message: "^Please select Date of Birth",
      // message: "^Please enter bio",
    },
  },
  phone: {
    presence: {
      message: "^Please enter valid number",
    },
    length: {
      minimum: 10,
      message: "^Please enter valid number",
      // message: "^Please enter bio",
    },
    // format: {
    //   // pattern: /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/,
    //   pattern: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
    //   message: "Invalid phone number",
    // },
  },

  phoneNumber: {
    presence: {
      message: "^Please enter valid number",
    },
    length: {
      minimum: 10,
      message: "^Please enter valid number",
      // message: "^Please enter bio",
    },
    // format: {
    //   // pattern: /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/,
    //   pattern: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
    //   message: "Invalid phone number",
    // },
  },

  levelOfEducation: {
    presence: {
      message: "^Please select level of education",
    },
    length: {
      minimum: 1,
      message: "^Please select level of education",
      // message: "^Please enter bio",
    },
  },

  typeOfId: {
    presence: {
      message: "^Please select type of id you wish to upload",
    },
    length: {
      minimum: 1,
      message: "^Please select type of id you wish to upload",
      // message: "^Please enter bio",
    },
  },

  idNumber: {
    presence: {
      message: "^Please enter ID Number",
    },
    length: {
      minimum: 1,
      message: "^Please enter ID Number",
      // message: "^Please enter bio",
    },
  },

  yearsOfExperience: {
    presence: {
      message: "^Please specify years of experience",
    },
    length: {
      minimum: 1,
      message: "^Please specify years of experience",
    },
  },

  howYouHearAboutUs: {
    presence: {
      message: "^Please tell us how you heard about us",
    },
    length: {
      minimum: 1,
      message: "^Please tell us how you heard about us",
    },
  },

  kinPhone: {
    presence: {
      message: "^Please enter valid number",
    },
    length: {
      minimum: 10,
      message: "^Please enter valid number",
    },
  },

  mentorPhone: {
    presence: {
      message: "^Please enter valid number",
    },
    length: {
      minimum: 10,
      message: "^Please enter valid number",
    },
  },

  kinEmail: {
    // presence: {
    //   message: "^Please enter an email address",
    // },
    email: {
      message: "^Please enter a valid email address",
    },
  },

  mentorEmail: {
    // presence: {
    //   message: "^Please enter an email address",
    // },
    email: {
      message: "^Please enter a valid email address",
    },
  },

  businessIndustry: {
    presence: {
      message: "^Please select Industry Category",
    },
    length: {
      minimum: 1,
      message: "^Please select Industry Category",
    },
  },

  businessName: {
    presence: {
      message: "^Please Enter Business Name",
    },
    length: {
      minimum: 1,
      message: "^Please Enter Business Name",
    },
  },

  nameOfOrganization: {
    presence: {
      message: "^Please Enter name of organization",
    },
    length: {
      minimum: 1,
      message: "^Please Enter name of organization",
    },
  },

  // isResidentialRural: {
  //   // presence: {
  //   //   message: "^Please select",
  //   // },
  //   // type: "boolean",
  //   type: {
  //     types: "boolean",
  //     message: "^Please select",
  //   },
  // },

  aboutIdea: {
    presence: {
      message: "^Please tell us about your business idea",
    },
    length: {
      minimum: 50,
      message: "^Please Enter a minimum of 50 characters",
    },
  },

  yearsOfStartupExperience: {
    presence: {
      message: "^Please tell us how many years of start up experience you have",
    },
    length: {
      minimum: 1,
      message: "^Please tell us how many years of start up experience you have",
    },
  },

  sdgs: {
    presence: {
      message: "^Select atleast 1 SDG",
    },
    length: {
      minimum: 1,
      maximum: 3,
      message: "^Select atleast 1 SDG",
    },
  },

  idUrl: {
    presence: {
      message: "^Please upload mode of identification",
    },
    length: {
      minimum: 1,
      message: "^Please upload mode of identification",
    },
  },
  aboutBusiness: {
    presence: {
      message: "^Please tell us about your business",
    },
    length: {
      minimum: 50,
      message: "^Please Enter a minimum of 50 characters",
    },
  },

  businessAddress: {
    presence: {
      message: "^Please tell us where your business is located",
    },
    length: {
      minimum: 1,
      message: "^Please tell us where your business is located",
    },
  },

  businessLocation: {
    presence: {
      message: "^Please tell us where your business is located",
    },
    length: {
      minimum: 1,
      message: "^Please tell us where your business is located",
    },
  },
  businessAge: {
    presence: {
      message: "^Please tell us how old your business is",
    },
    length: {
      minimum: 1,
      message: "^Please tell us how old your business is",
    },
  },
  businessCountry: {
    presence: {
      message: "^Please select the country your business is located",
    },
    length: {
      minimum: 1,
      message: "^Please select the country your business is located",
    },
  },

  businessCountryId: {
    presence: {
      message: "^Please select the country your business is located",
    },
    length: {
      minimum: 1,
      message: "^Please select the country your business is located",
    },
  },

  industrySubSector: {
    presence: {
      message: "^Please select your business industry sub-sector",
    },
    length: {
      minimum: 1,
      message: "^Please select your business industry sub-sector",
    },
  },

  businessState: {
    presence: {
      message: "^In which state is your business located",
    },
    length: {
      minimum: 1,
      message: "^In which state is your business located",
    },
  },
  businessCity: {
    presence: {
      message: "^In which city is your business located",
    },
    length: {
      minimum: 1,
      message: "^In which city is your business located",
    },
  },

  country: {
    presence: {
      message: "^Please select your country of residence",
    },
    length: {
      minimum: 1,
      message: "^Please select your country of residence",
    },
  },

  countryId: {
    presence: {
      message: "^Please select your country of residence",
    },
    length: {
      minimum: 1,
      message: "^Please select your country of residence",
    },
  },

  state: {
    presence: {
      message: "^In which state are you located",
    },
    length: {
      minimum: 1,
      message: "^In which state are you located",
    },
  },
  city: {
    presence: {
      message: "^In which city are you located",
    },
    length: {
      minimum: 1,
      message: "^In which city are you located",
    },
  },
  address: {
    presence: {
      message: "^Please enter your residential address",
    },
    length: {
      minimum: 1,
      message: "^Please enter your residential address",
    },
  },

  facebookUrl: {
    url: {
      message:
        "should be a valid url and should start with http:// or https://",
    },
  },
  websiteUrl: {
    url: {
      message:
        "should be a valid url and should start with http:// or https://",
    },
  },
  twitterUrl: {
    url: {
      message:
        "should be a valid url and should start with http:// or https://",
    },
  },
  linkedinUrl: {
    url: {
      message:
        "should be a valid url and should start with http:// or https://",
    },
  },

  businessWebsite: {
    url: true,
  },
  url: {
    url: {
      message:
        "should be a valid url and should start with http:// or https://",
    },
  },

  youtubeUrl: {
    url: {
      message:
        "should be a valid url and should start with http:// or https://",
    },
  },
  instagramUrl: {
    url: {
      message:
        "should be a valid url and should start with http:// or https://",
    },
  },

  // tefMentor
  title: {
    presence: {
      message: "^Please select title",
    },
    length: {
      minimum: 1,
      message: "^Please select title",
    },
  },

  organization: {
    presence: {
      message: "^Please enter name of organization",
    },
    length: {
      minimum: 1,
      message: "^Please enter name of organization",
      // message: "^Please enter bio",
    },
  },
  religion: {
    presence: {
      message: "^Please select religion",
    },
    length: {
      minimum: 1,
      message: "^Please select religion",
      // message: "^Please enter bio",
    },
  },
  profileUrl: {
    url: {
      message:
        "should be a valid url and should start with http:// or https://",
    },
  },
  mentorshipLanguage: {
    presence: {
      message: "^Please select the language you would like to mentor in",
    },
    length: {
      minimum: 1,
      message: "^Please select language you would to mentor in",
      // message: "^Please enter bio",
    },
  },
  languages: {
    length: {
      minimum: 1,
      message: "^Please select",
      // message: "^Please enter bio",
    },

    presence: {
      message: "^Please select",
    },
  },
  qualities: {
    length: {
      minimum: 1,
      message: "^Please select 3 qualities that most relate to you",
      // message: "^Please enter bio",
    },
  },
  industries: {
    length: {
      minimum: 1,
      message: "^Please select industries you have experience in",
      // message: "^Please enter bio",
    },
  },
  yearOfTefMentorship: {
    presence: {
      message: "^Please select how long you have been a TEF mentor for",
    },
    length: {
      minimum: 1,
      message: "^Please select how long you have been a TEF mentor for",
      // message: "^Please enter bio",
    },
  },
  recommendationLevel: {
    presence: {
      message: "^how would you likely recommend TEF to others",
    },
    numericality: {
      greaterThan: 0,
      message: "^Please select",
    },
  },
  reasonForRating: {
    presence: {
      message: "^Please provide reason for rating",
    },
    length: {
      minimum: 1,
      message: "^Please provide reason for rating",
      // message: "^Please enter bio",
    },
  },
  noOfPeopleMentored: {
    presence: {
      message: "^Please select",
    },
    length: {
      minimum: 1,
      message: "^Please select",
      // message: "^Please enter bio",
    },
  },
  yearOfMentorship: {
    presence: {
      message: "^Please select",
    },
    length: {
      minimum: 1,
      message: "^Please select",
      // message: "^Please enter bio",
    },
  },
  availabilityHour: {
    presence: {
      message: "^Please select",
    },
    length: {
      minimum: 1,
      message: "^Please select",
      // message: "^Please enter bio",
    },
  },
  motivation: {
    presence: {
      message:
        "^Please tell us about what motivates you to apply for this programme",
    },
    length: {
      minimum: 50,
      message: "^Your motivation must be at least 50 characters",
      // message: "^Please enter bio",
    },
  },
  maxNoOfEntrepreneurs: {
    presence: {
      message: "^Please select",
    },

    numericality: {
      greaterThan: 0,
      message: "^Please select",
    },
  },
  howYouFindUs: {
    presence: {
      message: "^Please select",
    },
    length: {
      minimum: 1,
      message: "^Please select",
      // message: "^Please enter bio",
    },
  },
  preferedModeOfCommunication: {
    presence: {
      message: "^Please select",
    },
    length: {
      minimum: 1,
      message: "^Please select",
      // message: "^Please enter bio",
    },
  },

  // investor

  role: {
    presence: {
      message: "^Please select your roles as investor",
    },
    length: {
      minimum: 1,
      message: "^Please select your roles as investor",
      // message: "^Please enter bio",
    },
  },
  previousInvestment: {
    presence: {
      message: "^Please select previous investment history",
    },
    length: {
      minimum: 1,
      message: "^Please select previous investment history",
      // message: "^Please enter bio",
    },
  },
  folioSize: {
    presence: {
      message: "^Please select folio size",
    },
    length: {
      minimum: 1,
      message: "^Please select folio size",
      // message: "^Please enter bio",
    },
  },

  startDate: {
    presence: {
      message: "^Please specify the start date for this event.",
    },
    length: {
      minimum: 1,
      message: "^Please specify the start date for this event.",
    },
  },
  endDate: {
    presence: {
      message: "^Please specify the end date for this event.",
    },
    length: {
      minimum: 1,
      message: "^Please specify the end date for this event.",
    },
  },
  privacy: {
    presence: {
      message: "^Please select privacy.",
    },
    length: {
      minimum: 1,
      message: "^Please select privacy.",
    },
  },
  userGroup: {
    presence: {
      message: "^Please select user group.",
    },
    length: {
      minimum: 1,
      message: "^Please select user group.",
    },
  },
  opportunityTitle: {
    presence: {
      message: "^Please Enter title",
    },
    length: {
      minimum: 1,
      message: "^Please Enter title",
    },
  },
  shortSummary: {
    presence: {
      message: "^Please give a short summary of opportunity.",
    },
    length: {
      minimum: 1,
      message: "^Please give a short summary of opportunity.",
    },
  },
  hubId: {
    presence: {
      message: "^Please select hub.",
    },
    length: {
      minimum: 1,
      message: "^Please select hub.",
    },
  },
  countryAll: {
    presence: {
      message: "^Please select country.",
    },
    length: {
      minimum: 1,
      message: "^Please select country.",
    },
  },
  fullDescription: {
    presence: {
      message: "^Please give a full description of opportunity.",
    },
    length: {
      minimum: 1,
      message: "^Please give a full description of opportunity.",
    },
  },

  eligibilityRequirement: {
    presence: {
      message: "^Please provide the criteria for selection.",
    },
    length: {
      minimum: 1,
      message: "^Please provide the criteria for selection.",
    },
  },
  categoryId: {
    presence: {
      message: "^please select the catgory of this opportunity.",
    },
    length: {
      minimum: 1,
      message: "^please select the catgory of this opportunity.",
    },
  },

  deadline: {
    presence: {
      message: "^Please specify the deadline for this opportunity.",
    },
    length: {
      minimum: 1,
      message: "^Please specify the deadline for this opportunity.",
    },
  },
  opportunityLink: {
    // presence: {
    //   message: "^Please provide link to opportunity",
    // },
    // length: {
    //   minimum: 1,
    //   message: "^Please provide link to opportunity",
    // },
    url: {
      message:
        "^Please provide a valid link to the opportunity, the link must include http:// or https://",
    },
  },
  link: {
    url: true,
  },
  fileName: {
    presence: {
      message: "^Please upload photo",
    },
    length: {
      minimum: 1,
      message: "^Please upload photo",
    },
  },
  contactEmail: {
    email: {
      message: "^Please enter a valid email address",
    },
  },
  officialWebsite: {
    url: {
      message:
        "^Please provide a valid official website to the opportunity, it must include http:// or https://",
    },
  },

  //  <=====opportunity validations ends here=====>

  //  <=====create opportunity validations =====>
  toolShortDescription: {
    presence: {
      message: "^Please give a short description of the tool.",
    },
    length: {
      minimum: 1,
      message: "^Please give a short description of the tool.",
    },
  },

  toolTitle: {
    presence: {
      message: "^Please enter a title for the tool",
    },
    length: {
      minimum: 2,
      message: "^Please enter a valid title for the tool",
    },
  },

  toolOfficialWebsite: {
    url: {
      message:
        "^Please provide a valid link to the tool, the link must include http:// or https://",
    },
  },

  toolTopics: {
    presence: {
      message: "Please select a category",
    },
  },

  //  <=====create appointment validations =====>
  appointmentTitle: {
    presence: {
      message: "^Please enter title for the appointment",
    },
    length: {
      minimum: 2,
      message: "^Please enter a valid title for the appointment",
    },
  },

  appointmentDescription: {
    presence: {
      message: "^Please enter description for the appointment",
    },
    length: {
      minimum: 2,
      message: "^Please enter a valid description for the appointment",
    },
  },

  appointmentSelectedMentor: {
    presence: {
      message: "^Please select mentor.",
    },
    length: {
      minimum: 1,
      message: "^Please select a mentor.",
    },
  },

  appointmentMeetingUrl: {
    url: true,
  },

  ageRange: {
    presence: {
      message: "^Please select",
    },
    length: {
      minimum: 1,
      message: "^Please select",
    },
  },

  businessAddressGreenEconomyOrClimateChangeDescription: {
    presence: {
      message: "^Required",
    },
    length: {
      minimum: 1,
      message: "^Required",
    },
  },

  businessStage: {
    presence: {
      message: "^Required",
    },
    length: {
      minimum: 1,
      message: "^Required",
    },
  },
  businessRegistrationNumber: {
    presence: {
      message: "^Required",
    },
    length: {
      minimum: 1,
      message: "^Required",
    },
  },

  businessLocalGovernmentArea: {
    presence: {
      message: "^Required",
    },
    length: {
      minimum: 1,
      message: "^Required",
    },
  },
};

export { validation };
