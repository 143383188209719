import React from "react";
import MasterclassUser from "src/modules/Discover/components/TefMasterClass/MasterclassDetails/components/User/MasterclassUser";
import { FaFlag } from "react-icons/fa";
import classes from "./MasterclassComment.module.css";
import moment from "moment";

const MasterclassComment = ({ comments }) => {
  return (
    <>
      {comments &&
        comments.map((comment) => (
          <div className={classes.CommentContainer} key={comment.id}>
            <div className={classes.CommentBody}>
              <MasterclassUser
                instructorFullName={`${comment && comment.profile.firstName} ${
                  comment && comment.profile.lastName
                }`}
                instructorImageUrl={comment && comment.profile.imageUrl}
                commentDate={moment(comment && comment.createdOn).fromNow()}
              />
              <div>{comment.content}</div>
            </div>
            <div className={classes.CommentFlag}>
              <FaFlag />
            </div>
          </div>
        ))}
    </>
  );
};

export default MasterclassComment;
