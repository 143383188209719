import React from "react";
import { FormField, Select } from "src/modules/onboarding/components";
import ValidateJs from "src/utils/Validations/validation_wrapper";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";

import { DatePickerInput } from "rc-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { displayError } from "src/modules/onboarding/helper";
import { languages } from "src/modules/onboarding/tefMentorOnboarding/static";
import { useTranslation } from "react-i18next";
import {
  CustomCountryDropdown,
  CustomCountryRegionDropdown,
} from "src/components/CustomCountryRegionDropdown";

// registerLocale("en", en);
export const formatMaxBirthYear = (maxYear) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - maxYear);
  date.setMonth(11, 31);
  // date.setDate("31");
  // return date.toLocaleDateString().split("/").reverse().join("-").toString();
  return date.toISOString().split("T")[0];
};

export const validateBirthYear = (target, value, onChange) => {
  // const date = new Date(value).toLocaleDateString().split("/");
  const date = value.split("T")[0];
  const incoming = date.split("-");

  let incoming_year = incoming[0];
  const incoming_date = incoming[2];
  const incoming_month = incoming[1];

  const maxYear = formatMaxBirthYear(18).split("-")[0];

  if (incoming_year > maxYear) {
    incoming_year = maxYear;
    onChange(target, `${incoming_year}-${incoming_month}-${incoming_date}`);
    return;
  }
  return onChange(target, date);
};

export const postions = [
  { label: "Chief Executive Officer", value: "chief executive officer" },
  { label: "Director", value: "director" },
  { label: "Manager", value: "manager" },
  { label: "VC Partner", value: "vc partner" },
  { label: "Investor Scout", value: "investor scout" },
  { label: "Analyst", value: "analyst" },
  { label: "Associate", value: "associate" },
  { label: "Officer", value: "officer" },

  { label: " Head of Department", value: " head of department" },
  { label: "Student", value: "student" },
  { label: "Founder", value: "founder" },
  { label: "Senior Manager", value: "senior manager" },
  { label: "Founder and CEO", value: "founder and CEO" },
  { label: "Vice President", value: "vice president" },
  { label: "President", value: "president" },
  { label: "Managing Partner", value: "managing partner" },
  // { label: "Others", value: "others" },
];

export const maritalStatus = [
  { label: "Married", value: "married" },
  { label: "Single", value: "single" },
  { label: "Divorced", value: "divorced" },
  // { label: "I'd rather not say", value: "i'd rather not say" },
];
export const gender = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  // { label: "Other", value: "other" },
  // { label: "I'd rather not say", value: "i'd rather not say" },
];

export const UserName = ({
  lastName,
  firstName,
  onChangeRoleButtonClick,
  role,
}) => {
  const { t } = useTranslation();
  return (
    <div className="page-container__form-section--left-section-heading custom-row">
      <h1
        className="flex-grow notranslate"
        style={{ marginLeft: "9px", marginTop: "9px" }}
      >
        {firstName} {lastName}
      </h1>
      <span style={{ alignSelf: "center", fontSize: "12px", color: "#63666a" }}>
        {t(`${role}`)}
        <span
          onClick={onChangeRoleButtonClick}
          style={{ marginLeft: "5px", cursor: "pointer" }}
        >
          {t("Change")}
        </span>
      </span>
    </div>
  );
};

export const Bio = ({
  userInfo,
  onChange,
  handleValidationErrorChanges,
  gotoRef,
  onChangeRoleButtonClick,
}) => {
  const user = JSON.parse(localStorage.getItem("data")).user;
  const { role } = userInfo;
  const { t } = useTranslation();

  const _limitYearsOfExperience = (target, value) => {
    if (Number(value) > 30) {
      if (value.length >= 3) {
        value = value.substr(0, 2);
      } else if (value.length === 2) {
        value = value.substr(0, 1);
      }
      onChange(target, value);
    } else {
      onChange(target, value);
    }
  };
  const checkURL = (url, stateString) => {
    let string = url;
    if (!~string.indexOf("http")) {
      string = `https://${string}`;
      onChange(stateString, string);
    }
    return url;
  };

  return (
    <div className="pad-inner" style={{ marginTop: "48px" }}>
      <UserName
        lastName={user.lastName}
        firstName={user.firstName}
        onChangeRoleButtonClick={onChangeRoleButtonClick}
        role={role}
      />
      {/* <form> */}
      <div>
        <FormField
          className="flex"
          label="Bio"
          labelInfo="Required"
          type="textarea"
          placeholder={t("Type in a brief overview of yourself")}
          onChange={onChange}
          name="bio"
          hasCounter
          maxLength={1000}
          value={userInfo.bio} // comment if word counter is needed
          // value={enforceWordCountValue(
          //   wordCounter(userInfo.bio),
          //   userInfo.bio,
          //   160
          // )} //remove comment if word counter is needed
          required
          // isEmpty={!userInfo["bio"]}
          onBlur={() =>
            handleValidationErrorChanges("bio", ValidateJs("bio", userInfo.bio))
          }
          error={userInfo.validationErrors.bio}
          id="bio"
        />
        {userInfo.validationErrors.bio &&
          displayError(userInfo.validationErrors.bio)}
      </div>
      <div className="row" ref={gotoRef}>
        <div className="col-md-6">
          <FormField label="Select Role" labelInfo="Optional">
            <Select
              list={postions}
              value={userInfo.industryRole}
              onChange={onChange}
              name="industryRole"
              className="flex"
            />
          </FormField>
        </div>
        <div className="col-md-6">
          <FormField
            className="flex"
            label="Years of Experience"
            labelInfo=""
            type="number"
            onChange={_limitYearsOfExperience}
            // onChange={onChange}
            // value={_removeLeadingZero(userInfo.yearsOfExperience)}
            value={userInfo.yearsOfExperience.toString()}
            name="yearsOfExperience"
            min="0"
            max="30"
          ></FormField>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormField
            // className="flex"
            label="Gender"
            labelInfo="Required"
            required
            id="gender"
            // isEmpty={!userInfo["gender"]}
          >
            <Select
              list={gender}
              value={userInfo.gender}
              onChange={onChange}
              name="gender"
              className="flex"
              onBlur={() =>
                handleValidationErrorChanges(
                  "gender",
                  ValidateJs("gender", userInfo.gender)
                )
              }
              error={userInfo.validationErrors.gender}
            />
          </FormField>
        </div>
        <div className="col-md-6">
          <FormField label="Marital Status" labelInfo="">
            <Select
              list={maritalStatus}
              value={userInfo.maritalStatus}
              onChange={onChange}
              name="maritalStatus"
              className="flex"
            />
          </FormField>
        </div>
      </div>

      {userInfo.validationErrors.gender &&
        displayError(userInfo.validationErrors.gender)}

      {/* dob */}
      <div className="row">
        <div className="col-md-6">
          <FormField
            className="flex"
            label="Date of Birth"
            labelInfo="Required"
            error={userInfo.validationErrors.dateOfBirth}
            id="dateOfBirth"
          >
            <div
              className={`rc-dp${
                userInfo.validationErrors.dateOfBirth
                  ? " default-error-state"
                  : ""
              }`}
            >
              <DatePickerInput
                className="default-error-state"
                readOnly
                maxDate={new Date(formatMaxBirthYear(18))}
                startDate={new Date(formatMaxBirthYear(18))}
                onChange={(jsDate, dateString) =>
                  validateBirthYear("dateOfBirth", dateString, onChange)
                }
                // locale={calenderLocale}
                placeholder="MM/DD/YYYY"
                onHide={() =>
                  handleValidationErrorChanges(
                    "dateOfBirth",
                    ValidateJs("dateOfBirth", userInfo.dateOfBirth)
                  )
                }
                value={userInfo.dateOfBirth}
                showOnInputClick
                autoClose={false}
              />
            </div>
          </FormField>

          {userInfo.validationErrors.dateOfBirth
            ? displayError(userInfo.validationErrors.dateOfBirth)
            : displayError("")}
        </div>

        <FormField
          className="col-md-6 pad-inner"
          label="Phone"
          labelInfo="Required"
          required
          // isEmpty={!userInfo["phone"]}
          id="phone"
        >
          <div style={{ marginTop: "9.5px" }}>
            <PhoneInput
              countryCodeEditable={false}
              enableSearch
              country={"ng"}
              value={userInfo.phone}
              inputClass="phone-input"
              name="phone"
              onChange={(phone) => onChange("phone", phone)}
              autoFormat={false}
              inputStyle={{
                width: "100%",
                marginTop: "8px 10px",
                borderColor: userInfo.validationErrors.phone ? "red" : "",
              }}
              onBlur={() =>
                handleValidationErrorChanges(
                  "phone",
                  ValidateJs("phone", userInfo.phone)
                )
              }
            />
            {userInfo.validationErrors.phone
              ? displayError(userInfo.validationErrors.phone)
              : displayError("")}
          </div>
        </FormField>
      </div>

      <FormField label="Language" labelInfo="Optional">
        <Select
          list={languages}
          value={userInfo.language}
          onChange={onChange}
          name="language"
          className="flex"
        />
      </FormField>

      <FormField
        label="Country"
        className="flex"
        labelInfo="Required"
        // isEmpty={!userInfo["country"]}
        id="country"
      >
        <CustomCountryDropdown
          classes={`flex country-state-list ${
            userInfo.validationErrors.country
              ? "country-state-list-error-state"
              : ""
          }`}
          value={userInfo.country}
          name="country"
          valueType="short"
          onChange={
            (val) => onChange("country", val)
            // AsyncFunction("country", val).then(() => {
            //   setTimeout(() => {
            //     onChange("state", "");
            //   }, 1000);
            // })
          }
          onBlur={() =>
            handleValidationErrorChanges(
              "country",
              ValidateJs("country", userInfo.country)
            )
          }
          defaultOptionLabel={t("Select")}
        />
        {userInfo.validationErrors.country ? (
          <div className="displayError">
            {displayError(userInfo.validationErrors.country)}
          </div>
        ) : (
          <div className="displayError">{displayError("")}</div>
        )}
      </FormField>

      <FormField label="State" labelInfo="Required" className="flex" id="state">
        <CustomCountryRegionDropdown
          countryValueType="short"
          defaultOptionLabel={t("Select")}
          country={userInfo.country}
          classes={`flex country-state-list ${
            userInfo.validationErrors.state
              ? "country-state-list-error-state"
              : ""
          }`}
          name="state"
          value={userInfo.state}
          onChange={(val) => onChange("state", val)}
          onBlur={() =>
            handleValidationErrorChanges(
              "state",
              ValidateJs("state", userInfo.state)
            )
          }
        />
        {userInfo.validationErrors.state ? (
          <div className="displayError">
            {displayError(userInfo.validationErrors.state)}
          </div>
        ) : (
          <div className="displayError">{displayError("")}</div>
        )}
      </FormField>

      <FormField
        label="City"
        labelInfo="Required"
        placeholder="Please enter your city of residence"
        name="city"
        className="flex"
        required
        id="city"
        onChange={onChange}
        value={userInfo.city}
        onBlur={() =>
          handleValidationErrorChanges(
            "city",
            ValidateJs("city", userInfo.city)
          )
        }
        error={userInfo.validationErrors.city}
      />
      {userInfo.validationErrors.city
        ? displayError(userInfo.validationErrors.city)
        : displayError("")}

      <FormField
        label="Address"
        type="textarea"
        labelInfo="Required"
        placeholder="Please enter your residential address"
        name="address"
        className="flex"
        required
        id="address"
        onChange={onChange}
        value={userInfo.address}
        onBlur={() =>
          handleValidationErrorChanges(
            "address",
            ValidateJs("address", userInfo.address)
          )
        }
        error={userInfo.validationErrors.address}
      />
      {userInfo.validationErrors.address
        ? displayError(userInfo.validationErrors.address)
        : displayError("")}

      <FormField
        className="flex"
        type="url"
        label="Facebook Link"
        labelInfo="Optional"
        placeholder="e.g. https://facebook.com/janedoe"
        name="facebookUrl"
        id="facebookUrl"
        onChange={onChange}
        value={userInfo.facebookUrl}
        onBlur={
          userInfo.facebookUrl &&
          checkURL(userInfo.facebookUrl, "facebookUrl") &&
          userInfo.facebookUrl.length > 0
            ? () =>
                handleValidationErrorChanges(
                  "facebookUrl",
                  ValidateJs("facebookUrl", userInfo.facebookUrl)
                )
            : () => {
                handleValidationErrorChanges("facebookUrl", null);
              }
        }
        error={userInfo.validationErrors.facebookUrl}
      />
      {userInfo.validationErrors.facebookUrl &&
        displayError(userInfo.validationErrors.facebookUrl)}

      <FormField
        className="flex"
        type="url"
        label="Linkedin Link"
        labelInfo="Optional"
        placeholder="e.g. https://linkedin.com/janedoe"
        name="linkedinUrl"
        id="linkedinUrl"
        onChange={onChange}
        value={userInfo.linkedinUrl}
        onBlur={
          userInfo.linkedinUrl &&
          checkURL(userInfo.linkedinUrl, "linkedinUrl") &&
          userInfo.linkedinUrl.length > 0
            ? () =>
                handleValidationErrorChanges(
                  "linkedinUrl",
                  ValidateJs("linkedinUrl", userInfo.linkedinUrl)
                )
            : () => {
                handleValidationErrorChanges("linkedinUrl", null);
              }
        }
        error={userInfo.validationErrors.linkedinUrl}
      />
      {userInfo.validationErrors.linkedinUrl &&
        displayError(userInfo.validationErrors.linkedinUrl)}

      <FormField
        className="flex"
        type="url"
        label="Twitter Link"
        labelInfo="Optional"
        placeholder="e.g. https://twitter.com/janedoe"
        name="twitterUrl"
        id="twitterUrl"
        onChange={onChange}
        value={userInfo.twitterUrl}
        onBlur={
          userInfo.twitterUrl &&
          checkURL(userInfo.twitterUrl, "twitterUrl") &&
          userInfo.twitterUrl.length > 0
            ? () =>
                handleValidationErrorChanges(
                  "twitterUrl",
                  ValidateJs("twitterUrl", userInfo.twitterUrl)
                )
            : () => {
                handleValidationErrorChanges("twitterUrl", null);
              }
        }
        error={userInfo.validationErrors.twitterUrl}
      />
      {userInfo.validationErrors.twitterUrl &&
        displayError(userInfo.validationErrors.twitterUrl)}

      <FormField
        className="flex"
        type="url"
        label="Personal Website Link"
        labelInfo="Optional"
        placeholder="e.g. https://janedoe.com"
        name="websiteUrl"
        id="websiteUrl"
        onChange={onChange}
        value={userInfo.websiteUrl}
        onBlur={
          userInfo.websiteUrl &&
          checkURL(userInfo.websiteUrl, "websiteUrl") &&
          userInfo.websiteUrl.length > 0
            ? () =>
                handleValidationErrorChanges(
                  "websiteUrl",
                  ValidateJs("websiteUrl", userInfo.websiteUrl)
                )
            : () => {
                handleValidationErrorChanges("websiteUrl", null);
              }
        }
        error={userInfo.validationErrors.websiteUrl}
      />
      {userInfo.validationErrors.websiteUrl &&
        displayError(userInfo.validationErrors.websiteUrl)}

      <FormField
        className="flex"
        type="url"
        label="Youtube Link"
        labelInfo="Optional"
        placeholder="e.g. https://your-youtubechannel.com"
        name="youtubeUrl"
        id="youtubeUrl"
        onChange={onChange}
        value={userInfo.youtubeUrl}
        onBlur={
          userInfo.youtubeUrl &&
          checkURL(userInfo.youtubeUrl, "youtubeUrl") &&
          userInfo.youtubeUrl.length > 0
            ? () =>
                handleValidationErrorChanges(
                  "youtubeUrl",
                  ValidateJs("youtubeUrl", userInfo.youtubeUrl)
                )
            : () => {
                handleValidationErrorChanges("youtubeUrl", null);
              }
        }
        error={userInfo.validationErrors.youtubeUrl}
      />
      {userInfo.validationErrors.youtubeUrl &&
        displayError(userInfo.validationErrors.youtubeUrl)}

      <FormField
        className="flex"
        type="url"
        label="Instagram Link"
        labelInfo="Optional"
        placeholder="e.g. https://your-instagram-channel-link.com"
        name="instagramUrl"
        id="instagramUrl"
        onChange={onChange}
        value={userInfo.instagramUrl}
        onBlur={
          userInfo.instagramUrl &&
          checkURL(userInfo.instagramUrl, "instagramUrl") &&
          userInfo.instagramUrl.length > 0
            ? () =>
                handleValidationErrorChanges(
                  "instagramUrl",
                  ValidateJs("instagramUrl", userInfo.instagramUrl)
                )
            : () => {
                handleValidationErrorChanges("instagramUrl", null);
              }
        }
        error={userInfo.validationErrors.instagramUrl}
      />
      {userInfo.validationErrors.instagramUrl &&
        displayError(userInfo.validationErrors.instagramUrl)}
      {/* </form> */}
    </div>
  );
};
