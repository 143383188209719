/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import ClickOutside from "react-click-outside-component";
import Avatar from "react-avatar";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import { ReactComponent as More } from "src/assets/img/home/more.svg";
import { ReactComponent as ShareIcon } from "src/assets/img/svgs/share.svg";
import { ReactComponent as CommentIcon } from "src/assets/img/svgs/comments.svg";
// import { ReactComponent as Email } from "src/modules/assets/img/home/email.svg";
// import { ReactComponent as Share } from "src/modules/assets/img/home/share.svg";
import "./PostCard.scss";
import { Context } from "src/store";
import {
  likePost,
  unlikePost,
  getUserLike,
  deletePost,
} from "src/requests/StudyGroupPostRequest";
import { getAllComments } from "src/requests/StudyGroupCommentRequest";
import Comment from "src/modules/training/StudyGroups/components/Comment/Comment";
import ImageGridGenerator from "src/utils/imageGridGenerator";
import PhotoModal from "src/modules/training/StudyGroups/components/ImageModal/PhotoModal/PhotoModal";
import { onHandleDelete } from "src/utils/helper";
import { Link } from "react-router-dom";
import UserLikes from "src/modules/training/StudyGroups/components/UserLikes/UserLikes";
import { EditorContentDisplay } from "src/components/Editor";
import MoreOptions from "src/components/MoreOptions/MoreOptions";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { convertToRaw } from "draft-js";
import { convertTextForSharing } from "src/modules/training/StudyGroups/helper";

const PostCard = ({
  postId,
  profilePic,
  name,
  groupName,
  postDate,
  post,
  likeCount,
  commentCount,
  shareCount,
  userId: postOwnerId,
  postImages,
  groupId,
  hideComment,
}) => {
  const { userId: loggedInUserId } = useCurrentUser();
  const [toggleDelete, setToggleDelete] = useState(false);
  const [toggleShareOptions, setToggleShareOptions] = useState(false);
  const [likes, setLikes] = useState(likeCount);
  const [liked, setLiked] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState([]);
  const [counts, setCounts] = useState(commentCount);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showImages, setShowImages] = useState([]);
  const [showLikes, setShowLikes] = useState(false);
  const [shareText, setShareText] = useState("");
  const handleDeleteToggle = () => setToggleDelete(!toggleDelete);
  const handleShareToggle = () => setToggleShareOptions(!toggleShareOptions);
  const handleCommentToggle = () => setShowComments(!showComments);
  const handleDelete = () => {
    onHandleDelete(deletePost, dispatch, postId);
    handleDeleteToggle();
  };
  const [double, setDouble] = useState(false);
  const toggleImageModal = () => setShowImageModal(!showImageModal);
  const { state, dispatch } = useContext(Context);

  const handleShowImages = (images) => {
    toggleImageModal();
    setShowImages(images);
  };

  useEffect(() => {
    if (toggleShareOptions) {
      setShareText(convertTextForSharing(post));
    }
  }, [toggleShareOptions]);

  useEffect(() => {
    getUserLike(dispatch, postId).then((res) => {
      if (res && res.status === 200) {
        const {
          data: { data },
        } = res;
        if (data) {
          setLiked(true);
        }
      }
      if (res && res.status !== 200) {
        setLiked(false);
      }
    });
  }, []);

  const handleLikePost = () => {
    setDouble(true);
    getUserLike(dispatch, postId).then((res) => {
      const { data } = res.data;
      if (data) {
        unlikePost(dispatch, postId).then((res) => {
          setLikes(likes - 1);
          setLiked(false);
          setDouble(false);
        });
      } else {
        likePost(dispatch, postId).then((res) => {
          setLikes(likes + 1);
          setLiked(true);
          setDouble(false);
        });
      }
    });
  };

  const getComments = () => {
    handleCommentToggle();
    triggerGetComments();
  };

  const triggerGetComments = () => {
    getAllComments(dispatch, postId).then((res) => {
      const { data } = res.data.data;
      setCounts(data.length);
      setComments(data);
    });
  };

  return (
    <div className="card-div">
      <div className="card-div__top">
        <div className="name-container notranslate">
          <Link to={`/profile/${postOwnerId}`}>
            {profilePic === "" ? (
              <Avatar
                round={true}
                name={name}
                size={50}
                className="card-div__dp notranslate"
              />
            ) : (
              <img
                src={profilePic}
                alt="profile"
                className="card-div__dp notranslate"
              />
            )}
          </Link>
          <div className="card-div__names">
            <h1 className="card-div__h1">
              <Link className="card-div__h1" to={`/profile/${postOwnerId}`}>
                {name}
              </Link>
              {groupName ? (
                <Link
                  to={`/community/group?groupId=${groupId}`}
                  style={{ textDecoration: "none" }}
                  className="card-div__h1"
                >
                  {" -> " + groupName}
                </Link>
              ) : (
                ""
              )}
            </h1>
            <h3 className="card-div__date hidden-xs">{postDate}</h3>
          </div>
        </div>
        {/* <More onClick={handleDeleteToggle} className="more-button" /> */}
      </div>

      {/* <ClickOutside onClickOutside={() => setToggleDelete(false)}>
        <MoreOptions
          userId={loggedInUserId}
          postOwnerId={postOwnerId}
          show={toggleDelete}
          handleDelete={handleDelete}
          contentId={postId}
          contentType="post"
          title={post}
        />
      </ClickOutside> */}

      <div className="card-div__main">
        {/* <p className="card-div__post">{post}</p> */}

        <div className="card-div__post">
          <EditorContentDisplay content={post} readOnly />
        </div>

        <ImageGridGenerator
          imageLength={postImages.length}
          images={postImages}
          handleClick={(images) => handleShowImages(images)}
        />
      </div>

      <div className="card-div__bottom">
        <div className="action-counts">
          <h3
            onMouseEnter={likes > 0 ? () => setShowLikes(true) : null}
            onMouseLeave={() => setShowLikes(false)}
          >
            <span className="count-bold">{likes}</span>{" "}
            <span className="text-1">Likes</span>
            {showLikes ? <UserLikes postId={postId} /> : ""}
          </h3>
          <h3 onClick={getComments}>
            <span className="count-bold">{counts}</span>{" "}
            <span className="text-1">Comments</span>
          </h3>
        </div>

        <div className="svg-actions">
          <button
            className="svg-actions__like-btn"
            disabled={double}
            onClick={handleLikePost}
          >
            {liked ? <FaHeart style={{ color: "#cf1d10" }} /> : <FaRegHeart />}
            <span className="text">Like</span>
          </button>

          <h3 onClick={getComments}>
            <CommentIcon />
            <span className="text">Comment</span>
          </h3>

          <h3 className="more-button" onClick={handleShareToggle}>
            <ShareIcon />
            <span className="text">Share</span>
          </h3>
        </div>
      </div>

      <ClickOutside onClickOutside={() => setToggleShareOptions(false)}>
        <div
          className={
            toggleShareOptions ? "share-options" : "share-option hide-div"
          }
        >
          <ul className="share-options__lists">
            {/* <li> */}
            {/* <Share className="icon-tab" /> */}
            {/* Share on my timeline */}
            {/* </li> */}
            {/* <li> */}
            {/* <Email className="icon-tab" /> */}
            {/* Send as Message */}
            {/* </li> */}
            <li>
              <TwitterShareButton url="tefconnect.com" title={shareText}>
                <TwitterIcon size={20} round={true} className="icon-tab" />
                Share via Twitter
              </TwitterShareButton>
            </li>
            <li>
              <FacebookShareButton url="tefconnect.com" quote={shareText}>
                <FacebookIcon size={20} round={true} className="icon-tab" />
                Share via Facebook
              </FacebookShareButton>
            </li>
            <li>
              <LinkedinShareButton url="tefconnect.com" source={shareText}>
                <LinkedinIcon size={20} round={true} className="icon-tab" />
                Share via LinkedIn
              </LinkedinShareButton>
            </li>
          </ul>
        </div>
      </ClickOutside>
      <Comment
        id={postId}
        count={counts}
        comments={comments}
        style={showComments}
        dp={state.Profile.profile.imageUrl}
        getComments={triggerGetComments}
        hideCommentForm={hideComment}
      />
      <PhotoModal
        open={showImageModal}
        toggle={toggleImageModal}
        image={showImages}
      />
    </div>
  );
};

export default PostCard;
