/* eslint-disable react/no-unescaped-entities */
import React from "react";
import classes from "./TC.module.css";

const OffcycleTC = () => {
  return (
    <div>
      <h4 className={`${classes.Bold} ${classes.Center}`}>
        TONY ELUMELU FOUNDATION – UNITED NATIONS DEVELOPMENT PROGRAMME MALI
        ENTREPRENEURSHIP PROGRAMME 2020
      </h4>
      <h5 className={`${classes.Bold} ${classes.Center}`}>
        MENTORING AGREEMENT
      </h5>
      <h4 className={classes.Subtitle1}>
        PLEASE READ THROUGH THIS AGREEMENT CAREFULLY BEFORE CLICKING “I AGREE”.{" "}
      </h4>
      <p>
        <span className={classes.Bold}>THIS AGREEMENT</span> (the “
        <span className={classes.Bold}>Agreement</span>”) is made by and
        between:
      </p>
      <p>
        {" "}
        <span className={classes.Bold}>THE TONY ELUMELU FOUNDATION</span>, a
        not-for- profit organisation incorporated under the laws of the Federal
        Republic of Nigeria (hereinafter referred to as the{" "}
        <span className={classes.Bold}>“Foundation”</span> which expression
        shall, where the context so admits, include its successors-in-title and
        assigns of the one part;
      </p>
      <p className={classes.Bold}>AND </p>
      <p>
        <span className={classes.Bold}>YOU</span>, a mentor in the Tony Elumelu
        Foundation – United Nations Development Programme Mali Entrepreneurship
        Programme (hereinafter referred to as the{" "}
        <span className={classes.Bold}>“Mentor”</span>) of the other part. The
        Foundation and the Mentor may each be referred to herein as a{" "}
        <span className={classes.Bold}>“Party”</span> and collectively as the{" "}
        <span className={classes.Bold}>“Parties”</span>.
      </p>
      <h5 className={classes.Bold}>WHEREAS</h5>
      <p className={classes.NumberedParagraph}>
        <p className={classes.Number}>1.</p>
        <p>
          The Foundation is a private not-for-profit organization committed to
          the economic transformation of Africa through its support of private
          sector engagement and the promotion of entrepreneurship and leadership
          across Africa;
        </p>
      </p>
      <p className={classes.NumberedParagraph}>
        <p className={classes.Number}> 2. </p>
        <p>
          The TEF - UNDP Mali Entrepreneurship Programme (the{" "}
          <span className={classes.Bold}>“Programme”</span>) is an
          entrepreneurship programme of the Foundation in partnership with the
          United Nations Development Programme (“UNDP”) with the purpose of
          identifying, over a period of 3 years, 10,000 Malian start-ups and
          entrepreneurs with ideas that have the potential to succeed; support
          the growth of these businesses through business training, mentoring,
          access to seed capital funding, information and membership of their
          respective founders and/or owners (entrepreneurs) into the TEF
          Africa-wide alumni network and; to create businesses that can generate
          at least 1,000,000 new jobs and contribute at least $10 billion in new
          revenues across Africa over 10 years;
        </p>
      </p>
      <p className={classes.NumberedParagraph}>
        <p className={classes.Number}>3.</p>
        <p>
          As part of the Programme, selected Programme Participants (
          <span>“Mentees’”</span>) are to be assigned mentors to provide
          guidance and support during the Programme;
        </p>
      </p>
      <p className={classes.NumberedParagraph}>
        <p className={classes.Number}>4.</p>
        <p>
          The Mentor has accepted to provide the required guidance and support
          for the Programme.
        </p>
      </p>
      <p style={{ marginBottom: "1.5rem" }}>
        In consideration of the above premises, their mutual promises,
        assurances, guarantees and undertakings, the Parties hereby agree as
        follows:
      </p>
      <p>
        <p className={classes.Bold}>1. MENTORING OBLIGATIONS</p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}> 1.1. </p>
          <p>
            You will be a Mentor for the duration of the Programme (the{" "}
            <span className={classes.Bold}>“Mentoring Duration”</span>).
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>1.2. </p>
          <p>
            You will be required to mentor a maximum of 4 Mentees, subject to a
            required minimum of 1 Mentee during the Mentoring Duration.
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>1.3.</p>
          <p>
            During the Mentoring Duration, you will be required to connect with
            your Mentees for a minimum of one (1) hour a week. This time
            commitment shall be inclusive of a weekly/monthly check-in or
            discussion session during which you will track the Mentees’ progress
            in completing assigned tasks. Such mentoring sessions shall be held
            at such times as may be mutually agreed with the Mentee.
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>1.4.</p>
          <p>
            As a Mentor, You will be required to demonstrate a strong
            understanding of business operations and desire to help others grow,
            and to provide close guidance to encourage the Mentees’ learning and
            development of entrepreneurial and personal skills.{" "}
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>1.5.</p>
          <p>
            {" "}
            You shall notify the Foundation with at least 2 weeks prior notice
            via email, if you are unable to continue with your obligations as a
            Mentor.
          </p>
        </p>
      </p>
      <p>
        <p className={classes.Bold}>2. CONFIDENTIALITY AND PRIVACY</p>
        <p className={classes.Bold}>Confidentiality</p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>2.1. </p>
          <p>
            You acknowledge that as a result of signing up to be a Mentor, You
            will receive Confidential Information belonging to the Mentees and
            their businesses. As a Mentor you undertake not to make any
            unauthorised disclosure of Confidential Information to any third
            party and to maintain the confidentiality, and to take all steps
            reasonably necessary to protect the secrecy of the Confidential
            Information.
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>2.2.</p>
          <p>
            {" "}
            &quot;Confidential Information&quot; means and includes all
            information (including Personal Information) and documentation which
            belongs to, or which a Mentee is obligated to treat as confidential
            pursuant to any course of dealing or any agreement to which the
            Mentee is a party; financial, tax and accounting information,
            business plans, trade secrets, strategies for development or growth,
            branding and marketing practices and techniques, technical
            information, specifications, research and work-in-progress,
            prototypes, any other proprietary information of the Mentee not
            known generally to the public or in the public domain relating in
            any way to their business or any other information regarding the
            management or method of operation of their business.
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>2.3.</p>
          <p>
            {" "}
            All Confidential Information shall remain the property of the
            Mentees and nothing in this Agreement shall be construed as granting
            or implying any transfer of rights in the Confidential Information
            to you. You shall use the Confidential Information strictly for the
            purpose of this Agreement and the provision of mentoring and
            guidance to the Mentees.
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>2.4.</p>
          <p>
            You shall not in any way or manner, whether directly or indirectly,
            circumvent, or attempt to circumvent the Mentees’ business. You
            further agree not to compete with a Mentee and shall not use
            Confidential Information or any opportunity or advantages derivable
            thereof in your business or affairs without the written consent of
            the Mentee. This does not however preclude you from carrying out
            independent business similar to a Mentees business and pursuing such
            business so long as you are not in breach of the foregoing.
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>2.5.</p>
          <p>
            You shall comply with the provisions of the Nigerian Data Protection
            Regulations and other data protection laws applicable to you in
            respect of Personal Information disclosed to you in connection with
            this Agreement. You shall keep all Personal Information you receive
            on the Mentees confidential and shall not at any time make use of
            the same for any purpose other than providing support at a Mentor
            under this Agreement without obtaining the prior written approval of
            the Mentee.{" "}
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>2.6.</p>
          <p>
            Personal Information means and includes any information relating to
            a Mentee such as a name, address, a photo, an email address, bank
            details, posts on social networking websites, medical information,
            and other unique identifier such as but not limited to MAC address,
            IP address, IMEI number, IMSI number, SIM and others.{" "}
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>2.7.</p>
          <p>
            Your obligations of confidentiality and data protection shall
            survive the termination of this Agreement.
          </p>
        </p>
        <p className={classes.Bold}>Fiduciary Duty</p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>2.8.</p>
          <p>
            {" "}
            Signing up as a TEF Mentor puts you in a position of trust in
            relation to the Mentees and the Foundation. You agree that you have
            a fiduciary duty to deal at all times in an honest, fair and
            trustworthy manner and to act in utmost good faith towards the
            Mentees and the Foundation.
          </p>
        </p>
        <p className={classes.Bold}>Ethical Standard & Conflict of Interest </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>2.9.</p>
          <p>
            You shall maintain high moral and ethical standards while providing
            mentoring to the Mentees.
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>2.10.</p>
          <p>
            You shall exercise reasonable caution and care in engagement with
            the Mentees especially as relates to personal or business
            engagements. The Foundation, its affiliated companies, its trustees,
            officers, employees, representatives, partners and licensors shall
            not be liable to you for any direct, indirect, incidental,
            consequential, or punitive damages, loss of profit or anticipated
            profit, loss of reputation, loss of opportunities arising from any
            personal dealings or business relationship you may engage in with
            the Mentees.
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>2.11. </p>
          <p>
            {" "}
            You shall not discriminate against any Mentee on grounds of age,
            gender, sexual orientation, religion, literacy level, physical
            impairment, ethnicity or nationality.{" "}
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>2.12. </p>
          <p>
            You shall report any violation of applicable laws, regulations,
            Foundation policies, or any other concerns about the operation and
            integrity of the Programme to the Foundation.
          </p>
        </p>
      </p>
      <p>
        <p className={classes.Bold}>3. DURATION AND TERMINATION</p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>3.1. </p>
          <p>
            This Agreement shall commence upon your acceptance and shall
            continue for the Mentoring Duration.
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>3.2.</p>
          <p>
            {" "}
            Where you are found to be in breach of any of the terms of this
            Agreement, or found to be bringing or have brought the Foundation
            into disrepute by your conduct or statements, the Foundation
            reserves the right to immediately terminate this Agreement and
            withdraw access to your mentoring profile on TEFConnect. The
            Foundation may also suspend your mentoring account during any period
            of investigation into any allegation against you.{" "}
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>3.3.</p>
          <p>
            Your account may also be deactivated if you are found to be in
            breach of the Terms of Use of TEFConnect.
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>3.4.</p>
          <p>
            You may terminate this Agreement at any time by giving at least 2
            weeks prior notice via email. Your mentoring access on TEFConnect
            shall be withdrawn upon effective termination.{" "}
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>3.5.</p>
          <p>
            TEF may terminate this Agreement at any time by giving at least 2
            weeks prior notice via email. Your mentoring access on TEFConnect
            shall be withdrawn upon effective termination.
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>3.6. </p>
          <p>
            Upon termination/expiration of this Agreement, TEF may withdraw your
            mentoring access on TEFConnect where you choose to be disengaged or
            at the Foundation’s discretion.{" "}
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>3.7. </p>
          <p>
            {" "}
            Termination of this Agreement for any reason shall be without
            prejudice to any rights which may have accrued to the Foundation
            prior to such termination, and without prejudice to any clause in
            this Agreement that is intended to survive such termination.{" "}
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>3.8.</p>
          <p>
            Where this Agreement is terminated for grounds in Clause 3.2 or 3.3,
            you shall henceforth be prohibited from referring to yourself as a
            TEF Mentor verbally or in any written communication.
          </p>
        </p>
      </p>

      <p>
        <p className={classes.Bold}>4. FEES AND EXPENSES</p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>4.1.</p>
          <p>
            You understand and agree that you are signing up as a mentor is on a
            voluntary basis and as such you are not entitled to claim or receive
            any fees or compensation for being a Mentor.
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>4.2.</p>
          <p>
            You shall not incur any expenses hereunder on behalf of the
            Foundation without the prior written approval of the Foundation.{" "}
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>4.3. </p>
          <p>
            Where invited to Foundation events, you are expected to bear your
            cost of attending, save where the Foundation expressly indicates
            that it shall bear the cost of your attending.
          </p>
        </p>
      </p>
      <p>
        <p className={classes.Bold}>5. INDEMNITY</p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>5.1. </p>
          <p>
            You agree to defend, indemnify and hold harmless the Foundation, its
            affiliates, officers and employees with respect to any claim
            (including third party claims), demand, cause of action, loss, debt
            or liability, including reasonable attorneys&apos; fees, arising or
            resulting from your conduct as a Mentor.
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>5.2.</p>
          <p>
            Limitation of Liability: The Foundation, its affiliated companies,
            its trustees, officers, employees, representatives, partners and
            licensors shall not be liable to you for any direct, indirect,
            incidental, consequential, or punitive damages, loss of profit or
            anticipated profit, loss of reputation, loss of opportunities
            arising as a result of you signing up to be a Mentor.
          </p>
        </p>
      </p>
      <p>
        <p className={classes.Bold}>6. PUBLICATION AND USE OF NAMES</p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>6.1.</p>
          <p>
            You shall be entitled to refer to your status as a TEF Mentor in
            verbal and written communications including on social media channels
            and websites for the Mentoring Duration and thereafter provided that
            such right shall cease upon the occurrence of termination grounds as
            set out in Clause 3.8. The Foundation may also, at its sole
            discretion, withdraw this right at any time by written notice to
            you.
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>6.2.</p>
          <p>
            {" "}
            You are familiar with the ideals and objectives of the Foundation
            and shall not use the Foundation’s name or logo in a manner
            inconsistent with those ideals or objectives. The Foundation
            therefore reserves the right to restrict or prohibit such use where
            it is found to be used in a manner that puts the brand and
            reputation of the Foundation into disrepute. In such instance, you
            shall be notified and requested to remove such reference material.
          </p>
        </p>
      </p>
      <p>
        <p className={classes.Bold}>7. INTELLECTUAL PROPERTY RIGHTS </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>7.1.</p>
          <p>
            {" "}
            All rights, title and interest in all materials, including but not
            limited to notes, word, pdf or such other files, PowerPoint
            presentation, audio or video tapes and recordings, articles,
            templates, case studies, Foundation branded materials etc. made
            available to you by the Foundation or any person acting on behalf of
            the Foundation shall be the sole and exclusive property of the
            Foundation. Nothing in this Agreement purports to transfer or confer
            on you any intellectual property right to any such materials. You
            shall not use the materials for any purpose other than for the
            Programme and shall not copy, reproduce, alter, distribute or sell
            any of the materials made available to you as a Mentor.
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>7.2.</p>
          <p>
            Intellectual property rights including trademarks and copyrights in
            material provided by the Mentees remains the property rights of the
            Mentees or any third-party owner. The provision of such material to
            you by the Mentees does not give or transfer any rights to you.
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>7.3.</p>
          <p>
            You hereby grant the Foundation a perpetual, irrevocable,
            non-exclusive, royalty-free right to use, modify, transfer and
            maintain such Content. You hereby warrant to the Foundation such
            Content shall not involve copying or unauthorised borrowing from any
            other works and hereby indemnify the Foundation for any loss that
            may be suffered by any of them for any action against them for
            infringement of applicable intellectual property rights in such
            Content.
          </p>
        </p>
        <p className={classes.NumberedParagraph}>
          <p className={classes.Number}>7.4. </p>
          <p>
            {" "}
            The provisions of this clause on Intellectual Property shall survive
            the termination of this Agreement.
          </p>
        </p>
      </p>
      <p>
        <p className={classes.Bold}>8. DISCLAIMER</p>
        <p>
          The Foundation, its affiliated companies, its trustees, officers,
          employees, representatives, partners and licensors disclaim all
          implied warranties and representations and do not guarantee or hold
          itself out for the conduct, action or utterance of any Mentee.
        </p>
      </p>
      <p>
        <p className={classes.Bold}>9. AMENDMENTS</p>
        <p>
          {" "}
          No amendment of this Agreement shall be valid unless made in writing
          and signed by the Parties. You shall be notified of any modification
          or amendment to this Agreement and required to accept such
          modification to continue being a Mentor.
        </p>
      </p>
      <p>
        <p className={classes.Bold}>10. NO PARTNERSHIP/AGENCY </p>
        <p>
          {" "}
          This Agreement is not intended to create, and shall not be construed
          to create a partnership or agency relationship between the Parties and
          no Party shall be deemed to be a partner, agent or representative of
          the other Party by virtue of this Agreement save to the extent
          expressly provided herein.
        </p>
      </p>
      <p>
        <p className={classes.Bold}>11. ASSIGNMENT</p>
        <p>
          Neither Party may assign any of its obligations under this Agreement
          without the prior written consent of the other Party. Notwithstanding
          the foregoing, the Foundation may assign any or all of its obligations
          hereunder to any of its affiliates by given written notice of same to
          you.
        </p>
      </p>
      <p>
        <p className={classes.Bold}>12. SEVERABILITY </p>
        <p>
          If any provision of this Agreement is held by a court of competent
          jurisdiction to be invalid, void, or unenforceable, the remaining
          provisions shall continue to be binding between the Parties.
        </p>
      </p>
      <p>
        <p className={classes.Bold}>13. GOVERNING LAW </p>
        <p>
          The construction, validity and performance of this Agreement shall be
          governed at all times by Nigerian law.
        </p>
      </p>
      <p>
        <p className={classes.Bold}>14. DISPUTE RESOLUTION</p>
        <p>
          {" "}
          Any dispute or claim arising out of or in connection with this
          Agreement, including any questions regarding the existence, validity
          or termination, shall be referred to and finally resolved by the
          courts of the Federal Republic of Nigeria, which shall have exclusive
          jurisdiction.
        </p>
      </p>
      <p>
        <p className={classes.Bold}>15. ENTIRE UNDERSTANDING</p>
        <p>
          {" "}
          This Agreement sets forth the entire understanding and agreement of
          the Parties and supersedes any and all oral or written agreements or
          understandings between the Parties as to the subject matter of this
          Agreement.
        </p>
      </p>
    </div>
  );
};

export default OffcycleTC;
