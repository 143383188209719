/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import HowToApplyModal from "./HowToApplyModal";
import styles from "src/modules/Application/components/TCmodal/TCMain.module.scss";
import { useTranslation } from "react-i18next";
import { Context } from "src/store";
import { getProgrammeEventsContents } from "src/requests/ProgrammeEventRequest";
import { Code } from "react-content-loader";
import Cookies from "js-cookie";
import { getLanguage } from "src/utils/helper";

const MyCodeLoader = () => (
  <>
    <Code />
    <Code />
    <Code />
  </>
);

const HowToApply = ({ show, toggle, apply, programmeEventId, lang }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState("");
  const { dispatch } = useContext(Context);
  const language = getLanguage();

  useEffect(() => {
    if (show) {
      const payload = {
        id: programmeEventId,
        lang: language || lang,
        type: "how-to-apply",
      };

      getProgrammeEventsContents(dispatch, payload).then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          const { data } = res.data;
          if (data.length > 0) {
            const { content } = data[0];
            setContent(content);
          }
        }
      });
    }
  }, [show]);

  return (
    <Modal
      size="lg"
      isOpen={show}
      contentClassName={styles.TCMain}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>
        {isLoading ? (
          <MyCodeLoader />
        ) : content !== "" ? (
          <>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
            <div className={styles.TCMain__btn}>
              <button className={styles.TCMain__acceptBtn} onClick={apply}>
                {t("Continue")}
              </button>
            </div>
          </>
        ) : (
          <HowToApplyModal proceed={apply} />
        )}
      </ModalBody>
    </Modal>
  );
};

export default HowToApply;
