import React, { useEffect } from "react";
import axios from "axios";

const checkRequests = (Wrapped) => {
  function CheckRequests(props) {
    useEffect(() => {
      axios.interceptors.request.use(
        (config) => {
          return config;
        },
        (err) => {
          return Promise.reject(err);
        }
      );

      axios.interceptors.response.use(
        function (response) {
          // Do something with response data
          return response;
        },
        function (err) {
          return new Promise((resolve, reject) => {
            if (err.response.status === 500) {
              props.history.push("/500"); // we will redirect user into 500 page
            } else if (err.response.status === 408) {
              props.history.push("/408"); // we will redirect user into 408 page
            }
            return reject(err);
          });
          // Do something with response error
        }
      );
    });

    return <Wrapped {...props} />;
  }
  return CheckRequests;
};

export default checkRequests;
