import React, { Fragment, useState, useContext, useEffect } from "react";
import ArticleDetail from "./ArticleDetail";
import "../../../homepage/components/StoryCard/ArticleCard/ArticleCard.scss";
import { getTopArticles } from "src/requests/DiscoverRequest";
// import { useHistory } from "react-router-dom";
import { Row, Button, Col } from "reactstrap";
import ContentLoader from "react-content-loader";
import { Context } from "src/store";
import ArticleNotFound from "src/modules/Discover/components/NotFound/ArticleNotFound";
import moment from "moment";

const ReactContentLoader = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div
        style={{ backgroundColor: "#f3f3f3", height: "50px", width: "80%" }}
      ></div>
      <div
        style={{
          backgroundColor: "white",
          padding: "10px",
        }}
        // className="loader-item"
      >
        <ContentLoader
          speed={2}
          // width={324}
          // height={150}
          viewBox="0 0 400 160"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="8" rx="3" ry="3" width="300" height="30" />
          <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
          <rect x="0" y="56" rx="3" ry="3" width="300" height="6" />
          <rect x="0" y="72" rx="3" ry="3" width="300" height="6" />
          <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
          {/* <circle cx="20" cy="20" r="20" /> */}
        </ContentLoader>
      </div>
    </div>
  );
};
const TrendingArticles = () => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loading] = useState(false);
  const [pageDetails, setPageDetails] = useState({
    pageNumber: 1,
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const { dispatch } = useContext(Context);

  useEffect(() => {
    _getTopArticles(1, false);
  }, []);

  const _onLoadMoreClick = () => {
    const pageNum = pageDetails.pageNumber + 1;
    _getTopArticles(pageNum, true);
  };
  const _getTopArticles = (pageNumber, loadMore) => {
    getTopArticles(dispatch, pageNumber).then((res) => {
      if (res.status === 200) {
        const {
          data: { data, pager },
        } = res;
        if (loadMore) {
          const previousArticle = [...articles];
          const newArticle = [...previousArticle, ...data];
          setArticles(newArticle);
        } else {
          setArticles(data);
        }
        setPageDetails({ ...pageDetails, ...pager });
        setIsLoading(false);
      }
    });
  };
  const RenderContentLoader = () => {
    const arr = [1, 1, 1, 1];
    const contentArr = arr.fill(4);
    const contLoadArr = contentArr.map((c, idx) => (
      <Col md={12} key={idx}>
        <ReactContentLoader />
      </Col>
    ));
    return <Row>{contLoadArr}</Row>;
  };
  return (
    <Fragment>
      <p
        className="article-card-heading"
        style={{
          background: "white",
          padding: "0.8rem 0 1rem 1rem",
          marginTop: "1rem",
          textTransform: "capitalize",
          textAlign: "left",
          color: "#26292d",
          fontSize: "16px",
        }}
      >
        Trending Articles
      </p>
      <div className="article-card" style={{ marginRight: "0rem" }}>
        <div className="article-card__articles">
          {isLoading ? (
            RenderContentLoader()
          ) : articles.length === 0 ? (
            <ArticleNotFound />
          ) : (
            articles &&
            articles.map((art, idx) => (
              <ArticleDetail
                category={art.categoryName}
                key={art.id}
                title={art.title}
                datePosted={moment(art.date).fromNow()}
                articleId={art.id}
              />
            ))
          )}
        </div>
        {pageDetails.hasNextPage && (
          <Row>
            <Button
              disabled={loading}
              onClick={_onLoadMoreClick}
              className="loadmore-btn"
            >
              Show more
            </Button>
          </Row>
        )}
      </div>
    </Fragment>
  );
};

export default TrendingArticles;
