import React, { useState, useContext } from "react";
import styles from "./InviteForm_v2.module.scss";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import {
  Form,
  InputGroup,
  Input,
  Button,
  Spinner,
  // FormText,
  FormFeedback,
} from "reactstrap";
import { IoIosSend } from "react-icons/io";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { Context } from "src/store";
import { sendInvite } from "src/requests/InviteRequest";
import { validateEmail } from "src/utils/Validations/validateSignup";
import useForm from "src/utils/useForm";

const InviteForm_v2 = () => {
  const {
    user: { id },
  } = get_user_info_from_token();
  const initialState = {
    inviteAsMentor: false,
  };
  const [data] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useContext(Context);

  // const handleRadioChanged = (e) => {
  //   const name = e.target.name;
  //   setData({
  //     ...data,
  //     [name]: e.target.value.toLowerCase() === "true",
  //   });
  // };

  const onSend = () => {
    setIsLoading(true);
    const payload = {
      ...values,
      ...data,
      invitedByUserId: id,
    };

    sendInvite(dispatch, payload)
      .then((_res) => {
        setIsLoading(false);
      })
      .catch((_err) => setIsLoading(false));
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    onSend,
    validateEmail
  );
  return (
    <div className={styles.container}>
      <h4>
        Invite your friends to grow your to grow your <span>TEF network</span>
      </h4>

      <section className={styles.section}>
        <span>Share link via</span>
        <div className={styles.socialContainer}>
          <TwitterShareButton
            url="tefconnect.com"
            title=""
            className={styles.socialContainer__item}
          >
            <TwitterIcon size={45} round={false} borderRadius={10} />
          </TwitterShareButton>

          <FacebookShareButton
            url="tefconnect.com"
            quote=""
            className={styles.socialContainer__item}
          >
            <FacebookIcon
              size={45}
              round={false}
              borderRadius={10}
              // className="icon-tab"
            />
          </FacebookShareButton>
          <LinkedinShareButton
            url="tefconnect.com"
            title=""
            className={styles.socialContainer__item}
          >
            <LinkedinIcon
              size={45}
              round={false}
              borderRadius={10}
              // className="icon-tab"
            />
          </LinkedinShareButton>
        </div>
      </section>

      <section className={styles.section}>
        <span>Invite your friends by email</span>
        <Form onSubmit={handleSubmit}>
          <InputGroup>
            <Input
              className={styles.input}
              placeholder="Enter email here"
              onChange={handleChange}
              invalid={errors.email}
              value={values.email}
              name="email"
              type="email"
              required
            />
            <Button className={styles.sendBtn}>
              {!isLoading && <IoIosSend />}{" "}
              {isLoading && (
                <Spinner size={15} style={{ height: "15px", width: "15px" }} />
              )}
            </Button>
            {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
          </InputGroup>
        </Form>
      </section>
    </div>
  );
};

export default InviteForm_v2;
