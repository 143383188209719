import React from "react";
import classes from "./LandingPage.module.scss";
import img from "src/assets/img/landingPage/landingPagem3.webp";

const Ressources = () => {
  return (
    <div className={classes.resourceContainer}>
      <div className={classes.resourceContainer__left}>
        <h4>Resources</h4>
        <h3>
          Take advantage of an array of tools and resources, curated in one
          place, just for you.
        </h3>
      </div>
      <div className={classes.resourceContainer__right}>
        <img src={img} alt="img" className={`img-fluid`} />
      </div>
    </div>
  );
};

export default Ressources;
