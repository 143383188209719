import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isImageFile, UploadFile } from "src/requests/fileUploadRequest";
import partnerIcon from "src/assets/img/partnerIcon.png";
import Resizer from "react-image-file-resizer";
import { Context } from "src/store";
import Loader from "react-loader-spinner";

const ImageLoader = () => (
  <div
    style={{
      position: "absolute",
      zIndex: 1,
      left: 0,
      right: 0,
      backgroundColor: "rgba(0,0,0,0.3)",
      height: "inherit",
      width: "inherit",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    }}
  >
    <Loader height={20} width={20} type="Oval" color="blue" />
    <span style={{ color: "#fff", fontSize: "10px", textAlign: "center" }}>
      uploading photo
    </span>
  </div>
);

export const AvatarOnboarding = ({
  info,
  onChange,
  // top,
  // left,
  // bottom,
  // right,
  className,
}) => {
  const [previewImage, setPreviewImage] = useState(info.imageUrl);
  const [image, setImage] = useState(info.imageUrl);
  const [loadingImageProgress, setLoadingImageProgress] = useState(false);
  const [isValidImage, setIsInvalidImage] = useState(false);

  const { dispatch } = useContext(Context);

  const { t } = useTranslation();

  useEffect(() => {
    info && info.imageUrl ? setImage(info.imageUrl) : setImage(partnerIcon);
  }, [info]);

  const uploadImageClick = () => {
    document.getElementById("files").click();
  };

  const _handleImageChange = (e) => {
    const file = e.target.files[0];
    if (isImageFile(e.target.files, false).hasError) {
      setIsInvalidImage(true);
      return;
    } else {
      setIsInvalidImage(false);
    }
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      100,
      0,
      (uri) => {
        setPreviewImage(uri);
      },
      "base64"
    );
    // setPreviewImage(file);
    setLoadingImageProgress(true);
    UploadFile(dispatch, false, e.target.files)
      .then((res) => {
        setLoadingImageProgress(false);
        if (res.status === 200 || res.status === 201) {
          onChange("imageUrl", res.files[0]);
        }
      })
      .catch((e) => {
        setLoadingImageProgress(false);
      });
  };
  return (
    <div className={className}>
      <img
        src={previewImage || image}
        className="img-fluid"
        style={{ borderRadius: "inherit", objectFit: "cover" }}
      />
      {loadingImageProgress && <ImageLoader />}
      <div
        id="fileup"
        style={{
          position: "absolute",
          backgroundColor: "#fff5e8",
          padding: "2px 0px",
          fontSize: "12px",
          fontFamily: "Poppins",
          color: "#63666a",
          alignSelf: "center",
          marginLeft: "5px",
          marginRight: "5px",
          border: "solid 1px #979797",
          flex: 1,
          cursor: "pointer",
          bottom: "-15px",
          left: "0px",
          right: "0px",
          borderRadius: "10px",
          // opacity: ".8",
          textAlign: "center",
          zIndex: 10,
        }}
        onClick={() => uploadImageClick()}
      >
        {t("Upload Photo")}
        <input
          type="file"
          id="files"
          style={{
            opacity: 0,
            zIndex: -1,
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
          onChange={_handleImageChange}
        />
      </div>
      {isValidImage && (
        <span
          className="pad-inner"
          style={{
            color: "red",
            position: "absolute",
            right: "-160px",
            top: "55px",
            fontSize: "12px",
            zIndex: 1,
          }}
        >
          {t("Please select a valid image")}
        </span>
      )}
    </div>
  );
};
