import "./About.scss";
import React, { useState, useEffect } from "react";
import { TabContent, Tabs } from "src/components/CustomTab/CustomTab";
import PageNav from "src/components/TabNavs/TabNavs";
import PersonalInfo from "./PersonalInfo";
import SocialMedia from "./SocialMedia";
import { CastValue } from "src/modules/onboarding/helper";

const defaultInfo = {
  industryRole: "",
  yearsOfExperience: 0,
};
export const AboutEdit = (props) => {
  const { cancel, profile } = props;
  const [info, setInfo] = useState(defaultInfo);

  useEffect(() => {
    if (profile) {
      setInfo(profile);
    }
  }, [profile]);

  const tabs = ["Personal Info", "Social Media"];
  const [activeTab, setCurrentTab] = useState(tabs[0]);

  const _handleChange = (target, value) => {
    if (target === "country" || target === "businessCountry") {
      if (target === "country") {
        setInfo({ ...info, [target]: value, state: "" });
      }
      if (target === "businessCountry") {
        setInfo({ ...info, [target]: value, businessState: "" });
      }
    } else {
      setInfo({ ...info, [target]: CastValue(target, value) });
    }
  };

  return (
    <div className="">
      <div className="edit-profile-header">
        <h1>Edit About</h1>
      </div>
      <hr />
      <PageNav activeTab={activeTab} setTab={setCurrentTab} titles={tabs} />
      <Tabs activeTab={activeTab}>
        <TabContent title={tabs[0]}>
          <PersonalInfo
            userInfo={info}
            onCancel={cancel}
            onChange={_handleChange}
          />
        </TabContent>
        <TabContent title={tabs[1]}>
          <SocialMedia
            userInfo={info}
            onCancel={cancel}
            onChange={_handleChange}
          />
        </TabContent>
      </Tabs>
    </div>
  );
};
