import React, { useContext, useState } from "react";
import Avatar from "react-avatar";
import { Context } from "src/store";
import { createComment } from "src/requests/CommentRequest";
import "./CommentForm.scss";
import Loader from "src/components/Loader/Loader";

const CommentForm = ({ id, name, dp, trigger }) => {
  const [commentInput, setCommentInput] = useState("");
  const { dispatch } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    setCommentInput(e.target.value);
  };

  const handlePost = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      postId: id,
      content: commentInput,
    };

    createComment(dispatch, payload)
      .then((res) => {
        setIsLoading(false);
        setCommentInput("");
        trigger();
      })
      .catch((_err) => {
        setIsLoading(false);
      });
  };

  return (
    <div className="comment-form-div">
      {dp === "" || dp === undefined || dp === null ? (
        <Avatar round={true} name={name} size={50} />
      ) : (
        <img src={dp} alt="profile" className="card-div__dp" />
      )}
      <form onSubmit={handlePost}>
        <input
          type="text"
          placeholder="What do you think?"
          value={commentInput}
          onChange={handleInputChange}
          className="comment-input"
          required={true}
          autoFocus
        />
        <button className="comment-button" disabled={isLoading}>
          {isLoading ? <Loader color="light" /> : "post"}
        </button>
      </form>
    </div>
  );
};

export default CommentForm;
