import { asyncActionName } from "src/utils/AsyncUtils";
import { GET_COMMENTS, CREATE_COMMENT, DELETE_COMMENT } from "src/actions";

import { initialState } from "src/store";

const CommentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case asyncActionName(GET_COMMENTS).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_COMMENTS).success:
      return {
        ...state,
        comments: payload.data,
        pageDetails: payload.page,
        success: true,
      };
    case asyncActionName(GET_COMMENTS).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(CREATE_COMMENT).loading:
      return { ...state, loading: payload };
    case asyncActionName(CREATE_COMMENT).success:
      return {
        ...state,
        comment: payload,
        success: true,
      };
    case asyncActionName(CREATE_COMMENT).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    case asyncActionName(DELETE_COMMENT).loading:
      return { ...state, loading: payload };
    case asyncActionName(DELETE_COMMENT).success:
      return {
        ...state,
        comment: payload,
        success: true,
      };
    case asyncActionName(DELETE_COMMENT).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };
    default:
      return state;
  }
};

export default CommentReducer;
