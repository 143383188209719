import React from "react";
import PropTypes from "prop-types";
import "./PageNav.scss";
import SearchBar from "src/components/SearchBar/SearchBar";
const PageNav = ({
  activeTab,
  setTab,
  titles,
  search,
  searchData,
  setSearchData,
}) => {
  return (
    <div className="tab-c">
      {titles.map((title, index) => (
        <div
          onClick={() => setTab(title)}
          className={`tab-ind ${activeTab === title ? "active-tab-item" : ""}`}
          key={index}
        >
          <span key={index}>{title}</span>
        </div>
      ))}
      {search && (
        <SearchBar searchData={searchData} setSearchData={setSearchData} />
      )}
    </div>
  );
};

PageNav.propTypes = {
  titles: PropTypes.instanceOf(Array).isRequired,
};

export default PageNav;
