/** User logged in using email+password. */
export const LOGIN_METHOD_FORM = "Form";
/** User logged in using email+password with the single sign-on flow. */
export const LOGIN_METHOD_FORM_SSO = "SSO";

const LOGIN_PROVIDER = "LoginProvider";

export const storeLoginMethod = (method) => {
  localStorage.setItem("loginMethod", method);
};

export const loginMethod = () => {
  return localStorage.getItem("loginMethod");
};

/** Returns true if the user signed in using the SSO flow. */
export const usedSingleSignOn = () => {
  return loginMethod() === LOGIN_METHOD_FORM_SSO;
};

export const getLoginProvider = () => {
  return localStorage.getItem(LOGIN_PROVIDER);
};

export const clearLoginProvider = () => {
  localStorage.removeItem(LOGIN_PROVIDER);
};

export const setLoginProvider = (provider) => {
  localStorage.setItem(LOGIN_PROVIDER, provider);
};
