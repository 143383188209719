import { getAlumniHubUsers } from "src/requests/AlumniHubRequest";

export function getHubUsers(
  dispatch,
  userData,
  setHubUsers,
  setIsLoadingHubUsers
) {
  return (hubId) => {
    getAlumniHubUsers(dispatch, hubId)
      .then((response) => {
        // const hubUsersArray = response.data.data.data.filter(
        //   (user) => user.userId !== userData.id
        // );
        setHubUsers(response.data.data.data);
      })
      .catch((fetchhubuserserror) =>
        console.log("fetchhubuserserror", fetchhubuserserror)
      )
      .finally(() => setIsLoadingHubUsers(false));
  };
}
