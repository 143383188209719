import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardImg, CardBody, CardText, Spinner } from "reactstrap";
import { FiClock } from "react-icons/fi";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import moment from "moment";
import { Context } from "src/store";
import { likeEvent, userlikedEvent } from "src/requests/AlumniEventRequest";

const EventCard = ({ alumniEvent }) => {
  const { dispatch } = useContext(Context);
  const [isLikeEvent, setIsLikeEvent] = useState(false);
  const [isLoadingLikeEvent, setIsLoadingLikeEvent] = useState(false);
  const [, setCountEventLike] = useState([]);
  // const copyAlumniEvent = Object.assign({}, alumniEvent);
  // const [singlAlumniEvent] = useState(copyAlumniEvent);

  useEffect(() => {
    _getUserLike(alumniEvent.id);
  }, [alumniEvent?.id]);
  const style = {
    backgroundImage: `linear-gradient(
          to right bottom,
          rgba(0,0,0,0.3),
          rgba(0,0,0,0.4)
      ), url(${alumniEvent?.bannerUrl})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "120px",
  };
  const HumanDate = (startDate) => {
    return moment(startDate).format("DD MMMM, YYYY");
  };
  const truncateWithEllipses = (str, length, ending) => {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  const truncate = (str, no_words) => {
    str = str.replace(/<\/?[^>]+(>|$)/g, "");

    return str.split(" ").splice(0, no_words).join(" ");
  };
  const handleLikeEvent = (id) => {
    setIsLoadingLikeEvent(true);
    likeEvent(dispatch, id).then((res) => {
      const { message } = res.data;
      if (message) {
        _getUserLike(id);
      } else {
        setIsLoadingLikeEvent(false);
      }
    });
  };
  const islikeEventFunc = (data, id) => {
    const exist = data.find((item) => item.eventId === id);

    if (exist) return true;
    return false;
  };
  const _getUserLike = (id) => {
    console.log("is", id);
    userlikedEvent(dispatch, id).then((res) => {
      const {
        data: { data },
      } = res;

      console.log("event", data);

      setIsLoadingLikeEvent(false);

      if (islikeEventFunc(data, id)) {
        setIsLikeEvent(true);
      } else {
        setIsLikeEvent(false);
      }
      const userLike =
        data && data.map((user) => user.eventsLikesByUser.imageUrl);
      setCountEventLike(userLike);
    });
  };
  return (
    <div sm="2" md="4" className="mb-3 alumni-event-list-item width-23">
      <Card className="alumni-event">
        <CardImg top width="100%" className="alumni-event__img" style={style} />
        {alumniEvent.isAdminCreated && (
          <>
            {" "}
            <div className="event__only">Admin Verified</div>
            <div
              className="alumni__only-mobile mobile-event"
              style={{ backgroundColor: "#074B7C" }}
            >
              Admin Verified
            </div>
          </>
        )}

        <CardBody className="alumni-event__card-body">
          <CardText style={{ alignSelf: "start", width: "100%" }}>
            <div className="alumni-event__posted" style={{ display: "flex" }}>
              <span className="alumni-event__time">
                Posted {moment(alumniEvent.createdOn).fromNow()}
              </span>
              <div onClick={() => handleLikeEvent(alumniEvent.id)}>
                {isLoadingLikeEvent ? (
                  <Spinner size="sm" />
                ) : isLikeEvent ? (
                  <FaHeart
                    size={14}
                    style={{ color: "#cf1d10", cursor: "pointer" }}
                  />
                ) : (
                  <FaRegHeart
                    size={14}
                    color="#919BA8"
                    style={{ cursor: "pointer", marginRight: "10px" }}
                  />
                )}
              </div>
            </div>
          </CardText>
          <CardText style={{ alignSelf: "start", width: "100%" }}>
            <p className="alumni-event__title title-full">
              <strong>{alumniEvent.title}</strong>
            </p>
            <p className="opportunity__title title-mobile">
              {truncateWithEllipses(alumniEvent.title, 20)}
            </p>
            <div
              className="alumni-event-box-icon-box"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: "14px",
                }}
                className="alumni-event-box-icon-box__meta"
              >
                <span className="alumni-event__icon">
                  <FiClock color="#919ba8" />
                </span>
                <span className="deadline-fund">
                  <span className="event--date-val">
                    {HumanDate(alumniEvent.startDate)}
                  </span>
                </span>
              </div>
            </div>
          </CardText>
          <CardText>
            <p className="alumni-event__short-desc" style={{ width: "100%" }}>
              {truncate(alumniEvent.description, 20)}
            </p>
          </CardText>
          <Link
            to={`/alumni-events-detail/${alumniEvent.id}`}
            className="alumni-event--link"
          >
            View Details
          </Link>
        </CardBody>
      </Card>
    </div>
  );
};

export default EventCard;
