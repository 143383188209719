import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import ReactStars from "react-rating-stars-component";
import { toast } from "react-toastify";
import { Button, Row, Col } from "reactstrap";
import {
  updateCourseRating,
  rateCourse,
  deleteUserCourseRating,
} from "src/requests/TrainingRequest";

const RatingComponent = ({
  courseId,
  getCourseRating,
  hasRated,
  userId,
  toggle,
  userCourseRating,
  eventId,
  getUserCourseRating,
}) => {
  const [rating, setRating] = useState(userCourseRating.rate || 0);
  const [, setUserRating] = useState(null);
  const [submittingRating, setSubmittingRating] = useState(false);
  const [deletingRating, setDeletingRating] = useState(false);
  // setHasRated
  const _onRatingChange = (value) => {
    setRating(value);
  };

  const onAddRate = () => {
    setSubmittingRating(true);
    const payload = {
      rate: rating,
      userId,
      courseId,
    };
    hasRated ? updateRating(payload) : newRating(payload);
  };

  useEffect(() => {
    if (userCourseRating && Object.keys(userCourseRating).length > 0) {
      setUserRating(userCourseRating);
      _onRatingChange(userCourseRating.rate);
    }
  }, [userCourseRating]);

  const newRating = (payload) => {
    rateCourse(payload, eventId).then((res) => {
      getUserCourseRating();
      getCourseRating().then(() => {
        toast("Your rating on this course has been submitted");
        setSubmittingRating(false);
        toggle();
      });
    });
  };

  const updateRating = (payload) => {
    updateCourseRating(payload, eventId, userCourseRating.id).then(() => {
      getUserCourseRating();
      getCourseRating().then(() => {
        toast("Your rating on this course has been submitted");

        setSubmittingRating(false);
        toggle();
      });
    });
  };

  const deleteRating = () => {
    setDeletingRating(true);
    deleteUserCourseRating(eventId, userCourseRating.id).then(() => {
      getUserCourseRating();
      getCourseRating().then(() => {
        toast("Your rating on this course has been removed");
        setDeletingRating(false);
        toggle();
      });
    });
  };

  return (
    <>
      <span className="training-course-rating">
        <ReactStars
          value={rating || 0}
          size={25}
          count={5}
          isHalf={true}
          onChange={_onRatingChange}
          classNames="rr"
          edit={submittingRating.toString() === "false"}
        />
        <div className="rrc">
          <span className="section-rating--figure">{rating}</span>
        </div>
      </span>
      <br />
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Col md={6} sm={12}>
          {hasRated && (
            <Button
              className=" d-flex"
              color="primary"
              onClick={deleteRating}
              disabled={deletingRating || submittingRating}
            >
              Remove Rating{" "}
              {deletingRating && (
                <Loader
                  type="Oval"
                  color="white"
                  height={15}
                  width={15}
                  style={{ marginLeft: "5px" }}
                />
              )}
            </Button>
          )}
        </Col>
        <Col md={6} sm={12}>
          <Button
            className="float-right d-flex"
            disabled={rating === null || submittingRating || deletingRating}
            onClick={onAddRate}
          >
            Submit{" "}
            {submittingRating && (
              <Loader
                type="Oval"
                color="white"
                height={15}
                width={15}
                style={{ marginLeft: "5px" }}
              />
            )}
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default RatingComponent;
