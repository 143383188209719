import React, { useEffect } from "react";
import classes from "./login_v2.module.scss";
import tefLogo from "src/assets/img/logo/logo_new.webp";
import tefLogo_mobile from "src/assets/img/logo/logo_new_white.svg";
import LoginFormV3 from "./LoginForm/LoginFormV3";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import img1 from "src/assets/img/onbording/entrepreneur.webp";
import img2 from "src/assets/img/bg/female-entrepreneur_1.webp";
import img3 from "src/assets/img/bg/male-entrepreneur.jpg";

import { useCarousel } from "src/utils/useCarousel";

const slides = [
  {
    image: img1,
    title: "Be Connected!",
    paragraph:
      "Connect with entrepreneurs, mentors, and invsetors alike across and beyond Africa",
  },
  {
    image: img2,
    title: "Access new markets",
    paragraph:
      "Gain access to new markets with over 1,000,000 users across all 54 African countries on our platform",
  },
  {
    image: img3,
    title: "Business Management Trainings",
    paragraph:
      "Undergo world-class business management training to equip you with relevant knowledge to building an impactful and profitable business",
  },
];

export const CarouselContent = ({ slide, slides, active, snapToSlide }) => {
  return (
    <div className={classes.carouselContentContainer}>
      <div className={classes.carouselContentContainer__text}>
        <h5>{slide.title}</h5>
        <p>{slide.paragraph}</p>
      </div>
      <div className={classes.carouselIndicatorContainer}>
        {slides.length > 1 &&
          slides.map((_, i) => {
            return (
              <div
                onClick={() => snapToSlide(i)}
                key={i}
                className={classes.carouselIndicator}
                style={{
                  backgroundColor: active === i ? "var(--tef-red)" : "",
                }}
              ></div>
            );
          })}
      </div>
    </div>
  );
};

const Loginv2 = () => {
  const { currentIndex, snapToSlide } = useCarousel(slides.length, 3000);
  const _handleScroll = () => {
    const c_ss = document.getElementById("lc_cs");
    const l_ss = document.getElementById("ll_cs");
    if (c_ss) {
      const distanceTop_c_ss = c_ss.getBoundingClientRect().top;
      if (distanceTop_c_ss <= -443.859375) {
        l_ss.style.display = "none";
      } else {
        l_ss.style.display = "block";
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", _handleScroll);
    return () => window.removeEventListener("scroll", _handleScroll);
  }, []);

  return (
    <div className={classes.login_container}>
      <div className={classes.formSection}>
        <div className={classes.logoContainter}>
          <Link to="/">
            <img src={tefLogo} alt="tef_logo" className={classes.tef_logo} />
          </Link>
        </div>

        <div className={classes.logoContainter_mobile} id="ll_cs">
          <Link to="/">
            <img
              src={tefLogo_mobile}
              alt="tef_logo"
              className={classes.tef_logo_mobile}
            />
          </Link>
        </div>

        <div className={classes.carouselSS} id="lc_cs">
          <CarouselContent
            slide={slides[currentIndex]}
            slides={slides}
            active={currentIndex}
            snapToSlide={snapToSlide}
          />
        </div>

        <div className={classes.formContainer}>
          <h2 className={classes.logintitle}>Log into your account</h2>
          <p className={classes.newUser}>
            New User?{" "}
            <Link to={`/signup`} className={classes.signupHere}>
              {" "}
              Sign up here
            </Link>
          </p>

          <LoginFormV3 />
        </div>
      </div>

      {slides.map((slide, idx) => {
        if (currentIndex === idx) {
          return (
            <div
              key={idx}
              className={`${classes.carouselContainer} ${classes.fade}`}
              style={{ backgroundImage: `url(${slide.image})` }}
            >
              <div className={classes.carouselBS}>
                <CarouselContent
                  slide={slide}
                  slides={slides}
                  active={currentIndex}
                  snapToSlide={snapToSlide}
                />
              </div>
              {/* <fadeDiv /> */}
            </div>
          );
        }
        return null;
      })}

      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="myAwesomeCookieName2"
        style={{
          background: "#2B373B",
          // opacity: "0.9",
          zIndex: 10000,
          fontWeight: "600",
        }}
        buttonStyle={{
          backgroundColor: "red",
          color: "#fff",
          fontSize: "13px",
          borderRadius: "50px",
          fontWeight: "600",
        }}
        expires={150}
      >
        <span style={{ fontSize: "16px" }}>
          This website uses cookies to offer you the most relevant information.
          By continuing to browse the website, you are agreeing to use our site
          cookies.{" "}
          <Link to="/privacy" style={{ color: "red", fontWeight: "600" }}>
            Read our policy here
          </Link>
        </span>
      </CookieConsent>
    </div>
  );
};

export default Loginv2;
