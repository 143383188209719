import { asyncActionName } from "src/utils/AsyncUtils";
import {
  GET_USER_PROFILE,
  UPDATE_MENTOR_PROFILE,
  UPDATE_USER_PROFILE,
} from "src/actions";

import { initialState } from "src/store";

const ProfileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case asyncActionName(GET_USER_PROFILE).loading:
      return { ...state, loading: payload };
    case asyncActionName(GET_USER_PROFILE).success:
      return {
        ...state,
        profile: payload,
        onboardingCompleted: !!payload,
        success: true,
      };
    case asyncActionName(GET_USER_PROFILE).failure:
      return {
        ...state,
        error: payload.status,
        success: false,
      };

    // profile update
    case asyncActionName(UPDATE_USER_PROFILE).loading:
      return { ...state, loadingUpdatingUserProfile: payload };
    case asyncActionName(UPDATE_USER_PROFILE).failure:
      return {
        ...state,
        updatingUserProfileError: payload.status,
      };

    // mentor settings
    case asyncActionName(UPDATE_MENTOR_PROFILE).loading:
      return { ...state, loadingSetAvailability: payload };
    case asyncActionName(UPDATE_MENTOR_PROFILE).failure:
      return {
        ...state,
        setAvailabilityError: payload.status,
      };
    default:
      return state;
  }
};

export default ProfileReducer;
