/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Row } from "reactstrap";
import { Context } from "src/store";
import CourseSummaryCard from "./CourseSummaryCard";
import ModuleSummaryTable from "./ModuleSummaryTable";
import Section from "./Section";

const AssessmentSummary = ({ modules }) => {
  const [moduleSummary, setModuleSummary] = useState([]);
  const [courseSummary, setCourseSummary] = useState({
    cumulativeAvgScore: 0,
    highestScore: 0,
    lowestScore: 0,
    totalScore: 0,
  });
  const { state } = useContext(Context);

  useEffect(() => {
    setCourseSummary((prevState) => ({
      ...prevState,
      cumulativeAvgScore:
        state.Training?.trainingCourseAssessment?.cumulativeAvgScore || 0,
      highestScore: state.Training?.trainingCourseAssessment?.highestScore || 0,
      lowestScore: state.Training?.trainingCourseAssessment?.lowestScore || 0,
      totalScore: state.Training?.trainingCourseAssessment?.totalScore || 0,
    }));
    setModuleSummary(
      state.Training?.trainingCourseAssessment?.moduleScoreSummaries || []
    );
  }, [state.Training?.trainingCourseAssessment]);
  return (
    <>
      <Section title="Course Summary">
        <Row>
          <CourseSummaryCard
            score={courseSummary.totalScore}
            title={"Overall Avg. Score"}
          />
          <CourseSummaryCard
            score={courseSummary.highestScore}
            title={"Highest Score"}
          />
          <CourseSummaryCard
            score={courseSummary.lowestScore}
            title={"Lowest Score"}
          />
          <CourseSummaryCard
            score={courseSummary.cumulativeAvgScore}
            title={"Cumulative Average"}
          />
        </Row>
      </Section>

      <hr />

      <Section title="Modules Summary">
        <ModuleSummaryTable moduleSummary={moduleSummary} modules={modules} />
      </Section>
    </>
  );
};

export default AssessmentSummary;
