/* eslint-disable no-multi-str */
import React, { useEffect, useState, useContext } from "react";
import { Row, Col, FormGroup, Button } from "reactstrap";
import Layout from "src/components/Layout/Layout";
import { IoIosArrowRoundForward } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import _ from "lodash";
import { Link, useHistory } from "react-router-dom";
import tefArticleBanner from "src/assets/img/discover/image2.png";
import articleImage from "src/assets/img/discover/article.png";
import bannerBackground from "src/assets/img/discover/Vector5.png";
import { Editor } from "@tinymce/tinymce-react";
import "../TefStory/TefStories.scss";
import CreateArticleForm from "./CreateArticleForm";
import CreateArticleSuccess from "./CreateArticleSuccess";
import {
  getAllCategories,
  getAllTags,
  publishArticle,
} from "src/requests/DiscoverRequest";
import { Context } from "src/store";
import { isAllowedFiles, UploadFile } from "src/requests/fileUploadRequest";
import Loader from "src/components/Loader/Loader";
import DefaultImage from "src/assets/img/defaultAchievementImage.png";

const CreateArticle = () => {
  const [modal, setModal] = useState(false);
  const { state, dispatch } = useContext(Context);
  const data = {
    title: "",
    shortDescription: "",
    fullDescription: "",
    bannerUrl: DefaultImage,
    categoryId: "",
    tags: [],
  };
  const history = useHistory();
  const toggle = () => setModal(!modal);
  const [articleData, setArticleData] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState([]);
  useEffect(() => {
    getAllCategories(dispatch);
    getAllTags(dispatch);
  }, []);

  useEffect(() => {}, [articleData]);
  const { categories, tags } = state.Discovers;
  const industryObj = categories.map((category) => {
    return {
      label: category.name,
      value: category.id,
    };
  });
  const tagsOption = tags.map((category) => {
    return {
      label: category.name,
      value: category.id,
      color: "#666666",
    };
  });
  const handleChange = (selectedItem) => {
    setArticleData({
      ...articleData,
      tags: selectedItem,
    });
  };
  const onDrop = (picture) => {
    const existingFiles = [...file];
    const validateFiles = isAllowedFiles(Array.from([...picture]));
    if (validateFiles.hasError) {
      validateFiles.errors.forEach((file) => {});
    } else {
      existingFiles.push(...picture);
      const uniqueFile = _.uniqBy(existingFiles, "name");
      setFile(uniqueFile);
    }
  };
  const submitArticle = (e) => {
    e.preventDefault();
    const selectTagLabel = articleData.tags.map((tag) => tag.value);
    articleData.tags = selectTagLabel;
    setIsLoading(true);
    if (file.length > 0) {
      UploadFile(dispatch, true, file)
        .then((res) => {
          if (res && res.status === 200) {
            res.files.map((img) => (articleData.bannerUrl = img));
            _submit(articleData);
          }
        })
        .catch(() => {
          // _submit(articleData);
        });
    } else {
      // _submit(articleData);
    }
  };

  const _submit = (data) => {
    publishArticle(dispatch, data).then((res) => {
      setIsLoading(false);
      if (res.status === 201) {
        setArticleData(data);
        setArticleData({
          title: "",
          shortDescription: "",
          fullDescription: "",
          bannerUrl: DefaultImage,
          categoryId: "",
          tags: [],
        });
        toggle();
      }
    });
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setArticleData({ ...articleData, [name]: value });
  };

  const selectHandler = (name, value) => {
    setArticleData({ ...articleData, [name]: value });
  };
  return (
    <Layout hideLeft hideRight>
      <div className="mb-5">
        <div className="create-tef-story">
          <Link to="/discover" style={{ fontWeight: "bold" }}>
            {"TEF Articles"} <IoIosArrowRoundForward />
          </Link>
          <span>{"Create an Article"}</span>
        </div>
        <div className="create-tefstory-banner">
          <Row>
            <Col md={10}>
              <div>
                <h1>
                  Create An Article{" "}
                  <span>
                    <img src={articleImage} alt="logo" />
                  </span>
                </h1>
                <h6>Tell your story to us in details</h6>
              </div>
            </Col>
            <Col md={2}>
              <div className="create-tefstory-banner__logo">
                <img
                  src={bannerBackground}
                  alt="background"
                  style={{ width: "200px", top: "-32px", right: "80px" }}
                  className="position-relative"
                />
                <img
                  src={tefArticleBanner}
                  alt="logo"
                  style={{ width: "65px", top: "-20px" }}
                  className="position-absolute"
                />
              </div>
            </Col>
          </Row>
        </div>
        <form onSubmit={submitArticle}>
          <CreateArticleForm
            industryObj={industryObj}
            tagsOption={tagsOption}
            toggle={toggle}
            selectHandler={selectHandler}
            handleChange={handleChange}
            inputHandler={inputHandler}
            onDrop={onDrop}
            articleData={articleData}
          />
          <div className="tefstory-editor mt-3">
            <Row>
              <Col sm={12} md={12}>
                <FormGroup type="textarea">
                  <Editor
                    apiKey={process.env.REACT_APP_TINY_CLOUD_KEY}
                    init={{
                      height: 400,
                      branding: false,
                      statusbar: false,
                      placeholder: "Start Typing...",
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help",
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
                    }}
                    onEditorChange={(content, editor) => {
                      setArticleData({
                        ...articleData,
                        fullDescription: content,
                      });
                    }}
                    value={articleData.fullDescription}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                className="preview-btn text-center w-25"
                onClick={() => history.push("/discover")}
              >
                <span className="pr-2">
                  <FaEye />
                </span>
                Cancel
              </Col>
              <Col md={6}>
                <Button
                  className="submit-tef-story text-center"
                  // onClick={submitArticle}
                  disabled={isLoading}
                  type="submit"
                  style={{ width: "100%", display: "block" }}
                >
                  {isLoading ? <Loader color="light" /> : "Publish Article"}
                </Button>
              </Col>
            </Row>
          </div>
        </form>
      </div>
      <CreateArticleSuccess
        modal={modal}
        title={articleData.title}
        toggle={toggle}
      />
    </Layout>
  );
};

export default CreateArticle;
