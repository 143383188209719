import React from "react";
import NewAuthLayout_v2 from "src/Auth/NewLayout/NewAuthLayout_v2";
import LandingPageCarousel from "./Carousel";
import Community from "./Community";
import Event from "./Event";
import GiveBack from "./Giveback";
import CountDown from "./CountDown";
import NewsLetter from "./NewLetter";

// import classes from "./LandingPage.module.scss";

const LandingPage = () => (
  <NewAuthLayout_v2>
    <div style={{ backgroundColor: "#F2F4F7" }}>
      <LandingPageCarousel />
      <Community />
      <Event />
      <GiveBack />
      <CountDown />
      <NewsLetter />
    </div>
  </NewAuthLayout_v2>
);

export default LandingPage;
