import React from "react";
import { Table } from "reactstrap";
import { RiMessage2Line, RiEyeLine } from "react-icons/ri";
import {
  AiOutlineDownload,
  AiFillFileExcel,
  AiOutlineFileWord,
  AiOutlineFilePdf,
  AiOutlineFilePpt,
  AiOutlineFileText,
} from "react-icons/ai";
// import ReactPaginate from "react-paginate";
import "./index.scss";

// {
//  data,
//  onDownload,
//  pageTotal,
// }
const TableList = ({ resources, onDownload, onOpen, ErrorEmpPage }) => {
  // const PER_PAGE = 50;

  return (
    <div>
      {resources.length > 0 ? (
        <Table borderless responsive className={`bg-white`}>
          <thead>
            <tr style={{ backgroundColor: "white" }}>
              <th className="table_th">Name</th>
              <th className="table_th">Type</th>
              <th className="table_th">Last Modified</th>
              <th className="table_th">
                <RiMessage2Line color="999" />
              </th>
              <th className="table_th">
                <RiEyeLine color="999" />
              </th>
              <th className="table_th"></th>
            </tr>
          </thead>
          <tbody>
            {resources.map((data) => (
              <tr style={{ backgroundColor: "white" }} key={data.id}>
                <td className="table_td d-flex">
                  <Icon path={data.fileUrl} />
                  {data.title}
                </td>
                <td className="table_td">Contact</td>
                <td className="table_td">
                  {data.datePublished.toLocaleDateString()}{" "}
                  <span className="text-muted text-gray">
                    {" "}
                    By {data.author}
                  </span>
                </td>
                <td className="table_td">{data.commentCount}</td>
                <td className="table_td">{data.viewCount}</td>
                <td className="table_td">
                  <AiOutlineDownload
                    onClick={() => onDownload(data)}
                    style={{ fontSize: 17, cursor: "pointer" }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <ErrorEmpPage />
      )}
    </div>
  );
};

const Icon = ({ path }) => {
  const style = { fontSize: 20, marginRight: 10 };
  if (path.match(/\.(docx|doc)$/)) {
    return <AiOutlineFileWord color="blue" style={style} />;
  } else if (path.match(/\.(pdf)$/)) {
    return <AiOutlineFilePdf color="red" style={style} />;
  } else if (path.match(/\.(xls|xlsx)$/)) {
    return <AiFillFileExcel color="green" style={style} />;
  } else if (path.match(/\.(txt|csv)$/)) {
    return <AiOutlineFileText style={style} />;
  } else if (path.match(/\.(ppt)$/)) {
    return <AiOutlineFilePpt color="orange" style={style} />;
  } else {
    return null;
  }
};

export default TableList;
