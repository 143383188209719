import React, { useState, useEffect } from "react";
import { CardGroup } from "reactstrap";
import NewsCard from "src/modules/Alumni/Resources/components/NewsCard/index";

const MainTab = ({ resources, onClick }) => {
  const [largePrints, setLargePrints] = useState([]);
  const [smallPrints, setSmallPrints] = useState([]);
  useEffect(() => {
    const __largePrints = [];
    const __smallPrints = [];
    resources.forEach((res, i) => {
      if (i <= 1) {
        __largePrints.push(res);
      } else __smallPrints.push(res);
      setLargePrints(__largePrints);
      setSmallPrints(__smallPrints);
    });
  }, [resources]);

  return (
    <div className="mt-4">
      <CardGroup>
        {largePrints.map((res, i) => (
          <NewsCard
            key={i}
            md={6}
            img={res.fileUrl}
            title={res.title}
            author={res.author || ""}
            date={res.createdOn.toLocaleDateString()}
            content={""}
            onClick={() => onClick(res)}
          />
        ))}

        {smallPrints.map((res, i) => (
          <NewsCard
            key={i}
            md={6}
            img={res.fileUrl}
            title={res.title}
            author={res.author || ""}
            date={res.createdOn.toLocaleDateString()}
            onClick={() => onClick(res)}
            isListMode={true}
          />
        ))}
      </CardGroup>
    </div>
  );
};

export default MainTab;
