import React, { useContext, useState } from "react";
// import { FaTrash, FaBookmark, FaRegBookmark } from "react-icons/fa";
import { Context } from "src/store";
import { addBookmark } from "src/requests/PostRequest";

const MoreOptions = ({
  show,
  handleDelete,
  userId,
  postOwnerId,
  title,
  contentType,
  contentId,
  isBookmarked,
}) => {
  const [bookmarked, setBookmarked] = useState(isBookmarked);
  const { dispatch } = useContext(Context);
  const tempTitle = title
    ? title.split(" ").length > 5
      ? [
          title.split(" ")[0],
          title.split(" ")[1],
          title.split(" ")[2],
          title.split(" ")[3],
        ].join(" ")
      : title
    : "No title";

  const handleBookmark = (e) => {
    const payload = {
      title: tempTitle,
      contentType,
      contentId,
      userId,
    };

    addBookmark(dispatch, payload).then((bookmarkResponse) => {
      if (bookmarkResponse.status === 200) {
        setBookmarked(!bookmarked);
      } else {
        setBookmarked(!bookmarked);
      }
    });
  };

  return (
    <div
      className={
        show ? "more-options delete-post" : "more-options hide-options"
      }
    >
      <button onClick={handleBookmark} className="more-options__btn">
        {/* {bookmarked ? <FaBookmark /> : <FaRegBookmark />} */}
        <span className="more-options__btn--span">
          {bookmarked ? "Bookmarked" : "Bookmark Post"}
        </span>
      </button>
      {postOwnerId === userId ? (
        <button onClick={handleDelete} className="more-options__btn">
          {/* <FaTrash color="red" /> */}
          <span className="more-options__btn--span">Delete Post</span>
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default MoreOptions;
