/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from "react";
import "../common.scss";
import "./experience.scss";
import { FormField, Select } from "src/modules/onboarding/components";
import { FaSave, FaUpload } from "react-icons/fa";
import { SelectBox } from "src/modules/Application/Flagship/personalInfo/components";
import { Multiselect } from "multiselect-react-dropdown";

import {
  retrieveTempUserApplication,
  storeTempUserApplication,
  _setSteps,
  getUserCurrentApplication,
  _sanitizeData,
} from "../../../helper";
import { personalInfo } from "../personanlInfoObj";
import { Context } from "../../../../../store";
// import { getUserProfile } from "../../../../../requests/ProfileRequest";
import {
  displayError,
  // _scrollToRef,
} from "src/modules/onboarding/helper";
import queryString from "query-string";
import { UploadFile } from "src/requests/fileUploadRequest";
import "react-phone-input-2/lib/style.css";
import { sdgs, experience, businessStages } from "./options";
import {
  // getIndustrySubSector,
  getCountryAlpha2Code,
} from "src/modules/Application/Flagship/personalInfo/helper";
// import { industryTypes } from "src/modules/onboarding/stepTwo/industryType.js";
import Loader from "react-loader-spinner";
import axios from "axios";
// import { numbers } from "src/modules/onboarding/tefMentorOnboarding/static";
import CLoader from "src/modules/Application/components/CLoader";
import ProgressReport from "src/modules/Application/components/ApplicantProgressReport/ProgressReport";
import { toast } from "react-toastify";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { sectionTwoTranslations } from "src/modules/Application/instructions";
import { useTranslation } from "react-i18next";
import {
  CustomCountryDropdown,
  CustomCountryRegionDropdown,
} from "src/components/CustomCountryRegionDropdown";
import { FileViewerReadOnly } from "src/components/FileViewer/FileViewer";
import { updateUserProfile } from "src/requests/ProfileRequest";
import { Input } from "reactstrap";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useIndustryList } from "src/modules/Application/applicationHooksAll/useIndustryTypeList";

const setExistingInfo = (info, profile) => {
  info = JSON.parse(JSON.stringify(info));

  const profileInfo = [
    "businessName",
    "businessCity",
    // "businessCountry",
    "businessState",
    "businessAddress",
    // "businessIndustry",
    // "industrySubSector",
  ];

  profileInfo.forEach((pInfo) => {
    if (!info[pInfo]) {
      info[pInfo] = profile[pInfo];
    }
  });

  if (!info.businessCountryId) {
    info.businessCountryId = getCountryAlpha2Code(
      profile.businessCountry || "Nigeria"
    );
  }

  return info;
};

function isValidFile(fileName) {
  const ext = fileName.split(".").pop();
  const validEx = ["pdf", "docx", "doc"];
  return validEx.includes(ext.toLowerCase());
}

const Experience = (props) => {
  const { applicationId, endDate, lang } = queryString.parse(
    props.history.location.search
  );
  const [isPersonalInfoInit, setIsPersonalInfoInit] = useState(false);
  const { dispatch } = useContext(Context);
  const [hasError, setHasError] = useState(false);
  const { user } = get_user_info_from_token();
  const [isSavingAndContinue, setIsSavingAndContinue] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [percentComplete, setPercentComplete] = useState(40);
  const [fileError, setFileError] = useState("");
  const [isuploadingFile, setIsUploadingFile] = useState(false);
  const [updatingMessage, setUpdatingMessage] = useState("");

  const currentUser = useCurrentUser();
  // const language = JSON.parse(localStorage.getItem("language"));
  const [language, setLanguage] = useState(lang);
  // const yearsOfExperience = yearsOfStarupExperience[language || "en"];
  const experienceObj = experience[language || "en"] || experience.en;
  const sdgGoals = sdgs[language || "en"] || sdgs.en;
  // const industryObj = industryTypes[language || "en"];
  const bStages = businessStages[language || "en"] || businessStages.en;
  // const [industrySubSectList, setIndustrySubSectList] = useState([]);

  const { t, i18n } = useTranslation();

  const ref = useRef();

  const schema = Yup.object({
    aboutIdea: Yup.string().required(t("Required")),
    sdgs: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string(),
          label: Yup.string(),
        })
      )
      .min(3, t("Select minimum of three"))
      .required(t("Required")),

    isBusinessAddressGreenEconomyOrClimateChange: Yup.boolean().required(
      t("Please select")
    ),

    businessAddressGreenEconomyOrClimateChangeDescription: Yup.string().when(
      "isBusinessAddressGreenEconomyOrClimateChange",
      {
        is: true,
        then: () => Yup.string().required(t("Required")),
      }
    ),
    businessName: Yup.string(),
    businessIndustry: Yup.string().required(t("Required")),
    industrySubSector: Yup.string().required(t("Required")),
    businessCountryId: Yup.string().required(t("Required")),
    businessStage: Yup.string().required(t("Required")),
    businessState: Yup.string().required(t("Required")),
    businessCity: Yup.string().required(t("Required")),
    businessAddress: Yup.string().required(t("Required")),
    businessWebsite: Yup.string().url("Invalid url"),
    businessLocalGovernmentArea: Yup.string().when("businessCountryId", {
      is: "NG" || "ng",
      then: () => Yup.string().required(t("Required")),
    }),
    isBusinessRegistered: Yup.boolean().required(t("Required")),
    hasBusinessPartnerConsent: Yup.boolean().required(t("Required")),
    isBusinessRural: Yup.boolean().required(t("Required")),
    hasReceivedPriorTEFFunding: Yup.boolean().required(t("Required")),
    partnerConsentLetterUrl: Yup.string().when("hasBusinessPartnerConsent", {
      is: true,
      then: () => Yup.string().required(t("Required")),
    }),
    teamSize: Yup.string().required(t("Required")),
    haveBusinessPartners: Yup.boolean().required(t("Required")),
    isIdeaInWasteManagementSector: Yup.boolean().required(t("Required")),
    availableRevenue: Yup.string().required(t("Required")),
    hasMeasuredEnvironmentalImpact: Yup.boolean().required(t("Required")),
    hasReceivedPriorTEFFundingOnSameBusinessIdea: Yup.boolean().when(
      "hasReceivedPriorTEFFunding",
      {
        is: true,
        then: () => Yup.string().required(t("Required")),
      }
    ),
    businessRegistrationNumber: Yup.string().when("isBusinessRegistered", {
      is: true,
      then: () => Yup.string().required(t("Required")),
    }),
  }).required();

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    defaultValues: { ...personalInfo },
  });

  const { industryList, subSectorList } = useIndustryList(
    watch().businessIndustry,
    watch().languageId
  );

  // console.log(errors);
  // console.log(watch());

  const extractUserGroup = () => {
    const userGroups = currentUser.userGroups.map((group) => {
      return group.group.id;
    });
    return userGroups;
  };

  useEffect(() => {
    const lng = lang || "en";
    setLanguage(lng);
    setValue("languageId", lang);
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
  }, [lang]);

  useEffect(() => {
    const errorsvalues = Object.values(errors);
    if (errorsvalues.length > 0) {
      const ref = errorsvalues?.[0]?.ref;
      if (ref) {
        document
          .getElementById(errorsvalues?.[0]?.ref.name)
          ?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [errors]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const query = props.history.location.search;
    if (query) {
      const parsedQuery = queryString.parse(query);
      if (Object.keys(parsedQuery).length === 0 || !parsedQuery.applicationId) {
        props.history.replace("/programme/dashboard", {});
        props.history.go(1);
      }
    } else {
      props.history.replace("/programme/dashboard", {});
      props.history.go(1);
    }
  }, [props.history.location.search]);

  useEffect(() => {
    getUserCurrentApplication(
      `${process.env.REACT_APP_APPLICATION_BASE_URL}/applicant`
    ).then((userApps) => {
      const applicationId = queryString.parse(props.history.location.search)
        .applicationId;

      const foundApplication = userApps.filter((app) => {
        return app.applicationId === applicationId;
      });

      if (foundApplication.length > 0) {
        const app = foundApplication[0];
        if (app.progress === 40) {
          return props.history.push({
            pathname: "/application/tests/personality",
            search: `?applicationId=${applicationId}&endDate=${endDate}&lang=${app.languageId}`,
          });
        }
        if (app.progress > 40 || app.progress === 90) {
          return props.history.push({
            pathname: "/application/declaration",
            search: `?applicationId=${applicationId}&endDate=${endDate}&lang=${app.languageId}`,
          });
        }
      } else {
        retrieveTempUserApplication(applicationId).then((application) => {
          if (!application || application === null) {
            props.history.replace("/application/tour", {});
            props.history.go(1);
          } else {
            if (!application.applicationId) {
              props.history.replace("/home", {});
              props.history.go(1);
            } else {
              reset({ ...personalInfo, ...application });

              setIsPersonalInfoInit(true);
              return application;
            }
          }
        });
      }
    });
  }, []);

  // console.log(errors);

  useEffect(() => {
    if (currentUser && isPersonalInfoInit) {
      let application = { ...getValues() };
      application = setExistingInfo(application, currentUser, user);
      reset({ ...application });
    }
  }, [currentUser, isPersonalInfoInit]);

  // const onChange = (target, value) => {
  //   if (target === "businessCountryId") {
  //     const obj = { ...info, [target]: value, businessState: "" };
  //     if (value.toLowerCase() !== "") {
  //       obj.businessLocalGovernmentArea = "";
  //     }
  //     setInfo(obj);
  //   } else if (
  //     target === "teamSize" &&
  //     info.isWorkingInTeam &&
  //     (value === "" || value === 0)
  //   ) {
  //     setInfo({ ...info, [target]: 1 });
  //   } else {
  //     setInfo({ ...info, [target]: CastValue(target, value) });
  //   }
  // };

  const updateProfileAccount = (info) => {
    const profileDetails = [
      "businessName",
      "businessCity",
      "businessState",
      "businessAddress",
      // "businessIndustry",
      // "industrySubSector",
    ];

    let profileObj = {};
    profileDetails.forEach((key) => {
      if (info[key]) {
        profileObj = { ...profileObj, [key]: info[key] };
      }
    });

    if (!profileObj.businessCountry) {
      profileObj.businessCountry = info.businessCountryId;
    }

    if (!profileObj.aboutBusiness) {
      profileObj.aboutBusiness = info.aboutIdea;
    }

    if (!profileObj.businessRevenue) {
      profileObj.businessRevenue = info.monthlyIncome;
    }

    delete profileObj.businessCountryId;
    return updateUserProfile(dispatch, profileObj);
  };
  const _handleSubmit = (info) => {
    if (loadingSave) return;
    setLoadingSave(true);
    const data = _sanitizeData(info, extractUserGroup);
    axios.defaults.headers.common["application-id"] = applicationId;
    setUpdatingMessage(t("Saving your application progress..."));
    return axios
      .post(`${process.env.REACT_APP_APPLICATION_BASE_URL}/applicant`, {
        ...data,
      })
      .then((res) => {
        setUpdatingMessage(t("Updating profile information..."));
        return updateProfileAccount(data).then(() => {
          setUpdatingMessage("");
          setPercentComplete(res.data.data.progress);
          setLoadingSave(false);
          setShowReport(true);
        });
      })
      .catch((e) => {
        setLoadingSave(false);
      });
  };

  const _onContinueClick = () => {
    return props.history.push({
      pathname: `/application/tests/personality`,
      search: `?applicationId=${
        getValues().applicationId
      }&endDate=${endDate}&lang=${language}`,
    });
  };

  const _saveAndContinue = (e) => {
    e.preventDefault();
    if (isSavingAndContinue) return;
    const info = getValues();
    setIsSavingAndContinue(true);
    const appInfo = JSON.parse(JSON.stringify(info));
    Object.keys(appInfo.validationErrors).forEach((key) => {
      appInfo.validationErrors[key] = null;
    });
    const currentStep =
      window.location.pathname +
      `?applicationId=${info.applicationId}&endDate=${endDate}&lang=${language}`;
    const userInfo = _setSteps(appInfo, currentStep, currentStep, "");
    setUpdatingMessage("Saving your application progress...");

    // applicationId is misnamed - this is really programmeEventId
    // TODO(TP-2083): rename to programmeEventId.
    storeTempUserApplication(userInfo, info.applicationId).then((app) => {
      setUpdatingMessage("Updating profile information...");
      return updateProfileAccount(userInfo).then(() => {
        toast(`${t("your application progress has been saved")}`);
        // _scrollToRef(ref);
        setIsSavingAndContinue(false);
      });
    });
  };

  const _previousStep = (e) => {
    e.preventDefault();
    const info = getValues();
    const appInfo = JSON.parse(JSON.stringify(info));
    Object.keys(appInfo.validationErrors).forEach((key) => {
      appInfo.validationErrors[key] = null;
    });
    const currentStep =
      window.location.pathname +
      `?applicationId=${info.applicationId}&endDate=${endDate}&lang=${language}`;
    const userInfo = _setSteps(
      appInfo,
      appInfo.steps.currentCompletedStep,
      currentStep,
      `/application/personalinfo/bio?applicationId=${
        appInfo.applicationId
      }&endDate=${endDate.trim()}&lang=${lang}`
    );
    setIsSavingAndContinue(true);
    // return console.log(userInfo);
    setUpdatingMessage("Saving your application progress...");
    // applicationId is misnamed - this is really programmeEventId
    // TODO(TP-2083): rename to programmeEventId.
    storeTempUserApplication(userInfo, userInfo.applicationId).then((app) => {
      setIsSavingAndContinue(false);
      // return console.log(userInfo);
      setUpdatingMessage("");
      props.history.replace(
        `/application/personalinfo/bio?applicationId=${info.applicationId}&endDate=${endDate}&lang=${language}`,
        {}
      );
      props.history.go(1);
    });
  };

  const _onDocChange = (e) => {
    const files = e.target.files;
    if (!isValidFile(files[0].name)) {
      setFileError(
        t("Invalid file format. Only doc, docx and pdf are allowed")
      );
      return;
    }

    setFileError("");
    setIsUploadingFile(true);
    UploadFile(dispatch, false, files)
      .then((res) => {
        const { files } = res;
        setValue("partnerConsentLetterUrl", files[0]);
        setIsUploadingFile(false);
      })
      .catch((e) => {
        setIsUploadingFile(false);
      });
  };

  // console.log(watch());

  return (
    <div className="application-personal-info-c notranslate">
      <div className="application-personal-info-c__heading">
        <h1>{experienceObj.title}</h1>
        <p>{t("Application_Overview")}</p>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <span
          className="back-2-somewhere"
          onClick={() => props.history.replace("/home", {})}
        >
          {t("Back to Home")}
        </span>

        <span className="back-2-somewhere" onClick={_previousStep}>
          {t("Back to Bio")}
        </span>
      </div>

      <div className="application-personal-info-c__form">
        <div className="p-info-form-heading">
          <h5>
            <span className="bold">{experienceObj.title}</span>{" "}
          </h5>
          <h4>{sectionTwoTranslations[language || "en"]}</h4>
        </div>
        <form ref={ref} onSubmit={handleSubmit(_handleSubmit)}>
          {hasError && (
            <span style={{ color: "red" }}>{experienceObj.requiredInfo}</span>
          )}
          <div className="row">
            <div className="col-md-12">
              <Controller
                name="aboutIdea"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      placeholder={experienceObj.businessSummary}
                      label={experienceObj.businessIdea}
                      type="textarea"
                      id="aboutIdea"
                      value={value}
                      onChange={(_, val) => onChange(val)}
                      name={name}
                      onBlur={onBlur}
                      error={error?.message}
                    />
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <br />

              <Controller
                name="sdgs"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={experienceObj.goals} id="sdgs">
                      <Multiselect
                        options={sdgGoals}
                        displayValue="label"
                        onSelect={(selectedList, selectedItem) =>
                          // onSdgSelect("sdgs", selectedList)
                          onChange(selectedList)
                        }
                        placeholder={t("Select")}
                        selectionLimit={3}
                        selectedValues={value}
                        closeOnSelect={false}
                        style={{
                          chips: {
                            background: "red",
                          },
                        }}
                        onBlur={onBlur}
                        onRemove={(selectedList, selectedItem) =>
                          onChange(selectedList)
                        }
                      />
                    </FormField>

                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <br />
              <Controller
                name="hasReceivedPriorTEFFunding"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.hasReceivedPriorTEFFunding}
                      id="hasReceivedPriorTEFFunding"
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            label={experienceObj.yes}
                            onSelect={(_, value) => {
                              onChange(value);
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            label={experienceObj.no}
                            onSelect={(_, value) => {
                              onChange(value);
                              setValue(
                                "hasReceivedPriorTEFFundingOnSameBusinessIdea",
                                false
                              );
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                      </div>
                      <>
                        {error?.message && displayError(`${t(error?.message)}`)}
                      </>
                    </FormField>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <br />
              <Controller
                name="hasReceivedPriorTEFFundingOnSameBusinessIdea"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={
                        experienceObj.hasReceivedPriorTEFFundingOnSameBusinessIdea
                      }
                      disabled={!watch().hasReceivedPriorTEFFunding}
                      id="hasReceivedPriorTEFFundingOnSameBusinessIdea"
                      // onChange={(_, val) => onChange(val)}
                      value={value}
                      name={name}
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            label={experienceObj.yes}
                            onSelect={(_, value) => {
                              onChange(value);
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            label={experienceObj.no}
                            onSelect={(_, value) => {
                              onChange(value);
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                      </div>
                    </FormField>
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <br />
              <Controller
                name="isBusinessAddressGreenEconomyOrClimateChange"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={
                        experienceObj.isBusinessAddressGreenEconomyOrClimateChange
                      }
                      id="isBusinessAddressGreenEconomyOrClimateChange"
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            label={experienceObj.yes}
                            onSelect={(_, value) => {
                              onChange(value);
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            label={experienceObj.no}
                            onSelect={(_, value) => {
                              onChange(value);
                              setValue(
                                "businessAddressGreenEconomyOrClimateChangeDescription",
                                ""
                              );
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                      </div>
                      <>
                        {error?.message && displayError(`${t(error?.message)}`)}
                      </>
                    </FormField>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Controller
                name="businessAddressGreenEconomyOrClimateChangeDescription"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      disabled={
                        !watch().isBusinessAddressGreenEconomyOrClimateChange
                      }
                      label={
                        experienceObj.businessAddressGreenEconomyOrClimateChangeDescription
                      }
                      type="textarea"
                      id="businessAddressGreenEconomyOrClimateChangeDescription"
                      value={value}
                      onChange={(_, val) => onChange(val)}
                      name={name}
                      onBlur={onBlur}
                      error={error?.message}
                    />
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <br />
              <Controller
                name="isIdeaInWasteManagementSector"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.isIdeaInWasteManagementSector}
                      id="isIdeaInWasteManagementSector"
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            label={experienceObj.yes}
                            onSelect={(_, value) => {
                              onChange(value);
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            label={experienceObj.no}
                            onSelect={(_, value) => {
                              onChange(value);
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                      </div>
                      <>
                        {error?.message && displayError(`${t(error?.message)}`)}
                      </>
                    </FormField>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6" style={{ marginTop: "-5px" }}>
              <Controller
                name="businessName"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.businessName}
                      placeholder={experienceObj.namePlaceholder}
                      value={value}
                      onChange={(_, val) => onChange(val)}
                      name={name}
                      id="businessName"
                      onBlur={onBlur}
                      error={error?.message}
                    />
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                name="businessIndustry"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.industry}
                      id="businessIndustry"
                    >
                      <Select
                        defaultVal={experienceObj.select1}
                        list={industryList}
                        value={value}
                        onChange={(_, value) => {
                          onChange(value);
                          setValue("industrySubSector", "");
                        }}
                        name={name}
                        onBlur={onBlur}
                        error={error?.message}
                      />
                    </FormField>
                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Controller
                name="industrySubSector"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.subSector}
                      id="industrySubSector"
                    >
                      <Input
                        type="select"
                        defaultValue={t("Select")}
                        onBlur={onBlur}
                        onChange={(e) => onChange(e.target.value)}
                        name={name}
                        value={value}
                      >
                        <option value="">{t("Select")}</option>
                        {subSectorList.map((e, i) => {
                          return (
                            <option key={i} value={e.value}>
                              {e.label}{" "}
                            </option>
                          );
                        })}
                      </Input>
                    </FormField>

                    <>
                      {error?.message && displayError(`${t(error?.message)}`)}
                    </>
                  </>
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                name="businessCountryId"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.businessCountry}
                      id="businessCountryId"
                    >
                      <CustomCountryDropdown
                        classes={`flex country-state-list`}
                        value={value}
                        name={name}
                        valueType="short"
                        onChange={(val) => {
                          onChange(val);
                          setValue("businessState", "");
                        }}
                        onBlur={onBlur}
                      />
                    </FormField>
                    {error?.message && (
                      <div
                        className="displayError"
                        style={{ marginLeft: "3px" }}
                      >
                        {error?.message && displayError(`${t(error?.message)}`)}
                      </div>
                    )}
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Controller
                name="businessState"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.businessState}
                      id="businessState"
                    >
                      <CustomCountryRegionDropdown
                        countryValueType="short"
                        defaultOptionLabel={experienceObj.select2}
                        country={watch().businessCountryId}
                        classes={`flex country-state-list $}`}
                        name={name}
                        value={value}
                        onChange={(val) => onChange(val)}
                        onBlur={onBlur}
                      />
                    </FormField>
                    {error?.message && (
                      <div
                        className="displayError"
                        style={{ marginLeft: "3px" }}
                      >
                        {error?.message && displayError(`${t(error?.message)}`)}
                      </div>
                    )}
                  </>
                )}
              />
            </div>
            <div className="col-md-6" style={{ marginTop: "-10px" }}>
              <Controller
                name="businessCity"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.businessCity}
                      placeholder={experienceObj.cityPlaceholder}
                      value={value}
                      name={name}
                      id="businessCity"
                      onChange={(_, val) => onChange(val)}
                      onBlur={onBlur}
                    />
                    {error?.message && displayError(error?.message)}
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Controller
                name="businessAddress"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.businessLocation}
                      placeholder="Enter Business address"
                      value={value}
                      onChange={(_, val) => onChange(val)}
                      name={name}
                      id="businessAddress"
                      onBlur={onBlur}
                      error={error?.message}
                    />
                    {error?.message && displayError(error?.message)}
                  </>
                )}
              />
            </div>

            <div className="col-md-6">
              <Controller
                name="businessWebsite"
                control={control}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.businessWebsite}
                      value={value}
                      onChange={(_, value) => onChange(value)}
                      name={name}
                      id="businessWebsite"
                      type={"url"}
                      error={error?.message}
                      onBlur={onBlur}
                    />
                    {error?.message && displayError(error?.message)}
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Controller
                name="businessLocalGovernmentArea"
                control={control}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.businessLocalGovernmentArea}
                      placeholder={experienceObj.businessLocalGovernmentArea}
                      value={value}
                      onChange={(_, val) => onChange(val)}
                      disabled={
                        watch().businessCountryId.toLowerCase() !== "ng"
                      }
                      name="businessLocalGovernmentArea"
                      id="businessLocalGovernmentArea"
                      onBlur={onBlur}
                      error={error?.message}
                    />

                    {error?.message && displayError(error?.message)}
                  </>
                )}
              />
            </div>

            <div className="col-md-6">
              <Controller
                name="businessStage"
                control={control}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.businessStage}
                      id="businessStage"
                    >
                      <Select
                        defaultVal={experienceObj.select1}
                        list={bStages}
                        value={value}
                        onChange={(_, val) => onChange(val)}
                        name="businessStage"
                        onBlur={onBlur}
                        error={error?.message}
                      />
                    </FormField>
                    {error?.message && displayError(error?.message)}
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <Controller
                name="isBusinessRegistered"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.isBusinessRegistered}
                      id="isBusinessRegistered"
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            label={experienceObj.yes}
                            onSelect={(target, value) => {
                              onChange(value);
                            }}
                            name="isBusinessRegistered"
                            selected={value}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            label={experienceObj.no}
                            onSelect={(target, value) => {
                              onChange(value);
                              setValue("businessRegistrationNumber", "");
                            }}
                            name="isBusinessRegistered"
                            selected={value}
                          />
                        </div>
                      </div>
                      {error?.message && displayError(error?.message)}
                    </FormField>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Controller
                name="businessRegistrationNumber"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      disabled={!watch().isBusinessRegistered}
                      label={experienceObj.businessRegistrationNumber}
                      id="businessRegistrationNumber"
                      value={value}
                      onChange={(_, v) => onChange(v)}
                      name="businessRegistrationNumber"
                      onBlur={onBlur}
                      error={error?.message}
                    />
                    {error?.message && displayError(error?.message)}
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <Controller
                name="teamSize"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.noOfPeople}
                      placeholder="e.g 20"
                      type="number"
                      min="1"
                      name="teamSize"
                      id="teamSize"
                      onChange={(_, val) => onChange(val)}
                      value={value.toString()}
                      onBlur={onBlur}
                    ></FormField>

                    {error?.message && displayError(error?.message)}
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <Controller
                name="availableRevenue"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.availableRevenue}
                      type="number"
                      min="1"
                      name="availableRevenue"
                      id="availableRevenue"
                      onChange={(_, val) => onChange(val)}
                      value={value.toString()}
                      onBlur={onBlur}
                    ></FormField>

                    {error?.message && displayError(error?.message)}
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <br />
              <Controller
                name="hasMeasuredEnvironmentalImpact"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.hasMeasuredEnvironmentalImpact}
                      id="hasMeasuredEnvironmentalImpact"
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            label={experienceObj.yes}
                            onSelect={(_, value) => {
                              onChange(value);
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            label={experienceObj.no}
                            onSelect={(_, value) => {
                              onChange(value);
                            }}
                            name={name}
                            selected={value}
                          />
                        </div>
                      </div>
                      <>
                        {error?.message && displayError(`${t(error?.message)}`)}
                      </>
                    </FormField>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <br />
              <Controller
                name="haveBusinessPartners"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.businessPartners}
                      id="haveBusinessPartners"
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            selected={value}
                            name="haveBusinessPartners"
                            label={experienceObj.yes}
                            onSelect={(target, value) => {
                              onChange(value);
                            }}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            selected={value}
                            name="haveBusinessPartners"
                            label={experienceObj.no}
                            onSelect={(target, value) => {
                              onChange(value);
                              setValue("hasBusinessPartnerConsent", false);
                            }}
                          />
                        </div>
                      </div>
                      {error?.message && displayError(error?.message)}
                    </FormField>
                  </>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <br />
              <Controller
                name="hasBusinessPartnerConsent"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField
                      label={experienceObj.partnerConsent}
                      id="hasBusinessPartnerConsent"
                    >
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            selected={value}
                            name="hasBusinessPartnerConsent"
                            label={experienceObj.yes}
                            onSelect={(target, value) => {
                              onChange(value);
                            }}
                            disabled={!watch().haveBusinessPartners}
                            nullable={!watch().hasBusinessPartnerConsent}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            selected={value}
                            name="hasBusinessPartnerConsent"
                            label={experienceObj.no}
                            onSelect={(target, value) => {
                              onChange(value);
                            }}
                            disabled={!watch().haveBusinessPartners}
                            nullable={!watch().haveBusinessPartners}
                          />
                        </div>
                      </div>
                    </FormField>
                    {error?.message && displayError(error?.message)}
                  </>
                )}
              />
            </div>
          </div>

          {watch().hasBusinessPartnerConsent && (
            <div className="row">
              <div className="col-md-12">
                <Controller
                  name="partnerConsentLetterUrl"
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value, onBlur },
                    fieldState: { error },
                  }) => (
                    <>
                      <FormField
                        label={experienceObj.partnerConsentLetterUrl}
                        type="file"
                        name="partnerConsentLetterUrl"
                        id="partnerConsentLetterUrl"
                        onBlur={onBlur}
                        error={value}
                      >
                        <div>
                          <div>
                            <div
                              className="id-upload-btn col-md-5"
                              onClick={() =>
                                document
                                  .getElementById(
                                    "previewPartnerConsentLetterUrl"
                                  )
                                  .click()
                              }
                            >
                              <span>
                                <FaUpload color="white" size={12} />
                              </span>
                              <span>{t("Choose file")}</span>
                              <span>
                                {isuploadingFile && (
                                  <Loader
                                    type="Oval"
                                    height={13}
                                    width={13}
                                    color="white"
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                          <input
                            type="file"
                            name="previewPartnerConsentLetterUrl"
                            id="previewPartnerConsentLetterUrl"
                            onChange={_onDocChange}
                            className="id-file-upload"
                            // accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          />
                        </div>

                        {watch().partnerConsentLetterUrl && (
                          <div className="">
                            <FileViewerReadOnly
                              files={[watch().partnerConsentLetterUrl]}
                            />
                          </div>
                        )}
                      </FormField>
                      {fileError && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {fileError}
                        </span>
                      )}
                      {error?.message && displayError(error?.message)}
                    </>
                  )}
                />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-md-6">
              <br />
              <Controller
                name="isBusinessRural"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <FormField label={experienceObj.rural} id="isBusinessRural">
                      <div className="row">
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={true}
                            selected={value}
                            name="isBusinessRural"
                            label={experienceObj.yes}
                            onSelect={(target, value) => {
                              onChange(value);
                            }}
                          />
                        </div>
                        <div className="col-sm-6 gutter-v">
                          <SelectBox
                            value={false}
                            selected={value}
                            name="isBusinessRural"
                            label={experienceObj.no}
                            onSelect={(target, value) => {
                              onChange(value);
                            }}
                          />
                        </div>
                      </div>
                      {error?.message && displayError(error?.message)}
                    </FormField>
                  </>
                )}
              />
            </div>
          </div>

          <div className="submit-box">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <button
                  className="submit-btn"
                  // onClick={_handleSubmit}
                  data-testid="experience-next"
                >
                  <span>{experienceObj.continue}</span>
                  {loadingSave && (
                    <Loader type="Oval" color="white" height={15} width={15} />
                  )}
                </button>
              </div>
              <div className="col-md-6 col-sm-12">
                <button
                  className="save-continue-btn"
                  onClick={_saveAndContinue}
                  type="button"
                >
                  {isSavingAndContinue ? (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        outline: "none",
                      }}
                    >
                      <span> {t("saving progress")}</span>
                      <span>
                        <Loader
                          type="Oval"
                          color="red"
                          height={15}
                          width={15}
                        />
                      </span>
                    </span>
                  ) : (
                    <span>
                      <span>
                        <FaSave />{" "}
                      </span>{" "}
                      {t("Save to continue later")}
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <>
        <CLoader
          loading={!isPersonalInfoInit || loadingSave || isSavingAndContinue}
          additonalInfo={updatingMessage}
        />
      </>
      <>
        <ProgressReport
          showReport={showReport}
          onReportClose={_onContinueClick}
          onSubmitClick={_onContinueClick}
          percentageComplete={percentComplete}
        />
      </>
    </div>
  );
};
export default Experience;
