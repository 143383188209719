import React from "react";
import { Col } from "reactstrap";
import Interests from "src/modules/mentorship/components/Interests/Interests";
import InviteForm from "src/modules/homepage/components/InviteForm/InviteForm";
const RightBar = (props) => {
  return (
    <React.Fragment>
      <Col>
        <h6 className="sideright-heading">FOLLOW TOP INTERESTS</h6>
        <Interests />
      </Col>
      <Col>
        <InviteForm />
      </Col>
    </React.Fragment>
  );
};

RightBar.propTypes = {};

export default RightBar;
