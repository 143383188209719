import React, { useState, useContext, useEffect } from "react";
import RightBar from "src/modules/profile/components/RightBar/RightBar";
import Layout from "src/components/Layout/Layout";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  Row,
  Col,
  ButtonDropdown,
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Button,
  // Popover,
  // PopoverBody,
} from "reactstrap";
import { MdMoreVert } from "react-icons/md";

// import TefRedButton from "src/modules/Alumni/Components/Button/TefRedButton";
import { BsTag, BsAlarm } from "react-icons/bs";

import { FiClock } from "react-icons/fi";
import { RiWallet2Line } from "react-icons/ri";

import classes from "./OpportunityDetail.module.css";
import FullDescription from "src/modules/Alumni/Components/FullDescription/FullDescription";
// import SubHeading from "src/modules/Alumni/components/SubHeading/SubHeading";

import SummaryCard from "src/modules/Alumni/Components/SummaryCard/SummaryCard";
import {
  FaHeart,
  FaRegHeart,
  FaTwitter,
  FaLongArrowAltRight,
} from "react-icons/fa";
// import { FaRegHeart, FaHeart } from "react-icons/fa";
// import { FaCommentAlt, FaRegHeart, FaTwitter } from "react-icons/fa";
import InputComment from "src/modules/Alumni/Components/Comments/InputComment";
import ViewComment from "src/modules/Alumni/Components/Comments/ViewComment";
import TEFButton from "src/modules/Alumni/Components/Button/TEFButton";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import DefaultImage from "src/assets/img/bg/background_640-1.webp";
import Logo from "src/modules/Alumni/assets/logo_globe.png";
import Swal from "sweetalert2";
import SocialMediaButton from "src/modules/Alumni/Components/Button/SocialMediaButton";
import {
  getAlumninOpportunity,
  getTotalComment,
  likeOpportunity,
} from "src/requests/AlumniOpportunityRequest";

import {
  postComment,
  deleteComment,
  setReminder,
  cancelReminder,
} from "src/requests/OpportunityRequest";
import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  FacebookShareButton,
} from "react-share";
import { Context } from "src/store";
import moment from "moment";
import { AiFillLinkedin } from "react-icons/ai";

import OpportunityDetailContentLoader from "src/modules/Alumni/Components/SkeletonLoader/opportunityDetailLoader";
import "./OpportunityDetail.scss";
// import { Skeleton } from "react-loading-skeleton";
import CommentLoader from "src/modules/Alumni/Components/SkeletonLoader/CommentLoader";
import RelatedOpportunity from "./RelatedOpportunity";
import { getInterests } from "src/requests/onboarding";
import { FRONTEND_URL } from "src/utils/frontendUrl";

const OpportunityDetails = () => {
  const params = useParams();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [opportunity, setOpportunity] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [loadingComment, setLoadingComment] = useState(false);
  const { dispatch } = useContext(Context);
  const [commentData, setCommentData] = useState("");
  const [postingComment, setPostingComment] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [interestTags, setInterestTags] = useState([]);
  const [isLoadingLike, setIsLoadingLike] = useState(false);
  const [isLoadingLikeOpportunity, setIsLoadingLikeOpportunity] = useState(
    false
  );
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const history = useHistory();

  const styleHeader = (opp) => ({
    backgroundImage: `linear-gradient(
      to right bottom,
      rgba(0,0,0,0.3),
      rgba(0,0,0,0.4)),
    url(${opp.fileName || DefaultImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: "100%",
    // paddingTop: "60px",
  });

  const localDomain = `${FRONTEND_URL}/alumni-opportunity-detail/${params.id}`;

  useEffect(() => {
    _getOpportunity();
    _getTotalComment();
  }, [params.id]);

  const _getOpportunity = () => {
    getAlumninOpportunity(dispatch, params.id).then((res) => {
      if (res.status === 200) {
        const {
          data: { data },
        } = res;
        setIsLoading(false);
        setOpportunity(data);
      }
    });
  };

  useEffect(() => {
    getInterests(dispatch).then((res) => {
      const { status, data } = res;
      if (status === 200 || status === 201) {
        const interests = data.map((interest) => {
          return {
            interestId: interest.id,
            interestName: interest.name,
          };
        });
        setInterestTags(interests);
      } else {
        setInterestTags([]);
      }
    });
  }, []);

  const handleLikeLoader = (val) => {
    setIsLoadingLike(val);
  };

  const getComments = (val) => {
    getTotalComment(dispatch, params.id, 1).then((res) => {
      if (res.status === 200) {
        const {
          data: { data },
        } = res;
        setIsLoadingLike(val);
        setComments(data);
      }
    });
  };
  const handleMoreOption = () => setPopoverOpen(!popoverOpen);
  const deleteUserComment = (id) => {
    deleteComment(dispatch, id).then((res) => {
      _getComments(dispatch, opportunity.id, 1);
    });
  };
  const inputHandler = (e) => {
    const { value } = e.target;

    setCommentData(value);
  };
  const postOpportunityComment = (e) => {
    e.preventDefault();
    const payload = {
      opportunityId: opportunity.id,
      newComment: commentData,
    };

    setPostingComment(true);
    postComment(dispatch, payload).then((res) => {
      setPostingComment(false);
      setCommentData("");
      _getComments(dispatch, opportunity.id, 1);
    });
  };

  const _getComments = (dispatch, id, pageNum) => {
    getTotalComment(dispatch, id, pageNum).then((res) => {
      if (res.status === 200) {
        const {
          data: { data },
        } = res;

        setComments(data);
      }
    });
  };

  const handleLikeOpportunity = () => {
    setIsLoadingLikeOpportunity(true);
    likeOpportunity(dispatch, params.id).then((res) => {
      const { data } = res.data;
      if (data) {
        getAlumninOpportunity(dispatch, params.id).then((res) => {
          if (res.status === 200) {
            const {
              data: { data },
            } = res;
            setIsLoadingLikeOpportunity(false);
            setOpportunity(data);
          }
        });
      }
    });
  };

  const _getTotalComment = () => {
    setLoadingComment(true);
    getTotalComment(dispatch, params.id).then((res) => {
      if (res.status === 200) {
        const {
          data: { data },
        } = res;

        setLoadingComment(false);
        setComments(data);
      }
    });
  };
  const deadLineHumanDate = (deadline) => {
    return moment(deadline).format("ll");
  };
  const postedHumanDate = (createdDate) => {
    return moment(createdDate).fromNow();
  };
  const _setReminder = (reminderType, reminderTypeString) =>
    setReminder(dispatch, params.id, reminderType).then(() =>
      Swal.fire(
        `You'll receive a reminder email about this opportunity a ${reminderTypeString} before the deadline`
      )
    );

  const applicationLinkFunc = () => {
    // window.location = `${opportunity?.opportunityLink ?? "/"}`;
    window.open(`${opportunity?.opportunityLink}`);
  };

  const _cancelReminder = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not receive reminder about this opportunity anymore",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel reminder!",
      cancelButtonText: "Go Back",
    }).then((result) => {
      if (result.value) {
        cancelReminder(dispatch, params.id).then((res) => {
          if (res.status && res.status === 200) {
            Swal.fire(
              "Cancelled!",
              "You have cancelled reminder about this opportunity"
            );
          }
        });
      }
    });
  };

  const getCommentCount = () => (comments ? comments.length : 0);

  return (
    <Layout SideContent={<RightBar />}>
      <div className="create-opp-breadcrumb mb-3">
        <Link to="/alumni-opportunity" style={{ fontWeight: "bold" }}>
          <span className="mr-3">Alumni Opportunities</span>
        </Link>
        <FaLongArrowAltRight color="#919BA8" size={12} />
        <span className="ml-3">{opportunity && opportunity.title}</span>
      </div>
      {isLoading ? (
        <OpportunityDetailContentLoader />
      ) : (
        <>
          <Row>
            <Col>
              <div className="opportunity-header">
                <div
                  className="media media-cd opportunity-header__photo-box"
                  style={styleHeader(opportunity)}
                >
                  {opportunity.isVerified && (
                    <div
                      style={{ backgroundColor: "rgb(218, 41, 28)" }}
                      className="alumni__only alumni-flag"
                    >
                      Admin Verified
                    </div>
                  )}
                </div>
                <div className="opportunity-header__detail-box">
                  <h1 className="opportunity-header__title">
                    {opportunity.title}
                  </h1>
                  <div className="tag-box">
                    <div className="opportunity-tag-box">
                      {opportunity &&
                        opportunity.interestIds &&
                        interestTags &&
                        interestTags
                          .filter((relevantTag) =>
                            opportunity.interestIds.includes(
                              relevantTag.interestId
                            )
                          )
                          .map((tag, idx) => (
                            <p key={idx} className="opportunity-tag">
                              {tag.interestName}
                            </p>
                          ))}
                    </div>
                    <div onClick={handleLikeOpportunity}>
                      {isLoadingLikeOpportunity ? (
                        <Spinner size="sm" />
                      ) : opportunity &&
                        opportunity.opportunityLikeCount > 0 ? (
                        <FaHeart
                          size={19}
                          style={{ color: "#cf1d10", cursor: "pointer" }}
                        />
                      ) : (
                        <FaRegHeart
                          size={19}
                          color="#919BA8"
                          style={{ cursor: "pointer", marginRight: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                  <p className="opportunity-posted-by">
                    Posted:{" "}
                    <span className="opportunity-creator">{`${
                      opportunity.createdByDetails &&
                      opportunity.createdByDetails.firstName
                    }  ${
                      opportunity.createdByDetails &&
                      opportunity.createdByDetails.lastName
                    }`}</span>{" "}
                    | {postedHumanDate(opportunity.createdDate)}
                  </p>
                  <div className="opportunity-action">
                    <div className="apply_section">
                      <Button
                        onClick={applicationLinkFunc}
                        className="opportunity-action--apply"
                      >
                        Apply Now
                      </Button>
                      <ButtonDropdown
                        isOpen={popoverOpen}
                        toggle={handleMoreOption}
                        className="remainder-wrapper"
                      >
                        <DropdownToggle
                          block
                          caret
                          className="remainder-popover"
                        >
                          <span className="remainder-alarm">
                            <BsAlarm color="#D42316" />
                          </span>
                          <p className="remainder-text">
                            <span>Set Reminder</span>
                          </p>
                        </DropdownToggle>
                        <DropdownMenu className="remainder-menu">
                          <DropdownItem>
                            <div
                              className="remainder-freq mb-3 p-3"
                              onClick={() => _setReminder(200, "day")}
                            >
                              A Day Before
                            </div>
                          </DropdownItem>

                          <DropdownItem>
                            <div
                              className="remainder-freq mb-3 p-3"
                              onClick={() => _setReminder(201, "week")}
                            >
                              A Week Before
                            </div>
                          </DropdownItem>
                          <DropdownItem>
                            <div
                              className="remainder-freq mb-3 p-3"
                              onClick={() => _setReminder(202, "month")}
                            >
                              A Month Before
                            </div>
                          </DropdownItem>
                          <DropdownItem>
                            <div
                              className="remainder-freq"
                              onClick={() => _cancelReminder()}
                            >
                              Cancel Reminder
                            </div>
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </div>

                    <ButtonDropdown
                      className="share-opportunity"
                      isOpen={dropdownOpen}
                      toggle={toggle}
                    >
                      <DropdownToggle className="btn-drop mr-0">
                        {" "}
                        <span>
                          <MdMoreVert color="#919BA8" fontSize="30px" />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem divider />
                        <DropdownItem>
                          <LinkedinShareButton
                            url={localDomain}
                            title={opportunity.shortSummary}
                          >
                            <LinkedinIcon
                              size={20}
                              round={true}
                              className="icon-tab"
                            />
                            Share via LinkedIn
                          </LinkedinShareButton>
                        </DropdownItem>
                        <DropdownItem>
                          <FacebookShareButton
                            url={localDomain}
                            quote={opportunity.shortSummary}
                          >
                            <FacebookIcon
                              size={20}
                              round={true}
                              className="icon-tab"
                            />
                            Share via Facebook
                          </FacebookShareButton>
                        </DropdownItem>
                        <DropdownItem>
                          <TwitterShareButton
                            url={localDomain}
                            title={opportunity.shortSummary}
                          >
                            <TwitterIcon
                              size={20}
                              round={true}
                              className="icon-tab"
                            />
                            Share via Twitter
                          </TwitterShareButton>
                        </DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <section
                className={`${classes.Summary} ${classes.sectionContainer}`}
                style={{
                  width: "100%",
                  margin: 0,
                  marginBottom: "20px",
                  display: "flex",
                  padding: "20px",
                }}
              >
                <h3 className="opportunity-summary">Summary</h3>
                <div
                  className={`${classes.SummaryText} opportunity-detail-short-desc`}
                >
                  {opportunity.shortSummary}
                </div>
                <div className={`${classes.SummaryHighlight} summary-highlist`}>
                  <SummaryCard
                    icon={<FiClock size={20} color={"rgb(212, 35, 22)"} />}
                    info={deadLineHumanDate(opportunity.deadline)}
                    details={"Deadline"}
                    otherClassName="detail-font"
                    iconClass="icon-pad"
                    detailPad="detail-pad"
                    infoPad="info-pad"
                  />
                  <SummaryCard
                    icon={
                      <RiWallet2Line size={20} color={"rgb(212, 35, 22)"} />
                    }
                    info={` ${opportunity.totalPrice}`}
                    details="Total Prize"
                    otherClassName="detail-font"
                    iconClass="icon-pad"
                    detailPad="detail-pad"
                    infoPad="info-pad"
                  />
                  <SummaryCard
                    icon={<BsTag size={20} color={"rgb(212, 35, 22)"} />}
                    info={
                      opportunity.opportunityType &&
                      opportunity.opportunityType.name
                    }
                    details="Opportunity Type"
                    otherClassName="detail-font"
                    iconClass="icon-pad"
                    detailPad="detail-pad"
                    infoPad="info-pad"
                  />
                </div>
                <div className={classes.FullDescription}>
                  <FullDescription
                    fullDescription={opportunity.fullDescription}
                    eligibilityRequirement={opportunity.eligibilityRequirement}
                    benefits={opportunity.benefits}
                    // contactEmail={opportunity.contactEmail}
                    // officialWebsite={opportunity.officialWebsite}
                    otherClassName="opportunity-detail-short-desc"
                  />
                </div>
                <div className="opp-detail--card mt-4 opp-shares">
                  <h4 className="event-detail-tag-title">Share</h4>
                  <div id="share-btn" className={classes.ShareButton}>
                    <TwitterShareButton
                      url={window.location.href}
                      title={opportunity && opportunity.title}
                      style={{ outline: "none", flex: "0 0 25%" }}
                    >
                      <SocialMediaButton
                        logo={<FaTwitter color={`white`} />}
                        label="Tweet"
                        bgColor="#1da1f2"
                        textColor="white"
                      />
                    </TwitterShareButton>
                    <LinkedinShareButton
                      url="tefconnect.com"
                      title="test"
                      summary={opportunity.title}
                      style={{ outline: "none", flex: "1" }}
                    >
                      <SocialMediaButton
                        logo={<AiFillLinkedin color={"white"} />}
                        label="Post"
                        bgColor="#0077b5"
                        textColor="white"
                      />
                    </LinkedinShareButton>
                    <span>
                      <TEFButton
                        logo={Logo}
                        rPad="r-pad"
                        label="Share on TEFConnect"
                      />
                    </span>
                  </div>
                </div>
              </section>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <section
                className={`${classes.Comments} ${classes.sectionContainer}`}
                style={{ width: "100%", margin: 0, padding: "20px" }}
              >
                <h3
                  className="opportunity-summary"
                  style={{ fontSize: "15px" }}
                >
                  Comment
                  {`(${getCommentCount()})`}
                </h3>
                <InputComment
                  submitHandler={postOpportunityComment}
                  inputHandler={inputHandler}
                  commentData={commentData}
                  postingComment={postingComment}
                  screenType="full-input"
                />
                <div className="comment-input-area mb-5">
                  <Input type="textarea" className="comment-textarea" />
                  <Button className="opportunity-action--apply">post</Button>
                </div>
                {loadingComment ? (
                  <CommentLoader />
                ) : comments === null || comments.length === 0 ? (
                  <h6>No Comment</h6>
                ) : (
                  <ViewComment
                    opportunityId={params.id}
                    discussions={comments}
                    deleteUserComment={deleteUserComment}
                    getComments={getComments}
                    handleLikeLoader={handleLikeLoader}
                    isLoadingLike={isLoadingLike}
                  />
                )}
              </section>
            </Col>
          </Row>

          <RelatedOpportunity
            history={history}
            relatedOpportunitiesIds={opportunity.interestIds}
          />
        </>
      )}
    </Layout>
  );
};

export default ifOnboardedThenShow(OpportunityDetails);
