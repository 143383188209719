import React from "react";

export const TabContent = ({ children }) => {
  return <>{children}</>;
};

export const Tabs = ({ activeTab, children }) => {
  return (
    <>
      {children.map((child) => {
        if (child.props?.title === activeTab) {
          return child;
        }
        return null;
      })}
    </>
  );
};
