import React from "react";
import "./CategoryModal.scss";
import { Col, ModalBody, Modal, ModalHeader } from "reactstrap";
import ModalImg2 from "src/assets/img/success-tef.png";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { useTranslation } from "react-i18next";

const messages_success = {
  en: {
    p1:
      "Your application has been successfully submitted and will be assessed.",

    p2:
      "The TEF Entrepreneurship Programmes team will contact you via email on your eligibility status and the next steps of the programme on or before the 20th of October 2023.",

    p3: "We wish you all the best as you progress through the Programme",
  },

  sw: {
    p1: "Maombi yako yameshawasilishwa kwa mafanikio na yatahakikiwa.",
    p2:
      "Timu ya Mipango ya Ujasiriamali ya TEF itawasiliana nawe kupitia barua pepe kuhusu hali yako ya kustahiki na hatua zinazofuata za mpango mnamo au kabla ya tarehe 20 Oktoba 2023.",
    p3: "Tunakutakia mafanikio mema unapoendelea na Programu.",
  },

  fr: {
    p1: "Votre candidature a été soumise avec succès et sera évaluée.",
    p2:
      "L'équipe des programmes d'entrepreneuriat du TEF vous contactera par e-mail concernant votre statut d'éligibilité et les prochaines étapes du programme au plus tard le 20 octobre 2023.",
    p3:
      "Nous vous souhaitons tout le meilleur alors que vous progressez dans le programme.",
  },

  ar: {
    p1: "تم تقديم طلبك بنجاح وسيتم تقييمه.",
    p2:
      "سيتصل بك فريق TEF Entrepreneurship Programs عبر البريد الإلكتروني بشأن حالة أهليتك والخطوات التالية للبرنامج في أو قبل 20 أكتوبر 2023.",
    p3: "نتمنى لك كل التوفيق في التقدم خلال البرنامج.",
  },

  pt: {
    p1: "A sua candidatura foi enviada com sucesso e será avaliada.",
    p2:
      "A equipe TEF Entrepreneurship Programs entrará em contato com você por e-mail sobre seu status de elegibilidade e as próximas etapas do programa até 20 de outubro de 2023.",
    p3: "Desejamos-lhe todo o sucesso à medida que avança no programa.",
  },
};

// leave the old message pattern commented until it is confirmed no longer need
// const messages_success = {
//   en: {
//     p1:
//       "You have successfully completed the application assessment and have been found eligible to go on to the bespoke Business Management Training segment of the programme. Our team would contact you any time after the 16th of April 2022 via email for the details about the training and next steps.",

//     p2:
//       "Please be advised that this training is online and does not mean you will receive seed capital as there will be further criteria to qualify for this.",

//     p3: "Good Luck as you progress to the next stage.",
//   },

//   fr: {
//     p1:
//       "YVous avez terminé avec succès l'évaluation de la candidature et avez été déclaré éligible pour suivre le segment de formation en gestion d’affaires sur mesure du programme. Notre équipe vous contactera à tout moment après le 16 avril 2022 par e-mail pour les détails de la formation et les prochaines étapes.",

//     p2:
//       "Veuillez noter que cette formation est en ligne et ne signifie pas que vous recevrez un capital d'amorçage car il y aura d'autres critères pour en bénéficier.",

//     p3: "Bonne chance pour passer à l'étape suivante.",
//   },

//   ar: {
//     p1:
//       "لقد أكملت بنجاح تقييم التطبيق وتم العثور على مؤهل للذهاب إلى قسم التدريب على إدارة الأعمال مفصل من البرنامج. سيتصل بك فريقنا في أي وقت بعد 16 أبريل 2022 عبر البريد الإلكتروني للحصول على تفاصيل حول التدريب والخطوات التالية",

//     p2:
//       "يرجى العلم أن هذا التدريب هو على الانترنت ولا يعني أنك سوف تتلقى رأس المال الأولي كما سيكون هناك معايير أخرى للتأهل لهذا.",

//     p3: "حظا سعيدا كما تقدم لك إلى المرحلة التالية.",
//   },

//   pt: {
//     p1:
//       "Você concluiu com êxito a avaliação da inscrição e foi considerado elegível para prosseguir para o segmento de Treinamento em Gestão de Negócios sob medida do programa. Nossa equipe entrará em contato com você a qualquer momento após o dia 16 de Abril de 2022 via e-mail para os detalhes sobre o treinamento e as próximas etapas.",

//     p2:
//       "Informamos que este treinamento é online e não significa que você receberá capital inicial, pois haverá outros critérios para se qualificar para isso.",

//     p3: "Boa sorte conforme você avança para a próxima fase.",
//   },
// };

// not sure if this will be returned back toString, so i left it it
// const messages_unsuccess = {
//   en: {
//     p1:
//       "Thank you for your interest in the Tony Elumelu Entrepreneurship Programme.",

//     p2:
//       "Based on your responses, you do not qualify to proceed to the bespoke TEF Business Management Training phase for now.",

//     p3:
//       "Please engage yourself with the Preparatory Business Management Training Course already made available to you in the Training section on TEFConnect.net. This training course is not assessed.",
//     p4: "You are welcome to re-apply next year.",
//     p5: "We wish you the best in your entrepreneurship journey.",
//   },

//   fr: {
//     p1:
//       "Merci de l'intérêt que vous portez au programme d'entrepreneuriat Tony Elumelu. Sur la base de vos réponses, vous n'êtes pas qualifié pour le moment pour passer à la phase de formation en gestion d’affaires sur mesure TEF.",

//     p2:
//       "Veuillez-vous engager avec le cours de formation préparatoire à la gestion d’affaires déjà mis à votre disposition dans la section Formation sur TEFConnect.net. Cette formation n'est pas évaluée.",

//     p3: "Vous pouvez postuler à nouveau l'année prochaine.",
//     p4:
//       "Nous vous souhaitons le meilleur dans votre aventure entrepreneuriale.",
//     p5: "",
//   },

//   ar: {
//     p1: "شكرا لك على اهتمامك ببرنامج توني إلوميلو لريادة الأعمال",

//     p2:
//       "بناء على ردودك ، أنت غير مؤهل للشروع في مرحلة التدريب على إدارة الأعمال حسب الطلب في الوقت الحالي. ",

//     p3:
//       "يرجى الانخراط في الدورة التدريبية التحضيرية لإدارة الأعمال التي أتيحت لك بالفعل في قسم التدريب على TEFConnect.net. لم يتم تقييم هذه الدورة التدريبية.",
//     p4: "انكم مدعوون الى إعادة تطبيق العام المقبل.",
//     p5: "نتمنى لكم الأفضل في رحلة ريادة الأعمال الخاصة بك.",
//   },

//   pt: {
//     p1:
//       "Obrigado pelo seu interesse no Programa de Empreendedorismo Tony Elumelu.",

//     p2:
//       "Com base em suas respostas, você não se qualifica para prosseguir para a fase de Treinamento em Gestão de Negócios TEF sob medida por enquanto.",

//     p3:
//       "Por favor, participe do Curso Preparatório de Treinamento em Gestão de Negócios já disponibilizado para você na seção Treinamento em TEFConnect.net. Este curso de treinamento não é avaliado.",
//     p4: "Você está convidado a se inscrever novamente no próximo ano.",
//     p5: "Desejamos a você o melhor em sua jornada de empreendedorismo.",
//   },
// };

const CategoryModal = ({ levelModal, score, onContinueClick, lang = "en" }) => {
  const user = useCurrentUser();
  const { t } = useTranslation();
  // const paragraphs_unsuccess = messages_unsuccess[lang];
  const paragraphs_success = messages_success[lang];
  return (
    <Modal isOpen={levelModal} toggle={onContinueClick}>
      <ModalHeader
        style={{ borderBottom: "none" }}
        toggle={onContinueClick}
      ></ModalHeader>
      <ModalBody style={{ textAlign: "center", padding: "2rem" }}>
        <div className="modalImg">
          <img src={ModalImg2} alt="" />
        </div>
        <span></span>
        <h4 style={{ marginBottom: "1.2rem" }}>{/* Great work!😃 */}</h4>

        <Col style={{ textAlign: "left" }}>
          <span style={{ fontWeight: "bold" }}>
            {t("Dear")} {user && user.firstName},
          </span>
          {/* 
          {score < 40 && (
            <div>
              <p>{paragraphs_unsuccess.p1}</p>
              <p>{paragraphs_unsuccess.p2}</p>
              <p>{paragraphs_unsuccess.p3}</p>
              <p>{paragraphs_unsuccess.p4}</p>
              <p>{paragraphs_unsuccess.p5}</p>
            </div>
          )} */}

          {/* {score >= 40 && ( */}
          <div>
            <p>{paragraphs_success.p1}</p>
            <p>{paragraphs_success.p2}</p>
            <p>{paragraphs_success.p3}</p>
          </div>
          {/* )} */}
          <div className="continue-btn" onClick={onContinueClick}>
            {t("Continue")}
          </div>
        </Col>
      </ModalBody>
    </Modal>
  );
};

export default CategoryModal;
