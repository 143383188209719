import React from "react";
import { Row, Col, NavLink } from "reactstrap";
import PropTypes from "prop-types";
import "./ProfileTabNav.scss";
const PageNav = ({ activeTab, setTab, titles }) => {
  return (
    <Row>
      <div className="profile-nav-card">
        <Row>
          {titles.map((title, index) => (
            <Col md={2} key={index}>
              <NavLink
                active={activeTab === title}
                className="profile-tab-links"
                href="#"
                onClick={() => {
                  title !== "More" && setTab(title);
                }}
              >
                {title}
              </NavLink>
            </Col>
          ))}
        </Row>
      </div>
    </Row>
  );
};

PageNav.propTypes = {
  titles: PropTypes.instanceOf(Array).isRequired,
};

export default PageNav;
