import React from "react";
import { Row, Col } from "reactstrap";
import LineGraph from "react-line-graph";
import "./index.scss";

const DataBoard = ({
  title,
  desc,
  value,
  graph,
  md,
  children,
  className,
  onClick,
  style,
}) => {
  return (
    <Col
      className={`${className || "data-board"}`}
      style={style || {}}
      md={md || 0}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <div className="data-board__curve-box">
        {children || (
          <>
            <h5>{title}</h5>
            <h6>{desc || ""}</h6>
            <Row>
              <Col md={graph ? 5 : 12}>
                <h3>{value}</h3>
              </Col>
              {graph && graph.length > 0 && (
                <Col md={7}>
                  <LineGraph
                    data={graph}
                    smoothing={0.3}
                    accent={"palevioletred"}
                    fillBelow={"rgba(200,67,23,0.1)"}
                    hover={true}
                    height={60}
                  />
                </Col>
              )}
            </Row>
          </>
        )}
      </div>
    </Col>
  );
};

export default DataBoard;
