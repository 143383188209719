import FormFieldModel from "src/modules/Alumni/models/FormField";

const _formFields = () => ({
  title: new FormFieldModel("title", true, "text"),
  summary: new FormFieldModel("summary", true, "text"),
  businessNature: new FormFieldModel("businessNature", true, "array"),
  questions: [],
});

export default _formFields;
