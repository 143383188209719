import React from "react";
import styles from "./SideDrawer.module.scss";
import BackDrop from "src/components/Layout/BackDrop/BackDrop";
import SearchBar from "src/components/SearchBar/SearchBar";
import { useCurrentUser } from "src/utils/Services/currentUser";
import UserSection from "src/components/Layout/SideBar/UserSection/UserSection";
import SidebarItem from "src/modules/training/components/Layout/SidebarItem/SidebarItem";
import { Link } from "react-router-dom";
import { getLanguage } from "src/utils/helper";

const SideDrawer = ({
  show,
  onClose,
  details,
  trainingEventId,
  onQuizClick,
  lessonComplete,
  moduleComplete,
}) => {
  const currentUser = useCurrentUser();
  const language = getLanguage() || "en";

  const display = details.map((data) => {
    const moduleCourse = data.moduleTitles.find(
      (title) => title.lang === language
    );
    return (
      <SidebarItem
        key={data.id}
        menuTitle={(moduleCourse && moduleCourse.title) || ""}
        menuId={data.id}
        subMenus={data.moduleLessons}
        trainingEventId={trainingEventId}
        lang={language}
        courseId={data.courseId}
        quiz={data.moduleQuiz}
        quizClicked={onQuizClick}
        moduleNo={data.moduleNo}
        completed={data.completedOn}
        started={data.startedOn}
        isLessonComplete={lessonComplete}
        isModuleComplete={moduleComplete}
      />
    );
  });

  return (
    <>
      <BackDrop show={show} close={onClose} />
      <div
        className={`notranslate ${styles.SideDrawer} ${
          show ? styles.SideDrawerOpen : styles.SideDrawerHide
        }`}
      >
        <div>
          <div className={styles.SideDrawer__search}>
            <SearchBar />
          </div>
          <UserSection currentUser={currentUser} />
          <ul className={styles.SideDrawer__nav}>{display}</ul>
        </div>
        <Link
          to={`/training/LMS?trainingEventId=${trainingEventId}`}
          className={styles.SideDrawer__back}
        >
          back to dashboard
        </Link>
      </div>
    </>
  );
};

export default SideDrawer;
