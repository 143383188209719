import React, { useState, useEffect } from "react";
import styles from "./ContactCard.module.scss";
import { Link, useParams } from "react-router-dom";
import Avatar from "react-avatar";
import moment from "moment";
import { EditorContentDisplay } from "src/components/Editor";

const ContactCard = ({
  conversationId,
  pic,
  name,
  date,
  message,
  userId,
  clicked,
}) => {
  const { id } = useParams();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(conversationId === id);
    return () => {
      setActive(false);
    };
  }, [id]);

  return (
    <Link
      to={`/messages/thread/${conversationId}`}
      className={`${styles.contactCard} ${active && styles.active}`}
      onClick={(e) => clicked(e, { name, userId })}
    >
      {pic ? (
        <img src={pic} alt="" className={styles.contactCard__dp} />
      ) : (
        <Avatar
          name={name}
          size="35"
          round={true}
          className={styles.contactCard__dp}
        />
      )}
      <div className={styles.contactCard__details}>
        <div className={styles.contactCard__nameInfo}>
          <h3 className={`notranslate ${styles.contactCard__h3}`}>{name}</h3>
          <h3
            style={{ fontWeight: "normal", fontSize: "xx-small" }}
            className={styles.contactCard__h3}
          >
            {moment(date).format("MMM Do")}
          </h3>
        </div>
        {/* <p className={styles.contactCard__message}> */}
        <EditorContentDisplay
          className={styles.contactCard__message}
          content={message}
          readOnly
          subStringLength={20}
        />
        {/* </p> */}
      </div>
    </Link>
  );
};

export default ContactCard;
