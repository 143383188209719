import React from "react";
// import { Link } from "react-router-dom";
import styles from "./RightSideBar.module.scss";

const RightSideBar = ({ hide, children }) => {
  return (
    <div
      className={`${styles.RightSideBar} ${hide && styles.RightSideBarHide}`}
    >
      {children}

      {/* <ul className={styles.nav}>
        <li className={styles.navItem}>
          <Link to="#" className={styles.mainLinkText}>
            &copy; {new Date().getFullYear()}
          </Link>
        </li>
        <li className={styles.navItem}>
          <a
            href="https://www.tonyelumelufoundation.org/"
            target="_blank"
            rel="noreferrer"
            className={styles.mainLinkText}
          >
            About TEF
          </a>
        </li>
        <li className={styles.navItem}>
          <Link to="/terms-of-use" className={styles.mainLinkText}>
            Terms Of Use
          </Link>
        </li>
        <li className={styles.navItem}>
          <Link to="/privacy" className={styles.mainLinkText}>
            Privacy Policy
          </Link>
        </li>
        <li className={styles.navItem}>
          <Link to="/feedback" className={styles.mainLinkText}>
            Send Feedback
          </Link>
        </li>
      </ul> */}
    </div>
  );
};

export default RightSideBar;
