import React, { useContext } from "react";
import { Context } from "src/store";
import CalendarLogo from "src/modules/mentorship/Calendar/utils/calendarLogo";
import classes from "./InvitedEvent.module.css";
import {
  acceptInvitation,
  declineInvitation,
} from "src/requests/MentorRequests";
import Empty from "src/modules/mentorship/Calendar/utils/Empty";
import moment from "moment";

const ReceivedEvents = ({ receivedInvitations, reload }) => {
  const { dispatch } = useContext(Context);
  const _acceptInvitation = (id) => {
    acceptInvitation(dispatch, id);
    reload();
  };

  const _declineInvitation = (id) => {
    declineInvitation(dispatch, id);
    reload();
  };
  const formatDate = (d) => moment(d).format("MMM DD");
  return (
    <>
      {receivedInvitations && receivedInvitations.length <= 0 ? (
        <Empty message="No Invitation to show" />
      ) : (
        receivedInvitations.map((event) => (
          <div className={classes.InvitedEvent} key={event.id}>
            <div className={classes.Left}>
              <div className={classes.EventLogo}>
                <CalendarLogo />
              </div>
              <div className={classes.EventDetails}>
                <div className={classes.EventTitle}>{event.title}</div>
                <div className={classes.EventDate}>{event.datetime}</div>
                <div className={classes.Requestedby}>
                  Received from:{" "}
                  <span className={classes.Requestee}>{event.requestee}</span>{" "}
                  {event.invitedBy && event.invitedBy.name}
                </div>
                <div className={classes.Requestedby}>
                  <span>From: {formatDate(event && event.startDate)}</span>{" "}
                  &nbsp; - &nbsp;
                  <span>To: {formatDate(event && event.endDate)}</span>
                </div>
              </div>
            </div>

            <div className={classes.Right}>
              {event.status === 1 ? (
                <>
                  <div
                    className={classes.AcceptButton}
                    onClick={() => _acceptInvitation(event.id)}
                  >
                    Accept
                  </div>
                  <div
                    className={classes.DeclineButton}
                    onClick={() => _declineInvitation(event.id)}
                  >
                    Decline
                  </div>
                </>
              ) : (
                <>
                  {event.status === 2 ? (
                    <div className={classes.AcceptedButton}>Accepted</div>
                  ) : (
                    <div className={classes.DeclinedButton}> Declined </div>
                  )}
                </>
              )}
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default ReceivedEvents;
