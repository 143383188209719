import React, { useContext, useEffect, useState } from "react";
// import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import QAndAPostCard from "src/modules/training/components/QAndAPostCard";
import { FileViewer } from "src/components/FileViewer/FileViewer";
import {
  postQAQuestion,
  getQAPost,
  // getQAndALikes,
  likeQAndAPost,
  unLikeQAndAPost,
  getQAndALikes,
  deleteQAnAPost,
} from "src/requests/TrainingRequest";
import { Context } from "src/store";
import { useCurrentUser } from "src/utils/Services/currentUser";
// import ShareInput from "src/modules/mentorship/components/ShareInput/ShareInput";
import { uniqBy } from "lodash";
import { isAllowedFiles, UploadFile } from "src/requests/fileUploadRequest";
import { loader, PlaceholderField } from "./components";
import Comment from "./QAndAComment";
import Loader from "react-loader-spinner";
import { MdAttachFile } from "react-icons/md";
// import SearchQAndA from "./SearchQAndA";

const QuestionAndAnswer = () => {
  const { state, dispatch } = useContext(Context);
  const [message, setMessage] = useState("");
  // const [clearContent, setClearContent] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  // const [loadingPosts, setLoadingPosts]= useState(false)
  const [posts, setPosts] = useState([]);
  // const [newPost, setNewPost] = useState(null);
  const [pageDetails, setPageDetails] = useState({
    pageNumber: 1,
    hasNextPage: false,
  });
  const [files, setFiles] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);

  const currentUser = useCurrentUser();
  const { courseId, eventId } = useParams();
  const [activeComment, setActiveComment] = useState(null);
  const [deletedItems, SetDeletedItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [deletingPost, setDeletingPost] = useState(false);
  const [openAskModal, setOpenAskModal] = useState(false);
  const [searchkey, setSearchKey] = useState("");
  // const [openSearchForm, setOpenSearchForm] = useState(false);

  // const toggleSaerch = () => {
  //   setOpenSearchForm(!openSearchForm);
  // };

  const submit = (payload) => {
    setMessage("");
    postQAQuestion(dispatch, eventId, payload, courseId).then((res) => {
      const { status, data } = res;
      if (status === 200 || status === 201) {
        const newPost = data.data.post;
        newPost.user = {
          imageUrl: currentUser.imageUrl,
          userId: currentUser.userId,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
        };

        const exisitngPost = posts;
        const newPosts = [newPost].concat([...exisitngPost]);
        // exisitngPost.unshift(newPost);
        // setNewPost(newPost);
        setPosts(newPosts);
        // _getQAPost(1);
      }
      setLoadingSubmit(false);
      _toggleAskModal();
    });
  };

  const _onSubmit = () => {
    setLoadingSubmit(true);
    // setClearContent(true);

    const payload = {
      post: {
        content: message,
        mentionedUserIds: [],
      },
      files: [
        // {
        //   url: ,
        //   fileType:,
        // },
      ],
    };

    if (files.length > 0) {
      // upload files first
      UploadFile(dispatch, true, files)
        .then((res) => {
          if (res && res.status === 200) {
            payload.files = res.files.map((f) => {
              return {
                url: f,
                fileType: f.split(".").pop(),
              };
            });
            setFiles([]);
            submit(payload);
          }
        })
        .catch((e) => {
          submit(payload);
        });
    } else {
      submit(payload);
    }
  };

  const _toggleAskModal = () => setOpenAskModal(!openAskModal);

  const _handleFileChange = (e) => {
    const existingFiles = [...files];
    const validefiles = isAllowedFiles(Array.from([...e.target.files]), true);
    if (validefiles.hasError) {
      validefiles.errors.forEach((file) => {
        console.log(file.name);
      });
    } else {
      existingFiles.push(...e.target.files);
      const uniqueFile = uniqBy(existingFiles, "name");
      setFiles(uniqueFile);
    }
  };

  const _onRemoveFile = (file) => {
    const existingFiles = [...files];
    const filtered = existingFiles.filter((f) => {
      return f.name !== file;
    });
    setFiles(filtered);
  };

  const _onSearchChange = (value) => {
    // if (!value) return;
    setSearchKey(value);
    _getQAPost(1, value, true);
  };

  // useEffect(() => {
  //   // setLoadingPosts(true)
  //   _getQAPost(pageDetails.pageNumber);
  // }, [newPost]);

  useEffect(() => {
    if (state.Training.coursesQAndA) {
      const { page, data } = state.Training.coursesQAndA;
      // if (data && data.length > 0) {
      setPosts(data);
      // }
      setPageDetails({ ...pageDetails, ...page });
    }
  }, [state.Training.coursesQAndA]);

  const _getQAPost = (pageNumber, searchkey, clearInitialState) => {
    getQAPost(
      dispatch,
      eventId,
      { pageNumber, searchkey, clearInitialState },
      courseId,
      false
    ).then(() => {
      // setSearchKey(searchkey)
      setLoadingMore(false);
    });
  };

  const loadMore = () => {
    setLoadingMore(true);
    _getQAPost(pageDetails.pageNumber + 1, searchkey, false);
  };

  const _likePost = (postId) => {
    likeQAndAPost(postId, eventId);
  };

  const _unlikePost = (postId) => {
    unLikeQAndAPost(postId, eventId);
  };

  const toggleComment = (id) => {
    if (activeComment === id) {
      return setActiveComment(null);
    }
    setActiveComment(id);
  };

  const _triggerDelete = (post) => {
    setSelectedPost(post);
    toggleDeleteModal();
  };

  const _onDeletePost = () => {
    setDeletingPost(true);
    deleteQAnAPost(selectedPost.id, eventId).then(() => {
      const deletedIds = [...deletedItems];
      deletedIds.push(selectedPost.id);
      SetDeletedItems(deletedIds);
      setDeletingPost(false);
      toggleDeleteModal();
    });
  };
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const _onAttachmentClick = () => {
    document.getElementById("files").click();
  };

  return (
    <div className="QANDA-c">
      <PlaceholderField
        onClick={_toggleAskModal}
        // onSearchClick={toggleSaerch}
        onSearchChange={_onSearchChange}
      />

      <div className="QANDA-list">
        {state.Training.loadingGetCourseQA && loader()}

        {posts && posts.length > 0 ? (
          posts.map((post, i) => {
            if (deletedItems.includes(post.id)) return null;
            // getQAndALikes(post.id, eventId);
            return (
              <div
                className="QANDA-c__item"
                key={post.id.split("-").join("").toString().substring(0, 15)}
              >
                <QAndAPostCard
                  type="discussion"
                  createdDate={post?.createdOn}
                  replyCount={post?.commentCount}
                  likeCount={post?.likeCount}
                  message={post?.content}
                  firstName={post?.user?.firstName}
                  lastName={post?.user?.lastName}
                  imageUrl={post?.user?.imageUrl}
                  senderId={post?.user?.userId}
                  files={
                    post.postFiles && post.postFiles.length > 0
                      ? post.postFiles.map((f) => f.url)
                      : []
                  }
                  itemId={post?.id}
                  onLikeClick={() => _likePost(post?.id)}
                  eventId={eventId}
                  onReplyClick={() => toggleComment(post?.id)}
                  onUnLikeClick={() => _unlikePost(post?.id)}
                  getLikes={getQAndALikes}
                  onDeleteClick={() => _triggerDelete(post)}
                  // key={post.id}
                />
                <Comment post={post} selectedPost={activeComment} />
              </div>
            );
          })
        ) : (
          <Col style={{ textAlign: "center", marginTop: "20px" }} md={12}>
            {!state.Training.loadingGetCourseQA && (
              <>
                {" "}
                {/* <img src={Empty} /> */}
                <h3 className="goals-heading">No Questions found</h3>
                <br />
                <br />
              </>
            )}
          </Col>
        )}
        {loadingMore && loader("20px")}
        {pageDetails.hasNextPage && !loadingMore && (
          <Row className="mt-2">
            <Col sm={12} md={12} className="d-flex justify-content-center">
              <Button onClick={loadMore}>Show More</Button>
            </Col>
          </Row>
        )}
      </div>

      <>
        <Modal isOpen={openAskModal} centered size="lg">
          <ModalHeader toggle={_toggleAskModal}>Ask a question</ModalHeader>

          <ModalBody>
            <div className="input-q-and-a-tips">
              <h4>Tips on asking a question</h4>
              <ul>
                <li>
                  &#x2022; Make sure your question has not been asked already
                </li>
                <li>&#x2022; Ask clear and direct questions</li>
                <li> &#x2022; Double check grammer</li>
              </ul>
            </div>
            <form>
              <label>Enter Question</label>
              <input
                className="input-q-and-a"
                type="text"
                placeholder="Begin question with how, why, when, what, etc"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
              <div style={{ width: "100%", backgroundColor: "white" }}>
                <FileViewer
                  files={files}
                  write
                  onFileRemoveClick={_onRemoveFile}
                  // onUploadFinish={_onUploadFinish}
                  onAddImageClick={() =>
                    document.getElementById("files").click()
                  }
                />
              </div>
              <input
                type="file"
                id="files"
                multiple
                style={{
                  opacity: 0,
                  zIndex: -1,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                }}
                onChange={_handleFileChange}
              />
              <span
                className="input-q-and-a-tips-attch"
                onClick={_onAttachmentClick}
              >
                <MdAttachFile />
                Optional: include files(images or documents)
              </span>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              outline
              onClick={_toggleAskModal}
              disable={loadingSubmit}
            >
              Cancel
            </Button>
            <Button
              className="d-flex"
              onClick={_onSubmit}
              disabled={loadingSubmit || message.length === 0}
            >
              Add Question{" "}
              {loadingSubmit && (
                <Loader
                  color="white"
                  type="Oval"
                  height={15}
                  width={15}
                  style={{ marginLeft: "5px" }}
                />
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </>

      <>
        <Modal isOpen={showDeleteModal} centered>
          <ModalHeader toggle={toggleDeleteModal}>Are you sure?</ModalHeader>
          <ModalFooter>
            <Button color="primary" outline onClick={toggleDeleteModal}>
              Cancel
            </Button>
            <Button className="d-flex" onClick={_onDeletePost}>
              Proceed{" "}
              {deletingPost && (
                <Loader
                  color="white"
                  type="Oval"
                  height={15}
                  width={15}
                  style={{ marginLeft: "5px" }}
                />
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </>

      {/* <>
        <SearchQAndA isOpen={openSearchForm} toggle={toggleSaerch} />
      </> */}
    </div>
  );
};

export default QuestionAndAnswer;
