import React from "react";
import { FormGroup, Input } from "reactstrap";
import classes from "./bankdetailsform.module.css";

const FormTextArea = ({
  label,
  status,
  placeholder,
  getFormInputValue,
  value,
  name,
  required,
}) => {
  return (
    <FormGroup>
      <div className={`${classes.formgroupcontainer}`}>
        <p>
          {label} {""}
          <em>
            <small style={{ color: "#BDC3CC" }}>{status}</small>
          </em>
        </p>
        <Input
          type="textarea"
          name={name}
          className={`shadow-none ${classes.bankdetailsinput}`}
          style={{ backgroundColor: "#f0f0f0", border: "none" }}
          placeholder={placeholder}
          value={value}
          onChange={(e) => getFormInputValue(e)}
          rows={4}
          required={required}
        />
      </div>
    </FormGroup>
  );
};

export default FormTextArea;
