import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "reactstrap";
import "./PhotosMini.scss";
import { useParams } from "react-router-dom";
import Loader from "react-loader-spinner";
import { getUserPhotos } from "src/requests/ProfileRequest";
// import { Context } from "src/store";
const PhotosMini = ({ setCurrentTab }) => {
  const { id } = useParams();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  // const { state } = useContext(Context);

  // useEffect(() => {
  //   if (id === user.userId) {
  //     setImages(user.galleries);
  //   }
  // }, [id, user]);

  // useEffect(() => {
  //   if (state.Profile.profile) {
  //     setImages(state.Profile.profile.galleries);
  //   }
  // }, [state.Profile.profile]);

  useEffect(() => {
    setLoading(true);

    getUserPhotos(id).then((res) => {
      const { status, data } = res;
      if (status === 200 || status === 201) {
        setImages(data.data);
      }
      setLoading(false);
    });
  }, []);

  return (
    <Card className="photoMini">
      <Row>
        <Col md={8}>
          <h1 className="photoMini-h1">Photos</h1>
        </Col>
        {images && images.length > 0 && (
          <Col md={4}>
            <span
              onClick={() => setCurrentTab("Photos")}
              className="photoMini-span"
            >
              SEE ALL
            </span>
          </Col>
        )}
      </Row>
      <hr />
      <div className="">
        <Col>
          {!loading && (
            <Row>
              {images && images.length > 0 ? (
                images.slice(0, 4).map((img) => {
                  return (
                    <Col key={img.imageUrl} className="photoMini__pic" md={4}>
                      <img
                        src={img.imageUrl}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        alt="user-photos"
                      />
                    </Col>
                  );
                })
              ) : (
                <div className="empty-image-state" style={{ fontSize: "12px" }}>
                  {" "}
                  Nothing to show
                </div>
              )}
            </Row>
          )}
          {loading && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader type="ThreeDots" height={50} width={50} color="red" />
            </div>
          )}
        </Col>
      </div>
      <br />
    </Card>
  );
};

PhotosMini.propTypes = {};

export default PhotosMini;
