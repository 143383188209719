import React from "react";
import classes from "./ViewComment.module.css";
import CommentDetail from "./CommentDetail";

const ViewComment = ({
  opportunityId,
  getComments,
  discussions,
  deleteUserComment,
  isLoadingLike,
  handleLikeLoader,
}) => {
  return (
    <>
      <div className={classes.ViewComment}>
        {discussions &&
          discussions.map((comment, idx) => (
            <CommentDetail
              key={comment.id}
              opportunityId={opportunityId}
              discussions={discussions}
              deleteUserComment={deleteUserComment}
              getComments={getComments}
              handleLikeLoader={handleLikeLoader}
              isLoadingLike={isLoadingLike}
              comment={comment}
              idx={idx}
            />
          ))}
      </div>
    </>
  );
};

export default ViewComment;
