import React, { useState, useEffect, useContext } from "react";
import { Carousel, CarouselItem } from "reactstrap";
import "./Banner.scss";
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import createLogo from "src/assets/img/opportunity/create-banner.png";
import { getTopOpportunities } from "src/requests/OpportunityRequest";
import { Context } from "src/store";
import { useHistory } from "react-router-dom";
import { _truncateText } from "src/utils/helper";
import { useTranslation } from "react-i18next";

const BannerCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const { state, dispatch } = useContext(Context);

  const items =
    state.Opportunities.topOpportunities &&
    state.Opportunities.topOpportunities;

  useEffect(() => {
    getTopOpportunities(dispatch, 5);
  }, []);

  const history = useHistory();
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const { t } = useTranslation();

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.id}
        className="opp-carousel-container"
      >
        <div className="lastest-opp-containter">
          <div className="lastest-opp-containter__info">
            <div className="opp-banner-latest">
              <div className="opp-banner-latest__btn-latest">{t("Latest")}</div>
              <h5 className="opp-banner-latest__heading">
                {_truncateText(item.title, 52)}
              </h5>
              <p className="opp-banner-latest__desc">
                {_truncateText(item.shortSummary, 130)}
              </p>
              <div
                className="opp-banner-latest__btn-apply"
                onClick={() => history.push(`/opportunity-details/${item.id}`)}
              >
                {t("Apply Now")}
              </div>
            </div>
          </div>

          <div className="lastest-opp-containter__logo">
            <img src={item.fileName} alt="title" />
          </div>
        </div>
      </CarouselItem>
    );
  });

  return (
    <>
      {items && items.length > 0 && (
        <div className="opp-banner-wrapper">
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            className="opp-carousel-container"
          >
            {slides}
            <div className="opp-carousel-custom-cont">
              <div className="opp-carousel-custom-cont__inner-container">
                <div
                  onClick={previous}
                  className="opp-carousel-custom-cont__left-arrow"
                >
                  <IoIosArrowRoundBack />
                </div>
                <div
                  onClick={next}
                  className="opp-carousel-custom-cont__right-arrow"
                >
                  <IoIosArrowRoundForward />
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      )}
    </>
  );
};

const BannerCreateOpportunity = () => {
  return (
    <>
      <div className="create-opportuniy-banner">
        <div className="create-opportuniy-banner__info">
          <h1>Submit Opportunity 👋 </h1>
          <p>
            TEFConnect is committed to sharing as many relevant opportunities
            for African entrepreneurs as much as possible.
          </p>
          <p>
            We invite you to share with us! We would have a look and get it
            published if we think its cool – Its FREE! Kindly fill the form
            below {">>"}
          </p>
        </div>
        <div className="create-opportuniy-banner__logo">
          <img src={createLogo} alt="logo" />
        </div>
      </div>
    </>
  );
};

export { BannerCarousel, BannerCreateOpportunity };
