import React, { useEffect, useContext } from "react";
import { getRelatedOpportunities } from "src/requests/OpportunityRequest";
import { Context } from "src/store";
import OpportunityList from "src/modules/Opportunity/components/List/List";

const RelatedOpportunity = ({ relatedOpportunitiesIds }) => {
  const { state, dispatch } = useContext(Context);
  useEffect(() => {
    if (relatedOpportunitiesIds.length > 0) {
      getRelatedOpportunities(dispatch, relatedOpportunitiesIds);
    }
  }, []);
  const { relatedOpportunities } = state.Opportunities;

  return (
    <>
      <OpportunityList list={relatedOpportunities} />
    </>
  );
};

export default RelatedOpportunity;
