import React from "react";
import Avatar from "src/components/Avatar/Avatar";
import "./index.scss";

const Comment = ({ name, avatar, date, content }) => (
  <div className="comment-item mt-1">
    <div className="d-flex">
      <div className="comment-item__img-container">
        <Avatar src={avatar} dimension={30} />
      </div>
      <div className="comment-content">
        <h4>{name || "Mary Jane"}</h4>
        <h6>{date || "Jan 14, 2020 at 5:03pm"}</h6>
        <p>{content}</p>
      </div>
    </div>
  </div>
);

export default Comment;
