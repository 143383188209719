import React, { useContext, useEffect, useState } from "react";
import { TabContent, Tabs } from "src/components/CustomTab/CustomTab";
import { Row, Col, Button, Card } from "reactstrap";
import { useParams, useLocation } from "react-router-dom";
import ProfileCard from "src/modules/profile/components/ProfileCard/ProfileCard";
import Bio from "src/modules/profile/components/Bio/Bio";
import About from "src/modules/profile/components/About/About";
// import { getInterests } from "src/requests/onboarding";
import Skills from "src/modules/profile/components/Skills/Skills";
import Goals from "src/modules/profile/components/Goals/Goals";
import { FaRegHeart, FaGlobeAfrica, FaPlusCircle } from "react-icons/fa";
// import CreatePost from "src/modules/homepage/components/MainContent/MainContent";
import ProfileStatus from "src/modules/profile/components/ProfileStatus/ProfileStatus";
import AboutMini from "src/modules/profile/components/AboutMini/AboutMini";
import PhotosMini from "src/modules/profile/components/PhotosMini/PhotosMini";
import Photos from "src/modules/profile/components/Photos/Photos";
// import GoalsMini from "src/modules/profile/components/GoalsMini/GoalsMini";
import { Context } from "src/store";
import {
  getUserProfile,
  getUserMentorshipArea,
  getUserAchievemets,
  getUserSkills,
} from "src/requests/ProfileRequest";
import BusinessDetails from "src/modules/profile/components/BusinessDetails/BusinessDetails";
import Achievements from "src/modules/profile/components/Achievements/Achievements";
import MentorArea from "src/modules/profile/components/MentorArea/MentorArea";
import CreateGoalModal from "src/components/CreateGoalModal/CreateGoalModal";

import CModal from "src/modules/onboarding/components/CModal";
import {
  BioEdit,
  AboutEdit,
  SkillEdit,
  BusinessEdit,
  AchievementEdit,
  MentorshipEdit,
} from "src/modules/profile/ProfileEdit";
import { getCountryFromCode } from "src/utils/helper";
import { setCurrentUser, useCurrentUser } from "src/utils/Services/currentUser";
// import ConnectionCard from "src/modules/profile/components/Connections/FindUsers";
import { useTranslation } from "react-i18next";
import AllConnections from "src/modules/Connections/AllConnections/AllConnections";
import MainContent_v2 from "src/modules/homepage/components/MainContent/MainContent_v2";
import queryString from "query-string";

const MainContent = ({ currentTab, setCurrentTab }) => {
  const { id } = useParams();
  const { state, dispatch } = useContext(Context);
  const [modal, setModal] = useState(false);
  const [user, setUser] = useState(null);
  const currentUser = useCurrentUser();
  const [loadingDecoupledProfile, setLoadingDecoupledProfile] = useState(false);

  const [isBioEdit, setIsBioEdit] = useState(false);
  const [isAboutEdit, setIsAboutEdit] = useState(false);
  const [isSKillEdit, setIsSkillEdit] = useState(false);
  const [isBusinessEdit, setIsBusinessEdit] = useState(false);
  const [isAcheivementEdit, setIsAcheivementEdit] = useState(false);
  const [isMentorshipEdit, setIsMentorshipEdit] = useState(false);

  const [mentorshipAreas, setMentorshipAreas] = useState([]);
  const [skills, setSkills] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const location = useLocation();

  const { target_prop } = queryString.parse(location.search);

  useEffect(() => {
    // When the user comes from profile status, this indicates which field they want to edit.
    if (target_prop && id === currentUser.userId) {
      setIsAboutEdit(true);
    }
  }, [target_prop]);

  useEffect(() => {
    setUser(state.Profile.profile);
  }, [state.Profile.profile]);

  const { t } = useTranslation();
  const toggle = () => setModal(!modal);

  const decoupled_profile_parts_get_methods = {
    userMentorshipAreas: (userId) => getUserMentorshipArea(userId),
    userSkills: (userId) => getUserSkills(userId),
    achievements: (userId) => getUserAchievemets(userId),
  };
  // useEffect(() => {
  //   getInterests(dispatch);
  // }, []);

  useEffect(() => {
    if (id !== currentUser.userId) {
      setUser(null);
      getUserProfile(dispatch, id);
    } else {
      setUser(currentUser);
    }
  }, [id, currentUser]);

  useEffect(() => {
    if (id) {
      _getDecoupledProfileParts(id);
    }
  }, [id]);

  const _getDecoupledProfileParts = async (userId) => {
    setLoadingDecoupledProfile(true);
    const keys = Object.keys(decoupled_profile_parts_get_methods);
    const all = keys.map(async (key) => {
      return await decoupled_profile_parts_get_methods[key](userId);
    });
    const result = {
      userMentorshipAreas: [],
      userSkills: [],
      achievements: [],
    };
    try {
      const [mentorshipArea, skills, achvmnts] = await Promise.all(all);

      if (mentorshipArea && mentorshipArea.data && mentorshipArea.data.data) {
        result.userMentorshipAreas = mentorshipArea.data.data;
      }

      if (skills && skills.data && skills.data) {
        result.userSkills = skills.data.data;
      }
      if (achvmnts && achvmnts.data && achvmnts.data) {
        result.achievements = achvmnts.data.data;
      }
    } catch (e) {
      console.log(e);
    }

    if (id === currentUser.userId) {
      setCurrentUser({ ...currentUser, ...result });
    }
    setMentorshipAreas(result.userMentorshipAreas);
    setSkills(result.userSkills);
    setAchievements(result.achievements);

    setLoadingDecoupledProfile(false);
  };

  const closeBioEdit = () => {
    setIsBioEdit(false);
  };
  const openBioEdit = () => {
    setIsBioEdit(!isBioEdit);
  };

  const closeAboutEdit = () => {
    setIsAboutEdit(false);
  };
  const openAboutEdit = () => {
    setIsAboutEdit(!isAboutEdit);
  };

  const openSkillEdit = () => {
    setIsSkillEdit(!isSKillEdit);
  };

  const closeSkillEdit = () => {
    setIsSkillEdit(false);
  };

  const openBusinessEdit = () => {
    setIsBusinessEdit(!isBusinessEdit);
  };

  const closeBusinessEdit = () => {
    setIsBusinessEdit(false);
  };

  const openAchievementEdit = () => {
    setIsAcheivementEdit(!isAcheivementEdit);
  };

  const closeAchievementEdit = () => {
    setIsAcheivementEdit(false);
  };

  const openMentorshipEdit = () => {
    setIsMentorshipEdit(!isMentorshipEdit);
  };

  const closeMentorshipEdit = () => {
    setIsMentorshipEdit(false);
  };

  const _getUserGroup = (groups) => {
    if (groups && Array.isArray(groups)) {
      const userGroupNames = groups.map((g) => {
        return g.group.name.toLowerCase();
      });
      if (
        userGroupNames.includes("tef-mentee") ||
        userGroupNames.includes("entrepreneur")
      ) {
        return "entrepreneur";
      } else if (
        userGroupNames.includes("tef-mentor") ||
        userGroupNames.includes("mentor")
      ) {
        return "mentor";
      }
    }
  };

  const renderTabContent = () => {
    return (
      <Tabs activeTab={currentTab}>
        <TabContent title="About">
          <Row>
            <Col md={12}>
              <ProfileCard
                title="Short Bio"
                onEditClick={openBioEdit}
                loading={!user}
              >
                <Bio description={user && user.bio} />
                <CModal isOpen={isBioEdit} closeModal={closeBioEdit}>
                  {/* bio edit */}
                  <BioEdit bio={user && user.bio} cancel={closeBioEdit} />
                </CModal>
              </ProfileCard>
              <ProfileCard
                title={t("About")}
                onEditClick={openAboutEdit}
                loading={!user}
              >
                <About
                  firstName={user && user.firstName}
                  lastName={user && user.lastName}
                  profile={user && user}
                />
                {/* about edit */}
                <CModal isOpen={isAboutEdit} closeModal={closeAboutEdit}>
                  <AboutEdit profile={user && user} cancel={closeAboutEdit} />
                </CModal>
              </ProfileCard>
              <ProfileCard
                title={t("Skills")}
                onEditClick={openSkillEdit}
                loading={!user || loadingDecoupledProfile}
              >
                <Skills skills={skills} />
                {/* skills edit */}
              </ProfileCard>
              <React.Fragment>
                <CModal isOpen={isSKillEdit} closeModal={closeSkillEdit}>
                  <SkillEdit
                    skills={skills}
                    cancel={closeSkillEdit}
                    callbacks={[_getDecoupledProfileParts]}
                  />
                </CModal>
              </React.Fragment>
              {/* <ProfileCard
                title={t("Educational Background")}
                onEditClick={openEducationEdit}
                loading={!user}
              >
                <EduBackground education={user && user.educations} />
                <CModal
                  isOpen={isEducationEdit}
                  closeModal={closeEducationEdit}
                >
                  <EduEdit
                    educations={user && user.educations}
                    onCancel={closeEducationEdit}
                  />
                </CModal>
              </ProfileCard> */}
              <ProfileCard
                title={t("Business Details")}
                onEditClick={openBusinessEdit}
                loading={!user}
              >
                <BusinessDetails
                  businessName={user && user.businessName}
                  businessCategory={user && user.businessIndustry}
                  businessWebsite={user && user.businessWebsite}
                  businessRevenue={
                    user &&
                    user.businessRevenue &&
                    user.businessRevenue.toLocaleString()
                  }
                  businessAddress={user && user.businessAddress}
                  aboutBusiness={user && user.aboutBusiness}
                  city={user && user.businessCity}
                  state={user && user.businessState}
                  country={
                    user &&
                    user.businessCountry &&
                    getCountryFromCode(user.businessCountry)
                  }
                />
                <CModal isOpen={isBusinessEdit} closeModal={closeBusinessEdit}>
                  <BusinessEdit
                    profile={user && user}
                    onCancel={closeBusinessEdit}
                  />
                </CModal>
              </ProfileCard>
              <ProfileCard
                title="Achievements or Past Projects"
                onEditClick={openAchievementEdit}
                loading={!user || loadingDecoupledProfile}
              >
                <Achievements projects={achievements} />
              </ProfileCard>

              <React.Fragment>
                <CModal
                  isOpen={isAcheivementEdit}
                  closeModal={closeAchievementEdit}
                >
                  <AchievementEdit
                    achievements={achievements}
                    onCancel={closeAchievementEdit}
                    callbacks={[_getDecoupledProfileParts]}
                  />
                </CModal>
              </React.Fragment>

              <ProfileCard
                title={
                  _getUserGroup(user && user.userGroups) === "entrepreneur"
                    ? "Areas I need mentoring on"
                    : _getUserGroup(user && user.userGroups) === "mentor"
                    ? "Areas I can mentor in"
                    : "Mentorship areas of interest"
                }
                onEditClick={openMentorshipEdit}
                loading={!user || loadingDecoupledProfile}
              >
                <MentorArea areas={mentorshipAreas} />
              </ProfileCard>
              <React.Fragment>
                <CModal
                  isOpen={isMentorshipEdit}
                  closeModal={closeMentorshipEdit}
                >
                  <MentorshipEdit
                    mentorshipArea={mentorshipAreas}
                    cancel={closeMentorshipEdit}
                    callbacks={[_getDecoupledProfileParts]}
                  />
                </CModal>
              </React.Fragment>
              <br />
            </Col>
          </Row>
        </TabContent>
        <TabContent title="Connections">
          {/* <Row> */}
          {/* <Col md={12}> */}
          <ProfileCard title="Connections" showEdit={false}>
            {/* <h4>Coming Soon</h4> */}
            <AllConnections userId={id} />
          </ProfileCard>
          {/* </Col> */}
          {/* </Row> */}
        </TabContent>

        <TabContent title="Timeline">
          <br />
          <Row>
            <Col md={4}>
              {currentUser.userId === id && (
                <ProfileStatus
                  percentageCompletion={user && user.percentageCompletion}
                />
              )}

              <AboutMini
                bio={user && user.bio}
                joinedDate={user && user.createdOn}
                businessName={user && user.businessName}
                firstName={user && user.firstName}
                lastName={user && user.lastName}
                email={user && user.email}
                hiddenFields={user && user.hiddenFields}
              />
              <PhotosMini setCurrentTab={setCurrentTab} />
            </Col>
            <Col md={8}>
              {/* <CreatePost userId={id} timeline={true} /> */}
              <MainContent_v2 userId={id} timeline />
            </Col>
          </Row>
        </TabContent>

        <TabContent title="Photos">
          <br />
          <Photos />
        </TabContent>

        <TabContent title="Personal Goals">
          <br />
          <div style={{ width: "100%" }}>
            {/* <Col md={12}> */}
            <Card title="Goal Board">
              <div className="main-card-container">
                <h3 className="my-board-heading">My Board</h3>

                <Row>
                  <Col md={7} className="top-row-icon">
                    <div style={{ marginLeft: "20px" }}>
                      <span>
                        <FaRegHeart /> | <FaGlobeAfrica />
                      </span>
                      <span className="top-row-text">Public</span>
                    </div>
                  </Col>

                  <Col md={5}>
                    <Button
                      color="secondary"
                      outline
                      className="header-button-white goal-button float-right"
                      onClick={() => toggle()}
                    >
                      New Goal <FaPlusCircle />
                    </Button>
                  </Col>

                  <CreateGoalModal modal={modal} toggle={toggle} />
                </Row>
                <Goals />
              </div>
            </Card>
            {/* </Col> */}
            {/* <Col md={3}>
              <GoalsMini />
            </Col> */}
            {/* </Col> */}
          </div>
        </TabContent>
      </Tabs>
    );
  };
  return <div className="">{renderTabContent()}</div>;
};

MainContent.propTypes = {};

export default MainContent;
