import axios from "src/utils/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CREATE_PROGRAMME_EVENT,
  GET_ALL_PROGRAMME_EVENTS,
  DELETE_PROGRAMME_EVENT,
  START_PROGRAMME_EVENT,
  STOP_PROGRAMME_EVENT,
  GET_PROGRAMME_EVENT_TYPES,
  EDIT_PROGRAMME_EVENT,
} from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import { ProgrammeUrl } from "src/constants";

export const createProgrammeEvent = (dispatch, payload) => {
  dispatch(asyncActions(CREATE_PROGRAMME_EVENT).loading(true));
  return axios({
    method: "post",
    url: ProgrammeUrl.CREATE_PROGRAMME_EVENT,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(CREATE_PROGRAMME_EVENT).loading(false));
      if (response.status === 201) {
        dispatch(
          asyncActions(CREATE_PROGRAMME_EVENT).success(response.data.data)
        );
        dispatch(asyncActions(CREATE_PROGRAMME_EVENT).loading(false));
        toast("Programme Created Successfully ✅");
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(CREATE_PROGRAMME_EVENT).loading(false));
      dispatch(asyncActions(CREATE_PROGRAMME_EVENT).failure(true, error));
      toast("An error occurred, Please try again ");
      return error;
    });
};

export const getAllProgrammeEvents = (dispatch) => {
  dispatch(asyncActions(GET_ALL_PROGRAMME_EVENTS).loading(true));
  return axios({
    method: "get",
    url: ProgrammeUrl.GET_ALL_PROGRAMME_EVENTS,
  })
    .then((response) => {
      dispatch(asyncActions(GET_ALL_PROGRAMME_EVENTS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_ALL_PROGRAMME_EVENTS).success(data));
        dispatch(asyncActions(GET_ALL_PROGRAMME_EVENTS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_ALL_PROGRAMME_EVENTS).loading(false));
      dispatch(
        asyncActions(GET_ALL_PROGRAMME_EVENTS).failure(true, error.response)
      );
      return error.response;
    });
};

export const deleteProgrammeEvent = (dispatch, id) => {
  dispatch(asyncActions(DELETE_PROGRAMME_EVENT).loading(true));
  return axios({
    method: "delete",
    url: `${ProgrammeUrl.DELETE_PROGRAMME_EVENT}/?id=${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(DELETE_PROGRAMME_EVENT).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(DELETE_PROGRAMME_EVENT).success(id));
        dispatch(asyncActions(DELETE_PROGRAMME_EVENT).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(DELETE_PROGRAMME_EVENT).loading(false));
      dispatch(
        asyncActions(DELETE_PROGRAMME_EVENT).failure(true, error.response)
      );
      return error.response;
    });
};

export const getProgrammeEventTypes = (dispatch) => {
  dispatch(asyncActions(GET_PROGRAMME_EVENT_TYPES).loading(true));
  return axios({
    method: "get",
    url: `${ProgrammeUrl.GET_PROGRAMME_EVENT_TYPES}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_PROGRAMME_EVENT_TYPES).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_PROGRAMME_EVENT_TYPES).success(data));
        dispatch(asyncActions(GET_PROGRAMME_EVENT_TYPES).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_PROGRAMME_EVENT_TYPES).loading(false));
      dispatch(
        asyncActions(GET_PROGRAMME_EVENT_TYPES).failure(true, error.response)
      );
      return error.response;
    });
};
export const editProgrammeEvent = (dispatch, payload, id) => {
  dispatch(asyncActions(EDIT_PROGRAMME_EVENT).loading(true));
  return axios({
    method: "patch",
    url: `${ProgrammeUrl.EDIT_PROGRAMME_EVENT}/${id}`,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(EDIT_PROGRAMME_EVENT).loading(false));
      if (response.status === 200) {
        dispatch(
          asyncActions(EDIT_PROGRAMME_EVENT).success(response.data.data)
        );
        dispatch(asyncActions(EDIT_PROGRAMME_EVENT).loading(false));
        toast("Programme Updated Successfully ✅");
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(EDIT_PROGRAMME_EVENT).loading(false));
      dispatch(asyncActions(EDIT_PROGRAMME_EVENT).failure(true, error));
      toast("An error occurred, Please try again ");
      return error;
    });
};

export const startProgrammeEvent = (dispatch, id) => {
  dispatch(asyncActions(START_PROGRAMME_EVENT).loading(true));
  return axios({
    method: "patch",
    url: `${ProgrammeUrl.START_PROGRAMME_EVENT}/${id}/start`,
  })
    .then((response) => {
      dispatch(asyncActions(START_PROGRAMME_EVENT).loading(false));
      if (response.status === 200 || response.status === 201) {
        dispatch(
          asyncActions(START_PROGRAMME_EVENT).success(response.data.data)
        );
        dispatch(asyncActions(START_PROGRAMME_EVENT).loading(false));
        toast("Programme started Successfully ✅");
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(START_PROGRAMME_EVENT).loading(false));
      dispatch(asyncActions(START_PROGRAMME_EVENT).failure(true, error));
      toast("An error occurred, Please try again ");
      return error;
    });
};

export const stopProgrammeEvent = (dispatch, id) => {
  dispatch(asyncActions(STOP_PROGRAMME_EVENT).loading(true));
  return axios({
    method: "patch",
    url: `${ProgrammeUrl.STOP_PROGRAMME_EVENT}/${id}/stop`,
  })
    .then((response) => {
      dispatch(asyncActions(STOP_PROGRAMME_EVENT).loading(false));
      if (response.status === 200 || response.status === 201) {
        dispatch(
          asyncActions(STOP_PROGRAMME_EVENT).success(response.data.data)
        );
        dispatch(asyncActions(STOP_PROGRAMME_EVENT).loading(false));
        toast("Programme stopped Successfully ✅");
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(STOP_PROGRAMME_EVENT).loading(false));
      dispatch(asyncActions(STOP_PROGRAMME_EVENT).failure(true, error));
      toast("An error occurred, Please try again ");
      return error;
    });
};
