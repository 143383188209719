import React from "react";
import { Picker } from "emoji-mart";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { HiUserAdd, HiDotsVertical } from "react-icons/hi";
import { FiPaperclip, FiSmile, FiNavigation } from "react-icons/fi";
import Chatubble from "src/modules/Alumni/Chat/Components/ChatBubble/index";
import { FaCaretLeft } from "react-icons/fa";
import "./chatHistory.scss";

const ChatHistory = ({
  currentChatHistory,
  dropdownOpen,
  showEmojis,
  handleSubmit,
  toggleMembersModal,
  removeChat,
  currentChat,
  toggleAddUserToGroupModalOpen,
  toggleGroupMemberMobile,
  emojiRef,
  addEmoji,
  handleChange,
  text,
  setShowEmojis,
  toggleAttachmantModal,
  toggleChatBox,
  isGroupAdmin,
  toggleMessageViewer,
  leaveGroupChat,
  restrictPosting,
  showChatTextarea,
}) => {
  const styles = {
    container: {
      padding: 20,
      borderTop: "1px #4C758F solid",
      marginBottom: 20,
    },
    form: {
      display: "flex",
    },
    input: {
      color: "inherit",
      background: "none",
      outline: "none",
      border: "none",
      flex: 1,
      fontSize: 16,
    },
    getEmojiButton: {
      cssFloat: "right",
      border: "none",
      margin: 0,
      marginRight: 20,
      fontSize: 24,
      cursor: "pointer",
    },
    emojiPicker: {
      position: "absolute",
      bottom: 0,
      left: 0,
      cssFloat: "left",
      marginLeft: "0px",
    },
  };
  return (
    <div className="chat-widget__body">
      <div className="current-chat__info">
        <div className="mobile-align-items-center">
          <span onClick={() => toggleChatBox()} className="mr-4">
            <FaCaretLeft size={18} />
          </span>
          <div className="current-chat__image">
            {currentChat &&
              (currentChat?.imageUrl || currentChat?.bannerUrl) && (
                <img
                  src={currentChat?.imageUrl || currentChat?.bannerUrl}
                  alt=""
                  className="current-chat-logo"
                />
              )}
          </div>
          <h5 className="current-chat__name">
            {currentChat && currentChat?.name}
          </h5>
        </div>
        <div className="d-flex align-items-center">
          <div
            style={{ fontSize: 24, cursor: "pointer" }}
            onClick={toggleAddUserToGroupModalOpen}
          >
            {currentChat?.isGroup && (
              <HiUserAdd className="mr-2" color={"#3a3838"} />
            )}
          </div>
          {currentChat && currentChat.isGroupChat && (
            <div>
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggleGroupMemberMobile}
                style={{ overflow: "initial" }}
                // className="float-none"
              >
                <DropdownToggle color="white">
                  <HiDotsVertical />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={toggleAddUserToGroupModalOpen}>
                    <div onClick={() => toggleAddUserToGroupModalOpen()}>
                      Add Group Member
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div onClick={() => toggleMembersModal()}>View Members</div>
                  </DropdownItem>
                  <DropdownItem>
                    <div onClick={leaveGroupChat}>Leave Group</div>
                  </DropdownItem>

                  {currentChat?.isGroupChat && isGroupAdmin() && (
                    <DropdownItem>
                      <div onClick={restrictPosting}>
                        {currentChat.isRestricted
                          ? "unRestrict Posting"
                          : "unRestrict Posting"}
                      </div>
                    </DropdownItem>
                  )}
                  {currentChat?.isGroupChat && isGroupAdmin() && (
                    <DropdownItem>
                      <div onClick={toggleMessageViewer}>Messages Viewers</div>
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </div>
          )}
        </div>
      </div>
      <div className="current-chat__messages">
        <Chatubble chatHistory={currentChatHistory} removeChat={removeChat} />
      </div>
      <div className="current-chat__action">
        <div className="current-chat__action-left">
          {showEmojis ? (
            <span style={styles.emojiPicker} ref={emojiRef}>
              <Picker
                onSelect={addEmoji}
                emojiTooltip={true}
                showPreview={false}
                title=""
              />
            </span>
          ) : (
            <>
              <span
                style={styles.getEmojiButton}
                onClick={() => setShowEmojis(true)}
              >
                <FiSmile />
              </span>
              <span
                style={{ fontSize: 24, cursor: "pointer" }}
                onClick={toggleAttachmantModal}
              >
                <FiPaperclip />
              </span>
            </>
          )}
        </div>
        <div className="current-chat__action-input__box">
          <form onSubmit={handleSubmit}>
            <textarea
              className="form-control"
              style={{ resize: "none", border: "none" }}
              value={text}
              onChange={handleChange}
              placeholder="Type Message..."
            ></textarea>
          </form>
          {/* // <EditorBox useEmoji={true} onEditorChange={handleChange} /> */}
        </div>
        <div className="current-chat__action-right" onClick={handleSubmit}>
          <FiNavigation backgroundColor="red" />
        </div>
      </div>
    </div>
  );
};

export default ChatHistory;
