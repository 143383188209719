import React, { useContext, useEffect, useState } from "react";
import Layout from "src/components/Layout/Layout";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import HubBanner from "src/modules/Alumni/AlumniHub/component/HubBanner/index";
import { Row, Col, Container } from "reactstrap";
import classes from "./Hubs.module.scss";
import Loader from "react-loader-spinner";
import HubCard from "src/modules/Alumni/AlumniHub/component/hubs/index";
import { getOtherHubs, getUserHub } from "src/requests/AlumniHubRequest";
import { Context } from "src/store";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const HubsListing = () => {
  const { dispatch } = useContext(Context);
  const [hubs, setHubs] = useState([]);
  const userData = get_user_info_from_token().user;
  const [hubDetails, setHubDetails] = useState({});
  const [isLoadingHubs, setIsLoadingHubs] = useState(true);

  const _getOtherHubs = () => {
    getOtherHubs(dispatch)
      .then((response) => {
        const otherHubs = response.data.data.data.filter(
          (hub) => hub.name !== "General"
        );
        setHubs(otherHubs);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoadingHubs(false));
  };

  const _getUserHub = () => {
    getUserHub(dispatch, { userId: userData.id }).then((response) => {
      const hubDetailsArray = response.data.data.data;
      const _currentHub = hubDetailsArray.find((hub) => hub.isCurrent === true);
      setHubDetails(_currentHub);
    });
  };

  useEffect(() => {
    _getOtherHubs();
    _getUserHub();
  }, []);

  return (
    <Layout SideContent={<SidebarRight />}>
      <Row>
        <Col>
          <HubBanner
            hubBanner={{
              bannerUrl: hubDetails && hubDetails.bannerUrl,
              state: hubDetails && hubDetails.state,
            }}
          />
        </Col>
      </Row>
      <Container>
        <Row>
          <Col>
            <div className={classes["hubs-wrapper"]}>
              {isLoadingHubs ? (
                <div className="w-100 d-flex justify-content-center py-5">
                  <Loader type="Oval" color="red" height={40} width={40} />
                </div>
              ) : (
                <>
                  <div className={classes["hubs-title"]}>
                    <h6 className="fs-16 fw-600 mb-3 border-bottom pb-2">
                      TEF Alumni Hubs
                    </h6>
                  </div>
                  <div className={classes["hubs-grid"]}>
                    {hubs.length > 0
                      ? hubs.map((hub) => (
                          <HubCard key={hub.id} hubDetails={hub} />
                        ))
                      : null}
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default HubsListing;
