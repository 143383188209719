import React, { useContext, useEffect, useState, useRef } from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import classes from "./ApplicationUpdate.module.scss";
import { getUserApplications } from "src/requests/ApplicationRequest";
import PreviewApplication from "src/modules/Application/components/PreviewApplication/PreviewApplication";
import { Context } from "src/store";
import CLoader from "src/modules/Application/components/CLoader";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { GiBinoculars } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader, Col } from "reactstrap";
// import Header from "src/components/Layout/Header/Header";
import ModalImg2 from "src/assets/img/success-tef.png";
import { successMessage, others } from "./translation";

const AppPreviewUpdate = (props) => {
  const location = useLocation();
  const query = location.search;
  const [loadingApp, setLoadingApp] = useState(false);
  const { applicationId, applicants, lang, validate } = queryString.parse(
    query
  );
  const { userId } = useCurrentUser();
  const { t } = useTranslation();
  const [toggleModal, setToggleModal] = useState(false);
  const history = useHistory();

  const openEditRef = useRef();

  const tEdit = () => {
    openEditRef.current.toggleOpenEditFromParent();
  };
  const toggleModalPrompt = () => setToggleModal(!toggleModal);

  // const [showDrawer, setShow] = useState(false);
  // const toggle = () => setShow(!show);

  const { state, dispatch } = useContext(Context);
  const togglePreview = (e) => {
    if (applicants && applicants === "2020") return toggleModalPrompt();
    // e.preventDefault();
    return history.goBack();
  };

  const some_translation = others[lang || "en"];

  useEffect(() => {
    if (query) {
      const parsedQuery = queryString.parse(query);
      if (Object.keys(parsedQuery).length === 0 || !parsedQuery.applicationId) {
        props.history.replace("/programme/dashboard", {});
      }
    } else {
      props.history.replace("/programme/dashboard", {});
      props.history.go(1);
    }
    // setLoader(false);
  }, [query]);

  useEffect(() => {
    setLoadingApp(true);
    getUserApplications(dispatch, userId).then(() => {
      setLoadingApp(false);
    });
  }, []);

  const { userApplications } = state.Application;
  const applicant =
    userApplications &&
    userApplications.filter(
      (applicant) => applicant.applicationId === applicationId
    );

  const showValidationModal = (applicant) => {
    const success_message = successMessage[lang || "en"];

    return (
      <Modal isOpen={toggleModal} toggle={toggleModalPrompt}>
        <ModalHeader toggle={toggleModalPrompt}>
          {success_message.congrats}
        </ModalHeader>
        <ModalBody style={{ textAlign: "center", padding: "2rem" }}>
          <div className="modalImg">
            <img src={ModalImg2} alt="" />
          </div>
          <span></span>
          <h4 style={{ marginBottom: "1.2rem" }}>{/* Great work!😃 */}</h4>

          <Col style={{ textAlign: "left" }}>
            <span style={{ fontWeight: "bold" }}>
              {success_message.title} {applicant && applicant.firstName},
            </span>
            <p>{success_message.p}</p>
            {/* <p>However, you can still make changes if need be.</p> */}
            <div
              className="continue-btn"
              onClick={() => history.replace("/home", {})}
            >
              {t("Back to Home")}
            </div>
          </Col>
        </ModalBody>
      </Modal>
    );
  };

  useEffect(() => {
    validate && Boolean(validate) && toggleModalPrompt();
  }, [validate]);

  return (
    <div className={`${classes.prevMain} notransalate`}>
      {!loadingApp && applicant.length > 0 && (
        <>
          {/* <Header onOpen={true} /> */}
          <br />
          <div className={classes.gbC} onClick={() => history.push("/home")}>
            {some_translation.backHome}
          </div>

          <br />
          <div className={classes.gbC} onClick={tEdit}>
            {some_translation.edit}
          </div>

          <div style={{ marginTop: "10px" }}></div>
          <PreviewApplication
            application={applicant[0]}
            togglePreview={togglePreview}
            showValidateBtn={false}
            ref={openEditRef}
          />
          {showValidationModal(applicant[0])}
        </>
      )}
      {!loadingApp && applicant.length === 0 && (
        // <div className="not-f">
        //   Oops what you are looking for does not exist
        // </div>
        <div className={classes.notF}>
          <GiBinoculars size={200} />
          Oops what you are looking for does not exist
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              backgroundColor: "var(--tef-red)",
              padding: "10px 20px",
              width: "max-content",
              color: "white",
              marginTop: "10px",
            }}
            onClick={() => history.push("/home")}
          >
            {some_translation.backHome}
          </div>
        </div>
      )}

      <CLoader loading={loadingApp} />
    </div>
  );
};
export default AppPreviewUpdate;
