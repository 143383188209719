import React, { useEffect, useContext, useState } from "react";
import { Row, Col } from "reactstrap";
import { Context } from "src/store";
import "./Discussions.scss";
import ShareInput from "src/modules/mentorship/components/ShareInput/ShareInput";
import DiscussionChat from "./DiscussionChat";
import {
  getGoalDiscussions,
  postDiscussion,
  likeOrUnlike,
  getDiscussionDetails,
  getDiscussionReplies,
} from "src/requests/discussion";
import ContentLoader from "react-content-loader";
import shortid from "shortid";
import Empty from "src/assets/img/mentorship-empty.png";
import { useHistory, useParams } from "react-router-dom";
import ReplyChat from "./ReplyChat";
import { FileViewer } from "src/components/FileViewer/FileViewer";
import { UploadFile, isAllowedFiles } from "src/requests/fileUploadRequest";
import _ from "lodash";
import { useCurrentUser } from "src/utils/Services/currentUser";

const Loader = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "10px",
        // border: "1px solid #c4cdd5",
        borderRadius: "10px",
        margin: "8px",
        width: "100%",
      }}
      className="loader-item"
    >
      <ContentLoader
        speed={2}
        width="100%"
        height={160}
        viewBox="0 0 400 160"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
        <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
        <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
        <circle cx="20" cy="20" r="20" />
      </ContentLoader>
    </div>
  );
};

const showLoading = () => {
  const loaders = [];
  for (let i = 0; i <= 20; i++) {
    loaders.push(<Loader key={shortid.generate()} />);
  }

  return loaders;
};

const Discussions = ({ isActive }) => {
  const [activeGoal, setActiveGoal] = useState(null);
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [selectedDiscussion, setSelectedDiscussion] = useState("");
  const { state, dispatch } = useContext(Context);
  const history = useHistory();
  const { goalId } = useParams();
  const [loading, setLoading] = useState(false);
  const user = useCurrentUser();
  const [clearContent, setClearContent] = useState(false);

  useEffect(() => {
    setActiveGoal(goalId);
  }, [goalId]);

  useEffect(() => {
    const _getDiscussions = () =>
      goalId ? getGoalDiscussions(dispatch, goalId) : null;
    _getDiscussions();
  }, []);

  const _onChange = (value) => {
    // const value = e.target.value;
    setMessage(value);
  };

  const _onSubmit = () => {
    if (!activeGoal || (!message && files.length === 0)) {
      return;
    }
    const discussion = {
      goalId: activeGoal,
      message,
      fileUploadNames: [],
    };
    if (files.length > 0) {
      setLoading(!loading);
      UploadFile(dispatch, true, files)
        .then((res) => {
          if (res && res.status === 200) {
            discussion.fileUploadNames = res.files;
            setFiles([]);
            setMessage("");
            setClearContent(true);
            _handleSubmit(discussion);
          }
        })
        .catch((e) => {
          console.log(e);
          _handleSubmit(discussion);
        });
    } else {
      setLoading(!loading);
      _handleSubmit(discussion);
      setMessage("");
      setClearContent(true);
    }
    // setUseFileViewerUpload(true);
  };

  const _handleSubmit = (discussion) => {
    postDiscussion(dispatch, discussion, history, user, user.userId).then(
      () => {
        setLoading(false);
      }
    );
  };

  const onRepyIconClick = (id) => {
    if (selectedDiscussion === id) {
      setSelectedDiscussion("");
    } else {
      setSelectedDiscussion(id);
      getDiscussionReplies(dispatch, id, true);
    }
  };

  const _likeOrUnlike = (discussionId) => {
    likeOrUnlike(dispatch, discussionId).then((res) => {
      if (res.status === 200) {
        getDiscussionDetails(dispatch, discussionId);
      }
    });
  };

  const _onUploadFinish = (files) => {
    console.log(files);
  };

  const _handleFileChange = (e) => {
    const existingFiles = [...files];
    const validefiles = isAllowedFiles(Array.from([...e.target.files]), true);
    if (validefiles.hasError) {
      validefiles.errors.forEach((file) => {
        console.log(file.name);
      });
    } else {
      existingFiles.push(...e.target.files);
      const uniqueFile = _.uniqBy(existingFiles, "name");
      setFiles(uniqueFile);
    }
  };

  const _onRemoveFile = (file) => {
    const existingFiles = [...files];
    const filtered = existingFiles.filter((f) => {
      return f.name !== file;
    });
    setFiles(filtered);
  };

  const displayDiscussions = () => {
    if (
      state.Discussions.discussions &&
      state.Discussions.discussions.length > 0
    ) {
      const discussions = state.Discussions.discussions;
      return discussions.map((discussion, i) => {
        return (
          <div
            key={discussion.id}
            style={{
              backgroundColor: "white",
              margin: "20px 0px 20px 11px",
              padding: "10px",
              borderRadius: "4px",
            }}
          >
            <DiscussionChat
              data={discussion}
              onLikeClick={() => _likeOrUnlike(discussion.id)}
              onReplyClick={() => onRepyIconClick(discussion.id)}
              type="discussion"
              senderDetails={discussion.otherDetails}
              senderId={discussion.createdBy}
              files={discussion.fileNames}
            />

            {isActive && (
              <ReplyChat
                discussion={discussion}
                activeGoal={activeGoal}
                selectedDiscussion={selectedDiscussion}
              />
            )}
          </div>
        );
      });
    } else {
      return (
        <Col
          style={{ textAlign: "center", marginTop: "20px" }}
          md={{ size: 6, offset: 3 }}
        >
          <img src={Empty} />
          <h3 className="goals-heading">No Discussions found for this goal</h3>
          <br />
          <br />
        </Col>
      );
    }
  };

  return (
    <Row>
      <div
        className="Mentors-container"
        style={{ width: "100%", backgroundColor: "#f2f2f3" }}
      >
        <Col md={12}>
          <Row md={12} className="discussions-input">
            {isActive && (
              <ShareInput
                value={message}
                onSumit={_onSubmit}
                onChange={_onChange}
                showAttachementBtn
                handleFileChange={_handleFileChange}
                files={files}
                loading={loading}
                clearContent={clearContent}
                setClearContent={setClearContent}
              />
            )}
            <FileViewer
              files={files}
              write
              onFileRemoveClick={_onRemoveFile}
              onUploadFinish={_onUploadFinish}
              onAddImageClick={() => document.getElementById("files").click()}
            />
          </Row>
          <Col>{state.Discussions.loading && <>{showLoading()}</>}</Col>
          <>{displayDiscussions()}</>
        </Col>
      </div>
    </Row>
  );
};

Discussions.propTypes = {};

export default Discussions;
