import React, { useEffect, useState } from "react";
import classes from "./HubUsers.module.scss";
import "./HubUser.scss";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ListGroup,
  ListGroupItem,
  Spinner,
  Button,
} from "reactstrap";
import { LinkedinShareButton, TwitterShareButton } from "react-share";
import { MdChat } from "react-icons/md";
import { FaLinkedin, FaTwitter } from "react-icons/fa";
import { selectColor } from "src/modules/Alumni/AlumniHub/helper/selectColor";
import { useHistory } from "react-router";
import { CountryRegionData } from "react-country-region-selector";

import { getUserProfile } from "src/requests/AlumniChatRequest";
import { FRONTEND_URL } from "src/utils/frontendUrl";

const HubUser = ({ userDetails, index }) => {
  const history = useHistory();
  const colorPicker = selectColor();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUserId] = useState("");
  const [userProfile, setUserProfile] = useState({});
  const [isLoadingUserProfile, setIsLoadingUserProfile] = useState(false);

  // const [loadingUserProfile, setLoadingUserProfile] = useState(false);
  // const [foundUserProfile, setFoundUserProfile] = useState(userDetails)
  const localUrl = (params) =>
    `${FRONTEND_URL}/alumni/hub/${params.userId}/users`;
  const toggleUserProfile = () => setIsOpen((prev) => !prev);
  const openUserProfileModal = (id) => {
    setIsOpen(true);
    _getUserProfile(userDetails.userId);
  };
  const getCountryByCode = (code) => {
    const val = getCountries().find((item) => item.value === code);
    return val?.label;
  };
  function getCountries() {
    return CountryRegionData.map((item) => {
      return {
        label: item[0],
        value: item[1],
      };
    });
  }
  useEffect(() => {}, [selectedUserId]);
  const _getUserProfile = (id) => {
    setIsLoadingUserProfile(true);

    getUserProfile(id)
      .then((res) => {
        const {
          data: { data },
        } = res;
        setIsLoadingUserProfile(false);
        setUserProfile(data);
      })
      .catch(() => {
        setIsLoadingUserProfile(false);
      });
  };

  // useEffect(() => {
  //
  // }, [selectedUserId]);
  return (
    <div className={classes["hub-user__card"]}>
      <Modal
        isOpen={isOpen}
        toggle={true}
        className="modal-container"
        centered={true}
      >
        <ModalHeader toggle={toggleUserProfile}>
          {userDetails.firstName} Profile
        </ModalHeader>
        <ModalBody>
          {isLoadingUserProfile ? (
            <div style={{ marginLeft: "auto", width: "50%" }}>
              <Spinner />
            </div>
          ) : (
            <div style={{ height: "80vh", overflowY: "auto" }}>
              <div
                className="user-background"
                style={{ backgroundColor: colorPicker(index % 6).background }}
              >
                {userDetails.imageUrl ? (
                  <img
                    src={`${userDetails.imageUrl}`}
                    className="user-hub-img"
                  />
                ) : (
                  <div className="hub-user-no-img">
                    <span>{`${userDetails.firstName.charAt(
                      0
                    )}${userDetails.lastName.charAt(0)}`}</span>
                  </div>
                )}
              </div>

              <div className="user-full-detail mb-4">
                <ListGroup>
                  <ListGroupItem>
                    First Name: {userProfile?.firstName}
                  </ListGroupItem>
                  <ListGroupItem>
                    Last Name: {userProfile?.lastName}
                  </ListGroupItem>
                  <ListGroupItem>
                    Country: {getCountryByCode(userProfile?.country ?? "")}
                  </ListGroupItem>
                  <ListGroupItem>Gender: {userProfile?.gender}</ListGroupItem>
                  <ListGroupItem>Email: {userProfile?.email}</ListGroupItem>
                  <ListGroupItem>Phone: {userProfile?.phone}</ListGroupItem>
                  <ListGroupItem>City: {userProfile?.city}</ListGroupItem>
                  <ListGroupItem>
                    Business Name: {userProfile?.businessName}
                  </ListGroupItem>
                  <ListGroupItem>
                    Business Industry: {userProfile?.businessIndustry}
                  </ListGroupItem>
                  <ListGroupItem>
                    Business Description: {userProfile?.aboutBusiness}
                  </ListGroupItem>
                </ListGroup>
              </div>
              <Button
                className="float-right"
                onClick={() => history.push("/alumni/chat")}
              >
                Connect with {userProfile?.firstName}
              </Button>
            </div>
          )}
        </ModalBody>
      </Modal>
      <div className={classes["hub-user__image"]}>
        {userDetails.imageUrl ? (
          <img
            src={userDetails.imageUrl}
            style={{ height: "120px", width: "100%", objectFit: "cover" }}
            className="image-fluid"
            alt="hub user"
          />
        ) : (
          <div
            className={classes["user-image__alt-wrapper"]}
            style={{ backgroundColor: colorPicker(index % 6).background }}
          >
            <div
              className={classes["user-image__alt-details"]}
              // style={colorPicker(index % 6).color}
              style={{ backgroundColor: colorPicker(index % 6).background }}
            >
              {userDetails.firstName.charAt(0)} {userDetails.lastName.charAt(0)}
            </div>
          </div>
        )}
      </div>
      <div className={classes["hub-user__details"]}>
        <h6 className={classes["user-name"]}>
          {userDetails.firstName} {userDetails.lastName}
        </h6>
        <p className={classes["user-position"]}>{userDetails.email}</p>
        <small
          className="fs-11 mt-4 text-secondary pointer"
          onClick={openUserProfileModal}
        >
          View profile
        </small>
        <div className={classes["user-socials"]}>
          <div
            onClick={() => history.push("/alumni/chat")}
            className={`${classes["social-icon__box"]} ${classes["social-icon__box-chat"]}`}
          >
            <MdChat />
          </div>
          <div
            className={`${classes["social-icon__box"]} ${classes["social-icon__box-twitter"]}`}
          >
            {" "}
            <TwitterShareButton url={localUrl(userDetails)}>
              <FaTwitter />
            </TwitterShareButton>
          </div>
          <div
            className={`${classes["social-icon__box"]} ${classes["social-icon__box-linkedin"]}`}
          >
            <LinkedinShareButton url={localUrl(userDetails)}>
              <FaLinkedin />
            </LinkedinShareButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HubUser;
