/* eslint-disable prettier/prettier */
import React, { useContext, useState, useEffect } from "react";

import { Row, Col, Spinner } from "reactstrap";
import Cycle from "src/modules/Tef-Dms/components/Cycle/Cycle";
import UploadDocument from "src/modules/Tef-Dms/components/UploadDocument";
import { Context } from "src/store";
import SubmitDocument from "src/modules/Tef-Dms/components/SubmitDocument/SubmitDocument";
import SubmitDocumentModal from "src/modules/Tef-Dms/components/Modals/SubmitDocumentModal/SubmitDocumentModal";
import BusinessPlanGenerator from "src/modules/Tef-Dms/components/BusinessPlanGenerator/index";
import NoDocuments from "src/assets/img/mentorship-empty.png";
import { getSettings } from "src/requests/DmsRequest";
import jwt_decode from "src/utils/jwt-decode";
import Swal from "sweetalert2";

import classes from "./BusinessPlans.module.css";

const BusinessPlans = ({
  isUseBuilder,
  handleBuilder,
  loader,
  uploadLoader,
  handleSubmitForReview,
  handleDocumentSelect,
  handleUpload,
}) => {
  const [
    businessplanStageRequiredDocuments,
    setBusinessPlanStageRequiredDocuments,
  ] = useState([]);
  const [businessplanDocuments, setBusinessPlanDocuments] = useState([]);
  const [displayBusinessPlans, setDisplayBusinessPlans] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [businessPlanStart, setStartDate] = useState("");
  const [businessPlanEnd, setEndDate] = useState("");
  const [todaysDate, setToday] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const { state } = useContext(Context);

  useEffect(() => {
    const businessPlansRequiredDocuments =
      state.dms && state.dms.businessPlansRequiredDocuments;
    if (businessPlansRequiredDocuments) {
      setBusinessPlanStageRequiredDocuments(businessPlansRequiredDocuments);
    }
  }, [state.dms.businessPlansRequiredDocuments]);

  useEffect(() => {
    const businessplanDocuments =
      state.dms && state.dms.businessPlan && state.dms.businessPlan.documents;

    if (businessplanDocuments) {
      setBusinessPlanDocuments(businessplanDocuments);
    }
    const submitted =
      state.dms &&
      state.dms.businessPlan &&
      state.dms.businessPlan.stageProgresses;
    if (submitted && submitted.length >= 1) setSubmitted(true);
  }, [state.dms.businessPlan]);

  useEffect(() => {
    const tkn = localStorage.getItem("data");
    const token = JSON.parse(tkn).token;
    const { dms_user } = jwt_decode(token);
    const programId = dms_user[0];

    const _getSettings = async () => {
      try {
        const payload = {
          programId,
          submissionType: "BusinessPlan",
        };
        const response = await getSettings(payload);
        const { data } = response.data;
        if (data && data.length <= 0) {
          setError(true);
          return;
        } else {
          const startDate = new Date(data[0].startDate);
          const endDate = new Date(data[0].endDate);
          const today = new Date();

          if (startDate && endDate) {
            setToday(today);
            setEndDate(endDate);
            setStartDate(startDate);
            if (startDate > today) {
              setDisplayBusinessPlans(false);
              localStorage.setItem("businessPlanStatus", "Not Started");
            } else {
              setDisplayBusinessPlans(true);
              localStorage.setItem("businessPlanStatus", "In Progress");
            }
            return response;
          }
        }
      } catch (error) {
        setError(true);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "The Admin has not provided the start and end date for this business plan phase",
          confirmButtonColor: "#da291c",
        });

        return error;
      }
    };

    _getSettings();
  }, []);

  const showSubmitForReviewButton = (
    businessplanDocuments,
    businessPlansRequiredDocuments
  ) => {
    if (businessplanDocuments && businessPlansRequiredDocuments) {
      if (
        businessplanDocuments[0]?.documents.length ===
        businessPlansRequiredDocuments.length
      ) {
        return true;
      }
    }

    return false;
  };

  const renderApprovaLoader = () => {
    return (
      <React.Fragment>
        {state.dms && state.dms.loadingDueDilligence === true && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderApprovalMessage = () => {
    return (
      <React.Fragment>
        {state.dms &&
          state.dms.loadingDueDilligence === false &&
          state.dms.dueDilligence &&
          state.dms.dueDilligence.approvalStatus !== "APPROVED" && (
            <div className={classes.NoDocumentsContainer}>
              <img src={NoDocuments} className={classes.NoDocuments} />
              <h6>Your Due Dilligence documents have not been approved yet.</h6>
              <h6>
                <span style={{ fontWeight: "700" }}>Status:</span>{" "}
                {state.dms.dueDilligence.approvalStatus}
              </h6>
            </div>
          )}
      </React.Fragment>
    );
  };

  const renderBusinessPlan = () => {
    return (
      <div>
        {state.dms &&
          !state.dms.loadingBusinessPlans &&
          !state.dms.loadingDueDilligence &&
          state.dms.dueDilligence &&
          state.dms.dueDilligence.approvalStatus === "APPROVED" && (
            <div>
              {!isUseBuilder && (
                <>
                  <Row>
                    <Col md={12}>
                      <UploadDocument
                        handleDocumentSelect={handleDocumentSelect}
                        documentTypes={businessplanStageRequiredDocuments}
                        handleUpload={handleUpload}
                        uploadLoader={uploadLoader}
                        businessPlanText="or Use builder"
                        tabId={2}
                        handleBuilder={handleBuilder}
                        canUpload={submitted}
                      />
                    </Col>
                  </Row>
                  {businessplanDocuments.length < 1 &&
                    state.dms.loadingBusinessPlans && (
                      <div className={classes.NoDocumentsContainer}>
                        <Spinner color="dark" />
                      </div>
                    )}
                  {state.dms &&
                    !state.dms.loadingBusinessPlans &&
                    businessplanDocuments.length < 1 && (
                      <div className={classes.NoDocumentsContainer}>
                        <img
                          src={NoDocuments}
                          className={classes.NoDocuments}
                        />
                        <h6>No documents available!</h6>
                      </div>
                    )}
                  {state.dms &&
                    !state.dms.loadingBusinessPlans &&
                    businessplanDocuments.length > 0 && (
                      <Row>
                        {businessplanDocuments
                          .sort(
                            (a, b) =>
                              new Date(a.cycleStartDate) -
                              new Date(b.cycleStartDate)
                          )
                          .map((documents, index) => (
                            <Cycle
                              documents={documents}
                              key={index}
                              index={index}
                            />
                          ))}
                      </Row>
                    )}
                  <Row className="mt-5">
                    {isEndDatePassed(businessPlanEnd, todaysDate) && (
                      <SubmitDocument
                        buttonText="Submit"
                        message="This phase has ended"
                      />
                    )}
                    {!submitted && (
                      <SubmitDocument>
                        <SubmitDocumentModal
                          loader={loader}
                          handleSubmitForReview={handleSubmitForReview}
                          isShowButton={showSubmitForReviewButton(
                            businessplanDocuments,
                            businessplanStageRequiredDocuments
                          )}
                        />
                      </SubmitDocument>
                    )}
                  </Row>
                </>
              )}
              {isUseBuilder && (
                <div className="mt-4">
                  <p style={{ color: "#DA291C" }} onClick={handleBuilder}>
                    Switch to Document Upload
                  </p>

                  <div className="mt-4">
                    <div>
                      <h6>
                        <strong>Business Plan Generator</strong>
                      </h6>
                      <p>
                        <small style={{ color: "#BDC3CC" }}>
                          Provide the necessary information in the form <br />{" "}
                          below to fill the business plan{""}
                        </small>
                      </p>
                    </div>
                    <div></div>
                  </div>

                  <BusinessPlanGenerator />
                </div>
              )}
            </div>
          )}
      </div>
    );
  };

  const isWithinStartEndDate = (start, today) => {
    if (today >= start) {
      return true;
    }
    return false;
  };

  const isEndDatePassed = (end, today) => {
    if (today > end) {
      return true;
    }

    return false;
  };

  return (
    <>
      <div>
        {displayBusinessPlans === false && (
          <div className={classes.NoDocumentsContainer}>
            <img src={NoDocuments} className={classes.NoDocuments} />
            <h6>This phase has not commenced yet </h6>
          </div>
        )}
      </div>

      <div>
        {error && (
          <div className={classes.NoDocumentsContainer}>
            <img src={NoDocuments} className={classes.NoDocuments} />
            <h6>This phase has not commenced yet</h6>
          </div>
        )}
      </div>

      {isWithinStartEndDate(businessPlanStart, todaysDate) && (
        <>
          <div>{renderApprovaLoader()}</div>
          <div>{renderApprovalMessage()}</div>
          <div>{renderBusinessPlan()}</div>
        </>
      )}
    </>
  );
};

export default BusinessPlans;
