/* eslint-disable no-multi-str */
import React, { useState, useEffect, useContext } from "react";
import { Row, Col, FormGroup, Button, Label, Input } from "reactstrap";
import Layout from "src/components/Layout/Layout";
import { Link, useHistory } from "react-router-dom";
import CreatableMulti from "src/components/MultiSelect/CreatableSelect";
import { Editor } from "@tinymce/tinymce-react";
import { Context } from "src/store";
import "./AlumniEvent.scss";
import CreateEventForm from "./CreateEventForm";
import CreateEventSuccess from "./CreateEventSucces";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import ValidateJs from "src/utils/Validations/validation_wrapper";
import Loader from "src/components/Loader/Loader";
import { DatePickerInput } from "rc-datepicker";
import moment from "moment";
import _ from "lodash";
import jwt_decode from "src/utils/jwt-decode";
import DefaultImage from "src/assets/img/event3.jpg";
// import { getInterests } from "src/requests/onboarding";
import { getUserHub } from "src/requests/AlumniHubRequest";
import Swal from "sweetalert2";
import { FaLongArrowAltRight } from "react-icons/fa";
import {
  getEventTags,
  postEventOpportunity,
} from "src/requests/AlumniEventRequest";
import { UploadFile } from "src/requests/fileUploadRequest";

const CreateEvent = () => {
  const data = {
    userId: "",
    title: "",
    description: "",
    privacy: "",
    userGroup: "",
    isOnline: true,
    link: "",
    startDate: "",
    endDate: "",
    tags: [],
    hubId: "",
    bannerUrl: DefaultImage,
    venue: "",
    isFeatured: false,
    isAdminCreated: false,
  };
  const eventValidationErrors = {
    userId: null,
    title: null,
    description: null,
    privacy: null,
    userGroup: null,
    isOnline: null,
    link: null,
    startDate: null,
    endDate: null,
    tags: [],
    bannerUrl: null,
    hubId: null,
  };
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const { dispatch } = useContext(Context);
  const [tags, setTags] = useState([]);
  const [eventData, setEventData] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [alumniHub, setAlumniHub] = useState([]);

  const [responseData, setResponseData] = useState("");
  const [validationErrors, setValidationErrors] = useState(
    eventValidationErrors
  );

  const [file, setFile] = useState([]);
  const toggle = () => setModal(!modal);
  const cUser = localStorage.getItem("current_user");
  const current_user = jwt_decode(cUser).userId;

  useEffect(() => {
    getUserHub(dispatch)
      .then((response) => {
        const {
          data: { data },
        } = response.data;

        const transform = data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setAlumniHub(transform);
      })
      .catch((getUserHubError) => console.log(getUserHubError));
  }, []);

  const _getBasicData = () => {
    getEventTags()
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          const tagCollections = data.data.map((i) => {
            return { value: i.id, label: i.name };
          });
          setTags(tagCollections);
        } else {
          setTags([]);
        }
      })
      .catch(() => {
        setTags([]);
      });
  };

  useEffect(() => {
    _getBasicData();
  }, []);

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setEventData({ ...eventData, [name]: value });
  };
  const checkboxHandler = (e) => {
    const { checked } = e.target;

    setEventData({ ...eventData, isOnline: checked });
  };
  const handleDate = (keyword, val) => {
    const selectedDate = moment(val).format("YYYY-MM-DDTHH:mm:ss.SSS");

    setEventData({ ...eventData, [keyword]: selectedDate });
  };

  const selectHandler = (name, value) => {
    setEventData({ ...eventData, [name]: value });
  };
  const onDrop = (picture) => {
    const existingFiles = [...file];

    existingFiles.push(...picture);
    const uniqueFile = _.uniqBy(existingFiles, "name");
    setFile(uniqueFile);
  };

  const handleChange = (selectedItem) => {
    setEventData({
      ...eventData,
      tags: selectedItem,
    });
  };

  console.log(eventData);

  const _handleValidationErrors = (target, value) => {
    setValidationErrors({ ...validationErrors, [target]: value });
  };
  const _hasValidationError = (data) => {
    const whitelist = ["officialWebsite"];
    // const whitelist = ["officialWebsite", "contactEmail"];

    data = { ...data };

    let errors = {};
    const errorList = [];
    Object.keys(data).forEach((key) => {
      errors = { ...errors, [key]: ValidateJs(key, data[key]) };
    });

    whitelist.forEach((key) => {
      if (data[key] === "") {
        delete errors[key];
      }
    });

    Object.keys(errors).forEach((key) => {
      if (errors[key] !== null) {
        errorList.push(key);
      }
    });
    setValidationErrors(errors);

    return { confirmed: errorList.length > 0, errors: errorList };
  };

  const submitEvent = (e) => {
    e.preventDefault();
    const errors = _hasValidationError(eventData);
    if (errors.confirmed) {
      document.getElementById(errors.errors[0]).scrollIntoView();
    } else {
      const selectTagLabel =
        eventData.tags && eventData.tags.length
          ? eventData.tags.map((tag) => tag.label)
          : [];
      eventData.tags = selectTagLabel;
      setIsLoading(true);
      if (file.length > 0) {
        UploadFile(dispatch, true, file)
          .then((res) => {
            if (res && res.status === 200) {
              res.files.map((img) => (eventData.bannerUrl = img));
              _submit(eventData);
            }
          })
          .catch(() => {
            eventData.bannerUrl = DefaultImage;
            _submit(eventData);
          });
      } else {
        _submit(eventData);
      }
    }
  };

  const _submit = (data) => {
    data.userId = current_user;

    postEventOpportunity(dispatch, data)
      .then((res) => {
        setIsLoading(false);
        if (res) {
          setResponseData(res);
          setEventData({
            userId: "",
            title: "",
            description: "",
            privacy: "",
            userGroup: "",
            isOnline: "",
            link: "",
            startDate: "",
            endDate: "",
            tags: [],
            bannerUrl: "",
          });
          toggle();
        }
      })
      .catch((err) => {
        Swal.fire(`${err.response} || Something went wrong`);
      });
  };
  return (
    <Layout SideContent={<SidebarRight />}>
      <div className="mb-5">
        <div className="create-opportunity">
          <Link to="/alumni-events" style={{ fontWeight: "bold" }}>
            <span className="mr-3">Alumni Events</span>
          </Link>
          <FaLongArrowAltRight color="#919BA8" size={12} />
          <span className="ml-3">{"Create an Event"}</span>
        </div>
        {/* <div className="create event">
          <Link
            to="/alumni-events"
            style={{
              fontWeight: "bold",
              fontSize: "12px",
              color: "black",
              textTransform: "capitalize",
            }}
          >
            <span className="mr-3">Alumni Event </span>
            <IoIosArrowRoundForward />
          </Link>
          <Link
            to="/opportunities"
            style={{
              fontSize: "12px",
              textTransform: "capitalize",
              color: "#ced4da",
            }}
          >
            create an event
          </Link>
        </div> */}
        <Row className="mt-4">
          <Col>
            <div className="create-event-header">
              <h5 className="create-header-title">Create an event</h5>
              <span
                className="create-header-cancel"
                onClick={() => history.push("/alumni-events")}
              >
                Cancel Create Event
              </span>
            </div>
          </Col>
        </Row>

        <form onSubmit={submitEvent}>
          <CreateEventForm
            tagsOption={tags}
            toggle={toggle}
            selectHandler={selectHandler}
            handleChange={handleChange}
            inputHandler={inputHandler}
            alumniHub={alumniHub}
            onDrop={onDrop}
            eventData={eventData}
            userGroupObj={[
              { label: "Alumni", value: 1 },
              // { label: "General", value: 2 },
            ]}
            privacyList={[
              { label: "Public", value: 1 },
              { label: "private", value: 2 },
            ]}
            validationErrors={validationErrors}
            _handleValidationErrors={_handleValidationErrors}
          />
          <div className="tefstory-editor mt-3 p-4">
            <Row>
              <Col sm={12} md={12}>
                <FormGroup type="textarea">
                  <Label className="create-event-label" htmlFor="category">
                    Description{" "}
                    <span className="required required-tag">- optional</span>
                  </Label>
                  <Editor
                    apiKey={process.env.REACT_APP_TINY_CLOUD_KEY}
                    init={{
                      height: 400,
                      branding: false,
                      statusbar: false,
                      placeholder: "Start Typing...",
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help",
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
                    }}
                    onEditorChange={(content, editor) => {
                      setEventData({
                        ...eventData,
                        description: content,
                      });
                    }}
                    id="description"
                    value={eventData.description}
                    onBlur={() =>
                      _handleValidationErrors(
                        "description",
                        ValidateJs("decription", eventData.description)
                      )
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          <section className="create-tefstory-form mt-3 p-4">
            <h5 className="event-where mb-4">Where and when</h5>
            <Row className="mb-4">
              <Col>
                <FormGroup check inline>
                  <Label className="create-event-label">
                    <Input
                      defaultChecked
                      type="checkbox"
                      name="isOnline"
                      checked={eventData.isOnline}
                      value={eventData.isOnline}
                      id="isOnline"
                      onChange={checkboxHandler}
                    />{" "}
                    Online/Virtual Event
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col sm={12} md={12}>
                <FormGroup>
                  <Label htmlFor="storyTitle">
                    Add Link
                    <span className="required required-tag"> - Required</span>
                  </Label>
                  <Input
                    type="text"
                    name="link"
                    id="link"
                    placeholder="Event Link"
                    maxLength={40}
                    required
                    className="event__input"
                    onChange={inputHandler}
                    value={eventData.link}
                  />
                  <span className="input-maxlength float-right">0/40</span>
                </FormGroup>
              </Col>
            </Row>

            {!eventData.isOnline && (
              <Row form>
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Label htmlFor="storyTitle">
                      Event Venue
                      <span className="required required-tag"> - Required</span>
                    </Label>
                    <div className="create-input event-">
                      <Input
                        type="textarea"
                        name="venue"
                        className="event__input no-border"
                        id="venue"
                        placeholder="Event Venue"
                        maxLength={100}
                        onChange={inputHandler}
                        rows={5}
                        required
                        value={eventData.venue}
                      />
                      <span className="input-maxlength count-length textarea-count-length">
                        {eventData?.venue?.length}/80
                      </span>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            )}

            <Row form>
              <Col sm={12} md={6}>
                <FormGroup id="event-date-pick">
                  <Label className="create-event-label" htmlFor="startDate">
                    Start Date{" "}
                    <span className="required required-tag">- Required</span>
                  </Label>
                  <DatePickerInput
                    readOnly
                    style={{
                      border: "none",
                      backgroundColor: "#EFEFEF !important",
                    }}
                    className="no-border"
                    onChange={(val) => handleDate("startDate", val)}
                    value={eventData.startDate}
                    id="startDate"
                    minDate={new Date()}
                    placeholder="MM/DD/YYYY"
                    showOnInputClick
                    onBlur={() =>
                      _handleValidationErrors(
                        "startDate",
                        ValidateJs("startDate", eventData.startDate)
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup id="event-date-pick">
                  <Label className="create-event-label" htmlFor="category">
                    End Date
                    <span className="required required-tag">- Required</span>
                  </Label>
                  <DatePickerInput
                    readOnly
                    style={{
                      border: "none",
                      backgroundColor: "#EFEFEF !important",
                    }}
                    className="no-border"
                    onChange={(val) => handleDate("endDate", val)}
                    value={eventData.endDate}
                    id="endDate"
                    minDate={new Date()}
                    placeholder="MM/DD/YYYY"
                    showOnInputClick
                    onBlur={() =>
                      _handleValidationErrors(
                        "endDate",
                        ValidateJs("endDate", eventData.startDate)
                      )
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label className="create-event-label" htmlFor="privacy">
                    Tags
                    <span className="required required-tag">- Required</span>
                  </Label>

                  <CreatableMulti
                    className="event__input no-border"
                    options={tags}
                    onChange={handleChange}
                    isMulti={true}
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={6} className="create-event-tag">
                <div className="event-tag-group mt-4">
                  {tags.length &&
                    tags.slice(0, 4).map((tag, _) => {
                      return (
                        <span
                          key={_}
                          className="create-header-cancel"
                          // onClick={() => {
                          //   const tgs = [...eventData.tags, tag];
                          //   handleChange(tgs);
                          // }}
                        >
                          #{tag.label}
                        </span>
                      );
                    })}
                </div>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <div className="float-right">
                  <Button className="cancel-send mr-3">Cancel</Button>
                  <Button>
                    {isLoading ? (
                      <Loader color="light" />
                    ) : (
                      "Create Opportunity"
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </section>
        </form>
      </div>
      <CreateEventSuccess
        modal={modal}
        title={eventData.title}
        resultId={responseData}
        toggle={toggle}
      />
    </Layout>
  );
};

export default CreateEvent;
