import React, { useEffect, useState, useContext } from "react";
import { Row, Col, PopoverBody, Popover } from "reactstrap";
import "./Article.scss";
import Title from "src/modules/Discover/components/Title/Title";
import { FaEdit, FaRegBookmark, FaRegSave, FaShareAlt } from "react-icons/fa";
import { imageValid } from "src/modules/Discover/helper";
import EmptyImage from "src/assets/img/courseImage.jpg";
import {
  createAndDeleteBookmark,
  downloadResourceSummary,
  getAllArticles,
  getAllBookmarks,
  deleteArticle,
} from "src/requests/DiscoverRequest";
import { Context } from "src/store";
import Avatars from "src/components/Avatar/Avatar";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { useCurrentUser } from "src/utils/Services/currentUser";
import classes from "./Articles.module.css";
import { RiDeleteBin5Line } from "react-icons/ri";
import moment from "moment";
import ContentLoader from "react-content-loader";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import ArticleNotFound from "src/modules/Alumni/Components/NotFound/ArticleNotFound";

const ReactContentLoader = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div
        style={{ backgroundColor: "#f3f3f3", height: "200px", width: "100%" }}
      ></div>
      <div
        style={{
          backgroundColor: "white",
          padding: "10px",
        }}
        // className="loader-item"
      >
        <ContentLoader
          speed={2}
          // width={324}
          // height={150}
          viewBox="0 0 400 160"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="8" rx="3" ry="3" width="300" height="30" />
          <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
          <rect x="0" y="56" rx="3" ry="3" width="300" height="6" />
          <rect x="0" y="72" rx="3" ry="3" width="300" height="6" />
          <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
          {/* <circle cx="20" cy="20" r="20" /> */}
        </ContentLoader>
      </div>
    </div>
  );
};
const RenderContentLoader = () => {
  const arr = [1, 1, 1, 1];
  const contentArr = arr.fill(4);
  const contLoadArr = contentArr.map((c, idx) => (
    <Col md={6} key={idx}>
      <ReactContentLoader />
    </Col>
  ));
  return <Row>{contLoadArr}</Row>;
};

const Articles = ({
  handleSeeAll,
  numberToReturn,
  showMore,
  filterOptions,
}) => {
  const [articles, setArticles] = useState([]);
  const [bookmarked, setBookmarked] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [articlesLength, setArticlesLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openPopOver, setOpenpopover] = useState(false);
  const [targetId, setTargetId] = useState(null);
  const [sortString, setSortString] = useState("");

  const userId = useCurrentUser().userId;
  const { dispatch } = useContext(Context);
  const history = useHistory();

  useEffect(() => {
    _getAllArticles();
    getAllBookmarked();

    return () => {
      setTargetId(null);
      setOpenpopover(false);
    };
  }, [refresh]);

  useEffect(() => {
    _getAllArticles();
  }, [sortString]);

  const _getAllArticles = async () => {
    setLoading(true);
    try {
      const articles = await getAllArticles(dispatch, 1, sortString);
      const {
        data: { data },
      } = articles.data;
      if (data && data.length > 0) {
        setArticlesLength(data.length);
        const renderedData = numberToReturn
          ? data.slice(0, numberToReturn)
          : data;
        setArticles(renderedData);
      } else {
        setArticlesLength(0);
        setArticles([]);
      }
      setLoading(false);
    } catch ({ response }) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          response && response.data
            ? response.data
            : "Something went wrong while fetching Articles",
        confirmButtonColor: "#da291c",
      });
    }
  };

  const handleDeleteArticle = async (id) => {
    Swal.fire({
      title: "Delete?",
      text: "Are you sure you want to delete this article?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#da291c",
      cancelButtonColor: "#63666a",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteArticle(id).then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                text: "Article Deleted!",
                confirmButtonColor: "#da291c",
              }).then(() => setRefresh(!refresh));
            }, 2000);
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Unable to bookmark article at this time, try again!",
              confirmButtonColor: "#da291c",
            }).then(() => setRefresh(!refresh));
          }
        });
      }
    });
  };

  const handleBookmark = async (storyId, title) => {
    Swal.fire({
      title: "Bookmark?",
      text: bookmarked.find((b) => b.contentId === storyId)
        ? "You are about removing this article from your bookmark list?"
        : "Are you sure you want to bookmark this article?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#da291c",
      cancelButtonColor: "#63666a",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        createAndDeleteBookmark(userId, storyId, title, "TEF-Article").then(
          (res) => {
            if (res.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Bookmarked!",
                text: "Article Bookmarked!",
                confirmButtonColor: "#da291c",
              }).then(() => setRefresh(!refresh));
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Unable to bookmark article at this time, try again!",
                confirmButtonColor: "#da291c",
              }).then(() => setRefresh(!refresh));
            }
          }
        );
      }
    });
  };

  const getAllBookmarked = async () => {
    const bookmarked = await getAllBookmarks(userId);
    const {
      data: { data },
    } = bookmarked;
    setBookmarked(data);
  };

  const handleDownload = (id, author) => {
    downloadResourceSummary(id, author);
  };

  const handleShare = (id) => {
    if (id !== null) {
      setTargetId(id);
    }
    setOpenpopover(!openPopOver);
  };

  const currentURL = window.location.href;

  const getSortBy = (str) => {
    setSortString(str);
  };

  return (
    <>
      {loading ? (
        <RenderContentLoader />
      ) : articles.length <= 0 ? (
        <ArticleNotFound />
      ) : (
        <div className="mt-3">
          <Title
            name="Articles"
            nameLength={articlesLength}
            rightTitle="Create Article"
            seeAll="See All"
            menuTitle="Articles"
            handlePage={() => history.push("/discover/create-article")}
            seeAllAction={() => handleSeeAll("Articles")}
            showFilterOptions={filterOptions}
            sortBy={getSortBy}
          />
          <div className="video-list-item p-3 mt-2">
            <Row>
              {articles.map((item) => (
                <Col md={12} key={item.id}>
                  <div className="mb-2 group-list d-flex">
                    <div
                      onClick={() =>
                        history.push(`/discover/article/${item.id}`)
                      }
                      className={`group-list__image mr-4 ${classes.ArticleBanner}`}
                    >
                      <img
                        src={
                          imageValid(item.bannerUrl)
                            ? item.bannerUrl
                            : EmptyImage
                        }
                        alt="Article Banner"
                      />
                    </div>
                    <div className="pt-3">
                      <h1
                        onClick={() =>
                          history.push(`/discover/article/${item.id}`)
                        }
                      >
                        {item.title}
                      </h1>
                      <div
                        className="group-list__group-category"
                        onClick={() =>
                          history.push(`/discover/article/${item.id}`)
                        }
                      >
                        <p className="Article-short-description">
                          {item.shortDescription}
                        </p>
                      </div>
                      <div>
                        <div
                          className={classes.ArticlePoster}
                          onClick={() =>
                            history.push(`/discover/article/${item.id}`)
                          }
                        >
                          <span className="mr-2">
                            <Avatars
                              src={item.imageUrl}
                              name={`${
                                item.user.firstName.charAt(0).toUpperCase() +
                                item.user.firstName.slice(1)
                              } ${item.user.lastName.charAt(0)} `}
                              dimension={20}
                            />
                          </span>

                          <span
                            className={`username mr-4 ${classes.TruncateText}`}
                            style={{ fontSize: "0.7rem" }}
                          >{`${
                            item.user.firstName.charAt(0).toUpperCase() +
                            item.user.firstName.slice(1)
                          } ${
                            item.user.lastName.charAt(0).toUpperCase() +
                            item.user.lastName.slice(1)
                          }`}</span>
                          <span className="date-posted">
                            <span className="mr-2">Posted on:</span>
                            {moment(item.createdOn).format("DD-MMM-YY")}
                          </span>
                        </div>
                        <div
                          onClick={() => handleDownload(item.id, item.title)}
                        >
                          <span>
                            <FaRegSave size="0.8em" />
                          </span>
                          <span className="save-btn pr-3 pl-1">Save</span>
                        </div>
                        <div
                          id={`pop__${item.id}`}
                          onClick={() => handleShare(`pop__${item.id}`)}
                        >
                          <span>
                            <FaShareAlt size="0.8em" />
                          </span>
                          <span className="share-btn pr-3 pl-1">Share</span>
                        </div>
                        {targetId === `pop__${item.id}` && (
                          <Popover
                            className="p-0 border-0 d-none d-md-block"
                            trigger="legacy"
                            placement="right"
                            isOpen={openPopOver}
                            target={targetId}
                            toggle={() => handleShare(null)}
                          >
                            <PopoverBody>
                              <ul className="share-options__lists">
                                <li>
                                  <TwitterShareButton
                                    url={`${currentURL}/article/${item.id}`}
                                    title={item.title}
                                  >
                                    <TwitterIcon
                                      size={20}
                                      round={true}
                                      className="icon-tab"
                                    />
                                    Share via Twitter
                                  </TwitterShareButton>
                                </li>
                                <li>
                                  <FacebookShareButton
                                    url={`${currentURL}/article/${item.id}`}
                                    quote={item.title}
                                  >
                                    <FacebookIcon
                                      size={20}
                                      round={true}
                                      className="icon-tab"
                                    />
                                    Share via Facebook
                                  </FacebookShareButton>
                                </li>
                                <li>
                                  <LinkedinShareButton
                                    url={`${currentURL}/article/${item.id}`}
                                    title={item.title}
                                    summary={item.title}
                                  >
                                    <LinkedinIcon
                                      size={20}
                                      round={true}
                                      className="icon-tab"
                                    />
                                    Share via LinkedIn
                                  </LinkedinShareButton>
                                </li>
                              </ul>
                            </PopoverBody>
                          </Popover>
                        )}
                        <div
                          onClick={() => {
                            handleBookmark(item.id, item.title);
                          }}
                          className={
                            bookmarked.find((b) => b.contentId === item.id)
                              ? classes.Bookmarked
                              : ""
                          }
                        >
                          <span>
                            <FaRegBookmark size="0.8em" />
                          </span>
                          <span className="share-btn pl-1">
                            {bookmarked.find((b) => b.contentId === item.id)
                              ? "Bookmarked"
                              : "Bookmark"}
                          </span>
                        </div>
                        {userId === item.user.id && (
                          <div
                            onClick={() =>
                              history.push(`/discover/edit-article/${item.id}`)
                            }
                          >
                            <span>
                              <FaEdit size="0.8em" />
                            </span>
                            <span className="share-btn pl-1">Edit</span>
                          </div>
                        )}
                        {userId === item.user.id && (
                          <div onClick={() => handleDeleteArticle(item.id)}>
                            <span>
                              <RiDeleteBin5Line size="0.8em" />
                            </span>
                            <span className="share-btn">Delete</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>

            {showMore && (
              <div
                onClick={() => handleSeeAll("Articles")}
                className="mt-4 text-center w-25 show-more"
              >
                Show more
              </div>
            )}
            {/* {targetId && (
              
            )} */}
          </div>
        </div>
      )}
    </>
  );
};

export default Articles;
