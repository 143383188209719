import React from "react";
import "./Avatar.scss";
import Avatar from "react-avatar";
const Avatars = ({ src, dimension, onClick, showBadge, name, badgeText }) => {
  return (
    <div
      style={{
        height: dimension,
        width: dimension,
      }}
      className="profile-avatar-container__image-c"
      data-testid="avatar"
    >
      {src ? (
        <>
          <img
            src={src}
            alt="profile"
            className="img-fluid notranslate"
            style={{
              borderRadius: "inherit",
              height: "100%",
              width: "100%",
              objectFit: "cover",
              objectPosition: "50% 50%",
            }}
            onClick={onClick}
          />
          {showBadge && (
            <span className={`profile-avatar-container__image-c--badge`}>
              {badgeText}
            </span>
          )}
        </>
      ) : (
        <>
          <Avatar
            className="img-fluid notranslate"
            round
            name={name}
            size={dimension}
            onClick={onClick}
          />
          {showBadge && (
            <span className={`profile-avatar-container__image-c--badge`}>
              {badgeText}
            </span>
          )}
        </>
      )}
    </div>
  );
};

Avatars.propTypes = {};

Avatars.defaultProps = {
  badgeText: "Mentor",
  showBadge: false,
};

export default Avatars;
