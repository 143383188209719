import React, { useState, useEffect } from "react";
import { Col, Row, Spinner } from "reactstrap";
import { getTemplates } from "src/requests/DmsRequest";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const Downloads = ({ activeTab }) => {
  const [dueDilligenceTemplates, setDueDilligenceTemplates] = useState([]);
  const [businessTemplates, setBusinessPlanTemplate] = useState([]);
  const [seedCapitalTemplates, setSeedCapitalTemplates] = useState([]);
  const [dueDilligenceLoader, setDueDilligenceLoader] = useState(false);
  const [businessPlanLoader, setBusinessPlanLoader] = useState(false);
  const [seedCapitalLoader, setSeedCapitalLoader] = useState(false);
  const user = get_user_info_from_token().user;
  const programId = user.dms_user[0];

  const _getDueDilligenceTemplates = async () => {
    try {
      setDueDilligenceLoader(true);
      const params = {
        programId,
        submissionType: "DueDilligence",
      };
      const response = await getTemplates(params);
      const {
        data: { data },
      } = response;
      setDueDilligenceTemplates(data);
      setDueDilligenceLoader(false);
    } catch (error) {
      setDueDilligenceLoader(false);

      return error;
    }
  };

  const _getBusinessPlanTemplates = async () => {
    try {
      setBusinessPlanLoader(true);
      const params = {
        programId,
        submissionType: "BusinessPlan",
      };
      getTemplates(params);
      const response = await getTemplates(params);
      const {
        data: { data },
      } = response;
      setBusinessPlanTemplate(data);
      setBusinessPlanLoader(false);
    } catch (error) {
      setBusinessPlanLoader(false);

      return error;
    }
  };

  const _getSeedCapitalTemplates = async () => {
    try {
      setSeedCapitalLoader(true);
      const params = {
        programId,
        submissionType: "SeedCapital",
      };
      getTemplates(params);
      const response = await getTemplates(params);
      const {
        data: { data },
      } = response;
      setSeedCapitalTemplates(data);
      setSeedCapitalLoader(false);
    } catch (error) {
      setSeedCapitalLoader(false);

      return error;
    }
  };

  useEffect(() => {
    _getDueDilligenceTemplates();
    _getBusinessPlanTemplates();
    _getSeedCapitalTemplates();
  }, []);

  const _onDownLoadClick = (doc) => {
    const url = doc.templateUrl;
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      {activeTab === "1" && dueDilligenceLoader && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        </div>
      )}
      {activeTab === "2" && businessPlanLoader && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        </div>
      )}
      {activeTab === "3" && seedCapitalLoader && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        </div>
      )}
      {activeTab === "1" &&
        !dueDilligenceLoader &&
        dueDilligenceTemplates.map((doc, index) => (
          <div key={doc.id} className="">
            <Row>
              <Col sm="1" xs={1}>
                <strong>{index + 1}</strong>
              </Col>
              <Col sm="7" xs={7}>
                <p style={{ lineHeight: "1.5em" }}>
                  <strong>{doc.title}</strong>
                </p>
              </Col>
              <Col
                sm="1"
                xs={1}
                style={{ color: "#DA291C", cursor: "pointer" }}
                onClick={() => _onDownLoadClick(doc)}
              >
                Download
              </Col>
            </Row>
          </div>
        ))}

      {activeTab === "2" &&
        !businessPlanLoader &&
        businessTemplates.map((doc, index) => (
          <div key={doc.id} className="">
            <Row>
              <Col sm="1" xs={1}>
                <strong>{index + 1}</strong>
              </Col>
              <Col sm="7" xs={7}>
                <p style={{ lineHeight: "1.5em" }}>
                  <strong>{doc.title}</strong>
                </p>
              </Col>
              <Col
                sm="1"
                xs={1}
                style={{ color: "#DA291C", cursor: "pointer" }}
                onClick={() => _onDownLoadClick(doc)}
              >
                Download
              </Col>
            </Row>
          </div>
        ))}

      {activeTab === "3" &&
        !seedCapitalLoader &&
        seedCapitalTemplates.map((doc, index) => (
          <div key={doc.id} className="">
            <Row>
              <Col sm="1" xs={1}>
                <strong>{index + 1}</strong>
              </Col>
              <Col sm="7" xs={7}>
                <p style={{ lineHeight: "1.5em" }}>
                  <strong>{doc.title}</strong>
                </p>
              </Col>
              <Col
                sm="1"
                xs={1}
                style={{ color: "#DA291C", cursor: "pointer" }}
                onClick={() => _onDownLoadClick(doc)}
              >
                Download
              </Col>
            </Row>
          </div>
        ))}
    </div>
  );
};

export default Downloads;
