import { asyncActionName } from "src/utils/AsyncUtils";
import { GET_USER, SEND_EMAIL } from "src/actions";

import { initialState } from "src/store";

const UserReducer = (state = initialState.user, action) => {
  switch (action.type) {
    case asyncActionName(GET_USER).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(GET_USER).success:
      return {
        ...state,
        user: action.payload,
        success: true,
      };
    case asyncActionName(GET_USER).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    case asyncActionName(SEND_EMAIL).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(SEND_EMAIL).success:
      return {
        ...state,
        success: true,
      };
    case asyncActionName(SEND_EMAIL).failure:
      return {
        ...state,
        error: action.payload.status,
        success: false,
      };
    default:
      return state;
  }
};

export default UserReducer;
