import React, { useState, useContext } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Link } from "react-router-dom";
import Avatars from "src/components/Avatar/Avatar";
import classes from "./PostCard_v2.module.scss";
import moment from "moment";
import MoreOptions from "src/components/MoreOptions/MoreOptions";
import ClickOutside from "react-click-outside-component";
import { onHandleDelete } from "src/utils/helper";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { Context } from "src/store";
import { deletePost } from "src/requests/PostRequest";
const PostHeader = ({
  profile,
  post_id,
  isBookmarked,
  userId,
  createdOn,
  post,
}) => {
  const user = useCurrentUser();
  const loggedInUserId = user.userId;
  const [toggleDelete, setToggleDelete] = useState(false);
  const handleDeleteToggle = () => setToggleDelete(!toggleDelete);

  const { dispatch } = useContext(Context);

  const handleDelete = () => {
    onHandleDelete(deletePost, dispatch, post_id);
    handleDeleteToggle();
  };

  // const { firstName, lastName, imageUrl } = profile;
  /* eslint-disable no-unneeded-ternary */
  const user_profile = profile ? profile : "";
  const name = user_profile
    ? `${user_profile.firstName} ${user_profile.lastName}`
    : "Anonymous";
  return (
    <div className={classes.postHeaderContainer}>
      <div className={classes.postHeaderContainer__avatarContainer}>
        <Avatars name={name} dimension={39} src={user_profile.imageUrl} />
        <div
          className={`${classes.postHeaderContainer__avatarContainer_left} notranslate`}
        >
          <h5 className="notranslate">
            <Link className="notranslate" to={`/profile/${userId}`}>
              {name}
            </Link>
          </h5>
        </div>
      </div>
      <div className={classes.postHeaderContainer__right}>
        <span>{moment(createdOn).fromNow()}</span>
      </div>
      <BiDotsVerticalRounded
        className={classes.menuButton}
        size={20}
        onClick={handleDeleteToggle}
      />

      <ClickOutside onClickOutside={() => setToggleDelete(false)}>
        <MoreOptions
          userId={loggedInUserId}
          postOwnerId={userId}
          show={toggleDelete}
          handleDelete={handleDelete}
          contentId={post_id}
          contentType="post"
          title={post}
          isBookmarked={isBookmarked}
        />
      </ClickOutside>
    </div>
  );
};
export default PostHeader;
