import React, { useState, useContext, useEffect } from "react";
import Layout from "src/components/Layout/Layout";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import InfiniteScroll from "react-infinite-scroll-component";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import { Row, Col, Button } from "reactstrap";
import "./AlumniEvent.scss";
import EventCard from "src/modules/Alumni/Components/Cards/EventCard";
import EventContent from "src/modules/Alumni/Components/Slider/SliderContent";
import FeaturedEvent from "src/modules/Alumni/Components/Slider/EventSlider";
import { RiEqualizerLine, RiAddFill } from "react-icons/ri";
import {
  getAllAlumniEvents,
  getAllFeaturedAlumniEvents,
} from "src/requests/AlumniEventRequest";
import { Context } from "src/store";
import Select, { components } from "react-select";
import { FaCaretDown } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import Loader from "src/components/Loader/Loader";
// import DefaultImage from "src/assets/img/Opportunity.jpeg";
import ArticleNotFound from "src/modules/Alumni/Components/NotFound/ArticleNotFound";
import OpportunityContentLoader, {
  RenderContentLoader,
} from "src/modules/Alumni/Components/SkeletonLoader/opportunityLoader";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaCaretDown color="#ACB3BD" />
    </components.DropdownIndicator>
  );
};

const AlumniEvents = () => {
  const [searchParams, setSearchParams] = useState({
    eventUserGroups: 1,
    eventsExpiry: 1,
    eventsPrivacy: 1,
  });
  const [alumniEvent, setAlumniEvent] = useState([]);
  const [pastAlumniEvent, setPastAlumniEvent] = useState([]);
  const [fetchingEvents, setFetchingEvents] = useState(false);
  const [fetchingFeaturedEvents, setFetchingFeaturedEvents] = useState(false);
  const { dispatch } = useContext(Context);
  const [isFeaturedEvent, setIsFeaturedEvent] = useState([]);
  const pageDetails = {
    firstItemOnPage: 1,
    hasNextPage: true,
    hasPreviousPage: false,
    lastItemOnPage: 0,
    pageCount: 0,
    pageNumber: 1,
    pageSize: 5,
    totalItemCount: 0,
  };
  const [pastPageDetails, setPastpageDetails] = useState({
    ...pageDetails,
  });
  const history = useHistory();

  const handleLoadMore = (pageNumber) => {
    const obj = { ...searchParams, eventsExpiry: 2, pageNumber };

    getAllAlumniEvents(dispatch, obj).then((res) => {
      const {
        data: { data, paging },
      } = res.data;

      const transformData = data.map((item) => {
        return {
          ...item,
          width: "100%",
        };
      });
      const updatedPastEvents = [...pastAlumniEvent, ...transformData];
      setPastAlumniEvent(updatedPastEvents);
      setPastpageDetails(paging);
    });
  };

  useEffect(() => {
    _getAllAlumniFeaturedEvent();
  }, []);

  useEffect(() => {
    _getAllAlumniEvent("upcoming");
  }, [searchParams]);
  useEffect(() => {
    _getAllPastAlumniEvent(1);
  }, [searchParams]);

  const drawerToggle = () => {
    const selectDrawer = document.querySelector(".drawer-search ");
    if (!selectDrawer.classList.contains("show-drawer")) {
      selectDrawer.classList.remove("hide-drawer");
      selectDrawer.classList.add("show-drawer");
    } else {
      selectDrawer.classList.add("hide-drawer");
      selectDrawer.classList.remove("show-drawer");
    }
  };

  const _getAllPastAlumniEvent = (pageNumber) => {
    setFetchingEvents(true);
    const obj = { ...searchParams, eventsExpiry: 2, pageNumber };

    getAllAlumniEvents(dispatch, obj).then((res) => {
      const {
        data: { data, paging },
      } = res.data;

      const transformData = data.map((item) => {
        return {
          ...item,
          width: "100%",
        };
      });

      setPastAlumniEvent(transformData);
      setPastpageDetails(paging);
    });
  };

  const _getAllAlumniFeaturedEvent = () => {
    setFetchingFeaturedEvents(true);
    getAllFeaturedAlumniEvents(dispatch, 1)
      .then((res) => {
        const {
          data: { data },
        } = res.data;
        const transformData = data.map((item) => {
          return {
            ...item,
            width: "100%",
          };
        });
        setFetchingFeaturedEvents(false);
        setIsFeaturedEvent(transformData);
      })
      .catch(() => {
        setFetchingEvents(false);
        Swal.fire({
          title: "",
          text: "Something went wrong try gain",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#da291c",
          cancelButtonColor: "#eddcd2",

          customClass: {
            icon: "swal-icon",
            cancelButton: "swal-cancel",
          },
        });
      });
  };
  const _getAllAlumniEvent = (current) => {
    setFetchingEvents(true);
    const obj = { ...searchParams };
    getAllAlumniEvents(dispatch, obj)
      .then((res) => {
        const {
          data: { data },
        } = res.data;
        const transformData = data.map((item) => {
          return {
            ...item,
            width: "100%",
          };
        });

        let isFeaturedEvent = transformData.filter(
          (item) => item.isFeatured === true
        );

        if (isFeaturedEvent.length === 0) {
          isFeaturedEvent = transformData.slice(0, 1);
        }
        setFetchingEvents(false);
        setAlumniEvent(transformData);
      })
      .catch(() => {
        setFetchingEvents(false);
        Swal.fire({
          title: "",
          text: "Something went wrong try gain",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#da291c",
          cancelButtonColor: "#eddcd2",
          customClass: {
            icon: "swal-icon",
            cancelButton: "swal-cancel",
          },
        });
      });
  };
  const selectHandler = (selectedItem, key) => {
    setSearchParams({ ...searchParams, [key]: selectedItem?.value });
  };

  const redirectToCreate = () => {
    history.push("/alumni-events/create");
  };
  const typeObj = [
    {
      value: "",
      lable: "All",
    },
    { value: "1", label: "Public" },
    { value: "2", label: "Private" },
  ];
  return (
    <Layout SideContent={<SidebarRight />}>
      <div className="add-opp-box">
        <Button
          className="float-right"
          onClick={() => history.push("/alumni-events/create")}
        >
          Create New Event
        </Button>
      </div>
      <div className="opp-home-heading opp-home-heading-full ">
        <p className="opportunity__short-desc">Alumni Event</p>
        <div id="event-search" className="search-select">
          <div id="event-search-boxes" className="search-boxes">
            <span className="search-cate opp-search-cate">Type:</span>
            <Select
              isClearable
              isSearchable
              name="color"
              placeholder="All"
              className="custom-opp-select"
              classNamePrefix="opp-select"
              options={typeObj}
              onChange={(val) => selectHandler(val, "eventsPrivacy")}
              components={{ DropdownIndicator }}
              styles={{
                menu: (base) => ({
                  ...base,
                  marginBottom: 76,
                  width: "100%",
                  border: "none",
                  borderRadius: 0,
                }),
              }}
            />
          </div>
        </div>
      </div>
      <div className="opp-home-heading-mobile">
        <p className="opportunity__mobile-desc">Alumni Event</p>

        <span className="toggle-search" onClick={drawerToggle}>
          <RiEqualizerLine size={25} />
        </span>

        <span className="mobile-add" onClick={redirectToCreate}>
          <RiAddFill color="#fff" size={15} />
        </span>
      </div>
      {fetchingFeaturedEvents ? (
        <OpportunityContentLoader />
      ) : isFeaturedEvent.length === 0 ? (
        <ArticleNotFound />
      ) : (
        <>
          <Row style={{ marginBottom: "20px" }}>
            <div className="drawer-search">
              <div className="drawer-content">
                <div className="drawer-header">
                  <span className="close-drawer" size onClick={drawerToggle}>
                    <span>
                      <MdClose color="#fff" size={35} />{" "}
                    </span>
                  </span>
                  <span className="drawer-filter">Filter</span>
                </div>
              </div>
              <div className="drawer-body">
                <div className="search-select">
                  <div className="search-boxes mr-3">
                    <span className="search-cate opp-search-cate">
                      Category:
                    </span>
                    <Select
                      isClearable
                      isSearchable
                      name="color"
                      placeholder="All"
                      className="custom-opp-select"
                      classNamePrefix="opp-select"
                      // options={}
                      components={{ DropdownIndicator }}
                      styles={{
                        menu: (base) => ({
                          ...base,
                          marginBottom: 76,
                          width: "100%",
                          border: "none",
                          borderRadius: 0,
                        }),
                      }}
                    />
                  </div>
                  <div className="search-boxes mr-3">
                    <span className="search-cate opp-search-cate">Country</span>
                    <Select
                      isClearable
                      isSearchable
                      name="color"
                      placeholder="All"
                      className="custom-opp-select"
                      classNamePrefix="opp-select"
                      components={{ DropdownIndicator }}
                      // options={}
                      styles={{
                        menu: (base) => ({
                          ...base,
                          marginBottom: 76,
                          width: "100%",
                          border: "none",
                          borderRadius: 0,
                        }),
                      }}
                    />
                  </div>
                  <div className="search-boxes mr-3">
                    <span className="search-cate opp-search-cate">Type:</span>
                    <Select
                      isClearable
                      isSearchable
                      name="color"
                      placeholder="All"
                      className="custom-opp-select"
                      classNamePrefix="opp-select"
                      components={{ DropdownIndicator }}
                      // options={categoryObj}
                      styles={{
                        menu: (base) => ({
                          ...base,
                          marginBottom: 76,
                          width: "100%",
                          border: "none",
                          borderRadius: 0,
                        }),
                      }}
                    />
                  </div>
                  <div className="search-boxes mr-3">
                    <span className="search-cate opp-search-cate">
                      Sort by:
                    </span>
                    <Select
                      isClearable
                      isSearchable
                      name="color"
                      placeholder="Latest"
                      className="custom-opp-select"
                      classNamePrefix="opp-select"
                      components={{ DropdownIndicator }}
                      // options={categoryObj}
                      styles={{
                        menu: (base) => ({
                          ...base,
                          marginBottom: 76,
                          width: "100%",
                          border: "none",
                          borderRadius: 0,
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Col>
              {isFeaturedEvent.length > 0 && (
                <figure className="alumni-event-imgbox">
                  <FeaturedEvent
                    list={isFeaturedEvent}
                    SliderContent={EventContent}
                    carouselHeight="300"
                  />
                </figure>
              )}
            </Col>
          </Row>
          <h5 className="alumni-event-imgbox__status">Upcoming Events</h5>

          <div className="alumni-event-list">
            {alumniEvent &&
              alumniEvent.length &&
              alumniEvent.map((item, idx) => (
                <EventCard alumniEvent={item} key={idx} />
              ))}
          </div>
        </>
      )}
      {fetchingEvents ? (
        <RenderContentLoader />
      ) : pastAlumniEvent.length === 0 ? (
        <ArticleNotFound />
      ) : (
        <>
          <h5 className="alumni-event-imgbox__status">Past Events</h5>

          <InfiniteScroll
            dataLength={pastAlumniEvent.length}
            next={() => handleLoadMore(pastPageDetails.pageNumber + 1)}
            hasMore={pastPageDetails.hasNextPage}
            style={{ display: "flex" }}
            loader={
              <div style={{ position: "absolute", bottom: 0, left: "50%" }}>
                <Loader color="danger" />
              </div>
            }
            endMessage={
              <p
                style={{
                  textAlign: "center",
                  position: "absolute",
                  bottom: 0,
                  left: "50%",
                }}
              >
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <div className="alumni-event-list">
              {pastAlumniEvent &&
                pastAlumniEvent.length &&
                pastAlumniEvent.map((item, idx) => (
                  <EventCard alumniEvent={item} key={idx} />
                ))}
            </div>
          </InfiniteScroll>
        </>
      )}
    </Layout>
  );
};

export default ifOnboardedThenShow(AlumniEvents);
