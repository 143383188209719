import axios from "src/utils/axios";
import { UserGroupUrl } from "src/constants";
import { GET_USER_GROUPS } from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";

export const addUserToGroup = (data) => {
  return axios({
    method: "post",
    url: UserGroupUrl.ADD_USER_TO_GROUP,
    data,
  });
};

export const getUserGroups = (dispatch) => {
  dispatch(asyncActions(GET_USER_GROUPS).loading(true));
  return axios({
    method: "get",
    url: UserGroupUrl.GET_ALL_GROUPS,
  })
    .then((response) => {
      dispatch(asyncActions(GET_USER_GROUPS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_USER_GROUPS).success(data));
        dispatch(asyncActions(GET_USER_GROUPS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_USER_GROUPS).loading(false));
      dispatch(asyncActions(GET_USER_GROUPS).failure(true, error.response));
      return error.response;
    });
};
