import countryAndRegion from "./countryList.json";
import { useRef, useEffect } from "react";
import Swal from "sweetalert2";
import i18n from "src/i18n";

const langauages = [
  { name: "English", code: "en" },
  { name: "French", code: "fr" },
  { name: "Portuguese", code: "pt" },
  { name: "Arabic", code: "ar" },
  { name: "Spanish", code: "es" },
];
export const getCountryAlpha2Code = (filter) => {
  const code = countryAndRegion.find((country) => {
    return (
      country.countryName.toLowerCase() === filter.toLowerCase() ||
      country.countryShortCode.toLowerCase() === filter.toLowerCase()
    );
  });
  return code.countryShortCode || "NG";
};

export const capitalize_Words = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getCountryFromCode = (filter) => {
  const countryName = countryAndRegion.find((country) => {
    return (
      country.countryName.toLocaleLowerCase() === filter.toLowerCase() ||
      country.countryShortCode.toLocaleLowerCase() === filter.toLowerCase()
    );
  });
  return countryName.countryName || "Nigeria";
};

export const useOuterClick = (callback) => {
  const innerRef = useRef();
  const callbackRef = useRef();

  // set current callback in ref, before second useEffect uses it
  useEffect(() => {
    // useEffect wrapper to be safe for concurrent mode
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);

    // read most recent callback and innerRef dom node from refs
    function handleClick(e) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target)
      ) {
        callbackRef.current(e);
      }
    }
  }, []); // no need for callback + innerRef dep

  return innerRef; // return ref; client can omit `useRef`
};

export const getLanguageNameOrCode = (type, filter) => {
  if (!filter) {
    return type === "name" ? "English" : "en";
  }
  const filteredItem = langauages.find((lang) => {
    return (
      lang.name.toLocaleLowerCase() === filter.toLocaleLowerCase() ||
      lang.code.toLocaleLowerCase() === filter.toLocaleLowerCase()
    );
  });

  if (!filteredItem) {
    return type === "name" ? "English" : "en";
  }

  return type === "name"
    ? filteredItem.name || "English"
    : filteredItem.code || "en";
};

export const onHandleDelete = (deleteRequest, dispatch, id) => {
  Swal.fire({
    title: "Hello, Are you sure?",
    text: "You will not be able to recover this",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
    confirmButtonColor: "red",
  }).then((result) => {
    if (result.value) {
      deleteRequest(dispatch, id).then((res) => {
        res.status === 200
          ? Swal.fire({
              title: "Deleted!",
              text: "Successfully deleted",
              icon: "success",
              confirmButtonColor: "red",
            })
          : Swal.fire({
              title: "Cancelled",
              text: "Could not delete, please try again",
              icon: "error",
              confirmButtonColor: "red",
            });
      });
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire({
        title: "Cancelled",
        text: "Delete cancelled",
        icon: "error",
        confirmButtonColor: "red",
      });
    }
  });
};

export const _truncateText = (value, maxLength) => {
  if (maxLength === null) {
    return value;
  } else {
    if (value?.length > maxLength) {
      return value.substring(0, maxLength) + " ...";
    }
    return value;
  }
};

export const updateQueryStringParameter = (uri, key, value) => {
  const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  const separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
};

export const changePasswordModal = () => {
  Swal.fire({
    title: "<strong>Security Check</strong>",
    text:
      "You are required to change your password, please check your mail for further steps",
    icon: "warning",
    iconColor: "red",
    confirmButtonText: "Ok",
    confirmButtonColor: "red",
  });
};

export const stripHtmlTags = (str) => {
  if (str === null || str === "") return false;
  else str = str.toString();
  return str.replace(/<[^>]*>/g, "");
};

export const getLanguage = () => {
  // Use languages[0] instead of language because that follows configuration
  // (supportedLngs, load). See https://www.i18next.com/overview/api#language.
  return i18n.languages[0];
};

export const stringToColour = function (str) {
  str = str || "null";
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

export const getDefaultGUID = () => {
  return "00000000-0000-0000-0000-000000000000";
};

export const _breakInChunck = (list, chunkSize = 4) => {
  const listChunk = [];
  for (let i = 0; i < list.length; i += chunkSize) {
    listChunk.push(list.slice(i, i + chunkSize));
  }
  return listChunk;
};

export function hasDecimal(num) {
  return !!(num % 1);
}
