import React, { useState, useContext, useEffect } from "react";
import classes from "./HubDetails.module.scss";
import Layout from "src/components/Layout/Layout";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { Row, Col } from "reactstrap";

import { Link, useParams } from "react-router-dom";
import HubBanner from "src/modules/Alumni/AlumniHub/component/HubBanner/index";
// import DefaultImage from "src/assets/img/bg/background_640-1.webp";
import PrimaryButton from "src/modules/Alumni/Components/PrimaryButton/index";

import RequestToJoinHubModal from "src/modules/Alumni/AlumniHub/component/RequestToJoinHub/index";
import {
  getSingleAlumniHub,
  getOtherHubs,
} from "src/requests/AlumniHubRequest";

import { Context } from "src/store";
import Loader from "react-loader-spinner";
import { getHubEventCount } from "src/modules/Alumni/AlumniHub/containers/helper/getAlumniHubEventCount";
import { getHubUsers } from "src/modules/Alumni/Chat/helper/getHubUsers";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import noUsersImage from "src/modules/Alumni/AlumniHub/assets/no-users.svg";
import bannerBackground from "src/assets/img/bg/background_1920-5.webp";
import moment from "moment";

const user = get_user_info_from_token();

let id;
if (user) {
  id = user.user.id;
}

const HubDetails = () => {
  const userData = get_user_info_from_token().user;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { hubId } = useParams();
  const { dispatch } = useContext(Context);
  const [hubDetails, setHubDetails] = useState({});
  const [isLoadingHubDetails, setIsloadingHubDetails] = useState(true);
  const [hubEventCount, setHubEventCount] = useState(null);
  const [isLoadingHubEventCount, setIsLoadingHubEventCount] = useState(true);
  const [hubUsers, setHubUsers] = useState([]);
  const [isloadingHubUsers, setIsLoadingHubUsers] = useState(true);
  const [hubs, setHubs] = useState([]);

  const _getAlumniHubEventsCount = getHubEventCount(
    dispatch,
    setHubEventCount,
    setIsLoadingHubEventCount
  );

  const _getOtherHubs = () => {
    getOtherHubs(dispatch)
      .then((response) => {
        const otherHubs = response.data.data.data.filter(
          (hub) => hub.name !== "General"
        );
        setHubs(otherHubs);
      })
      .catch((error) => console.log(error));
  };

  const _getSingleAlumnihub = () => {
    getSingleAlumniHub(dispatch, hubId)
      .then((response) => setHubDetails(response.data.data))
      .catch((fetchSingleTransactionError) =>
        console.log(fetchSingleTransactionError)
      )
      .finally(() => setIsloadingHubDetails(false));
  };

  const _getHubUsers = getHubUsers(
    dispatch,
    userData,
    setHubUsers,
    setIsLoadingHubUsers
  );

  useEffect(() => {
    _getSingleAlumnihub();
    _getAlumniHubEventsCount(hubId);
    _getHubUsers(hubId);
    _getOtherHubs();
  }, [hubId]);

  const isUserJoinedToHub = () => {
    const user = hubUsers && hubUsers.map((user) => user.userId).includes(id);

    return user;
  };

  return (
    <Layout SideContent={<SidebarRight />}>
      <Row>
        <Col>
          <HubBanner
            hubBanner={{
              bannerUrl: hubDetails?.bannerUrl ?? bannerBackground,
              state: hubDetails.state,
              name: hubDetails.name,
            }}
          />
        </Col>
      </Row>
      {isLoadingHubDetails ? (
        <div className="text-center">
          <Loader type="Oval" color="red" height={50} width={50} />
        </div>
      ) : (
        <Row className="mx-0">
          <Col md={3} className="mt-4">
            <section className="alumniHub__profile">
              <figure className="alumniHub__image-box">
                <img
                  src={hubDetails.hubLeadProfile?.imageUrl}
                  className="alumniHub__user-photo"
                />
              </figure>
              <div className="alumniHub__detail-box">
                <h5 className="user--name">{`${
                  hubDetails.hubLeadProfile?.firstName ?? ""
                } ${hubDetails.hubLeadProfile?.lastName ?? ""}`}</h5>
                <h6 className="user--position">
                  <strong>Hub Lead</strong>
                </h6>
                <h6 className="user--year">
                  Year: {moment(hubDetails.hubLeadProfile).format("YYYY")}
                </h6>
                <h6 className="user--year">
                  Contact: {hubDetails.hubLeadProfile?.email}
                </h6>

                <Link
                  to={`/profile/${hubDetails.hubLeadProfile?.userId}`}
                  className="profile--link"
                >
                  View Profile
                </Link>
                <div className="alumniHub__total">
                  <div className="alumni-count alumniHub__total--first">
                    <h6 className="hub-figure">{hubDetails.usersCount}</h6>
                    <span className="hub-name">Hub Members</span>
                  </div>
                  <div className=" alumni-count alumniHub__total--second">
                    {isLoadingHubEventCount ? (
                      <Loader type="Oval" color="red" height={20} width={20} />
                    ) : (
                      <>
                        <h6 className="hub-figure">{hubEventCount}</h6>
                        <span className="hub-name">Hub Events</span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </section>

            <div className="pt-3 d-flex justify-content-center">
              {!isUserJoinedToHub() && (
                <PrimaryButton
                  buttonText="Request to join Hub"
                  style={{ fontSize: "14px" }}
                  buttonAction={toggle}
                />
              )}
            </div>
          </Col>

          <Col md={9}>
            <div className={classes["hub-details__wrapper"]}>
              <div className={classes["hub-details__description-title"]}>
                <h5>About {hubDetails.name}</h5>
              </div>
              <div className={classes["hub-details__description-body"]}>
                <p className="text-muted">{hubDetails.description}</p>
              </div>
            </div>

            <div className="alumniHub-event mb-4">
              {isloadingHubUsers ? (
                <div className="d-flex justify-content-center py-5 text-center">
                  <Loader type="Oval" color="red" height={40} width={40} />
                </div>
              ) : (
                <>
                  <div className="alumni-box mb-2">
                    <h6 className="alumni-box__category alumniHub--list mb-1">
                      Alumni Members
                      <span className="text-muted pl-1">
                        ({hubDetails.usersCount})
                      </span>
                    </h6>
                  </div>
                  <div className="row">
                    {hubUsers.length > 0 ? (
                      hubUsers
                        .filter((user) => user.userId !== id)
                        .map((user) => (
                          <div className="col-md-4" key={user.userId}>
                            <div className="event-box user-item--list w-100">
                              <div className="media media-cd">
                                {user.user.imageUrl ? (
                                  <img
                                    className="event-box__photo hub-photo-radius"
                                    src={user.user.imageUrl}
                                    alt="user"
                                  />
                                ) : (
                                  <div className="user-no-img">
                                    <span>{`${user.user.firstName[0]}${user.user.lastName[0]}`}</span>
                                  </div>
                                )}
                              </div>
                              <div className="event-box__detail-box">
                                <h6 className="event-box__title hub-box__title">
                                  <Link to={`/profile/${user?.user?.userId}`}>
                                    {`${user.user.firstName} ${user.user.lastName}`}
                                  </Link>
                                </h6>
                                <p className="event-box__time hub-box__time">
                                  Year 2021
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <div className="d-flex align-items-center flex-column w-100 justify-conent-center my-3">
                        <img
                          src={noUsersImage}
                          width="70"
                          alt="no users image"
                        />
                        <h5 className="mt-2">
                          No users in this hub at the moment
                        </h5>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </Col>

          {/* modal to request to join a new hub */}
          <RequestToJoinHubModal
            modal={modal}
            toggle={toggle}
            hubs={hubs}
            hubId={hubId}
          />
        </Row>
      )}
    </Layout>
  );
};

export default HubDetails;
