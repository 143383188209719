import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import {
  getGoal,
  updateGoalReminder,
  rateGoal,
  updateMenteeGoalStatus,
} from "src/requests/GoalRequests";
import { getUser } from "src/requests/AuthRequest";
import { Context } from "src/store";
import Layout from "src/components/Layout/Layout";
import Discussions from "src/components/Discussions/DIscussions";
import { Col, Card, Row, Button } from "reactstrap";
import StarRatings from "react-star-ratings";
import { FaCheck } from "react-icons/fa";
import { MdLock } from "react-icons/md";
import "./GoalDetails.scss";
import ReactTooltip from "react-tooltip";
import {
  UpdateGoalStatus,
  GoalMoreItem,
  GoalUpdateCard,
} from "src/modules/mentorship/components/Goal/goalUpdateCard";
import { IoIosMore, IoMdList } from "react-icons/io";
import {
  _truncateText,
  retrieveUserInfo,
} from "src/modules/mentorship/components/helper";
import LoaderSpinner from "react-loader-spinner";
import Avatar from "src/components/Avatar/Avatar";

import ReactAvatar from "react-avatar";
import EditGoalModal from "src/modules/mentorship/components/EditGoalModal/EditGoalModal";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { CustomSwitch } from "src/components/CustomFormField/CustomFormField";

const Loader = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingTop: "50px",
      }}
    >
      <LoaderSpinner
        type="Puff"
        color="var(--tef-red)"
        height={75}
        width={75}
      />
    </div>
  );
};

export const _getStatusColor = (status) => {
  if (status) {
    status = status.toLowerCase();
    switch (status) {
      case "not started":
        return "status-color-in-progress";
      case "under review":
        return "status-color-in-review";
      case "locked":
        return "status-color-locked";
      default:
        return "status-color-completed";
    }
  }
};

const GoalDetails = (props) => {
  const user = get_user_info_from_token(null, null, true).user;
  const { state, dispatch } = useContext(Context);
  const [showGoalStatusCard, setGoalStatusCard] = useState(false);
  const [showGoalMoreCard, setShowGoalMoreCard] = useState(false);
  const [loadingStatusUpdate, setLoadingStatusUpdate] = useState(false);
  const [deleteGoalLoading, setDeleteGoalLoading] = useState(false);
  const [assignedTo, setAssignedTo] = useState({ firstName: "", lastName: "" });
  const [descriptionMaxLength, setDescriptionMaxLength] = useState(500);
  const [rating, setRating] = useState(5);
  const [modal, setModal] = useState(false);
  const [isReminderSet, setIsReminder] = useState(false);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    getGoal(dispatch, props.match.params.goalId);
  }, []);

  useEffect(() => {
    if (state.Goals.goal) {
      getUser(dispatch, state.Goals.goal.creator);
    }
  }, [state.Goals]);

  useEffect(() => {
    if (state.Goals.goal) {
      setRating(state.Goals.goal.rating);
      setIsReminder(state.Goals.goal.isReminderSet);
    }
  }, [state.Goals.goal]);

  const changeRating = (newRating) => {
    setRating(newRating);
    rateGoal(dispatch, props.match.params.goalId, newRating);
  };

  const completeStatus = () => {
    updateMenteeGoalStatus(dispatch, props.match.params.goalId);
  };

  const isMentor = () => {
    // const copyUser = user;
    // return copyUser.hasOwnProperty("tef-mentor");
    return Object.prototype.hasOwnProperty.call(user, "tef-mentor");
  };

  useEffect(() => {
    if (state.Goals.goal) {
      // retrieveUserProfile(createdBy).then((res) => {
      //   setUserImage(res.data.data.imageUrl);
      // });
      const { mentees, mentors } = state.Goals.goal;
      if (mentees === null && mentors) {
        if (mentors.includes(user.id)) {
          setAssignedTo(user);
        } else {
          retrieveUserInfo(mentors[0]).then((res) => {
            setAssignedTo(res.data.data);
          });
        }
      } else if (mentors == null && mentees) {
        if (mentees.includes(user.id)) {
          setAssignedTo(user);
        } else {
          retrieveUserInfo(mentees[0]).then((res) => {
            setAssignedTo(res.data.data);
          });
        }
      }
    }
  }, [state.Goals.goal]);

  useEffect(() => {
    setLoadingStatusUpdate(state.Goals.requestingChangeGoalStatus);
  }, [state.Goals.requestingChangeGoalStatus]);

  useEffect(() => {
    setDeleteGoalLoading(state.Goals.requestingGoalDelete);
  }, [state.Goals.requestingGoalDelete]);

  useEffect(() => {
    if (
      state.Goals.goal &&
      state.Goals.goal.status === "deleted" &&
      state.Goals.goal.id === props.match.params.goalId
    ) {
      // props.history.replace("/mentorship", {});
      // props.history.go(1);
      props.history.goBack();
    }
  }, [state.Goals.goal]);

  const _lineThrough = (status) => {
    if (status && status.toLowerCase() === "completed") {
      return "line-through";
    }
    return "";
  };

  const toggleDescriptionMaxLength = () => {
    if (descriptionMaxLength === null) {
      setDescriptionMaxLength(500);
    } else {
      setDescriptionMaxLength(null);
    }
  };

  const onReminderToggle = (e) => {
    const value = !isReminderSet;
    setIsReminder(value);
    updateGoalReminder(dispatch, state.Goals.goal.id);
  };

  return (
    <Layout hideRight>
      <Col md={9}>
        <div className="goal-details-breadcrumb">
          <h6 className="goal-details-breadcrumb__heading">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => props.history.push("/mentorship")}
            >
              Mentorship → My Board
            </span>{" "}
            → {state.Goals.goal && state.Goals.goal.title}
          </h6>
        </div>
        <Card className="goal-details-card">
          {state.Goals.goal ? (
            <Col>
              {state.Goals.goal && state.Goals.goal.isActive === false && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <span data-tip data-for="isActive">
                    <MdLock color="grey" size={30} />
                  </span>{" "}
                  <ReactTooltip id="isActive" place="top" effect="solid">
                    This goal is no longer active as there is no connection
                    between both parties. No further Actions are allowed.
                  </ReactTooltip>
                </div>
              )}

              <Row>
                <Col md={8}>
                  <h3
                    className={`goal-details-heading ${_lineThrough(
                      state.Goals.goal && state.Goals.goal.status
                    )}`}
                  >
                    {state.Goals.goal && state.Goals.goal.title}
                  </h3>
                  <p className="goal-details-su">
                    Added by{" "}
                    <span style={{ color: "var(--tef-red)" }}>
                      {user.id === state.Goals.goal.creator
                        ? "Me"
                        : state.User.user &&
                          `${state.User.user.firstName} ${state.User.user.lastName}`}
                    </span>{" "}
                    |{" "}
                    {state.Goals.goal &&
                      moment(state.Goals.goal.createdDate).fromNow()}
                  </p>
                </Col>
                <Col md={4}>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Row style={{ marginTop: "5px" }}>
                      {state.Goals.goal &&
                      state.Goals.goal.hasMenteeCompleted ? (
                        <span
                          className="goal-completion-box goal-completed"
                          onClick={completeStatus}
                        >
                          {/* <FaCheck style={{ marginRight: "5px" }} />{" "} */}
                          <span className="goal-complete-text goal-completed-text">
                            Done
                          </span>
                        </span>
                      ) : (
                        <Button
                          className="goal-completion-box"
                          onClick={completeStatus}
                        >
                          <FaCheck style={{ marginRight: "5px" }} />{" "}
                          <span className="goal-complete-text">
                            Mark as Done
                          </span>
                        </Button>
                      )}
                      {isMentor() &&
                        state.Goals.goal.status === "Completed" &&
                        state.Goals.goal &&
                        state.Goals.goal.rating === 0 && (
                          <StarRatings
                            rating={rating}
                            starRatedColor="red"
                            changeRating={changeRating}
                            starDimension={"1.5rem"}
                            name="rating"
                          />
                        )}

                      {state.Goals.goal &&
                        state.Goals.goal.status === "Completed" &&
                        state.Goals.goal.rating > 1 && (
                          <StarRatings
                            rating={rating}
                            starRatedColor="red"
                            starDimension={"1.5rem"}
                          />
                        )}
                    </Row>
                    {state.Goals.goal.creator === user.id && (
                      <IoIosMore
                        onClick={() => {
                          setGoalStatusCard(false);
                          setShowGoalMoreCard(!showGoalMoreCard);
                        }}
                        style={{ marginLeft: "15px", cursor: "pointer" }}
                      />
                    )}
                    {/* </span> */}
                    <GoalUpdateCard show={showGoalMoreCard}>
                      <GoalMoreItem
                        setShowCard={setShowGoalMoreCard}
                        toggle={toggle}
                        goalId={state.Goals.goal.id}
                        loading={deleteGoalLoading}
                      />
                    </GoalUpdateCard>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <h4 className="goal-details-sub">ASSIGNED TO</h4>
                  {/* <div className="assigned-to-container"> */}
                  {!assignedTo.imageUrl ? (
                    <ReactAvatar
                      name={`${assignedTo.firstName} ${assignedTo.lastName}`}
                      round
                      size={30}
                    />
                  ) : (
                    <Avatar
                      dimension="24px"
                      src={assignedTo.imageUrl}
                      showBadge={assignedTo && assignedTo.tefMentor}
                    />
                  )}

                  <span className="assignee">
                    {assignedTo.firstName} {assignedTo.lastName}
                  </span>
                  {/* </div> */}
                  <div className="mt-">
                    {isMentor() &&
                      state.Goals.goal &&
                      state.Goals.goal.status === "Completed" &&
                      state.Goals.goal.rating === 0 && (
                        <StarRatings
                          rating={rating}
                          starRatedColor="red"
                          changeRating={changeRating}
                          starDimension={"1.5rem"}
                          name="rating"
                        />
                      )}
                    {isMentor() &&
                      state.Goals.goal &&
                      state.Goals.goal.status === "Completed" &&
                      state.Goals.goal.rating > 1 && (
                        <StarRatings
                          rating={rating}
                          starRatedColor="red"
                          starDimension={"1.5rem"}
                        />
                      )}
                  </div>
                </Col>
                <Col md={4}>
                  <h4 className="goal-details-sub">DUE DATE</h4>
                  <p className="goal-date-due">
                    {state.Goals.goal &&
                      moment(state.Goals.goal.dueDate).format("YYYY-MM-DD")}
                  </p>
                  <p className="goal-details-sub due">
                    <span>
                      Due{" "}
                      {state.Goals.goal &&
                        moment(state.Goals.goal.dueDate).fromNow()}
                    </span>
                  </p>
                </Col>
                <Col md={4}>
                  <div style={{ width: "100%", position: "relative" }}>
                    <h4 className="goal-details-sub">STATUS</h4>
                    <span
                      className={`goal-details-status text-uppercase ${_getStatusColor(
                        state.Goals.goal && state.Goals.goal.isActive
                          ? state.Goals.goal.status
                          : "Locked"
                      )}`}
                    >
                      {state.Goals.goal && state.Goals.goal.isActive
                        ? state.Goals.goal.status
                        : "Locked"}
                    </span>{" "}
                    {isMentor() &&
                      state.Goals.goal &&
                      state.Goals.goal.isActive && (
                        <span
                          className="change-action-button"
                          onClick={() => {
                            setGoalStatusCard(!showGoalStatusCard);
                            setShowGoalMoreCard(false);
                          }}
                        >
                          change
                        </span>
                      )}
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        marginTop: "-20px",
                      }}
                    >
                      {isMentor() &&
                        state.Goals.goal &&
                        state.Goals.goal.isActive && (
                          <GoalUpdateCard show={showGoalStatusCard}>
                            <UpdateGoalStatus
                              setShowCard={() => setGoalStatusCard(false)}
                              goalId={state.Goals.goal.id}
                              loading={loadingStatusUpdate}
                            />
                          </GoalUpdateCard>
                        )}
                    </div>
                  </div>
                </Col>
              </Row>
              {state.Goals.goal.isActive && (
                <Row className="reminder-c">
                  <Col md={12}>
                    <CustomSwitch
                      label={
                        state.Goals.goal.isReminderSet
                          ? "Turn off reminder"
                          : "Turn on reminder"
                      }
                      name="reminder"
                      checked={isReminderSet}
                      value={isReminderSet}
                      onChange={onReminderToggle}
                    />
                  </Col>
                </Row>
              )}

              <hr />
              <div>
                <h5 className="goal-description">Goal Description</h5>
                <div style={{ display: "flex" }}>
                  <span style={{ marginRight: "10px" }}>
                    <IoMdList size={25} />
                  </span>
                  {state.Goals.goal &&
                    state.Goals.goal.description &&
                    state.Goals.goal.description.length && (
                      <p className="goal-paragraph">
                        {_truncateText(
                          state.Goals.goal && state.Goals.goal.description,
                          descriptionMaxLength
                        )}
                      </p>
                    )}
                </div>
                <br />
                {descriptionMaxLength !== null &&
                  state.Goals.goal &&
                  state.Goals.goal.description &&
                  state.Goals.goal.description.length > 500 && (
                    <span
                      className="show-full-desc-text"
                      onClick={toggleDescriptionMaxLength}
                    >
                      Show Full Description
                    </span>
                  )}
                {descriptionMaxLength == null && (
                  <span
                    className="show-full-desc-text"
                    onClick={toggleDescriptionMaxLength}
                  >
                    Show Less
                  </span>
                )}
              </div>
            </Col>
          ) : (
            Loader()
          )}
        </Card>
        <Card className="goal-details-card" style={{ marginTop: "18px" }}>
          <h3
            className="my-board-heading"
            style={{ marginLeft: "-13px", marginBottom: "30px" }}
          >
            Discussions{" "}
            {state.Discussions.discussions && (
              <span style={{ color: "#919ba8" }}>
                ({state.Discussions.discussions.length})
              </span>
            )}
          </h3>
          <Discussions
            isActive={
              state.Goals && state.Goals.goal && state.Goals.goal.isActive
            }
          />
          {/* <Discussions activeGoal={Number(props.match.params.goalId)} /> */}
          {state.Goals && state.Goals.goal && (
            <EditGoalModal
              modal={modal}
              toggle={toggle}
              goal={state.Goals.goal}
            />
          )}
        </Card>
      </Col>
    </Layout>
  );
};

GoalDetails.propTypes = {};

export default GoalDetails;
