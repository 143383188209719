import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import Layout from "src/components/Layout/Layout";
import SideRight from "src/modules/homepage/components/SideRight/SideRight";
import classes from "./MasterclassDetails.module.css";
import Viewers from "./components/Viewers/Viewers";
import VideoPlayer from "./components/VideoPlayer/VideoPlayer";
import DateTimeDetails from "./components/DateTimeDetails/DateTimeDetails";
import Description from "./components/Description/Description";
import MasterclassUser from "./components/User/MasterclassUser";
import MasterclassComment from "./components/Comment/MasterclassComment";
import MasterclassPageNav from "./components/MasterclassPageNav/MasterclassPageNav";
import Transcript from "./components/Transcript/Transcript";
import Files from "./components/Files/Files";
import { getMasterClass } from "src/requests/MasterClassRequest";
import { Context } from "src/store";
import {
  createArticleComment,
  getAllComments,
} from "src/requests/DiscoverRequest";
import CustomLoader from "src/modules/Discover/components/CustomLoader/CustomLoader";
import Empty from "src/modules/mentorship/Calendar/utils/Empty";
import { IoMdArrowBack } from "react-icons/io";

const MasterclassDetails = () => {
  const [detailSelected, setDetailSelected] = useState(true);
  const [transcriptSelected, setTranscriptSelected] = useState(false);
  const [masterClass, setMasterClass] = useState({});
  const [newComment, setNewComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const { dispatch } = useContext(Context);
  const params = useParams();
  const history = useHistory();
  const id = params.id;

  useEffect(() => {
    _getMasterClass();
  }, []);
  useEffect(() => {
    _getComments();
  }, [refreshPage]);

  const _getMasterClass = () => {
    getMasterClass(dispatch, params.id).then((res) => {
      if (res.status === 200) {
        const {
          data: { data },
        } = res;
        setMasterClass(data);
      }
    });
  };
  const _getComments = () => {
    getAllComments(dispatch, id, 1).then((res) => {
      console.log(res.data);
      const { data } = res.data.data;
      setComments(data);
    });
  };
  const handleNewComment = (e) => {
    e.preventDefault();
    setNewComment(e.target.value);
  };
  const handleKeyPressed = (e) => {
    if (e.keyCode === 13 && newComment.trim() !== "") {
      setLoading(true);
      const payload = {
        articleId: id,
        content: newComment,
      };
      createArticleComment(dispatch, payload).then((res) => {
        if (res.status === 200 || res.status === 201) {
          setNewComment("");
          setRefreshPage(!refreshPage);
          setLoading(false);
        }
      });
    }
  };
  const selectDetail = () => {
    setDetailSelected(true);
    setTranscriptSelected(false);
  };
  const selectTranscript = () => {
    setDetailSelected(false);
    setTranscriptSelected(true);
  };
  const selectFile = () => {
    setDetailSelected(false);
    setTranscriptSelected(false);
  };
  return (
    <Layout SideContent={<SideRight />}>
      <div
        onClick={() => history.push("/discover")}
        className="font-weight-bolder"
      >
        <span className="cursor-pointer">
          <IoMdArrowBack />
          Back
        </span>
      </div>
      <div className={classes.MasterclassDetails}>
        <VideoPlayer item={masterClass} />
        <div className={classes.MasterclassBody}>
          <div className={classes.MasterclassMainBody}>
            <MasterclassPageNav
              selectFile={selectFile}
              selectTranscript={selectTranscript}
              selectDetail={selectDetail}
              masterClass={masterClass}
            />
            {detailSelected ? (
              <>
                <Viewers masterClass={masterClass} />
                <DateTimeDetails
                  date={masterClass.date}
                  duration={masterClass.duration}
                  startTime={masterClass.startTime}
                  endTime={masterClass.endTime}
                  location={masterClass.masterClassLocation}
                />
                <Description description={masterClass.shortDescription} />
                <MasterclassUser
                  instructorFullName={masterClass.instructorFullName}
                  instructorImageUrl={masterClass.instructorImageUrl}
                  position={masterClass.position}
                />
              </>
            ) : transcriptSelected ? (
              <Transcript />
            ) : (
              <Files />
            )}
          </div>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CustomLoader />
            </div>
          ) : (
            <div className={classes.MasterclassComments}>
              <input
                placeholder="Add a public comment"
                maxLength="200"
                className={classes.CommentInput}
                value={newComment}
                onChange={(e) => handleNewComment(e)}
                onKeyDown={(e) => handleKeyPressed(e)}
              />
              {comments.length > 0 && (
                <div className={classes.CommentCount}>
                  {+comments.length.toLocaleString()} Comment(s)
                </div>
              )}
              {comments.length < 1 ? (
                <Empty message="No Comment has been added yet" />
              ) : (
                <MasterclassComment comments={comments} />
              )}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default MasterclassDetails;
