/* eslint-disable no-prototype-builtins */
import React from "react";
import styles from "./SideBar.module.scss";
// import UserSection from "./UserSection/UserSection";
// import { useCurrentUser } from "src/utils/Services/currentUser";
import Navigations from "./Navigations/Navigations";

export const isTefMentorOrMentee = (user) => {
  return user.hasOwnProperty("tef-mentee") || user.hasOwnProperty("tef-mentor");
};

export const isEntrepreneur = (user) => {
  return user.hasOwnProperty("tef-mentee");
};

const SideBar = () => {
  // const currentUser = useCurrentUser();

  return (
    <nav className={styles.SideBar}>
      {/* <UserSection currentUser={currentUser} /> */}
      <Navigations />
    </nav>
  );
};

export default SideBar;
