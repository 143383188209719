import React from "react";
import { Modal, ModalBody } from "reactstrap";
import TCmodal from "./TCmodal";
import styles from "./TCMain.module.scss";
import NotEligible from "./NotEligible";
import { useHistory } from "react-router-dom";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const TCMainIndex = ({ show, toggle, userId, redirectUrl }) => {
  const history = useHistory();
  const user = get_user_info_from_token().user;
  const programmeId = user.training ? user.training[0] : null;
  const trainingEventId = user.training ? user.training[1] : null;

  const redirectTo = () => {
    history.push(redirectUrl);
    toggle();
  };

  return (
    <Modal
      size={user.training && "lg"}
      isOpen={show}
      toggle={toggle}
      contentClassName={styles.TCMain}
      centered
      backdrop="static"
    >
      <ModalBody>
        {user.training ? (
          <TCmodal
            toggle={redirectTo}
            cancel={toggle}
            programmeId={programmeId}
            trainingEventId={trainingEventId}
            userId={userId}
          />
        ) : (
          <NotEligible cancel={toggle} />
        )}
      </ModalBody>
    </Modal>
  );
};

export default TCMainIndex;
