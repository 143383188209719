import React from "react";
import { Row, Modal, Col } from "reactstrap";
import previewClasses from "./PreviewApplication.module.scss";

const PreviewModal = ({ children, openPreviewModal, toggle }) => {
  return (
    <Modal
      isOpen={openPreviewModal}
      toggle={toggle}
      className={previewClasses.modalMain}
      size="lg"
    >
      {/* <ModalHeader>Preview info</ModalHeader> */}

      <Row>
        <Col sm={12}>{children}</Col>
      </Row>
    </Modal>
  );
};

export default PreviewModal;
