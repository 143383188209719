import React from "react";
import "./ShareInput.scss";
import Avatar from "src/components/Avatar/Avatar";
import { MdAttachFile } from "react-icons/md";
import ReactAvatar from "react-avatar";
import { useCurrentUser } from "src/utils/Services/currentUser";
import Loader from "react-loader-spinner";
import { EditorBox } from "src/components/Editor";

const ShareInput = ({
  onChange,
  onSumit,
  value,
  files,
  showAttachementBtn,
  handleFileChange,
  loading,
  placeholder,
  clearContent,
  setClearContent,
}) => {
  const _onAttachmentClick = () => {
    document.getElementById("files").click();
  };

  const userProfile = useCurrentUser();

  return (
    <form className="share-container">
      <div className="user">
        {!userProfile.imageUrl ? (
          <ReactAvatar
            name={`${userProfile.firstName} ${userProfile.lastName}`}
            round
            size={40}
          />
        ) : (
          <Avatar dimension="40px" src={userProfile.imageUrl} />
        )}
      </div>

      <EditorBox
        content={value}
        onEditorChange={onChange}
        className="share-input"
        setClearContent={setClearContent}
        clearContent={clearContent}
        placeholder={placeholder}
        // useEmoji
      />
      {/* </form> */}
      {showAttachementBtn && (
        <MdAttachFile
          size={25}
          className="attach-button"
          onClick={_onAttachmentClick}
        />
      )}

      <input
        type="file"
        id="files"
        multiple
        style={{
          opacity: 0,
          zIndex: -1,
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
        onChange={handleFileChange}
      />

      {files ? (
        <div
          // disabled={value.length === 0 && files.length === 0}
          className={`share-button ${
            value.length === 0 && files && files.length === 0
              ? "share-button-disabled"
              : ""
          }`}
          onClick={
            value.length === 0 && files && files.length === 0
              ? () => {}
              : () => onSumit()
          }
        >
          {loading ? (
            <span>
              <Loader color="white" height={15} width={15} type="Oval" />
            </span>
          ) : (
            " Post "
          )}
        </div>
      ) : (
        <div
          // disabled={value.length === 0 && files.length === 0}
          className={`share-button ${
            value.length === 0 ? "share-button-disabled" : ""
          }`}
          onClick={value.length === 0 ? () => {} : () => onSumit()}
        >
          Post
        </div>
      )}
    </form>
  );
};

ShareInput.defaultProps = {
  onAttachmentClick: () => {},
  showAttachementBtn: false,
  // files: [],
  loading: false,
  placeholder: "What do you think?",
  value: "",
  files: [],
  onSumit: () => {},
  onChange: () => {},
};

// ShareInput.propTypes = {};

export default ShareInput;
