import React from "react";

import FormInput from "src/modules/Tef-Dms/components/Form/FormInput";

const FinancialPlan = ({ financialPlan, setFinancialPlan }) => {
  const handleChange = (e) => {
    setFinancialPlan({
      ...financialPlan,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <React.Fragment>
      <h6 className="font-weight-bold mb-3">Financial Plan</h6>
      <div className="pr-2">
        <FormInput
          label="Revenue Model :"
          getFormInputValue={(e) => handleChange(e)}
          value={financialPlan.revenueModel}
          name="revenueModel"
          placeholder=""
        />

        <FormInput
          label="Product Prices:"
          getFormInputValue={(e) => handleChange(e)}
          value={financialPlan.productPrices}
          name="productPrices"
          placeholder=""
        />
      </div>
    </React.Fragment>
  );
};

export default FinancialPlan;
