/* eslint-disable no-unused-vars */
import React, { Fragment, useContext } from "react";
import Avatar from "src/components/Avatar/Avatar";
import classes from "./NotificationBody.module.css";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { readNotification } from "src/requests/NotificationRequest";
import { Context } from "src/store";

const NotificationBody = ({ notifications }) => {
  const { dispatch } = useContext(Context);
  const history = useHistory();

  const handleNotificationDetails = (url, id) => {
    readNotification(dispatch, id).then((res) => {
      if (res.status === 200) {
        history.push(url);
      }
    });
  };

  return (
    <Fragment>
      {notifications &&
        notifications.map((notification) => (
          <div
            className={`${classes.NotificationBody} ${
              notification.isRead ? "" : classes.IsUnread
            }`}
            key={notification.id}
            onClick={() =>
              handleNotificationDetails(notification.url, notification.id)
            }
          >
            <div className={classes.Avatar}>
              <Avatar
                name={
                  `${notification.createdForFirstName} ${notification.createdForLastName}` ||
                  "Unknown User"
                }
                dimension={30}
                src={notification.createdForImageURL}
              />
            </div>
            <div className={classes.Message}>
              <div className={classes.Notify}>{notification.message}</div>
              <div className={classes.Who}>
                {notification.createdForFirstName}{" "}
                {notification.createdForLastName}
              </div>
              <div className={classes.When}>
                {moment(notification.createdDate).fromNow()}
              </div>
            </div>
          </div>
        ))}
    </Fragment>
  );
};

export default NotificationBody;
