import React from "react";
import Layout from "src/modules/Application/components/Layout/Layout";
import ExperiencePage from "./experience";
// import Layout from "src/modules/Application/Layout/Layout";

const Experience = (props) => {
  return (
    <div>
      {/* <CLoader loading additonalInfo="Getting your application details" /> */}
      <Layout>
        <ExperiencePage {...props} />
      </Layout>
    </div>
  );
};

export default Experience;
