import React from "react";
import FormInput from "./FormInput";
import FormSelectOption from "./FormSelectOption";
import { Col, Card, Row } from "reactstrap";
import FormTextArea from "./FormTextArea";
import { useCurrentUser } from "src/utils/Services/currentUser";
import countries from "src/utils/countryList.json";

const BankDetailsForm = ({
  getFormInputValue,
  bankName,
  beneficiaryAccountName,
  accountType,
  bankAddress,
  beneficiaryAccountNo,
  beneficiaryAddress,
  routingNumber,
  abaNumber,
  sortCode,
  intermediaryBankAddress,
  swiftCode,
  ibanNumber,
  intermediaryBankName,
  intermediaryBankSwiftCode,
  country,
  beneficiaryCountry,
  additionalInfo,
  intermediaryBankAccountNo,
}) => {
  const { tefId } = useCurrentUser();
  const countryList = countries.map((country) => {
    return {
      title: country.countryName,
      value: country.countryShortCode,
    };
  });
  return (
    <Card>
      <Row className="pl-3 pr-3 pt-3">
        <Col md={6} sm={12}>
          <FormInput
            name="TEF ID"
            label="TEF ID"
            value={(tefId && tefId) || null}
            disabled
          />
          <FormInput
            name="bankName"
            label="Bank Name"
            status=" - Required"
            placeholder="e.g. Bank Name"
            getFormInputValue={getFormInputValue}
            value={bankName}
            required={true}
          />
          <FormInput
            name="beneficiaryAccountName"
            label="Beneficiary account name"
            status=" - Required"
            placeholder="e.g Business account name"
            getFormInputValue={getFormInputValue}
            value={beneficiaryAccountName}
            required={true}
          />
          <FormInput
            name="accountType"
            label="Account type"
            status=" - Required"
            placeholder="e.g Savings"
            getFormInputValue={getFormInputValue}
            value={accountType}
            required={true}
          />
        </Col>
        <Col md={6} sm={12}>
          <FormTextArea
            name="bankAddress"
            label="Bank Address"
            status=" - Required"
            placeholder="e.g. 22A, off washington street.."
            getFormInputValue={getFormInputValue}
            value={bankAddress}
            required={true}
          />
          <div className="mt-4">
            <FormInput
              name="beneficiaryAccountNo"
              label="Beneficiary account number"
              status=" - Required"
              getFormInputValue={getFormInputValue}
              value={beneficiaryAccountNo}
              required={true}
            />
          </div>
          <FormSelectOption
            name="BeneficiaryCountry"
            label="Beneficiary Businness Country"
            status=" - Required"
            placeholder="Nigeria"
            getFormInputValue={getFormInputValue}
            value={beneficiaryCountry}
            required={true}
            options={countryList}
          />
        </Col>
      </Row>
      <Row className="pl-3 pr-3">
        <Col md={12}>
          <FormTextArea
            name="beneficiaryAddress"
            label="Beneficiary address"
            status=" - Required"
            placeholder=""
            getFormInputValue={getFormInputValue}
            value={beneficiaryAddress}
            required={true}
          />
        </Col>
      </Row>
      <Row className="pl-3 pr-3">
        <Col md={6}>
          <FormInput
            name="routingNumber"
            label="Routing number (for US bound transactions)"
            status=""
            placeholder=""
            getFormInputValue={getFormInputValue}
            value={routingNumber}
          />
          <FormInput
            name="abaNumber"
            label="ABA number"
            status=""
            placeholder=""
            getFormInputValue={getFormInputValue}
            value={abaNumber}
          />
          <FormInput
            name="sortCode"
            label="Sort Code (for UK bound transactions)"
            status=""
            placeholder=""
            getFormInputValue={getFormInputValue}
            value={sortCode}
          />

          <FormInput
            name="intermediaryBankAccountNo"
            label="Intermediary Bank Account Number"
            status=""
            placeholder=""
            getFormInputValue={getFormInputValue}
            value={intermediaryBankAccountNo}
          />

          <FormInput
            name="intermediaryBankAddress"
            label="Intermediary Bank Address"
            status=""
            placeholder=""
            getFormInputValue={getFormInputValue}
            value={intermediaryBankAddress}
          />
        </Col>
        <Col md={6}>
          <FormInput
            name="swiftCode"
            label="Swift Code"
            status=""
            placeholder=""
            getFormInputValue={getFormInputValue}
            value={swiftCode}
          />
          <FormInput
            name="ibanNumber"
            label="IBAN number"
            status=""
            placeholder=""
            getFormInputValue={getFormInputValue}
            value={ibanNumber}
          />
          <FormInput
            name="intermediaryBankName"
            label="Intermediary Bank name"
            status=""
            placeholder=""
            getFormInputValue={getFormInputValue}
            value={intermediaryBankName}
          />

          <FormInput
            name="intermediaryBankSwiftCode"
            label="Intermediary Bank swift code"
            status=""
            placeholder=""
            getFormInputValue={getFormInputValue}
            value={intermediaryBankSwiftCode}
          />
        </Col>
      </Row>
      <Row className="pl-3 pr-3">
        <Col md={12}>
          <FormInput
            name="additionalInfo"
            label="Any other information that may be necessary"
            status=""
            placeholder="e.g. your bank’s Minimum Transferable Fund, Sender’ Compliance Policy, etc."
            getFormInputValue={getFormInputValue}
            value={additionalInfo}
          />
        </Col>
      </Row>
      {/* <Row className="pl-3 pr-3">
        <Col md={12}>
          <FormInput
            name="country"
            label="Country of Residence"
            status=""
            placeholder=""
            getFormInputValue={getFormInputValue}
            value={country}
          />
        </Col>
      </Row> */}
    </Card>
  );
};

export default BankDetailsForm;
