import React from "react";

const calendarLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-312 -601) translate(280 515) translate(32 86)">
              <rect width="64" height="64" fill="#485C7A" rx="8" />
              <g opacity=".8">
                <path
                  d="M0.724 0H42.724V42H0.724z"
                  transform="translate(11 11)"
                />
                <path
                  stroke="#FFF"
                  strokeWidth="1.5"
                  d="M8.724 34h26c.552 0 1-.448 1-1V10c0-.552-.448-1-1-1h-26c-.552 0-1 .448-1 1v23c0 .552.448 1 1 1zM26.724 26.5L29.724 26.5M19.724 26.5L22.724 26.5M12.724 26.5L15.724 26.5M26.724 19.5L29.724 19.5M12.724 19.5L15.724 19.5M19.724 19.5L22.724 19.5"
                  transform="translate(11 11)"
                />
                <path
                  stroke="#FFF"
                  strokeLinecap="square"
                  strokeWidth="1.5"
                  d="M14.224 6L14.224 7M27.224 6L27.224 7"
                  transform="translate(11 11)"
                />
                <path
                  stroke="#FFF"
                  strokeWidth="1.5"
                  d="M7.724 13.5L35.724 13.5"
                  transform="translate(11 11)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default calendarLogo;
