import { useEffect, useState, useContext } from "react";
import jwt_decode from "src/utils/jwt-decode";
import jwt from "jsonwebtoken";
import { Context } from "src/store";
import {
  getUserProfile,
  getUserProfileFull,
} from "src/requests/ProfileRequest";
import { get_user_info_from_token } from "../someTokenMenthods";
const secret = process.env.REACT_APP_JWT_SECRET;

const setLocalStorageCurrentUser = (data) => {
  const encoded = jwt.sign(data, secret);
  localStorage.setItem("current_user", JSON.stringify(encoded));
};

export const setCurrentUser = (data) => {
  setLocalStorageCurrentUser(data);

  setTimeout(() => {
    document.dispatchEvent(new Event("profile_updated"));
  }, 500);
};

const getCurrentUser = () => {
  return jwt_decode(JSON.parse(localStorage.getItem("current_user")));
};

export const useCurrentUser = () => {
  const { dispatch } = useContext(Context);

  /* This part is a workaround to prevent the app breaking on not having `current_user`.
     A better fix would be to fully understand how and when `current_user` is created.  */
  if (!localStorage.getItem("current_user")) {
    const { user } = get_user_info_from_token();
    setLocalStorageCurrentUser(user);
  }
  const currentUser = getCurrentUser();
  useEffect(() => {
    if (!currentUser.userInterests) {
      getUserProfileFull(dispatch, currentUser.id).then((response) => {
        const data = response?.data?.data;
        data && setCurrentUser(data);
      });
    }
  }, []);
  /* End of workaround */

  const [currentUserState, setCurrentUserState] = useState(getCurrentUser());

  const retrieveUser = () => {
    setCurrentUserState(getCurrentUser());
  };

  useEffect(() => {
    document.addEventListener("profile_updated", retrieveUser);
    return () => document.removeEventListener("profile_updated", retrieveUser);
  }, []);

  return currentUserState;
};

export const useUserProfile = ({ id }) => {
  const [user, setUser] = useState(null);
  const { dispatch } = useContext(Context);

  useEffect(() => {
    getUserProfile(dispatch, id).then((res) => {
      setUser(res.data.data);
    });
  }, [id]);
  return user;
};
