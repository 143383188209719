/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useContext } from "react";

import classes from "./tefdms.module.css";
import Layout from "src/components/Layout/Layout";
import { Col, Row } from "reactstrap";
import SectionNav from "./components/SectionNav/SectionNav";
import NavContent from "./components/NavContent/NavContent";
import BusinessPlanRequiredDocuments from "./components/Lists/BusinessPlanRequiredDocuments";
import SeedCapitalRequiredDocuments from "./components/Lists/SeedCapitalRequiredDocuments";
import DueDilligenceRequiredDocuments from "./components/Lists/DueDilligenceRequiredDocuments";
import "../../components/TabNavs/PageNav.scss";
import Details from "./components/Details";
import Downloads from "./components/Lists/Downloads";
import WelcomeTab from "./components/WelcomeTab/WelcomeTab";
import { Context } from "src/store";
import jwt_decode from "src/utils/jwt-decode";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {
  getDuelligenceDocuments,
  getSeedCapitalDocuments,
  submitDueDilligenceDocuments,
  submitSeedCapitalDocuments,
  getDueDilligenceRequiredDocuments,
  getBusinessplansRequiredDocuments,
  getSeedCapitalRequiredDocuments,
  submitBusinessPlansDocuments,
  saveSeedCapitalDocumentsForLater,
  saveDueDilligenceDocumentsForLater,
  submitBusinessPlansForLater,
  getBusinessPlanDocuments,
} from "src/requests/DmsRequest";
import { UploadFile } from "src/requests/fileUploadRequest";
import Swal from "sweetalert2";
import Empty from "./components/Empty";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

function TefDMS() {
  const [activeTab, setActiveTab] = useState("1");
  const [isUseBuilder, setIsUseBuilder] = useState(false);
  const [loader, setLoader] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [dueDilligenceTypeId, setDocumentType] = useState("");
  const [seedCapitalTypeId, setSeedCapitalType] = useState("");
  const [businesPlansTypeId, setBusinessPlansType] = useState("");

  const user = get_user_info_from_token().user;

  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    _getDueDilligenceDocuments();
    _getSeedCapitalDocuments();
    _getBusinessPlansDocuments();
    _getStagedRequiredDocuments();
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleBusinessPlanType = () => {
    setIsUseBuilder((prev) => !prev);
  };

  const handleUpload = (file) => {
    setUploadLoader(true);
    const newFile = [];
    newFile.push(file);
    UploadFile(dispatch, true, newFile)
      .then((response) => {
        if (response.status === 200) {
          setUploadLoader(false);
          const fileUrl = response.files[0];
          if (activeTab === "1") {
            saveDueDilligenceDocuments(file.name, fileUrl);
          } else if (activeTab === "2") {
            saveBusinessPlansDocumentForLater(file.name, fileUrl);
          } else if (activeTab === "3") {
            submitSeedCapitalDocumentForLater(file.name, fileUrl);
          }
        }
      })
      .catch((error) => {
        setUploadLoader(false);
        toast("Failed to Upload. Please try again later");
        return error;
      });
  };

  const handleDocumentSelect = (e) => {
    return setDocumentType(e.target.value);
  };

  const handleSeedCapitalDocumentSelect = (e) => {
    return setSeedCapitalType(e.target.value);
  };

  const handleBusinessPlansDocumentSelect = (e) => {
    return setBusinessPlansType(e.target.value);
  };

  const submitDueDilligenceDocumentForReview = () => {
    setLoader(true);
    // const tkn = localStorage.getItem("data");
    // const token = JSON.parse(tkn).token;
    // const { dms_user } = jwt_decode(token);
    const programId = user.dms_user[0];
    const programEventId = user.dms_user[1];

    const payload = {
      documentUrl:
        state.dms &&
        state.dms.dueDilligence.dueDilligenceDocuments[2]?.documentUrl,
      title:
        state.dms && state.dms.dueDilligence.dueDilligenceDocuments[2]?.title,
      stageRequiredDocumentId:
        state.dms && state.dms.dueDilligenceRequiredDocuments[0]?.id,
      programId: programId,
      programEventId: programEventId,
    };

    submitDueDilligenceDocuments(payload)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setLoader(false);
          setModal((prev) => !prev);
          _getDueDilligenceDocuments();
          toast("Documents submitted successfully!! ✅");
        }
      })
      .catch(() => {
        setLoader(false);
        toast("Documents failed to submit!!");
      });
  };

  const saveDueDilligenceDocuments = (fileName, fileUrl) => {
    // const tkn = localStorage.getItem("data");
    // const token = JSON.parse(tkn).token;
    // const { dms_user } = jwt_decode(token);
    const programId = user.dms_user[0];
    const programEventId = user.dms_user[1];

    const payload = {
      documentUrl: fileUrl,
      title: fileName,
      stageRequiredDocumentId: dueDilligenceTypeId,
      programId: programId,
      programEventId: programEventId,
    };

    saveDueDilligenceDocumentsForLater(payload)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          _getDueDilligenceDocuments();
          toast("Documents saved successfully!! ✅");
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });

        return error;
      });
  };

  const submitBusinessPlansDocumentForReview = () => {
    setLoader(true);
    const tkn = localStorage.getItem("data");
    const token = JSON.parse(tkn).token;
    const { dms_user } = jwt_decode(token);
    const programId = dms_user[0];
    const programEventId = dms_user[1];

    const payload = {
      documentUrl:
        state.dms &&
        state.dms.businessPlan?.documents[0]?.documents[1]?.documentUrl,
      title:
        state.dms && state.dms.businessPlan?.documents[0]?.documents[1]?.title,
      stageRequiredDocumentId:
        state.dms && state.dms.businessPlansRequiredDocuments[0]?.id,
      programId: programId,
      programEventId: programEventId,
    };

    submitBusinessPlansDocuments(payload)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setLoader(false);
          _getBusinessPlansDocuments();
          toast("Documents submitted successfully!! ✅");
        }
      })
      .catch((error) => {
        setLoader(false);
        toast("Documents failed to submit!!");

        return error;
      });
  };

  const saveBusinessPlansDocumentForLater = (fileName, fileUrl) => {
    const tkn = localStorage.getItem("data");
    const token = JSON.parse(tkn).token;
    const { dms_user } = jwt_decode(token);
    const programId = dms_user[0];
    const programEventId = dms_user[1];

    const payload = {
      documentUrl: fileUrl,
      title: fileName,
      stageRequiredDocumentId: businesPlansTypeId,
      programId: programId,
      programEventId: programEventId,
    };

    submitBusinessPlansForLater(payload)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          _getBusinessPlansDocuments();
          toast("Document uploaded successfully ✅");
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.data,
          confirmButtonColor: "#da291c",
        });

        return error;
      });
  };

  const submitSeedCapitalDocumentForReview = () => {
    const tkn = localStorage.getItem("data");
    const token = JSON.parse(tkn).token;
    const { dms_user } = jwt_decode(token);
    const programId = dms_user[0];
    const programEventId = dms_user[1];

    setLoader(true);
    const payload = {
      documentUrl:
        state.dms &&
        state.dms.seedCapital?.seedCapitalDocuments[
          state.dms.seedCapital?.seedCapitalDocuments.length - 1
        ]?.documentUrl,
      title:
        state.dms &&
        state.dms.seedCapital?.seedCapitalDocuments[
          state.dms.seedCapital?.seedCapitalDocuments.length - 1
        ]?.title,
      stageRequiredDocumentId:
        state.dms && state.dms.seedCapitalRequiredDocuments[0]?.id,
      programId: programId,
      programEventId: programEventId,
    };

    submitSeedCapitalDocuments(payload)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setLoader(false);
          setModal((prev) => !prev);
          _getSeedCapitalDocuments();
          toast("Documents submitted successfully!! ✅");
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        toast("Documents failed to submit!!");
      });
  };

  const submitSeedCapitalDocumentForLater = (fileName, fileUrl) => {
    const tkn = localStorage.getItem("data");
    const token = JSON.parse(tkn).token;
    const { dms_user } = jwt_decode(token);
    const programId = dms_user[0];
    const programEventId = dms_user[1];

    const payload = {
      documentUrl: fileUrl,
      title: fileName,
      stageRequiredDocumentId: seedCapitalTypeId,
      programId: programId,
      programEventId: programEventId,
    };

    saveSeedCapitalDocumentsForLater(payload)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setModal((prev) => !prev);
          _getSeedCapitalDocuments();
          toast("Document uploaded successfully ✅");
        }

        return response;
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.status,
          confirmButtonColor: "#da291c",
        });

        return error;
      });
  };

  const _getDueDilligenceDocuments = () => {
    const tkn = localStorage.getItem("data");
    const token = JSON.parse(tkn).token;
    const { dms_user } = jwt_decode(token);
    const programId = dms_user[0];

    return getDuelligenceDocuments(dispatch, programId);
  };

  const _getSeedCapitalDocuments = () => {
    const tkn = localStorage.getItem("data");
    const token = JSON.parse(tkn).token;
    const { dms_user } = jwt_decode(token);
    const programId = dms_user[0];

    return getSeedCapitalDocuments(dispatch, programId);
  };

  const _getBusinessPlansDocuments = () => {
    const tkn = localStorage.getItem("data");
    const token = JSON.parse(tkn).token;
    const { dms_user } = jwt_decode(token);
    const programId = dms_user[0];

    return getBusinessPlanDocuments(dispatch, programId);
  };

  const _getStagedRequiredDocuments = () => {
    getDueDilligenceRequiredDocuments(dispatch, "DueDilligence");
    getBusinessplansRequiredDocuments(dispatch, "BusinessPlan");
    getSeedCapitalRequiredDocuments(dispatch, "SeedCapital");
  };

  return (
    <Layout withoutRIghtSideBar hideRight>
      <Row id="dms-container" className={classes.dmscontainer}>
        <Col md={3} className={classes.dmscolone}>
          <WelcomeTab activeTab={activeTab} />
        </Col>
        {(activeTab === "2" && isUseBuilder === true) || activeTab === "5" ? (
          <Col md={9} className={classes.dmscoltwo}>
            <SectionNav toggle={toggle} activeTab={activeTab} />
            <NavContent
              activeTab={activeTab}
              handleBuilder={toggleBusinessPlanType}
              isUseBuilder={isUseBuilder}
              handleDocumentSelect={handleDocumentSelect}
              handleSaveSeedCapitalForLater={submitSeedCapitalDocumentForLater}
              handleSaveDueDilligenceForLater={saveDueDilligenceDocuments}
              handleSaveBusinessPlanForLater={saveBusinessPlansDocumentForLater}
              submitDueDilligenceDocumentForReview={
                submitDueDilligenceDocumentForReview
              }
              submitBusinessPlansDocumentsForReview={
                submitBusinessPlansDocumentForReview
              }
              handleUpload={handleUpload}
              modal={modal}
              loader={loader}
              uploadLoader={uploadLoader}
              handleBusinessPlanSelect={handleBusinessPlansDocumentSelect}
              handleSeedCapitalDocumentSelect={handleSeedCapitalDocumentSelect}
              submitSeedCapitalDocumentForReview={
                submitSeedCapitalDocumentForReview
              }
            />
          </Col>
        ) : (
          <Col md={6}>
            <SectionNav toggle={toggle} activeTab={activeTab} />
            <NavContent
              activeTab={activeTab}
              handleBuilder={toggleBusinessPlanType}
              isUseBuilder={isUseBuilder}
              handleDocumentSelect={handleDocumentSelect}
              handleSaveSeedCapitalForLater={submitSeedCapitalDocumentForLater}
              handleSaveDueDilligenceForLater={
                saveDueDilligenceDocumentsForLater
              }
              handleSaveBusinessPlanForLater={saveBusinessPlansDocumentForLater}
              submitDueDilligenceDocumentForReview={
                submitDueDilligenceDocumentForReview
              }
              handleUpload={handleUpload}
              modal={modal}
              loader={loader}
              uploadLoader={uploadLoader}
              handleBusinessPlanSelect={handleBusinessPlansDocumentSelect}
              handleSeedCapitalDocumentSelect={handleSeedCapitalDocumentSelect}
              submitSeedCapitalDocumentForReview={
                submitSeedCapitalDocumentForReview
              }
              submitBusinessPlansDocumentsForReview={
                submitBusinessPlansDocumentForReview
              }
            />
          </Col>
        )}
        <Col
          md={3}
          className={classes.dmscolone}
          style={
            (activeTab === "2" && isUseBuilder === true) || activeTab === "5"
              ? { display: "none" }
              : null
          }
        >
          <div className="mb-5 mt-3">
            <Details title="Stage Required Documents" />
            {activeTab === "3" &&
              state.dms &&
              state.dms.seedCapitalRequiredDocuments.map((document) => (
                <SeedCapitalRequiredDocuments
                  document={document}
                  key={document.id}
                />
              ))}
            {activeTab === "1" &&
              state.dms &&
              state.dms.dueDilligenceRequiredDocuments.map((document) => (
                <DueDilligenceRequiredDocuments
                  document={document}
                  key={document.id}
                />
              ))}
            {activeTab === "2" &&
              state.dms &&
              state.dms.businessPlansRequiredDocuments.map((document) => (
                <BusinessPlanRequiredDocuments
                  document={document}
                  key={document.id}
                />
              ))}
            {activeTab === "4" && (
              <Empty message="No Document required for this stage" />
            )}
          </div>
          <div className="mb-5 mt-3">
            <Details title="Templates" />
            <Downloads activeTab={activeTab} />
            {activeTab === "4" && (
              <Empty message="No Download available for this stage" />
            )}
          </div>
          <div className="mb-5 mt-3">
            <Details title="Eligibity Status" />
            {/* <Card
              style={{ backgroundColor: "#F1F1F1", outlineColor: "#F1F1F1" }}
              className="p-2 border-0"
            >
              <p>
                <strong>Qualified to the next stage</strong>
              </p>
              <p>
                You are now qualified to move to the next stage of the program.
                Provide all nececcessary details and submit on time.{" "}
              </p>
            </Card> */}
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default TefDMS;
