import React from "react";
import { FaSearch } from "react-icons/fa";
import "./SearchBar.scss";

const SearchBar = ({
  searchData,
  setSearchData,
  style,
  placeholder,
  onSearchButtonClick,
}) => {
  // const [searchData, setSearchData] = useState('');
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log(searchData);
  // }

  return (
    <form className="search-container">
      <input
        type="text"
        placeholder={placeholder}
        style={style}
        name="search"
        className="search-community"
        onChange={(e) => setSearchData(e.target.value)}
      />
      <button
        type="button"
        className="search-community-icon"
        onClick={onSearchButtonClick}
      >
        <FaSearch />
      </button>
    </form>
  );
};
SearchBar.defaultProps = {
  placeholder: "Search",
  onSearchButtonClick: () => {},
};

export default SearchBar;
