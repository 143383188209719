import React from "react";
import { Col } from "reactstrap";
import { AiOutlineDownload } from "react-icons/ai";
import { RiHeart3Line, RiHeart3Fill } from "react-icons/ri";
import "./index.scss";

const BookCard = ({
  md,
  title,
  desc,
  author,
  coverImg,
  onDownload,
  onLike,
  isLiked,
}) => {
  return (
    <Col md={md || 0} className="pl-2 pr-2 mt-5">
      <div className="book-card-container">
        <div className="d-flex">
          <div
            className="img-container"
            style={{ backgroundImage: `url(${coverImg})` }}
          >
            <button className="btn" onClick={onLike}>
              {!isLiked ? (
                <RiHeart3Line color="fff" style={{ fontSize: 15 }} />
              ) : (
                <RiHeart3Fill color={"da291c"} style={{ fontSize: 15 }} />
              )}
            </button>
          </div>
          <div className="content-container" style={{ width: "100%" }}>
            <div
              className={
                desc
                  ? "content-container__title"
                  : "content-container__title __only"
              }
            >
              <h4>{title}</h4>
              <span>By {author}</span>
            </div>

            {desc && (
              <div className="content-container__description">
                <h6>{desc}</h6>
              </div>
            )}
            <div>
              <button
                className="btn content-container__btn"
                onClick={onDownload}
              >
                <AiOutlineDownload fontSize={15} /> Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default BookCard;
