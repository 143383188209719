import React from "react";
import { RiFileDownloadLine } from "react-icons/ri";
import { IoMdAttach } from "react-icons/io";
import classes from "./Files.module.css";
import Empty from "src/modules/mentorship/Calendar/utils/Empty";

const Files = ({ resources }) => {
  // const files = [
  //   {
  //     fileName: "Guide Animation",
  //     fileAttachment: "guide_animation.pdf",
  //     size: "16kb",
  //   },
  //   {
  //     fileName: "Guide Animation",
  //     fileAttachment: "guide_animation.pdf",
  //     size: "16kb",
  //   },
  //   {
  //     fileName: "Guide Animation",
  //     fileAttachment: "guide_animation.pdf",
  //     size: "16kb",
  //   },
  // ];
  return (
    <div>
      {resources ? (
        resources.map((file, index) => (
          <div className={classes.Files} key={index}>
            <div>
              {index + 1}. {file.fileName}
            </div>
            <div className={classes.AttachmentRow}>
              {" "}
              <IoMdAttach /> {file.fileAttachment}
            </div>
            <div className={classes.DownloadRow}>
              <div>{file.size}</div>
              <div className={classes.DownloadIcon}>
                <RiFileDownloadLine />
                <div>Download</div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>
          <Empty message="No resources uploaded for this masterclasses" />
        </div>
      )}
    </div>
  );
};

export default Files;
