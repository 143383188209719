import * as types from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import { OnboardingURL } from "src/constants";
import axios from "src/utils/axios";

export const getOrganizations = (dispatch) => {
  dispatch(asyncActions(types.GET_ORGANIZATIONS).loading(true));
  return axios({
    method: "get",
    url: OnboardingURL.GET_ORGANIZATIONS,
  })
    .then((response) => {
      dispatch(asyncActions(types.GET_ORGANIZATIONS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(types.GET_ORGANIZATIONS).success(data));
        dispatch(asyncActions(types.GET_ORGANIZATIONS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(types.GET_ORGANIZATIONS).loading(true));
      dispatch(asyncActions(types.GET_ORGANIZATIONS).success([]));
      dispatch(
        asyncActions(types.GET_ORGANIZATIONS).failure(true, error.response)
      );
      // return error.response;
      return { status: 504, data: null };
    });
};

export const getSkills = (dispatch) => {
  dispatch(asyncActions(types.GET_SKILLS).loading(true));
  return axios({
    method: "get",
    url: OnboardingURL.GET_SKILLS,
  })
    .then((response) => {
      dispatch(asyncActions(types.GET_SKILLS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(types.GET_SKILLS).success(data));
        dispatch(asyncActions(types.GET_SKILLS).loading(false));
        return { data, status: response.status };
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(types.GET_SKILLS).loading(true));
      dispatch(asyncActions(types.GET_SKILLS).success([]));
      dispatch(asyncActions(types.GET_SKILLS).failure(true, error.response));
      return { status: 504, data: null };
    });
};

export const getMentorshipAreas = (dispatch) => {
  dispatch(asyncActions(types.GET_MENTORSHIP_AREAS).loading(true));
  return axios({
    method: "get",
    url: OnboardingURL.GET_MENTORSHIP_AREAS,
  })
    .then((response) => {
      dispatch(asyncActions(types.GET_MENTORSHIP_AREAS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(types.GET_MENTORSHIP_AREAS).success(data));
        dispatch(asyncActions(types.GET_MENTORSHIP_AREAS).loading(false));
        return { data, status: response.status };
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(types.GET_MENTORSHIP_AREAS).success([]));
      dispatch(asyncActions(types.GET_MENTORSHIP_AREAS).loading(true));
      dispatch(
        asyncActions(types.GET_MENTORSHIP_AREAS).failure(true, error.response)
      );
      // return error.response;
      return { status: 504, data: null };
    });
};

export const getInterests = (dispatch) => {
  dispatch(asyncActions(types.GET_INTERESTS).loading(true));
  return axios({
    method: "get",
    url: OnboardingURL.GET_INTERESTS,
  })
    .then((response) => {
      dispatch(asyncActions(types.GET_INTERESTS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(types.GET_INTERESTS).success(data));
        dispatch(asyncActions(types.GET_INTERESTS).loading(false));
        return { data, status: response.status };
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(types.GET_INTERESTS).success([]));
      dispatch(asyncActions(types.GET_INTERESTS).loading(true));
      dispatch(asyncActions(types.GET_INTERESTS).failure(true, error.response));
      // return error.response;
      return { status: 504, data: null };
    });
};

export const submitOnboardingData = (dispatch, data, history) => {
  dispatch(asyncActions(types.SUBMIT_ONBOARDING_DATA).loading(true));
  return axios({
    method: "post",
    url: OnboardingURL.SUBMIT_ONBOARDING_DATA,
    data: data,
  })
    .then((response) => {
      dispatch(asyncActions(types.SUBMIT_ONBOARDING_DATA).loading(false));
      if (response.status === 201) {
        const { data } = response.data;
        dispatch(asyncActions(types.SUBMIT_ONBOARDING_DATA).success(data));
        dispatch(asyncActions(types.SUBMIT_ONBOARDING_DATA).loading(false));
        return { data, status: response.status };
        // history.push("/onboarding/success");
      }
      return response;
    })
    .catch((error) => {
      console.log(error.response);
      dispatch(asyncActions(types.SUBMIT_ONBOARDING_DATA).success([]));
      dispatch(asyncActions(types.SUBMIT_ONBOARDING_DATA).loading(true));
      dispatch(
        asyncActions(types.SUBMIT_ONBOARDING_DATA).failure(true, error.response)
      );
      return error.response;
      // return { status: 504, data: null };
    });
};
