import { env } from "./env";

const POSTS_BASE_URL = process.env.REACT_APP_POSTS_BASE_URL;
const MENTORSHIP_URL = process.env.REACT_APP_MENTORSHIP_BASE_URL;
const APPLICATION_URL = process.env.REACT_APP_APPLICATION_BASE_URL;
const FILE_UPLOAD_BASE_URL = process.env.REACT_APP_FILE_UPLOAD_BASE_URL;
const OPPORTUNITY_BASE_URL = process.env.REACT_APP_OPPORTUNITY_BASE_URL;
const ALUMNI_BASE_URL = process.env.REACT_APP_ALUMNI_BASE_URL;
const ALUMNI_EVENT_URL = process.env.REACT_APP_EVENT_BASE_URL;
const TOOLS_BASE_URL = process.env.REACT_APP_TOOLS_BASE_URL;
const CHAT_BASE_URL = process.env.REACT_APP_CHAT_BASE_URL;
const POST_OFFICE_URL = process.env.REACT_APP_POST_OFFICE_URL;
const TRAINING_BASE_URL = process.env.REACT_APP_NEW_TRAINING_URL;
const DISCOVER_BASE_URL = process.env.REACT_APP_DISCOVER_BASE_URL;
const NOTIFICATION_BASE_URL = process.env.REACT_APP_NOTIFICATION_BASE_URL;
const DMS_BASE_URL = process.env.REACT_APP_DMS_BASE_URL;

export const MentorURL = {
  FETCH_MENTOR_FAQ_URL: `${MENTORSHIP_URL}/api/v1/faq`,
  FETCH_MENTORS: `${env.REACT_APP_CORE_BASE_URL}/profile`,
  GET_UNCONNECTED: `${MENTORSHIP_URL}/api/v1/connections/request`,
  CONNECT: `${MENTORSHIP_URL}/api/v1/connections/request`,
  GET_CONNECTIONS: `${MENTORSHIP_URL}/api/v1/connections`,
  GET_CONNECTIONS_STATS: `${MENTORSHIP_URL}/api/v1/connections/count/status`,
  ACCEPT_REQUESTS: `${MENTORSHIP_URL}/api/v1/connections/accept`,
  REJECT_REQUESTS: `${MENTORSHIP_URL}/api/v1/connections/reject`,
  REMOVE_MENTORSHIP_CONNECTION: `${MENTORSHIP_URL}/api/v1/connections`,
  FETCH_MENTEES: `${env.REACT_APP_CORE_BASE_URL}/profile/pagination?type=tef-mentee`,
  GET_SURVEY_QUESTIONS: `${MENTORSHIP_URL}/api/v1/survey/groups/user`,
  SUBMIT_SURVEY_ANSWERS: `${MENTORSHIP_URL}/api/v1/survey/questions/users/responses`,
  CREATE_APPOINTMENT: `${MENTORSHIP_URL}/api/v1/appointments`,
  GET_APPOINTMENTS: `${MENTORSHIP_URL}/api/v1/appointments`,
  ACCEPT_INVITATION: `${MENTORSHIP_URL}/api/v1/appointments/decision`,
  DELETE_INVITATION: `${MENTORSHIP_URL}/api/v1/appointments`,
  GET_TERMS_AND_CONDITION_URL: `${MENTORSHIP_URL}/api/v1/agreement`,
  GET_PROGRAMME_DETAILS_URL: `${env.REACT_APP_CORE_BASE_URL}/programme`,
  AGREE_TO_TERMS_URL: `${MENTORSHIP_URL}/api/v1/agreement`,
  AGREE_TO_PARTICIPATE_URL: `${MENTORSHIP_URL}/api/v1/agreement/add-many`,
  HAS_AGREED_TO_PARTICIPATE_URL: `${MENTORSHIP_URL}/api/v1/agreement/programmeevent`,
};

export const AuthURL = {
  REGISTER_USER_URL: `${env.REACT_APP_BASE_URL}/auth/register`,
  SSO_LOGIN_USER_URL: `${env.REACT_APP_BASE_URL}/auth/loginWithRedirect`,
  EXTERNAL_LOGIN_USER_URL: `${env.REACT_APP_BASE_URL}/external/challenge`,
  RESEND_CONFIRM_EMAIL_URL: `${env.REACT_APP_BASE_URL}/Auth/resend-confirmation`,
  FORGET_PASSWORD_URL: `${env.REACT_APP_BASE_URL}/auth/password/forgot`,
  RESET_PASSWORD_URL: `${env.REACT_APP_BASE_URL}/auth/password/reset`,
  CHANGE_PASSWORD_URL: `${env.REACT_APP_BASE_URL}/auth/password/change`,
  VERIFY_ACCOUNT_URL: `${env.REACT_APP_BASE_URL}/auth/verification`,
  SEND_VERIFY_LINK_URL: `${env.REACT_APP_BASE_URL}/auth/verification/resend`,
  GET_USER_URL: `${env.REACT_APP_BASE_URL}/api/v1/users`,
  SEARCH_USER_URL: `${env.REACT_APP_BASE_URL}/api/v1/users/search`,
};

export const OnboardingURL = {
  GET_ORGANIZATIONS: `${env.REACT_APP_CORE_BASE_URL}/group/onboarding`,
  GET_SKILLS: `${env.REACT_APP_CORE_BASE_URL}/skill`,
  GET_INTERESTS: `${env.REACT_APP_CORE_BASE_URL}/interest`,
  GET_MENTORSHIP_AREAS: `${env.REACT_APP_CORE_BASE_URL}/mentorshipArea`,
  SUBMIT_ONBOARDING_DATA: `${env.REACT_APP_CORE_BASE_URL}/profile`,
};

export const InterestsUrl = {
  GET_INTERESTS: `${env.REACT_APP_CORE_BASE_URL}/interest`,
  GET_SINGLE_INTEREST: `${env.REACT_APP_CORE_BASE_URL}/interest`,
};

export const PostUrl = {
  CREATE_POST_URL: `${POSTS_BASE_URL}/posts`,
  VIEW_ALL_POSTS_URL: `${POSTS_BASE_URL}/posts`,
  DELETE_POST_URL: `${POSTS_BASE_URL}/posts`,
  UPDATE_POST_URL: `${POSTS_BASE_URL}/posts`,
  LIKE_POST_URL: POSTS_BASE_URL + "/posts",
  UNLIKE_POST_URL: POSTS_BASE_URL + "/posts",
  GET_USER_LIKE_URL: POSTS_BASE_URL + "/posts",
  CREATE_COMMENT_URL: `${POSTS_BASE_URL}/posts/comments`,
  GET_COMMENTS_URL: `${POSTS_BASE_URL}/posts`,
  DELETE_COMMENT_URL: `${POSTS_BASE_URL}/posts/comments`,
  LIKE_COMMENT_URL: POSTS_BASE_URL + "/posts/comments",
  GET_POST_URL: POSTS_BASE_URL + "/posts",
  GET_USER_LIKE_COMMENT_URL: POSTS_BASE_URL + "/posts/comments",
  SHARE_POST_URL: POSTS_BASE_URL + "/posts/shares",

  CREATE_QUESTION_URL: `${POSTS_BASE_URL}/questions`,
  DELETE_QUESTION_URL: `${POSTS_BASE_URL}/questions/options/{id}`,
  PLACE_VOTE_URL: POSTS_BASE_URL + "/questions/users/options",
  GET_USER_VOTE_URL: POSTS_BASE_URL + "/questions",
  GET_LIKE_USERS_URL: `${POSTS_BASE_URL}/posts`,
  ADD_BOOKMARK_URL: `${POSTS_BASE_URL}/bookmarks`,
  REMOVE_BOOKMARK_URL: `${POSTS_BASE_URL}/bookmarks`,
  GET_ALL_BOOKMARKS_URL: `${POSTS_BASE_URL}/bookmarks`,
};
export const StudyGroupPostUrl = {
  CREATE_POST_URL: `${TRAINING_BASE_URL}/posts`,
  VIEW_ALL_POSTS_URL: `${TRAINING_BASE_URL}/posts`,
  DELETE_POST_URL: `${TRAINING_BASE_URL}/posts`,
  UPDATE_POST_URL: `${TRAINING_BASE_URL}/posts`,
  LIKE_POST_URL: TRAINING_BASE_URL + "/posts",
  UNLIKE_POST_URL: TRAINING_BASE_URL + "/posts",
  GET_USER_LIKE_URL: TRAINING_BASE_URL + "/posts",
  CREATE_COMMENT_URL: `${TRAINING_BASE_URL}/posts/comments`,
  GET_COMMENTS_URL: `${TRAINING_BASE_URL}/posts`,
  DELETE_COMMENT_URL: `${TRAINING_BASE_URL}/posts/comments`,
  LIKE_COMMENT_URL: TRAINING_BASE_URL + "/posts/comments",
  GET_POST_URL: TRAINING_BASE_URL + "/posts",
  GET_USER_LIKE_COMMENT_URL: TRAINING_BASE_URL + "/posts/comments",
  SHARE_POST_URL: TRAINING_BASE_URL + "/posts/shares",

  CREATE_QUESTION_URL: `${TRAINING_BASE_URL}/questions`,
  DELETE_QUESTION_URL: `${TRAINING_BASE_URL}/questions/options/{id}`,
  PLACE_VOTE_URL: TRAINING_BASE_URL + "/questions/users/options",
  GET_USER_VOTE_URL: TRAINING_BASE_URL + "/questions",
  GET_LIKE_USERS_URL: `${TRAINING_BASE_URL}/posts`,
  ADD_BOOKMARK_URL: `${TRAINING_BASE_URL}/bookmarks`,
  REMOVE_BOOKMARK_URL: `${TRAINING_BASE_URL}/bookmarks`,
  GET_ALL_BOOKMARKS_URL: `${TRAINING_BASE_URL}/bookmarks`,
};

export const GoalUrl = {
  VIEW_GOAL: `${MENTORSHIP_URL}/api/v1/goals`,
  CREATE_GOAL_URL: `${MENTORSHIP_URL}/api/v1/goals`,
  VIEW_ONE_GOAL_URL: `${MENTORSHIP_URL}/api/v1/goals`,
  VIEW_ALL_GOALS_URL: `${MENTORSHIP_URL}/api/v1/goals`,
  VIEW_GOALS_ANALYSIS_URL: `${MENTORSHIP_URL}/api/v1/goals/analysis`,
  CHANGE_GOAL_STATUS: `${MENTORSHIP_URL}/api/v1/goals`,
  GOAL_BASE: `${MENTORSHIP_URL}/api/v1/goals`,
  UPDATE_GOAL_REMINDER: `${MENTORSHIP_URL}/api/v1/goals/setreminder`,
};

export const discussionURL = {
  GET_GOAL_DISCUSSIONS: `${MENTORSHIP_URL}/api/v1/goals`,
  CREATE_DISCUSSION_ON_GOAL: `${MENTORSHIP_URL}/api/v1/discussions`,
  LIKE_DISCUSSION: `${MENTORSHIP_URL}/api/v1/discussions`,
  GET_DISCUSSION_DETAILS: `${MENTORSHIP_URL}/api/v1/discussions`,
  GET_DISCUSSION_REPLIES: `${MENTORSHIP_URL}/api/v1/discussions`,
  CREATE_DISCUSSION_REPLY: `${MENTORSHIP_URL}/api/v1/replies`,
  GET_REPLY_DETAILS: `${MENTORSHIP_URL}/api/v1/replies`,
  LIKE_REPLY: `${MENTORSHIP_URL}/api/v1/replies`,
};

export const ProfileUrl = {
  GET_USER_PROFILE_URL: `${env.REACT_APP_CORE_BASE_URL}/profile`,
  UPDATE_USER_PROFILE: `${env.REACT_APP_CORE_BASE_URL}/profile`,
  UPDATE_MENTOR_PROFILE: `${env.REACT_APP_CORE_BASE_URL}/profile/mentor`,
  USER_SKILL: `${env.REACT_APP_CORE_BASE_URL}/userskill`,
  USER_ACHIEVEMENT: `${env.REACT_APP_CORE_BASE_URL}/achievement`,
  USER_EDUCATION: `${env.REACT_APP_CORE_BASE_URL}/education`,
  USER_MENTORSHIPAREA: `${env.REACT_APP_CORE_BASE_URL}/usermentorshiparea`,
  USER_INTEREST: `${env.REACT_APP_CORE_BASE_URL}/userinterest`,
  USER_GALLERY: `${env.REACT_APP_CORE_BASE_URL}/gallery`,
  HIDE_PROFLE_FIELDS: `${env.REACT_APP_CORE_BASE_URL}/profile/hide`,
  GET_USER_LIGHT_PROFILE_URL: `${env.REACT_APP_CORE_BASE_URL}/profile/many-light`,
  BECOME_AN_INVESTOR_PARTNER: `${env.REACT_APP_CORE_BASE_URL}/partners`,
};

export const ProgrammeUrl = {
  GET_ALL_PROGRAMMES_URL: `${env.REACT_APP_CORE_BASE_URL}/programme`,
  GET_ALL_PROGRAMME_EVENTS: `${env.REACT_APP_CORE_BASE_URL}/programmeevent`,
  GET_PROGRAMME_EVENT_TYPES: `${env.REACT_APP_CORE_BASE_URL}/eventtype`,
  GET_PROGRAMME_EVENT_CONTENTS_URL: `${env.REACT_APP_CORE_BASE_URL}/programmeeventcomponent/programmeeventid`,
};

export const ApplicationUrl = {
  FETCH_APPLICATION_FAQ_URL: `${APPLICATION_URL}/faq`,
  CREATE_PROGRAMME_URL: `${APPLICATION_URL}/application`,
  GET_PERSONALITY_QUESTIONS_URL: `${APPLICATION_URL}/questiongroups`,
  GET_MCQ_QUESTIONS_URL: `${APPLICATION_URL}/questiongroups`,
  DELETE_PROGRAMME_URL: `${APPLICATION_URL}/application`,
  GET_USER_APPLICATIONS: `${APPLICATION_URL}/applicant`,
  GET_USER_TRAINING_SCORE: `${APPLICATION_URL}/applicant`,
  SUBMIT_DECLARATION_URL: `${APPLICATION_URL}/declarations`,
  SUBMIT_ASSESSMENT_URL: `${APPLICATION_URL}/questions`,
  VERIFY_RESULT_URL: `${APPLICATION_URL}/questions/verify`,
  UPDATE_USER_APPLICATION: `${APPLICATION_URL}/applicant`,
  GET_MENTEE_TRAINING_SCORE: `${APPLICATION_URL}/score/training`,
  GET_TEMP_APPLICATIONS: `${APPLICATION_URL}/applicant/temp/applications`,
  UPDATE_TEMP_APPLICATION: `${APPLICATION_URL}/applicant/temp/applications`,
};

export const UserGroupUrl = {
  GET_ALL_GROUPS: `${env.REACT_APP_CORE_BASE_URL}/group`,
  ADD_USER_TO_GROUP: `${env.REACT_APP_CORE_BASE_URL}/group/add-user`,
};

export const FeedbackURL = {
  SEND_FEEDBACK_URL: `${POSTS_BASE_URL}/feedback`,
  SEND_DATA_ACCESS_RIGHT_URL: `${env.REACT_APP_BASE_URL}/api/v1/dataaccessright`,
  CONTACT_US: `${env.REACT_APP_CORE_BASE_URL}/contactus`,
};

export const PersonalGoalURL = {
  CREATE_PERSONAL_GOAL: `${POSTS_BASE_URL}/goal`,
  VIEW_PERSONAL_GOALS: `${POSTS_BASE_URL}/goal/users`,
  VIEW_PERSONAL_GOAL: `${POSTS_BASE_URL}/goal`,
  UPDATE_PERSONAL_GOAL: `${POSTS_BASE_URL}/goal`,
  DELETE_PERSONAL_GOAL: `${POSTS_BASE_URL}/goal`,
};

export const FileUpload = {
  FILE_UPLOAD: `${FILE_UPLOAD_BASE_URL}/storage/upload`,
};

export const InviteURL = {
  SEND_INVITE_URL: `${env.REACT_APP_BASE_URL}/api/invite`,
};

export const AlumniRequestURL = {
  CREATE_RECOMMENDATION_LETTER: `${ALUMNI_BASE_URL}/recommendationletters`,

  // hub
  DEFAULT_ALUMNI_HUB_URL: `${ALUMNI_BASE_URL}/alumnihubs`,

  // chat
  DEFAULT_ALUMNI_CHAT_URL: `${ALUMNI_BASE_URL}/chats`,
  GET_EVENT_URL: `${ALUMNI_BASE_URL}/events`,
  DEFAULT_RESOURCES_URL: `${ALUMNI_BASE_URL}/resources`,
  BUSINESS_REPORTING_SURVEY_URL: `${ALUMNI_BASE_URL}/businessreports/survey`,
  SUBMIT_BUSINESS_REPORT_URL: `${ALUMNI_BASE_URL}/businessreports/usersubmission`,
  GET_DASHBOARD_DATA: `${ALUMNI_BASE_URL}/businessreports/usersubmission/dashboard`,
  CONFIRM_SURVEY_SUBMISSION_STATUS: `${ALUMNI_BASE_URL}/businessreports/hassubmitted`,
  GET_SUBMITTED_STATUS: `${ALUMNI_BASE_URL}/businessreports/hassubmitted`,
  USER_PROFILE_URL: `${env.REACT_APP_CORE_BASE_URL}/profile`,
  GET_RESOURCE_URL: `${ALUMNI_BASE_URL}/resources`,
  GET_NEWS_URL: `${ALUMNI_BASE_URL}/news`,
  GET_ALUMNIHUB_URL: `${ALUMNI_BASE_URL}/alumnihubs`,
  GET_CHAT_GROUP_URL: `${ALUMNI_BASE_URL}/chat/groups`,
  GET_USER_PROFILE: `${env.REACT_APP_CORE_BASE_URL}/profile`,
};

export const AlumniEventRequestUrl = {
  GET_EVENT_URL: `${ALUMNI_EVENT_URL}/events`,
  TAGS: `${ALUMNI_EVENT_URL}/tags`,
};

export const OpportunityURL = {
  GET_OPPORTUNITY_URL: `${OPPORTUNITY_BASE_URL}/opportunities`,
  DELETE_OPPORTUNITY_URL: `${OPPORTUNITY_BASE_URL}/opportunities`,
  CREATE_OPPORTUNITY: `${OPPORTUNITY_BASE_URL}/opportunities`,
  EDIT_OPPORTUNITY: `${OPPORTUNITY_BASE_URL}/opportunities`,
  GET_OPPORTUNITY_CATEGORIES: `${OPPORTUNITY_BASE_URL}/categories`,
  GET_OPPORTUNITY_TYPES: `${OPPORTUNITY_BASE_URL}/opportunitytypes`,
  FLAG_OPPORTUNITY_URL: `${OPPORTUNITY_BASE_URL}/opportunities/flag`,
  GET_RELATED_OPPORTUNITY_URL: `${OPPORTUNITY_BASE_URL}/opportunities/interest-based`,
  GET_OPPORTUNITY_REMINDER_URL: `${OPPORTUNITY_BASE_URL}/setreminder`,
  GET_OPPORTUNITY_COMMENTS_URL: `${OPPORTUNITY_BASE_URL}/opportunities`,
  POST_OPPORTUNITY_COMMENT_URL: `${OPPORTUNITY_BASE_URL}/discussions`,
  LIKE_OPPORTUNITY_COMMENT_URL: `${OPPORTUNITY_BASE_URL}/discussions`,
  REPLY_OPPORTUNITY_COMMENT_URL: `${OPPORTUNITY_BASE_URL}/replies`,
  GET_OPPORTUNITY_COMMENT_RESPONSE_URL: `${OPPORTUNITY_BASE_URL}/discussions`,
  GET_MY_OPPORTUNITES: `${OPPORTUNITY_BASE_URL}/opportunities/by-loggedin-user`,
  DELETE_OPPORTUNITY_COMMENT_URL: `${OPPORTUNITY_BASE_URL}/discussions`,
  TOGGLE_OPPORTUNITY_REMINDER_URL: `${OPPORTUNITY_BASE_URL}/opportunities/setreminder`,
  INCREASE_OPPORTUNITY_VIEW_URL: `${OPPORTUNITY_BASE_URL}/views`,
  INCREASE_OPPORTUNITY_POPULARITY_URL: `${OPPORTUNITY_BASE_URL}/popularities`,
  CANCEL_OPPORTUNITY_REMINDER_URL: `${OPPORTUNITY_BASE_URL}/opportunities/removereminder`,
  FLAG_OPPORTUNITY_NOTIFICATION_URL: `${OPPORTUNITY_BASE_URL}/notification/opportunity/flag`,
  POST_OPPORTUNITY_COMMENT_NOTIFICATION_URL: `${OPPORTUNITY_BASE_URL}/notification/opportunity/discussion/add`,
  LIKE_OPPORTUNITY_COMMENT_NOTIFICATION_URL: `${OPPORTUNITY_BASE_URL}/notification/opportunity/discussion/like-unlike`,
  GET_COMMNENTER_DETAILS: `${env.REACT_APP_CORE_BASE_URL}/profile/many-light`,
};

export const CommunityURL = {
  GET_ALL_CATEGORIES_URL: `${POSTS_BASE_URL}/category`,
  GET_CATEGORY_GROUPS_URL: `${POSTS_BASE_URL}/category`,
  CREATE_GROUP_URL: `${POSTS_BASE_URL}/groups`,
  GET_ALL_GROUPS_URL: `${POSTS_BASE_URL}/groups`,
  GET_GROUP_URL: `${POSTS_BASE_URL}/groups`,
  JOIN_GROUP_URL: `${POSTS_BASE_URL}/groups/add-user`,
  LEAVE_GROUP_URL: `${POSTS_BASE_URL}/groups`,
  IS_GROUP_MEMBER_URL: `${POSTS_BASE_URL}/groups/user`,
  GET_GROUP_POSTS_URL: `${POSTS_BASE_URL}/posts/group`,
  DELETE_GROUP_URL: `${POSTS_BASE_URL}/groups`,
  GET_GROUP_USERS: `${POSTS_BASE_URL}/groups`,
  GROUP_INVITE_URL: `${POSTS_BASE_URL}/groupinvite`,
  GET_USER_GROUPS_URL: `${POSTS_BASE_URL}/groups/user`,
  SEARCH_GROUPS_URL: `${POSTS_BASE_URL}/groups/search`,
  REMOVE_USER_URL: `${POSTS_BASE_URL}/groups`,
  FLAG_A_TOPIC_URL: `${POSTS_BASE_URL}/report`,
  CONNECTION_URL: `${POSTS_BASE_URL}/connection`,
  GET_CONNECTIONS_GROUPS_URL: `${POSTS_BASE_URL}/groups/friends`,
  UPDATE_GROUP_URL: `${POSTS_BASE_URL}/groups`,
};

export const StudyGroupURL = {
  GET_ALL_CATEGORIES_URL: `${TRAINING_BASE_URL}/category`,
  GET_CATEGORY_GROUPS_URL: `${TRAINING_BASE_URL}/category`,
  CREATE_GROUP_URL: `${TRAINING_BASE_URL}/groups`,
  GET_ALL_GROUPS_URL: `${TRAINING_BASE_URL}/groups`,
  GET_GROUP_URL: `${TRAINING_BASE_URL}/groups`,
  JOIN_GROUP_URL: `${TRAINING_BASE_URL}/groups/add-user`,
  LEAVE_GROUP_URL: `${TRAINING_BASE_URL}/groups`,
  IS_GROUP_MEMBER_URL: `${TRAINING_BASE_URL}/groups/user`,
  GET_GROUP_POSTS_URL: `${TRAINING_BASE_URL}/posts/group`,
  DELETE_GROUP_URL: `${TRAINING_BASE_URL}/groups`,
  GET_GROUP_USERS: `${TRAINING_BASE_URL}/groups`,
  GROUP_INVITE_URL: `${TRAINING_BASE_URL}/groupinvite`,
  GET_USER_GROUPS_URL: `${TRAINING_BASE_URL}/groups/user`,
  SEARCH_GROUPS_URL: `${TRAINING_BASE_URL}/groups?search`,
  REMOVE_USER_URL: `${TRAINING_BASE_URL}/groups`,
  FLAG_A_TOPIC_URL: `${TRAINING_BASE_URL}/report`,
  CONNECTION_URL: `${TRAINING_BASE_URL}/connection`,
  GET_CONNECTIONS_GROUPS_URL: `${TRAINING_BASE_URL}/groups/friends`,
  // STUDY_GROUP_COURSE_MATE: `${APPLICATION_URL}/score/training`,
};

export const TopicURL = {
  CREATE_TOPIC_URL: `${POSTS_BASE_URL}/topic`,
  GET_ALL_TOPICS_URL: `${POSTS_BASE_URL}/topic`,
  DELETE_TOPIC_URL: `${POSTS_BASE_URL}/topic`,
  LIKE_TOPIC_URL: `${POSTS_BASE_URL}/topic/like-toggle`,
};

export const ToolsURL = {
  GET_ALL_TOOLS_CATEGORIES_URL: `${TOOLS_BASE_URL}/categories`,
  GET_ALL_TOOLS_URL: `${TOOLS_BASE_URL}/resources`,
  GET_TOOL_CATEGORY_URL: `${TOOLS_BASE_URL}/categories`,
  FILTER_TOOL_LIST_URL: `${TOOLS_BASE_URL}/resources/filter`,
  CREATE_TOOL_URL: `${TOOLS_BASE_URL}/resources`,
  GET_TOOL_DETAILS_URL: `${TOOLS_BASE_URL}/resources`,
  POST_TOOL_COMMENT_URL: `${TOOLS_BASE_URL}/comments`,
  GET_TOOL_COMMENTS_URL: `${TOOLS_BASE_URL}/resources`,
  LIKE_TOOL_COMMENT_URL: `${TOOLS_BASE_URL}/comments`,
  DELETE_TOOL_COMMENT_URL: `${TOOLS_BASE_URL}/comments`,
  INCREASE_TOOL_VIEW_URL: `${TOOLS_BASE_URL}/views`,
  INCREASE_TOOL_POPULARITY_URL: `${TOOLS_BASE_URL}/popularities`,
  GET_TOOLS_BASED_ON_POPULARITY_URL: `${TOOLS_BASE_URL}/resources/popularity`,
  GET_CATEGORY_NAME_URL: `${TOOLS_BASE_URL}/categories`,
  GET_TOOL_VIEWERS_URL: `${TOOLS_BASE_URL}/views`,
  ADD_TOOL_NOTIFICATION_URL: `${TOOLS_BASE_URL}/notification/tool/add`,
};
export const messageURL = {
  SEND_MESSAGE_URL: `${CHAT_BASE_URL}/chat/messages`,
  READ_MESSAGE_URL: `${CHAT_BASE_URL}/chat/messages`,
  GET_ALL_MESSAGES_URL: `${CHAT_BASE_URL}/chat/messages`,
  GET_USER_MESSAGES_URL: `${CHAT_BASE_URL}/chat/messages`,
  GET_CONVERSATION_ID: `${CHAT_BASE_URL}/chat/messages/user`,
  OPEN_CONNECTION: `${CHAT_BASE_URL}/hubs/chat`,
};

export const postOfficeURL = {
  SEND_EMAIL_URL: `${POST_OFFICE_URL}/api/v1/messages/email`,
};

export const trainingURL = {
  GET_ALL_TRAINING_COURSES_URL: `${TRAINING_BASE_URL}/course`,
  GET_ALL_TRAINING_COURSES_WITH_LANG_URL: `${TRAINING_BASE_URL}/course/overview`,
  GET_QUIZ_FOR_MODULE_URL: `${TRAINING_BASE_URL}/questiongroups`,
  SUBMIT_LMS_ASSESSMENT_URL: `${TRAINING_BASE_URL}/questions/submit`,
  GET_ALL_TRAINING_MODULES_URL: `${TRAINING_BASE_URL}/module/course`,
  GET_LESSON_CONTENT_URL: `${TRAINING_BASE_URL}/lesson`,
  GET_USER_COURSE_PROGRESS: `${TRAINING_BASE_URL}/course/progress`,
  USER_STARTS_COURSE_URL: `${TRAINING_BASE_URL}/course/user`,
  GET_COURSE_OVERVIEW_URL: `${TRAINING_BASE_URL}/course/overview`,
  GET_USER_REGISTERED_COURSES_URL: `${TRAINING_BASE_URL}/course/user`,
  USER_STARTS_LESSON_URL: `${TRAINING_BASE_URL}/userlessonview`,
  USER_COMPLETES_LESSON_URL: `${TRAINING_BASE_URL}/userlessonview`,
  HAS_COMPLETED_LESSON_URL: `${TRAINING_BASE_URL}/userlessonview`,
  Modules: `${TRAINING_BASE_URL}/module`,
  Get_TRAINING_COURSE_MODULES: `${TRAINING_BASE_URL}/course`,
  QUESTION_AND_ANSWER: `${TRAINING_BASE_URL}/posts`,
  USER_STARTS_MODULE_URL: `${TRAINING_BASE_URL}/module/userstarted`,
  USER_COMPLETES_MODULE_URL: `${TRAINING_BASE_URL}/module/usercompleted`,
  USER_COMPLETES_COURSE_URL: `${TRAINING_BASE_URL}/course/user/completed`,
  AGREE_TO_PARTICIPATE_URL: `${APPLICATION_URL}/training/agreement`,
  HAS_AGREED_TO_PARTICIPATE_URL: `${APPLICATION_URL}/training/agreement`,
  TRAINING_GENERAL: TRAINING_BASE_URL,
  // STUDY_GROUP_COURSE_MATE: `${APPLICATION_URL}/score/training`,
};

export const discoverURL = {
  GET_ALL_ARTICLES_URL: `${DISCOVER_BASE_URL}/api/v1/article`,
  GET_ARTICLE_CATEGORY_URL: `${DISCOVER_BASE_URL}/api/v1/categories`,
  GET_ARTICLE_TAGS_URL: `${DISCOVER_BASE_URL}/api/v1/tags`,
  GET_ARTICLES_LIKES: `${DISCOVER_BASE_URL}/api/article`,
  GET_ALL_MASTER_CLASS_URL: `${DISCOVER_BASE_URL}/api/v1/masterclass`,
  GET_ALL_MASTER_CLASS_CATEGORY_URL: `${DISCOVER_BASE_URL}/api/v1/categories`,
  GET_ALL_STORY_URL: `${DISCOVER_BASE_URL}/api/v1/stories`,
  GET_ALL_STORY_INDUSTRIES: CommunityURL.GET_ALL_CATEGORIES_URL,
  GET_ALL_STORY_COUNTRIES: `${DISCOVER_BASE_URL}/api/v1/countries`,
  POST_COMMENT: `${DISCOVER_BASE_URL}/api/v1/article/comments`,
  GET_ALL_LEARNING_RESOURCE: `${DISCOVER_BASE_URL}/api/v1/learningresource`,
  GET_TOP_ARTICLE: `${DISCOVER_BASE_URL}/api/v1/article/top`,
  CREATE_STORY_COMMENT: `${DISCOVER_BASE_URL}/api/v1/stories/comments`,
  GET_TOP_ARTICLES_URL: `${DISCOVER_BASE_URL}/api/v1/article/top`,
  INCREASE_ARTICLE_VIEW_URL: `${DISCOVER_BASE_URL}/api/v1/article`,
  GET_ALL_NEWS_URL: `${DISCOVER_BASE_URL}/api/v1/news`,
  CREATE_ARTICLE_COMMENT: `${DISCOVER_BASE_URL}/api/v1/news/comments`,
  GET_DISCOVER_MODULE_VIDEOS_URL: `${DISCOVER_BASE_URL}/api/v1/learningresource/video-resources`,
  CREATE_AND_DELETE_BOOKMARK: `${DISCOVER_BASE_URL}/api/v1/bookmarks`,
  GET_BOOKMARKS: `${DISCOVER_BASE_URL}/api/v1/bookmarks/user`,
  DOWNLOAD_RESOURCE: `${DISCOVER_BASE_URL}/api/v1/archive/downloads`,
  DELETE_ARTICLE: `${DISCOVER_BASE_URL}/api/v1/article`,
  UPDATE_ARTICLE: `${DISCOVER_BASE_URL}/api/v1/article`,
  SEARCH_DISCOVER: `${DISCOVER_BASE_URL}/api/v1/archive/search`,
  UPDATE_STORY: `${DISCOVER_BASE_URL}/api/v1/stories`,
  FLAG_COMMENT: `${DISCOVER_BASE_URL}/api/v1/report`,
  DELETE_STORY: `${DISCOVER_BASE_URL}/api/v1/stories`,
  DELETE_COMMENT: `${DISCOVER_BASE_URL}/api/v1/archive/comments`,
};

export const NotificationURL = {
  GET_ALL_NOTIFICATIONS: `${NOTIFICATION_BASE_URL}/notifications`,
};

export const DmsUrl = {
  GET_ALL_DUEDILLIGENCE_DOCUMENTS: `${DMS_BASE_URL}/dms/duedilligence/documents`,
  POST_DUEDILLIGENCE_DOCUMENTS: `${DMS_BASE_URL}/dms/duedilligence/documents`,
  GET_ALL_SEEDCAPITAL_DOCUMENTS: `${DMS_BASE_URL}/dms/seedcapital/documents`,
  POST_SEEDCAPITAL_DOCUMENTS: `${DMS_BASE_URL}/dms/seedcapital/documents`,
  POST_BANKDETAILS: `${DMS_BASE_URL}/dms/banks/user-bank`,
  GET_BANKDETAILS: `${DMS_BASE_URL}/dms/banks/user-bank-details`,
  GET_STAGE_REQUIRED_DOCUMENTS: `${DMS_BASE_URL}/dms/stage-required-documents`,
  POST_BUSINESSPLANS_DOCUMENTS: `${DMS_BASE_URL}/dms/bussiness-plans/documents`,
  GET_ALL_BUSINESSPLAN_DOCUMENTS: `${DMS_BASE_URL}/dms/business-plans`,
  POST_BUSINESSPLANS_BUILDER: `${DMS_BASE_URL}/dms/bussiness-plan-builder-data`,
  GET_BUSINESSPLANS_BUILDER: `${DMS_BASE_URL}/dms/bussiness-plan-builder-data`,
  GET_TEMPLATES: `${DMS_BASE_URL}/dmsadmin/templates`,
  GET_SETTINGS: `${DMS_BASE_URL}/dmsadmin/settings`,
  UPLOAD_SEED_CAPITAL_INVOICE: `${DMS_BASE_URL}/dms/upload-seed-capital-invoice`,
};

export const ParticipationAgreementUrl = {
  GET_PARTICIPATION_AGREEMENT: `${env.REACT_APP_CORE_BASE_URL}/programmeeventcomponent/programmeeventid`,
};
