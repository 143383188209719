import React from "react";
import classes from "./SummaryCard.module.css";

const SummaryCard = ({ icon, info, details }) => {
  return (
    <div className={classes.SummaryCard}>
      <div className={classes.IconContainer}>
        <div className={classes.Icon}>{icon}</div>
      </div>
      <div className={classes.OtherDetails}>
        <div className={classes.Info}>{info}</div>
        <div className={classes.Details}>{details}</div>
      </div>
    </div>
  );
};

export default SummaryCard;
