import React from "react";
import ReactAvatar from "react-avatar";
import styles from "./UserSection.module.scss";

const UserSection = ({ currentUser }) => {
  return (
    <span className="notranslate">
      <div outline="true" color="warning" className={styles.UserSection}>
        {!currentUser.imageUrl ? (
          <ReactAvatar
            className="notranslate"
            size={32}
            name={`${currentUser.firstName} ${currentUser.lastName}`}
            round={true}
          />
        ) : (
          <img
            src={currentUser.imageUrl}
            alt="profile"
            className={styles.UserSection__dp}
          />
        )}
        <h6
          className={styles.UserSection__name}
        >{`${currentUser.firstName} ${currentUser.lastName}`}</h6>
      </div>
    </span>
  );
};

export default UserSection;
