import React, { useState, useContext } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
} from "reactstrap";
import Avatar from "src/components/Avatar/Avatar";
import {
  acceptConnection,
  rejectConnection,
} from "src/requests/MentorRequests";
import { Context } from "src/store";
import { isMentor } from "src/modules/mentorship/components/helper";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import { useHistory, Link } from "react-router-dom";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";

const Request = ({ user }) => {
  const { state, dispatch } = useContext(Context);
  const loggedInUser = get_user_info_from_token().user;
  // const loggedInUser = useCurrentUser();
  const history = useHistory();

  const [currentMentee] = useState(user.otherDetails);
  const [modal, setModal] = useState(false);
  const [reason, setReason] = useState("");
  const [loadingAccept, setLoadingAccept] = useState(false);

  const toggle = () => setModal(!modal);

  const hasReachmaxConnection = () => {
    const maxAllowedMentorConnection = 5;
    const maxAllowedMenteeConnection = 3;
    const maxAllowed = isMentor(loggedInUser)
      ? maxAllowedMentorConnection
      : maxAllowedMenteeConnection;
    const message = isMentor(loggedInUser)
      ? "You have reached the maximum number of mentees you can connect with."
      : "You have reached the maximum number of mentors you can connect with.";
    const connectionCount = state.Mentors.connected.length;
    return {
      confirmed: connectionCount >= maxAllowed,
      message,
    };
  };

  const handleConnect = () => {
    const reachedMaxConn = hasReachmaxConnection();
    if (reachedMaxConn.confirmed)
      return Swal.fire({
        title: "Warning",
        text: reachedMaxConn.message,
        icon: "warning",
        confirmButtonColor: "red",
      });
    setLoadingAccept(true);
    acceptConnection(dispatch, user.requestId).then(() => {
      setLoadingAccept(false);
    });
  };
  const handleDecline = () => {
    rejectConnection(dispatch, user.requestId, reason).then(() => {
      toggle();
    });
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };
  const declineRequest = () => (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Decline Connection Request</ModalHeader>
        <ModalBody>
          <Form>
            <Input
              placeholder="Reason for decline"
              onChange={handleReasonChange}
              value={reason}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={handleDecline}
            disabled={String(reason).trim().length === 0}
            // disabled
          >
            Decline
          </Button>{" "}
          <Button color="secondary" outline onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
  return (
    <>
      {!currentMentee ? null : (
        <div style={{ minHeight: "50px" }}>
          <Row>
            <Col md={1} sm={1} xs={1}>
              {currentMentee && (
                <Avatar
                  dimension={47}
                  name={`${currentMentee.firstName} ${currentMentee.lastName}`}
                  onClick={() => {
                    history.push(`/profile/${user.namedId}`);
                  }}
                  // src={currentMentee.imageUrl}
                />
              )}
            </Col>
            <Col md={5} sm={5} xs={5} className="mt-3 ml-2">
              <Link to={`/profile/${user.namedId}`}>
                {currentMentee && currentMentee.firstName}{" "}
                {currentMentee && currentMentee.lastName}
              </Link>
            </Col>
            {!user.ownersRequest ? (
              <Col md={5} sm={5} xs={5} className="mt-2">
                <Row>
                  <Button
                    style={{ padding: "5px", display: "flex" }}
                    onClick={handleConnect}
                    className="mr-1"
                  >
                    Accept
                    {loadingAccept && (
                      <span style={{ marginLeft: "5px" }}>
                        <Loader
                          height={15}
                          width={15}
                          color="white"
                          type="Oval"
                        />
                      </span>
                    )}
                  </Button>
                  <Button
                    style={{ padding: "5px", display: "inline" }}
                    onClick={toggle}
                    color="secondary"
                    outline
                  >
                    Decline
                  </Button>
                </Row>
              </Col>
            ) : (
              <Col md={4} className="mt-2">
                <Button color="primary" outline>
                  Pending
                </Button>
              </Col>
            )}
          </Row>
          {declineRequest()}
        </div>
      )}
    </>
  );
};

Request.propTypes = {};

export default Request;
