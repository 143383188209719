import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import CreateCommunityGroup from "./CreateCommunityGroup";

const CreateCommunity = ({ show, toggle, className, categories }) => {
  return (
    <Modal isOpen={show} toggle={toggle} className={className}>
      <ModalHeader toggle={toggle}>Create A Group</ModalHeader>
      <ModalBody>
        <CreateCommunityGroup categories={categories} cancel={toggle} />
      </ModalBody>
    </Modal>
  );
};

export default React.memo(CreateCommunity);
