import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "reactstrap";
import "./Video.scss";
// import { videos } from "./VideoList";
import Title from "src/modules/Discover/components/Title/Title";
import { getDiscoverModuleVideos } from "src/requests/DiscoverRequest";
import { Context } from "src/store";
import { videoValid } from "src/modules/Discover/helper";
// import { videoValid } from "src/modules/Discover/helper";

const Videos = () => {
  const [videos, setVideos] = useState([]);
  const { dispatch } = useContext(Context);

  useEffect(() => {
    _getVideos();
  }, []);

  const _getVideos = () => {
    getDiscoverModuleVideos(dispatch).then((res) => {
      if (res.status === 200) {
        const { data } = res.data.data;
        setVideos(data);
      }
    });
  };
  return (
    <div className="mt-3">
      <Title name="All Videos" nameLength="8" />

      <div className="video-list-item p-3 mt-2">
        <Row>
          {videos.slice(0, 9).map(
            (item, index) =>
              videoValid(item.featureVideoUrl) && (
                <Col md={6} key={index}>
                  <div className="video-list-item__video mb-2">
                    <video src={item && item.featureVideoUrl} controls />
                  </div>
                  <div className="">
                    <h1>{item && item.title}</h1>
                  </div>
                  <div className="mb-2 video-list-item__video-views">
                    <span>{item && item.views}</span>
                    <span className="px-2" style={{ fontSize: "1rem" }}>
                      &#46;
                    </span>
                    <span>{item && item.timePosted}</span>
                  </div>
                </Col>
              )
          )}
        </Row>
        {/* <div className="mt-4 text-center w-25 show-more">Show more</div> */}
      </div>
    </div>
  );
};

export default Videos;
