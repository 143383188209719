import React from "react";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";

export default ({ loading, additonalInfo }) => {
  const { t } = useTranslation();
  if (!loading) {
    return null;
  }
  return (
    <div
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        height: "100vh",
        // minHeight: "max-content",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        position: "fixed",
        zIndex: 1,
        // width: "100%",
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Loader
        type="ThreeDots"
        color="red"
        height={100}
        width={100}
        // timeout={3000} //3 secs
      />
      {additonalInfo && (
        <div
          style={{
            padding: "5px",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            color: "white",
            borderRadius: "4px",
          }}
        >
          {" "}
          {t(`${additonalInfo}`)}
        </div>
      )}
    </div>
  );
};
