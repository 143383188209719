/* eslint-disable react/jsx-key */
import React, { useState } from "react";
import { Row, Card, Collapse } from "reactstrap";

const FAQ = ({ faqs, className = "ml-5 mt-5 mr-5 mb-0" }) => {
  const [isOpen, setIsOpen] = useState("");

  const toggle = (index) => {
    if (isOpen === index) {
      setIsOpen(false);
    } else {
      setIsOpen(index);
    }
  };

  return (
    <Row>
      {faqs.map((faq, index) => (
        <div style={{ width: "100%" }} className={className} key={index}>
          <Card
            onClick={() => toggle(index)}
            style={{
              padding: "1.8%",
              // width: "658px",
              // width:"inherit",
              minHeight: "49px",
              maxHeight: "100%",
              cursor: "pointer",
            }}
          >
            <p className="faq-question d-flex justify-content-between">
              {" "}
              <span> {faq.question}</span>
              <span className="font-weight-bold">+</span>{" "}
            </p>
            <Collapse isOpen={isOpen !== false && isOpen === index}>
              <hr />
              <span style={{ fontSize: "12px" }}>{faq.solution}</span>
            </Collapse>
          </Card>
        </div>
      ))}
    </Row>
  );
};

export default FAQ;
