import React from "react";
import { Redirect } from "react-router-dom";
import { isLoggedIn } from "src/utils/isLoggedIn";
import { usePageView } from "./usePageViewHook";
import SentryRoute from "src/SentryRoute";

// const isEntrepreneur = () => {
//   return (
//     (data && data.user.scope.includes("Entrepreneur")) ||
//     data.user.scope.includes("entrepreneur")
//   );
// };

const EntrepreneurRoute = ({ component: Component, ...rest }) => {
  usePageView();
  return (
    <SentryRoute
      {...rest}
      render={(props) =>
        isLoggedIn() ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default EntrepreneurRoute;
