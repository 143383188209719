import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from "draft-js";
import Editor, { createEditorStateWithText } from "draft-js-plugins-editor";
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from "draft-js-mention-plugin";
import createHashtagPlugin from "draft-js-hashtag-plugin";
import createLinkifyPlugin from "draft-js-linkify-plugin";
import createEmojiPlugin from "draft-js-emoji-plugin";
import classes from "./EditorBox.module.css";
import mentionsStyles from "./theme.module.css";
// import emojiStyles from "./emoji_theme.module.css";
import { Entry, positionSuggestions } from "./CutomMentionEntry";
import "draft-js-emoji-plugin/lib/plugin.css";
import PropTypes from "prop-types";
import "draft-js/dist/Draft.css";
// import { positionEmoji } from "./customEmojiEntry";

// const emojiDefault = (defaultTheme.emojiSelectPopover =
//   defaultTheme.emojiSelectPopover + ` ${emojiStyles.emojiSelectPopover}`);

/**
 *
 *
 * @description To use Emoji, set useEmoji to true (default is false)
 * @description if you need to use mentions e.g @whatever, set useMention to true(default is false) and pass in an array of objects to mention via the mentions prop. The object in the array should have at least a name and link. e.g  {name:"Jogn does", link:"johndoe.com", avatar:"https://linkto/my/avatar"}
 * @description You can add other options that can be displayed along side with or without the emoji option
 * @description optionClassName is an string to styling the options bar
 * @returns String
 */

const EditorBox = forwardRef(
  (
    {
      useEmoji,
      // useHashTags,
      useMentions,
      mentions,
      addtionalOptions,
      readOnly,
      className,
      optionsClassName,
      content,
      onEditorChange,
      placeholder,
      clearContent,
      setClearContent,
      styles,
      additionalOptionStyle,
      optionsRightContentStyle,
      additionalContent,
      additionalContentClass,
      containerClassName,
      onFocus,
    },
    ref
  ) => {
    const initializeEditorState = (content) => {
      if (content) {
        if (typeof content === "string") {
          if (content.length > 0) {
            try {
              const incomingContent = JSON.parse(content);
              const type = typeof incomingContent;
              if (type === "object") {
                return EditorState.createWithContent(
                  convertFromRaw(incomingContent)
                );
              }
            } catch (error) {
              return createEditorStateWithText(content);
            }
          } else {
            return EditorState.createEmpty();
          }
        } else {
          throw new Error(
            `Expected content of type string but got ${typeof content}`
          );
        }
      } else {
        return EditorState.createEmpty();
      }
    };
    const editorContent = initializeEditorState(content);

    const [editorState, setEditorState] = useState(editorContent);
    const [suggestions, setSuggestions] = useState(mentions);

    useEffect(() => {
      if (clearContent) {
        setEditorState(EditorState.createEmpty());
      }
      setTimeout(() => {
        setClearContent(false);
      }, 500);
    }, [clearContent]);

    useImperativeHandle(ref, () => ({
      clearInput() {
        _clearContent();
      },
      hasContent() {
        return editorState.getCurrentContent().hasText();
      },
    }));

    const _clearContent = () => {
      setEditorState(
        EditorState.push(editorState, ContentState.createFromText(""))
      );
      onEditorChange("");
    };

    // initialize
    const [mentionPlugin] = useState(
      createMentionPlugin({
        entityMutability: "IMMUTABLE",
        theme: mentionsStyles,
        positionSuggestions,
        mentionPrefix: "@",
        supportWhitespace: true,
        mentions,
        mentionComponent: (mentionProps) => (
          <span
            className={mentionProps.className}
            onClick={() =>
              (window.location.href = `${mentionProps.mention.link}`)
            }
          >
            {mentionProps.children}
          </span>
        ),
      })
    );

    // initialize link
    const linkifyPlugin = createLinkifyPlugin({
      target: "_blank",
      component: (props) => {
        const { contentState, ...rest } = props;
        return (
          <a
            {...rest}
            onClick={() => window.open(props.href)}
            target="_blank"
            style={{ color: "#4a85bb", cursor: "pointer" }}
          />
        );
      },
    });
    // initialize emoji
    const [emojiPlugin] = useState(
      createEmojiPlugin({
        useNativeArt: true,
        // theme: emojiDefault,
        // positionSuggestions: positionEmoji,
      })
    );

    // initialize hashtags
    const [hashTagPlugin] = useState(
      createHashtagPlugin({
        theme: classes.hashtag,
      })
    );

    const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin, linkifyPlugin, hashTagPlugin, emojiPlugin];

    const _onEditorChange = (editorState) => {
      if (!editorState.getCurrentContent().hasText()) {
        onEditorChange("");
      } else {
        onEditorChange(
          JSON.stringify(convertToRaw(editorState.getCurrentContent()))
        );
      }
      setEditorState(editorState);
    };

    const onSearchMentionChange = ({ value }) => {
      setSuggestions(defaultSuggestionsFilter(value, mentions));
    };

    let editorRef = useRef(null);
    const _focus = () => {
      onFocus();
      editorRef.focus();
    };

    return (
      <div
        className={`${classes.defaultContainerClassName} ${containerClassName}`}
      >
        <div className={className} style={styles} onClick={_focus}>
          <Editor
            editorState={editorState}
            ref={(elem) => {
              editorRef = elem;
            }}
            onChange={_onEditorChange}
            plugins={plugins}
            readOnly={readOnly}
            placeholder={placeholder}
          />

          {!readOnly && <EmojiSuggestions />}

          {useMentions && (
            <MentionSuggestions
              onSearchChange={onSearchMentionChange}
              suggestions={suggestions}
              // onAddMention={_onAddMentions}
              entryComponent={Entry}
            />
          )}
        </div>

        {additionalContent && (
          <div className={additionalContentClass}> {additionalContent}</div>
        )}

        <>
          {!readOnly && (
            <div
              style={additionalOptionStyle}
              className={`${classes.options} ${optionsClassName}`}
            >
              {useEmoji && <EmojiSelect />}
              {addtionalOptions && (
                <div
                  className={classes.optionsRightContent}
                  style={optionsRightContentStyle}
                >
                  {addtionalOptions}
                </div>
              )}
            </div>
          )}
        </>
      </div>
    );
  }
);

EditorBox.defaultProps = {
  useEmoji: false,
  useHashTags: false,
  useMentions: false,
  mentions: [],
  addtionalOptions: null,
  readOnly: false,
  className: "",
  optionsClassName: "",
  placeholder: "Place your text here...",
  clearContent: false,
  additionalContentClass: "",
  setClearContent: () => {},
  containerClassName: "",
  onFocus: () => {},
};

EditorBox.propTypes = {
  useEmoji: PropTypes.bool,
  // useHashTags,
  useMentions: PropTypes.bool,
  mentions: PropTypes.instanceOf(Array),
  addtionalOptions: PropTypes.any,
  additionalContent: PropTypes.any,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  optionsClassName: PropTypes.string,
  additionalContentClass: PropTypes.string,
  content: PropTypes.string,
  placeholder: PropTypes.string,
  clearContent: PropTypes.bool,
  setClearContent: PropTypes.func,
  containerClassName: PropTypes.string,
  onFocus: PropTypes.func,
  // content: PropTypes.instanceOf(EditorState)
};
export default EditorBox;
