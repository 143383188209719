import React from "react";
import { Link } from "react-router-dom";
import NewAuthLayout from "src/Auth/NewLayout";
import tefDark from "src/assets/img/logo/logo_200.png";
import "./ResetPassword.scss";

const ResetPasswordMessage = () => {
  return (
    <NewAuthLayout>
      <div className="main-wrapper__form-div">
        <Link to="/">
          <img src={tefDark} className="img-2" alt="TEFConnect Logo" />
        </Link>
        <div className="reset-password-div">
          <h1 className="reset-password-div__heading">Reset Password</h1>
          <h3 className="reset-password-div__sub-heading">
            Password reset email sent
          </h3>
          <div className="reset-password-div__message">
            <p className="message-1">
              If{" "}
              <span className="message-1__email">
                vladimir.raksha@gmail.com
              </span>{" "}
              is associated with an existing account, you will receive and email
              with instructions for resetting your password
            </p>
            <p className="message-2">
              If you did not receive any email from us within 30 minutes, please
              check you SPAM folder.
            </p>
            <button className="message-button">Send Again</button>
          </div>
        </div>
      </div>
    </NewAuthLayout>
  );
};

export default ResetPasswordMessage;
