import axios from "axios";
import { AlumniRequestURL, AlumniEventRequestUrl } from "src/constants";
import { asyncActions } from "src/utils/AsyncUtils";
import { CREATE_ALUMNI_HUB, GET_ALUMNI_HUBS } from "src/actions";

export const createAlumniHub = (dispatch, formData) => {
  dispatch(asyncActions(CREATE_ALUMNI_HUB).loading(true));
  return axios({
    method: "post",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_HUB_URL}`,
    data: formData,
  });
};

export const getAlumniHubs = (dispatch) => {
  dispatch(asyncActions(GET_ALUMNI_HUBS).loading(true));
  return axios({
    method: "get",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_HUB_URL}`,
  });
};

export const uploadAlumniHubGallery = (dispatch, hubId, imageUrls) => {
  dispatch(asyncActions(GET_ALUMNI_HUBS).loading(true));
  return axios({
    method: "post",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_HUB_URL}/${hubId}/upload-gallery`,
    data: imageUrls,
  });
};

export const getAlumniHubGallery = (dispatch, hubId) => {
  dispatch(asyncActions(GET_ALUMNI_HUBS).loading(true));
  return axios({
    method: "get",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_HUB_URL}/${hubId}/galleries`,
  });
};

export const getSingleAlumniHub = (dispatch, hubId) => {
  dispatch(asyncActions(GET_ALUMNI_HUBS).loading(true));
  return axios({
    method: "get",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_HUB_URL}/${hubId}`,
  });
};

export const getAlumniHubUsers = (dispatch, hubId) => {
  dispatch(asyncActions(GET_ALUMNI_HUBS).loading(true));
  return axios({
    method: "get",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_HUB_URL}/${hubId}/hub-users`,
  });
};

export const requestToJoinAlumniHub = (dispatch, formdata) => {
  dispatch(asyncActions(GET_ALUMNI_HUBS).loading(true));
  return axios({
    method: "post",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_HUB_URL}/request`,
    data: formdata,
  });
};

export const getUserHub = (dispatch) => {
  dispatch(asyncActions(GET_ALUMNI_HUBS).loading(true));
  return axios({
    method: "get",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_HUB_URL}/user/hubs`,
  });
};

export const getAlumniHubEventCount = (dispatch, hubId) => {
  dispatch(asyncActions(GET_ALUMNI_HUBS).loading(true));
  return axios({
    method: "get",
    url: `${AlumniEventRequestUrl.GET_EVENT_URL}/getbyhubs/counts/${hubId}`,
  });
};

export const getAlumniHubEvent = (dispatch, hubId) => {
  dispatch(asyncActions(GET_ALUMNI_HUBS).loading(true));
  return axios({
    method: "get",
    url: `${AlumniEventRequestUrl.GET_EVENT_URL}/getbyhubs/${hubId}`,
  });
};

export const getOtherHubs = (dispatch) => {
  dispatch(asyncActions(GET_ALUMNI_HUBS).loading(true));
  return axios({
    method: "get",
    url: `${AlumniRequestURL.DEFAULT_ALUMNI_HUB_URL}/other-hubs`,
  });
};
