import React from "react";
import { FiSearch } from "react-icons/fi";
import classes from "./SearchBox.Module.scss";

const SearchBox = ({ placeholder, extraClass, handleSearch }) => {
  return (
    <div className={classes["search-box__wrapper"]}>
      <div className="form-group position-relative">
        <input
          type="search"
          className={`form-control ${classes["input-borderless"]} ${extraClass}`}
          placeholder={placeholder}
          onChange={handleSearch}
        />
        <span className={classes["input-search__icon"]}>
          <FiSearch />
        </span>
      </div>
    </div>
  );
};

export default SearchBox;
