import React, { useState, useEffect } from "react";
import {
  FaAngleRight,
  FaAngleDown,
  FaCheckCircle,
  FaCircle,
  FaClipboardList,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import classes from "./SidebarItem.module.scss";

const SidebarItem = ({
  menuTitle,
  menuId,
  subMenus,
  completed,
  trainingEventId,
  lang,
  courseId,
  quiz,
  quizClicked,
  moduleNo,
  started,
  isLessonComplete,
}) => {
  const qa = useLocation();
  const {
    moduleId,
    lessonId,
    quizId,
    moduleNo: moduleNumber,
  } = queryString.parse(qa.search);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const val = quiz ? 1 : 0;
  const startedValue = started ? 1 : 0;
  let lessonsCompleted = false || subMenus.length === 0;

  useEffect(() => {
    setIsOpen(parseInt(moduleNumber) === moduleNo);
  }, [moduleNumber]);

  return (
    <>
      <div
        onClick={toggle}
        className={`notranslate ${classes.SidebarItem} ${
          isOpen && classes.SidebarItemActive
        }`}
      >
        {completed ? (
          <FaCheckCircle size={20} color="green" />
        ) : (
          <FaCircle size={20} />
        )}
        <span className={classes.SidebarItem__title}>{menuTitle}</span>
        <span className={classes.SidebarItem__count}>{`(${
          subMenus.length + val
        })`}</span>
        {!isOpen ? (
          <FaAngleRight className={classes.SidebarItem__icon} />
        ) : (
          <FaAngleDown className={classes.SidebarItem__icon} />
        )}
      </div>
      {isOpen && (
        <>
          {subMenus.map((menu, index) => {
            const isActive = moduleId === menuId && menu.lessonId === lessonId;
            const lastLesson = subMenus.length - 1 === index;
            lessonsCompleted = !!menu.completedOn;
            const prevLesson = subMenus[index - 1];
            const isFirstLesson = index === 0;
            const hasCompletedPreviousLesson =
              prevLesson && !!prevLesson.completedOn;

            const linkTo =
              lastLesson && !quiz
                ? `/training/lms/course?courseId=${courseId}&moduleId=${menuId}&lessonId=${menu.lessonId}&trainingEventId=${trainingEventId}&lang=${lang}&title=${menuTitle}&moduleNo=${moduleNo}&lessonNo=${menu.lessonNo}&started=${startedValue}&lessonsCompleted=${lessonsCompleted}&last=1`
                : `/training/lms/course?courseId=${courseId}&moduleId=${menuId}&lessonId=${menu.lessonId}&trainingEventId=${trainingEventId}&lang=${lang}&title=${menuTitle}&moduleNo=${moduleNo}&lessonNo=${menu.lessonNo}&started=${startedValue}&lessonsCompleted=${lessonsCompleted}`;
            if (
              isLessonComplete.status &&
              isLessonComplete.no === menu.lessonId
            ) {
              menu.completedOn = new Date();
            }

            return (
              <Link
                to={linkTo}
                key={index}
                onClick={() => quizClicked({})}
                className={`notranslate ${classes.SidebarItem__subMenu} ${
                  isActive && classes.SidebarItemSubMenuActive
                }`}
                style={
                  isFirstLesson
                    ? null
                    : !isFirstLesson && hasCompletedPreviousLesson
                    ? null
                    : { pointerEvents: "none" }
                }
              >
                <li className={classes.SidebarItem__name}>
                  {menu.completedOn ? (
                    <FaCheckCircle
                      size={20}
                      color="green"
                      style={{ marginRight: "10px" }}
                    />
                  ) : (
                    <FaCircle size={20} style={{ marginRight: "10px" }} />
                  )}
                  {menu.title}
                </li>
              </Link>
            );
          })}
          {quiz && (
            <Link
              to={`/training/lms/course?courseId=${courseId}&moduleId=${menuId}&quizId=${quiz.quizId}&trainingEventId=${trainingEventId}&lang=${lang}&title=${menuTitle}&moduleNo=${moduleNo}&started=${startedValue}&lessonsCompleted=${lessonsCompleted}`}
              className={`notranslate ${classes.SidebarItem__subMenu} ${
                quizId === quiz.quizId && classes.SidebarItemSubMenuActive
              } `}
              onClick={() => quizClicked(quiz)}
              style={
                subMenus.some((menu) => menu.completedOn == null)
                  ? { pointerEvents: "none" }
                  : null
              }
            >
              <span className="notranslate">
                <FaClipboardList size={20} style={{ marginRight: "10px" }} />
                {quiz.title}
              </span>
            </Link>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(SidebarItem);
