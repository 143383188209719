export const bioInfo = {
  en: {
    title1: "Personal Details",
    firstname: "First name*",
    lastname: "Last name*",
    middleName: "Middle name",
    gender: "Gender*",
    male: "male",
    female: "female",
    others: "Others",
    birthday: "Date of Birth (DD/MM/YYYY)*",
    phoneNumber: "Phone number*",
    email: "Email Address",
    education: "Highest Level of Education*",
    experience: "Years of work experience*",
    country: "Country*",
    state: "State/Region*",
    city: "City*",
    address: "Residential Address*",
    area: "Are you in a rural area or an urban center?*",
    applied: "Have you accessed other grants from other Foundations?*",
    yes: "Yes",
    no: "No",
    ifYes:
      "If yes, name the organisation(s) from which you previously accessed from.",
    typeOfId: "Type of ID*",
    idNumber: "ID Number",
    idScan: "ID scan*",
    programme: "How did you hear about the TEF programme?*",
    title2: "Next of Kin",
    message1:
      "It’s important to have an alternative contact detail in case you’re unreachable",
    message2:
      "We'll love you to onboard your mentor to the TEF platform to help you grow your business.",
    message3: "We'll not spam them",
    message4:
      "Please obtain your mentor’s permission to share their details on this form before completing this section",
    kinFirstname: "First name",
    kinLastname: "Last name",
    kinMiddlename: "Middle name",
    kinPhoneNumber: "Phone number",
    kinEmail: "Email address",
    title3: "Mentor's Details",
    mentorFirstname: "First name",
    mentorLastname: "Last name",
    mentorMiddlename: "Middle name",
    mentorPhoneNumber: "Phone number",
    mentorEmail: "Email address",
    mentorGender: "Gender",
    continue: "Continue to Business Details",
    requiredInfo: "Please provide the required information",
    orgName: "Enter Organization Name",
    kinFirst: "Enter Next of Kin First Name",
    kinLast: "Enter Next of Kin Last Name",
    kinMiddle: "Enter Next of Kin Middle Name",
    kinemail: "Enter Next of Kin Email",
    menFirst: "Enter Next of Mentor's First Name",
    menLast: "Enter Next of Mentor's Last Name",
    menMid: "Enter Next of Mentor's Middle Name",
    menEmail: "Enter Next of Mentor's Email",
    select1: "Select",
    select2: "Select, please",
    cityPlaceholder: "Type in your city of residence",
    motivation:
      "Please describe briefly what motivates you to participate in the programme and what are your expectations from the programme?*",
    ageRange: "Age Range *",
    isDisabledPerson: "Do you consider yourself as a person with disability*",
    typeOfDisability: "If yes, please select any of the following",
  },
  ar: {
    title1: "التفاصيل الشخصية",
    firstname: "الاسم الأول. ",
    lastname: "اسم العائلة.",
    middleName: "الاسم الأوسط.",
    gender: "الجنس.",
    male: "ذكر",
    female: " أنثى",
    others: " أخرى",
    birthday: "تاريخ الميلاد (يوم/شهر/سنة).",
    phoneNumber: "رقم الهاتف.",
    email: "البريد الإلكتروني.",
    education: "أعلى مستوى من التعليم.",
    experience: "سنوات الخبرة في العمل ",
    country: "البلد.",
    state: "الولاية / المنطقة.",
    city: "المدينة.",
    address: "عنوان السكن.",
    area: "هل أنت في منطقة ريفية؟",
    applied: "هل حصلت على منح أخرى من مؤسسات أخرى؟",
    yes: "نعم",
    no: "لا.",
    ifYes:
      "إذا كانت الإجابة بنعم ، فقم بتسمية المنظمة (المنظمات) التي دخلت منها مسبقًا.",
    typeOfId: "نوع المعرف ",
    idNumber: "أدخل رقم المعرّف.",
    idScan: "مسح بطاقة التعريف",
    programme: "كيف سمعت عن برنامج TEF؟",
    title2: "Next of Kin",
    message1:
      " من المهم الحصول على تفاصيل جهة اتصال بديلة في حال تعذر الوصول إليك ",
    message2: "سنحبّك أن تقوم بضم معلمك إلى منصة TEF لمساعدتك في تنمية أعمالك.",
    message3: " لن نرسل لهم بريدًا إلكترونيًا غير هام.",
    message4:
      "يرجى الحصول على إذن المرشد الخاص بك لمشاركة تفاصيله في هذا النموذج قبل إكمال هذا القسم.",
    kinFirstname: "الاسم الأول.",
    kinLastname: "اسم العائلة.",
    kinMiddlename: "الاسم الأوسط.",
    kinPhoneNumber: "رقم الهاتف.",
    kinEmail: "البريد الإلكتروني.",
    title3: "Mentor's Details",
    mentorFirstname: "الاسم الأول.",
    mentorLastname: "اسم العائلة.",
    mentorMiddlename: "الاسم الأوسط.",
    mentorPhoneNumber: "رقم الهاتف.",
    mentorEmail: "البريد الإلكتروني.",
    mentorGender: "Gender",
    continue: "تابع إلى تفاصيل الأعمال.",
    requiredInfo: "يرجى توفير المعلومات المطلوبة.",
    orgName: " أدخل اسم العمل",
    kinFirst: "أدخل الاسم الأول لـ Kin التالي",
    kinLast: " أدخل اسم عائلة القربى التالي",
    kinMiddle: "أدخل الاسم الأوسط لأقارب.",
    kinemail: "أدخل بريد KIN الإلكتروني التالي.",
    menFirst: "أدخل الاسم الأول للمرشد التالي.",
    menLast: " أدخل الاسم الأخير للمرشد التالي.",
    menMid: "أدخل الاسم الأوسط للمرشد التالي.",
    menEmail: "أدخل التالي من البريد الإلكتروني الخاص بالمرشد ",
    select1: " اختر",
    select2: " اختر، من فضلك",
    cityPlaceholder: "اكتب في مدينة إقامتك",
    motivation:
      "يرجى وصف ما الذي يحفّزك على المشاركة في البرنامج بإيجاز وما هي توقعاتك من البرنامج؟",
    ageRange: "* الفئة العمرية",
    isDisabledPerson: "هل تعتبر نفسك شخصًا ذو إعاقة*",
    typeOfDisability: "إذا كانت الإجابة نعم، فضلاً اختر واحدة من هذه",
  },
  fr: {
    title1: "Détails personnels",
    firstname: "Prénom*",
    lastname: "Nom",
    middleName: "Deuxième prénom*",
    gender: "Sexe",
    male: "Mâle",
    female: "Femelle",
    others: "Autres",
    birthday: "Date de naissance (JJ/MM/AAAA)*",
    phoneNumber: "Numéro de téléphone*",
    email: "Courriel/adresse e-mail",
    education: "Niveau d'éducation le plus élevé*",
    experience: "Nombre d’années d'expérience professionnelle?",
    country: "Pays*",
    state: " État/ Région",
    city: "Ville*",
    address: "Adresse résidentielle*",
    area: "Êtes-vous dans une zone rurale?",
    applied: "Avez-vous accédé à d'autres subventions d'autres fondations ?",
    yes: "Oui",
    no: "Non",
    ifYes:
      "Si oui, nommez l'organisation ou les organisations à partir desquelles vous avez précédemment accédé.",
    idNumber: "Entrez le numéro d'identification",
    typeOfId: "Type d'identité",
    idScan: "ID Scan… ..Choisissez le fichier",
    programme: "Comment avez-vous entendu parler du programme TEF?",
    title2: "LE PLUS PROCHE PARENT ",
    message1:
      "Il est important d'avoir un autre détail de contact au cas où vous ne seriez pas joignable ",
    message2:
      "Nous serons ravis que vous intégriez votre mentor à la plateforme TEF pour vous aider à développer votre entreprise.",
    message3: "Nous ne les spammerons pas",
    message4:
      "Veuillez obtenir la permission de votre mentor pour partager ses coordonnées sur ce formulaire avant de remplir cette section",
    kinFirstname: "Prénom",
    kinLastname: "Nom",
    kinMiddlename: "Deuxième prénom",
    kinPhoneNumber: "Numéro de téléphone",
    kinEmail: "Courriel/adresse e-mail",
    title3: "Détails Mentor",
    mentorFirstname: "Prénom",
    mentorLastname: "Nom",
    mentorMiddlename: "Deuxième prénom",
    mentorPhoneNumber: "Numéro de telephone",
    mentorEmail: "Courriel",
    mentorGender: "Sexe",
    continue: "Continuer vers les détails de l'entreprise",
    requiredInfo: "Veuillez fournir les informations requises",
    orgName: "Entrez le nom de l'organisation",
    kinFirst: "Entrez le prénom le plus proche parent",
    kinLast: " Entrez le nom de famille le plus proche",
    kinMiddle: "Entrez le deuxième prénom du parent",
    kinemail: "Entrez l'e-mail du plus proche parent",
    menFirst: "Entrez le suivant du prénom du mentor",
    menLast: "Ecrivez le suivant du nom de famille du mentor",
    menMid: "Entrez le suivant du deuxième prénom du mentor",
    menEmail: "Entrez le suivant de l'e-mail du mentor",
    select1: "Sélectionnez",
    select2: "Sélectionnez, s'il vous plaît",
    cityPlaceholder: "Tapez votre ville de résidence",
    motivation:
      "Veuillez décrire brièvement ce qui vous motive à participer au programme et quelles sont vos attentes par rapport*",
    ageRange: "Plage d'âge *",
    isDisabledPerson: "Vous considérez-vous comme une personne handicapée*",
    typeOfDisability:
      "Si oui, veuillez sélectionner l'une des options suivantes",
  },
  pt: {
    title1: "detalhes pessoais",
    firstname: "Nome Próprio ",
    lastname: "Apelidos ",
    middleName: "Nome do Meio",
    gender: "Gênero*",
    male: "Masculino",
    female: "Feminino",
    others: "Outros",
    birthday: "Data de Nascimento (DD/MM/AAAA) ",
    phoneNumber: "Número de Telefone",
    email: "E-mail",
    education: "Nivel mais alto de educacao ",
    experience: "Anos de experiência de trabalho",
    country: "País",
    state: "Estado/ Região",
    city: "Cidade*",
    address: "Endereço residencial",
    area: "Você está em uma área rural?*",
    applied: "Você acessou outros subsídios de outras fundações?",
    yes: "Sim",
    no: "Não",
    ifYes:
      "Em caso afirmativo, nomeie a (s) organização (ões) da (s) qual (is) você acessou anteriormente.",
    typeOfId: "Tipo de documento de Indentificação",
    idNumber: "Insira o número do documento de identificação",
    idScan: "Digitalização do documento ….. Escolha o ficheiro ",
    programme: "Como você ficou sabendo sobre o Programa da TEF?",
    title2: "Parente mais próximo",
    message1:
      "É importante ter um detalhe de contato alternativo, caso você esteja inacessível ",
    message2:
      "Ficaremos felizes se você integrar seu mentor à plataforma TEF para ajudá-lo a expandir seus negócios.",
    message3: "Nós não os enviaremos como spam",
    message4:
      "Obtenha permissão de seu mentor para compartilhar suas informações de contato neste formulário antes de preencher esta seção",
    kinFirstname: "Primeiro Nome",
    kinLastname: "Apelido",
    kinMiddlename: "Nome do Meio",
    kinPhoneNumber: "Número de Telefone",
    kinEmail: "Email",
    title3: "Mentor",
    mentorFirstname: "Primeiro Nome",
    mentorLastname: "Apelido",
    mentorMiddlename: "Nome do Meio",
    mentorPhoneNumber: "Número de Telefone",
    mentorEmail: "Email",
    mentorGender: "Gênero",
    continue: "Continue para detalhes da empresa",
    requiredInfo: "Forneça as informações necessárias",
    orgName: "Insira o nome da organização",
    kinFirst: "Insira o nome do parente mais próximo",
    kinLast: "Digite o apelido do parente mais próximo",
    kinMiddle: "Digite o nome do meio do próximo parente",
    kinemail: "Insira o e-mail de parente próximo",
    menFirst: "Digite o nome do mentor",
    menLast: "Digite o sobrenome do mentor",
    menMid: "Digite o nome do meio do mentor",
    menEmail: "Digite o e-mail do mentor",
    select1: "Selecione",
    select2: "Selecione, por favor",
    cityPlaceholder: "Digite sua cidade de residência",
    ageRange: "Faixa etária *",
    isDisabledPerson: "Você se considera uma pessoa com deficiência*",
    typeOfDisability: "Se sim, selecione qualquer uma das seguintes opções",
  },

  sw: {
    title1: "Maelezo Binafsi",
    firstname: "Jina la Kwanza*",
    lastname: "Jina la Mwisho*",
    middleName: "Jina la Kati",
    gender: "Jinsia*",
    male: "kiume",
    female: "kike",
    others: "Wengine",
    birthday: "Tarehe ya Kuzaliwa (DD/MM/YYYY)*",
    phoneNumber: "Nambari ya Simu*",
    email: "Anwani ya Barua pepe",
    education: "Kiunga cha Elimu cha Juu*",
    experience: "Miaka ya Uzoefu wa Kazi*",
    country: "Nchi*",
    state: "Mkoa/Mahali*",
    city: "Jiji*",
    address: "Anwani ya Makazi*",
    area: "Je, wewe uko eneo la vijijini au katikati ya mji?*",
    applied: "Je, umewahi kupata ruzuku kutoka kwa mashirika mengine?*",
    yes: "Ndiyo",
    no: "Hapana",
    ifYes:
      "Ikiwa ndiyo, taja jina la shirika(s) kutoka ambalo umewahi kupata kutoka.",
    typeOfId: "Aina ya Kitambulisho*",
    idNumber: "Nambari ya Kitambulisho",
    idScan: "Skana ya Kitambulisho*",
    programme: "Vipi ulipata habari kuhusu programu ya TEF?*",
    title2: "Mfano wa Karibu",
    message1:
      "Ni muhimu kuwa na maelezo mengine ya mawasiliano kwa kesi hauwezi kupatikana",
    message2:
      "Tungependa uwaunge mkono na mhudumu wako kwenye jukwaa la TEF ili kukusaidia kuendeleza biashara yako.",
    message3: "Hatutawatuma ujumbe usiohitajika",
    message4:
      "Tafadhali pata idhini ya mhudumu wako kabla ya kushiriki maelezo yao kwenye fomu hii kabla ya kumaliza sehemu hii",
    kinFirstname: "Jina la Kwanza",
    kinLastname: "Jina la Mwisho",
    kinMiddlename: "Jina la Kati",
    kinPhoneNumber: "Nambari ya Simu",
    kinEmail: "Anwani ya Barua pepe",
    title3: "Maelezo ya Msimamizi",
    mentorFirstname: "Jina la Kwanza",
    mentorLastname: "Jina la Mwisho",
    mentorMiddlename: "Jina la Kati",
    mentorPhoneNumber: "Nambari ya Simu",
    mentorEmail: "Anwani ya Barua pepe",
    mentorGender: "Jinsia",
    continue: "Endelea kwenye Maelezo ya Biashara",
    requiredInfo: "Tafadhali toa habari zinazohitajika",
    orgName: "Ingiza Jina la Shirika",
    kinFirst: "Ingiza Jina la Kwanza la Mfano wa Karibu",
    kinLast: "Ingiza Jina la Mwisho la Mfano wa Karibu",
    kinMiddle: "Ingiza Jina la Kati la Mfano wa Karibu",
    kinemail: "Ingiza Anwani ya Barua pepe ya Mfano wa Karibu",
    menFirst: "Ingiza Jina la Kwanza la Msimamizi",
    menLast: "Ingiza Jina la Mwisho la Msimamizi",
    menMid: "Ingiza Jina la Kati la Msimamizi",
    menEmail: "Ingiza Anwani ya Barua pepe ya Msimamizi",
    select1: "Chagua",
    select2: "Chagua, tafadhali",
    cityPlaceholder: "Ingiza jina la jiji lako la makazi",
    motivation:
      "Tafadhali eleza kwa ufupi kinachokusukuma kushiriki kwenye programu na ni nini matarajio yako kutoka kwenye programu?*",
    ageRange: "Kundi la Umri *",
    isDisabledPerson: "Je, unajiona kama mtu mwenye ulemavu*",
    typeOfDisability: "Ikiwa ndiyo, tafadhali chagua mojawapo ya hizi",
  },
};

export const previewInfo = {
  en: {
    title1: "TEF Application Form",
    title2: "Full Bio Details",
    gender: "Gender",
    birthday: "Date of Birth",
    phoneNumber: "Phone number",
    email: "Email Address",
    education: "Highest Level of Education",
    experience: "Years of work experience",
    country: "Country",
    state: "State",
    city: "City",
    address: "Address",
    area: "Are you in a rural Area?",
    applied: "Have you applied for other grants from other foundations?",
    yes: "Yes",
    no: "No",
    ifYes: "If yes, name the organization",
    typeOfId: "Type of ID",
    idNumber: "ID Number",
    programme: "How did you hear about the TEF programme?",
    title3: "Next of kin",
    kinPhoneNumber: "Next of Kin Phone number",
    kinEmail: "Next of Kin Email",
    title4: "Mentor's Details",
    mentorName: "Name",
    mentorPhoneNumber: "Phone number",
    mentorEmail: "Email address",
  },
  ar: {
    title1: "TEF Application Form",
    title2: "Full Bio Details",
    gender: "Gender",
    birthday: "Date of Birth",
    phoneNumber: "Phone number",
    email: "Email Address",
    education: "Highest Level of Education",
    experience: "Years of work experience",
    country: "Country",
    state: "State",
    city: "City",
    address: "Address",
    area: "Are you in a rural Area?",
    applied: "Have you applied for other grants from other foundations?",
    yes: "Yes",
    no: "No",
    ifYes: "If yes, name the organization",
    typeOfId: "Type of ID",
    idNumber: "ID Number",
    programme: "How did you hear about the TEF programme?",
    title3: "Next of kin",
    kinPhoneNumber: "Next of Kin Phone number",
    kinEmail: "Next of Kin Email",
    title4: "Mentor's Details",
    mentorName: "Name",
    mentorPhoneNumber: "Phone number",
    mentorEmail: "Email address",
  },
  fr: {
    title1: "Formulaire de candidature TEF",
    title2: "Détails bio complets",
    gender: "Sexe",
    birthday: "Date de naissance",
    idNumber: "Numéro d'identification",
    phoneNumber: "Numéro de telephone",
    email: "Courriel/adresse e-mail",
    education: "Niveau d'éducation le plus élevé",
    experience: "Nombre d’années d'expérience professionnelle?",
    country: "Pays",
    state: "Région",
    city: "Ville",
    address: "Adresse",
    area: "Êtes-vous dans une zone rurale?",
    applied: "Avez-vous demandé d'autres subventions à d'autres fondations?",
    yes: "Oui ",
    no: "Non",
    ifYes:
      "Si oui, nommez les organisations auprès desquelles vous avez déjà postulé",
    typeOfId: "Type d'identité",
    programme: "Comment avez-vous entendu parler de la Fondation Tony Elumelu?",
    title3: "Contact d’urgence",
    kinPhoneNumber: "Numéro de telephone",
    kinEmail: "Courriel/adresse e-mail",
    title4: "Détails Mentor",
    mentorName: "Nom",
    mentorPhoneNumber: "Numéro de telephone",
    mentorEmail: "Courriel",
    motivation:
      "Veuillez décrire brièvement ce qui vous motive à participer au programme et quelles sont vos attentes par rapport",
  },
  pt: {
    title1: "TEF Application Form",
    title2: "Full Bio Details",
    gender: "Gênero",
    birthday: "Aniversário",
    phoneNumber: "Número de Telefone",
    email: "E-mail",
    education: "Nível Máximo de Educação",
    experience: "Quantos anos de trabalho têm?",
    country: "País",
    state: "Estado",
    idNumber: "Número de identidade",

    city: "Cidade",
    address: "Móbile",
    area: "Você está em uma área rural?",
    applied: "Você já aplicou para as outras subvenções das outras fundações?",
    yes: "Sim",
    no: "Não",
    ifYes:
      "Em caso afirmativo, nomeie a (s) organização (ões) às quais você se inscreveu anteriormente.",
    typeOfId: "Tipo de documento de Indentificação",
    programme: "Como entendeu do Programa de TEF?",
    title3: "Parente mais próximo",
    kinPhoneNumber: "Número de Telefone",
    kinEmail: "Email",
    title4: "Mentor",
    mentorName: "Nome",
    mentorPhoneNumber: "Número de Telefone",
    mentorEmail: "Email",
  },
  sw: {
    title1: "Fomu ya Maombi ya TEF",
    title2: "Maelezo Kamili ya Wasifu",
    gender: "Jinsia",
    birthday: "Tarehe ya Kuzaliwa",
    phoneNumber: "Nambari ya Simu",
    email: "Anwani ya Barua pepe",
    education: "Kiunga cha Elimu cha Juu",
    experience: "Miaka ya Uzoefu wa Kazi",
    country: "Nchi",
    state: "Mkoa",
    city: "Jiji",
    address: "Anwani",
    area: "Je, wewe uko katika eneo la vijijini?",
    applied: "Je, umewahi kuomba ruzuku kutoka kwa mashirika mengine?",
    yes: "Ndiyo",
    no: "Hapana",
    ifYes: "Ikiwa ndiyo, taja jina la shirika",
    typeOfId: "Aina ya Kitambulisho",
    idNumber: "Nambari ya Kitambulisho",
    programme: "Ulipataje habari kuhusu programu ya TEF?",
    title3: "Mfano wa Karibu",
    kinPhoneNumber: "Nambari ya Simu ya Mfano wa Karibu",
    kinEmail: "Anwani ya Barua pepe ya Mfano wa Karibu",
    title4: "Maelezo ya Msimamizi",
    mentorName: "Jina",
    mentorPhoneNumber: "Nambari ya Simu",
    mentorEmail: "Anwani ya Barua pepe",
  },
};

export const preViewBtns = {
  en: {
    edit: "Edit Information",
    backHome: "Back to Homepage",
    validate: "Validate",
    discard: "Discard",
    fullAppDetails: "Full application details",
    updateBio: "Update Personal data",
    updateExp: "Update Idea & Experience",
    completeVal: "Complete Validation",
  },
  fr: {
    edit: "Changer les informations",
    backHome: "Retour à la page d'accueil",
    validate: "Valider",
    discard: "Débarrasser",
    fullAppDetails: "Tous les détails de la candidature",
    updateBio: "Mise à jour des donnée personnelle",
    updateExp: "Mise à jour de l'idée & l'expérience",
    completeVal: "Validation complète",
  },
  pt: {
    edit: "Editar Informação",
    backHome: "Voltar à página inicial",
    validate: "Validar",
    discard: "Descartar",
    fullAppDetails: "Detalhes completos da candidatura",
    updateBio: "Actualizar dados pessoais",
    updateExp: "Actualizar ideia e experiência",
    completeVal: "Validação Completa",
  },
  ar: {
    edit: "تحرير المعلومات",
    backHome: "العودة إلى الصفحة الرئيسية",
    validate: "التحقق",
    discard: "تجاهل",
    fullAppDetails: "كامل تفاصيل التطبيق",
    updateBio: "تحديث البيانات الشخصية",
    updateExp: "تحديث الفكرة والخبرة",
    completeVal: "التحقق الكامل من الصحة",
  },
  sw: {
    edit: "Hariri Taarifa",
    backHome: "Rudi kwenye Ukurasa wa Nyumbani",
    validate: "Thibitisha",
    discard: "Tupa",
    fullAppDetails: "Maelezo Kamili ya Maombi",
    updateBio: "Sasisha Taarifa za Kibinafsi",
    updateExp: "Sasisha Wazo na Uzoefu",
    completeVal: "Kamisha Uhakiki",
  },
};
