import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import {
  FLAG_DISABLE_THIRD_PARTY_NETWORK_CALLS,
  getBooleanFlagValue,
} from "src/modules/Flags/urlFlagParams";

// In order to debug Google Analytics, use the Chrome extension
// https://chrome.google.com/webstore/detail/google-analytics-debugger/jnkmfdileelhofjcijamephohjechhna

// Universal analytics will stop working on 1st July 2023.
const enableGaUniversal = () => {
  return !getBooleanFlagValue(FLAG_DISABLE_THIRD_PARTY_NETWORK_CALLS);
};

const enableGa4 = () => {
  return !getBooleanFlagValue(FLAG_DISABLE_THIRD_PARTY_NETWORK_CALLS);
};

export const initGoogleAnalytics = () => {
  if (enableGaUniversal()) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID_UNIVERSAL);
  }
  if (enableGa4()) {
    ReactGA4.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);
  }
};

const googleAnalyticsEvent = (event) => {
  if (enableGaUniversal()) {
    ReactGA.event(event);
  }
  if (enableGa4()) {
    ReactGA4.event(event);
  }
};

/**
 * Logs an event related to sign-in to Google Analytics.
 *
 * @param provider Sign in provider, e.g. "Google" or "Form".
 * @param result Sign in result: "Success" | "Failure" | Error.
 */
export const logSignInEvent = (provider, result) => {
  googleAnalyticsEvent({
    category: "Sign In",
    action: provider,
    label: result.toString(),
  });
};

/** Logs a button click to Google Analytics. */
export const logButtonClick = (buttonLabel) => {
  googleAnalyticsEvent({
    category: "Button",
    action: "Click",
    label: buttonLabel,
  });
};

/** Logs timing to Google Analytics. */
export const googleAnalyticsTiming = ({ category, variable, milliseconds }) => {
  if (enableGaUniversal()) {
    ReactGA.timing({ category, variable, value: milliseconds });
  }

  if (enableGa4()) {
    // Based on https://developers.google.com/analytics/devguides/collection/gtagjs/user-timings
    ReactGA4.gtag("event", "timing_complete", {
      name: variable,
      value: milliseconds,
      event_category: category,
    });
  }
};

/** Logs exception to Google Analytics. */
export const googleAnalyticsException = ({ error, fatal }) => {
  if (enableGaUniversal()) {
    ReactGA.exception({
      description: error,
      fatal,
    });
  }

  if (enableGa4()) {
    ReactGA4.gtag("event", "exception", {
      description: error,
      fatal,
    });
  }
};

export const googleAnalyticsPageview = (pathname) => {
  if (enableGa4()) {
    ReactGA4.send({ hitType: "pageview", page: pathname });
  }
  if (enableGaUniversal()) {
    ReactGA.pageview(pathname);
  }
};
