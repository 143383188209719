import axios from "src/utils/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CREATE_PERSONAL_GOAL,
  VIEW_PERSONAL_GOALS,
  VIEW_PERSONAL_GOAL,
  UPDATE_PERSONAL_GOAL,
  DELETE_PERSONAL_GOAL,
} from "src/actions";
import { asyncActions } from "src/utils/AsyncUtils";
import { PersonalGoalURL } from "src/constants";

export const createPersonalGoal = (dispatch, payload) => {
  dispatch(asyncActions(CREATE_PERSONAL_GOAL).loading(true));
  return axios({
    method: "post",
    url: PersonalGoalURL.CREATE_PERSONAL_GOAL,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(CREATE_PERSONAL_GOAL).loading(false));
      if (response.status === 201) {
        dispatch(asyncActions(CREATE_PERSONAL_GOAL).success(response.data));
        dispatch(asyncActions(CREATE_PERSONAL_GOAL).loading(false));
        toast("Personal Goal Created, Check Your Profile To View Goal");
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(CREATE_PERSONAL_GOAL).loading(false));
      dispatch(
        asyncActions(CREATE_PERSONAL_GOAL).failure(true, error.response)
      );
      toast("An error occurred, Please try again ");
      return error.response;
    });
};

export const getPersonalGoals = (dispatch, id) => {
  dispatch(asyncActions(VIEW_PERSONAL_GOALS).loading(true));
  return axios({
    method: "get",
    url: `${PersonalGoalURL.VIEW_PERSONAL_GOALS}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(VIEW_PERSONAL_GOALS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(VIEW_PERSONAL_GOALS).success(data));
        dispatch(asyncActions(VIEW_PERSONAL_GOALS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(VIEW_PERSONAL_GOALS).loading(false));
      dispatch(asyncActions(VIEW_PERSONAL_GOALS).failure(true, error.response));
      return error.response;
    });
};

export const getPersonalGoal = (dispatch, id, loading = true) => {
  dispatch(asyncActions(VIEW_PERSONAL_GOAL).loading(loading));
  return axios({
    method: "get",
    url: `${PersonalGoalURL.VIEW_PERSONAL_GOAL}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(VIEW_PERSONAL_GOAL).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(VIEW_PERSONAL_GOAL).success(data));
        dispatch(asyncActions(VIEW_PERSONAL_GOAL).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(VIEW_PERSONAL_GOAL).loading(false));
      dispatch(asyncActions(VIEW_PERSONAL_GOAL).failure(true, error.response));
      return error.response;
    });
};

export const updatePersonalGoal = (dispatch, id, payload) => {
  dispatch(asyncActions(UPDATE_PERSONAL_GOAL).loading(true));
  return axios({
    method: "put",
    url: `${PersonalGoalURL.UPDATE_PERSONAL_GOAL}/${id}`,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(UPDATE_PERSONAL_GOAL).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(UPDATE_PERSONAL_GOAL).success(response.data));
        dispatch(asyncActions(UPDATE_PERSONAL_GOAL).loading(false));
        getPersonalGoal(dispatch, id).then(() => {
          toast("Goal Updated Successfully");
        });
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(UPDATE_PERSONAL_GOAL).loading(false));
      dispatch(
        asyncActions(UPDATE_PERSONAL_GOAL).failure(true, error.response)
      );
      toast("An error occurred, Please try again ");
      return error.response;
    });
};

export const deletePersonalGoal = (dispatch, id) => {
  dispatch(asyncActions(DELETE_PERSONAL_GOAL).loading(true));
  return axios({
    method: "delete",
    url: `${PersonalGoalURL.DELETE_PERSONAL_GOAL}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(DELETE_PERSONAL_GOAL).loading(false));
      if (response.status === 200) {
        // const { data } = response.data;
        dispatch(asyncActions(DELETE_PERSONAL_GOAL).success(id));
        dispatch(asyncActions(DELETE_PERSONAL_GOAL).loading(false));
        getPersonalGoal(dispatch, id).then(() => {
          toast("Goal Deleted Successfully");
        });
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(DELETE_PERSONAL_GOAL).loading(false));
      dispatch(
        asyncActions(DELETE_PERSONAL_GOAL).failure(true, error.response)
      );
      return error.response;
    });
};
