import React, { Fragment } from "react";
import Video from "./Video";

const ViewedVideos = () => {
  return (
    <Fragment>
      <div className="video-card">
        <h6 className="my-4 font-weight-bolder">Most Viewed Videos</h6>
        <div className="article-card__articles">
          <Video
            video="https://player.vimeo.com/external/225795843.sd.mp4?s=225e44b8a2d4b6c9fcab641b15233ede42dbc60c&profile_id=164&oauth2_token_id=57447761"
            title="How Timberland created the Visual ..."
          />
          <Video
            video="https://player.vimeo.com/external/228614686.sd.mp4?s=9bf377abb052b31731df24292c40ca6f938e7524&profile_id=164&oauth2_token_id=57447761"
            title="How Timberland created the Visual ..."
          />
          <Video
            video="https://htmlreference.io/assets/HTML%205%20Video.mp4"
            title="How Timberland created the Visual ..."
          />
          <Video
            video="https://htmlreference.io/assets/HTML%205%20Video.mp4"
            title="How Timberland created the Visual ..."
          />
          <Video
            video="https://htmlreference.io/assets/HTML%205%20Video.mp4"
            title="How Timberland created the Visual ..."
          />
        </div>
        <p className="see-more">…See more</p>
      </div>
    </Fragment>
  );
};

export default ViewedVideos;
