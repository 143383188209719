import moment from "moment";
import React from "react";
import Avatars from "src/components/Avatar/Avatar";
import { EditorContentDisplay } from "src/components/Editor";
import classes from "./Comment_item.module.scss";

const CommentItem = ({ comment }) => {
  const { profile, content, createdOn } = comment;
  return (
    <div className={classes.container}>
      <div className={classes.container__left}>
        <div>
          <Avatars
            name={`${profile.firstName} ${profile.lastName}`}
            dimension={39}
            src={profile.imageUrl}
          />
        </div>
        <div className={`${classes.commentDetail} notranslate`}>
          <h4>{`${
            profile.firstName
              ? `${profile.firstName} ${profile.lastName}`
              : "Blocked User"
          }`}</h4>
          <EditorContentDisplay
            content={content}
            className={classes.content}
          ></EditorContentDisplay>
        </div>
      </div>
      <div className={classes.container__right}>
        <p>{moment(createdOn).fromNow()}</p>
      </div>
    </div>
  );
};

export default CommentItem;
