import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from "reactstrap";
import { Context } from "src/store";
import { FcApproval } from "react-icons/fc";

const BusinessPlanRequiredDocuments = ({ document }) => {
  const [businessPlanDocs, setDocuments] = useState([]);
  const [businessPlans, setBusinessPlans] = useState({});

  const { state } = useContext(Context);
  useEffect(() => {
    const businessplanDocuments =
      state.dms && state.dms.businessPlan && state.dms.businessPlan.documents;
    const businessPlansData = state.dms && state.dms.businessPlan;

    if (businessplanDocuments) {
      setDocuments(businessplanDocuments);
    }

    if (businessPlansData) {
      setBusinessPlans(businessPlansData);
    }
  }, [state.dms.businessPlan]);

  const checkIfSubmitted = (id) => {
    const currentCycle = businessPlanDocs.find(
      (doc) => doc.cycleId === businessPlans.currentCycleId
    );

    if (currentCycle) {
      return currentCycle.documents.some(
        (doc) => doc.stageRequiredDocumentId === id
      );
    }
  };

  return (
    <div className="">
      <Row>
        <Col sm="1" xs={1}>
          <strong>{document.order}</strong>
        </Col>
        <Col sm="8" xs={8}>
          <p style={{ lineHeight: "1.5em" }}>
            <strong>{document.title}</strong> <br />
            {document.isRequired && (
              <em style={{ color: "#A4A5A6" }}>Required</em>
            )}
          </p>
        </Col>
        <Col sm="2" xs={2}>
          {checkIfSubmitted(document.id) && <FcApproval />}
        </Col>
      </Row>
    </div>
  );
};

export default BusinessPlanRequiredDocuments;
