/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from "react";
import "../common.scss";
import "./bio.scss";
import { FormField, Select } from "src/modules/onboarding/components";
import PhoneInput from "react-phone-input-2";
import { FaUpload, FaMale, FaFemale, FaSave } from "react-icons/fa";
import { SelectBox } from "src/modules/Application/Offcycle/personalInfo/components";

import {
  retrieveTempUserApplication,
  storeTempUserApplication,
  _setSteps,
} from "src/modules/Application/helper";
import { personalInfo } from "src/modules/Application/Offcycle/personalInfo/personanlInfoObj";
import { Context } from "src/store";
import { updateUserProfile } from "src/requests/ProfileRequest";
import {
  CastValue,
  displayError,
  // _scrollToRef,
} from "src/modules/onboarding/helper";
import {
  formatMaxBirthYear,
  validateBirthYear,
} from "src/modules/onboarding/stepTwo/Sections";
import "react-phone-input-2/lib/style.css";
import { degreeTypes } from "src/modules/Application/Offcycle/personalInfo/degreeTypes.js";
import { idTypes } from "src/modules/Application/Offcycle/personalInfo/idTypes.js";
import { getCountryAlpha2Code } from "src/modules/Application/Offcycle/personalInfo/helper";
import ValidateJs from "src/utils/Validations/validation_wrapper";
import Loader from "react-loader-spinner";
import CLoader from "src/modules/Application/components/CLoader";
import { howYouHearAboutUs } from "src/modules/Application/Offcycle/personalInfo/howYouHeardAboutUs";
import queryString from "query-string";
import { UploadFile, isImageFile } from "src/requests/fileUploadRequest";
import { toast } from "react-toastify";
import InstructionModal from "src/modules/Application/InstructionModal/InstructionModal";
import {
  instructions,
  sectionOneTranslations,
} from "src/modules/Application/instructions";
import { bioInfo } from "src/modules/Application/personalInfo";
import { useTranslation } from "react-i18next";
import { returnTranslationOption } from "src/modules/Application/Offcycle/Declaration/DeclarationTranslations";
import {
  CustomCountryRegionDropdown,
  CustomCountryDropdown,
} from "src/components/CustomCountryRegionDropdown";
import { ageRange } from "src/modules/Application/Flagship/personalInfo/ageRange";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { refreshToken } from "src/utils/someTokenMenthods";
import { DatePickerInput } from "rc-datepicker";

const setExistingInfo = (info, profile) => {
  info = JSON.parse(JSON.stringify(info));
  // const userInfo = ["firstName", "lastName", "email"];
  const profileInfo = [
    "gender",
    "dateOfBirth",
    "yearsOfExperience",
    "state",
    "city",
    "address",
    "imageUrl",
    "firstName",
    "lastName",
    "email",
    "levelOfEducation",
  ];

  // userInfo.forEach((uInfo) => {
  //   if (!info[uInfo]) {
  //     info[uInfo] = user[uInfo];
  //   }
  // });

  profileInfo.forEach((pInfo) => {
    if (!info[pInfo]) {
      info[pInfo] = profile[pInfo];
    }
  });

  if (!info.phoneNumber) {
    info.phoneNumber = profile.phone;
  }

  if (!info.dateOfBirth) {
    info.dateOfBirth =
      new Date(profile.dateOfBirth) === "Invalid Date" // ||
        ? new Date(formatMaxBirthYear(18))
        : profile.dateOfBirth;
  }

  if (!info.countryId) {
    info.countryId = getCountryAlpha2Code(profile.country);
  }

  return info;
};

const Bio = (props) => {
  const { applicationId, endDate, lang, title } = queryString.parse(
    props.history.location.search
  );
  const [info, setInfo] = useState(personalInfo);
  const [isPersonalInfoInit, setIsPersonalInfoInit] = useState(false);
  const { dispatch } = useContext(Context);
  // const user = JSON.parse(localStorage.getItem("data")).user;
  const [language, setLanguage] = useState(lang);
  const [hasError, setHasError] = useState(false);
  const [isSavingAndContinue, setIsSavingAndContinue] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const ref = useRef();
  const [fileError, setFileError] = useState("");
  const [isuploadingId, setIsUploadingId] = useState(false);

  const [showInstructionModal, setShowInstructionModal] = useState(true);
  const instructionObj = instructions[language || "en"];
  const bioObj = bioInfo[language || "en"];
  const aboutUs = howYouHearAboutUs[language || "en"];
  const idtypes = idTypes[language || "en"];
  const degreeObj = degreeTypes[language || "en"];
  const ageRangeObj = ageRange[language || "en"];
  const declarationTranslations = returnTranslationOption()[language || "en"];

  const { t } = useTranslation();
  const [updatingMessage, setUpdatingMessage] = useState("");

  const toggleInstructionModal = () =>
    setShowInstructionModal(!showInstructionModal);

  const currentUser = useCurrentUser();

  useEffect(() => {
    const lng = lang || "en";
    setLanguage(lng);
    onChange("languageId", lang);
  }, [lang]);

  useEffect(() => {
    const query = props.history.location.search;
    if (query) {
      const parsedQuery = queryString.parse(query);
      if (Object.keys(parsedQuery).length === 0 || !parsedQuery.applicationId) {
        props.history.replace("/programme/dashboard", {});
        props.history.go(1);
      }
    } else {
      props.history.replace("/programme/dashboard", {});
      props.history.go(1);
    }
  }, [props.history.location.search]);

  // const applicationId = queryString.parse(props.history.location.search)
  // .applicationId;

  useEffect(() => {
    window.scrollTo(0, 0);
    retrieveTempUserApplication(applicationId)
      .then((application) => {
        if (!application || application === null) {
          // props.history.replace("/application/tour", {});
          // props.history.go(1);

          props.history.replace("/application/tour", {});
          props.history.go(1);
        } else {
          if (!application.applicationId) {
            props.history.replace("/home", {});
            props.history.go(1);
          } else {
            setInfo({ ...personalInfo, ...application });
            setIsPersonalInfoInit(true);
            return application;
          }
        }
      })
      .then((aplcn) => {
        const { endDate, applicationId, lang, title } = queryString.parse(
          window.location.search
        );
        const currentLocation =
          window.location.pathname +
          "?applicationId=" +
          applicationId +
          "&endDate=" +
          endDate +
          "&lang=" +
          lang +
          "&title=" +
          title;

        if (
          aplcn &&
          aplcn.steps.previousStep !== currentLocation &&
          aplcn.steps.nextStep !== ""
        ) {
          props.history.push(aplcn.steps.nextStep);
        }
      });
  }, []);

  // useEffect(() => {
  //   getUserProfile(dispatch, user.id);
  // }, []);

  useEffect(() => {
    if (currentUser && isPersonalInfoInit) {
      let application = { ...info };
      application = setExistingInfo(application, currentUser);
      setInfo(application);
    }
  }, [currentUser, isPersonalInfoInit]);

  const onChange = (target, value) => {
    if (target === "countryId" || target === "businessCountry") {
      if (target === "countryId") {
        setInfo({ ...info, [target]: value, state: "" });
      }
      if (target === "businessCountry") {
        setInfo({ ...info, [target]: value, businessState: "" });
      }
    } else {
      setInfo({ ...info, [target]: CastValue(target, value) });
    }
  };

  const _handleValidationErrorChanges = (target, value) => {
    const obj = { ...info.validationErrors, [target]: value };
    onChange("validationErrors", obj);
  };

  // const _limitYearsOfExperience = (target, value) => {
  //   if (Number(value) > 30) {
  //     if (value.length >= 3) {
  //       value = value.substr(0, 2);
  //     } else if (value.length === 2) {
  //       value = value.substr(0, 1);
  //     }
  //     onChange(target, value);
  //   } else {
  //     onChange(target, value);
  //   }
  // };

  const hasValidationError = (data) => {
    const appliedForOtherFoundationGrant = data.appliedForOtherFoundationGrant;
    const ignoreThese = [
      // "appliedForOtherFoundationGrant",
      "isIdeaInFieldOfExperience",
      "haveBusinessPartners",
      "isBusinessRural",
      // "isResidentialRural",
      "yearsOfStartupExperience",
      "yearsOfExperience",
      "aboutIdea",
      "sdgs",
      "businessName",
      "businessAddress",
      "businessIndustry",
      "businessState",
      "businessCity",
      "businessCountryId",
      "industrySubSector",
      "businessAge",
      "middleName",
      "isBusinessAddressGreenEconomyOrClimateChange",
      "businessAddressGreenEconomyOrClimateChangeDescription",
      "businessWebsite",
      "businessStage",
      "businessRegistrationNumber",
      "businessLocalGovernmentArea",
      "isBusinessRegistered",
    ];

    const removedFields = [
      "kinPhone",
      "kinEmail",
      "mentorEmail",
      "mentorPhone",
    ];
    const booleanFields = [
      "isResidentialRural",
      "appliedForOtherFoundationGrant",
    ];

    data = { ...data };
    for (const key of ignoreThese) {
      delete data[key];
    }

    let errors = {};
    const errorList = [];
    // validate boolean fields

    booleanFields.forEach((item) => {
      if (typeof data[item] !== "boolean")
        errors = { ...errors, [item]: "Please select" };
    });

    Object.keys(data).forEach((key) => {
      if (!booleanFields.indexOf(key) >= 0) {
        errors = { ...errors, [key]: ValidateJs(key, data[key]) };
      }
    });

    removedFields.forEach((key) => {
      if (data[key] === "") {
        delete errors[key];
      }
    });

    if (!appliedForOtherFoundationGrant) {
      delete errors.nameOfOrganization;
    }

    Object.keys(errors).forEach((key) => {
      if (errors[key] !== null) {
        errorList.push(key);
      }
    });
    const errObj = { ...info, validationErrors: errors };

    setInfo(errObj);

    return { confirmed: errorList.length > 0, errors: errorList };
  };

  const _onIdImageChange = (e) => {
    const files = e.target.files;
    if (files && files[0] && files[0].name && files[0].name.endsWith(".gif")) {
      setFileError("Invalid file format. Only jpeg and png are allowed");
      return;
    }
    if (isImageFile(files && files, false).hasError) {
      setFileError("Invalid file format. Only jpeg and png are allowed");
      return;
    }

    // const fileUrl = URL.createObjectURL(files[0]);
    // const img = new Image();
    // img.onload = () => {
    //   console.log(img.height);
    // };
    // img.src = fileUrl;

    setFileError("");
    setIsUploadingId(true);
    setPreviewImage(files);
    UploadFile(dispatch, false, files)
      .then((res) => {
        const { files } = res;
        onChange("idUrl", files[0]);
        setIsUploadingId(false);
      })
      .catch((e) => {
        setIsUploadingId(false);
      });
  };

  const updateProfileAccount = (info) => {
    const profileDetails = [
      "gender",
      "dateOfBirth",
      "yearsOfExperience",
      "state",
      "city",
      "address",
      "firstName",
      "lastName",
      "email",
      "phoneNumber",
      "countryId",
      "levelOfEducation",
      // "languageId",
    ];
    let profileObj = {};
    profileDetails.forEach((key) => {
      if (info[key]) {
        profileObj = { ...profileObj, [key]: info[key] };
      }
    });
    if (!profileObj.phone) {
      profileObj.phone = info.phoneNumber;
    }

    if (!profileObj.country) {
      profileObj.country = info.countryId;
    }

    // if (!profileObj.language) {
    //   profileObj.language = info.languageId;
    // }

    delete profileObj.countryId;
    delete profileObj.phoneNumber;
    return updateUserProfile(dispatch, profileObj).then(() => {
      return refreshToken();
    });
  };

  const _onSubmit = (e) => {
    e.preventDefault();
    if (loadingSave) return;

    const hasErrors = hasValidationError(info);
    if (!hasErrors.confirmed) {
      setLoadingSave(true);
      setUpdatingMessage("Saving your application progress...");
      const appInfo = JSON.parse(JSON.stringify(info));
      // Object.keys(appInfo.validationErrors).forEach((key) => {
      //   appInfo.validationErrors[key] = null;
      // });
      const currentStep = window.location.pathname;
      const userInfo = _setSteps(
        appInfo,
        currentStep,
        `/application/offcycle/personalinfo/experience?applicationId=${info.applicationId}&endDate=${endDate}&lang=${info.languageId}&title=${title}`,
        ""
      );

      // applicationId is misnamed - this is really programmeEventId
      // TODO(TP-2083): rename to programmeEventId.
      return storeTempUserApplication(userInfo, info.applicationId)
        .then((app) => {
          setUpdatingMessage("Updating profile information...");
          return updateProfileAccount(userInfo).then(() => {
            setLoadingSave(false);
            setUpdatingMessage("");
            return props.history.push({
              pathname: "/application/offcycle/personalinfo/experience",
              search: `?applicationId=${info.applicationId}&endDate=${endDate}&lang=${info.languageId}&title=${title}`,
            });
          });
        })
        .catch((e) => {
          setLoadingSave(false);
        });
    }
    // _scrollToRef(ref);
    document.getElementById(hasErrors.errors[0]).scrollIntoView();
    setHasError(true);
  };

  const _saveAndContinue = (e) => {
    e.preventDefault();
    if (isSavingAndContinue) return;
    setIsSavingAndContinue(true);
    const appInfo = JSON.parse(JSON.stringify(info));
    Object.keys(appInfo.validationErrors).forEach((key) => {
      appInfo.validationErrors[key] = null;
    });
    const currentStep =
      window.location.pathname +
      `?applicationId=${applicationId}&endDate=${endDate}&lang=${lang}&title=${title}`;
    const userInfo = _setSteps(appInfo, currentStep, currentStep, "");
    setUpdatingMessage("Saving your application progress...");
    // applicationId is misnamed - this is really programmeEventId
    // TODO(TP-2083): rename to programmeEventId.
    storeTempUserApplication(userInfo, info.applicationId).then((app) => {
      setUpdatingMessage("Updating profile information...");
      return updateProfileAccount(userInfo).then(() => {
        toast(`${t("your application progress has been saved")}`);
        setIsSavingAndContinue(false);
        // _scrollToRef(ref);
        setUpdatingMessage("");
        window.scrollTo(0, 0);
      });
    });
  };

  return (
    <div className="application-personal-info-c notransalate">
      <div className="application-personal-info-c__heading">
        <h1>{t("TEF Entrepreneurship Application")}</h1>
        <p>{declarationTranslations.welcome}</p>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <span
          className="back-2-somewhere"
          onClick={() => props.history.replace("/home", {})}
        >
          {t("Back to Home")}
        </span>
      </div>
      <div className="application-personal-info-c__form">
        <div className="p-info-form-heading">
          <h5>
            <span className="bold">{bioObj.title1}</span>
          </h5>
          <h4>{sectionOneTranslations[language || "en"]}</h4>
        </div>
        <form ref={ref}>
          {hasError && (
            <span style={{ color: "red" }}>{bioObj.requiredInfo}</span>
          )}
          <div className="row">
            <div className="col-md-6">
              <FormField
                placeholder="e.g Jane"
                label={bioObj.firstname}
                id="firstName"
                // disabled
                name="firstName"
                value={info.firstName}
                onChange={onChange}
                onBlur={() =>
                  _handleValidationErrorChanges(
                    "firstName",
                    ValidateJs("firstName", info.firstName)
                  )
                }
                error={info.validationErrors.firstName}
              />
              {info.validationErrors.firstName &&
                displayError(`${t(info.validationErrors.firstName)}`)}
            </div>
            <div className="col-md-6">
              <FormField
                placeholder="e.g Doe"
                label={bioObj.lastname}
                // disabled
                value={info.lastName}
                id="lastName"
                name="lastName"
                onChange={onChange}
                onBlur={() =>
                  _handleValidationErrorChanges(
                    "lastName",
                    ValidateJs("lastName", info.lastName)
                  )
                }
                error={info.validationErrors.lastName}
              />
              {info.validationErrors.lastName &&
                displayError(`${t(info.validationErrors.lastName)}`)}
            </div>
          </div>
          <FormField
            label={bioObj.middleName}
            placeholder="e.g John"
            value={info.middleName}
            name="middleName"
            id="middleName"
            onChange={onChange}
            // onBlur={() =>
            //   _handleValidationErrorChanges(
            //     "middleName",
            //     ValidateJs("middleName", info.middleName)
            //   )
            // }
            error={info.validationErrors.middleName}
          />
          {info.validationErrors.middleName &&
            displayError(`${t(info.validationErrors.middleName)}`)}
          {/* // t(displayError(info.validationErrors.middleName.toString))} */}

          <div className="" style={{ display: "flex", flexWrap: "wrap" }}>
            <div className="f1 dob">
              {/* <EditableContainer> */}
              <FormField label={bioObj.gender} id="gender">
                <div
                  style={{
                    marginTop: "7px",
                    display: "flex",
                    // flexWrap: "wrap",
                  }}
                >
                  <SelectBox
                    value="male"
                    // className="selected-box"
                    selected={info.gender}
                    icon={<FaMale />}
                    name="gender"
                    onSelect={onChange}
                    label={bioObj.male}
                    // disabled
                  />
                  <SelectBox
                    value="female"
                    icon={<FaFemale />}
                    name="gender"
                    selected={info.gender}
                    onSelect={onChange}
                    label={bioObj.female}
                    // disabled
                  />
                </div>
              </FormField>

              {info.validationErrors.gender ? (
                <div className="displayError" style={{ marginLeft: "5px" }}>
                  {displayError(info.validationErrors.gender)}
                </div>
              ) : (
                <div className="displayError">{displayError("")}</div>
              )}

              {/* </EditableContainer> */}
            </div>
            <div className="f1">
              <FormField
                placeholder="e.g Bond"
                label={bioObj.birthday}
                type="date"
                className="f1"
                max={formatMaxBirthYear(18)}
                onChange={validateBirthYear}
                value={info.dateOfBirth && info.dateOfBirth.split("T")[0]}
                name="dateOfBirth"
                id="dateOfBirth"
              >
                <div
                  className={`rc-dp${
                    info.validationErrors.dateOfBirth
                      ? " default-error-state"
                      : ""
                  }`}
                >
                  <DatePickerInput
                    className="default-error-state"
                    readOnly
                    maxDate={new Date(formatMaxBirthYear(18))}
                    startDate={new Date(formatMaxBirthYear(18))}
                    onChange={(jsDate, dateString) =>
                      validateBirthYear("dateOfBirth", dateString, onChange)
                    }
                    placeholder="MM/DD/YYYY"
                    onHide={() =>
                      _handleValidationErrorChanges(
                        "dateOfBirth",
                        ValidateJs("dateOfBirth", info.dateOfBirth)
                      )
                    }
                    value={info.dateOfBirth}
                    showOnInputClick
                    autoClose={false}
                  />
                </div>
              </FormField>
              {info.validationErrors.dateOfBirth &&
                displayError(`${t(info.validationErrors.dateOfBirth)}`)}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <FormField label={bioObj.ageRange} id="ageRange">
                <Select
                  list={ageRangeObj}
                  defaultVal={bioObj.select1}
                  name="ageRange"
                  onChange={onChange}
                  value={info.ageRange}
                  onBlur={() =>
                    _handleValidationErrorChanges(
                      "ageRange",
                      ValidateJs("ageRange", info.ageRange)
                    )
                  }
                  error={info.validationErrors.ageRange}
                />
              </FormField>
              {info.validationErrors.ageRange ? (
                <div className="displayError" style={{ marginLeft: "5px" }}>
                  {displayError(info.validationErrors.ageRange)}
                </div>
              ) : (
                <div className="displayError">{displayError("")}</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormField
                // className="col-md-6 "
                label={bioObj.phoneNumber}
                id="phoneNumber"
              >
                <div style={{ marginTop: "7.5px" }}>
                  <PhoneInput
                    countryCodeEditable={false}
                    enableSearch
                    country={"ng"}
                    inputClass="phone-input"
                    name="phoneNumber"
                    onChange={(phone) => onChange("phoneNumber", phone)}
                    autoFormat={false}
                    inputStyle={{
                      width: "100%",
                      marginTop: "8px 10px",
                      borderColor: info.validationErrors.phoneNumber
                        ? "red"
                        : "",
                    }}
                    onBlur={() =>
                      _handleValidationErrorChanges(
                        "phoneNumber",
                        ValidateJs("phoneNumber", info.phoneNumber)
                      )
                    }
                    // disabled
                    value={info.phoneNumber}
                  />
                </div>
              </FormField>
              {info.validationErrors.phoneNumber &&
                displayError(`${t(info.validationErrors.phoneNumber)}`)}
            </div>
            <div className="col-md-6" style={{ marginTop: "-7px" }}>
              <FormField
                type="email"
                placeholder={bioObj.email}
                label={bioObj.email}
                value={info.email}
                disabled
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <FormField label={bioObj.education} id="levelOfEducation">
                <Select
                  list={degreeObj}
                  defaultVal={bioObj.select2}
                  name="levelOfEducation"
                  onChange={onChange}
                  value={info.levelOfEducation}
                  onBlur={() =>
                    _handleValidationErrorChanges(
                      "levelOfEducation",
                      ValidateJs("levelOfEducation", info.levelOfEducation)
                    )
                  }
                  error={info.validationErrors.levelOfEducation}
                />
              </FormField>
              {info.validationErrors.levelOfEducation ? (
                <div className="displayError" style={{ marginLeft: "5px" }}>
                  {displayError(info.validationErrors.levelOfEducation)}
                </div>
              ) : (
                <div className="displayError">{displayError("")}</div>
              )}
            </div>

            {/* <div className="col-md-6">
              <FormField
                label={bioObj.experience}
                type="number"
                max="30"
                min="0"
                value={
                  info.yearsOfExperience && info.yearsOfExperience.toString()
                }
                name="yearsOfExperience"
                onChange={_limitYearsOfExperience}
                id="yearsOfExperience"
              />
            </div> */}
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormField
                // className="col-md-6 "
                label={bioObj.country}
                id="countryId"
              >
                <CustomCountryDropdown
                  classes={`flex country-state-list ${
                    info.validationErrors.countryId
                      ? "country-state-list-error-state"
                      : ""
                  }`}
                  value={info.countryId}
                  name="countryId"
                  valueType="short"
                  onChange={(val) => onChange("countryId", val)}
                  onBlur={() =>
                    _handleValidationErrorChanges(
                      "countryId",
                      ValidateJs("countryId", info.countryId)
                    )
                  }
                />
              </FormField>
              {info.validationErrors.countryId ? (
                <div className="displayError" style={{ marginLeft: "3px" }}>
                  {displayError(info.validationErrors.countryId)}
                </div>
              ) : (
                <div className="displayError">{displayError("")}</div>
              )}
            </div>
            <div className="col-md-6">
              <FormField label={bioObj.state} id="state">
                <CustomCountryRegionDropdown
                  countryValueType="short"
                  defaultOptionLabel={bioObj.select2}
                  country={info.countryId}
                  classes={`flex country-state-list ${
                    info.validationErrors.state
                      ? "country-state-list-error-state"
                      : ""
                  }`}
                  name="state"
                  value={info.state}
                  onChange={(val) => onChange("state", val)}
                  onBlur={() =>
                    _handleValidationErrorChanges(
                      "state",
                      ValidateJs("state", info.state)
                    )
                  }
                />
              </FormField>
              {info.validationErrors.state ? (
                <div className="displayError" style={{ marginLeft: "3px" }}>
                  {displayError(info.validationErrors.state)}
                </div>
              ) : (
                <div className="displayError">{displayError("")}</div>
              )}
            </div>
          </div>
          <div>
            <FormField
              label={bioObj.city}
              placeholder={bioObj.cityPlaceholder}
              value={info.city}
              name="city"
              onChange={onChange}
              required
              id="city"
              onBlur={() =>
                _handleValidationErrorChanges(
                  "city",
                  ValidateJs("city", info.city)
                )
              }
              error={info.validationErrors.city}
            />
            {info.validationErrors.city &&
              displayError(info.validationErrors.city)}
          </div>
          <div>
            <FormField
              label={bioObj.address}
              type="textarea"
              placeholder="Type in your residential address"
              value={info.address}
              name="address"
              onChange={onChange}
              required
              id="address"
              onBlur={() =>
                _handleValidationErrorChanges(
                  "address",
                  ValidateJs("address", info.address)
                )
              }
              error={info.validationErrors.address}
            />
            {info.validationErrors.address &&
              displayError(info.validationErrors.address)}
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormField label={bioObj.area} id="isResidentialRural">
                <div className="row">
                  <div className="col-sm-6 gutter-v">
                    <SelectBox
                      value={true}
                      selected={info.isResidentialRural}
                      onSelect={(target, value) => {
                        const errors = { ...info.validationErrors };
                        errors.isResidentialRural = null;
                        setInfo({
                          ...info,
                          [target]: value,
                          validationErrors: errors,
                        });
                      }}
                      name="isResidentialRural"
                      label={bioObj.yes}
                    />
                  </div>
                  <div className="col-sm-6 gutter-v">
                    <SelectBox
                      value={false}
                      selected={info.isResidentialRural}
                      onSelect={(target, value) => {
                        const errors = { ...info.validationErrors };
                        errors.isResidentialRural = null;
                        setInfo({
                          ...info,
                          [target]: value,
                          validationErrors: errors,
                        });
                      }}
                      name="isResidentialRural"
                      label={bioObj.no}
                    />
                  </div>
                </div>
              </FormField>
              {info.validationErrors.isResidentialRural &&
                displayError(`${t(info.validationErrors.isResidentialRural)}`)}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormField
                label={bioObj.applied}
                id="appliedForOtherFoundationGrant"
              >
                <div className="row">
                  <div className="col-sm-6 gutter-v">
                    <SelectBox
                      value={true}
                      selected={info.appliedForOtherFoundationGrant}
                      onSelect={(target, value) => {
                        const errors = { ...info.validationErrors };
                        errors.appliedForOtherFoundationGrant = null;

                        setInfo({
                          ...info,
                          [target]: value,
                          validationErrors: errors,
                        });
                      }}
                      name="appliedForOtherFoundationGrant"
                      label={bioObj.yes}
                    />
                  </div>
                  <div className="col-sm-6 gutter-v">
                    <SelectBox
                      value={false}
                      selected={info.appliedForOtherFoundationGrant}
                      onSelect={(target, value) => {
                        const errors = { ...info.validationErrors };
                        errors.nameOfOrganization = null;
                        errors.appliedForOtherFoundationGrant = null;
                        setInfo({
                          ...info,
                          [target]: value,
                          nameOfOrganization: "",
                          validationErrors: errors,
                        });
                      }}
                      name="appliedForOtherFoundationGrant"
                      label={bioObj.no}
                    />
                  </div>
                </div>
                {info.validationErrors.appliedForOtherFoundationGrant &&
                  displayError(
                    `${t(info.validationErrors.appliedForOtherFoundationGrant)}`
                  )}
              </FormField>
            </div>
          </div>
          <div>
            <FormField
              label={bioObj.ifYes}
              placeholder={bioObj.orgName}
              name="nameOfOrganization"
              value={info.nameOfOrganization}
              onChange={onChange}
              id="nameOfOrganization"
              disabled={!info.appliedForOtherFoundationGrant}
              onBlur={
                info.appliedForOtherFoundationGrant
                  ? () =>
                      _handleValidationErrorChanges(
                        "nameOfOrganization",
                        ValidateJs(
                          "nameOfOrganization",
                          info.nameOfOrganization
                        )
                      )
                  : () =>
                      _handleValidationErrorChanges("nameOfOrganization", null)
              }
              error={info.validationErrors.nameOfOrganization}
            />
            {info.validationErrors.nameOfOrganization &&
              displayError(info.validationErrors.nameOfOrganization)}
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormField label={bioObj.typeOfId} id="typeOfId">
                <Select
                  list={idtypes}
                  name="typeOfId"
                  value={info.typeOfId}
                  onChange={onChange}
                  onBlur={() =>
                    _handleValidationErrorChanges(
                      "typeOfId",
                      ValidateJs("typeOfId", info.typeOfId)
                    )
                  }
                  error={info.validationErrors.typeOfId}
                />
              </FormField>
              {info.validationErrors.typeOfId &&
                displayError(info.validationErrors.typeOfId)}
            </div>

            <div className="col-md-6" style={{ marginTop: "-5px" }}>
              <FormField
                label={bioObj.idNumber}
                id="idNumber"
                type="text"
                onChange={onChange}
                name="idNumber"
                value={info.idNumber}
                // min=
                onBlur={() =>
                  _handleValidationErrorChanges(
                    "idNumber",
                    ValidateJs("idNumber", info.idNumber)
                  )
                }
                placeholder={bioObj.idNumber}
              ></FormField>
              {info.validationErrors.idNumber &&
                displayError(info.validationErrors.idNumber)}
            </div>
          </div>
          <FormField
            label={bioObj.idScan}
            type="file"
            name="idUrl"
            id="idUrl"
            value={previewImage}
            onChange={_onIdImageChange}
            onBlur={() =>
              _handleValidationErrorChanges(
                "idUrl",
                ValidateJs("idUrl", info.idUrl)
              )
            }
            error={info.validationErrors.idUrl}
          >
            <div>
              <div>
                <div
                  className="id-upload-btn col-md-5"
                  onClick={() =>
                    document.getElementById("previewImage").click()
                  }
                >
                  <span>
                    <FaUpload color="white" size={12} />
                  </span>
                  <span>{t("Choose file")}</span>
                  <span>
                    {isuploadingId && (
                      <Loader
                        type="Oval"
                        height={13}
                        width={13}
                        color="white"
                      />
                    )}
                  </span>
                </div>
              </div>
              <input
                type="file"
                // value={previewImage}
                name="previewImage"
                id="previewImage"
                onChange={_onIdImageChange}
                className="id-file-upload"
                accept="image/x-png,image/jpeg"
              />
              {/* <span>loading</span> */}
            </div>

            {info.idUrl && (
              <div className="id-upload" data-testid="id-picture">
                <img
                  src={info.idUrl}
                  alt="avatar"
                  // className="img-fluid"
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
              </div>
            )}
          </FormField>
          {fileError && (
            <span style={{ color: "red", fontSize: "12px" }}>{fileError}</span>
          )}
          {info.validationErrors.idUrl &&
            displayError(info.validationErrors.idUrl)}
          <div>
            <FormField label={bioObj.programme} id="howYouHearAboutUs">
              <Select
                list={aboutUs}
                name="howYouHearAboutUs"
                value={info.howYouHearAboutUs}
                onChange={onChange}
                onBlur={() =>
                  _handleValidationErrorChanges(
                    "howYouHearAboutUs",
                    ValidateJs("howYouHearAboutUs", info.howYouHearAboutUs)
                  )
                }
                error={info.validationErrors.howYouHearAboutUs}
              />
            </FormField>
            {info.validationErrors.howYouHearAboutUs &&
              displayError(info.validationErrors.howYouHearAboutUs)}
          </div>

          <div>
            <FormField
              label={bioObj.motivation}
              type="textarea"
              placeholder={t(
                "Tell us about what motivates you to apply for the program*"
              )}
              value={info.motivation}
              hasCounter
              maxLength={1000}
              name="motivation"
              onChange={onChange}
              required
              id="motivation"
              onBlur={() =>
                _handleValidationErrorChanges(
                  "motivation",
                  ValidateJs("motivation", info.motivation)
                )
              }
              error={info.validationErrors.motivation}
            />
            {info.validationErrors.motivation &&
              displayError(t(info.validationErrors.motivation))}
          </div>

          <br />

          <div className="submit-box">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="submit-btn" onClick={_onSubmit}>
                  <span>{bioObj.continue}</span>
                  {loadingSave && (
                    <Loader type="Oval" color="white" height={15} width={15} />
                  )}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <button
                  className="save-continue-btn"
                  onClick={_saveAndContinue}
                >
                  {isSavingAndContinue ? (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        outline: "none",
                      }}
                    >
                      <span> {t("saving progress")}</span>
                      <span>
                        <Loader
                          type="Oval"
                          color="red"
                          height={15}
                          width={15}
                        />
                      </span>
                    </span>
                  ) : (
                    <span>
                      <span>
                        <FaSave />{" "}
                      </span>{" "}
                      {t("Save to continue later")}
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <CLoader
        loading={!isPersonalInfoInit || loadingSave || isSavingAndContinue}
        additonalInfo={updatingMessage}
      />
      <InstructionModal
        open={showInstructionModal}
        toggle={toggleInstructionModal}
        instructionsObj={instructionObj}
      />
    </div>
  );
};
export default Bio;
