import React from "react";
import DefaultImage from "src/assets/img/defaultAchievementImage.png";
import { FiClock } from "react-icons/fi";
import moment from "moment";
import { useHistory } from "react-router";

const EventCard = ({ classes, eventDetails }) => {
  const history = useHistory();
  return (
    <div
      className={`event-box event-box-upcoming-list ${classes}`}
      onClick={() => history.push(`/alumni-events-detail/${eventDetails.id}`)}
    >
      <div className="media media-cd" style={{ width: "25%" }}>
        <img
          className="event-box__photo"
          src={eventDetails?.bannerUrl || DefaultImage}
          alt="user"
        />
      </div>
      <div className="event-box__detail-box alumniHub-background pr-2">
        <p className="event-box__posted">
          {moment(eventDetails?.createdOn).fromNow()}
        </p>
        <h6 className="event-box__title">{eventDetails?.title}</h6>

        <p className="event-box__time">
          {" "}
          <span className="">
            <FiClock />
          </span>
          <span style={{ fontSize: "12px" }}>
            {" "}
            {moment(eventDetails?.createdOn).format("DD MMMM YYYY")}
          </span>
        </p>
      </div>
    </div>
  );
};

export default EventCard;
