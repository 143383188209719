import React, { useEffect, useContext } from "react";
import Layout from "src/components/Layout/Layout";
import SideRight from "src/modules/homepage/components/SideRight/SideRight";
import FAQ from "src/modules/homepage/components/HomeFaqCard/faq";
import { getApplicationFaq } from "src/requests/ApplicationRequest";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import { refreshToken } from "src/utils/someTokenMenthods";
import queryString from "query-string";
import { Context } from "src/store";
import { Card, Col } from "reactstrap";

const FrequentlyAskedQuestions = (props) => {
  const { dispatch } = useContext(Context);

  useEffect(() => {
    getApplicationFaq(dispatch);
  }, []);

  useEffect(() => {
    const query = props.history.location.search;
    if (query) {
      const parsedQuery = queryString.parse(query);
      if (
        Object.keys(parsedQuery).includes("redirect") ||
        Object.keys(parsedQuery).includes("Redirect")
      ) {
        refreshToken();
      }
    }
  }, [props.history.location.search]);

  return (
    <Layout SideContent={<SideRight />}>
      <Col md={12} sm={12} xs={12} lg={12}>
        <h2 className="faq-heading">TEF Flagship Programme FAQs</h2>
        <p className="personality-text">
          Answers To Frequently Asked Questions On The 2021 TEF Entrepreneurship
          Programme.
        </p>
        <Card>
          <FAQ />
        </Card>
      </Col>
    </Layout>
  );
};

export default ifOnboardedThenShow(FrequentlyAskedQuestions);
