import React from "react";
import { useTranslation } from "react-i18next";
import styles from "src/modules/Application/components/TCmodal/TCmodal.module.scss";

const HowToApplyModal = ({ proceed }) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: "0" }} className={`${styles.TCMain} notranslate`}>
      <h2 className={styles.TCMain__title}>{t("Application-title")}</h2>
      <h3 className={styles.TCMain__redText}>{t("Application-overview")}</h3>
      <p>{t("Application-overview-1")}</p>
      <p>{t("Application-overview-2")}</p>

      <h3 className={styles.TCMain__redText}>{t("How-to-apply-title")}</h3>
      <ol className={styles.TCMain__structuredList}>
        <p>{t("How-to-apply-text")}</p>
        <li>{t("How-to-apply-text-1")}</li>
        <li>{t("How-to-apply-text-2")}</li>
        <li>{t("How-to-apply-text-3")}</li>
        <li>{t("How-to-apply-text-4")}</li>
      </ol>
      <p>{t("How-to-apply-text2")}</p>

      <ol className={styles.TCMain__structuredList}>
        <p>{t("Application-guide")}</p>
        <li>{t("Application-guide-1")}</li>
        <li>{t("Application-guide-2")}</li>
        <li>{t("Application-guide-3")}</li>
        <li>{t("Application-guide-4")}</li>
        <li>{t("Application-guide-5")}</li>
      </ol>
      <p>{t("Application-note")}</p>

      <h3 className={styles.TCMain__redText}>{t("Programme-title")}</h3>
      <p>{t("Programme-title-1")}</p>
      <p>{t("Programme-title-2")}</p>
      <ol className={styles.TCMain__structuredList}>
        <li>
          <span className={styles.TCMain__boldText}>
            {t("Programme-details-1 title")}
          </span>{" "}
          <p>{t("Programme-details-1 text")}</p>
        </li>
        <li>
          <span className={styles.TCMain__boldText}>
            {t("Programme-details-2 title")}
          </span>{" "}
          <p>{t("Programme-details-2 text")}</p>
        </li>
        <li>
          <span className={styles.TCMain__boldText}>
            {t("Programme-details-3 title")}
          </span>{" "}
          <p>{t("Programme-details-3 text")}</p>
        </li>
        <li>
          <span className={styles.TCMain__boldText}>
            {t("Programme-details-4 title")}
          </span>{" "}
          <p>{t("Programme-details-4 text")}</p>
        </li>
        <li>
          <span className={styles.TCMain__boldText}>
            {t("Programme-details-5 title")}
          </span>{" "}
          <p>{t("Programme-details-5 text")}</p>
        </li>
      </ol>
      <div className={styles.TCMain__btn}>
        <button className={styles.TCMain__acceptBtn} onClick={proceed}>
          {t("Continue")}
        </button>
      </div>
    </div>
  );
};

export default HowToApplyModal;
