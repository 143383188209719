/* eslint-disable no-new */
import React from "react";
import styles from "./Header.module.scss";
import tefLOgo from "src/assets/img/logo/logo_200.png";
import HeaderNav from "./HeaderNav/HeaderNav";
import NotificationNav from "./NotificationNav/NotificationNav";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import { LanguageDropdown } from "src/components/LanguageDropdown/LanguageDropdown";

const Header = ({ onOpen }) => {
  return (
    <div className={styles.Header}>
      <div className={styles.Header__menuIcon}>
        <FaBars onClick={onOpen} size={30} color="#000" />
      </div>
      <Link to="/home">
        <img src={tefLOgo} alt="Logo" className={styles.Header__logo} />
      </Link>
      <div className={styles.Header__search}>{/* <SearchBar /> */}</div>
      <HeaderNav />
      <NotificationNav />
      <LanguageDropdown />
    </div>
  );
};

export default Header;
