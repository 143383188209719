import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "src/store";
import { FaAngleLeft } from "react-icons/fa";
import {
  // isGroupMember,
  getAllGroups,
} from "src/requests/StudyGroupsRequest";
import "./Results.scss";
import SuggestedGroupCard from "src/modules/training/StudyGroups/components/SuggestedGroup/SuggestedGroupCard/SuggestedGroupCard";
import Loader from "src/components/Loader/Loader";
import ResultPagination from "src/modules/training/StudyGroups/components/ResultPagination/ResultPagination";
// import { useCurrentUser } from "src/utils/Services/currentUser";

import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import axios from "axios";
import { useHistory } from "react-router";

const Results = () => {
  const user = get_user_info_from_token() && get_user_info_from_token().user;
  const trainingEventId = user && user.training ? user.training[1] : null;

  axios.defaults.headers.common["trainingevent-id"] = trainingEventId;
  // const { userId } = useCurrentUser();
  const { state, dispatch } = useContext(Context);
  // const [usersGroups, setUsersGroups] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [pager, setPager] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    pageNumber: 1,
    pageSize: 5,
  });

  const courseId = new URLSearchParams(location.search).get("course");

  const onPageSelect = (e) => {
    setIsPageLoading(true);
    _getGroups(e.selected + 1);
  };

  useEffect(() => {
    _getGroups(pager.pageNumber);
  }, []);

  const history = useHistory();
  const _getGroups = (pageNumber) => {
    setIsPageLoading(true);
    getAllGroups(
      dispatch,
      { pageSize: pager.pageSize, pageNumber },
      courseId
    ).then((res) => {
      if (res !== undefined && res.status === 200) {
        const {
          data: { pager },
        } = res.data;
        setPager(pager);
        setIsPageLoading(false);
      }
    });
  };

  const { groups } = state.Communities;
  const cards = groups.map((group, index) => {
    return (
      <SuggestedGroupCard
        key={index}
        id={group.id}
        groupLogo={group.imageUrl}
        title={group.name}
        members={group.membersCount}
        location={group.location}
        belongs={group.isMember}
        creatorId={group.userId}
        privacy={group.privacy}
        likeCount={group.likeCount}
      />
    );
  });

  return (
    <div className="results-main">
      <Link
        // to="/training/studygroups"
        className="results-main__heading--back"
        onClick={() => history.goBack()}
      >
        <FaAngleLeft /> &nbsp; Back
      </Link>
      <div className="results-main__heading">
        {/* <h1 className="results-main__heading--title">Suggested Groups</h1> */}
        <div className="suggested-group-heading__text">
          <h1 className="text-h1">suggested study groups</h1>
        </div>
      </div>
      {isPageLoading ? (
        <div className="results-main__loader">
          <Loader color="danger" />
        </div>
      ) : (
        <>
          {cards.length > 0 ? (
            <div className="results-main__results">{cards}</div>
          ) : (
            <div
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              No study group has been create yet for this course.
            </div>
          )}
        </>
      )}
      {(pager.hasPreviousPage || pager.hasNextPage) && (
        <div className="results-main__pagination results-main__pagination--bottom">
          <ResultPagination
            pageCount={pager.pageCount}
            pageRange={1}
            onSelect={onPageSelect}
            current={pager.pageNumber}
          />
        </div>
      )}
    </div>
  );
};

export default Results;
