/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import FriendsGroupCard from "./FriendsGroupCard/FriendsGroupCard";
import Loader from "src/components/Loader/Loader";

const index = ({ groups, groupLoading }) => {
  let list;

  if (groups.length > 0) {
    list = groups.map((group, index) => {
      // list = groups.slice(0, 6).map((group, index) => {
      const {
        id,
        name,
        membersCount,
        privacy,
        imageUrl,
        userId,
        inviteStatus,
      } = group;

      return (
        <FriendsGroupCard
          key={index}
          groupId={id}
          title={name}
          members={membersCount}
          location={privacy}
          groupImage={imageUrl}
          belongs={group.isAMember}
          ownerId={userId}
          inviteStatus={inviteStatus}
        />
      );
    });
  }

  return (
    <div className="friends-group-main">
      <div className="friends-group-heading">
        <div className="friends-group-heading__text">
          <h1 className="text-h1">Your Friends Group</h1>
          <h3 className="text-h3">Groups that your friends are in.</h3>
        </div>
        {/* {groups.length > 6 && ( */}
        <Link
          to="/community/connections-groups"
          className="friends-group-heading__link"
        >
          see all
        </Link>
        {/* )} */}
      </div>
      {groupLoading ? (
        <div className="friends-group-cards-loader">
          <Loader color="danger" />
        </div>
      ) : (
        <div className="friends-group-cards">
          {groups.length > 0 ? (
            list
          ) : (
            <p className="friends-group-cards__no-group">
              Your connections don't belong to any group at this moment
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default index;
