import axios from "src/utils/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { asyncActions } from "src/utils/AsyncUtils";
import {
  GET_ALL_CATEGORIES,
  GET_CATEGORY_GROUPS,
  CREATE_GROUP,
  GET_ALL_GROUPS,
  GET_GROUP,
  JOIN_GROUP,
  LEAVE_GROUP,
  IS_GROUP_MEMBER,
  GET_GROUP_POSTS,
  DELETE_GROUP,
  GET_GROUP_USERS,
  GROUP_INVITE,
  GET_USER_GROUPS,
  SEARCH_GROUPS,
  REMOVE_USER,
  FLAG_A_TOPIC,
  GET_CONNECTIONS_GROUPS,
  GET_STUDY_GROUP_COURSE_MATES,
  GET_STUDY_GROUP_INVITATION_RECEIVED,
  GET_STUDY_GROUP_INVITATION_SENT,
} from "src/actions";
import { StudyGroupURL, trainingURL } from "src/constants";
import { updateQueryStringParameter } from "src/utils/helper";

export const getAllCategories = (dispatch) => {
  dispatch(asyncActions(GET_ALL_CATEGORIES).loading(true));
  return axios({
    method: "get",
    url: `${StudyGroupURL.GET_ALL_CATEGORIES_URL}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_ALL_CATEGORIES).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_ALL_CATEGORIES).success(data));
        dispatch(asyncActions(GET_ALL_CATEGORIES).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_ALL_CATEGORIES).loading(false));
      dispatch(asyncActions(GET_ALL_CATEGORIES).failure(true, error.response));
      return error.response;
    });
};

export const createGroup = (dispatch, payload) => {
  dispatch(asyncActions(CREATE_GROUP).loading(true));
  return axios({
    method: "post",
    url: StudyGroupURL.CREATE_GROUP_URL,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(CREATE_GROUP).loading(false));
      const { data } = response.data;
      if (response.status === 201) {
        dispatch(asyncActions(CREATE_GROUP).success(data));
        dispatch(asyncActions(CREATE_GROUP).loading(false));
        toast.success("Group Successfully Created");
      }
      return response;
    })
    .catch((error) => {
      const {
        data: { message },
      } = error.response;
      const fail = "An error occurred, Please try again ";
      dispatch(asyncActions(CREATE_GROUP).loading(false));
      dispatch(asyncActions(CREATE_GROUP).failure(true, error.response));
      toast.error(message || fail);
      return error.response;
    });
};

export const updateGroup = (dispatch, payload, groupId) => {
  dispatch(asyncActions(CREATE_GROUP).loading(true));
  return axios({
    method: "put",
    url: `${StudyGroupURL.CREATE_GROUP_URL}/${groupId}`,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(CREATE_GROUP).loading(false));
      const { data } = response.data;
      if (response.status === 201) {
        dispatch(asyncActions(CREATE_GROUP).success(data));
        dispatch(asyncActions(CREATE_GROUP).loading(false));
        toast.success("Group Successfully Created");
      }
      return response;
    })
    .catch((error) => {
      const {
        data: { message },
      } = error.response;
      const fail = "An error occurred, Please try again ";
      dispatch(asyncActions(CREATE_GROUP).loading(false));
      dispatch(asyncActions(CREATE_GROUP).failure(true, error.response));
      toast.error(message || fail);
      return error.response;
    });
};

export const getAllGroups = (dispatch, payload, courseId) => {
  const url = payload
    ? `${StudyGroupURL.GET_ALL_GROUPS_URL}?pageNumber=${payload.pageNumber}&maxItem=${payload.pageSize}&courseId=${courseId}`
    : `${StudyGroupURL.GET_ALL_GROUPS_URL}?courseId=${courseId}`;
  dispatch(asyncActions(GET_ALL_GROUPS).loading(true));
  return axios({
    method: "get",
    url,
  })
    .then((response) => {
      dispatch(asyncActions(GET_ALL_GROUPS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_ALL_GROUPS).success(data));
        dispatch(asyncActions(GET_ALL_GROUPS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_ALL_GROUPS).loading(false));
      dispatch(asyncActions(GET_ALL_GROUPS).failure(true, error.response));
      return error.response;
    });
};

export const joinGroup = (dispatch, payload) => {
  dispatch(asyncActions(JOIN_GROUP).loading(true));
  return axios({
    method: "post",
    url: StudyGroupURL.JOIN_GROUP_URL,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(JOIN_GROUP).loading(false));
      if (response.status === 201) {
        dispatch(asyncActions(JOIN_GROUP).success(true));
        dispatch(asyncActions(JOIN_GROUP).loading(false));
        dispatch(asyncActions(JOIN_GROUP).success(false));
      }
      return response;
    })
    .catch((error) => {
      const {
        data: { message },
      } = error.response;
      const fail = "An error occurred, Please try again ";
      dispatch(asyncActions(JOIN_GROUP).loading(false));
      dispatch(asyncActions(JOIN_GROUP).failure(true, error.response));
      toast.error(message || fail);
      return error.response;
    });
};

export const joinPrivateGroup = (dispatch, payload) => {
  dispatch(asyncActions(JOIN_GROUP).loading(true));
  return axios({
    method: "post",
    url: `${StudyGroupURL.GROUP_INVITE_URL}`,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(JOIN_GROUP).loading(false));
      if (response.status === 201) {
        dispatch(asyncActions(JOIN_GROUP).success(true));
        dispatch(asyncActions(JOIN_GROUP).loading(false));
        dispatch(asyncActions(JOIN_GROUP).success(false));
      }
      return response;
    })
    .catch((error) => {
      // const {
      //   data: { message },
      // } = error;
      const fail = "An error occurred, Please try again ";
      dispatch(asyncActions(JOIN_GROUP).loading(false));
      dispatch(asyncActions(JOIN_GROUP).failure(true, error.response));
      toast.error(fail);
      return error;
    });
};

export const leaveGroup = (dispatch, payload) => {
  dispatch(asyncActions(LEAVE_GROUP).loading(true));
  return axios({
    method: "delete",
    url: `${StudyGroupURL.LEAVE_GROUP_URL}/${payload.groupId}/remove-user/${payload.userId}`,
  })
    .then((response) => {
      dispatch(asyncActions(LEAVE_GROUP).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(LEAVE_GROUP).success(true));
        dispatch(asyncActions(LEAVE_GROUP).loading(false));
        dispatch(asyncActions(LEAVE_GROUP).success(false));
      }
      return response;
    })
    .catch((error) => {
      const {
        data: { message },
      } = error.response;
      const fail = "An error occurred, Please try again ";
      dispatch(asyncActions(LEAVE_GROUP).loading(false));
      dispatch(asyncActions(LEAVE_GROUP).failure(true, error.response));
      toast.error(message || fail);
      return error.response;
    });
};

export const isGroupMember = (dispatch, payload) => {
  dispatch(asyncActions(IS_GROUP_MEMBER).loading(true));
  return axios({
    method: "get",
    url: `${StudyGroupURL.IS_GROUP_MEMBER_URL}/${payload}/belongsto`,
  })
    .then((response) => {
      dispatch(asyncActions(IS_GROUP_MEMBER).loading(false));
      const { data } = response.data;
      if (response.status === 200) {
        dispatch(asyncActions(IS_GROUP_MEMBER).success(data));
        dispatch(asyncActions(IS_GROUP_MEMBER).loading(false));
      }
      return response;
    })
    .catch((error) => {
      const {
        data: { message },
      } = error.response;
      const fail = "An error occurred, Please try again ";
      dispatch(asyncActions(IS_GROUP_MEMBER).loading(false));
      dispatch(asyncActions(IS_GROUP_MEMBER).failure(true, error.response));
      toast.error(message || fail);
      return error.response;
    });
};

export const getGroupDetails = (dispatch, payload) => {
  dispatch(asyncActions(GET_GROUP).loading(true));
  return axios({
    method: "get",
    url: `${StudyGroupURL.GET_GROUP_URL}/${payload}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_GROUP).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_GROUP).success(data));
        dispatch(asyncActions(GET_GROUP).loading(false));
      }
      return response;
    })
    .catch((error) => {
      const {
        data: { message },
      } = error.response;
      dispatch(asyncActions(GET_GROUP).loading(false));
      dispatch(asyncActions(GET_GROUP).failure(true, error.response));
      toast.error(message);
      return error.response;
    });
};

export const getAllGroupPosts = (dispatch, payload, pageNumber) => {
  dispatch(asyncActions(GET_GROUP_POSTS).loading(true));
  return axios({
    method: "get",
    url: `${StudyGroupURL.GET_GROUP_POSTS_URL}/${payload}?maxItem=10&pageNumber=${pageNumber}`,
  })
    .then((response) => {
      dispatch(asyncActions(GET_GROUP_POSTS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_GROUP_POSTS).success(data));
        dispatch(asyncActions(GET_GROUP_POSTS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      const {
        data: { message },
      } = error.response;
      const fail = "An error occurred, Please try again ";
      dispatch(asyncActions(GET_GROUP_POSTS).loading(false));
      dispatch(asyncActions(GET_GROUP_POSTS).failure(true, error.response));
      toast.error(message || fail);
      return error.response;
    });
};

export const getCategoryGroups = (dispatch, payload) => {
  const url = `${StudyGroupURL.GET_CATEGORY_GROUPS_URL}/${payload.categoryId}/groups?pageNumber=${payload.pageNumber}&maxItem=${payload.maxItem}`;
  dispatch(asyncActions(GET_CATEGORY_GROUPS).loading(true));
  return axios({
    method: "get",
    url,
  })
    .then((response) => {
      dispatch(asyncActions(GET_CATEGORY_GROUPS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_CATEGORY_GROUPS).success(data));
        dispatch(asyncActions(GET_CATEGORY_GROUPS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_CATEGORY_GROUPS).loading(false));
      dispatch(asyncActions(GET_CATEGORY_GROUPS).failure(true, error.response));
      return error.response;
    });
};

export const deleteGroup = (dispatch, id) => {
  dispatch(asyncActions(DELETE_GROUP).loading(true));
  return axios({
    method: "delete",
    url: `${StudyGroupURL.DELETE_GROUP_URL}/${id}`,
  })
    .then((response) => {
      dispatch(asyncActions(DELETE_GROUP).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(DELETE_GROUP).success(data));
        dispatch(asyncActions(DELETE_GROUP).loading(false));
      }
      return response;
    })
    .catch((error) => {
      const {
        data: { message },
      } = error.response;
      const fail = "An error occurred, Please try again ";
      dispatch(asyncActions(DELETE_GROUP).loading(false));
      dispatch(asyncActions(DELETE_GROUP).failure(true, error.response));
      toast.error(message || fail);
      return error.response;
    });
};

export const getAllGroupUsers = (
  dispatch,
  groupId,
  searchParam,
  pageNumber
) => {
  const params = {
    groupId: groupId,
    pageNumber,
    maxItem: 5,
  };
  if (searchParam) {
    params.searchMembersByName = searchParam;
  }
  dispatch(asyncActions(GET_GROUP_USERS).loading(true));
  return axios({
    method: "get",
    url: `${StudyGroupURL.GET_GROUP_USERS}/${groupId}/users`,
    params,
  })
    .then((response) => {
      dispatch(asyncActions(GET_GROUP_USERS).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(GET_GROUP_USERS).success(response.data));
        dispatch(asyncActions(GET_GROUP_USERS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_GROUP_USERS).loading(false));
      dispatch(asyncActions(GET_GROUP_USERS).failure(true, error.response));
      return error.response;
    });
};

export const groupInvite = (dispatch, payload) => {
  dispatch(asyncActions(GROUP_INVITE).loading(true));
  return axios({
    method: "post",
    url: StudyGroupURL.GROUP_INVITE_URL,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(GROUP_INVITE).loading(false));
      const { data } = response.data;
      if (response.status === 201) {
        dispatch(asyncActions(GROUP_INVITE).success(data));
        dispatch(asyncActions(GROUP_INVITE).loading(false));
        toast.success("Invite Sent Successfully");
      }
      return response;
    })
    .catch((error) => {
      const {
        data: { message },
      } = error.response;
      const fail = "An error occurred, Please try again ";
      dispatch(asyncActions(GROUP_INVITE).loading(false));
      dispatch(asyncActions(GROUP_INVITE).failure(true, error.response));
      toast.error(message || fail);
      return error.response;
    });
};

export const getUserGroups = (dispatch, userId, payload, courseId) => {
  const url = `${StudyGroupURL.GET_USER_GROUPS_URL}/${userId}/belongsto?pageNumber=${payload.pageNumber}&maxItem=${payload.pageSize}&courseId=${courseId}`;
  dispatch(asyncActions(GET_USER_GROUPS).loading(true));
  return axios({
    method: "get",
    url,
  })
    .then((response) => {
      dispatch(asyncActions(GET_USER_GROUPS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_USER_GROUPS).success(data));
        dispatch(asyncActions(GET_USER_GROUPS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_USER_GROUPS).loading(false));
      dispatch(asyncActions(GET_USER_GROUPS).failure(true, error.response));
      return error.response;
    });
};

export const searchForGroups = (dispatch, payload, courseId, pageNumber) => {
  dispatch(asyncActions(SEARCH_GROUPS).loading(true));
  return axios({
    method: "get",
    url: `${StudyGroupURL.SEARCH_GROUPS_URL}=${payload}&courseId=${courseId}&pageNumber=${pageNumber}&maxItem=10`,
  })
    .then((response) => {
      dispatch(asyncActions(SEARCH_GROUPS).loading(false));
      if (response.status === 200) {
        const {
          data: { data },
        } = response.data;
        dispatch(asyncActions(SEARCH_GROUPS).success(data));
        dispatch(asyncActions(SEARCH_GROUPS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      let fail = "An error occurred, Please try again ";
      if (error !== undefined) {
        const {
          data: { message },
        } = error.response;
        fail = message;
      }
      dispatch(asyncActions(SEARCH_GROUPS).loading(false));
      dispatch(asyncActions(SEARCH_GROUPS).failure(true, error.response));
      toast.error(fail);
      return error.response;
    });
};

export const removeUser = (dispatch, { groupId, userId }) => {
  dispatch(asyncActions(REMOVE_USER).loading(true));
  return axios({
    method: "delete",
    url: `${StudyGroupURL.REMOVE_USER_URL}/${groupId}/remove-user/${userId}`,
  })
    .then((response) => {
      dispatch(asyncActions(REMOVE_USER).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(REMOVE_USER).success(data));
        dispatch(asyncActions(REMOVE_USER).loading(false));
      }
      return response;
    })
    .catch((error) => {
      const {
        data: { message },
      } = error.response;
      const fail = "An error occurred, Please try again ";
      dispatch(asyncActions(REMOVE_USER).loading(false));
      dispatch(asyncActions(REMOVE_USER).failure(true, error.response));
      toast.error(message || fail);
      return error.response;
    });
};

export const flagATopic = (dispatch, payload) => {
  dispatch(asyncActions(FLAG_A_TOPIC).loading(true));
  return axios({
    method: "post",
    url: StudyGroupURL.FLAG_A_TOPIC_URL,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(FLAG_A_TOPIC).loading(false));
      const { data } = response.data;
      if (response.status === 201) {
        dispatch(asyncActions(FLAG_A_TOPIC).success(data));
        dispatch(asyncActions(FLAG_A_TOPIC).loading(false));
        toast.success("Post Flagged Successfully");
      }
      return response;
    })
    .catch((error) => {
      const {
        data: { message },
      } = error.response;
      const fail = "An error occurred, Please try again ";
      dispatch(asyncActions(FLAG_A_TOPIC).loading(false));
      dispatch(asyncActions(FLAG_A_TOPIC).failure(true, error.response));
      toast.error(message || fail);
      return error.response;
    });
};

export const getConnectionsGroups = (dispatch, userId, payload) => {
  const url = payload
    ? `${StudyGroupURL.GET_CONNECTIONS_GROUPS_URL}/${userId}?pageNumber=${payload.pageNumber}&maxItem=${payload.pageSize}`
    : `${StudyGroupURL.GET_CONNECTIONS_GROUPS_URL}/${userId}`;
  dispatch(asyncActions(GET_CONNECTIONS_GROUPS).loading(true));
  return axios({
    method: "get",
    url,
  })
    .then((response) => {
      dispatch(asyncActions(GET_CONNECTIONS_GROUPS).loading(false));
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_CONNECTIONS_GROUPS).success(data));
        dispatch(asyncActions(GET_CONNECTIONS_GROUPS).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_CONNECTIONS_GROUPS).loading(false));
      dispatch(
        asyncActions(GET_CONNECTIONS_GROUPS).failure(true, error.response)
      );
      return error.response;
    });
};

export const getCoursemates = (dispatch, courseId, payload) => {
  let url = `${trainingURL.TRAINING_GENERAL}/course/${courseId}/users/?maxItem=10&PageNumber=${payload.pageNumber}`;
  if (payload.search)
    url = updateQueryStringParameter(url, "search", payload.search);
  // const url = `${trainingURL.TRAINING_GENERAL}/dashboard/overview/course/${courseId}/user-course/?maxItem=10&PageNumber=${payload.pageNumber}`;
  dispatch(asyncActions(GET_STUDY_GROUP_COURSE_MATES).loading(true));
  return axios({
    method: "get",
    url,
  })
    .then((response) => {
      dispatch(asyncActions(GET_STUDY_GROUP_COURSE_MATES).loading(false));
      if (response.status === 200) {
        const data = response.data;
        dispatch(asyncActions(GET_STUDY_GROUP_COURSE_MATES).success(data));
        dispatch(asyncActions(GET_STUDY_GROUP_COURSE_MATES).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_STUDY_GROUP_COURSE_MATES).loading(false));
      dispatch(
        asyncActions(GET_STUDY_GROUP_COURSE_MATES).failure(true, error.response)
      );
      return error.response;
    });
};

export const likeStudyGroup = (groupId, userId) => {
  return axios({
    url: `${StudyGroupURL.GET_GROUP_URL}/${groupId}/user/${userId}/toggle-like`,
    method: "post",
  });
};

export const getGroupInvitesReceived = (
  dispatch,
  userId,
  status,
  pageNumber
) => {
  const url = `${trainingURL.TRAINING_GENERAL}/groupinvite/user/${userId}/received?status=${status}&maxItem=10&PageNumber=${pageNumber}`;
  dispatch(asyncActions(GET_STUDY_GROUP_INVITATION_RECEIVED).loading(true));
  return axios({
    method: "get",
    url,
  })
    .then((response) => {
      dispatch(
        asyncActions(GET_STUDY_GROUP_INVITATION_RECEIVED).loading(false)
      );
      if (response.status === 200 || response.status === 201) {
        const data = response.data;
        const result = data.data;
        dispatch(
          asyncActions(GET_STUDY_GROUP_INVITATION_RECEIVED).success(result)
        );
        dispatch(
          asyncActions(GET_STUDY_GROUP_INVITATION_RECEIVED).loading(false)
        );
      }
      return response;
    })
    .catch((error) => {
      dispatch(
        asyncActions(GET_STUDY_GROUP_INVITATION_RECEIVED).loading(false)
      );
      dispatch(
        asyncActions(GET_STUDY_GROUP_INVITATION_RECEIVED).failure(
          true,
          error.response
        )
      );
      return error.response;
    });
};

export const getGroupInvitesSent = (dispatch, userId, pageNumber) => {
  const url = `${trainingURL.TRAINING_GENERAL}/groupinvite/user/${userId}/sent?maxItem=5&PageNumber=${pageNumber}&status=pending_invite`;
  dispatch(asyncActions(GET_STUDY_GROUP_INVITATION_SENT).loading(true));
  return axios({
    method: "get",
    url,
  })
    .then((response) => {
      dispatch(asyncActions(GET_STUDY_GROUP_INVITATION_SENT).loading(false));
      if (response.status === 200 || response.status === 201) {
        const data = response.data;
        const result = data.data;
        dispatch(asyncActions(GET_STUDY_GROUP_INVITATION_SENT).success(result));
        dispatch(asyncActions(GET_STUDY_GROUP_INVITATION_SENT).loading(false));
      }
      return response;
    })
    .catch((error) => {
      dispatch(asyncActions(GET_STUDY_GROUP_INVITATION_SENT).loading(false));
      dispatch(
        asyncActions(GET_STUDY_GROUP_INVITATION_SENT).failure(
          true,
          error.response
        )
      );
      return error.response;
    });
};

export const studyGroupAcceptInvite = (inviteId) => {
  return axios({
    url: `${trainingURL.TRAINING_GENERAL}/groupinvite/${inviteId}/accept`,
    method: "post",
  });
};

export const studyGroupCancelInvite = (inviteId, userId) => {
  return axios({
    url: `${trainingURL.TRAINING_GENERAL}/groupinvite/${inviteId}/cancel?userId=${userId}`,
    method: "post",
  });
};

export const getGroupRequests = (groupId, status, payload) => {
  let params = {
    pageNumber: payload.pageNumber || 1,
  };
  Object.keys(payload).forEach((k) => {
    if (payload[k]) {
      params = { ...params, [k]: payload[k] };
    }
  });
  return axios({
    url: `${trainingURL.TRAINING_GENERAL}/groupinvite/group/${groupId}/received?groupId=${groupId}&status=${status}`,
    method: "get",
    params,
  });
};
