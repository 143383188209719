import React, { useEffect, useState, useContext } from "react";
import LMSCourseList from "src/modules/training/components/LMSCourseCard/LMSCourseList";
import classes from "./LMSDashboard.module.css";
import {
  getCourses,
  getFaq,
  // getUserCourses,
  // getUserRegisteredCourses,
} from "src/requests/TrainingRequest";
import { Context } from "src/store";
import Layout from "src/components/Layout/Layout";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { showLoading } from "src/modules/training/components/CLoader/Cloader";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { Col } from "reactstrap";
import { TabContent, Tabs } from "src/components/CustomTab/CustomTab";
import { getLanguage } from "src/utils/helper";
import { useHistory } from "react-router";
import Requests from "src/modules/training/StudyGroups/components/Main/Requests/Requests";
import TrainingFAQ from "src/modules/training/StudyGroups/components/Main/FAQ";

const LMSDashboard = () => {
  const { dispatch } = useContext(Context);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userRegisteredCourses, setUserRegisteredCourses] = useState([]);
  const [loadingFaq, setLoadingFaq] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const user = get_user_info_from_token().user;
  // const trainingEventId = user && user.training ? user.training[1] : null;
  const [activeTab, setActiveTab] = useState("Courses");
  const tabs = ["Courses", "Study groups requests", "FAQ"];

  const isActiveTab = (value) =>
    activeTab.toLowerCase() === value.toLowerCase();

  // const toggleTab = (value) => setActiveTab(value);
  const history = useHistory();

  const updateActiveTabfromQuery = (activeTab) => {
    history.push({
      pathname: window.location.pathname,
      search: `?activeTab=${activeTab}`,
    });
  };

  useEffect(() => {
    const activeTab = new URLSearchParams(location.search).get("activeTab");
    if (activeTab) {
      setActiveTab(activeTab);
    }
  }, [location.search]);

  useEffect(() => {
    if (user.training) {
      _getCourses(user.training[1]);
    } else {
      _getCourses();
    }
    _getFaq();

    // _getUserRegisteredCourses(user.training[1]);
    setUserRegisteredCourses([]);
  }, []);

  const _getCourses = (trainingEventId) => {
    setLoading(true);
    getCourses(dispatch, trainingEventId, getLanguage()).then((res) => {
      if (res && res.status === 200) {
        const { data } = res.data;
        setCourses(data);
        setLoading(false);
      }
    });
  };

  const _getFaq = () => {
    setLoadingFaq(true);
    getFaq()
      .then((res) => {
        const { data } = res;
        if (data && data.data) {
          setFaqs(data.data);
        } else {
          setFaqs([]);
        }
        setLoadingFaq(false);
      })
      .catch((e) => {
        setFaqs([]);
        setLoadingFaq(false);
      });
  };

  return (
    <Layout SideContent={<SidebarRight />}>
      {/* <span className="goals-tab-ind goals-tab-active-item">Courses</span> */}
      {/* <br />
      <br /> */}
      {/* <div className={classes.LMSCardContainer}>
        {loading ? (
          showLoading()
        ) : (
          <LMSCourseList
            courses={courses}
            userCourses={userRegisteredCourses}
          />
        )}
      </div> */}
      <Col>
        <div className="goals-tab-c">
          {tabs.map((t, idx) => {
            return (
              <span
                key={idx}
                onClick={() => updateActiveTabfromQuery(t)}
                className={`goals-tab-ind ${
                  isActiveTab(t) ? "goals-tab-active-item" : ""
                }`}
              >
                {t}
              </span>
            );
          })}
        </div>

        <Tabs activeTab={activeTab}>
          <TabContent title="Courses">
            <div className={classes.LMSCardContainer}>
              {loading ? (
                showLoading()
              ) : (
                <LMSCourseList
                  courses={courses}
                  userCourses={userRegisteredCourses}
                />
              )}
            </div>
          </TabContent>

          <TabContent title="Study groups requests">
            <Requests />
          </TabContent>

          <TabContent title="FAQ">
            <TrainingFAQ list={faqs} loading={loadingFaq} />
          </TabContent>
        </Tabs>
      </Col>
    </Layout>
  );
};

export default LMSDashboard;
