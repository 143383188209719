import React from "react";
import Resources from "./Resources";
import classes from "./LandingPage.module.scss";
import Trade from "./Trade";
import img1 from "src/assets/img/landingPage/landingPagem8.webp";
// import img2 from "src/assets/img/landingPage/landingPsm1.webp";
// import img3 from "src/assets/img/landingPage/landingPagesm2.webp";

const Community = () => {
  return (
    <div className={classes.communityContainer}>
      <div className={classes.commC}>
        <div className={classes.commC__left}>
          {/* <div className={classes.comm_orange_dot}></div> */}
          <div className={classes.img_container}>
            <img src={img1} className={`img-fluid`} />
            {/* <div className={classes.img_sm_top}>
              <img
                src={img2}
                className={`img-fluid`}
                alt="img"
                style={{ maxWidth: "198px", width: "100%" }}
              />
            </div>

            <div className={classes.img_sm_bt}>
              <img src={img3} className={`img-fluid`} alt="img" />
            </div> */}
          </div>
        </div>

        <div className={classes.commC__right}>
          <h4>Community</h4>
          <h3>
            Connect with a global network of business leaders at the touch of a
            button
          </h3>
        </div>
      </div>
      <Resources />
      <Trade />
    </div>
  );
};

export default Community;
