/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from "react";
import { Row, Card, Collapse, Spinner } from "reactstrap";
import Swal from "sweetalert2";
import { getFaq } from "src/requests/ApplicationRequest";

const FAQ = () => {
  const [isOpen, setIsOpen] = useState("");
  const [faqs, setFaq] = useState([]);
  const [loader, setLoader] = useState(undefined);

  const toggle = (index) => {
    if (isOpen === index) {
      setIsOpen(false);
    } else {
      setIsOpen(index);
    }
  };

  useEffect(() => {
    getFaq()
      .then((response) => {
        setLoader(true);
        if (response.status === 200) {
          const { data } = response.data;
          setFaq(data);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Could not fetch frequently asked questions at this time",
        });

        return error;
      });
  }, []);

  return (
    <div>
      {loader && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      )}

      <Row>
        {faqs.map((faq, index) => (
          <div
            style={{ width: "100%" }}
            className="ml-5 mt-5 mr-5 mb-0"
            key={index}
          >
            <Card
              onClick={() => toggle(index)}
              style={{
                padding: "1.8%",
                // width: "658px",
                // width:"inherit",
                minHeight: "49px",
                maxHeight: "100%",
                cursor: "pointer",
              }}
            >
              <p className="faq-question d-flex justify-content-between">
                {" "}
                <span> {faq.question}</span>
                <span className="font-weight-bold">+</span>{" "}
              </p>
              <Collapse isOpen={isOpen !== false && isOpen === index}>
                <hr />
                <span style={{ fontSize: "12px" }}>{faq.solution}</span>
              </Collapse>
            </Card>
          </div>
        ))}
      </Row>
    </div>
  );
};

export default FAQ;
