/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useContext, useEffect } from "react";
import { Context } from "src/store";
import {
  getGroupDetails,
  // isGroupMember,
  getAllGroupPosts,
  updateGroup,
} from "src/requests/CommunityRequest";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import "./Group.scss";
import moment from "moment";
import SideInfo from "./component/SideInfo/SideInfo";
import CreatePost from "src/modules/homepage/components/CreatePost/CreatePost";
import CoverPhoto from "src/components/CoverPhoto/CoverPhoto";
import PostCard from "src/modules/homepage/components/PostCard/PostCard";
import Layout from "src/components/Layout/Layout";
import PageLoader from "src/modules/homepage/components/PageLoader/PageLoader";
import SideRight from "src/modules/homepage/components/SideRight/SideRight";
import MobileSideInfo from "./component/MobileSideInfo";
import { useCurrentUser } from "src/utils/Services/currentUser";
import ErrorBoundaryComponentLevel from "src/utils/Services/ErrorBoundaryComponentLevel";
import { isAllowedFiles, UploadFile } from "src/requests/fileUploadRequest";
import _ from "lodash";

const Group = () => {
  const { imageUrl, userId, firstName, lastName } = useCurrentUser();
  const userName = firstName + " " + lastName;
  const { state, dispatch } = useContext(Context);
  const qa = useLocation();
  const { groupId } = queryString.parse(qa.search);
  const [groupInfo, setGroupInfo] = useState({});
  const [belongsToGroup, setBelongsToGroup] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [groupPosts, setGroupPosts] = useState([]);
  const [isCoverPhotoLoading, setIsCoverPhotoLoading] = useState(false);

  let postCard;

  useEffect(() => {
    _getGroupDetails();
  }, [state.Posts.post, state.FileUpload]);

  const _getGroupDetails = () => {
    getGroupDetails(dispatch, groupId).then((groupResponse) => {
      if (groupResponse !== undefined && groupResponse.status === 200) {
        const {
          data: { data },
        } = groupResponse;
        setGroupInfo(data);
        setBelongsToGroup(data.isAMember);
        setIsPageLoading(false);
      }
    });
  };

  // const getGroupUserBelongsTo = () => {
  //   isGroupMember(dispatch, userId).then((res) => {
  //     if (res !== undefined && res.status === 200) {
  //       const {
  //         data: { data },
  //       } = res.data;
  //       setBelongsToGroup(
  //         Boolean(Number(data.filter((group) => group.id === groupId).length))
  //       );
  //     }
  //   });
  // };

  useEffect(() => {
    getAllGroupPosts(dispatch, groupId).then((post) => {
      if (post !== undefined && post.status === 200) {
        const {
          data: {
            data: { data },
          },
        } = post;
        setGroupPosts(data);
      }
    });
  }, [state.Posts.post, state.Posts.vote]);

  useEffect(() => {
    // getGroupUserBelongsTo();
  }, [!!state.Communities.joinGroup, !!state.Communities.leaveGroup]);

  if (groupPosts.length > 0) {
    postCard = groupPosts.map((post, index) => {
      const user = post.profile ? post.profile : "";
      const userDp =
        user.imageUrl === "" ||
        user.imageUrl === null ||
        user.imageUrl === undefined
          ? ""
          : user.imageUrl;
      return (
        <ErrorBoundaryComponentLevel key={post.id}>
          <PostCard
            key={index}
            postId={post.id}
            name={user ? user.firstName + " " + user.lastName : "Anonymous"}
            postDate={moment(post.createdOn).format("MMMM Do YYYY, h:mm:ss a")}
            post={post.content}
            likeCount={post.likeCount}
            commentCount={post.commentCount}
            shareCount={post.shareCount}
            userId={user ? user.userId : ""}
            profilePic={userDp}
            postImages={post.postFiles}
            groupName={groupInfo.name}
            hideComment={!belongsToGroup}
          />
        </ErrorBoundaryComponentLevel>
      );
    });
  } else {
    postCard = (
      <h2 className="no-post">
        No post at the moment
        <br />
        <span className="no-post__sub">
          Be the first to post here{" "}
          <span role="img" aria-label="joy">
            😊
          </span>
        </span>
      </h2>
    );
  }

  const handleUpdateGroup = async (payload) => {
    const response = await updateGroup(dispatch, payload);
    if (response.status === 200) {
      setIsCoverPhotoLoading(false);
    }
  };

  const handleCoverPhotoChange = (e) => {
    setIsCoverPhotoLoading(true);
    const existingFile = [];
    const validateFile = isAllowedFiles(Array.from([...e.target.files]), true);
    if (validateFile.hasError) {
      validateFile.errors.forEach((_file) => {});
    } else {
      existingFile.push(...e.target.files);
      const uniqueFile = _.uniqBy(existingFile, "name");
      if (uniqueFile.length > 0) {
        UploadFile(dispatch, true, uniqueFile).then((res) => {
          if (res && res.status === 200) {
            const { files } = res;
            const payload = {
              id: groupInfo.id,
              name: groupInfo.name,
              about: groupInfo.about,
              imageUrl: groupInfo.imageUrl,
              coverImage: files[0],
              userId: groupInfo.userId,
              phoneNumber: groupInfo.phoneNumber,
              email: groupInfo.email,
              categoryId: groupInfo.categoryId,
              privacy: groupInfo.privacy,
              location: groupInfo.location,
            };
            handleUpdateGroup(payload);
          }
        });
      }
    }
  };

  return (
    <Layout SideContent={<SideRight />}>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <div className="community-group-home">
          <nav className="community-group-home__side d-none d-md-block">
            <SideInfo
              groupLogo={groupInfo.imageUrl}
              groupTitle={groupInfo.name}
              groupType={groupInfo.privacy}
              createdBy={
                groupInfo.user
                  ? groupInfo.user.firstName + " " + groupInfo.user.lastName
                  : "Anonymous"
              }
              about={groupInfo.about}
              membersCount={groupInfo.membersCount}
              NoOfPosts={groupPosts.length}
              member={belongsToGroup}
              creatorPhoneNumber={groupInfo.phoneNumber}
              creatorEmail={groupInfo.email}
              groupCategory={groupInfo.category.name}
              createdDate={moment(groupInfo.createdOn).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}
              creatorId={groupInfo.userId}
              createdByDp={groupInfo.user && groupInfo.user.imageUrl}
              inviteStatus={groupInfo.inviteStatus}
              getGroupDetails={_getGroupDetails}
            />
          </nav>
          <main className="community-group-home__main">
            <div className="community-group-home__main--container">
              <CoverPhoto
                coverPhoto={groupInfo.coverImage}
                groupTitle={groupInfo.name}
                showButton={groupInfo.userId === userId}
                handleCoverPhotoChange={handleCoverPhotoChange}
                loading={isCoverPhotoLoading}
              />
              {groupInfo.privacy.toLowerCase() === "private" &&
              !belongsToGroup ? (
                <h5 className="no-member">
                  <span role="img" aria-label="closed lock">
                    🔒
                  </span>{" "}
                  Only members can see who's in the group and what they post
                </h5>
              ) : belongsToGroup ? (
                <CreatePost
                  userName={userName}
                  groupId={groupId}
                  dp={imageUrl}
                />
              ) : (
                <h5 className="no-member">
                  Only members can add posts/comments
                </h5>
              )}

              <div className="d-block d-md-none">
                <MobileSideInfo
                  creatorId={groupInfo.userId}
                  member={belongsToGroup}
                  groupType={groupInfo.privacy}
                  groupTitle={groupInfo.name}
                  inviteStatus={groupInfo.inviteStatus}
                  getGroupDetails={_getGroupDetails}
                />
              </div>
              {groupInfo.privacy.toLowerCase() === "private" && !belongsToGroup
                ? ""
                : postCard}
            </div>
          </main>
        </div>
      )}
    </Layout>
  );
};

export default Group;
