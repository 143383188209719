import React from "react";
import Loader from "react-loader-spinner";

const CustomLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "5rem",
      }}
    >
      <Loader type="Oval" color="#FF0000" height={50} width={50} />
    </div>
  );
};

export default CustomLoader;
