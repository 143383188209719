import React from "react";
import { FaAngleLeft } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import "./Document.scss";

const Privacy = () => {
  const history = useHistory();
  const onBackClick = (e) => {
    e.preventDefault();
    history.goBack();
  };
  return (
    <div className="document">
      <Link to="#" onClick={onBackClick}>
        <h6 className="declaration-h6">
          <FaAngleLeft /> &nbsp; Back
        </h6>
      </Link>
      <h3 className="red-text bolder-text center">
        Data Protection Privacy Policies
      </h3>
      <p>
        We at The <span className="red-text">Tony Elumelu Foundation</span>{" "}
        treat your privacy with the highest importance. Our Data Protection
        Privacy Policies detail the measures we take to preserving and safely
        guarding your privacy when you visit our website or generally
        communicate with our personnel. These Data Protection Privacy Policies
        have been approved and provided by our legal advisors.
      </p>
      <p>These policies include:</p>
      <ol className="alpha">
        <li>
          <a href="#privacy" className="blue-link">
            Privacy Policies
          </a>
          <ol className="roman">
            <li>Data Collection</li>
            <li>Your Personal Data and how it is Used</li>
            <li>Change of Purpose</li>
            <li>Your Personal Data Rights </li>
            <li>Persons who have access to your Personal Data </li>
            <li>Security &amp; Confidentiality </li>
            <li>Transfer of Personal Data outside Nigeria</li>
            <li>Retention of Personal Data</li>
            <li>Third Party Links</li>
          </ol>
        </li>
        <li>
          <a href="#cookies" className="blue-link">
            Cookies Policy
          </a>
        </li>
        <li>
          <a href="#sar" className="blue-link">
            Subject Access Request Response Procedure
          </a>
        </li>
      </ol>
      <p>
        We undertake regular updates to our Data Protection Privacy Policies
        which we will notify you of on our website and/or by email.
      </p>

      <h3 className="red-text bolder-text">Glossary</h3>
      <ul>
        <li>
          <b>Affiliated Third Parties</b> includes companies with which we have
          common ownership or management or other contractual strategic support
          or partnership relationships with, our advisers, consultants, bankers,
          vendors or sub-contractors.
        </li>
        <li>
          <b>Data</b> is information, which is stored electronically, on a
          computer, or in certain paper-based filing systems.
        </li>
        <li>
          <b>Data Controller</b> is a person responsible for determining the
          manner in which Personal Data would be processed.
        </li>
        <li>
          <b>NDPR</b> means the Nigerian Data Protection Regulations
        </li>
        <li>
          <b>NITDA</b> means the National Information Technology Development
          Agency
        </li>
        <li>
          <b>Personal Data</b> is the information relating to an identified or
          identifiable natural person. These include a name, gender, a photo, an
          email address, bank details, medical information, computer internet
          protocol address and any other information specific to the physical,
          physiological, genetic, mental, economic, cultural or social identity
          of that natural person.
        </li>
        <li>
          <b>Processing</b> is any activity that involves use of Personal Data.
          It includes obtaining, recording or holding the data, or carrying out
          any operation or set of operations on the data including organizing,
          amending, recording, retrieving, using, disclosing, erasing or
          destroying it. Processing also includes transferring personal data to
          third parties.
        </li>
        <li>
          <b>Sensitive Personal Data</b> includes information about a person’s
          racial origin, political opinions, religious or similar beliefs, trade
          union membership, physical or mental health or condition or sexual
          life.
        </li>
      </ul>

      <h3 id="privacy" className="bolder-text center">
        Privacy Policy
      </h3>
      <ol>
        <li>
          <span className="red-text bolder-text">Data Collection</span>
          <p>
            We may collect, use, store and transfer different kinds of Personal
            Data about you which we have grouped together as follows:
          </p>
          <ul>
            <li>
              <b>Identity Data</b> includes first name, last name, username or
              similar identifier, title, date of birth and gender.
            </li>
            <li>
              <b> Contact Data</b> includes residential address, email address
              and telephone numbers.
            </li>
            <li>
              <b>Human Resource Data</b> includes information on your employment
              history, professional and educational information submitted upon
              applying for employment with us.{" "}
            </li>
            <li>
              <b>Technical Data</b> includes internet protocol (IP) address,
              domain name, your login data, browser type and version, time zone
              setting and location, browser plug-in types and versions,
              operating system and platform, and other technology on the devices
              you use to access this website.
            </li>
            <li>
              <b>Profile Data</b> includes your username and password, purchases
              or orders made by you, your interests, preferences, feedback and
              survey responses
            </li>
            <li>
              <b>Usage Data</b> includes information about how you use our
              website and services.
            </li>
            <li>
              <b>Marketing and Communications Data</b> includes your preferences
              in receiving marketing communications from us and our Affiliated
              Third Parties and your communication preferences.
            </li>
          </ul>
          <p>
            You provide this information through direct interaction when you
            visit our website, sign up to our newsletters or publications,
            request marketing materials to be sent to you, respond to surveys,
            complete our feedback or comment form, provide your business card to
            any of our staff, sign our visitor management form, complete other
            forms, apply for employment through our careers page, or{" "}
            <Link className="blue-link" to="/feedback">
              contact us
            </Link>{" "}
            to request for any information or other correspondence by post,
            email, our website or otherwise.
          </p>
          <p>
            As you interact with our website, we will automatically collect
            technical data about your equipment, browsing actions and patterns.
            We collect this data by using cookies, and other similar
            technologies. Please see our{" "}
            <a href="#cookies" className="blue-link">
              cookies policy
            </a>{" "}
            for further details.{" "}
          </p>
          <p>
            We do not intentionally or knowingly collect any Sensitive Personal
            Data. We ask that you do not send to us nor disclose such Sensitive
            Personal Data save where required for a specific purpose.
          </p>
        </li>
        <li>
          <span className="red-text bolder-text">
            Your Personal Data and how it is Used
          </span>
          <p>
            Primarily, we collect, process and store your Personal Data to help
            us better connect with you. The following are the purposes for which
            we collect your Personal Data:
          </p>
          <ol className="alpha">
            <li>
              To monitor, review, evaluate and improve your experience when you
              visit our website{" "}
            </li>
            <li>
              To analyse the traffic on our website, including determining the
              number of visitors to the website and analyse how they navigate
              the website.
            </li>
            <li>
              To invite you to complete a survey or provide feedback to us on
              specific matters.
            </li>
            <li>
              At any time, you request information from us via a form or other
              electronic transmission we may use your Personal Data to fulfil
              that request and keep a record of such request and how it was
              handled, for quality assurance and service improvement purposes.
            </li>
            <li>
              To keep you updated on our activities, programmes and events where
              your explicit consent has been given.{" "}
            </li>
            <li>
              To notify you of changes to our websites or relevant processes.
            </li>
            <li>
              We may also use your information or allow Affiliated Third Parties
              such as our affiliate companies or partners use of this Personal
              Data, to offer you information about unrelated products or
              services you may be interested in. We or such Affiliated Third
              Parties can only communicate with you if you have expressly
              consented to such communication and data use.{" "}
            </li>
            <li>
              We may share your personal data with Affiliated Third Parties such
              as service providers who we have engaged to assist with providing
              certain services on our behalf, for which they require your
              personal data.{" "}
            </li>
            <li>
              Where we have any contracts with you which create a commitment, we
              may require contact or use of your information to perform the
              contract.
            </li>
            <li>
              To process or manage your appointments with any of our staff.
            </li>
            <li>
              To fulfil legal/ regulatory obligations or to report any criminal
              or unethical activity.
            </li>
            <li>
              To store either on our central computer system or a third-party
              Computer’s central computer system for archiving and back up
              purposes.
            </li>
          </ol>
          <p>
            Be aware that we do not reveal identifiable information about you to
            our advertisers, though we may at times share statistical visitor
            information with our advertisers.{" "}
          </p>
        </li>
        <li>
          <span className="red-text bolder-text">Change of Purpose</span>
          <p>
            We will only use your Personal Data for the aforementioned purposes,
            unless we reasonably consider that we need to use it for another
            reason and that reason is compatible with the original purpose. If
            you wish to get an explanation as to how the processing for the new
            purpose is compatible with the original purpose, please contact us.
          </p>
          <p>
            If we need to use your Personal Data for an unrelated purpose, we
            will notify you and request for your express consent.
          </p>
        </li>
        <li>
          <span className="red-text bolder-text">
            Your Personal Data Rights
          </span>
          <p>
            Data Protection Laws provides you with certain rights in relation to
            the information that we collect about you.
          </p>
          <ol className="alpha">
            <li>
              The right to withdraw consent previously given to us or our
              Affiliated Third Parties. In order to make use of your personal
              data, we would have obtained your consent. For consent to be
              valid, it must be given voluntarily. In line with regulatory
              requirements, consent cannot be implied, and we ensure that you
              have the opportunity to read our data protection privacy policy
              before you provide your consent. Consent in respect of Sensitive
              Personal Data must be explicit and will be given by you in writing
              to us. The consent of minors (under the age of 18) will always be
              protected and obtained from the minor’s representatives in
              accordance with applicable regulatory requirements. You can ask us
              or Affiliated Third Parties to stop sending you marketing messages
              at any time by logging onto the website and checking or unchecking
              relevant boxes to adjust your marketing preferences or by
              following the opt-out links on any marketing message sent to you.
            </li>
            <li>
              The right to request that we delete your Personal Data that is in
              our possession, subject however to retention required for legal
              purposes and the time required technically to delete such
              information.
            </li>
            <li>
              The right to request for access to your Personal Data or object to
              us processing the same. Where personal data is held electronically
              in a structured form, such you have a right to receive that data
              in a common electronic format.
            </li>
            <li>
              The right to update your Personal Data that is kept with us. You
              may do this at anytime your personal data changes and you wish to
              update us.{" "}
            </li>
            <li>
              The right to receive your Personal Data and have it transferred to
              another Data Controller, as applicable.
            </li>
            <li>The right to lodge a complaint. </li>
          </ol>
          <p>
            You may exercise any of the above stated rights following our{" "}
            <b>Data Subject Access Request Procedure.</b>
          </p>
        </li>

        <li>
          <span className="red-text bolder-text">
            Persons who have access to your Personal Data{" "}
          </span>
          <p>
            In addition to our staff who have a business need to know, the
            following trusted third parties have access to your information:
          </p>
          <ol className="alpha">
            <li>
              We use a customer relationship management tool to help manage our
              contact database and send out email communications to you.
            </li>
            <li>
              Our Affiliate Third Parties who require your information for the
              same purposes described in this Policy and who have adopted
              similar privacy policy standards further to contractual
              obligations to us under a third party data processing agreement we
              have entered with them.
            </li>
            <li>
              Professional service providers such as IT service providers and
              website hosts.
            </li>
          </ol>
          <p>
            We will transfer your Personal Data to only those Affiliated Third
            Parties who we are sure can offer the required level of protection
            to your privacy and information and who are also contractually
            obligated to us to do so. We do not and will not at any point in
            time sell your Personal Data. We require all Affiliated Third
            Parties to respect the security of your personal data and to treat
            it in accordance with the law. We do not allow our professional
            service providers to use your Personal Data for their own purposes
            and only permit them to process your Personal Data for specified
            purposes and in accordance with our instructions.
          </p>
        </li>

        <li>
          <span className="red-text bolder-text">
            Security & Confidentiality
          </span>
          <p>
            Information submitted by you is stored on secure servers we have
            which are encrypted and access is restricted to only authorized
            persons in charge of maintaining the servers. We have put in place
            physical, electronic and procedural processes that safeguard and
            protect your information against unauthorised access, modification
            or erasure. However, we cannot guarantee 100% security as no
            security programme is completely fool proof. In the unlikely event
            that we experience any breach to your personal data, such breach
            shall be handled in accordance with our Personal Data Breach
            Management Procedures. All such breaches shall be notified to the
            NITDA within 72 hours of occurrence and where deemed necessary,
            based on the severity and potential risks, we shall notify you of
            such occurrence, steps taken and remedies employed to prevent a
            reoccurrence.
          </p>
          <p>
            Our staff also have an obligation to maintain the confidentiality of
            any Personal Data held by us.
          </p>
          <p>
            As you know, transmission of data on the internet is never
            guaranteed regarding safety. It is impossible to completely
            guarantee your safety with electronic data and transmission. You are
            therefore at your own risk if you elect to transmit any data
            electronically.
          </p>
        </li>

        <li>
          <span className="red-text bolder-text">
            Transfer of Personal Data outside Nigeria
          </span>
          <p>
            The Personal Data we collect may be transferred to and processed in
            another country other than your country of residence for the
            purposes stated above. The data protection laws in those countries
            may be different from, and less stringent than the laws applicable
            in your country of residence.
          </p>
          <p>
            By accepting this Policy or by providing your Personal Data to us,
            you expressly consent to such transfer and Processing. We will
            however take all reasonable steps to ensure that your data is
            treated securely and transfer of your Personal Data will only be
            done in accordance with the requirements of applicable laws and to
            parties who have put in place adequate controls to secure and
            protect your Personal Data.
          </p>
        </li>

        <li>
          <span className="red-text bolder-text">
            Retention of Personal Data
          </span>
          <p>
            We retain your Personal Data for no longer than reasonably necessary
            for the purposes set out in this Policy and in accordance with
            legal, regulatory, tax, accounting or reporting requirements.
          </p>
          <p>
            We may retain your Personal Data for a longer period in the event of
            a complaint or if we reasonably believe there is a prospect of
            litigation in respect to our relationship with you.
          </p>
          <p>
            To determine the appropriate retention period for personal data, we
            consider the amount, nature and sensitivity of the Personal Data,
            the potential risk of harm from unauthorised use or disclosure of
            your Personal Data, the purposes for which we process your Personal
            Data and whether we can achieve those purposes through other means,
            and the applicable legal, regulatory, tax, accounting or other
            requirements.{" "}
          </p>
          <p>
            Where your Personal Data is contained within a document, the
            retention period applicable to such type of document in our document
            retention policy shall apply.{" "}
          </p>
        </li>

        <li>
          <span className="red-text bolder-text">Third Party Links</span>
          <p>
            This website or our email communication may include links to third
            party websites, plug-ins and applications. Clicking on those links
            or enabling those connections may allow third parties to collect or
            share data about you. We do not control these third-party websites
            and are not responsible for their privacy statements. When you leave
            our website, we encourage you to read the privacy policy of every
            website you visit.
          </p>
        </li>
      </ol>

      <h3 id="cookies" className="red-text center bolder-text">
        Cookies Policy
      </h3>
      <ol>
        <li>
          Our advertisers and organisation may have the occasion to collect
          information in regard to your computer for our services. The
          information is gained in a statistical manner for our use or
          advertisers on our site.
        </li>
        <li>
          Data gathered will not identify you personally. It is strictly
          aggregate statistical data about our visitors and how they used our
          resources on the site. No identifying Personal Data will be shared at
          any time via cookies.
        </li>
        <li>
          Close to the above, data gathering can be about general online use
          through a cookie file. When used, cookies are automatically placed in
          your hard drive where information transferred to your computer can be
          found. These cookies are designed to help us correct and improve our
          site’s services for you.
        </li>
        <li>
          You may elect to decline all cookies via your computer or set up
          alerts to prompt you when websites set or access cookies. Every
          computer has the ability to decline file downloads like cookies. Your
          browser has an option to enable the declining of cookies. If you do
          decline cookie downloads you may be limited to certain areas of our
          site, as there are parts of our site that require cookies.
        </li>
        <li>
          Any of our advertisers may also have a use for cookies. We are not
          responsible, nor do we have control of the cookies downloaded from
          advertisements. They are downloaded only if you click on the
          advertisement.
        </li>
      </ol>

      <h3 className="center bolder-text" id="sar">
        Subject Access Request Response Procedure
      </h3>
      <ol>
        <li>
          Where you wish to exercise any of your data privacy rights you shall
          make a formal request by completing the{" "}
          <Link to="/dsar" className="blue-link">
            Subject Access Request Form (SAR Form)
          </Link>{" "}
          and sending the completed form via email to us at{" "}
          <a
            href="mailto:privacy@tonyelumelufoundationheirsholdings.com"
            className="blue-link"
          >
            privacy@tonyelumelufoundationheirsholdings.com
          </a>
          .{" "}
        </li>
        <li>
          We shall contact you within 5 working days of the receipt of the SAR
          Form to confirm receipt of the subject access request and may request
          additional information to verify and confirm the identity of the
          individual making the request.
        </li>
        <li>
          On receiving any request from you, we shall record the request and
          carry out verification of the identity of the individual making the
          request using the details provided in the SAR Form and a valid means
          of identification such as international passport, driver’s license,
          national identification card or any other acceptable means of
          identification.
        </li>
        <li>
          Where the request is from a third party (such as relative or your
          representative), we will verify their authority to act for you and may
          contact you to confirm their identity and request your consent to
          disclose the information.
        </li>
        <li>
          When your identity is verified, we shall coordinate the gathering of
          all information collected with respect to you in a concise,
          transparent, intelligible and easily accessible form, using clear and
          plain language with a view to responding to the specific request. The
          information may be provided in writing, or by other means, including,
          where appropriate, by electronic means or orally provided that your
          identity is proven by other means. We may also contact you to ask you
          for further information in relation to your request to speed up our
          response.
        </li>
        <li>
          Where the information requested relates directly or indirectly to
          another person, we will seek the consent of that person before
          processing the request. However, where disclosure would adversely
          affect the rights and freedoms of others and we are unable to disclose
          the information, we will inform you promptly, with reasons for that
          decision.
        </li>
        <li>
          <b>Fees and Time Frame</b>
          <ol className="roman">
            <li>
              We shall ensure that we provide the information required by you
              within a period of one month from the receipt of the request.
              Occasionally it could take us longer than a month if your request
              is particularly complex or you have made a number of requests. In
              this case, we will notify you and keep you updated. However, where
              we are unable to act on your request, we shall inform you promptly
              at least within one month of receipt of the request of the reasons
              for not taking action and give you the option of lodging a
              complaint with the NITDA, in line with the NDPR.{" "}
            </li>
            <li>
              Where the request relates to any perceived violation of your
              rights, we shall take appropriate steps to remedy such violations,
              once confirmed. Remedies shall include but not limited to the
              investigation and reporting to appropriate authorities, recovering
              the personal data, correcting it and/ or enhancing controls around
              it. You shall be appropriately informed of the remedies employed.{" "}
            </li>
            <li>
              Any information provided to you by us shall be provided free of
              charge. However, where requests are manifestly unfounded or
              excessive in particular because of their repetitive or cumbersome
              nature, we may:
              <ol className="alpha">
                <li>
                  charge a reasonable fee taking into account the administrative
                  costs of providing the information or communication, taking
                  the action required or making a decision to refuse to act on
                  the request; or
                </li>
                <li>
                  write a letter to you stating refusal to act on the request
                  and copying the National Information Technology Development
                  Agency (NITDA).
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>Exceptions to Data Subjects Access Rights</b>
          <p>
            To the extent permitted by applicable laws, we may refuse to act on
            your request, if at least one of the following applies:
          </p>
          <ol className="alpha">
            <li>
              in compliance with a legal obligation to which we are subject;
            </li>
            <li>
              protecting your vital interests or of another natural person; and
            </li>
            <li>
              for public interest or in exercise of official public mandate
              vested in us.
            </li>
          </ol>
        </li>
      </ol>

      <h3 className="red-text center bolder-text">Contacting Us</h3>
      <p>
        We welcome any queries, requests you may have regarding our Data
        Protection Privacy Policies, or our privacy practices. Please feel free
        to contact us at{" "}
        <span className="red-text">
          <a href="mailto:privacy@tonyelumelufoundation.com">
            privacy@tonyelumelufoundation.com
          </a>{" "}
          or by completing the{" "}
          <b>
            <Link to="/dsar">Subject Access Request Form (SAR Form)</Link>
          </b>
        </span>
      </p>
    </div>
  );
};

export default Privacy;
