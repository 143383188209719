/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";

// import NewAuthLayout from "src/Auth/NewLayout";
// import tefDark from "src/assets/img/logo/logo_200.png";
import checkImg from "src/assets/img/signup/check.svg";
import close from "src/assets/img/home/close.svg";
import { verifyAccount, reSendLink } from "src/requests/AuthRequest";
import { Context } from "src/store";

import "./VerifyUser.scss";
import "../ResetPassword/ResetPassword.scss";
import Loader from "src/components/Loader/Loader";
import NewAuthLayout_v2 from "src/Auth/NewLayout/NewAuthLayout_v2";

const VerifyUser = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [check, setCheck] = useState(false);
  const [success, setSuccess] = useState(false);
  const { state, dispatch } = useContext(Context);
  const values = queryString.parse(props.location.search);

  const handleResendEmail = (e) => {
    e.preventDefault();
    const userInfo = {
      email: values.userId,
    };
    reSendLink(dispatch, userInfo).then((res) => {
      const { status } = res;
      if (status === 200) {
        setSuccess(true);
      }
    });
  };

  const handleVerification = () => {
    try {
      const userInfo = {
        userId: encodeURIComponent(values.userId),
        token: encodeURIComponent(values.token.replace(/ /g, "+")),
      };

      verifyAccount(dispatch, userInfo)
        .then((res) => {
          const { status, data } = res;
          if (status !== 200) {
            setIsLoading(false);
            if (data.message.toLowerCase() === "email already confirmed") {
              setMessage(data.message);
              setCheck(true);
            } else {
              setMessage(data.message);
              setStatus(data.status);
            }
          } else {
            setIsLoading(false);
            setMessage(data.message);
            setStatus(data.status);
            setCheck(true);
          }
        })
        .catch((_err) => {
          setIsLoading(false);
          setMessage("Something went wrong, please contact support");
          setStatus("fail");
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleVerification();
  }, []);

  return (
    <NewAuthLayout_v2>
      <div className="main-wrapper__form-div">
        <div className="">
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "25% 0",
            }}
          >
            {isLoading ? (
              <div style={{ height: "40vh" }}>
                <Loader color="danger" />
              </div>
            ) : (
              <React.Fragment>
                <div className="c_e_c">
                  <img
                    src={status === "fail" || !check ? close : checkImg}
                    data-testid={status !== "fail" && check && "success"}
                    className="check-div__check"
                    alt="check"
                    style={{
                      height: "66px",
                      width: "66px",
                      marginRight: "35px",
                    }}
                  />
                  <div>
                    <h4
                      style={{
                        font: "normal normal bold 30px/36px Poppins",
                        color: "#070707",
                      }}
                    >
                      {status === "fail" || !check
                        ? "Account confirmation failed"
                        : "Account confirmation was successful"}
                    </h4>
                    <p
                      style={{
                        font: "normal normal medium 14px/21px Poppins",
                        color: "#6E6E70",
                      }}
                      className="message-0"
                    >
                      {message}
                    </p>
                  </div>
                </div>

                {status === "fail" && !check ? (
                  <button
                    disabled={success}
                    className="message-button resend-btn"
                    onClick={handleResendEmail}
                    style={{
                      width: "100%",
                      flex: 1,
                      backgroundColor: "green",
                    }}
                  >
                    {state.Users.loading ? (
                      <Loader color="danger" />
                    ) : success ? (
                      "Sent"
                    ) : (
                      "Resend Link"
                    )}
                  </button>
                ) : (
                  <Link
                    to="/login"
                    className="message-button"
                    style={{ width: "50%" }}
                  >
                    Sign in
                  </Link>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </NewAuthLayout_v2>
  );
};

export default VerifyUser;
