import { useEffect, useState } from "react";

export const useCarousel = (
  size,
  speed = 2000,
  preChangeClass,
  postChangeClass
) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [classname, setClassname] = useState("");

  const automateSlide = () => {
    slideNext();
  };

  const slideNext = () => {
    preChangeClass && setClassname(preChangeClass);
    let cur = currentIndex;
    if (cur < size - 1) {
      cur++;
    } else {
      cur = 0;
    }

    postChangeClass && setClassname(postChangeClass);
    setCurrentIndex(cur);
  };

  const slidePrev = () => {
    let cur = currentIndex;
    if (cur === 0) {
      cur = size - 1;
    } else {
      cur--;
    }
    setCurrentIndex(cur);
  };

  const snapToSlide = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    let intervalId;
    if (size > 1) {
      intervalId = setInterval(automateSlide, speed);
    }

    return () => clearInterval(intervalId);
  });

  return { currentIndex, slidePrev, slideNext, snapToSlide, classname };
};
