import React, { useContext, useEffect, useState } from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import Avatar from "react-avatar";
import { Context } from "src/store";
import { likeComment, getUserLikeComment } from "src/requests/CommentRequest";
import "./Comments.scss";

const Comments = ({
  commentId,
  commentName,
  commentPost,
  commentDate,
  commentLikes,
  dp,
}) => {
  const [likes, setLikes] = useState(commentLikes);
  const [liked, setLiked] = useState(false);
  const { dispatch } = useContext(Context);

  useEffect(() => {
    getUserLikeComment(dispatch, commentId).then((res) => {
      const {
        data: { data },
        status,
      } = res;
      if (status !== 200) {
        setLiked(false);
      }
      if (data) {
        setLiked(true);
      }
    });
  }, []);

  const handleLike = () => {
    likeComment(dispatch, commentId)
      .then((res) => {
        const { message } = res.data;
        if (message === "unlike") {
          setLikes(likes - 1);
          setLiked(false);
        } else if (message === "like") {
          setLikes(likes + 1);
          setLiked(true);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="comments">
      {dp === "" || dp === undefined || dp === null ? (
        <Avatar round={true} name={commentName} size={50} />
      ) : (
        <img src={dp} alt="profile" className="card-div__dp" />
      )}
      <div className="comments-content">
        <h3 className="notranslate">{commentName}</h3>
        <p>{commentPost}</p>
        <div className="comments-actions">
          <span onClick={handleLike} style={{ cursor: "pointer" }}>
            {liked ? <FaHeart style={{ color: "#cf1d10" }} /> : <FaRegHeart />}{" "}
            &nbsp; {likes} {commentLikes <= 1 ? "Like" : "Likes"}
          </span>
          <h5>{commentDate}</h5>
        </div>
      </div>
    </div>
  );
};

export default Comments;
