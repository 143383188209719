import React from "react";

import { saveAs } from "file-saver";
import { Spinner } from "reactstrap";

import {
  Document,
  Packer,
  Paragraph,
  VerticalAlign,
  HeadingLevel,
  TableOfContents,
  AlignmentType,
  TextRun,
  Table,
  TableRow,
  TableCell,
  WidthType,
} from "docx";

const createArray = (incomingArray) => {
  const arr = [];

  incomingArray.forEach((item) => {
    arr.push(createBullet(item));
  });

  return arr;
};

const createBullet = (text) => {
  return new Paragraph({
    text: text,
    bullet: {
      level: 0,
    },
    style: "introduction",
  });
};

const createText = (array) => {
  const arr = [];

  array.forEach((item) => {
    arr.push(new TextRun(`${item}, `));
  });

  return arr;
};

const createTableListMaterials = (array) => {
  const arr = [];

  array.forEach((item) => {
    arr.push(
      new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph(item.materials)],
          }),
          new TableCell({
            children: [new Paragraph(item.description)],
          }),
          new TableCell({
            children: [new Paragraph(item.source)],
          }),
        ],
      })
    );
  });

  return arr;
};

const createTableListEquipment = (array) => {
  const arr = [];

  array.forEach((item) => {
    arr.push(
      new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph(item.equipment)],
          }),
          new TableCell({
            children: [new Paragraph(item.description)],
          }),
          new TableCell({
            children: [new Paragraph(item.source)],
          }),
        ],
      })
    );
  });

  return arr;
};

const createTableListManagementTeam = (array) => {
  const arr = [];

  array.forEach((item) => {
    arr.push(
      new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph(item.Position)],
          }),
          new TableCell({
            children: [new Paragraph(item.Skills)],
          }),
          new TableCell({
            children: [new Paragraph(item.responsibility)],
          }),
        ],
      })
    );
  });
  return arr;
};

const DownloadBusinessPlan = ({
  executiveSummary,
  introductionDetails,
  industryMarketAnalysisDetails,
  productPhotosDetails,
  competitionDetails,
  swotDetails,
  financialPlanDetails,
  operationalPlanDetails,
  marketingPlanDetails,
  managementDetails,
  loader,
}) => {
  const downloadPlan = () => {
    const doc = new Document({
      styles: {
        paragraphStyles: [
          {
            id: "HeadingOne",
            name: "Heading 2",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              size: 30,
              bold: true,
              center: true,
            },
            paragraph: {
              spacing: {
                after: 120,
              },
            },
          },
          {
            id: "HeadingTwo",
            name: "Heading 3",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              size: 26,
              bold: true,
              center: true,
            },
            paragraph: {
              spacing: {
                before: 240,
                after: 120,
              },
            },
          },
          {
            id: "HeadingThree",
            name: "Heading 3",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              size: 20,
              bold: true,
              center: true,
            },
            paragraph: {
              spacing: {
                after: 120,
                line: 300,
                before: 300,
              },
            },
          },
          {
            id: "introduction",
            name: "normal",
            basedOn: "Normal",
            next: "Normal",
            run: {
              italics: false,
            },
            paragraph: {
              spacing: {
                line: 276,
                after: 200,
              },
            },
          },
        ],
      },
      numbering: {
        config: [
          {
            reference: "introduction",
            levels: [
              {
                level: 0,
                format: "decimal",
                text: "%1",
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    spacing: {
                      line: 276,
                      after: 200,
                    },
                    indent: { left: 720, hanging: 260 },
                  },
                },
              },
              {
                level: 1,
                format: "decimal",
                text: "%1.1",
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    spacing: {
                      line: 276,
                      after: 200,
                    },
                    indent: { left: 0, hanging: 400 },
                  },
                },
              },
              {
                level: 2,
                text: "",
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    spacing: {
                      line: 276,
                      after: 200,
                    },
                    indent: { left: 230, hanging: 400 },
                  },
                },
              },
            ],
          },
        ],
      },
    });

    doc.addSection({
      children: [
        new Paragraph({
          text: "BUSINESS PLAN",
          VerticalAlign: VerticalAlign.CENTER,
          alignment: AlignmentType.CENTER,
          heading: HeadingLevel.HEADING_2,
          style: "HeadingOne",
        }),
        new Paragraph({
          text: "OF",
          VerticalAlign: VerticalAlign.CENTER,
          alignment: AlignmentType.CENTER,
          heading: HeadingLevel.HEADING_3,
          style: "HeadingTwo",
        }),
      ],
    });

    doc.addSection({
      children: [
        new Paragraph({
          text: "Table of Contents",
          VerticalAlign: VerticalAlign.CENTER,
          alignment: AlignmentType.CENTER,
          heading: HeadingLevel.HEADING_2,
          style: "HeadingOne",
        }),
        new TableOfContents("Summary", {
          hyperlink: true,
          headingStyleRange: "1-2",
          // stylesWithLevels: [new StyleLevel("MySpectacularStyle", 1)],
          italics: false,
        }),
      ],
    });

    doc.addSection({
      children: [
        new Paragraph({
          text: "Executive Summary",
          VerticalAlign: VerticalAlign.CENTER,
          heading: HeadingLevel.HEADING_2,
          alignment: AlignmentType.CENTER,
        }),
        new Paragraph({
          text: `${
            executiveSummary.businessName === ""
              ? "[BUSINESS NAME]"
              : executiveSummary.businessName
          } is a ${
            executiveSummary.stageOfBusiness === ""
              ? "[STAGE OF BUSINESS]"
              : executiveSummary.stageOfBusiness
          } stage business located at ${
            executiveSummary.addressOfBusiness === ""
              ? "[ADDRESS OF BUSINESS]"
              : executiveSummary.addressOfBusiness
          }.`,
          style: "introduction",
        }),
        new Paragraph({
          text: `${
            executiveSummary.valueProposition === ""
              ? "[VALUE PROPOSITION]"
              : executiveSummary.valueProposition
          }.`,
          style: "introduction",
        }),
        new Paragraph({
          text: `Our target market is ${
            executiveSummary.targetMarket === ""
              ? "[TARGET MARKET]"
              : executiveSummary.targetMarket
          }.`,
          style: "introduction",
        }),
        new Paragraph({
          text: `The approach to promote ${
            executiveSummary.businessName === ""
              ? "[BUSINESS NAME]"
              : executiveSummary.businessName
          } will be through ${
            executiveSummary.marketApproach === ""
              ? "[MARKETING APPROACH]"
              : executiveSummary.marketApproach
          }. ${
            executiveSummary.businessName === ""
              ? "[BUSINESS NAME]"
              : executiveSummary.businessName
          } will ${
            executiveSummary.objective === ""
              ? "[OBJECTIVE 1]"
              : executiveSummary.objective
          }. ${
            executiveSummary.diffrentiation === ""
              ? "[DIFFERENTIATION]"
              : executiveSummary.diffrentiation
          }.`,
          style: "introduction",
        }),
        new Paragraph({
          text: `The proposed business would operate under the ${
            executiveSummary.typeOfOwnership === ""
              ? "[TYPE OF BUSINESS OWNERSHIP]"
              : executiveSummary.typeOfOwnership
          } business ownership structure and will be managed by ${executiveSummary.businessOwner.map(
            (owner, index) => <span key={index}>{owner + ", "}</span>
          )}.`,
          style: "introduction",
        }),

        new Paragraph({
          text: `Our vision is ${
            executiveSummary.visionStatement === ""
              ? "[VISION STATEMENT]"
              : executiveSummary.visionStatement
          }. And our mission is ${
            executiveSummary.missionStatement === ""
              ? "[MISSION STATEMENT]"
              : executiveSummary.missionStatement
          }.`,
          style: "introduction",
        }),

        new Paragraph({
          text: `We are seeking the total sum off ${
            executiveSummary.fundingRequirement === ""
              ? "[FUNDING REQUIREMENT]"
              : executiveSummary.fundingRequirement
          }. This will be used for ${
            executiveSummary.useOfFundingRequirement === ""
              ? "[USE OF FUNDING REQUIREMENT]"
              : executiveSummary.useOfFundingRequirement
          }. We anticipate a sales turnover of ${
            executiveSummary.salesTurnoverAmount === ""
              ? "[SALES TURNOVER AMOUNT]"
              : executiveSummary.salesTurnoverAmount
          }, a gross profit of ${
            executiveSummary.grossProfitAmount === ""
              ? "[GROSS PROFIT AMOUNT]"
              : executiveSummary.grossProfitAmount
          } and a net profit after tax of ${
            executiveSummary.netProfit === ""
              ? "[NET PROFIT]"
              : executiveSummary.netProfit
          } by our 1st year of commencement of operation.`,
          style: "introduction",
        }),

        ...createArray(executiveSummary.risks),
      ],
    });

    doc.addSection({
      children: [
        new Paragraph({
          text: "Introduction",
          VerticalAlign: VerticalAlign.CENTER,
          heading: HeadingLevel.HEADING_2,
          alignment: AlignmentType.CENTER,
        }),
        new Paragraph({
          text: "Business Overview",
          VerticalAlign: VerticalAlign.CENTER,
          heading: HeadingLevel.HEADING_4,
          alignment: AlignmentType.CENTER,
          style: "HeadingThree",
        }),
        new Paragraph({
          text: `Description of Business`,
          numbering: {
            reference: "introduction",
            level: 1,
            text: "%1.51",
          },
          style: "introduction",
        }),
        new Paragraph({
          text: `${
            introductionDetails.elevatorPitch === ""
              ? "[ELEVATOR PITCH]"
              : introductionDetails.elevatorPitch
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
          style: "introduction",
        }),
        new Paragraph({
          text: `${
            introductionDetails.businessDescription === ""
              ? "[DETAILED DESCRIPTION OF BUSINESS]"
              : introductionDetails.businessDescription
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
          style: "introduction",
        }),
        new Paragraph({
          text: `Our vision is ${
            executiveSummary.visionStatement === ""
              ? "[VISION STATEMENT]"
              : executiveSummary.visionStatement
          }. And our mission is ${
            executiveSummary.missionStatement === ""
              ? "[MISSION STATEMENT]"
              : executiveSummary.missionStatement
          }.`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
          style: "introduction",
        }),
        new Paragraph({
          text: `Vision: `,
          numbering: {
            reference: "introduction",
            level: 2,
          },
          style: "introduction",
        }),
        new Paragraph({
          text: `${
            executiveSummary.visionStatement === ""
              ? "[VISION STATEMENT]"
              : executiveSummary.visionStatement
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
          style: "introduction",
        }),
        new Paragraph({
          text: `Mission`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
          style: "introduction",
        }),
        new Paragraph({
          text: `${
            executiveSummary.missionStatement === ""
              ? "[MISSION STATEMENT]"
              : executiveSummary.missionStatement
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
          style: "introduction",
        }),
        new Paragraph({
          text: `Business Objective(s)`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
          style: "introduction",
        }),
        ...createArray(introductionDetails.businessObjectives),

        new Paragraph({
          text: `Critical Success Factors of the Business are:`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
          style: "introduction",
        }),
        ...createArray(introductionDetails.successfactors),
        new Paragraph({
          text: `Value Propostion`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
          style: "introduction",
        }),

        new Paragraph({
          text: `${
            introductionDetails.valueProposition === ""
              ? "[VALUE PROPOSTION]"
              : introductionDetails.valueProposition
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
          style: "introduction",
        }),
        new Paragraph({
          text: `Current Status of Business`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
          style: "introduction",
        }),

        new Paragraph({
          text: `${
            introductionDetails.businessStatus === ""
              ? "[CURRRENT STATE OF BUSINESS]"
              : introductionDetails.businessStatus
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
          style: "introduction",
        }),
        new Paragraph({
          text: `Contribution to Local and National Economy`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
          style: "introduction",
        }),

        new Paragraph({
          text: `${
            introductionDetails.contributionToEconomy === ""
              ? "[COMMUNITY AND NATIONAL CONTRIBUTION]"
              : introductionDetails.contributionToEconomy
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
          style: "introduction",
        }),
      ],
    });

    doc.addSection({
      children: [
        new Paragraph({
          text: "The Products/Service",
          VerticalAlign: VerticalAlign.CENTER,
          heading: HeadingLevel.HEADING_2,
          alignment: AlignmentType.CENTER,
        }),
        new Paragraph({
          text: `DESCRIPTIONS OF PRODUCT(S) OR SERVICE(S)`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),
        new Paragraph({
          text: `${
            productPhotosDetails.description === ""
              ? "[DESCRIPTION OF PRODUCTS OR SERVICES]"
              : productPhotosDetails.description
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),
        new Paragraph({
          text: `[INSERT PHOTOS OF PRODUCT(S)]`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),
      ],
    });

    doc.addSection({
      children: [
        new Paragraph({
          text: "Industry and Market Analysis",
          VerticalAlign: VerticalAlign.CENTER,
          heading: HeadingLevel.HEADING_2,
          alignment: AlignmentType.CENTER,
        }),
        new Paragraph({
          text: `DESCRIPTION OF THE BUSINESS INDUSTRY/ INDUSTRY ANALYSIS. `,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),
        new Paragraph({
          text: `${
            industryMarketAnalysisDetails.descriptionOfIndustry === ""
              ? "[DESCRIPTION OF INDUSTRY]"
              : industryMarketAnalysisDetails.descriptionOfIndustry
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),
        new Paragraph({
          text: `${
            industryMarketAnalysisDetails.descriptionOfMarket === ""
              ? "[DESCRIPTION OF MARKET]"
              : industryMarketAnalysisDetails.descriptionOfMarket
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),

        new Paragraph({
          children: [
            new TextRun("The big players in this sector include "),
            ...createText(industryMarketAnalysisDetails.competitors),
          ],
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),

        new Paragraph({
          text: `Potential Challenges in the Business Industry`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),

        ...createArray(industryMarketAnalysisDetails.challengesInBusiness),

        new Paragraph({
          text: `Mitigation for the above challenges;`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),

        ...createArray(industryMarketAnalysisDetails.mitigationOfChallenges),

        new Paragraph({
          text: `The Opportunity`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),

        ...createArray(industryMarketAnalysisDetails.opportunity),

        new Paragraph({
          text: `TARGET MARKET.`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),

        ...createArray(industryMarketAnalysisDetails.targetMarket),
      ],
    });

    doc.addSection({
      children: [
        new Paragraph({
          text: "Competition",
          VerticalAlign: VerticalAlign.CENTER,
          heading: HeadingLevel.HEADING_2,
          alignment: AlignmentType.CENTER,
        }),
        new Paragraph({
          text: `COMPETITIVE ANALYSIS `,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),
        new Paragraph({
          text: `${
            competitionDetails.competitiveAnalysis === ""
              ? "[COMPETITIVE ADVANTAGE]"
              : competitionDetails.competitiveAnalysis
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),
        new Paragraph({
          text: `CUSTOMERS RELATIONSHIPS`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),

        new Paragraph({
          text: `${
            competitionDetails.productOffers === ""
              ? "[PRODUCT/SERVICE OFFERS]"
              : competitionDetails.productOffers
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),

        new Table({
          width: {
            size: 100,
            type: WidthType.PCT,
          },
          alignment: AlignmentType.CENTER,
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph("Name of Competitors")],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.nameOfCompetitors[0] === ""
                          ? "[BUSINESS NAME]"
                          : competitionDetails.nameOfCompetitors[0]
                      }`
                    ),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.nameOfCompetitors[1] === ""
                          ? "[BUSINESS NAME]"
                          : competitionDetails.nameOfCompetitors[1]
                      }`
                    ),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.nameOfCompetitors[2] === ""
                          ? "[BUSINESS NAME]"
                          : competitionDetails.nameOfCompetitors[2]
                      }`
                    ),
                  ],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph("Location of Competitors")],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.locationOfCompetitors[0] === ""
                          ? "[COMP-AN-LOC-C1]"
                          : competitionDetails.locationOfCompetitors[0]
                      }`
                    ),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.locationOfCompetitors[1] === ""
                          ? "[COMP-AN-LOC-C1]"
                          : competitionDetails.locationOfCompetitors[1]
                      }`
                    ),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.locationOfCompetitors[2] === ""
                          ? "[COMP-AN-LOC-C1]"
                          : competitionDetails.locationOfCompetitors[2]
                      }`
                    ),
                  ],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph("Products and services offered")],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.productsOffered[0] === ""
                          ? "[COMP-AN-PSO1]"
                          : competitionDetails.productsOffered[0]
                      }`
                    ),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.productsOffered[1] === ""
                          ? "[COMP-AN-PSO1]"
                          : competitionDetails.productsOffered[1]
                      }`
                    ),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.productsOffered[2] === ""
                          ? "[COMP-AN-PSO1]"
                          : competitionDetails.productsOffered[2]
                      }`
                    ),
                  ],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph("Pricing Strategy")],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.pricingStrategy[0] === ""
                          ? "[COMP-AN-PS1]"
                          : competitionDetails.pricingStrategy[0]
                      }`
                    ),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.pricingStrategy[1] === ""
                          ? "[COMP-AN-PS2]"
                          : competitionDetails.pricingStrategy[1]
                      }`
                    ),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.pricingStrategy[2] === ""
                          ? "[COMP-AN-PS3]"
                          : competitionDetails.pricingStrategy[2]
                      }`
                    ),
                  ],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph("What are their uniqueness?")],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.uniqueness[0] === ""
                          ? "[COMP-UNIQ-1]"
                          : competitionDetails.uniqueness[0]
                      }`
                    ),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.uniqueness[1] === ""
                          ? "[COMP-UNIQ-2]"
                          : competitionDetails.uniqueness[1]
                      }`
                    ),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.uniqueness[2] === ""
                          ? "[COMP-UNIQ-3]"
                          : competitionDetails.uniqueness[2]
                      }`
                    ),
                  ],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph("What is their other strength?")],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.strength[0] === ""
                          ? "[COMPETITOR STRENGTHS 1]"
                          : competitionDetails.strength[0]
                      }`
                    ),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.strength[1] === ""
                          ? "[COMPETITOR STRENGTHS 2]"
                          : competitionDetails.strength[1]
                      }`
                    ),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.strength[2] === ""
                          ? "[COMPETITOR STRENGTHS 3]"
                          : competitionDetails.strength[2]
                      }`
                    ),
                  ],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph("What are their weaknesses?")],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.weaknesses[0] === ""
                          ? "[COMPETITOR WEAKNESSES 1]"
                          : competitionDetails.weaknesses[0]
                      }`
                    ),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.weaknesses[1] === ""
                          ? "[COMPETITOR WEAKNESSES 2]"
                          : competitionDetails.weaknesses[1]
                      }`
                    ),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.weaknesses[2] === ""
                          ? "[COMPETITOR WEAKNESSES 3]"
                          : competitionDetails.weaknesses[2]
                      }`
                    ),
                  ],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph("How long have they been existing?"),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.durationOfExistence[0] === ""
                          ? "[COMPETITOR HISTORY 1]"
                          : competitionDetails.durationOfExistence[0]
                      }`
                    ),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.durationOfExistence[1] === ""
                          ? "[COMPETITOR HISTORY 2]"
                          : competitionDetails.durationOfExistence[1]
                      }`
                    ),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.durationOfExistence[2] === ""
                          ? "[COMPETITOR HISTORY 3]"
                          : competitionDetails.durationOfExistence[2]
                      }`
                    ),
                  ],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph("Customers segments")],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.customerSegments[0] === ""
                          ? "[COMPETITOR CUSTOMER SEGMENTS 1]"
                          : competitionDetails.customerSegments[0]
                      }`
                    ),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.customerSegments[1] === ""
                          ? "[COMPETITOR CUSTOMER SEGMENTS 2]"
                          : competitionDetails.customerSegments[1]
                      }`
                    ),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        competitionDetails.customerSegments[2] === ""
                          ? "[COMPETITOR CUSTOMER SEGMENTS 3]"
                          : competitionDetails.customerSegments[2]
                      }`
                    ),
                  ],
                }),
              ],
            }),
          ],
        }),
      ],
    });

    doc.addSection({
      children: [
        new Paragraph({
          text: "SWOT Analysis",
          VerticalAlign: VerticalAlign.CENTER,
          heading: HeadingLevel.HEADING_2,
          alignment: AlignmentType.CENTER,
        }),

        new Table({
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph("STRENGTH")],
                }),
                new TableCell({
                  children: [new Paragraph(`WEAKNESSES`)],
                }),
              ],
            }),

            new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph("[STRENGTH]")],
                }),
                new TableCell({
                  children: [new Paragraph(`[WEAKNESSES]`)],
                }),
              ],
            }),

            new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph("[OPPORTUNITIES]")],
                }),
                new TableCell({
                  children: [new Paragraph(`[THREATS]`)],
                }),
              ],
            }),
          ],
        }),
        new Paragraph({
          text: `The risks involved in this kind of business are;`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),

        ...createArray(swotDetails.risks),
      ],
    });

    doc.addSection({
      children: [
        new Paragraph({
          text: "Operational Plans",
          VerticalAlign: VerticalAlign.CENTER,
          heading: HeadingLevel.HEADING_2,
          alignment: AlignmentType.CENTER,
        }),
        new Paragraph({
          text: `DESCRIPTION OF OPERATIONS`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),
        new Paragraph({
          text: `${
            operationalPlanDetails.descriptionOfOperations === ""
              ? "[DESCRIPTION OF OPERATIONS]"
              : operationalPlanDetails.descriptionOfOperations
          }`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),
        new Paragraph({
          text: `Key Resources:`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),
        ...createArray(operationalPlanDetails.keyResources),
        new Paragraph({
          text: `Suppliers;`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),
        ...createArray(operationalPlanDetails.suppliers),

        new Paragraph({
          text: `RAW MATERIALS/CONSUMABLES NEEDED/SUPPLIES`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),

        new Table({
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph("Materials")],
                }),
                new TableCell({
                  children: [new Paragraph(`Description/ uses`)],
                }),
                new TableCell({
                  children: [new Paragraph(`Key Supplier/Source`)],
                }),
              ],
            }),
            ...createTableListMaterials(operationalPlanDetails.rawMaterials),
          ],
        }),

        new Paragraph({
          text: `List of Capital Equipment, description, uses and suppliers.`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),

        new Table({
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph("Equipment")],
                }),
                new TableCell({
                  children: [new Paragraph(`Description/ uses`)],
                }),
                new TableCell({
                  children: [new Paragraph(`Key Supplier/Source`)],
                }),
              ],
            }),
            ...createTableListEquipment(
              operationalPlanDetails.capitalEquipments
            ),
          ],
        }),

        new Paragraph({
          text: `List of Consumables`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),

        new Table({
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph("Materials")],
                }),
                new TableCell({
                  children: [new Paragraph(`Description/ uses`)],
                }),
                new TableCell({
                  children: [new Paragraph(`Key Supplier/Source`)],
                }),
              ],
            }),
            ...createTableListMaterials(operationalPlanDetails.consumablesList),
          ],
        }),
      ],
    });

    doc.addSection({
      children: [
        new Paragraph({
          text: "Marketing Plan",
          VerticalAlign: VerticalAlign.CENTER,
          heading: HeadingLevel.HEADING_2,
          alignment: AlignmentType.CENTER,
        }),
        new Paragraph({
          text: `Marketing and Promotion Channels`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),
        new Paragraph({
          text: `${
            marketingPlanDetails.marketingChannels === ""
              ? "[MARKETING CHANNELS]"
              : marketingPlanDetails.marketingChannels
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),
        new Paragraph({
          text: `Pricing Strategy`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),
        new Paragraph({
          text: `${
            marketingPlanDetails.pricingStrategy === ""
              ? "[PRICING STRATEGY]"
              : marketingPlanDetails.pricingStrategy
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),
        new Paragraph({
          text: `Distribution Strategy`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),
        new Paragraph({
          text: `${
            marketingPlanDetails.distributionStrategy === ""
              ? "[DISTRIBUTION STRATEGY]"
              : marketingPlanDetails.distributionStrategy
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),
      ],
    });

    doc.addSection({
      children: [
        new Paragraph({
          text: "Management",
          VerticalAlign: VerticalAlign.CENTER,
          heading: HeadingLevel.HEADING_2,
          alignment: AlignmentType.CENTER,
        }),
        new Paragraph({
          text: `ORGANIZATIONAL AND MANAGEMENT PLAN`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),
        new Paragraph({
          text: `OWNERSHIP OF THE BUSINESS`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),
        new Paragraph({
          text: `Our form of business ownership is [TYPE OF BUSINESS OWNERSHIP]. We choose this form of business ownership because ${
            managementDetails.reason === ""
              ? "[REASON FOR TYPE OF BUSINESS OWNERSHIP]"
              : managementDetails.reason
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),
        new Paragraph({
          text: `PROFILE OF THE PROMOTER`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),
        new Paragraph({
          text: `${
            marketingPlanDetails.pricingStrategy === ""
              ? "[PRICING STRATEGY]"
              : marketingPlanDetails.pricingStrategy
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),
        new Paragraph({
          text: `${
            marketingPlanDetails.pricingStrategy === ""
              ? "[PRICING STRATEGY]"
              : marketingPlanDetails.pricingStrategy
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),
        new Paragraph({
          text: `DETAILS OF EMPLOYEE`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),
        new Paragraph({
          text: `${
            managementDetails.detailsOfEmployee === ""
              ? "[DETAILS OF MANAGEMENT AND EMPLOYEES]"
              : managementDetails.detailsOfEmployee
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),
        new Paragraph({
          text: `Key Management Team`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),

        new Table({
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph("Position")],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `Skills, qualification and experience required`
                    ),
                  ],
                }),
                new TableCell({
                  children: [new Paragraph(`Functions and responsibility`)],
                }),
              ],
            }),
            ...createTableListManagementTeam(
              managementDetails.keyManagementTeam
            ),
          ],
        }),

        new Paragraph({
          text: `${
            managementDetails.operatingHours === ""
              ? "[OPERATING HOURS]"
              : managementDetails.operatingHours
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),
        new Paragraph({
          text: `Organizational Structure`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),
        new Paragraph({
          text: `${
            marketingPlanDetails.distributionStrategy === ""
              ? "[DISTRIBUTION STRATEGY]"
              : marketingPlanDetails.distributionStrategy
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),
      ],
    });

    doc.addSection({
      children: [
        new Paragraph({
          text: "Financial Plan",
          VerticalAlign: VerticalAlign.CENTER,
          heading: HeadingLevel.HEADING_2,
          alignment: AlignmentType.CENTER,
        }),
        new Paragraph({
          text: `Financial Plan`,
          numbering: {
            reference: "introduction",
            level: 1,
          },
        }),
        new Paragraph({
          text: `${
            financialPlanDetails.revenueModel === ""
              ? "[REVENUE MODEL]"
              : financialPlanDetails.revenueModel
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),
        new Paragraph({
          text: `${
            financialPlanDetails.productPrices === ""
              ? "[PRODUCT PRICES]"
              : financialPlanDetails.productPrices
          }`,
          numbering: {
            reference: "introduction",
            level: 2,
          },
        }),
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "Business Plan.docx");
    });
  };
  return (
    <button
      type="submit"
      onClick={downloadPlan}
      className="btn btn-secondary px-4"
    >
      Download
      {loader && (
        <Spinner
          color="#ffffff"
          style={{ height: "1em", width: "1em", marginLeft: "1em" }}
        />
      )}
    </button>
  );
};

export default DownloadBusinessPlan;
