import React from "react";
import classes from "./ErrorMessage.module.scss";
import { BiInfoCircle } from "react-icons/bi";

const ErrorMessage = ({ msg }) => {
  return (
    <small className={classes["error-wrapper"]}>
      <BiInfoCircle /> {msg}
    </small>
  );
};

export default ErrorMessage;
