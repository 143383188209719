/* eslint-disable react/jsx-key */
import React, { useState, useEffect, useContext } from "react";
import { Col, Button, Row } from "reactstrap";
import Empty from "src/assets/img/mentorship-empty.png";
import {
  getPendingConnectionReceived,
  getPendingConnectionSent,
} from "src/requests/ConnectionRequest";
import { Context } from "src/store";
import Request from "./Request";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import { ContentLoaderC } from "src/modules/Connections/Suggestions/Suggestions";
// import InfiniteScroll from "react-infinite-scroll-component";

const status = {
  PENDING: "pending",
  ACCEPTED: "accepted",
  DECLINED: "declined",
  CANCELED: "canceled",
};
const Requests = ({ currentTab, setCurrentTab, role }) => {
  const { dispatch } = useContext(Context);
  const [sentRequests, setSentRequests] = useState([]);
  const [sentPageDetails, setSentPageDetails] = useState({});
  const [receivedPageDetails, setReceivedPageDetails] = useState({});
  const [receivedRequests, setReceivedRequests] = useState([]);
  const user = get_user_info_from_token().user;
  const { id } = user;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (currentTab === "Sent Requests") {
      getPendingConnectionSent(dispatch, id, 1, status.PENDING).then((res) => {
        if (res.status === 200) {
          setSentRequests([...res.data.data.data]);
          setSentPageDetails(res.data.data.page);
        }
        setLoading(false);
      });
    } else {
      getPendingConnectionReceived(dispatch, id, 1, status.PENDING).then(
        (res) => {
          if (res.status === 200) {
            setReceivedRequests([...res.data.data.data]);
            setReceivedPageDetails(res.data.data.page);
          }
          setLoading(false);
        }
      );
    }
  }, []);

  // useEffect(() => {
  //   if (currentTab === "Sent Requests") {
  //     if (state.Connections.sent && state.Connections.sent.length > 0) {
  //       setSentRequests(state.Connections.sent);
  //     }
  //   } else {
  //     if (state.Connections.received && state.Connections.received.length > 0) {
  //       setReceivedRequests(state.Connections.received);
  //     }
  //   }
  // }, [state.Connections.sent, state.Connections.received]);

  const fetchMoreRequests = () => {
    setLoading(true);

    if (currentTab === "Sent Requests") {
      getPendingConnectionSent(
        dispatch,
        id,
        sentPageDetails.pageNumber + 1,
        status.PENDING
      ).then((res) => {
        if (res.status === 200) {
          setSentRequests([...sentRequests, ...res.data.data.data]);
          setSentPageDetails(res.data.data.page);
        }
        setLoading(false);
      });
    } else {
      getPendingConnectionReceived(
        dispatch,
        id,
        receivedPageDetails.pageNumber + 1,
        status.PENDING
      ).then((res) => {
        if (res.status === 200) {
          setReceivedRequests([...receivedRequests, ...res.data.data.data]);
          setReceivedPageDetails(res.data.data.page);
        }
        setLoading(false);
      });
    }
  };

  const hasNext = () => {
    return currentTab === "Sent Requests"
      ? sentPageDetails.hasNextPage
      : receivedPageDetails.hasNextPage;
  };

  const displaySentRequests = () => {
    return sentRequests && sentRequests.length > 0 ? (
      sentRequests.map((sent, i) => (
        <Request
          user={sent.toUser}
          ownersRequest={true}
          setCurrentTab={setCurrentTab}
          role={role}
          connectionId={sent.id}
          loggedInUserId={user.id}
          key={i}
          createdOn={sent.createdOn}
        />
      ))
    ) : (
      <>
        {!loading && (
          <Col style={{ textAlign: "center" }} md={{ size: 6, offset: 3 }}>
            <br />
            <br />

            <img src={Empty} alt={"error"} />
            <h3 className="goals-heading">No Requests</h3>
            <br />
            <br />
          </Col>
        )}
      </>
    );
  };
  const displayReceivedRequests = () => {
    return receivedRequests && receivedRequests.length > 0 ? (
      receivedRequests.map((received, i) => {
        return (
          <Request
            user={received.fromUser}
            ownersRequest={false}
            setCurrentTab={setCurrentTab}
            role={role}
            connectionId={received.id}
            loggedInUserId={user.id}
            createdOn={received.createdOn}
            key={i}
          />
        );
      })
    ) : (
      <>
        {!loading && (
          <Col style={{ textAlign: "center" }} md={{ size: 6, offset: 3 }}>
            <br />
            <br />

            <img src={Empty} alt={"error"} />
            <h3 className="goals-heading">No Requests</h3>
            <br />
            <br />
          </Col>
        )}
      </>
    );
  };
  return (
    <>
      <div className="notranslate req-conn-container">
        <div className="card mt-3 pt-3" style={{ border: "none" }}>
          {/* <InfiniteScroll
            dataLength={
              currentTab === "Sent Requests"
                ? sentRequests.length
                : receivedRequests.length
            }
            next={fetchMoreRequests}
            hasMore={
              currentTab === "Sent Requests"
                ? sentPageDetails.hasNextPage
                : receivedPageDetails.hasNextPage
            }
            loader={<ContentLoaderC />}
          > */}
          <Col>
            {currentTab === "Sent Requests"
              ? displaySentRequests()
              : displayReceivedRequests()}
          </Col>
          {loading && (
            <div style={{ width: "100%", margin: "0 25px" }}>
              <ContentLoaderC />
            </div>
          )}
          {/* </InfiniteScroll> */}
        </div>

        {/* <hr /> */}
      </div>

      {hasNext() && (
        <Row style={{ marginTop: "-150px", marginBottom: "100px" }}>
          <Button
            disabled={loading}
            onClick={fetchMoreRequests}
            className="loadmore-btn"
          >
            Show more
          </Button>
        </Row>
      )}
    </>
  );
};

Requests.propTypes = {};

export default Requests;
