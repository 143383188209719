import React, { useState } from "react";
import { MainContentLeft } from "./MainContentLeft";
import { MainContentRight } from "./MainContentRight";
import { Col, Row } from "reactstrap";
import "./_mainContent.scss";
import TabNavs from "src/components/TabNavs/TabNavs";
import { useParams } from "react-router-dom";
import { getDefaultGUID } from "src/utils/helper";

export const MainContent = ({ props, courseProgress }) => {
  const { eventId } = useParams();

  const tabTitles =
    eventId !== getDefaultGUID()
      ? ["Overview", "Course Modules", "Q and A", "Assessment"]
      : ["Overview", "Course Modules", "Q and A"];

  const [activeTab, setActiveTab] = useState(tabTitles[0]);
  return (
    <div className="training-main-content">
      <div style={{ marginLeft: "15px" }}>
        <TabNavs
          titles={tabTitles}
          activeTab={activeTab}
          setTab={setActiveTab}
        />
      </div>
      <Row className="tr-main-c">
        <Col md={12} sm={12} lg={9}>
          <MainContentLeft
            activeTab={activeTab}
            props={props}
            courseProgress={courseProgress}
          />
        </Col>
        <Col md={12} sm={12} lg={3}>
          <MainContentRight />
        </Col>
      </Row>
    </div>
  );
};
