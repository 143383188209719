import React, { useState, useEffect } from "react";
import "./CreatePost.scss";
import CreatePostModal from "./CreatePostModal/CreatePostModal";
// import CreateGoalModal from "src/components/CreateGoalModal/CreateGoalModal";
import CreateQuestion from "src/modules/homepage/components/CreateQuestion/CreateQuestion";
import ReactAvatar from "react-avatar";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { useCurrentUser } from "src/utils/Services/currentUser";

const CreatePost = ({ userName, dp, groupId }) => {
  const [showPostModal, setShowPostModal] = useState(false);
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const user = useCurrentUser();
  // const [modal, setModal] = useState(false);

  const location = useLocation();

  const { shareInPost } = queryString.parse(location.search);

  const history = useHistory();

  const toggleCreatePostModal = () => setShowPostModal(!showPostModal);

  // const toggle = () => setModal(!modal);
  const toggleQuestionModal = () => setShowQuestionModal(!showQuestionModal);

  useEffect(() => {
    shareInPost && setShowPostModal(true);
  }, [shareInPost]);

  return (
    <div className="create-post">
      <div className="create-post__top-actions">
        <div onClick={toggleCreatePostModal} className="active-tab">
          post
        </div>
        <div onClick={toggleQuestionModal}>create a poll</div>
        <div
          onClick={() =>
            history.push({
              pathname: `/profile/${user.userId}`,
              search: "active=Personal Goals",
            })
          }
        >
          create personal goal
        </div>
      </div>
      <div className="create-post__main-actions">
        {dp === null || dp === undefined || dp === "" ? (
          <ReactAvatar
            className="notranslate"
            name={userName}
            size={45}
            round={true}
          />
        ) : (
          <img src={dp} alt="profile" className="card-div__dp" />
        )}
        <div
          className="create-post__post-container"
          onClick={toggleCreatePostModal}
        >
          <h3>Share a post, {userName}?</h3>
        </div>
      </div>
      <div className="create-post__bottom-actions">
        <div className="icons-div">
          <span onClick={toggleCreatePostModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                fill="#919BA8"
                fillRule="evenodd"
                d="M8.876 18h-.045c-1.38-.011-2.802-.56-3.904-1.506-1.192-1.026-1.842-2.355-1.83-3.743l.065-8.37c.009-1.08.559-2.104 1.55-2.888C5.598.791 6.701.408 7.773.4c2.213.018 3.998 1.832 3.98 4.044l-.053 6.776c-.006.752-.304 1.457-.841 1.986-.538.527-1.261.81-2 .808-.75-.006-1.455-.304-1.982-.84-.528-.537-.815-1.246-.81-1.998l.04-5.156c.005-.455.376-.82.83-.817.455.004.822.375.817.83l-.04 5.156c-.002.312.117.607.337.83.218.222.511.346.823.348.3-.005.608-.116.83-.336.223-.22.347-.511.35-.823l.053-6.777c.01-1.304-1.042-2.373-2.347-2.384-.635.007-1.437.27-2.029.739-.582.46-.919 1.046-.923 1.608l-.066 8.37c-.007.896.44 1.778 1.258 2.48.798.688 1.86 1.102 2.843 1.11h.033c.994 0 2.002-.357 2.769-.984.784-.638 1.22-1.48 1.227-2.368l.053-6.75c.003-.454.375-.82.83-.816.454.003.82.374.817.83l-.053 6.749c-.015 1.923-1.258 3.657-3.414 4.572-.7.298-1.469.413-2.23.413"
              />
            </svg>
          </span>

          <span onClick={toggleCreatePostModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                fill="#919BA8"
                fillRule="evenodd"
                d="M2.894 2.977c-3.437 3.437-3.437 9.009 0 12.446 3.436 3.436 9.008 3.436 12.445 0 3.436-3.437 3.436-9.009 0-12.446-3.437-3.436-9.009-3.436-12.445 0zm11.348 11.35c-2.83 2.83-7.421 2.83-10.252 0-2.831-2.832-2.831-7.422 0-10.253 2.831-2.832 7.421-2.832 10.252 0 2.832 2.83 2.832 7.421 0 10.252zm-1.675-3.495c.315 0 .5.342.341.615-.76 1.301-2.168 2.183-3.788 2.183-1.63 0-3.046-.893-3.803-2.21-.15-.262.053-.588.355-.588zm-.563-4.492c.53 0 .962.431.962.962 0 .532-.431.963-.962.963-.532 0-.963-.431-.963-.963 0-.53.431-.962.963-.962zm-5.775 0c.53 0 .962.431.962.962 0 .532-.431.963-.962.963-.532 0-.963-.431-.963-.963 0-.53.431-.962.963-.962z"
              />
            </svg>
          </span>

          {/* <span onClick={toggleCreatePostModal}> */}
          {/* <svg */}
          {/* xmlns="http://www.w3.org/2000/svg" */}
          {/* width="24" */}
          {/* height="24" */}
          {/* viewBox="0 0 24 24" */}
          {/* > */}
          {/* <path */}
          {/* fill="#919BA8" */}
          {/* fillRule="evenodd" */}
          {/* d="M6 1.5h12c2.485 0 4.5 2.015 4.5 4.5v12c0 2.485-2.015 4.5-4.5 4.5H6c-2.485 0-4.5-2.015-4.5-4.5V6c0-2.485 2.015-4.5 4.5-4.5zm0 1C4.067 2.5 2.5 4.067 2.5 6v12c0 1.933 1.567 3.5 3.5 3.5h12c1.933 0 3.5-1.567 3.5-3.5V6c0-1.933-1.567-3.5-3.5-3.5H6zM8.306 9c.803 0 1.472.201 2.007.604.535.402.889.95 1.061 1.645h-1.147c-.146-.37-.387-.665-.721-.886-.335-.22-.731-.331-1.19-.331-.42 0-.798.1-1.132.302-.335.201-.596.487-.784.857-.188.37-.282.804-.282 1.304 0 .526.096.977.287 1.353.19.377.46.664.807.862.348.198.75.297 1.21.297.56 0 1.04-.177 1.442-.53.402-.354.644-.84.726-1.456H8.115v-.818h3.393v1.061c-.077.507-.253.966-.53 1.378-.278.412-.644.741-1.1.988-.455.247-.976.37-1.562.37-.656 0-1.236-.15-1.74-.448-.503-.298-.891-.712-1.165-1.241C5.137 13.78 5 13.177 5 12.495c0-.681.137-1.287.41-1.816.275-.529.662-.94 1.162-1.236C7.072 9.148 7.65 9 8.306 9zm5.39.058v6.874h-1.09V9.058h1.09zm5.304 0v.896h-2.896v2.132h2.39v.857h-2.39v2.989h-1.089V9.058H19z" */}
          {/*  /> */}
          {/* </svg> */}
          {/* </span> */}

          {/* <span onClick={toggleQuestionModal}> */}
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"> */}
          {/* <path fill="#92929D" fillRule="evenodd" d="M18 5.5h2c.828 0 1.5.672 1.5 1.5v13c0 .828-.672 1.5-1.5 1.5h-2c-.828 0-1.5-.672-1.5-1.5V7c0-.828.672-1.5 1.5-1.5zm0 1c-.276 0-.5.224-.5.5v13c0 .276.224.5.5.5h2c.276 0 .5-.224.5-.5V7c0-.276-.224-.5-.5-.5h-2zm-16 6h2c.828 0 1.5.672 1.5 1.5v6c0 .828-.672 1.5-1.5 1.5H2c-.828 0-1.5-.672-1.5-1.5v-6c0-.828.672-1.5 1.5-1.5zm0 1c-.276 0-.5.224-.5.5v6c0 .276.224.5.5.5h2c.276 0 .5-.224.5-.5v-6c0-.276-.224-.5-.5-.5H2zm8-13h2c.828 0 1.5.672 1.5 1.5v18c0 .828-.672 1.5-1.5 1.5h-2c-.828 0-1.5-.672-1.5-1.5V2c0-.828.672-1.5 1.5-1.5zm0 1c-.276 0-.5.224-.5.5v18c0 .276.224.5.5.5h2c.276 0 .5-.224.5-.5V2c0-.276-.224-.5-.5-.5h-2z"/> */}
          {/* </svg> */}
          {/* </span> */}

          {/* <span className="icon-view"> */}
          {/* <svg */}
          {/* xmlns="http://www.w3.org/2000/svg" */}
          {/* width="15" */}
          {/* height="17" */}
          {/* viewBox="0 0 15 17" */}
          {/* > */}
          {/* <g fill="none" fillRule="evenodd"> */}
          {/* <path */}
          {/* fill="#FFD7BF" */}
          {/* d="M7.905.421c-2.478 0-4.483 1.72-4.483 3.846 0 2.125 2.005 3.845 4.483 3.845 1.696 0 3.07-1.72 3.07-3.845C10.974 2.14 9.601.42 7.904.42z" */}
          {/* /> */}
          {/* <path */}
          {/* fill="#4A90E2" */}
          {/* d="M7.84 9.572c-4.082 0-7.39 3.03-7.39 6.767h13.327c0-3.737-2.871-6.767-5.937-6.767z" */}
          {/* /> */}
          {/* <path */}
          {/* fill="#202841" */}
          {/* fillRule="nonzero" */}
          {/* d="M7.238 8.31c2.298 0 4.168-1.865 4.168-4.155C11.406 1.864 9.536 0 7.238 0S3.07 1.864 3.07 4.154c0 2.291 1.87 4.155 4.168 4.155zm0-7.468c1.832 0 3.323 1.486 3.323 3.313 0 1.826-1.49 3.312-3.323 3.312-1.832 0-3.323-1.486-3.323-3.312 0-1.827 1.49-3.313 3.323-3.313z" */}
          {/* /> */}
          {/* <path */}
          {/* fill="#202841" */}
          {/* d="M7.238 9.993c3.369 0 6.135 2.621 6.353 5.925H2.357v.842h11.67c.233 0 .422-.188.422-.42 0-3.964-3.235-7.189-7.21-7.189-3.977 0-7.212 3.225-7.212 7.188 0 .233.189.421.422.421h1.908v-.842H.886c.218-3.304 2.984-5.925 6.352-5.925zM7.238 6.064c.554 0 1.08-.24 1.445-.658.153-.176.135-.442-.041-.595-.176-.152-.443-.133-.596.042-.204.234-.499.368-.808.368-.309 0-.603-.134-.807-.368-.154-.175-.42-.194-.596-.042-.176.153-.195.419-.042.595.365.418.891.658 1.445.658z" */}
          {/* /> */}
          {/* </g> */}
          {/* </svg> */}
          {/* Everyone */}
          {/* </span> */}
        </div>
        <button className="share-btn">share</button>
      </div>
      <CreatePostModal
        modal={showPostModal}
        toggle={toggleCreatePostModal}
        user={userName}
        dp={dp}
        groupId={groupId}
      />
      {/* <CreateGoalModal modal={modal} toggle={toggle} /> */}
      <CreateQuestion
        modal={showQuestionModal}
        toggle={toggleQuestionModal}
        user={userName}
        dp={dp}
      />
    </div>
  );
};

export default React.memo(CreatePost);
