/* eslint-disable no-multi-str */
import React, { useState, useContext } from "react";
import { Context } from "src/store";
import Loader from "src/components/Loader/Loader";
import { createTopic } from "src/requests/TopicRequest";
import { Editor } from "@tinymce/tinymce-react";

const CreateThread = ({ cancel, categories }) => {
  const titleLimit = 100;
  const [isLoading, setIsLoading] = useState(false);
  const [threadInfo, setThreadInfo] = useState({
    name: "",
    about: "",
    categoryId: "",
  });
  const { dispatch } = useContext(Context);

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setThreadInfo({
      ...threadInfo,
      [name]: value,
    });
  };

  const categoriesOptions = categories.map((category) => {
    const { id, name } = category;
    const option = { value: id, label: name };
    return option;
  });

  const options = categoriesOptions.map((category, index) => (
    <option key={index} value={category.value}>
      {category.label}
    </option>
  ));

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    createTopic(dispatch, threadInfo).then((topicRes) => {
      if (topicRes.status === 201) {
        cancel();
      }
      setIsLoading(false);
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    cancel();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-div">
        <div className="community-form-div">
          <label htmlFor="title" className="community-form-div__label">
            Title <span>- Required</span>
          </label>
          <div className="community-form-div__input-div">
            <input
              id="title"
              name="name"
              type="text"
              className="community-form-input"
              required
              onChange={handleInputChange}
              maxLength={
                threadInfo.name.replace(/ /g, "").length === titleLimit
                  ? 0
                  : 200
              }
              placeholder="What is this about in a brief sentence"
            />
            <label className="community-form-label">
              {threadInfo.name.replace(/ /g, "").length}/{titleLimit}
            </label>
          </div>
        </div>
        <div className="community-form-div">
          <label htmlFor="description" className="community-form-div__label">
            Description <span>- Required</span>
          </label>
          <div className="community-form-div__input-div">
            <Editor
              apiKey={process.env.REACT_APP_TINY_CLOUD_KEY}
              init={{
                width: "100%",
                // menubar: false,
                placeholder: "Topic description",
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help",
              }}
              onEditorChange={(content, editor) => {
                setThreadInfo({
                  ...threadInfo,
                  about: content,
                });
              }}
              value={threadInfo.about}
            />
          </div>
        </div>
        <div className="community-form-div">
          <label htmlFor="category" className="community-form-div__label">
            Select Category <span>- Required</span>
          </label>
          <div className="community-form-div__input-div">
            <select
              id="category"
              className="community-form-input"
              required
              name="categoryId"
              onChange={handleInputChange}
            >
              <option>Select Category</option>
              {options}
            </select>
          </div>
        </div>
      </div>
      <div className="community-buttons">
        <button
          className="community-button community-cancel-button"
          onClick={handleCancel}
        >
          cancel
        </button>
        <button
          className="community-button community-create-button"
          disabled={isLoading || threadInfo.about === ""}
          type="submit"
        >
          {isLoading ? <Loader color="light" /> : "create"}
        </button>
      </div>
    </form>
  );
};

export default CreateThread;
