import React, { useState, useContext, useEffect } from "react";
import classes from "./UserReactions.module.css";
import { Popover, PopoverBody } from "reactstrap";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { FiShare2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Context } from "src/store";
import { likeComment, deleteComment } from "src/requests/ToolsRequest";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import { useCurrentUser } from "src/utils/Services/currentUser";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const UserReactions = ({
  message,
  commentId,
  totalLikes,
  allLikesIds,
  createdBy,
  reload,
}) => {
  const { dispatch } = useContext(Context);
  const user = useCurrentUser().userId;

  const [liked, setLiked] = useState(false);
  const [countLikes, setCountLikes] = useState(totalLikes);
  // const [showReplyComment, setShowReplyComment] = useState(false);
  const [shareComment, setShareComment] = useState(false);
  // const [double, setDouble] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    hasLiked() ? setLiked(true) : setLiked(false);
  }, []);

  const { t } = useTranslation();

  const hasLiked = () => allLikesIds.includes(user);

  const handleLikeComment = () => {
    setLoading(true);
    likeComment(dispatch, commentId).then((res) => {
      if (res.status === 200) {
        hasLiked() ? setLiked(false) : setLiked(true);
        liked ? setCountLikes(countLikes - 1) : setCountLikes(countLikes + 1);
        setLoading(false);
      }
    });
  };

  // const handleReplyComment = () => {
  //   setShowReplyComment(!showReplyComment);
  // };

  const handleShareComment = () => setShareComment(!shareComment);

  const canDelete = () => user === createdBy;

  const handleDeleteComment = () => {
    Swal.queue([
      {
        title: "Delete This Comment?",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#da291c",
        cancelButtonColor: "#63666a",
        preConfirm: () =>
          deleteComment(dispatch, commentId)
            .then((res) => {
              reload();
            })
            .catch(() =>
              Swal.fire({
                icon: "error",
                title: "An error has occured!",
                text: "You cannot delete comment right now, please try later.",
                confirmButtonColor: "#da291c",
              })
            ),
      },
    ]);
  };
  return (
    <div>
      <div className={classes.UserReactions}>
        <div
          onClick={() => handleLikeComment()}
          className={`${classes.ActionButton} ${
            loading ? classes.Disabled : ""
          }`}
        >
          {liked ? (
            <span className="mr-1">
              <FaHeart
                size={15}
                style={{ color: "#cf1d10", cursor: "pointer" }}
              />
            </span>
          ) : (
            <span className="mr-1">
              <FaRegHeart
                size={15}
                style={{ cursor: "pointer", color: "#444" }}
              />
            </span>
          )}

          <span>
            <span>{countLikes}</span>
            <span>Likes</span>
          </span>
        </div>
        <div
          className={classes.ActionButton}
          onClick={handleShareComment}
          id="shareComment"
        >
          <span className="mr-1">
            <FiShare2 size={15} style={{ cursor: "pointer", color: "#444" }} />
          </span>
          <span>Share</span>
          <Popover
            className={`p-0 border-0 d-none d-md-block`}
            trigger="legacy"
            placement="bottom"
            isOpen={shareComment}
            target="shareComment"
            toggle={handleShareComment}
          >
            <PopoverBody>
              <ul className="share-options__lists">
                <li>
                  <TwitterShareButton url="tefconnect.com" title={message}>
                    <TwitterIcon size={20} round={true} className="icon-tab" />
                    Share via Twitter
                  </TwitterShareButton>
                </li>
                <li>
                  <FacebookShareButton url="tefconnect.com" quote={message}>
                    <FacebookIcon size={20} round={true} className="icon-tab" />
                    Share via Facebook
                  </FacebookShareButton>
                </li>
                <li>
                  <LinkedinShareButton
                    url="tefconnect.com"
                    title="test"
                    summary={message}
                  >
                    <LinkedinIcon size={20} round={true} className="icon-tab" />
                    Share via LinkedIn
                  </LinkedinShareButton>
                </li>
              </ul>
            </PopoverBody>
          </Popover>
        </div>
        {canDelete() && (
          <div className={classes.ActionButton} onClick={handleDeleteComment}>
            <span>
              <RiDeleteBin6Line
                size={15}
                style={{ cursor: "pointer", color: "#444" }}
              />
            </span>
            <span>{t("Delete")}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserReactions;
