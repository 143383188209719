import React from "react";
import shortid from "shortid";
import ReactTooltip from "react-tooltip";

const Interest = ({
  name,
  id,
  followers,
  onFollowClick,
  userInterest,
  isFolllowing,
  selected,
}) => {
  const toolTip = shortid.generate().toString();

  const status = () => {
    return isFolllowing ? "Unfollowing..." : "Following...";
  };

  return (
    <div
      className={`interest-wrapper ${isFolllowing ? "following" : ""}`}
      id={toolTip}
      data-tip
      data-for={toolTip}
      onClick={() => onFollowClick({ ...userInterest, id, isFolllowing })}
    >
      <span className="interest-text">{selected === id ? status() : name}</span>
      <ReactTooltip id={toolTip} place="top" effect="solid" className="tt">
        {followers} follower(s)
        <br />
        Click to {isFolllowing ? "Unfollow" : "follow"} {name}
      </ReactTooltip>
    </div>
  );
};

Interest.propTypes = {};

export default Interest;
