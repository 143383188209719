import React from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { useLocation, Link } from "react-router-dom";
import "./RightSideRar.scss";

import { useTranslation } from "react-i18next";

export const RightSideRar = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div className="right-side-bar">
      <div className="application-tips">
        <h3>
          <span role="img" aria-label="star">
            ⭐
          </span>{" "}
          {t("Application Tips")}
        </h3>
        <p>{t("sideTip")}</p>
      </div>
      {location.pathname.indexOf("offcycle") > -1 ? (
        <Link to="/application/offcycle/faq">
          <div className="need-help">
            <FaQuestionCircle color="#0db884" /> {t("FAQ")}
          </div>
        </Link>
      ) : (
        <Link to="/application/faq">
          <div className="need-help">
            <FaQuestionCircle color="#0db884" /> {t("FAQ")}
          </div>
        </Link>
      )}
    </div>
  );
};
