import React from "react";
// import { Progress } from "reactstrap";
import classes from "./PollCard_v2.module.scss";

const PollCardResultButton = ({ option, percentageCount }) => {
  return (
    <button className={`${classes.reviewed}`}>
      <div
        className={classes.bar}
        style={{
          width: `${
            percentageCount <= 0 ? percentageCount : percentageCount + 1
          }%`,
        }}
      ></div>
      <span className={classes.optionText}>{option}</span>
      <span className={classes.resultText}>{percentageCount} %</span>
    </button>
  );
};

export default PollCardResultButton;
