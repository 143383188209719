import React, { useState } from "react";

import { Button, Modal, Spinner } from "reactstrap";
import SelectOptions from "src/modules/Tef-Dms/components/SelectOptions/SelectOptions";
import { MdCloudUpload } from "react-icons/md";
import DropZone from "react-drop-zone";
import { GrClose } from "react-icons/gr";

import classes from "./UploadDocumentModal.module.css";

const UploadDocumentModal = ({
  handleUpload,
  documentTypes,
  handleDocumentSelect,
  uploadLoader,
  canUpload,
}) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <React.Fragment>
      {!canUpload && (
        <Button style={{ width: "100%" }} onClick={toggle}>
          Upload Document
        </Button>
      )}
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={`modal-dialog-centered ${classes.ModalContainer}`}
      >
        <div className={classes.Content}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 style={{ fontWeight: "700" }}>Upload Document</h5>
            <GrClose
              style={{ marginTop: "0.4em", cursor: "pointer" }}
              onClick={toggle}
            />
          </div>

          <div style={{ display: "flex" }}>
            Document Type:
            <SelectOptions
              handleDocumentSelect={handleDocumentSelect}
              documentTypes={documentTypes}
            />
          </div>
          <div className={classes.DashedContainer}>
            <div>
              <MdCloudUpload size={80} color="#BDC3CC" />
            </div>
            <p className={classes.DragAndDrop}>
              Drag & Drop <br />
              <span className={classes.Text}>
                your files to upload or{" "}
                <DropZone onDrop={(file) => handleUpload(file)}>
                  {({ over, overDocument }) => (
                    <span className={classes.Browse}>
                      browse to upload {uploadLoader && <Spinner />}
                    </span>
                  )}
                </DropZone>
              </span>
            </p>
            <p className={classes.Central}>
              <small>Max. size: 200MB</small>
              <small>Format PDF, JPG, PNG, DOC, DOCX</small>
            </p>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default UploadDocumentModal;
