import React, { useEffect, useState } from "react";
import "./SocialSignupButton.scss";
import { FaLinkedinIn } from "react-icons/fa";
import { AuthURL } from "src/constants";
import { FcGoogle } from "react-icons/fc";
import Loader from "src/components/Loader/Loader";
import { clearLoginProvider, setLoginProvider } from "src/utils/loginMethod";

const SocialButton = ({ provider, favicon, returnUrl }) => {
  const [loading, setLoading] = useState(false);
  const clickHandler = () => {
    setLoading(true);
    setLoginProvider(provider + " SSO");
  };
  return (
    <form
      action={AuthURL.EXTERNAL_LOGIN_USER_URL}
      method="GET"
      className="btn-form"
    >
      <input type="hidden" name="returnUrl" defaultValue={returnUrl} />
      <input type="hidden" name="scheme" defaultValue={provider} />
      <button
        className="btn-linkedin social-button-border"
        onClick={clickHandler}
        data-testid={provider}
      >
        {loading ? <Loader size={20} /> : favicon}
        <span>Sign in with {provider}</span>
      </button>
    </form>
  );
};

/** Social signup with SSO enabled. */
const SocialSignupButtons = ({ returnUrl }) => {
  useEffect(() => {
    clearLoginProvider();
  }, []);
  return (
    <div className="social-button-div">
      <SocialButton
        provider="LinkedIn"
        favicon={<FaLinkedinIn color="#0073b1" size={20} className="favicon" />}
        returnUrl={returnUrl}
      />
      <div className="spacer" />
      <SocialButton
        provider="Google"
        favicon={<FcGoogle color="#0073b1" size={20} className="favicon" />}
        returnUrl={returnUrl}
      />
    </div>
  );
};

export default SocialSignupButtons;
