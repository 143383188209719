/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import { TabContent, Tabs } from "src/components/CustomTab/CustomTab";

// import downloadIcon from "src/assets/img/downloadIcon.png";
// import pdfIcon from "src/assets/img/pdfIcon.png";
// import FAQ from "src/components/FAQ/FAQ";
// import { getCourseOverview } from "src/requests/TrainingRequest";
// import { Context } from "src/store";
import { Overview } from "./Overview";
import { TrainingModule } from "./Modules";
import { Context } from "src/store";
import QuestionAndAnswer from "./QAndA";
import AssessmentSummary from "./AssessmentSummary/Assessment";
import { useParams } from "react-router-dom";
import { getCourseAssessmentSummary } from "src/requests/TrainingRequest";

export const MainContentLeft = ({ activeTab, props, courseProgress }) => {
  const { state, dispatch } = useContext(Context);
  const { courseId, eventId } = useParams();

  const getSummary = () => {
    getCourseAssessmentSummary(eventId, courseId, dispatch);
  };

  useEffect(() => {
    getSummary();
  }, []);
  return (
    <div>
      <Tabs activeTab={activeTab}>
        <TabContent title="Overview">
          <Overview
            overview={state.Training?.userCourseModules?.overview}
            loading={state.Training.loadingGetUserTrainingModules}
          />
        </TabContent>
        <TabContent title="Course Modules">
          <TrainingModule
            modules={state.Training?.userCourseModules?.modules}
            courseProgress={courseProgress}
          />
        </TabContent>
        <TabContent title="Q and A">
          <div className="discussion">
            <h6>DISCUSSIONS</h6>
            <QuestionAndAnswer />
          </div>
        </TabContent>

        <TabContent title="Assessment">
          <div className="discussion">
            <AssessmentSummary
              modules={state.Training?.userCourseModules?.modules}
            />
          </div>
        </TabContent>
      </Tabs>
    </div>
  );
};
