/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { FaAngleLeft } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";

import "./Document.scss";

const TermOfUse = () => {
  const history = useHistory();
  const onBackClick = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <div className="document">
      <Link to="#" onClick={onBackClick}>
        <h6 className="declaration-h6">
          <FaAngleLeft /> &nbsp; Back
        </h6>
      </Link>
      <h3 className="red-text bolder-text center">Terms of Service</h3>
      <p className="text-italic">
        Welcome to TEFConnect - the largest digital entrepreneur ecosystem in
        Africa
      </p>
      <ol className="structured-list">
        <li>
          <span className="bolder-text">Introduction</span>
          <ol className="structured-list">
            <li>
              <span className="bolder-text">Agreement</span>
              <p>
                Please read these Terms of Service ("Terms", "Terms of Service")
                carefully before using the TEFConnect platform (the “Platform”)
                operated by the Tony Elumelu Foundation (the “Foundation”).
              </p>
              <p>
                These Terms govern your access to and use of TEFConnect and
                services provided on the Platform (the “Service”) either as a
                Visitor or Member, and any information, figures, data, text,
                links, graphics, photos, audio, videos, branding (including
                trade names, trademarks, service marks, logos), interactive
                features, software, metrics, and other material(s) uploaded,
                downloaded, accessible or appearing on or through the Platform.
                (collectively referred to as “Content”). Content is provided by
                the Foundation and other users (collectively referred to as
                “Users”)
              </p>
              <p>
                By accessing or using the Platform you agree to be bound by
                these Terms, some of which survive your use of the Platform. If
                you disagree to these Terms, do not access or otherwise use the
                Platform. If you wish to nullify your agreement, at any time,
                you can do so by closing your account and/or no longer accessing
                or using the Platform.
              </p>
              <p>
                Failure to comply with these Terms may result in you losing
                access to the Platform and the Services.
              </p>
            </li>
            <li>
              <span className="bolder-text">Change</span>
              <p>
                The Tony Elumelu Foundation may modify these Terms from time to
                time. If material changes are made to these, you will be
                notified, to provide you the opportunity to review the changes
                before they become effective. If you object to any changes, you
                may cease using the Platform by closing your account. Your
                continued use of the Platform after changes to the Terms have
                been sent or published means that you consent to the Terms as
                updated.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <span className="bolder-text">Privacy</span>
          <p>
            We process all data in accordance with our Privacy Policy. By using
            the Platform, you consent to such processing and warrant that all
            data provided by you is accurate. Our{" "}
            <Link to="/privacy" className="red-text">
              Privacy Policy
            </Link>{" "}
            describes how the information you provide to us is handled. You
            understand that through your use of the Platform you consent to the
            collection and use (as set forth in the Privacy Policy) of your
            information.
          </p>
        </li>
        <li>
          <span className="bolder-text">Use of the Platform</span>
          <ol className="structured-list">
            <li>
              <span className="bolder-text">Members and Visitors</span>
              <p>
                The Platform is not for use by anyone under the age of 18 or
                under the applicable minimum legal age to use a Platform such as
                this in their country (“
                <span className="bolder-text">Minimum Age</span>”). By using the
                Platform, you agree that you are above the Minimum Age.
              </p>
              <p>
                When you register and join the Platform as an ‘Entrepreneur’,
                ‘Mentor’, ‘Investor’, you become a Member.
              </p>
              <p>
                If you have been previously restricted from using the Platform,
                do not attempt to make use of the Platform. You therefore
                confirm that you are not already restricted by the Foundation
                from using the Platform.
              </p>
            </li>
            <li>
              <span className="bolder-text">Your Account</span>
              <p>
                Membership is required for access to some Services on the
                Platform and Members are responsible for safeguarding their
                accounts with strong and secure passwords. You agree not to
                share your password or transfer your account to another User
                (without confirmation from the Administrators of the Platform).
                You are responsible for any activity undertaken with your
                account thing that happens through your account unless prior
                notification has always been given to the Foundation on possible
                misuse or transfer .
              </p>
              <p>
                A Member should not have multiple accounts or use pseudonyms,
                you therefore agree that you will have only one TEFConnect
                account in your official government name or name that you use in
                your everyday life. You agree not to create an account using
                false information or with the intention to impersonate another
                person.
              </p>
              <p>
                Notices and Messages will be provided to account holders
                (Members) within the Platform or via the contact information
                provided (e.g. Email and Mobile number), and Members agree to
                keep their contact information up to date.
              </p>
              <p>
                The Foundation may restrict, suspend or revoke your access to
                your account, the Platform and Services provided if (a) you fail
                to fully comply with these Terms, or (b) your conduct on the
                Platform is considered by the Foundation to be inconsistent with
                or detrimental to the purpose of the Platform.
              </p>
              <p>
                The Foundation may, in its sole discretion, accept or reject an
                application to open an account on the Platform for any reason or
                no reason at all.
              </p>
            </li>
            <li>
              <span className="bolder-text">Services on the Platform</span>
              <p>
                The Foundation may decide to modify, suspend or discontinue any
                Service provided on the Platform in its sole discretion and
                information relating to such Services may not be stored,
                maintained or shared upon request. To the extent allowed by law,
                these changes may be effective upon notice provided to Users of
                the Platform.
              </p>
              <p>
                The Foundation reserves the right to limit use of certain
                Services.The Services are provided by the Foundation for free.
                No fee or charge applies, neither is anyone authorised to charge
                a fee for the use of the Services. You shall not sell, lease,
                rent or charge for the Services or access to the Platform. Any
                known violation of these Terms should be reported to the
                Foundation immediately. The Foundation reserves the right to
                amend this provision at any time if it deems it necessary.
              </p>
            </li>
            <li>
              <span className="bolder-text">Conduct on the Platform</span>
              <p>
                The Foundation wishes to create a welcoming and positive
                environment for all Users. Therefore, all interactions on the
                Platform shall be carried out in a professional manner.
              </p>
              <p>You shall not post any Content which:</p>
              <ul>
                <li>
                  Is vexatious, defamatory, obscene, threatening, offensive,
                  inappropriate or discriminatory against any race, religion,
                  gender, culture or sexual preference, physical or mental
                  condition.
                </li>
                <li>
                  Promotes violence, terrorism or is otherwise unlawful,
                  fraudulent, deceptive or misleading.
                </li>
                <li>
                  Violates any person’s privacy rights including revealing their
                  personal information including their phone number, email or
                  physical address, banking details, or any other information
                  that could be used to identify, impersonate or contact such
                  person
                </li>
                <li>Which violates any of these Terms</li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <span className="bolder-text">Platform Content</span>
          <ol className="structured-list">
            <li>
              <span className="bolder-text">Access to your Content</span>
              <p>
                The TEFConnect Platform allows Users share Content on the
                Platform in numerous ways, such as through their Profile,
                Messaging, Community discussions, Marketplace, Business Tools
                and general comments. Certain shared Content and information
                will be seen by other Users and are therefore considered
                non-confidential and non-proprietary
              </p>
              <p>
                The Foundation may request for specific actions or additional
                Content from you which may be required to improve Users’
                experience on the Platform. By posting Content on the Platform,
                you agree to other Users having access to your Content. The
                Foundation does not have control over who views your Content and
                how it is reposted or shared and will not be liable for any copy
                or reproduction of your Content by other Users. In some
                instances, you can select what personal information is visible
                to other Users by adjusting your privacy settings (for example,
                contact details).
              </p>
            </li>
            <li>
              <span className="bolder-text">Platform and Content security</span>
              <p>
                You shall not upload any viruses, worms, malware, trap doors or
                any other codes, instructions or devices of a similar nature
                that may be used to access, modify, delete, disable, corrupt,
                harm or impair the proper working of the Platform or any device
                on which the Platform is accessed.
              </p>
              <p>
                You shall not reverse, engineer, decompile, disassemble or
                otherwise attempt to derive the source code of the Platform or
                any of the Services provided.
              </p>
              <p>
                You shall not override any security feature of the Platform or
                bypass or circumvent any access control or restrictions on the
                Services put in place by the Foundation.
              </p>
            </li>
            <li>
              <span className="bolder-text">Use of Content</span>
              <p>
                Use or reliance on any Content shared on the Platform or
                obtained through the Platform is at the risk of the User. The
                Foundation does not endorse, support, represent or guarantee the
                accuracy, reliability or security of any Content or opinions
                expressed on the Platform. Content shared by Users on the
                Platform are the sole responsibility of those who such Content
                originated from. We may not monitor or control the Content
                shared, thus, we cannot take responsibility for such Content.
              </p>
              <p>
                Any non-compliant Content may be removed from the Platform by
                the Foundation. Where it considers it appropriate, the
                Foundation may by written notice, restrict a violating User from
                future use of the Platform. We encourage you to report any
                Content or User in violation of these Terms to the Foundation.
              </p>
              <p>
                Save as may be required by law or as set out in our{" "}
                <Link to="/privacy" className="red-text">
                  Privacy Policy
                </Link>
                , the Foundation has no obligation to store, maintain or provide
                you with a copy of Content you upload to the Platform.
              </p>
            </li>
            <li>
              <span className="bolder-text">Third Party Content</span>
              <p>
                The Platform contains third party Content, apps and links which
                have their own terms of use and{" "}
                <Link to="/privacy" className="red-text">
                  Privacy Policy
                </Link>{" "}
                which may differ from these Terms. By accessing these
                third-party Content, apps or links, you may be bound to those
                terms. The Foundation is not responsible for your accessing
                these links, apps or third-party Content and you agree to access
                them at your own risk.
              </p>
            </li>
            <li>
              <span className="bolder-text">
                User Content Rights, Obligations &amp; Warranties
              </span>
              <p>
                As a User, you retain your rights to Content shared or displayed
                through the Platform by you. By publishing Content on the
                Platform, you grant the Foundation and its affiliated companies
                a non-exclusive worldwide, transferable license to use, copy,
                modify, distribute, publish, translate, run, publicly perform
                and process your Content once put on the Platform. While edits
                and format changes may be made to your Content, the meaning and
                connotations behind your original Content will not be modified,
                unless with your written consent. Your Content will not be
                included in advertisements for products or services from
                affiliated companies or third parties without your specific
                consent.
              </p>
              <p>
                Though this agreement can be terminated at any time, your
                account deactivated, and majority of shared Content deleted, for
                technical reasons, Content that has been re-shared or copied may
                persist on the Platform or outside the Platform.
              </p>
              <p>
                You agree that the Foundation may access, store, process and use
                any information and personal data provided in accordance with
                the{" "}
                <Link to="/privacy" className="red-text">
                  Privacy Policy
                </Link>
                .
              </p>
              <p>
                You agree to only provide Content that does not violate any
                applicable law nor anyone’s rights (including intellectual
                property rights). Moreover, you confirm that you have, or have
                obtained, all rights and privileges, consents, licenses,
                permissions and/or authority necessary to use and share Content
                shared on the Platform. You confirm that all the Content you
                provide on the Platform will be true and accurate in all
                material respects. You will not post or upload any Content which
                you are not certain of the truth or accuracy.
              </p>
              <p>
                The Foundation is not obligated to publish any Content you
                provide on the Platform and thus can remove it in its sole
                discretion, with or without notice, without having to give any
                reason.
              </p>
            </li>
            <li>
              <span className="bolder-text">
                Foundation and Third-Party Content Rights
              </span>
              <p>
                The Foundation reserves all intellectual property rights in
                Content it provides on the Platform and the Services including
                the Foundation’s trademarked and copyrighted Content. Using the
                Platform and accessing the Services does not give or transfer
                any rights to you. Third Party intellectual property rights
                including trademarks and copyrights in Content provided by the
                Foundation or other Users remains the rights of the respective
                owners.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <span className="bolder-text">
            Disclaimer, Limitation of Liability and Indemnity
          </span>
          <ol className="structured-list">
            <li>
              <span className="bolder-text">Disclaimer</span>
              <p>
                The Services and all Content are provided without warranty of
                any kind including merchantability or fitness for purpose. The
                Foundation has not and makes no commitment to evaluate, confirm,
                endorse, or otherwise stand behind any Content provided by any
                User, including itself. The Foundation does not provide any
                legal, tax, accounting or other professional consulting or
                advise. Any Content shared containing any such material shall
                not be construed as the Foundation or the Platform providing any
                such advise or service. You are advised to seek legal, tax,
                accounting or other professional advice to protect your
                interests as appropriate.
              </p>
              <p>
                To the extent permitted by law, the Foundation, its affiliated
                companies, its trustees, officers, employees, representatives,
                partners and licensors disclaim all implied warranties and
                representations, do not guarantee that the Services of the
                Platform will function without error or interruption, and
                provide the Services and Content “as is” and “as available”.
              </p>
            </li>
            <li>
              <span className="bolder-text">Limitation of Liability</span>
              <p>
                The Foundation does not provide any endorsement, representation
                or guarantee of any kind with respect to other Users you may
                connect or engage with on the Platform or any information you
                receive from such Users. The Foundation is not an agent or
                representative of any User on the Platform and is not a party to
                any transaction or agreement entered into by Users on the
                Platform. Any form of categorisation (including use of badges or
                ratings) are not an indication of any endorsement,
                representation or guarantee of such User.
              </p>
              <p>
                The Foundation, its affiliated companies, its trustees,
                officers, employees, representatives, partners and licensors
                shall not be liable to you or others for any direct, indirect,
                incidental, consequential, or punitive damages, any loss of
                Content, loss of profit or anticipated profit, loss of
                reputation, loss of opportunities related to the provision or
                use of the Services.
              </p>
              <p>
                By using the Platform, you agree that the Foundation, its
                affiliated companies, trustees, officers, employees,
                representatives, partners and licensors liability is limited to
                the maximum extent permissible.
              </p>
              <p>
                The Foundation’s maximum aggregate liability to you under or in
                connection with the Platform or the Services howsoever arising
                shall in no circumstances exceed sum of US$500 (Five Hundred
                United States Dollars).
              </p>
              <p>
                Any cause of action related to the Services must commence within
                3(three) months after the cause of action accrues.
              </p>
            </li>
            <li>
              <span className="bolder-text">Indemnity</span>
              <p>
                You agree to indemnify and hold harmless, the Foundation, its
                affiliated companies, its trustees, officers, employees,
                representatives, partners and licensors from and against any and
                all liability, costs, claim, and expenses arising as a result of
                your use of the Platform and any violation of these Terms.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <span className="bolder-text">Termination</span>
          <p>
            Both Members and the Foundation can terminate this agreement at any
            time with given notice to the other. Each visit to the Platform
            constitutes an agreement and terminates upon exit from the Platform.
            Once you become a User, the agreement continues until your account
            is closed, which constitutes a termination. Any subsequent use of
            the Platform as a Member will however result in the creation of a
            new agreement for the duration of use. Upon termination of the
            agreement however, our rights to use and disclose your feedback and
            the rights of other Members to further re-share content to the
            extent copied or shared prior to termination, shall survive.
          </p>
        </li>
        <li>
          <span className="bolder-text">Governing Law & Jurisdiction</span>
          <p>
            This Agreement shall be governed by and interpreted and construed in
            accordance with the laws of The Federal Republic of Nigeria.
          </p>
          <p>
            The courts of the Federal Republic of Nigeria shall have
            non-exclusive jurisdiction to settle any claim or dispute arising
            from the use of the Platform and these Terms. The Foundation
            reserves the right to institute any proceedings against you for
            breach of these Terms in your country of residence or any country
            that has jurisdiction.
          </p>
        </li>
        <li>
          <span className="bolder-text">General Terms</span>
          <p>
            In the event that any provision of these Terms is held invalid or
            unenforceable, then that provision will be limited or eliminated to
            the minimum extent necessary, while the remaining provisions remain
            in full effect. The Foundation’s failure to enforce any right or
            provision of these Terms will not be deemed a waiver of such right
            or provision.
          </p>
          <p>
            These Terms are an agreement between you and the Tony Elumelu
            Foundation, with its registered office at Heirs Place, 1 MacGregor
            Road, Ikoyi, Lagos, Nigeria. Third party rights are hereby excluded.
            Please contact us if you any questions about these Terms.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default TermOfUse;
