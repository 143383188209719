import React from "react";
import { Button } from "reactstrap";

const LinkButton = ({ children, onClick }) => (
  <Button
    color="link"
    onClick={onClick}
    style={{ padding: 0, color: "red", backgroundColor: "white", margin: 2 }}
  >
    {children}
  </Button>
);

export default LinkButton;
