import React, { useContext } from "react";
import { Modal, ModalHeader, ModalBody, Input } from "reactstrap";
import PrimaryButton from "src/modules/Alumni/Components/PrimaryButton/index";
import SecondaryButton from "src/modules/Alumni/Components/SecondaryButton/index";
import { useForm } from "react-hook-form";
import ErrorMessage from "src/modules/Alumni/Components/ErrorMessage/index";
import { requestToJoinAlumniHub } from "src/requests/AlumniHubRequest";
import { toast } from "react-toastify";
import { Context } from "src/store";

const RequestToJoinHubModal = (props) => {
  const { className, toggle, modal, hubs = [], hubId } = props;
  const { register, handleSubmit, reset, formState, errors } = useForm();
  const { dispatch } = useContext(Context);
  const { isSubmitting } = formState;
  const onSubmit = async (data) => {
    data.currentHubId = "";
    requestToJoinAlumniHub(dispatch, data)
      .then(() => toast.auccess("request sent successfully"))
      .catch((requestTojoinHubError) => console.log(requestTojoinHubError));
    reset();
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle} className="border-0">
          Request to Join Hub
          <small className="d-block text-muted fs-15">
            Fill the form below and kindly provide enough reason <br />
            why you are making a request to join another group
          </small>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="current_hub" className="form-label fw-500">
                    Reuested Hub
                  </label>
                  <Input
                    type="select"
                    defaultValue={hubId || ""}
                    id="current_hub"
                    className="form-control bg-light"
                    innerRef={register({ required: true })}
                    name="hubId"
                  >
                    <option hidden>Select Hub</option>
                    {hubs.map((hub) => (
                      <option key={hub.id} value={hub.id}>
                        {hub.name}
                      </option>
                    ))}
                  </Input>
                </div>
                {errors.hubId && <ErrorMessage msg="This field is required" />}
              </div>

              <div className="col-12">
                <label htmlFor="reason" className="form-label fw-500">
                  Reason for the request
                </label>
                <textarea
                  name="reason"
                  ref={register({ required: true })}
                  id="reason"
                  cols="5"
                  className="form-control bg-light"
                ></textarea>
                {errors.reason && <ErrorMessage msg="This field is required" />}
              </div>
            </div>
            <div className="mt-3">
              <SecondaryButton buttonText="cancel" buttonAction={toggle} />
              <PrimaryButton
                buttonText="Submit Request"
                disabled={isSubmitting}
                style={{ marginLeft: 20 }}
                type="submit"
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default RequestToJoinHubModal;
