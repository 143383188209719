import React from "react";
import SuccessImage from "src/assets/img/success-tef.png";
import { useHistory } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import "../TefStory/TefStories.scss";

const EditArticleSuccess = ({ modal, toggle }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const firstName = JSON.parse(localStorage.getItem("data"))
    ? JSON.parse(localStorage.getItem("data")).user.firstName
    : null;

  return (
    <div className="create-tef-story-modal">
      <Modal isOpen={modal} toggle={toggle} modalClassName="modal-title">
        <ModalHeader toggle={toggle} className="border-bottom-0">
          <div className="create-opp-breadcrumb">
            <div className="float-left">
              <div onClick={toggle} className="cursor-pointer">
                <MdKeyboardArrowLeft />
                {t("Return to article")}
              </div>
            </div>
            <div
              className="add-story border float-right px-1"
              style={{ borderRadius: "3px", cursor: "pointer" }}
              onClick={toggle}
            >
              <span className="px-1">+</span> Add new article
            </div>
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="success-create-opp">
            <div className="">
              <div className="success-create-opp__content--image-container my-3">
                <img src={SuccessImage} alt="icon" className="img-fluid" />
              </div>
              <div className="success-create-opp__content--message">
                <h2>
                  {t("Congratulations")} {firstName}
                  <span role="img" aria-label="hello">
                    👏
                  </span>
                </h2>
                <p style={{ fontSize: "14px" }}>
                  <>
                    {t(`We already know how magnificient you are...`)}
                    <br />
                    {t(
                      `We know you have a story that will inspire and uplift,`
                    )}
                    <br />
                    {t(
                      `And we love that you were able to share your story with us`
                    )}
                  </>
                </p>
                <br />

                <div
                  onClick={() => history.push("/discover")}
                  className="submit-tef-story text-center mt-4 mx-auto w-50"
                >
                  {t("View Articles")}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EditArticleSuccess;
