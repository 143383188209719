import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { RiAddFill } from "react-icons/ri";
import Layout from "src/components/Layout/Layout";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import SidebarRight from "src/modules/homepage/components/SideRight/SideRight";
import { ifOnboardedThenShow } from "src/utils/Services/isOnboarded";
import SecondaryButton from "src/modules/Alumni/Components/SecondaryButton/index";
import LinkButton from "src/modules/Alumni/Components/LinkButton/index";
// import InputField from "src/modules/Alumni/Components/InputField/index";
import CustomLoader from "src/modules/Alumni/Components/CustomLoader";
// import Icon from "src/modules/Alumni/Components/FileIcon";
import DataBoard from "./components/DataBoard/index";
import {
  fetchBusinessSurveys,
  getBusinessReportsStatus,
  fetchDashboardData,
} from "src/requests/AlumniRequest";
import "./index.scss";

const BusinessReporting = () => {
  const history = useHistory();
  // const { dispatch } = useContext(Context);
  // const [overviewType, setOverviewType] = useState("last_update");
  const [isLoading, setIsLoading] = useState(false);
  const [isDashboardLoading, setIsDashboardLoading] = useState(false);
  const [isReportsLoading, setIsReportsLoading] = useState(false);
  const [surveys, setSurveys] = useState([]);
  const [businessReports, setBusinessReports] = useState([]);
  const [dataBoard, setDataBoard] = useState([]);
  const [singleBusinessReport, setSingleBusinessReport] = useState();
  const [pageCount, setPageCount] = useState(false);

  const surveyModel = {
    title: "",
    summary: "",
    createdOn: new Date(),
    id: "",
    reports: [],
  };

  useEffect(() => {
    _getCreatedSurveys();
    _getDashboardData();
  }, []);

  useEffect(() => {
    if (singleBusinessReport)
      setBusinessReports([...businessReports, singleBusinessReport]);
  }, [singleBusinessReport]);

  const _getCreatedSurveys = (QS) => {
    setIsLoading(true);
    fetchBusinessSurveys()
      .then((res) => {
        const { data, pager } = res.data.data;
        const surveys = [];
        data.forEach((datum) => {
          const survey = { ...surveyModel, ...datum };
          survey.createdOn = new Date(datum.createdOn);
          console.log("pageCount", pageCount);
          _getSubmittedStatusForSurvey(survey.id, {
            survey,
            setSingleBusinessReport,
            setIsReportsLoading,
          });
          // fetch the business reports submitted under this survey
          surveys.push(survey);
        });
        setSurveys(surveys);
        setPageCount(pager.pageCount);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toast("Something went wrong, try again");
      });
  };

  const _getDashboardData = () => {
    setIsDashboardLoading(true);
    fetchDashboardData()
      .then((res) => {
        const { data } = res.data;
        setDataBoard(data);
        setIsDashboardLoading(false);
      })
      .catch(() => {
        setIsDashboardLoading(false);
        toast("Something went wrong, try again");
      });
  };

  return (
    <Layout
      SideContent={<SidebarRight />}
      smallHeader="Alumni Business Reporting"
    >
      {isLoading && <CustomLoader fullScreen={true} />}
      <div className="ml-2">
        <Row className="tab-heading mainContainer">
          <Col md={3}>
            <h2 className="mainContainer__title">Overview</h2>
          </Col>
          <Col md={9}></Col>
        </Row>

        <div className="container-box" style={{ marginTop: 10 }}>
          {/* <h6 style={{ fontWeight: 600, fontSize: 12 }}>
            Please select a Report to submit
          </h6> */}
          {isDashboardLoading && <CustomLoader size={30} />}
          {!isDashboardLoading && (
            <>
              <Row>
                {dataBoard.map((data) => (
                  <DataBoard
                    key={data.questionId}
                    md={4}
                    title={data.shortTitle || data.surveyTitle}
                    desc="(TEF Programme)"
                    value={data.value || "Not Answered"}
                    // graph={[10, 0, -2.5, 540]}
                  />
                ))}
              </Row>

              {/* <Row style={{ marginTop: 10 }}>
                <DataBoard
                  title="Revenue Generated"
                  desc="(after TEF Programme)"
                  value="600K"
                  graph={[10, 0, -2.5, 540]}
                />
                <DataBoard
                  title="No of People Employed"
                  desc="(after TEF Programme)"
                  value="500"
                  graph={[10, 0, -2.5, 540]}
                />
                <DataBoard
                  title="Business Stage"
                  desc="(Since Programme Completion)"
                  value="Launched Prototype"
                />
              </Row> */}
            </>
          )}

          <div style={{ marginTop: 10 }}>
            <h3>Ongoing Surveys:</h3>
            <div className="row">
              {surveys.length > 0 &&
                surveys.map((survey) => (
                  <Col md={3} key={survey.id}>
                    <SecondaryButton
                      style={{ height: 30, margin: 5 }}
                      color="tef-red"
                      buttonText={
                        <label style={{ textTransform: "capitalize" }}>
                          <RiAddFill color="white" />
                          {survey.title}
                        </label>
                      }
                      buttonAction={() =>
                        history.push(
                          `/alumni/business-reporting/${survey.title
                            .toLowerCase()
                            .replace(/ /g, "_")}/${survey.id}`
                        )
                      }
                    />
                  </Col>
                ))}
            </div>
          </div>
        </div>

        <div className="container-box">
          <h3>Submitted Reports:</h3>
          <Row style={{ marginTop: 20 }}>
            <Col md={12}>
              <div className="container-box__regular-box text-center">
                {isReportsLoading && <CustomLoader size={30} />}
                <div className="d-flex container-box__header-box">
                  <h6 className="col-md-4 text-left">Name</h6>
                  <h6 className="col-md-4 text-center">Last Modified</h6>
                  <h6 className="col-md-4 text-right">Action</h6>
                </div>
                {businessReports.length > 0 &&
                  businessReports.map((survey) => (
                    <div
                      className="d-flex container-box__content-box"
                      key={survey.id}
                    >
                      <Col md={4}>
                        <h6 className="text-left">{survey.title}</h6>
                      </Col>
                      <Col md={4}>
                        <h6 className="text-center">
                          {new Date(survey.createdOn).toLocaleDateString()}
                        </h6>
                      </Col>
                      <Col md={4}>
                        <h6 className="text-right">
                          <LinkButton
                            onClick={() =>
                              history.push(
                                `/alumni/business-reporting/view/${survey.id}`
                              )
                            }
                          >
                            View Report
                          </LinkButton>
                        </h6>
                      </Col>
                    </div>
                  ))}

                {!businessReports.length && (
                  <h6 className="text-center mt-2">
                    You haven&apos;t submitted any survey yet.
                  </h6>
                )}
              </div>
            </Col>

            {/* <Col md={6}>
              <div className="container-box__regular-box text-center">
                <div className="d-flex container-box__header-box">
                  <h6 className="col-md-6 text-left">Name</h6>
                  <h6 className="col-md-6 text-right">Last Modified</h6>
                </div>
                <div className="d-flex container-box__content-box">
                  <Col md={2}>
                    <img className="text-left" src="" alt="PDF Icon" />
                  </Col>
                  <Col md={6}>
                    <h6 className="text-left">
                      Impact Assessment Survey 2020 H2
                    </h6>
                  </Col>
                  <Col md={4}>
                    <h6 className="text-right">14 June 2020 3:45PM</h6>
                  </Col>
                </div>
              </div>
            </Col> */}
          </Row>
        </div>
      </div>
    </Layout>
  );
};

const _getSubmittedStatusForSurvey = (
  surveyId,
  { survey, setSingleBusinessReport, setIsReportsLoading }
) => {
  setIsReportsLoading(true);
  getBusinessReportsStatus(surveyId)
    .then((res) => {
      const { data } = res.data;
      if (data === true) {
        setSingleBusinessReport(survey);
      }
      setIsReportsLoading(false);
    })
    .catch(() => {
      setIsReportsLoading(false);
      toast("Something went wrong, try again");
    });
};

export default ifOnboardedThenShow(BusinessReporting);
