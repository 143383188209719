import React from "react";

import FormInput from "src/modules/Tef-Dms/components/Form/FormInput";
import { RiCloseFill } from "react-icons/ri";

const Management = ({ management, setManagement }) => {
  const handleChange = (e) => {
    setManagement({
      ...management,
      [e.target.name]: e.target.value,
    });
  };

  const handleDynamicInput = (key, i, e) => {
    const newArray = key;
    newArray[i] = e.target.value;
    setManagement({
      ...management,
      [key]: newArray,
    });
  };

  const addRow = (key, value) => {
    const newArray = key;
    newArray.push(value);
    setManagement({
      ...management,
      [key]: newArray,
    });
  };
  return (
    <React.Fragment>
      <div className="pr-2">
        <FormInput
          label="Reason For Type Of Business Ownership:"
          getFormInputValue={(e) => handleChange(e)}
          value={management.reason}
          name="reason"
          placeholder=""
        />

        <FormInput
          label="Details of Employee:"
          getFormInputValue={(e) => handleChange(e)}
          value={management.detailsOfEmployee}
          name="detailsOfEmployee"
          placeholder=""
        />

        <FormInput
          label="Operating Hours:"
          getFormInputValue={(e) => handleChange(e)}
          value={management.operatingHours}
          name="operatingHours"
          placeholder=""
        />

        <div className="mt-5">
          <label>Key Management Team</label>
          <table className="table table-bordered text-center">
            <thead>
              <tr>
                <th scope="row">Position</th>
                <th>Skills, qualification and experience required</th>
                <th>Key Functions and responsibility</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {management.keyManagementTeam.map((item, index) => (
                <tr key={index}>
                  <td>
                    <FormInput
                      key={index}
                      getFormInputValue={(e) =>
                        handleDynamicInput(
                          management.keyManagementTeam,
                          "Position",
                          index,
                          e
                        )
                      }
                      value={item.Position}
                    />
                  </td>
                  <td>
                    <FormInput
                      key={index}
                      getFormInputValue={(e) =>
                        handleDynamicInput(
                          management.keyManagementTeam,
                          "Skills",
                          index,
                          e
                        )
                      }
                      value={item.Skills}
                    />
                  </td>
                  <td>
                    <FormInput
                      key={index}
                      getFormInputValue={(e) =>
                        handleDynamicInput(
                          management.keyManagementTeam,
                          "responsibility",
                          index,
                          e
                        )
                      }
                      value={item.responsibility}
                    />
                  </td>
                  <td className="text-danger">
                    {" "}
                    <RiCloseFill />{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            type="button"
            onClick={() =>
              addRow(management.keyManagementTeam, {
                Position: "",
                Skills: "",
                responsibility: "",
              })
            }
            className="btn btn-primary text-white px-2 py-1 border-0 mt-2"
          >
            Add row
          </button>
        </div>

        <FormInput
          label="Organizational Structure:"
          getFormInputValue={(e) => handleChange(e)}
          value={management.organisationalStructure}
          name="organisationalStructure"
          placeholder=""
        />
      </div>
    </React.Fragment>
  );
};

export default Management;
