import React, { useContext } from "react";
// import icon1 from "src/assets/img/training/icon1.png";
// import icon2 from "src/assets/img/training/icon2.png";
import icon3 from "src/assets/img/training/icon3.png";
import icon4 from "src/assets/img/training/icon4.png";
import { Context } from "src/store";
// import linkIcon from "src/assets/img/linkIcon.png";
// import pdfIcon from "src/assets/img/pdfIcon.png";
// import { Link } from "react-router-dom";
import "./_mainContent.scss";

const TopContent = ({ heading, extraInfo, icon }) => {
  return (
    <div className="right-content__top--item">
      <img src={icon} alt="icon" />
      <div className="right-content__top--item--heading">
        <h6>{heading}</h6>
        <span>{extraInfo}</span>
      </div>
    </div>
  );
};

// const LearningResources = ({ resourceType, resources, resourceTitle }) => {
//   let icon;
//   if (resourceType === "pdf") {
//     icon = pdfIcon;
//   }
//   if (resourceType === "link") {
//     icon = linkIcon;
//   }
//   return (
//     <>
//       <div className="right-content__learning-resources--content--resources-type">
//         <h1 style={{ marginBottom: "27px" }}>
//           {resourceTitle} <span>({resources.length})</span>
//         </h1>
//         {resources.map((resource, index) => {
//           return (
//             <div key={index}>
//               <div className="right-content__learning-resources--content--resources-type--item">
//                 <div className="right-content__learning-resources--content--resources-type--item--img-container">
//                   <img
//                     src={icon}
//                     alt="icon"
//                     style={{ width: "inherit", height: "inherit" }}
//                   />
//                 </div>
//                 <p>{resource}</p>
//               </div>
//               <hr style={{ marginTop: "-8px" }} />
//             </div>
//           );
//         })}
//       </div>
//     </>
//   );
// };

export const MainContentRight = () => {
  // const pdfResources = [
  //   "The Future of Entrepreneurship.pdf",
  //   "Understanding Innovations.pdf",
  //   // "The Startup Game.pdf",
  //   // "The Future of Entrepreneurship.pdf",
  // ];

  const { state } = useContext(Context);

  return (
    <div className="right-content">
      <div className="right-content__top">
        <TopContent
          heading="100% Self-paced"
          extraInfo="Learn at your own schedule. Super easy!"
          icon={icon4}
        />

        <TopContent
          heading={
            state.Training.userCourseModules &&
            state.Training.userCourseModules.segment
          }
          extraInfo="Get started with the basics."
          icon={icon3}
        />

        {/* <TopContent
          heading="Approx. 14 hours to complete"
          extraInfo="3 weeks of study, 4 hrs/week"
          icon={icon1}
        />

        <TopContent
          heading="Video Transcripts"
          extraInfo="English, French & Portugese Languages"
          icon={icon2}
        /> */}
      </div>

      {/* <section className="right-content__learning-resources">
        <h4 className="right-content__learning-resources--heading">
          LEARNING RESOURCES
        </h4>
        <div className="right-content__learning-resources--content">

          <LearningResources
            resourceType="pdf"
            resourceTitle="FREE PDFS"
            resources={pdfResources}
          />

          <Link to="" className="see-more">
            <p>... See more </p>
          </Link>
        </div>
      </section> */}
    </div>
  );
};
