import { asyncActionName } from "src/utils/AsyncUtils";
import {
  FETCH_USER_MENTORS,
  GET_REQUESTED_MENTORS,
  GET_CONNECTIONS,
  GET_REQUESTED_MENTEES,
  FETCH_ENTREPRENEURS,
  GET_SURVEY_QUESTIONS,
  REMOVE_MENTORSHIP_CONNECTION,
  ACCEPT_REQUESTS,
  CONNECT,
  REJECT_REQUESTS,
} from "src/actions";
import { initialState } from "src/store";

const removedConnection = (connections, filter) => {
  const filtered = connections.filter((connected) => {
    return connected.requestId !== filter;
  });
  return filtered;
};

const MentorReducer = (state = initialState.mentors, action) => {
  switch (action.type) {
    case asyncActionName(FETCH_USER_MENTORS).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(FETCH_USER_MENTORS).success:
      return {
        ...state,
        mentors: action.payload,
      };
    case asyncActionName(FETCH_USER_MENTORS).failure:
      return {
        ...state,
        error: action.payload.status,
      };
    case asyncActionName(FETCH_ENTREPRENEURS).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(FETCH_ENTREPRENEURS).success:
      return {
        ...state,
        entrepreneurs: action.payload,
      };
    case asyncActionName(FETCH_ENTREPRENEURS).failure:
      return {
        ...state,
        error: action.payload.status,
      };
    case asyncActionName(GET_REQUESTED_MENTORS).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(GET_REQUESTED_MENTORS).success:
      return {
        ...state,
        unconnected: action.payload,
      };
    case asyncActionName(ACCEPT_REQUESTS).success:
      return {
        ...state,
        unconnected: state.unconnected.filter(
          (conn) => conn.requestId !== action.payload
        ),
      };

    case asyncActionName(REJECT_REQUESTS).success:
      return {
        ...state,
        unconnected: state.unconnected.filter(
          (conn) => conn.requestId !== action.payload
        ),
      };

    case asyncActionName(CONNECT).success:
      return {
        ...state,
        unconnected: [...state.unconnected, { ...action.payload }],
      };

    case asyncActionName(GET_REQUESTED_MENTORS).failure:
      return {
        ...state,
        error: action.payload.status,
      };

    case asyncActionName(GET_REQUESTED_MENTEES).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(GET_REQUESTED_MENTEES).success:
      return {
        ...state,
        unconnectedMentees: action.payload,
      };
    case asyncActionName(GET_REQUESTED_MENTEES).failure:
      return {
        ...state,
        error: action.payload.status,
      };

    case asyncActionName(GET_CONNECTIONS).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(GET_CONNECTIONS).success:
      return {
        ...state,
        connected: action.payload,
      };
    case asyncActionName(GET_CONNECTIONS).failure:
      return {
        ...state,
        error: action.payload.status,
      };

    case asyncActionName(GET_SURVEY_QUESTIONS).loading:
      return { ...state, loading: action.payload };
    case asyncActionName(GET_SURVEY_QUESTIONS).success:
      return {
        ...state,
        survey: action.payload,
      };
    case asyncActionName(GET_SURVEY_QUESTIONS).failure:
      return {
        ...state,
        error: action.payload.status,
      };
    case asyncActionName(REMOVE_MENTORSHIP_CONNECTION).loading:
      return { ...state, loadingRemoveConnection: action.payload };
    case asyncActionName(REMOVE_MENTORSHIP_CONNECTION).success:
      return {
        ...state,
        connected: removedConnection(state.connected, action.payload),
      };
    case asyncActionName(REMOVE_MENTORSHIP_CONNECTION).failure:
      return {
        ...state,
        removeConnectionError: action.payload.status,
      };

    default:
      return state;
  }
};
export default MentorReducer;
