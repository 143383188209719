import React, { useState, useContext } from "react";
import "./FriendsGroupCard.scss";
// import FriendsList from "src/modules/training/StudyGroups/components/FriendsGroup/FriendsList/FriendsList";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import { joinGroup, leaveGroup } from "src/requests/StudyGroupsRequest";
import { useCurrentUser } from "src/utils/Services/currentUser";
import { Context } from "src/store";
import Loader from "src/components/Loader/Loader";

const FriendsGroupCard = ({
  groupId,
  title,
  location,
  members,
  groupImage,
  belongs,
}) => {
  const { dispatch } = useContext(Context);
  const linkTo = `/training/studygroups/group?groupId=${groupId}`;
  const { userId } = useCurrentUser();
  const [joined, setJoined] = useState(belongs);
  const [setLeft] = useState(false);
  const [isJoinLoading, setIsJoinLoading] = useState(false);
  const [noOfMembers, setNoOfMembers] = useState(members);

  const handleJoinGroup = () => {
    setIsJoinLoading(true);
    joinGroup(dispatch, { groupId, userId }).then((_) => {
      setIsJoinLoading(false);
      setJoined(true);
      setLeft(false);
      setNoOfMembers(noOfMembers + 1);
    });
  };

  const handleLeaveGroup = () => {
    setIsJoinLoading(true);
    leaveGroup(dispatch, { groupId, userId }).then((_) => {
      setIsJoinLoading(false);
      setLeft(true);
      setJoined(false);
      setNoOfMembers(noOfMembers - 1);
    });
  };

  const handleClick = () => {
    joined ? handleLeaveGroup() : handleJoinGroup();
  };

  return (
    <div className="friends-group-card">
      <div className="friends-group-card__image">
        <Link to={linkTo}>
          {groupImage ? (
            <img src={groupImage} className="group-image-1" alt="group logo" />
          ) : (
            <Avatar name={title} size="100%" />
          )}
        </Link>
      </div>
      <div className="friends-group-card__details">
        <div className="friends-group-details-info">
          <h1 className="friends-group-details-info__h1">
            <Link to={linkTo}>{title}</Link>
          </h1>
          <h3 className="friends-group-details-info__h3">
            {location} <span>.</span> {members} Members
          </h3>
          {/* <FriendsList /> */}
        </div>
        <button
          disabled={isJoinLoading}
          className={`friends-group-card-details-button ${
            joined
              ? "friends-group-card-button-active"
              : "friends-group-card-button-inactive"
          }`}
          onClick={handleClick}
        >
          {isJoinLoading ? (
            <Loader color="light" />
          ) : joined ? (
            "leave group"
          ) : (
            "join group"
          )}
        </button>
      </div>
    </div>
  );
};

export default FriendsGroupCard;
