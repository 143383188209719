import React from "react";
import styles from "./NotEligible.module.scss";

const NotEligible = ({ cancel }) => {
  return (
    <div className={styles.NotEligible}>
      <h3 className={styles.NotEligible__h3}>
        Sorry, you are not eligible for training
      </h3>
      <button className={styles.NotEligible__btn} onClick={cancel}>
        OK
      </button>
    </div>
  );
};

export default NotEligible;
