import React, { useState, useContext } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { createPost } from "src/requests/PostRequest";
import { Context } from "src/store";
import classes from "./ShareOpportunityModal.module.css";
import { useTranslation } from "react-i18next";

const ShareOpportunityModal = ({ toggle, modal }) => {
  const { state } = useContext(Context);
  const { t } = useTranslation();
  const [shareOpportunity, setShareOpportunity] = useState(
    `${window.location.href}`
  );
  const { opportunity } = state.Opportunities;
  const { dispatch } = useContext(Context);
  const payload = {
    post: {
      content: shareOpportunity,
      accessibleBy: 0,
    },
    files: [
      {
        url: opportunity.fileName,
      },
    ],
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    createPost(dispatch, payload);
    toggle();
  };

  // EditorBox
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {t("Share Opportunity on TEFConnect")}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <div className={classes.ShareOpportunity}>
              <textarea
                value={shareOpportunity}
                onChange={(e) => setShareOpportunity(e.target.value)}
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleSubmit}>
            {t("Share on TEFConnect")}
          </Button>{" "}
          <Button color="primary" onClick={toggle}>
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ShareOpportunityModal;
