import React, { useState, useContext, useEffect } from "react";
import cancelIcon from "src/assets/img/cancel.png";
import { FormField } from "src/modules/onboarding/components";
import "./_stepThree.scss";
import { Button } from "src/modules/training/components";
import CModal from "src/modules/onboarding/components/CModal";
import Header from "src/modules/onboarding/components/Header";
import shortid from "shortid";
import {
  CastValue,
  filterListOnSearch,
  retrieveUserTempProfile,
  storeOnboardingProgress,
} from "src/modules/onboarding/helper";
import _ from "lodash";
import { Context } from "src/store";
import { getInterests, submitOnboardingData } from "src/requests/onboarding";
import { defaultUserInfo } from "src/modules/onboarding/stepOne/userInfo";
import ContentLoader from "react-content-loader";
import { getInterestIcon } from "./getInterestIcons";
import {
  get_user_info_from_token,
  refreshToken,
} from "src/utils/someTokenMenthods";
import { getUserUpdatedProfile } from "src/requests/ProfileRequest";
import { useTranslation } from "react-i18next";
import { ifNotOnboardedThenShow } from "src/utils/Services/isOnboarded";

export const _setCurrentStep = (
  info,
  currentStepUrl,
  nextStepUrl,
  previousStep
) => {
  const completd = info.steps.completedSteps;
  completd.push(currentStepUrl);
  info.steps.completedSteps = _.uniq(completd);
  info.steps.currentCompletedStep = currentStepUrl;
  info.steps.nextStep = nextStepUrl;
  info.steps.previousStep = previousStep;
  return info;
};

const Loader = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "20px",
        border: "1px solid #c4cdd5",
        borderRadius: "10px",
        margin: "8px",
      }}
    >
      <ContentLoader
        speed={2}
        width={200}
        height={160}
        viewBox="0 0 400 160"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
        <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
        <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
        <circle cx="20" cy="20" r="20" />
      </ContentLoader>
    </div>
  );
};

const showLoadingInterest = () => {
  const loaders = [];
  for (let i = 0; i <= 5; i++) {
    loaders.push(<Loader key={shortid.generate()} />);
  }

  return loaders;
};

const decideStage = (userInfo) => {
  const { role, isTefMentor } = userInfo;
  if (role.toLowerCase() === "mentor") {
    if (isTefMentor) {
      return "4 of 4";
    }
    return "3 of 3";
  } else if (role.toLowerCase().includes("investor")) {
    return "4 of 4";
  }
  return "3 of 3";
};

const InterestItem = ({ name, selected, onSelect, followersCount }) => {
  // const selected = seletectedInterests.indexOf(name) >= 0;
  const selectedBg = selected ? "#da291c" : "#fff";
  const selectedTextColor = selected ? "#fff" : "#da291c";
  const selectedText = selected ? "Selected" : "Select";
  return (
    <div className="step-three-page-container__section--content__main--items-item">
      <div className="item-bg">
        <div style={{ height: "66px", width: "66px" }}>
          <img
            src={getInterestIcon(name)}
            alt="icon"
            className="img-fluid"
            style={{ margin: "0 auto" }}
          />
        </div>
      </div>
      <h1>{name}</h1>
      <span>{followersCount + " Follower(s)"}</span>

      <div className="select-btn">
        <Button
          value={selectedText}
          bgColor={selectedBg}
          textColor={selectedTextColor}
          height="36px"
          width="134px"
          fontSize="18px"
          onClick={onSelect}
        />
      </div>
    </div>
  );
};

const AddNewInterests = ({ onBackClick, onSaveClick }) => {
  const [name, setName] = useState("");
  const [added, setAdded] = useState([]);

  const setN = (target, value) => {
    console.log(target);
    setName(value);
  };
  const _onAddNewTopicCLick = () => {
    const existing = [...added];
    if (name.length > 2) {
      existing.push({ name });
      setAdded(_.uniqBy(existing, "name"));
      setName("");
    }
  };
  const onRemoveTopic = (i) => {
    const existing = [...added];
    const filterd = existing.filter((item, index) => {
      console.log(item);
      return index !== i;
    });
    setAdded(filterd);
  };

  // const _onBackClick = () => {
  //   setAddedTopics([]);
  //   onBackClick();
  // };
  const _onSaveClick = () => {
    onSaveClick(added);
    // onChange("newInterests", added);
  };
  return (
    <div>
      <h1
        style={{
          fontFamily: "Roboto",
          fontSize: "20px",
          fontWeight: "500",
          color: "#3e3f42",
        }}
      >
        Add Interests
      </h1>
      <hr />
      <div className="input">
        <FormField
          label="Add interest"
          labelInfo="Optional"
          className="add-topic"
          onChange={setN}
          value={name}
          placeholder="Type in a new interest"
          name="addInterest"
        />
        {name.length > 2 && (
          <div className="suggestion-box">
            <span
              style={{
                flex: 1,
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "500",
                letterSpacing: "0.2",
                color: "#26292d",
              }}
            >
              {name}
            </span>
            <span
              style={{
                flex: 1,
                fontFamily: "Poppins",
                fontSize: "12px",
                fontWeight: "500",
                letterSpacing: "0.3",
                color: "#919ba8",
                cursor: "pointer",
              }}
              onClick={_onAddNewTopicCLick}
            >
              Add new interest
            </span>
          </div>
        )}
      </div>
      {added.length > 0 && (
        <div className="added-topics-items">
          {added.map((item, i) => {
            return (
              <div
                className="added-topics-items__item"
                key={shortid.generate()}
              >
                <span>{item.name}</span>
                <span
                  style={{
                    width: "8px",
                    height: "8px",
                    marginLeft: "19px",
                    cursor: "pointer",
                  }}
                  onClick={() => onRemoveTopic(i)}
                >
                  <img src={cancelIcon} alt="icon" className="img-fluid" />
                </span>
              </div>
            );
          })}
        </div>
      )}

      <hr />
      {/* {interestLength + added.length > 5 && (
        <span style={{ color: "red", fontSize: "10px" }}>
          you are allowed only 5 interests
        </span>
      )} */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Button
          value="Back"
          width="83px"
          height="38px"
          fontSize="14px"
          bgColor="#fff"
          textColor="#3e3f42"
          borderColor="#d8dce6"
          onClick={onBackClick}
        />
        <Button
          value="Add Interest"
          width="113px"
          height="38px"
          fontSize="14px"
          onClick={() => _onSaveClick()}
          // isDisabled={interestLength + added.length > 5}
        />
      </div>
    </div>
  );
};

const StepThreeOnboarding = (props) => {
  const [info, setInfo] = useState(defaultUserInfo);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [interests, setIntetrests] = useState([]);
  const [filteredInterest, setFilteredInterest] = useState([]);
  const [searchString, setSearchString] = useState("");
  const { state, dispatch } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const { user } = get_user_info_from_token();
  const { t } = useTranslation();
  let r = "mentor";

  useEffect(() => {
    // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // if (!userInfo || userInfo === null) {
    //   // props.history.push({ pathname: "/onboarding", search: "" });
    //   props.history.replace("/onboarding", {});
    //   props.history.go(-1);
    // } else {
    //   if (!userInfo.groupId || userInfo.groupId === null) {
    //     props.history.replace("/onboarding", {});
    //     props.history.go(-1);
    //   } else {
    //     setInfo(userInfo);
    //   }
    // }

    window.scrollTo(0, 0);

    retrieveUserTempProfile().then((profile) => {
      if (!profile || profile === null) {
        props.history.push({ pathname: "/onboarding", search: "" });
      } else {
        if (!profile.groupId || profile.groupId === null) {
          props.history.go(-1);
        } else {
          setInfo(profile);
        }
      }
    });

    getInterests(dispatch).then((res) => {
      const { status, data } = res;
      if (status === 200) {
        setIntetrests(data);
        setFilteredInterest(data);
      } else {
        setIntetrests([]);
        setFilteredInterest([]);
      }
    });
  }, []);

  const _handleSearch = (target, value) => {
    console.log(target);
    setSearchString(value);
    if (value.length === 0) {
      setFilteredInterest(interests);
    } else {
      const filtered = filterListOnSearch(interests, value, "name");
      setFilteredInterest(filtered);
    }
  };

  const _handleChange = (target, value) => {
    setInfo({ ...info, [target]: CastValue(target, value) });
  };

  const onSelectTopic = (item) => {
    let interests = [...selectedInterests];
    if (selectedInterests.indexOf(item) >= 0) {
      interests = interests.filter((i) => {
        return i !== item;
      });
      setSelectedInterests(interests);
    } else {
      interests.push(item);
      setSelectedInterests(interests);
    }
  };

  const _onSelectUserIneterests = (item) => {
    let interests = [...info.userInterests];
    if (interests.findIndex((int) => int.interestId === item.id) >= 0) {
      interests = interests.filter((interest) => {
        return interest.interestId !== item.id;
      });
      _handleChange("userInterests", interests);
    } else {
      // if (info.userInterests.length + info.newInterests.length >= 5) {
      //   return;
      // }
      interests.push({ interestId: item.id });
      _handleChange("userInterests", interests);
    }
  };

  const _onSelectNewIneterests = (item) => {
    let interests = [...info.newInterests];
    if (interests.findIndex((int) => int.name === item.name) >= 0) {
      interests = interests.filter((interest) => {
        return interest.name !== item.name;
      });
      _handleChange("newInterests", interests);
    } else {
      if (info.newInterests.length + info.newInterests.length >= 5) {
        return;
      }
      interests.push({ name: item.name });
      _handleChange("newInterests", interests);
    }
  };

  const _onSaveNewTopics = (newInterests) => {
    const existing = [...info.newInterests, ...newInterests];
    _handleChange("newInterests", existing);
    setOpenAddModal(!openAddModal);
  };

  const _submit = (data) => {
    setLoading(true);
    submitOnboardingData(dispatch, data, props.history).then((res) => {
      const { status } = res;
      if (status === 201 || status === 200) {
        return refreshToken()
          .then(() => {
            return getUserUpdatedProfile(dispatch, user.id).then(() => {
              setLoading(false);
              return props.history.push({
                pathname: "/onboarding/success",
                search: `?ent=${
                  r.toLowerCase().includes("entrepreneur") ? "true" : "false"
                }`,
              });
            });
          })
          .catch((e) => {
            setLoading(false);
            props.history.push({
              pathname: "/onboarding/success",
              search: `?ent=${
                r.toLowerCase().includes("entrepreneur") ? "true" : "false"
              }`,
            });
          });
      }
      setLoading(false);
    });
    // .catch((e) => {
    //   console.log(e);
    //   setLoading(false);
    // });
  };

  const makeEmptyNull = (list, data) => {
    const obj = { ...data };
    for (const key of list) {
      if (obj[key].length === 0) {
        obj[key] = null;
      }
    }
    return obj;
  };

  const _sanitizeData = (data) => {
    const userArea = data.userMentorshipAreas.map((area) => {
      const { value } = area;
      return { mentorshipAreaId: value };
    });
    const userSkills = data.userSkills.map((skill) => {
      const { skillId } = skill;
      return { skillId };
    });

    // const phone = data["countryPhoneCode"] + data["phone"];
    const obj = {
      ...data,
      userMentorshipAreas: userArea,
      userSkills: userSkills,
      // ["phone"]: phone,
    };
    r = obj.role;
    obj.firstName = user.firstName;
    obj.lastName = user.lastName;
    obj.email = user.email;
    if (!obj.language || obj.language === "") {
      delete obj.language;
    }
    // delete obj["countryPhoneCode"];
    delete obj.role;
    delete obj.validationErrors;
    // delete obj["steps"];
    return makeEmptyNull(
      [
        "facebookUrl",
        "twitterUrl",
        "websiteUrl",
        "linkedinUrl",
        "businessWebsite",
        "imageUrl",
        "youtubeUrl",
        "instagramUrl",
      ],
      obj
    );
    // return obj;
  };

  const _onGoBackClick = () => {
    let uInfo = JSON.parse(JSON.stringify(info));
    Object.keys(uInfo.validationErrors).forEach((key) => {
      uInfo.validationErrors[key] = null;
    });
    uInfo = _setCurrentStep(
      uInfo,
      uInfo.steps.currentCompletedStep,
      window.location.pathname,
      decidePreviousStep(info.role, info.isTefMentor)
    );
    previousStep(uInfo).then(() => {
      props.history.replace(
        decidePreviousStep(info.role, info.isTefMentor),
        {}
      );
      props.history.go(1);
    });
  };

  const previousStep = (userInfo) => {
    return new Promise((resolve, reject) => {
      const info = JSON.parse(JSON.stringify(userInfo));
      storeOnboardingProgress(JSON.stringify(info)).then((status) => {
        if (status === "success") {
          return resolve();
        }
        // return reject("failed to submit");
      });
      // window.localStorage.setItem("userInfo", JSON.stringify(info))
    });
  };

  const decidePreviousStep = (role) => {
    if (role.toLowerCase() === "mentor") {
      return "/onboarding/tef-mentor";
    } else if (info.role.toLowerCase().includes("investor")) {
      return "/onboarding/investor";
    } else {
      return "/onboarding/step-two";
    }
  };

  const _saveAndContinue = (userInfo) => {
    const { role } = userInfo;
    let data = Object.assign({}, userInfo);
    delete data.roledId;

    if (role.toLowerCase() === "mentor") {
      delete data.investor;
      delete data.isTefMentor;
      data = _sanitizeData(data);
      if (!data.tefMentor.isATefMentor) {
        data.tefMentor.yearOfTefMentorship = "";
      }
      data.businessName = data.tefMentor.organization;
      data.tefMentor.isAspiringTefMentor = data.tefMentor.isAspiringMentor;
      delete data.tefMentor.isAspiringMentor;
      _submit(data);
    } else if (info.role.toLowerCase().includes("investor")) {
      delete data.isTefMentor;
      delete data.tefMentor;
      const priorInvestment = data.investor.priorInvestment;
      data.priorInvestment = priorInvestment;
      delete data.investor.priorInvestment;
      data = _sanitizeData(data);
      _submit(data);
    } else {
      delete data.investor;
      delete data.isTefMentor;
      delete data.tefMentor;
      data = _sanitizeData(data);
      // console.log(data);
      _submit(data);
    }
  };

  return (
    <div>
      <Header {...props} />
      <div className="step-three-page-container">
        <div className="step-three-page-container__banner">
          <div
            className="custom-row"
            style={{
              justifyContent: "space-between",
              display: "flex",
              marginTop: "-55px",
              marginBottom: "44px",
            }}
          >
            <span className="backSkipButtons" onClick={_onGoBackClick}>
              {`< ${t("Back")}`}
            </span>
            <span
              className="backSkipButtons"
              onClick={() => _saveAndContinue(info)}
            >
              Skip
            </span>
          </div>
          <h1>Choose your interests</h1>
          <p>
            Help us curate the best topics and opportunities that is only
            relevant to you.
          </p>
        </div>

        <section className="step-three-page-container__section">
          <div className="step-three-page-container__section--content">
            <div className="step-three-page-container__section--content__search-form">
              <FormField
                placeholder="Search and discover new interests…"
                className="form-height"
                value={searchString}
                onChange={_handleSearch}
              />
            </div>
            <div className="step-three-page-container__section--content__main">
              <h1>Explore Topics by Categories</h1>
              <div className="step-three-page-container__section--content__main--items">
                {state.Onboarding.requestingInterests
                  ? showLoadingInterest()
                  : filteredInterest.length > 0 &&
                    filteredInterest.map((interest) => {
                      return (
                        <InterestItem
                          key={shortid.generate()}
                          name={interest.name}
                          onSelect={() =>
                            onSelectTopic(_onSelectUserIneterests(interest))
                          }
                          selected={
                            info.userInterests.findIndex(
                              (int) => int.interestId === interest.id
                            ) >= 0
                          }
                          // followersCount={Math.floor(
                          //   Math.random() * (1000 - 0) + 0
                          // )}
                          followersCount={interest.followers || 0}
                        />
                      );
                    })}

                {info.newInterests.length > 0
                  ? info.newInterests.map((interest) => {
                      return (
                        <InterestItem
                          key={shortid.generate()}
                          name={interest.name}
                          onSelect={() => _onSelectNewIneterests(interest)}
                          selected={
                            info.newInterests.findIndex(
                              (int) => int.name === interest.name
                            ) >= 0
                          }
                          followersCount={0}
                        />
                      );
                    })
                  : null}
                {/* <AddInterest
                  onAddClick={() => setOpenAddModal(!openAddModal)}
                /> */}
              </div>
            </div>
            <section
              style={{
                marginTop: "61px",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
              className="pad-inner-center"
            >
              <div style={{ flex: 1 }}>
                <Button
                  width="303px"
                  height="54px"
                  value="Save & Continue"
                  onClick={() => _saveAndContinue(info)}
                />
              </div>

              <span
                style={{
                  flexGrow: 1,
                  alignSelf: "center",
                  marginLeft: "10px",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  color: "#919ba8",
                  // textAlign: "center",
                }}
                className="steps-count"
              >
                Step {decideStage(info)}
              </span>
            </section>
          </div>
        </section>
        <CModal
          isOpen={openAddModal}
          closeModal={() => setOpenAddModal(!openAddModal)}
        >
          <AddNewInterests
            onBackClick={() => setOpenAddModal(!openAddModal)}
            onSaveClick={_onSaveNewTopics}
            addedInterests={info.newInterests}
            onChange={_handleChange}
            interestLength={
              info.userInterests.length + info.newInterests.length
            }
          />
        </CModal>

        <CModal isOpen={loading} closeModal={() => setLoading(!loading)}>
          <span
            style={{
              marginLeft: "24px",
              fontFamily: "Poppins",
              fontSize: "25px",
              color: "#919ba8",
              textAlign: "center",
            }}
          >
            Setting up your profile....Please wait
          </span>
        </CModal>
      </div>
    </div>
  );
};

export default ifNotOnboardedThenShow(StepThreeOnboarding);
// export default StepThreeOnboarding;
