import React from "react";
import { FormGroup, Input } from "reactstrap";
import classes from "./bankdetailsform.module.css";

const FormSelect = ({
  label,
  status,
  getFormInputValue,
  value,
  name,
  required,
  options,
}) => {
  return (
    <FormGroup>
      <div className={`${classes.formgroupcontainer}`}>
        <p>
          {label} {""}
          <em>
            <small style={{ color: "#BDC3CC" }}>{status}</small>
          </em>
        </p>
        <Input
          className={`shadow-none ${classes.bankdetailsinput}`}
          type="select"
          style={{ backgroundColor: "#f0f0f0", border: "none" }}
          name={name}
          value={value}
          required={required}
          onChange={getFormInputValue}
        >
          <option>--Select--</option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.title}
            </option>
          ))}
        </Input>
      </div>
    </FormGroup>
  );
};

FormSelect.defaultProps = {
  options: [],
};

export default FormSelect;
