export const appointmentObj = {
  title: "",
  description: "",
  selectMentor: "",
  location: "",
  startDate: "",
  endDate: "",
  startTime: "",
  endTime: "",
};

export const appointmentValidationErrors = {
  title: null,
  description: null,
  selectMentor: null,
  location: null,
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null,
};
