import axios from "axios";
import { asyncActions } from "src/utils/AsyncUtils";
import { discoverURL } from "src/constants";
import { toast } from "react-toastify";
import {
  GET_ALL_ARTICLES,
  GET_ALL_ARTICLES_CATEGORIES,
  CREATE_ARTICLES,
  GET_ALL_ARTICLES_TAGS,
  GET_ARTICLES_LIKES,
  CREATE_ARTICLE_COMMENT,
  GET_ALL_ARTICLE_COMMENTS,
  GET_TOP_ARTICLES,
  GET_ARTICLE,
  INCREASE_ARTICLE_VIEW,
  GET_DISCOVER_MODULE_VIDEOS,
  UPDATE_ARTICLE,
} from "src/actions";
import Swal from "sweetalert2";

export const increaseArticleView = (dispatch, id) => {
  dispatch(asyncActions(INCREASE_ARTICLE_VIEW).loading(true));
  return axios({
    method: "POST",
    url: `${discoverURL.INCREASE_ARTICLE_VIEW_URL}/${id}/views`,
  });
};
export const getTopArticles = (dispatch) => {
  dispatch(asyncActions(GET_TOP_ARTICLES).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_TOP_ARTICLES_URL}?count=5`,
  });
};

export const getAllArticles = (dispatch, pageNumber, sortString = "") => {
  dispatch(asyncActions(GET_ALL_ARTICLES).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ALL_ARTICLES_URL}?pageNumber=${pageNumber}&SortBy=${sortString}`,
  });
};
export const getArticle = (dispatch, id) => {
  dispatch(asyncActions(GET_ARTICLE).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ALL_ARTICLES_URL}/${id}`,
  });
};

export const getAllCategories = (dispatch) => {
  dispatch(asyncActions(GET_ALL_ARTICLES_CATEGORIES).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ARTICLE_CATEGORY_URL}`,
  })
    .then((res) => {
      dispatch(asyncActions(GET_ALL_ARTICLES_CATEGORIES).loading(false));
      if (res.status === 200) {
        const {
          data: { data },
        } = res.data;
        dispatch(asyncActions(GET_ALL_ARTICLES_CATEGORIES).success(data));
        dispatch(asyncActions(GET_ALL_ARTICLES_CATEGORIES).loading(false));
      }
      return res;
    })
    .catch((err) => {
      dispatch(asyncActions(GET_ALL_ARTICLES_CATEGORIES).loading(false));
      dispatch(
        asyncActions(GET_ALL_ARTICLES_CATEGORIES).failure(true, err.response)
      );
      return err.response;
    });
};
export const getAllTags = (dispatch) => {
  dispatch(asyncActions(GET_ALL_ARTICLES_CATEGORIES).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ARTICLE_TAGS_URL}`,
  })
    .then((res) => {
      dispatch(asyncActions(GET_ALL_ARTICLES_TAGS).loading(false));
      if (res.status === 200) {
        const {
          data: { data },
        } = res.data;
        dispatch(asyncActions(GET_ALL_ARTICLES_TAGS).success(data));
        dispatch(asyncActions(GET_ALL_ARTICLES_TAGS).loading(false));
      }
      return res;
    })
    .catch((err) => {
      dispatch(asyncActions(GET_ALL_ARTICLES_TAGS).loading(false));
      dispatch(asyncActions(GET_ALL_ARTICLES_TAGS).failure(true, err.response));
      return err.response;
    });
};

export const publishArticle = (dispatch, payload) => {
  dispatch(asyncActions(CREATE_ARTICLES).loading(true));
  return axios({
    method: "post",
    url: discoverURL.GET_ALL_ARTICLES_URL,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(CREATE_ARTICLES).loading(false));
      const { data } = response.data;
      if (response.status === 201) {
        dispatch(asyncActions(CREATE_ARTICLES).success(data));
        dispatch(asyncActions(CREATE_ARTICLES).loading(false));
        // toast.success("Group Successfully Created");
      }
      return response;
    })
    .catch((error) => {
      const {
        data: { message },
      } = error.response;
      const fail =
        "An error occurred, Please ensure all fields are correctly filled, then try again ";
      dispatch(asyncActions(CREATE_ARTICLES).loading(false));
      dispatch(asyncActions(CREATE_ARTICLES).failure(true, error.response));
      toast.error(fail);
      console.log(message, fail);
      return error.response;
    });
};

export const updateArticle = (dispatch, payload, id, bannerUrl = "") => {
  dispatch(asyncActions(UPDATE_ARTICLE).loading(true));
  return axios({
    method: "put",
    url: `${discoverURL.UPDATE_ARTICLE}/${id}`,
    data: { ...payload, bannerUrl },
  })
    .then((response) => {
      dispatch(asyncActions(UPDATE_ARTICLE).loading(false));
      const { data } = response.data;
      if (response.status === 201) {
        dispatch(asyncActions(UPDATE_ARTICLE).success(data));
        dispatch(asyncActions(UPDATE_ARTICLE).loading(false));
        // toast.success("Group Successfully Created");
      }
      return response;
    })
    .catch((error) => {
      const {
        data: { message },
      } = error.response;
      const fail =
        "An error occurred, Please ensure all fields are correctly filled, then try again ";
      dispatch(asyncActions(UPDATE_ARTICLE).loading(false));
      dispatch(asyncActions(UPDATE_ARTICLE).failure(true, error.response));
      toast.error(fail);
      console.log(message, fail);
      return error.response;
    });
};

export const getUserArticleLike = (dispatch, payload) => {
  dispatch(asyncActions(GET_ARTICLES_LIKES).loading(true));
  return axios({
    method: "get",
    url: discoverURL.GET_ARTICLES_LIKES,
  })
    .then((response) => {
      if (response.status === 200) {
        const { data } = response.data;
        dispatch(asyncActions(GET_ARTICLES_LIKES).success(data));
        dispatch(asyncActions(GET_ARTICLES_LIKES).loading(false));
        return response;
      }
    })
    .catch((error) => {
      dispatch(asyncActions(GET_ARTICLES_LIKES).failure(error));
      return error.response;
    });
};
export const createArticleComment = (dispatch, payload) => {
  dispatch(asyncActions(CREATE_ARTICLE_COMMENT).loading(true));
  return axios({
    method: "post",
    url: discoverURL.POST_COMMENT,
    data: payload,
  })
    .then((response) => {
      dispatch(asyncActions(CREATE_ARTICLE_COMMENT).loading(false));
      if (response.status === 200) {
        dispatch(asyncActions(CREATE_ARTICLE_COMMENT).success(response.data));
        dispatch(asyncActions(CREATE_ARTICLE_COMMENT).loading(false));
        toast("Commented Successfully ✅");
      }
      return response;
    })
    .catch((error) => {
      console.log(error);
      dispatch(asyncActions(CREATE_ARTICLE_COMMENT).loading(false));
      dispatch(
        asyncActions(CREATE_ARTICLE_COMMENT).failure(true, error.response)
      );
      toast("An error occurred, Please try again ");
      return error.response;
    });
};

export const getAllComments = (dispatch, payload, pageNumber) => {
  dispatch(asyncActions(GET_ALL_ARTICLE_COMMENTS).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ALL_ARTICLES_URL}/${payload}/comments?pageNumber=${pageNumber}`,
  });
};
export const toggleLike = (dispatch, articleId) => {
  dispatch(asyncActions(GET_ALL_ARTICLE_COMMENTS).loading(true));
  return axios({
    method: "post",
    url: `${discoverURL.GET_ALL_ARTICLES_URL}/${articleId}/likes/user/toggle`,
  });
};

export const getAllLikeUser = (dispatch, articleId) => {
  dispatch(asyncActions(GET_ALL_ARTICLE_COMMENTS).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ALL_ARTICLES_URL}/${articleId}/likes`,
  });
};

export const getTotalComment = (dispatch, articleId) => {
  dispatch(asyncActions(GET_ALL_ARTICLE_COMMENTS).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_ALL_ARTICLES_URL}/${articleId}/comments/count`,
  });
};
export const getStoryView = (dispatch, articleId) => {
  dispatch(asyncActions(GET_ALL_ARTICLE_COMMENTS).loading(true));
  return axios({
    method: "post",
    url: `${discoverURL.GET_ALL_ARTICLES_URL}/${articleId}/views`,
  });
};

export const getDiscoverModuleVideos = (dispatch) => {
  dispatch(asyncActions(GET_DISCOVER_MODULE_VIDEOS).loading(true));
  return axios({
    method: "get",
    url: `${discoverURL.GET_DISCOVER_MODULE_VIDEOS_URL}`,
  });
};

export const createAndDeleteBookmark = (
  userId,
  contentId,
  title,
  contentType
) => {
  return axios({
    method: "POST",
    url: `${discoverURL.CREATE_AND_DELETE_BOOKMARK}`,
    data: {
      userId,
      contentId,
      title,
      contentType,
    },
  });
};

export const getAllBookmarks = (userId) => {
  return axios({
    method: "GET",
    url: `${discoverURL.GET_BOOKMARKS}/${userId}`,
  });
};

export const deleteArticle = (id) => {
  return axios({
    method: "DELETE",
    url: `${discoverURL.DELETE_ARTICLE}/${id}`,
  });
};

export const downloadResourceSummary = (resourceId, name) => {
  Swal.fire({
    title: "Download?",
    text: "Are you sure you want to continue with this download?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#da291c",
    cancelButtonColor: "#63666a",
    confirmButtonText: "Yes!",
  }).then((res) => {
    if (res.isConfirmed) {
      return axios({
        method: "POST",
        url: `${discoverURL.DOWNLOAD_RESOURCE}/${resourceId}`,
      }).then((resp) => {
        const blob = resp.data;
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, `${name}.docx`);
        } else {
          const downloadLink = window.document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], {
              type:
                ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            })
          );
          downloadLink.download = `${name}.docx`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Unable to complete download, try again!",
        confirmButtonColor: "#da291c",
      });
    }
  });
};

export const searchDiscover = (str, type = "") => {
  return axios({
    method: "GET",
    url: `${discoverURL.SEARCH_DISCOVER}?Type=${type}&SearchTerm=${str}`,
  });
};

export const flagComment = (payload) => {
  return axios({
    method: "POST",
    url: `${discoverURL.FLAG_COMMENT}`,
    data: payload,
  });
};

export const deleteStory = (id) => {
  return axios({
    method: "DELETE",
    url: `${discoverURL.DELETE_STORY}/${id}`,
  });
};

export const deleteComment = (id) => {
  return axios({
    method: "DELETE",
    url: `${discoverURL.DELETE_COMMENT}/${id}`,
  });
};
