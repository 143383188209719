import React, { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { Row, Col, Button, FormGroup, Input } from "reactstrap";
import moment from "moment";
import readingTime from "reading-time";
import "./TefStories.scss";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import { Context } from "src/store";
import Loader from "src/components/Loader/Loader";
import { get_user_info_from_token } from "src/utils/someTokenMenthods";
import {
  FaComment,
  FaHeart,
  FaLink,
  FaEye,
  FaRegHeart,
  FaEdit,
} from "react-icons/fa";
import {
  getStories,
  // getUserArticleLike,
  createStoryComment,
  getAllComments,
  toggleLike,
  getAllLikeUser,
} from "src/requests/DiscoverStoryRequest";
import DOMPurify from "dompurify";
import Avatar from "src/components/Avatar/Avatar";
import ViewComment from "src/modules/Discover/components/components/Comments/ViewComment";
import Layout from "src/components/Layout/Layout";
import RightBar from "src/modules/profile/components/RightBar/RightBar";
import ArticleContentLoader from "src/modules/Discover/components/components/ArticleSkeleton";
import ShareArticleModal from "src/modules/Discover/components/Articles/ShareArticleModal/ShareOpportunityModal";
import Swal from "sweetalert2";
import { FRONTEND_URL } from "../../../../utils/frontendUrl";

const ViewStory = () => {
  const history = useHistory();
  const params = useParams();
  const [story, setStory] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [liked, setLiked] = useState(false);
  const [double] = useState(false);
  const [countLikes, setCountLikes] = useState(0);
  const { dispatch } = useContext(Context);
  const [commentData, setCommentData] = useState("");
  const [postingComment, setPostingComment] = useState(false);
  const [comments, setComments] = useState([]);
  const [likeUsers] = useState([]);
  const [tempCountLikes, setTempCountLikes] = useState(0);
  const [showShareOnTEFModal, setShowShareOnTEFModal] = useState(false);
  const localDomain = `${FRONTEND_URL}/discover/story/${params.id}`;
  useEffect(() => {
    _getStory();
    _getComments(dispatch, params.id, 1);
  }, [likeUsers.length]);
  useEffect(() => {
    getAllLikeUser(dispatch, params.id).then((res) => {
      if (res.status === 200) {
        const { data } = res.data;
        setTempCountLikes(data.length);
        const likeIds = data.map((x) => x.userId).includes(current_user);
        setCountLikes(data.length);
        likeIds ? setLiked(true) : setLiked(false);
      }
    });
  }, [countLikes]);
  const humanDate = (article) => {
    return moment
      .utc(article.createdOn)
      .local()
      .format("MMMM Do YYYY, h:mm:ss a");
  };
  const _getStory = () => {
    getStories(dispatch, params.id).then((res) => {
      if (res.status === 200) {
        const {
          data: { data },
        } = res;
        setIsLoading(false);
        setStory(data);
      }
    });
  };
  let timeToRead = null;
  if (story && story.fullDescription) {
    timeToRead = readingTime(story.fullDescription);
  }
  const handleLikePost = () => {
    toggleLike(dispatch, story.id).then((res) => {
      const { message } = res.data;
      if (message === "like") {
        setLiked(true);
      } else {
        setLiked(false);
      }
    });

    const totalLikeCount =
      countLikes <= tempCountLikes ? tempCountLikes - 1 : tempCountLikes;
    liked ? setCountLikes(totalLikeCount) : setCountLikes(tempCountLikes + 1);
  };
  // const cUser = localStorage.getItem("current_user");
  // const current_user = jwt_decode(cUser).userId;
  const current_user = get_user_info_from_token()?.user?.id ?? "";
  const _getComments = async (dispatch, id, pageNum) => {
    try {
      const comments = await getAllComments(dispatch, id, pageNum);
      const {
        data: { data },
      } = comments.data;
      setIsLoading(false);
      setComments(data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Unable to load comment",
        confirmButtonColor: "#da291c",
      });
    }
  };

  const postComment = (e) => {
    e.preventDefault();
    const payload = {
      storyId: story.id,
      content: commentData,
    };
    setPostingComment(true);
    createStoryComment(dispatch, payload).then((res) => {
      setPostingComment(false);
      setCommentData("");
      _getComments(dispatch, story.id, 1);
      // setReload((prev) => !prev);
    });
  };
  const inputHandler = (e) => {
    const { value } = e.target;
    setCommentData(value);
  };
  const handleShareOnTEFConnect = () =>
    setShowShareOnTEFModal(!showShareOnTEFModal);

  const handleEditStory = () => {
    history.push(`/discover/edit-story/${params.id}`);
  };
  return (
    <Layout SideContent={<RightBar />}>
      {isLoading ? (
        <ArticleContentLoader />
      ) : (
        <div className="view-tef-article container-fluid px-md-5">
          <div
            onClick={() => history.push("/discover")}
            className="font-weight-bolder"
          >
            <span className="cursor-pointer">
              <IoMdArrowBack />
              Back
            </span>
            {/* <span className="px-2">TEF Stories</span>
            <IoMdArrowForward />
            <span>{story.title}</span> */}
          </div>
          <div className="text-center text-justify mt-5">
            <h2 className="text-capitalize">{story.title}</h2>
            <p>{story.shortDecription}</p>
            <div className="mt-4">
              <div className="d-flex">
                <Col md={4} className="">
                  <div className="d-flex pl-md-5">
                    {story.imageUrl ? (
                      <Avatar name="Anon" dimension={30} />
                    ) : (
                      <Avatar name={story.createdBy} dimension={30} />
                    )}
                    <p className="text-justify font-weight-bolder">
                      {story.createdBy || "Anonm"} <br />
                      <span
                        className="font-weight-normal"
                        style={{ fontSize: "12px" }}
                      >
                        {humanDate(story)} ({timeToRead && timeToRead.text})
                      </span>
                    </p>
                  </div>
                </Col>
                {/* <Col md={4} className="">
                  <div className="">
                    <span className="follow-btn">
                      {" "}
                      <IoIosAdd />
                      Follow
                    </span>
                  </div>
                </Col> */}
              </div>
            </div>
          </div>
          <div className="text-center mt-4">
            <img src={story.bannerUrl} alt="article" className="StoryBanner" />
          </div>
          <div className="">
            <Row noGutters className="offset-1 mt-5">
              {/* <Col md={2} className="">
            <div className="related-articles ml-auto order-sm-3">
              <p style={{ fontSize: "12px" }}>
                <span>Related Articles</span>
                <span>
                  <img
                    src={More}
                    className="contain__continue float-right"
                    alt="more"
                  />
                </span>
              </p>
              <p className="font-weight-bolder my-3">
                Useful Custom Hooks For Tired React Dev...
              </p>
              <p className="font-weight-normal" style={{ fontSize: "12px" }}>
                Oct 20, 2020 . 9 mins read
              </p>
            </div>
          </Col> */}
              <Col md={10} className="">
                <div className="text-justify px-md-5 order-sm-1">
                  <p className="mb-4">
                    <div
                      className="Article-short-description"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(story.fullDescription),
                      }}
                    />
                  </p>
                  <div className="d-flex">
                    <button
                      className="svg-actions__like-btn"
                      style={{ marginRight: "25px" }}
                    >
                      <FaEye /> <span>{story.viewCount}</span>
                    </button>
                    <button
                      className="svg-actions__like-btn"
                      style={{ marginRight: "25px" }}
                    >
                      <FaComment /> <span>{comments.length || 0}</span>
                    </button>
                    <button
                      className="svg-actions__like-btn"
                      onClick={handleLikePost}
                      disabled={double}
                    >
                      {liked ? (
                        <FaHeart style={{ color: "#cf1d10" }} />
                      ) : (
                        <FaRegHeart />
                      )}
                      <span>{countLikes} Like</span>
                    </button>
                  </div>
                  <div className="d-flex mt-4">
                    {story &&
                      story.tags &&
                      story.tags.length > 0 &&
                      story.tags.map((item, idx) => (
                        <p key={idx} className="article-tag mr-3">
                          {item.name}
                        </p>
                      ))}
                  </div>
                  {/* <p className="font-weight-bold mt-3">#Tags</p>
              <div className="d-flex">
                <p className="article-tag">Tech</p>
                <p className="article-tag mx-5">Startups</p>
                <p className="article-tag">Music</p>
              </div>  <div className="d-flex">
                <p className="article-tag">Tech</p>
                <p className="article-tag mx-5">Startups</p>
                <p className="article-tag">Music</p>
              </div>
              <p>Posted on October 20, 2020</p>  {/* <p className="font-weight-bold mt-3">#Tags</p>
              <div className="d-flex">
                <p className="article-tag">Tech</p>
                <p className="article-tag mx-5">Startups</p>
                <p className="article-tag">Music</p>
              </div>
              <p>Posted on October 20, 2020</p> */}
                  <h5 className="font-weight-bolder mt-4">Responses</h5>
                  <div>
                    <form onSubmit={postComment}>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <Input
                              type="textarea"
                              rows="3"
                              name="content"
                              value={commentData}
                              id="articleComment"
                              placeholder="Share your thoughts"
                              required
                              onChange={inputHandler}
                            />
                          </FormGroup>
                          <Button
                            className="float-right"
                            disabled={isLoading}
                            type="submit"
                          >
                            {postingComment ? (
                              <Loader color="light" />
                            ) : (
                              "Comment"
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                  <ViewComment
                    postType="tef-story"
                    postId={params.id}
                    commentDa={comments}
                  />
                </div>
              </Col>
              <Col md={2}>
                <div className="order-sm-2 d-flex flex-column cursor-pointer">
                  <p>Share</p>
                  <div className="mb-3">
                    <p>
                      <FacebookShareButton
                        url={localDomain}
                        quote={story.shortDecription}
                      >
                        <FacebookIcon size={20} />
                      </FacebookShareButton>
                    </p>
                    <p>
                      <TwitterShareButton
                        url={localDomain}
                        title={story.shortDecription}
                      >
                        <TwitterIcon size={20} />
                      </TwitterShareButton>
                    </p>
                    <p>
                      <LinkedinShareButton
                        url={localDomain}
                        title={story.shortDecription}
                      >
                        <LinkedinIcon size={20} />
                      </LinkedinShareButton>
                    </p>
                    <p className="share-icon" onClick={handleShareOnTEFConnect}>
                      <FaLink />
                    </p>
                    {story.user && current_user === story.user.id && (
                      <p
                        className="share-icon"
                        onClick={() => handleEditStory()}
                      >
                        <FaEdit />
                      </p>
                    )}
                  </div>
                  <ShareArticleModal
                    toggle={handleShareOnTEFConnect}
                    modal={showShareOnTEFModal}
                    banner={story.bannerUrl}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default ViewStory;
