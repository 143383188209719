export const investorRole = [
  {
    label: "I invest as an individual (as an angel investor)",
    value: "I invest as an individual (as an angel investor)",
  },
  {
    label: "I represent a Certified/Registered Investment Advisory company",
    value: "I represent a Certified/Registered Investment Advisory company",
  },
  {
    label: "I work at a venture capital firm",
    value: "I work at a venture capital firm",
  },
  {
    label: "I scout for emerging startups",
    value: "I scout for emerging startups",
  },
];
export const previousInvestmentHistory = [
  {
    label: "I have invested in the public markets",
    value: "I have invested in the public markets",
  },
  {
    label: "I have invested in a VC fund",
    value: "I have invested in a VC fund",
  },
  {
    label: "I have invested in a single-deal SPV (Special Purpose Vehicle)",
    value: "I have invested in a single-deal SPV (Special Purpose Vehicle)",
  },
  {
    label: "I have received equity for advising a startup",
    value: "I have received equity for advising a startup",
  },
];

export const folioSize = [
  { label: "$10,000 - $100,000", value: "$10,000 - $100,000" },
  { label: "$100,000 - $1m", value: "$100,000 - $1m" },
  { label: "$1m - $10m", value: "$1m - $10m" },
];

export const investmentStages = [
  { label: "Seed Stage", value: "Seed Stage" },
  { label: "Series A", value: "Series A" },
  { label: "Series B", value: "Series B" },
  { label: "Series C", value: "Seed C" },
];

export const allYear = () => {
  const years = [];
  const currentYear = new Date().getFullYear();

  for (let i = currentYear; i >= 1900; i--) {
    years.push({ label: i, value: i });
  }
  return years;
};
