import React, { useContext } from "react";
import { _getStatusColor } from "src/modules/profile/components/GoalDetails/GoalDetails";
import { TiPencil } from "react-icons/ti";
import { FiTrash } from "react-icons/fi";
import { updateGoalStatus } from "src/requests/GoalRequests";
import { deletePersonalGoal } from "src/requests/PersonalGaolRequest";
import { Context } from "src/store";
import Loader from "react-loader-spinner";

export const GoalUpdateCard = (props) => {
  return (
    <>
      {props.show ? (
        <div className="goal-update-card" id="mouse-down">
          {props.children}
        </div>
      ) : null}
    </>
  );
};

export const UpdateGoalStatus = ({ setShowCard, goalId, loading }) => {
  const { dispatch } = useContext(Context);

  const status = {
    inProgress: 1001,
    underReview: 1003,
    completed: 1002,
  };

  const updateStatus = (status) => {
    updateGoalStatus(dispatch, goalId, status).then(() => {
      setShowCard(false);
    });
  };

  return (
    <div className="goal-status-update-card-items" id="mouse-down-update">
      {loading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader type="Rings" color="var(--tef-red)" height={30} width={30} />
        </div>
      )}
      <div className="goal-status-update-head">
        <span>Select Status</span>
        <span style={{ cursor: "pointer" }} onClick={setShowCard}>
          X
        </span>
      </div>
      <div className="status-list">
        <span
          onClick={() => {
            updateStatus(status.inProgress);
          }}
          className={`status-list__item ${_getStatusColor("Not started")}`}
        >
          Not Started
        </span>
        <span
          onClick={() => {
            updateStatus(status.underReview);
          }}
          className={`status-list__item ${_getStatusColor("under review")}`}
        >
          Under Review
        </span>
        <span
          onClick={() => {
            updateStatus(status.completed);
          }}
          className={`status-list__item ${_getStatusColor("Completed")}`}
        >
          Completed
        </span>
      </div>
    </div>
  );
};

export const GoalMoreItem = ({ setShowCard, goalId, loading, toggle }) => {
  const { dispatch } = useContext(Context);

  const _deleteGoal = () => {
    deletePersonalGoal(dispatch, goalId).then(() => {
      setShowCard(false);
    });
  };

  return (
    <div className="goal-more-list" id="mouse-down">
      {loading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader type="Rings" color="var(--tef-red)" height={30} width={30} />
        </div>
      )}
      <div className="goal-status-update-head">
        <span></span>
        <span style={{ cursor: "pointer" }} onClick={() => setShowCard(false)}>
          X
        </span>
      </div>
      <div className="goal-more-list__item" onClick={toggle}>
        <TiPencil
          size={"14px"}
          color="#26292d"
          style={{ marginRight: "10px", alignSelf: "center" }}
        />{" "}
        Edit
      </div>
      <div className="goal-more-list__item" onClick={_deleteGoal}>
        <FiTrash
          size={"14px"}
          color="#26292d"
          style={{ marginRight: "10px", alignSelf: "center" }}
        />{" "}
        Delete
      </div>
    </div>
  );
};
