import React from "react";
import Layout from "src/modules/Application/components/Layout/Layout";
import BioPage from "./bio";

const Bio = (props) => {
  return (
    <div>
      <Layout>
        <BioPage {...props} />
      </Layout>
    </div>
  );
};

export default Bio;
